import { actionCreatorFactory } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('Pages/KarteMemberResults')

export default {
  fetch: actionCreator.async<{ leaderId: string; id: string }, any>('FETCH'),
  resetError: actionCreator<{}>('RESET_ERROR'),

  empty: actionCreator<{}>('empty'),
}
