import React from 'react'

import { IconButton, InputAdornment, TextField, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'

import { LoadingCircular } from 'pages/teams/components'
import { TeamKarte, TeamMember, TeamMemberRole } from 'utils/generated'

import SearchSvg from '../../../assets/search.svg'
import { KarteValueType } from '../../../utils'

import { DataRow } from './components/DataRow'
import { DataRowHeaders } from './components/DataRowHeaders'
import { TimesFilter } from './components/TimesFilter'

import {
  COLOR_ONBOARDING_GRAY_LIGHT,
  COLOR_ONBOARDING_MAIN,
  COLOR_ONBOARDING_TEXT_GRAY,
  TEXT_WHITE,
  COLOR_TEAMBUILDING_NEUTRAL_200,
  COLOR_TEAMBUILDING_NEUTRAL_500,
} from 'assets/constants'
import { theme } from 'assets/theme'

export const getTypeClass = (type: KarteValueType | null) => {
  switch (type) {
    case KarteValueType.Result:
      return 'graph'
    case KarteValueType.Achievement:
      return 'display'
    case KarteValueType.TeamBuilding:
      return 'people'
    default:
      return null
  }
}

export const serialNumDescList = (max: number) => (max ? Array.from({ length: max }, (_, i) => max - i) : [])

export interface TeamKarteWithTime extends TeamKarte {
  time: number
}

export const DataList: React.FC<{
  teamMemberList: TeamMember[]
  teamKarteList: TeamKarte[]
  loaded: boolean
  setSelectedTeamKarte: (value: TeamKarte) => void
}> = ({ teamMemberList, teamKarteList, loaded, setSelectedTeamKarte }) => {
  const classes = useStyles()
  const isSmUP = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUP = useMediaQuery(theme.breakpoints.up('md'))
  const size = isSmUP ? (isMdUP ? 'md' : 'sm') : 'xs'

  const [page, setPage] = React.useState(1)
  const [teamMemberListWithOpen, setTeamMemberListWithOpen] = React.useState<TeamMember[]>(teamMemberList)

  React.useEffect(() => {
    const listWithoutAdmin = teamMemberList
      .filter((m) => m.role.includes(TeamMemberRole.Member) || m.role.includes(TeamMemberRole.Leader))
      .sort((a, b) => a.fullName.localeCompare(b.fullName, 'ja')) // 名前順にソート

    setTeamMemberListWithOpen(listWithoutAdmin)
  }, [teamMemberList])

  React.useEffect(() => {
    setPage(1)
  }, [size, setPage])

  const [filter, setFilter] = React.useState({ name: '' })
  const [allTime, setAllTime] = React.useState<number>()

  // 最もカルテを実施しているメンバーの回数を取得
  const { maxTimes } = teamKarteList.reduce<{ [key: string]: number }>(
    (acc, { userId }) => {
      acc[userId] = (acc[userId] || 0) + 1
      acc.maxTimes = Math.max(acc.maxTimes, acc[userId])
      return acc
    },
    { maxTimes: 0 }
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <div className={classes.title}>みんなの診断結果</div>
        </div>
        <div className={classes.headerRight}>
          <div>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchSvg} alt="search icon" />
                  </InputAdornment>
                ),
              }}
              size="small"
              style={{
                width: 200,
              }}
              className={classes.search}
              classes={{
                root: classes.textField,
              }}
              placeholder="メンバーを検索"
              onChange={(e) => {
                setFilter({ ...filter, name: e.target.value })
              }}
            />
          </div>
          <TimesFilter asyncItem={{ maxTimes, loaded }} setTime={setAllTime} />
        </div>
      </div>

      <div className={classes.body}>
        <div className={classes.tableHead}>
          <div className={classNames(classes.row, classes.headerRow)}>
            <div
              className={classNames(classes.box, classes.name, classes.row)}
              style={{ justifyContent: 'space-between' }}
            >
              名前
              <IconButton
                size="small"
                style={{
                  backgroundColor: page === 1 ? COLOR_ONBOARDING_GRAY_LIGHT : COLOR_ONBOARDING_MAIN,
                }}
                onClick={() => {
                  const next = page - 1
                  if (next > 0) {
                    setPage(next)
                  }
                }}
              >
                <ChevronLeftIcon
                  style={{
                    color: page === 1 ? COLOR_ONBOARDING_TEXT_GRAY : TEXT_WHITE,
                  }}
                />
              </IconButton>
            </div>
            <DataRowHeaders page={page} size={size} setPage={setPage} />
          </div>
        </div>
        <div>
          {loaded ? (
            <>
              <div>
                {teamMemberListWithOpen
                  .filter((teamMember) => {
                    return teamMember.fullName.includes(filter.name)
                  })
                  .map((teamMember, index) => (
                    <DataRow
                      key={`team-karte-data-list-${index}`}
                      page={page}
                      teamMember={teamMember}
                      teamKarteList={teamKarteList}
                      allTime={allTime}
                      size={size}
                      setSelectedTeamKarte={setSelectedTeamKarte}
                    />
                  ))}
              </div>
            </>
          ) : (
            <>
              <LoadingCircular loading={true} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const VALUE_HEIGHT = 76
export const NAME_WIDTH = 195
export const MAX_NAME_WIDTH = NAME_WIDTH - 16 * 2 - 24 - 8 - 24 - 8

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 24,
      backgroundColor: '#fff',

      '& .people': {
        color: '#357895',
        backgroundColor: '#F4FCFC',
      },
      '& .graph': {
        color: '#CE2C2C',
        backgroundColor: '#FCF4F4',
      },
      '& .display': {
        color: '#966D27',
        backgroundColor: '#FCFCF4',
      },

      '& .justifyBetween': {
        justifyContent: 'space-between',
      },
      '& .justifyEnd': {
        justifyContent: 'flex-end',
      },
    },

    title: {
      fontWeight: 'bold',
      fontSize: 14,
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: 16,
      marginBottom: 16,
    },
    headerLeft: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    headerRight: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexDirection: 'column',
      },
    },

    search: {
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: 8,
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: COLOR_TEAMBUILDING_NEUTRAL_200,
        },
      },
      '& input:valid + fieldset': {
        border: `1px solid ${COLOR_TEAMBUILDING_NEUTRAL_200}`,
        borderRadius: 8,
      },
      '& input:valid:focus + fieldset': {
        border: `1px solid ${COLOR_TEAMBUILDING_NEUTRAL_500}`,
      },
    },

    textField: {
      '& input': {
        '&::placeholder': {
          fontSize: 12,
          color: COLOR_TEAMBUILDING_NEUTRAL_500,
          opacity: 1,
        },
      },
    },

    body: {
      border: `1px solid ${COLOR_ONBOARDING_GRAY_LIGHT}`,
      position: 'relative',
    },

    tableHead: {
      borderBottom: `1px solid ${COLOR_ONBOARDING_GRAY_LIGHT}`,
      position: 'sticky',
      top: 48,
      backgroundColor: '#fff',
      zIndex: 999,
    },

    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    headerRow: {
      position: 'sticky',
    },

    box: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: VALUE_HEIGHT,
    },

    name: {
      fontSize: 12,
      padding: 16,

      width: '50%',
      [theme.breakpoints.up('sm')]: {
        minWidth: NAME_WIDTH,
        width: NAME_WIDTH,
      },
    },
  }),
  { name: 'DataList' }
)
