import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { IKarteMemberResults } from 'pages/hataraku/pages/results/interface'
import { makeEvenPoints } from 'pages/hataraku/utils/results'

import { constants } from 'assets'
import { CopperCrownSvg, GoldCrownSvg, SilverCrownSvg } from 'assets/svg'

type Props = {
  points: IKarteMemberResults['points']
}

export const Graph: React.FC<Props> = ({ points }) => {
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <div className={classes.heading}>はたらく動機の傾向</div>
      <ol className={classes.rankingContainer}>
        {points.map((point, index) => (
          <li key={point.title}>
            <RankingItem point={{ ...point, value: makeEvenPoints(point.value) }} rank={index + 1} />
          </li>
        ))}
      </ol>
    </div>
  )
}

type StyleProps = {
  rankingItemColor?: string
}

export const CROWN_MAP: {
  [key: number]: string
} = {
  1: GoldCrownSvg,
  2: SilverCrownSvg,
  3: CopperCrownSvg,
} as const

const RankingItem: React.FC<{
  point: IKarteMemberResults['points'][number]
  rank: number
}> = ({ point, rank }) => {
  const classes = useStyles({ rankingItemColor: point.color })

  // 値を更新させないためのダミー関数
  const handleChange = (e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()

  return (
    <div className={classes.rankingItem}>
      <div className={classes.rankingItemHead}>
        <div className={classes.rankingItemRank}>
          {CROWN_MAP[rank] ? <img src={CROWN_MAP[rank]} alt="crown" /> : rank}
        </div>
        <div className={classes.rankingItemTitle}>{point.title}</div>
      </div>
      <div className={classes.rankingItemBody}>
        <input
          type="range"
          defaultValue={point.value.toFixed(1)}
          min="0"
          max="10"
          step="0.1"
          onChange={() => handleChange}
          className={classes.rankingItemGraph}
        />
        <span className={classes.rankingItemValue}>{point.value.toFixed(1)}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: {
      marginBottom: 88,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 40,
      },
    },

    heading: {
      color: '#fff',
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 24,
    },

    rankingContainer: {
      padding: 40,
      backgroundColor: '#fff',
      borderRadius: 8,
      margin: 0,
      listStyle: 'none',
      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },

      '& li': {
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
          marginBottom: 24, // SPの場合は多めに余白を取る
        },
      },
      '& li:last-child': {
        marginBottom: 0,
      },
    },

    rankingItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    rankingItemHead: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    rankingItemRank: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
      borderRadius: '50%',
      width: 40,
      height: 40,
      color: constants.TEXY_GRAY3,
      fontSize: 16,
      fontWeight: 'bold',

      '& img': {
        width: 40,
        height: 40,
      },
    },

    rankingItemTitle: ({ rankingItemColor }) => ({
      color: rankingItemColor,
      fontSize: 16,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }),

    rankingItemBody: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 24,
      width: '100%',
      maxWidth: 'calc(100% - 300px)',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
        gap: 0,
        maxWidth: '100%',
        marginTop: -16,
      },
    },

    rankingItemGraph: ({ rankingItemColor }) => ({
      '-webkit-appearance': 'none',
      appearance: 'none',
      cursor: 'default',
      width: '100%',
      height: 8,
      background: constants.COLOR_GRAY4,
      border: 'none',
      borderRadius: 10,
      outline: 0,
      pointerEvents: 'none',
      '&:focus': {
        boxShadow: 'none',
      },

      // -webkit-向けのつまみ
      '&::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        width: 16,
        height: 16,
        background: rankingItemColor,
        borderRadius: '50%',
        boxShadow: 'none',
      },

      // Firefox以外ではconsoleに警告が出るので、そもそものスタイル適用を条件分岐で行う
      ...(navigator.userAgent.includes('Firefox') && {
        // -moz-向けのつまみ
        '&::-moz-range-thumb': {
          width: 16,
          height: 16,
          background: rankingItemColor,
          border: 'none',
          borderRadius: '50%',
          boxShadow: 'none',
        },
        // Firefoxで点線が周りに表示されてしまう問題の解消
        '&::-moz-focus-outer': {
          border: 0,
        },
      }),

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    }),

    rankingItemValue: ({ rankingItemColor }) => ({
      fontSize: 16,
      fontWeight: 'bold',
      color: rankingItemColor,
    }),
  }),
  { name: 'Graph' }
)
