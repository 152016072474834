import { useState } from 'react'

import { ICreateConsultationParams } from 'pages/hint/_shared/stores/apis/createConsultation'
import { ICreateHintParams } from 'pages/hint/_shared/stores/apis/createHint'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getParams: () => ICreateHintParams
  setParams: (params: ICreateHintParams) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useModalHandler = (): IProps => {
  const [_params, _setParams] = useState<ICreateConsultationParams>({
    id: '',
    title: '',
    content: '',
    contentPreview: '',
    category: null,
    isPublished: 0,
  })

  /**
   * セットしたEditprのParamsを取得する
   */
  const getParams = (): ICreateHintParams => {
    return _params
  }

  /**
   * EditorのParamsをセットする
   */
  const setParams = (params: ICreateHintParams): void => {
    _setParams(params)
  }

  return {
    getParams,
    setParams,
  }
}
