import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'
type Props = {
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  disabled?: boolean
} & StyleProps

type StyleProps = {
  widthlarge?: number
}

export const ToggleSwitch = (props: Props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.wrapper}>
      <input className="input" id="toggle" type="checkbox" {...props} />
      <label className="label" htmlFor="toggle" />
    </div>
  )
}

// トグルスイッチボタンのサイズ
// default: width 48 * height 24
// widthlarge: width 60 * height 30

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: ({ widthlarge }: StyleProps) => ({
      position: 'relative',
      width: widthlarge || 48,
      height: widthlarge ? 30 : 24,

      '& .input': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        opacity: 0,
        cursor: 'pointer',
      },
      '& .label': {
        width: widthlarge || 48,
        height: widthlarge ? 30 : 24,
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        position: 'relative',
        display: 'inline-block',
        borderRadius: widthlarge ? 18 : 12,
        transition: '0.4s',
        boxSizing: 'border-box',

        '&:after': {
          content: "''",
          position: 'absolute',
          width: widthlarge ? 24 : 20,
          height: widthlarge ? 24 : 20,
          borderRadius: '100%',
          left: widthlarge ? 3 : 2,
          top: widthlarge ? 3 : 2,
          zIndex: 2,
          backgroundColor: constants.COLOR_WHITE,
          transition: '0.2s',
        },
      },
      '& input:checked + .label': {
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '& input:checked + .label:after': {
        left: widthlarge ? 33 : 26,
      },
    }),
  }),
  { name: 'ToggleSwitch' }
)
