import * as React from 'react'

import { Font } from '../atoms/index'

type Props = {
  why: string
  pro: string
  con: string
  ownStyles?: React.CSSProperties
}

export const GhostBackgroundAndBenefitsAndRisks2: React.FC<Props> = ({ why, pro, con, ownStyles }) => {
  return (
    <div style={ownStyles}>
      <Font fontSize={14} bold>
        オバケの出没経緯
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '6px 0 0 0' }}>
        {why}
      </Font>
      <Font fontSize={14} bold ownStyles={{ margin: '24px 0 0 0' }}>
        オバケのご利益
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '6px 0 0 0' }}>
        {pro}
      </Font>
      <Font fontSize={14} bold ownStyles={{ margin: '24px 0 0 0' }}>
        オバケの悪さ
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '6px 0 0 0' }}>
        {con}
      </Font>
    </div>
  )
}
