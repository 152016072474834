import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { DropZoneCsv } from 'pages/onboarding/components'
import { teamConstants } from 'pages/teams/assets/constants'
import {
  ButtonContainer,
  Card,
  CardFooter,
  CSVTemplateDownloadBox,
  DefaultButton,
  HorizontalLine,
  NavigationTextLink,
  ReservationFramework,
  ResultFramework,
  ResultWithIcon,
} from 'pages/teams/components'
import { useCsvUpload } from 'pages/teams/hooks/useCsvUpload'
import { formatDateTime } from 'pages/teams/utils/dateTimeHandler'

import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

interface IInput {
  sendEmailDateAt?: string
  sendEmailTimeAt?: string
}

type DateBox = {
  date: string
  dayOfWeek: string
  time: string
}

const defaultValues = {
  sendEmailDateAt: '',
  sendEmailTimeAt: '',
}

const TeamsManageCsvPageBloc = {
  useAdapter: () => {
    const { route } = useHistory()
    const [csvData, setCsvData] = useState<string[][]>([])
    const [uploadFile, setUploadFile] = useState<File>()
    const [dateBox, setDateBox] = useState<DateBox>()
    const isSmDown = useCustomMediaQuery('down', 'sm')
    const isXsDown = useCustomMediaQuery('down', 'xs')
    const { onUploadTeams, processing, uploadedNum, setUploadedNum, uploadErrors, setUploadErrors, hasTeamMembers } =
      useCsvUpload(csvData, setCsvData)
    const disabled = !csvData.length
    const methods = useForm<IInput>()
    const { handleSubmit } = methods

    const handleBack = () => {
      setUploadFile(undefined)
      setUploadedNum(undefined)
      setUploadErrors(null)
    }

    const handleToTeamList = (teamId: string | undefined) => {
      if (!teamId) return
      route.push(`${Pages.TeamsList}?hidden=${true}`, { teamId })
    }

    const onSubmit = (input: IInput) => {
      let sendEmailAt = ''

      if (hasTeamMembers) {
        if (input.sendEmailDateAt && !input.sendEmailTimeAt) {
          alert('招待メールの送信時間を入力してください。')
          return
        }
        if (!input.sendEmailDateAt && input.sendEmailTimeAt) {
          alert('招待メールの送信日を入力してください。')
          return
        }

        if (input.sendEmailDateAt && input.sendEmailTimeAt) {
          const { formattedDate, formattedDateJP, formattedTime, dayOfWeek } = formatDateTime(
            input.sendEmailDateAt,
            input.sendEmailTimeAt
          )
          sendEmailAt = `${formattedDate} ${formattedTime}`

          if (new Date(sendEmailAt) < new Date()) {
            alert('予約の時間は既に過ぎております。')
            return
          }

          setDateBox({
            date: formattedDateJP,
            dayOfWeek: `${dayOfWeek}曜日`,
            time: formattedTime,
          })
        }
      }

      onUploadTeams(sendEmailAt)
    }

    return {
      disabled,
      setCsvData,
      processing,
      uploadedNum,
      uploadErrors,
      uploadFile,
      setUploadFile,
      open,
      isSmDown,
      isXsDown,
      handleBack,
      handleToTeamList,
      hasTeamMembers,
      methods,
      handleSubmit,
      onSubmit,
      dateBox,
    }
  },
}

export const TeamsNewByCsvPage: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId?: string }>()

  const {
    disabled,
    setCsvData,
    processing,
    uploadedNum,
    uploadErrors,
    uploadFile,
    setUploadFile,
    isSmDown,
    isXsDown,
    handleBack,
    handleToTeamList,
    hasTeamMembers,
    methods,
    handleSubmit,
    onSubmit,
    dateBox,
  } = TeamsManageCsvPageBloc.useAdapter()
  const isResult = (uploadedNum || uploadErrors) && !processing

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          headerTitle="チーム一括追加"
          headerRightNode={!isSmDown && !isResult && <CSVTemplateDownloadBox type="team" />}
          style={{
            padding: isSmDown ? '16px 16px 24px' : 24,
            justifyContent: isSmDown ? 'center' : 'space-between',
            alignItems: 'flex-start',
          }}
          headerStyles={{ justifyContent: 'space-between' }}
          titleStyles={{ height: isSmDown ? 22 : 40, fontSize: 14, display: 'flex', alignItems: 'center' }}
          headerMb={0}
          titleContainerPb={0}
        >
          {isResult ? (
            <ResultFramework>
              {uploadErrors ? (
                <ResultWithIcon
                  topText="アップロードエラー"
                  iconType="warning"
                  message={`CSVファイルの内容を再度確認してください。\n\n${uploadErrors.join('\n')}${
                    uploadedNum
                      ? `\n残りのデータは正常に登録されました（${uploadedNum?.team && `チーム ${uploadedNum.team}件`}${
                          uploadedNum?.member ? `、メンバー ${uploadedNum.member}件` : ''
                        }）`
                      : ''
                  }`}
                  dateBox={dateBox}
                  button={{
                    label: '戻る',
                    onClick: handleBack,
                  }}
                />
              ) : (
                <ResultWithIcon
                  topText="アップロード完了"
                  iconType="success"
                  message={`データが正常に登録されました（${uploadedNum?.team && `チーム ${uploadedNum.team}件`}${
                    uploadedNum?.member ? `、メンバー ${uploadedNum.member}件` : ''
                  }）`}
                  dateBox={dateBox}
                  button={{
                    label: 'チームリストに戻る',
                    onClick: () => handleToTeamList(teamId),
                  }}
                />
              )}
            </ResultFramework>
          ) : (
            <>
              {isSmDown && <CSVTemplateDownloadBox type="team" />}
              <HorizontalLine style={{ marginTop: isSmDown ? 16 : 24, marginBottom: 24 }} />
              <ReservationFramework
                defaultValues={defaultValues}
                isShowForm={hasTeamMembers}
                isReserved={false}
                isHideRightNode={!uploadFile}
              >
                <div className={`${classes.dropZoneItem} ${uploadFile ? 'uploaded' : ''}`}>
                  <DropZoneCsv
                    uploadFile={uploadFile}
                    setUploadFile={setUploadFile}
                    setCsvData={setCsvData}
                    rootStyle={{ padding: 0 }}
                  />
                </div>
              </ReservationFramework>
              {(!isXsDown || !uploadFile) && <HorizontalLine style={{ marginTop: 24, marginBottom: 24 }} />}

              <CardFooter>
                <ButtonContainer
                  buttons={[
                    <DefaultButton
                      key="buttons-member-csvAddPage-1"
                      title="アップロード"
                      color={disabled ? 'team_disabled' : 'darkPrimary'}
                      type="submit"
                      disabled={disabled || processing}
                      loading={processing}
                      style={{
                        ...teamConstants.cardFooterButtonStlyes,
                        border: disabled ? teamConstants.disabledBorder : undefined,
                        opacity: disabled ? 1 : undefined,
                      }}
                    />,
                    <DefaultButton
                      key="buttons-member-csvAddPage-1"
                      title="キャンセル"
                      color="team_white"
                      onClick={() => handleToTeamList(teamId)}
                      style={teamConstants.cardFooterButtonStlyes}
                    />,
                  ]}
                  marginTopPcLayout={0}
                />
              </CardFooter>
            </>
          )}
        </Card>
      </form>

      {!isResult && (
        <NavigationTextLink
          path={replacePathParams(Pages.TeamsNew, { teamId })}
          text="チーム追加はこちら"
          containerStyle={{ marginLeft: isSmDown ? 24 : undefined }}
        />
      )}
    </FormProvider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    dropZoneItem: {
      width: '100%',

      '&.uploaded': {
        [theme.breakpoints.down('xs')]: {
          marginBottom: 24,
        },
      },
    },
  }),
  { name: 'TeamsNewByCsvPage' }
)
