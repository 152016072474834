import * as React from 'react'
import { Link } from 'react-router-dom'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { AuthorName } from 'pages/hint/_shared/components/authorName/Index'
import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { Date } from 'pages/hint/_shared/components/date/Index'
import { Details } from 'pages/hint/_shared/components/details/Index'
import { ArticlesHeading } from 'pages/hint/_shared/components/heading/articlesHeading/Index'
import { Lead } from 'pages/hint/_shared/components/lead/Index'
import {
  ListArticleWrapper,
  ListInWrapper,
  ContentWrapper,
  DateWrapper,
} from 'pages/hint/_shared/layouts/articles/Index'
// import { Comment } from 'pages/hint/_shared/components/comment/Index'
import { IHintListResult } from 'pages/hint/_shared/stores/apis/getHintList'
import { IHintBase } from 'pages/hint/_shared/stores/models/hint'
import { formatDate } from 'pages/hint/_shared/utils/formatDate'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },

  list: {
    display: 'flex',
    textDecoration: 'none',
    borderBottom: 'solid 1px #E8E8E8',
    boxSizing: 'border-box',
  },

  link: {
    width: '100%',
    textDecoration: 'none',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  datas: IHintListResult
}

// -----------------------------
// Component
// -----------------------------
export const Articles = (props: IProps) => {
  const classes = useStyles()

  return (
    <>
      {props.datas && (
        <ul className={classes.root}>
          {props.datas.items.map((data: IHintBase) => {
            return (
              <li className={classes.list} key={data.id}>
                <Link to={`/hint/tips/${data.id}`} className={classes.link}>
                  <ListArticleWrapper>
                    <ListInWrapper>
                      <Box display="block" mr="24px" style={{ width: 'calc(100% - 100px)' }}>
                        <ArticlesHeading text={data.title} />
                        <ContentWrapper>
                          <Lead text={data.contentPreview} ellipsis />
                        </ContentWrapper>
                      </Box>
                      <Box>{data?.user?.imageUrl && <Avatar src={data.user.imageUrl} alt="avator" size={76} />}</Box>
                    </ListInWrapper>
                    <Box mt={{ xs: '16px', lg: '24px' }}>
                      <Details>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex">
                            <DateWrapper>
                              <Date text={formatDate(data.createdAt)} />
                            </DateWrapper>
                            {data?.user?.firstName && data?.user?.lastName && (
                              <AuthorName text={`${data.user.lastName} ${data.user.firstName}`} />
                            )}
                            {(!data?.user?.firstName || !data?.user?.lastName) && data?.user?.nickname && (
                              <AuthorName text={data.user.nickname} />
                            )}
                          </Box>
                          {/* <Comment count={data.commentCount} /> */}
                        </Box>
                      </Details>
                    </Box>
                  </ListArticleWrapper>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}
