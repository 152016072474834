import { OnboardingTeamMember } from 'utils/generated'

import * as constants from 'assets/constants'

export const convertUrl = (text: string) => {
  const urlRegExp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  const urlReplacer = (url: string) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
  }

  return text.replace(urlRegExp, urlReplacer)
}

export const convertMentions = (text:string,teamMembers:OnboardingTeamMember[]) => {
const nickname = teamMembers.map((item) => item.nickname ?? '')
const escapeRegExpText = (str: string) => str.replace('+', '\\+')
  const isMentionsRegExp = () => {
    const mentionsString = nickname.map((items) => escapeRegExpText(items)).join('|')
    return new RegExp(`@(${mentionsString})`, 'g')
  }

  const mentionsReplacer = (nickname: string) => {
      return `<span target="_blank" style="color:${constants.COLOR_ONBOARDING_MAIN}">${nickname}</span>`
    }
    return text.replace(isMentionsRegExp(), mentionsReplacer)
  }

  export const converts = (text:string,teamMembers:OnboardingTeamMember[]) => {
    return convertMentions(convertUrl(text),teamMembers)
  }
  