import React, { useState } from 'react'

import {
  queryGetOnboardingActionsFeedbacks,
  queryGetOnboardingActionList,
  queryGetOnboardingPostCommentListOfOneUser,
  queryGetOnboardingPostLikeListOfOneUser,
  queryGetOnboardingPostCommentLikeListOfOneUser,
  queryGetOnboardingPostSecondCommentLikeListOfOneUser,
  queryGetOnboardingActionLikeListOfOneUser,
  queryGetOnboardingPostList,
} from 'pages/onboarding/graphql'
import {
  OnboardingPost,
  OnboardingTeamMember,
  OnboardingTeamMemberRole,
  OnboardingPostType,
  OnboardingTeamMemberStatus,
} from 'utils/generated'

export type MemberOverview = {
  id: string
  imageUrl: string | undefined
  name: string
  allAction: number
  doneAction: number
  allDaily: number
  badCondition: number
  post: number
  good: number
  commented: number
  lastVisit: string
}
export type SupporterOverview = {
  id: string
  imageUrl: string | undefined
  name: string
  good: number
  commentIng: number
  commented: number
  lastVisit: string
}

export const useOverView = (teamId?: string, teamMembers?: OnboardingTeamMember[]) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const [memberInfosContainer, setMemberInfosContainer] = useState<MemberOverview[]>([])
  const [supportersInfosContainer, setSupportersInfosContainer] = useState<SupporterOverview[]>([])

  const refresh = React.useCallback(async () => {
    if (!teamId || !teamMembers || !teamMembers.length) {
      return
    }

    setLoading(true)
    setError(undefined)
    //helpers--->
    // all data for used all members
    const teamPosts = await queryGetOnboardingPostList({
      teamId,
      first: 1000,
    })
    // all data for used role members
    const allActions = await queryGetOnboardingActionList({ teamId, first: 1000 })

    //functions
    const likeCount = async (teamId: string, userId: string) => {
      let count = 0
      const postLikes = await queryGetOnboardingPostLikeListOfOneUser({ teamId, userId, first: 1000 })
      const postCommentLikes = await queryGetOnboardingPostCommentLikeListOfOneUser({
        teamId,
        userId,
        first: 1000,
      })
      const postSecondCommentLikes = await queryGetOnboardingPostSecondCommentLikeListOfOneUser({
        teamId,
        userId,
        first: 1000,
      })
      const actionLikes = await queryGetOnboardingActionLikeListOfOneUser({
        teamId,
        userId,
        first: 1000,
      })
      if (actionLikes?.items) {
        count =
          postLikes.items.length +
          postCommentLikes.items.length +
          postSecondCommentLikes.items.length +
          actionLikes?.items?.length
      }
      return count
    }

    //calc dailyCheck
    type Dailies = { [k: string]: { all: number; badCondition: number } }
    const calcDailies = (): Dailies | undefined => {
      const teamDailies = teamPosts.getOnboardingPostList?.items?.filter(
        (item) => item.type === OnboardingPostType.Daily
      )
      const returnValue: Dailies | undefined = teamDailies?.reduce((sum: Dailies, current: OnboardingPost): Dailies => {
        const checkBadCondition = (post: OnboardingPost): boolean =>
          post.daily === 'BAD_WORK_PROGRESS' ||
          post.daily === 'BAD_PRIVATE_TIME' ||
          post.daily === 'BAD_FEELING_SOMEWHAT'
        if (current.userId in sum) {
          return checkBadCondition(current)
            ? {
                ...sum,
                [current.userId]: {
                  all: 1 + sum[current.userId].all,
                  badCondition: 1 + sum[current.userId].badCondition,
                },
              }
            : {
                ...sum,
                [current.userId]: { all: 1 + sum[current.userId].all, badCondition: sum[current.userId].badCondition },
              }
        } else {
          return checkBadCondition(current)
            ? { ...sum, [current.userId]: { all: 1, badCondition: 1 } }
            : { ...sum, [current.userId]: { all: 1, badCondition: 0 } }
        }
      }, {})
      return returnValue
    }
    const dailies = calcDailies()

    //calc posts
    type PostsPerMembers = { [k: string]: { count: number; commented: number } }
    const calcPostsPerMembers = (): PostsPerMembers | undefined => {
      const returnValue: PostsPerMembers | undefined = teamPosts.getOnboardingPostList?.items?.reduce(
        (sum: PostsPerMembers, current: OnboardingPost): PostsPerMembers => {
          if (current.userId in sum) {
            return {
              ...sum,
              [current.userId]: {
                count: 1 + sum[current.userId].count,
                commented: sum[current.userId].commented + (current?.commentCount ?? 0),
              },
            }
          } else {
            return { ...sum, [current.userId]: { count: 1, commented: current?.commentCount ?? 0 } }
          }
        },
        {}
      )
      return returnValue
    }
    const postsPerMembers = calcPostsPerMembers()
    //<---helpers

    try {
      for (const teamMember of teamMembers) {
        if (
          teamMember.role === OnboardingTeamMemberRole.Admin ||
          teamMember.status !== OnboardingTeamMemberStatus.Accepted
        ) {
          continue
        } else if (teamMember.role === OnboardingTeamMemberRole.Supporter) {
          const commentIng = await queryGetOnboardingPostCommentListOfOneUser({
            teamId,
            userId: teamMember.userId,
            first: 1000,
          })
          const likes = await likeCount(teamId, teamMember.userId)
          const supporterItems: SupporterOverview = {
            id: teamMember.id,
            imageUrl: teamMember.imageUrl ?? undefined,
            name:
              teamMember.nickname && teamMember.nickname !== '名無し' ? teamMember.nickname : teamMember.email ?? '',
            commentIng: commentIng.items.length ?? 0,
            commented: postsPerMembers?.[teamMember.userId]?.commented ?? 0,
            good: likes,
            lastVisit: teamMember.lastVisitAction ?? '',
          }
          setSupportersInfosContainer((prev) => [...prev, supporterItems])
        } else if (teamMember.role === OnboardingTeamMemberRole.Member) {
          const actionsFeedback = await queryGetOnboardingActionsFeedbacks({ teamId, userId: teamMember.userId })
          const likes = await likeCount(teamId, teamMember.userId)
          const memberItems: MemberOverview = {
            id: teamMember.id,
            imageUrl: teamMember.imageUrl ?? undefined,
            name:
              teamMember.nickname && teamMember.nickname !== '名無し' ? teamMember.nickname : teamMember.email ?? '',
            allAction: allActions.getOnboardingActionList?.items?.length ?? 0,
            doneAction: actionsFeedback.getOnboardingActionsFeedbacks?.length ?? 0,
            allDaily: dailies?.[teamMember.userId]?.all ?? 0,
            badCondition: dailies?.[teamMember.userId]?.badCondition ?? 0,
            post: postsPerMembers?.[teamMember.userId]?.count ?? 0,
            commented: postsPerMembers?.[teamMember.userId]?.commented ?? 0,
            good: likes,
            lastVisit: teamMember.lastVisitAction ?? '',
          }
          setMemberInfosContainer((prev) => [...prev, memberItems])
        }
      }
    } catch (e) {
      console.log('e', e)
      setError(e as Error)
    }
    setLoading(false)
  }, [teamId, teamMembers])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { loading, error, memberInfosContainer, supportersInfosContainer } as const
}
