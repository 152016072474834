import { actionCreatorFactory } from 'typescript-fsa'

import { IConfirmSignUp } from '../../services/amplify/confirmSignUp'
import { IResetPassword } from '../../services/amplify/resetPassword'
import { ISignin } from '../../services/amplify/signin'
import { ISignup, SignUpOption } from '../../services/amplify/signup'
import { UpdateAccountInput } from '../../services/api/updateAccount'

import { IUser } from './model'
import {
  FETCH_USER,
  SIGNUP_USER,
  UPDATE_ACCOUNT,
  REFRESH_STATE_USER,
  SIGNIN_USER,
  REFRESH_ERRORS_USER,
  SET_EMAIL_TO_PARAM_USER,
  RESET_PASSWORD_USER,
  RESTORE_USER,
  CONFIRM_USER,
  RESEND_CODE_USER,
} from './types'


const actionCreator = actionCreatorFactory()
export const UserActions = {
  fetch: actionCreator.async<{}, IUser>(FETCH_USER),
  signup: actionCreator.async<{ user: ISignup; option: SignUpOption }, {}>(SIGNUP_USER),
  confirmSignUp: actionCreator.async<{ user: IConfirmSignUp }, {}>(CONFIRM_USER),
  resendSignUp: actionCreator.async<{ email: string }, {}>(RESEND_CODE_USER),
  refresState: actionCreator<{}>(REFRESH_STATE_USER),
  signin: actionCreator.async<{ user: ISignin }, {}>(SIGNIN_USER),
  signout: actionCreator.async<{}, {}>('SIGNOUT_USER'),
  restore: actionCreator.async<{ email: string }, {}>(RESTORE_USER),
  resetPassword: actionCreator.async<{ user: IResetPassword }, {}>(RESET_PASSWORD_USER),
  refreshErrors: actionCreator<{}>(REFRESH_ERRORS_USER),
  setEmailToParam: actionCreator<{ email: string }>(SET_EMAIL_TO_PARAM_USER),
  updateAccount: actionCreator.async<{ params: UpdateAccountInput }, {}>(UPDATE_ACCOUNT),
}
