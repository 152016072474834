import * as React from 'react'

type Props = {}

export const UncheckedIcon: React.FC<Props> = () => {
  return (
    <>
      <svg
        id="グループ_1180"
        data-name="グループ 1180"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <circle id="楕円形_88" data-name="楕円形 88" cx="12" cy="12" r="12" fill="#00838c" />
        <g id="グループ_1178" data-name="グループ 1178" transform="translate(4 4)">
          <line
            id="線_69"
            data-name="線 69"
            x2="4"
            y2="4"
            transform="translate(2 8)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.8"
          />
          <line
            id="線_70"
            data-name="線 70"
            x1="8"
            y2="8"
            transform="translate(6 4)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.8"
          />
        </g>
      </svg>
    </>
  )
}
