import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

export const Hero: React.FC = () => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'xs')

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        <img
          className={classes.hero}
          src={`${process.env.PUBLIC_URL}/img/hataraku/hero${isXsDown ? '-sp' : ''}.png`}
          alt="はたらく価値観診断"
        />
      </h1>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      background: '#fff',
    },
    title: {
      margin: 0,
    },
    hero: {
      width: '100%',
      verticalAlign: 'top',
    },
  }),
  { name: 'Hero' }
)
