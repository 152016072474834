import React from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import {
  BuildingHooksContext,
  useBuildingHooksContext,
  ModalBuildingContext,
  useModalBuildingContext,
} from 'pages/teams/contexts'
import { HooksContext, useHooksContext } from 'pages/teams/contexts/HooksContext'
import { BuildingModal } from 'pages/teams/pages/_tools/buildings/components'

import { LoadingCircular } from '../LoadingCircular'

import { constants } from 'assets'

interface Props {
  children: React.ReactElement
  loading?: boolean
}

interface RouteParams {
  teamId?: string
}

export const TeamBuildingContainer: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { children, loading } = props

  const { teamId } = useParams<RouteParams>()

  const {
    currentUser,
    refreshCurrentUser,
    currentTeam,
    refreshTeam,
    loadingTeam,
    currentTeamMember,
    currentTeamMemberRole,
    isAdmin,
    isLeader,
    isMember,
    refreshCurrentTeamMember,
    currentTeamAdmin,
    refreshTeamAdmin,
    teamPlans,
    loadingTeamMember,
    loadedTeamBuilding,
    teamBuilding,
    loadingTeamBuilding,
    refreshTeamBuilding,
  } = useHooksContext(teamId)

  const {
    open: openBuildingModal,
    setOpen: setOpenBuildingModal,
    content: contentBuildingModal,
    onModal: onModalBuildingModal,
    onClose: onCloseBuildingModal,
  } = useModalBuildingContext()

  const {
    teamBuildingActions,
    refreshTeamBuildingActions,
    teamBuildingMembers,
    refreshTeamBuildingMembers,
    currentTeamBuildingMember,
    refreshTeamBuildingMember,
    defaultActions,
    createdActions,
  } = useBuildingHooksContext(teamId)

  return (
    <ModalBuildingContext.Provider
      value={{
        onModal: onModalBuildingModal,
        onClose: onCloseBuildingModal,
      }}
    >
      <HooksContext.Provider
        value={{
          currentUser,
          refreshCurrentUser,
          currentTeam,
          refreshTeam,
          loadingTeam,
          currentTeamMember,
          currentTeamMemberRole,
          isAdmin,
          isLeader,
          isMember,
          refreshCurrentTeamMember,
          currentTeamAdmin,
          refreshTeamAdmin,
          teamPlans,
          teamBuilding,
          loadingTeamBuilding,
          loadedTeamBuilding,
          refreshTeamBuilding,
          loading: loadingTeamMember,
        }}
      >
        <BuildingHooksContext.Provider
          value={{
            teamBuildingActions,
            refreshTeamBuildingActions,
            teamBuildingMembers,
            refreshTeamBuildingMembers,
            currentTeamBuildingMember,
            refreshTeamBuildingMember,
            defaultActions,
            createdActions,
          }}
        >
          <div className={classes.root}>
            <div className={classes.spacing}></div>
            {!loading && !loadingTeamMember ? children : <LoadingCircular loading={loading || loadingTeamMember} />}
          </div>
        </BuildingHooksContext.Provider>
      </HooksContext.Provider>
      <BuildingModal open={openBuildingModal} setOpen={setOpenBuildingModal} content={contentBuildingModal} />
    </ModalBuildingContext.Provider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontFamily: constants.TEAM_BUILDING_FONT_FAMILY,
      '& input, textarea': {
        fontFamily: constants.TEAM_BUILDING_FONT_FAMILY,
      },
      position: 'relative',
    },
    spacing: { marginTop: `-${constants.HEADER_HEIGHT}px` },
  }),
  { name: 'TeamBuildingContainer' }
)
