import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core'

interface Props {
  errors?: Error[]
}

export const HookErrors: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <>
      {props.errors ? (
        <>
          {props.errors.map((error, index) => (
            <p key={`hook-error-${index}`} className={classes.label}>
              {error.message}
            </p>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: 'red',
  },
}))
