import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import gql from 'graphql-tag'

import {
  Maybe,
  MutationCreateOnboardingActionArgs,
  MutationCreateOnboardingActionCommentArgs,
  MutationCreateOnboardingActionFeedbackArgs,
  MutationCreateOnboardingActionLikeToggleArgs,
  MutationCreateOnboardingPostActionArgs,
  MutationCreateOnboardingPostCommentArgs,
  MutationCreateOnboardingPostCommentLikeToggleArgs,
  MutationCreateOnboardingPostDailyArgs,
  MutationCreateOnboardingPostLikeToggleArgs,
  MutationDeleteOnboardingActionCommentArgs,
  MutationDeleteOnboardingActionFeedbackArgs,
  MutationDeleteOnboardingPostCommentArgs,
  MutationUpdateOnboardingActionCommentArgs,
  MutationUpdateOnboardingPostActionArgs,
  MutationUpdateOnboardingPostCommentArgs,
  MutationCreateOnboardingPostSecondCommentArgs,
  MutationCreateOnboardingPostSecondCommentLikeToggleArgs,
  MutationUpdateOnboardingPostSecondCommentArgs,
  MutationDeleteOnboardingPostSecondCommentArgs,
  OnboardingActionFeedback,
  OnboardingActionLike,
  OnboardingActionLikeConnection,
  OnboardingPost,
  OnboardingPostLikeConnection,
  OnboardingPostCommentLikeConnection,
  OnboardingPostSecondCommentLikeConnection,
  OnboardingTeam,
  QueryGetOnboardingActionCommentListArgs,
  QueryGetOnboardingActionLikeListArgs,
  QueryGetOnboardingActionLikeListOfOneUserArgs,
  QueryGetOnboardingActionListArgs,
  QueryGetOnboardingPostArgs,
  QueryGetOnboardingPostLikeListArgs,
  QueryGetOnboardingPostCommentListOfOneUserArgs,
  QueryGetOnboardingPostSecondCommentListOfOneUserArgs,
  QueryGetOnboardingPostLikeListOfOneUserArgs,
  QueryGetOnboardingPostSecondCommentLikeListArgs,
  QueryGetOnboardingPostSecondCommentLikeListOfOneUserArgs,
  QueryGetOnboardingPostCommentLikeListArgs,
  QueryGetOnboardingPostCommentLikeListOfOneUserArgs,
  QueryGetOnboardingTeamArgs,
  QueryGetOnboardingTeamMemberArgs,
  MutationDeleteOnboardingActionArgs,
  OnboardingAction,
  MutationUpdateOnboardingActionArgs,
  Notifycation,
  NotifycationConnection,
  QueryGetOnboardingNotifycationArgs,
  QueryGetOnboardingNotifycationListArgs,
  MutationUpdateOnboardingNotifycationArgs,
  MutationCreateOnboardingPostPostArgs,
  MutationUpdateOnboardingPostPostArgs,
  MutationDeleteOnboardingPostPostArgs,
  QueryGetOnboardingPostFileArgs,
  OnboardingPostFile,
  QueryListOnboardingPostFilesArgs,
  QueryGetOnboardingTeamMemberTargetFileArgs,
} from 'utils/generated'

import {
  QueryGetUserRequest,
  QueryGetUserResponse,
  QueryGetOnboardingPostCommentListRequest,
  QueryGetOnboardingPostCommentListResponse,
  QueryGetOnboardingPostSecondCommentListRequest,
  QueryGetOnboardingPostSecondCommentListResponse,
  MutationCreateOnboardingPostLikeToggleResponse,
  MutationCreateOnboardingPostCommentResponse,
  MutationCreateOnboardingPostCommentLikeToggleResponse,
  MutationCreateOnboardingPostSecondCommentResponse,
  MutationCreateOnboardingPostSecondCommentLikeToggleResponse,
  MutationOnboardingPostDailyCreateInputResponse,
  QueryGetOnboardingActionsRequest,
  QueryGetOnboardingActionsResponse,
  QueryGetOnboardingActionRequest,
  QueryGetOnboardingActionResponse,
  QueryGetOnboardingActionCommentListResponse,
  QueryGetOnboardingActionsFeedbacksRequest,
  QueryGetOnboardingActionsFeedbacksResponse,
  MutationCreateOnboardingActionCommentResponse,
  MutationCreateOnboardingActionFeedbackResponse,
  QueryGetOnboardingOwnTeamMemberListRequest,
  QueryGetOnboardingOwnTeamMemberListResponse,
  QueryGetOnboardingTeamMemberListRequest,
  QueryGetOnboardingTeamMemberListResponse,
  QueryGetOnboardingTeamMemberResponse,
  QueryGetOnboardingPostListRequest,
  QueryGetOnboardingPostListOfOneUserRequest,
  QueryGetOnboardingPostListResponse,
  QueryGetOnboardingPostListOfOneUserResponse,
  QueryGetOnboardingActionsRadarRequest,
  QueryGetOnboardingActionsRadarResponse,
  MutationCreateOnboardingPostActionResponse,
  QueryGetAccountResponse,
  MutationUpdateAccountRequest,
  MutationUpdateAccountResponse,
  QueryGetOnboardingActionListResponse,
  MutationCreateOnboardingActionResponse,
  MutationUpdateOnboardingActionResponse,
} from './utils/domain'

export const QueryGetUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    nickname
    firstName
    lastName
    imageUrl
    shortDescription
    description
  }
}`

export const QueryGetOnboardingPost = gql`
  query GetOnboardingPost($id: ID!) {
    getOnboardingPost(id: $id) {
      id
      teamId
      userId
      type
      actionId
      comment
      label
      daily
      createdAt
      likeCount
      commentCount
      fileNames
      rankingActions {
        teamMemberId
        count
      }
      rankingPosts {
        teamMemberId
        count
      }
      rankingLikes {
        teamMemberId
        count
      }

      user {
        id
      }
      action {
        id
        teamId
        category
        mission
        why
        what
        how
        period
        importance
        point
        # commentCount
        # likeCount
        # isLiked
      }
      teamMember {
        nickname
        imageUrl
        role
      }
      isLiked
    }
  }
`

export const QueryGetOnboardingPostCommentList = gql`
  query GetOnboardingPostCommentList($postId: ID!, $first: Int, $after: String) {
    getOnboardingPostCommentList(postId: $postId, first: $first, after: $after) {
      items {
        id
        postId
        userId
        text
        createdAt
        commentCount
        likeCount
        user {
          id
        }
        teamMember {
          nickname
          imageUrl
        }
        isLiked
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostSecondCommentList = gql`
  query GetOnboardingPostSecondCommentList($postCommentId: ID!, $first: Int, $after: String) {
    getOnboardingPostSecondCommentList(postCommentId: $postCommentId, first: $first, after: $after) {
      items {
        id
        postCommentId
        userId
        text
        createdAt
        likeCount
        user {
          id
        }
        teamMember {
          nickname
          imageUrl
        }
        isLiked
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingActions = gql`
  query GetOnboardingActions($teamId: ID!, $userId: ID!) {
    getOnboardingActions(teamId: $teamId, userId: $userId) {
      id
      teamId
      category
      mission
      why
      what
      how
      period
      deadline
      importance
      point
      commentCount(actionOwnerId: $userId)
      likeCount(actionOwnerId: $userId)
      isLiked(actionOwnerId: $userId)
    }
  }
`

export const QueryGetOnboardingAction = `query GetOnboardingAction($id: ID!, $userId: ID!) {
    getOnboardingAction(id: $id) {
      id
      teamId
      category
      mission
      why
      what
      how
      period
      deadline
      importance
      point
      commentCount(actionOwnerId: $userId)
      likeCount(actionOwnerId: $userId)
      isLiked(actionOwnerId: $userId)
    }
  }`

export const QueryGetOnboardingActionCommentList = gql`
  query getOnboardingActionCommentList($actionId: ID!, $actionOwnerId: ID!, $first: Int, $after: String) {
    getOnboardingActionCommentList(actionId: $actionId, actionOwnerId: $actionOwnerId, first: $first, after: $after) {
      items {
        id
        actionId
        actionOwnerId
        userId
        text
        createdAt

        user {
          id
        }
        teamMember {
          nickname
          imageUrl
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostLikeList = gql`
  query getOnboardingPostLikeList($postId: ID!, $first: Int, $after: String) {
    getOnboardingPostLikeList(postId: $postId, first: $first, after: $after) {
      items {
        id
        postId
        userId
        icon
        createdAt
        user {
          nickname
          imageUrl
        }
        teamMember {
          nickname
          imageUrl
          position
          role
          userId
        }
      }
      nextToken
    }
  }
`
export const QueryGetOnboardingPostSecondCommentListOfOneUser = gql`
  query getOnboardingPostSecondCommentListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostSecondCommentListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`

// コメントを「した」数を持ってくるクエリ。今後使用する可能性あり。
export const QueryGetOnboardingPostCommentListOfOneUser = gql`
  query getOnboardingPostCommentListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostCommentListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`
export const QueryGetOnboardingPostLikeListOfOneUser = gql`
  query getOnboardingPostLikeListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostLikeListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostCommentLikeList = gql`
  query getOnboardingPostCommentLikeList($postId: ID!, $first: Int, $after: String) {
    getOnboardingPostCommentLikeList(postId: $postId, first: $first, after: $after) {
      items {
        id
        postId
        userId
        createdAt
        user {
          nickname
          imageUrl
        }
        teamMember {
          nickname
          imageUrl
          position
          role
          userId
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostCommentLikeListOfOneUser = gql`
  query getOnboardingPostCommentLikeListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostCommentLikeListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostSecondCommentLikeList = gql`
  query getOnboardingPostSecondCommentLikeList($postSecondCommentId: ID!, $first: Int, $after: String) {
    getOnboardingPostSecondCommentLikeList(postSecondCommentId: $postSecondCommentId, first: $first, after: $after) {
      items {
        id
        postSecondCommentId
        userId
        createdAt
        user {
          nickname
          imageUrl
        }
        teamMember {
          nickname
          imageUrl
          position
          role
          userId
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostSecondCommentLikeListOfOneUser = gql`
  query getOnboardingPostSecondCommentLikeListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostSecondCommentLikeListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingActionLikeList = gql`
  query getOnboardingActionLikeList($actionIdActionOwnerIdHash: ID!, $first: Int, $after: String) {
    getOnboardingActionLikeList(actionIdActionOwnerIdHash: $actionIdActionOwnerIdHash, first: $first, after: $after) {
      items {
        id
        actionId
        actionOwnerId
        actionIdActionOwnerIdHash
        createdAt
        teamMemberId
        user {
          nickname
          imageUrl
        }
        teamMember {
          nickname
          imageUrl
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingActionLikeListOfOneUser = gql`
  query getOnboardingActionLikeListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingActionLikeListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        createdAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingActionsFeedbacks = gql`
  query GetOnboardingActionsFeedbacks($teamId: ID!, $userId: ID!) {
    getOnboardingActionsFeedbacks(teamId: $teamId, userId: $userId) {
      id
      teamId
      userId
      actionId
      category
      value
      importance
      settedAt
      updatedAt

      action {
        id
        teamId
        category
        mission
        why
        what
        how
        period
        importance
        point
        commentCount(actionOwnerId: $userId)
        likeCount(actionOwnerId: $userId)
        isLiked(actionOwnerId: $userId)
      }
    }
  }
`

export const QueryGetOnboardingOwnTeamMemberList = gql`
  query GetOnboardingOwnTeamMemberList($first: Int, $after: String) {
    getOnboardingOwnTeamMemberList(first: $first, after: $after) {
      items {
        id
        teamId
        createdAt
        team {
          id
          title
          managerId
          memberCount
          usageStatus
        }
        role
        status
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingTeamMemberList = gql`
  query GetOnboardingTeamMemberList($teamId: ID!, $first: Int, $after: String) {
    getOnboardingTeamMemberList(teamId: $teamId, first: $first, after: $after) {
      items {
        id
        userId
        user {
          id
          nickname
          firstName
          lastName
          imageUrl
          shortDescription
          description
        }
        nickname
        description
        imageUrl
        role
        status
        reserveWillSendEmailAt
        position
        email
        lastVisitAction
        completeActionCount
        completeActionMostCategory
        startedAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingTeam = gql`
  query GetOnboardingTeam($id: ID!) {
    getOnboardingTeam(id: $id) {
      id
      title
      memberCount
      createdAt
      usageStatus
    }
  }
`

export const QueryGetOnboardingTeamMember = gql`
  query GetOnboardingTeamMember($teamId: ID!, $userId: ID) {
    getOnboardingTeamMember(teamId: $teamId, userId: $userId) {
      id
      userId
      user {
        id
        nickname
        firstName
        lastName
        imageUrl
        shortDescription
        description
        contractAt
        onboardingContractAt
      }
      team {
        usageStatus
      }
      role
      status
      reserveWillSendEmailAt
      readTutorialAt
      dailyConfirmedAt
      teamId
      nickname
      position
      description
      target
      imageUrl
      targetFileNames
      email
      emailNotify
      emailNotifyHour
      emailNotifyMinute
      lastVisitAction
      startedAt
    }
  }
`

export const QueryGetOnboardingPostList = gql`
  query GetOnboardingPostList($teamId: ID!, $first: Int, $after: String) {
    getOnboardingPostList(teamId: $teamId, first: $first, after: $after) {
      items {
        id
        teamId
        userId
        type
        actionId
        comment
        label
        daily
        fileNames
        createdAt
        likeCount
        commentCount
        rankingActions {
          teamMemberId
          count
        }
        rankingPosts {
          teamMemberId
          count
        }
        rankingLikes {
          teamMemberId
          count
        }
        user {
          id
        }
        action {
          id
          teamId
          category
          mission
          why
          what
          how
          period
          importance
          point
        }
        teamMember {
          nickname
          imageUrl
          role
        }
        isLiked
        isCommented
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingPostListOfOneUser = gql`
  query GetOnboardingPostListOfOneUser($teamId: ID!, $userId: ID!, $first: Int, $after: String) {
    getOnboardingPostListOfOneUser(teamId: $teamId, userId: $userId, first: $first, after: $after) {
      items {
        id
        teamId
        createdAt
        type
        daily
        actionId
        comment
        label
        likeCount
        commentCount
        fileNames
        action {
          id
          teamId
          category
          mission
          why
          what
          how
          period
          importance
          point
        }
        teamMember {
          nickname
          imageUrl
          role
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingActionsRadar = gql`
  query GetOnboardingActionsRadar($teamId: ID!, $userId: ID!) {
    getOnboardingActionsRadar(teamId: $teamId, userId: $userId) {
      feedback {
        category1
        category2
        category3
        category4
        category5
      }
    }
  }
`

export const QueryGetAccount = gql`
  query {
    getAccount {
      id
      email
      nickname
      firstName
      lastName
      imageUrl
      shortDescription
      description
      setupStartedAt
      karuteStartedAt
      emailNotifySetup
      emailNotifyHour
      emailNotifyMinute
      onboardingUsageStatus
    }
  }
`

export const QueryGetOnboardingActionList = gql`
  query GetOnboardingActionList($filter: OnboardingActionFilter, $first: Int, $after: String, $teamId: String) {
    getOnboardingActionList(filter: $filter, first: $first, after: $after, teamId: $teamId) {
      items {
        id
        teamId
        category
        mission
        why
        what
        how
        period
        importance
        point
        deadline
      }
    }
  }
`

export const QueryGetOnboardingNotifycation = gql`
  query GetOnboardingNotifycation($id: ID!) {
    getOnboardingNotifycation(id: $id) {
      id
      actionId
      to
      from
      teamId
      type
      checkedAt
      createdAt
    }
  }
`

export const QueryGetOnboardingNotifycationList = gql`
  query GetOnboardingNotifycationList($to: ID!, $first: Int, $after: String) {
    getOnboardingNotifycationList(to: $to, first: $first, after: $after) {
      items {
        id
        actionId
        to
        from
        teamId
        type
        checkedAt
        createdAt
        teamMember {
          id
          nickname
          imageUrl
        }
      }
      nextToken
    }
  }
`

export const MutationCreateOnboardingPostDaily = `mutation CreateOnboardingPostDaily($input: OnboardingPostDailyCreateInput!) {
    createOnboardingPostDaily(input: $input) {
      id
      teamId
      userId
      type
      daily
      createdAt
    }
  }`

export const MutationCreateOnboardingPostLikeToggle = `mutation CreateOnboardingPostLikeToggle($input: OnboardingPostLikeToggleInput!) {
      createOnboardingPostLikeToggle(input: $input) {
        id
        postId
        userId
        createdAt
      }
    }`

export const MutationCreateOnboardingPostComment = gql`
  mutation CreateOnboardingPostComment($input: OnboardingPostCommentCreateInput!) {
    createOnboardingPostComment(input: $input) {
      id
      postId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingPostCommentLikeToggle = `mutation createOnboardingPostCommentLikeToggle($input: OnboardingPostCommentLikeToggleInput!) {
  createOnboardingPostCommentLikeToggle(input: $input) {
    id
    postId
    userId
    createdAt
  }
}`

export const MutationUpdateOnboardingPostComment = gql`
  mutation UpdateOnboardingPostComment($input: OnboardingPostCommentUpdateInput!) {
    updateOnboardingPostComment(input: $input) {
      id
      postId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationDeleteOnboardingPostComment = gql`
  mutation DeleteOnboardingPostComment($id: ID!) {
    deleteOnboardingPostComment(id: $id) {
      id
      postId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingPostSecondComment = gql`
  mutation CreateOnboardingPostSecondComment($input: OnboardingPostSecondCommentCreateInput!) {
    createOnboardingPostSecondComment(input: $input) {
      id
      postCommentId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingPostSecondCommentLikeToggle = `mutation createOnboardingPostSecondCommentLikeToggle($input: OnboardingPostSecondCommentLikeToggleInput!) {
  createOnboardingPostSecondCommentLikeToggle(input: $input) {
    id
    postSecondCommentId
    userId
    createdAt
  }
}`

export const MutationUpdateOnboardingPostSecondComment = gql`
  mutation UpdateOnboardingPostSecondComment($input: OnboardingPostSecondCommentUpdateInput!) {
    updateOnboardingPostSecondComment(input: $input) {
      id
      postCommentId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationDeleteOnboardingPostSecondComment = gql`
  mutation DeleteOnboardingPostSecondComment($id: ID!) {
    deleteOnboardingPostSecondComment(id: $id) {
      id
      postCommentId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingActionLikeToggle = gql`
  mutation CreateOnboardingActionLikeToggle($input: OnboardingActionLikeToggleInput!) {
    createOnboardingActionLikeToggle(input: $input) {
      id
      actionId
      actionOwnerId
      userId
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingActionComment = gql`
  mutation CreateOnboardingActionComment($input: OnboardingActionCommentCreateInput!) {
    createOnboardingActionComment(input: $input) {
      id
      actionId
      actionOwnerId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationUpdateOnboardingActionComment = gql`
  mutation UpdatenboardingActionComment($input: OnboardingActionCommentUpdateInput!) {
    updateOnboardingActionComment(input: $input) {
      id
      actionId
      actionOwnerId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationDeleteOnboardingActionComment = gql`
  mutation DeleteOnboardingActionComment($id: ID!) {
    deleteOnboardingActionComment(id: $id) {
      id
      actionId
      actionOwnerId
      userId
      text
      createdAt

      user {
        id
      }
    }
  }
`

export const MutationCreateOnboardingActionFeedback = gql`
  mutation CreateOnboardingActionFeedback($input: OnboardingActionFeedbackCreateInput!) {
    createOnboardingActionFeedback(input: $input) {
      id
      teamId
      userId
      actionId
      category
      value
      importance
      settedAt
      updatedAt

      action {
        id
      }
    }
  }
`

export const MutationDeleteOnboardingActionFeedback = gql`
  mutation DeleteOnboardingActionFeedback($id: ID!) {
    deleteOnboardingActionFeedback(id: $id) {
      id
      teamId
      userId
      actionId
      category
      value
      importance
      settedAt
      updatedAt

      action {
        id
      }
    }
  }
`

export const MutationCreateOnboardingPostAction = gql`
  mutation CreateOnboardingPostAction($input: OnboardingPostActionCreateInput!) {
    createOnboardingPostAction(input: $input) {
      id
      teamId
      userId
      type
      actionId
      comment
      label
      createdAt
    }
  }
`

export const MutationUpdateOnboardingPostAction = gql`
  mutation UpdateOnboardingPostAction($input: OnboardingPostActionUpdateInput!) {
    updateOnboardingPostAction(input: $input) {
      id
      teamId
      userId
      type
      actionId
      comment
      label
      createdAt
    }
  }
`

export const MutationUpdateAccount = `mutation UpdateAccount($input: UserUpdateInput!) {
  updateAccount(input: $input) {
    id
    email
    nickname
    firstName
    lastName
    imageUrl
    shortDescription
    description
    setupStartedAt
    karuteStartedAt
    emailNotifySetup
    emailNotifyHour
    emailNotifyMinute
  }
}`

export const MutationCreateOnboardingAction = `mutation CreateOnboardingAction($input: OnboardingActionCreateInput!) {
  createOnboardingAction(input: $input) {
    id
    teamId
    category
    mission
    why
    what
    how
    period
    importance
    point
    createdAt
    updatedAt
    publishAt
    dammyHash
  }
}`

export const MutationUpdateOnboardingAction = `mutation UpdateOnboardingAction($input: OnboardingActionUpdateInput!) {
  updateOnboardingAction(input: $input) {
    id
    teamId
    category
    mission
    why
    what
    how
    period
    importance
    point
    createdAt
    updatedAt
    publishAt
    dammyHash
  }
}`

export const MutationDeleteOnboardingAction = `mutation DeleteOnboardingAction($id: ID!) {
  deleteOnboardingAction(id: $id) {
    id
    teamId
    category
    mission
    why
    what
    how
    period
    importance
    point
    createdAt
    updatedAt
    publishAt
    dammyHash
  }
}`

export const MutationUpdateOnboardingNotification = `mutation UpdateOnboardingNotifycation($id: ID!) {
  updateOnboardingNotifycation(id: $id) {
    id
    actionId
    to
    from
    teamId
    type
    checkedAt
    createdAt
  }
}`

export const queryGetUser = async (props: QueryGetUserRequest) => {
  const response: any = await API.graphql({
    query: QueryGetUser,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetUserResponse
}

export const queryGetOnboardingPost = async (args: QueryGetOnboardingPostArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPost,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPost as OnboardingPost
}

export const queryGetOnboardingPostCommentList = async (props: QueryGetOnboardingPostCommentListRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostCommentList,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingPostCommentListResponse
}

export const queryGetOnboardingPostSecondCommentList = async (
  props: QueryGetOnboardingPostSecondCommentListRequest
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostSecondCommentList,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingPostSecondCommentListResponse
}

export const queryGetOnboardingPostSecondCommentListOfOneUser = async (
  props: QueryGetOnboardingPostSecondCommentListOfOneUserArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostSecondCommentListOfOneUser,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostSecondCommentListOfOneUser
}

export const queryGetOnboardingActions = async (props: QueryGetOnboardingActionsRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActions,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionsResponse
}

export const queryGetOnboardingAction = async (props: QueryGetOnboardingActionRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingAction,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionResponse
}

export const queryGetOnboardingActionCommentList = async (args: QueryGetOnboardingActionCommentListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionCommentList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionCommentListResponse
}

export const queryGetOnboardingPostLikeList = async (args: QueryGetOnboardingPostLikeListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostLikeList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostLikeList as OnboardingPostLikeConnection
}

// コメントを「した」数を持ってくるクエリ。今後使用する可能性あり。
export const queryGetOnboardingPostCommentListOfOneUser = async (
  args: QueryGetOnboardingPostCommentListOfOneUserArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostCommentListOfOneUser,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostCommentListOfOneUser
}

export const queryGetOnboardingPostLikeListOfOneUser = async (args: QueryGetOnboardingPostLikeListOfOneUserArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostLikeListOfOneUser,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostLikeListOfOneUser as OnboardingPostLikeConnection
}

export const queryGetOnboardingPostCommentLikeList = async (args: QueryGetOnboardingPostCommentLikeListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostCommentLikeList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostCommentLikeList as OnboardingPostCommentLikeConnection
}

export const queryGetOnboardingPostCommentLikeListOfOneUser = async (
  args: QueryGetOnboardingPostCommentLikeListOfOneUserArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostCommentLikeListOfOneUser,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostCommentLikeListOfOneUser as OnboardingPostCommentLikeConnection
}

export const queryGetOnboardingPostSecondCommentLikeList = async (
  args: QueryGetOnboardingPostSecondCommentLikeListArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostSecondCommentLikeList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostSecondCommentLikeList as OnboardingPostSecondCommentLikeConnection
}

export const queryGetOnboardingPostSecondCommentLikeListOfOneUser = async (
  args: QueryGetOnboardingPostSecondCommentLikeListOfOneUserArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostSecondCommentLikeListOfOneUser,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostSecondCommentLikeListOfOneUser as OnboardingPostSecondCommentLikeConnection
}

export const queryGetOnboardingActionsFeedbacks = async (props: QueryGetOnboardingActionsFeedbacksRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionsFeedbacks,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionsFeedbacksResponse
}

export const queryGetOnboardingOwnTeamMemberList = async (props: QueryGetOnboardingOwnTeamMemberListRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingOwnTeamMemberList,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingOwnTeamMemberListResponse
}

export const queryGetOnboardingTeam = async (args: QueryGetOnboardingTeamArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingTeam,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingTeam as OnboardingTeam
}

export const queryGetOnboardingTeamMemberList = async (props: QueryGetOnboardingTeamMemberListRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingTeamMemberList,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingTeamMemberListResponse
}

export const queryGetOnboardingTeamMember = async (args: QueryGetOnboardingTeamMemberArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingTeamMember,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })
  return response.data as QueryGetOnboardingTeamMemberResponse
}

export const queryGetOnboardingPostList = async (props: QueryGetOnboardingPostListRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostList,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingPostListResponse
}

export const queryGetOnboardingPostListOfOneUser = async (props: QueryGetOnboardingPostListOfOneUserRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostListOfOneUser,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingPostListOfOneUserResponse
}

export const queryGetOnboardingActionsRadar = async (props: QueryGetOnboardingActionsRadarRequest) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionsRadar,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionsRadarResponse
}

export const queryGetAccount = async () => {
  const response: any = await API.graphql({
    query: QueryGetAccount,
    variables: {},
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetAccountResponse
}

export const queryGetOnboardingActionList = async (args: QueryGetOnboardingActionListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as QueryGetOnboardingActionListResponse
}

export const queryGetOnboardingActionLikeList = async (args: QueryGetOnboardingActionLikeListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionLikeList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingActionLikeList as Maybe<OnboardingActionLikeConnection>
}

export const queryGetOnboardingActionLikeListOfOneUser = async (
  args: QueryGetOnboardingActionLikeListOfOneUserArgs
) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionLikeListOfOneUser,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingActionLikeListOfOneUser as Maybe<OnboardingActionLikeConnection>
}

export const queryGetOnboardingNotifycation = async (args: QueryGetOnboardingNotifycationArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingNotifycation,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingNotifycation as Notifycation
}

export const queryGetOnboardingNotifycationList = async (args: QueryGetOnboardingNotifycationListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingNotifycationList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingNotifycationList as NotifycationConnection
}

export const mutationCreateOnboardingPostDaily = async (args: MutationCreateOnboardingPostDailyArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostDaily,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationOnboardingPostDailyCreateInputResponse
}

export const mutationCreateOnboardingPostLikeToggle = async (args: MutationCreateOnboardingPostLikeToggleArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostLikeToggle,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostLikeToggleResponse
}

export const mutationCreateOnboardingPostComment = async (args: MutationCreateOnboardingPostCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostCommentResponse
}

export const mutationCreateOnboardingPostCommentLikeToggle = async (
  args: MutationCreateOnboardingPostCommentLikeToggleArgs
) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostCommentLikeToggle,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostCommentLikeToggleResponse
}

export const mutationUpdateOnboardingPostComment = async (args: MutationUpdateOnboardingPostCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingPostComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostCommentResponse
}

export const mutationDeleteOnboardingPostComment = async (args: MutationDeleteOnboardingPostCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingPostComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostCommentResponse
}

export const mutationCreateOnboardingPostSecondComment = async (
  args: MutationCreateOnboardingPostSecondCommentArgs
) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostSecondComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostSecondCommentResponse
}

export const mutationCreateOnboardingPostSecondCommentLikeToggle = async (
  args: MutationCreateOnboardingPostSecondCommentLikeToggleArgs
) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostSecondCommentLikeToggle,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostSecondCommentLikeToggleResponse
}

export const mutationUpdateOnboardingPostSecondComment = async (
  args: MutationUpdateOnboardingPostSecondCommentArgs
) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingPostSecondComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostSecondCommentResponse
}

export const mutationDeleteOnboardingPostSecondComment = async (
  args: MutationDeleteOnboardingPostSecondCommentArgs
) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingPostSecondComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostSecondCommentResponse
}

export const mutationCreateOnboardingActionLikeToggle = async (args: MutationCreateOnboardingActionLikeToggleArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingActionLikeToggle,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.createOnboardingActionLikeToggle as Maybe<OnboardingActionLike>
}

export const mutationCreateOnboardingActionComment = async (args: MutationCreateOnboardingActionCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingActionComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingActionCommentResponse
}

export const mutationUpdateOnboardingActionComment = async (args: MutationUpdateOnboardingActionCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingActionComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingActionCommentResponse
}

export const mutationDeleteOnboardingActionComment = async (args: MutationDeleteOnboardingActionCommentArgs) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingActionComment,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingActionCommentResponse
}

export const mutationCreateOnboardingActionFeedback = async (args: MutationCreateOnboardingActionFeedbackArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingActionFeedback,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingActionFeedbackResponse
}

export const mutationDeleteOnboardingActionFeedback = async (args: MutationDeleteOnboardingActionFeedbackArgs) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingActionFeedback,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.deleteOnboardingActionFeedback as OnboardingActionFeedback
}

export const mutationCreateOnboardingPostAction = async (args: MutationCreateOnboardingPostActionArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostAction,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingPostActionResponse
}

export const mutationUpdateOnboardingPostAction = async (args: MutationUpdateOnboardingPostActionArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingPostAction,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.updateOnboardingPostAction as OnboardingPost
}

export const mutationUpdateAccount = async (props: MutationUpdateAccountRequest) => {
  const response: any = await API.graphql({
    query: MutationUpdateAccount,
    variables: { ...props },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationUpdateAccountResponse
}

// Create OnbAction
export const mutationCreateOnboardingAction = async (args: MutationCreateOnboardingActionArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingAction,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationCreateOnboardingActionResponse
}

export const mutationUpdateOnboardingAction = async (args: MutationUpdateOnboardingActionArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingAction,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as MutationUpdateOnboardingActionResponse
}

export const mutationDeleteOnboardingAction = async (args: MutationDeleteOnboardingActionArgs) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingAction,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.deleteOnboardingAction as OnboardingAction
}

export const mutationUpdateOnboardingNotification = async (args: MutationUpdateOnboardingNotifycationArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingNotification,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data as Notifycation
}

//
// Onb Post
//

const MutationCreateOnboardingPostPost = /* GraphQL */ `
  mutation createOnboardingPostPost($input: OnboardingPostPostCreateInput!) {
    createOnboardingPostPost(input: $input) {
      id
      teamId
      userId
      type
      comment
      createdAt
      updatedAt
      likeCount
      commentCount
      fileNames
    }
  }
`

export const mutationCreateOnboardingPostPost = async (args: MutationCreateOnboardingPostPostArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingPostPost,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.createOnboardingPostPost as OnboardingPost
}

const MutationUpdateOnboardingPostPost = /* GraphQL */ `
  mutation updateOnboardingPostPost($input: OnboardingPostPostUpdateInput!) {
    updateOnboardingPostPost(input: $input) {
      id
      teamId
      userId
      type
      comment
      createdAt
      updatedAt
      likeCount
      commentCount
      fileNames
    }
  }
`

export const mutationupdateOnboardingPostPost = async (args: MutationUpdateOnboardingPostPostArgs) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingPostPost,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.updateOnboardingPostPost as OnboardingPost
}

const MutationDeleteOnboardingPostPost = /* GraphQL */ `
  mutation DeleteOnboardingPostPost($id: ID!) {
    deleteOnboardingPostPost(id: $id) {
      id
      teamId
      userId
      type
      actionId
      comment
      label
      createdAt
    }
  }
`

export const mutationDeleteOnboardingPostPost = async (args: MutationDeleteOnboardingPostPostArgs) => {
  const response: any = await API.graphql({
    query: MutationDeleteOnboardingPostPost,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.deleteOnboardingPostPost as OnboardingPost
}

const QueryGetOnboardingPostFile = /* GraphQL */ `
  query getOnboardingPostFile($fileName: String!, $teamId: ID!) {
    getOnboardingPostFile(fileName: $fileName, teamId: $teamId) {
      fileName
      mimeType
      size
      signedUrl
    }
  }
`

export const queryGetOnboardingPostFile = async (args: QueryGetOnboardingPostFileArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingPostFile,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getOnboardingPostFile as OnboardingPostFile
}

const QueryListOnboardingPostFiles = /* GraphQL */ `
  query listOnboardingPostFiles($teamId: ID!, $postId: ID) {
    listOnboardingPostFiles(teamId: $teamId, postId: $postId) {
      fileName
      mimeType
      size
      signedUrl
    }
  }
`

export const queryListOnboardingPostFiles = async (args: QueryListOnboardingPostFilesArgs) => {
  const response: any = await API.graphql({
    query: QueryListOnboardingPostFiles,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.listOnboardingPostFiles as OnboardingPostFile[]
}

export const queryGetOnboardingTeamMemberTargetFile = async (args: QueryGetOnboardingTeamMemberTargetFileArgs) => {
  const QueryGetOnboardingTeamMemberTargetFile = /* GraphQL */ `
    query getOnboardingTeamMemberTargetFile($fileName: String!, $teamId: ID!, $userId: ID!) {
      getOnboardingTeamMemberTargetFile(fileName: $fileName, teamId: $teamId, userId: $userId) {
        fileName
        mimeType
        size
        signedUrl
      }
    }
  `

  const response: any = await API.graphql({
    query: QueryGetOnboardingTeamMemberTargetFile,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })
  return response.data.getOnboardingTeamMemberTargetFile
}
