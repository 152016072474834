import * as React from 'react'

import {
  CircularProgress,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Theme,
} from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { Controller, useForm } from 'react-hook-form'

import MessagePanel from 'components/MessagePanel'
import Slider from 'pages/karte/member/questionnaire/components/Slider'
import Stepper from 'pages/karte/member/questionnaire/components/Stepper'
import {
  IKarteMemberQuestionnaireAnswerSet,
  IKarteMemberQuestionnaireAnswerValueSet,
  IKarteMemberQuestionnaireFormField,
} from 'pages/karte/member/questionnaire/interface'
import { forms, teamCookieKeys } from 'pages/karte/member/static'
import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { Button } from '../../components/Button'
import { Phase, TeamToolsKarteDiagnoseContext } from '../../contexts/TeamToolsKarteDiagnoseContext'
import { mutationTeamKarteMemberStore } from '../../hooks/graphql'

import { getNextPhase, getPhaseIndex, getPreviousPhase } from './phase'
import { calcKarteResult, getCalculableKarteList } from './utils'

import { constants, Pages, useHistory } from 'assets'
import { replacePathParams } from 'assets/history'

export const Diagnose: React.FC = () => {
  const classes = useStyles()
  const { route } = useHistory()

  const { teamId } = React.useContext(TeamsHooksContext)
  const { phase, setPhase } = React.useContext(TeamToolsKarteDiagnoseContext)

  const [cookies, setCookie, removeCookie] = useCookies([teamCookieKeys.phase, teamCookieKeys.data])
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0)
  const [answers, setAnswers] = React.useState({} as IKarteMemberQuestionnaireAnswerSet)

  const { register, handleSubmit, setValue, errors, reset, control, getValues } =
    useForm<IKarteMemberQuestionnaireAnswerValueSet>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

  React.useEffect(() => {
    setCurrentStepIndex(getPhaseIndex(phase))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase])

  React.useEffect(() => {
    const data = cookies[teamCookieKeys.data]

    if (data) {
      setAnswers(data.answers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //For debug
  const [debugMode, setDebugMode] = React.useState(false)
  const [currentHiddenCommand, setCurrentHiddenCommand] = React.useState<number>(0)
  const keyDownFunction = React.useCallback(
    (e: KeyboardEvent) => {
      // dda
      const SECRET = [68, 68, 65]
      if (e.keyCode === SECRET[currentHiddenCommand]) {
        setCurrentHiddenCommand((old) => old + 1)
        if (currentHiddenCommand + 1 === SECRET.length) {
          setCurrentHiddenCommand(0)
          setDebugMode(!debugMode)
        }
      } else {
        setCurrentHiddenCommand(0)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentHiddenCommand]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', keyDownFunction, false)

    return () => {
      document.removeEventListener('keydown', keyDownFunction)
    }
  }, [keyDownFunction])
  //For debug

  React.useEffect(() => {
    reset() // reset form on change step

    const staticForm = forms[currentStepIndex]
    if (staticForm) {
      staticForm.fields.forEach((field) => {
        register(field.key, { required: `${field.label}は必須です` })
        if (answers[field.group] && answers[field.group][field.key]) {
          setValue(field.key, answers[field.group][field.key])
        }
      })

      if (debugMode) {
        const localAnswers = { ...answers }

        staticForm.fields.forEach((field) => {
          const randomValue = randomIntFromInterval(1, 7).toString()
          setValue(field.key, randomValue)
          if (!answers[field.group]) {
            localAnswers[field.group] = {} as IKarteMemberQuestionnaireAnswerValueSet
          }
          localAnswers[field.group][field.key] = randomValue
        })
        setAnswers(localAnswers)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, debugMode])

  const [backConfirm, setBackConfirm] = React.useState(false)

  const [processing, setProcessing] = React.useState(false)
  const onSubmit = async () => {
    if (teamId) {
      const next = getNextPhase(phase)

      if (next) {
        setPhase(next)
      }

      if (currentStepIndex < forms.length - 1) {
        const step = currentStepIndex + 1
        setCurrentStepIndex(step)

        setCookie(teamCookieKeys.data, {
          step,
          answers,
        })
      } else {
        setProcessing(true)
        const result = calcKarteResult(getCalculableKarteList(answers))
        const response = await mutationTeamKarteMemberStore({
          input: {
            teamId,
            data: JSON.stringify(result),
            anket: JSON.stringify(answers.anket),
            personal: JSON.stringify(answers.common),
          },
        })

        if (response) {
          resetAllData()
          route.push(replacePathParams(Pages.TeamsToolKarteResult, { teamId, karteId: response.id }))
        }

        setProcessing(false)
        window.scrollTo(0, 0)
      }
    }
  }

  const resetAllData = () => {
    removeCookie(teamCookieKeys.phase)
    removeCookie(teamCookieKeys.data)
    setAnswers({})
    setPhase(Phase.Prepare)
  }

  const renderQuestionnaireField = (field: IKarteMemberQuestionnaireFormField, index: number) => {
    const getFormAnswer = (group: string, fieldKey: string) => {
      return answers[group] && answers[group][fieldKey] ? answers[group][fieldKey] : getValues(field.key) ?? null
    }
    const setFormAnswer = (group: string, fieldKey: string, value: string) => {
      const localAnswers = { ...answers }
      if (!answers[group]) {
        localAnswers[group] = {} as IKarteMemberQuestionnaireAnswerValueSet
      }
      localAnswers[group][fieldKey] = value
      setAnswers(localAnswers)
      setValue(field.key, value)
    }
    const errorMessage =
      errors.hasOwnProperty(field.key) && errors[field.key] ? errors[field.key]?.message || 'Unknown error' : null

    if (field.type === 'radio') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__radio">
            <RadioGroup
              row={true}
              aria-label={field.key}
              value={getFormAnswer(field.group, field.key) ?? undefined}
              name={field.key}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
            >
              {field.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  value={option.key}
                  control={
                    <Radio
                      classes={{
                        checked: classes.radioChecked,
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {errorMessage ? <small style={{ color: 'red' }}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'select') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // defaultValue={getFormAnswer(field.group, field.key) || ''}
              value={getFormAnswer(field.group, field.key) || ''}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
              autoWidth={true}
              displayEmpty={true}
              disableUnderline={true}
              classes={{
                root: '__container__content__body__select__root',
                icon: '__container__content__body__select__icon',
              }}
              className={`__container__content__body__select__select ${
                getFormAnswer(field.group, field.key) !== null ? 'hasValue' : ''
              }`}
            >
              <MenuItem value="" disabled>
                {field.label}を選択
              </MenuItem>
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage ? <small style={{ color: 'red' }}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'slider') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__slider">
            <Controller
              name={`${field.key}`}
              control={control}
              defaultValue={parseInt(getFormAnswer(field.group, field.key) ?? '0', 0)}
              rules={{
                required: `${field.label}は必須です`,
              }}
              render={({
                onChange,
                //  onBlur,
                value,
              }) => (
                <div className="__container__content__body__slider__container">
                  <Slider
                    key={index}
                    name="member"
                    value={value}
                    options={field.options.map((option) => {
                      return { label: option.label, value: parseInt(option.key, 0) }
                    })}
                    onChange={(value) => {
                      onChange(value)
                      setFormAnswer(field.group, field.key, `${value}`)
                    }}
                  />
                  {errorMessage ? <small style={{ color: 'red', marginBottom: 16 }}>{errorMessage}</small> : null}
                </div>
              )}
            />
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    const staticForm = forms[currentStepIndex]

    if (staticForm) {
      return (
        <div className="__container__content" style={{ backgroundColor: staticForm.color || '#fff' }}>
          <div className="__container__content__title">{staticForm.label}</div>

          {staticForm.description ? (
            <div className="__container__content__description">{staticForm.description}</div>
          ) : null}

          <div className="__container__content__body">
            {staticForm.fields && staticForm.fields.map(renderQuestionnaireField)}
          </div>

          {renderStepButtons()}
        </div>
      )
    } else {
      return <></>
    }
  }

  const renderStepButtons = () => {
    const onBack = () => {
      switch (phase) {
        case Phase.Q1:
          setBackConfirm(true)
          break

        default:
          const next = getPreviousPhase(phase)

          if (next) {
            setPhase(next)
          }
      }
    }

    return (
      <div className="__container__content__buttons">
        <Button
          buttonType="light"
          style={{
            height: 48,
            borderRadius: 48,
          }}
          onClick={onBack}
          disabled={processing}
        >
          戻る
        </Button>

        <Button
          buttonType="primary"
          style={{
            height: 48,
            borderRadius: 48,
          }}
          type="submit"
          disabled={processing}
        >
          {!processing ? '次へ' : <CircularProgress size={18} />}
        </Button>
      </div>
    )
  }

  const renderConfirmationDialog = () => {
    return (
      <Modal open={backConfirm} className={classes.modal} disableBackdropClick={true}>
        <div className={classes.modalContainer}>
          <MessagePanel
            failure={true}
            handleClose={() => {
              setBackConfirm(false)
            }}
            body={
              <div>
                <p>
                  分析を途中で中止しますか?
                  <br />
                  「はい」を押すと途中まで回答したデータは破棄して診察室に戻ります。
                </p>
              </div>
            }
            footer={
              <div className={classes.modalButtons}>
                <Button
                  buttonType="light"
                  style={{
                    height: 48,
                    borderRadius: 48,
                  }}
                  onClick={() => setBackConfirm(false)}
                >
                  いいえ
                </Button>
                <Button
                  buttonType="light"
                  style={{
                    height: 48,
                    borderRadius: 48,
                  }}
                  onClick={resetAllData}
                >
                  はい
                </Button>
              </div>
            }
          />
        </div>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        {debugMode && `phase: ${phase}`}
        <div className="__container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="__container__header">
              <div style={{ width: '100%', textAlign: 'center' }}>チームカルテ診察室</div>
            </div>
            <div className="__container__stepper">
              <Stepper activeStepIndex={currentStepIndex} stepCount={forms.length} />
            </div>
            {renderContent()}
          </form>
        </div>
        {renderConfirmationDialog()}
      </div>
    </React.Fragment>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
    backgroundColor: '#F5F5F5',

    '& .__container': {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_MEDIUM,
      },

      '&__header': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px 8px',
        alignItems: 'center',
        marginTop: 8,
        height: 48,

        '&__button': {
          fontWeight: 'bold',
          '& i': {
            marginRight: 4,
          },
        },
      },

      '&__stepper': {
        padding: '0 32px 24px',
      },

      '&__content': {
        backgroundColor: '#fff',
        borderTopRightRadius: '30px',
        borderTopLeftRadius: '30px',
        padding: '16px 16px 32px',
        boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: 18,
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '8px 0',
        },

        '&__description': {
          color: constants.COLOR_RED,
          fontSize: 12,
          textAlign: 'center',
          marginTop: 20,
          whiteSpace: 'pre',
        },

        '&__body': {
          padding: 16,
          //

          '&__title': {
            fontWeight: 'bold',
            borderBottom: `1px solid ${constants.COLOR_MAIN}`,
            paddingBottom: '4px',
            marginBottom: '4px',
          },
          '&__radio': {
            padding: '4px 16px',
          },
          '&__select': {
            padding: '16px 0',

            '&__select': {
              // padding: "4px 16px",
              width: '100%',
              border: '1px solid #BEBEBE',
              '&.hasValue': {
                '& .__container__content__body__select__root': {
                  color: 'black !important',
                },
              },
            },

            '&__root': {
              // padding: "4px 16px",
              padding: '10px 12px',
              fontSize: 14,
              color: '#BEBEBE',
            },

            '&__icon': {
              color: 'white',
              background: constants.COLOR_MAIN,
              width: 40,
              height: '100%',
              top: 0,
            },
          },

          '&__slider__container': {
            paddingTop: '16px',
            paddingBottom: '16px',
          },
          '&__slider': {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },

        '&__buttons': {
          display: 'flex',
          justifyContent: 'center',
          padding: 32,
          gap: 16,

          '& button': {
            height: 50,
            borderRadius: 50,
            fontSize: 16,
          },
        },
      },
    },
  },

  radioChecked: {
    color: `${constants.COLOR_MAIN} !important`,
  },

  modalContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    padding: 16,

    '& div': {
      '&:focus': {
        outline: 'none',
      },
    },
  },

  modalButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    marginTop: '8px',
  },

  loadingCircularProgress: {
    '&:focus': {
      outline: 'none',
    },
  },
}))

const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
