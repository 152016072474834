import * as React from 'react'

import { IndexedTeam } from 'pages/teams/pages/list'

import { useTeamDesign } from './teamStyle'

export type SortedInfoKey =
  | 'teamNum' // No.
  | 'teamName' // チーム名
  | 'admin' // チーム管理者
  | 'leaderName' // リーダー名
  | 'countMember' // メンバー数
  | 'plans' // 利用プラン
  | 'costs' // 月額
  | 'status' // チームのステータス
  | 'invitation' // メンバーの招待状況
  | 'reservation' // 予約招待状況
  | 'teamBuildingStartDate' // キックオフ日
  | 'teamBuildingProgress' // アクション達成度
  | 'karteCount' // カルテ実施回数
  | 'teamBuildingReviewHistoryCount' // リフレクション実施回数
  | 'ghostHistoryCount' // オバケ実施回数
  | 'ghostLastPlayedDate' // オバケ最終実施日
  | 'anketBuildingDate' // 直近アンケート実施日
  | 'anketBuildingAverage' // 最新総合評価
  | 'anketBuildingGrowth' // 成長度（前回比）
  | 'anketBuildingValue' // チームビルディング度

export type SortedInfoSort = 'up' | 'down'
type El = string | number | undefined | null

export const useSortTeams = () => {
  const [sortKey, setSortKey] = React.useState<{
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null>(null)
  const { getTeamPlanLabels } = useTeamDesign()

  const sortTeams = (arr: IndexedTeam[] | undefined) => {
    if (!arr) return

    const sortedInfosTeams = sortKey === null ? arr : arr.sort(compareFn)

    return sortedInfosTeams
  }

  const executeCompare = (a: El, b: El): number => {
    const isUp = sortKey?.sort === 'up'

    if (typeof a === 'number' && typeof b === 'number') {
      return isUp ? a - b : b - a
      // undefined など、値がない場合は昇順・降順関わらず末尾に表示。
    } else if (a !== 0 && !Boolean(a)) {
      return 1
      // undefined など、値がない場合は昇順・降順関わらず末尾に表示。
    } else if (b !== 0 && !Boolean(b)) {
      return -1
    } else if (typeof a === 'string' && typeof b === 'string') {
      return isUp ? a.localeCompare(b) : b.localeCompare(a)
    } else {
      return 0
    }
  }

  // compareFn で定義漏れがないか確認するためのダミー関数です。実際のロジックには関係ありません。
  const assertUnreachable = (x: undefined): never => {
    throw new Error(`Unexpected value!! ${x}`)
  }

  function compareFn(a: IndexedTeam, b: IndexedTeam) {
    const compareItem: { first: El; second: El } = { first: undefined, second: undefined }

    switch (sortKey?.key) {
      // --------------------
      // 共通

      // No.
      case 'teamNum':
        compareItem.first = a.num
        compareItem.second = b.num
        break
      // チーム名
      case 'teamName':
        compareItem.first = a.name
        compareItem.second = b.name
        break

      // --------------------
      // GeneralList

      // チーム管理者
      case 'admin':
        compareItem.first = a.ownerFullName
        compareItem.second = b.ownerFullName
        break
      // リーダー名
      case 'leaderName':
        compareItem.first = a?.leaderFullNames ? a?.leaderFullNames[0] : ''
        compareItem.second = b?.leaderFullNames ? b?.leaderFullNames[0] : ''
        break
      // メンバー数
      case 'countMember':
        compareItem.first = a.countMember
        compareItem.second = b.countMember
        break
      // 利用プラン
      case 'plans':
        compareItem.first = getTeamPlanLabels(a.plans)
        compareItem.second = getTeamPlanLabels(b.plans)
        break
      // 月額
      case 'costs':
        compareItem.first = a.cost
        compareItem.second = b.cost
        break
      // チームのステータス
      case 'status':
        compareItem.first = a.statusUsage
        compareItem.second = b.statusUsage
        break
      // メンバーの招待状況
      case 'invitation':
        compareItem.first = a.countInvitedMember
        compareItem.second = b.countInvitedMember
        break
      // 予約招待状況
      case 'reservation':
        compareItem.first = a.memberInvitationDateTimes?.sort()[0]
        compareItem.second = b.memberInvitationDateTimes?.sort()[0]
        break

      // --------------------
      // GraphList

      // キックオフ日
      case 'teamBuildingStartDate':
        compareItem.first = a?.teamBuildingStartDate
        compareItem.second = b?.teamBuildingStartDate
        break
      // アクション達成度
      case 'teamBuildingProgress':
        compareItem.first = a?.teamBuildingProgress
        compareItem.second = b?.teamBuildingProgress
        break
      // カルテ実施回数
      case 'karteCount':
        compareItem.first = a?.karteCount
        compareItem.second = b?.karteCount
        break
      // リフレクション実施回数
      case 'teamBuildingReviewHistoryCount':
        compareItem.first = a?.teamBuildingReviewHistoryCount
        compareItem.second = b?.teamBuildingReviewHistoryCount
        break
      // オバケ実施回数
      case 'ghostHistoryCount':
        compareItem.first = a?.ghostHistoryCount
        compareItem.second = b?.ghostHistoryCount
        break
      // オバケ最終実施日
      case 'ghostLastPlayedDate':
        compareItem.first = a?.ghostLastPlayedDate
        compareItem.second = b?.ghostLastPlayedDate
        break
      // 直近アンケート実施日
      case 'anketBuildingDate':
        compareItem.first = a?.anketBuildingDate
        compareItem.second = b?.anketBuildingDate
        break
      // 最新総合評価
      case 'anketBuildingAverage':
        compareItem.first = a.anketBuildingAverage
        compareItem.second = b.anketBuildingAverage
        break
      // 成長度（前回比）
      case 'anketBuildingGrowth':
        compareItem.first = a.anketBuildingGrowth
        compareItem.second = b.anketBuildingGrowth
        break
      // チームビルディング度
      case 'anketBuildingValue':
        compareItem.first = a.anketBuildingValue
        compareItem.second = b.anketBuildingValue
        break

      default:
        // チェック用のダミー関数。定義漏れがあると type error が起きます。
        assertUnreachable(sortKey?.key)
        break
    }

    return executeCompare(compareItem.first, compareItem.second)
  }

  return { sortKey, setSortKey, sortTeams }
}
