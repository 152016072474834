import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constants from '../assets/constants'

export type Props = {
  category: string
  title: string
  description?: React.ReactElement
  marginTop?: 35
}

export const TitleRenewal: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { category, title, description } = props

  return (
    <div className={classes.root}>
      <h5>{category}</h5>
      <h1>{title}</h1>
      {description ? <p>{description}</p> : <></>}
    </div>
  )
}

type StyleProps = {
  marginTop?: 35
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({ marginTop }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h5': {
        color: constants.COLOR_MAIN_NEW,
        fontFamily:
          '"DIN 2014", "Fjalla One", "Lato","Noto Sans JP", "游ゴシック Medium", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif',
        fontSize: 14,
        fontWeight: 'normal',
        margin: marginTop ? `${marginTop}px 0 0` : '82px 0 0',
        [theme.breakpoints.down('xs')]: {
          margin: '26px 0 0',
        },
      },
      '& h1': {
        fontSize: 32,
        fontWeight: 'bold',
        margin: '25px 0 0',
        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
        },
      },
      '& p': {
        margin: '30px 0 0',
        textAlign: 'center',
        lineHeight: 1.8,
        [theme.breakpoints.down('xs')]: {
          margin: '20px 0 0',
        },
      },
    }),
  }),
  { name: 'TitleRenewal' }
)
