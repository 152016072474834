import * as React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { Breadcrumb } from 'components/Breadcrumb'

import * as constants from '../../assets/constants'


type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const terms = [
  {
    title: '利用規約の適用範囲',
    body: (
      <p>
        当社が定める「利用規約」（以下「本規約」といいます。）は、当サービス内すべての利用について適用されます。当サービスを利用いただく際は、本規約すべてにご同意いただいたものとみなします。
      </p>
    ),
  },
  {
    title: '個人情報の取得・管理',
    body: (
      <p>
        個人情報の取得・管理は、別途定める当社のプライバシーポリシーの定めるところにより、適正かつ適法に取り扱うものとします。
      </p>
    ),
  },
  {
    title: 'ログイン名・パスワードの管理',
    body: (
      <p>
        当サービスでは「会員」を設け、会員は本人が登録時に任意に設定するログイン名とパスワードで本人の認証をするものとし、認証後の結果において、万一会員に不利益等が生じた場合でも当社は一切責任を負わないものとします。ログイン名・パスワードは会員の責任において設定・管理するものとし、ログイン名・パスワードは第三者に利用させたり、貸与、譲渡、売買等をすることはできないものとします。ログイン名・パスワードの管理不十分、使用上の過誤、また、利用後のログアウト操作忘却、第三者の使用等による損害の責任は会員が負うものとし、当社は一切責任を負わないものとします。
      </p>
    ),
  },
  {
    title: '禁止事項',
    body: (
      <>
        <p>会員は、当サービスの利用にあたり、以下の行為をしてはなりません。</p>
        <p>
          ・法令または公序良俗に違反する行為
          <br />
          ・犯罪行為に関連する行為
          <br />
          ・当社、当サービスの他の会員、または第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          <br />
          ・当社のサービスの運営を妨害するおそれのある行為
          <br />
          ・他の会員に関する個人情報等を収集または蓄積する行為
          <br />
          ・不正アクセスをし、またはこれを試みる行為
          <br />
          ・他の会員に成りすます行為
          <br />
          ・当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          <br />
          ・当社、当サービスの他の会員または第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為
          <br />
          ・以下の表現を含み、または含むと当社が判断する内容を当サービス上に投稿し、または送信する行為
          <br />
          　①過度に暴力的な表現
          <br />
          　②露骨な性的表現
          <br />
          　③人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現
          <br />
          　④自殺、自傷行為、薬物乱用を誘引または助長する表現
          <br />
          　⑤その他反社会的な内容を含み他人に不快感を与える表現
        </p>
        <p>
          ・以下を目的とし、または目的とすると当社が判断する行為
          <br />
          　①営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）
          <br />
          　②性行為やわいせつな行為を目的とする行為
          <br />
          　③面識のない異性との出会いや交際を目的とする行為
          <br />
          　④他の会員に対する嫌がらせや誹謗中傷を目的とする行為
          <br />
          　⑤当社、当サービスの他の会員、または第三者に不利益、損害または不快感を与えることを目的とする行為
          <br />
          　⑥その他当サービスが予定している利用目的と異なる目的で当サービスを利用する行為
        </p>
        <p>
          ・宗教活動または宗教団体への勧誘行為
          <br />
          ・その他、当社が不適切と判断する行為
        </p>
      </>
    ),
  },
  {
    title: 'サービスの変更・停止',
    body: (
      <p>
        当社は、当サービスの全てまたは一部のサービスをいつでも、変更または停止することができるものとします。サービス変更・停止の際、当社はできうる限りの方法で、会員に対してその旨を事前に告知するものとします。但し、天災などやむを得ぬ場合は事前に告知することなく、サービスを変更・停止できるものとします。
      </p>
    ),
  },
  {
    title: '著作権',
    body: (
      <>
        <p>
          会員は、自ら著作権等の知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報に関してのみ、当サービスを利用し、投稿ないしアップロードすることができるものとします。
        </p>
        <p>
          会員が当サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作権については、当該会員その他既存の権利者に留保されるものとします。ただし、当社は、当サービスを利用して投稿ないしアップロードされた文章、画像、映像等について、当サービスの改良、品質の向上、または不備の是正等ならびに当サービスの周知宣伝等に必要な範囲で無償で利用できるものとし、会員は、この利用に関して、著作者人格権を行使しないものとします。
        </p>
        <p>
          上記に定めるものを除き、当サービスおよび当サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し、会員は無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。
        </p>
      </>
    ),
  },
  {
    title: '利用制限および登録抹消',
    body: (
      <>
        <p>
          当社は、会員が以下のいずれかに該当する場合には、事前の通知なく、投稿データを削除し、会員に対して当サービスの全部もしくは一部の利用を制限しまたは会員としての登録を抹消することができるものとします。
        </p>
        <p>
          　①本規約のいずれかの条項に違反した場合
          <br />
          　②登録事項に虚偽の事実があることが判明した場合
          <br />
          　③決済手段として当該会員が届け出たクレジットカードが利用停止となった場合
          <br />
          　④料金等の支払債務の不履行があった場合
          <br />
          　⑤当社からの連絡に対し、一週間以内に返答がない場合
          <br />
          　⑥当サービスについて、最終の利用から当社が別途定める期間利用がない場合
          <br />
          　⑦その他、当社が当サービスの利用を適当でないと判断した場合
          <br />
        </p>
        <p>
          前項各号のいずれかに該当した場合、会員は、当然に当社に対する一切の債務について期限の利益を失い、その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
        </p>
        <p>当社は、本条に基づき当社が行った行為により会員に生じた損害について、一切の責任を負いません。</p>
      </>
    ),
  },
  {
    title: '退会',
    body: (
      <>
        <p>会員は、当社の定める退会手続により、当サービスから退会できるものとします。</p>
      </>
    ),
  },
  {
    title: '保証・免責事項',
    body: (
      <>
        <p>
          当サービスの一時的な中断・停止、終了、利用不能、本規約の変更その他当サービスに関して会員に損害が発生した場合でも、法令に定める場合または当社の故意または重大な過失を原因とする場合を除き、当社は会員に対して責任を負わず、かつ、一切の損害賠償を行いません。当社が何らかの理由により責任を負う場合であっても、当社は会員に生じた直接的な損害に限り賠償に応じるものとし、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については賠償する責任を負わないものとします。
        </p>
        <p>
          会員は、当サービスから得た情報に基づく行為および行為の結果について、当該行為を自己が行ったか否かを問わず、一切の責任を負うものとし、当社に対し、異議またはいかなる請求も行わないものとします。当社は、提供するサービスの内容について、瑕疵やバグがないことは保証しておりません。
        </p>
        <p>
          当社のサービスにおいて、広告などにより当社以外の第三者が独自に個人情報を収集する場合がございます。このような場合の個人情報の取り扱いにつきましても、当社では責任を負いかねますのでご注意ください。
        </p>
      </>
    ),
  },
  {
    title: '通知または連絡',
    body: (
      <>
        <p>
          会員が当社への連絡を希望される場合には、お問い合わせページまたは当社が指定するメールアドレスあてのメールによって行っていただくものとします。当社は、会員からのお問い合わせに対する回答を原則としてメールのみで行います。
        </p>
      </>
    ),
  },
  {
    title: '本規約等の変更',
    body: (
      <>
        <p>
          当社は、会員への予告なく、本規約等を変更することができるものとします。変更の内容については、当サービス上に変更の効力発生時期の１ヶ月前から表示して周知するものとします。会員は、変更内容に同意しない場合、当サービスから退会することができます。会員が変更の効力発生時期においても当サービスの利用を継続した場合、会員は変更を了承したものとみなします。
        </p>
      </>
    ),
  },
  {
    title: '損害賠償',
    body: (
      <>
        <p>
          会員が本規約に反した行為、もしくは不正・違法な行為により当社に損害を与えた場合は、当社は会員に対して当社に生じた損害について損害賠償の請求ができるものとします。また会員が本規約等に違反したと認められる場合、その他当社が必要と認める場合は、当社は当該会員に対しサービスの停止・IDや資格のはく奪を実施することができるものとします。また、これらの措置により会員に不利益・損害が発生した場合においても当社はその責任を負いません。
        </p>
      </>
    ),
  },
  {
    title: '準拠法・裁判管轄',
    body: (
      <>
        <p>
          本規約の解釈にあたっては、日本法を準拠法とします。
          <br />
          当サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
        </p>
      </>
    ),
  },
]

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const history = useHistory()

  const handleClick = () => {
    history.push(constants.PAGE_TERMS)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.BreadcrumbWrapper}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="旧 利用規約 " />
        </div>
        <div className="__container">
          <h3>Cocolabo利用規約</h3>
          <div className={classes.link} onClick={handleClick}>
            <span>最新の利用規約ページはこちら</span>
          </div>

          <div className="__content">
            <p>
              株式会社NEWONE（以下「当社」といいます。）は当社が運営するWEBサービス「Cocolabo」（以下「当サービス」といいます。）の利用について以下のとおり定めます。
            </p>

            {terms.map((data, index) => (
              <React.Fragment key={'policy-' + index}>
                <div className="__title">
                  <div className="__num">{index + 1}. </div>
                  {data.title}
                </div>
                {data.body}
              </React.Fragment>
            ))}

            <p>2021年8月1日</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
      backgroundColor: constants.COLOR_GRAY,

      '& h3': {
        textAlign: 'center',
        fontSize: '24px',
        margin: 0,
        padding: '70px 0 24px',
        [theme.breakpoints.down('sm')]: {
          padding: '48px 0 24px',
        },
      },

      '& .__container': {
        margin: '0 auto',
        paddingBottom: '32px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          margin: '0 16px',
          padding: '32px 16px',

          '& p': {
            marginBottom: '24px',
          },
          '& .__title': {
            display: 'flex',
            alignItems: 'center',

            '& .__num': {
              fontWeight: 'bold',
              marginRight: '8px',
              color: constants.COLOR_MAIN,
            },
          },
        },
      },
    },
    BreadcrumbWrapper: {
      margin: '0 auto',
      padding: '0 16px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
    link: {
      textAlign: 'right',
      margin: '0 16px 5px 0',
      fontWeight: 'bold',
      cursor: 'pointer',
      '& span': {
        borderBottom: '1px solid black',
      },
    },
  })

export default withStyles(useStyles)(Index)
