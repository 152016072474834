import React from 'react'

import { useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ScheduleIcon from '@material-ui/icons/Schedule'

import { DefaultButton } from 'pages/teams/components'

import { constants } from 'assets'
import { theme } from 'assets/theme'

type Props = {
  title: string
  recommendedTime: string
  description: string
  reflectionNextAt?: string
  buttonLabel?: string
  handleOpenTool?: () => void
  hasSpWrap?: boolean
  hasMsg?: boolean
  isMainColorBtn?: boolean
}

export const DescriptionTools: React.FC<Props> = ({
  title,
  recommendedTime,
  description,
  reflectionNextAt,
  buttonLabel,
  handleOpenTool,
  hasSpWrap = true,
  hasMsg = true,
  isMainColorBtn,
}) => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={`${classes.root} ${hasSpWrap ? 'spWrap' : ''}`}>
      {hasMsg && (
        <>
          <p className={classes.title}>{`${title}${isMdUp ? recommendedTime : ''}`}</p>
          {isMdUp ? <></> : <p className={classes.title}>{recommendedTime}</p>}
          <p className={classes.description}>{description}</p>
        </>
      )}

      {reflectionNextAt && (
        <div className={classes.reflectionScheduleBox}>
          <ScheduleIcon className={classes.scheduleIcon} />
          <span className={classes.reflectionNextAt}>次回開催</span>
          {reflectionNextAt}
        </div>
      )}
      {buttonLabel ? (
        <div className={classes.buttonWrapper}>
          <DefaultButton
            title={buttonLabel}
            color={isMainColorBtn ? 'team_main' : 'team_white'}
            onClick={handleOpenTool}
            style={{
              padding: '8px 16px',
              fontSize: 12,
              fontWeight: 'bold',
              minWidth: 140,
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',

    '&.spWrap': {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
        padding: '24px 16px',
      },
    },
  },

  title: {
    margin: 0,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontFamily: 'Hiragino Sans',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '22px',
  },

  description: {
    margin: '16px 0',
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontFamily: 'Hiragino Sans',
    fontSize: 12,
    lineHeight: '22px',
    whiteSpace: 'pre-wrap',
  },

  reflectionScheduleBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 12,
  },
  scheduleIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
    marginTop: -1.5,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
  },
  reflectionNextAt: {
    marginRight: 16,
    textAlign: 'center',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontWeight: 'bold',
  },

  buttonWrapper: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))
