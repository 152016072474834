import React, { useContext } from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'


import { useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'
import { OnbContext } from 'pages/onboarding/pages/PagesRoot'
import { TutorialProps } from 'pages/onboarding/pages/tutorial/Tutorial'
import { OnboardingPostType, OnboardingPost, OnboardingTeamMember } from 'utils/generated'

import { AvatarBox } from '../../timeline/components/list-item/components/AvatarBox'
import { CardContent } from '../../timeline/components/list-item/components/card-content-new'
import { DailyCheckBox } from '../../timeline/components/list-item/components/DailyCheckBox'
import { UserNamePersonal } from '../../timeline/components/list-item/components/UserNamePersonal'

import { PostTypePost } from './comment/PostTypePost'

import * as constants from 'assets/constants'

// TODO
// - delegate logics to parent, it's a bit expensive
// - change name. ListItem is hard to guess what

export type OwnProps = {
  post: OnboardingPost
  postUser: OnboardingTeamMember
  teamMembers: OnboardingTeamMember[]
  tutorial?: TutorialProps
  onComment?: () => void
  onAvatar?: () => void
}

type Props = OwnProps & WithStyles<typeof useStyles>

export const TimelinePostBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { createComment } = useOnboardingPostActions()

    return {
      teamId,
      teamMember,
      createComment,
    }
  },
}

const Index: React.FC<Props> = ({ classes, post, postUser, teamMembers, tutorial, onComment, onAvatar }) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  //handle appear contents
  const isAction: boolean = post.type === OnboardingPostType.Action && Boolean(post.action)
  const isDaily: boolean = post.type === OnboardingPostType.Daily && Boolean(post.daily)
  const isPost: boolean = post.type === OnboardingPostType.Post
  const isActionDone: boolean = post.type === OnboardingPostType.ActionDone
  const isAppearPostOwner = isAction || isDaily || isPost || isActionDone

  return (
    <div className={classes.container} onClick={onComment}>
      <div className={classes.mainContent}>
        {isAppearPostOwner ? <AvatarBox post={post} postUser={postUser} onAvatar={onAvatar} /> : <></>}
        <div className={classes.contentContainer}>
          <div className={classes.linkContainer}>
            {isAppearPostOwner ? <UserNamePersonal post={post} postUser={postUser} /> : <></>}
            {isAction && post.action ? ( // post.action is duplicated but it is used
              <CardContent
                item={{
                  id: post.action.id,
                  importance: post.action.importance,
                  mission: post.action.mission,
                  what: post.action.what,
                  why: post.action.why,
                  how: post.action.how,
                  category: tutorial ? 'TUTORIAL' : post.action.category,
                  period: new Date().getTime(),
                  missionValues: undefined,
                  anketValues: undefined,
                  point: undefined,
                  weekDate: undefined,
                  valuesId: undefined,
                }}
                label={post.label}
              />
            ) : (
              <></>
            )}
            {isDaily && post.daily ? <DailyCheckBox post={post} isSmUp={isSmUp} /> : <></>}
            {isPost || isActionDone ? (
              <div>
                <PostTypePost post={post} teamMembers={teamMembers} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      backgroundColor: constants.COLOR_WHITE,
      marginTop: 24,
      boxShadow: '0px 0px 0px #0000004',
    },
    mainContent: {
      padding: '16px',
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: '#F9F9F9',
      borderRadius: 8,
      cursor: 'pointer',
    },
    summaryContainer: {
      flex: 1,
    },
    contentContainer: {
      marginLeft: 16,
      flex: 1,
    },
    countButton: {
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },
    commentCount: {
      marginTop: 17,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
    },
    latestComment: {
      marginTop: 18,
    },

    // comments
    commentContainerLink: {
      marginTop: 9,
      display: 'inline-block',
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      cursor: 'pointer',
      '&:hover': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
  })

export default withStyles(useStyles)(Index)
