import React from 'react'

import { Modal, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Content } from './Content'

type Props = {
  open: boolean
  fullContent?: boolean
  onClose: () => void
}

export const ContentModal: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.root}>
          {props.fullContent ? (
            <div className={classes.slimContent}>{props.children}</div>
          ) : (
            <Content>{props.children}</Content>
          )}
        </Box>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    width: 'calc(100% - 64px)',
    maxWidth: 600,
    maxHeight: '85vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    overflow: 'auto',
    '@media (max-width: 632px)': {
      width: 'calc(100% - 32px)',
    },
  },
  slimContent: {
    width: '100%',
    maxHeight: '100vh',
    borderRadius: 8,
    padding: '12px 8px',
    backgroundColor: '#FFFFFF',
    overflow: 'auto',
  },
}))
