import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'

import { usePagingButton } from './usePagingButton'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    minWidth: '100px',
    padding: '0 22px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '40px',

    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },

    '&.prev': {
      marginRight: 'auto',
    },

    '&.next': {
      marginLeft: 'auto',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',

    '& svg': {
      width: '100%',
      height: '100%',
      verticalAlign: 'middle',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  color?: string
  textColor?: string
  fullWidth?: boolean
  onClick?: any
  submit?: boolean
  body: any
  disabled?: boolean
  category: 'tips' | 'faq' | 'search' | 'profile'
  action: 'preview' | 'next'
}

// -----------------------------
// Component
// -----------------------------
export const PagingButton = (props: IProps) => {
  const classes = useStyles()
  const paging = usePagingButton()

  return (
    <>
      {props.action === 'preview' && (
        <Button
          variant="contained"
          className={`${classes.button} prev`}
          onClick={() => paging.onPreviewButton(props.category)}
          type={props.submit ? 'submit' : 'button'}
          style={{
            backgroundColor: props.color || constants.COLOR_BLACK,
            color: props.textColor || constants.COLOR_WHITE,
            width: props.fullWidth ? '100%' : '',
          }}
          disabled={props.disabled}
        >
          <span className={classes.icon}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-left"
              className="svg-inline--fa fa-angle-left fa-w-8"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill="currentColor"
                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
              ></path>
            </svg>
          </span>
          {props.body}
        </Button>
      )}
      {props.action === 'next' && (
        <Button
          variant="contained"
          className={`${classes.button} next`}
          onClick={() => paging.onNextButton(props.category)}
          type={props.submit ? 'submit' : 'button'}
          style={{
            backgroundColor: props.color || constants.COLOR_BLACK,
            color: props.textColor || constants.COLOR_WHITE,
            width: props.fullWidth ? '100%' : '',
          }}
          disabled={props.disabled}
        >
          {props.body}
          <span className={classes.icon}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-right"
              className="svg-inline--fa fa-angle-right fa-w-8"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill="currentColor"
                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
              ></path>
            </svg>
          </span>
        </Button>
      )}
    </>
  )
}
