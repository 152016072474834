import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { GenericModal } from 'components/Modal'
import personSvg from 'pages/teams/pages/list/assets/person.svg'
import { TeamBuildingMemberConnection, TeamMemberRole } from 'utils/generated'

import { Button } from './Button'

import { constants } from 'assets'

type Props = {
  state: {
    startModal: IModal
    setStartModal: React.Dispatch<React.SetStateAction<IModal>>
  }
  handleStart: () => Promise<void>
}
export interface IModal {
  isOpen: boolean
  response?: TeamBuildingMemberConnection | undefined
}

export const StartModal: React.FC<Props> = ({
  state: {
    startModal: { isOpen, response },
    setStartModal,
  },
  handleStart,
}) => {
  const classes = useStyles()

  const handleModalClose = () => {
    setStartModal({ isOpen: false })
  }

  return (
    <GenericModal
      isModalOpen={isOpen}
      handleModalClose={handleModalClose}
      styleProps={{ width: '100%', maxWidth: 350, maxHeight: '90%' }}
    >
      <div className={classes.root}>
        <h2>キックオフを開始します</h2>
        <p className={classes.info}>
          参加できていない人がいる場合は
          <br />
          前の画面に戻ってはじめるのボタンを押してください。
        </p>

        <p className={classes.count}>参加者人数 : {response?.items ? `${response?.items.length}人` : '_'}</p>
        <div className={classes.memberList}>
          <div className={classes.scrollOuter}>
            <div className={classes.scrollInner}>
              {response &&
                response.items.map((kickoff) => (
                  <div key={kickoff.id} className={classes.member}>
                    <img src={personSvg} alt="person icon" />
                    <div>
                      <div className={classes.name}>
                        {kickoff.teamMember?.fullName ? `${kickoff.teamMember?.fullName}さん` : '_'}
                      </div>
                      <div className={classes.role}>
                        {kickoff.teamMember?.role.includes(TeamMemberRole.Leader) ? 'ファシリテーター' : 'メンバー'}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className={classes.buttonBox}>
          <Button buttonType="secondary" type="button" onClick={handleModalClose} style={{ minWidth: 84, width: 84 }}>
            戻る
          </Button>
          <Button type="button" full onClick={handleStart}>
            確認
          </Button>
        </div>
      </div>
    </GenericModal>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '24px 16px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      '& h2': {
        margin: 0,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    info: {
      margin: '13px 0 0',
      lineHeight: 1.6,
    },
    count: {
      margin: '15px 0 0',
    },
    memberList: {
      marginTop: 13,
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
      overflow: 'hidden',
    },
    scrollOuter: {
      height: 277,
      overflow: 'auto',
      overscrollBehavior: 'contain',
    },
    scrollInner: {
      padding: 16,
    },
    member: {
      display: 'flex',
      gap: 8,
      '& img': {
        width: 24,
        verticalAlign: 'top',
      },
      '&:not(:last-child)': {
        marginBottom: 22,
      },
    },
    name: {
      fontWeight: 'bold',
    },
    role: {
      color: constants.COLOR_GRAY3,
      fontSize: 10,
    },
    buttonBox: {
      display: 'flex',
      gap: 8,
      marginTop: 24,
    },
  }),
  { name: 'StartModal' }
)
