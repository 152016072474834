import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { PagingButton } from 'pages/hint/_shared/components/buttons/pagingButton/Index'
import { SearchButton } from 'pages/hint/_shared/components/buttons/searchButton/Index'
import { useCategory } from 'pages/hint/_shared/components/categories/useCategory'
import { Hero } from 'pages/hint/_shared/components/hero/Index'
import { Loading } from 'pages/hint/_shared/components/loading/Index'
import { Search } from 'pages/hint/_shared/components/search/Index'
import { SearchPanel } from 'pages/hint/_shared/components/searchPanel/Index'
import { useSearchPanel } from 'pages/hint/_shared/components/searchPanel/useSearchPanel'
import { Slide } from 'pages/hint/_shared/components/slide/Index'
import { Tab } from 'pages/hint/_shared/components/tabs/tab/Index'
import { TabPanel } from 'pages/hint/_shared/components/tabs/tabPanel/Index'
import { Tabs } from 'pages/hint/_shared/components/tabs/tabs/Index'
import { useTabs } from 'pages/hint/_shared/components/tabs/tabs/useTabs'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useLink } from 'pages/hint/_shared/hooks/useLink'
import { useLoading } from 'pages/hint/_shared/hooks/useLoading'
import { Column } from 'pages/hint/_shared/layouts/column/Index'
import { Main } from 'pages/hint/_shared/layouts/main/Index'
import { Pager } from 'pages/hint/_shared/layouts/pager/Index'
import { Sidebar } from 'pages/hint/_shared/layouts/sidebar/Index'



import { Modal } from '../_shared/components/modal/modal/Index'

import { Articles } from './components/articles/Index'
import { useTipsIndex } from './hooks/useTipsIndex'
import { useTipsNextToken } from './hooks/useTipsNextToken'
import { Wrapper } from './layouts/wrapper/Index'

import * as constants from 'assets/constants'
import * as pages from 'assets/pages'

// -----------------------------
// Component
// -----------------------------
const Index = () => {
  window.scrollTo(0, 0)
  const link = useLink()
  const loading = useLoading()
  const tipsNextToken = useTipsNextToken()
  const location = useLocation()
  const tabs = useTabs('Tips')
  const category = useCategory()
  const searchPanel = useSearchPanel()
  const context = React.useContext(hintContext)
  const tipsIndex = useTipsIndex()

  const hintListSelectors = useSelector((state: RootStateType) => state.hint.hintList)

  React.useEffect(() => {
    if (link.isReload()) {
      window.location.href = pages.HINT_TIPS_HOME
    }
  }, [link])

  React.useEffect(() => {
    loading.setLoad(true)
    tipsNextToken.getTokenWithFetch()
    loading.onDoneLoaded(false, 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {/** Modal */}
      {context.isShowModal && (
        <Slide in={context.isShowModal} direction={'up'}>
          <Modal />
        </Slide>
      )}

      {/** Hero */}
      <Hero heading="Hint" subHeading="管理職向け、情報提供メディア" isContent={true} />

      {/** Wrapper */}
      <Wrapper>
        {/** Main */}
        <Main>
          <Column>
            <Box display="flex" justifyContent="flex-end" mb={{ xs: '24px', lg: 0 }}>
              <SearchButton onClick={searchPanel.onOpen} />
              <SearchPanel
                presenter={category}
                open={searchPanel.getOpen()}
                onClose={searchPanel.onClose}
                onOpen={searchPanel.onOpen}
              />
            </Box>

            <Tabs>
              <Tab icon="Tips" label="Tips" onClick={tabs.onChangePanelHandler} isActive={true} />
              <Tab icon="お悩み掲示板" label="お悩み掲示板" onClick={tabs.onChangePanelHandler} isActive={false} />
            </Tabs>

            <TabPanel>
              <Loading isLoad={loading.isLoad()}>
                {hintListSelectors && <Articles datas={hintListSelectors} />}

                {tipsIndex.isShowPager() && (
                  <Pager>
                    {tipsIndex.isHome() && (
                      <PagingButton
                        category={'tips'}
                        color={constants.COLOR_GREEN_DARK}
                        body={'前のページ'}
                        action={'preview'}
                      />
                    )}
                    {hintListSelectors.nextToken && (
                      <PagingButton
                        category={'tips'}
                        color={constants.COLOR_GREEN_DARK}
                        body={'次のページ'}
                        action={'next'}
                      />
                    )}
                  </Pager>
                )}
              </Loading>
            </TabPanel>
          </Column>
        </Main>

        {/** Sidebar */}
        <Sidebar>
          <Search presenter={category} />
        </Sidebar>
      </Wrapper>
    </>
  )
}

export default Index
