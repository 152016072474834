import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { Ghost } from 'utils/generated'

import info from '../../assets/svg/info.svg'
import { UsefulCard, Font, Flexbox } from '../atoms'
import { GhostMageImg } from '../modules'

import { LoadingIndicator } from './LoadingIndicator'

type Props = {
  ghostId: string
}

export const GhostDetailBox: React.FC<Props> = ({ ghostId }) => {
  const isMdUp = useCustomMediaQuery('up', 'md')
  const classes = useStyles(isMdUp)

  const { ghosts, setDetail, setModal, setDirectClose } = React.useContext(GhostContext)

  const ghost = ghosts.find((g) => g.id === ghostId)

  const openGhostDetailModal = (ghost: Ghost) => {
    setDetail(ghost.id)
    setModal('detail')
    setDirectClose(true)
  }

  if (!ghost) return <LoadingIndicator snapshot={{ vars: { ghost } }} />

  return (
    <div className={classes.root}>
      <UsefulCard backgroundColor="white" borderRadius={8}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={4} className={classes.flexItem}>
            <GhostMageImg ghostId={ghost.id} />
          </Grid>
          <Grid>
            <Flexbox
              ownStyles={{
                padding: 0,
                justifyContent: 'flex-start',
                gap: '24px',
              }}
            >
              <div>
                <Flexbox
                  ownStyles={{
                    gap: '24px',
                  }}
                >
                  <div>
                    <Font fontSize={10} ownStyles={{ color: constGhost.COLOR_GRAY2 }}>
                      FILE NO.
                      {ghost.fileNo}
                    </Font>
                    <Font
                      fontSize={isMdUp ? 16 : 14}
                      bold
                      ownStyles={{ color: constGhost.COLOR_MAIN_NEW }}
                      testId={ghost.name}
                    >
                      {ghost.name}
                    </Font>
                  </div>
                  <NoStyleButton className={classes.button} type="button" onClick={() => openGhostDetailModal(ghost)}>
                    <img loading="lazy" src={info} alt={'ghost info'} width="16" height="16" />
                    <span className={classes.buttonLabel}>詳細</span>
                  </NoStyleButton>
                </Flexbox>
              </div>
            </Flexbox>
          </Grid>
        </Grid>
      </UsefulCard>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: (isMdUp) => ({
      marginTop: isMdUp ? 24 : 16,
    }),
    gridContainer: (isMdUp) => ({
      padding: isMdUp ? 40 : 16,
      gap: 24,
      flexDirection: isMdUp ? 'row' : 'column-reverse',
    }),
    button: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 4,
      paddingRight: 4,
    },
    buttonLabel: {
      fontSize: 10,
      color: constGhost.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    flexItem: {
      flexBasis: 'auto',
    },
  }),
  { name: 'GhostDetailBox' }
)
