import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { IKarte } from '../interface'

export const QueryGetKarteLeaer = `query GetKarteLeader($leaderNumber: String!) {
  getKarteLeader(leaderNumber: $leaderNumber) {
    id
    leaderId
    data
    name
    leaderNumber
    leaderData
    createdAt
    updatedAt
  }
}`

export const karteLeaderGet = async (variables: { leaderNumber: string }) => {
  const response: any = await API.graphql({
    query: QueryGetKarteLeaer,
    variables,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getKarteLeader as IKarte
}
