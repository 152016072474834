import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import { Addresses, QueryGetAddressByZipcodeArgs } from 'utils/generated'

export const queryGetAddressByZipcode = async (args: QueryGetAddressByZipcodeArgs): Promise<Addresses | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getAddressByZipcode($input: AddressInput!) {
        getAddressByZipcode(input: $input) {
          zipcode
          prefcode
          address1
          address2
          address3
          kana1
          kana2
          kana3
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getAddressByZipcode: Addresses }>
  return response.data?.getAddressByZipcode
}
