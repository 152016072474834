import { constants } from 'assets'

interface CSSProperties {
  [index: string]: string | number
}

const cardFooterButtonStlyes: CSSProperties = {
  minWidth: 190,
  width: 190,
  height: 54,
  borderRadius: 50,
  border: `2px solid ${constants.COLOR_MAIN_NEW}`,
  boxShadow: 'none',
} as const

const disabledBorder = `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`

const inputLabelStyles: CSSProperties = {
  marginBottom: 8,
  color: constants.COLOR_TEAMBUILDING_TEXT,
  fontWeight: 600,
} as const

export const teamConstants = {
  cardFooterButtonStlyes,
  inputLabelStyles,
  disabledBorder,
} as const
