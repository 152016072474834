import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { UseFormMethods } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { usePopover } from 'components/popover/hooks/popover'
import { Popover } from 'components/popover/Popover'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { HooksContext } from 'pages/teams/contexts'
import { IndexedTeam } from 'pages/teams/pages/list'
import { SortedInfoKey, SortedInfoSort } from 'pages/teams/pages/list/components/hooks/sortTeams'
import { getTeamStatusUsageLabel } from 'utils/labels'

import { FilterProps } from '../Filter'
import { useTeamDesign } from '../hooks/teamStyle'

import { CountBatch } from './CountBatch'

import { useHistory } from 'assets'
import { Pages, constants } from 'assets'

type Props = {
  team: IndexedTeam
  statusChangeList: boolean
  filter: FilterProps
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  watchedTeamIds: string[] | string | boolean

  sortKey: {
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null
}

export const GeneralListBodyRow: React.FC<Props> = ({
  team,
  statusChangeList,
  filter,
  register,
  setValue,
  watchedTeamIds,
  sortKey,
}) => {
  const classes = useStyles()
  const { route } = useHistory()
  const isMdUp = useCustomMediaQuery('up', 'md')
  const { getTeamPlanLabels } = useTeamDesign()
  const { isOpenPopover, anchorEl, popoverId, handleOpenPopover, handleClosePopover } =
    usePopover('reserved-dates-popover')

  const [isHovered, setIsHovered] = React.useState(false)
  const { currentTeamMember, currentTeam } = React.useContext(HooksContext)
  const currentUserId = currentTeamMember?.userId
  const currentTeamId = currentTeamMember?.teamId

  const invitedMemberInfo = React.useMemo(() => {
    const { countInvitedMember, countMember, countReservedMember } = team
    if (!countInvitedMember) return ''

    const denominator = countMember - (countReservedMember || 0)
    const msg =
      countInvitedMember === denominator
        ? `${countInvitedMember}人未登録`
        : `${countInvitedMember}/${denominator}人未登録`

    return msg
  }, [team])

  const latestDate = (dateList: string[]) => {
    const latest = dateList.reduce((prev, current) => (new Date(prev) < new Date(current) ? prev : current))

    return latest
  }

  const invitationDates = team?.memberInvitationDateTimes
  const [reservedDay, reservedTime] = invitationDates?.length ? latestDate(invitationDates).split(' ') : []

  React.useEffect(() => {
    setValue('teamIds', [])
  }, [filter.isNonUse, setValue])

  const [checked, setChecked] = React.useState(false)
  const isChecked = React.useMemo(() => {
    if (Array.isArray(watchedTeamIds)) {
      return watchedTeamIds.includes(team.id)
    }
    return watchedTeamIds === team.id
  }, [watchedTeamIds, team.id])

  React.useEffect(() => {
    setChecked(isChecked)
  }, [isChecked, sortKey])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <>
      <TableRow
        className={`${classes.bodyRow} ${statusChangeList ? classes.cursorAuto : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* No. */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={`${classes.bodyCell} ${classes.textCenter} ${classes.stickyCellCol1} ${
            isHovered ? classes.activeHoverCell : ''
          }`}
        >
          {team.num}
        </TableCell>
        {/* チーム名 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={`${classes.bodyCell} ${classes.stickyCellCol2} ${isHovered ? classes.activeHoverCell : ''} ${
            statusChangeList ? classes.statusTeamCell : ''
          } ${team.id === currentTeam?.id ? classes.mainTextColor : ''}`}
        >
          {statusChangeList && (
            <>
              <div className={classes.checkbox}>
                <input
                  type="checkbox"
                  id={team.id}
                  value={team.id}
                  name="teamIds"
                  ref={register}
                  checked={checked || false}
                  onChange={handleCheckboxChange}
                />
              </div>
              <label className={classes.label} htmlFor={team.id}>
                {team.name}
              </label>
            </>
          )}
          {!statusChangeList && team.name}
        </TableCell>
        {/* チーム管理者 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {team.ownerFullName}
        </TableCell>
        {/* リーダー名 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {team?.leaderFullNames?.[0] ?? '_'}
        </TableCell>
        {/* メンバー数 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {team.countMember.toLocaleString()}
        </TableCell>
        {/* 利用プラン */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {getTeamPlanLabels(team.plans) || '_'}
        </TableCell>
        {/* 月額 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {team.cost.toLocaleString()}
        </TableCell>
        {/* チームのステータス */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          <span className={`${classes.statusLabel} ${team.statusUsage}`}>
            {getTeamStatusUsageLabel(team.statusUsage)}
          </span>
        </TableCell>
        {/* メンバーの招待状況 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={classes.bodyCell}
        >
          {team?.countInvitedMember ? (
            <div className={classes.invitedBox}>
              <span className={classes.invitedLabel}>招待中</span>
              <p className={classes.invitedInfo}>{invitedMemberInfo}</p>
            </div>
          ) : (
            '_'
          )}
        </TableCell>
        {/* 予約招待状況 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={`${classes.bodyCell} ${classes.reservedCell}`}
        >
          {invitationDates && invitationDates.length > 0 ? (
            <>
              <NoStyleButton
                className={`${classes.reservedBox} ${invitationDates.length <= 1 ? 'inactive' : ''}`}
                aria-owns={isOpenPopover ? popoverId : undefined}
                aria-haspopup="true"
                onClick={invitationDates.length > 1 ? (isMdUp ? undefined : handleOpenPopover) : undefined}
                onMouseEnter={invitationDates.length > 1 ? (isMdUp ? handleOpenPopover : undefined) : undefined}
                onMouseLeave={invitationDates.length > 1 ? (isMdUp ? handleClosePopover : undefined) : undefined}
              >
                <span className={classes.reservedLabel}>予約招待</span>
                <span className={classes.reservedInfo}>{`${reservedDay}\n${reservedTime}に配信予定`}</span>
              </NoStyleButton>
              <CountBatch className={classes.countBatch} count={invitationDates.length} />
            </>
          ) : (
            '_'
          )}
        </TableCell>
        {/* 現在のユーザーがチームの管理者またはリーダーである場合にのみ編集リンクを表示する */}
        {currentUserId && [team.ownerUserId, ...team.leaderUserIds].includes(currentUserId) && (
          <TableCell
            role="link"
            // チーム編集画面に遷移 teamId は現在アクティブなチームの ID, editTeamId は編集対象のチームの ID
            onClick={() => route.push(Pages.TeamsEdit, { teamId: currentTeamId, editTeamId: team.id })}
            className={classes.editCell}
          >
            編集
          </TableCell>
        )}
      </TableRow>

      <Popover
        className={classes.popover}
        popover={{
          isOpenPopover,
          anchorEl,
          popoverId,
          handleClosePopover,
          anchorOrigin: { horizontal: 'right' },
          transformOrigin: { horizontal: 'right' },
        }}
      >
        {invitationDates?.sort().map((date, index) => (
          <p className={classes.invitationDate} key={date}>
            <span>{index + 1}</span>
            {date}に配信予定
          </p>
        ))}
      </Popover>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    bodyRow: {
      padding: 16,
      backgroundColor: constants.COLOR_WHITE,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_GRAY_LIGHT7,
          cursor: 'pointer',
        },
      },
    },
    cursorAuto: {
      '@media (hover: hover)': {
        '&:hover': {
          cursor: 'auto',
        },
      },
    },
    bodyCell: {
      fontSize: 12,
      color: constants.TEXT_GRAY_DARK,
      whiteSpace: 'nowrap',
      border: 'none',
      maxWidth: 600,
      height: 50,
      padding: '0 16px',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    textCenter: {
      textAlign: 'center',
    },
    stickyCellCol1: {
      backgroundColor: constants.COLOR_WHITE,
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 0,
      },
    },
    stickyCellCol2: {
      borderRight: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      backgroundColor: constants.COLOR_WHITE,
      maxWidth: 250,
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 80,
      },
    },
    activeHoverCell: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: constants.COLOR_GRAY_LIGHT7,
      },
    },
    statusTeamCell: {
      display: 'flex',
      alignItems: 'baseline',
    },
    mainTextColor: {
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
    },
    statusLabel: {
      display: 'grid',
      placeItems: 'center',
      width: 46,
      height: 20,
      fontSize: 10,
      fontWeight: 'bold',
      borderRadius: 4,
      '&.IN_USE': {
        color: '#fff',
        background: constants.COLOR_MAIN_NEW,
      },
      '&.ON_BREAK': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
      },
      '&.NON_USE': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        background: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
      },
    },
    invitedBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
    },
    invitedLabel: {
      display: 'grid',
      placeItems: 'center',
      width: 46,
      height: 20,
      fontSize: 10,
      fontWeight: 'bold',
      borderRadius: 4,
      color: '#fff',
      background: constants.COLOR_RED4,
    },
    invitedInfo: {
      margin: 0,
      fontSize: 10,
    },
    reservedCell: {
      position: 'relative',
      padding: 0,
      minWidth: 175,
    },
    reservedBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      padding: '0 16px',
      width: '100%',
      height: '100%',
      color: constants.TEXT_GRAY_DARK,
      '&.inactive': {
        cursor: 'default',
      },
    },
    reservedLabel: {
      display: 'grid',
      placeItems: 'center',
      width: 56,
      height: 20,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
      fontWeight: 'bold',
      borderRadius: 4,
      background: constants.COLOR_TEAMBUILDING_YERROW,
    },
    reservedInfo: {
      margin: 0,
      fontSize: 10,
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
    },
    countBatch: {
      position: 'absolute',
      top: 0,
      right: 0,
      pointerEvents: 'none', // batch 上でもマウス event を有効化する。
    },
    editCell: {
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
      border: 'none',
      cursor: 'pointer',
    },
    popover: {
      '& .MuiPopover-paper': {
        marginTop: -32,
      },
    },
    invitationDate: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      margin: 0,
      paddingRight: 4,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
      lineHeight: 1,
      '&:not(:first-child)': {
        marginTop: 8,
        paddingTop: 8,
        borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
      '& span': {
        display: 'grid',
        placeItems: 'center',
        width: 12,
        height: 12,
        border: `1px solid ${constants.TEXT_GRAY_DARK}`,
        borderRadius: 6,
      },
    },
    checkbox: {
      display: 'inline-block',
      width: 16,
      height: 16,
      '& input[type="checkbox"]': {
        position: 'relative',
        width: 16,
        height: 16,
        border: `1px solid ${constants.COLOR_MAIN_NEW}`,
        borderRadius: 10,
        margin: 0,
        top: 4,
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '@media (hover: hover)': {
        '& input[type="checkbox"]:hover': {
          cursor: 'pointer',
        },
      },
      '& input[type="checkbox"]:checked': {
        border: 'none',
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '& input[type="checkbox"]:checked::before': {
        content: '""',
        position: 'absolute',
        top: 3,
        left: 6,
        width: 5,
        height: 8,
        borderRight: '2px solid #fff',
        borderBottom: '2px solid #fff',
        transform: 'rotate(45deg)',
      },
    },
    label: {
      display: 'inline-block',
      padding: '16px 8px',
      fontSize: 12,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      '@media (hover: hover)': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'GeneralListBodyRow' }
)
