import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router'

import { queryGetMasters } from 'pages/teams/hooks/graphql'
import { Master, MasterType, TeamBuilding } from 'utils/generated'

import {
  Button,
  ButtonContainer,
  FormLabel,
  InputCard,
  QuestionContainer,
  SelectItems,
  Timer,
  Waiting,
} from '../../components'
import { SelectItem } from '../../components/SelectItems'

import { TEAM_BUILDING_PRESEN_TIME } from 'assets/constants'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q3Vote1: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [keywordItems, setKeywordItems] = React.useState<SelectItem[]>([])

  // TODO: ユーザーの回答をもとにアイテムを生成する
  const getMasters = async () => {
    const response = await queryGetMasters({ type: MasterType.TeamBuildingQ4Keyword, limit: 5 })
    const items: Master[] = response?.items || []
    const initialItems = items.reduce((items: SelectItem[], i) => {
      if (i.title) {
        items.push({
          name: i.id,
          label: i.title,
          value: false,
        })
      }
      return items
    }, [])

    setKeywordItems(initialItems)
  }

  React.useEffect(() => {
    getMasters()
  }, [])

  const handleOnClickItem = (name: string, value: boolean) => {
    let count = 0
    if (value) {
      for (const i of keywordItems) {
        if (i.value) {
          count++
        }
      }
    }

    if (count < 3) {
      const updated = keywordItems.reduce((items: SelectItem[], i) => {
        if (i.name === name) {
          items.push({
            ...i,
            value,
          })
        } else {
          items.push(i)
        }
        return items
      }, [])
      setKeywordItems(updated)
    }
  }

  const [complete, setComplete] = React.useState(false)

  const handleOnNext = () => {
    setComplete(true)
  }

  return (
    <>
      <QuestionContainer
        active={3}
        // title={QuestionTitles.Q3}
        // subTitle={
        //   <div>
        //     <span>
        //       チーム最後のタイミングで、自分たちはどのようなチームであれば、{'\n'}”このチームで良かった”と感じますか？
        //       {'\n'}
        //     </span>
        //     <span style={{ color: 'red' }}>※チームの業務目標については今回は扱いませんのでご注意ください。</span>
        //   </div>
        // }
      >
        {!complete ? (
          <>
            <div className={classes.cardContainer}>
              <InputCard>
                <FormLabel>良いと感じる3つに”いいね“を押しましょう。</FormLabel>
                <SelectItems items={keywordItems} icon={'heart'} onClick={handleOnClickItem} />
              </InputCard>
            </div>

            <Timer time={TEAM_BUILDING_PRESEN_TIME} container />

            <ButtonContainer
              fixed
              buttons={[
                <Button
                  key="q3-vote-1-1"
                  onClick={handleOnNext}
                  disabled={keywordItems.filter((i) => i.value).length !== 3}
                >
                  次へ
                </Button>,
              ]}
            />
          </>
        ) : (
          <Waiting who={'leader'} progress={{ child: 5, parent: 10 }} onNext={handleOnNext} />
        )}
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  cardContainer: {
    width: '100%',
    marginBottom: 40,
  },
}))
