import * as React from 'react'

import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'

import SpeechBubble from './SpeechBubble'

interface IOwnProps {
  bubbles: React.ReactNode[]
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, bubbles }) => {
  return (
    <div className={classes.main}>
      <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
      <div>
        {bubbles.map((bubble) => (
          <SpeechBubble key={`bubble-${new Date().toString()}-${Math.random()}`}>{bubble}</SpeechBubble>
        ))}
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      display: 'flex',
      padding: '16px 16px 8px',

      '& img': {
        width: 50,
        height: 50,
        marginRight: 16,
      },
      '& strong': {
        color: constants.COLOR_MAIN,
        fontWeight: 'bold',
      },
    },
  })

export default withStyles(useStyles)(Index)
