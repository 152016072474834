import { reducerWithInitialState } from 'typescript-fsa-reducers'

import action from './action'

export interface State {
  isKarteNotFound?: boolean
  isLoading: boolean
  error: any
}

const INITIAL_STATE: State = {
  isLoading: false,
  error: null,
}

export default reducerWithInitialState(INITIAL_STATE)
  .case(action.fetch.started, (state) => ({
    ...state,
    isKarteNotFound: undefined,
    isLoading: true,
  }))
  .case(action.fetch.done, (state, payload) => ({
    ...state,
    isKarteNotFound: !payload.result || payload.result.name,
    isLoading: false,
  }))
  .case(action.fetch.failed, (state, payload) => ({
    ...state,
    isKarteNotFound: true,
    isLoading: false,
    error: payload.error,
  }))

  .case(action.resetError, (state, payload) => ({
    ...state,
    error: null,
    isKarteNotFound: undefined,
  }))
