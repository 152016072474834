import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetSetupItems } from './graphql'

const getSetupItems = async (setupStartedAt: string) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetSetupItems, {
      setupStartedAt,
    })
  )
  const { getSetupItems } = response.data

  return getSetupItems
}

export default getSetupItems
