import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

type Props = {
  children: React.ReactNode
}

export const ContentsWrapper: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      background: constants.COLOR_GRAY_LIGHT3,
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.HATARAKU_FONT_FAMILY,
    },
  }),
  { name: 'ContentsWrapper' }
)
