import * as React from 'react'
import { Link } from 'react-router-dom'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'


// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: constants.COLOR_GREEN,

    [theme.breakpoints.down('md')]: {
      height: '244px',
    },
  },

  profile: {
    height: '80px',
    [theme.breakpoints.down('md')]: {
      height: '18px',
    },
  },

  container: {
    position: 'relative',
    maxWidth: '1080px',
    height: '100%',
    margin: '0 auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 30px)',

    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'calc(100% - 16px) bottom',
    },
  },

  badge: {
    position: 'absolute',
    bottom: '-34px',
    left: '-6px',

    [theme.breakpoints.down('md')]: {
      left: '10px',
    },

    '& img': {
      display: 'block',
    },
  },

  headingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: constants.COLOR_WHITE,
    height: '100%',

    [theme.breakpoints.down('md')]: {
      paddingRight: '16px',
      paddingLeft: '16px',
    },
  },

  heading: {
    margin: 0,
    fontSize: '30px',
    textShadow: 'rgba(0, 0, 0, .1) 0 2px 1px',
    marginTop: '30px',

    [theme.breakpoints.down('md')]: {
      fontSize: '27px',
    },
  },

  subHeading: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '18px',
    fontWeight: 400,
    textShadow: 'rgba(0, 0, 0, .1) 0 2px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  heading?: string
  subHeading?: string
  route?: string
  isContent?: boolean
}

// -----------------------------
// Component
// -----------------------------
export const Hero = (props: IProps) => {
  const classes = useStyles()
  const isProfile = !props.isContent && props.route === 'profile'
  const isContent = props.isContent

  return (
    <>
      {isContent && (
        <Box className={classes.root} style={{ height: props.isContent ? '160px' : '80px' }}>
          {props.isContent && (
            <Box className={classes.container} style={{ backgroundImage: `url(/assets/svg/hint/hero.svg)` }}>
              <Box className={classes.headingWrapper}>
                <h2 className={classes.heading}>{props.heading}</h2>
                <p className={classes.subHeading}>{props.subHeading}</p>
              </Box>
              <Link className={classes.badge} to="/hint/tips/8a8bc3c2-3be6-4218-93ef-1a12cd51dad0">
                <img src="/assets/svg/hint/badge.svg" alt="使い方はこちら" width="90" height="90" />
              </Link>
            </Box>
          )}
        </Box>
      )}

      {!isContent && isProfile && (
        <Box className={`${classes.root} ${classes.profile}`}>
          {props.isContent && (
            <Box className={classes.container} style={{ backgroundImage: `url(/assets/svg/hint/hero.svg)` }}>
              <Box className={classes.headingWrapper}>
                <h2 className={classes.heading}>{props.heading}</h2>
                <p className={classes.subHeading}>{props.subHeading}</p>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
