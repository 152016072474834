import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  color?: string
  fontWeight?: number | 'normal' | 'lighter' | 'bolder'
}

export const SpanBold: React.FC<Props> = (props) => (
  <span
    style={{
      color: props.color ?? constGhost.COLOR_MAIN_NEW,
      fontWeight: props.fontWeight ?? 'bold',
    }}
  >
    {props.children}
  </span>
)
