import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/styles'

import { constants } from 'assets'

type Props = {
  path: string
  text: string
  containerStyle?: React.CSSProperties
  linkStyle?: React.CSSProperties
}

export const NavigationTextLink: React.FC<Props> = ({ path, text, containerStyle, linkStyle }) => {
  const classes = useStyles()

  return (
    <div className={classes.linkContainer} style={containerStyle}>
      <Link to={path} style={linkStyle}>
        {text}
      </Link>
    </div>
  )
}

const useStyles = makeStyles(
  {
    linkContainer: {
      marginTop: 16,

      '& a': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        fontSize: 14,
        fontWeight: 'bold',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  { name: 'TeamsNewPage' }
)
