import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { queryGetMasters } from 'pages/teams/hooks/graphql'
import {
  TeamBuilding,
  MasterType,
  TeamBuildingStatus,
  TeamBuildingMember,
  TeamBuildingV1ResultQ4,
  TeamBuildingV1ResultQ4Item,
  TeamBuildingV1ResultQ4Ranking,
} from 'utils/generated'

import {
  InputCard,
  QuestionContainer,
  Button,
  FormLabel,
  ButtonContainer,
  WaitingForLeader,
  Ranking,
  SelectItems,
  RankingIcon,
  SideBar,
} from '../../components'
import { RankingItem } from '../../components/Ranking'
import { SelectItem } from '../../components/SelectItems'

import { EditRanking } from './Q4Adjust/components/EditRanking'

import { constants } from 'assets'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

const MIN_COUNT = 3
const MAX_COUNT = 5

interface RankingItemWithRanking extends TeamBuildingV1ResultQ4Ranking {
  ranking: number
}

export const Q4Adjust: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const [isEditing, setIsEditing] = React.useState(false)
  const [rankings, setRankings] = React.useState<TeamBuildingV1ResultQ4Ranking[]>([])

  const handleOnSave = () => {
    if (teamBuilding && teamBuilding.resultQ4 && teamBuilding.id) {
      const resultQ4 = JSON.parse(teamBuilding.resultQ4) as TeamBuildingV1ResultQ4
      updateTeamBuilding({
        id: teamBuilding.id,
        resultQ4: JSON.stringify({
          ...resultQ4,
          rankings,
        }),
      })
      setIsEditing(false)
    }
    setIsEditing(false)
  }
  const handleOpen = () => {
    setIsEditing(true)
  }

  const handleOnClose = () => {
    setIsEditing(false)
  }

  React.useEffect(() => {
    if (teamBuilding && teamBuildingMembers) {
      if (!teamBuilding.resultQ4 && teamBuildingMembers.length > 0) {
        extractResult(teamBuildingMembers).then((r) =>
          updateTeamBuilding({
            id: props.match.params.teamId,
            resultQ4: JSON.stringify({
              version: 'v1',
              rankings: r,
              items: [],
            }),
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding, teamBuildingMembers])

  React.useEffect(() => {
    if (teamBuilding && teamBuilding.resultQ4) {
      const resultQ4 = JSON.parse(teamBuilding.resultQ4) as TeamBuildingV1ResultQ4
      setRankings(resultQ4.rankings)
    }
  }, [teamBuilding])

  const onSubmit = async () => {
    if (teamBuilding && teamBuilding.resultQ4) {
      const resultQ4 = JSON.parse(teamBuilding.resultQ4) as TeamBuildingV1ResultQ4
      const items: TeamBuildingV1ResultQ4Item[] = []
      keywordItems
        .filter((i) => i.value)
        .forEach((i) => items.push({ id: i.name, answer: i.label.replace(/\(.+?\)/g, '') }))

      await updateTeamBuilding({
        id: props.match.params.teamId,
        status: TeamBuildingStatus.Q4End,
        resultQ4: JSON.stringify({
          ...resultQ4,
          items,
        }),
        timeQ4End: new Date().toISOString(),
      })
    }
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [isEditing])

  React.useEffect(() => {
    if (teamBuilding && teamBuilding.resultQ4) {
      const initialItems: SelectItem[] = []
      const resultQ4 = JSON.parse(teamBuilding.resultQ4) as TeamBuildingV1ResultQ4

      const sorts = resultQ4.rankings.sort((a, b) => {
        const x = a.count
        const y = b.count
        if (x > y) {
          return -1
        }
        if (x < y) {
          return 1
        }
        return 0
      })

      const rankings: RankingItemWithRanking[] = []

      let count = sorts[0].count
      let ranking = 1
      sorts.forEach((cur) => {
        if (count !== cur.count) {
          ranking += 1
        }

        rankings.push({
          ...cur,
          ranking,
        })
        count = cur.count
      })

      for (const ranking of rankings) {
        initialItems.push({
          prefixIcon: <RankingIcon ranking={ranking.ranking} />,
          name: ranking.id,
          label: `${ranking.answer}(${ranking.count}票)`,
          value: false,
        })
      }

      setKeywordItems(initialItems)
    }
  }, [teamBuilding])

  const [keywordItems, setKeywordItems] = React.useState<SelectItem[]>([])
  const handleOnClickItem = (name: string, value: boolean) => {
    let count = 0
    if (value) {
      for (const i of keywordItems) {
        if (i.value) {
          count++
        }
      }
    }

    if (count < MAX_COUNT) {
      const updated = keywordItems.reduce((items: SelectItem[], i) => {
        if (i.name === name) {
          items.push({
            ...i,
            value,
          })
        } else {
          items.push(i)
        }
        return items
      }, [])
      setKeywordItems(updated)
    }
  }

  return (
    <>
      {isEditing ? (
        <SideBar>
          <div className={classes.root}>
            <div className={classes.container}>
              <div
                style={{
                  position: 'absolute',
                  fontFamily: 'Hiragino Kaku Gothic Pro',
                  fontSize: 14,
                  color: constants.COLOR_ONBOARDING_MAIN,
                  fontWeight: 'bold',
                  marginLeft: '20px',
                  cursor: 'pointer',
                }}
                onClick={handleOnClose}
              >
                <img src={process.env.PUBLIC_URL + '/assets/svg/arrowLeft.svg'} alt={`arrow`} />
                <span style={{ paddingLeft: '5px', color: '#707070' }}>戻る</span>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'Hiragino Kaku Gothic Pro',
                  fontSize: 14,
                  fontWeight: 'bold',
                  marginBottom: 20,
                }}
              >
                文言の編集
              </div>

              <div
                style={{
                  marginBottom: 24,
                }}
              >
                <Results items={rankings} setItems={setRankings} />
              </div>
              <div className={classes.buttonContainer}>
                <Button key="edit-ranking-1" onClick={handleOnClose} width={100} buttonType="secondary">
                  キャンセル
                </Button>
                <Button key="edit-ranking-2" onClick={handleOnSave} width={100}>
                  保存
                </Button>
              </div>
            </div>
          </div>
        </SideBar>
      ) : (
        <QuestionContainer active={4} progressDotsProps={{ maxLength: 3, currentProgress: 2 }} questionHeadBar>
          <div className={!isLeader ? classes.rootWaitingControl : undefined}>
            <div className={classes.cardContainer}>
              <InputCard noRadius>
                <FormLabel>
                  {isLeader ? (
                    <>
                      上位の行動規範に対して、チーム規範にするものを3つ選択しましょう。選びきれない場合は5つまでOKです。
                    </>
                  ) : (
                    <>
                      メンバーは閲覧のみです。リーダーが3つを選択し、よりチームにあった内容に変更していきますので、ディスカッションして決めてください。
                    </>
                  )}
                </FormLabel>
                {isLeader ? (
                  <SelectItems items={keywordItems} icon={'check'} onClick={handleOnClickItem} />
                ) : (
                  <Ranking
                    items={teamBuilding && teamBuilding.resultQ4 ? JSON.parse(teamBuilding.resultQ4).rankings : []}
                  />
                )}
              </InputCard>
            </div>

            {isLeader && <EditRanking handleOpen={handleOpen} />}

            {isLeader ? (
              <ButtonContainer
                fixed
                buttons={[
                  <Button
                    key="q2-result-1"
                    onClick={onSubmit}
                    disabled={!(keywordItems.filter((i) => i.value).length >= MIN_COUNT)}
                  >
                    確定
                  </Button>,
                ]}
              />
            ) : (
              <WaitingForLeader />
            )}
          </div>
        </QuestionContainer>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 16px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '0 70px 16px',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: 40,
    maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
    [theme.breakpoints.up('md')]: {
      maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
    },
  },
  buttonContainer: {
    marginBottom: 80,
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 8,
  },

  rootWaitingControl: {
    width: '100%',
    minHeight: 'calc(100vh - 174px)', // -150px is Container padding. 24px is progressDots.
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 166px)', // -128px is Container padding. -72px is header. 16px is progressDots. 50px is bottom adjustment.
      marginBottom: -50,
    },
  },
  cardContainer: {
    width: '100%',
    marginBottom: 40,
  },

  inputContainer: {
    marginBottom: 16,
  },
}))

const extractResult = async (members: TeamBuildingMember[]) => {
  const justArray: { id: string; answer: string; count: number }[] = []

  // マスターの分を配列に追加
  const response = await queryGetMasters({ type: MasterType.TeamBuildingQ4Keyword, limit: 100 })
  if (response) {
    for (const m of response.items) {
      if (m.title) {
        justArray.push({ id: m.id, answer: m.title, count: 0 })
      }
    }
  }

  // 回答の分を配列に追加
  for (const m of members) {
    if (m.resultQ4) {
      const resultQ4 = JSON.parse(m.resultQ4) as { label: string; name: string }[]
      resultQ4.forEach((r) => justArray.push({ id: r.name, answer: r.label, count: 1 }))
    }
  }

  // group by
  const groupBy = justArray.reduce(
    (res: { id: string; answer: string; count: number }[], current: { id: string; answer: string; count: number }) => {
      const element = res.find((p) => {
        return p.id === current.id
      })
      if (element) {
        element.count += current.count
      } else {
        res.push({
          id: current.id,
          answer: current.answer,
          count: current.count,
        })
      }
      return res
    },
    []
  )

  return groupBy.sort(function (a, b) {
    return a.count < b.count ? 1 : -1
  })
}

const Results: React.FC<{
  items: RankingItem[]
  setItems: React.Dispatch<React.SetStateAction<RankingItem[]>>
}> = ({ items, setItems }) => {
  const handleOnChange = (id: string, answer: string) => {
    const newItems = items.map((i) => {
      if (i.id === id) {
        return {
          ...i,
          answer,
        }
      } else {
        return i
      }
    })
    setItems(newItems)
  }
  return (
    <>
      {items.map((item, index) => (
        <ResultItem
          key={`q2-result-item-${index}-${item.id}`}
          item={item}
          ranking={index + 1}
          handleOnChange={handleOnChange}
        />
      ))}
    </>
  )
}

const ResultItem: React.FC<{
  item: RankingItem
  ranking: number
  handleOnChange: (id: string, answer: string) => void
}> = ({ item, handleOnChange }) => {
  const classes = resultItemUseStyles()

  return (
    <div className={classes.root}>
      <textarea
        className={classes.textarea}
        defaultValue={item.answer}
        onChange={(e) => handleOnChange(item.id, e.target.value)}
      />
    </div>
  )
}
const resultItemUseStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 14,
    borderRadius: 4,
  },

  textarea: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    color: constants.TEXT_BLACK,
    width: '100%',
    borderColor: '#E9E9E9',
    outline: 'none',
    resize: 'none',
    paddingLeft: '16px',
    paddingTop: '16px',
    borderRadius: '8px',
  },
}))
