import React, { FC, useEffect } from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

type Props = {
  clientWidth: number | undefined
}

const scrollBoxId = 'scrollBox'

export const FreetrialFlow: FC<Props> = ({ clientWidth }) => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  // scrollbar が含まれない画面幅（clientWidth）を親コンポーネントから受け取り、横 scroll を設定する dom の width に設定する。
  useEffect(() => {
    if (clientWidth) {
      const el = document.getElementById(scrollBoxId)
      if (el) {
        el.style.width = `${clientWidth}px`
      }
    }
  }, [clientWidth])

  return (
    <div id="TopUsefulDocs" className={classes.container}>
      <h2>
        2週間Cocolabo{isSmDown ? '\n' : ' '}
        <span className={classes.x}>×</span>
        {isSmDown ? '\n' : ' '}組織開発を<span className={classes.free}>無料</span>でトライアル
      </h2>
      <p className={classes.info}>
        2名 ‐ 3名の少人数単位で組織開発をご体験いただける無料トライアルをご提供しています。お気軽にお問合せ下さい。
        {'\n'}
        トライアル期間終了時に弊社より事前確認を行います。確認が取れない場合は有料にはなりません。
      </p>

      {isSmDown && <div className={classes.swipe}>Swipe →</div>}

      <div id={scrollBoxId} className={classes.scrollBox}>
        <div className={classes.contentContainer}>
          <div className={classNames(classes.contents, 'left')}>
            <div className={classes.setVertical}>
              <div className={classNames(classes.period, 'strong')}>お申込み＆準備期間</div>
              <div className={classNames(classes.period, 'mainColor')}>Week1</div>
            </div>
            <div className={classes.setLabel}>
              <div className={classNames(classes.label, 'backColor')}>貴社</div>
              <div className={classNames(classes.contentBox, 'short', 'flex1')}>
                <Ul list={['トライアル申込', '開始前MTG(1時間)']} />
              </div>
            </div>
            <div className={classes.setLabel}>
              <div className={classNames(classes.label)}>弊社</div>
              <div className={classNames(classes.contentBox, 'short', 'flex1')}>
                <Ul list={['申込書の送付', '各MTG日程調整']} />
              </div>
            </div>
            <div className={classes.setLabel}>
              <div className={classNames(classes.label, 'backColor', 'narrowSpace')}>ご提供コンテンツ</div>
              <div className={classNames(classes.contentBox, 'long', 'flex1', 'mainColor')}>
                <Ul list={['サービス詳細資料', '社内説明資料', 'ツール活用資料']} />
              </div>
            </div>
          </div>

          <div className={classNames(classes.contents, 'center')}>
            <div className={classes.setVertical}>
              <div className={classNames(classes.period, 'strong', 'fullWidth', 'backColor')}>無料トライアル期間</div>
              <div className={classes.setRow}>
                <div className={classNames(classes.period, 'mainColor', 'fullWidth')}>Week2</div>
                <div className={classNames(classes.period, 'mainColor', 'fullWidth')}>Week3</div>
              </div>
            </div>
            <div className={classes.setRow}>
              <div className={classNames(classes.contentBox, 'short', 'flex1')}>
                <Ul list={['開始前準備', 'メンバーへの連絡']} />
              </div>
              <div className={classNames(classes.contentBox, 'short', 'flex1')}>
                <Ul list={['中間MTG(30分)', 'メンバーと1on1実施']} />
              </div>
            </div>
            <div className={classNames(classes.setRow, classes.arrow)}>
              <div className={classNames(classes.contentBox, 'short', 'flex1')}>アカウント{'\n'}の発行</div>
              <div className={classNames(classes.contentBox, 'short', 'flex3')}>
                <Ul list={['運用サポート', '中間MTGの設定']} />
              </div>
            </div>
            <div className={classes.setRow}>
              <div className={classNames(classes.contentBox, 'long', 'mainColor', 'flex1')}>
                <Ul list={['動画', '各操作マニュアル', 'FAQページ']} />
              </div>
              <div className={classNames(classes.contentBox, 'long', 'mainColor', 'flex1')}>
                <Ul list={['1on1のアドバイス']} />
              </div>
            </div>
          </div>

          <div className={classNames(classes.contents, 'right')}>
            <div className={classes.setVertical}>
              <div className={classNames(classes.period, 'strong')}>振り返り期間</div>
              <div className={classNames(classes.period, 'mainColor')}>Week4</div>
            </div>
            <div className={classNames(classes.contentBox, 'short')}>振り返りMTG(30分)</div>
            <div className={classNames(classes.contentBox, 'short')}>
              <Ul list={['運用サポート', '継続意思のご確認']} />
            </div>
            <div className={classNames(classes.contentBox, 'long', 'mainColor')}>
              <Ul list={['ワークショップ事例', '組織開発説明資料']} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Ul = ({ list }: { list: string[] }) => (
  <ul>
    {list.map((text) => (
      <li key={text}>
        <span>{text}</span>
      </li>
    ))}
  </ul>
)

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: '80px 0 54px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        padding: '100px 0 40px',
      },

      '& h2': {
        margin: 0,
        fontSize: 32,
        textAlign: 'center',
        lineHeight: 1.3,
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
    },
    x: {
      fontSize: 32,
    },
    free: {
      color: constants.COLOR_MAIN_NEW,
    },
    info: {
      margin: '26px 0 0',
      fontSize: 16,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    swipe: {
      marginTop: 46,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    scrollBox: {
      marginTop: 50,
      marginLeft: -24,
      paddingLeft: 24,
      paddingBottom: 18,
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },
    contentContainer: {
      display: 'flex',
      gap: 103,
      width: 1024,
      paddingRight: 24,

      '& ul': {
        margin: 0,
        paddingLeft: 16,

        '& li': {
          '&::marker': {
            fontSize: 10,
          },

          '& span': {
            verticalAlign: '-2px',
          },
        },
      },
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '&.left': {
        width: 235,
      },
      '&.center': {
        flex: 1,
        position: 'relative',
        '&:before': {
          position: 'absolute',
          content: '""',
          top: 16,
          left: -16,
          width: 'calc(100% + 32px)',
          height: '100%',
          border: `2px dashed ${constants.COLOR_YELLOW3}`,
          zIndex: 2,
        },
      },
      '&.right': {
        width: 184,
      },

      '& .strong': {
        fontWeight: 'bold',
      },
      '& .mainColor': {
        color: constants.COLOR_MAIN_NEW,
      },
      '& .flex1': {
        flex: 1,
      },
    },
    setVertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 8,
    },
    period: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 184,
      backgroundColor: constants.COLOR_WHITE,
      height: 35,
      borderRadius: 18,

      '&.fullWidth': {
        width: '100%',
      },
      '&.backColor': {
        backgroundColor: constants.COLOR_YELLOW3,
        position: 'relative',
        zIndex: 3,
      },
    },
    setLabel: {
      display: 'flex',
      gap: 16,
    },
    label: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 35,
      writingMode: 'vertical-rl',
      letterSpacing: '10px',
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 18,

      '&.backColor': {
        backgroundColor: constants.COLOR_YELLOW3,
      },
      '&.narrowSpace': {
        letterSpacing: '4px',
      },
    },
    contentBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: constants.COLOR_WHITE,

      '&.short': {
        height: 116,
      },
      '&.long': {
        height: 177,
      },
      '&.flex1': {
        flex: 1,
      },
      '&.flex3': {
        flex: 3,
      },
    },
    setRow: {
      display: 'flex',
      gap: 8,
      width: '100%',
    },
    arrow: {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '""',
        top: 48,
        left: -67,
        borderTop: '15px solid transparent',
        borderBottom: '15px solid transparent',
        borderLeft: `15px solid ${constants.COLOR_YELLOW3}`,
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        top: 48,
        right: -67,
        borderTop: '15px solid transparent',
        borderBottom: '15px solid transparent',
        borderLeft: `15px solid ${constants.COLOR_YELLOW3}`,
      },
    },
  }),
  { name: 'FreetrialFlow' }
)
