import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import { MutationDeleteAccountArgs } from 'utils/generated'

export const mutationDeleteAccount = async (args: MutationDeleteAccountArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation DeleteAccount($input: DeleteAccountInput!) {
        deleteAccount(input: $input)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ deleteAccount: boolean }>

  return Boolean(response.data?.deleteAccount)
}

export const MutationUpdateAccount = gql`
  mutation UpdateAccount($isDeleted: Int) {
    updateAccount(input: { isDeleted: $isDeleted }) {
      id
      nickname
      email
      isDeleted
    }
  }
`

export const MutationQuitReasonComment = gql`
  mutation CreateQuitReasonComment($comment: String, $nickname: String!, $email: String!) {
    createQuitReasonComment(input: { comment: $comment, nickname: $nickname, email: $email }) {
      id
      nickname
      email
    }
  }
`
