import React from 'react'

import { ArrowRightIcon } from '../../assets'
import { useTeamDesign } from '../hooks/teamStyle'

import * as constants from 'assets/constants'

export const AverageEvaluation: React.FC<{ value: number | null | undefined; styles?: object }> = ({
  value,
  styles,
}) => {
  if (typeof value === 'number') {
    return (
      <span style={{ color: value < 0 ? constants.COLOR_RED : constants.COLOR_MAIN, ...styles }}>
        {value.toFixed(1)}
      </span>
    )
  }
  return <span style={{ ...styles }}>_</span>
}

export const GrowthRate: React.FC<{ value: number | null | undefined }> = ({ value }) => {
  const { getColor, rotate } = useTeamDesign()
  const color = getColor(value, 3, 1, -1, -3)

  if (value !== 0 && !value) {
    return <>_</>
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          minWidth: 24,
          width: 24,
          minHeight: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 8,
        }}
      >
        <ArrowRightIcon
          fill={constants.COLOR_TEAMBUILDING_WHITE}
          stroke={constants.COLOR_TEAMBUILDING_WHITE}
          style={{
            transform: rotate(value),
          }}
        />
      </div>
      <span style={{ color, fontSize: 12, fontWeight: 'bold' }}>
        {typeof value === 'number' ? value.toFixed(1) : '0.0'}
      </span>
    </div>
  )
}

export const BuildingRate: React.FC<{ value: number | null | undefined; gap: number | null | undefined }> = ({
  value,
  gap,
}) => {
  const { getColor, label } = useTeamDesign()
  const color = getColor(value, 4.0, 3.0, 0, -2.0)

  if (value !== 0 && !value) {
    return <>_</>
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          minWidth: 24,
          width: 24,
          minHeight: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: 1,
        }}
      >
        <div style={{ fontWeight: 'bold', color: constants.COLOR_TEAMBUILDING_WHITE }}>{label(value)}</div>
        <div style={{ fontWeight: 'bold', color: constants.COLOR_TEAMBUILDING_WHITE, padding: '2px 0 0' }}>
          {gap ? (gap >= 2 ? '-' : gap === 0 ? '+' : '') : ''}
        </div>
      </div>
    </div>
  )
}
