import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import useOnClickOutside from 'use-onclickoutside'

import { Button } from './'

import { constants } from 'assets'

interface InlineEditTextAreaProps {
  text: string
  onSubmit(text: string): void
}

export const InlineEditInput: React.FC<InlineEditTextAreaProps> = ({ text = '', onSubmit }) => {
  const classes = useStyles()

  const [loading, setLoading] = React.useState(false)
  const [isEdit, setIsEdit] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const innerRef = React.useRef(null)
  useOnClickOutside(innerRef, () => setIsEdit(false))

  const onEdit = () => {
    setIsEdit(true)
  }

  const handleSubmit = async () => {
    if (!inputRef.current) {
      return
    }

    setLoading(true)
    setIsEdit(false)

    const text = inputRef.current.value

    await onSubmit(text)

    setLoading(false)
  }

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
      // inputRef.current.selectionStart = inputRef.current.value.length
    }
  }, [isEdit])

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={20} />
      </div>
    )
  }

  return (
    <>
      {isEdit ? (
        <div ref={innerRef}>
          <input type="text" className={classes.edit} defaultValue={text} ref={inputRef} />
          <div className={classes.buttons}>
            <Button
              onClick={() => setIsEdit(false)}
              style={{ width: 'auto', height: 24, fontSize: 12 }}
              buttonType={'secondary'}
            >
              キャンセル
            </Button>
            <Button onClick={() => handleSubmit()} style={{ width: 'auto', height: 24, fontSize: 12, marginLeft: 6 }}>
              保存
            </Button>
          </div>
        </div>
      ) : (
        <span>
          <span className={classes.text}>{text}</span>
          <EditIcon className={classes.icon} onClick={() => onEdit()} />
        </span>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  edit: {
    width: '100%',
    resize: 'none',
    minWidth: 100,
    borderColor: '#E9E9E9',
    padding: 8,
    border: '1px solid #E9E9E9',
    borderRadius: 8,

    '&:focus-visible': {
      outline: `${constants.COLOR_ONBOARDING_MAIN} solid 1px`,
    },
  },

  buttons: {
    marginTop: 6,
    marginBottom: 6,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  text: {
    verticalAlign: 'middle',
  },

  icon: {
    marginLeft: 4,
    verticalAlign: 'middle',
    fontSize: 12,
    color: constants.COLOR_ONBOARDING_MAIN,
    cursor: 'pointer',
  },
}))
