// import { CommentList } from 'pages/hint/_shared/components/commentList/Index'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { DialogComment } from 'pages/hint/_shared/components/dialogs/dialogComment/Index'
import { useDialogComment } from 'pages/hint/_shared/components/dialogs/dialogComment/useDialogComment'
import { DialogCopy } from 'pages/hint/_shared/components/dialogs/dialogCopy/Index'
import { useArticleHeading } from 'pages/hint/_shared/components/heading/articleHeading/useArticleHeading'
import { ProfileTips } from 'pages/hint/_shared/components/profile/profileTips/Index'
import { Slide } from 'pages/hint/_shared/components/slide/Index'
import { SocialsShare } from 'pages/hint/_shared/components/socials/socialsShare/Index'
import { useSocials } from 'pages/hint/_shared/components/socials/useSocials'
import { useDocument } from 'pages/hint/_shared/hooks/useDcoment'
import { useGetHintEpic } from 'pages/hint/_shared/hooks/useGetHintEpic'
import {
  // WrapperComment,
  // WrapperForm,
  WrapperInTips,
  WrapperProfile,
  WrapperSocial,
} from 'pages/hint/_shared/layouts/article/Index'
import { Container } from 'pages/hint/_shared/layouts/container/Index'
import { Main } from 'pages/hint/_shared/layouts/main/Index'
import { Wrapper } from 'pages/hint/_shared/layouts/wrapper/Index'



import { hintContext } from '../../_shared/context/useHintContext'
import { Modal } from '../_shared/components/modal/modal/Index'

import { Article } from './components/article/Index'
// import CommentForm from './components/commentForm/Index'
import { Hero } from './components/hero/Index'
import { useTipsArticle } from './hooks/useTipsArticle'

import * as constants from 'assets/constants'
import * as registers from 'assets/registers'

// -----------------------------
// Component
// -----------------------------
const Index = () => {
  // -----------------------------
  // Hooks
  // -----------------------------
  const document = useDocument()
  const hintEpic = useGetHintEpic()
  const socials = useSocials()
  const dialogComment = useDialogComment()
  const articleHeading = useArticleHeading()
  const location = useLocation()
  const tipsArticle = useTipsArticle()
  const context = React.useContext(hintContext)

  const { register } = useForm()

  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)

  // -----------------------------
  // LifeCycle
  // -----------------------------
  React.useEffect(() => {
    const hintID = location.pathname.split('/').pop()
    if (hintID) {
      hintEpic.getHintEpic(hintID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (commonSelector.error) {
      alert(commonSelector.error.error)
    }
  }, [commonSelector])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.onSetDocumentHint(hintSelector.hint.title, hintSelector.hint.contentPreview)
    register('comment', registers.ARTICLE_COMMENT)
  }, [document, hintSelector.hint.contentPreview, hintSelector.hint.title, register])

  return (
    <>
      {/** Modal */}
      {context.isShowModal && (
        <Slide in={context.isShowModal} direction={'up'}>
          <Modal />
        </Slide>
      )}

      {/** Hero */}
      <Hero src={hintSelector.hint.imageUrl} />

      {socials.getWeb() && <DialogCopy open={socials.getWeb()} onClose={socials.onCloseDialog} />}
      {dialogComment.getOpen() && (
        <DialogComment
          open={dialogComment.getOpen()}
          onClose={dialogComment.onClose}
          presenter={dialogComment}
          params={tipsArticle.getCommentParams()}
        />
      )}

      {/** Wrapper */}
      <Wrapper color={constants.COLOR_WHITE}>
        <WrapperInTips>
          <Container maxWidth={780}>
            {/** Main */}
            <Main>
              <Article data={hintSelector.hint} presenter={articleHeading} />

              <WrapperSocial>
                <SocialsShare presenter={socials} />
              </WrapperSocial>

              <WrapperProfile>
                <ProfileTips data={hintSelector.hint.user} presenter={socials} />
              </WrapperProfile>

              {/* {tipsArticle.isShowForm() && (
                <WrapperForm>
                  <CommentForm presenter={tipsArticle} handler={dialogComment.onSubmitHandler} />
                </WrapperForm>
              )}

              {tipsArticle.isShowComments() && (
                <WrapperComment>
                  <CommentList
                    commentsHeading={'コメント'}
                    commentsNum={hintSelector?.hintCommentList?.length}
                    comments={hintSelector?.hintCommentList}
                  />
                </WrapperComment>
              )} */}
            </Main>
          </Container>
        </WrapperInTips>
      </Wrapper>
    </>
  )
}

export default Index
