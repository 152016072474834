import { TeamBuildingReview, TeamBuildingReviewStatus, TeamMemberRole } from 'utils/generated'

export const getRoundedValue = (value: number) => (Math.round(value * 10) / 10).toFixed(1)

export const getNextStatus = (
  teamBuildingReview: TeamBuildingReview | undefined,
  presenterLastNum: number,
  role: TeamMemberRole | undefined,
  hasHistories: boolean
) => {
  const { status, iceBreakUserIds } = teamBuildingReview ?? {}
  const length = iceBreakUserIds?.length ?? 0
  if (role === TeamMemberRole.Member) {
    return status
  }

  if (status === TeamBuildingReviewStatus.Created) {
    return TeamBuildingReviewStatus.Step1
  }
  if (status === TeamBuildingReviewStatus.Step1) {
    return TeamBuildingReviewStatus.Step1Select
  }
  if (status === TeamBuildingReviewStatus.Step1Select) {
    return TeamBuildingReviewStatus.Step1Presen
  }
  if (status === TeamBuildingReviewStatus.Step1Presen && length >= presenterLastNum) {
    return TeamBuildingReviewStatus.Step1End
  }
  if (status === TeamBuildingReviewStatus.Step1End) {
    return hasHistories ? TeamBuildingReviewStatus.Step2 : TeamBuildingReviewStatus.Step2Input
  }
  if (status === TeamBuildingReviewStatus.Step2) {
    return TeamBuildingReviewStatus.Step2Input
  }
  if (status === TeamBuildingReviewStatus.Step2Input) {
    return TeamBuildingReviewStatus.Step2Confirm
  }
  if (status === TeamBuildingReviewStatus.Step2Confirm) {
    return TeamBuildingReviewStatus.Step2Select
  }
  if (status === TeamBuildingReviewStatus.Step2Select) {
    return TeamBuildingReviewStatus.Step2Presen
  }
  if (status === TeamBuildingReviewStatus.Step2Presen && length >= presenterLastNum) {
    return TeamBuildingReviewStatus.Step2End
  }
  if (status === TeamBuildingReviewStatus.Step2End) {
    return TeamBuildingReviewStatus.Step3
  }
  if (status === TeamBuildingReviewStatus.Step3) {
    return TeamBuildingReviewStatus.Step3Select
  }
  if (status === TeamBuildingReviewStatus.Step3Select) {
    return TeamBuildingReviewStatus.Completed
  }
  return status
}

// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
export const shuffleArray = (arr: any[]) => {
  return arr
    .map((a) => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1])
}
