import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BackResponsiveCard, ButtonForGhost, GrayBackGround, LeadText, PageTitle } from 'pages/ghost/components/atoms'
import {
  ButtonContainer,
  ModalConfirmForCommon,
  WaitingContainer,
  WidthControlContainer,
} from 'pages/ghost/components/modules'
import { GhostReportCardList, LoadingIndicator } from 'pages/ghost/components/organisms'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { ReportContext } from 'pages/ghost/contexts/report'
import { usePBForFixedUIs } from 'pages/ghost/hooks/fixedUI'
import { useGetMembersWithReports } from 'pages/ghost/hooks/getMembersWithReports'
import { CommandContext } from 'pages/ghost/service/commands'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

type Props = {}

export const Step1Results: React.FC<Props> = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { ghosts } = useContext(GhostContext)
  const { teamMember, teamMemberList } = useContext(ghostTeamMemberContext)
  const { reports } = useContext(ReportContext)
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const { runGoToNextPage } = useContext(CommandContext)
  const { membersWithReports } = useGetMembersWithReports(teamMemberList, reports, ghosts)
  const pBForFixedUIs = usePBForFixedUIs({ isOnlyLeader: true })
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen((prev) => !prev)
  }

  const onClick = async () => {
    teamMember && (await runGoToNextPage(teamMember))
    await updateGhostTeam({
      input: {
        id: teamId,
        progress: GhostProgress.S1SelectingPresentationOptions,
      },
    })
  }

  const skip = async () => {
    await updateGhostTeam({
      input: { id: teamId, progress: GhostProgress.S1DoneRandomAnswers },
    })
  }

  const isLoading = !teamMember || !membersWithReports

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <PageTitle>個人ワーク結果</PageTitle>
          <LeadText ownStyles={{ marginBottom: 24 }}>
            仲間が選択したオバケを確認して対話しましょう。
            <br />
            次のページからランダムに発表者を指名します！選択した理由をお話しください。
          </LeadText>

          {isLoading ? null : <GhostReportCardList ghosts={ghosts} membersWithReports={membersWithReports} />}

          {isLoading ? (
            <LoadingIndicator snapshot={{}} />
          ) : teamMember.role === GhostMemberRole.Leader ? (
            <>
              <ForceProceed onClick={toggleModal} open={open} skip={skip} />
              <ButtonContainer
                fixed
                buttons={[
                  <ButtonForGhost
                    key="ghost-rule"
                    buttonBodyColor="green"
                    bodyText="発表へ"
                    onClick={onClick}
                    isResizeBasic
                  />,
                  <ButtonForGhost
                    key="done"
                    buttonBodyColor="white"
                    bodyText="発表をスキップ"
                    onClick={toggleModal}
                    isResizeBasic
                  />,
                ]}
              />
            </>
          ) : (
            <WaitingContainer />
          )}
        </WidthControlContainer>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

export const ForceProceed: React.FC<{
  open: boolean
  onClick: () => void
  skip: () => Promise<void>
}> = ({ open, onClick, skip }) => (
  <ModalConfirmForCommon
    open={open}
    handleModalChange={onClick}
    firstLine="発表をスキップしますか？"
    info="発表をスキップして、次のステップに進みますか？"
    btnFirstLine="発表をスキップ"
    btnSecondLine="キャンセル"
    toHome={skip}
  />
)
