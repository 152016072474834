import * as React from 'react'

import { StyleSheet, Text } from '@react-pdf/renderer'

import * as constants from '../../assets/constants'

interface IOwnProps {
  text: string
}

const styles = StyleSheet.create({
  title: {
    color: constants.COLOR_MAIN,
    fontSize: 16,
    fontWeight: 'bold',
    paddingBottom: 4,
    borderBottom: `2px solid ${constants.COLOR_MAIN}`,
  },
})

export const Title: React.FC<IOwnProps> = ({ text }) => {
  return <Text style={styles.title}>{text}</Text>
}
