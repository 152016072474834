import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

const menuList1 = ['求められるマネジメント理解', 'Cocolabo体感', '実践計画']
const menuList2 = ['実践内容と互いのノウハウシェア', '今後の応用方法の理解']

export const TopSupport: React.FC = () => {
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const classes = useStyles({})

  return (
    <div id="TopSupport" className={classes.container}>
      <h2>導入から定着まで{isSmDown && '\n'}伴走型支援を行います</h2>

      <div className={classes.flow}>
        <Content title="ワークショップ(3h)" menuList={menuList1} listMt={14} />

        <div className={classes.imgContent}>
          <div className={classes.pcImgWrapper}>
            <img className={classes.pcImg} src={`${process.env.PUBLIC_URL}/img/topPage/topSupportFlow.png`} alt="" />
          </div>

          <div className={classes.cocolabo}>
            <div>
              <div className={classes.cocolaboImg}>
                <img src={`${process.env.PUBLIC_URL}/img/topPage/topCocolabo.svg`} alt="cocolabo" />
              </div>
              <div className={classes.cocolaboText}>を活用して{'\n'}管理職がメンバーと実践</div>
            </div>
          </div>
        </div>

        <Content title="ワークショップ(3h)" menuList={menuList2} listMt={isSmDown ? 14 : 36} />
      </div>

      <div className={classes.info}>
        <p>
          ※ ワークショップを個別セッション形式でも実施可能。その場合は、個別のデータ結果から具体的なアドバイスも可能
        </p>
        <p>※ 上記のようなセッション2回ではなく、3回以上実施することの方が多い</p>
      </div>
    </div>
  )
}

type ContentProps = {
  title: string
  menuList: string[]
  listMt: number
}

const Content: React.FC<ContentProps> = ({ title, menuList, listMt }) => {
  const classes = useStyles({ listMt })

  return (
    <div className={classes.content}>
      <span>{title}</span>
      <div className={classes.list}>
        {menuList.map((menu) => (
          <span key={`${title}-${menu}`}>{menu}</span>
        ))}
      </div>
    </div>
  )
}

type StyleProps = {
  listMt?: number
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    container: {
      padding: '80px 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 60,
      },

      '& h2': {
        margin: 0,
        fontSize: 32,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
        },
      },
    },
    flow: {
      display: 'flex',
      gap: 26,
      marginTop: 46,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 41,
        marginTop: 40,
      },
    },
    imgContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        '&:before': {
          position: 'absolute',
          content: '""',
          top: -28,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRight: '15px solid transparent',
          borderLeft: '15px solid transparent',
          borderTop: `15px solid ${constants.COLOR_YELLOW3}`,
        },
        '&:after': {
          position: 'absolute',
          content: '""',
          bottom: -28,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRight: '15px solid transparent',
          borderLeft: '15px solid transparent',
          borderTop: `15px solid ${constants.COLOR_YELLOW3}`,
        },
      },
    },
    pcImgWrapper: {
      [theme.breakpoints.down('sm')]: {
        padding: '0 35px',
      },
    },
    pcImg: {
      width: 263,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 665,
      },
    },
    cocolabo: {
      position: 'relative',
      padding: '20px 30px',
      width: '100%',
      backgroundColor: constants.COLOR_WHITE,
      fontWeight: 'bold',
      borderRadius: 16,
      [theme.breakpoints.up('md')]: {
        '&:before': {
          position: 'absolute',
          content: '""',
          top: 35,
          left: -20,
          borderTop: '15px solid transparent',
          borderBottom: '15px solid transparent',
          borderLeft: `15px solid ${constants.COLOR_YELLOW3}`,
        },
        '&:after': {
          position: 'absolute',
          content: '""',
          top: 35,
          right: -20,
          borderTop: '15px solid transparent',
          borderBottom: '15px solid transparent',
          borderLeft: `15px solid ${constants.COLOR_YELLOW3}`,
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    cocolaboImg: {
      width: 140,

      '& img': {
        display: 'block',
        width: '100%',
      },
    },
    cocolaboText: {
      marginTop: 8,
    },
    info: {
      marginTop: 38,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },

      '& p': {
        margin: 0,
        paddingLeft: 16,
        lineHeight: 1.8,
        textIndent: -16,
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },

    content: {
      flex: 1,
      padding: '24px 16px',
      height: 285,
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    list: ({ listMt }) => ({
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      width: '100%',
      marginTop: listMt,
      fontSize: 14,

      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        backgroundColor: '#FFCD31',
        borderRadius: 8,
      },
    }),
  }),
  { name: 'TopSupport' }
)
