import { HintCategory, ConsultationCategory } from 'utils/generated'

export type IOptions = {
  label: string
  value: string
}

export const optionHintCategory: IOptions[] = [
  { label: 'エンゲージメントの高いチーム作り', value: HintCategory.CreatingAHighlyEngagedTeam },
  { label: 'Cocolaboについて', value: HintCategory.HowToUseSevenTools },
  // { label: 'マネジメントのお悩み', value: HintCategory.ManagementWorries },
  { label: 'カルテ', value: HintCategory.HowToUseKarte },
  // { label: '～セットアップ～ 各種ツールの使い方', value: HintCategory.HowToUseSetup },
  // { label: '～ヒント～ 各種ツールの使い方', value: HintCategory.HowToUseHint },
  { label: 'キックオフ', value: HintCategory.KickOff },
  { label: 'オバケ', value: HintCategory.Ghost },
  { label: 'リフレクション', value: HintCategory.Reflection },
  { label: 'TBチェック', value: HintCategory.TbCheck },
]

export const optionConsultationCategory: IOptions[] = [
  { label: 'エンゲージメントの高いチーム作り', value: ConsultationCategory.CreatingAHighlyEngagedTeam },
  { label: 'Cocolaboについて', value: ConsultationCategory.HowToUseSevenTools },
  // { label: 'マネジメントのお悩み', value: ConsultationCategory.ManagementWorries },
  { label: 'カルテ', value: ConsultationCategory.HowToUseKarte },
  // { label: '～セットアップ～ 各種ツールの使い方', value: ConsultationCategory.HowToUseSetup },
  // { label: '～ヒント～ 各種ツールの使い方', value: ConsultationCategory.HowToUseHint },
  { label: 'キックオフ', value: ConsultationCategory.KickOff },
  { label: 'オバケ', value: ConsultationCategory.Ghost },
  { label: 'リフレクション', value: ConsultationCategory.Reflection },
  { label: 'TBチェック', value: ConsultationCategory.TbCheck },
]

// CATEGORY_1 # Depreciated
// CATEGORY_2 # Depreciated
// CATEGORY_3 # Depreciated
