import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { PagingButton } from 'pages/hint/_shared/components/buttons/pagingButton/Index'
import { useCategory } from 'pages/hint/_shared/components/categories/useCategory'
import { Hero } from 'pages/hint/_shared/components/hero/Index'
import { Loading } from 'pages/hint/_shared/components/loading/Index'
import { ProfileAuthor } from 'pages/hint/_shared/components/profile/profileAuthor/Index'
import { Search } from 'pages/hint/_shared/components/search/Index'
import { Slide } from 'pages/hint/_shared/components/slide/Index'
import { useSocials } from 'pages/hint/_shared/components/socials/useSocials'
import { TabPanel } from 'pages/hint/_shared/components/tabs/tabPanel/Index'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useDocument } from 'pages/hint/_shared/hooks/useDcoment'
import { useGetHintEpic } from 'pages/hint/_shared/hooks/useGetHintEpic'
import { useLink } from 'pages/hint/_shared/hooks/useLink'
import { useLoading } from 'pages/hint/_shared/hooks/useLoading'
import { Column } from 'pages/hint/_shared/layouts/column/Index'
import { Main } from 'pages/hint/_shared/layouts/main/Index'
import { Pager } from 'pages/hint/_shared/layouts/pager/Index'
import { Sidebar } from 'pages/hint/_shared/layouts/sidebar/Index'
import { Modal } from 'pages/hint/tips/_shared/components/modal/modal/Index'



import { Articles } from './components/articles/Index'
import { ProfileBox } from './components/profileBox/Index'
import { useProfileIndex } from './hooks/useProfileIndex'
import { useProfileNextToken } from './hooks/useProfileNextToken'
import { Wrapper } from './layouts/wrapper/Index'

import * as constants from 'assets/constants'

// -----------------------------
// Component
// -----------------------------
const Index = () => {
  window.scrollTo(0, 0)
  const link = useLink()
  const document = useDocument()
  const loading = useLoading()
  const userEpic = useGetHintEpic()
  const category = useCategory()
  const socials = useSocials()
  const location = useLocation()
  const params = useParams() as { id: string }
  const context = React.useContext(hintContext)
  const profileIndex = useProfileIndex()
  const profileNextToken = useProfileNextToken()

  const profileSelector = useSelector((state: RootStateType) => state.hint.user)
  const hintProfileListSelectors = useSelector((state: RootStateType) => state.hint.hintProfileList)
  const hintProfileListUnpublishedSelectors = useSelector(
    (state: RootStateType) => state.hint.hintProfileListUnpublished
  )

  const init = async () => {
    await userEpic.getProfileEpic(params.id)
    profileNextToken.getTokenWithFetch(params.id)
    if (profileIndex.isHome()) {
      await userEpic.getProfileHintListUnpublishedEpic({ username: params.id, nextToken: '' })
    }
  }

  React.useEffect(() => {
    const isName = profileSelector?.firstName || profileSelector?.lastName
    document.onSetDocumentProfile(
      isName ? `${profileSelector?.firstName} ${profileSelector?.lastName}` : profileSelector.id,
      profileSelector.description
    )
  }, [document, location.pathname, profileSelector])

  React.useEffect(() => {
    if (link.isReload()) {
      window.location.href = `/hint/tips/profile/${params.id}`
    }
  }, [link, params.id, profileIndex])

  React.useEffect(() => {
    loading.setLoad(true)
    if (params.id) {
      init()
    }
    loading.onDoneLoaded(false, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {/** Modal */}
      {context.isShowModal && (
        <Slide in={context.isShowModal} direction={'up'}>
          <Modal />
        </Slide>
      )}

      {/** Hero */}
      <Hero route={'profile'} />

      {/** Wrapper */}
      <Wrapper>
        {/** Main */}
        <Main>
          <Column route={'profile'}>
            <TabPanel route={'profile'}>
              {profileIndex.isShowProfile() && (
                <ProfileBox>
                  <ProfileAuthor data={profileSelector} presenter={socials} isCard />
                </ProfileBox>
              )}

              <Loading isLoad={loading.isLoad()}>
                <Box>
                  {/** 下書きのTips記事が存在したら1ページ目のTOPに出す */}
                  {profileIndex.isShowUnPublished() && <Articles datas={hintProfileListUnpublishedSelectors} />}

                  {/** 下書きの後に公開済みTips一覧を出す */}
                  {hintProfileListSelectors && <Articles datas={hintProfileListSelectors} />}
                </Box>

                {profileIndex.isShowPager() && (
                  <Pager>
                    {!profileIndex.isHome() && (
                      <PagingButton
                        category={'profile'}
                        color={constants.COLOR_GREEN_DARK}
                        body={'前のページ'}
                        action={'preview'}
                      />
                    )}
                    {hintProfileListSelectors.nextToken && (
                      <PagingButton
                        category={'profile'}
                        color={constants.COLOR_GREEN_DARK}
                        body={'次のページ'}
                        action={'next'}
                      />
                    )}
                  </Pager>
                )}
              </Loading>
            </TabPanel>
          </Column>
        </Main>

        {/** Sidebar */}
        <Sidebar>
          <Search presenter={category} />
        </Sidebar>
      </Wrapper>
    </>
  )
}

export default Index
