import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'

import getSelfcheck from '../../services/api/getSelfCheck'
import getSelfCheckComments from '../../services/api/getSelfCheckComments'
import updateSelfcheckResult from '../../services/api/updateSelfcheckResult'
import { RootState } from '../reducers'

import { SelfcheckActions } from './action'

type Action = ActionType<typeof SelfcheckActions>

const fechSelfchekEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SelfcheckActions.fetch.started.match),
    exhaustMap(({ payload }) => {
      return from(getSelfcheck()).pipe(
        map((res) => {
          return SelfcheckActions.fetch.done({ params: payload, result: res })
        }),
        catchError((error) => of(SelfcheckActions.fetch.failed({ params: payload, error })))
      )
    })
  )

const updateSelfchekEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SelfcheckActions.updateSelfCheck.started.match),
    exhaustMap(({ payload }) => {
      return from(updateSelfcheckResult(payload.data)).pipe(
        map((res) => {
          return SelfcheckActions.updateSelfCheck.done({ params: payload, result: res })
        }),
        catchError((error) => of(SelfcheckActions.updateSelfCheck.failed({ params: payload, error })))
      )
    })
  )

const fetchSelfchekCommentsEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SelfcheckActions.fetchSelfCheckComments.started.match),
    exhaustMap(({ payload }) => {
      return from(getSelfCheckComments()).pipe(
        map((res) => {
          return SelfcheckActions.fetchSelfCheckComments.done({ params: payload, result: res })
        }),
        catchError((error) => of(SelfcheckActions.fetchSelfCheckComments.failed({ params: payload, error })))
      )
    })
  )

export default [updateSelfchekEpic, fechSelfchekEpic, fetchSelfchekCommentsEpic]
