import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Woman0: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-1408.409 328.949)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(1408.409 -328.949)" fill="#80d6dd" />
        <Path d="M1839.513-323.949" fill="#ffd04d" />
        <G>
          <G>
            <Path
              d="M1552.843-187.537,1553.614-86l.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.021-3.009-2.057-5.452-4.519-5.434l-87.115.661C1554.819-193.02,1552.821-190.549,1552.843-187.537Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(1593.669 -207.717) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1634.4 -258.766)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1539.479 -258.045)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(1549.781 -303.58)" fill="#ffe4d2" />
            <Path
              d="M1650.59,33.8a2.543,2.543,0,0,0-2.562-2.525h0l-31.375.241h0a2.545,2.545,0,0,0-2.524,2.563h0l.008.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.018-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M1590.991,34.256a2.547,2.547,0,0,0-2.565-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M1585.509-193.249l9.995,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M1601.84-193.023l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.693-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(1597.276 -185.178) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M1585.373-193.249l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M1619.257-193.509l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M1601.428-312.9c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C1543.381-284.979,1569.254-312.649,1601.428-312.9Z"
              fill="#522903"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1553.832 -57.398) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1613.445 -57.851) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M1727.48-280.443a11.563,11.563,0,0,1,4.393,15.7l-5.163,8.722a11.563,11.563,0,0,1-15.7,4.393l-.669-.377a11.563,11.563,0,0,1-4.393-15.7l5.164-8.723a11.564,11.564,0,0,1,15.7-4.393Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1730.916-259.9l-33.65,64.624s-10.256,2.919-15.139-.885c-7.8-6.075-7.952-12.915-7.952-12.915l33.646-64.625Z"
                fill="#545364"
              />
            </G>
            <Path d="M1698.858-197.663,1645.9-163.2l-13.692-23.152,52.956-34.466Z" fill="#545364" />
            <Path d="M1526.83-135.769l25.926-54.243,24.764,10.5-25.927,54.243Z" fill="#545364" />
            <G>
              <Path
                d="M1581.33-65.985a11.562,11.562,0,0,0,4.463-15.68l-5.122-8.746a11.563,11.563,0,0,0-15.681-4.464l-.671.374c-5.541,3.085-.667,7.654,2.417,13.193l5.122,8.746c3.086,5.541,3.259,10.036,8.8,6.951Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1584.86-86.51l-35.186-61.63s-10.128-.44-15.505,3.174c-5.563,3.74-7.649,10.517-7.649,10.517l35.19,61.631Z"
                fill="#545364"
              />
            </G>
          </G>
          <Path
            d="M1515.056-196.381l-30.33-23.018,17.906-24.123-23.138-29.12-24.884,26.063,28.247,10.607-18.331,20.139Z"
            fill="#ffd04d"
          />
          <Path
            d="M1946.309-194.6l23.027-17.475-13.6-18.314,17.568-22.108,18.9,19.788-21.445,8.053,13.917,15.289Z"
            fill="#ef4351"
          />
          <G>
            <Path
              d="M1706.332-90.089a11.563,11.563,0,0,1,2.877-16.048l8.191-5.97a11.561,11.561,0,0,1,16.047,2.877l.44.63a11.563,11.563,0,0,1-2.877,16.047l-8.191,5.971a11.56,11.56,0,0,1-16.047-2.876Z"
              fill="#ffe4d2"
            />
            <Path
              d="M1796.224,34.411v.517c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.975.155,18.227-9.7h.036V34.4a2.546,2.546,0,0,0-2.547-2.546h-31.388a2.546,2.546,0,0,0-2.546,2.546Z"
              fill="#72642a"
            />
            <Path
              d="M1855.87,34.411v.517c0,10.075,8.165,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.035V34.4a2.546,2.546,0,0,0-2.546-2.546h-31.389a2.546,2.546,0,0,0-2.546,2.546Z"
              fill="#72642a"
            />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1855.87 -57.605)" fill="#ffe5d2" />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1796.21 -57.605)" fill="#ffe5d2" />
            <Path
              d="M1845.238-261.2s-15.615,25.42-15.615,35.226a26.053,26.053,0,0,0,8.351,18.519c2.905,3.267-10.495,17.767-2.8,32.466,7.988,15.252,5.4,5.045,36.251,5.77s-7.608,32.9,6.189,13.651c6.76-9.429,21.76-29.779,25.712-52.5,3.4-19.562-6.914-63.218-6.914-63.218"
              fill="#6d614a"
            />
            <Path
              d="M1840.026-261.2s15.614,25.42,15.614,35.226a26.053,26.053,0,0,1-8.351,18.519c-2.9,3.267,10.5,17.767,2.8,32.466-7.988,15.252-5.4,5.045-36.251,5.77s7.609,32.9-6.189,13.651c-6.759-9.429-21.764-29.779-25.712-52.5-3.4-19.562,6.914-63.218,6.914-63.218"
              fill="#6d614a"
            />
            <Path
              d="M1887.881-193.321h-87.188a4.536,4.536,0,0,0-4.483,4.558V-72.751h96.136V-188.763A4.535,4.535,0,0,0,1887.881-193.321Z"
              fill="#7192cc"
            />
            <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(1833.805 -207.733)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1779.442 -258.396)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1874.438 -258.396)" fill="#ffe5d2" />
            <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(1789.676 -303.608)" fill="#ffe5d2" />
            <Path
              d="M1901.091-251.513c-18.3,0-12.284,3.232-25.759-18.872-23.806,25.614-76.5,17.25-89.471,9.88,3.63-23.475,24.713-52.414,56.91-52.414S1901.091-285.438,1901.091-251.513Z"
              fill="#6d614a"
            />
            <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(1796.21 -92.649)" fill="#7192cc" />
            <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(1818.637 -193.321)" fill="#ffe5d2" />
            <Path
              d="M1709.081-108.627l61.125-39.651s6.823-.505,13.614,6.685c4.252,4.5,2.323,14.985,2.323,14.985L1725.02-86.953"
              fill="#7192cc"
            />
            <Path d="M1762.637-139.119l33.38-53.647,23.429,13.215-33.38,53.647Z" fill="#7192cc" />
            <Path d="M1907.055-128.506l-37.172-47.252,21.864-15.669,37.172,47.252Z" fill="#7192cc" />
            <G>
              <Path
                d="M1856.055-89.036c4.046,4.882,5.837.756,10.72-3.289l7.945-6.3c4.882-4.045,11.081-6.538,7.034-11.42l-.49-.592a11.563,11.563,0,0,0-16.232-1.523l-7.945,6.3a11.562,11.562,0,0,0-1.522,16.231Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1876.331-88.884l55.13-44.689s.513-7.072-3.317-12.574c-3.7-5.317-13.3-8.578-13.3-8.578l-55.132,44.688Z"
                fill="#7192cc"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman0
