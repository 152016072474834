import * as React from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'

import { GenericModal } from 'components/Modal'
import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostItemBox } from 'pages/ghost/components/modules'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { useHistoryStore } from 'pages/ghost/contexts/history'
import { Ghost } from 'utils/generated'

import { HooksContext } from '../contexts'

import { DefaultButton } from './DefaultButton'

import info from '../assets/svg/info.svg'
import { constants } from 'assets'

const addLinefeed = (name: string) => (name === '言い出しっぺ負けオバケ' ? '言い出しっぺ負け\nオバケ' : name)

export const GhostListWithCount: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { ghosts } = React.useContext(GhostContext)
  const { currentTeamMember } = React.useContext(HooksContext)
  const { targetHistory, targetTimes, selectedGhost, setSelectedGhost, hasHistoryDate, reports, loading } =
    useHistoryStore(teamId, 'dummy-ghost-teamId', currentTeamMember?.id)

  const handleModalOpen = (ghost: Ghost) => {
    setSelectedGhost(ghost)
  }
  const handleModalClose = () => {
    setSelectedGhost(null)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          {ghosts.map((ghost) => (
            <a key={ghost.name} href="#ghost-selected" onClick={() => handleModalOpen(ghost)}>
              <GhostItemBox
                withBatch
                isOnDashboard
                childrenForTitle={
                  <>
                    <img
                      src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghost.id}.svg`}
                      alt="オバケ"
                      className={classes.ghostImage}
                    />
                    {ghost.found ? <p className={classes.foundNum}>過去に見つけた数：{ghost.found}</p> : null}
                  </>
                }
                childrenForContent={
                  <div className={classes.info}>
                    <div className={classes.infoBox}>
                      <p className={classes.ghostNum}>{`FILE NO.${ghost.fileNo}`}</p>
                      <p className={classes.ghostName}>{addLinefeed(ghost.name)}</p>
                    </div>
                    <div className={classes.iconBox}>
                      <img src={info} alt="詳細モーダルを開く" className={classes.infoImage} />
                      <span className={classes.infoLabel}>詳細</span>
                    </div>
                  </div>
                }
              />
            </a>
          ))}
        </div>
      </div>

      <GenericModal
        modalStyle={{ overflow: 'auto' }}
        styleProps={{ width: '100%', maxWidth: hasHistoryDate ? 600 : 388, maxHeight: '90%' }}
        isModalOpen={!!selectedGhost}
        handleModalClose={handleModalClose}
      >
        {selectedGhost ? (
          <div className={classes.modalPaper}>
            {hasHistoryDate ? (
              <p className={classes.modalDate}>
                第{targetTimes}回（{dayjs(targetHistory?.history?.createdAt).format('YY/MM/DD')}）
              </p>
            ) : (
              <div className={classes.noneModalDate}></div>
            )}

            <div className={classes.modalGhost}>
              <img
                src={process.env.PUBLIC_URL + `/assets/ghost/ghost${selectedGhost.id}.svg`}
                alt="オバケ"
                className={classes.modalImage}
              />

              <div>
                <p className={classes.modalNum}>{`FILE NO.${selectedGhost.fileNo}`}</p>
                <p className={classes.modalName}>{selectedGhost.name}</p>
                <p className={classes.modalCount}>
                  これまでの発見数<span className="count">{selectedGhost.found}</span>
                  <span className="times">回</span>
                </p>
              </div>
            </div>

            {!loading &&
              reports.map((report) =>
                report.answer ? (
                  <React.Fragment key={report.title}>
                    <p className={classes.reportTitle}>{report.title}</p>
                    <div className={classes.reportPaper}>
                      <span className={classes.reportAnswer}>{report.answer}</span>
                    </div>
                  </React.Fragment>
                ) : null
              )}

            <div className={`${classes.modalButton} ${!hasHistoryDate ? 'noneDate' : ''}`}>
              <DefaultButton
                title="閉じる"
                color="team_white"
                onClick={handleModalClose}
                style={{
                  padding: '0 16px',
                  fontSize: 12,
                  fontWeight: 'bold',
                  minWidth: 176,
                }}
              />
            </div>
          </div>
        ) : null}
      </GenericModal>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // page
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      background: '#fff',
      padding: '24px 0 0',
      color: constants.TEXT_GRAY_DARK,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        padding: '16px 15px',
      },
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '24px 40px',
      width: '100%',
      maxWidth: 714,
      [theme.breakpoints.down('sm')]: {
        gap: '24px 16px',
      },
    },
    ghostImage: {
      width: '100%',
      height: '100%',
      verticalAlign: 'top',
      background: '#fff',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      padding: 10,
    },
    foundNum: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      display: 'grid',
      placeItems: 'center',
      width: '100%',
      height: 19,
      margin: 0,
      background: constants.COLOR_TEAMBUILDING_YERROW,
      fontSize: 10,
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      columnGap: 3,
      marginTop: 8,
    },
    infoBox: {
      flex: 1,
      maxWidth: 124,
    },
    ghostNum: {
      margin: 0,
      fontSize: 10,
    },
    ghostName: {
      margin: '4px 0 0',
      fontSize: 12,
      whiteSpace: 'pre-wrap',
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 0,
    },
    infoImage: {
      width: 16,
      height: 16,
    },
    infoLabel: {
      marginTop: 4,
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 8,
      fontWeight: 'bold',
    },

    // modal
    modalPaper: {
      padding: 24,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '40px 16px 24px',
      },
    },
    modalDate: {
      margin: 0,
      fontSize: 12,
    },
    noneModalDate: {
      width: '100%',
      height: 17,
    },
    modalGhost: {
      marginTop: 12,
      display: 'flex',
      columnGap: 40,
      [theme.breakpoints.down('sm')]: {
        columnGap: 16,
      },
    },
    modalImage: {
      width: 147,
      height: 147,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    modalNum: {
      margin: 0,
      fontSize: 10,
    },
    modalName: {
      margin: 0,
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    modalCount: {
      margin: '14px 0 0',
      fontSize: 14,
      '& .count': {
        marginLeft: 4,
        color: constGhost.COLOR_MAIN_NEW,
        fontSize: 16,
        fontWeight: 'bold',
      },
      '& .times': {
        marginLeft: 4,
        fontWeight: 'bold',
      },
    },
    reportTitle: {
      margin: '24px 0 0',
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0 0',
      },
    },
    reportPaper: {
      marginTop: 4,
      padding: 8,
      minHeight: 81,
      background: constants.COLOR_WHITE2,
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        padding: 16,
        minHeight: 74,
      },
    },
    reportAnswer: {
      fontSize: 14,
      whiteSpace: 'pre-wrap',
    },
    modalButton: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
      '&.noneDate': {
        marginTop: 45,
      },
    },
  }),
  { name: 'GhostListWithCount' }
)
