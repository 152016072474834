import { OnboardingAction, OnboardingActionCategory, OnboardingTeamMember } from 'utils/generated'

import { onboardingDeadlinePeriod } from './onboardingDeadlinePeriod'

const categories: OnboardingActionCategory[] = [
  OnboardingActionCategory.Category1,
  OnboardingActionCategory.Category2,
  OnboardingActionCategory.Category3,
  OnboardingActionCategory.Category4,
  OnboardingActionCategory.Category5,
]

export const countOfActions = (onboardingActions: Array<{ category: OnboardingActionCategory }>) => {
  const initValue = {} as { [k in OnboardingActionCategory]: number }
  for (const category of categories) {
    initValue[category] = 0
  }
  return onboardingActions.reduce((accumulator, currentValue) => {
    accumulator[currentValue.category] = accumulator[currentValue.category] ? accumulator[currentValue.category] + 1 : 1
    return accumulator
  }, initValue)
}

export const countOfOverDeadlineActions = (
  visibleActions: OnboardingAction[],
  teamMember: OnboardingTeamMember | undefined
) => {
  const overDeadlineActions = visibleActions.filter(
    (item) =>
      new Date() >
      new Date(
        onboardingDeadlinePeriod(
          item.period,
          teamMember?.startedAt || new Date().toString(),
          item?.deadline || undefined
        )
      )
  )
  return countOfActions(overDeadlineActions)
}

export const calcPercent = (
  dividends: { [k in OnboardingActionCategory]: number },
  divisors: { [k in OnboardingActionCategory]: number }
) => {
  const donePercent = (category: OnboardingActionCategory) => dividends[category] / divisors[category]
  const divide = (category: OnboardingActionCategory) => {
    if (divisors[category] === 0) {
      return Infinity
    }
    return donePercent(category)
  }
  const returnValue = {} as { [k in OnboardingActionCategory]: number }
  for (const category of categories) {
    returnValue[category] = divide(category)
  }
  return returnValue
}
