import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'

import { useObakesagashi } from '../hooks/obakesagashi'

import { Pages, constants } from 'assets'
import { replacePathParams } from 'assets/history'

type Props = {
  handleClose: () => void
}
type Input = {
  resultId: string
}

export const FoundGhostResult: React.FC<Props> = ({ handleClose }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isErrorId, setIsErrorId] = React.useState(false)
  const { getNoSignInGhost } = useObakesagashi()

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
  } = useForm<Input>({
    mode: 'onChange',
    defaultValues: { resultId: '' },
  })

  const disabled = !isValid || !isDirty
  const watchResultId = watch('resultId')

  const onSubmit = async (data: Input) => {
    const res = await getNoSignInGhost(data.resultId)

    let dateDiff: number | undefined

    if (res && res.own?.createdAt) {
      // 基準時刻は今日の0時に設定する。
      const startOfToday = dayjs(new Date()).startOf('day')
      const createdAt = dayjs(res.own.createdAt)
      dateDiff = startOfToday.diff(createdAt, 'day')
    }

    // 30日以内なら結果画面へ遷移する。
    if (dateDiff !== undefined && dateDiff < 29) {
      history.push(replacePathParams(Pages.ObakesagashiResult, { id: data.resultId }))
      window.scrollTo(0, 0)
    } else {
      setIsErrorId(true)
    }
  }

  // エラーメッセージおよびエラーのスタイルをクリアする。
  React.useEffect(() => {
    setIsErrorId(false)
  }, [watchResultId])

  return (
    <div className={classes.container}>
      <NoStyleButton type="button" className={classes.close} onClick={handleClose}>
        <img src={`${process.env.PUBLIC_URL}/img/hataraku/close.svg`} alt="モーダルのクローズ" />
      </NoStyleButton>

      <div className={classes.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.info}>
            <p>
              診断IDを入力してください。
              <span>（診断から30日以内なら、確認できます）</span>
            </p>
          </div>

          <div className={classes.search}>
            <input
              className={classNames(classes.inputId, { isErrorId })}
              type="text"
              name="resultId"
              placeholder="IDを入力…"
              ref={register({ required: 'IDを入力してください。' })}
            />

            <NoStyleButton type="submit" disabled={disabled} className={classes.resultLink}>
              過去の結果を見る
            </NoStyleButton>
          </div>

          {isErrorId && <p className={classes.errorMsg}>期限切れまたは無効なIDです。</p>}
        </form>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'relative',
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      padding: 16,
      fontFamily: constants.HATARAKU_FONT_FAMILY,
      color: '#000',
    },
    close: {
      position: 'absolute',
      top: 16,
      right: 16,
      '& img': {
        width: 16,
        height: 16,
      },
    },
    content: {
      position: 'relative',
    },
    info: {
      '& p': {
        margin: 0,
        fontSize: 16,
        fontWeight: 'bold',
        '& span': {
          marginLeft: 4,
          fontSize: 12,
          fontWeight: 'normal',
          [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '4px 0 0 0',
          },
        },
      },
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      width: 583,
      height: 56,
      [theme.breakpoints.down('sm')]: {
        width: 500,
      },
      [theme.breakpoints.down('xs')]: {
        width: 300,
      },
    },
    inputId: {
      flex: 1,
      padding: '0 8px 0 40px',
      minWidth: 0,
      height: '100%',
      background: '#fff',
      fontSize: 16,
      border: `1px solid ${constants.COLOR_NAVY}`,
      borderRight: 'none',
      borderRadius: '28px 0px 0px 28px',
      '&::placeholder': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      },
      '&:focus': {
        background: 'rgba(28, 40, 88, 0.05)',
      },
      '&.isErrorId': {
        background: 'rgba(195, 35, 35, 0.10)',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 8px 0 16px',
      },
    },
    resultLink: {
      flexShrink: 0,
      width: 180,
      height: '100%',
      background: constants.COLOR_NAVY,
      color: '#fff',
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'inherit',
      border: `1px solid ${constants.COLOR_NAVY}`,
      borderRadius: '0px 28px 28px 0px',
      [theme.breakpoints.down('sm')]: {
        width: 140,
      },
      '&:not([disabled])': {
        '&:focus': {
          color: constants.COLOR_YELLOW4,
        },
        '@media (hover: hover)': {
          '&:hover': {
            color: constants.COLOR_YELLOW4,
          },
        },
      },
      '&:disabled': {
        cursor: 'not-allowed',
        color: '#fff',
      },
    },
    errorMsg: {
      position: 'absolute',
      bottom: -24,
      left: 0,
      margin: 0,
      color: '#C32323',
      fontSize: 12,
      lineHeight: 1,
    },
  }),
  { name: 'FoundGhostResult' }
)
