import React, { useEffect, KeyboardEventHandler } from 'react'

import { makeStyles, Theme, Fab } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { OnboardingTeamMember } from 'utils/generated'

import { useMentions } from '../hooks/mentions'
import { useOnbHistory } from '../navigation/route'

import NoImageSvg from '../assets/noImage.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


interface Props {
  teamId: string
  onSend: (message: string, mentions?: string[]) => void
  imageUrl: string
  teamMembers: OnboardingTeamMember[]
  isFocus?: boolean
  setAllEditClose?: React.Dispatch<React.SetStateAction<boolean>>
  postId: string
}

type PickInput = Pick<IFormCharCountInput, 'comment'>

export const MessageInput: React.FC<Props> = ({
  teamId,
  onSend,
  imageUrl,
  teamMembers,
  isFocus,
  setAllEditClose,
  postId,
}) => {
  const classes = useStyles()
  const history = useOnbHistory()

  const maxLength = 10000
  const inputName: keyof PickInput = 'comment'

  const { register, handleSubmit, errors, reset, watch, setValue } = useForm<PickInput>({ mode: 'onChange' })
  const watchInput = watch(inputName, '')
  const element = document.getElementById(postId) as HTMLInputElement
  const {
    checkCorrectMentions,
    clearMentions,
    SuggestBox,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
    convertMentionsStylesForInput,
  } = useMentions<'comment'>('comment', watchInput, teamMembers, setValue, element)
  const onKeyDown = (e: KeyboardEvent) => {
    openSuggestionByTypeText(e)
  }
  useEffect(() => {
    closeSuggestionByTypeText()
  }, [watchInput, closeSuggestionByTypeText])

  const onSubmit = (data: PickInput) => {
    if (setAllEditClose) {
      setAllEditClose((prev) => !prev)
    }
    if (data.comment.length > 0 && data.comment.length <= maxLength) {
      onSend(data.comment, checkCorrectMentions())
      reset({ comment: '' })
      clearMentions()
    }
  }

  return (
    <>
      <div className={classes.container}>
        <img
          className={classes.userAvatar}
          src={imageUrl || NoImageSvg}
          alt={imageUrl ? 'avatar' : 'no-avatar'}
          onClick={() => history.push(OnbPages.Mypage, { teamId })}
        />
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div className={classes.textareaBack} dangerouslySetInnerHTML={{ __html: convertMentionsStylesForInput() }} />
          <textarea
            id={postId}
            name={inputName}
            className={classes.textarea}
            placeholder={'コメントを追加する'}
            onKeyDown={onKeyDown as unknown as KeyboardEventHandler<HTMLTextAreaElement>}
            autoFocus={isFocus ?? false}
            required
            rows={Math.max(watchInput.split('\n').length, 1)}
            maxLength={300}
            ref={register}
          />
          <SuggestBox />
          {watchInput && !errors.comment ? (
            <Fab className={classes.btn} size="small" type="submit">
              送信
            </Fab>
          ) : (
            <></>
          )}
        </form>
      </div>
      <FormCharCount length={watchInput.length} maxLength={maxLength} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userAvatar: {
    width: 32,
    height: 32,
    marginRight: 16,
    borderRadius: 16,
    cursor: 'pointer',
    objectFit: 'cover',
  },
  form: {
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  textareaBack: {
    // for use mention highlight
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'transparent',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    overflow: 'hidden',
    margin: '10px 16px',
    // for same as textarea
    fontSize: 12,
    letterSpacing: 0.1,
    lineHeight: 1.6,
  },
  textarea: {
    resize: 'none',
    border: 'none',
    flex: 1,
    width: '100%',
    padding: '10px 50px 10px 16px',
    // for mentions
    background: 'transparent',
    position: 'relative',
    zIndex: 2,
    // for same as textareaBack
    fontSize: 12,
    letterSpacing: 0.1,
    lineHeight: 1.6,
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  },
  Input: {
    '&::placeholder': {
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      opacity: 1,
    },
  },
  btn: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 9,
    backgroundColor: constants.COLOR_WHITE + '00',
    boxShadow: 'none',
  },
}))
