import React from 'react'

import { Theme, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { HorizonalLine, UsefulCard, Font, Flexbox } from '../atoms'

import { WhiteGhostImg } from './WhiteGhostImg'

type Props = {
  visibleToGhostCounter?: boolean
  ownStyles?: React.CSSProperties
  flexOwnStyles?: React.CSSProperties
  imgOwnStyles?: React.CSSProperties
  goSelect?: boolean
  noline?: boolean
  textAlign?: 'center' | 'left' | 'right'
  label?: 'オバケを選びなおす'
  ghostVisibleInPC?: boolean
}

export const ButtonForGhostList: React.FC<Props> = ({
  visibleToGhostCounter = false,
  ownStyles,
  flexOwnStyles,
  imgOwnStyles,
  goSelect,
  noline,
  textAlign,
  label,
  ghostVisibleInPC,
}) => {
  const { setModal, setDirectClose, ghosts, resetSelectedGhosts } = React.useContext(GhostContext)
  const discoveredCount = ghosts.filter((g) => g.found).length
  const IsShow = ghostVisibleInPC ? 'block' : 'none'
  const useStyles = makeStyles((theme: Theme) => ({
    isVisible: {
      [theme.breakpoints.up('md')]: {
        display: IsShow,
      },
    },
    smallerizeGhost: {
      width: 135,
      [theme.breakpoints.down(500)]: {
        maxWidth: 90,
        width: '100%',
        minWidth: 30,
      },
    },
  }))
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.isVisible}>
      <UsefulCard backgroundColor="white" borderRadius={10} ownStyles={{ padding: 0, ...ownStyles }}>
        <Flexbox ownStyles={{ gap: isSmDown ? 18 : 40, ...flexOwnStyles }}>
          <div className={classes.smallerizeGhost}>
            <WhiteGhostImg
              ownStyles={{
                flex: '1 0 80px',
                maxHeight: 120,
                margin: '24px 0',
                ...imgOwnStyles,
                verticalAlign: 'bottom',
              }}
            />
          </div>
          <div
            style={{
              flex: !ghostVisibleInPC ? 'initial' : '0 0 200px',
              maxWidth: 450,
            }}
          >
            {visibleToGhostCounter ? (
              <div>
                <Flexbox ownStyles={{ margin: 0, alignItems: 'flex-end' }}>
                  <Font fontSize={14} ownStyles={{ margin: 0 }}>
                    これまでの発見数
                  </Font>
                  <Font
                    fontSize={24}
                    bold
                    ownStyles={{ margin: '0 8px', color: constGhost.COLOR_MAIN_NEW, lineHeight: 1 }}
                  >
                    {discoveredCount}
                  </Font>
                  <Font fontSize={14} bold ownStyles={{ margin: 0 }}>
                    オバケ
                  </Font>
                </Flexbox>
                <HorizonalLine
                  width={'200px'}
                  height={1}
                  ownStyles={{ margin: '5px auto 12px auto', display: noline ? 'none' : 'block' }}
                />
              </div>
            ) : (
              <></>
            )}

            <Font
              fontSize={14}
              bold
              textAlign={textAlign ? textAlign : 'center'}
              ownStyles={{ color: constGhost.COLOR_MAIN_NEW, boxSizing: 'border-box' }}
            >
              <span
                data-testid="ghostlist"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (label) {
                    resetSelectedGhosts()
                  }
                  setModal(goSelect ? 'select' : 'list')
                  setDirectClose(goSelect ?? false)
                }}
              >
                {label || (
                  <div>
                    <span>オバケリスト </span>

                    <img
                      src={process.env.PUBLIC_URL + '/assets/svg/arrow.svg'}
                      alt={`arrow`}
                      style={{ paddingTop: 20 }}
                    />
                  </div>
                )}
              </span>
            </Font>
          </div>
        </Flexbox>
      </UsefulCard>
    </div>
  )
}
