import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import {
  GhostTeam,
  MutationCreateGhostTeamArgs,
  MutationDeleteGhostTeamArgs,
  MutationUpdateGhostTeamArgs,
  QueryGetGhostTeamArgs,
  QueryGetGhostTeamByMasterArgs,
} from 'utils/generated'

import {
  mutationCreateGhostTeam,
  mutationDeleteGhostTeam,
  mutationUpdateGhostTeam,
  queryGetGhostTeam,
  queryGetGhostTeamList,
  queryGetGhostTeamByMaster,
} from './graphql'

export const getGhostTeam = async (args: QueryGetGhostTeamArgs) => {
  const res = await API.graphql({
    query: queryGetGhostTeam,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostTeam as GhostTeam
}

export const getGhostTeamList = async () => {
  const res = await API.graphql({
    query: queryGetGhostTeamList,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostTeamList as GhostTeam[]
}

export const getGhostTeamByMaster = async (args: QueryGetGhostTeamByMasterArgs) => {
  const res = await API.graphql({
    query: queryGetGhostTeamByMaster,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostTeamByMaster as GhostTeam
}

export const createGhostTeam = async (args: MutationCreateGhostTeamArgs) => {
  const res = await API.graphql({
    query: mutationCreateGhostTeam,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.createGhostTeam as GhostTeam
}

export const updateGhostTeam = async (args: MutationUpdateGhostTeamArgs) => {
  const res = await API.graphql({
    query: mutationUpdateGhostTeam,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })
  return res.data.updateGhostTeam as GhostTeam
}

export const deleteGhostTeam = async (args: MutationDeleteGhostTeamArgs) => {
  const res = await API.graphql({
    query: mutationDeleteGhostTeam,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.deleteGhostTeam as boolean
}
