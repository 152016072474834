import { Storage } from 'aws-amplify'

/**
 * S3に保存した画像のパスを取得する
 */
export const getS3objectUrl = (path: string) => {
  return `https://${process.env.REACT_APP_AWS_S3_BUCKET_UPLOAD}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${path}`
}

/**
 * S3にHero画像を保存する
 */
export const S3put = async (file: File | null, putData: Blob | null, uuid: string): Promise<string | undefined> => {
  try {
    if (file) {
      const extension = file?.name?.split('.').pop()
      const key = `hint/${uuid}.${extension}`
      await Storage.put(key, putData, {
        contentType: file.type,
        level: 'public',
      })
      const imageUrl = getS3objectUrl(key)
      return imageUrl
    }
  } catch (e) {
    throw e
  }
}

/**
 * MEMO: 必要になったら使用
 * S3から削除する
 */
// export const S3delete = async (uuid: string, extension: string): Promise<string | undefined> => {
//   try {
//       const key = `hint/${uuid}.${extension}`
//       await Storage.remove(key, { level: "public"})
//       const imageUrl = getS3objectUrl(key)
//       return imageUrl
//     } catch(e) {
//     throw e
//   }
// }
