import React, { useContext } from 'react'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
// import { page2Num } from 'pages/ghost/assets/pages'
import { BackResponsiveCard, GrayBackGround, ButtonForGhost } from 'pages/ghost/components/atoms'
import { ButtonContainer } from 'pages/ghost/components/modules'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'

import img20 from './dummy-20.png'
import img21 from './dummy-21.png'
import img22 from './dummy-22.png'
import img23 from './dummy-23.png'

export const Dummy: React.FC = () => {
  const { updateMemberPage, teamMember } = useContext(ghostTeamMemberContext)
  const isMdUp = useCustomMediaQuery('up', 'md')

  const pageImageSrc = (page: number | undefined | null) => {
    switch (page) {
      case 20:
        return img20
      case 21:
        return img21
      case 22:
        return img22
      case 23:
        return img23
      default:
        break
    }
  }

  const next = () => {
    if (teamMember?.page) {
      updateMemberPage(teamMember.page + 1)
    }
  }

  return (
    <GrayBackGround fullVh>
      <BackResponsiveCard ownStyles={{ padding: '0 0 100px' }}>
        <strong style={{ fontSize: 32 }}>page is {teamMember?.page}</strong>
        <div style={{ width: 300 }}>
          <img src={pageImageSrc(teamMember?.page)} alt="" style={{ width: '100%' }} />
        </div>

        <ButtonContainer
          fixed={isMdUp}
          ownRootStyles={{ padding: '16px 0', display: 'flex' }}
          ownRootStylesDownXs={{ padding: 0 }}
          buttons={[
            <ButtonForGhost
              key={'ghost-rule'}
              buttonBodyColor={'green'}
              bodyText="次へ"
              onClick={next}
              ownStyles={{ margin: isMdUp ? 0 : '24px 0 40px' }}
              isResizeBasic
            />,
          ]}
        />
      </BackResponsiveCard>
    </GrayBackGround>
  )
}
