import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { BuildingHooksContext } from 'pages/teams/contexts'
import { useUser } from 'pages/teams/hooks'

interface Props {
  teamMemberId?: string
  userId?: string
  isCircleIcon?: boolean
  size?: 40 | 32 | 24
}

export const UserIcon: React.FC<Props> = ({ teamMemberId, userId, isCircleIcon = true, size = 40 }) => {
  const classes = useStyles({ size, isCircleIcon })
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const teamMember = teamBuildingMembers.find((m) => m.userId === userId || m.id === teamMemberId)
  const { user } = useUser(teamMember?.userId)

  return (
    <div className={classes.root}>
      <img src={user?.imageUrl ?? process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
    </div>
  )
}

type StyleProps = {
  size: number
  isCircleIcon: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ size, isCircleIcon }) => ({
    width: size,
    height: size,
    overflow: 'hidden',
    border: isCircleIcon ? '1px solid #e3e3e3' : 'none',
    borderRadius: isCircleIcon ? '50%' : 'none',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }),
}))
