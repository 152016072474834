import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Auth } from 'aws-amplify'

import { connector, ContainerProps } from 'components/header/HeaderAdditionalActions.container'
import { ModalButton } from 'pages/hint/_shared/components/buttons/modalButton/Index'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'

import * as constants from 'assets/constants'


type Props = {} & ContainerProps

const HeaderAdditionalActions: React.FC<Props> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const context = React.useContext(hintContext)

  const updateIsAdmin = async (): Promise<void> => {
    const cognitoUser = await Auth.currentSession()
    const congnitoGroup = cognitoUser.getIdToken().payload['cognito:groups']
    const isGroup = congnitoGroup && congnitoGroup[0] === 'admin'
    setIsAdmin(!!isGroup)
  }

  const handleOpenTipsModal = async () => {
    window.scrollTo(0, 0)

    if (!props?.user?.username) {
      history.push(constants.PAGE_SIGNIN)
      return
    }

    if (isAdmin) {
      context.onSetShowCreateModal()
    } else {
      return
    }
  }

  const handleOpenConsultationModal = () => {
    window.scrollTo(0, 0)

    if (!props.user.username) {
      history.push(constants.PAGE_SIGNIN)
      return
    }
    context.onSetShowCreateModal()
  }

  const isHintPageShowMenu = () => {
    return location.pathname.startsWith('/hint')
  }

  const showTipsMenu = location.pathname.startsWith('/hint/tips')
  const showFAQMenu = location.pathname.startsWith('/hint/faq')

  React.useEffect(() => {
    updateIsAdmin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* additional menu */}
      {isHintPageShowMenu() && (
        <div className="__button">
          {showTipsMenu && isAdmin && (
            <ModalButton action="Tips" onClick={handleOpenTipsModal} color={constants.COLOR_SECOND} body={'投稿する'} />
          )}
          {showFAQMenu && (
            <ModalButton
              action="お悩み掲示板"
              onClick={handleOpenConsultationModal}
              color={constants.COLOR_SECOND}
              body={'相談する'}
            />
          )}
        </div>
      )}
    </>
  )
}

export default connector(HeaderAdditionalActions)
