import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { PointData } from 'pages/teams/pages/_tools/review/components/hooks/useReviewPoints'
import { getRoundedValue } from 'pages/teams/pages/_tools/review/lib'
import personSvg from 'pages/teams/pages/dashboard/assets/person.svg'

import { BarChart } from './BarChart'

import { constants } from 'assets'

type Props = {
  pointData: PointData
}

export const GraphBox: React.FC<Props> = ({ pointData }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.graphBox}>
        <div className={classes.title}>{pointData.label}</div>
        <div>
          <BarChart points={pointData.points} />
        </div>
      </div>
      <div className={classes.memberBox}>
        <div className={classes.average}>
          平均点数 <span>{getRoundedValue(pointData.average)}</span>
          {typeof pointData.prevAverage === 'number' ? ` (前回点数 ${getRoundedValue(pointData.prevAverage)})` : ''}
        </div>
        <div className={classes.listBack}>
          <ul className={classes.list}>
            {pointData.pointsWithName.map((member, i) => (
              <li key={`${member.name}-${i}`}>
                <div className={classes.name}>{member.name}さん</div>
                <div className={classes.points}>
                  {typeof member.prevPoint === 'number' && (
                    <span className={classes.prevPoint}>{member.prevPoint}点</span>
                  )}
                  {typeof member.prevPoint === 'number' && typeof member.point === 'number' && <span>{`-> `}</span>}
                  {typeof member.point === 'number' && <span className={classes.point}>{member.point}点</span>}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      gap: 24,
      width: '100%',
      height: 218,
      marginTop: 16,
      padding: 24,
      backgroundColor: constants.COLOR_WHITE2,
      color: constants.TEXT_GRAY_DARK,
      borderRadius: 8,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        height: 'auto',
        padding: 16,
      },
    },
    graphBox: {
      flex: 1,
      minWidth: 0,
      height: '100%',
    },
    title: {
      marginBottom: 8,
      fontSize: 12,
      fontWeight: 'bold',
    },
    memberBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minWidth: 300,
      width: 300,
      height: '100%',
      fontSize: 12,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        rowGap: 16,
        minWidth: '100%',
        width: '100%',
        height: 170,
      },
    },
    average: {
      '& span': {
        color: constants.COLOR_MAIN_NEW,
      },
    },
    listBack: {
      height: 140,
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      border: `1px solid ${constants.COLOR_WHITE}`, // scrollbar と背景色が似ているため設定
      overflow: 'hidden', // scrollbar の端に borderRadius を適用する
    },
    list: {
      margin: 0,
      padding: '8px 16px',
      height: '100%',
      overflow: 'auto',
      listStyleType: 'none',

      '& li': {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 8,
        margin: '8px 0',
        paddingLeft: 24,
        width: '100%',
        backgroundImage: `url(${personSvg})`,
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top 2px left 0',
      },
    },
    name: {
      flex: 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
    },
    points: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: 60,
      width: 60,
      whiteSpace: 'nowrap',
    },
    prevPoint: {
      flex: 1,
    },
    point: {
      flex: 1,
      color: constants.COLOR_MAIN_NEW,
      textAlignLast: 'right',
    },
  }),
  { name: 'GraphBox' }
)
