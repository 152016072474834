import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ButtonForGhost, Flexbox, UsefulModal } from 'pages/ghost/components/atoms'

type Props = {
  isOpen: boolean
  modalTitle: string
  secondLine?: string
  warningMessage?: React.ReactNode
  firstButtonText: string
  secondButtonText: string
  handleModalChange: () => void
  onClickFirstButton: () => void
}

export const ModalConfirmForWarning: React.FC<Props> = ({
  isOpen,
  modalTitle,
  warningMessage,
  firstButtonText,
  secondButtonText,
  handleModalChange,
  onClickFirstButton,
}) => {
  const classes = useStyles()
  return (
    <UsefulModal open={isOpen} handleModalChange={handleModalChange} ownStyles={{ padding: 24 }}>
      <img className={classes.img} src={process.env.PUBLIC_URL + '/assets/svg/exclamationRed.svg'} alt="注意" />
      <h2 id="modal-modal-title" className={classes.modalTitle}>
        {modalTitle}
      </h2>
      {warningMessage && <div className={classes.warningMessageBox}>{warningMessage}</div>}

      <Flexbox ownStyles={{ marginTop: 24, gap: 16 }} flexDirection="column">
        <ButtonForGhost buttonBodyColor="red" bodyText={firstButtonText} onClick={onClickFirstButton} isResizeBasic />
        <ButtonForGhost
          buttonBodyColor="outlineRed"
          bodyText={secondButtonText}
          onClick={handleModalChange}
          isResizeBasic
        />
      </Flexbox>
    </UsefulModal>
  )
}

const useStyles = makeStyles(
  () => ({
    img: {
      width: 80,
      height: 80,
      verticalAlign: 'top',
    },
    modalTitle: {
      margin: '24px 0',
      color: constGhost.COLOR_WARNING_RED,
      fontSize: 14,
      fontWeight: 'bold',
    },
    warningMessageBox: {
      textAlign: 'left',
      lineHeight: 1.6,
    },
  }),
  { name: 'ModalConfirmForWarning' }
)
