import React from 'react'

import { Avatar, IconButton } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import classNames from 'classnames'

import { TeamKarte, TeamMember } from 'utils/generated'

import { getTeamMemberKarteList } from '../../../../utils'
import { getKarteResult, getKarteValueType } from '../../../../utils'
import { TeamKarteWithTime, getTypeClass, VALUE_HEIGHT, NAME_WIDTH, MAX_NAME_WIDTH } from '../DataList'

import { DataRowValues } from './DataRowValues'

import { COLOR_ONBOARDING_MAIN, TEXT_GRAY_DARK } from 'assets/constants'

type Props = {
  page: number
  teamMember: TeamMember
  teamKarteList: TeamKarte[]
  allTime: number | undefined
  size: 'xs' | 'sm' | 'md'
  setSelectedTeamKarte: (value: TeamKarte) => void
}

export const DataRow: React.FC<Props> = ({ page, teamMember, teamKarteList, allTime, size, setSelectedTeamKarte }) => {
  const classes = useStyles()
  const [isOpenList, setIsOpenList] = React.useState(false)

  const switchOpen = () => {
    setIsOpenList(!isOpenList)
  }

  const handleCloseList = () => {
    setIsOpenList(false)
  }

  const targetTeamKarteList = getTeamMemberKarteList(teamMember.userId, teamKarteList)
  // メンバーの1行目に表示される診断結果を管理
  const [displayTime, setDisplayTime] = React.useState(allTime ?? targetTeamKarteList.length)
  // 診断結果に、回数と一致する time プロパティを付与
  const targetTeamKarteListWithTime: TeamKarteWithTime[] = targetTeamKarteList.map((item, index) => ({
    ...item,
    time: targetTeamKarteList.length - index,
  }))
  const topData =
    targetTeamKarteListWithTime.length > 0
      ? targetTeamKarteListWithTime.find((item) => item.time === displayTime)
      : null
  const hasExpandData = targetTeamKarteList.length > 1

  const results = getKarteResult(topData?.data)
  const type = getKarteValueType(results?.points[0].title)

  React.useEffect(() => {
    if (allTime !== undefined) {
      setDisplayTime(allTime)
    } else {
      setDisplayTime(targetTeamKarteList.length)
    }
    setIsOpenList(false)
  }, [allTime, targetTeamKarteList.length])

  return (
    <div>
      <div className={classes.row}>
        <div className={classNames(classes.box, classes.name, classes.avatarName, getTypeClass(type))}>
          <div className={classes.row}>
            <Avatar className={classes.avatar} src={teamMember.user?.imageUrl ?? undefined} />

            <div style={{ width: '100%' }}>
              <div className={classes.nameText}>{teamMember.fullName}</div>
            </div>
          </div>

          {hasExpandData && (
            <IconButton size="small" onClick={switchOpen}>
              <ChevronUpIcon
                style={{ color: COLOR_ONBOARDING_MAIN, transform: isOpenList ? undefined : 'rotate(180deg)' }}
              />
            </IconButton>
          )}
        </div>
        {targetTeamKarteListWithTime.length ? (
          <DataRowValues
            page={page}
            teamKarte={topData}
            size={size}
            resultCount={targetTeamKarteList.length}
            setSelectedTeamKarte={setSelectedTeamKarte}
            displayTimeState={{ displayTime, setDisplayTime }}
            handleCloseList={handleCloseList}
          />
        ) : (
          <div className={classes.noData}>_</div>
        )}
      </div>
      {hasExpandData && isOpenList && (
        <div>
          <div className={classes.scrollable}>
            {targetTeamKarteListWithTime
              // メンバーの1行目に表示される診断結果は除外する
              .filter((itemWithTime) => itemWithTime.time !== displayTime)
              .map((item, index) => (
                <div key={`team-karte-data-row-${index}`} className={classes.row}>
                  <div className={classNames(classes.box, classes.name)} />
                  <DataRowValues
                    page={page}
                    teamKarte={item}
                    size={size}
                    resultCount={targetTeamKarteList.length - (index + 1)}
                    setSelectedTeamKarte={setSelectedTeamKarte}
                    isExpandRow
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    box: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: VALUE_HEIGHT,
    },
    name: {
      fontSize: 12,
      padding: 16,

      width: '50%',
      [theme.breakpoints.up('sm')]: {
        minWidth: NAME_WIDTH,
        width: NAME_WIDTH,
      },
    },
    avatarName: {
      color: `${TEXT_GRAY_DARK} !important`, // アバターの名前は診断結果に左右されないよう important を設定
      marginBottom: 2,
    },
    avatar: {
      minHeight: 24,
      minWidth: 24,
      maxHeight: 24,
      maxWidth: 24,
      overflow: 'hidden',
      backgroundColor: '#eee',
      marginRight: 8,
      borderRadius: 24,
    },
    nameText: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      maxWidth: MAX_NAME_WIDTH,
      overflow: 'hidden',
      overflowWrap: 'anywhere',
    },
    noData: {
      fontSize: 10,
      backgroundColor: '#fbfbfb',
      height: VALUE_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      width: '50%',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    scrollable: {
      maxHeight: VALUE_HEIGHT * 4 + 2 * 4,
      overflow: 'auto',
    },
  }),
  { name: 'DataRow' }
)
