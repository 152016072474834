import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import Ranking1Svg from '../../../../assets/ranking1.svg'
import Ranking2Svg from '../../../../assets/ranking2.svg'
import Ranking3Svg from '../../../../assets/ranking3.svg'

type Props = {
  rank: number
}

export const DataRowHeaderRanking: React.FC<Props> = ({ rank }) => {
  const classes = useStyles()

  switch (rank) {
    case 1:
      return (
        <>
          <div className={classes.row}>
            <img src={Ranking1Svg} className={classes.rankingIcon} />
            1位
          </div>
        </>
      )
    case 2:
      return (
        <>
          <div className={classes.row}>
            <img src={Ranking2Svg} className={classes.rankingIcon} />
            2位
          </div>
        </>
      )
    case 3:
      return (
        <>
          <div className={classes.row}>
            <img src={Ranking3Svg} className={classes.rankingIcon} />
            3位
          </div>
        </>
      )
    case 4:
      return <>4位</>
    case 5:
      return <>5位</>
    case 6:
      return <>6位</>
    case 7:
      return <>7位</>
    case 8:
      return <>8位</>
    case 9:
      return <>9位</>
    default:
      return <></>
  }
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    rankingIcon: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  }),
  { name: 'DataRowHeaderRanking' }
)
