import Auth from '@aws-amplify/auth'
import { connect, ConnectedProps } from 'react-redux'

import { RootStateType } from '../../store'
import { UserActions } from '../../stores/user/action'

const mapDispatchToProps = (dispatch: any) => ({
  signout: () => {
    dispatch(UserActions.signout.started({ params: {} }))

    Auth.signOut()
      .then(() => {
        dispatch(
          UserActions.signout.done({
            result: {},
            params: {},
          })
        )
      })
      .catch((err) => {
        dispatch(
          UserActions.signout.failed({
            error: err,
            params: {},
          })
        )
      })
  },
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
