import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { OnboardingActionCategory } from 'utils/generated'

export const categories: { value: OnboardingActionCategory | 'ALL'; label: string }[] = [
  { value: 'ALL', label: '全て' },
  {
    value: OnboardingActionCategory.Category1,
    label: onboardingActionCategoryLabel(OnboardingActionCategory.Category1),
  },
  {
    value: OnboardingActionCategory.Category2,
    label: onboardingActionCategoryLabel(OnboardingActionCategory.Category2),
  },
  {
    value: OnboardingActionCategory.Category3,
    label: onboardingActionCategoryLabel(OnboardingActionCategory.Category3),
  },
  {
    value: OnboardingActionCategory.Category4,
    label: onboardingActionCategoryLabel(OnboardingActionCategory.Category4),
  },
  {
    value: OnboardingActionCategory.Category5,
    label: onboardingActionCategoryLabel(OnboardingActionCategory.Category5),
  },
]
