import { StringKeyObject } from 'pages/karte/member/questionnaire/index.container'
import { IKarteMemberQuestionnaireAnswerSet } from 'pages/karte/member/questionnaire/interface'
import {
  bubbleTexts,
  circleGraph,
  forms,
  questionnaireAnswerPatterns,
  questionnaireBubbles,
} from 'pages/karte/member/static'

export type KarteResult = {
  circleItems: CircleItem[]
  bubbles: Bubble[]
  points: Point[]
  characteristics: Characteristics
  about: About
  motivation: string
}

export type CircleItem = {
  circleGroup: string
  label: string
  description: string
  value: number
}

export type Bubble = {
  label: string
  color: string
  size: string
}

export type Point = {
  title: string
  description: string
  value: number
  color: string
  link: string
}

export type Characteristics = {
  good: string[]
  bad: string[]
}
export type About = {
  short: string
  long: string
}

export const calcKarteResult = (calculable: CalculableKarte[]): KarteResult => {
  const results = {
    about: {
      short: '',
      long: '',
    },
    motivation: '',
  }

  // Calculate about & motivation
  const formTotals: { group: string; weight: number; value: number }[] = []
  const formQuestionsTotals: {
    group: string
    key: string
    label: string
    weight: number
    value: number
  }[] = []
  const formCircleTotals: {
    circleGroup: string
    label: string
    description: string
    value: number
  }[] = circleGraph.map((item) => ({
    circleGroup: item.key,
    label: item.label,
    description: item.description,
    value: 0,
  }))

  const formValues: StringKeyObject = {}

  for (let formIndex = 0; formIndex < calculable.length; formIndex++) {
    const form = calculable[formIndex]

    for (let questionIndex = 0; questionIndex < form.questions.length; questionIndex++) {
      const question = form.questions[questionIndex]
      const addValue = question.value
      const addedValue = formValues[form.group]
        ? formValues[form.group].value
          ? formValues[form.group].value + addValue
          : addValue
        : addValue
      formValues[form.group] = {
        value: addedValue,
      }
      formValues[form.group].value += question.value

      formQuestionsTotals.push({
        group: form.group,
        key: question.key,
        label: question.label,
        weight: question.weight || 999,
        value: question.value,
      })

      const circleGraphIndex = circleGraph.findIndex((item) => item.groups.indexOf(form.group) !== -1)
      const circleDataGroupIndex = formCircleTotals.findIndex(
        (item) => item.circleGroup === circleGraph[circleGraphIndex].key
      )

      formCircleTotals[circleDataGroupIndex].value += question.value
    }

    if (form.questions.length > 0) {
      formTotals.push({
        group: form.group,
        weight: form.weight || 999,
        value: formValues[form.group].value / form.questions.length,
      })
    }
  }

  formTotals.sort((a, b) => {
    if (a.value > b.value) return -1
    if (a.value === b.value) return a.weight > b.weight ? 1 : -1
    return 1
  })

  formQuestionsTotals.sort((a, b) => {
    if (a.value > b.value) return -1
    if (a.value === b.value) return a.weight > b.weight ? 1 : -1
    return 1
  })

  formCircleTotals.sort((a, b) => {
    return a.value < b.value ? 1 : -1
  })
  const patternIndex = questionnaireAnswerPatterns.findIndex(
    (pattern) => pattern.first === formTotals[0].group && pattern.second === formTotals[1].group
  )
  if (patternIndex !== -1) {
    const pattern = questionnaireAnswerPatterns[patternIndex]
    results.about = pattern.about
    results.motivation = pattern.motivation
  }

  // Calculate bubbles & points
  const bubbles = []
  let minPoint = 0
  const points: { title: string; description: string; value: number; color: string; link: string }[] = []
  for (let formIndex = 0; formIndex < formTotals.length; formIndex++) {
    const form = formTotals[formIndex]
    const bubble = questionnaireBubbles[form.group] as {
      label: string
      color: string
    }
    if (formIndex <= 2) {
      minPoint = form.value
    }
    bubbles.push({
      label: bubbleTexts[form.group] && bubbleTexts[form.group].shortLabel,
      color: bubble.color,
      size: form.value >= minPoint ? 'large' : 'small',
    })
    points.push({
      title: bubbleTexts[form.group] && bubbleTexts[form.group].label,
      description: bubbleTexts[form.group].description,
      value: form.value,
      color: bubble.color,
      link: bubbleTexts[form.group].linkMember,
    })
  }

  // Calculate characteristics
  const characteristicsGood: string[] = []
  const characteristicsBad: string[] = []

  for (let characteristicIndex = 0; characteristicIndex < formQuestionsTotals.length; characteristicIndex++) {
    // calculate good
    if (
      formQuestionsTotals[characteristicIndex].value === formQuestionsTotals[0].value &&
      characteristicsGood.length < 10
    ) {
      characteristicsGood.push(formQuestionsTotals[characteristicIndex].label)
    }

    // calculate bad
    if (
      formQuestionsTotals[characteristicIndex].value === formQuestionsTotals[formQuestionsTotals.length - 1].value &&
      characteristicsBad.length < 10
    ) {
      characteristicsBad.push(formQuestionsTotals[characteristicIndex].label)
    }
  }

  const radomizer = (array: string[]) => {
    let count = array.length,
      randomnumber,
      temp
    while (count) {
      randomnumber = (Math.random() * count--) | 0
      temp = array[count]
      array[count] = array[randomnumber]
      array[randomnumber] = temp
    }
  }

  radomizer(characteristicsGood)
  radomizer(characteristicsBad)

  return {
    ...results,
    circleItems: formCircleTotals,
    bubbles,
    points,
    characteristics: {
      good: characteristicsGood,
      bad: characteristicsBad,
    },
  }
}

export interface CalculableKarte {
  group: string
  weight: number
  questions: KarteQuestion[]
}

export interface KarteQuestion {
  key: string
  label: string
  value: number
  weight: number
}

// calculable.push({
//     group: k,
//     weight: allQuestions[k].weight || 999,
//     questions: allQuestions[k].data,
//   })

export const getCalculableKarteList = (answers: IKarteMemberQuestionnaireAnswerSet): CalculableKarte[] => {
  const calculable: CalculableKarte[] = []
  // const simple = []

  const allQuestions: StringKeyObject = {}

  for (let formIndex = 0; formIndex < forms.length; formIndex++) {
    const form = forms[formIndex]
    if (form.weight) {
      // const questions = []

      for (let fieldIndex = 0; fieldIndex < form.fields.length; fieldIndex++) {
        const field = form.fields[fieldIndex]
        const value = answers[field.group][field.key]

        const prevData = allQuestions[field.group]
          ? allQuestions[field.group].data
            ? [...allQuestions[field.group].data]
            : []
          : []
        allQuestions[field.group] = {
          weight: form.weight,
          data: [
            ...prevData,
            {
              key: field.key,
              label: field.label,
              weight: field.weight || 999,
              value: value ? parseInt(value, 0) : 0,
            },
          ],
        }

        // questions.push({
        //   key: field.key,
        //   weight: field.weight || 999,
        //   value,
        // })
      }
    } else {
      // if (form.group) {
      //   simple.push({
      //     group: form.group,
      //     values: answers[form.group],
      //   })
      // }
    }
  }

  for (const k of Object.keys(allQuestions)) {
    calculable.push({
      group: k,
      weight: allQuestions[k].weight || 999,
      questions: allQuestions[k].data,
    })
  }

  return calculable
}

export const makeEvenPoints = (point: number) => {
  return parseFloat(((point / 14) * 10).toFixed(1))
}
