import * as React from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useTeamAnketBuildingSummaryListByTeam } from 'services/api/anket'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { LoadingCircular, DefaultButton } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import {
  TeamBuildingStatus,
  TeamStatusUsage,
  TeamTool,
  TeamAnketBuildingSummaryStatus,
  TeamAnketAnswerStatus,
  TeamBuildingReviewStatus,
} from 'utils/generated'

import info from '../../assets/info.svg'
import { UsageWayList } from '../UsageWayList'

import { Karte, Kickoff, Ghost, TB, Reflection } from './contents'

import { constants, useHistory, Pages } from 'assets'

export const firstTab = 'firstTab'

type LocationState = {
  [firstTab]: string
}

const TABS = [TeamTool.Karte, TeamTool.Building, TeamTool.Ghost, TeamTool.BuildingReview, TeamTool.BuildingCheck]

const tabItems = {
  [TABS[0]]: {
    label: 'カルテ',
    content: Karte,
    usageWays: [
      {
        heading: 'カルテファシリテーションマニュアル',
        desc: 'カルテを使ってチームで対話を行う際のファシリテーションマニュアルです。カルテの説明及び2つのカルテ対話のタイムライン、その他ポイントを記載しています。カルテ対話を行う際の参考になさって下さい。',
        link: 'https://cocolabo.club/hint/tips/15204c11-79a7-4047-9b95-8e73b68c9ce7',
      },
    ],
  },
  [TABS[1]]: {
    label: 'キックオフ',
    content: Kickoff,
    usageWays: [
      {
        heading: 'キックオフファシリテーションマニュアル',
        desc: 'キックオフを使ってチームで対話を行う際のファシリテーションマニュアルです。キックオフの説明及びキックオフ対話のタイムライン、その他ポイントを記載しています。キックオフ対話を行う際の参考になさって下さい。',
        link: 'https://cocolabo.club/hint/tips/2fb4c5dc-fbee-4802-a455-a654ddf3159b',
      },
    ],
  },
  [TABS[2]]: {
    label: 'オバケ',
    content: Ghost,
    usageWays: [
      {
        heading: 'オバケファシリテーションマニュアル',
        desc: 'オバケを使ってチームで対話を行う際のファシリテーションマニュアルです。オバケの説明及びオバケ対話のタイムラインや推奨アクションを記載しています。オバケ対話を行う際の参考になさって下さい。',
        link: 'https://cocolabo.club/hint/tips/4835235a-eafd-46c3-ad34-68a44d51decb',
      },
    ],
  },
  [TABS[3]]: {
    label: 'リフレクション',
    content: Reflection,
  },
  [TABS[4]]: {
    label: 'TBチェック',
    content: TB,
    usageWays: [
      {
        heading: 'TB（チームビルディング）チェックの使い方',
        desc: 'TBチェックについての説明やTBチェックの使い方について説明します。',
        link: 'https://cocolabo.club/hint/tips/553e408d-ff8a-4289-be44-d8c02a74fb6a',
      },
    ],
  },
}

type Tab = keyof typeof tabItems
export const Tabs: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { route } = useHistory()
  const location = useLocation()
  const locationState = location.state as LocationState
  // TODO もし1000件を超えるデータを表示するとしたら、既存の UI では厳しいので、そうした要望があったら UI・ロジックともに改修する。
  const summaryByTeam = useTeamAnketBuildingSummaryListByTeam(teamId, 1000)

  const { currentTeam, loadingTeam, teamBuilding, loadingTeamBuilding, isLeader, isMember } =
    React.useContext(HooksContext)
  const { teamBuildingReview } = React.useContext(TeamBuildingReviewHooksContext)
  const isMdUp = useCustomMediaQuery('up', 'md')
  const [activeTabs, setActiveTabs] = React.useState(TABS)
  const [checkedTab, setCheckedTab] = React.useState<Tab | undefined>(undefined)

  React.useEffect(() => {
    if (!currentTeam?.teamTools.includes(TeamTool.Building)) {
      setActiveTabs((prevTabs) =>
        prevTabs.filter((tab) => tab !== TeamTool.Building && tab !== TeamTool.BuildingReview)
      )
    }

    if (!currentTeam?.teamTools.includes(TeamTool.Karte)) {
      setActiveTabs((prevTabs) => prevTabs.filter((tab) => tab !== TeamTool.Karte))
    }

    if (!currentTeam?.teamTools.includes(TeamTool.Ghost)) {
      setActiveTabs((prevTabs) => prevTabs.filter((tab) => tab !== TeamTool.Ghost))
    }

    if (teamBuilding?.status !== TeamBuildingStatus.Completed) {
      setActiveTabs((prevTabs) => prevTabs.filter((tab) => tab !== TeamTool.BuildingReview))
    }

    if (!currentTeam?.teamTools.includes(TeamTool.BuildingCheck)) {
      setActiveTabs((prevTabs) => prevTabs.filter((tab) => tab !== TeamTool.BuildingCheck))
    }

    return () => {
      setActiveTabs(TABS)
    }
  }, [currentTeam, teamBuilding])

  React.useEffect(() => {
    if (locationState) {
      setCheckedTab(locationState[firstTab])
      window.history.replaceState({}, '') // state をクリア　※クリアしないとリロードしても残ってしまう
    } else {
      setCheckedTab(activeTabs[0])
    }
  }, [activeTabs, locationState])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTab(e.target.value as Tab)
  }
  const handleClickTools = () => {
    route.push(Pages.TeamsAbout, { teamId })
  }

  const isActiveTab = (activeTab: Tab) => checkedTab === activeTab

  const Content = checkedTab ? tabItems[checkedTab].content : () => <></>
  const activeUsageWays = checkedTab ? tabItems[checkedTab].usageWays : null

  const isLoading = loadingTeam || loadingTeamBuilding

  if (isLoading) return <LoadingCircular loading={isLoading} />

  return (
    <>
      {currentTeam?.statusUsage === TeamStatusUsage.InUse &&
      currentTeam?.teamTools &&
      currentTeam.teamTools.length > 0 ? (
        <>
          <div className={classes.pageTitle}>ツール実施状況</div>
          <div className={classes.rootContainer}>
            <div className={classes.tabHeader}>
              {activeTabs.map((tab) => (
                <React.Fragment key={`${tab}-feature-tab-key`}>
                  <label
                    className={classNames([classes.tabLabel, isActiveTab(tab) && 'checked'])}
                    htmlFor={`${tab}-tab`}
                  >
                    {isMdUp &&
                      (isLeader || isMember) &&
                      tabItems[tab].label === 'TBチェック' &&
                      summaryByTeam.teamSummaryItem?.summaryStatus === TeamAnketBuildingSummaryStatus.Open &&
                      summaryByTeam.teamSummaryItem?.answerStatus === TeamAnketAnswerStatus.Unanswered && (
                        <div className={classes.pointerRed}></div>
                      )}
                    {isMdUp &&
                    tabItems[tab].label === 'リフレクション' &&
                    teamBuildingReview &&
                    teamBuildingReview.status !== TeamBuildingReviewStatus.Completed ? (
                      <div className={classes.pointerRed}></div>
                    ) : null}
                    {tabItems[tab].label}
                  </label>
                  <input
                    id={`${tab}-tab`}
                    type="radio"
                    value={tab}
                    onChange={handleChange}
                    checked={isActiveTab(tab)}
                  />
                </React.Fragment>
              ))}
            </div>

            <div className={classes.tabContent}>
              <Content
                summaryByTeam={summaryByTeam}
                currentTeam={currentTeam}
                isLeader={isLeader}
                isMember={isMember}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.pageTitle}>利用ツール</div>
          <div className={classes.rootContainer}>
            <div className={classes.noToolContent}>
              <div className={classes.noToolTitle}>
                チームにあった利用プランを選択してツールの利用をはじめましょう。
              </div>

              <p className={classes.noToolDesc}>
                このエリアには利用しているツールのアクティビティが表示されます。
                {isMdUp && <br />}
                メニューの「利用ツール」から選択して利用を開始してください。（利用プランに応じて使えるツールが変わります。）
                <br />
                各ツールの機能と特徴をご確認の上で、チームにあったツールをご利用ください。
              </p>

              <div className={classes.noToolInfoWrapper}>
                <div className={classes.noToolInfo}>
                  <img src={info} alt="information" />
                  <span>利用するツールのみONにすることでダッシュボードをカスタマイズできます！</span>
                </div>
              </div>

              <div className={classes.buttonWrapper}>
                <DefaultButton
                  title={'利用するツールを選択する'}
                  color="team_white"
                  onClick={handleClickTools}
                  style={{
                    padding: '8px 16px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    minWidth: 176,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* ツール活用方法 */}
      {activeUsageWays && activeUsageWays.length ? <UsageWayList list={activeUsageWays} /> : null}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    pageTitle: {
      marginBottom: 16,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    rootContainer: {
      marginBottom: 40,
    },
    tabHeader: {
      display: 'flex',
      '& input': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 -16px',
        padding: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 42,
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
    },
    tabLabel: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&.checked': {
        backgroundColor: constants.COLOR_WHITE,
        color: constants.COLOR_MAIN_NEW,
      },
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        height: '100%',
        fontSize: 9,
        borderRadius: 13,
      },
      [theme.breakpoints.up('md')]: {
        width: 108,
        height: 35,
        '&:first-of-type': {
          borderTopLeftRadius: 4,
        },
        '&:last-of-type': {
          borderTopRightRadius: 4,
        },
      },
    },
    pointerRed: {
      top: 0,
      right: 0,
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: constants.COLOR_RED4,
      borderRadius: 4,
      zIndex: 10,
    },
    tabContent: {
      width: '100%',
      padding: '42px 24px',
      backgroundColor: constants.COLOR_WHITE,
      [theme.breakpoints.down('sm')]: {
        padding: '24px 0',
        // backgroundColor: 'inherit',
        backgroundColor: 'transparent',
      },
    },
    noToolContent: {
      width: '100%',
      padding: '42px 24px',
      backgroundColor: constants.COLOR_WHITE,
      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px',
      },
    },
    noToolTitle: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    noToolDesc: {
      margin: '16px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    noToolInfoWrapper: {
      marginTop: 20,
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    noToolInfo: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 16,
      padding: '16px 14px',
      backgroundColor: constants.COLOR_WHITE2,
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        columnGap: 8,
      },
      '& img': {
        [theme.breakpoints.down('sm')]: {
          alignSelf: 'flex-start',
        },
      },
      '& span': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
      },
    },
    buttonWrapper: {
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }),
  { name: 'Tabs' }
)
