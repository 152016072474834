import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { BackButton } from 'pages/hint/_shared/components/buttons/backButton/Index'
import { useBackButton } from 'pages/hint/_shared/components/buttons/backButton/useBackButton'
import { PagingButton } from 'pages/hint/_shared/components/buttons/pagingButton/Index'
import { useCategory } from 'pages/hint/_shared/components/categories/useCategory'
import { Loading } from 'pages/hint/_shared/components/loading/Index'
import { Search } from 'pages/hint/_shared/components/search/Index'
import { Slide } from 'pages/hint/_shared/components/slide/Index'
import { TabPanel } from 'pages/hint/_shared/components/tabs/tabPanel/Index'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useDocument } from 'pages/hint/_shared/hooks/useDcoment'
import { useLink } from 'pages/hint/_shared/hooks/useLink'
import { useLoading } from 'pages/hint/_shared/hooks/useLoading'
import { Column } from 'pages/hint/_shared/layouts/column/Index'
import { Main } from 'pages/hint/_shared/layouts/main/Index'
import { Pager } from 'pages/hint/_shared/layouts/pager/Index'
import { Sidebar } from 'pages/hint/_shared/layouts/sidebar/Index'
import { Articles } from 'pages/hint/search/index/components/articles/Index'
import { Modal } from 'pages/hint/tips/_shared/components/modal/modal/Index'



import { Empty } from './components/empty/Index'
import { Result } from './components/result/Index'
import { useResult } from './components/result/useResult'
import { useSearchIndex } from './hooks/useSearchIndex'
import { useSearchNextToken } from './hooks/useSearchNextToken'
import { Wrapper } from './layouts/wrapper/Index'

import * as constants from 'assets/constants'
import * as pages from 'assets/pages'

// -----------------------------
// Component
// -----------------------------
const Index = () => {
  const document = useDocument()
  const link = useLink()
  const category = useCategory()
  const back = useBackButton()
  const location = useLocation()
  const result = useResult()
  const loading = useLoading()
  const searchIndex = useSearchIndex()
  const searchNextToken = useSearchNextToken()
  const context = React.useContext(hintContext)

  const hintSearchSelectors = useSelector((state: RootStateType) => state.hint.hintSearchList)
  const consultationSelectors = useSelector((state: RootStateType) => state.hint.consultationSearchList)

  React.useEffect(() => {
    if (link.isReload()) {
      if (location.pathname.includes('tips')) {
        window.location.href = pages.HINT_TIPS_HOME
      } else {
        window.location.href = pages.HINT_FAQ_HOME
      }
    }
  }, [link, location.pathname])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.onSetDocumentSearch(result.getResultCategory())

    loading.setLoad(true)
    if (searchIndex.isSelectFetch()) {
      searchNextToken.getHintTokenWithFetch(searchIndex.getCatgory())
    } else {
      searchNextToken.getConsultationTokenWithFetch(searchIndex.getCatgory())
    }
    loading.onDoneLoaded(false, 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {/** Modal */}
      {context.isShowModal && (
        <Slide in={context.isShowModal} direction={'up'}>
          <Modal />
        </Slide>
      )}

      {/** Wrapper */}
      <Wrapper>
        {/** Main */}
        <Main>
          <Column>
            <Box display="flex" justifyContent="flex-start" mb={{ xs: '12px', lg: '30px' }}>
              <BackButton onClick={() => back.goBack(pages.HINT_TIPS_HOME)} />
            </Box>
            <Box>
              <Result
                searchCategory={result.getResultCategory()}
                searchCount={hintSearchSelectors?.items.length || consultationSelectors.items.length}
              />
            </Box>

            {/** Panel */}
            <TabPanel>
              <Loading isLoad={loading.isLoad()}>
                {searchIndex.isShowEmpty() && (
                  <Empty
                    text={
                      <>
                        お探しの記事は見つかりませんでした。
                        <br />
                        再度検索ください。
                      </>
                    }
                  />
                )}

                {!searchIndex.isShowEmpty() && (
                  <>
                    {searchIndex.isShowHint() ? (
                      <Articles datas={hintSearchSelectors} searchType={'HINT'} />
                    ) : (
                      <Articles datas={consultationSelectors} searchType={'CONSULTATION'} />
                    )}

                    {searchIndex.isShowPager() && (
                      <Pager>
                        {searchIndex.isHome() && (
                          <PagingButton
                            category={'search'}
                            color={constants.COLOR_GREEN_DARK}
                            body={'前のページ'}
                            action={'preview'}
                          />
                        )}
                        {location.pathname.includes('tips') &&
                          searchIndex.isShowNextButton() &&
                          hintSearchSelectors.nextToken && (
                            <PagingButton
                              category={'search'}
                              color={constants.COLOR_GREEN_DARK}
                              body={'次のページ'}
                              action={'next'}
                            />
                          )}
                        {!location.pathname.includes('tips') &&
                          searchIndex.isShowNextButton() &&
                          consultationSelectors.nextToken && (
                            <PagingButton
                              category={'search'}
                              color={constants.COLOR_GREEN_DARK}
                              body={'次のページ'}
                              action={'next'}
                            />
                          )}
                      </Pager>
                    )}
                  </>
                )}
              </Loading>
            </TabPanel>
          </Column>
        </Main>

        {/** Sidebar */}
        <Sidebar>
          <Search presenter={category} currentCategory={result.getResultCategory()} />
        </Sidebar>
      </Wrapper>
    </>
  )
}

export default Index
