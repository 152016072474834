import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'
import topAction from '../action'

import action from './action'


const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onFetch: (leaderId: string, id: string) => dispatch(action.fetch.started({ leaderId, id })),
  onResetError: () => dispatch(action.resetError({})),
  onSendResults: async (id: string, leaderId: string, memberEmail: string, sendToLeader: boolean) =>
    dispatch(topAction.sendResults.started({ id, leaderId, memberEmail, sendToLeader })),
})

const mapStateToProps = (state: RootStateType) => {
  const { isLoading, error } = state.pages.karteMemberResults
  const data = state.pages.karteMemberResults.data ? JSON.parse(state.pages.karteMemberResults.data) : null
  return {
    user: { ...state.user },
    data,
    isLoading,
    error,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
