import React from 'react'

import { TeamKarte } from 'utils/generated'

import { queryGetTeamKarte, queryGetTeamKarteList } from './graphql'

export const useTeamKarte = (id?: string) => {
  const [teamKarte, setTeamKarte] = React.useState<TeamKarte | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!id) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamKarte({
        id: id,
      })
      if (response) {
        setTeamKarte(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { teamKarte, loading, loaded, error, refresh } as const
}

export const useTeamKarteList = (teamId?: string) => {
  const [teamKarteList, setTeamKarteList] = React.useState<TeamKarte[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamKarteList({
        filter: {
          teamId,
        },
      })
      if (response) {
        setTeamKarteList(response?.items as TeamKarte[]) // TODO: remove as
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { teamKarteList, loading, loaded, error, refresh } as const
}
