import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

type StyleProps = {
  backgroundColor?: string
  ownStyles?: React.CSSProperties
}

type Props = {
  content: string
  positionOfTail?: 'left' | 'bottom'
} & StyleProps

export const SpeechBubble: React.FC<Props> = (props) => {
  SpeechBubble.defaultProps = { positionOfTail: 'left' }
  const classes = useStyles({ backgroundColor: props.backgroundColor })

  return (
    <div
      className={classNames([classes.speechBubbleRoot, props.positionOfTail])}
      style={{
        backgroundColor: props.backgroundColor,
        ...props.ownStyles,
      }}
    >
      {props.content}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  speechBubbleRoot: (props: StyleProps) => ({
    position: 'relative',
    borderRadius: 8,
    backgroundColor: props.backgroundColor,
    padding: '16px',
    margin: '0 auto',
    '&.left': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 'calc(50% - 5px)',
        left: '-8px',
        backgroundColor: props.backgroundColor,
        borderStyle: 'solid',
        borderWidth: ' 4px 8px 4px 0',
        borderColor: `#FFFFFFFF ${props.backgroundColor} #FFFFFFFF #FFFFFFFF`,
      },
    },
    '&.bottom': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '10%',
        backgroundColor: props.backgroundColor,
        borderStyle: 'solid',
        borderWidth: '8px 4px 0 4px',
        borderColor: `${props.backgroundColor} #FFFFFFFF #FFFFFFFF`,
      },
    },
  }),
}))
