import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import { I18n } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'

import { AccountType, MutationPrepareAccountArgs, User } from 'utils/generated'

import isConfirmed from '../api/isConfirmed'



import { PatternEmailLowerCase } from 'assets/patterns'
I18n.setLanguage('ja')

export interface ISignup {
  email: string
  password: string
  username: string
  passwordRe: string
}

export interface SignUpOption {
  accountType?: AccountType
}

type Payload = {
  user: ISignup
  option: SignUpOption
}

const signup = async (paylaod: Payload) => {
  const { user, option } = paylaod
  if (user.email.match(new RegExp(PatternEmailLowerCase))) {
    try {
      const res = await Auth.signUp({
        username: user.email,
        password: user.password,
        attributes: {
          nickname: user.username,
        },
      })
      await mutationPrepareAccount({
        input: {
          id: res.userSub,
          email: res.user.getUsername(),
          accountType: option.accountType ?? AccountType.Company,
        },
      })
      return { email: res.user.getUsername() }
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        try {
          e.isConfirmed = await isConfirmed(user.email)
        } catch (ee) {
          console.log(ee)
        }
        throw e
      } else {
        throw e
      }
    }
  } else {
    throw new Error('メールアドレスの形式が正しくありません')
  }
}

export default signup

const mutationPrepareAccount = async (args: MutationPrepareAccountArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation prepareAccount($input: UserPrepareAccountInput!) {
        prepareAccount(input: $input) {
          id
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ prepareAccount: User }>

  return response.data?.prepareAccount as User
}
