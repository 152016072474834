import * as React from 'react'

import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'

import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { useTeam, useTeams } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


interface IOwnProps {
  teamId?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, teamId }) => {
  const { teams } = useTeams(OnboardingTeamMemberRole.Admin)
  const { team } = useTeam(teamId)
  const history = useOnbHistory()
  const teamLabel = team ? `${team.title}(${team.memberCount || 0})` : ''

  return (
    <div className={classes.container}>
      <Dropdown
        items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
        value={teamId}
        handleOnClick={(teamId) => history.push(OnbPages.MemberManageAddMember, {teamId})}
        keyPrefix={'member-add-three-person'}
        teamLabel={teamLabel}
        borderRadius={8}
        border={constants.COLOR_ONBOARDING_GRAY_LIGHT}
        paperHeightMinus={240}
      />
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
      paddingLeft: 16,
      paddingRight: 16,
    },
    teams: {
      flex: 1,
    },
    persons: {
      flex: 1,
      marginLeft: 16,
    },
  })

export default withStyles(useStyles)(Index)
