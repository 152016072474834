import React from 'react'

import { Team, TeamMember, TeamMemberRole } from 'utils/generated'

import { useCurrentTeamMember, useTeam } from '../hooks'
import { isAdmin, isLeader, isMember } from '../utils/teamMember'

interface Props {
  // Team
  teamId?: string
  setTeamId: (value: string) => void
  team?: Team
  refreshTeam: () => void
  errorTeam?: Error[]
  // TeamMember
  currentTeamMember?: TeamMember
  currentTeamMemberRole?: TeamMemberRole
  currentTeamMemberLoaded: boolean
  isAdmin?: boolean
  isLeader?: boolean
  isMember?: boolean
  refreshCurrentTeamMember: () => Promise<void>
}

export const TeamsHooksContext = React.createContext<Props>({} as Props)

export const TeamsHooksContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [teamId, setTeamId] = React.useState<string | undefined>()

  const { team, refresh: refreshTeam, error: errorTeam } = useTeam(teamId)

  const {
    teamMember: currentTeamMember,
    refresh: refreshCurrentTeamMember,
    role: currentTeamMemberRole,
    loaded: currentTeamMemberLoaded,
  } = useCurrentTeamMember(teamId)

  return (
    <TeamsHooksContext.Provider
      value={{
        // Team
        teamId,
        setTeamId,
        team,
        refreshTeam,
        errorTeam,
        // TeamMember
        currentTeamMember,
        currentTeamMemberRole,
        currentTeamMemberLoaded,
        isAdmin: isAdmin(currentTeamMember),
        isLeader: isLeader(currentTeamMember),
        isMember: isMember(currentTeamMember),
        refreshCurrentTeamMember,
      }}
    >
      {children}
    </TeamsHooksContext.Provider>
  )
}
