import * as React from 'react'

import { makeStyles, Theme, Box, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import { Comment } from 'pages/onboarding/components/comment'
import { FormCharCount } from 'pages/onboarding/components/formCharCount'
import { useOnboardingActionsActions } from 'pages/onboarding/hooks/action'
import { OnboardingActionComment, OnboardingTeamMember } from 'utils/generated'

import { CommentAvatar } from './atoms/CommentAvatar'

import IconsDelete from '../assets/Icons-24px-Delete.svg'
import IconsEdit from '../assets/Icons-24px-Edit.svg'
import * as constants from 'assets/constants'

export type OwnProps = {
  item: OnboardingActionComment
  teamMembers: OnboardingTeamMember[]
  userId?: string
}

type Props = OwnProps

interface IInput {
  text: string
}

export const CommentListItem: React.FC<Props> = ({ item, teamMembers, userId }) => {
  const classes = useStyles()

  const [openEdit, setOpenEdit] = React.useState(false)

  const { deleteComment, updateComment } = useOnboardingActionsActions()

  const handleDelete = () => {
    const confirm = window.confirm('本当に削除しますか？')

    if (confirm) {
      deleteComment(item.id, item.actionId, item.actionOwnerId)
      setOpenEdit(false)
    }
  }

  const { register, handleSubmit, errors, watch } = useForm<IInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IInput) => {
    await updateComment(item.id, item.actionId, item.actionOwnerId, data.text)
    setOpenEdit(false)
  }

  return (
    <>
      <CommentAvatar item={item} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {openEdit ? (
          <>
            <TextField
              name="text"
              classes={{
                root: classes.inputBox,
              }}
              inputRef={register({
                required: true,
                maxLength: 10000,
              })}
              maxRows={8}
              multiline
              autoFocus
              defaultValue={item.text}
            />
            <FormCharCount length={watch('text', item.text).length} maxLength={10000} />
          </>
        ) : (
          <Box width={'100%'} paddingLeft={'48px'}>
            <Comment text={item.text} teamMembers={teamMembers} />
          </Box>
        )}
        {item.userId === userId ? (
          <>
            <div className={classes.buttonContainer}>
              {!openEdit ? (
                <>
                  <div onClick={() => setOpenEdit(true)}>
                    <img className={classes.iconsEdit} src={IconsEdit} alt="edit" />
                  </div>
                  <div onClick={handleDelete}>
                    <img className={classes.iconsDelete} src={IconsDelete} alt="delete" />
                  </div>
                </>
              ) : (
                <>
                  {openEdit ? (
                    <>
                      <div className={classes.submitTextBox}>
                        <div
                          className={classes.cancelText}
                          onClick={() => {
                            setOpenEdit(false)
                          }}
                        >
                          キャンセル
                        </div>
                        <Button
                          body={'保存'}
                          submit={true}
                          disabled={Boolean(errors.text)}
                          bgColor={constants.COLOR_MAIN}
                          onClick={handleSubmit}
                          fullWidth={false}
                          textColor={Boolean(errors.text) ? constants.COLOR_GRAY_DARK : constants.COLOR_ONBOARDING_MAIN}
                          style={{
                            backgroundColor: '#FFFFFF64',
                            border: 'none',
                            boxShadow: 'none',
                            padding: 0,
                            fontWeight: 'bold',
                            width: 30,
                            minWidth: 30,
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inputBox: {
    width: 'calc(100% - 48px)',
    padding: 8,
    margin: '8px 0 0 48px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    '& .MuiInputBase-root': {
      fontSize: 14,
      padding: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px 0 0 0',
    padding: '0 0 2px 0',
  },
  iconsEdit: {
    cursor: 'pointer',
  },
  iconsDelete: {
    cursor: 'pointer',
    marginLeft: 16,
  },
  submitTextBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelText: {
    margin: '0 24px 0 0',
    color: constants.COLOR_GRAY_DARK,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}))
