import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TOOL_DATA } from 'pages/landing/components/topPageModules/data/tool.data'

import { constants } from 'assets'

type Props = {
  tool: typeof TOOL_DATA[number]
}

export const ToolCard: React.FC<Props> = ({ tool }) => {
  const classes = useStyles()

  return (
    <div key={tool.alphabetName} className={classes.root}>
      <div className={classes.content}>
        <div className={classes.toolName}>
          <h3 className={classes.toolNamePrimary}>{tool.alphabetName}</h3>
          <span className={classes.toolNameSecondary}>{tool.japaneseName}</span>
        </div>
        <p className={classes.toolDesc}>{tool.description}</p>
        <div className={classes.iconContainer}>
          {tool.iconCaptions.map((caption, i) => {
            return (
              <div key={`icon-${tool.alphabetName}-${i}`} className={classes.iconWrapper}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/topPage/tools/icon_${tool.iconPath}_0${i + 1}.png`}
                  className={classes.iconImage}
                  alt={`${tool.japaneseName}のイメージ画像`}
                />
                <span key={caption} className={classes.iconCaption}>
                  {caption}
                </span>
              </div>
            )
          })}
        </div>
      </div>

      <div>
        <img
          src={`${process.env.PUBLIC_URL}/img/hataraku/screenshot-${tool.iconPath}.png`}
          alt="実際のツール使用画面"
          className={classes.toolImage}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      gap: 40,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 16,
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      [theme.breakpoints.down('sm')]: {
        gap: 16,
      },
    },

    toolName: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },

    toolNamePrimary: {
      margin: 0,
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 36,
      fontWeight: 'bold',
      lineHeight: '32px',
    },

    toolNameSecondary: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },

    toolDesc: {
      margin: 0,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeigth: 400,
      lineHeight: '28px',
      whiteSpace: 'pre-wrap',
    },

    iconContainer: {
      display: 'flex',
      gap: 24,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },

    iconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
    },

    iconImage: {
      width: 80,
      height: 80,
      [theme.breakpoints.down('xs')]: {
        width: 60,
        height: 60,
      },
    },

    iconCaption: {
      color: constants.TEXT_GRAY_DARK,
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '24px',
    },

    toolImage: {
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'ToolCard' }
)
