import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { IResetPassword } from '../../services/amplify/resetPassword'
import { RootStateType } from '../../store'
import { UserActions } from '../../stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetPassword: (user: IResetPassword) => dispatch(UserActions.resetPassword.started({ user })),
    refreshErrors: () => dispatch(UserActions.refreshErrors({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
