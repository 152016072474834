import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { getKeyFromValue } from 'pages/teams/pages/_tools/review/components/step2/Step2Chart'

import { constants } from 'assets'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const labels = ['0-2点', '3-5点', '6-8点', '9-10点']

// グラフの y 軸の最大値を求める
const findNearestMultiple = (value: number): number => {
  if (value <= 50) {
    // 値が50以下の場合、直近の5の倍数を求める
    const remainder = value % 5
    if (remainder === 0) {
      return value
    }
    const nearestMultipleOf5 = Math.ceil(value / 5) * 5
    return nearestMultipleOf5
  } else {
    // 値が50より大きい場合、直近の10の倍数を求める
    const remainder = value % 10
    if (remainder === 0) {
      return value
    }
    const nearestMultipleOf10 = Math.ceil(value / 10) * 10
    return nearestMultipleOf10
  }
}

type Props = {
  points: number[]
}

export const BarChart: React.FC<Props> = ({ points }) => {
  const classes = useStyles()

  const formattedData = React.useMemo(
    () =>
      points.reduce(
        (prev, val) => {
          const key = getKeyFromValue(val)
          if (prev[key]) {
            prev[key] = prev[key] + 1
          } else {
            prev[key] = 1
          }
          return prev
        },
        {
          '0~2': 0,
          '3~5': 0,
          '6~8': 0,
          '9~10': 0,
        }
      ),
    [points]
  )

  const min = 0
  const max = findNearestMultiple(Math.max(...Object.values(formattedData)))

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: Object.values(formattedData),
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    responsive: true, // width を 100% にするために必要
    maintainAspectRatio: false, // height を 100% にするために必要
    plugins: {
      legend: {
        display: false, // datasets の label を表示しない
      },
      title: {
        display: true,
        text: '(回答数)',
        color: constants.TEXT_GRAY_DARK,
        font: {
          size: 10,
          weight: 'normal',
        },
        position: 'top',
        align: 'start',
      },
      datalabels: {
        color: constants.COLOR_MAIN_NEW,
        font: {
          size: 10,
          weight: 'bold',
        },
        anchor: 'end' as const,
        align: 'end' as const,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        min,
        max,
        grid: {
          color: `${constants.COLOR_MAIN_NEW}1A`,
          lineWidth: 1,
          drawBorder: false,
          display: true,
        },
        ticks: {
          callback: (value) => {
            // 最大値と最小値に対してはラベル表示
            if (value === min || value === max) {
              return value
            } else {
              // それ以外の値にはラベルを非表示
              return ''
            }
          },
          stepSize: 1, // 小数の grid 線は表示しない
          maxTicksLimit: 10, // 指定しないと max の grid 線画表示されない場合がある
        },
      },
    },
  }

  return <Bar options={options} data={data} className={classes.bar} />
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    bar: {
      height: '100%',
    },
  }),
  { name: 'BarChart' }
)
