import * as React from 'react'

import { Circle, G, Line, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Woman3: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-7698.988 -7116.344)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(7698.988 7116.344)" fill="#80d6dd" />
        <G transform="translate(6285.69 6228.949)">
          <G>
            <Path
              d="M1785.348,1245.7v.518c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.975.154,18.227-9.7h.036v-.994a2.547,2.547,0,0,0-2.547-2.547h-31.388a2.546,2.546,0,0,0-2.546,2.547Z"
              fill="#72642a"
            />
            <Path
              d="M1844.994,1245.7v.518c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.976.154,18.228-9.7h.035v-.994a2.546,2.546,0,0,0-2.546-2.547h-31.389a2.546,2.546,0,0,0-2.546,2.547Z"
              fill="#72642a"
            />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1844.994 1153.683)" fill="#ffe5d2" />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1785.334 1153.683)" fill="#ffe5d2" />
            <Path
              d="M1834.362,950.088s-15.615,25.42-15.615,35.226a26.052,26.052,0,0,0,8.351,18.519c2.905,3.267-10.495,17.767-2.8,32.467,7.988,15.252,5.4,5.044,36.251,5.769s-7.608,32.9,6.189,13.652c6.76-9.43,21.76-29.779,25.712-52.5,3.4-19.562-6.914-63.218-6.914-63.218"
              fill="#6d614a"
            />
            <Path
              d="M1829.149,950.088s15.615,25.42,15.615,35.226a26.052,26.052,0,0,1-8.351,18.519c-2.9,3.267,10.495,17.767,2.8,32.467-7.988,15.252-5.4,5.044-36.251,5.769s7.608,32.9-6.189,13.652c-6.76-9.43-21.764-29.779-25.712-52.5-3.4-19.562,6.914-63.218,6.914-63.218"
              fill="#6d614a"
            />
            <Path
              d="M1877.005,1017.966h-87.188a4.537,4.537,0,0,0-4.483,4.558v116.013h96.136V1022.524A4.536,4.536,0,0,0,1877.005,1017.966Z"
              fill="#7192cc"
            />
            <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(1822.929 1003.554)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1768.566 952.891)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1863.562 952.891)" fill="#ffe5d2" />
            <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(1778.8 907.679)" fill="#ffe5d2" />
            <Path
              d="M1890.215,959.775c-18.3,0-12.284,3.231-25.759-18.873-23.806,25.614-76.5,17.25-89.471,9.881,3.63-23.475,24.713-52.414,56.91-52.414S1890.215,925.849,1890.215,959.775Z"
              fill="#6d614a"
            />
            <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(1785.334 1118.638)" fill="#7192cc" />
            <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(1807.761 1017.966)" fill="#ffe5d2" />
            <G>
              <Path
                d="M1794.009,1016.582a11.77,11.77,0,0,1,9.925,13.294l-3.122,13.5a11.766,11.766,0,0,1-13.3,9.924l-.774-.111a11.766,11.766,0,0,1-9.925-13.294l3.122-13.5a11.765,11.765,0,0,1,13.294-9.925Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1804.492,1036.928l-8.985,76.989s-11.377,5.1-15.3,4.436c-3.563-.6-11.786-8.364-11.786-8.364l8.986-76.988Z"
                fill="#7192cc"
              />
            </G>
            <G>
              <Path
                d="M1871.5,1017.665a11.769,11.769,0,0,1,13.136,10.133l.627,13.845a11.766,11.766,0,0,1-10.134,13.136l-.776.1a11.767,11.767,0,0,1-13.136-10.133l-.627-13.845a11.767,11.767,0,0,1,10.133-13.136Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1887.069,1034.435,1899.131,1111s-9.585,7.973-13.546,8.39c-3.594.38-13.6-4.884-13.6-4.884l-12.062-76.566Z"
                fill="#7192cc"
              />
            </G>
          </G>
          <G>
            <Path
              d="M1559.521,1023.755l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C1561.5,1018.272,1559.5,1020.743,1559.521,1023.755Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(1600.346 1003.575) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1641.077 952.526)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1546.157 953.247)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(1556.458 907.712)" fill="#ffe4d2" />
            <Path
              d="M1657.268,1245.1a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.545,2.545,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M1597.668,1245.543a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.545,2.545,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M1592.187,1018.043l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M1608.517,1018.269l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(1603.954 1026.114) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M1592.05,1018.043l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M1625.934,1017.783l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M1608.1,898.4c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C1550.058,926.313,1575.931,898.643,1608.1,898.4Z"
              fill="#522903"
            />
            <G>
              <Path
                d="M1730.646,1051.416l-48.126,28.734a10.128,10.128,0,0,1-13.889-3.5h0l-.347-.58c-2.868-4.8-2.077-12.577,2.727-15.445l48.126-28.734c4.8-2.868,11.8.256,14.668,5.059l.346.58A10.128,10.128,0,0,1,1730.646,1051.416Z"
                fill="#ffe4d2"
              />
              <Path d="M1654.059,1071.252l60.831-39.029,13.673,21.845-60.831,39.029Z" fill="#545364" />
            </G>
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1560.509 1153.894) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1620.122 1153.441) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M1632.943,1037.433a11.767,11.767,0,0,1-4.483,15.973l-12.6,5.761a11.766,11.766,0,0,1-15.973-4.484l-.384-.681a11.766,11.766,0,0,1,4.483-15.973l12.6-5.762a11.767,11.767,0,0,1,15.973,4.483Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1623.215,1058.143l-66.494,39.831s-10.98-5.906-12.849-9.424c-1.7-3.191-.559-14.442-.559-14.442l66.494-39.829Z"
                fill="#545364"
              />
            </G>
            <Path d="M1654.669,1019.818l28.11,49.877-22.381,11.8-28.114-49.877Z" fill="#545364" />
            <Path d="M1583.287,1029.166l-16.6,54.794-24-8.01,16.594-54.8Z" fill="#545364" />
          </G>
          <G>
            <Line
              x1="0.755"
              x2="0"
              y1="0"
              y2="7.023"
              transform="translate(1900.161 1012.276)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="5.836"
              x2="0"
              y1="0"
              y2="4.352"
              transform="translate(1904.462 1017.275)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="0"
              x2="5.739"
              y1="0"
              y2="1.032"
              transform="translate(1907.17 1025.947)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </G>
          <G>
            <Line
              x1="1.003"
              x2="0"
              y1="0"
              y2="6.992"
              transform="translate(1760.904 1009.164)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="0"
              x2="4.58"
              y1="0"
              y2="5.659"
              transform="translate(1751.581 1011.69)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="5.816"
              x2="0"
              y1="0.417"
              y2="0"
              transform="translate(1746.654 1020.449)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman3
