export const conditions = {
  GREAT_SHADE: '順調に進められていそうですね！\n今日も一日頑張りましょう！',
  BAD_HUMAN_RELATION: 'プライベートが充実していると\n仕事の活力も湧くそうです！\n今日も一日楽しんでいきましょう！',
  BAD_FEELING:
    'なぜ調子がいいのか、要因が分かるとその状態が維持できるかもしれません。なぜ調子がいいのか考えつつ、今日も楽しんでいきましょう！',

  BAD_WORK_PROGRESS: '仕事の進み具合が不安ですか？\nそんな時はタイムラインで上司に仕事の相談をしてみましょう！',
  BAD_PRIVATE_TIME: 'プライベートの気がかりは仕事にも影響が出るかもしれません。\n信頼ができる人に相談してみましょう！',
  BAD_FEELING_SOMEWHAT:
    '眠れていますか？適度な運動はできていますか？\n少しリフレッシュをしましょう。\n誰かに話してみてもいいかもしれません。',
  YESTERDAYS_MISTAKE: '',
  NEED_TO_REFRESH: '',
  WANT_TO_GO_HOME: '',
  NORMAL: '',
}
