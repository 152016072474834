import React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import arrow from 'pages/onboarding/assets/arrowTooltip.svg'
import QuestionsIcon from 'pages/onboarding/assets/QuestionIcon.svg'

import * as constants from 'assets/constants'


type Props = {
  toolTip?: () => void
  isOpen?: boolean
  titleFirst?: string
  messageFirst?: string
  titleSecond?: string
  messageSecond?: string
}

export const Tooltip: React.FC<Props> = (props: Props) => {
  // deps
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <span className={classes.iconWrapper}>
        <span>
          {isSmDown ? (
            <img
              onMouseDown={props.toolTip}
              src={QuestionsIcon}
              style={{ marginLeft: 5, cursor: 'pointer', verticalAlign: 'text-bottom' }}
              alt="questionsIcon"
            />
          ) : (
            <img
              onMouseEnter={props.toolTip}
              onMouseLeave={props.toolTip}
              src={QuestionsIcon}
              style={{ marginLeft: 5, cursor: 'pointer', verticalAlign: 'text-bottom' }}
              alt="questionsIcon"
            />
          )}
        </span>
        {props.isOpen ? (
          <div className={classes.toolTip}>
            <span className={classes.arrow}>
              <img src={arrow} alt="arrow" />
            </span>
            <div className={classes.toolTipTitle}>{props.titleFirst}</div>
            <div className={classes.toolText}>{props.messageFirst}</div>
            <div className={classes.toolTipTitleSecond}>{props.titleSecond}</div>
            <div className={classes.toolTextSecond}>{props.messageSecond}</div>
          </div>
        ) : (
          <></>
        )}
      </span>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    position: 'relative',
  },
  titleWrapper: {
    position: 'relative',
    marginLeft: 16,
    marginRight: 16,
  },
  arrow: {
    position: 'absolute',
    top: -15,
    left: 278,
    [theme.breakpoints.down('sm')]: {
      left: 70,
    },
  },
  toolTip: {
    background: constants.COLOR_TOOLTIP_CONTENT,
    borderRadius: 8,
    padding: '17px 16px 19px 16px',
    position: 'absolute',
    top: 35,
    zIndex: 10,
    minWidth: 340,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
      left: -50,
    },
    left: -90,
    boxShadow: '0px 4px 10px -5px rgba(0,0,0,0.5)',
    borderTop: '1px solid #E9E9E9',
  },
  toolTipTitle: {
    fontWeight: 'bold',
    paddingBottom: 13,
    color: constants.COLOR_MAIN_NEW,
    fontSize: 14,
  },
  toolText: {
    fontSize: 14,
    fontWeight: 'normal',
  },
  toolTipTitleSecond: {
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
    color: constants.COLOR_MAIN_NEW,
    fontSize: 14,
  },
  toolTextSecond: {
    fontSize: 14,
    fontWeight: 'normal',
  },
}))
