import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  backgroundColor: 'white' | 'lightGray' | 'lightYellow' | 'gray' | 'blue' | 'lightBlue' | 'lightGray2' | 'lightGray3' // only use lightGrey and white
  borderRadius?: number | 'none'
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  id?: string
  ownStyles?: React.CSSProperties
  noShadow?: boolean
}

export const UsefulCard: React.FC<Props> = (props) => {
  UsefulCard.defaultProps = { borderRadius: 8 }
  const classes = useStyles({ noShadow: props.noShadow })

  return (
    <div
      className={classNames([classes.usefulCard, props.backgroundColor])}
      style={{ borderRadius: props.borderRadius, ...props.ownStyles }}
      onClick={(event) => {
        if (props.onClick) props.onClick(event)
      }}
      id={props.id}
    >
      {props.children}
    </div>
  )
}

interface StyleProps {
  noShadow?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    usefulCard: (props: StyleProps) => ({
      boxShadow: props.noShadow ? undefined : `0px 1px 6px 0px ${constGhost.COLOR_BLACK}`,
      '&.white': {
        backgroundColor: constGhost.COLOR_WHITE,
        boxShadow: 'none',
      },
      '&.lightGray': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY2,
        boxShadow: 'none',
      },
      '&.lightYellow': {
        backgroundColor: constGhost.COLOR_LIGHTYELLOW,
        boxShadow: `0px 1px 6px 0px ${constGhost.COLOR_YELLOWSHADOW}`,
      },
      '&.gray': {
        backgroundColor: '#D3D3D3',
        boxShadow: 'none',
      },
      '&.blue': {
        backgroundColor: constGhost.COLOR_MAINBLUE,
      },
      '&.lightBlue': {
        backgroundColor: constGhost.COLOR_LIGHTBLUE,
        boxShadow: 'none',
      },
      '&.lightGray2': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY2,
      },
      '&.lightGray3': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY3,
      },
    }),
  }),
  { name: 'UsefulCard' }
)
