import { actionCreatorFactory } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('Pages/KarteMemberQuestionnaire')

export default {
  store: actionCreator.async<{ leaderId: string; id: string; data: any; successCallback: () => void }, any>('STORE'), // TODO: successCallback change to router
  resetError: actionCreator<{}>('RESET_ERROR'),

  empty: actionCreator<{}>('empty'),
}
