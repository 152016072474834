import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  children: React.ReactNode
  ownStyles?: React.CSSProperties
}
export const WidthControlContainer: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.backCard} style={{ ...props.ownStyles }}>
      <div className={classes.mainItem}>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    backCard: {
      display: 'flex',
    },

    mainItem: {
      flexGrow: constGhost.MAIN_CONTENT_WIDTH,
      margin: '0 auto',
      width: '100%',
      maxWidth: 800,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 600,
      },
    },
  }),
  { name: 'CounterSidebarStyle' }
)
