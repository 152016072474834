import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  isVisible?: boolean
  switchTabBtn: () => void
}

export const SwitchTabForSP: React.FC<Props> = ({ isVisible, switchTabBtn }) => {
  const classes = useStyles()
  return (
    <>
      {isVisible ? (
        <div onClick={switchTabBtn} className={classes.tabContainer}>
          <div className={classes.tabSelected}>メンバー情報</div>
          <div className={classes.tabNoSelected}>利用ツール</div>
        </div>
      ) : (
        <div onClick={switchTabBtn} className={classes.tabContainer}>
          <div className={classes.tabNoSelected}>メンバー情報</div>
          <div className={classes.tabSelected}>利用ツール</div>
        </div>
      )}
    </>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    borderRadius: 50,
    width: '100%',
    backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: constants.COLOR_GRAY_DARK,
    alignItems: 'center',
  },
  tabSelected: {
    color: `${constants.COLOR_MAIN_NEW} !important`,
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    padding: '7px 0',
    margin: 4,
    fontWeight: 'bold',
    borderRadius: 50,
    width: '50%',
    justifyContent: 'center',
    display: 'flex',
  },
  tabNoSelected: {
    color: `${constants.COLOR_GRAY_DARK} !important`,
    padding: '7px 0',
    margin: 4,
    fontWeight: 'bold',
    borderRadius: 50,
    width: '50%',
    justifyContent: 'center',
    display: 'flex',
  },
}))
