import { I18n } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'

import { mutationSendAuthCompletedEmail } from 'pages/signup/api/graphql'

I18n.setLanguage('ja')

export interface IConfirmSignUp {
  email: string
  code: string
}

export interface IConfirmSignUpTeam {
  teamId?: string | null
  userId?: string | null
}

export const confirmSignUp = async (user: IConfirmSignUp, password?: string) => {
  await Auth.confirmSignUp(user.email, user.code)

  // 認証完了のメールを送信
  try {
    await mutationSendAuthCompletedEmail({ email: user.email })
  } catch (error) {
    console.log('sendAuthCompletedEmail error:', error)
  }

  if (password) {
    await Auth.signIn(user.email, password)
  }

  return {}
}
