import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { TeamMember } from '../../../../utils/generated'
import { MemberDropdownItem } from '../atoms'

export type memberSelectedArray = Array<{
  member: TeamMember
  selected: boolean
}>

type Props = {
  dataArray: memberSelectedArray
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  handleVisible: () => void
  visible: boolean
  ownStyles?: React.CSSProperties
}

export const MemberDropdownList: React.FC<Props> = (props) => {
  const classes = useStyles()

  if (!props.dataArray || props.dataArray.length === 0) {
    return <></>
  }

  return (
    <div className={classNames([classes.root, props.visible ? '' : 'invisible'])}>
      {props.dataArray.map((data) => (
        <MemberDropdownItem
          key={data.member.id}
          onClick={(event) => props.onClick(event)}
          handleVisible={() => props.handleVisible()}
          id={data.member.id}
          selected={data.selected}
        >
          {data.member.fullName}
        </MemberDropdownItem>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: 44,
    backgroundColor: constGhost.COLOR_WHITE,
    border: `1px solid ${constGhost.COLOR_BORDERLIGHTGRAY}`,
    borderRadius: 8,
    zIndex: 1000,
    padding: '0 8px',
    boxShadow: `0px 1px 3px 0px ${constGhost.COLOR_BLACK}1E`,
    '&.invisible': {
      display: 'none',
    },
  },
}))
