import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { LikeMembers } from 'pages/onboarding/components/like-members'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingPostLikes } from 'pages/onboarding/hooks/post'

import { OnbContext } from '../PagesRoot'

export const TimelinePostLikesBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { postId } = useParams<{ postId: string }>()
    const { postLikes } = useOnboardingPostLikes(postId)

    return {
      teamId,
      postId,
      postLikes,
    }
  },
}

const Index = () => {
  const { teamId, postId, postLikes } = TimelinePostLikesBloc.useAdapter()

  return (
    <>
      <TitleWithBack title="いいねした人" />

      <LikeMembers teamId={teamId} postId={postId} likes={postLikes} />
    </>
  )
}

export default Index
