import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

import { useTeamMember, useUser } from 'pages/teams/hooks'

type Props = {
  id: string
}

export const MemberInfo: React.FC<Props> = React.memo(
  ({ id }) => {
    const classes = useStyles()
    const { teamMember } = useTeamMember(id)
    const { user } = useUser(teamMember?.userId)

    return (
      <div className={classes.root}>
        <Avatar src={user?.imageUrl ?? ''} alt="プロフィール画像" className="__avatar" />
        <span>{teamMember?.fullName}</span>
      </div>
    )
  },
  (prevProps, nextProps) => prevProps.id === nextProps.id
)

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      width: '100%',

      '& .__avatar': {
        width: 24,
        height: 24,
      },
      '& span': {
        fontSize: 12,
        fontWeight: 'bold',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
      },
    },
  }),
  { name: 'MemberInfo' }
)
