import React from 'react'

import {
  useCurrentTeamMember,
  useUser,
  useCurrentUser,
  useTeam,
  useTeamPlans,
  useTeamBuilding,
} from 'pages/teams/hooks'
import { Team, TeamBuilding, TeamMember, TeamMemberRole, TeamPlan, User } from 'utils/generated'

import { isAdmin, isLeader, isMember } from '../utils/teamMember'

interface Props {
  currentUser?: User
  refreshCurrentUser: () => Promise<void>
  currentTeam?: Team
  refreshTeam: () => Promise<Team | undefined>
  loadingTeam: boolean
  currentTeamMember?: TeamMember
  currentTeamMemberRole?: TeamMemberRole
  isAdmin?: boolean
  isLeader?: boolean
  isMember?: boolean
  refreshCurrentTeamMember: () => Promise<void>
  currentTeamAdmin?: User
  refreshTeamAdmin: () => Promise<void>

  teamPlans: TeamPlan[]

  teamBuilding?: TeamBuilding
  loadingTeamBuilding: boolean
  loadedTeamBuilding: boolean
  refreshTeamBuilding: () => Promise<void>

  loading?: boolean
}

export const useHooksContext = (teamId?: string) => {
  const { user: currentUser, refresh: refreshCurrentUser } = useCurrentUser()
  const { team: currentTeam, refresh: refreshTeam, loading: loadingTeam } = useTeam(teamId)
  const {
    teamMember: currentTeamMember,
    refresh: refreshCurrentTeamMember,
    role: currentTeamMemberRole,
    loading: loadingTeamMember,
  } = useCurrentTeamMember(teamId)
  const { user: currentTeamAdmin, refresh: refreshTeamAdmin } = useUser(currentTeam?.ownerUserId)
  const { plans: teamPlans } = useTeamPlans()

  const {
    teamBuilding,
    loading: loadingTeamBuilding,
    loaded: loadedTeamBuilding,
    refresh: refreshTeamBuilding,
  } = useTeamBuilding(teamId)

  return {
    currentUser,
    refreshCurrentUser,
    currentTeam,
    refreshTeam,
    loadingTeam,
    currentTeamMember,

    currentTeamMemberRole,
    isAdmin: isAdmin(currentTeamMember),
    isLeader: isLeader(currentTeamMember),
    isMember: isMember(currentTeamMember),
    refreshCurrentTeamMember,
    loadingTeamMember,
    currentTeamAdmin,
    refreshTeamAdmin,
    teamPlans,
    teamBuilding,
    loadingTeamBuilding,
    loadedTeamBuilding,
    refreshTeamBuilding,
  }
}

export const HooksContext = React.createContext<Props>({} as Props)
