import * as React from 'react'

import { GhostActionOrEffect } from '../modules'

type Props = {
  solution: string
  actions: string[]
  anti: string
  caveats: string[]
  gapMargin: 10 | 20 | 24
  ownStyles?: React.CSSProperties
}

export const GhostActionAndEffect: React.FC<Props> = ({ solution, actions, anti, caveats, gapMargin, ownStyles }) => {
  return (
    <div style={{ ...ownStyles }}>
      <GhostActionOrEffect
        dataType="actions"
        solution={solution}
        actions={actions}
        anti={anti}
        caveats={caveats}
        noBack
      />
      <GhostActionOrEffect
        dataType="caveats"
        solution={solution}
        actions={actions}
        anti={anti}
        caveats={caveats}
        ownStyles={{ marginTop: gapMargin }}
        noBack
      />
    </div>
  )
}
