import React, { useContext, useMemo } from 'react'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constGhost from 'pages/ghost/assets/constGhost'
import {
  BackResponsiveCard,
  ButtonForGhost,
  Flexbox,
  GrayBackGround,
  LeadText,
  PageTitle,
  PresenterInfo,
  ResearchBox,
} from 'pages/ghost/components/atoms'
import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { LoadingIndicator } from 'pages/ghost/components/organisms'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { ResearchContext } from 'pages/ghost/contexts/research'
import { usePBForFixedUIs } from 'pages/ghost/hooks/fixedUI'
import { useGetUnansweredUserIds } from 'pages/ghost/hooks/getUnansweredUserIds'
import { CommandContext } from 'pages/ghost/service/commands'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

type Props = {}

export const Step4Presentation: React.FC<Props> = () => {
  const { team } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList } = useContext(ghostTeamMemberContext)
  const { researchesFeeling, researchesNextAction } = useContext(ResearchContext)
  const { selectNextPresenter } = useContext(CommandContext)
  const { unansweredUserIds } = useGetUnansweredUserIds({
    team,
    researchesFeeling,
    researchesNextAction,
    teamMemberList,
  })
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer' })
  const isMdUp = useCustomMediaQuery('up', 'md')
  const isLgUp = useCustomMediaQuery('up', 'lg')

  const presenter = useMemo(() => {
    if (!team?.timerS4SelectUserIds?.length || !teamMemberList?.length) return null
    // timerS4SelectUserIds の先頭の要素が発表者の id
    const presenterId = team.timerS4SelectUserIds[0]
    const presenterMember = teamMemberList.find((m) => m.userId === presenterId)
    if (!presenterMember) return null

    return {
      ...presenterMember,
      feeling: researchesFeeling.find((research) => research.userId === presenterMember.userId)?.report ?? '',
      nextAction: researchesNextAction.find((research) => research.userId === presenterMember.userId)?.report ?? '',
    }
  }, [team?.timerS4SelectUserIds, teamMemberList, researchesFeeling, researchesNextAction])

  const isLoading = !team || !teamMember || !teamMemberList.length || !presenter
  const isLeader = teamMember?.role === GhostMemberRole.Leader

  const onNext = async () => {
    if (isLoading || !team.timerS4SelectNumber || !team.timerS4SelectUserIds?.length) return

    await selectNextPresenter({
      team,
      selectNumber: team.timerS4SelectNumber,
      selectUserIds: team.timerS4SelectUserIds,
      excludeUserIds: unansweredUserIds,
      teamMemberList,
      currentMember: teamMember,
      nextProgress: GhostProgress.S4DoneRandomAnswers,
    })
  }

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: (pBForFixedUIs ?? 0) + (isLeader ? (isLgUp ? 40 : 84) : 84) }}>
        <WidthControlContainer>
          <PageTitle>ランダムに解答者を指名します！</PageTitle>
          <LeadText ownStyles={{ marginBottom: 24 }}>
            「気づいたこと」「感じたこと」を発表してください。
            <br />
            また、自分が「明日から出来そうなアクション」を１つ宣言してください。
          </LeadText>
          <PresenterInfo
            currentIndex={team?.timerS4SelectUserIds?.length}
            totalNumber={team?.timerS4SelectNumber}
            presenterName={presenter?.name}
          />

          {!isLoading && (presenter.feeling || presenter.nextAction) && (
            <Flexbox
              flexDirection="column"
              ownStyles={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 16,
                padding: isMdUp ? 40 : 16,
                backgroundColor: constGhost.COLOR_WHITE,
                borderRadius: 8,
              }}
            >
              {presenter.feeling && <ResearchBox title="気づいたこと / 感じたこと" research={presenter.feeling} />}
              {presenter.nextAction && (
                <ResearchBox title="明日から出来そうなアクション" research={presenter.nextAction} />
              )}
            </Flexbox>
          )}

          {isLoading ? (
            <LoadingIndicator snapshot={{}} />
          ) : teamMember?.role === GhostMemberRole.Leader ? (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="ghost-rule"
                  buttonBodyColor="green"
                  bodyText="次へ"
                  onClick={onNext}
                  isResizeBasic
                />,
              ]}
            />
          ) : null}

          {team && (
            <Timer
              from={team.timerS4SelectStarted ?? new Date().toISOString()}
              time={team.timerS4SelectMinute ? team.timerS4SelectMinute * 60 : 0}
              hasGhostStyle
            />
          )}
        </WidthControlContainer>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}
