export const queryGetGhostTeam = /* GraphQL */ `
  query GetGhostTeam($id: ID!) {
    getGhostTeam(id: $id) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const queryGetGhostTeamList = /* GraphQL */ `
  query GetGhostTeamList {
    getGhostTeamList {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const queryGetGhostTeamByMaster = /* GraphQL */ `
  query GetGhostTeamByMaster($masterTeamId: String!) {
    getGhostTeamByMaster(masterTeamId: $masterTeamId) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const mutationCreateGhostTeam = /* GraphQL */ `
  mutation CreateGhostTeam($input: CreateGhostTeamInput) {
    createGhostTeam(input: $input) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const mutationUpdateGhostTeam = /* GraphQL */ `
  mutation UpdateGhostTeam($input: UpdateGhostTeamInput!) {
    updateGhostTeam(input: $input) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const mutationDeleteGhostTeam = /* GraphQL */ `
  mutation DeleteGhostTeam($id: ID!) {
    deleteGhostTeam(id: $id)
  }
`
export const subscriptionCreateGhostTeam = /* GraphQL */ `
  subscription OnCreateGhostTeam($masterTeamId: String!) {
    onCreateGhostTeam(masterTeamId: $masterTeamId) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const subscriptionUpdateGhostTeam = /* GraphQL */ `
  subscription OnUpdateGhostTeam($id: ID!) {
    onUpdateGhostTeam(id: $id) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`

export const subscriptionDeleteGhostTeam = /* GraphQL */ `
  subscription OnDeleteGhostTeam($id: ID!) {
    onDeleteGhostTeam(id: $id) {
      id
      masterTeamId
      name
      startAt
      endAt
      discover
      selectListManage
      report
      reportManage
      research
      researchSecond
      researchManage
      researchSecondManage
      feelingNextActionManage
      like
      likeSecond
      likeManage
      likeSecondManage
      status
      progress
      timerS1Started
      timerS1SelectMinute
      timerS1SelectNumber
      timerS1SelectUserIds
      timerS1SelectStarted
      timerS2Started
      timerS2SelectMinute
      timerS2SelectNumber
      timerS2SelectUserIds
      timerS2SelectStarted
      timerS3Started
      timerS3SelectMinuteReason
      timerS3SelectNumberReason
      timerS3SelectUserIdsReason
      timerS3SelectStartedReason
      timerS3Started2
      timerS3SelectMinute
      timerS3SelectNumber
      timerS3SelectUserIds
      timerS3SelectStarted
      timerS4Started
      timerS4SelectMinute
      timerS4SelectNumber
      timerS4SelectUserIds
      timerS4SelectStarted
      createdAt
      updatedAt
    }
  }
`
