import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  isHome: () => boolean
  isShowPager: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useTipsIndex = (): IProps => {
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)
  const hintListSelectors = useSelector((state: RootStateType) => state.hint.hintList)

  /**
   * ページのHOMEかどうかを判定する
   */
  const isHome = (): boolean => {
    return pagesSelectors.currentPages === 1 ? false : true
  }

  /**
   * ページングの表示を出し分けをする
   */
  const isShowPager = (): boolean => {
    return hintListSelectors.nextToken || pagesSelectors.currentPages > 1 ? true : false
  }

  return {
    isHome,
    isShowPager,
  }
}
