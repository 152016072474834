import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { Font } from 'pages/ghost/components/atoms/Font'
import { UsefulCard } from 'pages/ghost/components/atoms/UsefulCard'

type Props = {
  title: string
  research: string
}

export const ResearchBox: React.FC<Props> = ({ title, research }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Font fontSize={14} bold ownStyles={{ marginBottom: 16 }}>
        {title}
      </Font>
      <UsefulCard
        backgroundColor="lightGray"
        ownStyles={{ padding: 16, whiteSpace: 'pre-wrap', fontSize: 14, color: constGhost.COLOR_BLACK3 }}
      >
        {research}
      </UsefulCard>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
    },
  }),
  { name: 'ResearchBox' }
)
