import * as React from 'react'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { Layout } from '../components/Layout'
import { buttonBoxHeight, ObakeSelection } from '../components/ObakeSelection'
import { PersonalQuestions } from '../components/PersonalQuestions'

export const Search: React.FC = () => {
  const [activeGhostFileNo, setActiveGhostFileNo] = React.useState(1)
  const [selectedGhostIds, setSelectedGhostIds] = React.useState<Array<number>>([])
  const isSmDown = useCustomMediaQuery('down', 'sm')

  return (
    <Layout minHeightSub={isSmDown ? buttonBoxHeight.sp : buttonBoxHeight.pc}>
      {activeGhostFileNo !== 0 ? (
        <ObakeSelection
          activeGhostFileNo={activeGhostFileNo}
          setActiveGhostFileNo={setActiveGhostFileNo}
          setSelectedGhostIds={setSelectedGhostIds}
        />
      ) : (
        <PersonalQuestions selectedGhostIds={selectedGhostIds} />
      )}
    </Layout>
  )
}
