import * as React from 'react'

import { makeStyles, Theme, ClickAwayListener } from '@material-ui/core'

import { MemberDropdownList, DropdownBox, memberSelectedArray } from '../modules'

type Props = {
  dataArray: memberSelectedArray
  boxOnClick: (event: React.MouseEvent<HTMLDivElement>) => void
  listOnClick: (event: React.MouseEvent<HTMLDivElement>) => void
  ownStyles?: React.CSSProperties
  ownBoxStyles?: React.CSSProperties
}

export const MemberToolbar: React.FC<Props> = (props) => {
  const classes = useStyles({})
  const [visible, setVisible] = React.useState(false)

  const handleVisible = () => {
    setVisible((prev) => !prev)
  }
  const handleOpen = () => {
    if (props.dataArray?.length === 0) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }
  const handleClose = () => {
    setVisible(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.root} style={props.ownStyles}>
        <DropdownBox
          onClick={(event) => props.boxOnClick(event)}
          handleVisible={() => handleVisible()}
          ownBoxStyles={props.ownBoxStyles}
          popupOpen={visible}
        >
          {props.children}
        </DropdownBox>
        <MemberDropdownList
          onClick={(event) => props.listOnClick(event)}
          handleVisible={() => handleOpen()}
          visible={visible}
          dataArray={props.dataArray}
        />
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
}))
