import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

type StyleProps = {
  marginTop?: number
  padding: string
}
type Props = {
  style: StyleProps
}

export const BackCard: React.FC<Props> = ({ children, style }) => {
  const classes = useStyles({ marginTop: style?.marginTop, padding: style?.padding })

  return <div className={classes.backCardRoot}>{children}</div>
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    backCardRoot: (props: StyleProps) => ({
      background: '#FFFFFF',
      border: '1px solid #EBEBEB',
      boxShadow: '0px 5px 24px #EBEBEB',
      borderRadius: 8,
      maxWidth: 928,
      margin: `${props.marginTop || 0}px 16px 0`,
      padding: props.padding || 0,
      [theme.breakpoints.up('md')]: {
        margin: `${props.marginTop || 0}px auto 0`,
      },
    }),
  }),
  { name: 'BackCard' }
)
