import React from 'react'

import { Auth } from 'aws-amplify'

import { User, UserUpdateInput } from 'utils/generated'

import { queryGetUser, mutationUpdateUser } from './graphql'

export const useUser = (userId?: string) => {
  const [user, setUser] = React.useState<User | undefined>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!userId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetUser({ id: userId })
      setUser(response)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return { user, loading, error, refresh } as const
}

export const useCurrentUser = () => {
  const [user, setUser] = React.useState<User | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    const cognito = await Auth.currentAuthenticatedUser()

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetUser({ id: cognito.getUsername() })
      setUser(response)
    } catch (e) {
      setError(e)
    } finally {
      setLoaded(true)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    refresh()
  }, [])

  return { user, loading, loaded, error, refresh } as const
}

export const useManageUser = () => {
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()

  const updateUser = React.useCallback(async (input: UserUpdateInput): Promise<User | undefined> => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await mutationUpdateUser({ input })
      setUser(response)
      return response
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { user, loading, error, updateUser } as const
}
