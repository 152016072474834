import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { VALUE_HEIGHT } from '../DataList'

import { DataRowHeaderNext } from './DataRowHeaderNext'
import { DataRowHeaderRanking } from './DataRowHeaderRanking'

type Props = {
  page: number
  size: 'xs' | 'sm' | 'md'
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export const DataRowHeaders: React.FC<Props> = ({ page, size, setPage }) => {
  const classes = useStyles()

  switch (size) {
    case 'md':
      switch (page) {
        case 1:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>傾向</div>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <DataRowHeaderRanking rank={1} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={2} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={3} />
                  <DataRowHeaderNext nextPage={2} setPage={setPage} />
                </div>
              </div>
            </>
          )

        case 2:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={4} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={5} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={6} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={7} />
                  <DataRowHeaderNext nextPage={3} setPage={setPage} />
                </div>
              </div>
            </>
          )

        case 3:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={8} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={9} />
              </div>
              {/* 他のページと幅を合わせるための空要素 */}
              <div className={classNames(classes.box, classes.valueHeader)}></div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyEnd')}>
                  <DataRowHeaderNext disabled setPage={setPage} />
                </div>
              </div>
            </>
          )

        default:
          return <></>
      }
    case 'sm':
      switch (page) {
        case 1:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>傾向</div>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <DataRowHeaderRanking rank={1} />
              </div>
              {/* <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={2} />
              </div> */}
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={2} />
                  <DataRowHeaderNext nextPage={2} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 2:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={3} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={4} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={5} />
                  <DataRowHeaderNext nextPage={3} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 3:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={6} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={7} />
              </div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={8} />
                  <DataRowHeaderNext nextPage={4} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 4:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.rightBorder)}>回</div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <DataRowHeaderRanking rank={9} />
              </div>
              {/* 他のページと幅を合わせるための空要素 */}
              <div className={classNames(classes.box, classes.valueHeader)}></div>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyEnd')}>
                  <DataRowHeaderNext disabled setPage={setPage} />
                </div>
              </div>
            </>
          )
        default:
          return <></>
      }
    case 'xs':
      switch (page) {
        case 1:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  回
                  <DataRowHeaderNext nextPage={2} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 2:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  傾向
                  <DataRowHeaderNext nextPage={3} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 3:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={1} />
                  <DataRowHeaderNext nextPage={4} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 4:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={2} />
                  <DataRowHeaderNext nextPage={5} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 5:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={3} />
                  <DataRowHeaderNext nextPage={6} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 6:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={4} />
                  <DataRowHeaderNext nextPage={7} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 7:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={5} />
                  <DataRowHeaderNext nextPage={8} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 8:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={6} />
                  <DataRowHeaderNext nextPage={9} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 9:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={7} />
                  <DataRowHeaderNext nextPage={10} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 10:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={8} />
                  <DataRowHeaderNext nextPage={11} setPage={setPage} />
                </div>
              </div>
            </>
          )
        case 11:
          return (
            <>
              <div className={classNames(classes.box, classes.valueHeader, classes.row)}>
                <div className={classNames(classes.row, 'justifyBetween')}>
                  <DataRowHeaderRanking rank={9} />
                  <DataRowHeaderNext disabled setPage={setPage} />
                </div>
              </div>
            </>
          )
        default:
          return <></>
      }
    default:
      return <></>
  }
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    box: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: VALUE_HEIGHT,
    },
    valueHeader: {
      fontSize: 12,
      padding: 7,
      flex: 1,
    },
    rightBorder: {
      borderRight: '1px solid #eee',
    },
  }),
  { name: 'DataRowHeaders' }
)
