import { reducerWithInitialState } from 'typescript-fsa-reducers'

import actions from './actions'
import { IHintState } from './models'

export const HintState: IHintState = {
  // -----------------------------
  // Hint 個別
  // -----------------------------
  hint: {
    category: '',
    commentCount: 0,
    content: '',
    contentPreview: '',
    id: '',
    imageUrl: null,
    isPublished: 0,
    title: '',
    createdAt: '',
    updatedAt: '',
    user: {
      id: '',
      firstName: '',
      lastName: '',
      nickname: '',
      imageUrl: '',
      shortDescription: '',
      description: '',
    },
    username: '',
  },

  // -----------------------------
  // Hint 各種
  // -----------------------------
  hintList: {
    items: [],
    nextToken: '',
  },
  hintSearchList: {
    items: [],
    nextToken: '',
  },
  hintProfileList: {
    items: [],
    nextToken: '',
  },
  hintProfileListUnpublished: {
    items: [],
    nextToken: '',
  },
  hintCommentList: [],
  hintAction: {
    createData: null,
    deleteData: null,
    updateData: null,
  },

  // -----------------------------
  // Consultation 個別
  // -----------------------------
  consultation: {
    category: '',
    commentCount: 0,
    content: '',
    contentPreview: '',
    id: '',
    isPublished: 0,
    title: '',
    createdAt: '',
    updatedAt: '',
    user: {
      id: '',
      firstName: '',
      lastName: '',
      nickname: '',
      imageUrl: '',
      shortDescription: '',
      description: '',
    },
    username: '',
  },

  // -----------------------------
  // Consultation 各種
  // -----------------------------
  consultationList: {
    items: [],
    nextToken: '',
  },
  consultationSearchList: {
    items: [],
    nextToken: '',
  },
  consultationCommentList: [],
  consultationAction: {
    createData: null,
    deleteData: null,
    updateData: null,
  },

  // -----------------------------
  // コメント
  // -----------------------------
  comment: {
    id: '',
    hintId: '',
    content: '',
    type: '',
  },

  // -----------------------------
  // 回答
  // -----------------------------
  answer: {
    id: '',
    consultationId: '',
    content: '',
    type: '',
  },

  // -----------------------------
  // プロフィール
  // -----------------------------
  user: {
    id: '',
    firstName: '',
    lastName: '',
    imageUrl: '',
    shortDescription: '',
    description: '',
  },

  // -----------------------------
  // token
  // -----------------------------
  token: {
    nextToken: [],
    lastPage: false,
  },

  // -----------------------------
  // UI
  // -----------------------------
  ui: {
    modal: {
      isModal: false,
      status: 'CLOSE',
      id: '',
    },
  },

  // -----------------------------
  // ページ
  // -----------------------------
  pages: {
    currentPages: 1,
    pageAction: '',
    processing: false,
  },

  // -----------------------------
  // カテゴリー
  // -----------------------------
  category: {
    type: 'HINT',
    category: '',
  },

  // -----------------------------
  // 共通
  // -----------------------------
  common: {
    isLoading: false,
    error: null,
  },
}

export const HintReducers = reducerWithInitialState(HintState)
  // -----------------------------
  // Hint 個別
  // -----------------------------
  .case(actions.getHint.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHint.done, (state, payload) => ({
    ...state,
    hint: { ...state.hint, ...payload.result },
    common: { isLoading: false, error: null },
  }))

  .case(actions.getHint.failed, (state, error) => ({
    ...state,
    common: { isLoading: false, error: error },
  }))

  // -----------------------------
  // Hint 一覧
  // -----------------------------
  .case(actions.getHintList.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHintList.done, (state, payload) => ({
    ...state,
    hintList: {
      items: [...payload.result.items],
      nextToken: payload.result.nextToken,
    },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getHintList.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Hint カテゴリ一覧
  // -----------------------------
  .case(actions.getHintListSearch.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHintListSearch.done, (state, payload) => ({
    ...state,
    hintSearchList: {
      items: [...payload.result.items],
      nextToken: payload.result.nextToken,
    },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getHintListSearch.failed, (state, error) => ({
    ...state,
    common: { isLoading: false, error: error },
  }))

  // -----------------------------
  // Hint プロフィール 一覧[公開]
  // -----------------------------
  .case(actions.getHintListProfile.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHintListProfile.done, (state, payload) => ({
    ...state,
    hintProfileList: {
      items: [...payload.result.items],
      nextToken: payload.result.nextToken,
    },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getHintListProfile.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Hint プロフィール 一覧[下書き]
  // -----------------------------
  .case(actions.getHintListProfileUnpublished.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHintListProfileUnpublished.done, (state, payload) => ({
    ...state,
    hintProfileListUnpublished: {
      items: [...payload.result.items],
      nextToken: '',
    },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getHintListProfileUnpublished.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Hint コメント 一覧
  // -----------------------------
  .case(actions.getHintCommentList.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getHintCommentList.done, (state, payload) => ({
    ...state,
    hintCommentList: [...payload.result],
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getHintCommentList.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Hint 作成
  // -----------------------------
  .case(actions.createHint.started, (state) => ({
    ...state,
  }))

  .case(actions.createHint.done, (state) => ({
    ...state,
  }))

  .case(actions.createHint.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // Hint 更新
  // -----------------------------
  .case(actions.updateHint.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.updateHint.done, (state) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.updateHint.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // Hint 削除
  // -----------------------------
  .case(actions.deleteHint.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.deleteHint.done, (state, payload) => ({
    ...state,
    hintAction: { ...state.hintAction, deleteData: payload.result },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.deleteHint.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // Hint コメント 作成
  // -----------------------------
  .case(actions.createHintComment.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.createHintComment.done, (state, payload) => ({
    ...state,
    user: { ...state.user, ...payload.result },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.createHintComment.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // Consultation 個別
  // -----------------------------
  .case(actions.getConsultation.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getConsultation.done, (state, payload) => ({
    ...state,
    consultation: { ...state.consultation, ...payload.result },
    common: { isLoading: false, error: null },
  }))

  .case(actions.getConsultation.failed, (state, error) => ({
    ...state,
    common: { isLoading: false, error: error },
  }))

  // -----------------------------
  // Consultation 一覧
  // -----------------------------
  .case(actions.getConsultationList.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getConsultationList.done, (state, payload) => ({
    ...state,
    consultationList: {
      items: [...payload.result.items],
      nextToken: payload.result.nextToken,
    },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getConsultationList.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Consultation カテゴリ一覧
  // -----------------------------
  .case(actions.getConsultationListSearch.started, (state) => ({
    ...state,
  }))

  .case(actions.getConsultationListSearch.done, (state, payload) => ({
    ...state,
    consultationSearchList: {
      items: [...payload.result.items],
      nextToken: payload.result.nextToken,
    },
  }))

  .case(actions.getConsultationListSearch.failed, (state, error) => ({
    ...state,
    common: { isLoading: false, error: error },
  }))

  // -----------------------------
  // Consultation コメント 一覧
  // -----------------------------
  .case(actions.getConsultationCommentList.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getConsultationCommentList.done, (state, payload) => ({
    ...state,
    consultationCommentList: [...payload.result],
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getConsultationCommentList.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // Consultation 作成
  // -----------------------------
  .case(actions.createConsultation.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.createConsultation.done, (state) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.createConsultation.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // Consultation 更新
  // -----------------------------
  // .case(actions.updateConsultation.started, (state) => ({
  //   ...state,
  //   common: { isLoading: true, error: null }
  // }))

  // .case(actions.updateConsultation.done, (state) => ({
  //   ...state,
  //   common: { ...state.common, isLoading: false, error: null }
  // }))

  // .case(actions.updateConsultation.failed, (state) => ({
  //   ...state,
  // }))

  // -----------------------------
  // Consultation 削除
  // -----------------------------
  // .case(actions.deleteConsultation.started, (state) => ({
  //   ...state,
  //   common: { isLoading: true, error: null }
  // }))

  // .case(actions.deleteConsultation.done, (state, payload) => ({
  //   ...state,
  //   consultationAction: { ...state.consultationAction, ...payload.result },
  //   common: { ...state.common, isLoading: false, error: null }
  // }))

  // .case(actions.deleteConsultation.failed, (state, payload) => ({
  //   ...state,
  // }))

  // -----------------------------
  // Consultation コメント 作成
  // -----------------------------
  .case(actions.createConsultationComment.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.createConsultationComment.done, (state, payload) => ({
    ...state,
    user: { ...state.user, ...payload.result },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.createConsultationComment.failed, (state, error) => ({
    ...state,
    common: { ...state.common, isLoading: false, error: error },
  }))

  // -----------------------------
  // コメント
  // -----------------------------
  .case(actions.getComment, (state, payload) => ({
    ...state,
    comment: {
      ...state.comment,
      ...payload,
    },
  }))

  // -----------------------------
  // 回答
  // -----------------------------
  .case(actions.getAnswer, (state, payload) => ({
    ...state,
    answer: {
      ...state.answer,
      ...payload,
    },
  }))

  // -----------------------------
  // プロフィール情報
  // -----------------------------
  .case(actions.getUser.started, (state) => ({
    ...state,
    common: { isLoading: true, error: null },
  }))

  .case(actions.getUser.done, (state, payload) => ({
    ...state,
    user: { ...state.user, ...payload.result },
    common: { ...state.common, isLoading: false, error: null },
  }))

  .case(actions.getUser.failed, (state) => ({
    ...state,
  }))

  // -----------------------------
  // モーダルの状態 CREATE
  // -----------------------------
  .case(actions.createModalActive, (state, payload) => ({
    ...state,
    ui: {
      modal: { isModal: true, status: 'CREATE', id: payload.id },
    },
  }))

  // -----------------------------
  // モーダルの状態 UPDATE
  // -----------------------------
  .case(actions.updateModalActive, (state, payload) => ({
    ...state,
    ui: {
      modal: { isModal: true, status: 'UPDATE', id: payload.id },
    },
  }))

  // -----------------------------
  // モーダルの状態 CLOSE
  // -----------------------------
  .case(actions.closeModalActive, (state) => ({
    ...state,
    ui: {
      modal: { isModal: false, status: 'CLOSE', id: '' },
    },
  }))

  // -----------------------------
  // ページ
  // -----------------------------
  .case(actions.getPages, (state, payload) => ({
    ...state,
    pages: {
      ...state.pages,
      currentPages: payload.currentPages,
      pageAction: payload.pageAction,
      processing: payload.processing,
    },
  }))

  // -----------------------------
  // カテゴリー
  // -----------------------------
  .case(actions.getCategory, (state, payload) => ({
    ...state,
    category: { ...state.category, type: payload.type, category: payload.category },
  }))

  // -----------------------------
  // トークン
  // -----------------------------
  .case(actions.getToken, (state, payload) => ({
    ...state,
    token: {
      ...state.token,
      nextToken: [...payload.nextToken],
      lastPage: payload.lastPage,
    },
  }))

  .case(actions.resetToken, (state) => ({
    ...state,
    token: {
      nextToken: [],
      lastPage: false,
    },
  }))

  // -----------------------------
  // フェッチリセット
  // -----------------------------
  .case(actions.resetFetch, (state, payload) => ({
    ...state,
    hintList: {
      items: [],
      nextToken: '',
    },
    hintSearchList: {
      items: [],
      nextToken: '',
    },
    hintProfileList: {
      items: [],
      nextToken: '',
    },
    hintProfileListUnpublished: {
      items: [],
      nextToken: '',
    },
    consultationList: {
      items: [],
      nextToken: '',
    },
    consultationSearchList: {
      items: [],
      nextToken: '',
    },
  }))
