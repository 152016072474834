import React, { useState } from 'react'

import { Fade, makeStyles, Modal, Paper, Theme, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { render, unmountComponentAtNode } from 'react-dom'

import { SubmitButton } from '../button-large'

import * as constants from 'assets/constants'


type ScreenModalProps = {
  head?: string
  body?: string
  buttonText?: string
  onClickButton?: () => void
  altButtonText?: string
  onClickAltButton?: () => void
  outerText?: string
}

/**
 * Show screenModal w/o provided callback on button.
 * It sticks while url changes since it's outside `root` dom.
 */
export const callScreenModal = async (props: ScreenModalProps) => {
  await new Promise((r) => setTimeout(r, 100)) // unmount buffer
  const doc = document.getElementById('modal-tooltip-div')
  if (doc && doc.children.length === 0) {
    render(
      <ScreenModal
        key={props.head}
        {...props}
        onClickButton={props.onClickButton}
        onClickAltButton={props.onClickAltButton}
      />,
      doc
    )
  }
}

export const ScreenModal = ({
  head,
  body,
  buttonText,
  onClickButton,
  altButtonText,
  onClickAltButton,
  outerText,
}: ScreenModalProps) => {
  const classes = useStyles({ outerText })
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [open, setOpen] = useState(true)

  const withClose = (event?: () => void) => () => {
    setOpen(false)
    const tt = document.getElementById('modal-tooltip-div')
    tt && unmountComponentAtNode(tt)
    event && event()
  }

  if (!open) return null // unmount conditionally to pursuade React
  return (
    <Modal open={true} className={classes.modal} disableAutoFocus={true}>
      <>
        <Fade in={open} timeout={400}>
          <Paper className={classes.modalPaper}>
            {head && <div className={classes.head}>{head}</div>}
            {body && <div className={classes.body}>{body}</div>}
            {altButtonText && (
              <>
                {altButtonText === 'スキップ' ? (
                  <div className={classes.altButton} onClick={withClose(onClickAltButton)}>
                    {altButtonText}
                  </div>
                ) : (
                  <div className={classes.altButtonOriginal} onClick={withClose(onClickAltButton)}>
                    {altButtonText}
                  </div>
                )}
              </>
            )}
            {outerText && (
              <div className={classes.outerText}>
                <img src={require('./assets/time.svg').default} alt="time" />
                <span>{outerText}</span>
              </div>
            )}
          </Paper>
        </Fade>
        <div className={classes.footer}>
          <SubmitButton
            SPwidth={isSmDown ? true : false}
            placeholder={buttonText || 'OK'}
            disabled={false}
            onPress={withClose(onClickButton)}
          />
        </div>
      </>
    </Modal>
  )
}

const useStyles = makeStyles<Theme, ScreenModalProps>((theme: Theme) => ({
  wrapper: {
    opacity: 0.4,
    transition: 'all 3s ease-in',
    '&.appeared': {
      opacity: 1,
      backgroundColor: 'red',
    },
  },
  footer: {
    minWidth: '100%',
    position: 'absolute',
    bottom: '0',
    background: constants.COLOR_WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },

  head: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '1em',
    marginTop: 8,
    marginBottom: 21,
    '& ~ button': {
      marginTop: 8,
    },
  },

  body: {
    fontSize: 14,
    width: '100%',
    lineHeight: '24px',
    marginTop: 3,
    marginBottom: 22,
  },

  altButton: {
    fontSize: 12,
    color: constants.COLOR_WHITE,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: ({ outerText }) => (outerText ? 80 : 28),
    cursor: 'pointer',
    position: 'absolute',
    minWidth: 88,

    left: '35%',
    border: 'solid 2px white',
    borderRadius: '8px',
    padding: 10,
  },
  altButtonOriginal: {
    fontSize: 12,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    marginTop: 28,
    marginBottom: 10,
    cursor: 'pointer',
  },

  outerText: {
    color: '#fff',
    fontWeight: 'bold',
    position: 'absolute',
    fontSize: 14,
    bottom: -40,
    left: 0,

    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: 24,
      height: 24,
      display: 'block',
    },
    '& span': {
      display: 'block',
      marginLeft: 8,
    },
  },

  modalPaper: {
    position: 'relative',
    width: '100%',
    padding: 16,
    maxWidth: 350,
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
    '& button': {
      width: '100%',
    },
  },
}))
