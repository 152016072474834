import * as React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constants from 'assets/constants'

type SPWrapperProps = {
  children: React.ReactNode
}

type TopRenewalWrapperProps = {
  children: React.ReactNode
  bgColor?: string
}

export const SPWrapper = ({ children }: SPWrapperProps) => {
  const classes = useStyles()

  return <div className={classes.spPadding}>{children}</div>
}

export const TopRenewalWrapper = ({ children, bgColor }: TopRenewalWrapperProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div style={{ backgroundColor: bgColor ?? constants.COLOR_WHITE }}>
      <div
        style={{
          margin: isSmDown ? '0 auto' : undefined,
          maxWidth: isSmDown ? 600 : undefined,
        }}
      >
        <div className={classes.container}>{children}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '0 24px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    [theme.breakpoints.up('md')]: {
      padding: '0 7%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  spPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
}))
