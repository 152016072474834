import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import { UseFormMethods } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { useGhostStore } from 'pages/ghost/contexts/ghost'
import { HooksContext } from 'pages/teams/contexts'
import { IndexedTeam } from 'pages/teams/pages/list'
import { GraphListGhostModal } from 'pages/teams/pages/list/components/atoms/GraphListGhostModal'
import { SortedInfoKey, SortedInfoSort } from 'pages/teams/pages/list/components/hooks/sortTeams'

import { SearchIcon, GraphNonIcon } from '../../assets'
import { FilterProps } from '../Filter'

import { AverageEvaluation, BuildingRate, GrowthRate } from './'

import { constants, Pages, useHistory } from 'assets'

type Props = {
  team: IndexedTeam
  filter: FilterProps
  setFilter: (value: FilterProps) => void
  statusChangeList: boolean
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  watchedTeamIds: string[] | string | boolean
  sortKey: {
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null
}

export const GraphListBodyRow: React.FC<Props> = ({
  team,
  filter,
  setFilter,
  statusChangeList,
  register,
  setValue,
  watchedTeamIds,
  sortKey,
}) => {
  const classes = useStyles()
  const { route } = useHistory()
  const [isOpenGhostModal, setIsOpenGhostModal] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)
  const { currentTeam } = React.useContext(HooksContext)

  const { ghosts } = useGhostStore(team.id)
  const ghostName = (ghostId: string | undefined | null) => ghosts.find((item) => item.id === ghostId)?.name

  React.useEffect(() => {
    setValue('teamIds', [])
  }, [filter.isNonUse, setValue])

  const [checked, setChecked] = React.useState(false)
  const isChecked = React.useMemo(() => {
    if (Array.isArray(watchedTeamIds)) {
      return watchedTeamIds.includes(team.id)
    }
    return watchedTeamIds === team.id
  }, [watchedTeamIds, team.id])

  React.useEffect(() => {
    setChecked(isChecked)
  }, [isChecked, sortKey])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <>
      <TableRow
        className={`${classes.tableBodyRow}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* No. */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={`${classes.tableBodyCell} ${classes.cellCenter} ${classes.stickyBodyCellCol1} ${
            isHovered ? classes.activeHoverCell : ''
          }`}
        >
          {team.num}
        </TableCell>
        {/* チーム名 */}
        <TableCell
          role="link"
          onClick={() => {
            if (!statusChangeList) {
              route.push(Pages.TeamsDashboard, { teamId: team.id })
            }
          }}
          className={`${classes.tableBodyCell} ${classes.stickyBodyCellCol2} ${
            isHovered ? classes.activeHoverCell : ''
          } ${statusChangeList ? classes.statusTeamCell : ''} ${
            team.id === currentTeam?.id ? classes.mainTextColor : ''
          }`}
        >
          {statusChangeList && (
            <>
              <span className={classes.checkbox}>
                <input
                  type="checkbox"
                  id={team.id}
                  value={team.id}
                  name="teamIds"
                  ref={register}
                  checked={checked || false}
                  onChange={handleCheckboxChange}
                />
              </span>
              <label className={classes.label} htmlFor={team.id}>
                {team.name}
              </label>
            </>
          )}
          {!statusChangeList && team.name}
        </TableCell>
        {/* キックオフ日 */}
        <TableCell className={classes.tableBodyCell}>{team.teamBuildingStartDate ?? '_'}</TableCell>
        {/* アクション達成度 */}
        <TableCell className={classes.tableBodyCell}>
          {team?.teamBuildingProgress || team?.teamBuildingProgress === 0 ? team.teamBuildingProgress + '％' : '_'}
        </TableCell>
        {/* カルテ実施回数 */}
        <TableCell className={classes.tableBodyCell}>{team?.karteCount || '_'}</TableCell>
        {/* リフレクション実施回数 */}
        <TableCell className={classes.tableBodyCell}>{team?.teamBuildingReviewHistoryCount || '_'}</TableCell>
        {/* >オバケ実施回数 */}
        <TableCell className={classes.tableBodyCell}>{team?.ghostHistoryCount || '_'}</TableCell>
        {/* >オバケ最終実施日 */}
        <TableCell className={classes.tableBodyCell}>
          {team?.ghostLastPlayedDate && team?.ghostLastDiscoveredId ? (
            <div>
              <div>{team.ghostLastPlayedDate}</div>
              <div>
                <NoStyleButton className={classes.lastGhostName} onClick={() => setIsOpenGhostModal(true)}>
                  <SearchIcon />
                  <span>{ghostName(team.ghostLastDiscoveredId)}</span>
                </NoStyleButton>
              </div>
            </div>
          ) : (
            '_'
          )}
        </TableCell>
        {/* 直近アンケート実施日 */}
        <TableCell className={classes.tableBodyCell}>{team.anketBuildingDate || '_'}</TableCell>
        {/* 最新総合評価 */}
        <TableCell className={classes.tableBodyCell}>
          <AverageEvaluation value={team.anketBuildingAverage} />
        </TableCell>
        {/* 成長度（前回比） */}
        <TableCell className={`${classes.tableBodyCell} ${classes.cellCenter}`}>
          <GrowthRate value={team.anketBuildingGrowth} />
        </TableCell>
        {/* チームビルディング度 */}
        <TableCell className={`${classes.tableBodyCell} ${classes.cellCenter}`}>
          <BuildingRate value={team.anketBuildingValue} gap={team.anketBuildingDiff} />
        </TableCell>
        {/* チーム別評価詳細グラフ */}
        <TableCell className={`${classes.tableBodyCell} ${classes.actionButtons}`}>
          <div
            className={classNames(
              classes.graphButton,
              team.anketBuildingAverage === undefined && classes.graphButtonDisabled
            )}
            onClick={() => {
              team.anketBuildingAverage !== undefined &&
                (setFilter({ ...filter, selectedTeamId: team.id, isHidden: false }), window.scrollTo(0, 0))
            }}
            role="button"
          >
            <GraphNonIcon />
          </div>
        </TableCell>
      </TableRow>

      <GraphListGhostModal
        isOpen={isOpenGhostModal}
        setIsOpen={setIsOpenGhostModal}
        teamId={team.id}
        ghostId={team?.ghostLastDiscoveredId as string}
        ghostName={ghostName(team?.ghostLastDiscoveredId)}
        ghostHistoryCount={team?.ghostHistoryCount as number}
        ghostLastPlayedDate={team?.ghostLastPlayedDate as string}
      />
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    tableBodyRow: {
      padding: 16,
      backgroundColor: constants.COLOR_WHITE,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_GRAY_LIGHT7,
        },
      },
    },
    tableBodyCell: {
      fontSize: 12,
      color: constants.TEXT_GRAY_DARK,
      whiteSpace: 'nowrap',
      border: 'none',
      maxWidth: 600,
      height: 50,
      padding: '0 16px',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    cellCenter: {
      textAlign: 'center',
    },
    stickyBodyCellCol1: {
      backgroundColor: constants.COLOR_WHITE,
      minWidth: 80,
      width: 80,
      maxWidth: 80,

      '@media (hover: hover)': {
        '&:hover': {
          cursor: 'pointer',
        },
      },

      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 0,
      },
    },
    stickyBodyCellCol2: {
      borderRight: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      backgroundColor: constants.COLOR_WHITE,
      maxWidth: 250,

      '@media (hover: hover)': {
        '&:hover': {
          cursor: 'pointer',
        },
      },

      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 80,
      },
    },
    activeHoverCell: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: constants.COLOR_GRAY_LIGHT7,
      },
    },
    statusTeamCell: {
      display: 'flex',
      alignItems: 'baseline',
    },
    mainTextColor: {
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
    },
    lastGhostName: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        opacity: '75%',
      },
      '& span': {
        marginLeft: 3,
        color: constants.COLOR_MAIN_NEW,
        fontSize: 10,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
      },
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        height: '1.75rem',
        padding: '5px 10px',
      },
    },
    graphButton: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      border: `1px solid ${constants.COLOR_MAIN}`,
      borderRadius: 5,
      display: 'flex',
      '&:hover': {
        opacity: '75%',
      },
    },
    graphButtonDisabled: {
      cursor: 'auto',
      opacity: 0.3,
      '&:hover': {
        opacity: 'initial',
      },
    },
    checkbox: {
      display: 'inline-block',
      width: 16,
      height: 16,
      '& input[type="checkbox"]': {
        position: 'relative',
        width: 16,
        height: 16,
        border: `1px solid ${constants.COLOR_MAIN_NEW}`,
        borderRadius: 10,
        margin: 0,
        top: 4,
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '@media (hover: hover)': {
        '& input[type="checkbox"]:hover': {
          cursor: 'pointer',
        },
      },
      '& input[type="checkbox"]:checked': {
        border: 'none',
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '& input[type="checkbox"]:checked::before': {
        content: '""',
        position: 'absolute',
        top: 3,
        left: 6,
        width: 5,
        height: 8,
        borderRight: '2px solid #fff',
        borderBottom: '2px solid #fff',
        transform: 'rotate(45deg)',
      },
    },
    label: {
      display: 'inline-block',
      padding: '16px 8px',
      fontSize: 12,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      '@media (hover: hover)': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'GraphListBodyRow' }
)
