import React from 'react'

import { useTheme, useMediaQuery } from '@material-ui/core'

import Timeline from '../../timeline'
import { RoleTutorialProps } from '../Tutorial'

import { TimelinePostsDummy } from '../assets/dummy'
import { callTx } from '../assets/text'

const SupporterTutorial: React.FC<RoleTutorialProps> = (props) => {
  const { stage, setStage } = props
  const text = callTx(props.teamMember.role)[stage]

  const theme = useTheme()
  const isXSDown = useMediaQuery(theme.breakpoints.down('xs'))

  switch (props.stage) {
    case 4:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(false),
            loading: false,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'タイムラインへ',
              onClickButton: () => setStage(5),
            },
          }}
        />
      )
    case 5:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(false),
            loading: false,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -118, y: -5 },
              pointer: { position: 'bottom', offset: { x: 0, y: -5 } },
              onClickRef: () => setStage(6),
              onWhat: 'like',
            },
          }}
        />
      )
    case 6:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(true),
            loading: false,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 5, y: 10 },
              pointer: { position: 'bottom', offset: { x: isXSDown ? 5 : -105, y: 10 } },
              onClickRef: () => undefined,
              onWhat: 'comment',
            },
            next: () => setStage(7),
          }}
        />
      )
    case 7:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(true),
            loading: false,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'はじめる',
              onClickButton: props.endTutorial,
            },
          }}
        />
      )
    default:
      return null
  }
}

export default SupporterTutorial
