import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useTheme, useMediaQuery } from '@material-ui/core'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostMemberRole, GhostTeam, GhostTeamMember } from 'utils/generated'

import {
  GrayBackGround,
  BackResponsiveCard,
  PageTitle,
  Font,
  LeadText,
  UsefulCard,
  MemberBox,
  Imgcard,
  ButtonForGhost,
} from '../components/atoms'
import { WidthControlContainer, PeopleCounter, ButtonContainer, ModalConfirmForCommon } from '../components/modules'
import { ghostTeamContext, GhostTeamStore } from '../contexts/ghostTeam'
import { ghostTeamMemberContext, GhostTeamMemberStore, listStuckMembers } from '../contexts/ghostTeamMember'
import { PageManagerContext, PageManager } from '../contexts/pageManger'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

import { GP, num2Path, page2Num } from '../assets/pages'
import waitMember from '../assets/svg/waitMember.svg'

export type NextPagesAfterBreak = 'step1Results' | 'votingResults' | 'result' | 'done' | 'vote' | 'workResults'

const messages: { [k in NextPagesAfterBreak]: string[] } = {
  step1Results: ['個人ワーク結果待ち', 'メンバーが個人ワークを終えるまでしばらくお待ちください。', '個人ワーク状況'],
  votingResults: ['投票結果待ち', 'メンバーが投票を終えるまでしばらくお待ちください。', '投票状況'],
  result: ['個人ワーク結果待ち', 'メンバーが個人ワークを終えるまでしばらくお待ちください。', '個人ワーク状況'],
  done: ['個人ワーク結果待ち', 'メンバーが個人ワークを終えるまでしばらくお待ちください。', '個人ワーク状況'],
  vote: ['', '', ''],
  workResults: ['個人ワーク結果待ち', 'メンバーが個人ワークを終えるまでしばらくお待ちください。', '個人ワーク状況'],
}

export const useBreakCtl = (
  team: GhostTeam | undefined,
  teamMember: GhostTeamMember | undefined,
  teamMemberList: GhostTeamMember[],
  reportCategory: PageManager['reportCategory'],
  updateGhostTeam: GhostTeamStore['updateGhostTeam'],
  updateMemberPage: GhostTeamMemberStore['updateMemberPage'],
  renewTeamMemberList: GhostTeamMemberStore['renewTeamMemberList']
) => {
  const waitPageNums = (['step1Results', 'votingResults', 'result', 'done', 'vote', 'workResults'] as const).map((p) =>
    page2Num(p, teamMember)
  )
  const [next, setNext] = useState<NextPagesAfterBreak>()
  const [open, setOpen] = useState(false)
  const [stuckMembers, setStuckMembers] = useState<GhostTeamMember[]>([])
  const nextPageNum = teamMember?.page ? teamMember?.page + 1 : undefined
  const [currentMembers, setCurrentMembers] = useState<(GhostTeamMember & { isWaiting?: boolean })[]>([])
  const currentNum = currentMembers.filter((item) => item.isWaiting).length
  const isReady: boolean = teamMemberList.length === currentNum

  useEffect(() => {
    if (!team || !nextPageNum || !waitPageNums.includes(nextPageNum) || nextPageNum === 36) return
    switch (num2Path(nextPageNum)) {
      case GP.step1Results:
        setNext('step1Results')
        break
      case GP.votingResults:
        setNext('votingResults')
        break
      case GP.result:
        setNext('result')
        break
      case GP.done:
        setNext('done')
        break
      case GP.vote:
        setNext('vote')
        break
      case GP.workResults:
        setNext('workResults')
        break
    }
  }, [team, nextPageNum, waitPageNums, reportCategory, teamMember])

  const onButtonClick = useCallback(
    async ({ force = false } = {}) => {
      const isCorrectNextPageNumber = nextPageNum && waitPageNums.includes(nextPageNum) && nextPageNum !== 36
      if ((isReady || force) && isCorrectNextPageNumber && team?.id) {
        nextPageNum && (await updateMemberPage(nextPageNum))
        updateGhostTeam({ input: { id: team?.id, selectListManage: nextPageNum !== 7 ? true : false } }) // because of  only start to running pageSync at memberPages when this API runs
      }
    },
    [isReady, nextPageNum, team?.id, updateGhostTeam, updateMemberPage, waitPageNums]
  )

  const toggleModal = useCallback(async () => {
    if (teamMember && next && !open) {
      const members = await listStuckMembers(teamMember.teamId, next, teamMember)
      setStuckMembers(members)
    }
    setOpen((prev) => !prev)
  }, [next, open, teamMember])

  const handleProceed = useCallback(
    async (forceProceed: (page: NextPagesAfterBreak | 'waiting' | 'report') => Promise<void>) => {
      if (!next) return

      let stuckMembersLen = 0
      if (teamMember) {
        const members = await listStuckMembers(teamMember.teamId, next, teamMember)
        stuckMembersLen = members.length
      }

      if (!stuckMembersLen) {
        alert('除外対象のメンバーが存在しませんでした。')
        setOpen(false)
        return
      }
      await forceProceed(next)
      await onButtonClick({ force: true })
      setOpen(false)
    },
    [next, onButtonClick, teamMember]
  )

  useEffect(() => {
    //sync page when a member join the break page
    if (currentNum === 0) return
    renewTeamMemberList()
  }, [renewTeamMemberList, team, currentNum])

  useEffect(() => {
    const ownTeamMemberList = teamMemberList.map((item) => ({ ...item }))
    ownTeamMemberList.sort((a, b) => {
      if (a?.name && b?.name) {
        return a?.name <= b?.name ? -1 : 1
      } else return 0
    })
    setCurrentMembers(
      ownTeamMemberList.map((item) => {
        const isWaiting = item?.page && nextPageNum && item?.page + 1 === nextPageNum
        return isWaiting ? { ...item, isWaiting: true } : item
      })
    )
  }, [teamMemberList, nextPageNum])

  return {
    mes: next ? messages[next] : ['', '', ''],
    next,
    currentMembers,
    currentNum,
    isReady,
    onButtonClick,
    open,
    stuckMembers,
    toggleModal,
    handleProceed,
  }
}

// Waiting for others to submit report, research, likes.
export const Break: React.FC = () => {
  const { team, updateGhostTeam } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList, updateMemberPage, renewTeamMemberList } = useContext(ghostTeamMemberContext)
  const { reportCategory } = useContext(PageManagerContext)
  const { mes, currentNum, currentMembers, isReady, onButtonClick, open, stuckMembers, toggleModal, handleProceed } =
    useBreakCtl(
      team,
      teamMember ? teamMember : undefined,
      teamMemberList,
      reportCategory,
      updateGhostTeam,
      updateMemberPage,
      renewTeamMemberList
    )
  const { forceProceed } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'break' })

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <PageTitle ownStyles={{ textAlign: 'center' }}>{mes[0]}</PageTitle>
          <LeadText ownStyles={{ textAlign: 'center' }}>{mes[1]}</LeadText>
          <Imgcard src={waitMember} alt={'membersImage'} ownStyles={{ margin: isSmDown ? '27px 0 24px' : '40px 0' }} />
          <PeopleCounter current={currentNum} amount={teamMemberList.length || 0} />

          <UsefulCard backgroundColor={'white'} ownStyles={{ padding: 24, margin: '40px 0 0' }}>
            <Font fontSize={14} bold ownStyles={{ color: constGhost.COLOR_BLACK3, margin: '0 0 16px' }}>
              {mes[2]}
            </Font>
            <MemberBox currentMembers={currentMembers} page={'break'} />
          </UsefulCard>
          {teamMember?.role === GhostMemberRole.Leader || teamMember?.role === GhostMemberRole.Admin ? ( //delete  admin === true  later
            <>
              <ForceProceed
                onClick={toggleModal}
                open={open}
                handleProceed={() => handleProceed(forceProceed)}
                stuckMembersCount={stuckMembers.length}
              />
              <ButtonContainer
                fixed
                buttons={[
                  <ButtonForGhost
                    key={'next'}
                    buttonBodyColor={isReady ? 'green' : 'gray'}
                    bodyText={'次へ'}
                    onClick={isReady ? onButtonClick : undefined}
                    disabled={!isReady}
                    isResizeBasic
                  />,
                  <ButtonForGhost
                    key={'forceProceed'}
                    buttonBodyColor={isReady ? 'grayLine' : 'white'}
                    bodyText={'全員の回答を待たずに先に進む'}
                    disabled={isReady}
                    onClick={isReady ? undefined : toggleModal}
                    isResizeBasic
                  />,
                ]}
              />
            </>
          ) : (
            <></>
          )}
        </WidthControlContainer>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

export const ForceProceed: React.FC<{
  open: boolean
  onClick: () => void
  handleProceed: () => Promise<void>
  stuckMembersCount: number
}> = ({ open, onClick, handleProceed, stuckMembersCount }) => (
  <ModalConfirmForCommon
    open={open}
    handleModalChange={onClick}
    firstLine="ゲームを強制的に進めますか？"
    info={`メンバー${stuckMembersCount}名をゲームから除外し、ゲームを強制的に進めます。操作中のメンバーがいないか確認した上で、問題がなければOKを押してください。`}
    btnFirstLine="OK"
    btnSecondLine="キャンセル"
    toHome={handleProceed}
  />
)
