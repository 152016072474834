import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import UsefulCollapse from 'components/UsefulCollapse'
import Graph from 'pages/karte/components/result/Graph'
import RankingCard from 'pages/karte/components/result/RankingCard'
import UsefulCircle from 'pages/karte/components/result/UsefulCircle'
import SpeechBubbles from 'pages/karte/components/SpeechBubbles'

import { Button } from '../../components/Button'
import { KarteResult, makeEvenPoints } from '../diagnose/utils'

import { constants, Pages } from 'assets'
import { replacePathParams } from 'assets/history'

export const KarteResults: React.FC<{
  results: KarteResult
  teamId?: string
  hideBackButton?: boolean
  handleOnBack?: () => void
}> = ({ results, teamId, hideBackButton, handleOnBack }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className="__container">
        <div className="__container__content">
          <div className="__container__content__base">
            <SpeechBubbles
              bubbles={[
                <>
                  あなたは…
                  <br />
                  <strong>{results.about.short}</strong>です！
                </>,
              ]}
            />

            <div className="__container__content__note">
              <div className="__container__content__note__title">あなたの大事にしていることは？</div>
              <p>{results.about.long}</p>
              <div className="__container__content__note__title">あなたはどんな人？</div>
              <p>{results.motivation}</p>
            </div>

            <div className="__container__content__title">動機傾向</div>

            <div className="__container__content__circlesContainer">
              <div className="__container__content__circlesContainer__content">
                <div className="__container__content__circlesContainer__content__circle">
                  <UsefulCircle
                    first={results ? results.circleItems[0].label : ''}
                    second={results ? results.circleItems[1].label : ''}
                    third={results ? results.circleItems[2].label : ''}
                  />
                </div>
                <UsefulCollapse
                  body={
                    <div className={`${classes.usefulCircleBody} usefulCircleBody`}>
                      {results &&
                        results.circleItems.map((item, index) => (
                          <div key={index}>
                            <div className="usefulCircleBody__title">
                              <label className="one">
                                {index + 1}位 {item.label}
                              </label>
                            </div>
                            <p>{item.description}</p>
                            {results && index !== results.circleItems.length - 1 && (
                              <div className="usefulCircleBody__hr" />
                            )}
                          </div>
                        ))}
                    </div>
                  }
                  openLabel="各項目の説明を詳しくみる"
                />
              </div>
            </div>

            <div className="__container__content__bubblesContainer">
              <div className="__container__content__bubblesContainer__content">
                <Graph
                  data={results.points.map((item) => ({
                    ...item,
                    value: makeEvenPoints(item.value),
                  }))}
                />
              </div>
            </div>

            <div className="__container__content__rankingContainer">
              <div className="__container__content__rankingContainer__content">
                {results.points.map((point, index) => (
                  <div className="__container__content__rankingContainer__content__item" key={index}>
                    <RankingCard
                      index={index}
                      title={point.title}
                      point={makeEvenPoints(point.value)}
                      body={point.description}
                      color={point.color}
                      link={point.link}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="__container__content__title">特徴的なこだわり</div>

            <p>
              全36問の質問文で見たときに、特にこだわりが見られたのが以下の通りであり、上司や周りの人にも共有しましょう。
            </p>
          </div>

          <div className="__container__content__conclusionContainer">
            <div className="__container__content__conclusionContainer__title">重視していること</div>
            <ul>
              {results.characteristics.good.map((item, index) => (
                <li key={index}>
                  <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte/dotYellow.svg'} alt={'dot'} />
                  {item}
                </li>
              ))}
            </ul>
            <div className="__container__content__conclusionContainer__title">重視していないこと</div>
            <ul>
              {results.characteristics.bad.map((item, index) => (
                <li key={index}>
                  <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte/dotBlue.svg'} alt={'dot'} />
                  {item}
                </li>
              ))}
            </ul>
          </div>

          <div className="__container__content__link">
            <a
              href="https://cocolabo.club/hint/tips/8943dda5-996c-4093-8780-b74b2facede0"
              target="_blank"
              rel="noopener noreferrer"
            >
              更に詳しく特徴を知りたい方はこちら
            </a>
          </div>
          <div className="__container__content__link">
            <a
              href="https://cocolabo.club/hint/tips/459168f2-944f-43dd-b62a-53b9ca9d7db4"
              target="_blank"
              rel="noopener noreferrer"
            >
              診断結果を使ってチームメンバー同士でつながりを深めるには
            </a>
          </div>
          <div className="__container__content__link">
            <a
              href="https://cocolabo.club/hint/tips/37119684-4dc0-40ee-86dc-75a87cc6c15a"
              target="_blank"
              rel="noopener noreferrer"
            >
              カルテ対話を行う際の質問例
            </a>
          </div>

          {!hideBackButton && (
            <div style={{ padding: 16, paddingTop: 40, textAlign: 'center' }}>
              <Link to={replacePathParams(Pages.TeamsDashboard, { teamId })}>
                <Button
                  buttonType="light"
                  style={{
                    height: 48,
                    borderRadius: 48,
                  }}
                >
                  カルテダッシュボードへ戻る
                </Button>
              </Link>
            </div>
          )}

          {handleOnBack && (
            <div style={{ padding: 16, paddingTop: 40, textAlign: 'center' }}>
              <Button
                buttonType="light"
                style={{
                  height: 48,
                  borderRadius: 48,
                }}
                onClick={handleOnBack}
              >
                カルテダッシュボードへ戻る
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .__container': {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_MEDIUM,
      },

      '&__header': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px 8px',
        alignItems: 'center',
        marginTop: 8,
        height: 98,

        '&__button': {
          fontWeight: 'bold',
          '& i': {
            marginRight: 4,
          },
        },
      },

      '&__content': {
        backgroundColor: '#fff',
        borderTopRightRadius: '30px',
        borderTopLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        borderBottomLeftRadius: '30px',
        padding: '16px 0 32px',
        boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

        '&__base': {
          padding: '0 16px',
        },

        '&__note': {
          backgroundColor: '#F5F5F5',
          padding: 16,
          margin: 16,
          marginTop: 0,

          '&__title': {
            color: constants.COLOR_MAIN,
            // fontSize: 20,
            fontWeight: 'bold',
          },
        },

        '&__circlesContainer': {
          marginBottom: 16,

          '&__content': {
            '&__circle': {
              //
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              padding: 16,
              backgroundColor: '#F5F5F5',
            },
          },
        },

        '&__bubblesContainer': {
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'center',
          '&__content': {
            width: '100%',

            [theme.breakpoints.up('md')]: {
              width: '70%',
            },
          },
        },

        '&__rankingContainer': {
          marginBottom: 16,

          '&__content': {
            display: 'flex',
            flexWrap: 'wrap',

            '&__item': {
              width: '100%',

              [theme.breakpoints.up('md')]: {
                width: '50%',
              },
            },
          },
        },

        '&__conclusionContainer': {
          padding: 16,
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/karte/bg_heart.svg)`,
          backgroundSize: 'cover',
          '&__title': {
            fontWeight: 'bold',
            fontSize: 18,
            marginBottom: 16,
          },

          '& ul': {
            paddingLeft: 8,
          },

          '& li': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            marginBottom: 8,

            '& img': {
              width: 8,
              height: 8,
              marginRight: 8,
            },
          },
        },

        '&__compareContainer': {
          backgroundColor: '#F5F5F5',
          padding: 16,
          '&__title': {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 8,
          },
        },

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: 20,
          fontWeight: 'bold',
          padding: '16px 0',
          borderTop: '1px dotted',
        },

        '&__buttons': {
          display: 'flex',
          padding: 16,
          '& :first-child': {
            marginRight: '4px',
          },
          '& :last-child': {
            marginLeft: '4px',
          },
          '& button': {
            height: 50,
            borderRadius: 50,
            fontSize: 16,
          },
        },

        '&__link': {
          padding: '4px 16px',
          color: constants.COLOR_MAIN,
          textDecoration: 'underline',
        },
      },

      '&__footer': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px 8px',
        alignItems: 'center',
        marginTop: 8,
        height: 98,

        '&__button': {
          fontWeight: 'bold',
          '& i': {
            marginRight: 4,
          },
        },
      },
    },
  },

  usefulCircleBody: {
    padding: '8px 16px',
    borderLeft: '1px solid #f3f3f3',
    borderRight: '1px solid #f3f3f3',
    '& .usefulCircleBody': {
      '& p': {
        margin: 0,
        paddingBottom: 8,
      },

      '&__title': {
        display: 'block',
        fontSize: 16,
        fontWeight: 'bold',
        margin: '8px 0',

        '& label': {
          '&.one': {
            background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
            // fontWeight: 'bold',
          },
        },
      },

      '&__hr': {
        borderBottom: '1px solid #e5e5e5',
      },
    },
  },
}))
