import * as React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

import * as constants from '../../assets/constants'

import { AutoWrapLine } from './AutoWrapLine'
import Man from './svg/Man'
import Woman from './svg/Woman'

interface IOwnProps {
  gender: 'woman' | 'man'
  goodLabel: string
  badLabel: string
  answerGood: string[]
  answerBad: string[]
  compare?: boolean
}

const styles = StyleSheet.create({
  contents: {
    position: 'relative',
    padding: 4,
    borderRadius: 5,
    border: `2px solid ${constants.COLOR_BLUE_LIGHT}`,
    fontWeight: 'bold',
  },
  contents__compare: {
    position: 'relative',
    padding: 4,
    borderRadius: 5,
    border: `2px solid ${constants.COLOR_BLUE_LIGHT}`,
    fontWeight: 'bold',
    backgroundColor: constants.COLOR_MAIN_LIGHT,
  },
  memberAnswerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 8,
  },
  memberAnswerContainer__img: {
    width: 64,
    maxWidth: '33%',
    height: 136,
    padding: '0 8px',
    alignItems: 'center',
    display: 'flex',
  },
  memberAnswerContainer__title: {
    borderLeft: `3px solid ${constants.COLOR_BLUE_LIGHT}`,
    paddingLeft: 8,
    marginBottom: 16,
    fontSize: 9,
  },
  memberAnswerContainer__body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
    width: '100%',
  },
  memberAnswerContainer__body__good: {
    backgroundColor: constants.COLOR_RED2,
    minWidth: 12,
    minHeight: 12,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: constants.COLOR_WHITE,
    lineHeight: 0,
    marginRight: 4,
  },
  memberAnswerContainer__body__bad: {
    backgroundColor: constants.COLOR_BLUE,
    minWidth: 12,
    minHeight: 12,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: constants.COLOR_WHITE,
    lineHeight: 0,
    marginRight: 2,
  },
  memberAnswerContainer__answerGood: {
    width: '33%',
    paddingTop: 16,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
  memberAnswerContainer__answerBad: {
    width: '33%',
    paddingTop: 16,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
})

export const MembersAnswer: React.FC<IOwnProps> = (props) => {
  return (
    <View style={props.compare ? styles.contents__compare : styles.contents}>
      <View style={styles.memberAnswerContainer}>
        <View style={styles.memberAnswerContainer__answerGood}>
          <Text style={styles.memberAnswerContainer__title}>{props.goodLabel}</Text>
          {props.answerGood.map((answer, index) => (
            <View key={`answerGood-${index}`} style={styles.memberAnswerContainer__body}>
              <View style={styles.memberAnswerContainer__body__good}>
                <Text>{index + 1}</Text>
              </View>
              <AutoWrapLine text={answer} />
            </View>
          ))}
        </View>

        <View style={styles.memberAnswerContainer__img}>{props.gender === 'woman' ? Woman({}) : Man({})}</View>

        <View style={styles.memberAnswerContainer__answerBad}>
          <Text style={styles.memberAnswerContainer__title}>{props.badLabel}</Text>
          {props.answerBad.map((answer, index) => (
            <View key={`answerBad-${index}`} style={styles.memberAnswerContainer__body}>
              <View style={styles.memberAnswerContainer__body__bad}>
                <Text>{index + 1}</Text>
              </View>
              <AutoWrapLine text={answer} />
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}
