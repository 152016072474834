import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: '90vh',
    backgroundColor: constants.COLOR_WHITE,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  color: string
  className?: string
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const Wrapper = (props: IProps) => {
  const classes = useStyles()

  return (
    <section className={`${classes.root} ${props.className}`} style={{ backgroundColor: props.color }}>
      {props.children}
    </section>
  )
}
