import * as React from 'react'

import { TextField, makeStyles, Theme, InputAdornment } from '@material-ui/core'

import MagnifierSvg from './assets/magnifier.svg'
import * as constants from 'assets/constants'

interface Props {
  placeholder?: string
  borderColor?: string
  borderRadius?: number
  iconMarginLeft?: number
  iconMarginRight?: number
  onClickImg?: () => void
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPressInput?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputValue?: string
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>

  setInputValue?: React.Dispatch<React.SetStateAction<string>>
  searchValue?: string
}

export const SearchBar: React.FC<Props> = ({
  placeholder,
  borderColor,
  borderRadius,
  iconMarginLeft,
  iconMarginRight,
  onClickImg,
  onChangeInput,
  onKeyPressInput,
  inputValue,
  setSearchValue,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        border: borderColor ? `1px solid ${borderColor}` : 'none',
        borderRadius: borderRadius ?? 0,
      }}
    >
      <TextField
        type="search"
        className={classes.input}
        placeholder={placeholder ?? ''}
        value={inputValue}
        onChange={onChangeInput}
        onKeyPress={onKeyPressInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: 0 }}>
              <img
                src={MagnifierSvg}
                alt={'magnifier'}
                onClick={onClickImg}
                style={{
                  cursor: 'pointer',
                  marginLeft: iconMarginLeft ?? 0,
                  marginRight: iconMarginRight ?? 0,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {inputValue ? (
                <span
                  data-testid={'search'}
                  className={classes.button}
                  onClick={() => {
                    setSearchValue && setSearchValue(inputValue)
                  }}
                >
                  検索
                </span>
              ) : (
                <></>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 42,
    backgroundColor: constants.COLOR_WHITE,
    display: 'flex',
    alignItems: 'center',
    '& input[type="search"]::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  input: {
    width: '100%',
    fontSize: 14,
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  button: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: 6,
    padding: 10,
  },
}))
