import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

type Props = {
  steps: number
  currentStep: number
}

export const Steps: React.FC<Props> = ({ steps, currentStep }) => {
  const classes = useStyles()
  const stepsArray = Array.from(new Array(steps), (_, i) => i + 1)
  return (
    <div className={classes.step}>
      {stepsArray.map((step) => (
        <div key={step} className={`step${step === currentStep ? ' current' : ''}`} />
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  step: {
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',

    '& .step': {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: '#A2A2A2',
    },

    '& .current': {
      backgroundColor: '#00838C',
    },
  },
}))
