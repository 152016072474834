import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

const Woman4: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-1412.31 -1274.431)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(1412.31 1274.43)" fill="#80d6dd" />
        <G>
          <G>
            <Path
              d="M1587.3,1413.789l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C1589.273,1408.306,1587.274,1410.777,1587.3,1413.789Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(1628.122 1393.609) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1668.853 1342.56)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1573.933 1343.281)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(1584.234 1297.746)" fill="#ffe4d2" />
            <Path
              d="M1685.044,1635.13a2.543,2.543,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M1625.444,1635.577a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M1619.963,1408.077l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M1636.293,1408.3l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(1631.73 1416.148) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M1619.826,1408.077l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M1653.71,1407.817l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M1635.881,1288.429c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C1577.834,1316.347,1603.707,1288.677,1635.881,1288.429Z"
              fill="#522903"
            />
            <G>
              <Path
                d="M1760.167,1423.471l-42.02,37.1a10.128,10.128,0,0,1-14.294-.882h0l-.448-.507c-3.705-4.189-4.355-11.98-.161-15.683l42.021-37.095c4.189-3.705,11.646-1.919,15.348,2.275l.447.507A10.13,10.13,0,0,1,1760.167,1423.471Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1688.535,1457.057l52.616-49.551,17.457,18.957-52.615,49.552s-9.909-4.015-12.248-6.587C1691.487,1466.945,1688.535,1457.057,1688.535,1457.057Z"
                fill="#545364"
              />
            </G>
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1588.285 1543.928) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1647.898 1543.475) rotate(-0.434)"
              fill="#545364"
            />
            <Path d="M1681.669,1408.237l32.505,47.13-21.223,13.776-32.509-47.135Z" fill="#545364" />
            <G>
              <Path
                d="M1536.02,1514.285l28.637-55.342a11.4,11.4,0,0,1,15.3-5.1c.069.034.137.069.2.105l.072.039a11.8,11.8,0,0,1,4.723,15.828l-28.637,55.342a11.4,11.4,0,0,1-15.295,5.1l-.205-.1-.072-.038A11.8,11.8,0,0,1,1536.02,1514.285Z"
                fill="#ffe4d2"
              />
              <Path d="M1611.823,1421.991l-49.431,95.526-22.91-12.278,49.431-95.526Z" fill="#545364" />
            </G>
          </G>
          <G>
            <Path
              d="M1904.783,1290.8a66.7,66.7,0,0,1,32.068,42.866"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1921.063,1288.038a130.6,130.6,0,0,0-7.648,16.247"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1933.963,1301.836a102.341,102.341,0,0,0-14.357,9.918"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1939.609,1316.433l-15.231,5.027"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </G>
          <G>
            <Path
              d="M1549.837,1389.427a66.7,66.7,0,0,0-32.069,42.866"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1533.555,1386.669a130.469,130.469,0,0,1,7.648,16.247"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1520.663,1400.467a102.226,102.226,0,0,1,14.357,9.919"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M1515.011,1415.064l15.231,5.027"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </G>
          <G>
            <Path
              d="M1800,1635.732v.517c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.975.155,18.227-9.7h.036v-.995a2.546,2.546,0,0,0-2.547-2.546h-31.388a2.546,2.546,0,0,0-2.546,2.546Z"
              fill="#72642a"
            />
            <Path
              d="M1859.642,1635.732v.517c0,10.075,8.165,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.035v-.995a2.546,2.546,0,0,0-2.546-2.546h-31.388a2.546,2.546,0,0,0-2.547,2.546Z"
              fill="#72642a"
            />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1859.642 1543.716)" fill="#ffe5d2" />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1799.982 1543.716)" fill="#ffe5d2" />
            <Path
              d="M1849.01,1340.122s-15.615,25.42-15.615,35.225a26.05,26.05,0,0,0,8.351,18.519c2.905,3.267-10.495,17.768-2.8,32.467,7.988,15.252,5.4,5.044,36.251,5.769s-7.608,32.9,6.189,13.652c6.76-9.43,21.76-29.779,25.712-52.5,3.4-19.563-6.914-63.219-6.914-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1843.8,1340.122s15.614,25.42,15.614,35.225a26.05,26.05,0,0,1-8.351,18.519c-2.9,3.267,10.495,17.768,2.8,32.467-7.988,15.252-5.4,5.044-36.251,5.769s7.609,32.9-6.189,13.652c-6.759-9.43-21.764-29.779-25.712-52.5-3.4-19.563,6.915-63.219,6.915-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1891.654,1408h-87.189a4.536,4.536,0,0,0-4.483,4.558V1528.57h96.136V1412.558A4.534,4.534,0,0,0,1891.654,1408Z"
              fill="#7192cc"
            />
            <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(1837.577 1393.588)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1783.214 1342.925)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1878.21 1342.925)" fill="#ffe5d2" />
            <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(1793.448 1297.713)" fill="#ffe5d2" />
            <Path
              d="M1904.863,1349.808c-18.3,0-12.284,3.232-25.759-18.873-23.806,25.615-76.5,17.251-89.471,9.881,3.63-23.475,24.713-52.414,56.91-52.414S1904.863,1315.882,1904.863,1349.808Z"
              fill="#6d614a"
            />
            <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(1799.982 1508.672)" fill="#7192cc" />
            <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(1822.409 1408)" fill="#ffe5d2" />
            <G>
              <Rect
                x="0"
                y="0"
                width="91.351"
                height="25.061"
                rx="12.486"
                transform="translate(1759.8 1542.661) rotate(-73.125)"
                fill="#ffe4d2"
              />
              <Rect
                x="0"
                y="0"
                width="114.577"
                height="28.295"
                transform="translate(1765.269 1520.575) rotate(-73.125)"
                fill="#7192cc"
              />
            </G>
            <G>
              <Path
                d="M1822.2,1426.309a11.765,11.765,0,0,1,15.973-4.482l12.606,5.761a11.766,11.766,0,0,1,4.482,15.973l-.383.681a11.768,11.768,0,0,1-15.973,4.485l-12.6-5.762a11.768,11.768,0,0,1-4.483-15.973Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1844.952,1423.84l66.494,39.83s1.136,11.25-.559,14.441c-1.869,3.518-12.849,9.424-12.849,9.424l-66.494-39.83Z"
                fill="#7192cc"
              />
            </G>
            <Path d="M1895.478,1410.712l16.595,54.8-24,8.01-16.6-54.794Z" fill="#7192cc" />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman4
