import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { useTeams } from 'pages/onboarding/hooks/team'

import * as constants from 'assets/constants'


interface Props {
  teamId?: string
  teamLabel?: string
}

export const ToolbarTeams: React.FC<Props> = ({ teamId, teamLabel }) => {
  const classes = useStyles()
  const { teams } = useTeams()
  return (
    <div className={classes.container}>
      <Dropdown
        items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
        value={teamId}
        keyPrefix={'teams'}
        handleOnClick={(teamId) => (window.location.href = `/onboarding/${teamId}/daily-check`)} //make re-render by it  not history.push
        teamLabel={teamLabel}
      />
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: constants.COLOR_GRAY,
    padding: 0,
  },
  teams: {
    flex: 1,
  },
  persons: {
    flex: 1,
    marginLeft: 16,
  },
})
