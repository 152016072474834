import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import axios from 'axios'

import {
  queryGetOnboardingNotifycation,
  queryGetOnboardingNotifycationList,
  mutationUpdateOnboardingNotification,
} from 'pages/onboarding/graphql'
import { Notifycation, NotifycationConnection, NotifycationType } from 'utils/generated'

export type Tab = 'activity' | 'news'

export interface News {
  body: string
  createdAt: string
  description: string
  id: string
  publishedAt: string
  revisedAt: string
  title: string
  updatedAt: string
}

export interface NotificationCtx {
  news?: News
  newsList: News[]
  isNews: boolean
  unCheckedNotify: number
  notifications: Notifycation[]
  isNotification?: string
  unCheckedLeader: Notifycation[]
  loading: boolean
  tab: Tab
  setTab: (tab: Tab) => void
  getNews: (id: string) => void
  moreNewsList: () => void
  getNotification: (id: string) => Promise<Notifycation | undefined>
  updateNotification: (id: string) => Promise<void>
  moreNotificationList: () => void
}

export const useNotification = (memberId?: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [tab, setTab] = useState<Tab>('activity') // memorize for go back
  const location = useLocation()

  ///////////////////////////notifycation///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  const [notifications, setNotifications] = useState<Notifycation[]>([])
  const [isNotification, setIsNotification] = useState<string>()
  const [unCheckedNotify, setUnCheckedNotify] = useState<number>(0)
  const [unCheckedLeader, setUnCheckedLeader] = useState<Notifycation[]>([])
  const getNotification = useCallback(async (id: string): Promise<Notifycation | undefined> => {
    try {
      const res = await queryGetOnboardingNotifycation({ id })
      return res as Notifycation
    } catch (e) {
      console.log('e:', e)
    }
  }, [])

  const getUnCheckedNotify = useCallback((notificationList: Notifycation[]) => {
    setUnCheckedNotify(0)
    setUnCheckedLeader([])

    if (!notificationList || !notificationList.length) return
    const unChecked = notificationList.filter((item) => !item?.checkedAt) as Notifycation[]
    const leaderNotifies = unChecked.filter((item) => item?.type === NotifycationType.Leader) as Notifycation[]

    if (unChecked) {
      setUnCheckedNotify(unChecked.length)
      setUnCheckedLeader(leaderNotifies)
    }
  }, [])

  const getNotifycationList = useCallback(
    async (after = '') => {
      if (!memberId) return

      setLoading(true)
      try {
        const res: NotifycationConnection = await queryGetOnboardingNotifycationList({
          to: memberId,
          first: 10000,
          after,
        })

        const items = res.items as Notifycation[]
        const nextToken = res.nextToken as string
        setNotifications((prev) => [...prev, ...items])
        setIsNotification(nextToken)

        getUnCheckedNotify(items)
      } catch (e) {
        console.log('e:', e)
      }

      setLoading(false)
    },
    [memberId, getUnCheckedNotify]
  )

  const updateNotification = useCallback(
    async (id: string) => {
      if (!unCheckedNotify) return

      try {
        await mutationUpdateOnboardingNotification({ id })
        await getNotifycationList() // To update the batch count
      } catch (e) {
        console.log('e:', e)
      }
    },
    [unCheckedNotify, getNotifycationList]
  )

  const moreNotificationList = async () => {
    setLoading(true)
    setTimeout(async () => await getNotifycationList(isNotification), 1500)
  }

  ///////////////////////////     news   ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  const API_ENDPOINT = process.env.REACT_APP_MICROCMS_API_ENDPOINT

  const [newsList, setNewsList] = useState<News[]>([])
  const [news, setNews] = useState<News>()
  const [isNews, setIsNews] = useState<boolean>(false)
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  const getNews = useCallback(
    async (id: string) => {
      if (API_ENDPOINT) {
        setLoading(true)
        try {
          const response = await axios.get(API_ENDPOINT + 'news/' + id, {
            headers: { 'X-API-KEY': process.env.REACT_APP_MICROCMS_API_KEY },
          })
          const res = response.data as News
          setNews(res)
        } catch (e) {
          console.log('e:', e)
        }
        setLoading(false)
      }
    },
    [API_ENDPOINT]
  )

  const getNewsList = useCallback(async () => {
    if (!memberId) return
    const limit = 5
    const offset = 5 * currentOffset

    if (API_ENDPOINT) {
      const params: { limit: number; offset?: number } = {
        limit,
        offset,
      }

      setLoading(true)
      try {
        const response = await axios.get(API_ENDPOINT + 'news', {
          headers: { 'X-API-KEY': process.env.REACT_APP_MICROCMS_API_KEY },
          params,
        })

        setNewsList((prev) => [...prev, ...response.data.contents])

        if (response.data.totalCount >= newsList.length + limit) {
          setIsNews(true)
          setCurrentOffset((prev) => prev + 1)
        } else {
          setIsNews(false)
        }
      } catch (e) {
        console.log('e:', e)
      }
      setLoading(false)
    }
  }, [memberId, API_ENDPOINT, currentOffset, newsList])

  const moreNewsList = async () => {
    setLoading(true)
    setTimeout(async () => await getNewsList(), 1500)
  }

  const reset = () => {
    setNotifications([])
    setIsNotification(undefined)
    setNewsList([])
    setNews(undefined)
    setIsNews(false)
    setCurrentOffset(0)
    setTab('activity')
  }

  useEffect(() => {
    reset() // for change team
    getNotifycationList()
    getNewsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, location.pathname])

  return {
    notifications,
    isNotification,
    unCheckedLeader,
    news,
    newsList,
    isNews,
    unCheckedNotify,
    loading,
    tab,
    setTab,
    getNews,
    getNotification,
    updateNotification,
    moreNotificationList,
    moreNewsList,
  } as const
}

export const useIntersection = (ref: React.MutableRefObject<HTMLDivElement>) => {
  const [intersecting, setIntersecting] = useState(false)
  useEffect(() => {
    if (ref && ref.current) {
      const refCurrent = ref.current
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting)
      })

      observer.observe(refCurrent)

      return () => {
        observer.unobserve(refCurrent)
      }
    }
  })
  return { intersecting } as const
}
