import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { Header } from 'pages/hataraku/components'
import { useHataraku } from 'pages/hataraku/hooks'
import { useBuildHatarakuResults } from 'pages/hataraku/hooks/useBuildHatarakuResults'
import { useGetKarte } from 'pages/hataraku/hooks/useGetKarte'
import {
  AnswerNote,
  Commitment,
  DisplayId,
  Graph,
  Hero,
  IntroductionCocolabo,
  Ranking,
  Socials,
} from 'pages/hataraku/pages/results/components'
import { LoadingCircular } from 'pages/teams/components'

import { Pages } from 'assets'
import * as constants from 'assets/constants'

export const HatarakuResultsPage: React.FC = () => {
  const history = useHistory()
  const urlParams = useParams<{ tendencyId: string; id: string }>()
  const classes = useStyles()
  const { hatarakuKeyName } = useHataraku()
  const { karte, isLoading, errors: getKarteErrors } = useGetKarte(urlParams.id, hatarakuKeyName)
  const { results, getTendencyId } = useBuildHatarakuResults(karte?.data ? JSON.parse(karte.data) : '')

  const isValidId = React.useMemo(() => {
    const createdAt = karte?.createdAt
    if (!createdAt) return false

    const answeredAtDate = new Date(createdAt)
    // 閲覧可能期間が30日なので、回答日時から29日後の日付を取得
    const expirationDate = answeredAtDate.setDate(answeredAtDate.getDate() + 29)
    const expirationDateOnTime = new Date(expirationDate).setHours(23, 59, 59, 999)

    return expirationDateOnTime > new Date().getTime()
  }, [karte?.createdAt])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    const tendencyIdFromGetResults = getTendencyId(results?.about.short)

    // URLから取得した傾向IDとDBから取得した結果の傾向IDが一致しない場合は、はたらく価値観診断トップページにリダイレクト
    if (!isLoading && tendencyIdFromGetResults && tendencyIdFromGetResults !== urlParams.tendencyId) {
      history.push(Pages.Hataraku)
    }
  }, [getTendencyId, results, isLoading, urlParams.tendencyId, history])

  const RenderError: React.FC = () => {
    if (!getKarteErrors) return null
    return (
      <div>
        <ul className={classes.errorMessages}>
          {getKarteErrors.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      </div>
    )
  }

  if (!karte) return null

  return (
    <>
      <Header />
      {isValidId ? (
        <div className={classes.root}>
          <Hero tendencyId={urlParams.tendencyId} />
          {results && (
            <div className={classes.contentsWrapper}>
              <div className={classes.container}>
                <AnswerNote
                  important={results.about.long}
                  personality={results.motivation}
                  themeColor={results.themeColor}
                />
                <Ranking points={results.points} />
                <Graph points={results.points} />
                <Commitment characteristics={results.characteristics} />
                <DisplayId answeredAt={karte?.createdAt ?? ''} />
                <Socials tendency={results.about.short} />
                <IntroductionCocolabo />
              </div>
            </div>
          )}

          <LoadingCircular loading={isLoading} size={40} circularStyles={{ color: '#fff' }} />
          <RenderError />
        </div>
      ) : (
        <p className={classes.noResult}>診断IDの有効期限が過ぎています。</p>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingBottom: 120,
      width: '100%',
      minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundImage: 'linear-gradient(180deg, #01C9D9, #26A4AE)',
      fontFamily: constants.HATARAKU_FONT_FAMILY,
    },

    contentsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
      },
    },

    container: {
      width: '100%',
      maxWidth: 1000,
    },

    errorMessages: {
      listStyleType: 'none',
      padding: 0,
      color: 'red',

      '& li': {
        overflowWrap: 'break-word',
      },
    },

    noResult: {
      display: 'flex',
      justifyContent: 'center',
      margin: '48px 0',
    },
  }),
  { name: 'HatarakuResultsPage' }
)
