import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  isShowProfile: () => boolean
  isShowUnPublished: () => boolean
  isShowPager: () => boolean
  isHome: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useProfileIndex = (): IProps => {
  const location = useLocation()
  const hintSelectors = useSelector((state: RootStateType) => state.hint)
  const hintProfileListSelectors = useSelector((state: RootStateType) => state.hint.hintProfileList)
  const hintProfileListUnpublishedSelectors = useSelector(
    (state: RootStateType) => state.hint.hintProfileListUnpublished
  )
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)
  const profileSelectors = useSelector((state: RootStateType) => state.hint.user)
  const userSelectors = useSelector((state: RootStateType) => state.user)

  /**
   * プロフィールを表示するかどうかを判定
   */
  const isShowProfile = (): boolean => {
    return profileSelectors.id ||
      profileSelectors.firstName ||
      profileSelectors.lastName ||
      profileSelectors.imageUrl ||
      profileSelectors.description ||
      profileSelectors.shortDescription
      ? true
      : false
  }

  /**
   * 下書きの記事を出し分ける
   */
  const isShowUnPublished = (): boolean => {
    if (userSelectors.id) {
      const isFirstPage = hintProfileListUnpublishedSelectors && hintSelectors.pages.currentPages < 2
      const isAdmin = location.pathname.split('/').slice(-1)[0] === userSelectors.id
      if (isFirstPage && isAdmin) {
        return true
      }
    }
    return false
  }

  /**
   * ページングを出し分ける
   */
  const isShowPager = (): boolean => {
    return hintProfileListSelectors.nextToken || pagesSelectors.currentPages > 1 ? true : false
  }

  /**
   * ページのHOMEかどうかを判定する
   */
  const isHome = (): boolean => {
    const isProfilePage = !location.pathname.includes('/pages/')
    return isProfilePage ? true : false
  }

  return {
    isShowProfile,
    isShowUnPublished,
    isShowPager,
    isHome,
  }
}
