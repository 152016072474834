import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'

import { Breadcrumb } from 'components/Breadcrumb'
import { TitleRenewal } from 'components/TitleRenewal'
import { TopRenewalWrapper } from 'components/TopRenewalWrapper'
import { ArrowLinkButton } from 'pages/features/components/ArrowLinkButton'
import { FeatureBox } from 'pages/features/components/FeatureBox'
import { FEATURES, TAB_ITEMS, TAB_NAMES, TabNames } from 'pages/features/features.data'
import { useTeams } from 'pages/onboarding/hooks/team'

import { PlanStyledButton, OnbStyledButton } from './components/StyledButton'
import { connector, ContainerProps } from './index.container'

import * as constants from 'assets/constants'

type Props = ContainerProps & RouteComponentProps<{}>

const Features: React.FC<Props> = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const { teams } = useTeams()
  const defaultTeamId = React.useMemo(() => {
    return teams ? teams[0]?.id : undefined
  }, [teams])

  const [checked, setChecked] = React.useState<TabNames>('team')
  const checkedTabName = (name: TabNames) => checked === name

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.value as TabNames)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TopRenewalWrapper>
      <div className={classes.rootContainer}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="機能一覧" />
        <TitleRenewal
          category="Features"
          title="機能一覧"
          marginTop={35}
          description={
            <>
              Cocolaboは組織開発における対話を支援するためのツールです。{isMdUp && <br />}
              研修と最適なツール（手法、思考法）の掛け合わせにより、管理職主導の組織開発を支援し、エンゲージメント向上に役立つ組織づくりをサポートします。
              {isMdUp && <br />}
              機能一覧では研修後に職場で実際に使用する具体的なツール（手法、思考法）をご紹介します。​
            </>
          }
        />

        <div className={classes.tabHeader}>
          {TAB_NAMES.map((tabName) => (
            <React.Fragment key={`${tabName}-feature-tab-key`}>
              <label
                className={classNames([classes.tabLabel, checkedTabName(tabName) && 'checked'])}
                htmlFor={`${tabName}-tab`}
              >
                {TAB_ITEMS[tabName].header}
              </label>
              <input
                id={`${tabName}-tab`}
                type="radio"
                value={tabName}
                onChange={handleChange}
                checked={checkedTabName(tabName)}
              />
            </React.Fragment>
          ))}
        </div>

        <div className={classes.tabBody}>
          {checked === 'team' && (
            <>
              <div className={classes.planContainer}>
                {TAB_ITEMS['team'].plans.map((plan) => {
                  return (
                    <div key={plan.title} className={classes.planBox}>
                      <div>
                        <div className={classes.planTitleWrapper}>
                          <div className={classes.planTitle}>{plan.title}</div>
                        </div>
                        <p className={classes.planDescription}>
                          {plan.description}
                          詳細については
                          <Link to={constants.PAGE_CONTACT} className={classes.contactLink}>
                            お問い合わせ
                          </Link>
                          ください。
                        </p>
                      </div>
                      <div>
                        <div className={classes.planToolHeader}>使用可能機能</div>
                        <div className={classes.planToolContainer}>
                          {plan.tools.map((tool) => {
                            return (
                              <ArrowLinkButton
                                key={tool.name}
                                path={tool.path}
                                text={<span className={classes.planToolName}>{tool.name}</span>}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={classes.tabWrapperTop}>
                <FeatureBox feature={FEATURES[0]} checked={checked} />
              </div>
              <div className={classes.tabWrapperAfterSecond}>
                <FeatureBox feature={FEATURES[1]} checked={checked} />
              </div>
              <div className={classes.tabWrapperAfterSecond}>
                <FeatureBox feature={FEATURES[2]} checked={checked} />
              </div>
            </>
          )}
          {checked === 'onboarding' && (
            <>
              <h3 className={classes.onbPlanTitle}>
                <img src={`${process.env.PUBLIC_URL}/assets/landing/features/label_team.svg`} alt="チームアイコン" />
                <span>{TAB_ITEMS['onboarding'].title}</span>
              </h3>
              <p className={classes.onbPlanTitleDesc}>{TAB_ITEMS['onboarding'].titleDesc}</p>
              <div className={classes.tabWrapperTop}>
                <FeatureBox feature={FEATURES[3]} checked={checked} />
              </div>
            </>
          )}

          <div className={classes.bottomButtonWrapper}>
            <div className={classes.bottomButton}>
              <PlanStyledButton />
            </div>
            {checkedTabName('onboarding') && defaultTeamId && (
              <div className={classes.bottomButton}>
                <OnbStyledButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </TopRenewalWrapper>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    rootContainer: {
      paddingBottom: 140,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 100,
      },
    },
    tabHeader: {
      display: 'flex',
      marginTop: 70,
      [theme.breakpoints.up('md')]: {
        marginTop: 54,
      },
      '& input': {
        display: 'none',
      },
    },
    tabLabel: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50%',
      height: 64,
      padding: '0 6px',
      textAlign: 'center',
      backgroundColor: constants.COLOR_GRAY_LIGHT3,
      color: constants.COLOR_GRAY2,
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: '8px 8px 0 0',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        padding: '0 12px',
        fontSize: 20,
      },
      '&.checked': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: constants.TEXT_WHITE,
        '&:before': {
          position: 'absolute',
          content: '""',
          top: '100%',
          left: '50%',
          width: 12,
          height: 10,
          marginLeft: '-6px',
          background: `linear-gradient(to top right, transparent 50% , ${constants.COLOR_MAIN_NEW} 50%) no-repeat top left / 50% 100%, linear-gradient(to top left,transparent 50%, ${constants.COLOR_MAIN_NEW} 50%) no-repeat bottom right/50% 100%`,
        },
      },
    },
    tabBody: {
      padding: '65px 24px 72px',
      borderRadius: '0 0 8px 8px',
      boxShadow: '0px 0px 24px #00000014',
      [theme.breakpoints.up('md')]: {
        padding: '90px 40px',
      },
    },

    planContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 40,
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    planBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      border: `1px solid ${constants.COLOR_YELLOW3}`,
      borderRadius: 10,
      padding: '0 24px 24px',
    },
    planTitleWrapper: {
      position: 'relative',
      top: -20,
      display: 'flex',
      justifyContent: 'center',
    },
    planTitle: {
      maxWidth: 375,
      width: '100%',
      padding: '10px 0',
      borderRadius: 20,
      backgroundColor: constants.COLOR_YELLOW3,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
    },
    planDescription: {
      margin: '0 0 44px',
      lineHeight: 2,
      whiteSpace: 'pre-wrap',
    },
    contactLink: {
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    planToolHeader: {
      marginBottom: 18,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    planToolContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 18,
      columnGap: 40,
    },
    planToolName: {
      fontWeight: 'bold',
    },

    tabWrapperTop: {
      marginTop: 48,
    },
    tabWrapperAfterSecond: {
      marginTop: 72,
      [theme.breakpoints.up('md')]: {
        marginTop: 64,
      },
    },

    onbPlanTitle: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
      '& img': {
        position: 'relative',
        width: 24,
        height: 24,
        top: 5,
        [theme.breakpoints.up('md')]: {
          top: 3,
        },
      },
      '& span': {
        marginLeft: 14,
        [theme.breakpoints.up('md')]: {
          marginLeft: 16,
        },
      },
    },
    onbPlanTitleDesc: {
      margin: '30px 0 0',
      lineHeight: 1.8,
      whiteSpace: 'pre-wrap',
    },

    bottomButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 64,
      width: '100%',
      gap: '24px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 72,
      },
    },
    bottomButton: {
      width: 250,
      height: 50,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 350,
      },
    },
  }),
  { name: 'Features' }
)

export default connector(Features)
