import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'

/**
 * alt version of amplify graphqlOperation
 * that is safe to use ( making sure it uses COGNITO_USER_POOL )
 */
export const gqlOp = (query: any, variables?: any) => ({
  query,
  ...(variables && { variables }),
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
})

export type GqlSubRes<SubscriptionName extends string, ResType> = {
  provider: any
  value: {
    data: {
      [k in SubscriptionName]: ResType
    }
  }
}
