import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

type Props = { children: React.ReactNode; style?: React.CSSProperties }

export const BackCard: React.FC<Props> = ({ children, style }) => {
  const classes = useStyles()

  return (
    <div className={classes.backCardRoot} style={{ ...style }}>
      {children}
    </div>
  )
}

const useStyles = makeStyles({
  backCardRoot: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EBEBEB',
    boxShadow: '0px 5px 24px #EBEBEB',
    borderRadius: 8,
  },
})
