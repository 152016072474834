import { RouteComponentProps } from 'react-router-dom'

type Option = {
  location: RouteComponentProps['location']
}

const titlesWithPath = [
  { path: '/about', title: '運営会社' },
  { path: '/hint', title: 'ヒント' },
  { path: '/karte', title: 'カルテ' },
  { path: '/setup', title: 'セットアップ' },
  { path: '/selfcheck', title: 'セルフチェック' },
  { path: '/withdraw', title: '退会' },
]
const titlesWithSubPath = [{ path: '/email-notify', title: 'メール設定' }]

function getLogoTitle(location: Option['location']) {
  const titleWithPath = titlesWithPath.find((twp) => location.pathname.startsWith(twp.path)) ?? ''

  if (titleWithPath) {
    const path = location.pathname.replace(titleWithPath.path, '')
      ? location.pathname.replace(titleWithPath.path, '')
      : ''

    if (!path) {
      return titleWithPath.title
    }

    return titlesWithSubPath.find((twp) => path === twp.path)?.title ?? titleWithPath.title
  }

  return ''
}

export const useHeader = ({ location }: Option) => {
  const logoTitle: string = getLogoTitle(location)

  return {
    logoTitle,
  }
}
