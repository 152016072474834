import axios from 'axios'

export const get = async (q?: string, filter?: string) => {
  const API_ENDPOINT = process.env.REACT_APP_MICROCMS_API_ENDPOINT
  if (API_ENDPOINT) {
    const params: { q?: string; filters?: string; limit: number } = {
      limit: 999,
    }
    if (q) {
      params.q = q
    }
    if (filter) {
      params.filters = `category[equals]${filter}`
    }

    const response = await axios.get(API_ENDPOINT + 'help', {
      headers: { 'X-API-KEY': process.env.REACT_APP_MICROCMS_API_KEY },
      params,
      data: {},
    })

    const { result, categories } = filterData(response.data.contents)
    return { data: result, categories }
  } else {
    return null
  }
}

export interface IData {
  category: string
  question: string
  answer: string
  orderIndex: number
}

const filterData = (data: any[]) => {
  const result: any[] = [...data]

  const categoriesArray = data.map((d) => d.category)
  const categories = Array.from(new Set(categoriesArray))

  result.sort(function (a, b) {
    if (a.orderIndex < b.orderIndex) return -1
    if (a.orderIndex > b.orderIndex) return 1
    return 0
  })

  return { result, categories }
}
