import { Auth } from 'aws-amplify'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ISignup, SignUpOption } from '../../services/amplify/signup'
import { RootStateType } from '../../store'
import { UserActions } from '../../stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    signup: (data: ISignup, option: SignUpOption) => dispatch(UserActions.signup.started({ user: data, option })),
    signout: () => {
      Auth.signOut().then(() => {
        dispatch(
          UserActions.signout.done({
            result: {},
            params: {},
          })
        )
      })
    },
    refreshErrors: () => dispatch(UserActions.refreshErrors({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
