import * as React from 'react'

import Button, { ButtonProps } from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesome } from 'react-web-vector-icons'

import ChatIcon from './components/chat-icon'
import LikeIcon from './components/like-icon'

import * as constants from 'assets/constants'

interface IOwnProps {
  style?: React.CSSProperties
  className?: string
  title: string
  icon?: 'chat' | 'like' | 'send' | 'csv'
  theme?: 'primary-new' | 'primary' | 'primary-outline' | 'none'
  onPress?: () => void
}

type Props = IOwnProps & ButtonProps

const PrimaryNewButton = styled(Button)({
  backgroundColor: constants.COLOR_WHITE,
  color: constants.COLOR_MAIN_NEW,
  fontSize: 12,
  fontWeight: 'bold',
  padding: '6px 16px',
  border: `1px solid ${constants.COLOR_MAIN_NEW}`,
  borderRadius: 8,
  lineHeight: 1.2,
  textTransform: 'none',
  whiteSpace: 'pre-wrap',
})

const PrimaryButton = styled(Button)({
  fontSize: 12,
  padding: 16,
  backgroundColor: '#2CBBC6',
  color: '#FCFCFC',
  borderColor: '#2CBBC6',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
})

const PrimaryOutlineButton = styled(Button)({
  fontSize: 12,
  padding: 16,
  backgroundColor: '#FCFCFC',
  color: '#2CBBC6',
  borderColor: '#F5F5F5',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
})

const NoneButton = styled(Button)({
  fontSize: 12,
  color: '#2CBBC6',
})

const Index: React.FC<Props> = ({ style, className, title, icon, theme, onPress, ...props }) => {
  const classes = useStyles()
  const renderContent = (color: string) => {
    return (
      <>
        {icon === 'chat' ? <ChatIcon className={classes.icon} color={color} /> : <></>}
        {icon === 'like' ? <LikeIcon className={classes.icon} color={color} /> : <></>}
        {icon === 'send' ? <FontAwesome name="send" color={constants.COLOR_MAIN} size={12} /> : <></>}
        {icon === 'csv' ? (
          <img src={`/assets/svg/onboarding/csv-file.svg`} alt="icon" className={classes.img} />
        ) : (
          <></>
        )}
        {title}
      </>
    )
  }
  return (
    <>
      {theme === 'primary-new' ? (
        <PrimaryNewButton style={style} className={className} onClick={onPress} {...props}>
          {renderContent(constants.COLOR_WHITE)}
        </PrimaryNewButton>
      ) : null}
      {theme === 'primary' ? (
        <PrimaryButton style={style} className={className} onClick={onPress} {...props}>
          {renderContent(constants.COLOR_WHITE)}
        </PrimaryButton>
      ) : null}
      {theme === 'primary-outline' ? (
        <PrimaryOutlineButton style={style} className={className} onClick={onPress} {...props}>
          {renderContent(constants.COLOR_MAIN)}
        </PrimaryOutlineButton>
      ) : null}
      {theme === 'none' ? (
        <NoneButton style={style} className={className} onClick={onPress} {...props}>
          {renderContent(constants.COLOR_MAIN)}
        </NoneButton>
      ) : null}
    </>
  )
}

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  img: {
    marginRight: 8,
  },
})

export default Index
