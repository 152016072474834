import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { constants } from 'assets'

interface Props {
  title: string
  rightNode?: React.ReactNode
  bottomNode?: React.ReactNode
  onClick?: () => void
  onClickTitle?: React.ReactNode
  ownStyles?: React.CSSProperties
  titleStyles?: React.CSSProperties
}

interface StyleProps {
  headerMb?: number
  titleContainerPb?: number
  style?: {
    padding?: string | number
    flexDirection?: 'column'
    justifyContent?: 'center' | 'space-between'
    alignItems?: 'center' | 'flex-start'
    marginBottom?: string | number
    textDecoration?: 'none'
    fontWeight?: 'normal'
  }
}

export const CardHeader: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.header} style={{ ...props.ownStyles }}>
      <div className={classes.titleContainer}>
        <div className={classes.title} data-testid="cardHeaderTitle" style={{ ...props.titleStyles }}>
          {props.title}
        </div>
        {props.onClick ? (
          <div onClick={props.onClick} className={classes.action}>
            {props.onClickTitle || '編集'}
          </div>
        ) : (
          <></>
        )}
        {props.rightNode ? <div style={{ display: 'flex' }}>{props.rightNode}</div> : <></>}
      </div>
      {props.bottomNode ? <div className={classes.buttonContainer}>{props.bottomNode}</div> : <></>}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      marginBottom: (props: StyleProps) => props.headerMb ?? 16,
      width: '100%',
    },

    titleContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingBottom: (props: StyleProps) => props.titleContainerPb ?? 16,
      [theme.breakpoints.down('sm')]: {
        flexDirection: (props: StyleProps) => props.style?.flexDirection ?? 'row',
        justifyContent: (props: StyleProps) => props.style?.justifyContent ?? 'center',
        alignItems: (props: StyleProps) => props.style?.alignItems ?? 'flex-end',
      },
    },

    title: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        marginBottom: (props: StyleProps) => props.style?.marginBottom ?? '0',
      },
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    action: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      fontSize: 12,
      fontWeight: (props: StyleProps) => props.style?.fontWeight ?? 'bold',
      textDecoration: (props: StyleProps) => props.style?.textDecoration ?? 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'CardHeader' }
)
