import React, { useCallback, useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core'

export const CustomVideoPlayer = ({ src }: { src: string }) => {
  const classes = useStyles()

  const videoRef = useRef<HTMLVideoElement>(null)
  const sliderRef = useRef<HTMLInputElement>(null)

  const [duration, setDuration] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)

  const [play, setPlay] = useState(false)
  const togglePlay = useCallback(() => setPlay((playing) => !playing), [setPlay])

  useEffect(() => {
    const vid = videoRef.current
    if (vid) {
      vid.onloadeddata = () => {
        setDuration(Math.floor(vid.duration))
      }
    }
  }, [videoRef])

  useEffect(() => {
    const vid = videoRef.current
    const slider = sliderRef.current
    let alive = true
    if (vid && slider && duration && alive) {
      slider.max = Math.round(duration).toString()

      vid.onclick = () => {
        togglePlay()
      }

      vid.ontimeupdate = () => {
        setCurrentTime(Math.floor(vid.currentTime))
      }

      slider.oninput = () => {
        const time = parseInt(slider.value)
        setCurrentTime(time)
        vid.currentTime = time
      }
    }
    return () => {
      alive = false
    }
  }, [videoRef, sliderRef, duration, togglePlay])

  useEffect(() => {
    if (sliderRef.current) {
      const percentage = (currentTime / duration) * 100
      sliderRef.current.style.background = `linear-gradient(to right, #fff 0%, #fff ${percentage}%, rgba(255,255,255,0.5) ${percentage}%, rgba(255,255,255,0.5) 100%)`
    }
  }, [currentTime, duration, sliderRef])

  useEffect(() => {
    const vid = videoRef.current
    if (vid) {
      if (play) {
        vid.play()
      } else {
        vid.pause()
      }
    }
  }, [play, videoRef])

  return (
    <>
      <div className={classes.video}>
        <video
          key="video"
          src={`${src}#t=0.1`}
          ref={videoRef}
          muted
          playsInline
          preload="metadata"
          controls={false}
          autoPlay={false}
        />
        {!play && <img alt="play" src={require('./assets/play.svg').default} onClick={togglePlay} />}
      </div>
      <div className={classes.controls}>
        <div className={classes.pauseResume}>
          {play ? (
            <img alt="pause" src={require('./assets/pause.svg').default} onClick={() => setPlay(false)} />
          ) : (
            <img alt="resume" src={require('./assets/resume.svg').default} onClick={() => setPlay(true)} />
          )}
        </div>
        <input
          ref={sliderRef}
          type="range"
          className={classes.range}
          min="0"
          max={duration}
          value={currentTime}
          readOnly
        />
      </div>
      <div className={classes.time}>
        <span>{convertSec(currentTime)}</span>
        <span>{'- ' + convertSec(duration - currentTime)}</span>
      </div>
    </>
  )
}

const convertSec = (sec: number) => {
  const ms = [Math.floor(sec / 60), Math.floor(sec % 60)]
  return ms.map((t) => (t.toString().length < 2 ? `0${t}` : t)).join(':')
}

const useStyles = makeStyles(() => ({
  video: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& video': {
      cursor: 'pointer',
      borderRadius: 8,
      height: 'auto',
      maxHeight: '90%',
      maxWidth: '100%',
    },
    '& img': {
      position: 'absolute',
      width: 40,
      height: 40,
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
      zIndex: 100,
      cursor: 'pointer',
    },
  },
  controls: {
    width: '100%',
    maxHeight: '5%',
    position: 'relative',
  },
  pauseResume: {
    position: 'absolute',
    left: -36,
    bottom: -14,
    padding: 4,
    '& img': {
      width: 24,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  range: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    border: 'none',
    borderRadius: 8,
    height: 4,
    outline: 'none',
    '-webkit-appearance': 'none',
    '&::-webkit-slider-thumb': { ...thumb },
    '&::-moz-range-thumb': { ...thumb },
    '&::-ms-thumb': { ...thumb },
  },
  time: {
    marginTop: 8,
    width: '100%',
    maxHeight: '5%',
    position: 'relative',
    left: 2,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    fontSize: 16,
    '& span': {
      display: 'block',
    },
  },
}))

const thumb = {
  '-webkit-appearance': 'none',
  width: 10,
  height: 10,
  borderRadius: 10,
  border: 'none',
  background: '#fff',
}
