import React, { useEffect, useState, useRef } from 'react'
import { useContext } from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { GhostContext, GhostModalContents } from '../../contexts/ghost'

import { GhostDetails } from './GhostDetails'
import { GhostDetailsNoTab } from './GhostDetailsNoTab'
import { GhostList } from './GhostList'
import { GhostSelect } from './GhostSelect'

/**
 * Ghost app's screen modal container.
 * read ghostStore's state and return dynamic contents (GhostList, GhostSelect, GhostDetails)
 */
export const GhostModal = () => {
  const { ghosts, modal, setModal } = useContext(GhostContext)

  const classes = useStyles()

  const [apper, setAppear] = useState(false)

  const IsWaitingPage = modal === 'detail-no-tab-no-select' ? true : false

  const ref = useRef<HTMLDivElement>(null)

  const ModalChild = ({ modal }: { modal: GhostModalContents }) => {
    switch (modal) {
      case 'select':
        return <GhostSelect />
      case 'select-detail':
        return <GhostDetails select={true} />
      case 'detail':
        return <GhostDetailsNoTab readOnly={true} />
      case 'detail-no-tab':
        return <GhostDetailsNoTab select={true} />
      case 'detail-no-tab-no-select':
        return <GhostDetailsNoTab />
      case 'list':
      default:
        return <GhostList closeModal={() => setModal('')} />
    }
  }
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [modal])

  useEffect(() => {
    if (modal && ghosts.length) {
      setAppear(true)
      // prevent page scroll while modal's open
      document.body.setAttribute('style', 'position: fixed; width: 100vw; height: 100vh; overflow-y: hidden;')
    } else {
      setAppear(false)
      document.body.removeAttribute('style')
    }
  }, [modal, ghosts.length])

  return (
    <div
      className={classNames([classes.root, apper ? 'appear' : ''])}
      ref={ref}
      onClick={IsWaitingPage ? () => setModal('list') : () => setModal('')}
    >
      <ModalChild modal={modal} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'auto',
    display: 'none',
    zIndex: -1,
    boxSizing: 'border-box',
    padding: '39px 16px',
    background: 'rgba(51, 51, 51, 0.8)',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px 68px',
    },
    '&.appear': {
      zIndex: 1290,
      display: 'block',
      animation: '$fadeIn 500ms forwards',
      '@global': {
        '@keyframes fadeIn': {
          from: {
            opacity: '0',
          },
          to: {
            opacity: '1',
          },
        },
      },
    },
  },
}))
