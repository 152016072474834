import { customAlphabet } from 'nanoid/non-secure'

export const useCreateId = () => {
  const buildNanoidId = () => {
    const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const nanoid = customAlphabet(ALPHABET, 16)
    return nanoid()
  }

  return {
    buildNanoidId,
  }
}
