import React, { createContext, useContext, useEffect, useState } from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Header, Body, Container } from 'pages/onboarding/components'
import { FooterIcon } from 'pages/onboarding/components'
import { useLastVisitAction } from 'pages/onboarding/service/lastVisitAction'
import { OnboardingTeamMember, OnboardingUsageStatus } from 'utils/generated'

import { ModalInfomation } from '../../../components/Modal'
import { Loader } from '../components/loader'
import { useCognito } from '../hooks/cognito'
import { useNotification, NotificationCtx } from '../hooks/notifycation'
import { useTeam, useTeamMember } from '../hooks/team'
import { useSendDaily, useAuthCheck } from '../navigation/domain'
import { OnbRouteContext, useRouteManager } from '../navigation/route'

import ActionList from './actions-list'
import ActionDetail from './actions-list-detail'
import ActionManage from './actions-manage'
import { ActionsManageCsvPage } from './actions-manage-csv/ActionsManageCsvPage'
import ActionManageDetail from './actions-manage-detail'
import ActionManageForm from './actions-manage-form'
import { AddMemberCSV } from './add-member-csv/addCSV'
import OnboardingDailyCheckPage from './daily-check'
import Member from './member'
// eslint-disable-next-line
import { MemberManageAdd } from './member/add'
// eslint-disable-next-line
import MyPageEdit from './member/edit'
// eslint-disable-next-line
import { MemberInviteEdit } from './member/inviteEdit'
// eslint-disable-next-line
import MemberManage from './member-manage'
// eslint-disable-next-line
import { MemberStatusPage } from './memberStatus/MemberStatusPage'
import { MemberStatusDetailPage } from './memberStatus-detail/MemberStatusDetailPage'
import Notification from './notification'
import NotificationDetail from './notification-detail'
import { OverviewPage } from './overview/OverviewPage'
import { RankingPage } from './ranking/RankingPage'
import Team from './team'
import Timeline from './timeline'
// eslint-disable-next-line
import { TeamStorageContext, useTeamStorage } from './timeline/hooks/teamStorage'
import TimelineCommentLikes from './timeline-comment-likes'
import TimelinePost from './timeline-post'
import TimelinePostEdit from './timeline-post-edit'
import TimelinePostLikes from './timeline-post-likes'
import TimelineSecondCommentLikes from './timeline-second-comment-likes'
import Tutorial from './tutorial/Tutorial'
import { Usage } from './usage'

import { OnbPages, ONBOARDING_LANDING } from 'assets/pages'

export type RootPathProps = RouteComponentProps<{ teamId: string }>

export type OnbStore = {
  teamId: string
  setTeamId: (teamId: string) => void
  teamMember: OnboardingTeamMember
}

export const OnbContext = createContext<OnbStore>({} as OnbStore)
export const notificationCtx = createContext<NotificationCtx>({} as NotificationCtx)

export const useOnbPageStore = (paramTeamId: string) => {
  const userId = useCognito()[1]['sub']

  /// teamMember = logged in user's team member info.
  const { loading, teamMember } = useTeamMember(paramTeamId, userId)

  // always reference to this top-level state on which team user's viewing
  const [teamId, setTeamId] = useState(paramTeamId)
  useEffect(() => setTeamId(paramTeamId), [setTeamId, paramTeamId])

  if (loading || !teamMember) return null
  return { teamId, teamMember, setTeamId }
}

const PagesRoot: React.FC<RootPathProps> = ({ match }) => {
  const onbStore = useOnbPageStore(match.params.teamId)
  const onbRouteManager = useRouteManager(onbStore?.teamMember.role)

  useAuthCheck(onbRouteManager, onbStore?.teamId, onbStore?.teamMember)
  useSendDaily(onbRouteManager, onbStore?.teamId, onbStore?.teamMember)
  useLastVisitAction(onbStore?.teamId, onbStore?.teamMember.userId)

  const { team, loading: loadingTeam } = useTeam(match.params.teamId)
  const notificationStore = useNotification(onbStore?.teamMember.id)
  const teamStorage = useTeamStorage(match.params.teamId)

  if (onbStore == null) return <Loader />
  if (loadingTeam || !team) return <Loader />
  if (team.usageStatus !== OnboardingUsageStatus.Active) {
    window.location.href = ONBOARDING_LANDING
    return <Loader />
  }

  return (
    <OnbRouteContext.Provider value={onbRouteManager}>
      <OnbContext.Provider value={onbStore}>
        <TeamStorageContext.Provider value={teamStorage}>
          <notificationCtx.Provider value={notificationStore}>
            {onbStore.teamMember.readTutorialAt ? <Onboarding /> : <Tutorial />}
          </notificationCtx.Provider>
        </TeamStorageContext.Provider>
      </OnbContext.Provider>
    </OnbRouteContext.Provider>
  )
}

const Onboarding = () => {
  const onbStore = useContext(OnbContext)
  const notificationStore = useContext(notificationCtx)
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Container>
      <ModalInfomation toolName={'onboarding'} version={1} userId={onbStore.teamMember.id} isActive={false} />
      <Header teamId={onbStore.teamId} countNotification={notificationStore.unCheckedNotify} />
      <Body teamId={onbStore.teamId} countNotification={notificationStore.unCheckedNotify}>
        <OnbPagesMap />
        {!isXsDown ? <FooterIcon /> : <></>}
      </Body>
    </Container>
  )
}

const OnbPagesMap = () => (
  <Switch>
    {/* Common */}
    <Route exact={true} path={OnbPages.Timeline} component={Timeline} />
    <Route exact={true} path={OnbPages.TimelinePost} component={TimelinePost} />
    <Route exact={true} path={OnbPages.TimelinePostEdit} component={TimelinePostEdit} />
    <Route exact={true} path={OnbPages.TimelinePostLikes} component={TimelinePostLikes} />
    <Route exact={true} path={OnbPages.TimelineCommentLikes} component={TimelineCommentLikes} />
    <Route exact={true} path={OnbPages.TimelineSecondCommentLikes} component={TimelineSecondCommentLikes} />
    <Route exact={true} path={OnbPages.Mypage} component={Member} />
    <Route exact={true} path={OnbPages.MypageEdit} component={MyPageEdit} />
    <Route exact={true} path={OnbPages.Team} component={Team} />
    <Route exact={true} path={OnbPages.TeamMember} component={Member} />
    <Route exact={true} path={OnbPages.Notification} component={Notification} />
    <Route exact={true} path={OnbPages.NotificationDetail} component={NotificationDetail} />
    {/* Common Overview */}
    <Route exact={true} path={OnbPages.Ranking} component={RankingPage} />
    <Route exact={true} path={OnbPages.MemberStatus} component={MemberStatusPage} />
    <Route exact={true} path={OnbPages.MemberStatusDetail} component={MemberStatusDetailPage} />

    {/* Admin*/}
    <Route exact={true} path={OnbPages.MemberManageAddMember} component={MemberManageAdd} />

    {/* Admin and Supporter */}
    <Route exact={true} path={OnbPages.ActionManage} component={ActionManage} />
    <Route exact={true} path={OnbPages.ActionManageDetail} component={ActionManageDetail} />
    <Route exact={true} path={OnbPages.ActionManageAdd} component={ActionManageForm} />
    <Route exact={true} path={OnbPages.ActionManageAddCSV} component={ActionsManageCsvPage} />
    <Route exact={true} path={OnbPages.ActionManageEdit} component={ActionManageForm} />
    <Route exact={true} path={OnbPages.MemberManage} component={MemberManage} />
    <Route exact={true} path={OnbPages.MemberManageAddMember} component={MemberManageAdd} />
    <Route exact={true} path={OnbPages.MemberManageInviteEdit} component={MemberInviteEdit} />
    <Route exact={true} path={OnbPages.MemberManageAddMemberCSV} component={AddMemberCSV} />
    <Route exact={true} path={OnbPages.MemberManageActions} component={ActionList} />
    <Route exact={true} path={OnbPages.MemberManageActionDetail} component={ActionDetail} />
    {/* Admin and Supporter Overview */}
    <Route exact={true} path={OnbPages.Overview} component={OverviewPage} />

    {/* Member */}
    <Route exact={true} path={OnbPages.Actions} component={ActionList} />
    <Route exact={true} path={OnbPages.ActionsDetail} component={ActionDetail} />
    <Route exact={true} path={OnbPages.DailyCheck} component={OnboardingDailyCheckPage} />
    <Route exact={true} path={OnbPages.ONBOARDING_PAGE_USAGE} component={Usage} />
  </Switch>
)

export default PagesRoot
