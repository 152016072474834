import * as React from 'react'

import { CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/styles'

import { RankingItemBox } from 'pages/onboarding/components/RankingItemBox'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { categories, Categories, OrganizedRanking } from 'pages/onboarding/utils/ranking'
import { OnboardingPost, OnboardingPostRanking, Maybe } from 'utils/generated'

import { OnbContext } from '../pages/PagesRoot'


import * as constants from 'assets/constants'

type Props = {
  renderType: 'timeline' | 'detail'
  teamId: string
  post: OnboardingPost
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  // calculate it in this component, so that you don't have to set this property expressly.
  isOnlyAppearLikeData?: boolean
}

export const notExistUserName = 'チームに存在しないユーザーです'

export const SummaryBoxBloc = {
  useAdapter: () => {
    const { teamId } = React.useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)

    const dataController = React.useCallback(
      (items: Maybe<Array<Maybe<OnboardingPostRanking>>>) => {
        if (items === null) return []
        if (!teamMembers.length) return []

        const sortedData = items
          .map((item) => ({
            ...item,
            teamMember: teamMembers.find((el) => el.id === item?.teamMemberId),
          }))
          .sort((a, b) => (a.teamMember?.nickname ?? '').localeCompare(b.teamMember?.nickname ?? '', 'ja')) // Sort by Name in Japanese.
          .sort((x, y) => (y.count ?? 0) - (x.count ?? 0))

        const rankingData: OrganizedRanking = []
        sortedData.forEach((data, i) => {
          rankingData.push({
            id: data.teamMemberId ?? `rankingData-id-${i}`,
            userId: data.teamMember?.userId ?? '',
            src: data.teamMember?.imageUrl,
            name:
              data.teamMember?.nickname && data.teamMember.nickname !== '名無し'
                ? data.teamMember.nickname
                : data.teamMember?.email ?? notExistUserName,
            role: data.teamMember?.role ?? '',
            count: data.count ?? 0,
            barWidth: data.count ? Math.round((data.count / (sortedData[0].count ?? 0)) * 100) : 0, // Prevent NaN (0 / 0 = NaN).,
            rank: !rankingData.length
              ? 1 // for 1st
              : data.count === rankingData.slice(-1)[0].count
              ? rankingData.slice(-1)[0].rank // for tie rank
              : i + 1,
          })
        })

        return rankingData
      },
      [teamMembers]
    )

    return { dataController, teamMembers }
  },
}

export const SummaryBox: React.FC<Props & StyleProps> = ({ renderType, teamId, post, ownStyles }) => {
  const isOnlyAppearLikeData = renderType === 'timeline'
  const classes = useStyles({ ownStyles: ownStyles, isOnlyAppearLikeData: isOnlyAppearLikeData })
  const { dataController, teamMembers } = SummaryBoxBloc.useAdapter()

  const rankingActions = dataController(post.rankingActions ?? [])
  const rankingPosts = dataController(post.rankingPosts ?? [])
  const rankingLikes = dataController(post.rankingLikes ?? [])
  const categorizedRankingItems = {
    [categories.ownActions]: rankingActions,
    [categories.ownPosts]: rankingPosts,
    [categories.ownLikes]: rankingLikes,
  }

  return (
    <>
      <div className={classes.weeklySummaryRoot}>
        <h2 className={classes.title}>直近１週間の状況</h2>
        {!teamMembers.length ? (
          <div className={classes.loading}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <>
            {Object.values(categories).map((item) => {
              if (isOnlyAppearLikeData && item !== 'ownLikes') return null
              return (
                <WeeklySummaryItem
                  key={item}
                  teamId={teamId}
                  classes={classes}
                  category={item}
                  rankingItems={categorizedRankingItems[item]}
                />
              )
            })}
          </>
        )}
      </div>
    </>
  )
}

type WeeklySummaryItemProps = {
  teamId: string
  category: Categories
  rankingItems: OrganizedRanking
  classes: ClassNameMap
}

const WeeklySummaryItem: React.FC<WeeklySummaryItemProps> = ({ rankingItems, category, teamId, classes }) => {
  const titles = {
    [categories.ownActions]: 'アクション達成数',
    [categories.ownPosts]: '投稿数',
    [categories.ownLikes]: 'いいねした数',
  }

  return (
    <div className={classes.weeklySummaryItemRoot}>
      <div className={classes.categoryTitle}>{titles[category]}</div>
      <div className={classes.data}>
        {rankingItems.length ? (
          rankingItems.map((item) => <RankingItemBox rankingItem={item} teamId={teamId} key={item.id} />)
        ) : (
          <span className={classes.notExist}>対象のメンバーが存在しません</span>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    weeklySummaryRoot: ({ ownStyles, isOnlyAppearLikeData }: StyleProps & StyleSubProps) => ({
      margin: isOnlyAppearLikeData ? 0 : '0 0 -10px',
      ...ownStyles,
    }),
    weeklySummaryItemRoot: ({ isOnlyAppearLikeData }: StyleProps & StyleSubProps) => ({
      margin: isOnlyAppearLikeData ? 0 : '0 0 40px',
    }),
    title: {
      textAlign: 'center',
      margin: '0 0 24px',
      fontSize: 16,
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
    },
    loading: {
      width: '100%',
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiCircularProgress-svg': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
    categoryTitle: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    data: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      marginTop: 28,
      [theme.breakpoints.down('xs')]: {
        rowGap: 16,
      },
    },
    notExist: {
      paddingLeft: 8,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
    },
  }),
  { name: 'SummaryBox' }
)
