import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { DefaultButton } from 'pages/teams/components'
import { InputComment } from 'pages/withdraw/index.component'

import { constants } from 'assets'

type Props = {
  handleNextButtonClick: (data?: InputComment) => void
  email?: String
  isLoading: boolean
}

export const ConfirmWithdraw: React.FC<Props> = ({ handleNextButtonClick, email, isLoading }) => {
  const classes = useStyles()
  const history = useHistory()

  const handlePrevButtonClick = () => {
    history.push(constants.PAGE_HELP)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <div className={classes.heading}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/failure.svg'} alt="アイコン" />
        <h2>最終確認です</h2>
      </div>
      <div className={classes.textContainer}>
        <p>
          退会すると今までCocolaboで利用したデータが<strong className="__warning">すべて削除</strong>されます。
        </p>
        <p>退会後はデータの復旧はできませんので予めご了承ください。</p>
      </div>
      <div>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th>メールアドレス</th>
              <td>{email}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.buttons}>
        <DefaultButton
          title="キャンセル"
          color="team_white"
          onClick={() => handlePrevButtonClick()}
          style={{ maxWidth: 180, height: 56, borderRadius: 28, borderWidth: 2 }}
        />
        <DefaultButton
          title="退会する"
          color="team_main"
          disabled={isLoading}
          onClick={() => handleNextButtonClick()}
          style={{ maxWidth: 180, height: 56, borderRadius: 28 }}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
      marginBottom: 48,
      '& h2': {
        margin: 0,
        color: constants.COLOR_ALERT,
      },
      '& img': {
        width: 40,
        height: 40,
      },
    },

    textContainer: {
      marginBottom: 32,
      '& p': {
        margin: '0 0 8px',
        '& .__warning': {
          color: constants.COLOR_RED,
          fontWeight: 'bold',
        },
      },
    },

    table: {
      width: '100%',
      borderCollapse: 'collapse',
      color: constants.TEXT_BLACK,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      '& th, td': {
        padding: '16px 24px',
        textAlign: 'left',
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

        [theme.breakpoints.down('sm')]: {
          display: 'block',
          padding: '8px 16px',
          textAlign: 'center',
        },
      },

      '& th': {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      },

      '& td': {
        pointerEvents: 'none', // スマホでの自動リンクを無効化
      },
    },

    buttons: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      marginTop: 48,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
      },
    },
  }),
  { name: 'ConfirmWithdraw' }
)
