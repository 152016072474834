import React from 'react'

import { TeamPlan, MasterType } from 'utils/generated'

import { queryGetMasters } from './graphql'

const PUBLIC_PLAN = 1

export const useTeamPlans = () => {
  const limit = 100
  const [plans, setTeamPlans] = React.useState<TeamPlan[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()
  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMasters({ type: MasterType.TeamPlan, limit })

      if (response) {
        setTeamPlans(
          (response.items.filter(
            (i) => i.code && i.name && i.tools && i.limit && i.price && i.publicRange && i.publicRange === PUBLIC_PLAN
          ) as TeamPlan[]) || []
        )
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { plans, nextToken, loading, error, refresh } as const
}
