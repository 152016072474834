import React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ClassNameMap } from '@material-ui/styles'

import { OnboardingTeamMemberRole } from 'utils/generated'

import { SubmitButton } from '../../../components/button-large'
import { OnbStore } from '../../PagesRoot'
import { TutorialStateProps } from '../Tutorial'

import check from '../assets/images/check.svg'
import { callTx } from '../assets/text'
import * as constants from 'assets/constants'


export const Complete = ({ stage, setStage }: TutorialStateProps & OnbStore) => {
  const styles = useCompleteStyles()

  const next = () => setStage(stage + 1)

  return (
    <div className={styles.completeRoot}>
      <div className={styles.wrapper}>
        <CompleteBody stage={stage} next={next} styles={styles} />
      </div>
    </div>
  )
}

const CompleteBody = ({ stage, next, styles }: { stage: number; next: () => void; styles: ClassNameMap }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const Tx = callTx(OnboardingTeamMemberRole.Member)

  return (
    <>
      <div className={styles.txHead}>{Tx[stage].head}</div>
      <img src={check} className={styles.img} alt={'check'} />
      <div className={styles.txBody}>{Tx[stage].body}</div>
      <div className={styles.btnWrapper}>
        <SubmitButton placeholder={'オンボーディングへ'} disabled={false} onPress={next} SPwidth={isSmDown} />
      </div>
    </>
  )
}

export const useCompleteStyles = makeStyles((theme: Theme) => ({
  completeRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    zIndex: 11000,
  },
  wrapper: {
    maxWidth: 560,
    margin: '136px auto 0',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    letterSpacing: 0,
    marginTop: 32,
  },
  txHead: {
    fontSize: 16,
    fontWeight: 'bold',
    color: constants.COLOR_ONBOARDING_MAIN,
    margin: '136px 0 0',
  },
  img: {
    height: 80,
    width: 80,
    margin: '30px 0 0',
  },
  txBody: {
    fontSize: 14,
    color: constants.TEXT_GRAY_DARK,
    margin: '42px 0 0',
  },
  btnWrapper: {
    minWidth: '100%',
    position: 'absolute',
    bottom: '0',
    background: constants.COLOR_WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
