import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { contents, contentIds } from './contents'

import { constants } from 'assets'

const CaseIntroduction: FC = () => {
  const classes = useStyles()
  const location = useLocation()

  const path = location.pathname.split('/').slice(-1)[0] as keyof typeof contentIds
  const content = contents[path]

  if (!content) {
    return <div className={classes.notExists}>ページが存在しません。</div>
  }

  return (
    <div className={classes.back}>
      <div className={classes.topContainer}>
        <div className={classes.topImage}>
          <div className={classes.topImageBox}>
            <img
              src={`${process.env.PUBLIC_URL}/img/case-introduction/${path}/${content.topImage}`}
              alt={content.topImage}
            />
          </div>
        </div>
        <div className={classes.titleBox}>
          <div className={classes.title}>
            {content.titleHead && <span className={classes.titleHead}>{content.titleHead}</span>}
            <h1>{content.title}</h1>
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.content}>
          {content.speakers && (
            <div className={classes.speakers}>
              <div className={classes.speakersTitle}>スピーカー</div>
              {content.speakers.map((speaker) => (
                <div key={speaker}>{speaker}</div>
              ))}
            </div>
          )}

          <div className={classes.paragraphs}>
            {content.paragraphs.map((paragraph, i) => (
              <div key={`paragraph-${i}`} className={classes.paragraph}>
                {paragraph.image && (
                  <div className={classes.image}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/case-introduction/${path}/${paragraph.image}`}
                      alt={paragraph.image}
                    />
                  </div>
                )}
                {paragraph.title && <h2>{paragraph.title}</h2>}

                <div className={classes.sentences}>
                  {paragraph.sentences.map((sentence, i) => (
                    <div key={`sentence-${i}`} className={classes.sentence}>
                      <div className={classes.text}>
                        <span className={classes.by}>{sentence.by}：</span>
                        {sentence.text}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    notExists: {
      padding: 16,
    },

    back: {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      lineHeight: 1.8,
      whiteSpace: 'pre-wrap',
    },

    topContainer: {
      display: 'flex',
      width: '100%',
      padding: '80px 12px 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '40px 16px 0',
      },

      '& h1': {
        margin: 0,
        fontSize: 24,
        lineHeight: 1.4,
      },

      '@global': {
        '@keyframes slideFromBottom': {
          '0%': {
            opacity: 0,
            transform: 'translateY(48px)',
          },
          '100%': {
            opacity: 1,
          },
        },
        '@keyframes slideFromLeft': {
          '0%': {
            opacity: 0,
            transform: 'translateX(-48px)',
          },
          '100%': {
            opacity: 1,
          },
        },
        '@keyframes slideFromRight': {
          '0%': {
            opacity: 0,
            transform: 'translateX(48px)',
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
    topImage: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    topImageBox: {
      width: '100%',
      maxWidth: 600,
      animation: '$slideFromBottom 800ms',
      [theme.breakpoints.up('md')]: {
        marginRight: -12,
        animation: '$slideFromLeft 800ms',
      },
      '& img': {
        width: '100%',
        borderRadius: 16,
        boxShadow: '0px 3px 6px #00000029',
        verticalAlign: 'top',
      },
    },
    titleBox: {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      minHeight: 160,
      backgroundColor: `${constants.COLOR_WHITE}66`,
      padding: '16px 24px',
      borderRadius: 16,
      boxShadow: '0px 3px 6px #00000029',
      animation: '$slideFromBottom 800ms 500ms both',
      [theme.breakpoints.up('md')]: {
        margin: '48px 0  0 -12px',
        padding: '16px 48px',
        maxWidth: 600,
        minHeight: 334,
        animation: '$slideFromRight 800ms 500ms both',
      },
    },
    titleHead: {
      marginBottom: 8,
      fontSize: 18,
    },

    container: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 24px 80px',
      [theme.breakpoints.down('xs')]: {
        padding: '0 16px 80px',
      },
    },
    content: {
      width: '100%',
      maxWidth: 1000,
    },
    speakers: {
      marginTop: 48,
      padding: '8px 0 8px 12px',
      borderLeft: `12px solid ${constants.COLOR_MAIN_NEW}66`,
    },
    speakersTitle: {
      fontWeight: 'bold',
    },
    paragraphs: {
      marginTop: 48,
    },
    paragraph: {
      marginTop: 32,

      '& h2': {
        margin: '32px 0 0',
        padding: '10px 16px',
        fontSize: 22,
        borderTop: `2px solid ${constants.COLOR_MAIN_NEW}66`,
        borderBottom: `2px solid ${constants.COLOR_MAIN_NEW}66`,
        lineHeight: 1.4,
      },
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
      width: '100%',

      '& img': {
        width: '100%',
        maxWidth: 600,
        boxShadow: '0px 3px 6px #00000029',
      },
    },
    sentences: {
      marginTop: 32,
    },
    sentence: {
      marginTop: 24,
    },
    text: {
      paddingLeft: 16,
      textIndent: -16,
    },
    by: {
      fontWeight: 'bold',
    },
  }),
  { name: 'CaseIntroduction' }
)

export default CaseIntroduction
