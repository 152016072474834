import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'

import action from './action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onKarteSearch: (id: string, successCallback: () => void) => dispatch(action.fetch.started({ id, successCallback })),
  onResetError: () => dispatch(action.resetError({})),
})

const mapStateToProps = (state: RootStateType) => {
  const { isKarteNotFound, isLoading, error } = state.pages.karteLeaderSearch
  return {
    user: { ...state.user },
    isKarteNotFound,
    isLoading,
    error,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
