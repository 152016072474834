import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { IConfirmSignUp } from '../../services/amplify/confirmSignUp'
import { RootStateType } from '../../store'
import { UserActions } from '../../stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    confirmSignUp: (user: IConfirmSignUp) => dispatch(UserActions.confirmSignUp.started({ user })),
    resendSignUp: (email: string) => dispatch(UserActions.resendSignUp.started({ email })),
    refreshErrors: () => dispatch(UserActions.refreshErrors({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
