import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'

import actions from 'pages/hint/_shared/stores/actions'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  goBack: (path?: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useBackButton = (): IProps => {
  const history = useHistory()
  const dispatch = useDispatch()
  const pagesSelector = useSelector((state: RootStateType) => state.hint.pages)

  /**
   * カテゴリのオプション一覧を取得する
   */
  const goBack = (path?: string): void => {
    if (path) {
      dispatch(actions.resetFetch())
      dispatch(actions.getPages({ currentPages: 1, pageAction: '' }))
      history.push(path)
    } else {
      dispatch(
        actions.getPages({
          currentPages: pagesSelector.currentPages,
          pageAction: 'BACK',
        })
      )
      history.goBack()
    }
  }

  return {
    goBack,
  }
}
