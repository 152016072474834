import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { DailyCheckLabels } from 'pages/onboarding/pages/daily-check/components/ListBtn'
import { getDailyCheckImage } from 'pages/onboarding/utils/timelineImage'
import { OnboardingPost } from 'utils/generated'

import * as constants from 'assets/constants'


type Props = {
  post: OnboardingPost
  isSmUp: boolean
}
type StyleProps = {}

export const DailyCheckBox: React.FC<Props & StyleProps> = ({ post, isSmUp }) => {
  const classes = useStyles()

  if (!post.daily) return null
  return (
    <>
      <div
        className={classes.dailyContainer}
        style={{ display: isSmUp ? 'flex' : 'block', alignItems: isSmUp ? 'center' : undefined }}
      >
        <div className={classes.dailyText} style={{ marginRight: isSmUp ? 16 : 0 }}>
          今日の気分は
        </div>
        <div style={{ marginTop: isSmUp ? 0 : 7 }}>
          <img
            className={classes.contentImage}
            src={getDailyCheckImage(post.daily)}
            alt={DailyCheckLabels[post.daily]}
          />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  {
    dailyContainer: {
      paddingTop: 24,
    },
    dailyText: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    contentImage: {
      width: '100%',
      maxWidth: 274,
      verticalAlign: 'top',
    },
  },
  { name: 'DailyCheckBox' }
)
