import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { TeamBuildingReview, TeamBuildingReviewTeamAnswerType } from 'utils/generated'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { ReviewTimer } from './generic/ReviewTimer'


type Props = {
  title: string
  subtitle: string
  teamBuildingReview: TeamBuildingReview | undefined
}

export const Step2Presen: React.FC<Props> = ({ title, subtitle, teamBuildingReview }) => {
  const classes = useStyles()
  const {
    summaryBuilding,
    teamMembers,
    teamBuildingReviewMembers,
    iceBreakNumber,
    iceBreakMinute,
    setIsPresenEnd,
    teamBuildingReviews,
  } = React.useContext(TeamBuildingReviewHooksContext)

  const from = teamBuildingReview?.timeStep2Presen
  const length = React.useMemo(() => teamBuildingReview?.iceBreakUserIds?.length ?? 0, [teamBuildingReview])
  const currentUser = React.useMemo(() => {
    if (teamBuildingReview?.iceBreakUserIds) {
      const currentId = length > 0 ? teamBuildingReview.iceBreakUserIds[length - 1] : undefined
      return teamMembers.find((member) => member.userId === currentId)
    }
    return undefined
  }, [length, teamBuildingReview, teamMembers])

  const prevTeamBuildingReview = React.useMemo(() => {
    if (teamBuildingReviews && teamBuildingReviews.length > 1) {
      return teamBuildingReviews[1]
    }
    return undefined
  }, [teamBuildingReviews])
  const getPrevValue = React.useCallback(
    (type: TeamBuildingReviewTeamAnswerType, index: number) => {
      const targetAnswer = prevTeamBuildingReview?.answers?.find(
        (answer) => answer.type === type && answer.index === index
      )
      return targetAnswer?.items.find((item) => item.userId === currentUser?.userId)?.value
    },
    [currentUser, prevTeamBuildingReview]
  )
  const current = React.useMemo(
    () =>
      teamBuildingReviewMembers?.items.find((item) => {
        return item.userId === currentUser?.userId && item.answers.length > 0
      }),
    [currentUser, teamBuildingReviewMembers]
  )
  const teamGoalAnswer = React.useMemo(
    () => current?.answers.find((answer) => answer.type === TeamBuildingReviewTeamAnswerType.TeamGoal),
    [current]
  )
  const codeOfConductAnswers = React.useMemo(
    () => current?.answers.filter((answer) => answer.type === TeamBuildingReviewTeamAnswerType.CodeOfConduct),
    [current]
  )
  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection title={`${length}/${iceBreakNumber}人目`}>
        <Content>
          {currentUser && (
            <div className={classes.flex}>
              <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
              <span className={classes.userName}>{currentUser?.fullName}さん</span>
            </div>
          )}
        </Content>
        <ContentSection title="チーム目標">
          <Content>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ fontWeight: 'bold' }}>{summaryBuilding?.goal}</div>
              <div style={{ textAlign: 'right' }}>
                <div className={classes.prevPoint}>
                  前回 {getPrevValue(TeamBuildingReviewTeamAnswerType.TeamGoal, 0) ?? '-'} 点
                </div>
                <div className={classes.point}>{teamGoalAnswer?.value ?? '-'} 点</div>
              </div>
            </div>
          </Content>
        </ContentSection>
        <ContentSection title="行動規範">
          {summaryBuilding?.codeOfConducts?.map((codeOfCunduct, i) => (
            <Content key={`code-of-conduct-${i}`}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ fontWeight: 'bold' }}>{codeOfCunduct}</div>
                <div style={{ textAlign: 'right' }}>
                  <div className={classes.prevPoint}>
                    前回 {getPrevValue(TeamBuildingReviewTeamAnswerType.CodeOfConduct, i) ?? '-'} 点
                  </div>
                  <div className={classes.point}>{codeOfConductAnswers ? codeOfConductAnswers[i].value : '-'} 点</div>
                </div>
              </div>
            </Content>
          ))}
        </ContentSection>
        <ReviewTimer from={from} time={iceBreakMinute * 60} container setIsPresenEnd={setIsPresenEnd} />
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    '@media (max-width: 632px)': {
      justifyContent: 'flex-start',
    },
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  point: {
    color: '#00838C',
    fontWeight: 'bold',
  },
  prevPoint: {
    fontSize: 12,
    color: '#333',
  },
}))
