import { useContext, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useConsultationModalEpic } from 'pages/hint/_shared/hooks/useConsultationModalEpic'
import { ICreateConsultationParams } from 'pages/hint/_shared/stores/apis/createConsultation'

// -----------------------------
// interface
// -----------------------------
export type IuseDialogPublishProps = {
  onClose: () => void
  onOpenDialog: () => void
  onDoNotPublish: () => void
  onDoPublish: (params: ICreateConsultationParams) => Promise<void>
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useDialogPublish = (): IuseDialogPublishProps => {
  const [open, setOpen] = useState<boolean>(false)
  const context = useContext(hintContext)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)
  const consultationModalEpic = useConsultationModalEpic()

  /**
   * ダイアログをクローズする時の処理
   */
  const onClose = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログをオープンする時の処理
   */
  const onOpenDialog = (): void => {
    setOpen(true)
  }

  /**
   * ダイアログ内の記事公開ボタンをキャンセルした時
   */
  const onDoNotPublish = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の記事公開ボタンを押した時
   */
  const onDoPublish = async (params: ICreateConsultationParams): Promise<void> => {
    await consultationModalEpic.createConsultationEpic(params, 1)

    if (!commonSelector.isLoading) {
      setOpen(false)
      context.onCloseModal()
      window.location.href = `/hint/faq`
    }
  }

  /**
   * ダイアログの状態を返す
   */
  const getOpen = (): boolean => {
    return open
  }

  return {
    onClose,
    onOpenDialog,
    onDoNotPublish,
    onDoPublish,
    getOpen,
  }
}
