import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../store'
import { HelpActions } from '../../stores/help/action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onMount: () => dispatch(HelpActions.onMount.started({})),
  search: (q?: string, filter?: string) => dispatch(HelpActions.serach.started({ q, filter })),
})

const mapStateToProps = (state: RootStateType) => {
  return {
    help: { ...state.help },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
