import { useEffect, useState } from 'react'

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

// -----------------------------
// interface
// -----------------------------
export type IuseModalValueProps = {
  setTitleValue: (value: string) => void
  setCategoryValue: (value: string) => void
  setContentValue: (content: string) => void
  validateTitle: (value: string) => void
  validateContent: (value: string) => void
  titleValue: string
  categoryValue: string | null
  contentValue: string
  contentPreviewValue: string
  titleError: boolean
  contentError: boolean
  isUpdate: () => boolean
  formatContentPreviewDatas: (htmls: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useModalValue = (): IuseModalValueProps => {
  const [titleValue, _setTitleValue] = useState<string>('')
  const [categoryValue, _setCategoryValue] = useState<string>('')
  const [contentValue, _setContentValue] = useState<string>('')
  const [contentPreviewValue, _setContentPreviewValue] = useState<string>('')

  const [titleError, _setTitleError] = useState<boolean>(false)
  const [contentError, _setContentError] = useState<boolean>(false)

  const modalSelector = useSelector((state: RootStateType) => state.hint.ui.modal)
  const hintSelector = useSelector((state: RootStateType) => state.hint)

  const EDITOR_DEFAULT_LEAD_LENGTH = 8

  useEffect(() => {
    if (modalSelector.status === 'UPDATE') {
      if (hintSelector.hint.title && hintSelector.hint.content && hintSelector.hint.category) {
        setTitleValue(hintSelector.hint.title)
        setContentValue(hintSelector.hint.content)
        setCategoryValue(hintSelector.hint.category)
      }
    }
  }, [hintSelector, modalSelector.status])

  /**
   * タイトルのValueを設定する
   */
  const setTitleValue = (value: string): void => {
    _setTitleValue(value)
  }

  /**
   * カテゴリーのValueを設定する
   */
  const setCategoryValue = (value: string): void => {
    _setCategoryValue(value)
  }

  /**
   * コンテントのValueを設定する
   */
  const setContentValue = (content: string): void => {
    _setContentValue(content)
  }

  /**
   * タイトルのErrorを設定する
   */
  const validateTitle = (value: string) => {
    _setTitleError(value === '' ? true : false)
  }

  /**
   * コンテントのErrorを設定する
   */
  const validateContent = (value: string) => {
    _setContentError(value.length > 5000 + EDITOR_DEFAULT_LEAD_LENGTH ? true : false)
  }

  /**
   * モーダルのアクション状態を返す
   */
  const isUpdate = (): boolean => {
    return modalSelector.status === 'UPDATE' ? true : false
  }

  /**
   * 入力されたEditorデータを平文にフォーマットする
   */
  const formatContentPreviewDatas = (htmls: string) => {
    const draft = htmlToDraft(htmls)
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
    const updatedEditorState = EditorState.createWithContent(contentState)
    const html = draftToHtml(convertToRaw(updatedEditorState.getCurrentContent()))
    const blocksFromHTML = convertFromHTML(html)

    if (blocksFromHTML.contentBlocks !== null) {
      const previewDatas = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks)
      const r = convertToRaw(previewDatas)
      const datas = r.blocks.map((r) => {
        return r.text
      })

      _setContentPreviewValue(datas.join(''))
    }
  }

  return {
    setTitleValue,
    setCategoryValue,
    setContentValue,
    validateTitle,
    validateContent,
    titleValue,
    categoryValue,
    contentValue,
    contentPreviewValue,
    titleError,
    contentError,
    isUpdate,
    formatContentPreviewDatas,
  }
}
