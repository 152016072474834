import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'

// -----------------------------
// Params
// -----------------------------
export type IHintCommentListParams = {
  id: string
}

// -----------------------------
// Result
// -----------------------------
export type IHintCommentListResult = {
  id: string
  hintId: string
  username: string
  user: {
    id: string
    firstName: string
    lastName: string
    imageUrl: string
    shortDescription: string
    description: string
  }
  content: string
  createdAt: string
  updatedAt: string
}

export const getHintCommentList = async (params: IHintCommentListParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getHintCommentList,
      variables: {
        hintId: params.id,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return res?.data?.getHintCommentList?.items
  } catch (e) {
    throw e
  }
}
