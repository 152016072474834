import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { QueryGetKarte } from '../../graphql'
import { IKarte } from '../../interface'

export const karteGet = async (variables: { id: string }) => {
  const response: any = await API.graphql({
    query: QueryGetKarte,
    variables,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.getKarte as IKarte
}
