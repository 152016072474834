import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { Point } from 'utils/generated'

import { UserActions } from '../user/action'

import { SetupActions } from './action'

export interface ISetup {
  point?: Point
  radar?: IRadar
  fetched?: boolean

  // state for change setup start date
  currentSetupStartedAt?: string | null
}

export interface IRadar {
  reaction: IRadarValue
  anket: IRadarValue
}

export interface IRadarValue {
  boss: number
  vision: number
  member: number
  team: number
  other: number
}

export const initialState: ISetup = {
  point: undefined,
  radar: undefined,

  fetched: false,

  currentSetupStartedAt: null,
}

export const SetupReducer = reducerWithInitialState<ISetup>(initialState)
  // fetchPoint
  .case(SetupActions.fetchPoint.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(SetupActions.fetchPoint.done, (state, payload) => {
    return { ...state, point: payload.result, error: null }
  })
  .case(SetupActions.fetchPoint.failed, (state, error) => {
    return { ...state, ...error }
  })

  // fetchRadar
  .case(SetupActions.fetchRadar.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(SetupActions.fetchRadar.done, (state, payload) => {
    return { ...state, radar: payload.result, error: null }
  })
  .case(SetupActions.fetchRadar.failed, (state, error) => {
    return { ...state, ...error }
  })

  // changeCurrentSetupStartedAt
  .case(SetupActions.changeCurrentSetupStartedAt, (state, payload) => {
    return { ...state, currentSetupStartedAt: payload.date }
  })

  // signout
  .case(UserActions.signout.done, (state, payload) => {
    return { ...initialState, signout: true, error: null }
  })
