import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Dialog, useTheme, useMediaQuery, ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { NotificationBatch } from 'pages/onboarding/components/notification'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { useIsTeam } from 'pages/plan/hooks/useIsTeam'

import { useRenderMenuHelpers } from '../hooks/useHamburgerBoxStyle'

import { FooterIcon } from './components/FooterIcon'
import { MenuItem } from './components/MenuItem'
import { OtherLinks } from './components/OtherLinks'

import ChangeIcon from './assets/ChangeIcon.svg'
import CocolaboOnbLogo from './assets/CocolaboOnbLogo.svg'
import email from './assets/email.svg'
import hatena from './assets/hatena.svg'
import movie from './assets/movie.svg'
import NotificationIcon from './assets/NotificationIcon.svg'
import ProfileIconSmall from './assets/ProfileIconSmall.svg'
import * as constants from 'assets/constants'
import { replacePathParams } from 'assets/history'
import { OnbPages, Pages } from 'assets/pages'

interface IOwnProps {
  teamId?: string
  disabledMenu?: boolean
  countNotification?: number
}

export const HEADER_HEIGHT = 56

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, teamId, countNotification }) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const historyRouter = useHistory()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { teamMember } = useTeamMember(teamId)
  const { teams, loading: loadingTeams } = useIsTeam()

  const location = useLocation()
  React.useEffect(() => {
    document.body.style.overflowY = ''
  }, [location])

  const history = useOnbHistory()

  const { menus, overviews } = useRenderMenuHelpers(history, teamId)
  const links = [OnbPages.Ranking, OnbPages.Overview, OnbPages.MemberStatus]

  const handleOnClickLink = (to: OnbPages) => {
    history.push(to, { teamId })
    document.body.style.overflowY = ''
    setOpenMenu(false)
  }
  const pushUsagePage = (e: any) => {
    e.stopPropagation()
    history.push(OnbPages.ONBOARDING_PAGE_USAGE, { teamId })
  }

  const handleClickOpen = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenMenu(true)
  }

  const handleClose = (e: React.MouseEvent | React.MouseEvent<Document, MouseEvent>) => {
    e.stopPropagation()
    setOpenMenu(false)
  }

  const handleClickExpansion = () => {
    setExpanded((prev) => !prev)
  }

  const closeMenu = () => {
    setOpenMenu(false)
  }

  const pureHistory = useHistory()
  const handleClickTeam = () => {
    if (teams && teams.items.length > 0) {
      pureHistory.push(replacePathParams(Pages.TeamsDashboard, { teamId: teams.items[0].id }))
    }
  }

  const menuBody = (
    <div className={classes.menus}>
      <div className={classes.menuOverlap}>
        {!isSmUp ? (
          <div className={classes.menuContainer}>
            <div
              className={classes.titleContainer}
              onClick={() => {
                handleOnClickLink(OnbPages.Timeline)
              }}
            >
              <div
                onClick={() => {
                  historyRouter.push(constants.PAGE_CONTACT)
                }}
              >
                <img className={classes.logo} src={CocolaboOnbLogo} alt={'logo'} />
              </div>
            </div>
            <div
              className={classNames([classes.closeIcon, openMenu && 'closeIconOpenL', !openMenu && 'closeIconCloseL'])}
              onClick={handleClose}
            >
              <img
                className="closeIconImg"
                src={process.env.PUBLIC_URL + '/assets/svg/newclose_black.svg'}
                alt={'close'}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.menuList}>
          {!isSmUp ? (
            <>
              <ul>
                <li
                  onClick={() => {
                    handleOnClickLink(OnbPages.Mypage)
                  }}
                  className="menuTitleContainer"
                >
                  {teamMember && teamMember.imageUrl ? (
                    <img className={classes.avatar} src={teamMember.imageUrl} alt={'avatar'} />
                  ) : (
                    <img className={classes.avatar} src={ProfileIconSmall} alt={'avatar'} />
                  )}
                  <div className="menuPlofs">
                    <div className="menuNickname">{teamMember?.nickname ? teamMember?.nickname : ''}</div>
                    <div className="menuAuthType">
                      {teamId ? teamMemberRoleLabel(teamMember && teamMember.role) : ''}
                    </div>
                  </div>
                </li>
                <div className="line"></div>
                <div className="spaces"></div>
                {teamMember?.role ? (
                  <>
                    {menus[teamMember?.role].map((item) => (
                      <li
                        key={item.imgSrc}
                        onClick={() => {
                          item.onClick()
                          document.body.style.overflowY = ''
                          setOpenMenu(false)
                        }}
                      >
                        <img src={item.imgSrc} alt={'icon'} />
                        <span>{item.labelText}</span>
                      </li>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                <li
                  onClick={() => {
                    handleOnClickLink(OnbPages.Notification)
                  }}
                >
                  <img src={NotificationIcon} alt={'NotificationIcon'} />
                  <span>お知らせ</span>
                  <NotificationBatch count={countNotification ? countNotification : 0} withWrapper />
                </li>
                <div className="line"></div>
                {teamMember?.role ? (
                  <>
                    {overviews[teamMember?.role].map((item, i) => (
                      <MenuItem key={item.labelText} onClick={() => handleOnClickLink(links[i])} menus={item} />
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ul>

              {!loadingTeams && teams && teams.items.length > 0 ? (
                <Accordion square={true} expanded={expanded} classes={{ root: classes.rootExpansion }}>
                  <AccordionSummary
                    className={classes.summary}
                    onClick={handleClickExpansion}
                    classes={{
                      content: classes.contentSummary,
                      expandIcon: classes.MUISummaryExpandIcon,
                    }}
                  >
                    <img src={ChangeIcon} alt={'ChangeIcon'} />
                    <span>プラン切替</span>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{
                      root: classes.detailsExpansion,
                    }}
                  >
                    <div className={classNames([classes.itemPlan, classes.activePlan])} onClick={handleClickExpansion}>
                      オンボーディングプラン
                    </div>
                    <div className={classes.itemPlan} onClick={handleClickTeam}>
                      チームビルディングプラン
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <OtherLinks handleOnClickLink={closeMenu} />
          <FooterIcon />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={classes.container}>
        <div className={classes.widthContainer}>
          <div
            className={classes.titleContainer}
            onClick={() => {
              handleOnClickLink(OnbPages.Timeline)
            }}
          >
            <img className={classes.logo} src={CocolaboOnbLogo} alt={'logo'} />
          </div>

          <div ref={anchorRef}>
            {!openMenu ? (
              <div
                className={classNames([
                  classes.burgerIcon,
                  openMenu && 'burgerIconOpenL',
                  !openMenu && 'burgerIconCloseL',
                ])}
                onClick={
                  isSmUp
                    ? undefined
                    : (e) => {
                        handleClickOpen(e)
                      }
                }
              >
                {!isSmUp ? (
                  <img
                    className="burgerIconImg"
                    src={process.env.PUBLIC_URL + '/assets/svg/newburger_green.svg'}
                    alt={'burger'}
                  />
                ) : (
                  <div className={classes.iconContainer}>
                    <div className={classes.iconWrapperL} onClick={(e) => pushUsagePage(e)}>
                      <img src={movie} alt="使い方" className={classes.icon} />
                      <span className={classes.iconText}>使い方</span>
                    </div>
                    <div className={classes.iconWrapper} onClick={() => historyRouter.push(constants.PAGE_HELP)}>
                      <img className={classes.icon} src={hatena} alt="ヘルプ" />
                      <span className={classes.iconText}>ヘルプ</span>
                    </div>

                    <div className={classes.iconWrapperR} onClick={() => historyRouter.push(constants.PAGE_CONTACT)}>
                      <img src={email} alt="問い合わせ" className={classes.icon} />
                      <span className={classes.iconText}>お問合せ</span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={classNames([
                  classes.closeIcon,
                  openMenu && 'closeIconOpenL',
                  !openMenu && 'closeIconCloseL',
                ])}
                onClick={handleClose}
              >
                <img
                  className="closeIconImg"
                  src={process.env.PUBLIC_URL + '/assets/svg/newclose_black.svg'}
                  alt={'close'}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* opened menu */}
      {isSmUp ? (
        <Popper
          open={openMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="bottom-end"
          style={{ zIndex: 1100, marginTop: 16 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper
                className={classes.paper}
                elevation={3}
                classes={{ elevation3: classes.elevation3 }}
                style={{ maxHeight: `calc(${window.innerHeight}px - 80px)` }}
              >
                <ClickAwayListener mouseEvent="onClick" touchEvent="onTouchStart" onClickAway={handleClose}>
                  {menuBody}
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      ) : (
        <Dialog
          open={openMenu}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          BackdropProps={{
            classes: { root: classes.dialogBackdrop },
            timeout: { enter: 500, exit: 500 },
          }}
          TransitionProps={{
            timeout: { enter: 500, exit: 500 },
          }}
          classes={{
            paper: classes.dialogPaper,
          }}
          className={classes.dialog}
        >
          {menuBody}
        </Dialog>
      )}
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      zIndex: 999,
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      minHeight: HEADER_HEIGHT,
      backgroundColor: constants.COLOR_WHITE,
      width: '100%',
    },
    widthContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: HEADER_HEIGHT,
      maxWidth: '812px',
      margin: '0 auto',
      padding: '2px 16px 0',
      [theme.breakpoints.down(901)]: {
        maxWidth: 676,
      },
    },
    titleContainer: {
      cursor: 'pointer',
    },
    title: {
      color: '#FFFFFF',
      fontSize: 14,
    },
    logo: {},
    coutionDailyCheck: {},

    avatar: {
      width: 60,
      height: 60,
      borderRadius: 30,
      backgroundColor: constants.COLOR_GRAY,
      objectFit: 'cover',
    },

    burgerIcon: {
      [theme.breakpoints.down('xs')]: {
        height: 26,
        width: 26,
        padding: 5,
        marginRight: -5,
        cursor: 'pointer',
        '&.burgerIconOpenL': {
          animation: `$burgerIconFadeIn 500ms ${theme.transitions.easing.easeOut}`,
          '@global': {
            '@keyframes burgerIconFadeIn': {
              '0%': {
                display: 'block',
                transform: ' rotate(0turn);',
              },
              '100%': {
                display: 'none',
                transform: ' rotate(0.25turn);',
              },
            },
          },
        },
        '&.burgerIconCloseL': {
          animation: `$burgerIconFadeOut 500ms ${theme.transitions.easing.easeOut}`,
          '@global': {
            '@keyframes burgerIconFadeOut': {
              '0%': {
                display: 'none',
                transform: ' rotate(0.25turn)',
              },
              '100%': {
                display: 'block',
                transform: ' rotate(0turn)',
              },
            },
          },
        },
        '& .burgerIconImg': {
          height: 16,
          width: 16,
        },
      },
    },

    closeIcon: {
      height: 26,
      width: 26,
      padding: 5,
      marginRight: -5,
      cursor: 'pointer',
      '&.closeIconOpenL': {
        animation: `$closeIconFadeIn 500ms ${theme.transitions.easing.easeOut}`,
        '@global': {
          '@keyframes closeIconFadeIn': {
            '0%': {
              opacity: 0,
              transform: ' rotate(0turn)',
            },
            '100%': {
              opacity: 1,
              transform: ' rotate(0.25turn)',
            },
          },
        },
      },
      '&.closeIconCloseL': {
        animation: `$closeIconFadeOut 500ms ${theme.transitions.easing.easeOut}`,
        '@global': {
          '@keyframes closeIconFadeOut': {
            '0%': {
              opacity: 1,
              transform: ' rotate(0.25turn)',
            },
            '100%': {
              opacity: 0,
              transform: ' rotate(0turn)',
            },
          },
        },
      },
      '& .closeIconImg': {
        height: 16,
        width: 16,
      },
    },
    iconContainer: {
      display: 'flex',
    },
    icon: {
      width: 16,
      height: 16,
      margin: '5px auto',
    },
    iconWrapper: {
      display: 'flex',
      flexFlow: 'column',
      margin: '0 30px',
      cursor: 'pointer',
    },
    iconWrapperR: {
      display: 'flex',
      flexFlow: 'column',
      cursor: 'pointer',
    },
    iconWrapperL: {
      display: 'flex',
      flexFlow: 'column',
      cursor: 'pointer',
    },
    iconText: {
      fontSize: 10,
      fontWeight: 'bold',
      color: constants.COLOR_GRAY_DARK,
    },
    menuOverlap: {
      backgroundColor: '#F5F5F5',
      maxWidth: 300,
      margin: '0 0 0 auto',
    },

    menuContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: HEADER_HEIGHT,
      backgroundColor: constants.COLOR_WHITE,
      padding: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },

    paper: {
      borderRadius: 8,
      overflow: 'auto',
    },
    elevation3: {
      boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
    },

    dialogBackdrop: {
      backgroundColor: 'rgba(10, 10, 10, 0.5)',
    },
    dialogPaper: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: 0,
      borderRadius: 0,
    },
    dialog: {
      fontFamily: constants.ONBOARDING_FONT_FAMILY,
      '& .MuiDialog-paperScrollPaper': {
        maxHeight: '100%',
      },
    },

    menus: {
      // width: '100%',
      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,

        '& .menuTitleContainer': {
          height: '108px',
          padding: '24px 16px',
          margin: '0',
        },

        '& li': {
          margin: 0,
          padding: '20px 16px',
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: constants.TEXT_GRAY_DARK,
          fontSize: '14px',
          fontSWeight: 600,
          '&:hover': {
            backgroundColor: '#fafafa',
          },
          '& .menuNickname': {
            fontSize: 18,
            fontWeight: 'bold',
            color: constants.TEXT_GRAY_DARK,
            margin: '0 0 0 16px',
            wordBreak: 'break-all',
          },

          '& .menuAuthType': {
            fontSize: 10,
            fontWeight: 300,
            color: constants.COLOR_GRAY_DARK,
            margin: '0 0 0 16px',
          },

          '& span': {
            padding: '1px 0 0 16px',
            fontSize: 16,
            fontWeight: 'bold',
          },

          // for button reset
          backgroundColor: 'white',
          border: 0,
          width: '100%',
          cursor: 'pointer',
          '&:focus': {
            outline: 'none',
          },
        },
      },

      '& .line': {
        margin: '0',
        padding: '0 0 1.04px 0',
        backgroundColor: '#F5F5F5',
      },

      '& .spaces': {
        margin: '0',
        padding: '8px 0',
        backgroundColor: 'white',
      },

      '& .__additionalMenu': {
        backgroundColor: constants.COLOR_GRAY,
        padding: '40px 32px 50px',
      },
      '& .__buttons': {
        display: 'flex',
        marginBottom: '16px',

        '& a': {
          width: '100%',
          textDecoration: 'none',
        },

        '& :first-child': {
          marginRight: '4px',
        },
        '& :last-child': {
          marginLeft: '4px',
        },
      },
    },

    rootExpansion: {
      boxShadow: 'none',
      '&::before': {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
      '&.Mui-expanded': {
        margin: 0,
      },
      '&.MuiAccordion-root': {
        '&.Mui-expanded': {
          margin: 0,
          '&::before': {
            opacity: 1,
          },
        },
      },
    },
    summary: {
      margin: 0,
      padding: '20px 16px',
      '& span': {
        marginLeft: 16,
        fontSize: 16,
        fontWeight: 'bold',
      },
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    contentSummary: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      '&.MuiAccordionSummary-content': {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    detailsExpansion: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    itemPlan: {
      padding: '23px 56px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    activePlan: {
      color: constants.COLOR_MAIN_NEW,
    },
  })

export default withStyles(useStyles)(Index)
