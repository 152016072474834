type FAQ = {
  Q: string
  A: string
  URL?: string
}

export const FAQData: Array<FAQ> = [
  {
    Q: '料金について教えて下さい。',
    A: '料金につきましてはお問い合わせください。また、基本的にツール単体でのご提供は行なっていません。',
  },
  {
    Q: '管理職以外が使ってもよいのでしょうか？',
    A: 'はい。どなた様でもご利用いただけます。プロジェクトリーダーの方や、いずれチーム作りをご経験する予定がある方など、ぜひご活用下さい。',
  },
  {
    Q: '各機能の使い方を詳しく知りたいのですが',
    A: '各機能については、機能一覧（https://cocolabo.club/features）をご覧下さい。また、お問い合わせフォームよりご連絡いただければ担当よりご説明させていただきます。また、定期的にセミナーも開催していますのでこちらもお問い合わせ下さい。',
  },
  {
    Q: '組織開発のワークショップ内容をもう少し知りたいのですが',
    A: 'お問い合わせフォームよりお問い合わせ下さい。担当よりご案内させていただきます。実績については企業導入事例をご参照下さい。',
    URL: 'https://cocolabo.club/case',
  },
]
