import { useRef } from 'react'
import { createContext, useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'
import { v4 as uuidv4 } from 'uuid'

import actions from '../stores/actions'


import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IContext = {
  isShowModal: boolean
  onSetShowUpdateModal: () => void
  onSetShowCreateModal: () => void
  onCloseModal: () => void
}

// -----------------------------
// Hooks
// -----------------------------
export const useHintContext = (): IContext => {
  const history = useHistory()
  const [isShowModal, setShowModal] = useState<boolean>(false)
  const uuidRefs = useRef<string>('')
  const userSelector = useSelector((state: RootStateType) => state.user)
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const dispatch = useDispatch()

  // --------------------------------------------------
  // Editorの作成時に固定のuuidを作成して持ち回す
  // --------------------------------------------------
  React.useEffect(() => {
    uuidRefs.current = uuidv4() // Hint-ID
  }, [])

  /**
   * モーダルをオープンする
   */
  const onSetShowUpdateModal = (): void => {
    if (!userSelector.username) {
      history.push(constants.PAGE_SIGNIN)
      return
    }
    dispatch(actions.updateModalActive({ id: hintSelector.hint.id }))
    setShowModal(true)
  }

  /**
   * モーダルをオープンする
   */
  const onSetShowCreateModal = async (): Promise<void> => {
    dispatch(actions.createModalActive({ id: uuidRefs.current }))
    setShowModal(true)
  }

  /**
   * モーダルをクローズする
   */
  const onCloseModal = (): void => {
    dispatch(actions.closeModalActive())
    setShowModal(false)
  }

  return {
    isShowModal,
    onSetShowUpdateModal,
    onSetShowCreateModal,
    onCloseModal,
  }
}

// -----------------------------
// Context
// -----------------------------
export const hintContext = createContext<IContext>({} as IContext)
