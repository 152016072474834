import * as React from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { GhostCardList } from '../components/GhostCardList'
import { Layout } from '../components/Layout'
import { ResultGhostFinding } from '../components/ResultGhostFinding'
import { ResultHero } from '../components/ResultHero'
import { SectionLine } from '../components/SectionLine'
import { SimilarTypeGhosts } from '../components/SimilarTypeGhosts'
import { useObakesagashi } from '../hooks/obakesagashi'

import { constants } from 'assets'

export const Result: React.FC = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { result, ghosts } = useObakesagashi(id)

  const isValidId = React.useMemo(() => {
    const createdAt = result?.own?.createdAt
    if (!createdAt) return false

    const answeredAtDate = new Date(createdAt)
    // 閲覧可能期間が30日なので、回答日時から29日後の日付を取得
    const expirationDate = answeredAtDate.setDate(answeredAtDate.getDate() + 29)
    const expirationDateOnTime = new Date(expirationDate).setHours(23, 59, 59, 999)

    return expirationDateOnTime > new Date().getTime()
  }, [result?.own?.createdAt])

  const findGhostById = React.useCallback(
    (id: number) => {
      return ghosts.find((ghost) => Number(ghost.id) === id)
    },
    [ghosts]
  )

  const resultGhostIdList = React.useMemo(() => {
    return result?.own?.resultGhostIdList
  }, [result])

  return (
    <Layout>
      <div className={classes.headerGhost}></div>
      <SectionLine backImage="typeOne" isFadeIn />

      {result === null ? (
        <p className={classes.noResult}>結果が存在しません。</p>
      ) : result === undefined ? (
        <></>
      ) : !isValidId ? (
        <p className={classes.noResult}>診断IDの有効期限が過ぎています。</p>
      ) : (
        <div className={classes.body}>
          <ResultHero resultGhostsCount={resultGhostIdList?.length ?? 0} />
          <GhostCardList ghostIdList={resultGhostIdList?.slice(0, 3)} findGhostById={findGhostById} />
          <SimilarTypeGhosts result={result} findGhostById={findGhostById} />
          <ResultGhostFinding createdAt={result.own?.createdAt} />
        </div>
      )}
    </Layout>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerGhost: {
      height: 86,
      background: constants.COLOR_NAVY,
      [theme.breakpoints.down('sm')]: {
        height: 106,
      },
    },
    noResult: {
      display: 'flex',
      justifyContent: 'center',
      margin: '48px 0',
    },
    body: {
      color: '#fff',
    },
  }),
  { name: 'Result' }
)
