import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { queryGetTeams } from 'pages/teams/hooks/graphql'
import { TeamConnection } from 'utils/generated'

export const useIsTeam = (fetched: boolean | undefined, userId: string | undefined) => {
  const [teams, setTeams] = React.useState<TeamConnection | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const location = useLocation()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)

    try {
      if (fetched && userId) {
        const getTeams = await queryGetTeams({ limit: 1 })
        setTeams(getTeams)
      } else {
        // userId が存在しない状態ではチーム情報をクリアする（ログアウトしたタイミングでチーム情報が保持されるのを防ぐ）。
        setTeams(undefined)
      }
    } catch (e) {
      console.log('e', e as Error)
      setError(e as Error)
    }

    setLoading(false)
  }, [fetched, userId])

  React.useEffect(() => {
    refresh()
  }, [location.pathname, refresh])

  return { teams, loading, error } as const
}
