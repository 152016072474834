import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import { QueryGetEncryptStringArgs } from 'utils/generated'

export const useUtils = () => {
  const getEncryptString = async (args: QueryGetEncryptStringArgs): Promise<string | undefined> => {
    const response = (await API.graphql({
      query: gql`
        query getEncryptString($encrypt: Boolean!, $text: String!) {
          getEncryptString(encrypt: $encrypt, text: $text)
        }
      `,
      variables: { ...args },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })) as GraphQLResult<{ getEncryptString: string }>
    return response.data?.getEncryptString
  }

  return { getEncryptString } as const
}
