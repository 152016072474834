import { format, addDays } from 'date-fns'

import getSetupAnketFeedback from './api/getSetupAnketFeedback'
import { getWeeksByDay } from './utils/weekday'

export async function calcAnketValue(weekDate: string, setupStartedAt: string): Promise<number> {
  const baseWeekDate = new Date(weekDate)
  const setupStartedDate = new Date(setupStartedAt || '')
  const week = getWeeksByDay(baseWeekDate, setupStartedDate)

  let total = 0
  for (let i = 0; i < week; i++) {
    const baseDate = format(addDays(setupStartedDate, 7 * (i + 1)), 'yyyy-MM-dd')
    const resGetSetupAnketFeedback = await getSetupAnketFeedback(baseDate, setupStartedAt)
    if (resGetSetupAnketFeedback) {
      const subTotal = resGetSetupAnketFeedback.total
      total += subTotal
    }
  }

  return total
}
