import * as React from 'react'

import { Font, UsefulCard, GhostArrayData } from '../atoms/index'

type Props = {
  solution: string
  actions: string[]
  anti: string
  caveats: string[]
  dataType: 'actions' | 'caveats'
  ownStyles?: React.CSSProperties
  noBack?: boolean
}

export const GhostActionOrEffect: React.FC<Props> = ({
  solution,
  actions,
  anti,
  caveats,
  dataType,
  ownStyles,
  noBack,
}) => {
  return (
    <>
      <UsefulCard
        backgroundColor={noBack ? 'white' : 'lightBlue'}
        borderRadius="none"
        ownStyles={{ padding: noBack ? 0 : '20px 9px', ...ownStyles }}
      >
        <Font fontSize={14} bold={true} ownStyles={{ marginBottom: 10 }}>
          {dataType === 'actions' ? <>オバケを退治するアクション例</> : <>オバケがいなくなったときの副作用</>}
        </Font>
        <Font fontSize={14} ownStyles={{ marginBottom: 10 }}>
          {dataType === 'actions' ? solution : anti}
        </Font>
        {dataType === 'actions' ? (
          <GhostArrayData items={actions} fieldType="scenes" fontSize={14} />
        ) : (
          <GhostArrayData items={caveats} fieldType="scenes" fontSize={14} />
        )}
      </UsefulCard>
    </>
  )
}
