import React, { useCallback, useContext, useEffect, useState } from 'react'

// import * as constants from 'assets/constants'
import { makeStyles } from '@material-ui/core/styles'
import { render } from 'react-dom'

import { TeamStorageContext } from 'pages/onboarding/pages/timeline/hooks/teamStorage'
import { OnboardingPostFile } from 'utils/generated'

import previewCloseIcon from '../../assets/fileTypeIcons/previewCloseIcon.svg'

import { CustomVideoPlayer } from './CustomVideoPlayer'

type MediaPreview = {
  type: 'img' | 'video'
  src: string
}

export const usePreview = () => {
  const [preview, setPreview] = useState<OnboardingPostFile>()

  const { determineFileType } = useContext(TeamStorageContext)
  const previewInfo = useCallback(
    (file: OnboardingPostFile): MediaPreview | undefined => {
      const type = determineFileType(file.fileName)
      const src = file.signedUrl
      if ((type === 'img' || type === 'video') && src) {
        return { type, src }
      }
    },
    [determineFileType]
  )

  useEffect(() => {
    if (!preview) return
    const container = document.createElement('div')
    document.body.appendChild(container)
    const prv = previewInfo(preview)
    prv && render(<PreviewBox preview={prv} onClose={() => setPreview(undefined)} />, container)
    return () => {
      document.body.removeChild(container)
    }
  }, [preview, previewInfo])

  return {
    setPreview,
  }
}

type Props = {
  preview: MediaPreview
  onClose: () => void
}

export const PreviewBox: React.FC<Props> = ({ preview, onClose }) => {
  const classes = useStyles()

  const [show, setShow] = useState(true)

  const close = () => {
    setShow(false)
    onClose()
  }

  if (!show) return <></>
  return (
    <div className={classes.root} onClick={close}>
      <div className={classes.container} onClick={(e) => e.stopPropagation()}>
        <img loading="lazy" src={previewCloseIcon} alt="close" className={classes.previewCloseIcon} onClick={close} />
        {preview.type === 'img' ? (
          <img loading="lazy" src={preview.src} className={classes.img} alt="img" />
        ) : (
          <CustomVideoPlayer src={preview.src} />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    width: 'max(50vw, 330px)',
    height: 'clamp(400px, 55vh, 800px)',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewCloseIcon: {
    width: 48,
    height: 48,
    position: 'absolute',
    display: 'block',
    top: -52,
    right: -4,
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
}))
