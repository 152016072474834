import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'

import { PeopleIcon, PeopleNonIcon, GraphIcon, GraphNonIcon, CheckedIcon, CheckedNonIcon } from '../assets'

import { constants } from 'assets'
import { theme } from 'assets/theme'

export type FilterType = 'GENERAL' | 'GRAPH'
export type FilterProps = {
  isNonUse: boolean
  type: FilterType
  selectedTeamId?: string
  isHidden?: boolean
}

type Props = {
  filter: FilterProps
  setFilter: React.Dispatch<React.SetStateAction<FilterProps>>
  statusChangeList: boolean
}

export const Filter: React.FC<Props> = (props) => {
  const { filter, setFilter, statusChangeList } = props
  const classes = useStyles(props)

  const isMdScreenUp = useMediaQuery(theme.breakpoints.up('md'))

  const switchFilter = React.useCallback(() => {
    setFilter({ ...filter, isNonUse: !filter.isNonUse })
  }, [filter, setFilter])

  return (
    <div className={isMdScreenUp ? classes.filterContainerPC : classes.filterContainerSP}>
      <div className={classes.activeContainer}>
        <div className={classes.active} onClick={switchFilter}>
          <div className={classes.activeLabel}>終了チームを表示</div>
          {filter.isNonUse ? <CheckedIcon /> : <CheckedNonIcon />}
        </div>
      </div>
      {!statusChangeList && (
        <div className={classes.typeContainer}>
          <div
            className={classNames(classes.type, filter.type === 'GENERAL' && classes.typeActive, classes.leftIcon)}
            onClick={() => setFilter({ ...filter, type: 'GENERAL' })}
          >
            {filter.type === 'GENERAL' ? <PeopleIcon /> : <PeopleNonIcon />}
          </div>
          <div
            className={classNames(classes.type, filter.type === 'GRAPH' && classes.typeActive, classes.rightIcon)}
            onClick={() => setFilter({ ...filter, type: 'GRAPH' })}
          >
            {filter.type === 'GRAPH' ? <GraphIcon /> : <GraphNonIcon />}
          </div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filterContainerPC: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px',
  },

  filterContainerSP: {
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    gap: '16px',

    '&::-webkit-scrollbar': {
      display: 'block',
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E9E9E9',
      borderRadius: 10,
    },
  },

  // Actives
  activeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '@media (hover: hover)': {
      '&:hover': {
        opacity: '75%',
      },
    },
  },
  activeLabel: {
    marginRight: 8,
    fontSize: '12px',
  },

  // 表示件数選択
  displayOptionButton: {
    width: '30px',
    height: '24px',
    fontWeight: 'bold',
    border: '1px solid',
    borderRadius: 50,
    borderColor: constants.COLOR_TEAMBUILDING_PRIMARY,

    '@media (hover: hover)': {
      '&:hover': {
        opacity: '75%',
      },
    },
  },
  unselectedOptionButton: {
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    backgroundColor: constants.COLOR_WHITE,
    '&:hover': {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      backgroundColor: constants.COLOR_WHITE,
    },
  },
  selectedOptionButton: {
    color: constants.COLOR_WHITE,
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    '&:hover': {
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },

  // Types
  typeContainer: {
    display: 'flex',
    height: 24,
    width: 80,
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: constants.COLOR_TEAMBUILDING_PRIMARY,
  },

  type: {
    height: '100%',
    width: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',

    '@media (hover: hover)': {
      '&:hover': {
        opacity: '75%',
      },
    },
  },

  leftIcon: {
    borderRadius: '50px 0 0 50px',
  },
  rightIcon: {
    borderRadius: '0 50px 50px 0',
  },
  typeActive: {
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
  },
}))
