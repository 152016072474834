import * as React from 'react'

import { useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'

export type ReactionProps = {
  postId: string
  teamId: string
  isLiked: boolean | null | undefined
  ttRef?: React.MutableRefObject<HTMLElement | undefined>
  ttOnLike?: boolean
  next?: () => void
  clearRefStyle?: () => void
}

export const reactionNumbers = [...Array(13).keys()] // Number of icons

export const useReactionSelections = ({ postId, teamId, isLiked, ttOnLike, next, clearRefStyle }: ReactionProps) => {
  const [open, setOpen] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const { likeToggleHandler } = useOnboardingPostActions()

  React.useEffect(() => {
    if (isLiked === null) {
      setVisible(true)
    }
  }, [isLiked])

  const handleToggle = React.useCallback(() => setOpen((prevOpen) => !prevOpen), [])

  const handleClose = React.useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }, [])

  const handleReactionClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setVisible(false)
      handleClose(event)

      if (ttOnLike) {
        clearRefStyle && clearRefStyle()
        next && next()
      } else {
        await likeToggleHandler(postId, teamId, Number(event.currentTarget.getAttribute('data-icon')))
      }
    },
    [likeToggleHandler, postId, teamId, handleClose, ttOnLike, next, clearRefStyle]
  )

  return { open, visible, anchorRef, reactionNumbers, handleToggle, handleClose, handleReactionClick }
}
