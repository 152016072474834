import * as React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

import * as constants from '../../assets/constants'

interface IOwnProps {
  karteNo: string
}

const styles = StyleSheet.create({
  header: {
    height: 48,
    alignContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: constants.COLOR_MAIN,
  },
  header__main: {
    fontSize: 20,
    fontWeight: 'bold',
    color: constants.COLOR_WHITE,
    marginHorizontal: 'auto',
    marginLeft: 0,
    paddingLeft: 24,
  },
  header__sub: {
    fontSize: 16,
    color: constants.COLOR_WHITE,
    marginHorizontal: 'auto',
    marginRight: 0,
    paddingRight: 24,
  },
})

export const Header: React.FC<IOwnProps> = ({ karteNo }) => {
  return (
    <View style={styles.header}>
      <Text style={styles.header__main}>リーダー診断結果</Text>
      <Text style={styles.header__sub}>診断結果 カルテNo. {karteNo}</Text>
    </View>
  )
}
