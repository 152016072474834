import * as React from 'react'

import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'

interface IOwnProps {
  first: string
  second: string
  third: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={classes.main}>
      <img
        src={
          process.env.PUBLIC_URL +
          `/assets/svg/karte/${
            props.first === '有能感' && props.second === '自律性'
              ? 'circle_2_1.svg'
              : props.first === '有能感' && props.second === '関係性'
              ? 'circle_2_2.svg'
              : props.first === '自律性' && props.second === '有能感'
              ? 'circle_2_3.svg'
              : props.first === '自律性' && props.second === '関係性'
              ? 'circle_2_4.svg'
              : props.first === '関係性' && props.second === '有能感'
              ? 'circle_2_5.svg'
              : 'circle_2_6.svg'
          }`
        }
        alt={'circle'}
      />
      <div className="__circleLabel __first">{props.first}</div>
      <div className="__circleLabel __second">{props.second}</div>
      <div className="__circleLabel __third">{props.third}</div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      position: 'relative',

      '& img': {
        width: '100%',
      },
      '& .__circleLabel': {
        position: 'absolute',
        color: '#fff',
        width: 100,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',

        '&.__first': {
          top: 'calc(100% - 85%)',
          left: 'calc(50% - 55px)',
        },
        '&.__second': {
          top: 'calc(100% - 40%)',
          left: 'calc(75% - 35px)',
        },
        '&.__third': {
          top: 'calc(100% - 40%)',
          left: 'calc(15% - 40px)',
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
