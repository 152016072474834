import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { NoStyleButton } from 'components/NoStyleButton'

import CheckedAlert from '../assets/checkedAlert.svg'
import { constants } from 'assets'

type StatusErrorDialogProps = {
  isShowError: boolean
  setIsShowError: React.Dispatch<React.SetStateAction<boolean>>
  teamNames: string[]
}

export const StatusErrorDialog: React.FC<StatusErrorDialogProps> = ({ isShowError, setIsShowError, teamNames }) => {
  const classes = useStyles()
  if (!isShowError) return null

  const handleClose = () => {
    setIsShowError(false)
  }

  return (
    <div className={classes.statusContainer}>
      <img src={CheckedAlert} className={classes.checkedIcon} alt="check icon" />
      <div className={classes.text}>
        下記チームは更新に失敗しました。
        <br />
        時間をおいて再度お試しください。
      </div>
      <ul className={classes.teamList}>
        {teamNames.map((teamName, index) => (
          <li key={index}>{teamName}</li>
        ))}
      </ul>
      <NoStyleButton type="button" onClick={() => handleClose()} className={classes.closeButton}>
        閉じる
      </NoStyleButton>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 1000,
      gap: 16,
      width: 260,
      padding: 16,
      right: 0,
      bottom: 200,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_DANGER,
      fontWeight: 'bold',
      boxShadow: `0px 1px 6px 0px #00000029`,
      height: 300,
      overflowY: 'auto',

      [theme.breakpoints.down('xs')]: {
        gap: 12,
        width: 220,
        padding: 12,
      },
    },
    checkedIcon: {
      width: 24,
      height: 24,
    },
    text: {
      display: 'flex',
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    teamList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      fontSize: 12,
      width: '100%',

      '& li': {
        overflowWrap: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: 1.7,
      },
    },
    closeButton: {
      backgroundColor: constants.COLOR_DANGER,
      color: constants.COLOR_WHITE,
      fontSize: 14,
      fontWeight: 600,
      padding: '8px 16px',
      width: 80,
      height: 40,
      borderRadius: 20,
      marginTop: 'auto',
    },
  }),
  { name: 'StatusErrorDialog' }
)
