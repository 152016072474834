import { useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import * as s3 from '../../../apis/s3'
import * as utils from '../../../apis/utils'

// -----------------------------
// interface
// -----------------------------
export type IuseHeroProps = {
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCloseFile: () => void
  getFile: () => string | undefined
  resizeImage: string | null
  setUpdateHero: () => void
}

export type IImage = {
  file: Blob | null
  url: string | ArrayBuffer | null | undefined
}

// -----------------------------
// hooks
// -----------------------------
export const useHero = (): IuseHeroProps => {
  const [resizeImage, setRisezeImage] = useState<string | null>(null)
  const modalSelector = useSelector((state: RootStateType) => state.hint.ui)
  const hintSelector = useSelector((state: RootStateType) => state.hint)

  const WIDTH = 1000
  const HEIGHT = 1000
  const MAX_SIZE = 600000

  /**
   * ファイルをセットする
   */
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files
    setRisezeImage(null)

    if (!files) return

    const file = files[0]
    if (!file) return
    if (!file?.type?.match(/^image\/(png|jpeg|gif)$/)) return

    const reader = new FileReader()

    reader.onload = () => {
      const imgReader = new Image()
      imgReader.src = URL.createObjectURL(file)

      imgReader.onload = async () => {
        const canvas = document.createElement('canvas')
        canvas.width = WIDTH
        canvas.height = HEIGHT
        const ctx = canvas.getContext('2d')

        const imgWidth = imgReader.width, //画像の元の幅を取得
          imgHeight = imgReader.height, //画像の元の高さを取得
          imgRate = imgWidth / imgHeight //画像の比率を取得
        let imgPos = 0 //Canvas上での画像の位置を初期化

        if (ctx) {
          //画像が横長のとき
          if (imgRate >= 1) {
            imgPos = (WIDTH - WIDTH * imgRate) / 2
            ctx.drawImage(imgReader, imgPos, 0, WIDTH * imgRate, WIDTH)

            //画像が縦長のとき
          } else {
            imgPos = (HEIGHT - HEIGHT / imgRate) / 2
            ctx.drawImage(imgReader, 0, imgPos, HEIGHT, HEIGHT / imgRate)
          }
        }

        // testGeneratedDownloadAnker(canvas.toDataURL('image/jpeg'), 'output.jpg');

        // 縮小後の画像情報をStateに詰める
        const resizeSize = utils.base64ToFile(canvas.toDataURL('image/jpeg'))

        let uploadBlob = resizeSize

        // 上限サイズより大きい場合は上限サイズを切るように縮小後の画像の容量を落とす
        if (MAX_SIZE <= resizeSize['size']) {
          const capacityRatio = MAX_SIZE / resizeSize['size']
          const processingBinary = canvas.toDataURL('image/jpeg', capacityRatio)
          uploadBlob = utils.base64ToFile(processingBinary)
        }

        const imageUrl = await s3.S3put(file, uploadBlob, modalSelector.modal.id)

        if (imageUrl) {
          setRisezeImage(imageUrl)
        }
      }
    }
    reader.readAsDataURL(file)
  }

  /**
   * ✗ボタンを押して閉じる
   */
  const onCloseFile = useCallback((): void => {
    setRisezeImage(null)
  }, [])

  /**
   * stateのファイルを取得する
   */
  const getFile = (): string | undefined => {
    if (resizeImage) {
      return resizeImage
    }
    return
  }

  /**
   * UpdateのときにHero画像が存在すればセットする
   */
  const setUpdateHero = (): void => {
    if (modalSelector.modal.status === 'UPDATE' && hintSelector.hint.imageUrl) {
      setRisezeImage(hintSelector.hint.imageUrl)
    }
  }

  return {
    onChangeFile,
    onCloseFile,
    getFile,
    resizeImage,
    setUpdateHero,
  }
}
