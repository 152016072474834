import * as React from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { createTeamBuildingReview, getTeamBuildingReviewsLimit, useTeamBuildingReviews } from 'services/api/review'

import { DescriptionTools, LoadingCircular } from 'pages/teams/components'
import { useReviewPoints } from 'pages/teams/pages/_tools/review/components/hooks/useReviewPoints'
import { TeamBuildingReview, TeamBuildingReviewStatus } from 'utils/generated'

import { GraphBox } from './components/GraphBox'

import { Pages, constants } from 'assets'
import { replacePathParams } from 'assets/history'

dayjs.extend(utc)
dayjs.extend(timezone)

export const Reflection: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { goalPoints, codeOfConductPoints } = useReviewPoints({ isDashboard: true })

  const { teamBuildingReviews: reflectionData, loading: isLoadingReflectionData } = useTeamBuildingReviews(teamId)

  const [latestReflectionCompleted] =
    reflectionData?.filter((review) => review.status === TeamBuildingReviewStatus.Completed) ?? []

  const openReflectionPage = React.useCallback(
    (reviewId: string) => {
      window.open(
        replacePathParams(Pages.TeamsReview, {
          teamId,
          reviewId,
        }),
        '_blank'
      )
    },
    [teamId]
  )

  const handleStartReflection = React.useCallback(async () => {
    // リフレクションが開始済みかを判定するために、最新データを取得する
    const res = await getTeamBuildingReviewsLimit(teamId, 1)

    if (!res || res.length === 0 || res[0].status === TeamBuildingReviewStatus.Completed) {
      const createdTeamBuildingReview: TeamBuildingReview = await createTeamBuildingReview({ teamId })
      openReflectionPage(createdTeamBuildingReview.id)
    } else {
      openReflectionPage(res[0].id)
    }
  }, [openReflectionPage, teamId])

  if (isLoadingReflectionData) return <LoadingCircular loading />

  return (
    <>
      <DescriptionTools
        title="キックオフが完了したら定期的に振り返りましょう。"
        recommendedTime="（推奨時間：30分）"
        description={
          'キックオフが完了したら、キックオフで設定した目標や行動規範について、定期的にチームで\n現状について振り返りましょう。具体的に数字で振り返るので、互いの認識の違いを\n通じてより深い対話につながります。'
        }
        reflectionNextAt={latestReflectionCompleted ? latestReflectionCompleted.nextAt ?? '未設定' : undefined}
        buttonLabel="リフレクションを始める"
        handleOpenTool={handleStartReflection}
        hasMsg={!latestReflectionCompleted}
      />

      {latestReflectionCompleted ? (
        <div className={classes.graphContainer}>
          <div>
            <div className={classes.categoryName}>チーム目標</div>
            <GraphBox pointData={goalPoints} />
          </div>

          <div className={classes.graphSet}>
            <div className={classes.categoryName}>行動規範</div>
            {codeOfConductPoints.map((pointData) => (
              <GraphBox key={pointData.label} pointData={pointData} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    categoryName: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    graphContainer: {
      marginTop: 32,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        padding: '24px 16px',
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    graphSet: {
      marginTop: 24,
    },
  }),
  { name: 'Reflection' }
)
