import React from 'react'

import { Avatar } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import closeSvg from 'pages/teams/assets/svg/close.svg'
import { useTeamMember } from 'pages/teams/hooks'
import { TeamKarte } from 'utils/generated'

import { ButtonToBack } from '../../components/ButtonToBack'
import { getKarteResult } from '../../utils'
import { KarteResults } from '../result/KarteResults'

import { COLOR_MAIN_NEW, COLOR_GRAY, COLOR_TEAMBUILDING_NEUTRAL_200 } from 'assets/constants'

export const HomeKarteResults: React.FC<{
  teamKarte: TeamKarte
  onBack: () => void
  isOnDashboard?: boolean
  dataCount?: number
}> = ({ teamKarte, onBack, isOnDashboard, dataCount }) => {
  const classes = useStyles()

  const results = getKarteResult(teamKarte.data)
  const { teamMember, loading } = useTeamMember(`${teamKarte.teamId}-${teamKarte.userId}`)
  const isXsDown = useCustomMediaQuery('down', 'xs')

  const nameShortened = (name: string | undefined) => {
    if (!name) {
      return ''
    }

    const limit = isXsDown ? 5 : 18
    return name.length > limit ? `${name.slice(0, limit)}...` : name
  }

  if (!results || loading) {
    return <></>
  }

  return (
    <div className={isOnDashboard ? classes.dashboardRoot : classes.root}>
      <div className={`${classes.header} ${isOnDashboard ? classes.dashboardHeader : ''}`}>
        <div className={classes.headerLeft}>
          <Avatar
            className={isOnDashboard ? classes.dashboardAvatar : classes.avatar}
            src={teamMember?.user?.imageUrl ?? undefined}
          />
          <div className={classes.title}>
            {isOnDashboard ? nameShortened(teamMember?.fullName) : teamMember?.fullName}さんの{isOnDashboard && '最新'}
            診断結果
            {isOnDashboard
              ? `\n第${dataCount ?? '_'}回　${format(new Date(teamKarte.createdAt), 'yyyy/MM/dd')}`
              : `（${format(new Date(teamKarte.createdAt), 'yyyy/MM/dd')}）`}
          </div>
        </div>

        <div className={classes.headerRight}>
          {isOnDashboard ? (
            <NoStyleButton className={classes.close} type="button" onClick={onBack}>
              <img src={closeSvg} alt="close icon" />
            </NoStyleButton>
          ) : (
            <ButtonToBack onClick={onBack} />
          )}
        </div>
      </div>

      {isOnDashboard ? (
        <div className={isOnDashboard ? classes.resultWrapper : undefined}>
          <div className={isOnDashboard ? classes.resultBox : undefined}>
            <KarteResults teamId={teamKarte.teamId} results={results} hideBackButton />
          </div>
        </div>
      ) : (
        <KarteResults teamId={teamKarte.teamId} results={results} hideBackButton handleOnBack={onBack} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 24,
    backgroundColor: '#fff',
  },
  dashboardRoot: {
    padding: '16px 16px 0',
    height: '100%',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  dashboardHeader: {
    marginBottom: 16,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },

  avatar: {
    marginRight: 8,
  },
  dashboardAvatar: {
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginRight: 8,
    },
  },

  title: {
    fontWeight: 'bold',
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },

  toBack: {
    color: COLOR_MAIN_NEW,
    display: 'block',
    fontSize: 10,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  headerRight: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },

  close: {
    display: 'grid',
    placeContent: 'center',
    width: 40,
    height: 40,
  },

  resultWrapper: {
    height: 'calc(100% - 56px)', // header の高さを引いて modal の高さと一致させる
    overflow: 'auto',
    overscrollBehavior: 'contain', // スクロールの伝播を抑止
  },
  resultBox: {
    marginBottom: 16,
    padding: '24px 8px',
    backgroundColor: COLOR_GRAY,
    border: `1px solid ${COLOR_TEAMBUILDING_NEUTRAL_200}`,
  },
}))
