export const movie_sorce = [
  {
    id: 1,
    movieOwnerId: 'COMMON',
    title: 'チュートリアル動画',
    role: 'MEMBER',
    src: 'https://player.vimeo.com/video/809452047?h=ec4f8c9ae6',
  },
  {
    id: 2,
    movieOwnerId: 'COMMON',
    title: 'オンボーディングとは',
    role: 'MEMBER',
    src: 'https://player.vimeo.com/video/805861352?h=1494008dc5',
  },
  {
    id: 3,
    movieOwnerId: 'COMMON',
    title: 'オンボーディングのご紹介',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/648475446?h=60074935ec',
  },
  {
    id: 4,
    movieOwnerId: 'COMMON',
    title: 'ツール活用のポイント',
    secondTitle: '（育成者向け）',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/809631631?h=22dff966ab',
  },
  {
    id: 5,
    movieOwnerId: 'COMMON',
    title: 'かかわり方のポイント',
    secondTitle: '【Iメッセージ】',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/570086726?h=c7458b877b',
  },
  {
    id: 6,
    movieOwnerId: 'COMMON',
    title: 'かかわり方のポイント',
    secondTitle: '【期待を伝える】',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/570088905?h=8561c02667',
  },
  {
    id: 7,
    movieOwnerId: 'COMMON',
    title: 'かかわり方のポイント',
    secondTitle: '【傾聴】',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/596339014?h=8a247f88b3',
  },
  {
    id: 8,
    movieOwnerId: 'COMMON',
    title: 'かかわり方のポイント',
    secondTitle: '【承認】',
    role: 'SUPPORTER',
    src: 'https://player.vimeo.com/video/642214365?h=d7b15e88b5',
  },
]
