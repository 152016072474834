import { TeamMember, TeamMemberRole } from 'utils/generated'

export const getTeamMemberRole = (teamMember?: TeamMember): TeamMemberRole | undefined => {
  if (teamMember?.role.includes(TeamMemberRole.Admin)) {
    return TeamMemberRole.Admin
  } else if (teamMember?.role.includes(TeamMemberRole.Leader)) {
    return TeamMemberRole.Leader
  } else if (teamMember?.role.includes(TeamMemberRole.Member)) {
    return TeamMemberRole.Member
  }
}

export const isAdmin = (teamMember?: TeamMember): boolean => {
  if (teamMember?.role.includes(TeamMemberRole.Admin)) {
    return true
  } else {
    return false
  }
}

export const isLeader = (teamMember?: TeamMember): boolean => {
  if (teamMember?.role.includes(TeamMemberRole.Leader)) {
    return true
  } else {
    return false
  }
}

export const isMember = (teamMember?: TeamMember): boolean => {
  if (teamMember?.role.includes(TeamMemberRole.Member)) {
    return true
  } else {
    return false
  }
}
