import React from 'react'
import { useParams } from 'react-router-dom'

import { TeamsHooksContext } from '../contexts/HooksContextTeams'

interface Props {
  children: React.ReactNode
}

export const TeamAuth: React.FC<Props> = (props) => {
  const { team, setTeamId, errorTeam } = React.useContext(TeamsHooksContext)

  const { teamId } = useParams<{ teamId: string }>()

  React.useEffect(() => {
    if (teamId) {
      setTeamId(teamId)
    }
  }, [teamId, setTeamId])

  if (errorTeam) {
    return (
      <>
        {errorTeam
          .reduce((acc: string[], cur) => {
            if (cur.message) {
              return [...acc, cur.message]
            } else {
              return acc
            }
          }, [])
          .map((message, index) => {
            return <div key={`team-auth-error-${index}`}>{message}</div>
          })}
      </>
    )
  }

  if (team) {
    return <>{props.children}</>
  } else {
    return <>loading...</>
  }
}
