import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { HooksContext } from 'pages/teams/contexts'

import { Flow } from './Flow'

import * as constants from 'assets/constants'

export const SideBar: React.FC = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { teamBuilding } = React.useContext(HooksContext)
  const [isDisplayed, setIsDisplayed] = React.useState(false)

  React.useEffect(() => {
    if (
      !teamBuilding || // Required for initial display
      (teamBuilding?.status &&
        (teamBuilding.status !== 'COMPLETED' || /.+\/tools\/building\/subs\/?$/.test(window.location.pathname)))
    ) {
      setIsDisplayed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding?.status])

  return (
    <div style={{ display: isMdUp && isDisplayed ? 'flex' : 'block' }}>
      {isMdUp && isDisplayed ? (
        <div className={classes.sideBar}>
          <div className={classes.container}>
            <div className={classes.logo}>
              <img src={process.env.PUBLIC_URL + '/assets/svg/teams/logo_kickoff.svg'} alt="cocolabo-kickoff" />
            </div>
            <Flow />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div style={{ flex: isMdUp && isDisplayed ? 1 : undefined }}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  sideBar: {
    flexShrink: 0,
    width: 340,
    marginTop: -15,
  },
  container: {
    position: 'fixed',
    padding: 24,
    width: 340,
    height: '100vh',
    backgroundColor: constants.COLOR_WHITE,
    overflow: 'auto',
  },
  logo: {
    marginBottom: 40,
    width: 140,
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
  },
}))
