import * as React from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { UsefulCard } from '../atoms'
import { ProgressItem } from '../modules'

type Props = {
  step: number
}

export const ProgressBoard: React.FC<Props> = ({ step }) => {
  const itemContents = ['オバケ選択', 'メンバーが見かけたオバケ確認', '対話', '今後のアクション']
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <UsefulCard
      backgroundColor="white"
      ownStyles={{
        padding: isMdUp ? '40px 245px 16px' : '24px 16px',
        margin: '40px 0 24px',
        fontSize: isMdUp ? 14 : 12,
      }}
    >
      {itemContents.map((item, index, array) => (
        <ProgressItem key={item + index + step} step={step} order={index + 1} length={array.length} content={item} />
      ))}
    </UsefulCard>
  )
}
