import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetSelfCheckComments } from './graphql'

const getSelfcheckComments = async () => {
  const response: any = await API.graphql(graphqlOperation(QueryGetSelfCheckComments))

  const { getSelfCheckCommentList } = response.data

  return getSelfCheckCommentList
}

export default getSelfcheckComments
