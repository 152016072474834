import { GhostResearch, TeamMember } from 'utils/generated'

export type MemberWithNextActions = GhostResearch & { memberName: string }

export const combineMembersAndNextActions = (
  nextActionList: GhostResearch[],
  teamMembers: TeamMember[]
): MemberWithNextActions[] => {
  return nextActionList
    .map((action) => {
      const matchingMember = teamMembers.find((member) => member.id === action.userId)
      const memberName = matchingMember?.fullName || ''
      return { ...action, memberName }
    })
    .sort((a, b) => a.memberName.localeCompare(b.memberName, 'ja')) // メンバー名 50音順にソート
}
