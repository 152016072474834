import * as React from 'react'

import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { Controller, useForm, useWatch } from 'react-hook-form'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { Calendar } from './step3/Calendar'

import { CalendarSvg } from 'assets/svg'

type Props = {
  title: string
  subtitle: string
}
type Input = {
  start_at: string
  end_at: string
}
const isAfter = (date: dayjs.Dayjs) => {
  return date.isAfter(dayjs().startOf('day'))
}

const CalenderModal: React.FC<{
  selectedDate: dayjs.Dayjs | null
  onSelectDate(selectedDate: dayjs.Dayjs): void
  setShowModal(bool: boolean): void
}> = ({ selectedDate, onSelectDate, setShowModal }) => {
  const classes = useStyles()

  return (
    <div className={classes.calendarModal}>
      <div style={{ margin: '0 auto' }} onFocus={() => setShowModal(true)}>
        <Calendar selectedDate={selectedDate} onSelectDate={onSelectDate} isAvailableDate={isAfter} />
      </div>
    </div>
  )
}

export const Step3Select: React.FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles()
  const { control } = useForm<Input>()

  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const { start_at, end_at } = useWatch<Input>({ control })
  const { setNextAt } = React.useContext(TeamBuildingReviewHooksContext)
  const onSelectDate = (date: dayjs.Dayjs) => {
    setSelectedDate(date)
    setShowModal(false)
  }
  const outerClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    if (showModal) {
      setShowModal(false)
    }
  }
  React.useEffect(() => {
    if (selectedDate && start_at && end_at) {
      if (start_at >= end_at) {
        setNextAt(undefined)
        return
      }
      setNextAt(`${selectedDate?.format('YYYY-MM-DD')} ${start_at} ~ ${end_at}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_at, selectedDate, start_at])
  return (
    <div className={classes.root} onClick={outerClick}>
      <ContentHead title={title} subtitle={subtitle} steps={2} currentStep={2} />
      <ContentSection>
        <div className={classes.formRoot}>
          <div className={classes.calendarWrap}>
            <Content>
              <div style={{ margin: '0 auto' }}>
                <Calendar selectedDate={selectedDate} onSelectDate={onSelectDate} isAvailableDate={isAfter} />
              </div>
            </Content>
          </div>
          <div style={{ position: 'relative' }}>
            <div style={{ display: `${showModal ? 'block' : 'none'}`, position: 'absolute', zIndex: 100, top: 72 }}>
              <CalenderModal selectedDate={selectedDate} onSelectDate={onSelectDate} setShowModal={setShowModal} />
            </div>
            <Content useMobileOnly>
              <div
                onClick={() => setShowModal(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: 8,
                  height: 50,
                  fontSize: 14,
                  padding: '0 16px',
                  marginBottom: 16,
                  background: '#FFFFFF',
                }}
              >
                <div>{selectedDate?.format('YYYY年 MM月DD日 ddd曜日')}</div>
                <img src={CalendarSvg} alt="calendar" className={classes.calendarIcon} />
              </div>
              <div className={classes.times}>
                <div className={classes.time}>
                  <span>開始時刻</span>
                  <Controller
                    name={'start_at'}
                    control={control}
                    defaultValue={'09:00'}
                    value={start_at}
                    render={({ onChange, value }) => (
                      <TextField
                        type="time"
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        inputProps={{
                          style: { padding: '8px', textAlign: 'right', background: '#FFFFFF' },
                        }}
                      />
                    )}
                  />
                </div>
                <div className={classes.time}>
                  <div className={classes.dash}>-</div>
                </div>
                <div className={classes.time}>
                  <span>終了時刻</span>
                  <Controller
                    name={'end_at'}
                    control={control}
                    defaultValue={'10:00'}
                    value={end_at}
                    render={({ onChange, value }) => (
                      <TextField
                        type="time"
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        inputProps={{
                          style: { padding: '8px', textAlign: 'right', background: '#FFFFFF' },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </Content>
          </div>
        </div>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  formRoot: {
    display: 'flex',
    gap: '16px',
    '@media (max-width: 632px)': {
      display: 'block',
    },
  },
  calendarModal: {
    margin: 16,
    borderRadius: 8,
    fontSize: 14,
    padding: '0 16px 16px',
    background: '#FFFFFF',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    '@media (min-width: 633px)': {
      display: 'none',
    },
  },
  calendarWrap: {
    display: 'none',
    maxWidth: '320px',
    marginBottom: 0,
    '@media (min-width: 633px)': {
      display: 'block',
    },
  },
  calendarIcon: {
    '@media (min-width: 633px)': {
      display: 'none',
    },
  },
  times: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontWeight: 'bold',
    maxWidth: 340,
    '@media (max-width: 632px)': {
      width: '100%',
      fontSize: 12,
      justifyContent: 'space-between',
    },
  },
  time: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '8px',
    '@media (max-width: 632px)': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '@media (max-width: 375px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  dash: {
    textAlign: 'center',
    width: '1em',
    '@media (min-width: 633px)': {
      paddingTop: '1em',
      marginTop: 8,
    },
    '@media (max-width: 375px)': {
      paddingTop: '1em',
      marginTop: 8,
    },
  },
}))
