import { useState } from 'react'

import { GraphQLResult } from '@aws-amplify/api'

import { useCreateId } from 'hooks/createId'
import { mutationCreateKarteMember } from 'pages/hataraku/pages/questionnaire/api'
import { IKarteMemberQuestionnaireAnswerSet } from 'pages/hataraku/pages/questionnaire/interface'
import { StringKeyObject } from 'pages/hataraku/pages/results/interface'
import { forms } from 'pages/hataraku/pages/static'
import { ERROR_MESSAGES_FOR_HATARAKU, isErrorWithGraphQLErrors } from 'pages/hataraku/utils/error'
import { Karte } from 'utils/generated'

export const useCreateHataraku = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<GraphQLResult['errors'] | null>(null)
  const { buildNanoidId: buildHatarakuId } = useCreateId()

  const createHataraku = async ({
    id,
    leaderId,
    answers,
  }: {
    id: string
    leaderId: string
    answers: IKarteMemberQuestionnaireAnswerSet
  }) => {
    let response: Karte | null = null
    setIsLoading(true)
    setErrors(null)
    const data = buildHatarakuData(answers)

    const MAX_RETRY_COUNT = 3

    // 3回までリトライする
    for (let i = 0; i < MAX_RETRY_COUNT; i++) {
      try {
        response = await mutationCreateKarteMember({
          id: i === 0 ? id : buildHatarakuId(), // 1回目は引数に受け取ったidをそのまま利用し、2回目以降は新しいIDを生成
          leaderId,
          data: JSON.stringify(data),
        })
        if (response) break
      } catch (e) {
        if (isErrorWithGraphQLErrors(e)) {
          setErrors(
            e.errors?.map((error) => ({
              message: error.errorType.includes('ConditionalCheckFailedException')
                ? ERROR_MESSAGES_FOR_HATARAKU.CREATE_FAILED
                : error.message,
            }))
          )
        } else {
          setErrors([{ message: ERROR_MESSAGES_FOR_HATARAKU.UNEXPECTED }])
        }
      }
    }

    setIsLoading(false)

    return response
  }

  return {
    isLoading,
    errors,
    createHataraku,
  }
}

export const buildHatarakuData = (answers: IKarteMemberQuestionnaireAnswerSet) => {
  const calculable: any[] = []
  const simple = []

  const allQuestions: StringKeyObject = {}

  for (let formIndex = 0; formIndex < forms.length; formIndex++) {
    const form = forms[formIndex]
    if (form.weight) {
      const questions = []

      for (let fieldIndex = 0; fieldIndex < form.fields.length; fieldIndex++) {
        const field = form.fields[fieldIndex]
        const value = answers[field.group][field.key]

        const prevData = allQuestions[field.group]
          ? allQuestions[field.group].data
            ? [...allQuestions[field.group].data]
            : []
          : []
        allQuestions[field.group] = {
          weight: form.weight,
          data: [
            ...prevData,
            {
              key: field.key,
              label: field.label,
              weight: field.weight || 999,
              value,
            },
          ],
        }

        questions.push({
          key: field.key,
          weight: field.weight || 999,
          value,
        })
      }
    } else {
      if (form.group) {
        simple.push({
          group: form.group,
          values: answers[form.group],
        })
      }
    }
  }

  for (const k of Object.keys(allQuestions)) {
    calculable.push({
      group: k,
      weight: allQuestions[k].weight || 999,
      questions: allQuestions[k].data,
    })
  }

  return {
    calculable,
    simple,
  }
}
