import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { AnyAction } from 'typescript-fsa'

import { RootState } from '../../../../stores/reducers'

import action from './action'
import { karteLeaderStore } from './api'

const storeEpic: Epic<AnyAction, AnyAction, RootState> = (action$, store) =>
  action$.pipe(
    filter(action.store.started.match),
    exhaustMap(({ payload }) =>
      from(karteLeaderStore({ ...payload })).pipe(
        map((res) => {
          return action.store.done({ params: payload, result: res })
        }),
        catchError((error) => of(action.store.failed({ params: payload, error })))
      )
    )
  )

const storeSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (action$, store) =>
  action$.pipe(
    filter(action.store.done.match),
    map(({ payload }) => {
      payload.params.successCallback() // TODO: make direct push to navigation history
      return action.empty({})
    })
  )

export default combineEpics(storeEpic, storeSuccessEpic)
