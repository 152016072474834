import { actionCreatorFactory } from 'typescript-fsa'

import { SelfCheck, SelfCheckCommentConnection } from '../../utils/generated'

import { UPDATE_SELFCHECK, FETCH_SELFCHECK, FETCH_SELFCHECK_COMMENTS } from './types'

const actionCreator = actionCreatorFactory()
export const SelfcheckActions = {
  fetch: actionCreator.async<{ currentHistory: number }, SelfCheck>(FETCH_SELFCHECK),
  updateSelfCheck: actionCreator.async<{ data: string }, SelfCheck>(UPDATE_SELFCHECK),
  fetchSelfCheckComments: actionCreator.async<{}, SelfCheckCommentConnection>(FETCH_SELFCHECK_COMMENTS),
}
