import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { GP, indexedPagePath } from 'pages/ghost/assets/pages'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { PageManagerContext } from 'pages/ghost/contexts/pageManger'
import { useBreakCtl } from 'pages/ghost/pages/Break'
import { GhostMemberRole } from 'utils/generated'

import { ButtonForGhost, Flexbox, Font, Imgcard, UsefulModal } from '../atoms'

import { constants } from 'assets'

export const MemberImageForLeader: React.FC = () => {
  const classes = useStyles()
  const { teamMember, teamMemberList, updateMemberPage, renewTeamMemberList } = React.useContext(ghostTeamMemberContext)
  const { team, updateGhostTeam } = React.useContext(ghostTeamContext)
  const { reportCategory } = React.useContext(PageManagerContext)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const isXsDown = useCustomMediaQuery('down', 'xs')

  const { isReady } = useBreakCtl(
    team,
    teamMember ? teamMember : undefined,
    teamMemberList,
    reportCategory,
    updateGhostTeam,
    updateMemberPage,
    renewTeamMemberList
  )

  const handleClick = () => {
    setIsModalOpen(true)
  }
  const handleClose = () => {
    setIsModalOpen(false)
  }

  //　TODO ページ遷移のチェックのためのログ 改修完了時に削除予定
  // console.log('%cteamMember?.page', 'color: blue', teamMember?.page)

  const activeImage = React.useMemo((): {
    name: string
    status?: 'hasTimer' | 'hasTimer2Btn' | 'noneTimer2Btn'
  } | null => {
    if (!teamMember?.page) return null

    const pagePath = indexedPagePath[teamMember.page - 1] // page は 1 始まりなので -1 で調整。
    switch (pagePath) {
      // multiple
      case GP.break:
        if (isReady) {
          if (teamMember?.page === 13) {
            return { name: 'break-vote', status: 'noneTimer2Btn' }
          } else {
            return { name: 'break', status: 'noneTimer2Btn' }
          }
        } else if (teamMember?.page === 7) {
          return { name: 'pick-ghosts', status: 'noneTimer2Btn' }
        } else if (teamMember?.page === 13) {
          return { name: 'vote', status: 'noneTimer2Btn' }
        } else if (teamMember?.page === 17 || teamMember?.page === 24) {
          return { name: `report-${teamMember.page - 1}`, status: 'noneTimer2Btn' }
        } else if (teamMember?.page === 19 || teamMember?.page === 26) {
          return { name: `result-${teamMember.page - 1}`, status: 'noneTimer2Btn' }
        } else if (teamMember?.page === 32) {
          return { name: 'step4-report', status: 'noneTimer2Btn' }
        } else {
          return null
        }

      case GP.presentationOptions:
        return { name: 'presentation-options' }

      // individual
      case GP.selectLeader:
        return { name: 'selectLeader' }
      case GP.start:
        return { name: 'start' }
      default:
        return null
    }
  }, [teamMember?.page, isReady])

  // case GP.selectLeader　の時点では GhostMemberRole.Leader が未確定のため、GhostMemberRole.Admin も含める
  if (
    !teamMember ||
    !activeImage ||
    (teamMember?.role !== GhostMemberRole.Leader && teamMember?.role !== GhostMemberRole.Admin)
  )
    return null

  const memberImage = (
    <Imgcard
      src={`${process.env.PUBLIC_URL}/assets/ghost/member-images/${activeImage.name}.png`}
      alt="現在のメンバー画面"
      ownWrapStyles={{ width: '99%' }}
      ownStyles={{ width: '100%', verticalAlign: 'top' }}
    />
  )

  return (
    <>
      {!isModalOpen && (
        <div
          className={classNames([
            classes.root,
            activeImage.status === 'hasTimer' && classes.hasTimer,
            activeImage.status === 'hasTimer2Btn' && classes.hasTimer2Btn,
            activeImage.status === 'noneTimer2Btn' && classes.noneTimer2Btn,
          ])}
        >
          <a className={classes.anchor} href="#dummy" onClick={handleClick}>
            <Flexbox ownStyles={{ justifyContent: 'flex-start', columnGap: 8, marginBottom: 4 }}>
              <Imgcard
                src={`${process.env.PUBLIC_URL}/assets/ghost/memberImgZoom.svg`}
                alt=""
                ownWrapStyles={{ width: isXsDown ? 20 : 24, flexShrink: 0 }}
                ownStyles={{ width: '100%', verticalAlign: 'top' }}
              />
              <Font fontSize={10} color={constants.COLOR_MAIN_NEW} bold>
                現在のメンバー画面
              </Font>
            </Flexbox>

            {memberImage}
          </a>
        </div>
      )}

      <UsefulModal
        open={isModalOpen}
        handleModalChange={handleClose}
        ownStyles={{
          width: '90%',
          maxWidth: 600,
          height: isXsDown ? '70%' : isSmDown ? '80%' : '95%',
          maxHeight: isXsDown ? 450 : isSmDown ? 600 : 710,
          padding: isXsDown ? 24 : 40,
        }}
      >
        <Flexbox flexDirection="column" ownStyles={{ height: '100%', justifyContent: 'flex-start' }}>
          <Font fontSize={16} color={constants.COLOR_MAIN_NEW} bold textAlign="center" ownStyles={{ marginBottom: 16 }}>
            現在のメンバー画面はこちらです
          </Font>
          {/* scrollbar が表示されても丸角が維持されるよう設定 */}
          <div className={classes.wrapForHidden}>
            <div className={classes.modalImgWrapper}>{memberImage}</div>
          </div>
          <Flexbox ownStyles={{ marginTop: 16 }}>
            <ButtonForGhost buttonBodyColor="white" bodyText="閉じる" onClick={handleClose} isResizeBasic />
          </Flexbox>
        </Flexbox>
      </UsefulModal>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      right: 0,
      bottom: 110,
      width: 168,
      backgroundColor: '#fff',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      borderRight: 'none',
      borderRadius: '8px 0 0 8px',
      zIndex: 100,
      [theme.breakpoints.down('xs')]: {
        bottom: 92,
        width: 104,
      },
    },
    hasTimer: {
      bottom: 176,
      [theme.breakpoints.down('xs')]: {
        bottom: 158,
      },
    },
    hasTimer2Btn: {
      bottom: 176,
      [theme.breakpoints.down('xs')]: {
        bottom: 218,
      },
    },
    noneTimer2Btn: {
      bottom: 110,
      [theme.breakpoints.down('xs')]: {
        bottom: 140,
      },
    },
    anchor: {
      display: 'block',
      padding: '6px 12px 13px',
    },
    wrapForHidden: {
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 8,
      overflow: 'hidden',
    },
    modalImgWrapper: {
      height: '100%',
      background: constants.COLOR_WHITE2,
      overflow: 'auto',
      overscrollBehavior: 'contain', // スクロールの伝播を抑止
    },
  }),
  { name: 'MemberImageForLeader' }
)
