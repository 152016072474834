import React from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { teamConstants } from 'pages/teams/assets/constants'
import {
  DefaultInputWithLabel,
  DefaultButton,
  HookErrors,
  CardFooter,
  Card,
  ButtonContainer,
  HorizontalLine,
  NavigationTextLink,
  ResultFramework,
  ResultWithIcon,
} from 'pages/teams/components'
import { CreateTeamInput } from 'utils/generated'

import { useManageTeam } from '../../hooks/teams'

import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

export const TeamsNewPage: React.FC = () => {
  const { route } = useHistory()
  const { createTeam, loading, error } = useManageTeam()
  const { handleSubmit, control, errors } = useForm<CreateTeamInput>()
  const { teamId } = useParams<{ teamId?: string }>()
  const [isCompleted, setIsCompleted] = React.useState(false)
  const isSmDown = useCustomMediaQuery('down', 'sm')

  const handleToTeamList = () => {
    route.push(`${Pages.TeamsList}?hidden=${true}`, { teamId })
  }

  const onSubmit = async (input: CreateTeamInput) => {
    const [team] = await createTeam(input)
    if (team) {
      setIsCompleted(true)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          headerTitle="チーム追加"
          style={{ padding: isSmDown ? '16px 16px 24px' : 24 }}
          titleStyles={{ height: isSmDown ? 22 : 40, fontSize: 14, display: 'flex', alignItems: 'center' }}
          headerMb={0}
          titleContainerPb={0}
        >
          {!isCompleted ? (
            <>
              <HorizontalLine style={{ marginTop: isSmDown ? 16 : 24, marginBottom: 24 }} />
              <DefaultInputWithLabel
                label="チーム名"
                name="name"
                control={control}
                rules={{ required: 'チーム名は必須です' }}
                error={errors.name}
              />

              <CardFooter>
                <ButtonContainer
                  buttons={[
                    <DefaultButton
                      key="buttons-member-csvAddPage-4"
                      title="追加"
                      color={!!errors.name ? 'team_disabled' : 'team_main'}
                      type="submit"
                      disabled={!!errors.name}
                      loading={loading}
                      style={{
                        ...teamConstants.cardFooterButtonStlyes,
                        border: !!errors.name ? teamConstants.disabledBorder : undefined,
                      }}
                    />,
                    <DefaultButton
                      key="buttons-member-csvAddPage-5"
                      title="キャンセル"
                      color="team_white"
                      onClick={() => route.push(`${Pages.TeamsList}?hidden=${true}`, { teamId })}
                      style={teamConstants.cardFooterButtonStlyes}
                    />,
                  ]}
                  marginTopPcLayout={0}
                />
              </CardFooter>

              <HookErrors errors={error} />
            </>
          ) : (
            <ResultFramework>
              <ResultWithIcon
                topText="チームを追加しました"
                iconType="success"
                message="チームリストから内容をご確認ください"
                button={{
                  label: 'チームリストに戻る',
                  onClick: handleToTeamList,
                }}
              />
            </ResultFramework>
          )}
        </Card>
      </form>

      {!isCompleted && (
        <NavigationTextLink
          path={replacePathParams(Pages.TeamsNewByCsv, { teamId })}
          text="チームCSV一括追加はこちら"
          containerStyle={{ marginLeft: isSmDown ? 24 : undefined }}
        />
      )}
    </>
  )
}
