import * as React from 'react'

import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core'
import Slider, { Mark } from '@material-ui/core/Slider'
// import * as constants from '../../../../assets/constants'

interface IProps {
  name: string
  value?: number | undefined
  options: Mark[]
  onChange: (value: number) => void
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes, options, onChange } = props

  if (options.length === 0) return null

  return (
    <div className={classes.container}>
      <div className="__slider">
        <Slider
          classes={{ mark: '__mark', thumb: '__thumb', rail: '__rail' }}
          value={props.value || 0}
          valueLabelDisplay="off"
          step={1}
          marks={options}
          track={false}
          min={options[0].value}
          max={options[options.length - 1].value}
          onChange={(e, newValue) => onChange(newValue as number)}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      '& .__slider': {
        lineHeight: 0,
      },
      '& .__mark': {
        display: 'none',
      },
      '& .__rail': {
        backgroundColor: '#00838C',
        opacity: 1,
      },
      '& .__thumb': {
        transform: 'translateY(-6px)',
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid #00838C',
      },
    },
  })

export default withStyles(useStyles)(Index)
