import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetSetupItemFeedbacks } from './graphql'

const getSetupItemFeedbacks = async (setupStartedAt: string) => {
  try {
    const response: any = await API.graphql(graphqlOperation(QueryGetSetupItemFeedbacks, { setupStartedAt }))
    const { getSetupItemFeedbacks } = response.data

    return getSetupItemFeedbacks
  } catch (e) {
    console.log('getSetupItemFeedbacks error', e)
  }
}

export default getSetupItemFeedbacks
