import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { useHistoryStore } from 'pages/ghost/contexts/history'
import { LoadingCircular } from 'pages/teams/components'
import { Modal } from 'pages/teams/pages/list/components/atoms/Modal'

import { constants } from 'assets'

type Tab = '発生した原因' | '今後の対処法'
const tabs: Tab[] = ['発生した原因', '今後の対処法']

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  teamId: string
  ghostId?: string
  ghostName?: string
  ghostHistoryCount?: number
  ghostLastPlayedDate?: string
}

export const GraphListGhostModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  teamId,
  ghostId,
  ghostName,
  ghostHistoryCount,
  ghostLastPlayedDate,
}) => {
  const classes = useStyles()
  const { loading, historyList } = useHistoryStore(teamId, 'dummy-ghost-teamId')

  // 最後に見つけたオバケのこれまでの発見回数
  const lastDiscovredGhostCount = historyList?.filter((item) => item.history?.ghostId === ghostId).length

  // 最新のオバケの対話結果を取得
  const latestGhostHistory = React.useMemo(() => {
    if (!historyList || !historyList.length) return

    return historyList.reduce((prev, current) =>
      new Date(current.history?.createdAt ?? '') > new Date(prev.history?.createdAt ?? '') ? current : prev
    )
  }, [historyList])

  const [activeTab, setActiveTab] = React.useState<Tab>(tabs[0])
  const isActiveTab = (tab: Tab) => tab === activeTab

  const handleChangeTab = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveTab(e.target.value as Tab)
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={classes.modal}>
        <h2 id="transition-modal-title" className={classes.modalTitle}>
          最新オバケの対話結果
        </h2>
        <div
          id="transition-modal-description"
          className={classes.modalDate}
        >{`${ghostHistoryCount}回目：${ghostLastPlayedDate}`}</div>
        <div className={classes.modalInfoWrapper}>
          {ghostId && (
            <div className={classes.modalGhostImg}>
              <img src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghostId}.svg`} alt="最新のオバケ" />
            </div>
          )}
          <div className={classes.modalGhostInfoBox}>
            <div className={classes.modalGhostNum}>FILE NO.{ghostId}</div>
            <div className={classes.modalGhostName}>{ghostName}</div>
            <div className={classes.modalGhostCount}>過去に見つけた数：{lastDiscovredGhostCount}</div>
          </div>
        </div>
        {loading ? (
          <LoadingCircular loading={loading} />
        ) : (
          <>
            <div className={classes.tabHeader}>
              {tabs.map((tab, index) => (
                <React.Fragment key={`teams-latest-ghost-modal-${index}`}>
                  <label
                    className={classNames([classes.tabLabel, isActiveTab(tab) && 'checked'])}
                    htmlFor={`${tab}-tab`}
                  >
                    {tab}
                  </label>
                  <input
                    id={`${tab}-tab`}
                    type="radio"
                    value={tab}
                    onChange={handleChangeTab}
                    checked={isActiveTab(tab)}
                  />
                </React.Fragment>
              ))}
            </div>
            <div>
              {activeTab === tabs[0]
                ? (latestGhostHistory?.research?.first ?? []).map((cause, index) => (
                    <div key={`latest-ghost-cause-${index}`} className={classes.modalGhostResearch}>
                      {cause.report}
                    </div>
                  ))
                : (latestGhostHistory?.research?.second ?? []).map((cope, index) => (
                    <div key={`latest-ghost-cope-${index}`} className={classes.modalGhostResearch}>
                      {cope.report}
                    </div>
                  ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

const useStyles = makeStyles(
  () => ({
    modal: {
      color: constants.TEXT_GRAY_DARK,
      whiteSpace: 'pre-wrap',
    },
    modalTitle: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      textAlign: 'center',
    },
    modalDate: {
      marginTop: 12,
      padding: 4,
      backgroundColor: constants.COLOR_WHITE2,
      fontSize: 10,
      textAlign: 'center',
    },
    modalInfoWrapper: {
      display: 'flex',
      columnGap: 16,
      marginTop: 12,
    },
    modalGhostImg: {
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      '& img': {
        width: 100,
        height: 100,
        verticalAlign: 'top',
      },
    },
    modalGhostInfoBox: {
      width: '100%',
    },
    modalGhostNum: {
      fontSize: 10,
    },
    modalGhostName: {
      marginTop: 6,
      fontSize: 12,
    },
    modalGhostCount: {
      marginTop: 6,
      padding: 4,
      backgroundColor: constants.COLOR_TEAMBUILDING_YERROW,
      fontSize: 10,
      textAlign: 'center',
    },
    tabHeader: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      height: 42,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      padding: 4,
      borderRadius: 21,
      '& input': {
        display: 'none',
      },
    },
    tabLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      height: '100%',
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
      cursor: 'pointer',
      borderRadius: 17,
      '&.checked': {
        backgroundColor: constants.COLOR_WHITE,
        color: constants.COLOR_MAIN_NEW,
      },
    },
    modalGhostResearch: {
      marginTop: 16,
      padding: 16,
      backgroundColor: constants.COLOR_WHITE2,
      fontSize: 12,
      borderRadius: 8,
    },
  }),
  { name: 'GraphListGhostModal' }
)
