import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { StepDescription } from './agenda/StepDescription'
import { StepLabel } from './agenda/StepLabel'
import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'

type Props = {
  title: string
  subtitle: string
  current?: 1 | 2 | 3
}

export const Agenda: React.FC<Props> = ({ title, subtitle, current }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} complete={current && current > 1} />
      <ContentSection>
        <Content>
          <div className={classes.stepWrap}>
            <div className={classes.stepCounts}>
              <StepLabel label="01" completed={current && current > 1} />
              <StepLabel label="02" completed={current && current > 2} />
              <StepLabel label="03" />
              <div className={classes.line} />
            </div>
            <div className={classes.stepDescriptions}>
              <StepDescription
                title="アイスブレイク"
                description="場の雰囲気醸成"
                min={3}
                max={15}
                isCurrent={current === 1}
              />
              <StepDescription
                title="目的と行動規範の対話"
                description="現状の相互の認識を合わせる"
                min={5}
                max={15}
                isCurrent={current === 2}
              />
              <StepDescription
                title="計画に対しての修正"
                description="プランの再考/今後の流れについて全員で共通認識を持つ"
                min={5}
                max={15}
                isCurrent={current === 3}
              />
            </div>
          </div>
        </Content>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  stepWrap: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    '@media (max-width: 632px)': {
      flexDirection: 'row',
    },
  },
  stepCounts: {
    position: 'relative',
    width: '100%',
    maxWidth: 428,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 632px)': {
      width: 'auto',
      flexDirection: 'column',
    },
  },
  line: {
    position: 'absolute',
    height: 1,
    width: '100%',
    maxWidth: 432,
    borderBottom: '1px solid #00838C',
    zIndex: 1,
    '@media (max-width: 632px)': {
      height: 'calc(100% - 38px)',
      width: 1,
      borderBottom: 'none',
      borderLeft: '1px solid #00838C',
    },
  },
  stepDescriptions: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
    },
  },
}))
