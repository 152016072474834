import React from 'react'

import { makeStyles, Modal } from '@material-ui/core'
import { format } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import { CalendarForm } from 'components/form/CalendarForm'
import { FormError } from 'components/form/FormError'
import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { mutationSendTeamKarteEmail } from '../hooks/graphql'

import { Button } from './Button'

import { constants } from 'assets'
import { COLOR_BLACK, COLOR_ONBOARDING_MAIN, COLOR_WHITE } from 'assets/constants'

type Props = {
  isFirstPage?: boolean
}

type Input = { end: Date }

export const ButtonToPlans: React.FC<Props> = ({ isFirstPage }) => {
  const classes = useStyles()

  const { team, teamId } = React.useContext(TeamsHooksContext)

  const { control, errors, setError, handleSubmit, reset, watch } = useForm<Input>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const end = watch('end')

  const onClick = () => {
    setOpen(true)
  }

  const [open, setOpen] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [complete, setComplete] = React.useState(false)

  const onClose = () => {
    setOpen(false)
    setComplete(false)
    reset()
  }

  const onSubmit = async (data: Input) => {
    if (teamId) {
      try {
        setProcessing(true)
        await mutationSendTeamKarteEmail({
          teamId,
          end: format(data.end, 'yyyy/MM/dd'),
        })
        setComplete(true)
      } catch (e) {
        setError('end', {
          type: 'validate',
          message: 'エラーが発生しました',
        })
      } finally {
        setProcessing(false)
      }
    }
  }

  return (
    <>
      <Button
        buttonType={isFirstPage ? 'secondary' : 'primary'}
        style={{
          padding: '8px 16px',
          height: isFirstPage ? 40 : 32,
          borderRadius: 20,
          width: isFirstPage ? 140 : 128,
          fontSize: 12,
        }}
        endIconStyle={{
          margin: '0 -6px 0 0',
        }}
        hoverStyle={{
          opacity: 0.7,
          backgroundColor: isFirstPage ? '#fff' : constants.COLOR_MAIN_NEW,
          color: isFirstPage ? constants.COLOR_MAIN_NEW : '#fff',
        }}
        icon={isFirstPage ? undefined : 'chevron-right'}
        onClick={onClick}
      >
        診断を依頼する
      </Button>
      <Modal open={open} onClose={onClose} disableEscapeKeyDown>
        <div className={classes.root}>
          <div className={classes.title}>診断を依頼する</div>
          <div className={classes.caution}>※チームに登録されているメンバー全員に送信されます</div>

          {!complete ? (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.formContainer}>
                  <div className={classes.label}>回答締切日</div>
                  <div>
                    <Controller
                      name={'end'}
                      rules={{
                        required: '締切日は必須です',
                      }}
                      defaultValue={''}
                      control={control}
                      render={({ onChange }) => (
                        <>
                          <CalendarForm onChange={onChange} />
                          <div className="mt-2">
                            <FormError error={errors.end} />
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    buttonType="primary"
                    style={{
                      height: 48,
                      borderRadius: 48,
                      marginBottom: 16,
                    }}
                    full
                    type="submit"
                    disabled={processing}
                  >
                    送信
                  </Button>
                  <Button
                    buttonType="secondary"
                    style={{
                      height: 48,
                      borderRadius: 48,
                      marginBottom: 16,
                    }}
                    full
                    onClick={onClose}
                    disabled={processing}
                  >
                    キャンセル
                  </Button>
                </div>
              </form>

              {end && (
                <>
                  <div className={classes.exampleContainerTitle}>送信されるメールの内容</div>
                  <div className={classes.exampleContainer}>
                    <span className={classes.exampleTitle}>タイトル</span>
                    {'\n'}
                    [Cocolaboチーム] カルテの回答依頼が届きました{'\n'}
                    {'\n'}
                    <span className={classes.exampleTitle}>内容</span>
                    {'\n'}
                    {team?.name}でのカルテの回答依頼が届きました！{'\n'}
                    ログインしてカルテに回答しましょう。{'\n'}
                    {'\n'}
                    ---{'\n'}
                    チーム名： {team?.name}
                    {'\n'}
                    回答締切日： {format(new Date(end), 'yyyy/MM/dd')}まで{'\n'}
                    チームへのアクセスはこちらからご確認ください{'\n'}
                    /teams/{teamId}/tools/karte/diagnose
                    {'\n'}
                    {'\n'}
                    ---{'\n'}
                    Cocolabo 運営事務局{'\n'}
                    mail: cocolabo-support@new-one.co.jp
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className={classes.description}>メンバーへ診断依頼のメールを送信しました</div>

              <div className={classes.buttonContainer}>
                <Button
                  buttonType="secondary"
                  style={{
                    height: 48,
                    borderRadius: 48,
                    marginBottom: 16,
                  }}
                  full
                  onClick={onClose}
                >
                  閉じる
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(
  () => ({
    editButton: {
      color: COLOR_ONBOARDING_MAIN,
      fontWeight: 'bold',
    },

    editIcon: {
      fontSize: '16px !important',
    },

    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 350,
      background: COLOR_WHITE,
      borderRadius: 8,
      outline: 'none',
      boxSizing: 'border-box',
      padding: 24,
      textAlign: 'center',
    },

    title: {
      fontWeight: 'bold',
      color: COLOR_ONBOARDING_MAIN,
      marginBottom: 24,
    },

    caution: {
      color: COLOR_BLACK,
      textAlign: 'left',
      marginBottom: 16,
      fontSize: 12,
    },

    description: {
      marginBottom: 24,
    },

    formContainer: {
      marginBottom: 24,
      textAlign: 'left',
    },

    label: {
      fontWeight: 'bold',
      marginBottom: 10,
    },

    buttonContainer: {},

    confirmIcon: {
      marginBottom: 16,
    },

    confirmMessage: {
      color: COLOR_ONBOARDING_MAIN,
      whiteSpace: 'pre-wrap',
      fontWeight: 'bold',
      fontSize: 14,
      marginBottom: 30,
    },
    exampleContainerTitle: {
      fontSize: 12,
      textAlign: 'left',
    },
    exampleContainer: {
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      border: '1px solid #999',
      borderRadius: 4,
      fontSize: 12,
      padding: 4,
      maxHeight: 200,
      overflow: 'auto',
    },
    exampleTitle: {
      fontWeight: 'bold',
    },
  }),
  { name: 'ButtonToPlans' }
)
