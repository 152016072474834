import React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { CROWN_MAP } from 'pages/hataraku/pages/results/components/Graph'
import { IKarteMemberResults } from 'pages/hataraku/pages/results/interface'
import { makeEvenPoints } from 'pages/hataraku/utils/results'

import { constants } from 'assets'
import { ArrowRightBlueDark2Svg, minusBlueDark2Svg, plusBlueDark2Svg } from 'assets/svg'

const DEFAULT_MAX_DISPLAY_RANKING = 3
const RANKING_CARD_BREAKPOINT = 900

type Props = {
  points: IKarteMemberResults['points']
}

type StyleProps = {
  rankingItemColor?: string
}

export const Ranking: React.FC<Props> = ({ points }) => {
  const classes = useStyles({})
  const [isOpened, setIsOpened] = React.useState(false)

  const onClickButton = () => {
    setIsOpened(!isOpened)

    if (isOpened) {
      const element = document.getElementById('ranking')
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={classes.root} id="ranking">
      <ol className={classes.rankingContainer}>
        {points.slice(0, DEFAULT_MAX_DISPLAY_RANKING).map((point, i) => (
          <li key={point.title}>
            <RankingCard point={point} rank={i + 1} />
          </li>
        ))}
        {isOpened &&
          points.slice(DEFAULT_MAX_DISPLAY_RANKING).map((point, i) => (
            <li key={point.title}>
              <RankingCard key={point.title} point={point} rank={i + DEFAULT_MAX_DISPLAY_RANKING + 1} />
            </li>
          ))}
      </ol>
      <Button label={isOpened ? '閉じる' : '4位以降を見る'} isOpened={isOpened} onClick={() => onClickButton()} />
    </div>
  )
}

const RankingCard: React.FC<{
  point: IKarteMemberResults['points'][number]
  rank: number
}> = ({ point, rank }) => {
  const classes = useStyles({ rankingItemColor: point.color })

  return (
    <div className={classes.rankingCard}>
      <div className={classes.rankingCardHeaderContainer}>
        <div className={classes.rankingCardScoreCircle}>
          {CROWN_MAP[rank] && <img src={CROWN_MAP[rank]} alt="crown" />}
          {makeEvenPoints(point.value).toFixed(1)}
          <span>点</span>
        </div>
        <span className={classes.rankingCardTitle}>{point.title}</span>
      </div>
      <a href={point.link} target="_blank" rel="noreferrer" className={classes.rankingCardLink}>
        <span>{point.description}</span>
        <img src={ArrowRightBlueDark2Svg} alt="" />
      </a>
    </div>
  )
}

const Button: React.FC<{ label: string; isOpened: boolean; onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({
  label,
  isOpened,
  onClick,
}) => {
  const classes = useStyles({})

  return (
    <button onClick={onClick} className={classes.button}>
      {label}
      <img src={isOpened ? minusBlueDark2Svg : plusBlueDark2Svg} alt="" />
    </button>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40,
      gap: 16,
    },

    rankingContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    rankingCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 40px',
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      [theme.breakpoints.down(RANKING_CARD_BREAKPOINT)]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: 8,
        padding: 16,
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT3,
        },
      },
    },

    rankingCardHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 24,
    },

    rankingCardScoreCircle: ({ rankingItemColor }) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      gap: 2,
      position: 'relative', // 王冠画像表示のため
      width: 80,
      height: 80,
      borderRadius: '50%',
      color: constants.COLOR_WHITE,
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: rankingItemColor,
      '& span': {
        fontSize: 12,
      },
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 24,
        height: 24,
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down(RANKING_CARD_BREAKPOINT)]: {
        gap: 1,
        width: 40,
        height: 40,
        fontSize: 12,
        '& span': {
          fontSize: 8,
        },
        '& img': {
          top: -6,
          left: -6,
          width: 20,
          height: 20,
        },
      },
    }),

    rankingCardTitle: ({ rankingItemColor }) => ({
      color: rankingItemColor,
      fontSize: 16,
      fontWeight: 'bold',
    }),

    rankingCardLink: {
      display: 'flex',
      alignItems: 'center',
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 16,
      fontWeight: 900,
      textDecoration: 'none',
      gap: 8,
      [theme.breakpoints.down(RANKING_CARD_BREAKPOINT)]: {
        width: '100%',
        justifyContent: 'space-between',
        fontSize: 14,
      },
      '@media (hover: hover)': {
        '&:hover': {
          '& span': {
            textDecoration: 'underline',
          },
        },
      },
      '& img': {
        width: 24,
        height: 24,
      },
    },

    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 24,
      width: 235,
      margin: '0 auto',
      padding: '24px 40px',
      fontSize: 16,
      fontWeight: 900,
      color: constants.COLOR_BLUE_DARK2,
      border: 'none',
      borderRadius: 50,
      backgroundColor: constants.COLOR_WHITE,
      opacity: '90%',
      cursor: 'pointer',
      '& img': {
        width: 15,
        height: 15,
      },
      '@media (hover: hover)': {
        '&:hover': {
          opacity: '100%',
        },
      },
    },
  }),
  { name: 'Ranking' }
)
