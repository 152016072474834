import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { format, parseISO } from 'date-fns'

import arrowDownSvg from 'pages/teams/pages/_tools/karte/assets/arrowDown.svg'
import { GhostHistory } from 'utils/generated'

import { TableFilter } from './index'

import { constants } from 'assets'

const TEXT_SELECT = '回を選択'
const TEXT_NO_RESULT = '探索結果がありません'

type Props = {
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
  completedHistories: GhostHistory[]
}

export const GhostHistorySelect: React.FC<Props> = ({ setFilter, completedHistories = [] }) => {
  const classes = useStyles()
  const historyLength = completedHistories.length

  return (
    <select
      className={classes.select}
      onChange={(e) =>
        setFilter((prev) => ({
          ...prev,
          allTargetHistoryId: e.target.value,
          memberHistory: { memberId: '', targetHistoryId: '' },
        }))
      }
    >
      <option value={''}>{historyLength ? TEXT_SELECT : TEXT_NO_RESULT}</option>
      {completedHistories.map((history, i) => (
        <option key={history.id} value={history.id}>
          {`${historyLength - i}回目の探索結果（${format(parseISO(history.createdAt), 'yy/MM/dd')}）`}
        </option>
      ))}
    </select>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    select: {
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      width: 235,
      height: 40,
      padding: 8,
      backgroundColor: '#fff', // ブラウザごとのデフォルトの背景色を上書き
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 8,
      cursor: 'pointer',
      backgroundImage: `url(${arrowDownSvg})`,
      backgroundPosition: 'right 14px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 16,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '&:focus': {
        outline: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
      },
    },
  }),
  { name: 'GhostHistorySelect' }
)
