import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import * as chardet from 'chardet'
import * as iconv from 'iconv-lite'
import { parse } from 'papaparse'
import { useDropzone, FileRejection } from 'react-dropzone'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { fileIcon, fileSize } from 'pages/onboarding/utils/attachmentFile'

import Button from '../../pages/member/components/Button'

import clear from './assets/clear.svg'
import csvFile from './assets/csv-file.svg'
import warning from './assets/warning.svg'
import * as constants from 'assets/constants'

type Props = {
  uploadFile: File | undefined
  setUploadFile: Dispatch<SetStateAction<File | undefined>>
  setCsvData: Dispatch<SetStateAction<string[][]>>
  rootStyle?: React.CSSProperties
}

const fileLimit = { label: '10MB', byte: 10000000, line: 1000 }

export const DropZoneCsv = ({ uploadFile, setUploadFile, setCsvData, rootStyle }: Props) => {
  const classes = useStyles()
  const [checkedLine, setCheckedLine] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const isSmUp = useCustomMediaQuery('up', 'sm')

  const updateCsvData = useCallback(
    (result: { data: string[][] }) => {
      const data = result.data
      if (data.length > fileLimit.line) {
        setErrMsg(`読み込み可能な上限を超えています。(上限行数 :${fileLimit.line})`)
        return
      }

      setCsvData(data)
      setCheckedLine(true)
    },
    [setCsvData]
  )

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        if (e && e.target?.result && typeof e.target.result !== 'string') {
          const codes = new Uint8Array(e.target.result)
          const encode = chardet.detect(codes)?.toString()
          if (encode) {
            const decodedString = iconv.decode(Buffer.from(e.target.result), encode)
            parse(decodedString, {
              complete: updateCsvData,
              header: false,
            })
          } else {
            setErrMsg('このファイルはアップロードできません。別のファイルでお試しください。')
            return
          }
        }
      }
      fileReader.readAsArrayBuffer(acceptedFiles[0])
    },
    [updateCsvData]
  )

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    if (fileRejections[0].errors[0].code === 'file-too-large') {
      setErrMsg(`ファイルサイズが上限（${fileLimit.label}）を超えています。`)
      return
    }
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      setErrMsg(`この形式のファイルはアップロード出来ません。`)
      return
    }
    setErrMsg('このファイルはアップロードできません。別のファイルでお試しください。')
  }, [])

  const handleClear = useCallback(() => {
    if (window.confirm('アップロードを取り消しますか？')) {
      setUploadFile(undefined)
      setCheckedLine(false)
      setCsvData([])
    }
  }, [setUploadFile, setCsvData])

  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    multiple: false,
    maxFiles: 1,
    maxSize: fileLimit.byte,
    accept: { 'text/csv': ['.csv'] },
  })

  useEffect(() => {
    setErrMsg('')
  }, [uploadFile])

  useEffect(() => {
    if (checkedLine && acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0])
    }
  }, [checkedLine, acceptedFiles, setUploadFile])

  return (
    <div className={`${classes.rootContainer}${errMsg ? ' err' : ''}`} style={rootStyle}>
      {!uploadFile ? (
        <div
          className={`${classes.dropzoneContainer}${isDragActive ? ' ' + classes.droppingBorder : ''}`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <img src={csvFile} alt="csv icon" />
          <p className={classes.msg}>{uploadFile ? 'アップロードされたファイル' : 'CSVファイルをアップロード'}</p>
          {isSmUp && (
            <>
              {isDragActive ? (
                <p className={classes.description}>ファイルをドロップしてください</p>
              ) : (
                <p className={classes.description}>ドラッグ＆ドロップでファイルをアップロード</p>
              )}
            </>
          )}

          {!isDragActive && (
            <div className={classes.selectButtonWrapper}>
              <Button
                bgColor={constants.COLOR_WHITE}
                textColor={constants.COLOR_MAIN_NEW}
                borderColor={constants.COLOR_MAIN_NEW}
                square={true}
                fontSize={12}
                body="ファイルを選択する"
                noShadow={true}
                height={31}
                onClick={open}
                style={{ borderRadius: 50, padding: '0 16px' }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classes.uploadedContainer}>
          <div className={classes.file}>
            <img src={fileIcon(uploadFile.name)} alt="attachmentFile" className={classes.fileIcon} />
            <div className={classes.fileInfo}>
              <div className={classes.fileName}>{uploadFile.name}</div>
              <div className={classes.fileSize}>サイズ：{fileSize(uploadFile.size)}</div>
            </div>
          </div>
          <NoStyleButton className={classes.clearButton} type="button" onClick={handleClear}>
            <img src={clear} alt={'attachmentFile'} className={classes.fileIcon} />
          </NoStyleButton>
        </div>
      )}
      {errMsg ? (
        <div className={classes.err}>
          <img src={warning} alt="warning" />
          {errMsg}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    rootContainer: {
      background: constants.COLOR_WHITE,
      padding: 16,
      borderRadius: 4,
      '&.err': {
        paddingBottom: 8,
      },
    },
    dropzoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '16px',
      backgroundColor: constants.COLOR_WHITE2,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 4,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '24px 16px',
        height: 160,
      },
    },
    droppingBorder: {
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
    },
    msg: {
      margin: '6px 0 0',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    description: {
      margin: '4px 0 0',
      color: constants.COLOR_GRAY_DARK,
      fontSize: 10,
    },
    selectButtonWrapper: {
      marginTop: 8,
    },

    uploadedContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      height: 56,
      padding: '13px 16px',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
    },
    file: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      flex: 1,
      minWidth: 0,
    },
    fileIcon: {
      height: 24,
    },
    fileInfo: {
      minWidth: 0,
      width: '100%',
    },
    fileName: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fileSize: {
      marginTop: 2,
      color: constants.COLOR_GRAY3,
      fontSize: 10,
    },
    clearButton: {
      width: 24,
      height: 24,
      '& img': {
        width: 24,
      },
    },
    err: {
      marginTop: 6,
      color: constants.COLOR_RED4,
      fontSize: 14,
      '& img': {
        position: 'relative',
        top: 3,
        marginRight: 4,
      },
    },
  }),
  { name: 'DropZoneCsv' }
)
