import { createContext, useCallback } from 'react'

import { pickupRandomMember } from 'pages/ghost/utils/pickupMember'
import { progressSelectTimerItem } from 'pages/ghost/utils/timer'
import {
  GhostReport,
  GhostResearchType,
  GhostTeam,
  GhostTeamMember,
  GhostTeamManage,
  MutationCreateGhostTeamArgs,
  GhostProgress,
} from 'utils/generated'

import { addLikeOnGhostResearch } from '../api/research/handlers'
import { GhostTeamStore } from '../contexts/ghostTeam'
import { GhostTeamMemberStore } from '../contexts/ghostTeamMember'
import { PageManager } from '../contexts/pageManger'
import { ReportStore } from '../contexts/report'
import { ResearchStore } from '../contexts/research'
import { LoaderPatterns } from '../hooks/loader'
import { NextPagesAfterBreak } from '../pages/Break'

import { StagesNum, UnitOfWork } from './unitOfWork'

import { GP, page2Num, teamIdPath } from '../assets/pages'

export type CommandBus = ReturnType<typeof useCommands>
export const CommandContext = createContext({} as CommandBus)

export const useCommands = (
  { checkIsGhostTeam, completeWithTransition, transitionExec }: UnitOfWork,
  { createGhostTeam, updateGhostTeam, deleteGhostTeam }: GhostTeamStore,
  updateMemberPage: GhostTeamMemberStore['updateMemberPage'],
  updateGhostTeamMemberByAdmin: GhostTeamMemberStore['updateGhostTeamMemberByAdmin'],
  retire: GhostTeamMemberStore['retire'],
  dropMembers: GhostTeamMemberStore['dropMembers'],
  { createReports, voteReports, forceGetResult }: ReportStore,
  createResearch: ResearchStore['createResearch'],
  { jumpTo, reportCategory }: PageManager,
  masterTeamId: String
) => {
  const runCreateGhostTeam = async (input: MutationCreateGhostTeamArgs) => {
    await transitionExec('busy', async () => {
      const response = await createGhostTeam(input)
      if (response) {
        jumpTo(teamIdPath(GP.waiting, response?.id))
      }
    })
  }

  const runGoToSelectLeader = useCallback(
    async (ghostTeam: GhostTeam) => {
      await checkIsGhostTeam(async () => {
        await transitionExec('selectLeader', async () => {
          await updateGhostTeam({ input: { id: ghostTeam.id, status: GhostTeamManage.Prepare } })
          await updateMemberPage(page2Num('selectLeader'))
        })
      }, '別の方がおばけ探索チームを削除しました。ダッシュボードに戻ります。')
    },
    [checkIsGhostTeam, transitionExec, updateGhostTeam, updateMemberPage]
  )

  const runDeleteGhostTeam = useCallback(
    //deleteGhostTeam with error message when ghostTeam is already deleted
    async () => {
      await checkIsGhostTeam(async () => {
        await deleteGhostTeam()
      }, 'すでにこのおばけ探索チームは存在しません。ダッシュボードに戻ります。')
    },
    [deleteGhostTeam, checkIsGhostTeam]
  )

  const runGoToStart = useCallback(
    async (teamId: string, userId: string) => {
      await transitionExec('start', async () => {
        await updateGhostTeamMemberByAdmin({ input: { id: teamId, memberId: userId } })
        await updateMemberPage(page2Num('start'))
      })
    },
    [transitionExec, updateGhostTeamMemberByAdmin, updateMemberPage]
  )

  const runGoToIntro = useCallback(
    async (ghostTeam: GhostTeam) => {
      if (!ghostTeam) return
      await updateGhostTeam({ input: { id: ghostTeam.id, status: GhostTeamManage.Doing } })
      await updateMemberPage(page2Num('intro'))
    },
    [updateMemberPage, updateGhostTeam]
  )

  const runcDeleteGhostTeamMember = async () => {
    checkIsGhostTeam(async () => {
      await retire()
      jumpTo(`/teams/${masterTeamId}`)
    }, '別の方がおばけ探索チームを削除しました。ダッシュボードに戻ります。')
  }

  const runCreateReports = useCallback(
    (selectedGhostIds: string[]) => {
      completeWithTransition({
        pattern: 'busy',
        page: 6,
        submitProcess: async () => await createReports(selectedGhostIds),
        duration: 2000,
      })
    },
    [completeWithTransition, createReports]
  )

  const runFromCompletePage = useCallback(
    async (
      ghostTeam: GhostTeam,
      ghostTeamMember: GhostTeamMember,
      loaderPattern: LoaderPatterns,
      duration?: number
    ) => {
      if (!(ghostTeam && ghostTeamMember)) return
      await transitionExec(
        loaderPattern,
        async () => {
          ghostTeamMember?.page && (await updateMemberPage(ghostTeamMember?.page + 1))
        },
        duration
      )
    },
    [transitionExec, updateMemberPage]
  )

  const runVoteReports = useCallback(
    async (reportsToVote: GhostReport[], usersVotes: boolean[]) => {
      await completeWithTransition({
        pattern: 'report',
        page: 12,
        submitProcess: async () => {
          await voteReports(reportsToVote, usersVotes)
        },
      })
    },
    [completeWithTransition, voteReports]
  )

  const runCreateResearch = useCallback(
    async (
      reasonReport?: string,
      solutionReport?: string,
      feelingReport?: string,
      nextActionReport?: string,
      teamMember?: GhostTeamMember
    ) => {
      const category = reportCategory(teamMember)

      const categoryPages = {
        reason: 16,
        solution: 23,
        nextAction: 31,
      }

      const getCurrentPage = (category?: keyof typeof categoryPages): StagesNum =>
        (category ? categoryPages[category] : 16) as StagesNum

      const updateFnc = () => {
        switch (category) {
          case 'reason':
            reasonReport && createResearch(reasonReport, GhostResearchType.First)
            break
          case 'solution':
            solutionReport && createResearch(solutionReport, GhostResearchType.Second)
            break
          case 'nextAction':
            feelingReport && createResearch(feelingReport, GhostResearchType.Feeling)
            nextActionReport && createResearch(nextActionReport, GhostResearchType.NextAction)
            break
          default:
            break
        }
      }
      await completeWithTransition({
        pattern: 'research',
        page: getCurrentPage(category),
        submitProcess: async () => {
          await updateFnc()
        },
      })
    },
    [completeWithTransition, createResearch, reportCategory]
  )

  const runSubmitLikes = useCallback(
    async (likedResearchIds: string[], teamMember?: GhostTeamMember) => {
      const category = reportCategory(teamMember)
      const currentPage = category === 'reason' ? 18 : 25
      await completeWithTransition({
        pattern: 'like',
        page: currentPage,
        submitProcess: async () => {
          for (const id of likedResearchIds) {
            await addLikeOnGhostResearch({ id })
          }
        },
        duration: 2000,
      })
    },
    [completeWithTransition, reportCategory]
  )

  const terminateGame = useCallback(async () => {
    await deleteGhostTeam()
    // other processes
  }, [deleteGhostTeam])

  const forceProceed = useCallback(
    async (page: 'waiting' | 'report' | NextPagesAfterBreak) => {
      if (page === 'report') {
        await forceGetResult()
        // -> evt SubscriptionResultDetected
      }
      await dropMembers(page)
      // -> evt SubscriptionDeleteMemberDetected
    },
    [forceGetResult, dropMembers]
  )

  const runGoToNextPageWithTransition = useCallback(
    async (teamMember: GhostTeamMember, loaderPattern: LoaderPatterns, duration?: number) => {
      if (!teamMember || !teamMember.page) return
      await transitionExec(
        loaderPattern,
        async () => {
          teamMember?.page && (await updateMemberPage(teamMember?.page + 1))
        },
        duration
      )
    },
    [transitionExec, updateMemberPage]
  )

  const runGoToNextPage = useCallback(
    async (teamMember: GhostTeamMember) => {
      if (!teamMember || !teamMember.page) return
      await updateMemberPage(teamMember.page + 1)
    },
    [updateMemberPage]
  )

  const selectNextPresenter = useCallback(
    async ({
      team,
      selectNumber,
      selectUserIds,
      excludeUserIds,
      teamMemberList,
      currentMember,
      nextProgress,
    }: {
      team: GhostTeam
      selectNumber: number
      selectUserIds: string[]
      excludeUserIds?: string[]
      teamMemberList: GhostTeamMember[]
      currentMember: GhostTeamMember
      nextProgress: GhostProgress
    }) => {
      const timerItem = progressSelectTimerItem(team)
      if (!timerItem) return

      // 発表人数が設定人数になっていれば次のフェーズへ
      if (selectUserIds.length >= selectNumber) {
        await updateGhostTeam({
          input: {
            id: team.id,
            progress: nextProgress,
          },
        })
        if (currentMember) await runGoToNextPage(currentMember)
      } else {
        const pickedMember = pickupRandomMember(teamMemberList, [...selectUserIds, ...(excludeUserIds ?? [])])
        if (pickedMember) {
          await updateGhostTeam({
            input: {
              id: team.id,
              // 配列の先頭に次の発表者の id を追加
              [timerItem.userIds]: [pickedMember.userId, ...selectUserIds],
              [timerItem.started]: new Date().toISOString(),
            },
          })
        }
      }
    },
    [updateGhostTeam, runGoToNextPage]
  )

  return {
    runCreateGhostTeam,
    runGoToStart,
    runGoToSelectLeader,
    runDeleteGhostTeam,
    runGoToIntro,
    runCreateReports,
    runFromCompletePage,
    runVoteReports,
    runCreateResearch,
    runSubmitLikes,
    terminateGame,
    runcDeleteGhostTeamMember,
    forceProceed,
    runGoToNextPageWithTransition,
    runGoToNextPage,
    selectNextPresenter,
  }
}
