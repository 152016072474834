import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import actions from 'pages/hint/_shared/stores/actions'

// -----------------------------
// interface
// -----------------------------
export type IuseTabsProps = {
  onSwitchHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
  onChangePanelHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
  isTabsTips: () => boolean
  isTabsFaq: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useTabs = (initTab: string): IuseTabsProps => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [tab, setTab] = useState({
    tab: initTab,
  })

  /**
   * TabをToggleするハンドラー
   */
  const onSwitchHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    const element = e.currentTarget
    setTab({ tab: element.textContent ? element.textContent : '' })
  }, [])

  /**
   * パネルの切り替えとともにページを遷移する
   */
  const onChangePanelHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const element = e.currentTarget
    dispatch(actions.resetFetch())
    if (element.textContent === 'Tips') {
      history.push('/hint/tips')
      dispatch(actions.getPages({ currentPages: 1, pageAction: '' }))
    } else {
      history.push('/hint/faq')
      dispatch(actions.getPages({ currentPages: 1, pageAction: '' }))
    }
  }

  /**
   * TipsタブがTrueかどうかを判定
   */
  const isTabsTips = (): boolean => {
    return tab.tab === 'Tips' ? true : false
  }

  /**
   * FaqタブがTrueかどうかを判定
   */
  const isTabsFaq = (): boolean => {
    return tab.tab === 'お悩み掲示板' ? true : false
  }

  return {
    onSwitchHandler,
    onChangePanelHandler,
    isTabsTips,
    isTabsFaq,
  }
}
