import React from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles, Theme, styled, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'

import { Card } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useManageTeam } from 'pages/teams/hooks'
import { getEnableTeamTools, getTeamPlansByIds } from 'pages/teams/utils/teamPlan'
import { RelatedTeamPlan, TeamTool } from 'utils/generated'

import { constants } from 'assets'

type Props = {
  teamId: string
  cardStyles?: React.CSSProperties
}

export const TeamsTools: React.FC<Props> = (props) => {
  const { teamId, cardStyles } = props
  const classes = useStyles()
  const { currentTeam, refreshTeam, isAdmin, teamPlans } = React.useContext(HooksContext)
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const { control, setValue } = useForm()

  const { updateTeam } = useManageTeam()

  const tools = [
    { label: 'キックオフ・リフレクション', name: TeamTool.Building },
    { label: 'オバケ', name: TeamTool.Ghost },
    { label: 'カルテ', name: TeamTool.Karte },
    { label: 'TBチェック', name: TeamTool.BuildingCheck },
    // { label: '会議支援ツール', name: TeamTool.Meeting },
    // { label: 'オンボーディング', name: TeamTool.Onboarding },
  ]

  React.useEffect(() => {
    if (currentTeam) {
      const teamTools = currentTeam?.teamTools ?? []
      Object.entries(TeamTool).forEach(([, value]) => {
        setValue(value, teamTools.includes(value))
      })
    }
  }, [setValue, currentTeam])

  const getDefaultValue = (tool: TeamTool): boolean => {
    return (currentTeam?.teamTools ?? []).includes(tool) || false
  }

  const [loading, setLoading] = React.useState(false)
  const handleOnChange = async (tool: TeamTool, value: boolean) => {
    setLoading(true)
    if (currentTeam) {
      let updateTeamTools: TeamTool[] = Array.from(currentTeam?.teamTools ?? [])
      updateTeamTools = updateTeamTools.filter((n) => n !== tool)

      // Building（キックオフ）を ON にしたら、BuildingReview（リフレクション）も ON にする。
      if (value && tool === TeamTool.Building && !updateTeamTools.includes(TeamTool.BuildingReview)) {
        updateTeamTools.push(TeamTool.BuildingReview)
      }
      // Building（キックオフ）を OFF にしたら、BuildingReview（リフレクション）も OFF にする。
      if (!value && tool === TeamTool.Building) {
        updateTeamTools = updateTeamTools.filter((n) => n !== TeamTool.BuildingReview)
      }

      if (value) {
        updateTeamTools.push(tool)
      }

      const updated = await updateTeam({
        id: teamId,
        teamTools: updateTeamTools,
      })

      if (updated) {
        await refreshTeam()
      }
    }
    setLoading(false)
  }

  if (!isAdmin) {
    return <></>
  }

  const currentTeamPlans = currentTeam && currentTeam.plans ? (JSON.parse(currentTeam.plans) as RelatedTeamPlan[]) : []
  const plans = getTeamPlansByIds(teamPlans, currentTeamPlans)
  const enabledTools = getEnableTeamTools(plans)

  return (
    <>
      <Card
        headerTitle={'利用ツール'}
        loading={currentTeam ? false : true}
        ownStyles={{ ...cardStyles }}
        headerMb={isSmDown ? 8 : 0}
      >
        <p className={classes.description}>
          「利用する」にしたツールのみ、チーム画面の利用しているツールに表示されます。
        </p>

        <div className={classes.container}>
          {tools.map((tool, index) => {
            const editable = enabledTools.includes(tool.name)

            return (
              <div
                key={`tool-editor-${index}`}
                className={classNames([classes.tool, index >= 1 ? classes.afterFirst : ''])}
              >
                <div className={classes.toolContainer}>
                  <div className={classes.toolTitle}>{tool.label}</div>
                  {editable || getDefaultValue(tool.name) ? (
                    <Controller
                      name={tool.name}
                      control={control}
                      defaultValue={false}
                      render={({ onChange, value }) => (
                        <FormControlLabel
                          control={
                            <CocolaboSwitch
                              color="primary"
                              checked={value}
                              data-testid={`toolSwitch-${index}`}
                              onChange={(value) => {
                                if (!loading) {
                                  handleOnChange(tool.name, value.target.checked)
                                  onChange(value.target.checked)
                                }
                              }}
                            />
                          }
                          labelPlacement="start"
                          label=""
                        />
                      )}
                    />
                  ) : (
                    <span className={classes.switchLabel} style={{ color: '#A4ACAC', whiteSpace: 'pre-wrap' }}>
                      プランを設定してください
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Card>
    </>
  )
}

const CocolaboSwitch = styled(Switch)(({ theme }) => ({
  padding: 0,
  width: 60,
  height: 30,

  '& .MuiIconButton-root': {
    padding: '1px 3px',
  },

  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(26px)',
  },

  '& .MuiSwitch-colorPrimary.Mui-checked': {
    color: '#fff',
  },

  '& .MuiSwitch-track': {
    borderRadius: 22,
    opacity: '1 !important',
    backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_500,

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 7,
    },
  },

  '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
  },

  '& .MuiSwitch-thumb': {
    backgroundColor: constants.COLOR_WHITE,
    boxShadow: 'none',
    width: 24,
    height: 24,
    margin: 2,
  },
}))

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    // width: '60%',
    gap: '10%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },

  description: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 40,
  },

  toolContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  toolSubContainer: {
    marginTop: 24,
    paddingLeft: 1,
  },

  tool: {
    width: '40%',
    margin: ' 0 0 10px',
    padding: 0,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  afterFirst: {
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 0',
    },
  },

  toolTitle: {
    fontSize: 14,
    fontWeight: 600,
  },

  toolSubTitle: {
    fontSize: 12,
    fontWeight: 400,
  },

  switchLabel: {
    fontSize: 12,
    color: '#A4ACAC',
    marginRight: 10,
  },
}))
