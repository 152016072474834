import { differenceInDays, startOfWeek, addDays, format } from 'date-fns'

import { IActionItem } from '../../stores/setupaction/reducer'

export function startOfDayOfMondays(setupStartedAt: Date): Date {
  return differenceInDays(startOfWeek(setupStartedAt, { weekStartsOn: 1 }), setupStartedAt) > 0
    ? startOfWeek(setupStartedAt, { weekStartsOn: 1 })
    : startOfWeek(addDays(setupStartedAt, 7), {
        weekStartsOn: 1,
      })
}

export function getWeeksByDayOfMonday(dayOfMonday: Date, setupStartedAt: Date): number {
  let n = 0
  while (true && n < 99) {
    const startOfDayOfMonday = startOfDayOfMondays(setupStartedAt)
    if (format(addDays(startOfDayOfMonday, 7 * n), 'yyyy-MM-dd') === format(dayOfMonday, 'yyyy-MM-dd')) {
      return n + 1
    }

    n++
  }

  return -1
}

export function getWeeksByDay(day: Date, setupStartedAt: Date): number {
  let n = 1
  while (true && n < 99) {
    if (differenceInDays(addDays(setupStartedAt, 7 * n), day) >= 0) {
      return n
    }
    n++
  }

  return -1
}

export function getBasePeriod(setupStartedAt: Date): number {
  return differenceInDays(new Date(), setupStartedAt)
}

export function getWeeksBySetupStartedAt(setupStartedAt: Date): number {
  return Math.ceil(getBasePeriod(setupStartedAt) / 7)
}

export function getWeeksByBasePeriod(basePeriod: number): number {
  if (basePeriod === 0) {
    return 1
  }
  return Math.ceil(basePeriod / 7)
}

export function isStatusCloseByObject(basePeriod: number, object: IActionItem): boolean {
  if (object && object.period !== null && object.period !== undefined) {
    if (object.missionValues && object.missionValues.value) {
      if (object.missionValues.value < 0) {
        return isStatusCloseByPeriod(basePeriod, object.period)
      }
    } else if (!object.anketValues || (object.anketValues && !object.anketValues.total)) {
      return isStatusCloseByPeriod(basePeriod, object.period)
    }
  }
  return false
}

export function isStatusFailedByObject(basePeriod: number, object: IActionItem): boolean {
  if (object && object.period !== null && object.period !== undefined) {
    if (object.missionValues && object.missionValues.value) {
      if (object.missionValues.value < 0) {
        return isStatusFailedByPeriod(basePeriod, object.period)
      }
    } else if (!object.anketValues || (object.anketValues && !object.anketValues.total)) {
      return isStatusFailedByPeriod(basePeriod, object.period)
    }
  }
  return false
}

export function isStatusCloseByPeriod(basePeriod: number, period: number): boolean {
  if (basePeriod - period >= 6 && basePeriod - period < 10) {
    return true
  } else {
    return false
  }
}

export function isStatusFailedByPeriod(basePeriod: number, period: number): boolean {
  if (basePeriod - period >= 10) {
    return true
  } else {
    return false
  }
}
