import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'

import cautionIcon from 'pages/onboarding/assets/cautionIcon.svg'
import { TitleWithUpload, FormLabelMark, ButtonContainer } from 'pages/onboarding/components'
import { SubmitButton } from 'pages/onboarding/components/button-large'
import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { FormCharCount } from 'pages/onboarding/components/formCharCount'
import { CssTextField } from 'pages/onboarding/components/input'
import { callScreenModal } from 'pages/onboarding/components/modal/ScreenModal'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import {
  ActionCreateInput,
  ActionUpdateInput,
  useOnboardingAction,
  useOnboardingActionCreater,
  useOnboardingActionUpdater,
} from 'pages/onboarding/hooks/action'
import { useTeams } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingAction, OnboardingTeam, OnboardingActionCategory, OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from '../../../../assets/constants'
import { categories } from '../../feature/action/domain'
import { OnbContext } from '../PagesRoot'
import { TutorialProps } from '../tutorial/Tutorial'

import { OnbPages } from 'assets/pages'

// domain
export const maxLengths = { mission: 50, why: 100, what: 100, how: 100 }

const selectCategories = categories.slice(1)

/**
 * generates initial form state based on action.
 */
const initFormState = (editAction?: OnboardingAction | undefined) => {
  return editAction
    ? {
        // edit
        id: editAction.id,
        category: editAction.category,
        mission: editAction.mission,
        what: editAction.what,
        why: editAction.why,
        how: editAction.how,
        period: editAction.period,
        deadline: editAction.deadline || 0, // can be null for old records
      }
    : {
        // create
        category: OnboardingActionCategory.Category1,
        mission: '',
        what: '',
        why: '',
        how: '',
        importance: 0,
        period: 0,
        deadline: 7,
      }
}

const validate = (formState: ReturnType<typeof initFormState>) => {
  return Object.entries(maxLengths).every(([k, v]) => {
    const field = k as keyof typeof maxLengths
    return formState[field] && formState[field].length < v
  })
}

export const ActionManageFormBloc = {
  useAdapter: () => {
    const { actionId } = useParams<{ actionId?: string }>()
    const {
      teamId,
      setTeamId,
      teamMember: { userId },
    } = useContext(OnbContext)
    const { teams: teamsOfAdminRole } = useTeams(OnboardingTeamMemberRole.Admin)
    const { teams: teamsOfSupporterRole } = useTeams(OnboardingTeamMemberRole.Supporter)
    const teams: OnboardingTeam[] = [...teamsOfAdminRole, ...teamsOfSupporterRole]
    const isEditing = Boolean(userId && actionId)
    const { action: editAction } = useOnboardingAction(actionId, userId)

    const createHandler = useOnboardingActionCreater()
    const updateHandler = useOnboardingActionUpdater()

    return {
      teamId,
      setTeamId,
      teams,
      isEditing,
      editAction,
      createHandler,
      updateHandler,
    }
  },
}
type OwnProps = {
  tutorialSkipFunc?: () => void
  tutorial?: TutorialProps
}

export const Index: React.FC<OwnProps> = ({ tutorial, tutorialSkipFunc }) => {
  // deps
  const { teamId, setTeamId, teams, isEditing, editAction, createHandler, updateHandler } =
    ActionManageFormBloc.useAdapter()
  const theme = useTheme()
  const isBreakPointsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const [formState, setFormState] = useState(initFormState())
  const [formErr, setFormErr] = useState<React.ReactNode | null>(null)
  useEffect(() => {
    setFormState(initFormState(editAction))
  }, [editAction, setFormState])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value })
  }

  //handleErrMsg
  useEffect(() => {
    if (validate(formState)) {
      setFormErr(null)
    } else {
      setFormErr(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: constants.COLOR_ONBOARDING_ALERT,
            fontSize: 14,
            margin: '0 0 8px',
          }}
        >
          <img src={cautionIcon} alt={'cautionIcon'} style={{ display: 'inline-block', margin: '0 4px 0 0 ' }} />
          必須項目を入力してください。
        </div>
      )
    }
  }, [formState])

  const history = useOnbHistory()
  const back2manage = useCallback(() => {
    history.push(OnbPages.ActionManage, { teamId })
  }, [history, teamId])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const payload = {
        ...formState,
        point: 3,
        teamId,
      }

      let res, mes
      if (editAction) {
        res = await updateHandler(payload as ActionUpdateInput)
        mes = 'アクションが編集されました'
      } else {
        res = await createHandler(payload as ActionCreateInput)
        mes = 'アクションが追加されました'
      }

      if (tutorial) {
        tutorial.next && tutorial.next()
        return
      }

      if (res) {
        back2manage()
        callScreenModal({ head: mes })
      }
    },
    [formState, back2manage, createHandler, editAction, teamId, updateHandler, tutorial]
  )

  const classes = useStyles()

  return (
    <>
      {isEditing ? (
        <TitleWithBack title={'アクション編集'} omitBack={Boolean(tutorial)} />
      ) : (
        <TitleWithUpload
          teamId={teamId}
          renderKey={'action-add'}
          isTutorial={Boolean(tutorial)}
          ownStyles={{ margin: '27px 20px' }}
        />
      )}
      <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}>
        <div className={classes.root}>
          <div className={classes.label}>追加するチーム</div>
          <Dropdown
            items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
            value={teamId}
            handleOnClick={(value: any) => setTeamId(value)}
            keyPrefix={'action-team-select'}
            teamLabel={teams.find((t) => t.id === teamId)?.title || ''}
            border={constants.COLOR_ONBOARDING_GRAY_LIGHT}
          />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            タイトル
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <CssTextField
            InputProps={{ classes: { input: classes.textInput } }}
            name={'mission'}
            variant="outlined"
            placeholder="先輩に感謝を伝えよう"
            onChange={handleChange}
            value={formState.mission}
          />
          <FormCharCount length={formState.mission.length} maxLength={maxLengths.mission} />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            目的
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <CssTextField
            InputProps={{ classes: { input: classes.textInput } }}
            multiline
            minRows={7}
            name={'why'}
            placeholder="先輩から教えてもらったことがプラスになったと伝えることで、先輩にもっとサポートしたいと思ってもらうようになるため"
            onChange={handleChange}
            value={formState.why}
          />
          <FormCharCount length={formState.why.length} maxLength={maxLengths.why} />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            アクション
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <CssTextField
            InputProps={{ classes: { input: classes.textInput } }}
            multiline
            minRows={7}
            name={'what'}
            placeholder="先輩から教えてもらったことを活かし、その旨の感謝を伝える"
            onChange={handleChange}
            value={formState.what}
          />
          <FormCharCount length={formState.what.length} maxLength={maxLengths.what} />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            達成基準
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <CssTextField
            InputProps={{ classes: { input: classes.textInput } }}
            multiline
            minRows={7}
            name={'how'}
            placeholder="先輩に配属1ヶ月以内に1回以上感謝の言葉を伝えられたらOK"
            onChange={handleChange}
            value={formState.how}
          />
          <FormCharCount length={formState.how.length} maxLength={maxLengths.how} />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>カテゴリ</div>

          <Dropdown
            items={selectCategories}
            value={formState.category}
            handleOnClick={(value: any) => {
              setFormState({
                ...formState,
                category: value as OnboardingActionCategory,
              })
            }}
            keyPrefix={'action-category-select'}
            teamLabel={selectCategories.find((c) => c.value === formState.category)?.label || ''}
            border={constants.COLOR_ONBOARDING_GRAY_LIGHT}
          />
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            メンバー参加から何日後に追加しますか？
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <div className={classes.dayFieldWrap}>
            <CssTextField
              value={formState.period}
              InputProps={{
                classes: { input: classes.textInput },
                inputProps: { min: 0, style: { textAlign: 'right' } },
              }}
              type="number"
              name="period"
              onChange={handleChange}
            />
            <span>日後に追加</span>
          </div>
        </div>

        <div className={classes.root}>
          <div className={classes.label}>
            アクション追加から何日後を期限としますか？
            <FormLabelMark
              markType={'required'}
              ownStyles={{
                margin: '0 0 0 8px',
              }}
            />
          </div>
          <div className={classes.dayFieldWrap}>
            <CssTextField
              value={formState.deadline}
              InputProps={{
                classes: { input: classes.textInput },
                inputProps: { min: 0, style: { textAlign: 'right' } },
              }}
              type="number"
              name="deadline"
              onChange={handleChange}
            />
            <span>日後が期限</span>
          </div>
        </div>
        {/* only use margin for SP */}
        {isBreakPointsDown || Boolean(tutorial) ? <div style={{ height: 150 }}></div> : <></>}
        <ButtonContainer
          buttonWidth={250}
          errMsg={formErr}
          button={
            <SubmitButton
              placeholder={isEditing ? '保存する' : '追加する'}
              disabled={!validate(formState)}
              submit
              ownStyles={{ margin: 0 }}
            />
          }
          isCancelEditButton
          CancelEditButtonProps={
            tutorial
              ? { text: 'スキップ', onClick: tutorialSkipFunc }
              : { text: '編集をキャンセル', onClick: back2manage }
          }
        />
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
    padding: '12px 16px',
  },

  csvWrapper: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    alignItems: 'center',
    background: constants.COLOR_WHITE,
    [theme.breakpoints.down('sm')]: {
      margin: '0 16px',
    },

    margin: '0 8px',
  },
  csvText: {
    fontWeight: 'bold',
    marginLeft: 8,
    color: constants.COLOR_MAIN_NEW,
  },

  header: {
    padding: '16px 0 8px 0',
    fontSize: 16,
    fontWeight: 'bold',
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
    lineHeight: '1em',
  },

  textInput: {
    backgroundColor: '#fff',
    fontSize: 14,
    lineHeight: '24px',
    padding: 16,
    borderRadius: 8,
    minHeight: 48,
    boxSizing: 'border-box',
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    '&:focus': {
      border: `1.5px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    },
  },

  dayFieldWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& div:first-child': {
      width: 100,
    },
    '& > span': {
      display: 'block',
      marginLeft: 16,
    },
  },
}))

export default Index
