import * as React from 'react'

import { StyleSheet, View } from '@react-pdf/renderer'

import { AutoWrapLine } from './AutoWrapLine'
import DotBlue from './svg/DotBlue'
import DotYellow from './svg/DotYellow'

interface IOwnProps {
  dotColor: 'yellow' | 'blue'
  text: string
}

const styles = StyleSheet.create({
  commitment: {
    flexDirection: 'row',
    fontSize: 9,
    marginTop: 4,
  },
  dot: {
    width: 8,
    height: 8,
    marginVertical: 'auto',
    marginHorizontal: 0,
  },
  el: {
    marginLeft: 16,
  },
})

export const Commitment: React.FC<IOwnProps> = ({ dotColor, text }) => {
  return (
    <View style={styles.commitment}>
      <View style={styles.dot}>{dotColor === 'blue' ? DotBlue({}) : DotYellow({})}</View>
      <View style={styles.el}>
        <AutoWrapLine text={text} />
      </View>
    </View>
  )
}
