import React, { useContext, useState } from 'react'

import { Header, Body, Container } from 'pages/onboarding/components'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMemberRole } from 'utils/generated'

import { OnbContext, OnbStore } from '../PagesRoot'

import { Intro } from './components/Intro'
import LeaderTutorial from './components/Leader'
import MemberTutorial from './components/Member'
import SupporterTutorial from './components/Supporter'



import { TxInfoTop, TxInfoBottom } from './assets/text'
import { OnbPages } from 'assets/pages'


const Tutorial = () => {
  const onbStore = useContext(OnbContext)

  // handle tutorial stages by this state. Each role tutorial has its own stages.
  const [tutorialStage, setTutorialStage] = useState(1)

  // when stage reaches the end of each tutorial, call endTutorial below.
  const history = useOnbHistory()
  const { updateTeamMember } = useTeamManage()
  const endTutorial = async () => {
    setTutorialStage(0) // unmount modal immediately to prevent blinking
    await updateTeamMember({ teamId: onbStore.teamId, readTutorialAt: new Date().toISOString() })
    if (onbStore.teamMember.role === OnboardingTeamMemberRole.Member) {
      history.push(OnbPages.DailyCheck, { teamId: onbStore.teamId })
    } else history.push(OnbPages.Timeline, { teamId: onbStore.teamId })
  }

  const props = {
    stage: tutorialStage,
    setStage: setTutorialStage,
    endTutorial,
    ...onbStore,
  }

  if (tutorialStage < 4 && tutorialStage > 0) {
    return <Intro {...props} />
  } else {
    let RoleTutorial
    switch (onbStore.teamMember.role) {
      case OnboardingTeamMemberRole.Admin:
        RoleTutorial = <LeaderTutorial {...props} />
        break
      case OnboardingTeamMemberRole.Member:
        RoleTutorial = <MemberTutorial {...props} />
        break
      case OnboardingTeamMemberRole.Supporter:
        RoleTutorial = <SupporterTutorial {...props} />
        break
    }

    return (
      <Container>
        <Header teamId={onbStore.teamId} />
        <Body teamId={onbStore.teamId}>{RoleTutorial}</Body>
      </Container>
    )
  }
}

// TYPES //

export type TutorialStateProps = {
  stage: number
  setStage: (stage: number) => void
  endTutorial: () => void
}

export type RoleTutorialProps = TutorialStateProps & OnbStore

/**
 * props for each page body component, which each tutorial component uses as child
 *
 * toggle the usages by just providing properties on this object.
 * (tooltip, modal or show-nothing, and tutorial stage handling)
 */
export type TutorialProps = {
  tooltip?: TutorialToolTip
  modal?: TutorialModal
  next?: () => void
  skip?: () => void
  notRelative?: boolean
  scene?: 'actionAchieved'
}

type Coords = { x: number; y: number }

export type TutorialToolTip = {
  head?: string
  body?: string
  offset: Coords
  center?: boolean
  pointer: {
    position: 'top' | 'bottom'
    display?: 'none'
    offset?: Coords
  }
  onClickRef: () => void
  onWhat?: 'post' | 'modal' | 'done' | 'like' | 'comment' | 'sample' // pass this to toggle ref element within the same component
  scrollTarget?: 'tooltip'
  autoHeight?: boolean
  msg?: { top?: keyof typeof TxInfoTop; bottom?: keyof typeof TxInfoBottom }
  button?: { text: string; onClick: () => void }
  ownControl?: 'timelineSample' | 'actionAchieved' | 'actionSelect' // handle additional control like events,css...etc
}

export type TutorialModal = {
  head?: string
  body?: string
  buttonText?: string
  onClickButton?: () => void
  altButtonText?: string
  onClickAltButton?: () => void
  outerText?: string
}

export default Tutorial
