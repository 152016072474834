import React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share'

import { constants } from 'assets'

const shareItem = {
  title: 'チームの目に見えない課題を“見える化”するフレームワーク『職場のオバケ探し』',
  url: 'https://cocolabo.club/obakesagashi',
}

export const SNSShare: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>SNSでシェアしてみよう</h2>

      <div className={classes.body}>
        <p className={classes.message}>
          職場の上司や同僚にも「職場のオバケ探し」を共有してみんなで
          <br />
          職場のオバケを探してみましょう（あなたが選んだオバケの情報は共有されません）
        </p>
        <div className={classes.socials}>
          <TwitterShareButton
            title={shareItem.title}
            url={shareItem.url}
            hashtags={['職場のオバケ探し,Cocolabo,組織開発,エンゲージメント,推せる職場']}
          >
            <span className={classNames(classes.shareIcon, 'x')}>
              <img src={`${process.env.PUBLIC_URL}/img/icon-x-white.svg`} alt="Xでシェア" />
            </span>
          </TwitterShareButton>
          {/* localhostのURLを渡した際は、Facebookでのシェアに失敗する */}
          <FacebookShareButton url={shareItem.url} hashtag="#職場のオバケ探し">
            <span className={classes.shareIcon}>
              <img src={`${process.env.PUBLIC_URL}/img/icon-facebook.svg`} alt="Facebookでシェア" />
            </span>
          </FacebookShareButton>
          {/* スマートフォンでのシェア内容には、titleが含まれず、urlのみとなる */}
          <LineShareButton title={shareItem.title} url={shareItem.url}>
            <span className={classes.shareIcon}>
              <img src={`${process.env.PUBLIC_URL}/img/icon-line.png`} alt="LINEでシェア" />
            </span>
          </LineShareButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 25px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 8px',
      },
    },
    heading: {
      margin: 0,
      width: '100%',
      color: '#000',
      fontFamily: '"MOBO SemiBold"',
      fontWeight: 600,
      fontSize: 40,
      lineHeight: 1,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    body: {
      display: 'flex',
      columnGap: 8,
      marginTop: 32,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 24,
        marginTop: 16,
      },
    },
    message: {
      flex: 1,
      margin: 0,
      whiteSpace: 'pre-wrap',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 1.6,
      [theme.breakpoints.down('sm')]: {
        '& br': {
          display: 'none',
        },
      },
    },
    socials: {
      display: 'flex',
      alignItems: 'flex-start',
      columnGap: 16,
      '& button': {
        flexShrink: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 16,
        borderLeft: `1px solid #D9D9D9`,
      },
      [theme.breakpoints.down('sm')]: {
        columnGap: 24,
      },
    },
    shareIcon: {
      display: 'grid',
      placeItems: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      '& img': {
        width: '100%',
        height: '100%',
      },
      '&.x': {
        background: '#000',
        '& img': {
          width: 24,
          height: 'auto',
        },
      },
    },
  }),
  { name: 'SNSShare' }
)
