import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Grid, Avatar, CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import format from 'date-fns/format'

import NoImage from 'pages/onboarding/assets/noImage.svg'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { convertUrl } from 'pages/onboarding/utils/convertText'
import { OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from '../../../../assets/constants'
import { OnbPages } from '../../../../assets/pages'
import { DownloadDrop } from '../../components/file/DownloadDrop'
import { useFiles } from '../../utils/files'
import { TargetOutput } from '../member/components/TargetOutput'
import { OnbContext } from '../PagesRoot'

import ArrowLeft from './assets/arrow-left.svg'
import Edit from './assets/edit.svg'

export const MemberBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { userId: targetUserId } = useParams<{ userId: string }>()

    // if targetUserId is undefined, it retrieves self user info.
    const { teamMember: targetMemberInfo, loading } = useTeamMember(teamId, targetUserId)
    const { file } = useFiles(
      targetMemberInfo?.targetFileNames ? targetMemberInfo?.targetFileNames[0] : undefined,
      teamId,
      targetMemberInfo?.userId
    )

    return {
      teamId,
      teamMember,
      targetUserId: targetUserId || teamMember.userId, // memberlist detail || my page
      targetMemberInfo,
      loading,
      file,
    }
  },
}

const Index: React.FC = () => {
  // deps
  const {
    teamId,
    teamMember, // the viewer
    targetUserId,
    targetMemberInfo, // target member detail shown on this component
    loading,
    file,
  } = MemberBloc.useAdapter()

  const { imageUrl, nickname, email, user, position, description, target, startedAt } = useMemo(
    () => ({
      imageUrl: targetMemberInfo?.imageUrl,
      nickname: targetMemberInfo?.nickname,
      email: targetMemberInfo?.email,
      user: targetMemberInfo?.user,
      position: targetMemberInfo?.position,
      description: targetMemberInfo?.description,
      target: targetMemberInfo?.target,
      startedAt: targetMemberInfo?.startedAt,
    }),
    [targetMemberInfo]
  )

  // UI condition
  const isNotMyOwnPage = targetUserId !== teamMember.userId

  const classes = useStyles()

  const history = useOnbHistory()

  if (loading) return <CircularProgress />
  return (
    <>
      <>
        <Box className={classes.title}>
          {isNotMyOwnPage ? (
            <>
              <div
                className={classes.toBack}
                onClick={() => {
                  history.goBack()
                }}
              >
                <img src={ArrowLeft} alt="arrow-left" />
                戻る
              </div>
              <div className={classes.titleText}>メンバー詳細</div>
            </>
          ) : (
            <div className={classes.pageTitle}>
              <>マイページ</>
            </div>
          )}
        </Box>
      </>
      {targetMemberInfo ? (
        <>
          <Box className={classes.list}>
            <div className={classes.topContainer}>
              <div className={classes.profileWrapper}>
                <Box
                  className={classes.root}
                  style={{
                    borderRadius: isNotMyOwnPage ? '8px 8px 0 0' : undefined,
                  }}
                >
                  <Box className={classes.avatar}>
                    <Avatar alt="avatar" src={imageUrl || NoImage} style={{ height: 80, width: 80 }} />
                  </Box>
                </Box>
                <div className={classes.profileContainer}>
                  <Box className={classNames([classes.root, classes.name])}>
                    <Grid>
                      <Box className={classes.nickname}>{user ? nickname ?? '' : email}</Box>
                      <Box>
                        <span className="__position">{position ?? '役職/部署'}</span>
                      </Box>
                      <Box className={classes.labelWrapper}>
                        <Box className={classes.role__label}>
                          <div>{teamMemberRoleLabel(targetMemberInfo?.role)}</div>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </div>
              </div>

              <div className={classes.profileLinkWrapper}>
                {targetUserId === teamMember.userId || targetUserId === undefined ? (
                  <>
                    {isNotMyOwnPage ? <div style={{ paddingTop: 37 }}></div> : <></>}
                    <Box className={classes.edit}>
                      <Box
                        className={classes.editContainer}
                        onClick={() => history.push(OnbPages.MypageEdit, { teamId })}
                      >
                        <img src={Edit} alt="edit" />
                        <span className="__txt">プロフィールを編集する</span>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <Box
              className={classNames([classes.container, classes.separator])}
              style={{
                borderRadius: isNotMyOwnPage ? '0 0 8px 8px' : undefined,
              }}
            >
              <Box
                className={!isNotMyOwnPage ? classes.description : `${classes.description} ${classes.memberListBottom}`}
              >
                <div className="__name" dangerouslySetInnerHTML={{ __html: convertUrl(description ?? '自己紹介') }} />
              </Box>
              {targetMemberInfo?.role === OnboardingTeamMemberRole.Member && (
                <div className={classes.targetWrapper}>
                  <Box>
                    <TargetOutput value={target} isSideBySide isNotMyOwnPage={isNotMyOwnPage} />
                  </Box>
                  <div>{file && <DownloadDrop uploadedFile={file} />}</div>
                </div>
              )}
              <div className={classes.mailWrapper}>
                <span className={classes.textLabel}>参加登録日</span>
                <span className={classes.joinDay}>
                  {startedAt ? format(Date.parse(startedAt), 'yyyy年MM月dd日') : '____'}
                </span>
              </div>
              {!isNotMyOwnPage && teamMember.role === OnboardingTeamMemberRole.Admin && (
                <>
                  <Box>
                    <div className={classes.contractWrapper}>
                      <span className={classes.textLabel}>契約日</span>
                      <span className={classes.contractDay}>
                        {teamMember.user?.onboardingContractAt
                          ? format(Date.parse(teamMember.user?.onboardingContractAt), 'yyyy年MM月dd日')
                          : '____'}
                      </span>
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <div className={classes.errorMessage}>
          チーム情報を取得できませんでした。
          <br />
          更新もしくは、一度ログアウトの上、再度ログインをしてください。
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    minHeight: '100vh',
  },

  container: {
    boxSizing: 'border-box',
    padding: '24px 16px',
    backgroundColor: constants.COLOR_WHITE,
  },

  description: {
    fontSize: 14,
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
    '& .__name': {
      lineHeight: 1.6,
      marginBottom: 27,
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      '& a': {
        color: constants.COLOR_MAIN,
        textDecoration: 'underline',
      },
    },
    '& .__nameSub': {
      lineHeight: 1.6,
      fontWeight: 'bold',
      marginBottom: 27,
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
    },
  },
  memberListBottom: {
    marginBottom: 3,
  },

  notificate: {
    marginBottom: 50,
    '& .__switch': {
      marginBottom: 27,
    },
  },

  title: {
    position: 'relative',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    boxSizing: 'border-box',
    padding: '24px 0',
    margin: '0 16px',
  },

  pageTitle: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  titleText: {
    display: 'inline-block',
    textAlign: 'left',
    maxWidth: 'calc(100% - 96px)',
    wordBreak: 'break-all',
  },

  toBack: {
    position: 'absolute',
    top: 22,
    left: 5,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
    fontWeight: 'bold',
    padding: 5,
    cursor: 'pointer',
    '& img': {
      position: 'relative',
      top: 1,
      right: 8,
    },
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    marginBottom: 20,
  },

  root: {
    display: 'flex',
    padding: '20px 16px',
    backgroundColor: constants.COLOR_WHITE,
  },

  name: {
    fontSize: 18,
    fontWeight: 'bold',
    justifyContent: 'center',
    '& .__position': {
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontWeight: 'initial',
      fontSize: 10,
    },
  },

  nickname: {
    wordBreak: 'break-all',
  },

  textLabel: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
  },
  mailTime: {
    marginLeft: 20,
  },
  mailNotify: {
    marginLeft: 46,
  },
  joinDay: {
    marginLeft: 46,
  },
  target: {
    marginLeft: 85,
  },

  profileWrapper: {
    display: 'flex',
    width: '100%',
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: constants.COLOR_WHITE,
  },
  profileLinkWrapper: {
    backgroundColor: constants.COLOR_WHITE,
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    justifyContent: 'end',
  },
  topContainer: {
    display: 'flex',
    flexFlow: 'column',
    background: constants.COLOR_WHITE,
  },

  avatar: {
    position: 'relative',
    margin: '4px auto 0 auto',
  },

  labelWrapper: {
    display: 'flex',
    marginTop: 8,
  },

  role__label: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 10,
    paddingLeft: 8,
    paddingRight: 8,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    height: '20px',
    fontWeight: 'bold',
  },

  setRight: {
    float: 'right',
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontWeight: 'bold',
  },

  separator: {
    borderTop: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
  },

  edit: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: constants.COLOR_ONBOARDING_MAIN,
    '& .__txt': {
      marginLeft: 16,
      fontWeight: 'bold',
    },
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 26,
    marginRight: 24,
  },
  mailWrapper: {
    display: 'flex',
    marginBottom: 27,
  },
  contractWrapper: {
    display: 'flex',
  },
  contractDay: {
    marginLeft: 80,
  },

  targetWrapper: {
    marginBottom: 15,
  },
  errorMessage: {
    padding: '24px',
  },
}))

export default Index
