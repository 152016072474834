import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'

import { NoStyleButton } from 'components/NoStyleButton'
import { DescriptionTools } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useFormatTeamBuildingActionDates } from 'pages/teams/hooks/formatTeamBuildingActionDates'
import { useTeamBuildingActionDates } from 'pages/teams/hooks/teamBuildingActionDates'
import { InlineEditInput } from 'pages/teams/pages/_tools/buildings/components'
import {
  TeamBuilding,
  TeamBuildingActionDate,
  TeamBuildingActionDateStatus,
  TeamBuildingStatus,
  TeamSummaryBuilding,
} from 'utils/generated'

import { IdealTeamDialog } from './IdealTeamDialog'
import { PieChartForKickoffTab } from './PieChartForKickoffTab'
import { TeamBuildingInProgress } from './TeamBuildingInProgress'

import { constants, Pages } from 'assets'
import { replacePathParams } from 'assets/history'

type Props = {
  teamId: string
  summary: TeamSummaryBuilding
  teamBuilding?: TeamBuilding
  handleUpdateTeamBuilding: any
}

export const SummaryTeamBuilding: React.FC<Props> = ({ teamId, summary, teamBuilding, handleUpdateTeamBuilding }) => {
  const classes = useStyles()

  const [isOpenIdealTeamDialog, setIsOpenIdealTeamDialog] = React.useState(false)
  const { currentTeam, isAdmin, isLeader } = React.useContext(HooksContext)
  const { teamBuildingActionDates } = useTeamBuildingActionDates(currentTeam?.id)
  const { completedActionList, loomingDueActionList, overDueActionList, todaysDueActionCount, overDueActionCount } =
    useFormatTeamBuildingActionDates(teamBuildingActionDates)

  const onChangeGoal = async (text: string) => {
    if (teamBuilding && teamBuilding.resultQ3) {
      const resultQ3 = JSON.parse(teamBuilding.resultQ3)
      resultQ3.answer = text

      return handleUpdateTeamBuilding({
        id: teamBuilding.id,
        resultQ3: JSON.stringify(resultQ3),
      })
    }
  }

  const onChangeCodeOfConduct = async (text: string, index: number) => {
    if (teamBuilding && teamBuilding.resultQ4) {
      const resultQ4 = JSON.parse(teamBuilding.resultQ4)
      resultQ4.items[index].answer = text

      return handleUpdateTeamBuilding({
        id: teamBuilding.id,
        resultQ4: JSON.stringify(resultQ4),
      })
    }
  }

  const handleOpenKickoffPage = () => {
    window.open(replacePathParams(Pages.TeamsToolBuilding, { teamId }), '_team-building')
  }

  const handleOpenIdealModal = () => {
    setIsOpenIdealTeamDialog(true)
  }

  if (!teamBuilding?.status || teamBuilding.status === TeamBuildingStatus.Created) {
    return (
      <DescriptionTools
        title="チーム登録が完了したらキックオフをはじめましょう。"
        recommendedTime="（推奨時間：60分）"
        description={
          'チームを登録を終えたらまずはキックオフをはじめましょう。\nチーミングに重要なことを成長のタイミングに合わせて順次提示していきます。\n進捗が100％に近づく頃には、チーム力も100％になっているに違いありません。'
        }
        buttonLabel="キックオフを始める"
        handleOpenTool={handleOpenKickoffPage}
      />
    )
  }

  if (teamBuilding.status !== TeamBuildingStatus.Completed) {
    return <TeamBuildingInProgress teamBuilding={teamBuilding} teamId={teamId} />
  }

  return (
    <div className={classes.root}>
      <div className={`${classes.headingWrapper} ${classes.teamStatusHeading}`}>
        <span className={classes.heading}>チーム状況</span>
        <Link
          to={replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId })}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <span>編集</span>
          <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow.svg`} alt="キックオフダッシュボードページへ移動" />
        </Link>
      </div>

      <div className={classes.teamStatusContainer}>
        <div className={classes.verticalStack}>
          <div className={`${classes.goalWrapper} ${classes.box}`}>
            <div className={classes.heading}>チーム目標</div>
            {summary?.goal && (
              <div>
                {isAdmin || isLeader ? <InlineEditInput text={summary.goal} onSubmit={onChangeGoal} /> : summary.goal}
              </div>
            )}
          </div>

          <div className={classes.idealWrapper}>
            <NoStyleButton onClick={handleOpenIdealModal} type="button" className={classes.openIdealModalButton}>
              <span className={classes.heading}>個人で選んだ理想のチーム状態</span>
              <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow.svg`} alt="個人で選んだ理想のチーム状態を表示" />
            </NoStyleButton>
            <IdealTeamDialog isOpen={isOpenIdealTeamDialog} handleCloseDialog={() => setIsOpenIdealTeamDialog(false)} />
          </div>

          <div className={`${classes.codesWrapper} ${classes.box}`}>
            <div className={classes.heading}>チーム行動規範</div>
            <div>
              <ol className={classes.codes}>
                {summary?.codeOfConducts?.map((code, index) => (
                  <li className={classes.code} key={`summary-table-building-codes-${index}`}>
                    {isAdmin || isLeader ? (
                      <InlineEditInput text={code} onSubmit={(text) => onChangeCodeOfConduct(text, index)} />
                    ) : (
                      `${code}`
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>

        <div className={classes.box}>
          <div className={classes.headingWrapper}>
            <span className={classes.heading}>チームのタスク進捗状況</span>
          </div>
          <div className={classes.progressContentsWrapper}>
            <PieChartForKickoffTab numerator={summary?.progress ?? 0} pcSize={140} mdSize={120} spSize={80} />
            <div className={classes.actionDueDateWrapper}>
              <div className={classes.actionDueList}>
                <div>本日期限 {todaysDueActionCount}件</div>
                <div>期限切れアクション {overDueActionCount}件</div>
              </div>
              <div className={classes.detailsLinkContainer}>
                <Link
                  to={replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <span>詳細はこちら</span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svg/arrow.svg`}
                    alt="キックオフダッシュボードページへ移動"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionList heading="直近のメンバーのアクション／完了したアクション" actionList={completedActionList} />
      <ActionList heading="期日が近いアクションリスト" actionList={loomingDueActionList} />
      <ActionList heading="期日が過ぎているアクションリスト" actionList={overDueActionList} />
    </div>
  )
}

type ActionListProps = {
  heading: string
  actionList?: TeamBuildingActionDate[]
}

const ActionList: React.FC<ActionListProps> = ({ heading, actionList }) => {
  const classes = useStyles()
  const DEFAULT_MAX_SHOW_COUNT = 5

  const [isShowAllAction, setIsShowAllAction] = React.useState(false)

  if (!actionList || !actionList.length) return null

  return (
    <>
      <div className={classes.headingWrapper}>
        <span className={classes.heading}>{heading}</span>
      </div>
      <div className={classes.actionCardsWrapper}>
        {actionList.slice(0, DEFAULT_MAX_SHOW_COUNT).map((action) => {
          return (
            <div key={action.id} className={classes.actionCard}>
              <ActionLabel actionDate={action} />
              <div className={classes.actionTask}>{action.name}</div>
            </div>
          )
        })}
        {isShowAllAction &&
          actionList.slice(DEFAULT_MAX_SHOW_COUNT).map((action) => {
            return (
              <div key={action.id} className={classes.actionCard}>
                <ActionLabel actionDate={action} />
                <div className={classes.actionTask}>{action.name}</div>
              </div>
            )
          })}
        {actionList.length > DEFAULT_MAX_SHOW_COUNT && (
          <div className={classes.showHandleButtonContainer}>
            <NoStyleButton
              onClick={() => setIsShowAllAction((prevIsShow) => !prevIsShow)}
              type="button"
              className={classes.ShowHandleButton}
            >
              {isShowAllAction ? (
                <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow-up.svg`} alt="" className={classes.arrowIcon} />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow-down.svg`} alt="" className={classes.arrowIcon} />
              )}
              {isShowAllAction ? '表示数を減らす' : 'もっと表示する'}
            </NoStyleButton>
          </div>
        )}
      </div>
    </>
  )
}

type ActionLabelProps = {
  actionDate: TeamBuildingActionDate
}

const ActionLabel: React.FC<ActionLabelProps> = ({ actionDate }) => {
  const classes = useStyles()
  const now = dayjs()
  const actionDateDay = dayjs(actionDate.date)
  const label = actionDateDay.format('YYYY/MM/DD')

  if (actionDate.status === TeamBuildingActionDateStatus.Completed) {
    // 実施済み
    return (
      <div className={`${classes.actionListItemLabel} primary`}>
        <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_check.svg`} alt="" />
        <span>{label}</span>
      </div>
    )
  } else if (now.isAfter(actionDateDay)) {
    // 期限が過ぎているもの
    return (
      <div className={`${classes.actionListItemLabel} red`}>
        <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_alert_white.svg`} alt="" />
        <span>{label}</span>
      </div>
    )
  } else if (now.isAfter(actionDateDay.subtract(1, 'day')) || now.isSame(actionDateDay, 'day')) {
    // 期限が迫っているもの
    return (
      <div className={`${classes.actionListItemLabel} yellow`}>
        <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_alert_black.svg`} alt="" />
        <span>{label}</span>
      </div>
    )
  } else {
    // デフォルト
    return (
      <div className={`${classes.actionListItemLabel} light_gray`}>
        <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_clock.svg`} alt="" />
        <span>{label}</span>
      </div>
    )
  }
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // 共通
    root: {
      fontSize: 12,
    },
    box: {
      padding: 16,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
        border: `none`,
      },
    },
    headingWrapper: {
      marginBottom: 16,
    },
    heading: {
      color: '#000000',
      fontSize: 12,
      fontWeight: 'bold',
    },
    link: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      cursor: 'pointer',
      fontWeight: 'bold',

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    // チーム状況
    teamStatusHeading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    teamStatusContainer: {
      display: 'grid',
      gap: 16,
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginBottom: 44,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
    verticalStack: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },

    // チーム目標
    goalWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      gap: 4,
    },

    // 個人で選んだ理想のチーム状態
    idealWrapper: {
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      '&:hover': {
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      },

      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
        border: 'none',

        '&:hover': {
          backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
        },
      },
    },
    openIdealModalButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 16,
      width: '100%',
    },

    // チーム行動規範
    codesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 16,
    },
    codes: {
      margin: 0,
      '& li::marker': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        fontWeight: 'bold',
      },
    },
    code: {
      paddingBottom: 8,
    },

    // チームのタスク進捗状況
    progressContentsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 24,

      [theme.breakpoints.down('md')]: {
        gap: 16,
      },
    },
    actionDueDateWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      width: '100%',

      [theme.breakpoints.down('md')]: {
        gap: 20,
      },
      [theme.breakpoints.down('sm')]: {
        gap: 10,
      },
    },
    actionDueList: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    detailsLinkContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    // アクションリスト
    actionCardsWrapper: {
      marginBottom: 26,

      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    actionCard: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      padding: 16,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderLeft: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRight: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      '&:first-child': {
        borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },

      [theme.breakpoints.down('sm')]: {
        gap: 8,
        backgroundColor: constants.COLOR_WHITE,
        border: `none`,
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

        '&:first-child': {
          borderTop: 'none',
        },
        '&:last-child': {
          borderBottom: `none`,
        },
      },
    },
    actionListItemLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '3px 5px 3px 2px',
      whiteSpace: 'nowrap',
      borderRadius: 50,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
      color: constants.COLOR_WHITE,

      '& > span': {
        marginLeft: '4px',
      },

      '&.red': {
        backgroundColor: constants.COLOR_TEAMBUILDING_RED,
      },

      '&.yellow': {
        color: constants.COLOR_TEAMBUILDING_TEXT,
        backgroundColor: constants.COLOR_TEAMBUILDING_YERROW,
      },

      '&.light_gray': {
        color: constants.COLOR_TEAMBUILDING_TEXT,
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_ON,
      },
    },
    actionTask: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    // もっと表示するボタン
    showHandleButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    arrowIcon: {
      marginRight: 4,
      marginBottom: 2,
    },
    ShowHandleButton: {
      padding: '16px 0',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      textAlign: 'center',
    },
  }),
  { name: 'SummaryTeamBuilding' }
)
