import { GraphQLResult } from '@aws-amplify/api'

// GraphQLResult型のエラープロパティをチェックするヘルパー関数
export const isErrorWithGraphQLErrors = (error: any): error is { errors?: GraphQLResult['errors'] } => {
  return error && Array.isArray(error.errors)
}

export const ERROR_MESSAGES = {
  unexpected: '予期しないエラー形式が検出されました。サポートチームに連絡するか、もう一度お試しください。',
}
