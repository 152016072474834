import React from 'react'

import { GraphQLResult } from '@aws-amplify/api'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import {
  subscriptionUpdatedTeamBuildingReview,
  subscriptionUpdatedTeamBuildingReviewMember,
  useTeamBuildingReviewMembers,
  useTeamBuildingReviews,
  useTeamBuildingReviewSummary,
} from 'services/api/review'

import {
  Master,
  MasterType,
  TeamBuildingActionDate,
  TeamBuildingReview,
  TeamBuildingReviewMember,
  TeamBuildingReviewMemberConnection,
  TeamBuildingReviewStatus,
  TeamBuildingReviewSummary,
  TeamBuildingReviewSummaryHistory,
  TeamMember,
  TeamMemberRole,
  TeamSummaryBuilding,
  User,
} from 'utils/generated'

import { useCurrentTeamMember, useCurrentUser, useSummaryTeamBuilding, useTeamMembers } from '../hooks'
import { queryGetMasters } from '../hooks/graphql'
import { useTeamBuildingActionDates } from '../hooks/teamBuildingActionDates'

dayjs.locale('ja')

interface Props {
  defaultActionMasters: Master[]
  nextAt: string | undefined
  icebreak: string | null | undefined
  iceBreakNumber: number
  iceBreakMinute: number
  teamId: string
  reviewId: string
  currentTeamMember?: TeamMember
  role: TeamMemberRole | undefined
  loading: boolean
  currentUser: User | undefined
  isPresenEnd: boolean
  teamMembers: TeamMember[]
  summaryBuilding: TeamSummaryBuilding | undefined
  teamBuildingActionDates: TeamBuildingActionDate[]
  refreshTeamBuildingActionDates: () => void
  teamBuildingReview: TeamBuildingReview | undefined
  teamBuildingReviews: TeamBuildingReview[] | undefined
  teamBuildingReviewMembers: TeamBuildingReviewMemberConnection | undefined
  teamBuildingReviewSummary: TeamBuildingReviewSummary | undefined
  refreshTeamBuildingReviews: () => Promise<void>
  refreshTeamBuildingReviewMembers: () => Promise<void>
  setNextAt: React.Dispatch<React.SetStateAction<string | undefined>>
  setTeamId: React.Dispatch<React.SetStateAction<string>>
  setReviewId: React.Dispatch<React.SetStateAction<string>>
  setIceBreak: React.Dispatch<React.SetStateAction<string | null | undefined>>
  setIsPresenEnd: React.Dispatch<React.SetStateAction<boolean>>
  setIceBreakNumber: React.Dispatch<React.SetStateAction<number>>
  setIceBreakMinute: React.Dispatch<React.SetStateAction<number>>
  prevSummary: TeamBuildingReviewSummaryHistory | undefined
  subscribeUpdatedTeamBuildingReview: () => void
  subscribeUpdatedTeamBuildingReviewMember: () => void
}

const MIN_PRESENTER_NUM = 1
export const MIN_PRESENTATION_TIME = 1

export const useTeamBuildingReviewHooksContext = () => {
  const [nextAt, setNextAt] = React.useState<string | undefined>(undefined)
  const [teamId, setTeamId] = React.useState<string>('')
  const [reviewId, setReviewId] = React.useState<string>('')
  const [icebreak, setIceBreak] = React.useState<string | null | undefined>(undefined)
  const [iceBreakNumber, setIceBreakNumber] = React.useState<number>(MIN_PRESENTER_NUM)
  const [iceBreakMinute, setIceBreakMinute] = React.useState<number>(MIN_PRESENTATION_TIME)
  const [isPresenEnd, setIsPresenEnd] = React.useState<boolean>(false)
  const [defaultActionMasters, setDefaultActionMasters] = React.useState<Master[]>([])

  const getMasterActions = async () => {
    const response = await queryGetMasters({ type: MasterType.TeamBuildingQ5Action, limit: 100 })
    const items: Master[] = response?.items || []

    setDefaultActionMasters(items)
  }

  // use data
  const {
    teamBuildingReviewMembers,
    loading: loadingReviewMembers,
    dataRefresh: refreshTeamBuildingReviewMembers,
  } = useTeamBuildingReviewMembers({ teamBuildingReviewId: reviewId })

  const {
    teamBuildingReviews,
    loading: loadingReviews,
    dataRefresh: refreshTeamBuildingReviews,
  } = useTeamBuildingReviews(teamId)
  const { teamMembers, loading: loadingTeamMember } = useTeamMembers(teamId)
  const { teamMember: currentTeamMember, role } = useCurrentTeamMember(teamId)
  const { user: currentUser } = useCurrentUser()
  const { summaryBuilding } = useSummaryTeamBuilding(teamId)
  const { teamBuildingReviewSummary } = useTeamBuildingReviewSummary(teamId)
  const { teamBuildingActionDates, refresh: refreshTeamBuildingActionDates } = useTeamBuildingActionDates(teamId)

  const loading = loadingReviews || loadingReviewMembers || loadingTeamMember
  const teamBuildingReview = React.useMemo(
    () => (teamBuildingReviews ? teamBuildingReviews[0] : undefined),
    [teamBuildingReviews]
  )
  const selfReviewMemberData = React.useMemo(
    () => teamBuildingReviewMembers?.items.find((item) => item.userId && item.userId === currentUser?.id),
    [currentUser, teamBuildingReviewMembers]
  )
  const prevSummary = React.useMemo(() => {
    return teamBuildingReviewSummary?.histories?.reverse()[1]
  }, [teamBuildingReviewSummary])

  React.useEffect(() => {
    if (teamBuildingReview?.iceBreak) {
      setIceBreak(teamBuildingReview?.iceBreak)
    }
    if (teamBuildingReview?.iceBreakMinute && teamBuildingReview.iceBreakNumber) {
      setIceBreakMinute(teamBuildingReview?.iceBreakMinute)
      setIceBreakNumber(teamBuildingReview?.iceBreakNumber)
    }
  }, [teamBuildingReview])

  React.useEffect(() => {
    getMasterActions()
  }, [])

  const subscribeUpdatedTeamBuildingReview = React.useCallback(() => {
    const sub = subscriptionUpdatedTeamBuildingReview().subscribe({
      next: ({ value }: { value: GraphQLResult<{ updatedTeamBuildingReview: TeamBuildingReview }> }) => {
        try {
          if (value.data?.updatedTeamBuildingReview && value.data?.updatedTeamBuildingReview.id === reviewId) {
            refreshTeamBuildingReviews()
            if (value.data?.updatedTeamBuildingReview?.status === TeamBuildingReviewStatus.Step3) {
              refreshTeamBuildingActionDates()
            }

            if (value.data?.updatedTeamBuildingReview?.status === TeamBuildingReviewStatus.Created) {
              refreshTeamBuildingReviewMembers()
            }
          }
        } catch (e) {
          const error = e as Error
          console.error(`sub Error: ${error.message}`)
        }
      },
    })
    const unsubscribe = () => {
      sub.unsubscribe()
    }
    return unsubscribe
  }, [refreshTeamBuildingActionDates, refreshTeamBuildingReviews, reviewId, refreshTeamBuildingReviewMembers])

  const subscribeUpdatedTeamBuildingReviewMember = React.useCallback(() => {
    const sub = subscriptionUpdatedTeamBuildingReviewMember().subscribe({
      next: ({ value }: { value: GraphQLResult<{ updatedTeamBuildingReviewMember: TeamBuildingReviewMember }> }) => {
        const isAnswered = selfReviewMemberData?.answers && selfReviewMemberData?.answers.length > 0
        try {
          const { updatedTeamBuildingReviewMember } = value.data ?? {}
          const updated = updatedTeamBuildingReviewMember?.teamBuildingReviewId === reviewId
          if (!updated || !updatedTeamBuildingReviewMember) {
            return
          }

          const isStep2Input = teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Input
          const isSelf = updatedTeamBuildingReviewMember.userId === currentUser?.id
          const waitingStep2Input = isStep2Input && (isAnswered || isSelf)

          if (!isStep2Input || waitingStep2Input) {
            refreshTeamBuildingReviewMembers()
          }
        } catch (e) {
          const error = e as Error
          console.error(`sub Error: ${error.message}`)
        }
      },
    })
    const unsubscribe = () => {
      sub.unsubscribe()
    }
    return unsubscribe
  }, [currentUser, refreshTeamBuildingReviewMembers, reviewId, selfReviewMemberData, teamBuildingReview])

  return {
    defaultActionMasters,
    nextAt,
    icebreak,
    iceBreakNumber,
    iceBreakMinute,
    teamId,
    reviewId,
    currentTeamMember,
    role,
    loading,
    currentUser,
    teamMembers,
    summaryBuilding,
    teamBuildingActionDates,
    isPresenEnd,
    refreshTeamBuildingActionDates,
    teamBuildingReview,
    teamBuildingReviews,
    teamBuildingReviewMembers,
    teamBuildingReviewSummary,
    subscribeUpdatedTeamBuildingReview,
    subscribeUpdatedTeamBuildingReviewMember,
    refreshTeamBuildingReviews,
    refreshTeamBuildingReviewMembers,
    setNextAt,
    setTeamId,
    setReviewId,
    setIceBreak,
    setIsPresenEnd,
    setIceBreakNumber,
    setIceBreakMinute,
    prevSummary,
  }
}

export const TeamBuildingReviewHooksContext = React.createContext<Props>({} as Props)
