export const queryGetGhostList = /* GraphQL */ `
  query GetGhostList($masterTeamId: ID) {
    getGhostList(masterTeamId: $masterTeamId) {
      id
      name
      sympton
      fileNo
      scenes
      pro
      con
      why
      solution
      actions
      anti
      caveats
      found
    }
  }
`

export const queryGetGhost = /* GraphQL */ `
  query GetGhost($id: ID!) {
    GetGhost(id: $id) {
      id
      name
      sympton
      fileNo
      scenes
      pro
      con
      why
      solution
      actions
      anti
      caveats
      found
    }
  }
`
