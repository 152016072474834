import * as React from 'react'

import { GhostBackground, GhostBenefitAndRisk } from '../modules'

type Props = {
  why: string
  pro: string
  con: string
  ownStyles?: React.CSSProperties
}

export const GhostBackgroundAndBenefitsAndRisks: React.FC<Props> = ({ why, pro, con, ownStyles }) => {
  return (
    <div style={ownStyles}>
      <GhostBackground text={why} noBack />
      <GhostBenefitAndRisk pro={pro} con={con} />
    </div>
  )
}
