import React from 'react'
import { useLocation } from 'react-router-dom'

import { RouteComponentProps } from 'react-router'

import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { queryGetTeamBuildingAction, queryGetTeamBuildingActionDates } from 'pages/teams/hooks/graphql'
import {
  TeamBuildingActionDate,
  TeamBuildingAction,
  IndexGetTeamBuildingActionDates,
  TeamBuildingStatus,
} from 'utils/generated'

import { ActionPreview, QuestionContainer, Header } from '../../../components'

import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = RouteComponentProps<{
  teamId: string
  actionId: string
}>

export const TeamsToolsBuildingDashboardActionPreviewPage: React.FC<Props> = (props) => {
  const { route } = useHistory()
  const { search } = useLocation()
  const param = new URLSearchParams(search)
  const from = param.get('from')

  const [teamBuildingAction, setTeamBuildingAction] = React.useState<TeamBuildingAction | null>(null)
  const [teamBuildingActionDates, setTeamBuildingActionDates] = React.useState<TeamBuildingActionDate[]>([])

  const getData = async (teamBuildingActionId: string) => {
    const fetchedTeamBuildingAction = await queryGetTeamBuildingAction({ id: teamBuildingActionId })

    if (fetchedTeamBuildingAction) {
      setTeamBuildingAction(fetchedTeamBuildingAction)
    }

    const fetchedTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
      id: teamBuildingActionId,
      index: IndexGetTeamBuildingActionDates.TeamBuildingActionId,
    })

    if (fetchedTeamBuildingActionDates) {
      setTeamBuildingActionDates(fetchedTeamBuildingActionDates?.items)
    }
  }

  React.useEffect(() => {
    if (props.match.params.actionId) {
      getData(props.match.params.actionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { currentTeam, loadedTeamBuilding, loadingTeam, teamBuilding } = React.useContext(HooksContext)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = () => {
    if (from) {
      route.push(from)
    } else {
      route.push(replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId: currentTeam?.id }))
    }
  }

  const onSubmit = () => {
    const path = replacePathParams(Pages.TeamsToolBuildingDashboardActionEdit, {
      teamId: currentTeam?.id,
      actionId: props.match.params.actionId,
    })

    if (from) {
      route.push(`${path}?from=${from}`)
    } else {
      route.push(path)
    }
  }

  if (!loadedTeamBuilding || loadingTeam) {
    return <LoadingCircular loading={true} />
  }

  return (
    <>
      <QuestionContainer addPaddingSide header={teamBuilding?.status === 'COMPLETED'}>
        {teamBuilding?.status === 'COMPLETED' && <Header />}
        {teamBuildingAction && (
          <ActionPreview
            layout={'preview'}
            teamBuildingAction={teamBuildingAction}
            teamBuildingActionDates={teamBuildingActionDates}
            handleBack={handleBack}
            onSubmit={onSubmit}
            updatable={teamBuilding && teamBuilding.status === TeamBuildingStatus.Completed}
          />
        )}
      </QuestionContainer>
    </>
  )
}
