import React from 'react'

import { makeStyles } from '@material-ui/core'

import { constants } from 'assets'

type Props = {
  title: string
}

export const QuestionTitle: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.title}</div>
}

const useStyles = makeStyles({
  root: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '33px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
})
