import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import {
  MutationCreateNoSignInGhostArgs,
  NoSignInGhost,
  NoSignInGhostWithTotal,
  QueryGetNoSignInGhostArgs,
} from 'utils/generated'

export const mutationCreateNoSignInGhost = async (
  args: MutationCreateNoSignInGhostArgs
): Promise<NoSignInGhost | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createNoSignInGhost($input: CreateNoSignInGhostInput!) {
        createNoSignInGhost(input: $input) {
          id
          questions {
            gender
            age
            industry
            occupation
            employeeSize
            position
          }
          resultGhostIdList
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ createNoSignInGhost: NoSignInGhost }>

  return response.data?.createNoSignInGhost
}

export const queryGetNoSignInGhost = async (
  args: QueryGetNoSignInGhostArgs
): Promise<NoSignInGhostWithTotal | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getNoSignInGhost($id: ID!) {
        getNoSignInGhost(id: $id) {
          own {
            id
            questions {
              gender
              age
              industry
              occupation
              employeeSize
              position
            }
            resultGhostIdList
            createdAt
            updatedAt
          }
          totalTopGhostId {
            industry
            position
            person
          }
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ getNoSignInGhost: NoSignInGhostWithTotal }>

  return response.data?.getNoSignInGhost
}
