import * as React from 'react'

import en from './i18n/en'
import blockquote from './images/editor_blockquote.svg'
import bold from './images/editor_bold.svg'
import comment from './images/editor_comment.svg'
import dotlist from './images/editor_dotlist.svg'
import images from './images/editor_images.svg'
import link from './images/editor_link.svg'
import numlist from './images/editor_numlist.svg'

// en['components.controls.blocktype.h1'] = <img src={blockquote} alt="h1" />;
// en['components.controls.blocktype.h2'] = <img src={blockquote} alt="h2" />;
en['components.controls.blocktype.code'] = <img src={comment} alt="comment" />
en['components.controls.blocktype.blocktype'] = <img src={bold} alt="bold" />
en['components.controls.blocktype.blockquote'] = <img src={blockquote} alt="blockquote" />
en['components.controls.image.image'] = <img src={images} alt="images" />
en['components.controls.list.list'] = <img src={numlist} alt="numlist" />
en['components.controls.list.Unordered'] = <img src={dotlist} alt="dotlist" />
en['components.controls.link.link'] = <img src={link} alt="link" />

export const localization = {
  locale: 'en',
  translations: en,
}
