import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  width: number | string
  height: number | string
  ownStyles?: React.CSSProperties
}

export const HorizonalLine: React.FC<Props> = ({ width, height, ownStyles }) => {
  return <div style={{ width: width, height: height, background: constGhost.COLOR_LIGHTGRAY3, ...ownStyles }}></div>
}
