import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man: React.FC = () => {
  return (
    <Svg width="167.018" height="358.354" viewBox="0 0 167.018 358.354">
      <G transform="translate(747.559 -644.054)">
        <Path
          d="M-620.021,764.818h-88.028c-2.489,0-4.526,2.486-4.526,5.524V908.1c0,.055.012.106.014.16v2.663H-615.5V770.342C-615.5,767.3-617.533,764.818-620.021,764.818Z"
          fill="#545364"
        />
        <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(-674.615 750.267)" fill="#ffe4d2" />
        <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-729.506 699.117)" fill="#ffe4d2" />
        <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-633.591 699.117)" fill="#ffe4d2" />
        <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(-719.172 653.47)" fill="#ffe4d2" />
        <Path
          d="M-712.574,991.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.148.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
          fill="#2a2a2a"
        />
        <Path
          d="M-652.337,991.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.149.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
          fill="#2a2a2a"
        />
        <Path d="M-682.647,764.818l9.075,26.674h15.125l9.9-26.674Z" fill="#fff" />
        <Path
          d="M-671.1,768.888l1.1,8.274-1.1,4.675-3.575,7.425,7.975,26.4,9.075-25.849-3.3-7.724.546-13.2-4.671-3.714Z"
          fill="#b15f78"
        />
        <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(-671.097 773.068)" fill="#c07087" />
        <Path d="M-665.273,764.818l11.229,15.033,5.637-15.033Z" fill="#e5e5e4" />
        <Path d="M-664.723,764.818l-13.383,14.107-4.541-14.107Z" fill="#e5e5e4" />
        <Path
          d="M-606.681,706.063c-12.367,1.479-18.661-9.536-21.808-18.191-37.765-2.36-59.009-3.147-72.384,0-2.36,9.442-23.6,27.538-22.134,9.1,3.662-23.7,24.949-52.918,57.46-52.918S-606.681,671.814-606.681,706.063Z"
          fill="#323333"
        />
        <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-652.337 901.846)" fill="#545364" />
        <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-712.575 901.846)" fill="#545364" />
        <G>
          <Rect
            x="0"
            y="0"
            width="83.15"
            height="22.811"
            rx="11.365"
            transform="translate(-747.559 886.185) rotate(-73.125)"
            fill="#ffe4d2"
          />
          <Rect
            x="0"
            y="0"
            width="104.291"
            height="25.755"
            transform="translate(-742.582 866.082) rotate(-73.125)"
            fill="#545364"
          />
        </G>
        <G>
          <Rect
            x="0"
            y="0"
            width="22.996"
            height="83.822"
            rx="11.457"
            transform="translate(-626.879 813.617) rotate(-16.875)"
            fill="#ffe4d2"
          />
          <Rect
            x="0"
            y="0"
            width="25.963"
            height="105.134"
            transform="translate(-640.924 773.819) rotate(-16.875)"
            fill="#545364"
          />
        </G>
      </G>
    </Svg>
  )
}

export default Man
