// -----------------------------
// Tipsの記事を制作する
// -----------------------------
export const createHint = /* GraphQL */ `
  mutation CreateHint($input: HintCreateInput!) {
    createHint(input: $input) {
      id
      username
      user {
        id
      }
      title
      imageUrl
      content
      contentPreview
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// Tipsの記事を編集する
// -----------------------------
export const updateHint = /* GraphQL */ `
  mutation UpdateHint($input: HintUpdateInput!) {
    updateHint(input: $input) {
      id
      username
      user {
        id
      }
      title
      imageUrl
      content
      contentPreview
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// Tipsの記事を削除する
// -----------------------------
export const deleteHint = /* GraphQL */ `
  mutation DeleteHint($id: ID!) {
    deleteHint(id: $id) {
      id
      username
      user {
        id
      }
      title
      imageUrl
      content
      contentPreview
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// Tipsの記事にコメントする
// -----------------------------
export const createHintComment = /* GraphQL */ `
  mutation CreateHintComment($input: HintCommentCreateInput!) {
    createHintComment(input: $input) {
      id
      hintId
      username
      user {
        id
      }
      content
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// お悩み掲示板の記事を制作する
// -----------------------------
export const createConsultation = /* GraphQL */ `
  mutation CreateConsultation($input: ConsultationCreateInput!) {
    createConsultation(input: $input) {
      id
      username
      user {
        id
      }
      title
      content
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// お悩み掲示板の記事を編集する
// -----------------------------
export const updateConsultation = /* GraphQL */ `
  mutation UpdateConsultation($input: ConsultationUpdateInput!) {
    updateConsultation(input: $input) {
      id
      username
      user {
        id
      }
      title
      content
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// お悩み掲示板の記事を削除する
// -----------------------------
export const deleteConsultation = /* GraphQL */ `
  mutation DeleteConsultation($id: ID!) {
    deleteConsultation(id: $id) {
      id
      username
      user {
        id
      }
      title
      content
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// お悩み掲示板の記事にコメントする
// -----------------------------
export const createConsultationComment = /* GraphQL */ `
  mutation CreateConsultationComment($input: ConsultationCommentCreateInput!) {
    createConsultationComment(input: $input) {
      id
      consultationId
      username
      user {
        id
      }
      content
      createdAt
      updatedAt
    }
  }
`
