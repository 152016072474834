import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { SelfCheck, SelfCheckCommentConnection } from '../../utils/generated'

import { SelfcheckActions } from './action'

export interface IResult {
  result?: SelfCheck
  fetched: boolean
  currentHistory: number
  comments?: SelfCheckCommentConnection
}

const initialState: IResult = {
  result: { id: '', updatedAt: '' },
  fetched: false,
  currentHistory: 0,
  comments: {},
}

export const SelfcheckReducer = reducerWithInitialState<IResult>(initialState)
  //fetch
  .case(SelfcheckActions.fetch.started, (state, payload) => {
    return { ...state, fetched: true, error: null, currentHistory: payload.currentHistory }
  })
  .case(SelfcheckActions.fetch.done, (state, payload) => {
    return { ...state, result: { ...payload.result }, fetched: true, error: null }
  })
  .case(SelfcheckActions.fetch.failed, (state, error) => {
    return { ...state, ...error }
  })

  // setSetupStartedAt
  .case(SelfcheckActions.updateSelfCheck.started, (state) => {
    return { ...state, fetched: true, error: null }
  })
  .case(SelfcheckActions.updateSelfCheck.done, (state, payload) => {
    return { ...state, result: { ...payload.result } }
  })
  .case(SelfcheckActions.updateSelfCheck.failed, (state, error) => {
    return { ...state, ...error }
  })

  // fetchSelfCheckComments
  .case(SelfcheckActions.fetchSelfCheckComments.started, (state) => {
    return { ...state, fetched: true, error: null }
  })
  .case(SelfcheckActions.fetchSelfCheckComments.done, (state, payload) => {
    return { ...state, comments: { ...payload.result } }
  })
  .case(SelfcheckActions.fetchSelfCheckComments.failed, (state, error) => {
    return { ...state, ...error }
  })
