import * as React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Logo from 'components/icon/Logo'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from 'assets/constants'

const POINTS = [
  '導入から定着まで伴走支援',
  <>
    各企業に導入・成功してきた
    <br />
    ノウハウやテクノロジーを提供
  </>,
  <>
    心理的安全性向上と
    <br />
    エンゲージメント向上にこだわった
    <br />
    WEBアプリケーション
  </>,
] as const

const ARROW_SIDE_TEXT_LIST = [
  { before: '偉い先生が大人数集めて実施するものである', after: '偉い先生ではなく、管理職が主導で組織開発をする​' },
  {
    before: 'その場の応用力が大事で、形式知化しづらいものである',
    after: 'Cocolaboを使って型を試し、そこから応用化させる（守破離）',
  },
  {
    before: '場が大事であって、効果はわかりにくいものである',
    after: 'ツールデータを集計し、人事等が実施状況や傾向値を把握する',
  },
] as const

export const TopAbout: React.FC = () => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()

  return (
    <div className={classes.root} id={constTop.anchorLinkId[0]}>
      <div className={classes.topic1}>
        経験のない管理職でも簡単にできる​
        <br />
        相互の違いが気づきとなり、
        {isSmUp ? '' : <br />}
        チームの関係性向上につながる​
      </div>
      <div className={classes.pointCardContainer}>
        {POINTS.map((point, index) => {
          return (
            <div
              key={index}
              className={classes.pointCard}
              style={{ height: isMdUp ? '153px' : `calc(134px + ${index * 24}px)` }}
            >
              <div className={classes.pointTitle}>POINT 0{index + 1}</div>
              <div className={classes.pointDescription}>{point}</div>
            </div>
          )
        })}
      </div>
      <div className={classes.topic2}>
        <Logo width={isMdUp ? 260 : 210} height={isMdUp ? 36 : 26} />
        <span>×</span>
        <div>管理職主導型組織開発</div>
      </div>
      <div className={classes.sentence}>
        エンゲージメント向上を目的に、今までの組織開発よりも安価で、かつ管理職のスキルとなることで永続的に
        {isMdUp ? <br /> : null}
        実施できるようになることがポイントです​。
      </div>
      <div className={classes.mockImageContainer}>
        <img
          src={process.env.PUBLIC_URL + '/img/topPage/about/mocmain.png'}
          className={classes.mockImage}
          alt="mockImage"
        />
      </div>
      <div className={classes.arrowRootContainer}>
        {ARROW_SIDE_TEXT_LIST.map(({ before, after }) => {
          return (
            <div key={before} className={classes.arrowContainer}>
              <div className={`${classes.arrowSideBox} ${classes.whiteBox}`}>{before}</div>
              <div className={classes.arrow}>
                <img src={process.env.PUBLIC_URL + '/img/topPage/arrow.svg'} />
              </div>
              <div className={`${classes.arrowSideBox} ${classes.yellowBox}`}>{after}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&::before': {
        ...constTop.fixAnchorLinkPosition,
      },
      padding: '80px 0',
      [theme.breakpoints.down('sm')]: {
        paddingTop: 40,
      },
    },
    topic1: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 45,
      textAlign: 'center',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 40,
        fontSize: 20,
        textAlign: 'left',
      },
    },
    pointCardContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
      marginBottom: 85,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 16,
      },
    },
    pointCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 10,
      backgroundColor: constants.COLOR_WHITE,
      textAlign: 'center',
      padding: '21px 26px 26px',
      width: 320,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 29,
        width: '100%',
      },
    },
    pointTitle: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 24,
      fontWeight: 'bold',
      paddingBottom: 18,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 13,
      },
    },
    pointDescription: {
      fontSize: 14,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    topic2: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 14,
      marginBottom: 29,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 7,
        fontSize: 20,
      },
    },
    sentence: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      color: constants.TEXT_GRAY_DARK,
      ...constTop.smallTopicTextStyles,
      textAlign: 'center',
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    mockImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 40,
      },
    },
    mockImage: {
      width: '100%',
      maxWidth: 665,
      margin: '0 auto',
    },
    arrowRootContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        gap: 40,
      },
    },
    arrowContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: 28,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    arrowSideBox: {
      display: 'flex',
      alignItems: 'center',
      height: 68,
      width: '100%',
      maxWidth: 464,
      padding: '13px 24px',
      textAlign: 'left',
      borderRadius: 10,
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        padding: 16,
        justifyContent: 'center',
        textAlign: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'left',
        textAlign: 'left',
      },
    },
    whiteBox: {
      backgroundColor: constants.COLOR_WHITE,
    },
    yellowBox: {
      backgroundColor: '#FFCD31',
      fontWeight: 600,
    },
    arrow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(90deg)',
      },
      '& img': {
        width: 15,
        height: 30,
      },
    },
  }),
  { name: 'TopAbout' }
)
