import React, { Dispatch, SetStateAction } from 'react'

import { UseFormMethods } from 'react-hook-form'

import { IndexedTeam } from 'pages/teams/pages/list'

import { FilterProps } from './Filter'
import { SortedInfoKey, SortedInfoSort } from './hooks/sortTeams'
import { GeneralList, GraphList } from './modules'

type Props = {
  filter: FilterProps
  setFilter: Dispatch<SetStateAction<FilterProps>>
  sortKey: {
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null
  setSortKey: Dispatch<
    SetStateAction<{
      key: SortedInfoKey
      sort: SortedInfoSort
    } | null>
  >
  teams: IndexedTeam[]
  statusChangeList: boolean
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  watchedTeamIds: string[] | string | boolean
}

export const TeamList: React.FC<Props> = ({
  filter,
  setFilter,
  teams,
  sortKey,
  setSortKey,
  statusChangeList,
  register,
  setValue,
  watchedTeamIds,
}) => {
  return (
    <>
      {filter.type === 'GENERAL' && (
        <GeneralList
          teams={teams}
          sortKey={sortKey}
          setSortKey={setSortKey}
          statusChangeList={statusChangeList}
          filter={filter}
          register={register}
          setValue={setValue}
          watchedTeamIds={watchedTeamIds}
        />
      )}
      {filter.type === 'GRAPH' && (
        <GraphList
          teams={teams}
          filter={filter}
          setFilter={setFilter}
          sortKey={sortKey}
          setSortKey={setSortKey}
          statusChangeList={statusChangeList}
          register={register}
          setValue={setValue}
          watchedTeamIds={watchedTeamIds}
        />
      )}
    </>
  )
}
