import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'

interface Props {
  onClick?: () => void
  full?: boolean
}

export const BackToIndex: React.FC<Props> = ({ onClick, full, children }) => {
  const classes = useStyles({ full })

  return (
    <div className={classes.root} onClick={onClick}>
      <ChevronLeftRoundedIcon fontSize="small" />
      <span>{children || '戻る'}</span>
    </div>
  )
}

type StyleProps = {
  full?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ full }) => ({
    display: 'flex',
    alignItems: 'center',
    color: '#707070',
    cursor: 'pointer',
    width: full ? '100%' : 'auto',
    '& > span': {
      fontSize: 12,
    },
  }),
}))
