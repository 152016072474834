import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import {
  makeStyles,
  StyleRules,
  useMediaQuery,
  useTheme,
  Box,
  Select,
  Switch,
  MenuItem,
  InputBase,
  Typography,
  Theme,
} from '@material-ui/core'
import classNames from 'classnames'
import { useForm, Controller } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { FormLabelMark, ButtonContainer } from 'pages/onboarding/components'
import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { OnboardingTeamMemberRole, OnboardingTeamMemberStatus } from 'utils/generated'

import { OnbContext } from '../../PagesRoot'
import { useTutorial } from '../../tutorial/logics/hooks'
import { TutorialProps } from '../../tutorial/Tutorial'
import { FormDate } from '../add/components/FormDate'
import { FormTime } from '../add/components/FormTime'
import Toolbar from '../add/components/toolbar'
import { Tooltip } from '../add/components/Tooltip'

import ArrowDownSvg from '../assets/arrow-down.svg'
import ArrowUpSvg from '../assets/arrow-up.svg'
import CheckSvg from '../assets/check.svg'
import * as constants from 'assets/constants'
import { PatternEmailLowerCase, PatternEmailLowerCaseMessage } from 'assets/patterns'

type PickInput = Pick<IFormCharCountInput, 'memberEmail'>

interface IInput extends PickInput {
  role: OnboardingTeamMemberRole
  startedAt: Date
  sendEmailDateAt: Date
  sendEmailTimeAt: Date
}

export const maxLengthEmail = 254

export const MemberAddBloc = {
  useAdapter: () => {
    const onbStore = useContext(OnbContext)
    const { userId: paramUserId } = useParams<{ userId?: string }>()
    const { teamMember: selectedTeamMember } = useTeamMember(onbStore.teamId, paramUserId)
    const { updateTeamMember } = useTeamManage()
    return {
      paramUserId,
      selectedTeamMember,
      teamId: onbStore.teamId,
      updateTeamMember,
    }
  },
}

export const MemberInviteEdit: React.FC<{ tutorial?: TutorialProps }> = (props) => {
  // deps
  const classes = useStyles()
  const theme = useTheme()
  const isBreakPointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const tutorial = props.tutorial
  useTutorial(tutorial)
  const { paramUserId, updateTeamMember, selectedTeamMember, teamId } = MemberAddBloc.useAdapter()

  // #common-effect
  const history = useHistory()

  // UI control
  const [opened, setOpened] = useState<Boolean>(false)

  // form event

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    control,
    // setValue,
    reset,
  } = useForm<IInput>({
    mode: 'onChange',
    defaultValues: {
      memberEmail: '',
      role: OnboardingTeamMemberRole.Member,
      startedAt: '',
      sendEmailDateAt: '',
      sendEmailTimeAt: '',
    },
  })

  const initValues = useMemo(
    () => ({
      memberEmail: selectedTeamMember?.email ?? '',
      role: selectedTeamMember?.role ?? OnboardingTeamMemberRole.Member,
      startedAt: selectedTeamMember?.startedAt ?? '',
      sendEmailDateAt: selectedTeamMember?.reserveWillSendEmailAt ?? '',
      sendEmailTimeAt: selectedTeamMember?.reserveWillSendEmailAt?.split(' ')[1] ?? '',
    }),
    [selectedTeamMember]
  )

  React.useEffect(() => {
    reset(initValues)
  }, [reset, initValues])

  const inputName: keyof PickInput = 'memberEmail'
  const watchEmail = watch(inputName, '')

  const [isReserveVisible, setIsReserveVisible] = React.useState<boolean>(false)
  const handleReserveOpen = () => setIsReserveVisible(true)
  const handleReserveClose = () => setIsReserveVisible(false)
  useEffect(() => {
    if (selectedTeamMember?.reserveWillSendEmailAt) {
      setIsReserveVisible(true)
    }
  }, [selectedTeamMember])

  const onSubmit = async (data: IInput) => {
    if (errors > 0) {
      alert('入力内容を再度確認してください。')
      return
    }

    let reserveWillSendEmailAt = ''

    const isSendSoon = !data.sendEmailDateAt && !data.sendEmailTimeAt
    const isReservedBefore =
      selectedTeamMember?.status === OnboardingTeamMemberStatus.Emailreserved &&
      selectedTeamMember?.reserveWillSendEmailAt

    if (isReserveVisible) {
      const year = new Date(data.sendEmailDateAt).getFullYear().toString()
      const month = (new Date(data.sendEmailDateAt).getMonth() + 1).toString()
      const day = new Date(data.sendEmailDateAt).getDate().toString()
      reserveWillSendEmailAt = `${year}/${month}/${day} ${data.sendEmailTimeAt || '0:00'}`
    }

    let successMsgFlg: 'changeReserved' | 'done' | '' = ''
    if (reserveWillSendEmailAt) {
      //exception
      if (window.confirm(`${reserveWillSendEmailAt}　に招待メールを送ります。よろしいですか？`)) {
        successMsgFlg = 'changeReserved'
      } else {
        return
      }
    }
    if (new Date(reserveWillSendEmailAt) < new Date()) {
      alert('予約の時間は既に過ぎております。')
      return
    } else if (!isReserveVisible && isReservedBefore && isSendSoon) {
      //if reserved data is null...
      if (window.confirm('招待メールをすぐに送信します。よろしいでしょうか？')) {
        successMsgFlg = 'done'
      } else {
        return
      }
    }

    const params = {
      teamId,
      reserveWillSendEmailAt,
    }

    if (teamId) {
      const teamMember = await updateTeamMember(params, paramUserId)

      // 成功時の
      if (teamMember && 'id' in teamMember) {
        if (tutorial && tutorial.next) {
          tutorial.next()
        } else {
          history.push({
            pathname: `/onboarding/${teamId}/team`,
            state: { invFlg: successMsgFlg },
          })
        }
      } else {
        alert('正常に登録できませんでした。再度お試しください。')
      }
    }
  }

  return (
    <>
      <TitleWithBack title={'メンバー詳細'} omitBack={Boolean(tutorial)} />
      {!tutorial && (
        <>
          <Typography className={classes.teamLabel}>追加するチーム</Typography>
          <Toolbar teamId={teamId} />
        </>
      )}
      <div className={classes.template}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Box paddingLeft={2} paddingRight={2} className={classes.box}>
            <div>
              <div className={classes.labelContainer}>
                <label htmlFor="mail" className={classes.inputLabel}>
                  メールアドレス
                </label>
                <FormLabelMark
                  markType={'required'}
                  ownStyles={{
                    borderRadius: 4,
                    margin: ' 24px 0 10px 8px',
                    backgroundColor: constants.COLOR_ALERT,
                  }}
                />
              </div>
              <InputBase
                id="email"
                placeholder="Taro@gmail.com"
                className={classes.readOnly}
                readOnly
                style={{
                  paddingLeft: 16,
                  border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
                  width: '100%',
                  height: 49,
                  borderRadius: 8,
                  color: constants.TEXT_GRAY_DARK,
                  fontSize: 14,
                }}
                inputProps={{ 'aria-label': 'メールアドレス' }}
                name={inputName}
                inputRef={register({
                  required: true,
                  maxLength: maxLengthEmail,
                  pattern: {
                    value: PatternEmailLowerCase,
                    message: PatternEmailLowerCaseMessage,
                  },
                })}
              />
              <FormCharCount length={watchEmail.length} maxLength={maxLengthEmail} invisibleHeight={21} />
            </div>
            <Controller
              control={control}
              name="role"
              defaultValue={initValues.role}
              render={({ onChange, name, ref }) => (
                <div>
                  <label htmlFor="kind" className={classes.inputLabel} style={{ marginTop: 3 }}>
                    種別
                  </label>
                  <Select
                    labelId="kind"
                    name={name}
                    defaultValue={initValues.role}
                    ref={ref}
                    readOnly
                    inputRef={ref}
                    style={{
                      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
                      width: '100%',
                      height: 42,
                      borderRadius: 8,
                    }}
                    disableUnderline={true}
                    className={classes.readOnly}
                    classes={{ select: classes.select, icon: classes.selectIcon }}
                    onChange={onChange}
                    MenuProps={{
                      classes: { paper: classes.selectPaper },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    renderValue={() => (
                      <>{watch('role') === OnboardingTeamMemberRole.Member ? 'メンバー' : 'サポーター'}</>
                    )}
                    IconComponent={() => (
                      <img
                        src={opened ? ArrowUpSvg : ArrowDownSvg}
                        style={{ position: 'absolute', right: 8, pointerEvents: 'none' }}
                        alt="dropdown-arrow"
                      />
                    )}
                    onClose={() => setOpened(false)}
                    onOpen={() => setOpened(true)}
                  >
                    <MenuItem value={OnboardingTeamMemberRole.Member} classes={{ selected: classes.selected }}>
                      <div className={classes.listCheck}>
                        {watch('role') === OnboardingTeamMemberRole.Member ? (
                          <img className={classes.listHead} src={CheckSvg} alt="check-mark" />
                        ) : (
                          <></>
                        )}
                      </div>
                      <span
                        style={{
                          fontSize: 14,
                          color:
                            watch('role') === OnboardingTeamMemberRole.Member
                              ? constants.COLOR_ONBOARDING_MAIN
                              : constants.TEXT_GRAY_DARK,
                        }}
                      >
                        メンバー
                      </span>
                    </MenuItem>
                    <MenuItem value={OnboardingTeamMemberRole.Supporter} classes={{ selected: classes.selected }}>
                      <div className={classes.listCheck}>
                        {watch('role') === OnboardingTeamMemberRole.Supporter ? (
                          <img className={classes.listHead} src={CheckSvg} alt="check-mark" />
                        ) : (
                          <></>
                        )}
                      </div>
                      <span
                        style={{
                          fontSize: 14,
                          color:
                            watch('role') === OnboardingTeamMemberRole.Supporter
                              ? constants.COLOR_ONBOARDING_MAIN
                              : constants.TEXT_GRAY_DARK,
                        }}
                      >
                        サポーター
                      </span>
                    </MenuItem>
                  </Select>
                </div>
              )}
            />

            <div>
              <div className={classes.labelContainer}>
                <label htmlFor="kind" className={classes.inputLabel}>
                  参加日
                </label>
                <FormLabelMark
                  markType={'required'}
                  ownStyles={{
                    borderRadius: 4,
                    margin: ' 24px 0 10px 8px',
                    backgroundColor: constants.COLOR_ALERT,
                  }}
                />
                <Tooltip ownStyles={{ margin: '20px 0 10px 8px' }}>
                  <div className={classes.toolTipTitle}>オンボーディングアクションスタート日</div>
                  <div className={classes.toolText}>
                    オンボーディングアクションとは、メンバーが職場の一員として活躍するために配属後100日間の間に何をすればよいかを指し示した行動のことを言います。配属日をアクションスタートする日として設定してください。
                  </div>
                </Tooltip>
              </div>

              <Controller
                control={control}
                name="startedAt"
                rules={{ required: true }}
                render={({ onChange, name, value }) => (
                  <FormDate name={name} onChange={onChange} value={value} readOnly />
                )}
              />
            </div>

            <div>
              <div className={classes.reserveLabelWrapper}>
                <label htmlFor="kind" className={classes.inputWrapperLabel}>
                  予約招待メール
                </label>
                <Tooltip
                  ownStyles={{ margin: '0 0 0 8px' }}
                  detailStyles={{ top: 40 }}
                  arrowPosition={isBreakPointsUp ? 8 : 56}
                >
                  <div className={classes.toolTipTitle}>予約招待メール</div>
                  <div className={classes.toolText}>
                    メンバーの追加をすると、入力したメールアドレス宛に招待メールが届きます。
                    招待メールを送信する日付と時間を指定することができます。
                  </div>
                </Tooltip>
                <FormLabelMark markType={'optional'} ownStyles={{ margin: '0 0 0 16px' }} />
                <Switch
                  checked={isReserveVisible}
                  onClick={isReserveVisible ? handleReserveClose : handleReserveOpen}
                  disableRipple
                  classes={{
                    root: classes.MuiSwitchRoot,
                  }}
                />
              </div>
              {isReserveVisible ? (
                <div className={classes.reserveInputWrapper}>
                  <Controller
                    control={control}
                    name="sendEmailDateAt"
                    rules={{
                      validate: (value) => {
                        if (!isReserveVisible) return true
                        const isInValid = value?.toString() !== 'Invalid Date'
                        const isNotEmpty = value !== null
                        return isInValid && isNotEmpty
                      },
                    }}
                    render={({ onChange, name, value }) => (
                      <FormDate
                        name={name}
                        onChange={onChange}
                        value={value}
                        ownStyles={{ maxWidth: '50%', minWidth: 150 }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="sendEmailTimeAt"
                    render={({ onChange, name, value }) => (
                      <FormTime name={name} value={value} onChange={onChange} ownStyles={{ margin: '0 0 0 24px' }} />
                    )}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Box>

          <div className={classes.buttonContainer}>
            <ButtonContainer
              button={
                <NoStyleButton
                  type={'submit'}
                  disabled={!isValid}
                  className={classNames([classes.submitButton, !isValid ? 'submitButtonInVisible' : ''])}
                >
                  追加する
                </NoStyleButton>
              }
            />
          </div>
        </form>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    titleWrapper: {
      position: 'relative',
      marginLeft: 16,
      marginRight: 16,
    },
    toolTipTitle: {
      fontWeight: 'bold',
      paddingBottom: 13,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
    },
    toolText: {
      fontSize: 14,
      fontWeight: 'normal',
    },

    toBack: {
      position: 'absolute',
      top: -1,
      left: 7,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      padding: 5,
      cursor: 'pointer',
      '& img': {
        position: 'relative',
        top: 1,
        right: 8,
      },
    },
    title: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 21,
      marginBottom: 29,
    },

    template: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    topMsg: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      margin: '0 16px 27px',
    },
    teamLabel: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      margin: '0 16px 10px 20px',
    },
    box: {
      backgroundColor: constants.COLOR_WHITE,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginLeft: 16,
      marginRight: 16,
      paddingBottom: 24,
      borderRadius: 8,
      '& p': {
        fontWeight: 'bold',
      },
    },
    select: {
      paddingLeft: 16,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    selectPaper: {
      marginTop: 5,
      borderRadius: 8,
      boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
    },
    selected: {
      '&:focus': {
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    inputLabel: {
      display: 'block',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      position: 'relative',
      margin: '24px 0 10px 4px',
    },
    readOnly: {
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    },
    inputWrapperLabel: {
      display: 'block',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      position: 'relative',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    listCheck: {
      display: 'inline-block',
      width: 21,
    },
    listHead: {
      position: 'relative',
      top: 1,
      width: 13,
      height: 13,
    },
    reserveLabelWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '24px 0 0',
    },
    reserveInputWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '20px 0 0',
    },
    MuiSwitchRoot: {
      margin: '0 0 0 24px',
      padding: 6,
      '& .MuiIconButton-root:hover': {
        backgroundColor: '#FFFFFF00',
      },
      '& .MuiIconButton-label': {
        color: constants.COLOR_WHITE,
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        borderRadius: 50,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        opacity: 1,
      },
    },
    submitButton: {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 16,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 8,
      width: 350,
      padding: '12px 0',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
      },
      '&.submitButtonInVisible': {
        border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
        backGroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        color: constants.COLOR_ONBOARDING_TEXT_GRAY,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 16px 0',
    },
  }),
  { name: 'MemberInviteEdit' }
)
