import { useContext } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { hintContext } from 'pages/hint/_shared/context/useHintContext'

// -----------------------------
// interface
// -----------------------------
export type IuseArticleHeading = {
  onOpenDraftModal: (username: string | undefined) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useArticleHeading = (): IuseArticleHeading => {
  const context = useContext(hintContext)
  const userSelector = useSelector((state: RootStateType) => state.user)

  /**
   * 記事を書いた本人がログイン中なら編集モーダルを立ち上げる
   */
  const onOpenDraftModal = (username: string | undefined): void => {
    // 記事のauthorIDを受け取って判定する
    if (username === userSelector.id) {
      context.onSetShowUpdateModal()
    }
  }

  return {
    onOpenDraftModal,
  }
}
