import { combineReducers } from 'redux'

import { IHintState } from './hint/_shared/stores/models'
import { HintReducers } from './hint/_shared/stores/reducer'
import KarteLeaderQuestionnaireReducer, {
  State as KarteLeaderQuestionnaireState,
} from './karte/leader/questionnaire/reducer'
import KarteLeaderReducer, { State as KarteLeaderState } from './karte/leader/reducer'
import KarteLeaderResultsReducer, { State as KarteLeaderResultsState } from './karte/leader/results/reducer'
import KarteLeaderSearchReducer, { State as KarteLeaderSearchState } from './karte/leader/search/reducer'
import KarteMemberQuestionnaireReducer, {
  State as KarteMemberQuestionnaireState,
} from './karte/member/questionnaire/reducer'
import KarteMemberReducer, { State as KarteMemberState } from './karte/member/reducer'
import KarteMemberResultsReducer, { State as KarteMemberResultsState } from './karte/member/results/reducer'


export interface State {
  karteLeaderSearch: KarteLeaderSearchState
  karteLeaderQuestionnaire: KarteLeaderQuestionnaireState
  karteLeaderResults: KarteLeaderResultsState
  karteLeader: KarteLeaderState
  karteMemberQuestionnaire: KarteMemberQuestionnaireState
  karteMemberResults: KarteMemberResultsState
  karteMember: KarteMemberState
  hint: IHintState
}

export default combineReducers<State>({
  karteLeaderSearch: KarteLeaderSearchReducer,
  karteLeaderQuestionnaire: KarteLeaderQuestionnaireReducer,
  karteLeaderResults: KarteLeaderResultsReducer,
  karteLeader: KarteLeaderReducer,
  karteMemberQuestionnaire: KarteMemberQuestionnaireReducer,
  karteMemberResults: KarteMemberResultsReducer,
  karteMember: KarteMemberReducer,
  hint: HintReducers,
})
