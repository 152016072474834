import { useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { OnboardingTeamMember, OnboardingTeamMemberRole } from 'utils/generated'

import { useDaily } from '../hooks/daily'

import { OnbHistory } from './route'

import { OnbPages } from 'assets/pages'

const AdminAndSupporterOnlyPages: OnbPages[] = [
  OnbPages.ActionManage,
  OnbPages.ActionManageDetail,
  OnbPages.ActionManageAdd,
  OnbPages.ActionManageEdit,
  OnbPages.MemberManageAddMember,
  OnbPages.MemberManage,
  OnbPages.MemberManageActionDetail,
  //overView
  OnbPages.Overview,
]

const AdminOnlyPages: OnbPages[] = [OnbPages.MemberManageAddMember]

const MemberOnlyPages: OnbPages[] = [OnbPages.DailyCheck]

export const authorizePageContent = (role: OnboardingTeamMemberRole, location: OnbPages) => {
  if (role === OnboardingTeamMemberRole.Member && AdminAndSupporterOnlyPages.includes(location)) {
    return false
  }
  if (role !== OnboardingTeamMemberRole.Admin && AdminOnlyPages.includes(location)) {
    return false
  }
  if (role !== OnboardingTeamMemberRole.Member && MemberOnlyPages.includes(location)) {
    return false
  }
  return true
}

// if user access page that it is NOT authorized, jump to timeline page.
export const useAuthCheck = (history: OnbHistory, teamId?: string, teamMember?: OnboardingTeamMember) => {
  const routeMatch = useRouteMatch()

  useEffect(() => {
    if (!teamId || !teamMember || !routeMatch.path) return
    const urlParts = routeMatch.url.split('/').slice(3)
    const replaceParams = () => {
      switch (urlParts.length) {
        case 0:
          return routeMatch.path // exception
        case 1:
          return routeMatch.path.replace('*', urlParts[0])
        default:
          const urlString = urlParts.slice(2).join('/')
          if (urlParts[0] === 'timeline') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:postId${urlString ? `/${urlString}` : ''}`)
          } else if (urlParts[0] === 'teammember') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId?`)
          } else if (urlParts[0] === 'notification') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:notificationId`)
          } else if (urlParts[0] === 'memberstatus') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId`)
          } else if (urlParts[0] === 'actions' && urlParts.length === 3) {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId/:actionId`)
          } else if (urlParts[0] === 'actions' && urlParts.length === 4) {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId/detail/:actionId`)
          } else if (urlParts[0] === 'action-edit') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId/target/:actionId`)
          } else if (urlParts[0] === 'member-manage') {
            return routeMatch.path.replace('*', `${urlParts[0]}/add-member`)
          } else if (urlParts[0] === 'member-manage-actions' && urlString === '') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId`)
          } else if (urlParts[0] === 'member-manage-actions' && urlString !== '') {
            return routeMatch.path.replace('*', `${urlParts[0]}/:userId/:actionId`)
          }
      }
    }
    const urlParams = replaceParams()
    const hasEnum = Object.values(OnbPages).find((item) => item === urlParams)

    if (!hasEnum) return
    const hasAuth = teamMember.role && urlParams && authorizePageContent(teamMember.role, hasEnum)
    if (hasAuth) return
    alert('オンボーディングのこちらのページは、一部の方しかご確認いただけないページです。タイムラインに戻ります。')
    history.push(OnbPages.Timeline, { teamId })
  }, [history, teamId, teamMember, routeMatch])
}

/**
 * Member should submit daily-check to enter onboarding.
 * if Member already finished and access to daily-check,redirect to timelinePage
 */
export const useSendDaily = (history: OnbHistory, teamId?: string, teamMember?: OnboardingTeamMember) => {
  const { pathname } = useLocation()
  const { postDaily, loading: postDailyLoading } = useDaily(
    teamId,
    teamMember?.role !== OnboardingTeamMemberRole.Member
  )

  useEffect(() => {
    const isEndTutorial = teamMember && teamMember.role === OnboardingTeamMemberRole.Member && teamMember.readTutorialAt
    const isNotYetDailyCheck = !postDailyLoading && !postDaily && !pathname.includes('daily-check')
    if (isEndTutorial && isNotYetDailyCheck) {
      history.push(OnbPages.DailyCheck, { teamId })
    }
  }, [teamId, teamMember, pathname, postDaily, history, postDailyLoading])
}
