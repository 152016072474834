import React, { useContext } from 'react'

import { ResearchContext } from '../../contexts/research'
import { Font, GhostArrayData, UsefulCard } from '../atoms/index'

type Props = {
  solution: string
  actions: string[]
  anti: string
  caveats: string[]
  ownStyles?: React.CSSProperties
}
// 後で修正
export const GhostActionAndEffect2: React.FC<Props> = ({ solution, actions, anti, caveats, ownStyles }) => {
  const { researches1 } = useContext(ResearchContext)

  return (
    <div style={ownStyles}>
      <Font fontSize={14} bold>
        オバケを退治するアクション例
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '6px 0 0 0' }}>
        {solution}
      </Font>
      <UsefulCard backgroundColor="lightGray2" noShadow ownStyles={{ marginTop: 14, padding: 16 }}>
        <GhostArrayData
          items={actions}
          fieldType="scenes"
          fontSize={14}
          itemStyles={{ lineHeight: 'normal' }}
          listStyles={{ margin: 0 }}
          listMarginBottom={8}
          listTop={10}
        />
      </UsefulCard>

      <Font fontSize={14} bold ownStyles={{ margin: '24px 0 0 0' }}>
        オバケがいなくなったときの副作用
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '6px 0 0 0' }}>
        {anti}
      </Font>
      <UsefulCard backgroundColor="lightGray2" noShadow ownStyles={{ marginTop: 14, padding: 16 }}>
        <GhostArrayData
          items={caveats}
          fieldType="scenes"
          fontSize={14}
          itemStyles={{ lineHeight: 'normal' }}
          listStyles={{ margin: 0 }}
          listMarginBottom={8}
          listTop={10}
        />
      </UsefulCard>

      <Font fontSize={14} bold ownStyles={{ margin: '24px 0 0 0' }}>
        オバケが発生したと思われる原因
      </Font>
      {researches1 &&
        researches1.map((researche, index) => (
          <React.Fragment key={`reseach_${index}`}>
            <UsefulCard backgroundColor="lightGray2" noShadow ownStyles={{ marginTop: 14, padding: 16 }}>
              {researche.report}
            </UsefulCard>
          </React.Fragment>
        ))}
    </div>
  )
}
