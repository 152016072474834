import React, { Dispatch, SetStateAction } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { fileIcon, fileSize } from 'pages/onboarding/utils/attachmentFile'
import { OnboardingPostFile } from 'utils/generated'

import closeGrayIcon from '../../assets//fileIcons/closeGrayIcon.svg'
import downloadIcon from '../../assets/fileIcons/downloadIcon.svg'

import * as constants from 'assets/constants'

type Props = {
  uploadedFile?: OnboardingPostFile
  isEditPage?: boolean
  SetIsDeleteFile?: Dispatch<SetStateAction<boolean>>
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const DownloadDrop = ({ uploadedFile, isEditPage, SetIsDeleteFile, ownStyles }: Props & StyleProps) => {
  const classes = useStyles({ ownStyles: ownStyles })
  const deleteDL = () => {
    if (!window.confirm('このファイルを削除します。よろしいですか？')) {
      return
    }
    SetIsDeleteFile && SetIsDeleteFile(false)
  }

  return (
    <>
      {uploadedFile && (
        <div className={isEditPage ? classes.uploadedContainerForEdit : classes.uploadedContainer}>
          <div className={classes.file}>
            <img src={fileIcon(uploadedFile.fileName)} alt={'attachmentFile'} className={classes.fileIcon} />
            <div className={classes.fileInfo}>
              <div className={classes.fileName}>{uploadedFile?.fileName}</div>
              {uploadedFile.size && <div className={classes.fileSize}>サイズ：{fileSize(uploadedFile.size)}</div>}
            </div>
          </div>
          {isEditPage ? (
            <>
              <div className={classes.download} onClick={() => deleteDL()}>
                <img src={closeGrayIcon} alt="close" />
              </div>
            </>
          ) : (
            <>
              {uploadedFile.signedUrl && (
                <a
                  className={classes.downloadBtn}
                  href={uploadedFile.signedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={classes.download}>
                    <img src={downloadIcon} alt="ダウンロード" />
                  </div>
                </a>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    dropzoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '16px',
      backgroundColor: constants.COLOR_WHITE2,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 4,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '24px 16px',
        height: 160,
      },
    },
    droppingBorder: {
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
    },
    msg: {
      margin: '6px 0 0',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    description: {
      margin: '4px 0 0',
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },
    selectButtonWrapper: {
      marginTop: 8,
    },

    uploadedContainer: ({ ownStyles }: StyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      height: 56,
      padding: '13px 16px',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
      margin: '0 0 20px 120px',
      ...ownStyles,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    }),
    uploadedContainerForEdit: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      height: 56,
      padding: '13px 16px',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    file: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      flex: 1,
      minWidth: 0,
    },
    fileIcon: {
      height: 24,
    },
    fileInfo: {
      minWidth: 0,
      width: '100%',
    },
    fileName: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fileSize: {
      marginTop: 2,
      color: constants.COLOR_GRAY3,
      fontSize: 10,
    },
    clearButton: {
      width: 24,
      height: 24,
      '& img': {
        width: 24,
      },
    },
    downloadBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
    },
    download: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24,
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 12,
      cursor: 'pointer',
    },
  }),
  { name: 'DownloadDrop' }
)
