import * as React from 'react'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { mergeClasses } from 'pages/onboarding/utils/styles'

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={mergeClasses([classes.container, className])}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10.5" viewBox="0 0 12 10.5">
        <path
          d="M6-9.75c-3.314,0-6,2.182-6,4.875A4.327,4.327,0,0,0,1.336-1.812,5.963,5.963,0,0,1,.052.434a.186.186,0,0,0-.035.2A.184.184,0,0,0,.187.75a5.36,5.36,0,0,0,3.3-1.2A7.147,7.147,0,0,0,6,0c3.314,0,6-2.182,6-4.875S9.314-9.75,6-9.75ZM3-4.125a.749.749,0,0,1-.75-.75A.749.749,0,0,1,3-5.625a.749.749,0,0,1,.75.75A.749.749,0,0,1,3-4.125Zm3,0a.749.749,0,0,1-.75-.75A.749.749,0,0,1,6-5.625a.749.749,0,0,1,.75.75A.749.749,0,0,1,6-4.125Zm3,0a.749.749,0,0,1-.75-.75A.749.749,0,0,1,9-5.625a.749.749,0,0,1,.75.75A.749.749,0,0,1,9-4.125Z"
          transform="translate(0 9.75)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
