import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'
import { IHintBase } from '../models/hint'

// -----------------------------
// Params
// -----------------------------
export type IHintListProfileUnpublishedParams = {
  username: string
}

// -----------------------------
// Result
// -----------------------------
export type IHintListProfileUnpublishedResult = {
  items: IHintBase[]
  nextToken: string
}

export const getHintListProfileUnpublished = async (params: IHintListProfileUnpublishedParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getHintList,
      variables: {
        filter: { isPublished: 0, username: params.username },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return {
      items: [...res?.data?.getHintList?.items],
      nextToken: res?.data?.getHintList?.nextToken,
    }
  } catch (e) {
    throw e
  }
}
