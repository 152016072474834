import * as React from 'react'

import { Button } from '@material-ui/core'

import * as constants from '../assets/constants'

interface Props {
  body: string
  onClick?: (() => Promise<void>) | (() => void)
  variant?: 'contained' | 'outlined' | 'text'
  fullWidth?: boolean
  disabled?: boolean
  submit?: boolean
  bgColor?: string
  borderless?: boolean
  borderColor?: string
  textColor?: string
  fontSize?: number
  fontWeight?: number
  width?: number
  height?: number
  radius?: number
  noShadow?: boolean
  loading?: boolean
  style?: React.CSSProperties
  margin?: number | string
}

export const ButtonNew: React.FC<Props> = (props) => {
  return (
    <Button
      onClick={!props.loading ? props.onClick : undefined}
      variant={props.variant || 'contained'}
      fullWidth={props.fullWidth ?? true}
      disabled={props.disabled}
      type={props.submit ? 'submit' : 'button'}
      style={{
        backgroundColor: props.disabled
          ? constants.COLOR_ONBOARDING_GRAY_LIGHT
          : props.bgColor ?? constants.COLOR_WHITE,
        border:
          props.borderless || props.disabled
            ? '1px solid transparent'
            : props.borderColor
            ? `1px solid ${props.borderColor}`
            : `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
        color: props.disabled ? constants.COLOR_ONBOARDING_GRAY : props.textColor || constants.COLOR_ONBOARDING_MAIN,
        fontSize: props.fontSize ?? 16,
        fontWeight: props.fontWeight ?? 600,
        width: props.width ?? undefined,
        height: props.height ?? 48,
        borderRadius: props.radius ?? 8,
        boxShadow: props.noShadow ? 'none' : undefined,
        ...props.style,
      }}
    >
      {props.body}
    </Button>
  )
}
