import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { IData } from '../../services/api/helps'

import { HelpActions } from './action'

export interface IHelp {
  data?: IData[]
  categories?: string[]
  fetched?: boolean
}

export const initialState: IHelp = {
  data: undefined,
  fetched: false,
}

export const HelpReducer = reducerWithInitialState<IHelp>(initialState)
  // onMount
  .case(HelpActions.onMount.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(HelpActions.onMount.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(HelpActions.onMount.failed, (state, error) => {
    return { ...state, ...error }
  })

  // serach
  .case(HelpActions.serach.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(HelpActions.serach.done, (state, payload) => {
    return { ...state, data: payload.result, error: null }
  })
  .case(HelpActions.serach.failed, (state, error) => {
    return { ...state, ...error }
  })
