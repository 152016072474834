import * as React from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuItemList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ScrollToId } from './ScrollToId'

import * as constants from 'assets/constants'


type Props = {
  page: string
  items: {
    id: string
    label: string
  }[]
  buttonLabel: '機能から選ぶ' | '見たい項目から選ぶ' | 'カテゴリから選ぶ'
  paperHeightMinus?: number
  styleMdDown?: React.CSSProperties
  styleMdUp?: React.CSSProperties
  smooth?: boolean
}

type ScrollToIdFeatureProps = {
  page: string
  id: string
  children: React.ReactNode
  fullWidth?: boolean
  style?: React.CSSProperties
  smooth?: boolean
}

const ScrollToIdFeature: React.FC<ScrollToIdFeatureProps> = ({ page, id, children, fullWidth, style, smooth }) => (
  <ScrollToId
    page={page}
    id={id}
    style={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontSize: 14,
      fontWeight: 'bold',
      width: fullWidth ? '100%' : undefined,
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,
      ...style,
    }}
    hoverColor={constants.COLOR_MAIN_NEW}
    smooth={smooth}
  >
    {children}
  </ScrollToId>
)

export const MenuList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const menuRectBottom = document.querySelector('#menu-button')?.getBoundingClientRect().bottom

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <>
      {isMdUp ? (
        <ul className={classes.ulContainer} style={props.styleMdUp}>
          {props.items.map((item, index) => {
            return (
              <li key={`menu-item-key-${index}`} style={{ height: '100%' }}>
                <ScrollToIdFeature page={props.page} id={item.id} smooth={props.smooth}>
                  <span>{item.label}</span>
                </ScrollToIdFeature>
              </li>
            )
          })}
        </ul>
      ) : (
        <>
          <Button
            id="menu-button"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.featureButton}
            endIcon={
              <img
                src={`${process.env.PUBLIC_URL}/assets/landing/features/arrow_${open ? 'up' : 'down'}.svg`}
                alt={`menu-list-arrow`}
              />
            }
            style={{ fontFamily: constants.FONT_FAMILY_TOP_TEXT, ...props.styleMdDown }}
          >
            {props.buttonLabel}
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper
                  className={classes.paper}
                  style={{
                    width: anchorRef.current?.offsetWidth,
                    maxHeight: `calc(${window.innerHeight}px - ${menuRectBottom}px - 10px)`,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuItemList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {props.items.map((item, index) => (
                        <MenuItem key={`menu-item-key-${index}`} onClick={handleClose} className={classes.menuItem}>
                          <ScrollToIdFeature
                            page={props.page}
                            id={item.id}
                            fullWidth
                            style={{
                              paddingLeft: 16,
                              height: 48,
                            }}
                            smooth={props.smooth}
                          >
                            <span>{item.label}</span>
                          </ScrollToIdFeature>
                        </MenuItem>
                      ))}
                    </MenuItemList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  ulContainer: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    width: '100%',
    maxWidth: '1000px',
    height: 60,
    borderRadius: 8,
    boxShadow: '0px 0px 24px #00000014',
    backgroundColor: constants.COLOR_WHITE,
  },

  featureButton: {
    padding: '0 16px 0 24px',
    width: '100%',
    height: 60,
    borderRadius: 8,
    boxShadow: '0px 0px 24px #00000014',
    backgroundColor: constants.COLOR_WHITE,
    fontWeight: 'bold',
    textAlign: 'left',
    position: 'relative',
    '&:hover': {
      backgroundColor: constants.COLOR_WHITE,
    },
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 20,
    },
  },
  paper: {
    marginTop: 3,
    borderRadius: 8,
    width: '100%',
    boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
    overflow: 'auto',
  },
  menuItem: {
    padding: 0,
  },
}))
