import * as React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme, makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import { NoStyleButton } from 'components/NoStyleButton'
import { textToHtml } from 'utils/snitize'

import { Content } from '../hooks/useNotification'

import { constants } from 'assets'

dayjs.extend(timezone)

type Props = {
  isLoading: boolean
  error: Error | null
  notifications: Content[]
  page: number
  numPerPage: number
  isLatestLink: boolean
}

export const NotificationList = ({ isLoading, error, notifications, page, numPerPage, isLatestLink }: Props) => {
  const classes = useStyles()
  const selectedContents = React.useMemo(
    () => notifications.filter((_, i) => i + 1 > (page - 1) * numPerPage && i + 1 <= page * numPerPage),
    [notifications, page, numPerPage]
  )
  const isOpenContent = isLatestLink && page === 1

  if (isLoading) {
    return (
      <div className={classes.progress}>
        <CircularProgress size={34} />
      </div>
    )
  }

  if (error) {
    console.log('error:', error)
    return <div>データを取得できませんでした。ページを再読み込みしてください。</div>
  }

  if (notifications.length === 0) {
    return <div>現在のところお知らせはありません。</div>
  }

  return (
    <>
      {selectedContents.map((content, i) => (
        <React.Fragment key={content.title}>
          <ListItem index={i} isOpenFirst={isOpenContent && i === 0} {...content} />
        </React.Fragment>
      ))}
    </>
  )
}

const ListItem = ({ title, body, date, index, isOpenFirst }: Content & { index: number; isOpenFirst: boolean }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(isOpenFirst)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div className={`${classes.box} ${index % 2 === 0 ? classes.bgOdd : ''}`}>
      <div className={classes.date}>
        <span>お知らせ</span>
        <time dateTime={date}>{dayjs(date).format('YYYY.MM.DD')}</time>
      </div>
      <div className={classes.sentence}>
        <div className={classes.titleBox}>
          <h2 className={`${classes.title} ${!isOpen ? 'short' : ''}`}>{title}</h2>
          {!isOpen && (
            <div className={classes.openBtnBox}>
              <NoStyleButton className={classes.button} onClick={handleOpen}>
                全文を見る
              </NoStyleButton>
            </div>
          )}
        </div>
        <div className={`${classes.bodyBox} ${isOpen ? 'open' : ''}`}>
          {textToHtml(body)}
          <div className={classes.closeBtnBox}>
            {isOpen && (
              <NoStyleButton className={classes.button} onClick={handleClose}>
                閉じる
              </NoStyleButton>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // --------------------
    // NotificationList

    progress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiCircularProgress-root': {
        color: constants.COLOR_MAIN_NEW,
      },
    },

    // --------------------
    // ListItem

    box: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px 24px',
      padding: 16,
      lineHeight: 1.6,

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: 24,
      },
    },
    bgOdd: {
      backgroundColor: constants.COLOR_GRAY_LIGHT3,
    },
    date: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 16,

      '& span': {
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        height: 32,
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: constants.COLOR_WHITE,
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 16,
      },
      '& time': {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 'bold',
      },
    },
    sentence: {
      flex: 1,
    },
    titleBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      gap: '16px 32px',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        marginTop: 5,
      },
    },
    title: {
      flex: 1,
      margin: 0,
      fontSize: 14,
      fontWeight: 'bold',

      '&.short': {
        [theme.breakpoints.up('md')]: {
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': 1,
        },
      },
    },
    openBtnBox: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right',
      },
    },
    button: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    bodyBox: {
      margin: 0,
      maxHeight: 0,
      transition: 'all .4s',
      overflow: 'hidden',
      '&.open': {
        margin: '24px 0 0',
        maxHeight: '100svh',
      },

      '& p': {
        margin: 0,
        fontSize: 14,
        whiteSpace: 'pre-wrap',
      },
      '& a': {
        color: constants.COLOR_MAIN_NEW,
        textDecoration: 'underline',
      },
    },
    closeBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 32,
    },
  }),
  { name: 'NotificationList' }
)
