import React, { useContext } from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { GhostMemberRole } from 'utils/generated'

import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'
import { FormBox, Font, UsefulCard } from '../atoms'

type Props = {
  reportNumber: 1 | 2
  onChange: (val: string) => void
  formState: { 1: string; 2: string }
}

export const ReportBox: React.FC<Props> = ({ reportNumber, onChange, formState }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { teamMember } = useContext(ghostTeamMemberContext)

  return (
    <>
      <UsefulCard
        backgroundColor="white"
        borderRadius={8}
        ownStyles={{ marginTop: 16, padding: isSmDown ? '27px 16px 16px' : 40 }}
      >
        <Font
          fontSize={14}
          bold
          ownStyles={{
            paddingBottom: 8,
          }}
        >
          <>{reportNumber === 1 ? 'オバケが発生したと思われる原因' : '今後の対処方法'}</>
        </Font>
        <Font
          fontSize={12}
          ownStyles={{
            paddingBottom: 8,
          }}
        >
          ※この後全体に公開されます
        </Font>
        {teamMember?.role === GhostMemberRole.Leader && (
          <Font fontSize={12}>※ファシリテーターは入力しなくても先へ進めます。</Font>
        )}

        <FormBox
          onChange={onChange}
          text={formState[reportNumber]}
          ownStyles={{ height: isSmDown ? 203 : 177, marginTop: 10 }}
          placeholder={reportNumber === 1 ? '個人の目標を重視するあまり…' : '自部署の仕事の価値について…'}
        />
      </UsefulCard>
    </>
  )
}
