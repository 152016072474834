import React, { FC } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import MuiModal from '@material-ui/core/Modal'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

interface ModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Modal: FC<ModalProps> = ({ isOpen, setIsOpen, children }) => {
  const classes = useStyles()

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: `${constants.TEXT_GRAY_DARK}B3` },
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>{children}</div>
      </Fade>
    </MuiModal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: '0 12px',
    padding: 40,
    width: '100%',
    maxWidth: 350,
    maxHeight: '90%',
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 8,
    outline: 'none',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
}))
