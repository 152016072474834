import { connect, ConnectedProps } from 'react-redux'

import { RootStateType } from '../../../store'
import { SetupactionActions } from '../../../stores/setupaction/action'
import { IMission, IActionItem } from '../../../stores/setupaction/reducer'

const mapDispatchToProps = (dispatch: any) => ({
  submit: async (item: IActionItem, data: IMission) => {
    if (item.valuesId && data.value !== null && item.point && item.category) {
      dispatch(
        SetupactionActions.submitMission.started({
          data: {
            id: item.valuesId,
            setupItemId: item.id,
            category: item.category,
            value: data.value,
            point: item.point,
            settedAt: data.settedAt,
            comment: data.comment,
            setupStartedAt: data.setupStartedAt,
          },
          item,
        })
      )
    }
  },
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    setup: { ...state.setup },
    setupaction: { ...state.setupaction },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
