import * as React from 'react'

import { makeStyles, Theme, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import CheckIcon from '../assets/checkedIcon.svg'
import * as constants from 'assets/constants'

type Props = {
  maxNumber: number //only over 1
  value: number
  onChange: () => void
  style?: React.CSSProperties
  hasThinBorder?: boolean
}

export const SelectBox: React.FC<Props> = ({ value, onChange, maxNumber, hasThinBorder }) => {
  const classes = useStyles()

  const options = () => {
    const items = [] as number[]
    if (maxNumber < 1) {
      console.log('maxNumberは1以上を選択してください')
      items.push(1)
      return items
    }
    for (let i = 1; i <= maxNumber; i++) {
      items.push(i)
    }
    return items
  }
  return (
    <div className={`${classes.selectBoxRoot} ${hasThinBorder ? classes.thinBorder : ''}`}>
      <Select
        value={value}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          classes: { paper: classes.muiPaperRoot },
        }}
      >
        {options()?.map((item) => (
          <MenuItem key={`menuItem-${item}`} value={item} classes={{ root: classes.muiListItemRoot }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  selectBoxRoot: {
    //for init
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    //
    // === for style ===
    //classes.selectBoxRoot
    width: 94,
    height: 48,
    borderRadius: 8,
    border: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    color: constants.TEXT_GRAY_DARK,
    '& .MuiInputBase-root': {
      width: '100%',
      height: '100%',
    },
    '& .MuiSelect-root': {
      width: '100%',
      height: 'calc(100% - 12px)',
      padding: '12px 8px 0 16px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      '&:focus': {
        background: constants.COLOR_WHITE,
      },
    },
    '& .MuiSelect-icon': {
      right: 8,
      fill: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },
  thinBorder: {
    border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    '& .MuiSelect-root': {
      borderRadius: 8,
    },
  },

  //for popOverPosition
  muiPaperRoot: {
    maxHeight: 200,
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  //for menuItems
  muiListItemRoot: {
    padding: '14px 0 14px 37px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontWeight: 'normal',
    '&.Mui-selected': {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      background: constants.COLOR_WHITE,
      padding: '14px 0 14px 13px',
      '&:before': {
        content: '" "',
        backgroundImage: `url(${CheckIcon})`,
        display: 'inline-block',
        height: '1rem',
        width: '1rem',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        marginRight: 8,
      },
    },
  },
}))
