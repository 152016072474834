import React from 'react'

import { CreateTeamInput, Team, UpdateTeamInput } from 'utils/generated'

import { mutationCreateTeam, mutationUpdateTeam, queryGetTeam, queryGetTeams } from './graphql'

export const useTeam = (teamId?: string) => {
  const [team, setTeam] = React.useState<Team | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error[] | undefined>()

  const fetchTeam = async (teamId?: string): Promise<Team | undefined> => {
    if (!teamId) {
      return
    }

    return await queryGetTeam({ id: teamId })
  }

  const refresh = async (): Promise<Team | undefined> => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeam({ id: teamId })
      if (response) {
        setTeam(response)
        return response
      } else {
        setError([new Error('チーム情報を取得できませんでした')])
      }
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { team, loading, error, refresh, fetchTeam } as const
}

export const useManageTeam = () => {
  const [team, setTeam] = React.useState<Team | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()
  const [isUpdated, setIsUpdated] = React.useState(false)

  const createTeam = React.useCallback(
    async (input: CreateTeamInput): Promise<[Team | undefined, undefined | Error[]]> => {
      setLoading(true)
      setError(undefined)
      try {
        const response = await mutationCreateTeam({ input })
        setTeam(response)
        return [response, undefined]
      } catch (e) {
        setError(e.errors)
        return [undefined, e.errors]
      } finally {
        setLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  )

  const updateTeam = React.useCallback(async (input: UpdateTeamInput): Promise<Team | undefined> => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await mutationUpdateTeam({ input })
      setTeam(response)
      setIsUpdated(true)
      return response
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { team, loading, error, createTeam, updateTeam, isUpdated } as const
}

export const useTeams = ({ isSortByCreatedAt = false }: { isSortByCreatedAt?: boolean } = {}) => {
  const MAX_TEAMS_GET_COUNT = 100
  const [teams, setTeams] = React.useState<Team[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isMoreLoading, setIsMoreLoading] = React.useState<boolean>(false)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeams({ limit: MAX_TEAMS_GET_COUNT, isSortByCreatedAt })

      if (response) {
        setTeams(response.items || [])
        setNextToken(response.nextToken || undefined)
      }
    } catch (e: any) {
      setError(e.errors as Error)
    } finally {
      setLoaded(true)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    setIsMoreLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeams({ limit: MAX_TEAMS_GET_COUNT, nextToken, isSortByCreatedAt })
      if (response) {
        setTeams([...teams, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e: any) {
      setError(e.errors as Error)
    }
    setIsMoreLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, nextToken])

  return { teams, nextToken, loading, loaded, error, refresh, more, isMoreLoading } as const
}
