import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { Ghost, QueryGetGhostArgs, QueryGetGhostListArgs } from 'utils/generated'

import { queryGetGhost, queryGetGhostList } from './graphql'

export const getGhostList = async (args: QueryGetGhostListArgs) => {
  const res = await API.graphql({
    query: queryGetGhostList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })

  return res.data.getGhostList as Ghost[]
}

export const getGhost = async (args: QueryGetGhostArgs) => {
  const res = await API.graphql({
    query: queryGetGhost,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhost as Ghost
}
