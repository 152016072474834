import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'
import { forms } from '../static'

import action from './action'
import { IKarteMemberQuestionnaireAnswerSet } from './interface'

export interface StringKeyObject {
  [key: string]: any
}

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onStore: (leaderId: string, id: string, answers: IKarteMemberQuestionnaireAnswerSet, successCallback: () => void) => {
    const calculable: any[] = []
    const simple = []

    const allQuestions: StringKeyObject = {}

    for (let formIndex = 0; formIndex < forms.length; formIndex++) {
      const form = forms[formIndex]
      if (form.weight) {
        const questions = []

        for (let fieldIndex = 0; fieldIndex < form.fields.length; fieldIndex++) {
          const field = form.fields[fieldIndex]
          const value = answers[field.group][field.key]

          const prevData = allQuestions[field.group]
            ? allQuestions[field.group].data
              ? [...allQuestions[field.group].data]
              : []
            : []
          allQuestions[field.group] = {
            weight: form.weight,
            data: [
              ...prevData,
              {
                key: field.key,
                label: field.label,
                weight: field.weight || 999,
                value,
              },
            ],
          }

          questions.push({
            key: field.key,
            weight: field.weight || 999,
            value,
          })
        }
      } else {
        if (form.group) {
          simple.push({
            group: form.group,
            values: answers[form.group],
          })
        }
      }
    }

    for (const k of Object.keys(allQuestions)) {
      calculable.push({
        group: k,
        weight: allQuestions[k].weight || 999,
        questions: allQuestions[k].data,
      })
    }

    dispatch(
      action.store.started({
        leaderId,
        id,
        data: JSON.stringify({
          calculable,
          simple,
        }),
        successCallback,
      })
    )
  },
  onResetError: () => dispatch(action.resetError({})),
})

const mapStateToProps = (state: RootStateType) => {
  const { isLoading, error } = state.pages.karteMemberQuestionnaire
  return {
    user: { ...state.user },
    isLoading,
    error,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
