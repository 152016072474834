import React from 'react'
import { useParams } from 'react-router-dom'

import { GraphQLResult } from '@aws-amplify/api'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { HooksContext } from 'pages/teams/contexts'
import { useTeamBuilding, useManageTeamMembers } from 'pages/teams/hooks'
import { subscriptionUpdatedTeamBuilding } from 'pages/teams/hooks/graphql'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import { Tabs } from './components/tabs/Tabs'
dayjs.extend(utc)
dayjs.extend(timezone)

export const TeamsDashboardPage: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  const { teamBuilding, refresh: refreshTeamBuilding } = useTeamBuilding(teamId)
  const { currentUser } = React.useContext(HooksContext)
  const [initial, setInitial] = React.useState(false)

  const { updateTeamMember } = useManageTeamMembers()
  React.useEffect(() => {
    if (currentUser && !initial) {
      updateTeamMember({
        id: `${teamId}-${currentUser.id}`,
      })
      setInitial(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  // Update building before completed
  const subscribeUpdatedTeamBuilding = (teamBuilding: TeamBuilding) => {
    const sub = subscriptionUpdatedTeamBuilding().subscribe({
      next: ({ value }: { value: GraphQLResult<{ updatedTeamBuilding: TeamBuilding }> }) => {
        try {
          if (
            value.data?.updatedTeamBuilding &&
            value.data?.updatedTeamBuilding.id === teamBuilding.id &&
            value.data?.updatedTeamBuilding.status === TeamBuildingStatus.Completed
          ) {
            refreshTeamBuilding()
          }
        } catch (error: any) {
          console.error(`sub Error: ${error.message}`)
        }
      },
    })
    const unsubscribe = () => {
      sub.unsubscribe()
    }
    return unsubscribe
  }

  React.useEffect(() => {
    if (teamBuilding && teamBuilding.status !== TeamBuildingStatus.Completed) {
      subscribeUpdatedTeamBuilding(teamBuilding)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Tabs />
}
