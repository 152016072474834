import React from 'react'

import { Auth } from 'aws-amplify'

import { TeamBuildingMember, UpdateTeamBuildingMemberInput } from 'utils/generated'

import { mutationUpdateTeamBuildingMember, queryGetTeamBuildingMember, queryGetTeamBuildingMembers } from './graphql'

export const useTeamBuildingMember = (teamId?: string) => {
  const [teamBuildingMember, setTeamBuildingMember] = React.useState<TeamBuildingMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }
    const cognito = await Auth.currentAuthenticatedUser()
    if (!cognito) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingMember({
        id: `${teamId}-${cognito.getUsername()}`,
      })

      if (response) {
        setTeamBuildingMember(response)
      }
    } catch (e) {
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  return { teamBuildingMember, loading, error, refresh } as const
}

export const useTeamBuildingMembers = (teamId?: string) => {
  const limit = 100
  const [teamBuildingMembers, setTeamBuildingMembers] = React.useState<TeamBuildingMember[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingMembers({
        teamId,
        limit,
      })
      if (response) {
        setTeamBuildingMembers(response.items)
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  return { teamBuildingMembers, nextToken, loading, error, refresh } as const
}

export const useManageTeamBuildingMember = () => {
  const [teamBuildingMember, setTeamBuildingMember] = React.useState<TeamBuildingMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()

  const updateTeamBuildingMember = React.useCallback(async (input: UpdateTeamBuildingMemberInput) => {
    setLoading(true)
    setError(undefined)

    try {
      const response = await mutationUpdateTeamBuildingMember({
        input,
      })
      if (response) {
        setTeamBuildingMember(response)
        return response
      }
    } catch (e) {
      setError(e.errors)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { teamBuildingMember, updateTeamBuildingMember, loading, error } as const
}

export const useTeamBuildingMemberById = (teamId?: string, userId?: string | null) => {
  const [teamBuildingMember, setTeamBuildingMember] = React.useState<TeamBuildingMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId || !userId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingMember({
        id: `${teamId}-${userId}`,
      })
      if (response) {
        setTeamBuildingMember(response)
      }
    } catch (e) {
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId, userId])

  return { teamBuildingMember, loading, error, refresh } as const
}
