import React from 'react'

import { DescriptionTools } from 'pages/teams/components'

export const Description = () => (
  <DescriptionTools
    title={'TBチェック'}
    recommendedTime={'（推奨時間：5分）'}
    hasSpWrap={false}
    description={
      'ツールを利用したらTBチェックのアンケートに回答しましょう。\nよりよいチーム作りのためにあなたの率直な意見を入力しましょう。\n出てきた結果について、一人ひとりがチームの当事者として受け止めた上で、\nよりよいチーム作りのために対話の時間を持ちましょう。'
    }
  />
)
