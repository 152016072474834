import React, { useState } from 'react'

import {
  queryGetOnboardingActionsFeedbacks,
  queryGetOnboardingPostListOfOneUser,
  queryGetOnboardingPostLikeListOfOneUser,
  queryGetOnboardingPostCommentLikeListOfOneUser,
  queryGetOnboardingPostSecondCommentLikeListOfOneUser,
  queryGetOnboardingActionLikeListOfOneUser,
} from 'pages/onboarding/graphql'
import { categories } from 'pages/onboarding/utils/ranking'
import { OnboardingTeamMember, OnboardingTeamMemberStatus } from 'utils/generated'

export const useRanking = (teamId?: string, teamMembers?: OnboardingTeamMember[]) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()
  const [teamMembersOwnData, setTeamMembersOwnData] = useState<OnboardingTeamMember[]>([])

  const refresh = React.useCallback(async () => {
    if (!teamId || !teamMembers || !teamMembers.length) {
      return
    }

    setLoading(true)
    setError(undefined)

    try {
      for (const teamMember of teamMembers) {
        if (teamMember.status !== OnboardingTeamMemberStatus.Accepted) {
          continue
        }

        const qActions = queryGetOnboardingActionsFeedbacks({ teamId, userId: teamMember.userId })
        const qPosts = queryGetOnboardingPostListOfOneUser({ teamId, userId: teamMember.userId, first: 1000 })
        const qPostLikes = queryGetOnboardingPostLikeListOfOneUser({ teamId, userId: teamMember.userId, first: 1000 })
        const qPostCommentLikes = queryGetOnboardingPostCommentLikeListOfOneUser({
          teamId,
          userId: teamMember.userId,
          first: 1000,
        })
        const qPostSecondCommentLikes = queryGetOnboardingPostSecondCommentLikeListOfOneUser({
          teamId,
          userId: teamMember.userId,
          first: 1000,
        })
        const qActionLikes = queryGetOnboardingActionLikeListOfOneUser({
          teamId,
          userId: teamMember.userId,
          first: 1000,
        })

        const [resActions, resPosts, resPostLikes, resPostCommentLikes, resPostSecondCommentLikes, resActionLikes] =
          await Promise.all([qActions, qPosts, qPostLikes, qPostCommentLikes, qPostSecondCommentLikes, qActionLikes])

        setTeamMembersOwnData((prev) => [
          ...prev,
          {
            ...teamMember,
            [categories.ownActions]: resActions.getOnboardingActionsFeedbacks || [],
            [categories.ownPosts]: resPosts.getOnboardingPostListOfOneUser?.items || [],
            [categories.ownLikes]: [
              ...resPostLikes.items,
              ...resPostCommentLikes.items,
              ...resPostSecondCommentLikes.items,
              ...(resActionLikes?.items || []),
            ],
          },
        ])
      }
    } catch (e) {
      console.log('e', e)
      setError(e as Error)
    }
    setLoading(false)
  }, [teamId, teamMembers])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { loading, error, teamMembersOwnData } as const
}
