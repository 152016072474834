import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { ClientLogoBox } from 'pages/landing/components/topPageModules/ClientLogoBox'

import { constants } from 'assets'

export const TopHero: React.FC = () => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'xs')

  return (
    <>
      <div className={classes.headerSpace}></div>
      <div>
        <div className={classes.hero}>
          <h1>
            <span>＼ 管理職主導で組織が変わる ／</span>
            簡単に始められる{isXsDown && '\n'}組織開発で{'\n'}エンゲージメントが{isXsDown && '\n'}向上する職場づくりを
          </h1>
          <div className={classes.book}>
            <img src={`${process.env.PUBLIC_URL}/img/topPage/book.png`} alt="社長書籍" />
            {!isXsDown && <span>NEWONE代表取締役社長{'\n'}上林周平 書籍</span>}
          </div>
        </div>

        <ClientLogoBox />
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerSpace: {
      height: constants.HEADER_HEIGHT_SP,
      [theme.breakpoints.up('md')]: {
        height: constants.HEADER_HEIGHT,
      },
    },
    hero: {
      position: 'relative',
      textAlign: 'center',
      height: `calc(100vh - ${constants.HEADER_HEIGHT_SP}px)`,
      color: constants.COLOR_WHITE,
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
      backgroundImage: `url(${process.env.PUBLIC_URL}/img/topPage/topImage-sp.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      animation: '$animationForFirstVisibleContent 700ms cubic-bezier(0,0.8,0.8,1) 0s 1 normal forwards running',
      '@global': {
        '@keyframes animationForFirstVisibleContent': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/topPage/topImage.jpg)`,
      },
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${constants.HEADER_HEIGHT}px)`,
      },

      '& h1': {
        margin: 0,
        padding: '0 4px',
        fontSize: 30,
        [theme.breakpoints.up('md')]: {
          fontSize: 44,
        },

        '& span': {
          display: 'block',
          padding: '0 0 16px',
          fontSize: 16,
          [theme.breakpoints.up('sm')]: {
            padding: '0 0 20px',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 20,
          },
        },
      },
    },
    prize: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 24,
      padding: '0 16px',
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },

      '& img': {
        marginTop: 16,
        width: 327,
        [theme.breakpoints.up('sm')]: {
          width: 570,
        },
      },
      '& span': {
        marginTop: 24,
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 1.6,
        [theme.breakpoints.down('xs')]: {
          marginTop: 18,
          width: 'calc(100% - 100px)',
          alignSelf: 'flex-start',
          textAlign: 'left',
        },
      },
    },
    book: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      textAlign: 'right',
      bottom: 80,
      right: 14,
      [theme.breakpoints.up('sm')]: {
        bottom: 90,
      },

      '& img': {
        width: 84,
        [theme.breakpoints.up('sm')]: {
          width: 120,
        },
      },

      '& span': {
        position: 'relative',
        top: -4,
        right: 8,
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 1.6,
      },
    },
  }),
  { name: 'TopHero' }
)
