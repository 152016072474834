// -----------------------------
// interface
// -----------------------------
export type IProps = {
  onLinkHanlder: (path: string) => void
  isReload: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useLink = (): IProps => {
  /**
   * プロフィールページに遷移するハンドラー
   */
  const onLinkHanlder = (path: string): void => {
    window.location.href = `/hint/tips/profile/${path}`
  }

  /**
   * リロードを検知する
   */
  const isReload = (): boolean => {
    return window.performance.navigation.type === 1 ? true : false
  }

  return {
    onLinkHanlder,
    isReload,
  }
}
