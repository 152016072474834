import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { Font } from '../atoms'

type Props = {
  goSelect?: boolean
  label?: 'オバケを選びなおす'
}

export const GostImageForSidebar: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { setModal, resetSelectedGhosts } = useContext(GhostContext)
  return (
    <>
      <div className={classes.container}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/ghostList.svg'} alt={`ghostList`} style={{ paddingTop: 20 }} />
      </div>
      <Font
        fontSize={14}
        bold
        onClick={() => {
          if (props.label) {
            resetSelectedGhosts()
          }
          setModal(props.goSelect ? 'select' : 'list')
        }}
        ownStyles={{
          color: constGhost.COLOR_MAIN_NEW,
          textAlign: 'center',
          padding: '10px 0 18px',
          background: 'white',
          maxWidth: 160,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        {props.label || (
          <div>
            <span>オバケリスト </span>

            <img src={process.env.PUBLIC_URL + '/assets/svg/arrow.svg'} alt={`arrow`} />
          </div>
        )}
      </Font>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 160,
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
    [theme.breakpoints.up('sm')]: {
      marginBotttom: 20,
    },
  },
}))
