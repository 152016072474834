import * as React from 'react'

export const usePopover = (id: string) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const isOpenPopover = Boolean(anchorEl)
  const popoverId = isOpenPopover ? id : undefined

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  return { isOpenPopover, anchorEl, popoverId, handleOpenPopover, handleClosePopover }
}
