import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form'

import { FormError, SelectBox } from 'pages/teams/pages/_tools/buildings/components'

import type { Input } from '../../pages/PresentationOptions'

import { constants } from 'assets'

type Props = {
  maxNumber: number
  control: Control
  errors: DeepMap<Input, FieldError>
}

export const SelectTimer: React.FC<Props> = ({ maxNumber, control, errors }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Controller
          name={'answerCount'}
          control={control}
          defaultValue={1}
          rules={{ required: '入力してください' }}
          render={({ onChange, value }) => (
            <>
              <div className={classes.selectWrapper}>
                <p className={classes.selectText}>発表する人数を選択</p>
                <div className={classes.selectBox}>
                  <SelectBox maxNumber={maxNumber} value={value} onChange={onChange} hasThinBorder />
                  <span className={classes.unit}>人</span>
                </div>
              </div>
              <FormError error={errors.answerCount} />
            </>
          )}
        />
      </div>
      <div className={classes.card}>
        <Controller
          name={'minute'}
          control={control}
          rules={{ required: '入力してください' }}
          defaultValue={1}
          render={({ onChange, value }) => (
            <>
              <div className={classes.selectWrapper}>
                <p className={classes.selectText}>1人あたりの発表時間</p>
                <div className={classes.selectBox}>
                  <SelectBox maxNumber={60} value={value} onChange={onChange} hasThinBorder />
                  <span className={classes.unit}>分</span>
                </div>
              </div>
              <FormError error={errors.minute} />
            </>
          )}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      color: constants.TEXT_GRAY_DARK,
    },
    card: {
      padding: '24px 40px',
      background: '#fff',
      borderRadius: 8,
    },
    selectWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 8,
    },
    selectText: {
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
    },
    selectBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    unit: {
      fontSize: 14,
    },
  }),
  { name: 'SelectTimer' }
)
