import React, { useEffect, useRef } from 'react'

import { render, unmountComponentAtNode } from 'react-dom'

import { callScreenModal } from 'pages/onboarding/components/modal/ScreenModal'
import { ToolTip } from 'pages/onboarding/components/tooltip/index'

import { TutorialProps } from '../Tutorial'

/**
 * use this hook on where tutorial-things(modal, tooltip) appears.
 * each page component body listens to `tutorial` prop, and if provided, this hook renders modal and tooltips
 */
export const useTutorial = (tutorial?: TutorialProps) => {
  // this ref holds the element where tutorial tooltip appers on.
  const ttRef = useRef<HTMLElement>()
  // show tutorial content
  useEffect(() => {
    if (tutorial) {
      if (tutorial.tooltip && ttRef.current) {
        ttRef.current.setAttribute(
          'style',
          tutorial.notRelative ? 'z-index: 11000;' : 'z-index: 11000; position: relative;'
        )
        render(
          <ToolTip
            ttRefElm={ttRef.current}
            head={tutorial.tooltip.head}
            body={tutorial.tooltip.body}
            offset={tutorial.tooltip.offset}
            center={tutorial.tooltip.center}
            pointer={tutorial.tooltip.pointer}
            scrollTarget={tutorial.tooltip.scrollTarget}
            autoHeight={tutorial.tooltip.autoHeight}
            msg={tutorial.tooltip.msg}
            button={tutorial.tooltip.button}
            ownControl={tutorial.tooltip.ownControl}
          />,
          document.getElementById('modal-tooltip-div')
        )

        if (tutorial.tooltip.onWhat !== 'comment' && tutorial.tooltip.onWhat !== 'like') {
          ttRef.current.onclick = async (e) => {
            e.preventDefault()
            if (tutorial.tooltip) {
              ttRef.current?.removeAttribute('style')
              unmountTooltip()
              tutorial.tooltip?.onClickRef()
            }
          }
        }
      } else if (tutorial.modal) {
        callScreenModal(tutorial.modal)
      }
    }
    return () => {
      if (tutorial?.tooltip) {
        unmountTooltip()
      }
    }
  }, [ttRef, tutorial])

  const clearRefStyle = () => {
    ttRef.current?.removeAttribute('style')
    if (tutorial?.tooltip && tutorial.tooltip.onWhat !== 'comment') {
      unmountTooltip()
      tutorial.tooltip?.onClickRef()
    }
  }

  return { ttRef, clearRefStyle }
}

export const unmountTooltip = () => {
  const tt = document.getElementById('modal-tooltip-div')
  tt && unmountComponentAtNode(tt)
}
