import React from 'react'

import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api'
import dayjs from 'dayjs'

import { isLeader, isMember } from 'pages/teams/utils/teamMember'
import {
  CreateTeamAnketBuildingInput,
  CreateTeamAnketBuildingSummaryInput,
  CalcTeamAnketBuildingInput,
  QueryGetTeamAnketBuildingSummaryListByTeamArgs,
  TeamMember,
  TeamAnketBuildingSummary,
  TeamAnketBuildingItem,
  TeamAnketBuildingStatusForStart,
  TeamAnketBuildingSendEmail,
  MutationSendEmailToUnansweredTeamAnketMembersArgs,
  TeamAnketBuildingDataForExport,
} from 'utils/generated'

import {
  MutationCreateTeamAnketBuildingSummary,
  MutationCalcTeamAnketBuilding,
  MutationCreateTeamAnketBuilding,
  MutationSendTeamAnketEmail,
  QueryGetTeamAnketBuildingStatusForStart,
  QueryGetTeamAnketBuildingSummaryListByTeam,
  MutationSendEmailToUnansweredTeamAnketMembers,
  QueryGetTeamAnketBuildingDataForExport,
} from './graphql'

import { Pages } from 'assets'
import { replacePathParams } from 'assets/history'

export const getTeamAnketBuildingStatusForStart = async (teamId: string) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetTeamAnketBuildingStatusForStart, {
      teamId,
    })
  )

  return response.data.getTeamAnketBuildingStatusForStart
}

export const getTeamAnketBuildingDataForExport = async (teamIds: string[]) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetTeamAnketBuildingDataForExport, {
      teamIds,
    })
  )

  return response.data.getTeamAnketBuildingDataForExport
}

export const getTeamAnketBuildingSummaryListByTeam = async (args: QueryGetTeamAnketBuildingSummaryListByTeamArgs) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamAnketBuildingSummaryListByTeam, args))

  const { getTeamAnketBuildingSummaryListByTeam } = response.data

  return getTeamAnketBuildingSummaryListByTeam
}

export const createTeamAnketBuildingSummary = async (input: CreateTeamAnketBuildingSummaryInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationCreateTeamAnketBuildingSummary, { input }))

  const { createTeamAnketBuildingSummary } = response.data

  return createTeamAnketBuildingSummary as TeamAnketBuildingSummary
}

export const sendTeamAnketEmail = async (teamId: string) => {
  const response: any = await API.graphql(graphqlOperation(MutationSendTeamAnketEmail, { teamId }))

  const { sendTeamAnketEmail } = response.data

  return sendTeamAnketEmail as boolean
}

export const sendEmailToUnansweredTeamAnketMembers = async ({
  teamId,
}: MutationSendEmailToUnansweredTeamAnketMembersArgs) => {
  const response: any = await API.graphql(graphqlOperation(MutationSendEmailToUnansweredTeamAnketMembers, { teamId }))

  const { sendEmailToUnansweredTeamAnketMembers } = response.data

  return sendEmailToUnansweredTeamAnketMembers as TeamAnketBuildingSendEmail
}

export const calcTeamAnketBuilding = async (input: CalcTeamAnketBuildingInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationCalcTeamAnketBuilding, { input }))

  const { calcTeamAnketBuilding } = response.data

  return calcTeamAnketBuilding as TeamAnketBuildingSummary
}

export const createTeamAnketBuilding = async (input: CreateTeamAnketBuildingInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationCreateTeamAnketBuilding, { input }))

  const { createTeamAnketBuilding } = response.data

  return createTeamAnketBuilding
}

export const useHandleTeamAnketStart = (currentTeamMember: TeamMember | undefined, teamId?: string) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | undefined>()

  const getTeamAnketBuildingStatus = async (): Promise<TeamAnketBuildingStatusForStart | undefined> => {
    if (!teamId) return

    setIsLoading(true)
    setError(undefined)
    try {
      const response = await getTeamAnketBuildingStatusForStart(teamId)
      if (response) {
        return {
          targetAnketId: response.targetAnketId as string,
          isActive: response.isActive as boolean,
          hasAnswered: response.hasAnswered as boolean,
        }
      }
    } catch (e: any) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleTeamAnketStart = async () => {
    const targetAnket = await getTeamAnketBuildingStatus()
    if (!targetAnket?.isActive) return alert('アンケートの回答期間ではありません')
    if (!isLeader(currentTeamMember) && !isMember(currentTeamMember)) {
      return alert('アンケートはリーダーとメンバーのみ回答できます')
    }
    if (targetAnket.hasAnswered) return alert('アンケートは回答済みです')
    if (error) return alert('アンケート情報の取得に失敗しました')

    if (targetAnket.targetAnketId) {
      window.open(
        replacePathParams(Pages.TeamsAnketNew, {
          anketId: targetAnket?.targetAnketId as string,
          teamId,
        }),
        '_blank'
      )
    }
  }

  return { handleTeamAnketStart, isLoading } as const
}

export const useGetTeamAnketBuildingDataForExport = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<GraphQLResult | undefined>(undefined)

  const getTeamAnketBuildingData = async (teamIds: string[]): Promise<TeamAnketBuildingDataForExport[] | undefined> => {
    setIsLoading(true)
    setError(undefined)

    try {
      const response = await getTeamAnketBuildingDataForExport(teamIds)
      return response as TeamAnketBuildingDataForExport[]
    } catch (e: any) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return { getTeamAnketBuildingData, isLoading, error } as const
}

export const useTeamAnketBuildingSummaryListByTeam = (teamId: string, maxLen?: number) => {
  const [teamSummaryItem, setTeamSummaryItem] = React.useState<TeamAnketBuildingItem>()
  const [loadingRefresh, setLoadingRefresh] = React.useState<boolean>(true)
  const [loadingEvent, setLoadingEvent] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const [isSendingMail, setIsSendingMail] = React.useState(false)

  const refresh = React.useCallback(async () => {
    if (teamId) {
      setLoadingRefresh(true)
      setError(undefined)

      try {
        const response = await getTeamAnketBuildingSummaryListByTeam({ teamId, maxLen })

        if (response) {
          setTeamSummaryItem(response)
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoadingRefresh(false)
      }
    }
  }, [teamId, maxLen])

  const graphSummaryList = React.useMemo(() => {
    // reverse を実行するために、shallow copy を作成する。
    const closedSummaryList = teamSummaryItem?.closedSummaryList ? [...teamSummaryItem.closedSummaryList] : []
    const reverseList = closedSummaryList.reverse()

    let dateCount = 1

    return reverseList.length
      ? reverseList.map((summary, i, list) => {
          const base = dayjs(summary.date).tz('Asia/Tokyo')
          const answeredCount = summary.anketList?.length || 0
          const countLabel =
            answeredCount > 0 ? `${teamSummaryItem?.memberCount ?? '_'}人/${answeredCount}人回答` : '無回答'

          // 同じ日にTBチェックを複数回行った場合でも、一意となるようにラベルを付与する。
          let uniqueLabel = ''
          if (summary.date === list[i - 1]?.date) {
            dateCount += 1
            uniqueLabel = `${base.format(`YYYY/MM/DD_${dateCount}`)} (${countLabel})`
          } else {
            dateCount = 1
            uniqueLabel = `${base.format(`YYYY/MM/DD`)} (${countLabel})`
          }

          return { ...summary, label: uniqueLabel }
        })
      : []
  }, [teamSummaryItem?.closedSummaryList, teamSummaryItem?.memberCount])

  const handleClickCreateSummary = React.useCallback(async () => {
    setLoadingEvent(true)

    try {
      const res = await createTeamAnketBuildingSummary({ teamId })

      if (res.id) {
        await refresh()
      }
    } catch (e: any) {
      const errors = e.errors as Error[]
      alert(errors[0].message)
    } finally {
      setLoadingEvent(false)
    }
  }, [teamId, refresh])

  const handleCreateSummaryBySendEmail = React.useCallback(async (): Promise<boolean> => {
    if (!teamId) return false
    setLoadingEvent(true)

    try {
      const isSendMailSuccessful = await sendTeamAnketEmail(teamId)
      if (isSendMailSuccessful) refresh()
      return isSendMailSuccessful
    } catch (e: any) {
      const errors = e.errors as Error[]
      alert(errors[0].message)
      return false
    } finally {
      setLoadingEvent(false)
    }
  }, [teamId, refresh])

  const handleSendEmailToUnansweredTeamAnketMembers = React.useCallback(async () => {
    if (!teamId) {
      return
    }
    setIsSendingMail(true)

    try {
      const res = await sendEmailToUnansweredTeamAnketMembers({ teamId })

      if (res.errorMessage) {
        alert(res.errorMessage)
      } else if (!res.sentCount) {
        alert('全員回答済みです')
        refresh() // UI の情報が古いため更新する
      } else if (res.isCompleted) {
        alert('送信しました')
      } else {
        alert('送信に失敗しました\n時間をおいて再度お試しください')
      }
    } catch (e: any) {
      const errors = e.errors as Error[]
      alert(`エラーが発生しました\n${errors[0].message}`)
    } finally {
      setIsSendingMail(false)
    }
  }, [teamId, refresh])

  const handleClickCloseSummary = React.useCallback(async () => {
    setLoadingEvent(true)

    try {
      const res = await calcTeamAnketBuilding({ teamId })

      if (res.id) {
        await refresh()
      }
    } catch (e: any) {
      const errors = e.errors as Error[]
      alert(errors[0].message)
    } finally {
      setLoadingEvent(false)
    }
  }, [teamId, refresh])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return {
    teamSummaryItem,
    loadingRefresh,
    loadingEvent,
    error,
    isSendingMail,
    refresh,
    handleClickCreateSummary,
    handleCreateSummaryBySendEmail,
    handleSendEmailToUnansweredTeamAnketMembers,
    handleClickCloseSummary,
    graphSummaryList,
  } as const
}
