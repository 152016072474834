import * as React from 'react'

import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { TeamMemberRole } from 'utils/generated'
import { getTeamMemberStatusUsageLabel } from 'utils/labels'

import { TeamMemberWithNum } from '..'

import { Pages, constants } from 'assets'
import { useHistory } from 'assets/history'
import crownSvg from 'assets/svg/crown.svg'

type Props = {
  teamMember: TeamMemberWithNum
  teamId: string
  editable: boolean
  popoverButton: {
    isOpenPopover: boolean
    popoverId: string | undefined
    handleOpenPopover: (event: React.MouseEvent<HTMLButtonElement>) => void
    handleClosePopover: () => void
  }
}

export const MemberTableRow: React.FC<Props> = ({
  teamMember,
  editable,
  teamId,
  popoverButton: { isOpenPopover, popoverId, handleOpenPopover, handleClosePopover },
}) => {
  const classes = useStyles()
  const [isHovered, setIsHovered] = React.useState(false)
  const { route } = useHistory()
  const isMdUp = useCustomMediaQuery('up', 'md')

  const [reservedDay, reservedTime] = teamMember?.invitationDateTime?.split(' ') || []

  return (
    <TableRow
      className={classes.tableBodyRow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TableCell className={`${classes.tableBodyCell} ${classes.number} ${isHovered ? classes.activeHoverCell : ''}`}>
        {teamMember.num}
      </TableCell>
      <TableCell className={`${classes.tableBodyCell} ${classes.fullName} ${isHovered ? classes.activeHoverCell : ''}`}>
        {teamMember.role.includes(TeamMemberRole.Leader) ? (
          <NoStyleButton
            aria-owns={isOpenPopover ? popoverId : undefined}
            aria-haspopup="true"
            className={classes.crown}
            onClick={isMdUp ? undefined : handleOpenPopover}
            onMouseEnter={isMdUp ? handleOpenPopover : undefined}
            onMouseLeave={isMdUp ? handleClosePopover : undefined}
          >
            <img src={crownSvg} alt="leader-icon" />
            {teamMember.fullName}
          </NoStyleButton>
        ) : (
          teamMember.fullName
        )}
      </TableCell>
      <TableCell className={classes.tableBodyCell}>{teamMember.username}</TableCell>
      <TableCell className={classes.tableBodyCell}>{teamMember.organization}</TableCell>
      <TableCell className={classes.tableBodyCell}>{teamMember.position}</TableCell>
      <TableCell className={classes.tableBodyCell}>{teamMember.email}</TableCell>
      <TableCell className={classes.tableBodyCell}>
        <div className={classes.labelBox}>
          <span className={`${classes.label} ${classes.labelStatus} ${teamMember.statusUsage}`}>
            {getTeamMemberStatusUsageLabel(teamMember.statusUsage)}
          </span>
          {teamMember.statusUsage === 'RESERVED' && (
            <span className={classes.reservedInfo}>{`${reservedDay}\n${reservedTime}に配信予定`}</span>
          )}
        </div>
      </TableCell>
      <TableCell className={classes.tableBodyCell} style={{ whiteSpace: 'nowrap' }}>
        {teamMember.updatedAt && format(new Date(teamMember.updatedAt), 'yyyy-MM-dd HH:mm')}
      </TableCell>
      <TableCell
        className={classes.tableBodyCellEdit}
        onClick={() => {
          if (editable) {
            route.push(Pages.TeamsMembersEdit, {
              teamId,
              teamMemberId: teamMember.id,
            })
          }
        }}
      >
        {editable ? '編集' : ''}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    tableBodyRow: {
      backgroundColor: '#fff',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_GRAY_LIGHT7,
        },
      },
    },
    tableBodyCell: {
      maxWidth: 300,
      height: 52,
      borderBottom: 'none',
      fontSize: 12,
      padding: '0 16px',
      color: constants.COLOR_TEAMBUILDING_TEXT,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      '&:first-of-type': {
        padding: '0 15px 0 24px',
      },
    },
    number: {
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      backgroundColor: '#fff',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 0,
      },
    },
    fullName: {
      position: 'sticky',
      left: 80,
      minWidth: 128,
      maxWidth: 128,
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    activeHoverCell: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: constants.COLOR_GRAY_LIGHT7,
      },
    },
    crown: {
      width: '100%',
      height: '100%',
      fontSize: 12,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      '& img': {
        position: 'relative',
        top: 3,
        width: 16,
        height: 16,
        marginRight: 4,
      },
    },
    labelBox: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    label: {
      padding: '4px 8px',
      fontSize: 10,
      fontWeight: 'bold',
      borderRadius: 4,
    },
    labelStatus: {
      '&.ACTIVE': {
        background: constants.COLOR_MAIN_NEW,
        color: '#fff',
      },
      '&.INVITED': {
        background: constants.COLOR_TEAMBUILDING_RED,
        color: '#fff',
      },
      '&.RESERVED': {
        background: constants.COLOR_TEAMBUILDING_YERROW,
      },
      '&.STOP': {
        color: constants.TEXT_GRAY_DARK,
      },
    },
    reservedInfo: {
      margin: 0,
      fontSize: 10,
      textAlign: 'left',
      whiteSpace: 'pre',
    },
    tableBodyCellEdit: {
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
  }),
  { name: 'MemberTableRow' }
)
