import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { replacePathParams } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { RankingItem } from 'pages/onboarding/utils/ranking'

import { notExistUserName } from './SummaryBox'

import { ProgressBar } from '.'

import batchBronze from '../assets/batch-bronze.svg'
import batchGold from '../assets/batch-gold.svg'
import batchSilver from '../assets/batch-silver.svg'
import noImage from '../assets/noImage.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'





type Props = {
  teamId: string
  rankingItem: RankingItem
}
type StyleProps = {}

export const RankingItemBox: React.FC<Props & StyleProps> = ({ teamId, rankingItem }) => {
  const classes = useStyles()
  const { id, userId, src, name, role, count, barWidth, rank } = rankingItem

  const batches = useCallback((rank: number) => {
    switch (rank) {
      case 1:
        return batchGold
      case 2:
        return batchSilver
      case 3:
        return batchBronze
      default:
        break
    }
  }, [])

  return (
    <div className={classes.memberData} key={id}>
      <div className={classes.profile}>
        <div className={classes.avatarWrapper}>
          <Link to={replacePathParams(OnbPages.TeamMember, { teamId: teamId, userId: userId })}>
            <img className={classes.avatar} src={src ?? noImage} alt="avatar" />
          </Link>
          <div className={classes.batch}>
            {rank <= 3 ? <img src={batches(rank)} alt="" /> : <div className={classes.numBatch}>{rank}</div>}
          </div>
        </div>
        <div className={classes.nameRole}>
          <div className={name === notExistUserName ? classes.notExistUserName : classes.nickname}>{name}</div>
          {role ? <span className={classes.role}>{teamMemberRoleLabel(role)}</span> : <></>}
        </div>
      </div>

      <ProgressBar barWidth={barWidth} counter={count} />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    memberData: {
      display: 'flex',
      columnGap: 24,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        rowGap: 16,
        padding: '16px 8px',
        backgroundColor: constants.COLOR_WHITE2,
      },
    },
    profile: {
      display: 'flex',
      columnGap: 16,
      width: 186,
      paddingLeft: 8,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    avatarWrapper: {
      position: 'relative',
    },
    avatar: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      objectFit: 'cover',
    },
    batch: {
      position: 'absolute',
      top: -8,
      left: -8,
      '& img': {
        width: 24,
        height: 24,
        border: `1px solid ${constants.COLOR_WHITE}`,
        borderRadius: 12,
        verticalAlign: 'top',
      },
    },
    numBatch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      border: `1px solid ${constants.COLOR_WHITE}`,
      borderRadius: 12,
      fontSize: 14,
      fontWeight: 'bold',
    },
    nameRole: {
      minWidth: 0,
    },
    nickname: {
      marginBottom: 6,
      fontSize: 12,
      fontWeight: 'bold',
      overflowWrap: 'anywhere',
    },
    notExistUserName: {
      marginBottom: 6,
      color: constants.COLOR_ALERT,
      fontSize: 12,
      overflowWrap: 'anywhere',
    },
    role: {
      padding: 3,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 10,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 4,
    },
  }),
  { name: 'RankingItemBox' }
)
