import * as React from 'react'

import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core'
import Slider, { Mark } from '@material-ui/core/Slider'

import { constants } from 'assets'

interface IProps {
  name: string
  value?: number | undefined
  options: Mark[]
  onChange: (value: number) => void
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes, options, onChange } = props

  if (options.length === 0) return null

  return (
    <div className={classes.container}>
      <div className="__slider">
        <Slider
          classes={{ mark: '__mark', thumb: '__thumb', rail: '__rail', markLabel: '__markLabel' }}
          value={props.value || 0}
          valueLabelDisplay="off"
          step={1}
          marks={options}
          track={false}
          min={options[0].value}
          max={options[options.length - 1].value}
          onChange={(e, newValue) => onChange(newValue as number)}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      '& .__slider': {
        lineHeight: 0,
      },
      // 目盛りを表示
      '& .__mark': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        height: 7,
        width: 3,
      },
      '& .__rail': {
        height: 2,
        backgroundColor: constants.COLOR_MAIN_NEW,
        opacity: 1,
      },
      '& .__thumb': {
        height: 24,
        width: 24,
        marginTop: -12, // スライダーのカーソルの位置調整に必要
        marginLeft: -12, // スライダーのカーソルの位置調整に必要
        backgroundColor: '#fff',
        border: `2px solid ${constants.COLOR_MAIN_NEW}`,

        [theme.breakpoints.down('xs')]: {
          height: 20,
          width: 20,
          marginTop: -10,
          marginLeft: -10,
        },
      },
      '& .__markLabel': {
        paddingTop: 3,
        fontSize: 12,
        fontWeight: 'bold',

        '&[data-index="0"], &[data-index="1"], &[data-index="2"], &[data-index="3"], &[data-index="4"]': {
          color: constants.COLOR_RED4,
        },
        '&[data-index="5"]': {
          color: constants.TEXT_GRAY_DARK,
        },
        '&[data-index="6"], &[data-index="7"], &[data-index="8"], &[data-index="9"], &[data-index="10"]': {
          color: constants.COLOR_MAIN_NEW,
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
