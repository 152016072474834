// 共通カラー、縦横幅数値用の定数ファイル

export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BLACK = '#292626'
export const COLOR_BLACK2 = '#191919'
export const COLOR_BLACK3 = '#333333'
export const COLOR_BLACKFORTEXT = '#000000'
export const COLOR_BLACKFORGRAY = '#3C3C3C'
export const COLOR_GRAY = '#B9B9B9'
export const COLOR_GRAY2 = '#707070'
export const COLOR_GRAYTEXT = '#A2A2A2'
export const COLOR_LIGHTGRAY = '#F5F5F5'
export const COLOR_LIGHTGRAY2 = '#F9F9F9'
export const COLOR_LIGHTGRAY3 = '#E9E9E9'
export const COLOR_LIGHTGRAY4 = '#E2E2E2'
export const COLOR_LIGHTGRAY5 = '#9F9F9F'
export const COLOR_DARKGRAY = '#313030'
export const COLOR_BORDERLIGHTGRAY = '#D8D8D8'
export const COLOR_HATENAGRAY = '#656565'
export const COLOR_HATENALIGHTGRAY = '#3c3c3c13' // include opacity 20%
export const COLOR_HATENABLACK = '#000000'
export const COLOR_YELLOW = '#FCCF00'
export const COLOR_LIGHTYELLOW = '#FFF8D5'
export const COLOR_YELLOWSHADOW = '#62570010' // include opacity 15%
export const COLOR_RED = '#F82525'
export const COLOR_WARNING_RED = '#CE2C2C'
export const COLOR_LIGHTBLUE = '#E9F8F9'
export const COLOR_MAINBLUE = '#2CBBC6'
export const COLOR_DARKBLUE = '#39B3BC'
export const COLOR_MAIN_NEW = '#00838C'
export const COLOR_TOOLTIP_CONTENT = '#F2F9F9'

export const BACKCARD_WIDTH = 1280 //if you use responsive, use md(960) of mediaQuery
export const CONTENT_WIDTH = 800
export const MAIN_CONTENT_WIDTH = 1
export interface dummyData {
  name: string
  label: string
}
