import csvIcon from '../assets/fileTypeIcons/csvIcon.svg'
import docxIcon from '../assets/fileTypeIcons/docxIcon.svg'
import pdfIcon from '../assets/fileTypeIcons/pdfIcon.svg'
import pptxIcon from '../assets/fileTypeIcons/pptxIcon.svg'
import txtIcon from '../assets/fileTypeIcons/txtIcon.svg'
import xlsxIcon from '../assets/fileTypeIcons/xlsxIcon.svg'

export const fileIcon = (fileName: string) => {
  const suffix = '.' + fileName.split('.').pop()
  switch (suffix) {
    case '.doc':
    case '.docx':
      return docxIcon
    case '.xls':
    case '.xlsx':
      return xlsxIcon
    case '.ppt':
    case '.pptx':
      return pptxIcon
    case '.pdf':
      return pdfIcon
    case '.csv':
      return csvIcon
    default:
      return txtIcon
  }
}

export const fileSize = (size: number) => {
  if (size >= 1000000) {
    return `${Math.round((size / 1000000) * 10) / 10}MB`
  }
  if (size >= 1000) {
    return `${Math.round((size / 1000) * 10) / 10}KB`
  }
  return `${Math.round(size * 10) / 10}バイト`
}
