import { useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'
import { RootState } from 'stores/reducers'

import { ICreateHintCommentParams } from 'pages/hint/_shared/stores/apis/createHintComment'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  isExistsComment: () => boolean | undefined
  isShowForm: () => boolean
  isShowComments: () => boolean
  onSetParams: (params: any) => void
  getCommentParams: () => ICreateHintCommentParams
}

// -----------------------------
// hooks
// -----------------------------
export const useTipsArticle = (): IProps => {
  const userSelector = useSelector((state: RootState) => state.user)
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const [params, setParams] = useState<ICreateHintCommentParams>({
    id: '',
    hintId: '',
    content: '',
    type: 'HINT',
  })

  /**
   * コメントが存在するかどうかをチェック
   */
  const isExistsComment = (): boolean | undefined => {
    if (userSelector.id) {
      const isExists = hintSelector.hintCommentList?.some((hintCommentList) => {
        return hintCommentList.id === userSelector?.id
      })
      return isExists
    }
    return false
  }

  /**
   * フォームの出し分けを行う
   */
  const isShowForm = (): boolean => {
    const isShow = !isExistsComment() && userSelector?.id !== hintSelector?.hint?.user?.id
    return isShow ? true : false
  }

  /**
   * コメント一覧の出し分けを行う
   */
  const isShowComments = (): boolean => {
    const isShow = hintSelector.hintCommentList && hintSelector.hintCommentList.length > 0
    return isShow ? true : false
  }

  /**
   * コメント投稿時のハンドラー
   */
  const onSetParams = useCallback(
    (params: ICreateHintCommentParams): void => {
      if (userSelector.id) {
        setParams({
          id: params.id,
          hintId: params.hintId,
          content: params.content,
          type: params.type,
        })
      }
    },
    [userSelector.id]
  )

  /**
   * コメント投稿時の情報を返す
   */
  const getCommentParams = (): ICreateHintCommentParams => {
    return params
  }

  return {
    isExistsComment,
    isShowForm,
    isShowComments,
    onSetParams,
    getCommentParams,
  }
}
