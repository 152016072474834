import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTeamAnketBuildingSummaryListByTeam } from 'services/api/anket'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { Card, CardHeader } from 'pages/teams/components'
import { Team } from 'utils/generated'

import { CloseIcon } from '../../assets'

import AnketBuildingSummary from './AnketBuildingSummary'

import { constants } from 'assets'

interface Props {
  team: Team
  onClose: () => void
}

export const GraphView: React.FC<Props> = (props) => {
  const { team, onClose } = props
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')
  // TODO もし1000件を超えるデータを表示するとしたら、既存の UI では厳しいので、そうした要望があったら UI・ロジックともに改修する。
  const { loadingRefresh, loadingEvent, graphSummaryList } = useTeamAnketBuildingSummaryListByTeam(team.id, 1000)

  return (
    <>
      {isSmDown && (
        <div className={classes.closeWrapper}>
          <div className={classes.closeButtonMobile} onClick={onClose}>
            <CloseIcon />
            <span>閉じる</span>
          </div>
        </div>
      )}
      <Card ownStyles={{ padding: isSmDown ? '26px 8px 8px' : '24px 18px 50px' }}>
        <CardHeader
          title={`${team.name} 状況`}
          onClickTitle={
            <div className={classes.closeButtonContainer}>
              <div className={classes.closeButton}>
                <CloseIcon />
              </div>
              閉じる
            </div>
          }
          onClick={!isSmDown ? onClose : undefined}
          style={{ textDecoration: 'none', fontWeight: 'normal' }}
        />

        <AnketBuildingSummary
          teamId={team.id}
          summaryList={graphSummaryList}
          loading={loadingRefresh || loadingEvent}
        />
      </Card>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  closeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: 14,

    '&:hover': {
      opacity: '75%',
    },
  },

  closeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 8,
  },
  closeButtonMobile: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& span': {
      paddingLeft: 8,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      fontSize: 14,
    },

    '&:hover': {
      opacity: '75%',
    },
  },

  closeButton: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 8,
  },

  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 40,
  },
}))
