import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import { BackResponsiveCard, ButtonForGhost, GrayBackGround } from '../components/atoms'
import { WaitingContainer } from '../components/modules/WaitingContainer'
import { LoadingIndicator } from '../components/organisms'
import { GhostVotingResultsList } from '../components/organisms/GhostVotingResultsList'
import { GhostContext } from '../contexts/ghost'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { useFetchReports } from '../hooks/fetchReports'
import { CommandContext } from '../service/commands'

type Props = {}

export const VotingResults: React.FC<Props> = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const classes = useStyles()
  const { ghosts } = useContext(GhostContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const { runGoToNextPage } = useContext(CommandContext)
  const { fetchReports, reports, isFetchingReports } = useFetchReports()

  const isLoading = !teamMember || reports.length === 0 || isFetchingReports
  useEffect(() => {
    if (teamId && teamMember) {
      fetchReports(teamId)
    }
  }, [teamId, teamMember, fetchReports])

  const onClick = async () => {
    teamMember && (await runGoToNextPage(teamMember))
    await updateGhostTeam({
      input: {
        id: teamId,
        progress: GhostProgress.S2DoneRandomAnswers,
      },
    })
  }
  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard>
          <WidthControlContainer>
            <h1 className={classes.mainTitle}>投票結果</h1>
            {isLoading ? null : <GhostVotingResultsList reports={reports} ghosts={ghosts} />}

            {isLoading ? (
              <LoadingIndicator snapshot={{}} />
            ) : teamMember?.role === GhostMemberRole.Leader ? (
              <ButtonContainer
                fixed
                buttons={[
                  <ButtonForGhost
                    key="ghost-rule"
                    buttonBodyColor="green"
                    bodyText="次へ"
                    onClick={onClick}
                    isResizeBasic
                  />,
                ]}
              />
            ) : (
              <WaitingContainer />
            )}
          </WidthControlContainer>
        </BackResponsiveCard>
      </GrayBackGround>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    mainTitle: {
      margin: '0 0 24px',
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }),
  { name: 'VotingResults' }
)
