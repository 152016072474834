import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Woman: React.FC = () => {
  return (
    <Svg width="168.576" height="358.016" viewBox="0 0 168.576 358.016">
      <G transform="translate(-788.762 -305)">
        <Path
          d="M825.176,652.33v.517c0,10.075,8.166,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.036v-.995a2.547,2.547,0,0,0-2.547-2.547H827.745a2.546,2.546,0,0,0-2.546,2.547Z"
          fill="#72642a"
        />
        <Path
          d="M884.823,652.33v.517c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.976.155,18.228-9.7h.035v-.995a2.546,2.546,0,0,0-2.546-2.547H887.391a2.546,2.546,0,0,0-2.546,2.547Z"
          fill="#72642a"
        />
        <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(884.823 560.314)" fill="#ffe5d2" />
        <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(825.163 560.314)" fill="#ffe5d2" />
        <Path
          d="M874.191,356.72s-15.615,25.42-15.615,35.225a26.05,26.05,0,0,0,8.351,18.519c2.9,3.267-10.495,17.768-2.8,32.467,7.988,15.252,5.4,5.044,36.251,5.769s-7.608,32.9,6.189,13.652c6.76-9.43,21.76-29.779,25.712-52.5,3.4-19.563-6.914-63.219-6.914-63.219"
          fill="#6d614a"
        />
        <Path
          d="M868.978,356.72s15.615,25.42,15.615,35.225a26.05,26.05,0,0,1-8.351,18.519c-2.905,3.267,10.495,17.768,2.8,32.467-7.988,15.252-5.4,5.044-36.251,5.769s7.608,32.9-6.189,13.652c-6.76-9.43-21.764-29.779-25.712-52.5-3.4-19.563,6.914-63.219,6.914-63.219"
          fill="#6d614a"
        />
        <Path
          d="M916.834,424.6H829.646a4.536,4.536,0,0,0-4.483,4.558V545.168H921.3V429.156A4.535,4.535,0,0,0,916.834,424.6Z"
          fill="#7192cc"
        />
        <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(862.758 410.186)" fill="#ffe5d2" />
        <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(808.395 359.523)" fill="#ffe5d2" />
        <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(903.39 359.523)" fill="#ffe5d2" />
        <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(818.629 314.31)" fill="#ffe5d2" />
        <Path
          d="M930.044,366.406c-18.3,0-12.284,3.232-25.759-18.873-23.806,25.615-76.5,17.25-89.471,9.881,3.63-23.475,24.713-52.414,56.91-52.414S930.044,332.48,930.044,366.406Z"
          fill="#6d614a"
        />
        <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(825.163 525.27)" fill="#7192cc" />
        <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(847.59 424.598)" fill="#ffe5d2" />
        <G>
          <G>
            <Path
              d="M789.314,550.7l19.574-64.528a12.747,12.747,0,0,1,15.9-8.5h0a12.744,12.744,0,0,1,8.5,15.9l-19.574,64.528a12.745,12.745,0,0,1-15.9,8.5h0A12.745,12.745,0,0,1,789.314,550.7Z"
              fill="#ce6f6f"
            />
            <Rect
              x="0"
              y="0"
              width="116.547"
              height="28.78"
              transform="translate(791.18 540.424) rotate(-73.125)"
              fill="#ce6f6f"
            />
          </G>
          <G>
            <Path
              d="M789.314,550.7l19.574-64.528a12.747,12.747,0,0,1,15.9-8.5h0a12.744,12.744,0,0,1,8.5,15.9l-19.574,64.528a12.745,12.745,0,0,1-15.9,8.5h0A12.745,12.745,0,0,1,789.314,550.7Z"
              fill="#ffe5d2"
            />
            <Rect
              x="0"
              y="0"
              width="116.547"
              height="28.78"
              transform="translate(791.18 540.424) rotate(-73.125)"
              fill="#7192cc"
            />
          </G>
        </G>
        <G>
          <G>
            <Path
              d="M948.29,566.591h0a12.747,12.747,0,0,1-15.9-8.5l-19.574-64.528a12.745,12.745,0,0,1,8.5-15.9h0a12.745,12.745,0,0,1,15.9,8.5L956.786,550.7A12.744,12.744,0,0,1,948.29,566.591Z"
              fill="#ce6f6f"
            />
            <Rect
              x="0"
              y="0"
              width="28.78"
              height="116.547"
              transform="translate(893.548 437.25) rotate(-16.875)"
              fill="#ce6f6f"
            />
          </G>
          <G>
            <Path
              d="M948.29,566.591h0a12.747,12.747,0,0,1-15.9-8.5l-19.574-64.528a12.745,12.745,0,0,1,8.5-15.9h0a12.745,12.745,0,0,1,15.9,8.5L956.786,550.7A12.744,12.744,0,0,1,948.29,566.591Z"
              fill="#ffe5d2"
            />
            <Rect
              x="0"
              y="0"
              width="28.78"
              height="116.547"
              transform="translate(893.548 437.25) rotate(-16.875)"
              fill="#7192cc"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman
