import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../assets/constants'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <h3>特定商取引法に基づく表示</h3>
          <div className="__content">
            <div className="__regal">
              <div className="__row">
                <p className="__column __title">販売事業者名 </p>
                <p className="__column __txt">{process.env.REACT_APP_COMPANY_NAME}</p>
              </div>
              <div className="__row">
                <p className="__column __title">販売事業者所在地 </p>
                <p className="__column __txt">{process.env.REACT_APP_COMPANY_ADDRESS}</p>
              </div>
              <div className="__row">
                <p className="__column __title">代表者または運営統括責任者</p>
                <p className="__column __txt">{process.env.REACT_APP_COMPANY_OWNER}</p>
              </div>
              <div className="__row">
                <p className="__column __title">代表者または運営統括責任者（フリガナ）</p>
                <p className="__column __txt">カンバヤシ シュウヘイ</p>
              </div>
              <div className="__row">
                <p className="__column __title">ホームページ</p>
                <p className="__column __txt">
                  <a href="https://new-one.co.jp/" className="__link">
                    https://new-one.co.jp/
                  </a>
                </p>
              </div>
              <div className="__row">
                <p className="__column __title">電子メール</p>
                <p className="__column __txt">{process.env.REACT_APP_COMPANY_MAIL}</p>
              </div>
              <div className="__row">
                <p className="__column __title">TEL</p>
                <p className="__column __txt">090-6014-2334</p>
              </div>
              <div className="__row">
                <p className="__column __title">商品等の引き渡し時期（日数）・発送方法</p>
                <p className="__column __txt">
                  特別な定めを置いている場合を除き、お支払い手続き完了後、１週間以内にご利用いただけます。
                </p>
              </div>
              <div className="__row">
                <p className="__column __title">代金の支払時期および方法</p>
                <p className="__column __txt">各種クレジットカード/各クレジットカード会社引き落とし日</p>
              </div>
              <div className="__row">
                <p className="__column __title">商品代金以外に必要な費用 ／送料、消費税等</p>
                <p className="__column __txt">
                  当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金、振込手数料はお客様のご負担となります。
                </p>
              </div>
              <div className="__row">
                <p className="__column __title">
                  返品の取扱条件／返品期限、返品時の送料負担
                  <br />
                  または解約や退会条件
                </p>
                <p className="__column __txt">
                  原則なし。
                  <br />
                  月額課金方式においては、月の途中に退会した場合も料金が発生
                  し、日割り精算等による返金を含めた返金は行われません。
                </p>
              </div>
              <div className="__row">
                <p className="__column __title">不良品の取扱条件</p>
                <p className="__column __txt">なし</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY,
      '& h3': {
        textAlign: 'center',
        fontSize: '24px',
      },
      '& .__container': {
        margin: '0 auto',
        padding: '5px 0 32px 0',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_LARGE,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          margin: '0 16px',
          '& .__regal': {
            border: '3px solid rgba(41, 38, 38, 0.2)',
            '& div:last-child': {
              borderBottom: 'none',
            },

            '& .__row': {
              display: 'flex',
              padding: 0,
              borderBottom: '3px solid rgba(41, 38, 38, 0.2)',

              '& .__column': {
                boxSizing: 'border-box',
                padding: '24px 16px',
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                '&.__title': {
                  maxWidth: '35%',
                  width: '100%',
                  fontWeight: 'bold',
                  backgroundColor: constants.COLOR_GRAY,
                  borderRight: '3px solid rgba(41, 38, 38, 0.2)',
                  color: constants.COLOR_MAIN_DARK,
                },
                '&.__txt': {
                  maxWidth: '65%',
                  width: '100%',
                  '& .__link': {
                    color: constants.COLOR_BLUE_DARK,
                    borderBottom: `1px solid ${constants.COLOR_BLUE_DARK}`,
                  },
                },
              },
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
