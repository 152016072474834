import * as React from 'react'

import { Menu } from '../../hooks/useHamburgerBoxStyle'

type Props = {
  onClick: () => void
  menus: Menu
}
type StyleProps = {}

export const MenuItem: React.FC<Props & StyleProps> = ({ onClick, menus }) => {
  return (
    <>
      <li onClick={onClick}>
        <img src={menus.imgSrc} alt={'Icon'} />
        <span>{menus.labelText}</span>
      </li>
    </>
  )
}
