import * as React from 'react'

import { Circle, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DotYellow: React.FC = () => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20">
      <Circle data-name="Ellipse 384" cx="10" cy="10" r="10" fill="#fccf00" />
    </Svg>
  )
}

export default DotYellow
