import * as React from 'react'

import { Circle, ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man2: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <Defs>
        <ClipPath>
          <Rect x="0" y="0" width="630" height="380" fill="none" />
        </ClipPath>
      </Defs>
      <G clip-path="url(#clip-path)">
        <G transform="translate(-7813 -10707)">
          <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(7813 10707)" fill="#80d6dd" />
          <G transform="translate(8925.717 10073.949)">
            <G transform="translate(-50 0)">
              <Path
                d="M-620.021,764.818h-88.028c-2.489,0-4.526,2.486-4.526,5.524V908.1c0,.055.012.106.014.16v2.663H-615.5V770.342C-615.5,767.3-617.533,764.818-620.021,764.818Z"
                fill="#545364"
              />
              <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(-674.615 750.267)" fill="#ffe4d2" />
              <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-729.506 699.117)" fill="#ffe4d2" />
              <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-633.591 699.117)" fill="#ffe4d2" />
              <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(-719.172 653.47)" fill="#ffe4d2" />
              <Path
                d="M-712.574,991.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.148.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
                fill="#2a2a2a"
              />
              <Path
                d="M-652.337,991.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.149.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
                fill="#2a2a2a"
              />
              <Path d="M-682.647,764.818l9.075,26.674h15.125l9.9-26.674Z" fill="#fff" />
              <Path
                d="M-671.1,768.888l1.1,8.274-1.1,4.675-3.575,7.425,7.975,26.4,9.075-25.849-3.3-7.724.546-13.2-4.671-3.714Z"
                fill="#b15f78"
              />
              <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(-671.097 773.068)" fill="#c07087" />
              <Path d="M-665.273,764.818l11.229,15.033,5.637-15.033Z" fill="#e5e5e4" />
              <Path d="M-664.723,764.818l-13.383,14.107-4.541-14.107Z" fill="#e5e5e4" />
              <Path
                d="M-606.681,706.063c-12.367,1.479-18.661-9.536-21.808-18.191-37.765-2.36-59.009-3.147-72.384,0-2.36,9.442-23.6,27.538-22.134,9.1,3.662-23.7,24.949-52.918,57.46-52.918S-606.681,671.814-606.681,706.063Z"
                fill="#323333"
              />
              <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-652.337 901.846)" fill="#545364" />
              <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-712.575 901.846)" fill="#545364" />
              <G>
                <Rect
                  x="0"
                  y="0"
                  width="83.15"
                  height="22.811"
                  rx="11.365"
                  transform="translate(-747.559 886.185) rotate(-73.125)"
                  fill="#ffe4d2"
                />
                <Rect
                  x="0"
                  y="0"
                  width="104.291"
                  height="25.755"
                  transform="translate(-742.582 866.082) rotate(-73.125)"
                  fill="#545364"
                />
              </G>
              <G>
                <Rect
                  x="0"
                  y="0"
                  width="22.996"
                  height="83.822"
                  rx="11.457"
                  transform="translate(-626.879 813.617) rotate(-16.875)"
                  fill="#ffe4d2"
                />
                <Rect
                  x="0"
                  y="0"
                  width="25.963"
                  height="105.134"
                  transform="translate(-640.924 773.819) rotate(-16.875)"
                  fill="#545364"
                />
              </G>
            </G>
            <G>
              <Path
                d="M-943.866,769.412l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-941.89,763.929-943.889,766.4-943.866,769.412Z"
                fill="#545364"
              />
              <Rect
                x="0"
                y="0"
                width="17.532"
                height="14.401"
                transform="translate(-903.041 749.232) rotate(-0.458)"
                fill="#ffe4d2"
              />
              <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-862.31 698.183)" fill="#ffe4d2" />
              <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-957.23 698.904)" fill="#ffe4d2" />
              <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(-946.929 653.369)" fill="#ffe4d2" />
              <Path
                d="M-846.119,990.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
                fill="#2a2a2a"
              />
              <Path
                d="M-905.719,991.205a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
                fill="#2a2a2a"
              />
              <Path d="M-911.2,763.7l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
              <Path
                d="M-894.87,763.926l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
                fill="#4866cd"
              />
              <Rect
                x="0"
                y="0"
                width="10.613"
                height="8.709"
                transform="translate(-899.433 771.771) rotate(-0.458)"
                fill="#5b75d2"
              />
              <Path d="M-911.337,763.7l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
              <Path d="M-877.453,763.44l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
              <Path
                d="M-895.282,644.052c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-953.329,671.97-927.456,644.3-895.282,644.052Z"
                fill="#522903"
              />
              <G>
                <Path
                  d="M-772.741,797.073l-48.126,28.734a10.128,10.128,0,0,1-13.889-3.5h0l-.347-.58c-2.868-4.8-2.077-12.577,2.727-15.445l48.126-28.734c4.8-2.868,11.8.256,14.668,5.059l.346.58A10.128,10.128,0,0,1-772.741,797.073Z"
                  fill="#ffe4d2"
                />
                <Path d="M-849.328,816.909-788.5,777.88l13.673,21.845-60.831,39.029Z" fill="#545364" />
              </G>
              <Rect
                x="0"
                y="0"
                width="36.461"
                height="91.715"
                transform="translate(-942.878 899.551) rotate(-0.434)"
                fill="#545364"
              />
              <Rect
                x="0"
                y="0"
                width="36.461"
                height="91.715"
                transform="translate(-883.265 899.098) rotate(-0.434)"
                fill="#545364"
              />
              <G>
                <Path
                  d="M-927.256,745.731a11.766,11.766,0,0,1,8.122,14.466l-4.842,12.986a11.765,11.765,0,0,1-14.467,8.121l-.753-.211a11.767,11.767,0,0,1-8.122-14.466l4.842-12.986a11.767,11.767,0,0,1,14.466-8.122Z"
                  fill="#ffe4d2"
                />
                <Path d="M-919.493,767.257l-18.869,75.179-26.355-7.4,18.87-75.178Z" fill="#545364" />
              </G>
              <Path d="M-848.718,765.475l28.11,49.877-22.381,11.8L-871.1,777.275Z" fill="#545364" />
            </G>
            <G>
              <Path
                d="M-943.866,769.412l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-941.89,763.929-943.889,766.4-943.866,769.412Z"
                fill="#545364"
              />
              <Rect
                x="0"
                y="0"
                width="17.532"
                height="14.401"
                transform="translate(-903.041 749.232) rotate(-0.458)"
                fill="#ffe4d2"
              />
              <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-862.31 698.183)" fill="#ffe4d2" />
              <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-957.23 698.904)" fill="#ffe4d2" />
              <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(-946.929 653.369)" fill="#ffe4d2" />
              <Path
                d="M-846.119,990.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
                fill="#2a2a2a"
              />
              <Path
                d="M-905.719,991.205a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
                fill="#2a2a2a"
              />
              <Path d="M-911.2,763.7l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
              <Path
                d="M-894.87,763.926l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
                fill="#4866cd"
              />
              <Rect
                x="0"
                y="0"
                width="10.613"
                height="8.709"
                transform="translate(-899.433 771.771) rotate(-0.458)"
                fill="#5b75d2"
              />
              <Path d="M-911.337,763.7l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
              <Path d="M-877.453,763.44l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
              <Path
                d="M-895.282,644.052c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-953.329,671.97-927.456,644.3-895.282,644.052Z"
                fill="#522903"
              />
              <G>
                <Path
                  d="M-772.741,797.073l-48.126,28.734a10.128,10.128,0,0,1-13.889-3.5h0l-.347-.58c-2.868-4.8-2.077-12.577,2.727-15.445l48.126-28.734c4.8-2.868,11.8.256,14.668,5.059l.346.58A10.128,10.128,0,0,1-772.741,797.073Z"
                  fill="#ffe4d2"
                />
                <Path d="M-849.328,816.909-788.5,777.88l13.673,21.845-60.831,39.029Z" fill="#545364" />
              </G>
              <Rect
                x="0"
                y="0"
                width="36.461"
                height="91.715"
                transform="translate(-942.878 899.551) rotate(-0.434)"
                fill="#545364"
              />
              <Rect
                x="0"
                y="0"
                width="36.461"
                height="91.715"
                transform="translate(-883.265 899.098) rotate(-0.434)"
                fill="#545364"
              />
              <G>
                <Path
                  d="M-927.256,745.731a11.766,11.766,0,0,1,8.122,14.466l-4.842,12.986a11.765,11.765,0,0,1-14.467,8.121l-.753-.211a11.767,11.767,0,0,1-8.122-14.466l4.842-12.986a11.767,11.767,0,0,1,14.466-8.122Z"
                  fill="#ffe4d2"
                />
                <Path d="M-919.493,767.257l-18.869,75.179-26.355-7.4,18.87-75.178Z" fill="#545364" />
              </G>
              <Path d="M-848.718,765.475l28.11,49.877-22.381,11.8L-871.1,777.275Z" fill="#545364" />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Man2
