// FIXME: remove disabling
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IActionItem } from '../../stores/setupaction/reducer'

export function filterAnket(
  beforeProcessing: IActionItem[],
  beforeDone: IActionItem[],
  init: IActionItem
): { processing: IActionItem[]; done: IActionItem[] } {
  const updateProcessing: any[] = beforeProcessing.filter(
    (currentData) =>
      currentData.missionValues ||
      !currentData.anketValues ||
      (currentData.anketValues && currentData.anketValues.id !== init.anketValues!.id)
  )

  let updated = false
  let updateDone = beforeDone!.map((d) => {
    if (d.id === init.anketValues!.id || (d.anketValues && d.anketValues.id === init.anketValues!.id)) {
      updated = true
      return { ...init }
    } else {
      return { ...d }
    }
  })

  if (!updated) {
    updateDone = [init, ...updateDone]
  }

  return {
    processing: updateProcessing,
    done: updateDone,
  }
}

export function filterMission(
  beforeProcessing: IActionItem[],
  beforeDone: IActionItem[],
  init: IActionItem
): { processing: IActionItem[]; done: IActionItem[] } {
  if (init.missionValues!.value! > 0) {
    const updateProcessing: IActionItem[] = beforeProcessing!.filter(
      (currentData) => currentData.valuesId !== init.valuesId
    )

    let updated = false
    let updateDone: IActionItem[] = beforeDone!.map((d) => {
      if (d.valuesId === init.valuesId) {
        updated = true
        return { ...init }
      } else {
        return { ...d }
      }
    })

    if (!updated) {
      updateDone = [init, ...updateDone]
    }
    return {
      processing: updateProcessing,
      done: updateDone,
    }
  } else {
    let updated = false
    let updateProcessing: IActionItem[] = beforeProcessing!.map((d) => {
      if (d.valuesId === init.valuesId) {
        updated = true
        return { ...init }
      } else {
        return { ...d }
      }
    })

    if (!updated) {
      updateProcessing = [init, ...updateProcessing]
    }

    const updateDone: IActionItem[] = beforeDone!.filter((currentData) => currentData.valuesId !== init.valuesId)
    return {
      processing: updateProcessing,
      done: updateDone,
    }
  }
}
/* eslint-enable @typescript-eslint/no-non-null-assertion */
