import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: 0,

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },

  hightlight: {
    color: '#8D8D8D',

    '&:before': {
      content: '"/"',
      margin: '0 8px',
      display: 'inline-block',

      [theme.breakpoints.down('md')]: {
        content: '""',
        margin: 0,
      },
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  name: string
  position: string
}

// -----------------------------
// Component
// -----------------------------
export const ProfileName = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box>
      <p className={classes.root}>
        {props.name}
        <span className={classes.hightlight}>{props.position}</span>
      </p>
    </Box>
  )
}
