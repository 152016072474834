import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { useGetConsultationEpic } from 'pages/hint/_shared/hooks/useGetConsultationEpic'
import { useNextToken } from 'pages/hint/_shared/hooks/useNextToken'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getTokenWithFetch: () => void
}

// -----------------------------
// hooks
// -----------------------------
export const useFaqNextToken = (): IProps => {
  const nextToken = useNextToken()
  const getConsultationEpic = useGetConsultationEpic()
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)

  /**
   * ページネーションによるnextTokenを制御してfetchする
   */
  const getTokenWithFetch = (): void => {
    if (pagesSelectors.pageAction === '') {
      getConsultationEpic.getConsultationListEpic({ isPublished: 1, nextToken: nextToken.setCurrentLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'NEXT') {
      getConsultationEpic.getConsultationListEpic({ isPublished: 1, nextToken: nextToken.setNextLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'PREVIOUS') {
      getConsultationEpic.getConsultationListEpic({ isPublished: 1, nextToken: nextToken.setPrevLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'BACK') {
      getConsultationEpic.getConsultationListEpic({ isPublished: 1, nextToken: nextToken.setBackLen() || '' })
      return
    }
  }

  return {
    getTokenWithFetch,
  }
}
