import * as React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ja from 'date-fns/locale/ja'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'

type Props = {
  name: string
  value: string | Date
  onChange: () => void
  readOnly?: boolean | undefined
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  readOnly?: boolean | undefined
}

export const FormDate: React.FC<Props & StyleProps & StyleSubProps> = ({
  name,
  value,
  onChange,
  readOnly = false,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles: ownStyles, readOnly: readOnly })
  const defaultValue = value || null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
      <KeyboardDatePicker
        name={name}
        value={defaultValue}
        onChange={onChange}
        disableToolbar
        readOnly={readOnly}
        variant="inline"
        format="yyyy年M月d日 EEEE"
        margin="normal"
        id="date-picker-inline"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        className={classes.main}
        autoOk={true}
        keyboardIcon={<img src={CalendarSvg} alt="calendar" style={{ padding: 0 }} />}
        PopoverProps={{ classes: { paper: classes.popover } }}
        disablePast={true}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: ({ ownStyles, readOnly }: StyleProps & StyleSubProps) => ({
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: readOnly ? constants.COLOR_TEAMBUILDING_NEUTRAL_100 : '',
      ...ownStyles,

      '& .MuiFormLabel-root': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },

      '& .MuiInputBase-root': {
        paddingRight: 0,
        borderRadius: 8,
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        height: 42,
        [theme.breakpoints.down('xs')]: {
          height: 56,
        },
      },
      '& .MuiInputBase-input': {
        color: constants.COLOR_TEAMBUILDING_TEXT,
        fontSize: 14,
        fontWeight: 300,
        padding: '8px 8px 8px 16px',
        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0 !important`,
      },
      '& .MuiButtonBase-root': {
        color: `${constants.COLOR_TEAMBUILDING_PRIMARY} !important`,
        marginRight: 8,
        padding: 8,
        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
          padding: 16,
        },
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    }),
    popover: {
      borderRadius: 8,
      '& .MuiPickersDay-current': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
        color: constants.TEXT_WHITE,
      },
      '& .MuiPickersBasePicker-pickerView': {
        [theme.breakpoints.down('xs')]: {
          minWidth: 288,
        },
      },
    },
  }),
  { name: 'FormDate' }
)
