import { API } from '@aws-amplify/api'

import * as mutations from '../../graphql/mutations'

// -----------------------------
// Params
// -----------------------------
export type ICreateHintParams = {
  id: string
  title: string
  imageUrl?: string
  content?: string
  contentPreview?: string
  category?: string | null
  isPublished: number
}

// -----------------------------
// Result
// -----------------------------
export type ICreateHintResult = {
  id: string
  title: string
  imageUrl?: string
  content?: string
  contentPreview?: string
  category?: string | null
  isPublished: number
}

export const createHint = async (params: ICreateHintResult) => {
  try {
    const res: any = await API.graphql({
      query: mutations.createHint,
      variables: {
        input: {
          id: params.id,
          title: params.title,
          imageUrl: params.imageUrl,
          content: params.content,
          contentPreview: params.contentPreview,
          category: params.category,
          isPublished: params.isPublished,
        },
      },
    })
    return res
  } catch (e) {
    throw e
  }
}
