import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import {
  MutationSendTeamKarteEmailArgs,
  MutationTeamKarteMemberStoreArgs,
  QueryGetTeamKarteArgs,
  QueryGetTeamKarteListArgs,
  TeamKarte,
  TeamKarteConnection,
} from 'utils/generated'

export const mutationSendTeamKarteEmail = async (args: MutationSendTeamKarteEmailArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation sendTeamKarteEmail($teamId: ID!, $end: String!) {
        sendTeamKarteEmail(teamId: $teamId, end: $end)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ sendTeamKarteEmail: boolean }>
  return response.data?.sendTeamKarteEmail || false
}

export const mutationTeamKarteMemberStore = async (
  args: MutationTeamKarteMemberStoreArgs
): Promise<TeamKarte | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation teamKarteMemberStore($input: TeamKarteMemberInput!) {
        teamKarteMemberStore(input: $input) {
          id
          data
          userId
          teamId
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ teamKarteMemberStore: TeamKarte }>
  return response.data?.teamKarteMemberStore
}

export const queryGetTeamKarte = async (args: QueryGetTeamKarteArgs): Promise<TeamKarte | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamKarte($id: ID!) {
        getTeamKarte(id: $id) {
          id
          data
          userId
          teamId
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamKarte: TeamKarte }>
  return response.data?.getTeamKarte
}

export const queryGetTeamKarteList = async (
  args: QueryGetTeamKarteListArgs
): Promise<TeamKarteConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamKarteList($filter: TeamKarteFilter, $first: Int, $after: String) {
        getTeamKarteList(filter: $filter, first: $first, after: $after) {
          items {
            id
            data
            userId
            teamId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamKarteList: TeamKarteConnection }>
  return response.data?.getTeamKarteList
}
