import { actionCreatorFactory } from 'typescript-fsa'
const actionCreator = actionCreatorFactory('Pages/KarteLeaderQuestionnaire')

export default {
  store: actionCreator.async<
    { id: string; name: string; leaderNumber: string; leaderData: string; successCallback: () => void },
    any
  >('STORE'), // TODO: successCallback change to router
  resetError: actionCreator<{}>('RESET_ERROR'),
  empty: actionCreator<{}>('empty'),
}
