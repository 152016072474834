import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

// import * as constants from 'assets/constants'

type Props = {
  title: string
  caption?: string
  ownRootStyles?: React.CSSProperties
  addOwnTitleStyles?: React.CSSProperties
  ownCaptionStyles?: React.CSSProperties
}

export const TopSectionTitle: React.FC<Props> = (props: Props) => {
  const classes = useStyles({})
  return (
    <div className={classes.root} style={props.ownRootStyles}>
      <div className={classes.title} style={props.addOwnTitleStyles}>
        {props.title}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    margin: '0 13px 0 24px',
  },
  caption: {
    fontSize: 14,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up(constTop.breakPointsSP)]: {
    margin: '0 13px 0 0',
  },
}))
