import React from 'react'

import { Fab } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import * as constants from '../../../../assets/constants'

export const ScrollTop: React.FC = () => {
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <div onClick={handleClick} role="presentation" className={classes.root}>
      <Fab size="large" aria-label="scroll back to top" className={classes.fab}>
        <KeyboardArrowUpIcon className={classes.icon} />
      </Fab>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    fab: {
      backgroundColor: constants.COLOR_GRAY_DARK,
      '&:hover': {
        backgroundColor: '#000000',
      },
    },
    icon: {
      fontSize: 46,
      color: constants.TEXT_WHITE,
    },
  })
)
