import React from 'react'

import { FeatureContainer } from 'pages/features/components/FeatureContainer'
import { FEATURES } from 'pages/features/features.data'

export const KickoffPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <FeatureContainer feature={FEATURES[1]} />
}
