import { I18n } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'

I18n.setLanguage('ja')

export interface ISignin {
  email: string
  password: string
}

export const signin = async (user: ISignin) => {
  const res = await Auth.signIn(user.email, user.password)

  return { id: res.attributes.sub, email: res.attributes.email }
}
