import * as React from 'react'
import { useState, useContext } from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'

import {
  GrayBackGround,
  ButtonForGhost,
  BackResponsiveCard,
  LeadText,
  LoadingCircularProgress,
  Flexbox,
  Font,
  UsefulCard,
  SpanBold,
} from '../components/atoms'
import { GhostThumbnail, CircularProgressCount, ButtonContainer } from '../components/modules'
import { WidthControlContainer } from '../components/modules/WidthControlContainer'
import { GhostReported } from '../components/organisms'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { PageManagerContext } from '../contexts/pageManger'
import { ResearchContext } from '../contexts/research'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

export const Result: React.FC = () => {
  const { currentHistory } = useContext(HistoryContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { researches1, researches2, loading } = useContext(ResearchContext)
  const { runSubmitLikes } = useContext(CommandContext)
  const { reportCategory } = useContext(PageManagerContext)
  const pBForFixedUIs = usePBForFixedUIs({})

  const [likes, setLikes] = useState<{ [k in 1 | 2]: string[] }>({ 1: [], 2: [] })
  const done = Object.values(likes).find((l) => l.length)

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const onSubmit = async () => {
    if (likes[1].length || likes[2].length) {
      const ids = [...likes[1], ...likes[2]]
      runSubmitLikes(ids, teamMember)
    }
  }

  const selectLike = (id: string, num: 1 | 2) => {
    let update = likes[num].slice()
    if (update.includes(id)) {
      const idx = update.findIndex((o) => o === id)
      update = [...update.slice(0, idx), ...update.slice(idx + 1)]
    } else {
      update.push(id)
    }
    setLikes({ ...likes, [num]: update })
  }

  if (!teamMember || !(currentHistory && currentHistory.ghostId))
    return <LoadingIndicator snapshot={{ vars: currentHistory }} />
  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard ownStyles={{ paddingTop: isSmDown ? 24 : 40, paddingBottom: pBForFixedUIs }}>
          <WidthControlContainer>
            <Flexbox ownStyles={{ justifyContent: 'flex-start' }}>
              <CircularProgressCount numerator={reportCategory(teamMember) === 'reason' ? 2 : 5} denominator={6} />
              <Font
                fontSize={16}
                bold
                color={constGhost.COLOR_MAIN_NEW}
                ownStyles={{
                  letterSpacing: 0.8,
                  marginLeft: 16,
                }}
              >
                {reportCategory(teamMember) === 'reason'
                  ? 'メンバーが考えた原因の確認'
                  : 'メンバーが考えた対処方法の確認'}
              </Font>
            </Flexbox>
            <LeadText>
              {reportCategory(teamMember) === 'reason' ? (
                <>
                  メンバーが考えた原因を確認し、共感するもの<SpanBold>最低1つにチェック</SpanBold>をしてください。
                </>
              ) : (
                <>
                  メンバーが考えた対処方法を確認し、共感するもの<SpanBold>最低1つにチェック</SpanBold>をしてください。
                </>
              )}
            </LeadText>

            <GhostThumbnail
              invisibleDetail
              ownStyles={{ marginTop: 24, padding: isMdUp ? 40 : '24px 16px 0', width: '100%' }}
              ghostId={currentHistory.ghostId}
            />

            <UsefulCard
              backgroundColor="white"
              borderRadius={8}
              ownStyles={{ marginTop: 24, marginBottom: 86 }}
              noShadow
            >
              <Font
                fontSize={14}
                textAlign={'center'}
                ownStyles={{
                  padding: isMdUp ? '40px 0 8px' : '24px 16px 8px',
                }}
              >
                {reportCategory(teamMember) === 'reason'
                  ? '「この原因に共感」は、最低１つ選択してください'
                  : '「この対処方法に共感」は、最低一つ選択してください'}
              </Font>

              {!loading ? (
                <>
                  {reportCategory(teamMember) === 'reason' ? (
                    <GhostReported
                      researches={researches1}
                      selected={likes[1]}
                      selectLike={(id: string) => selectLike(id, 1)}
                      thumbUpIconBehavior={'clickable'}
                      ownStyles={{ padding: isMdUp ? '16px 40px 40px' : '16px 16px 24px 16px' }}
                      balloonPadding={16}
                      isLike
                      isReason
                    />
                  ) : (
                    <GhostReported
                      researches={researches2}
                      selected={likes[2]}
                      selectLike={(id: string) => selectLike(id, 2)}
                      thumbUpIconBehavior={'clickable'}
                      ownStyles={{ padding: isMdUp ? '16px 40px 40px' : '16px 16px 24px 16px' }}
                      balloonPadding={16}
                      isLike
                    />
                  )}
                </>
              ) : (
                <LoadingCircularProgress ownStyles={{ padding: '24px 0' }} />
              )}
            </UsefulCard>

            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="result"
                  buttonBodyColor={done ? 'green' : 'gray'}
                  bodyText={'集計へ'}
                  onClick={
                    done
                      ? onSubmit
                      : () =>
                          alert(
                            reportCategory(teamMember) === 'reason'
                              ? '最低１つ「この原因に共感」にチェックをしてください'
                              : '最低１つ「この対処方法に共感」にチェックをしてください'
                          )
                  }
                  isResizeBasic
                />,
              ]}
            />
          </WidthControlContainer>
        </BackResponsiveCard>
      </GrayBackGround>
    </>
  )
}
