import * as React from 'react'

import { GhostFinding } from 'pages/obakesagashi/components/GhostFinding'
import { GhostGallery } from 'pages/obakesagashi/components/GhostGallery'
import { GhostGuide } from 'pages/obakesagashi/components/GhostGuide'
import { GhostUsage } from 'pages/obakesagashi/components/GhostUsage'
import { Hero } from 'pages/obakesagashi/components/Hero'
import { ResultSearchModal } from 'pages/obakesagashi/components/ResultSearchModal'
import { SectionLine } from 'pages/obakesagashi/components/SectionLine'
import { ToSearch } from 'pages/obakesagashi/components/ToSearch'

import { Layout } from '../components/Layout'

export const Top: React.FC = () => {
  return (
    <Layout>
      <Hero />
      <SectionLine backImage="typeTwo" />
      <ToSearch />
      <GhostGallery />
      <GhostGuide />
      <GhostUsage />
      <GhostFinding />
      <ResultSearchModal />
    </Layout>
  )
}
