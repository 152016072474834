import * as React from 'react'

import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'

import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { useTeams, useTeam } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'

import GreenPlusSvg from '../assets/greenPlus.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


interface IOwnProps {
  onClick: () => void
  teamId?: string
  members?: { value: string; label: string }[]
  selectedMemberId?: string
  onChangeMember?: (value: string) => void
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, teamId, members, selectedMemberId, onChangeMember, onClick }) => {
  const { teams } = useTeams()
  const { team } = useTeam(teamId)
  const history = useOnbHistory()

  const teamLabel = team ? `${team.title}(${team.memberCount || 0})` : ''

  return (
    <div className={classes.container}>
      <div className={classes.dropDownForm}>
        <Dropdown
          items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
          value={teamId}
          handleOnClick={(teamId) => history.push(OnbPages.Timeline,{teamId})}
          keyPrefix={'timeline-three-person'}
          teamLabel={teamLabel}
        />
      </div>
      <div className={classes.dropDownIcon}>
        <Dropdown
          items={[{ value: '', label: ' 全員' }, ...(members || [])]}
          value={selectedMemberId}
          handleOnClick={(value) => {
            if (onChangeMember) {
              onChangeMember(value)
            }
          }}
          keyPrefix={'timeline-two-person'}
          iconList="change"
        />
      </div>
      <div className={classes.newPostIcon} onClick={() => onClick()}>
        <img src={GreenPlusSvg} alt={'newPost'} />
      </div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 16,
      paddingRight: 16,
    },
    dropDownForm: {
      flex: 1,
    },
    dropDownIcon: {
      marginLeft: 16,
    },
    newPostIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 42,
      height: 42,
      margin: '0 0 0 16px',
      borderRadius: 21,
      backgroundColor: constants.COLOR_WHITE,
      cursor: 'pointer',
    },
  })

export default withStyles(useStyles)(Index)
