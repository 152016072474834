import React from 'react'

import { makeStyles } from '@material-ui/core'

import * as constGhost from 'pages/ghost/assets/constGhost'
import likeOn from 'pages/ghost/assets/svg/likeOn.svg'
import { Font } from 'pages/ghost/components/atoms'

import WaitMemberImg from '../../../assets/svg/waitMember.svg'

export const SelectLeader = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div style={{ maxWidth: 180, maxHeight: 137, margin: '0 auto 39px' }}>
        <img style={{ width: '100%' }} src={WaitMemberImg} alt="waitMember" />
      </div>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const Start = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const SelectGhost = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.switchable}>{split[0]}</div>
      {split.length === 3 ? (
        <div className={classes.switchableTitle}>{split[1]}</div>
      ) : (
        <div style={{ fontSize: 14 }}>{split[1]}</div>
      )}
      <div style={{ fontSize: 14 }}>{split[2]}</div>
    </div>
  )
}

export const Report = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const ReportResult = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.switchable}>{split[0]}</div>
      {split.length === 3 ? (
        <div className={classes.switchableTitle}>{split[1]}</div>
      ) : (
        <div style={{ fontSize: 14 }}>{split[1]}</div>
      )}
      <div style={{ fontSize: 14 }}>{split[2]}</div>
    </div>
  )
}
export const Research = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const ResearchResult = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.switchable}>{split[0]}</div>
      {split.length === 3 ? (
        <div className={classes.switchableTitle}>{split[1]}</div>
      ) : (
        <div style={{ fontSize: 14 }}>{split[1]}</div>
      )}
      <div style={{ fontSize: 14 }}>{split[2]}</div>
    </div>
  )
}

export const Complete2Result = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const Like = ({ message }: { message: string }) => {
  const classes = useStyles()
  return (
    <div className={classes.innerContainer}>
      <img src={likeOn} alt="like" style={{ position: 'relative', top: 6, right: 8 }} />
      <span>{message}</span>
    </div>
  )
}

export const LikeResult = ({ message }: { message: string }) => {
  const splitMsg = message.split('\n')
  return (
    <>
      <div style={{ marginTop: 40 }}>
        <Font
          color={constGhost.COLOR_MAIN_NEW}
          fontSize={22}
          bold
          ownStyles={{ marginBottom: 24, textAlign: 'center' }}
        >
          集計中
        </Font>
      </div>
      {splitMsg[0]}
      <img src={likeOn} alt="like" style={{ position: 'relative', top: 6, right: 0, padding: '0 7px' }} />
      {splitMsg[1]}
      <br />
      {splitMsg[2]}
    </>
  )
}

export const Busy = ({ message }: { message: string }) => {
  const classes = useStyles()
  const split = message.split('\n')
  return (
    <div className={classes.innerContainer}>
      <div className={classes.title}>{split[0]}</div>
      <div style={{ fontSize: 14 }}>{split[1]}</div>
    </div>
  )
}

export const Default = ({ message }: { message: string }) => {
  const classes = useStyles()
  return <div className={classes.message}>{message}</div>
}

const useStyles = makeStyles((theme) => ({
  message: {
    whiteSpace: 'pre-line',
    lineHeight: 1.8,
  },
  innerContainer: {
    boxSizing: 'border-box',
    paddingTop: 97,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    color: constGhost.COLOR_MAIN_NEW,
    marginBottom: 23,
  },
  switchable: {
    fontSize: 22,
    fontWeight: 'bold',
    color: constGhost.COLOR_MAIN_NEW,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 23,
    },
  },
  switchableTitle: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 22,
      marginBottom: 23,
    },
  },
}))
