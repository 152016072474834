import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetRadar } from './graphql'

const getRadar = async (week: number, setupStartedAt?: string) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetRadar, {
      week,
      setupStartedAt,
    })
  )

  const { getRadar } = response.data

  return { ...getRadar }
}

export default getRadar
