import React from 'react'
import { useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'

import { APP_METAS, DEFAULT_META } from 'assets/metas'

export const AppHelmet: React.FC = () => {
  const location = useLocation()
  const meta = APP_METAS.find((meta) => location.pathname.match(meta.route))

  return (
    <Helmet>
      <title>{meta?.title ?? DEFAULT_META.title}</title>
      <meta name="description" content={meta?.description ?? DEFAULT_META.description} />
    </Helmet>
  )
}
