import { MutableRefObject, useLayoutEffect, useRef } from 'react'

import Clipboard from 'clipboard'

// -----------------------------
// interface
// -----------------------------
export type IuseClipboardText = {
  textRef: MutableRefObject<null>
}

// -----------------------------
// hooks
// -----------------------------
export const useClipboardText = (copyText: string): IuseClipboardText => {
  const textRef = useRef(null)

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cb = new Clipboard(textRef!.current!, {
      text: () => copyText,
    })

    return () => cb.destroy()
  }, [copyText])

  return { textRef }
}
