import * as React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Breadcrumb } from 'components/Breadcrumb'
import { TitleRenewal } from 'components/TitleRenewal'
import { TopRenewalWrapper } from 'components/TopRenewalWrapper'
import { ArrowLinkButton } from 'pages/features/components/ArrowLinkButton'
import { TAB_ITEMS } from 'pages/features/features.data'
import { connector, ContainerProps } from 'pages/plan/index.container'

import { constants } from 'assets'

type Props = ContainerProps & RouteComponentProps<{}>

const Plan: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isXSDown = useMediaQuery(theme.breakpoints.down('xs'))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TopRenewalWrapper>
      <div className={classes.root}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="料金プラン" />
        <TitleRenewal
          category="Price"
          title="料金プラン"
          description={
            <>
              大企業の会社全体から小規模なチームまで規模や用途に応じたご対応が可能です。
              {isXSDown ? null : <br />}
              まずはご相談下さい。
            </>
          }
        />
        <div className={classes.planContainer}>
          {TAB_ITEMS['team'].plans.map((plan) => {
            return (
              <div key={plan.title} className={classes.planBox}>
                <div>
                  <div className={classes.planTitle}>{plan.title}</div>
                  <p className={classes.planDescription}>{plan.description}</p>
                  <div className={classes.contactBox}>
                    詳細については、
                    <Link to={constants.PAGE_CONTACT} className={classes.contactLink}>
                      お問い合わせ
                    </Link>
                    ください
                  </div>
                </div>
                <div>
                  <div className={classes.planToolHeader}>使用可能機能</div>
                  <div className={classes.planToolContainer}>
                    {plan.tools.map((tool) => {
                      return (
                        <ArrowLinkButton
                          key={tool.name}
                          path={tool.path}
                          text={<span className={classes.planToolName}>{tool.name}</span>}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </TopRenewalWrapper>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingBottom: 140,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 100,
      },
    },
    planContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 22,
      marginTop: 83,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    planBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      boxShadow: `0 0 8px ${constants.COLOR_GRAY_LIGHT}`,
      borderRadius: 10,
      padding: 40,
    },
    planTitle: {
      marginBottom: 28,
      fontSize: 24,
      fontWeight: 'bold',
    },
    planDescription: {
      margin: '0 0 27px',
      lineHeight: 2,
      whiteSpace: 'pre-wrap',
    },
    contactBox: {
      marginBottom: 48,
    },
    contactLink: {
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    planToolHeader: {
      marginBottom: 18,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    planToolContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 18,
      columnGap: 40,
    },
    planToolName: {
      fontWeight: 'bold',
    },
  }),
  { name: 'Plan' }
)

export default connector(Plan)
