import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { TeamBuildingReview } from 'utils/generated'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { ReviewTimer } from './generic/ReviewTimer'


type Props = {
  title: string
  subtitle: string
  teamBuildingReview: TeamBuildingReview | undefined
}

export const Step1Presen: React.FC<Props> = ({ title, subtitle, teamBuildingReview }) => {
  const classes = useStyles()
  const { iceBreakNumber, iceBreakMinute, teamMembers, setIsPresenEnd } = React.useContext(
    TeamBuildingReviewHooksContext
  )

  const from = teamBuildingReview?.timeStep1Presen
  const length = React.useMemo(() => teamBuildingReview?.iceBreakUserIds?.length ?? 0, [teamBuildingReview])
  const currentUser = React.useMemo(() => {
    if (teamBuildingReview && teamBuildingReview.iceBreakUserIds) {
      const currentId = teamBuildingReview.iceBreakUserIds[length - 1]
      return teamMembers.find((member) => member.userId === currentId)
    }
    return undefined
  }, [length, teamBuildingReview, teamMembers])

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection title={`${length}/${iceBreakNumber}人目`}>
        <Content>
          {currentUser && (
            <div className={classes.flex}>
              <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
              <span className={classes.userName}>{currentUser.fullName}さん</span>
            </div>
          )}
        </Content>
        <ReviewTimer from={from} time={iceBreakMinute * 60} container setIsPresenEnd={setIsPresenEnd} />
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    '@media (max-width: 632px)': {
      justifyContent: 'flex-start',
    },
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))
