import { useDispatch } from 'react-redux'

import actions from '../stores/actions'
import {
  getUser,
  getHint,
  getHintCommentList,
  getHintListProfile,
  getHintListSearch,
  getHintListProfileUnpublished,
  getHintList,
} from '../stores/apis'
import { IHintListParams } from '../stores/apis/getHintList'
import { IHintListProfileParams } from '../stores/apis/getHintListProfile'
import { IHintListSearchParams } from '../stores/apis/getHintListSearch'

import { useNextToken } from './useNextToken'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getHintEpic: (params: string) => Promise<void>
  getHintListEpic: (params: IHintListParams) => Promise<void>
  getProfileHintListEpic: (params: IHintListProfileParams) => Promise<void>
  getProfileHintListUnpublishedEpic: (params: IHintListProfileParams) => Promise<void>
  getProfileEpic: (params: string) => Promise<void>
  getHintListSearchListEpic: (params: IHintListSearchParams) => Promise<void>
}

// -----------------------------
// hooks
// -----------------------------
export const useGetHintEpic = (): IProps => {
  const dispatch = useDispatch()
  const nextToken = useNextToken()

  /**
   * HindIDをもとにTipsの個別記事とユーザー情報を取得する
   */
  const getHintEpic = async (hintId: string): Promise<void> => {
    await getHint({ id: hintId })
      .then((res) => {
        dispatch(actions.getHint.done({ params: { id: hintId }, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getHint.failed({ params: { id: hintId }, error: e }))
      })

    await getHintCommentList({ id: hintId })
      .then((res) => {
        dispatch(actions.getHintCommentList.done({ params: { id: hintId }, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getHintCommentList.failed({ params: { id: hintId }, error: e }))
      })
  }

  /**
   * Tips/Hintの一覧を取得する
   */
  const getHintListEpic = async (params: IHintListParams): Promise<void> => {
    await getHintList(params)
      .then((res) => {
        dispatch(actions.getHintList.done({ params: params, result: res }))
        nextToken.onNextToken(res.nextToken)
      })
      .catch((e) => {
        dispatch(actions.getHintList.failed({ params: params, error: e }))
      })
  }

  /**
   * プロフィールIDに紐付いたisPublished:1のHint記事を取得する
   */
  const getProfileHintListEpic = async (params: IHintListProfileParams): Promise<void> => {
    await getHintListProfile(params)
      .then((res) => {
        dispatch(actions.getHintListProfile.done({ params: params, result: res }))
        nextToken.onNextToken(res.nextToken)
      })
      .catch((e) => {
        dispatch(actions.getHintListProfile.failed({ params: params, error: e }))
      })
  }

  /**
   * プロフィールIDに紐付いたisPublished:0のHint記事を取得する
   */
  const getProfileHintListUnpublishedEpic = async (params: IHintListProfileParams): Promise<void> => {
    await getHintListProfileUnpublished(params)
      .then((res) => {
        dispatch(actions.getHintListProfileUnpublished.done({ params: params, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getHintListProfileUnpublished.failed({ params: params, error: e }))
      })
  }

  /**
   * プロフィールを取得する
   */
  const getProfileEpic = async (userId: string): Promise<void> => {
    await getUser({ id: userId })
      .then((res) => {
        dispatch(actions.getUser.done({ params: { id: userId }, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getUser.failed({ params: { id: userId }, error: e }))
      })
  }

  /**
   * サーチカテゴリーでHintを取得する
   */
  const getHintListSearchListEpic = async (params: IHintListSearchParams): Promise<void> => {
    await getHintListSearch(params)
      .then((res) => {
        dispatch(actions.getHintListSearch.done({ params: params, result: res }))
        nextToken.onNextToken(res.nextToken)
      })
      .catch((e) => {
        dispatch(actions.getHintListSearch.failed({ params: params, error: e }))
      })
  }

  return {
    getHintEpic,
    getHintListEpic,
    getProfileEpic,
    getProfileHintListEpic,
    getProfileHintListUnpublishedEpic,
    getHintListSearchListEpic,
  }
}
