import { useState } from 'react'

// -----------------------------
// interface
// -----------------------------
export type IuseSearchPanel = {
  onOpen: () => void
  onClose: () => void
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useSearchPanel = (): IuseSearchPanel => {
  const [isOpen, setOpen] = useState<boolean>(false)

  /**
   * パネルを開く
   */
  const onOpen = (): void => {
    setOpen(true)
  }

  /**
   * パネルを閉じる
   */
  const onClose = (): void => {
    setOpen(false)
  }

  /**
   * 現在のパネルの開閉ステートを返す
   */
  const getOpen = (): boolean => {
    return isOpen
  }

  return {
    onOpen,
    onClose,
    getOpen,
  }
}
