import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'

// -----------------------------
// Params
// -----------------------------
export type IConsultationParams = {
  id: string
}

// -----------------------------
// Result
// -----------------------------
export type IConsultationResult = {
  category: string
  commentCount: number
  content: string
  contentPreview: string
  id: string
  isPublished: number
  title: string
  createdAt: string
  updatedAt: string
  user: {
    id: string
    firstName: string
    lastName: string
    nickname: string
    imageUrl: string
    shortDescription: string
    description: string
  }
  username: string
}

export const getConsultation = async (params: IConsultationParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getConsultation,
      variables: { id: params.id },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return res?.data?.getConsultation
  } catch (e) {
    throw e
  }
}
