import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

type Props = {
  backImage: 'typeOne' | 'typeTwo'
  isFadeIn?: boolean
} & React.ComponentProps<'div'>

export const SectionLine: React.FC<Props> = ({ className, backImage, isFadeIn }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.contents}>
        <div className={classes.content}>
          <div className={classNames(classes.sectionLine, className, backImage, { isFadeIn })}></div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: 'transparent',
      marginBottom: -1,
    },
    contents: {
      zIndex: 100,
      position: 'relative',
    },
    content: {
      position: 'absolute',
      width: '100%',
      margin: '0 auto',
      padding: '0 20px',
      top: -87,
      left: 0,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        top: -52,
      },
      [theme.breakpoints.down('xs')]: {
        top: -48,
      },
    },
    sectionLine: {
      width: '100%',
      height: 115,
      '&.typeOne, &.typeTwo': {
        [theme.breakpoints.down('sm')]: {
          height: 70,
        },
        [theme.breakpoints.down('xs')]: {
          height: 76,
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-ghost-peeping-sp.png)`,
        },
      },
      '&.typeOne': {
        background: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-ghost-peeping-solo.png) no-repeat bottom / contain`,
      },
      '&.typeTwo': {
        background: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-ghost-peeping.png) no-repeat bottom / contain`,
      },
      '&.isFadeIn': {
        animation: `$fadeIn 500ms forwards`,
        '@global': {
          '@keyframes fadeIn': {
            from: {
              opacity: '0',
            },
            to: {
              opacity: '1',
            },
          },
        },
      },
    },
  }),
  { name: 'SectionLine' }
)
