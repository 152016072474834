import * as React from 'react'

import { Tooltip, Button } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constants from '../../assets/constGhost'

export const TooltipForGhost: React.FC = () => {
  //仮置き
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const toggle = () => {
    setOpen(!open)
  }
  const classes = useStyles()
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const arrowplace = isBreakpointsUp ? 'bottom' : 'bottom-end'

  return (
    <div className={classes.tooltip}>
      <Tooltip
        open={open}
        placement={arrowplace}
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            modifiers: {
              offset: {
                offset: isBreakpointsUp ? '-28px, 0' : '30px 0',
              },
            },
          },
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        arrow
        title={
          <div>
            <div className={classes.title}>ファシリテーターとは？</div>
            <div>ファシリテーターの主な仕事は、以下の通りです。</div>
            <div>
              <div>・オバケ対話の司会進行</div>
              <div>・タイムマネジメント</div>
              チームメンバーであれば誰でも進行を行うことができます。
            </div>
          </div>
        }
      >
        <Button onClick={toggle}>
          <img src={process.env.PUBLIC_URL + '/assets/ghost/tooltip.svg'} alt={'ghost'} />
        </Button>
      </Tooltip>
    </div>
  )
}

const useStyles = makeStyles<Theme>(
  (theme: Theme) => ({
    tooltip: {
      '& .MuiTooltip-tooltip': {
        maxWidth: 400,
        padding: 16,
        borderRadius: 10,
        background: constants.COLOR_TOOLTIP_CONTENT,
        boxShadow: `0 0 10px ${constants.COLOR_BORDERLIGHTGRAY}`,
        fontSize: 12,
        lineHeight: 1.5,
        color: constants.COLOR_BLACKFORTEXT,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 340,
        },
      },
      '& .MuiButton-root': {
        marginBottom: 3,
        minWidth: 1,
      },
      '& .MuiButton-text': {
        padding: '0 8px 0 8px',
      },
      '& .MuiTooltip-arrow': {
        color: constants.COLOR_TOOLTIP_CONTENT,
      },
      '& .MuiButton-root:hover': {
        backgroundColor: constants.COLOR_LIGHTGRAY2,
      },
    },
    title: {
      paddingBottom: 12,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
    },
  }),
  { name: 'TooltipForGhost' }
)
