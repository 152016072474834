import * as React from 'react'

import { Box } from '@material-ui/core'

import AlertDialog from 'components/AlertDialog'
import Button from 'components/Button'
import { ICreateConsultationCommentParams } from 'pages/hint/_shared/stores/apis/createConsultationComment'
import { ICreateHintCommentParams } from 'pages/hint/_shared/stores/apis/createHintComment'

import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  open: boolean
  onClose: () => void
  presenter?: any
  params: ICreateHintCommentParams | ICreateConsultationCommentParams
}

// -----------------------------
// Component
// -----------------------------
export const DialogComment = (props: IProps) => {
  return (
    <AlertDialog
      open={props.open}
      failure={true}
      onClose={props.onClose}
      body={<p>投稿したコメントは削除や編集ができません。投稿しますか ？</p>}
      footer={
        <Box display="flex">
          <Box mr="8px" width="100%">
            <Button
              bgColor={constants.COLOR_BLACK}
              textColor={constants.TEXT_WHITE}
              fullWidth={true}
              onClick={props.presenter.onDoNotPublish}
              body={<div>いいえ</div>}
            />
          </Box>
          <Box ml="8px" width="100%">
            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.TEXT_BLACK}
              fullWidth={true}
              onClick={() => props.presenter.onDoPublish(props.params)}
              body={<div>はい</div>}
            />
          </Box>
        </Box>
      }
    />
  )
}
