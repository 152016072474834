import React from 'react'

import { TeamBuilding, TeamBuildingResetConnection, UpdateTeamBuildingInput } from 'utils/generated'

import { mutationUpdateTeamBuilding, queryGetTeamBuilding, mutationResetTeamBuilding } from './graphql'

export const useTeamBuilding = (teamId?: string) => {
  const [teamBuilding, setTeamBuilding] = React.useState<TeamBuilding | undefined>(undefined)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()

  const refresh = async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuilding({ id: teamId })
      setTeamBuilding(response)
    } catch (e: any) {
      setError(e.errors)
      setTeamBuilding(undefined)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { teamBuilding, loading, loaded, error, refresh } as const
}

export const useManageTeamBuilding = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const updateTeamBuilding = React.useCallback(async (input: UpdateTeamBuildingInput) => {
    setLoading(true)
    try {
      const response = await mutationUpdateTeamBuilding({ input })
      setLoading(false)
      return response
    } catch (e: any) {
      setErrors(e.errors)
      setLoading(false)
    }
  }, [])

  return {
    updateTeamBuilding,
    errors,
    loading,
  } as const
}

export const useResetTeamBuilding = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [resetResult, setResetResult] = React.useState<TeamBuildingResetConnection | undefined>()
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const resetTeamBuilding = React.useCallback(async (teamId: string) => {
    setIsLoading(true)
    try {
      const response = await mutationResetTeamBuilding({ id: teamId })
      setResetResult(response)
      return response
    } catch (e: any) {
      setErrors(e.errors)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    resetTeamBuilding,
    resetResult,
    errors,
    isLoading,
  } as const
}
