import { API, graphqlOperation } from '@aws-amplify/api'

import { MutationUpdateAccount } from './graphql'

export type UpdateAccountInput = {
  q1: string
  q2: string
  q3: string
  q4: string
  q5: string
  anketDate: string
}

export const createAnket = async (params: UpdateAccountInput) => {
  try {
    const response: any = await API.graphql(graphqlOperation(MutationUpdateAccount, { input: params }))
    const updateAccount = response.data.updateAccount as UpdateAccountInput
    if (updateAccount) {
      return true
    }
  } catch (e) {
    console.log('error', e)
  }
}
