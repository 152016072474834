import React from 'react'

import { TeamBuildingAction } from 'utils/generated'

import { queryGetTeamBuildingActions } from './graphql'

export const useTeamBuildingActions = (teamId?: string) => {
  const limit = 100
  const [teamBuildingActions, setTeamBuildingActions] = React.useState<TeamBuildingAction[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActions({ teamId, limit })

      if (response) {
        setTeamBuildingActions(response.items)
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  return { teamBuildingActions, nextToken, loading, error, refresh } as const
}
