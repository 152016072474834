import React from 'react'

import { Master, MasterType, TableConst, TeamBuildingAction, TeamBuildingMember } from 'utils/generated'

import { useTeamBuildingMember, useTeamBuildingMembers, useTeamBuildingActions } from '../hooks/'
import { queryGetMasters } from '../hooks/graphql'

interface Props {
  teamBuildingMembers: TeamBuildingMember[]
  refreshTeamBuildingMembers: () => Promise<void>

  teamBuildingActions: TeamBuildingAction[]
  refreshTeamBuildingActions: () => Promise<void>

  currentTeamBuildingMember?: TeamBuildingMember
  refreshTeamBuildingMember: () => Promise<void>

  defaultActions: TeamBuildingAction[]
  createdActions: TeamBuildingAction[]
}

export const useBuildingHooksContext = (teamId?: string) => {
  const { teamBuildingMembers, refresh: refreshTeamBuildingMembers } = useTeamBuildingMembers(teamId)
  const { teamBuildingActions, refresh: refreshTeamBuildingActions } = useTeamBuildingActions(teamId)
  const { teamBuildingMember: currentTeamBuildingMember, refresh: refreshTeamBuildingMember } =
    useTeamBuildingMember(teamId)
  const [defaultActionMasters, setDefaultActionMasters] = React.useState<Master[]>([])
  const getMasterActions = async () => {
    const response = await queryGetMasters({ type: MasterType.TeamBuildingQ5Action, limit: 100 })
    const items: Master[] = response?.items || []
    setDefaultActionMasters(items)
  }

  React.useEffect(() => {
    getMasterActions()
  }, [])

  const [defaultEmptyActions, setDefaultEmptyActions] = React.useState<TeamBuildingAction[]>([])

  React.useEffect(() => {
    if (teamId && defaultActionMasters.length > 0) {
      setDefaultEmptyActions(convertMasterItemToTeamBuildingAction(defaultActionMasters, teamId))
    }
  }, [teamId, defaultActionMasters])

  const defaultActions = React.useMemo(() => {
    const replaced = defaultEmptyActions.map((masterAction) => {
      const found = teamBuildingActions.find(({ name }) => name === masterAction.name)

      return found ? found : masterAction
    })
    return replaced
  }, [defaultEmptyActions, teamBuildingActions])

  const createdActions = React.useMemo(() => {
    const masterActionsNames = defaultEmptyActions.map(({ name }) => name)
    const filtered = teamBuildingActions.filter((teamBuildingAction) => {
      return !masterActionsNames.includes(teamBuildingAction.name)
    })
    return filtered
  }, [defaultEmptyActions, teamBuildingActions])

  return {
    teamBuildingActions,
    refreshTeamBuildingActions,
    teamBuildingMembers,
    refreshTeamBuildingMembers,
    currentTeamBuildingMember,
    refreshTeamBuildingMember,
    defaultActions,
    createdActions,
  }
}

export const BuildingHooksContext = React.createContext<Props>({} as Props)

export const convertMasterItemToTeamBuildingAction = (masters: Master[], teamId: string): TeamBuildingAction[] => {
  return masters.reduce((result: TeamBuildingAction[], m) => {
    if (m.id && m.title && m.reason && m.detail && m.timing) {
      result.push({
        id: m.id,
        name: m.title,
        reason: m.reason,
        content: m.detail,
        timing: m.timing,
        teamId,
        assignedTeamMemberId: TableConst.Dummy,
        assignedTeamMemberName: TableConst.Dummy,
        editable: 0,
        dummyHash: TableConst.Dummy,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      })
    }
    return result
  }, [])
}
