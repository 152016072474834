import { useState, useEffect, useCallback } from 'react'

import {
  queryGetOnboardingPostLikeList,
  queryGetOnboardingPostCommentLikeList,
  queryGetOnboardingPostSecondCommentLikeList,
} from 'pages/onboarding/graphql'
import { OnboardingPostLike, OnboardingPostCommentLike, OnboardingPostSecondCommentLike } from 'utils/generated'

export const useOnboardingPostLikes = (postId: string) => {
  const [postLikes, setPostLikes] = useState<OnboardingPostLike[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!postId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingPostLikeList({ postId })
      setPostLikes(response?.items || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [postId])

  useEffect(() => {
    refresh()
  }, [postId, refresh])

  return { postLikes, loading, error, refresh } as const
}

export const useOnboardingPostCommentLikes = (postId: string) => {
  const [postCommentLikes, setPostCommentLikes] = useState<OnboardingPostCommentLike[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!postId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingPostCommentLikeList({ postId })
      setPostCommentLikes(response?.items || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [postId])

  useEffect(() => {
    refresh()
  }, [postId, refresh])

  return { postCommentLikes, loading, error, refresh } as const
}

export const useOnboardingPostSecondCommentLikes = (postSecondCommentId: string) => {
  const [postSecondCommentLikes, setPostSecondCommentLikes] = useState<OnboardingPostSecondCommentLike[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!postSecondCommentId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingPostSecondCommentLikeList({ postSecondCommentId })
      setPostSecondCommentLikes(response?.items || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [postSecondCommentId])

  useEffect(() => {
    refresh()
  }, [postSecondCommentId, refresh])

  return { postSecondCommentLikes, loading, error, refresh } as const
}
