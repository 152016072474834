import * as React from 'react'

import { Theme, StyleRules } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import rightArrowNew from '../../assets/svg/rightArrowNew.svg'
import { Font, UsefulCard } from '../atoms'
import { GhostMageImg } from '../modules'
import { LoadingIndicator } from '../organisms/LoadingIndicator'

type Props = {
  ownStyles?: React.CSSProperties
  ghostId: string
  invisibleDetail?: boolean
}

export const GhostThumbnail: React.FC<Props> = (props) => {
  const { ghosts, setDetail, setModal, setDirectClose } = React.useContext(GhostContext)
  const ghost = ghosts.find((g) => g.id === props.ghostId)
  const classes = useStyles()

  if (!ghost) return <LoadingIndicator snapshot={{ vars: { ghost } }} />
  return (
    <UsefulCard
      backgroundColor="white"
      ownStyles={{
        margin: '25px auto 0',
        ...props.ownStyles,
      }}
    >
      <div className={classes.container}>
        <div className={classes.containerItem}>
          <GhostMageImg largeSize={false} ghostId={props.ghostId} />
        </div>
        <div className={classes.containerItemForText}>
          <div>
            <Font fontSize={10} color={constGhost.COLOR_GRAY2}>{`FILE NO.${ghost.fileNo}`}</Font>
          </div>
          <div className={classes.gohstName}>{ghost.name}</div>
        </div>
      </div>
      {!props.invisibleDetail && (
        <div style={{ textAlign: 'right' }}>
          <Font
            fontSize={14}
            bold
            color={constGhost.COLOR_MAIN_NEW}
            ownStyles={{ cursor: 'pointer', display: 'inline-block', margin: '0 24px 26px 0' }}
            onClick={() => {
              setDetail(ghost.id)
              setModal('detail')
              setDirectClose(true)
            }}
          >
            詳しく見る
            <img src={rightArrowNew} alt="alert" style={{ marginLeft: 8 }} />
          </Font>
        </div>
      )}
    </UsefulCard>
  )
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    container: {
      display: 'flex',
      gap: 24,
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        flexFlow: 'column-reverse',
        paddingBottom: 24,
      },
    },
    spContainer: {
      [theme.breakpoints.down('sm')]: {
        padding: '25px 16px 22px',
      },
    },
    containerItemForText: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
        width: '100%',
      },
    },
    gohstName: {
      fontWeight: 'bold',
      fontSize: 16,
      color: constGhost.COLOR_MAIN_NEW,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  }),
  { name: 'GhostThumbnail' }
)
