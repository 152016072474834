import React from 'react'

import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import { QuestionContainer, Button, ButtonContainer, WaitingForLeader, PanelMessage } from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q1End: React.FC<Props> = (props) => {
  const { isLeader } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q2,
      timeQ2: new Date().toISOString(),
    })
  }

  return (
    <>
      <QuestionContainer active={1} questionHeadBar titleTextBold noPadding isWaiting={!isLeader}>
        <PanelMessage nextQuestion={'次は「方向性キーワード」を決めましょう。'} />

        {isLeader ? (
          <ButtonContainer
            fixed
            marginTopPcLayout={150}
            buttons={[
              <Button key="q1-end-1" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        ) : (
          <WaitingForLeader />
        )}
      </QuestionContainer>
    </>
  )
}
