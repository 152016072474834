import React, { useContext, useEffect, useState } from 'react'

import { useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { AttachmentFileBox } from 'pages/onboarding/components/timeline/AttachmentFIleBox'
import { AttachmentImgBox } from 'pages/onboarding/components/timeline/AttachmentImgBox'
import { MediaGrid } from 'pages/onboarding/components/timeline/MediaGrid'
import { usePreview } from 'pages/onboarding/components/timeline/PreviewBox'
import { TeamStorageContext } from 'pages/onboarding/pages/timeline/hooks/teamStorage'
import { converts } from 'pages/onboarding/utils/convertText'
import { OnboardingPost, OnboardingPostFile, OnboardingTeamMember } from 'utils/generated'

import * as constants from 'assets/constants'

type Props = {
  post: OnboardingPost
  teamMembers: OnboardingTeamMember[]
}

export const PostTypePost: React.FC<Props> = ({ post, teamMembers }) => {
  const { retrieveFiles } = useContext(TeamStorageContext)

  const [attachments, setAttachments] = useState<{ [k: string]: OnboardingPostFile[] }>({
    files: [],
    imgs: [],
  })

  useEffect(() => {
    ;(async () => {
      const [files, imgs] = await retrieveFiles(post.fileNames || [], post.id)
      setAttachments({ files, imgs })
    })()
  }, [post, retrieveFiles, setAttachments])

  const [isLongText, setIsLongText] = useState(false)
  const classes = useStyles({ isLongText, isAttachment: post.fileNames && post.fileNames.length > 0 ? true : false })
  const commentRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (commentRef.current) {
      const { height } = commentRef.current.getBoundingClientRect()
      if (height > 150) {
        setIsLongText(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef.current?.getBoundingClientRect().height])

  const { setPreview } = usePreview()

  const MAX_IMG_LEN = 4
  const theme = useTheme()
  const isSMDown = useMediaQuery(theme.breakpoints.down('sm'))
  const OverLine = isSMDown ? 50 : 78

  const elemminateText = (str: string) => {
    return str.substr(0, OverLine)
  }
  const [isOpenAccodion, setIsOpenAccodion] = useState(false)

  const accodionToggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpenAccodion((prevTogl) => !prevTogl)
  }

  return (
    <div className={classes.root}>
      <div>
        {post.comment && post.comment?.length >= OverLine ? (
          <div>
            {isOpenAccodion ? (
              <div>
                <div
                  className={classes.message}
                  dangerouslySetInnerHTML={{
                    __html: converts(post.comment || '', teamMembers),
                  }}
                  ref={commentRef}
                />
              </div>
            ) : (
              <div>
                <div
                  className={classes.message}
                  dangerouslySetInnerHTML={{
                    __html: converts(elemminateText(post.comment) || '', teamMembers),
                  }}
                  ref={commentRef}
                />
              </div>
            )}
            <div onClick={(e) => accodionToggle(e)} className={classes.messageDetail}>
              {isOpenAccodion ? <div>閉じる</div> : <div>もっと表示する</div>}
            </div>
          </div>
        ) : (
          <div
            onClick={(e) => e.stopPropagation()}
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: converts(post.comment || '', teamMembers),
            }}
            ref={commentRef}
          />
        )}
      </div>
      {attachments.files.length ? (
        <>
          {attachments.files.length <= 3 ? (
            <div style={{ margin: '7px 0 0 0' }}>
              {attachments.files.map((item, i) => (
                <AttachmentFileBox
                  key={`AttachmentFileBox+${i}`}
                  fileName={item.fileName}
                  src={item.signedUrl || ''}
                  boxType={'timeline'}
                />
              ))}
            </div>
          ) : (
            <div style={{ margin: '7px 0 0 0' }}>
              {attachments.files.slice(0, 3).map((item) => (
                <AttachmentFileBox
                  key={`${item.fileName}-${item.size}`}
                  fileName={item.fileName}
                  src={item.signedUrl || ''}
                  boxType="timeline"
                />
              ))}
              <div className={classes.messageMore}>
                <span onClick={() => setIsLongText(false)}>
                  {`他${attachments.files.length - 3}件を表示する`}
                  <ArrowForwardIosIcon className={classes.messageMoreIcon} />
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      {attachments.imgs.length > 0 && (
        <MediaGrid>
          {attachments.imgs.slice(0, MAX_IMG_LEN).map((item, i) => (
            <AttachmentImgBox
              key={`atc-${i}-${item.fileName}`}
              src={item.signedUrl || undefined}
              boxType={'timeline'}
              mime={item.mimeType}
              onClick={() => setPreview(item)}
              isDownload
            />
          ))}
        </MediaGrid>
      )}
      {attachments.imgs.length > MAX_IMG_LEN && (
        <div className={classes.messageMore}>
          <span onClick={() => setIsLongText(false)}>
            {`他${attachments.imgs.length - MAX_IMG_LEN}件を表示する`}
            <ArrowForwardIosIcon className={classes.messageMoreIcon} />
          </span>
        </div>
      )}
    </div>
  )
}

type StyleProps = {
  isLongText: boolean
  isAttachment: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  message: ({ isLongText }) => ({
    paddingBottom: isLongText ? 0 : 3,
    marginTop: 24,
    borderRadius: 8,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    lineHeight: 1.43,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
    display: isLongText ? '-webkit-box' : undefined,
    overflow: isLongText ? 'hidden' : undefined,
    '-webkit-line-clamp': isLongText ? 6 : undefined,
    '& a': {
      color: '#2CBBC6',
      textDecoration: 'underline',
    },
  }),
  messageMore: ({ isAttachment }) => ({
    backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
    top: 160,
    left: 0,
    padding: isAttachment ? '9px 0 0 0' : '19px 0 3px',
    height: 43,
    borderRadius: 8,
    width: '100%',
    textAlign: 'right',
    '& span': {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 12,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }),
  messageMoreIcon: {
    fontSize: 14,
    position: 'relative',
    top: 3,
    left: 3,
  },

  messageDetail: {
    color: '#707070',
    fontSize: 12,
    textAlign: 'end',
    paddingTop: 10,
    cursor: 'pointer',
  },
}))
