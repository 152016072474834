import * as React from 'react'
import { useParams } from 'react-router-dom'

import { LoadingCircular } from 'pages/teams/components'
import { useTeamBuilding, useSummaryTeamBuilding, useManageTeamBuilding } from 'pages/teams/hooks'
import { UpdateTeamBuildingInput } from 'utils/generated'

import { SummaryTeamBuilding } from './components/SummaryTeamBuilding'

export const Kickoff: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { teamBuilding, refresh: refreshTeamBuilding, loading } = useTeamBuilding(teamId)
  const { summaryBuilding, refresh: refreshSummaryTeamBuilding } = useSummaryTeamBuilding(teamId)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const handleUpdateTeamBuilding = async (input: UpdateTeamBuildingInput) => {
    await updateTeamBuilding(input)
    await refreshTeamBuilding()
    await refreshSummaryTeamBuilding()
  }

  if (loading) return <LoadingCircular loading={loading} />

  if (!summaryBuilding) return null

  return (
    <SummaryTeamBuilding
      teamId={teamId}
      summary={summaryBuilding}
      teamBuilding={teamBuilding}
      handleUpdateTeamBuilding={handleUpdateTeamBuilding}
    />
  )
}
