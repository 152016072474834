import { createContext, useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"

import {LocationState} from 'history'

import { OnboardingTeamMemberRole } from "utils/generated"

import { authorizePageContent } from "./domain"

import { OnbPages } from "assets/pages"

type OnbParams = 'teamId' | 'actionId' | 'postId' | 'userId' | 'notificationId'
type ParamObj = {[key in OnbParams]?: string}

export const replacePathParams = (
  path: string, 
  pathParams: ParamObj
) => {
  let targetPath = path
  Object.keys(pathParams).forEach((param) => {
    targetPath = targetPath.replace(`:${param}`, pathParams[param as OnbParams] || '')
  })
  return targetPath
}

interface OnbHistoryOption {
  scroll?: 'restore' | 'still'
}

const custom = (history: ReturnType<typeof useHistory>, userRole: OnboardingTeamMemberRole) => {
  const onbHistory = {
    push: (location: OnbPages, params: ParamObj, option?: OnbHistoryOption, state?: LocationState) => {

      // role check 
      if (!authorizePageContent(userRole, location)) {
        return
      }
      
      // sub params
      const finalDest = replacePathParams(location, params)

      // push path
      history.push(finalDest, state)

      // scroll
      if (!option) {
        window.scrollTo({top: 0})
      } else if (option.scroll === 'restore') {
        // restore previous scroll position. later
      }
    },
    location: history.location,
    goBack: history.goBack,
  }
  return onbHistory
}

export type OnbHistory = ReturnType<typeof custom>
export const OnbRouteContext = createContext({} as OnbHistory)
export const useRouteManager = (userRole: OnboardingTeamMemberRole = OnboardingTeamMemberRole.Member):OnbHistory => {

  const history = useHistory()

  // prev scroll position? 
  // const [prevPos, setPrevPos] = useState()

  const onbHistory = useMemo(() => custom(history, userRole), [history, userRole])

  return onbHistory
}



/**
 * @returns custom onboarding history object that handles scroll, authentication, position memoizing
 */
export const useOnbHistory = ():OnbHistory => {

  const history = useContext(OnbRouteContext)

  return history
}