import React from 'react'

import { Avatar } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { format } from 'date-fns'

import { GenericModal } from 'components/Modal'
import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { HomeKarteResults } from 'pages/teams/pages/_tools/karte/pages/home/HomeKarteResults'
import { getTeamMemberKarteList } from 'pages/teams/pages/_tools/karte/utils'
import { TeamKarte, TeamMember, TeamMemberRole } from 'utils/generated'

import { DiagnoseButtons } from './DiagnoseButtons'

import ArrowLeftSvg from '../assets/arrowLeft.svg'
import ArrowLeftInactiveSvg from '../assets/arrowLeftInactive.svg'
import ArrowRightSvg from '../assets/arrowRight.svg'
import ArrowRightInactiveSvg from '../assets/arrowRightInactive.svg'
import ThreePointSvg from '../assets/threePoint.svg'
import { constants, Pages } from 'assets'
import { replacePathParams } from 'assets/history'

const numPerPage = 10 // Number of users per page

export const DataList: React.FC<{
  teamMemberList: TeamMember[]
  teamKarteList: TeamKarte[]
  loaded: boolean
}> = ({ teamMemberList, teamKarteList, loaded }) => {
  const classes = useStyles()
  const [sortMembers, setSortMembers] = React.useState<TeamMember[]>([])
  const [page, setPage] = React.useState(1)
  const [spPageMembers, setSpPageMembers] = React.useState<TeamMember[]>([])
  const { currentTeam, loadingTeam } = React.useContext(HooksContext)
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const isXsDown = useCustomMediaQuery('down', 'xs')

  const validDates = teamKarteList
    .map((karteList) => new Date(karteList.createdAt))
    .filter((date) => !isNaN(date.getTime()))

  const latestDate = format(new Date(Math.max(...validDates.map((date) => date.getTime()))), 'yyyy/M/d') // '実施日' is the latest date of karte conducted in team.

  const lastPage = Math.ceil(sortMembers.length / numPerPage)

  const buttonPages = {
    one:
      lastPage === 5
        ? 2
        : lastPage === 4
        ? 2
        : page === 1
        ? 1
        : page === lastPage && lastPage !== 2
        ? page - 2
        : page - 1,
    two:
      lastPage === 5 ? 3 : lastPage === 4 ? 3 : page === 1 ? 2 : page === lastPage && lastPage !== 2 ? page - 1 : page,
    three: lastPage === 5 ? 4 : lastPage === 4 ? 4 : page === 1 ? 3 : page === lastPage ? page : page + 1,
  }

  React.useEffect(() => {
    const sortList = teamMemberList
      .filter((m) => m.role.includes(TeamMemberRole.Member) || m.role.includes(TeamMemberRole.Leader))
      .sort((a, b) => a.fullName.localeCompare(b.fullName, 'ja'))
    const displayList =
      sortList.length > numPerPage
        ? sortList.slice(page <= 1 ? 0 : (page - 1) * numPerPage, page * numPerPage)
        : sortList

    setSortMembers(sortList)
    setSpPageMembers(displayList) // sort by Name in Japanese
  }, [teamMemberList, page])

  const handlePageDown = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
      scrollTableTop()
    }
  }

  const handlePageUp = () => {
    if (page < lastPage) {
      setPage((prev) => prev + 1)
      scrollTableTop()
    }
  }

  const handlePage = (pageNum: number) => () => {
    if (pageNum >= 1 && pageNum <= lastPage && pageNum !== page) {
      setPage(pageNum)
      scrollTableTop()
    }
  }

  const scrollTableTop = () => {
    window.scrollTo({ top: isXsDown ? 250 : 200 })
  }

  const openKarteDashboard = React.useCallback(() => {
    window.open(replacePathParams(Pages.TeamsToolKarte, { teamId: currentTeam?.id }), '_team-karte')
  }, [currentTeam])

  return (
    <>
      <div className={classes.header}>
        <div>
          <div className={classes.headerTitleBox}>
            <div className={classes.headerTitle}>みんなの診断結果</div>
            <div className={classes.headerDate}>実施日：{latestDate}</div>
          </div>
          <p className={classes.headerMsg}>
            カルテへようこそ。診断を通してチームメンバーの考え方を理解し合いましょう。
            <br />
            お互いを尊重し、個性を活かしてチーム力を向上していきましょう。
            <br />
            <span>
              「一覧を見る」や他のメンバーの診断結果は、リーダーの指示があるまでクリックしないようにお願いします。
            </span>
          </p>
        </div>
        <DiagnoseButtons />
      </div>

      <div className={classes.body}>
        <div className={classes.tableHeader}>
          <div className={classes.headerRow}>
            <div className={classNames(classes.headerBox, classes.nameBox)}>名前</div>
            <div className={classNames(classes.headerBox, classes.resultBox)}>診断結果</div>
          </div>
        </div>

        <div>
          {loaded ? (
            <div className={classes.tableRows}>
              {(isSmDown ? spPageMembers : sortMembers).map((teamMember, index) => (
                <DataRow key={`team-dashboard-karte-${index}`} teamMember={teamMember} teamKarteList={teamKarteList} />
              ))}
            </div>
          ) : (
            <>
              <LoadingCircular loading={true} />
            </>
          )}
        </div>
      </div>

      {isSmDown && lastPage > 1 ? (
        <div>
          <div className={classes.paginationButtons}>
            {page > 1 ? (
              <NoStyleButton type="button" onClick={handlePageDown}>
                <img className={classes.arrowButton} src={ArrowLeftSvg} alt="left arrow button" />
              </NoStyleButton>
            ) : (
              <img className={classes.arrowButton} src={ArrowLeftInactiveSvg} alt="inactive left arrow button" />
            )}

            <div className={classes.numButtonWrapper}>
              {((page > 2 && lastPage !== 3) || lastPage === 4 || lastPage === 5) && (
                <NoStyleButton type="button" onClick={handlePage(1)}>
                  <div className={classNames([classes.numButton, page === 1 && classes.numButtonActive])}>1</div>
                </NoStyleButton>
              )}
              {lastPage > 5 && page > 3 && (
                <div className={classes.threePoint}>
                  <img src={ThreePointSvg} alt="three point" />
                </div>
              )}
              <NoStyleButton type="button" onClick={handlePage(buttonPages.one)}>
                <div className={classNames([classes.numButton, page === buttonPages.one && classes.numButtonActive])}>
                  {buttonPages.one}
                </div>
              </NoStyleButton>
              <NoStyleButton type="button" onClick={handlePage(buttonPages.two)}>
                <div className={classNames([classes.numButton, page === buttonPages.two && classes.numButtonActive])}>
                  {buttonPages.two}
                </div>
              </NoStyleButton>
              {lastPage > 2 && (
                <NoStyleButton type="button" onClick={handlePage(buttonPages.three)}>
                  <div
                    className={classNames([classes.numButton, page === buttonPages.three && classes.numButtonActive])}
                  >
                    {buttonPages.three}
                  </div>
                </NoStyleButton>
              )}
              {lastPage > 5 && page < lastPage - 2 && (
                <div className={classes.threePoint}>
                  <img src={ThreePointSvg} alt="three point" />
                </div>
              )}
              {((lastPage > 5 && page < lastPage - 1) || lastPage === 5) && (
                <NoStyleButton type="button" onClick={handlePage(lastPage)}>
                  <div className={classNames([classes.numButton, page === lastPage && classes.numButtonActive])}>
                    {lastPage}
                  </div>
                </NoStyleButton>
              )}
            </div>

            {page < lastPage ? (
              <NoStyleButton type="button" onClick={handlePageUp}>
                <img className={classes.arrowButton} src={ArrowRightSvg} alt="right arrow button" />
              </NoStyleButton>
            ) : (
              <img className={classes.arrowButton} src={ArrowRightInactiveSvg} alt="inactive right arrow button" />
            )}
          </div>
          <div className={classes.activePage}>
            {page}/{lastPage}ページ
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={classes.linkButtonContainer}>
        <NoStyleButton
          className={classes.listLink}
          type="button"
          onClick={!loadingTeam ? openKarteDashboard : undefined}
        >
          一覧を見る
          <img src={ArrowRightSvg} alt="arrow icon" />
        </NoStyleButton>
      </div>
    </>
  )
}

const DataRow = ({ teamMember, teamKarteList }: { teamMember: TeamMember; teamKarteList: TeamKarte[] }) => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const targetTeamKarteList = getTeamMemberKarteList(teamMember.userId, teamKarteList)
  const latestData = targetTeamKarteList.length > 0 ? targetTeamKarteList[0] : null
  const dataCount = targetTeamKarteList.length

  const handleModalOpen = () => {
    if (latestData) {
      setIsModalOpen(true)
    } else {
      alert('診断結果が存在しません')
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <a className={classes.bodyRowWrapper} href="#" onClick={handleModalOpen}>
        <div className={classes.bodyRow}>
          <div className={classNames(classes.avatarBox, classes.nameBox)}>
            <Avatar className={classes.avatar} src={teamMember.user?.imageUrl ?? undefined} />
            <div className={classes.avatarName}>{teamMember.fullName}</div>
          </div>

          <div className={classNames(classes.date, classes.resultBox)}>
            {latestData ? (
              <div>
                {format(new Date(latestData.createdAt), 'yyyy/MM/dd')}の<span>診断結果を見る</span>
              </div>
            ) : (
              <div className={classes.noData}>_</div>
            )}
          </div>
        </div>
      </a>

      {/* modal */}
      <GenericModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        // modalStyle={{ overflow: 'hidden' }}
        styleProps={{ width: '100%', maxWidth: 600, height: '100%', maxHeight: 687 }}
      >
        {latestData ? (
          <HomeKarteResults isOnDashboard dataCount={dataCount} teamKarte={latestData} onBack={handleModalClose} />
        ) : null}
      </GenericModal>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    headerTitleBox: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      columnGap: 16,
    },
    headerTitle: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
    },
    headerDate: {
      color: constants.COLOR_GRAY_DARK,
      fontSize: 10,
    },
    headerMsg: {
      margin: '16px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
      lineHeight: 1.6,

      '& span': {
        color: 'red',
        fontWeight: 'bold',
      },
    },

    body: {
      marginTop: 24,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 48,
      [theme.breakpoints.up('md')]: {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 47,
      [theme.breakpoints.down('sm')]: {
        columnGap: 2,
      },
    },
    headerBox: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      padding: '0 16px',
    },
    nameBox: {
      width: 'calc(100svw * 0.24)', // body 部分にスクロールバーが表示される場合を考慮し、viewport をもとに可変させる（% などだと header と body とでずれる）。
      maxWidth: 350,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100svw * 0.5)',
      },
    },
    resultBox: {
      flex: 1,
    },
    tableRows: {
      [theme.breakpoints.up('md')]: {
        maxHeight: 224,
        overflow: 'auto',
      },
    },
    paginationButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 24,
    },
    arrowButton: {
      padding: '5px 8px',
    },
    numButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    numButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 20,
    },
    numButtonActive: {
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: constants.COLOR_WHITE,
    },
    threePoint: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        verticalAlign: 'top',
      },
    },
    activePage: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 16,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },

    bodyRowWrapper: {
      display: 'block',
      textDecoration: 'none',
      borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_WHITE2,
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:first-of-type': {
          borderTop: 'none',
        },
      },
    },
    bodyRow: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: 56,
      boxSizing: 'border-box',
    },
    avatarBox: {
      display: 'flex',
      columnGap: 8,
      padding: 16,
    },
    avatar: {
      width: 24,
      height: 24,
      backgroundColor: constants.COLOR_GRAY_LIGHT5,
      overflow: 'hidden',
      borderRadius: 12,
    },
    avatarName: {
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    date: {
      padding: '12px 16px',
      color: constants.COLOR_GRAY_DARK,
      fontSize: 10,

      '& span': {
        display: 'block',
        color: constants.COLOR_MAIN_NEW,
        fontWeight: 'bold',
      },
    },
    noData: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
    },

    linkButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    listLink: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      '@media (hover: hover)': {
        '&:hover': {
          textDecoration: 'underline',
        },
      },

      '& img': {
        marginLeft: 14,
        width: 6,
      },
    },
  }),
  { name: 'DataList' }
)
