import React from 'react'

import { Input, InputAdornment } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Control, Controller, FieldError, RegisterOptions } from 'react-hook-form'

import { FormError } from './FormError'

import { constants } from 'assets'

type StyleProps = {
  confirm: boolean
  width?: number | string
}

type Props = {
  label?: string
  name: string
  control?: Control
  readOnly?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  error?: FieldError
  defaultValue?: string | null
  value?: string
  endAdornment?: React.ReactNode
  onChange?: (...event: any[]) => void
} & StyleProps

export const SignUpInputWithLabel: React.FC<Props> = (props) => {
  const classes = useStyles({ width: props.width, confirm: props.confirm })
  const { label, name, control, rules, error, defaultValue, value, readOnly, endAdornment, confirm } = props

  return (
    <div className={classes.root}>
      {label ? (
        <div className={rules?.required ? classes.labelBox : ''}>
          <InputLabel>{label}</InputLabel>
          {rules?.required && !confirm ? <div className={classes.requiredMark}>必須</div> : <></>}
        </div>
      ) : (
        <></>
      )}
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, name, value }) => (
            <>
              <Input
                className={`${classes.input} ${readOnly ? classes.inputReadOnly : ''}`}
                name={name}
                readOnly={readOnly}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  if (props.onChange) {
                    props.onChange(e)
                  }
                }}
                error={error ? true : false}
              />
              {error ? (
                <div className={classes.errorContainer}>
                  <FormError error={error} />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        />
      ) : (
        <Input
          className={`${classes.input} ${readOnly ? classes.inputReadOnly : ''}`}
          name={name}
          readOnly={readOnly}
          defaultValue={defaultValue}
          value={value}
          endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : <></>}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: 20,
    },
    labelBox: {
      display: 'flex',
      alignItems: 'center',
    },
    requiredMark: {
      margin: '0 0 0 16px',
      padding: 3,
      fontWeight: 'bold',
      fontSize: 9,
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_ALERT,
      borderRadius: 4,
      lineHeight: 1,
    },

    labelContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    },

    input: (props: StyleProps) => ({
      //for init
      '&.MuiInput-underline:before': {
        content: 'none',
      },
      '&.MuiInput-underline:after': {
        content: 'none',
      },
      //
      // === for style ===
      //classes.selectBoxRoot
      width: props.width || '100%',
      height: 48,
      borderRadius: 8,
      padding: 8,
      border: props.confirm ? 'none' : '2px solid #E2E6E2',
      backgroundColor: props.confirm ? constants.COLOR_WHITE : undefined,
      margin: props.confirm ? 0 : '16px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      '& .MuiInputBase-root': {
        width: '100%',
        height: '100%',
      },
    }),

    inputReadOnly: {
      backgroundColor: '#f5f5f5',
    },

    errorContainer: {
      marginTop: 4,
    },
  }),
  { name: 'SignUpInputWithLabel' }
)

interface InputLabelProps {
  children: React.ReactNode
}

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const classes = useInputLabelStyles()

  return (
    <div>
      <label className={classes.label}>{props.children}</label>
    </div>
  )
}

const useInputLabelStyles = makeStyles(
  (theme: Theme) => ({
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
    },
  }),
  { name: 'InputLabel' }
)
