import { useCallback } from 'react'

import { format } from 'date-fns'

import { createAnket } from '../api/createAnket'
import { IAnket } from '../interface'

export const useAnket = () => {
  const createSingupAnket = useCallback(async (email: string) => {
    const emailStr = email.substr(0, email.indexOf('@'))
    const anket = localStorage.getItem(`anket_${emailStr}`)
    if (anket) {
      const parseAnket: IAnket = JSON.parse(anket)
      await createAnket({
        q1: JSON.stringify(parseAnket.industry),
        q2: JSON.stringify(parseAnket.position),
        q3: JSON.stringify(parseAnket.job),
        q4: JSON.stringify(parseAnket.employee),
        q5: JSON.stringify(parseAnket.cue),
        anketDate: format(new Date(), 'yyyy/MM/dd'),
      })
      localStorage.removeItem(`anket_${emailStr}`)
    }
  }, [])

  return { createSingupAnket } as const
}
