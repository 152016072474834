import gql from 'graphql-tag'

export const QueryGetAccount = `query GetUser {
  getAccount {
    id
    nickname
    setupStartedAt
    setupStartedAtList
    emailNotifySetup
  }
}`

export const MutationUpdateAccount = `mutation UpdateAccount($input: UserUpdateInput!) {
  updateAccount(input: $input) {
    id
    email
    nickname
    firstName
    lastName
    imageUrl
    shortDescription
    description
    setupStartedAt
    setupStartedAtList
    karuteStartedAt
    emailNotifySetup
    emailNotifyHour
    emailNotifyMinute
  }
}`

export const QueryGetSetupItems = gql`
  query GetSetupItems($setupStartedAt: String!) {
    getSetupItems(setupStartedAt: $setupStartedAt) {
      id
      category
      mission
      why
      what
      how
      period
      interval
      importance
      point
      referenceTime
      type
      typeTitle
      url
    }
  }
`

export const MutationDeleteSetupStartedAtListItem = gql`
  mutation DeleteSetupStartedAtListItem($setupStartedAt: String!) {
    deleteSetupStartedAtListItem(setupStartedAt: $setupStartedAt)
  }
`

export const QueryGetSetupAnketFeedback = `query GetSetupAnketFeedback($weekDate: String!) {
  getSetupAnketFeedback(weekDate: $weekDate) {
    id
    username
    weekDate
    total
    boss
    vision
    member
    team
    other
    comment
    setupStartedAt
    updatedAt
  }
}`

export const MutationUpdateSetupAnketFeedback = `mutation UpdateSetupAnketFeedback($weekDate: String!, $boss: Float!, $vision: Float!, $member: Float!, $team: Float!, $other: Float!, $comment: String, $setupStartedAt: String) {
  updateSetupAnketFeedback(input: {weekDate: $weekDate, boss: $boss, vision: $vision, member: $member, team: $team, other: $other, comment: $comment, setupStartedAt: $setupStartedAt}) {
    id
    username
    weekDate
    total
    boss
    vision
    member
    team
    other
    comment
    updatedAt
  }
}`

export const QueryGetSetupItemFeedback = `query GetSetupAnketFeedback($id: String!) {
  getSetupItemFeedback(id: $id) {
    id
    username
    setupItemId
    category
    value
    point
    comment
    settedAt
    updatedAt
  }
}`

export const QueryGetSetupItemFeedbacks = `query GetSetupItemFeedbacks($setupStartedAt: String!) {
  getSetupItemFeedbacks(setupStartedAt: $setupStartedAt) {
    id
    username
    setupItemId
    category
    value
    point
    comment
    settedAt
    updatedAt
  }
}`

export const MutationUpdateSetupItemFeedback = `mutation UpdateSetupItemFeedback($input: SetupItemFeedbackUpdateInput!) {
  updateSetupItemFeedback(input: $input) {
    id
    username
    setupItemId
    category
    value
    point
    comment
    settedAt
    updatedAt
    setupStartedAt
  }
}`

export const QueryGetPoints = `query getPoints($week: Int!, $setupStartedAt: String!) {
  getPoints(week: $week, setupStartedAt: $setupStartedAt) {
    total
    description
  }
}`

export const QueryGetRadar = `query GetRadar($week: Int!, $setupStartedAt: String!) {
  getRadar(week: $week, setupStartedAt: $setupStartedAt) {
    anket {
      boss
      vision
      member
      team
      other
    }
    reaction {
      boss
      vision
      member
      team
      other
    }
  }
}`

export const MutationIsConfirmed = `mutation IsConfirmed($email: String!) {
  isConfirmed(email: $email)
}`

export const QueryGetSelfCheck = `query GetSelfCheck($id: ID) {
  getSelfCheck(id: $id) {
    data
  }
}`

export const MutaionUpdateSelfCheck = `mutation UpdateSelfCheck($input: SelfCheckInput!) {
  updateSelfCheck(input: $input) {
    id
    data
    updatedAt
  }
}`

export const QueryGetSelfCheckComments = `query GetSelfCheckCommentList {
  getSelfCheckCommentList {
    items {
      id
      head
      titleGoodFirst
      titleGoodSecond
      messageGood
      titleBad
      messageBad
      adviceFirst
      adviceSecond
      urlFirst
      urlSecond
    }
  }
}`

export const QueryGetTeamAnketBuildingStatusForStart = gql`
  query getTeamAnketBuildingStatusForStart($teamId: ID!) {
    getTeamAnketBuildingStatusForStart(teamId: $teamId) {
      targetAnketId
      isActive
      hasAnswered
    }
  }
`

export const QueryGetTeamAnketBuildingDataForExport = gql`
  query getTeamAnketBuildingDataForExport($teamIds: [ID!]!) {
    getTeamAnketBuildingDataForExport(teamIds: $teamIds) {
      teamName
      anketDataList {
        anketSummary {
          id
          type
          teamId
          status
          date
          average
          averageQ1
          averageQ2
          averageQ3
          averageQ4
          averageQ5
          averageQ6
          total
          totalQ1
          totalQ2
          totalQ3
          totalQ4
          totalQ5
          totalQ6
          leaderTotal
          leaderQ1
          leaderQ2
          leaderQ3
          leaderQ4
          leaderQ5
          leaderQ6
          memberTotal
          memberQ1
          memberQ2
          memberQ3
          memberQ4
          memberQ5
          memberQ6
          countAnswer
          countLeader
          countMember
          answerRate
          createdAt
          updatedAt
        }
        anketEntries {
          id
          userFullName
          date
          q1
          q2
          q3
          q4
          q5
          q6
          average
          freeComment {
            targetId
            message
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const QueryGetTeamAnketBuildingSummaryListByTeam = gql`
  query getTeamAnketBuildingSummaryListByTeam($teamId: ID!, $maxLen: Int) {
    getTeamAnketBuildingSummaryListByTeam(teamId: $teamId, maxLen: $maxLen) {
      summaryStatus
      summaryCount
      closedSummaryList {
        id
        type
        teamId
        status
        date
        average
        growth
        building
        averageQ1
        averageQ2
        averageQ3
        averageQ4
        averageQ5
        averageQ6
        averageQ7
        total
        totalQ1
        totalQ2
        totalQ3
        totalQ4
        totalQ5
        totalQ6
        totalQ7
        leaderTotal
        leaderQ1
        leaderQ2
        leaderQ3
        leaderQ4
        leaderQ5
        leaderQ6
        leaderQ7
        memberTotal
        memberQ1
        memberQ2
        memberQ3
        memberQ4
        memberQ5
        memberQ6
        memberQ7
        comment {
          title
          message
        }
        freeComment {
          targetId
          message
          createdAt
        }
        countAnswer
        countLeader
        countMember
        answerRate
        anketList {
          id
          type
          teamId
          userId
          role
          date
          q1
          q2
          q3
          q4
          q5
          q6
          q7
          comment {
            title
            message
          }
          freeComment {
            targetId
            message
            createdAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      openSummary {
        id
        date
        status
        teamId
        type
        createdAt
        updatedAt
      }
      answerStatus
      unansweredMemberList {
        id
        teamId
        userId
        role
        fullName
        username
        organization
        position
        email
        statusUsage
        updatedAt
      }
      memberCount
    }
  }
`

export const MutationSendTeamAnketEmail = gql`
  mutation sendTeamAnketEmail($teamId: ID!) {
    sendTeamAnketEmail(teamId: $teamId)
  }
`
export const MutationSendEmailToUnansweredTeamAnketMembers = gql`
  mutation sendEmailToUnansweredTeamAnketMembers($teamId: ID!) {
    sendEmailToUnansweredTeamAnketMembers(teamId: $teamId) {
      isCompleted
      sentCount
      errorMessage
    }
  }
`

export const MutationCreateTeamAnketBuildingSummary = gql`
  mutation createTeamAnketBuildingSummary($input: CreateTeamAnketBuildingSummaryInput!) {
    createTeamAnketBuildingSummary(input: $input) {
      id
      type
      teamId
      status
      createdAt
    }
  }
`

export const MutationCalcTeamAnketBuilding = gql`
  mutation calcTeamAnketBuilding($input: CalcTeamAnketBuildingInput!) {
    calcTeamAnketBuilding(input: $input) {
      id
      type
      teamId
      status
      createdAt
    }
  }
`

export const MutationCreateTeamAnketBuilding = gql`
  mutation createTeamAnketBuilding($input: CreateTeamAnketBuildingInput!) {
    createTeamAnketBuilding(input: $input) {
      id
      type
      teamId
      userId
      role
      date
      q1
      q2
      q3
      q4
      q5
      q6
      q7
      comment {
        title
        message
      }
      freeComment {
        targetId
        message
      }
      createdAt
      updatedAt
    }
  }
`

// review
export const QueryGetTeamBuildingReviewMember = gql`
  query getTeamBuildingReviewMember($id: ID!) {
    getTeamBuildingReviewMember(id: $id) {
      id
      teamBuildingReviewId
      date
      teamId
      userId
      username
      teamMemberId
      answers
      createdAt
      updatedAt
    }
  }
`

export const QueryGetTeamBuildingReviewMembers = gql`
  query getTeamBuildingReviewMembers($teamBuildingReviewId: ID!, $limit: Int, $nextToken: String) {
    getTeamBuildingReviewMembers(teamBuildingReviewId: $teamBuildingReviewId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamBuildingReviewId
        date
        teamId
        userId
        username
        teamMemberId
        answers {
          type
          index
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const QueryGetTeamBuildingReviewSummary = gql`
  query getTeamBuildingReviewSummary($teamId: ID!) {
    getTeamBuildingReviewSummary(teamId: $teamId) {
      id
      histories {
        date
        averages {
          type
          index
          average
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`
export const QueryGetTeamBuildingReviewLatest = gql`
  query getTeamBuildingReviewLatest($teamId: ID!) {
    getTeamBuildingReviewLatest(teamId: $teamId) {
      id
      date
      teamId
      status
      iceBreak
      iceBreakNumber
      iceBreakUserIds
      iceBreakMinute
      answers {
        type
        index
        average
        items {
          userId
          username
          value
        }
      }
      nextAt
      createdAt
      updatedAt
    }
  }
`

const teamBuildingReviewFragment = gql`
  fragment TeamBuildingReview_Base on TeamBuildingReview {
    id
    date
    teamId
    status
    iceBreak
    iceBreakNumber
    iceBreakUserIds
    iceBreakMinute
    answers {
      type
      index
      average
      items {
        userId
        username
        value
      }
    }
    nextAt
    createdAt
    updatedAt
    timeCreated
    timeStep1
    timeStep1Select
    timeStep1Presen
    timeStep1End
    timeStep2
    timeStep2History
    timeStep2Input
    timeStep2Confirm
    timeStep2Select
    timeStep2Presen
    timeStep2End
    timeStep3
    timeStep3Select
    timeCompleted
  }
`
export const QueryGetTeamBuildingReview = gql`
  query getTeamBuildingReview($id: ID!) {
    getTeamBuildingReview(id: $id) {
      ...TeamBuildingReview_Base
    }
  }
  ${teamBuildingReviewFragment}
`
export const QueryGetTeamBuildingReviews = gql`
  query getTeamBuildingReviews($teamId: ID!, $limit: Int, $nextToken: String) {
    getTeamBuildingReviews(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
      items {
        ...TeamBuildingReview_Base
      }
      nextToken
    }
  }
  ${teamBuildingReviewFragment}
`

export const MutationUpdateTeamBuildingReview = gql`
  mutation updateTeamBuildingReview($input: UpdateTeamBuildingReviewInput!) {
    updateTeamBuildingReview(input: $input) {
      ...TeamBuildingReview_Base
    }
  }
  ${teamBuildingReviewFragment}
`

export const MutationUpdateTeamBuildingReviewMember = gql`
  mutation updateTeamBuildingReviewMember($input: UpdateTeamBuildingReviewMemberInput!) {
    updateTeamBuildingReviewMember(input: $input) {
      id
      teamBuildingReviewId
      hash
      date
      teamId
      userId
      username
      teamMemberId
      answers {
        type
        index
        value
      }
      createdAt
      updatedAt
    }
  }
`

export const MutationCreateTeamBuildingReview = gql`
  mutation createTeamBuildingReview($input: CreateTeamBuildingReviewInput!) {
    createTeamBuildingReview(input: $input) {
      createdAt
      date
      iceBreak
      iceBreakMinute
      iceBreakUserIds
      iceBreakNumber
      id
      nextAt
      status
      updatedAt
      teamId
    }
  }
`
