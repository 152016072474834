import React, { useContext, useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constGhost from 'pages/ghost/assets/constGhost'
import { ButtonContainer, MemberInfo, ModalConfirmForCommon } from 'pages/ghost/components/modules'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import {
  GrayBackGround,
  ButtonForGhost,
  BackResponsiveCard,
  Font,
  Flexbox,
  UsefulCard,
  ResearchBox,
} from '../components/atoms'
import { WidthControlContainer } from '../components/modules'
import { WaitingContainer } from '../components/modules/WaitingContainer'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { ResearchContext } from '../contexts/research'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

type DisplayList = {
  id: string
  name: string
  role: GhostMemberRole
  feeling: string
  nextAction: string
}[]

export const WorkResults: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { currentHistory } = useContext(HistoryContext)
  const { researchesFeeling, researchesNextAction, loading } = useContext(ResearchContext)
  const { teamMember, teamMemberList } = useContext(ghostTeamMemberContext)
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const { runGoToNextPage } = useContext(CommandContext)
  const isMdUp = useCustomMediaQuery('up', 'md')
  const [displayList, setDisplayList] = useState<DisplayList>([])
  const pBForFixedUIs = usePBForFixedUIs({ isOnlyLeader: true })
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen((prev) => !prev)
  }

  const onClick = async () => {
    teamMember && (await runGoToNextPage(teamMember))
    await updateGhostTeam({
      input: {
        id: teamId,
        progress: GhostProgress.S4SelectingPresentationOptions,
      },
    })
  }

  const skip = async () => {
    await updateGhostTeam({
      input: { id: teamId, progress: GhostProgress.S4DoneRandomAnswers },
    })
  }

  useEffect(() => {
    if (!loading) {
      const displayList = teamMemberList.map((member) => ({
        id: member.id,
        name: member.name ?? '',
        role: member.role,
        feeling: researchesFeeling.find((research) => research.userId === member.userId)?.report ?? '',
        nextAction: researchesNextAction.find((research) => research.userId === member.userId)?.report ?? '',
      }))

      setDisplayList(displayList)
    }
  }, [loading, researchesFeeling, researchesNextAction, teamMemberList])

  if (!(currentHistory && currentHistory.ghostId)) return <LoadingIndicator snapshot={{ vars: { currentHistory } }} />

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <Flexbox
            ownStyles={{
              justifyContent: isMdUp ? 'flex-start' : 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Font
              fontSize={16}
              bold
              color={constGhost.COLOR_MAIN_NEW}
              ownStyles={{
                letterSpacing: 0.8,
              }}
            >
              個人ワーク結果
            </Font>
          </Flexbox>

          <Flexbox flexDirection="column" ownStyles={{ marginTop: 24, gap: 24, paddingBottom: isMdUp ? 86 : 80 }}>
            {!loading &&
              displayList.length &&
              displayList.map((research) =>
                research.feeling || research.nextAction ? (
                  <UsefulCard
                    key={research.id}
                    backgroundColor={'white'}
                    ownStyles={{
                      padding: isMdUp ? '40px' : '16px',
                      width: '100%',
                    }}
                  >
                    <MemberInfo
                      name={research.name}
                      role={research.role}
                      ownStyles={{ width: '100%', marginBottom: 16 }}
                    />
                    <Flexbox
                      flexDirection="column"
                      ownStyles={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 16,
                      }}
                    >
                      {research.feeling && (
                        <ResearchBox title="気づいたこと / 感じたこと" research={research.feeling} />
                      )}
                      {research.nextAction && (
                        <ResearchBox title="明日から出来そうなアクション" research={research.nextAction} />
                      )}
                    </Flexbox>
                  </UsefulCard>
                ) : (
                  <Fragment key={research.id}></Fragment>
                )
              )}
          </Flexbox>
        </WidthControlContainer>

        {teamMember?.role === GhostMemberRole.Leader ? (
          <>
            <ForceProceed onClick={toggleModal} open={open} skip={skip} />
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key={'ghost-rule'}
                  buttonBodyColor={'green'}
                  bodyText="発表へ"
                  onClick={onClick}
                  ownStyles={{ maxWidth: 343 }}
                  isResizeBasic
                />,
                <ButtonForGhost
                  key="done"
                  buttonBodyColor="white"
                  bodyText="発表をスキップ"
                  onClick={toggleModal}
                  isResizeBasic
                />,
              ]}
            />
          </>
        ) : (
          <WaitingContainer />
        )}
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

export const ForceProceed: React.FC<{
  open: boolean
  onClick: () => void
  skip: () => Promise<void>
}> = ({ open, onClick, skip }) => (
  <ModalConfirmForCommon
    open={open}
    handleModalChange={onClick}
    firstLine="発表をスキップしますか？"
    info="発表をスキップして、次のステップに進みますか？"
    btnFirstLine="発表をスキップ"
    btnSecondLine="キャンセル"
    toHome={skip}
  />
)
