import * as React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { Theme } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles } from '@material-ui/styles'
import ja from 'date-fns/locale/ja'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'


type Props = {
  defaultValue?: Date
  onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void
  minDate?: Date
  maxDate?: Date
}

export const CalendarForm: React.FC<Props> = (props: Props) => {
  const { defaultValue, onChange, minDate, maxDate } = props
  const classes = useStyles()

  const [date, setDate] = React.useState<Date | null>(defaultValue ?? null)

  React.useEffect(() => {
    if (onChange && date) {
      onChange(date)
    }
  }, [onChange, date])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        margin="normal"
        id="date-picker-inline"
        value={date}
        onChange={(date) => setDate(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        className={classes.main}
        autoOk={true}
        minDate={minDate}
        maxDate={maxDate}
        keyboardIcon={<img src={CalendarSvg} alt="calendar" style={{ padding: 0 }} />}
        PopoverProps={{ classes: { paper: classes.popover } }}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,

    '& .MuiFormLabel-root': {
      color: constants.COLOR_MAIN,
    },

    '& .MuiInputBase-root': {
      paddingRight: 0,
      borderRadius: 8,
      border: '1px solid ' + constants.COLOR_ONBOARDING_GRAY_LIGHT,
      height: 42,
    },
    '& .MuiInputBase-input': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      padding: 0,
      marginLeft: 16,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `0 !important`,
    },
    '& .MuiButtonBase-root': {
      color: `${constants.COLOR_MAIN} !important`,
      padding: 8,
    },
  },
  popover: {
    borderRadius: 8,
    '& .MuiPickersDay-current': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },

    '& .MuiPickersDay-daySelected': {
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      color: constants.TEXT_WHITE,
    },
  },
}))
