import { OnboardingTeamMemberRole } from 'utils/generated'

export type Tx = { [stage: number]: { head: string; body: string } }

export const TxIntro = {
  head: 'オンボーディングへようこそ！',
  body: 'オンボーディングは新入社員を職場で支え\n早期に活躍してもらうための職場での行動支援アプリです。',
}

export const callTx = (role: OnboardingTeamMemberRole) => {
  switch (role) {
    case 'ADMIN':
      return TxLd
    case 'MEMBER':
      return TxMem
    case 'SUPPORTER':
      return TxSup
    default:
      return TxLd
  }
}

export const TxInfoTop = {
  actionAchieved: '見本画面 : アクションを達成した時',
  timelineDummyPosted: '見本画面 : タイムラインに投稿された時',
}
export const TxInfoBottom = {
  sampleDisplay: '見本画面です',
}

export const TxLd: Tx = {
  2: {
    head: 'あなたは運営事務局です',
    body: '運営事務局はメンバーやサポーターの追加権限及び、オンボーディングアクションの追加・削除・修正権限を持っています。\nメンバー（新入社員）の日々のオンボーディング行動に対する支援やサポーターのメンバーへの関わり方に関する支援などを行います。',
  },
  4: {
    head: 'まずはメンバーの追加をしよう',
    body: 'オンボーディングにチームメンバーを招待しましょう。招待は「メンバーリスト」から行うことができます。',
  },
  5: {
    head: 'メンバーの追加はこちらから',
    body: 'こちらのアイコンをタップし、メンバー追加のための情報を入力しましょう。',
  },

  7: {
    head: 'メンバー招待が完了しました。',
    body: '本来であればメンバーに招待メールが送られます。メンバーは届いたメール内にあるリンクをタップすることで、オンボーディングに参加できます。',
  },
  8: {
    head: '次にアクション管理を行いましょう',
    body: '「アクション」とはメンバーが達成すべき行動です。運営事務局は必要なアクションを追加したり、達成期日を設けるなど管理を行いましょう。',
  },
  9: {
    head: 'アクションの追加はこちらから',
    body: 'こちらのアイコンをタップし、新規アクションを追加しましょう。',
  },
  11: {
    head: 'アクションが追加されました',
    body: 'アクションはいつでも「アクション管理」から確認することができます。',
  },
  12: {
    head: '次にタイムラインを活用しよう',
    body: 'タイムラインにはメンバーの課題などが投稿されます。運営事務局のあなたはその投稿に対し、いいねやコメントをしながらメンバーのアクションのサポートを行いましょう。',
  },
  13: {
    head: 'まずはいいねをしてみましょう',
    body: '投稿に共感したり、応援したい時は気軽にいいねでメンバーを後押ししましょう。',
  },
  14: {
    head: 'コメント欄からコメントをしましょう',
    body: 'メンバーとの交流やアドバイスなど、気軽にコメントをしていきましょう。',
  },
  15: {
    head: '実際にはじめましょう',
    body: 'これでチュートリアルは完了です。おつかれさまでした！それでは早速、実際に運営事務局としてチームメンバーを育成しましょう。',
  },
}

export const TxSup: Tx = {
  2: {
    head: 'あなたはサポーターです',
    body: 'サポーターはメンバー（新入社員）の日々のオンボーディング行動に対して支援を行います。\nアクション状況をチェックしてメンバーの状況を把握したり、デイリーチェックやアクションシェアに対して「いいね」やコメントで支援をしましょう。',
  },
  4: {
    head: 'タイムラインを活用しよう',
    body: 'タイムラインにはメンバーの課題などが投稿されます。サポーターのあなたはその投稿に対し、いいねやコメントをしながらメンバーのアクションのサポートを行いましょう。',
  },
  5: {
    head: 'まずはいいねをしてみましょう',
    body: '投稿に共感したり、応援したい時は気軽にいいねでメンバーを後押ししましょう。',
  },
  6: {
    head: 'コメント欄からコメントをしましょう',
    body: 'メンバーとの交流やアドバイスなど、気軽にコメントをしていきましょう。',
  },
  7: {
    head: '実際にサポートをはじめましょう',
    body: 'これでチュートリアルは完了です。おつかれさまでした！それでは早速、実際にメンバーのサポートを行いましょう。サポーターの存在がメンバーの成長を促進します。',
  },
}

export const TxMem: Tx = {
  2: {
    head: 'あなたはメンバーです',
    body: 'メンバーは新入社員の皆さんです。\nメンバーは毎日、デイリーチェックやオンボーディング行動の\n更新・シェア・相談などを行います。\n積極的に動いて「自分の居場所」を早期に獲得しましょう。',
  },
  4: {
    head: 'まずはアクションリストを確認しよう',
    body: 'アクションリストにはあなたがやるべきアクションが表示されています。毎日アクションを確認し、計画的に達成していきましょう。',
  },
  5: {
    head: 'まずは最初のアクションを実行しよう',
    body: 'まずは「自己紹介をしてみよう」のカードをタップし実行しましょう。',
  },
  6: {
    head: '「投稿」から自己紹介をしましょう',
    body: '投稿ボタンから、タイムラインへアクションを投稿することができます。',
  },
  7: {
    head: 'ラベルを指定しコメントをしましょう',
    body: 'ラベルを指定することで他のメンバーに状況をわかりやすく伝えることができます。',
  },
  8: {
    head: 'タイムラインに投稿されました',
    body: 'タイムラインでは他のメンバーやサポーターなどからいいねやコメントが送られてきます。コメントを返したり、他メンバーにもいいねをして、積極的にタイムラインを活用していきましょう。',
  },
  9: {
    head: 'タイムライン投稿例',
    body: 'タイムラインでは他のメンバーやサポーターなどからいいねやコメントが送られてきます。コメントを返したり、他メンバーにもいいねをして、積極的にタイムラインを活用していきましょう。（実際にはまだ投稿されていません。）',
  },
  10: {
    head: 'アクションを達成させましょう',
    body: 'アクションについての説明に戻ります。こちらの達成ボタンをタップすると、アクションを達成することができます。',
  },
  11: {
    head: 'アクション達成',
    body: 'アクションが達成されると、「達成リスト」にアクションが追加されます。達成されたアクションは、いつでも確認できます。それでは実際にオンボーディングを始めましょう。\n（実際にはまだ投稿されていません。）',
  },
  12: {
    head: 'チュートリアル完了',
    body: 'お疲れ様でした！',
  },
  13: {
    head: '毎日デイリーチェックをしよう',
    body: 'デイリーチェックでは自分の今の気分をチームに共有し、今の自分の状態を把握してもらいましょう。必要があれば、誰かに相談してみよう！',
  },
}
