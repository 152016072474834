import React from 'react'

import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus, TeamBuildingV1ResultQ3 } from 'utils/generated'

import { QuestionContainer, Button, ButtonContainer, WaitingForLeader, PanelMessage } from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q3End: React.FC<Props> = (props) => {
  const { isLeader, teamBuilding } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q4,
      timeQ4: new Date().toISOString(),
    })
  }

  const resultQ3 = JSON.parse(teamBuilding?.resultQ3 ?? '') as TeamBuildingV1ResultQ3

  return (
    <>
      <QuestionContainer active={3} questionHeadBar titleTextBold noPadding isWaiting={!isLeader}>
        <PanelMessage
          resultTitle={'チーム目標は下記に決定しました！'}
          resultDetails={<>{resultQ3?.answer}</>}
          nextQuestion={'次は「行動規範」を決めましょう。'}
        />

        {isLeader ? (
          <ButtonContainer
            fixed
            marginTopPcLayout={150}
            buttons={[
              <Button key="q2-end-1" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        ) : (
          <WaitingForLeader />
        )}
      </QuestionContainer>
    </>
  )
}
