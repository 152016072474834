import React from 'react'

import { UpdateTeamAdminInput, User, TransferTeamAdminInput, TransferTeamAdminAcceptInput, Team } from 'utils/generated'

import {
  mutationTransferTeamAdmin,
  mutationTransferTeamAdminAccept,
  mutationUpdateTeamAdmin,
  queryGetTeamsManagedByAdmin,
} from './graphql'

export const useManageTeamAdmin = () => {
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()

  const updateTeamAdmin = React.useCallback(async (input: UpdateTeamAdminInput): Promise<User | undefined> => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await mutationUpdateTeamAdmin({ input })
      setUser(response)
      return response
    } catch (e) {
      setError(e)
      console.log('e', e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { user, loading, error, updateTeamAdmin } as const
}

export const useTransferTeamAdmin = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const transfer = React.useCallback(async (input: TransferTeamAdminInput): Promise<boolean> => {
    setLoading(true)
    setErrors(undefined)
    try {
      return await mutationTransferTeamAdmin({ input })
    } catch (e) {
      setErrors(e.errors)
    } finally {
      setLoading(false)
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const accept = React.useCallback(async (input: TransferTeamAdminAcceptInput): Promise<boolean> => {
    setLoading(true)
    setErrors(undefined)
    try {
      return await mutationTransferTeamAdminAccept({ input })
    } catch (e) {
      setErrors(e.errors)
    } finally {
      setLoading(false)
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, errors, transfer, accept } as const
}

export const useGetTeamsManagedByAdmin = () => {
  const [managedTeams, setManagedTeams] = React.useState<Team[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null | undefined>()

  const fetchTeams = async (): Promise<Team[]> => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await queryGetTeamsManagedByAdmin()
      return response as Team[]
    } catch (e: any) {
      setError(e.errors[0].message)
      return []
    } finally {
      setIsLoading(false)
    }
  }

  const refresh = React.useCallback(async () => {
    const newTeams = await fetchTeams()
    setManagedTeams(newTeams)
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { managedTeams, isLoading, error, refresh } as const
}
