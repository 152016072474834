import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'


import * as constants from '../../../../assets/constants'
import UsefulButton from '../../../../components/Button'
import { randomDigitsString } from '../../../../services/utils/random'
import SpeechBubble from '../../components/SpeechBubble'
import { cookieKeys } from '../static'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ leaderId: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [cookies, setCookie, removeCookie] = useCookies([cookieKeys.number, cookieKeys.data])

  // TODO: Add membership validation

  React.useEffect(() => {
    const number = cookies[cookieKeys.number]
    if (number) {
      props.history.push(`/karte/member/${props.match.params.leaderId}/questionnaire/${number}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStart = () => {
    const number = `M${randomDigitsString(6)}`
    setCookie(cookieKeys.number, number)
    removeCookie(cookieKeys.data)
    props.history.push(`/karte/member/${props.match.params.leaderId}/questionnaire/${number}`)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__header">
            <div style={{ width: '100%', textAlign: 'center' }}>メンバー診察室</div>
          </div>
          <div className="__container__content">
            <div className="__container__content__description">
              <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
              <div>
                <SpeechBubble>こちらはメンバー診察室です。</SpeechBubble>
                <SpeechBubble>下記注意事項をよく確認した上で​、 診断をスタートしてください。</SpeechBubble>
              </div>
            </div>

            <div className="__container__content__cautions">
              <div className="__container__content__cautions__title">注意事項</div>
              <p>所要時間は約3分です。</p>
              <p>あまり1問毎に時間をかけず回答していきましょう。</p>
              <div className="__container__content__cautions__hr" />
              <p>回答は誰にも共有されません。</p>
              <p>率直に今の気持ちでお答えください。</p>
              <div className="__container__content__cautions__hr" />
              <p>2回目の方は、前回の回答を気にしないでください。</p>
              <p>今の率直な回答にこそ価値があります。</p>
              <div className="__container__content__cautions__hr" />
              <p>診断後、カルテNoを忘れずに保存してください。</p>
              <p>上司にカルテNoを伝えることで、面談を有意義なものにできるかもしれません。</p>
            </div>

            <div className="__container__content__buttons">
              <UsefulButton
                bgColor={constants.COLOR_BLUE}
                textColor={constants.COLOR_WHITE}
                fullWidth={true}
                body={<div>診断スタート</div>}
                onClick={handleStart}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 48,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__content': {
          backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__description': {
            display: 'flex',
            padding: '16px 16px 8px',

            '& img': {
              width: 50,
              height: 50,
              marginRight: 16,
            },
          },

          '&__cautions': {
            padding: 16,
            background: '#fff',
            borderRadius: 5,
            marginBottom: 24,

            '& p': {
              marginTop: 8,
              marginBottom: 8,
            },

            '&__title': {
              color: constants.COLOR_MAIN,
              fontSize: 20,
              fontWeight: 'bold',
            },

            '&__hr': {
              borderBottom: `1px dotted ${constants.COLOR_MAIN}`,
            },
          },

          '&__buttons': {
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
