import React from 'react'

import { makeStyles } from '@material-ui/core'

import { constants } from 'assets'

type Props = {
  name?: string
  value?: string | string[] | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: string
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  style?: React.CSSProperties
}

export const InputText = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const classes = useStyles()
  const { name = '', value, onChange, placeholder, type, minLength, maxLength, min, max, style } = props

  return (
    <input
      className={classes.root}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      minLength={minLength}
      maxLength={maxLength}
      min={min}
      max={max}
      style={style}
      ref={ref}
    />
  )
})

const useStyles = makeStyles(
  {
    root: {
      resize: 'none',
      minWidth: 100,
      height: 48,
      borderColor: '#E9E9E9',
      padding: 16,
      border: '1px solid #E9E9E9',
      borderRadius: 8,
      fontSize: 14,

      '&:focus-visible': {
        outline: `${constants.COLOR_ONBOARDING_MAIN} solid 1px`,
      },
      '&::placeholder': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        opacity: 1,
      },
    },
  },
  { name: 'InputText' }
)
