import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { LikeMembers } from 'pages/onboarding/components/like-members'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingPostSecondCommentLikes } from 'pages/onboarding/hooks/post'

import { OnbContext } from '../PagesRoot'

export const TimelineSecondCommentLikesBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { postId } = useParams<{ postId: string }>()
    const { postSecondCommentLikes } = useOnboardingPostSecondCommentLikes(postId)

    return {
      teamId,
      postId,
      postSecondCommentLikes,
    }
  },
}

const Index = () => {
  const { teamId, postId, postSecondCommentLikes } = TimelineSecondCommentLikesBloc.useAdapter()

  return (
    <>
      <TitleWithBack title="コメントの返信にいいねした人" />

      <LikeMembers teamId={teamId} postId={postId} likes={postSecondCommentLikes} />
    </>
  )
}

export default Index
