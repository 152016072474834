import { RelatedTeamPlan } from 'utils/generated'

export const useTeamDesign = () => {
  const getTeamPlanLabels = (plans: string): string => {
    const pp = plans ? (JSON.parse(plans) as RelatedTeamPlan[]) : []
    const rr = pp.reduce((acc: string[], cur) => {
      return [...acc, cur.name]
    }, [])
    return rr.join('/')
  }

  const getColor = (
    value: number | null | undefined,
    good: number,
    better: number,
    mid: number,
    soso: number
  ): string => {
    if (!value) {
      return '#45BB63'
    }
    if (value > good) {
      return '#C62C2C'
    } else if (value > better) {
      return '#C0A000'
    } else if (value > mid) {
      return '#45BB63'
    } else if (value > soso) {
      return '#357895'
    } else {
      return '#664087'
    }
  }

  const rotate = (value: number | null | undefined): string => {
    if (!value) {
      return 'rotate(0deg)'
    }
    if (value > 3) {
      return 'rotate(-90deg)'
    } else if (value > 1) {
      return 'rotate(-45deg)'
    } else if (value > -1) {
      return 'rotate(0deg)'
    } else if (value > -3) {
      return 'rotate(45deg)'
    } else {
      return 'rotate(90deg)'
    }
  }

  const label = (value: number | null | undefined): string => {
    if (typeof value !== 'number') {
      return '-'
    }
    if (value >= 4.0) {
      return 'S'
    } else if (value > 3.0) {
      return 'A'
    } else if (value > 0) {
      return 'B'
    } else if (value > -2.0) {
      return 'C'
    } else {
      return 'D'
    }
  }

  return { getTeamPlanLabels, getColor, rotate, label }
}
