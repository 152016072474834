import * as React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

import * as constants from '../../assets/constants'

interface IOwnProps {
  color: 'orange' | 'pink' | 'blue' | 'green'
  title: string
  rankNumber: number
  value: number
  description: string
}

const getWidth = (value: number): string => {
  return `${value * 10}%`
}
const colorMap: { [key in IOwnProps['color']]: { hex: string; rgba: string } } = {
  orange: {
    hex: '#F49147',
    rgba: 'rgba(244,145,71,0.1)',
  },
  pink: {
    hex: '#E76A74',
    rgba: 'rgba(231,106,116,0.1)',
  },
  blue: {
    hex: '#229BDB',
    rgba: 'rgba(34,115,219,0.1)',
  },
  green: {
    hex: '#41B0B8',
    rgba: 'rgba(65,176,184,0.1)',
  },
}

export const Graph: React.FC<IOwnProps> = (props) => {
  const styles = StyleSheet.create({
    graphContainer: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 6 },
    graphContainer__title: {
      marginVertical: 'auto',
      marginHorizontal: 0,
      display: 'flex',
      backgroundColor: colorMap[props.color].rgba,
      width: 132,
      height: 16,
    },
    graphContainer__title__text: {
      marginVertical: 'auto',
      color: colorMap[props.color].hex,
      fontSize: 9,
      marginRight: 8,
    },
    graphContainer__rank: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      border: `1px solid ${colorMap[props.color].hex}`,
      borderRadius: 2,
      width: 18,
      height: 18,
    },
    graphContainer__rank__number: {
      color: colorMap[props.color].hex,
      fontSize: 9,
      textAlign: 'left',
      marginTop: 4,
      marginLeft: 2,
    },
    graphContainer__rank__unit: { color: colorMap[props.color].hex, fontSize: 6, textAlign: 'right', marginTop: 4 },
    graphContainer__graphArea: {
      backgroundColor: '#F6F6F6',
      width: 136,
      height: 14,
      marginVertical: 'auto',
      marginHorizontal: 1,
    },
    graphContainer__graphArea__filled: {
      display: 'flex',
      backgroundColor: colorMap[props.color].hex,
      width: getWidth(props.value),
      height: 14,
    },
    graphContainer__graphArea__filled__text: {
      color: constants.COLOR_WHITE,
      fontSize: 6,
      marginVertical: 'auto',
      marginRight: '5%',
    },
    graphContainer__description: { fontSize: 9, marginVertical: 'auto', marginLeft: 8 },
  })

  return (
    <View style={styles.graphContainer}>
      <View style={styles.graphContainer__title}>
        <Text style={styles.graphContainer__title__text}>{props.title}</Text>
      </View>
      <View style={styles.graphContainer__rank}>
        <Text style={styles.graphContainer__rank__number}>{props.rankNumber}</Text>
        <Text style={styles.graphContainer__rank__unit}>&nbsp;位</Text>
      </View>
      <View style={styles.graphContainer__graphArea}>
        <View style={styles.graphContainer__graphArea__filled}>
          <Text style={styles.graphContainer__graphArea__filled__text}>{props.value}</Text>
        </View>
      </View>

      <Text style={styles.graphContainer__description}>…&nbsp;{props.description}</Text>
    </View>
  )
}
