import { reducerWithInitialState } from 'typescript-fsa-reducers'

import action from './action'

export interface State {
  name: string | null
  data: any
  leaderData: any
  isLoading: boolean
  error: any
}

const INITIAL_STATE: State = {
  name: null,
  data: null,
  leaderData: null,
  isLoading: false,
  error: null,
}

export default reducerWithInitialState(INITIAL_STATE)
  .case(action.fetch.started, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }))
  .case(action.fetch.done, (state, payload) => ({
    ...state,
    name: payload.result ? payload.result.name : null,
    data: payload.result ? payload.result.data : null,
    leaderData: payload.result ? payload.result.leaderData : null,
    isLoading: false,
  }))
  .case(action.fetch.failed, (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload.error,
  }))
  .case(action.resetError, (state, payload) => ({
    ...state,
    error: null,
  }))
