import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'

import { EmblaCarousel } from 'components/EmblaCarousel'
import { NoStyleButton } from 'components/NoStyleButton'
import { UsageNotes } from 'components/UsageNotes'
import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { WorkplaceLab } from 'components/WorkplaceLab'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { SimilarTypeSample } from 'pages/obakesagashi/components/SimilarTypeSample'

import { WaveBack } from './WaveBack'

import { constants } from 'assets'

const OPTIONS: EmblaOptionsType = { loop: true }
const PLUGINS: EmblaPluginType[] = [Autoplay({ playOnInit: true, delay: 15000 })]

export const GhostFinding: React.FC = () => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  const handleToTop = () => {
    window.open(constants.PAGE_TOP, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <SimilarTypeSample />

      <section className={classes.section}>
        <WaveBack />
        <WidthControlWrapper>
          <div className={classes.titleBox}>
            <h2 className={classes.title}>
              会社やチームで
              <br />
              みんなで{isSmDown && <br />}オバケさがし！
            </h2>
            <p className={classes.desc}>
              チームの対話支援ツール「Cocolabo」なら、チームみんなでリアルタイムに結果を共有しながらオバケ探しと対話をすることができます。
              <br />
              <br />
              職場のオバケ探しは、管理職と従業員の対話支援ツール「Cocolabo」の「オバケ」を、無料でお試し利用することができるツールです。「Cocolabo」では「オバケ」「カルテ」「キックオフ」の3つの対話ツールを用意してあり、組織やチームのシーンに応じて使い分けることができ、エンゲージメント向上に必要な仕事の進め方や関係性に特化した「対話」を気軽に実現することができます。
              {!isSmDown && <br />}
              正式版のCocolaboオバケでは、チーム全員でのオバケ探しや結果の管理、オバケに対処するために取り組むアクションの登録を行うことができます。
            </p>
          </div>
          <div className={classes.contentsBox}>
            <div className={classes.carousel}>
              <EmblaCarousel options={OPTIONS} plugins={PLUGINS} isTopGhost />
              <div className={classes.toTopButtonBox}>
                <NoStyleButton type="button" onClick={handleToTop}>
                  Cocolaboについてくわしく
                </NoStyleButton>
              </div>
            </div>
          </div>
        </WidthControlWrapper>

        <WorkplaceLab isGhost />
        <UsageNotes isGhost />
      </section>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    section: {
      background: `${constants.COLOR_NAVY} repeat top left / 67px url(${process.env.PUBLIC_URL}/img/obakesagashi/back-dia.svg)`,
      paddingBottom: 190,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 128,
      },
      marginBottom: -128,
    },
    titleBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      margin: '86px 0 0',
      color: constants.COLOR_YELLOW4,
      fontFamily: '"MOBO SemiBold"',
      fontSize: 35,
      fontWeight: 600,
      lineHeight: '40px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '72px 0 0',
        fontSize: 32,
      },
      '&::before, &::after': {
        position: 'absolute',
        top: 42,
        content: '""',
        width: '96px',
        height: '1.5px',
        backgroundColor: constants.COLOR_YELLOW4,
        [theme.breakpoints.down('sm')]: {
          top: 72,
          width: '100px',
        },
      },
      '&::before': {
        left: -86,
        transform: 'rotate(55deg)',
        [theme.breakpoints.down('sm')]: {
          left: -84,
        },
      },
      '&::after': {
        left: 'calc(100% - 12px)',
        transform: 'rotate(-55deg)',
        [theme.breakpoints.down('sm')]: {
          left: 'calc(100% - 24px)',
        },
      },
    },
    desc: {
      margin: '39px 0 0',
      maxWidth: 700,
      fontSize: 16,
      lineHeight: 1.5,
      color: constants.COLOR_WHITE,
      [theme.breakpoints.up('md')]: {
        letterSpacing: 1.6,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '24px 0 0',
        lineHeight: 2,
      },
    },
    contentsBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 40,
      marginTop: 70,
      [theme.breakpoints.down('sm')]: {
        marginTop: 80,
      },
      '& > div': {
        width: '100%',
        background: '#fff',
        borderRadius: 53,
        padding: 40,
        [theme.breakpoints.down('sm')]: {
          borderRadius: 16,
          padding: '24px 16px 16px',
        },
      },
    },
    carousel: {
      paddingTop: '76px !important',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '36px !important',
      },
    },
    toTopButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0 -9px',
      [theme.breakpoints.down('sm')]: {
        margin: '24px 0 24px',
      },
      '& button': {
        width: 300,
        height: 60,
        background: constants.COLOR_BLUE_DARK2,
        borderRadius: 30,
        color: '#FFF',
        fontSize: 16,
        fontWeight: 900,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: 350,
        },
      },
    },
  }),
  { name: 'GhostFinding' }
)
