import React from 'react'

import { View, Text } from '@react-pdf/renderer'

interface IOwnProps {
  text: string
}

/**
 * 長文の文字列を <Text> で出力すると、改行時に hyphenation されてしまうのと、
 * 日本語だと改行が挿入されてしまう箇所を制御できないため、それを解消するためのコンポーネント
 * @see https://github.com/diegomura/react-pdf/issues/419#issuecomment-886362634
 * @param props
 * @constructor
 */
export const AutoWrapLine: React.FC<IOwnProps> = (props) => {
  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {Array.from(props.text).map((char, index) => (
        <Text key={`line-${index}`} style={{ marginBottom: 4 }}>
          {char}
        </Text>
      ))}
    </View>
  )
}
