import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'

import { get } from '../../services/api/helps'
import { RootState } from '../reducers'

import { HelpActions } from './action'

type Action = ActionType<typeof HelpActions>

const onMountHelp: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(HelpActions.onMount.started.match),
    exhaustMap(({ payload }) =>
      from(get()).pipe(
        map((res) => {
          return HelpActions.onMount.done({ params: payload, result: res })
        }),
        catchError((error) => of(HelpActions.onMount.failed({ params: payload, error })))
      )
    )
  )

const searchHelp: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(HelpActions.serach.started.match),
    exhaustMap(({ payload }) =>
      from(get(payload.q, payload.filter)).pipe(
        map((res) => {
          return HelpActions.serach.done({ params: payload, result: res?.data })
        }),
        catchError((error) => of(HelpActions.serach.failed({ params: payload, error })))
      )
    )
  )

export default [onMountHelp, searchHelp]
