import * as React from 'react'

import leftArrowIcon from '../../assets/svg/leftArrowIcon.svg'
import rightArrowIcon from '../../assets/svg/rightArrowIcon.svg'
import { Flexbox, Imgcard } from '../atoms'

type Props = {
  onClickLeft: () => void
  onClickRight: () => void
  ownStyles?: React.CSSProperties
}

export const Arrows: React.FC<Props> = (props) => {
  return (
    <Flexbox ownStyles={{ justifyContent: 'space-between' }}>
      <Imgcard src={leftArrowIcon} alt="leftArrow" onClick={props.onClickLeft} />
      <Imgcard src={rightArrowIcon} alt="rightArrow" onClick={props.onClickRight} />
    </Flexbox>
  )
}
