import { connect, ConnectedProps } from 'react-redux'

import { RootStateType } from '../../../store'
import { SetupActions } from '../../../stores/setup/action'
import { SetupactionActions } from '../../../stores/setupaction/action'

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetch: async (setupStartedAt: string, week: number, username: string) =>
      dispatch(
        SetupactionActions.fetch.started({
          params: { setupStartedAt, week, username },
        })
      ),

    search: () => {
      dispatch(
        SetupactionActions.search.started({
          params: {},
        })
      )
      dispatch(
        SetupactionActions.search.done({
          result: { processing: [] },
          params: {},
        })
      )
    },

    changeCurrentSetupStartedAt: (date: string) => dispatch(SetupActions.changeCurrentSetupStartedAt({ date })),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    setup: { ...state.setup },
    setupaction: { ...state.setupaction },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
