import * as React from 'react'

import {
  queryGetOnboardingOwnTeamMemberList,
  queryGetOnboardingTeamMemberList,
  queryGetOnboardingTeamMember,
  queryGetOnboardingTeam,
} from 'pages/onboarding/graphql'
import { TeamMemberUpdateEvent } from 'utils/event'
import {
  OnboardingTeam,
  OnboardingTeamMember,
  OnboardingTeamMemberRole,
  OnboardingTeamMemberStatus,
  OnboardingUsageStatus,
} from 'utils/generated'

import { useOnbHistory } from '../navigation/route'

import { useTeamManage } from './teamManage'

import { OnbPages } from 'assets/pages'

export const useTeam = (teamId?: string) => {
  const [team, setTeam] = React.useState<OnboardingTeam | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetOnboardingTeam({ id: teamId })
      setTeam(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { team, loading, error, refresh } as const
}

export const useTeams = (role?: OnboardingTeamMemberRole) => {
  const [teams, setTeams] = React.useState<OnboardingTeam[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetOnboardingOwnTeamMemberList({})
      const localTeams: OnboardingTeam[] = []
      response.getOnboardingOwnTeamMemberList?.items.forEach((member) => {
        if (
          member.team &&
          member.status !== OnboardingTeamMemberStatus.Stopped &&
          member.team.usageStatus === OnboardingUsageStatus.Active
        ) {
          if (role) {
            if (role === member.role) {
              localTeams.push(member.team)
            }
          } else {
            localTeams.push(member.team)
          }
        }
      })
      setTeams(localTeams.filter((t) => t.usageStatus === OnboardingUsageStatus.Active))
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { teams, loading, error, refresh } as const
}

export const useTeamMembers = (teamId?: string) => {
  const LIMIT = 1000

  const [teamMembers, setTeamMembers] = React.useState<OnboardingTeamMember[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [currentToken, setCurrentToken] = React.useState<any>()
  const [tokens, setTokens] = React.useState<string[]>([])

  const refresh = React.useCallback(
    async (after?: string) => {
      if (!teamId) {
        return
      }
      setLoading(true)
      setError(undefined)
      try {
        const response = await queryGetOnboardingTeamMemberList({ teamId: teamId, first: LIMIT, after })
        const nextToken = response.getOnboardingTeamMemberList?.nextToken

        setTeamMembers(response.getOnboardingTeamMemberList?.items || [])

        setLoading(false)

        return nextToken
      } catch (e) {
        console.log('e', e)
        setError(e)
      }
    },
    [teamId]
  )

  const more = React.useCallback(
    async (index: number) => {
      const currentToken = tokens[index]
      const nextToken = await refresh(currentToken)
      setCurrentToken(nextToken)

      return nextToken
    },
    [tokens, refresh]
  )

  const next = React.useCallback(async () => {
    if (!loading && currentToken && currentToken !== null) {
      const nextToken = await more(currentPage)

      if (nextToken) {
        setTokens((prev) => [...prev, nextToken])
        setCurrentPage((prev) => ++prev)
      }
    }
  }, [loading, currentToken, currentPage, more])

  const prev = React.useCallback(async () => {
    if (!loading && tokens.length > 0) {
      const prevNum = currentToken === null ? 1 : 2

      const nextToken = await more(currentPage - prevNum)
      if (nextToken) {
        const newTokens = [...tokens.slice(0, -prevNum), nextToken]
        setTokens(newTokens)
      }

      if (currentPage && currentToken !== null) setCurrentPage((prev) => --prev)
    }
  }, [loading, tokens, currentToken, currentPage, more])

  React.useEffect(() => {
    ;(async () => {
      const nextToken = await refresh()
      setCurrentToken(nextToken)
      if (nextToken) setTokens([nextToken] || [])
    })()
  }, [teamId, refresh])

  return {
    teamMembers,
    loading,
    error,
    currentToken,
    currentPage,
    LIMIT,
    refresh,
    next,
    prev,
  } as const
}

export const useTeamMember = (teamId?: string, userId?: string) => {
  const [teamMember, setTeamMember] = React.useState<OnboardingTeamMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetOnboardingTeamMember({ teamId, userId })
      setTeamMember(response.getOnboardingTeamMember)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userId])

  React.useEffect(() => {
    refresh()
  }, [teamId, refresh])

  React.useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(TeamMemberUpdateEvent, handler)
    return () => {
      window.removeEventListener(TeamMemberUpdateEvent, handler)
    }
  }, [refresh])

  return { teamMember, loading, error, refresh } as const
}

/**
 * fetches self info using cognito sub on BE
 */
export const useTeamMemberWithAuth = (teamId?: string) => {
  const { teamMember, loading, error, refresh } = useTeamMember(teamId)
  const { teams } = useTeams()
  const history = useOnbHistory()
  const { activateMember } = useTeamManage()

  React.useEffect(() => {
    if (!loading) {
      if (!teamMember || teamMember.status === OnboardingTeamMemberStatus.Stopped) {
        if (teams.length > 0) {
          history.push(OnbPages.Timeline, { teamId: teams[0].id })
        }
      }

      if (teamMember && teamMember.status === OnboardingTeamMemberStatus.Pending) {
        if (teamId) {
          activateMember({ teamId })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, teams])

  return { teamMember, loading, error, refresh } as const
}
