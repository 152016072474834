import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs'

import { TeamAnketBuildingComment } from 'utils/generated'

import personIcon from '../assets/person.svg'
import { constants } from 'assets'

type Props = {
  comments: TeamAnketBuildingComment[] | null | undefined
  anketOpenDate?: string
}

type AnketCommentProps = {
  comment: TeamAnketBuildingComment
  anketOpenDate?: string
}

const AnketComment: React.FC<AnketCommentProps> = ({ comment, anketOpenDate }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.commentHead}>
        <div className="commentInfo">
          <img src={personIcon} alt="person" />
        </div>
        <span>
          {dayjs(comment?.createdAt ?? anketOpenDate)
            .tz('Asia/Tokyo')
            .format('YYYY.MM.DD')}
        </span>
      </div>
      <div className={classes.commentBody}>{comment.message}</div>
    </div>
  )
}

const LIMIT = 2 // コメントのデフォルト最大表示数

export const AnketComments: React.FC<Props> = ({ comments, anketOpenDate }) => {
  const classes = useStyles()
  const [showMore, setShowMore] = React.useState(false)
  const commentsByLimit = React.useMemo(
    () => (showMore ? comments : comments?.filter((_, i) => i < LIMIT)),
    [comments, showMore]
  )

  return (
    <div className={classes.comments}>
      <Typography className={classes.title}>フリーコメント</Typography>
      <div className={classes.comment}>
        {(commentsByLimit?.length === 0 || !commentsByLimit) && (
          <div className={classes.commentBody}>コメントがありません</div>
        )}
        {commentsByLimit?.map((comment, i) => (
          <AnketComment key={`$comment-${i}`} comment={comment} anketOpenDate={anketOpenDate} />
        ))}
        {comments && comments.length > LIMIT && (
          <div className={classes.showMore} onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? '少なく表示' : 'もっと見る'}
          </div>
        )}
      </div>
    </div>
  )
}
const useStyles = makeStyles(
  (theme: Theme) => ({
    commentsWrap: {
      display: 'flex',
      gap: '16px',
    },
    showMore: {
      cursor: 'pointer',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      textAlign: 'right',
    },
    comments: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      flex: 1,
    },
    title: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 14,
    },
    comment: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: 16,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      [theme.breakpoints.down('sm')]: {
        gap: 16,
        padding: '16px 8px',
        backgroundColor: '#fff',
      },
    },

    // For AnketComment Class
    commentHead: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      '& .commentInfo': {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        fontSize: 12,
        fontWeight: 'bold',
      },
      '& span': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_400,
        fontSize: 12,
      },
    },
    personIcon: {
      verticalAlign: 'top',
      width: 24,
    },
    commentBody: {
      padding: 16,
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 14,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      },
    },
  }),
  { name: 'AnketComments' }
)
