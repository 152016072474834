import { createTheme } from '@material-ui/core/styles'

import { COLOR_MAIN } from './constants'

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#1F1818',
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: COLOR_MAIN,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1250, // default 1400
    tooltip: 1275, // default 1500
    // header is 1290
  },
})
