import * as React from 'react'
import { useContext } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { Flexbox, Font } from '../atoms'

type Props = {
  ownStyles?: React.CSSProperties
}

export const GhostListTop: React.FC<Props> = (props) => {
  const { setModal } = useContext(GhostContext)

  return (
    <Flexbox ownStyles={{ justifyContent: 'space-between', padding: '16px 0' }}>
      <Font fontSize={14} bold ownStyles={{ margin: '0 0 0 16px' }}>
        オバケリスト
      </Font>
      <Font fontSize={14} ownStyles={{ margin: '0 16px 0 0 ' }} onClick={() => setModal('')}>
        <span style={{ fontSize: 15, marginRight: 7 }}>
          <FontAwesomeIcon icon={faTimes} color={constGhost.COLOR_BLACKFORTEXT} />
        </span>
        閉じる
      </Font>
    </Flexbox>
  )
}
