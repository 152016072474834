import * as React from 'react'

import { GraphQLResult } from '@aws-amplify/api'

import { useCreateId } from 'hooks/createId'
import { getGhostList } from 'pages/ghost/api/ghost/handlers'
import { ERROR_MESSAGES, isErrorWithGraphQLErrors } from 'utils/error'
import { CreateNoSignInGhostQuestions, Ghost, NoSignInGhost, NoSignInGhostWithTotal } from 'utils/generated'

import { mutationCreateNoSignInGhost, queryGetNoSignInGhost } from '../api/api'

export const useObakesagashi = (resultId?: string) => {
  const [ghosts, setGhosts] = React.useState<Ghost[]>([])
  const [result, setResult] = React.useState<NoSignInGhostWithTotal | null>()
  const [isCreateLoading, setIsCreateLoading] = React.useState(false)
  const [errors, setErrors] = React.useState<GraphQLResult['errors'] | null>(null)
  const { buildNanoidId } = useCreateId()

  const fetchGhostList = React.useCallback(async () => {
    const res = await getGhostList({})

    if (res) {
      // チーム版のオバケと同じ並びにする。
      res.sort((a, b) => {
        return Number(a.fileNo) - Number(b.fileNo)
      })
      setGhosts(res)
    }
  }, [])

  const createNoSignInGhost = React.useCallback(
    async ({
      questions,
      selectedGhostIdList,
    }: {
      questions: CreateNoSignInGhostQuestions
      selectedGhostIdList: number[]
    }) => {
      setIsCreateLoading(true)
      setErrors(null)

      let response: NoSignInGhost | undefined
      const MAX_RETRY_COUNT = 3

      // 生成する id が重複した場合に備え、指定した回数リトライする。
      for (let i = 0; i < MAX_RETRY_COUNT; i++) {
        try {
          response = await mutationCreateNoSignInGhost({
            input: {
              id: buildNanoidId(),
              questions,
              selectedGhostIdList,
            },
          })
          if (response) break
        } catch (e) {
          if (i === MAX_RETRY_COUNT - 1) {
            if (isErrorWithGraphQLErrors(e)) {
              setErrors(
                e.errors?.map((error) => ({
                  message: error.message || ERROR_MESSAGES.unexpected,
                }))
              )
            } else {
              setErrors([{ message: ERROR_MESSAGES.unexpected }])
            }
          }
        }
      }

      setIsCreateLoading(false)
      return response
    },
    [buildNanoidId]
  )

  const getNoSignInGhost = React.useCallback(async (id) => {
    try {
      const response = await queryGetNoSignInGhost({ id })
      setResult(response)
      return response
    } catch (e) {
      console.error('[Error] getNoSignInGhost:', e)
      setResult(null)
      return null
    }
  }, [])

  React.useEffect(() => {
    fetchGhostList()

    if (resultId) {
      getNoSignInGhost(resultId)
    }
  }, [fetchGhostList, getNoSignInGhost, resultId])

  return {
    ghosts,
    isCreateLoading,
    errors,
    createNoSignInGhost,
    result,
    getNoSignInGhost,
  }
}
