import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'

import { ContentHead } from './ContentHead'
import { ContentSection } from './ContentSection'

type Props = {
  title?: string
  subtitle?: string
  showMemberOnly?: boolean
}
export const WaitingOther: React.FC<Props> = ({ title, subtitle, showMemberOnly }) => {
  const classes = useStyles()
  const { teamBuildingReviewMembers } = React.useContext(TeamBuildingReviewHooksContext)
  const membersCount = React.useMemo(() => teamBuildingReviewMembers?.items.length ?? 0, [teamBuildingReviewMembers])
  const answeredCount = React.useMemo(
    () => teamBuildingReviewMembers?.items.filter((item) => item.answers.length > 0).length ?? 0,
    [teamBuildingReviewMembers]
  )
  return (
    <div className={classes.root}>
      {title && subtitle && <ContentHead title={title} subtitle={subtitle} />}
      <ContentSection>
        <div className={classes.wrap}>
          <div
            style={{
              height: 138,
            }}
          >
            <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/waitingPeople.svg'} alt="" />
          </div>
        </div>
        <div className={classes.answered}>
          {showMemberOnly && `参加済み：${membersCount}`}
          {!showMemberOnly && membersCount > 0 && answeredCount > 0 && `回答済み： ${answeredCount} / ${membersCount}`}
        </div>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  wrap: {
    padding: '64px 0 32px',
    width: '100%',
    textAlign: 'center',
  },
  answered: {
    margin: '32px auto 0',
    color: '#00838C',
    fontSize: 20,
    fontWeight: 'bold',
  },
}))
