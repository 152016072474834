import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import actions from 'pages/hint/_shared/stores/actions'
import { getHint } from 'pages/hint/_shared/stores/apis'

// -----------------------------
// interface
// -----------------------------
export type IuseArticle = {
  isDraftModal: (id?: string, isPublished?: number) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useArticle = (): IuseArticle => {
  const history = useHistory()
  const context = useContext(hintContext) // MEMO: 後にReduxに置き換わりそう。
  const dispatch = useDispatch()

  /**
   * 記事が下書きならmodalをopenして、下書きでなかったら遷移する
   */
  const isDraftModal = async (id?: string, isPublished?: number): Promise<void> => {
    if (isPublished === 0) {
      if (id) {
        context.onSetShowUpdateModal()
        const tipsRes = await getHint({ id: id })
        dispatch(actions.getHint.done({ params: { id: id }, result: tipsRes }))
      }
    } else {
      history.push(`/hint/tips/${id}`)
      return
    }
  }

  return {
    isDraftModal,
  }
}
