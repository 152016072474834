import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { QueryGetKarte, MutationKarteMemberSend } from '../graphql'
import { IKarte, IKarteMemberSendInput } from '../interface'

export const karteMemberGet = async (variables: { id: string; leaderId: string }) => {
  const response: any = await API.graphql({
    query: QueryGetKarte,
    variables,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })

  return response.data.getKarte as IKarte
}

export const karteMemberSend = async (input: IKarteMemberSendInput) => {
  const response: any = await API.graphql({
    query: MutationKarteMemberSend,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })

  return response.data.karteMemberSend as IKarte
}
