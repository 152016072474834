import React from 'react'

import { MenuItem, Select } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { format } from 'date-fns'

import { teamConstants } from 'pages/teams/assets/constants'
import { Card, DefaultButton, DefaultModal, DefaultInputWithLabel, DefaultErrorModal } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { mutationUpdateTeamPlan } from 'pages/teams/hooks/graphql'
import { filterTeamPlans, getTeamPlanById } from 'pages/teams/utils/teamPlan'
import { RelatedTeamPlan, RelatedTeamPlanStatus } from 'utils/generated'

import { constants } from 'assets'

const DEFAULT_USER_LIMIT = 20

type Props = {
  teamId: string
  cardStyles?: React.CSSProperties
}

export const TeamsPlans: React.FC<Props> = (props) => {
  const { teamId, cardStyles } = props
  const classes = useStyles()
  const { currentTeam, refreshTeam, teamPlans, isAdmin } = React.useContext(HooksContext)

  const [open, setOpen] = React.useState(false)
  const [selectedPlanId, setSelectedPlanId] = React.useState<string | undefined>(undefined)
  const [states, setStates] = React.useState({
    price: '',
    limit: '',
  })
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const onPlanChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    for (const p of teamPlans) {
      if (p.id === (event.target.value as string)) {
        setSelectedPlanId(p.id)
        setStates({
          price: `${p.price.toLocaleString()}円`,
          limit: `${p.limit}人`,
        })
      }
    }
  }

  React.useEffect(() => {
    if (open) {
      if (teamPlans.length > 0) {
        const pp = filterTeamPlans(teamPlans, currentTeamPlans)

        if (pp.length > 0) {
          setSelectedPlanId(pp[0].id)
          setStates({
            price: `${pp[0].price.toLocaleString()}円`,
            limit: `${pp[0].limit}人`,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, teamPlans])

  const handleSubmit = async () => {
    if (selectedPlanId) {
      const updated = await mutationUpdateTeamPlan({
        input: {
          teamId,
          planId: selectedPlanId,
          planStatus: RelatedTeamPlanStatus.Enable,
        },
      })
      if (updated) {
        setOpen(false)
        refreshTeam()
      }
    }
  }

  // 利用停止関連
  const [openCancel, setOpenCancel] = React.useState(false)
  const [selectedCancelPlanId, setSelectedCancelPlanId] = React.useState<string | undefined>(undefined)

  const handleCancelCancelPlan = async () => {
    setOpenCancel(false)
    await new Promise((resolve) => setTimeout(resolve, 500))
    setSelectedCancelPlanId(undefined)
  }

  const handleCancelPlan = async (cancelPlanId?: string) => {
    if (cancelPlanId) {
      const updated = await mutationUpdateTeamPlan({
        input: {
          teamId,
          planId: cancelPlanId,
          planStatus: RelatedTeamPlanStatus.Disable,
        },
      })
      if (updated) {
        refreshTeam()

        setOpenCancel(false)
        await new Promise((resolve) => setTimeout(resolve, 500))
        setSelectedCancelPlanId(undefined)
      }
    }
  }

  if (!isAdmin) {
    return <></>
  }

  const currentTeamPlans = currentTeam && currentTeam.plans ? (JSON.parse(currentTeam.plans) as RelatedTeamPlan[]) : []

  const editablePlan = currentTeam?.userLimit ? currentTeam?.userLimit === DEFAULT_USER_LIMIT : true

  const modalButtonWidth = isXsDown ? 130 : 200

  return (
    <>
      <Card headerTitle={'プラン'} loading={!currentTeam} ownStyles={{ ...cardStyles }} headerMb={isSmDown ? 8 : 24}>
        <div className={classes.root}>
          <div className={classes.title}>現在利用中のプラン</div>
          {(() => {
            return currentTeamPlans
              .filter((pp) => pp.status === RelatedTeamPlanStatus.Enable)
              .map((pp, index) => {
                const plan = getTeamPlanById(teamPlans, pp.id)
                if (plan) {
                  return (
                    <div className={classes.planContainer} key={`team-plan-item-${index}`}>
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                          <div className={classes.planIndex}>プラン{index + 1}</div>
                          <div className={classes.descriptionDetailCost}>{plan.price.toLocaleString()}円/月</div>
                        </div>

                        <div className={classes.descriptionContainer}>
                          <div>{plan.name}</div>
                          <div className={classes.descriptionDetailContainer}></div>
                        </div>
                      </div>

                      {editablePlan && (
                        <div className={classes.suspensionButton}>
                          <DefaultButton
                            color="team_white"
                            style={{ minWidth: 96, fontSize: 12 }}
                            onClick={() => {
                              setSelectedCancelPlanId(plan.id)
                              setOpenCancel(true)
                            }}
                          >
                            利用中止
                          </DefaultButton>
                        </div>
                      )}
                    </div>
                  )
                } else {
                  return <React.Fragment key={`team-plan-item-${index}`}></React.Fragment>
                }
              })
          })()}

          {/* {() => {
            const pp = filterTeamPlans(teamPlans, currentTeamPlans)

            return pp.length > 0 ? (
              <DefaultButton
                color="primary"
                style={{
                  height: 30,
                  width: 160,
                  minWidth: 100,
                  fontWeight: 300,
                }}
                icon={<img src="/assets/svg/teams/icon_add_plan.svg" alt="add" className={classes.addIcon} />}
                onClick={() => setOpen(true)}
                disabled={true}
              >
                プランを追加する
              </DefaultButton>
            ) : (
              <></>
            )
          }} */}
          <div className={classes.planButton}>
            <DefaultButton
              color={
                !editablePlan || filterTeamPlans(teamPlans, currentTeamPlans).length > 0 ? 'team_main' : 'team_disabled'
              }
              style={{
                minHeight: 40,
                fontWeight: 300,
                fontSize: 12,
                lineHeight: 1.4,
              }}
              titleStyle={{ whiteSpace: 'pre-wrap' }}
              icon={<img src="/assets/svg/teams/icon_add_plan.svg" alt="add" className={classes.addIcon} />}
              onClick={editablePlan ? () => setOpen(true) : undefined}
              disabled={!editablePlan || (filterTeamPlans(teamPlans, currentTeamPlans).length > 0 ? false : true)}
            >
              {editablePlan
                ? filterTeamPlans(teamPlans, currentTeamPlans).length > 0
                  ? 'プランを追加する'
                  : '追加できるプランはありません'
                : 'プランを追加は\n運営にお問い合わせください'}
            </DefaultButton>
          </div>
        </div>
      </Card>

      {/* プラン選択用モーダル */}
      <DefaultModal open={open} setOpen={setOpen} align={'left'}>
        <>
          <div className={classes.modalTitle}>プラン選択</div>

          <div className={classes.modalSubTitle}>ご希望のプラン</div>
          <Select
            className={classes.select}
            variant={'outlined'}
            value={selectedPlanId}
            onChange={onPlanChanged}
            IconComponent={ExpandMoreIcon}
          >
            {filterTeamPlans(teamPlans, currentTeamPlans).map((p, index) => (
              <MenuItem key={`team-plan-selector-${index}`} value={p.id} className={classes.menuItem}>
                {p.name}
              </MenuItem>
            ))}
          </Select>

          <DefaultInputWithLabel label={'月額料金'} name={'price'} readOnly value={states.price} />

          <DefaultInputWithLabel
            label={'利用人数上限'}
            name={'limit'}
            readOnly
            rootStyle={{
              marginBottom: 40,
            }}
            value={states.limit}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DefaultButton
              color="team_white"
              style={{
                marginRight: 10,
                minWidth: modalButtonWidth,
              }}
              onClick={() => setOpen(false)}
            >
              キャンセル
            </DefaultButton>
            <DefaultButton
              color="team_main"
              style={{
                marginLeft: 10,
                minWidth: modalButtonWidth,
              }}
              onClick={handleSubmit}
              disabled={!selectedPlanId}
            >
              利用開始
            </DefaultButton>
          </div>
        </>
      </DefaultModal>

      {/* プラン選択用モーダル */}
      <DefaultErrorModal open={openCancel} setOpen={setOpenCancel} iconColor={'danger'}>
        <div
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 30,
          }}
        >
          {getTeamPlanById(teamPlans, selectedCancelPlanId)?.name || 'プラン'}
          の利用を中止します
        </div>
        <div
          style={{
            fontSize: 16,
            marginBottom: 20,
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
          }}
        >
          {() => {
            const nextMonth = new Date()
            nextMonth.setMonth(nextMonth.getMonth() + 1)
            return format(nextMonth, 'yyyy年MM月')
          }}
          {format(new Date(), 'yyyy年MM月')}
          までで利用中のツールが使えなくなります{'\n'}本当に利用中止してもよろしいですか？
        </div>
        <div
          style={{
            fontSize: 16,
            marginBottom: 40,
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
          }}
        >
          ＊これまでの活動データは引き続き閲覧できます。{'\n'}
          ＊再度プランの利用を開始した場合、続きからではなく新しくはじめることとなります。
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DefaultButton
            color="team_white"
            style={{
              marginRight: 10,
              minWidth: modalButtonWidth,
            }}
            onClick={handleCancelCancelPlan}
          >
            キャンセル
          </DefaultButton>
          <DefaultButton
            color="danger"
            style={{
              marginLeft: 10,
              minWidth: modalButtonWidth,
              boxShadow: 'none',
            }}
            onClick={() => handleCancelPlan(selectedCancelPlanId)}
          >
            利用停止
          </DefaultButton>
        </div>
      </DefaultErrorModal>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
  },

  title: {
    ...teamConstants.inputLabelStyles,
  },

  planContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
    borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px 0',
    },
  },

  planIndex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: 10,
    fontWeight: 'bold',
    padding: '1px 12px',
    width: 69,
    height: 30,
    marginRight: 10,
    border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
    borderRadius: 4,
    whiteSpace: 'nowrap',
  },

  descriptionContainer: {},

  descriptionDetailContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  descriptionDetailCost: {
    marginRight: 20,
  },

  suspensionButton: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },

  planButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  addIcon: {
    marginRight: 8,
  },

  // Modals
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingBottom: 16,
    borderBottom: '2px solid #F2F4F4',
    marginBottom: 30,
  },

  modalSubTitle: {
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: 14,
    marginBottom: 5,
  },

  select: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 8,
    height: 49,
    fontSize: 14,
    border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

    '& .MuiSelect-root': {
      padding: 16,
    },

    '& .MuiSvgIcon-root': {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      right: 12,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  menuItem: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
  },
}))
