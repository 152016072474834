import {
  OnboardingAction,
  OnboardingActionCategory,
  OnboardingActionComment,
  OnboardingActionFeedback,
  OnboardingDaily,
  OnboardingPost,
  OnboardingPostComment,
  OnboardingPostSecondComment,
  OnboardingRadarValues,
  OnboardingTeamMember,
  User,
} from 'utils/generated'

export interface OnboardingTeam {
  id: string
  title: string
  memberCount?: number | null
  createdAt: string
}

export interface OnboardingTeamMemberConnection {
  items: OnboardingTeamMember[]
  nextToken?: string | null
}

// export enum OnboardingDaily {
//   GREAT_SHADE = 'GREAT_SHADE', // 超絶絶好調
//   YESTERDAYS_MISTAKE = 'YESTERDAYS_MISTAKE', // 昨日のミスを引きずり中
//   NEED_TO_REFRESH = 'NEED_TO_REFRESH', // 気分を切り替えたい気分
//   WANT_TO_GO_HOME = 'WANT_TO_GO_HOME', // 早く帰って寝たい
//   NORMAL = 'NORMAL', // いたって普通
// }

// export enum OnboardingPostType {
//   ACTION = 'ACTION',
//   DAILY = 'DAILY',
// }

export interface OnboardingPostConnection {
  items: OnboardingPost[]
  nextToken?: string | null
}

export interface OnboardingPostDailyCreateInput {
  id: string
  teamId: string
  daily: OnboardingDaily
}

export interface OnboardingPostLike {
  id: string
  postId: string
  userId: string
  createdAt: string

  user?: User | undefined
}

export interface OnboardingPostLikeConnection {
  items?: OnboardingPostLike[] | undefined
  nextToken?: String | undefined
}

export interface OnboardingPostCommentConnection {
  items?: OnboardingPostComment[] | undefined
  nextToken?: string | undefined
}

export interface OnboardingPostCommentCreateInput {
  id: string
  postId: string
  text: string
}

export interface OnboardingPostCommentLike {
  id: string
  postId: string
  userId: string
  createdAt: string

  user?: User | undefined
}

export interface OnboardingPostSecondCommentConnection {
  items?: OnboardingPostSecondComment[] | undefined
  nextToken?: string | undefined
}

export interface OnboardingPostSecondCommentLike {
  id: string
  postSecondCommentId: string
  userId: string
  createdAt: string

  user?: User | undefined
}

export interface OnboardingActionFeedbackCreateInput {
  teamId: string
  actionId: string
  category: OnboardingActionCategory
  value: number
  point: number
  settedAt: string
}

export interface OnboardingActionLike {
  id: string
  actionId: string
  actionOwnerId: string
  userId: string
  createdAt: string

  user?: User
}

export interface OnboardingActionLikeConnection {
  items?: OnboardingActionLike[]
  nextToken?: string
}

export interface OnboardingActionLikeToggleInput {
  actionId: string
  actionOwnerId: string
}

export interface OnboardingActionCommentConnection {
  items?: OnboardingActionComment[]
  nextToken?: string
}

export interface OnboardingActionCommentCreateInput {
  id: string
  actionId: string
  actionOwnerId: string
  text: string
}

export interface RadarValues {
  boss: number
  vision: number
  member: number
  team: number
  other: number
}

export interface UserUpdateInput {
  nickname?: string
  imageUrl?: string
  shortDescription?: string
  setupStartedAt?: string
  description?: string
  emailNotifySetup?: number
  emailNotifyHour?: number
  emailNotifyMinute?: number
}

export interface OnboardingActionListConnection {
  items?: OnboardingAction[]
  nextToken?: string
}

export interface OnboardingActionCreateInput {
  id: string
  teamId: string
  category: OnboardingActionCategory
  mission: string
  why: string
  what: string
  how: string
  weight: number
  period: number
  point: number
  importance: number
  publishAt: string
  updatedAt: string
}

// Query

export interface QueryGetUserRequest {
  id: string
}

export interface QueryGetUserResponse {
  getUser?: User
}

export interface QueryGetOnboardingOwnTeamMemberListRequest {
  first?: number
  after?: string
}

export interface QueryGetOnboardingOwnTeamMemberListResponse {
  getOnboardingOwnTeamMemberList?: OnboardingTeamMemberConnection
}

export interface QueryGetOnboardingTeamMemberListRequest {
  teamId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingTeamMemberListResponse {
  getOnboardingTeamMemberList?: OnboardingTeamMemberConnection
}

export interface QueryGetOnboardingTeamMemberResponse {
  getOnboardingTeamMember?: OnboardingTeamMember
}

export interface QueryGetOnboardingPostListRequest {
  teamId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingPostListOfOneUserRequest {
  teamId: string
  userId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingPostListResponse {
  getOnboardingPostList?: OnboardingPostConnection
}

export interface QueryGetOnboardingPostListOfOneUserResponse {
  getOnboardingPostListOfOneUser?: OnboardingPostConnection
}

export interface QueryGetOnboardingPostResponse {
  getOnboardingPost?: OnboardingPost
}

export interface QueryGetOnboardingPostCommentListRequest {
  postId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingPostCommentListResponse {
  getOnboardingPostCommentList?: OnboardingPostCommentConnection
}

export interface QueryGetOnboardingPostSecondCommentListRequest {
  postCommentId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingPostSecondCommentListResponse {
  getOnboardingPostSecondCommentList?: OnboardingPostSecondCommentConnection
}

export interface QueryGetOnboardingActionsRequest {
  teamId: string
  userId: string
}

export interface QueryGetOnboardingActionsResponse {
  getOnboardingActions?: OnboardingAction[]
}

export interface QueryGetOnboardingActionRequest {
  id: string
  userId: string
}

export interface QueryGetOnboardingActionResponse {
  getOnboardingAction?: OnboardingAction
}

export interface QueryGetOnboardingActionCommentListRequest {
  actionId: string
  actionOwnerId: string
  first?: number
  after?: string
}

export interface QueryGetOnboardingActionCommentListResponse {
  getOnboardingActionCommentList?: OnboardingActionCommentConnection
}

export interface QueryGetOnboardingActionsFeedbacksRequest {
  teamId: string
  userId: string
}

export interface QueryGetOnboardingActionsFeedbacksResponse {
  getOnboardingActionsFeedbacks?: OnboardingActionFeedback[]
}

export interface QueryGetOnboardingActionsRadarRequest {
  teamId: string
  userId: string
}

export interface QueryGetOnboardingActionsRadarResponse {
  getOnboardingActionsRadar?: OnboardingRadarValues
}

export interface QueryGetAccountResponse {
  getAccount?: User
}

export interface QueryGetOnboardingActionListResponse {
  getOnboardingActionList?: OnboardingActionListConnection
}

// Mutation

export interface MutationOnboardingPostDailyCreateInputRequest {
  input: OnboardingPostDailyCreateInput
}

export interface MutationOnboardingPostDailyCreateInputResponse {
  createOnboardingPostComment?: OnboardingPost
}

export interface MutationCreateOnboardingPostLikeToggleResponse {
  createOnboardingPostLikeToggle?: OnboardingPostLike
}

export interface MutationCreateOnboardingPostCommentResponse {
  createOnboardingPostComment?: OnboardingPostComment
}

export interface MutationCreateOnboardingPostCommentLikeToggleResponse {
  createOnboardingPostCommentLikeToggle?: OnboardingPostCommentLike
}

export interface MutationCreateOnboardingPostSecondCommentResponse {
  createOnboardingPostSecondComment?: OnboardingPostSecondComment
}

export interface MutationCreateOnboardingPostSecondCommentLikeToggleResponse {
  createOnboardingPostSecondCommentLikeToggle?: OnboardingPostSecondCommentLike
}

export interface MutationCreateOnboardingActionCommentRequest {
  input: OnboardingActionCommentCreateInput
}

export interface MutationCreateOnboardingActionCommentResponse {
  createOnboardingActionComment?: OnboardingActionComment
}

export interface MutationCreateOnboardingActionFeedbackResponse {
  createOnboardingActionFeedback?: OnboardingActionFeedback
}

export interface MutationCreateOnboardingPostActionResponse {
  createOnboardingPostAction?: OnboardingPost
}

export interface MutationUpdateAccountRequest {
  input: UserUpdateInput
}

export interface MutationUpdateAccountResponse {
  updateAccount?: User
}

export interface MutationCreateOnboardingActionRequest {
  input: OnboardingActionCreateInput
}

export interface MutationCreateOnboardingActionResponse {
  createOnboardingAction?: OnboardingAction
}

export interface MutationUpdateOnboardingActionResponse {
  updateOnboardingAction?: OnboardingAction
}

export enum OnboardingTeamMemberStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Stopped = 'STOPPED',
  Rejected = 'REJECTED',
}

export type OnboardingTeamMemberDeleteInput = {
  teamId: string
  userId: string
}

export enum OnboardingActionDeadline {
  ALERT,
  WARNING,
  DEFAULT,
}
