import { API, graphqlOperation } from '@aws-amplify/api'

import { MutaionUpdateSelfCheck } from './graphql'

const updateSelfcheckResult = async (data: any) => {
  const response: any = await API.graphql(graphqlOperation(MutaionUpdateSelfCheck, { input: { data: data } }))

  const { updateSelfCheck } = response.data

  return updateSelfCheck
}

export default updateSelfcheckResult
