import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TextField } from '@material-ui/core'
import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'

import { ButtonNew as Button } from 'components/ButtonNew'
import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingActionsActions } from 'pages/onboarding/hooks/action'
import { useOnboardingPost } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPostType } from 'utils/generated'

import * as constants from 'assets/constants'


type PickInput = Pick<IFormCharCountInput, 'editPost'>

const Index: React.FC<WithStyles<typeof useStyles>> = ({ classes }) => {
  // deps
  const { postId } = useParams<{ postId: string }>()
  const { post } = useOnboardingPost(postId)

  // BL
  const { updatePostHandler } = useOnboardingActionsActions()

  // form control
  const maxLength = 10000
  const inputName: keyof PickInput = 'editPost'
  const { register, handleSubmit, errors, watch, setValue } = useForm<PickInput>({ mode: 'onChange' })
  const watchInput = watch(inputName, '')

  const history = useOnbHistory()

  useEffect(() => {
    if (post && post.comment) {
      setValue(inputName, post.comment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post])

  const onSubmit = async (data: PickInput) => {
    if (post && data.editPost && post.label) {
      await updatePostHandler(post.id, data.editPost, post.label)
    }
    history.goBack()
    window.scrollTo(0, 0)
  }

  const isValid = () => {
    return watchInput && !errors.editPost && watchInput !== post?.comment ? true : false
  }

  return (
    <>
      <TitleWithBack title="投稿編集" />
      {post && post.comment && post.type === OnboardingPostType.Action && post.action && post.label ? (
        <div className={classes.container}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <TextField
              autoFocus
              name={inputName}
              id="update-comment-input"
              className={classes.textArea}
              placeholder={'コメントを追加する'}
              multiline
              rows={9}
              maxRows={9}
              inputRef={register({ required: true, maxLength })}
            />
            <FormCharCount length={watchInput.length} maxLength={maxLength} />
            <Button body="保存する" disabled={!isValid()} noShadow style={{ marginTop: 40 }} submit />
            <div className={classes.cancelWrapper}>
              <div className={classes.cancel} onClick={() => history.goBack()}>
                編集をキャンセル
              </div>
            </div>
          </form>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      margin: '26px 16px 0',
    },
    textArea: {
      backgroundColor: constants.COLOR_WHITE,
      width: '100%',
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 8,
      boxSizing: 'border-box',
      '& .MuiInputBase-root': {
        padding: 16,
      },
      '& .MuiInputBase-input': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
      },
      '& .MuiInput-underline:before': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:after': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
    cancelWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 34,
    },
    cancel: {
      display: 'inline-block',
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
  })

export default withStyles(useStyles)(Index)
