import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '800px',
    borderRadius: '0 0 3px 3px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  content: {
    backgroundColor: constants.COLOR_WHITE,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  route?: 'profile'
}

// -----------------------------
// Component
// -----------------------------
export const TabPanel = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box
        className={classes.content}
        style={{
          borderTop: props.route !== 'profile' ? `solid 2px ${constants.COLOR_MAIN}` : '',
          borderRadius: props.route === 'profile' ? '30px 30px 0 0 ' : '',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
