// THIS DROPDOWN COMPONENT IS FOR ADMIN'S ACTION-DETAIL (/onboardgin/:teamId/actions/userId/detail/actionId).
// basically ported from 'onboarding/components/dropdown-new/, though modified some. Consider merge them in the future.

import React, { useState, useRef } from 'react'

import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Theme } from '@material-ui/core'

import * as constants from 'assets/constants'

type DropdownItem = {
  label: string
  onClickItem: () => void
}

interface Props {
  items: DropdownItem[]
  keyPrefix: string
  anchorIcon: string // ref to the icon asset
  anchorText?: string
  customStyles?: { border: string; borderRadius: string }
}

/**
 * This DropDown consists of two parts:
 * icon(or text) anchor, which means clicable part,
 * and the popping-up DropBody.
 */
export const DropDownActions: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((prevOpen) => !prevOpen)

  // when the user clicks outside of anchor, close.
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const anchorRef = useRef<HTMLDivElement>(null)
  const anchorContent = <img src={require(`./assets/${props.anchorIcon}.svg`).default} alt="anchor" />

  return (
    <>
      <div
        ref={anchorRef}
        className={props.anchorIcon ? classes.iconWrapper : classes.textWrapper}
        aria-label="dropdown-list"
        aria-controls={open ? 'dropdown-list-grow' : undefined}
        aria-haspopup="true"
        onClick={toggle}
      >
        {anchorContent}
      </div>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition placement="bottom-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paper} elevation={3} classes={{ elevation3: classes.elevation3 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {props.items.map((item, idx) => (
                    <SelectItem key={props.keyPrefix + idx} item={item} />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const SelectItem = ({ item }: { item: DropdownItem }) => {
  const styles = useItemStyle()

  const handleClick = () => item.onClickItem()

  return (
    <MenuItem onClick={handleClick} className={styles.menuItem}>
      <div className={item.label === '削除' ? styles.itemLabelDanger : styles.itemLabel}>{item.label}</div>
    </MenuItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },

  textWrapper: {
    // maybe necessary when put texts on anchor
  },

  paper: {
    borderRadius: 8,
  },

  elevation3: {
    boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
  },
}))

const useItemStyle = makeStyles((theme: Theme) => ({
  menuItem: {
    display: 'flex',
    width: 200,
    padding: '8px 24px',
    alignItems: 'center',
  },

  itemLabel: {},

  itemLabelDanger: {
    color: constants.COLOR_RED,
  },
}))
