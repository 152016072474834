import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'

import ModalStartSetup from './components/FormModalStartSetup.component'
import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const handleStartSetup = () => {
    setOpen(true)
  }

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handlePageSignup = () => {
    props.history.push(constants.PAGE_SIGNUP, { transition: pages.PAGE_SETUP_LANDING })
    window.scrollTo(0, 0)
  }

  const handlePageSignin = () => {
    props.history.push(constants.PAGE_SIGNIN + '?r=' + pages.PAGE_SETUP_LANDING)
    window.scrollTo(0, 0)
  }

  const handlePageSetupHome = () => {
    props.history.push(pages.PAGE_SETUP_HOME)
    window.scrollTo(0, 0)
  }

  const hasSetupStartedAt = React.useMemo(() => {
    return !!props.user.setupStartedAtList
  }, [props.user])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && (
          <>
            <div className="__container">
              <div className="__content">
                <div className="__top">
                  <div className="__subTitle">
                    <span>初期のチーム作りを支援する</span>
                  </div>
                  <h3>セットアップ</h3>

                  <div className="__landing_front_wrapper">
                    <img
                      className="__landing_front"
                      src={process.env.PUBLIC_URL + '/assets/svg/front_set_up.svg'}
                      alt={'front_set_up'}
                    />
                    <Link className="__landing_front_badge" to="/hint/tips/da2bb220-6e9a-427c-89ee-7290eb46bbb4">
                      <img src="/assets/svg/setup/badge.svg" alt="使い方はこちら" width="90" height="90" />
                    </Link>
                  </div>

                  <div className="__buttons">
                    {props.user?.id ? (
                      <Button
                        bgColor={constants.COLOR_SECOND}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>利用開始</div>}
                        onClick={hasSetupStartedAt ? handlePageSetupHome : handleStartSetup}
                      />
                    ) : (
                      <>
                        <Button
                          bgColor={constants.COLOR_BLACK}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>無料登録</div>}
                          onClick={handlePageSignup}
                        />
                        <Button
                          bgColor={constants.COLOR_SECOND}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>ログイン</div>}
                          onClick={handlePageSignin}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="__description">
                  <div className="__title">セットアップとは？</div>
                  <div className="__body">
                    新任マネジャーは担うことが多く、混乱が生じることも多くあります。セットアップは、多数の業務がある新任マネジャーが業績向上に向けて集中するために、人・組織面で取り組むべきことを最適なタイミングでプッシュをしてくれるツールになります。
                  </div>
                  <div className="__body">6つの失敗パターンを打破し機能するチーム作りツール：セットアップ（6分）</div>
                  <div className="__video">
                    <iframe
                      src="https://player.vimeo.com/video/536586771"
                      title="setup"
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                    ></iframe>
                  </div>
                  <div className="__title">どんな人におすすめ？​</div>
                  <div className="__body">
                    複数メンバーをマネジメントする経験が浅い中で、担当チームを成果に導くことが求められている方に最適です。
                  </div>
                </div>
                <div className="__functions">
                  <div className="__description">
                    <div className="__title">機能紹介</div>
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/setup/setup1.png'} alt={'setup1'} />
                    </div>
                    <div className="__body">
                      新任マネジャーにとって、今取り組むことが望まれるアクション別に並んでいます。目的や達成基準なども明示されているため、行動に移しやすいです。
                      繰り返し発生するアクションや、達成すると新しい上位アクションが出てくるものもあります。​
                    </div>
                  </div>

                  <div className="__line" />

                  <div className="__description">
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/setup/setup2.png'} alt={'setup2'} />
                    </div>
                    <div className="__body">
                      達成済になったリストには達成時に付けた自己評価などが表示されています。​
                      ​達成した後でもこのリストを振り返ることにより、自分の得意不得意が把握でき、その後のマネジメント業務に活かすことができるようになります。
                    </div>
                  </div>

                  <div className="__line" />

                  <div className="__description">
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/setup/setup3.png'} alt={'setup3'} />
                    </div>
                    <div className="__body">
                      アクションの進捗具合は対象ごとにレーダーチャートで表示されます。​
                      自身のアクションリストの進捗に加え、組織の現状・成長も合わせて確認できます。
                    </div>
                  </div>
                </div>

                <div className="__buttons">
                  {props.user?.id ? (
                    <Button
                      bgColor={constants.COLOR_SECOND}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      body={<div>利用開始</div>}
                      onClick={handleStartSetup}
                    />
                  ) : (
                    <>
                      <Button
                        bgColor={constants.COLOR_BLACK}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>無料登録</div>}
                        onClick={handlePageSignup}
                      />
                      <Button
                        bgColor={constants.COLOR_SECOND}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>ログイン</div>}
                        onClick={handlePageSignin}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            <ModalStartSetup open={open} handleClose={handleClose} />
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
      backgroundColor: constants.COLOR_SECOND_LIGHT,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '& .__buttons': {
            display: 'flex',
            marginTop: '8px',
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
          },

          '& .__top': {
            '& .__subTitle': {
              textAlign: 'center',
              '& span': {
                // borderBottom: '1px solid',
                background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
                fontWeight: 'bold',
              },
            },
            '& h3': {
              textAlign: 'center',
              fontSize: '24px',
              marginTop: '4px',
            },
          },
          '& .__landing_front_wrapper': {
            position: 'relative',
            paddingTop: '30px',
          },
          '& .__landing_front': {
            width: '100%',
          },
          '& .__landing_front_badge': {
            position: 'absolute',
            right: 0,
            top: 0,
            '& img': {
              display: 'block',
            },
          },
          '& .__title': {
            fontSize: '20px',
            color: constants.COLOR_MAIN_DARK,
            margin: '16px 0',
            width: '100%',
            fontWeight: 'bold',
          },

          '& .__description': {
            padding: '16px',
            '& .__image': {
              '& img': {
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              },
            },

            '& .__body': {
              lineHeight: '24px',
              padding: '16px 0',
            },
            '& .__video': {
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              marginBottom: 42,
              '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            },
          },

          '& .__line': {
            margin: '16px 0',
            borderBottom: '1px solid #e3e3e3',
          },
          '& .__functions': {
            backgroundColor: constants.COLOR_GRAY,
            marginBottom: 16,
            '& .__description': {
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
              },
              '& .__image': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },

              '& .__body': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
