import { API, graphqlOperation } from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'

import { QueryGetAccount } from './graphql'

const defaultAdditionalParams = {
  setupStartedAt: null,
  setupStartedAtList: null,
  basePeriod: 0,
  weeks: -1,
}

const getAccount = async () => {
  let authRes
  try {
    authRes = await Auth.currentSession()
  } catch (err) {
    return {}
  }
  const cognitoIdToken = authRes.getIdToken().payload
  const cognitoInfo = {
    id: cognitoIdToken.sub,
    email: cognitoIdToken.email,
    username: cognitoIdToken.nickname, // TODO remove this username
    nickname: cognitoIdToken.nickname,
  }

  const res: any = await API.graphql(graphqlOperation(QueryGetAccount))
  const { getAccount: account } = res.data

  if (!account) {
    return {
      ...defaultAdditionalParams,
      ...cognitoInfo,
    }
  }

  return {
    ...defaultAdditionalParams,
    ...cognitoInfo,
    ...account,
  }
}

export default getAccount
