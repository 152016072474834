import React, { useContext, useEffect } from 'react'

import { useTheme, useMediaQuery } from '@material-ui/core'

import { GP, teamIdPath } from 'pages/ghost/assets/pages'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'

import { GrayBackGround, BackResponsiveCard, PageTitle, LeadText, ButtonForGhost } from '../components/atoms'
import { WhiteGhostImg, ButtonContainer } from '../components/modules'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { PageManagerContext } from '../contexts/pageManger'

type Props = { style?: React.CSSProperties }

export const StartedMessagePage: React.FC<Props> = (props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const { team } = useContext(ghostTeamContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { jumpTo } = useContext(PageManagerContext)

  const toHome = () => {
    jumpTo(`/teams/${team?.masterTeamId}`)
  }

  useEffect(() => {
    if (team && teamMember) jumpTo(teamIdPath(GP.waiting, team.id))
  }, [jumpTo, team, teamMember])

  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard>
          <PageTitle>次回までお待ちください</PageTitle>
          <LeadText ownStyles={isSmDown ? { margin: '23px 0 0' } : { margin: '29px 0 0' }}>
            チーム内ですでにゲームが進行中です。
            <br />
            次回のゲーム開始まで待機してください。
          </LeadText>
          <WhiteGhostImg
            ownStyles={{
              position: 'fixed',
              bottom: isXsDown ? 72 : 86,
              left: 'calc(50% - 82px)',
              display: 'inline',
              width: 165,
            }}
          />
          <ButtonContainer
            fixed
            buttons={[
              <ButtonForGhost
                key="StartedMessagePage"
                buttonBodyColor="green"
                bodyText="HOMEに戻る"
                onClick={toHome}
                isResizeBasic
              />,
            ]}
          />
        </BackResponsiveCard>
      </GrayBackGround>
    </>
  )
}
