import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import * as constants from 'assets/constants'

type DropdownItem = {
  label: string
  value: string
}

interface IOwnProps {
  className?: string
  items: DropdownItem[]
  value?: string
  onChange: (value: string) => void
  keyPrefix: string
}

type Props = IOwnProps

export const Dropdown: React.FC<Props> = ({ className, items, value, onChange, keyPrefix }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.container}${className ? ` ${className}` : ''}`}>
      <select className={classes.select} value={value} onChange={(e) => onChange(e.target.value)}>
        {items.map((item, index) => (
          <option key={`${keyPrefix}-${index}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: constants.COLOR_MAIN,
    minWidth: 30,
    minHeight: 30,
  },
  select: {
    flex: 1,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    padding: '14px 16px',
    width: '100%',
    fontSize: 14,
    color: constants.TEXT_GRAY_DARK,
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/Icons-24px-ArrowBottom.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '24px 24px',
    backgroundPosition: 'right 10px center',
    fontFamily: 'sans-serif',

    '&:focus': {
      outline: 'none',
    },
  },
  appearance: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 8,
  },
}))
