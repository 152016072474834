import { TeamMemberRole } from 'utils/generated'

interface MovieSource {
  id: number
  movieOwnerId: string // 'COMMON' or teamId を入力
  title: string
  role: TeamMemberRole // TeamMemberRole.Member or TeamMemberRole.Leader を入力
  src: string
}
export const movie_source: MovieSource[] = [
  {
    id: 15,
    movieOwnerId: 'COMMON',
    title: 'キックオフ対話動画',
    role: TeamMemberRole.Member,
    src: 'https://player.vimeo.com/video/920939234?h=505b029afe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
  {
    id: 14,
    movieOwnerId: 'COMMON',
    title: 'カルテ対話動画',
    role: TeamMemberRole.Member,
    src: 'https://player.vimeo.com/video/916932112?h=26fe9f8487&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
  {
    id: 13,
    movieOwnerId: 'COMMON',
    title: 'リフレクション対話動画',
    role: TeamMemberRole.Member,
    src: 'https://player.vimeo.com/video/908044290?h=123c63bed5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  },
  {
    id: 1,
    movieOwnerId: 'COMMON',
    title: 'オバケ対話動画',
    role: TeamMemberRole.Member,
    src: 'https://player.vimeo.com/video/877320780?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479',
  },
  {
    id: 2,
    movieOwnerId: 'COMMON',
    title: 'チームのエンゲージメントを高めるビジョンシリーズ～STORY～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/642212461',
  },
  {
    id: 3,
    movieOwnerId: 'COMMON',
    title: 'チームのエンゲージメントを高めるビジョンシリーズ～WHY～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/596337814?h=262476f826',
  },
  {
    id: 4,
    movieOwnerId: 'COMMON',
    title: 'チームのエンゲージメントを高めるビジョンシリーズ～WHAT～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/570091470',
  },
  {
    id: 5,
    movieOwnerId: 'COMMON',
    title: 'チームのエンゲージメントを高めるビジョンシリーズ～WILL～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/642216289',
  },
  {
    id: 6,
    movieOwnerId: 'COMMON',
    title: 'チームのエンゲージメントを高めるビジョンシリーズ～DRAW～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/596334101?h=8618f9c23c',
  },
  {
    id: 7,
    movieOwnerId: 'COMMON',
    title: '無意識に決めつけてしまっていませんか？～アンコンシャス・バイアス①～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/582865262',
  },
  {
    id: 8,
    movieOwnerId: 'COMMON',
    title: '無意識に決めつけてしまっていませんか？～アンコンシャス・バイアス②～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/582865512',
  },
  {
    id: 9,
    movieOwnerId: 'COMMON',
    title: '傾聴力～聴き方で相手の想いを引き出す～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/596339014?h=8a247f88b3',
  },
  {
    id: 10,
    movieOwnerId: 'COMMON',
    title: '相手を承認する',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/642214365',
  },
  {
    id: 11,
    movieOwnerId: 'COMMON',
    title: '相手を動かす伝え方～IメッセージとYOUメッセージ～',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/570086726',
  },
  {
    id: 12,
    movieOwnerId: 'COMMON',
    title: '期待を伝えることのメリット（ピグマリオン効果）',
    role: TeamMemberRole.Leader,
    src: 'https://player.vimeo.com/video/570088905',
  },
]
