import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { createGhostTeam, getGhostTeamByMaster } from 'pages/ghost/api/team/handlers'
import { useGhostTeam } from 'pages/ghost/contexts/ghostTeam'
import { useHistoryStore } from 'pages/ghost/contexts/history'
import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { DescriptionTools } from 'pages/teams/pages/list/components'

import { GhostDashboardTabs } from './components/GhostDashboardTabs'

import { replacePathParams } from 'assets/history'

export const Ghost: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { historyList, loading } = useHistoryStore(teamId, 'dummy-ghost-teamId')
  const { team } = useGhostTeam(teamId)

  // 進行中のオバケがある場合、リストから除外する。
  const checkedActiveList =
    historyList[historyList.length - 1]?.history?.id === team?.id ? historyList.slice(0, -1) : historyList.slice()

  const filterHistoryList = checkedActiveList.reverse().filter((item) => {
    return item.research?.second && item.research?.second.length > 0
  })
  const ghostHistoryArray = filterHistoryList.map((item) => {
    return item.history
  })

  const culcedGhostIds = ghostHistoryArray.map((item) => {
    return item?.ghostId
  })

  const GhostIdCulcFunc = (ghostId: string | undefined | null) => {
    return culcedGhostIds.filter((n) => n === ghostId).length
  }

  const uniqueGhostData = filterHistoryList.filter(
    (item, index, self) => index === self.findIndex((t) => t?.history?.ghostId === item?.history?.ghostId)
  )

  const { isAdmin, isLeader } = React.useContext(HooksContext)
  const canGhostTeamCreate = isAdmin || isLeader
  const handleOpenGhost = async () => {
    const ghostTeam = await getGhostTeamByMaster({ masterTeamId: teamId }) // it is usual to use hooks(fetchGhostTeamByMaster and team in useGhostTeam), but only this page use it because setStateAction is async process not to update before using value.
    if (ghostTeam) {
      window.open(replacePathParams(`/ghost/${ghostTeam.id}/waiting`, { teamId }), '_team-ghost')
    } else {
      if (canGhostTeamCreate) {
        const newGhostTeam = await createGhostTeam({ input: { masterTeamId: teamId } }) // in this API if ghostTeam already exist, return it.
        window.open(replacePathParams(`/ghost/${newGhostTeam.id}/waiting`, { teamId }), '_team-ghost')
      } else {
        alert('おばけゲーム立ち上げを行うため、一番最初はリーダーの方がこちらをクリックしてください')
      }
    }
  }

  const firstGhostCount = GhostIdCulcFunc(uniqueGhostData[0]?.history?.ghostId)

  const [hasGhostDate, hasSetGhostDate] = React.useState<boolean>(false)

  useEffect(() => {
    hasSetGhostDate(!!firstGhostCount)
  }, [historyList, firstGhostCount])

  if (loading) return <LoadingCircular loading={loading} />

  // オバケを過去一度も完了したことがない場合
  if (!hasGhostDate) {
    return (
      <DescriptionTools
        title="チーム登録が完了したらオバケをはじめましょう。"
        recommendedTime="（推奨時間：45分）"
        description={
          '組織で問題が起こっている時、その要因を特定の人でなく\n「関係性や環境から生まれた目に見えない象徴」として扱うのが「オバケ」です。\nエンゲージメント向上のために、チームに存在する「オバケ」について皆で対話し、一緒に解決の糸口を探しましょう！'
        }
        buttonLabel="オバケを始める"
        handleOpenTool={handleOpenGhost}
      />
    )
  }

  return <GhostDashboardTabs />
}
