import React from 'react'

import { Ghost, TeamSummaryBuilding, GhostHistoryConnection, TeamBuildingReviewSummary } from 'utils/generated'
import { Team } from 'utils/generated'

import { queryGetTeamSummaryBuilding, queryGetTeamSummaryGhosts } from './graphql'

export const useSummaryTeamBuilding = (teamId?: string) => {
  const [summaryBuilding, setSummaryBuilding] = React.useState<TeamSummaryBuilding | undefined>(undefined)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamSummaryBuilding({
        teamId,
      })
      if (response) {
        setSummaryBuilding(response)
      }
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { summaryBuilding, loading, loaded, error, refresh } as const
}
export type SortTeamSummryBuilding = TeamSummaryBuilding &
  GhostHistoryConnection & {
    createdAt: string
    historyList: GhostHistoryConnection[]
    karteCount: number | null
    latestAnketDate: string | null
  } & TeamBuildingReviewSummary
export type SortedAddSummaryTeams = { index: number } & Team & {
    summaryBuilding: SortTeamSummryBuilding | undefined
  } & { leader: string | undefined }

export const useSummaryTeamGhosts = (teamId?: string) => {
  const [summaryGhosts, setSummaryGhosts] = React.useState<Ghost[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamSummaryGhosts({
        masterTeamId: teamId,
        limit: 3,
      })

      if (response) {
        setSummaryGhosts(response)
      }
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { summaryGhosts, loading, loaded, error, refresh } as const
}
