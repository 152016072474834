import React from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { Box, Container, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTeamBuildingReviewLatest } from 'services/api/review'

import { GhostContext, useGhostStore } from 'pages/ghost/contexts/ghost'
import { HistoryContext, useHistoryStore } from 'pages/ghost/contexts/history'
import { ModalErrorContext, useModalErrorContext } from 'pages/teams/contexts'
import { HooksContext, useHooksContext } from 'pages/teams/contexts/HooksContext'
import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'

import { ModalInfomation } from '../../../../components/Modal'
import { HooksErrorModal } from '../DefaultModal'
import { Header } from '../Header'
import { LoadingCircular } from '../LoadingCircular'
import { Menus, MenuWidth, TeamMenuCategories, TeamMenus } from '../Menus'

import { constants, Pages } from 'assets'

interface Props {
  children: React.ReactElement
  loading?: boolean
}

interface RouteParams {
  teamId?: string
}

const noSidePaddingPages = [
  Pages.TeamsList,
  Pages.TeamsNew,
  Pages.TeamsNewByCsv,
  Pages.TeamsMembers,
  Pages.TeamsMembersAdd,
  Pages.TeamsMembersAddByCsv,
  Pages.TeamsMembersEdit,
  Pages.TeamsEdit,
]

export const DefaultContainer: React.FC<Props> = (props) => {
  const { children, loading } = props

  const { teamId } = useParams<RouteParams>()
  const classes = useStyles({ menuWidth: MenuWidth, teamId })

  const location = useLocation()
  const pathNames = location.pathname.split('/')

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const getMenuCategory = (paths: string[]): [TeamMenuCategories | undefined, TeamMenus | undefined] => {
    for (const path of paths) {
      switch (path) {
        case 'about':
          return [TeamMenuCategories.ManageTeams, TeamMenus.About]
        case 'tools':
          return [TeamMenuCategories.ManageTeams, TeamMenus.Tools]
        case 'plan':
          return [TeamMenuCategories.ManageTeams, TeamMenus.Plan]
        case 'list':
          return [TeamMenuCategories.ManageTeams, TeamMenus.List]

        case 'members':
          return [TeamMenuCategories.ManageMembers, TeamMenus.Members]
        case 'members-add':
        case 'members-add-by-csv':
          return [TeamMenuCategories.ManageMembers, TeamMenus.MembersAdd]

        case 'corp':
          return [TeamMenuCategories.Setting, TeamMenus.Corp]
        case 'payment':
          return [TeamMenuCategories.Setting, TeamMenus.Payment]
        case 'admin':
          return [TeamMenuCategories.Setting, TeamMenus.Admin]
      }
    }

    return [undefined, undefined]
  }
  const [category, menu] = getMenuCategory(pathNames)

  const {
    currentUser,
    refreshCurrentUser,
    currentTeam,
    refreshTeam,
    loadingTeam,
    currentTeamMember,
    currentTeamMemberRole,
    refreshCurrentTeamMember,
    currentTeamAdmin,
    refreshTeamAdmin,
    teamPlans,
    loadingTeamMember,
    loadedTeamBuilding,
    teamBuilding,
    loadingTeamBuilding,
    refreshTeamBuilding,
    isAdmin,
    isLeader,
    isMember,
  } = useHooksContext(teamId)

  const { setTeamId, setReviewId, refreshTeamBuildingReviews } = React.useContext(TeamBuildingReviewHooksContext)
  const { teamBuildingReviewLatest } = useTeamBuildingReviewLatest(teamId ?? '')

  const { onErrorModal, onCloseErrorModal, openErrorModal, setOpenErrorModal, errorModalErrors } =
    useModalErrorContext()
  const ghostStore = useGhostStore(teamId)
  const historyStore = useHistoryStore(teamId, 'dummy-ghost-teamId')

  React.useEffect(() => {
    if (teamId) {
      setTeamId(teamId)
    }
    if (teamBuildingReviewLatest) {
      setReviewId(teamBuildingReviewLatest.id)
      refreshTeamBuildingReviews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, teamBuildingReviewLatest])

  // new RegExp によって変数と正規表現を組み合わせる。
  const regForMemberEdit = new RegExp(`${teamId}-.+/edit`)
  const regForTeamsEdit = new RegExp('/teams/:teamId/edit/.+$')
  // <Box> の style を変更するために使用。
  const isNoSidePaddingPage = teamId
    ? noSidePaddingPages.includes(
        location.pathname
          .replace(`/${teamId}/`, '/:teamId/')
          .replace(regForMemberEdit, ':teamMemberId/edit')
          .replace(regForTeamsEdit, Pages.TeamsEdit) as Pages
      )
    : false

  return (
    <>
      <ModalInfomation toolName={'teamBuilding'} version={1} userId={currentUser?.id} isActive={true} />
      <ModalErrorContext.Provider
        value={{
          onErrorModal,
          onCloseErrorModal,
        }}
      >
        <HooksContext.Provider
          value={{
            currentUser,
            refreshCurrentUser,
            currentTeam,
            refreshTeam,
            loadingTeam,
            currentTeamMember,
            currentTeamMemberRole,
            refreshCurrentTeamMember,
            currentTeamAdmin,
            refreshTeamAdmin,
            teamPlans,
            teamBuilding,
            loadingTeamBuilding,
            loadedTeamBuilding,
            refreshTeamBuilding,
            loading: loadingTeamMember,
            isAdmin,
            isLeader,
            isMember,
          }}
        >
          <GhostContext.Provider value={ghostStore}>
            <HistoryContext.Provider value={historyStore}>
              <div>
                <Header teamId={teamId} />

                <Container maxWidth={teamId ? false : 'md'} className={classes.root}>
                  {teamId && isMdUp ? (
                    <div className={classes.menuWrapper}>
                      <div className={classes.menu}>
                        <Menus teamId={teamId} activeMenuCategory={category} activeMenu={menu} />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div id="scroll-container" className={classes.content}>
                    <Box className={`${classes.box} ${isNoSidePaddingPage ? 'noSide' : ''}`}>
                      {!loading && !loadingTeamMember ? (
                        children
                      ) : (
                        <LoadingCircular loading={loading || loadingTeamMember} />
                      )}
                    </Box>
                  </div>
                  {/* <PageOrders /> */}
                </Container>

                <div className={classes.footer}>
                  {teamId && !isMdUp ? (
                    <Menus teamId={teamId} activeMenuCategory={category} activeMenu={menu} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </HistoryContext.Provider>
          </GhostContext.Provider>
        </HooksContext.Provider>
        <HooksErrorModal open={openErrorModal} setOpen={setOpenErrorModal} errors={errorModalErrors} />
      </ModalErrorContext.Provider>
    </>
  )
}

interface StyleProps {
  menuWidth: number
  teamId?: string
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      padding: '72px 0 0',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },

    menuWrapper: {
      width: MenuWidth,
      position: 'relative',
    },
    menu: {
      width: MenuWidth,
      height: '100%',
      position: 'fixed',
    },

    content: (props: StyleProps) => ({
      flex: 1,
      minWidth: 0,
      minHeight: `calc(95vh - ${constants.HEADER_HEIGHT}px)`,
      maxHeight: `calc(95vh - ${constants.HEADER_HEIGHT}px)`,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
    }),

    box: (props: StyleProps) => ({
      padding: '42px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 16px 107px',
        '&.noSide': {
          padding: '16px 0 107px',
        },
      },
    }),

    footer: {
      width: '100%',
    },
  }),
  { name: 'DefaultContainer' }
)
