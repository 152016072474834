import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  important: string
  personality: string
  themeColor: string
}

type StyleProps = {
  themeColor: string
}

export const AnswerNote: React.FC<Props> = ({ important, personality, themeColor }) => {
  const classes = useStyles({ themeColor })

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.cardTitle}>あなたの大事にしていることは？</div>
        <p className={classes.cardBody}>{important}</p>
      </div>
      <div className={classes.card}>
        <div className={classes.cardTitle}>あなたはどんな人？</div>
        <p className={classes.cardBody}>{personality}</p>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      gap: 40,
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 24,
        marginBottom: 24,
      },
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 24,
      backgroundColor: '#fff',
      borderRadius: 8,
      width: '100%',
    },

    cardTitle: ({ themeColor }) => ({
      color: themeColor,
      fontSize: 20,
      fontWeight: 900,
    }),

    cardBody: {
      margin: 0,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      lineHeight: '28px',
    },
  }),
  { name: 'AnswerNote' }
)
