import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { HashLink } from 'react-router-hash-link'

import * as constants from 'assets/constants'

type StyleProps = {
  hoverColor?: string
}

type Props = {
  page: string
  id: string
  children: React.ReactNode
  style?: React.CSSProperties
  smooth?: boolean
} & StyleProps

export const ScrollToId: React.FC<Props> = ({ page, id, children, style, smooth, hoverColor }) => {
  const classes = useStyles({ hoverColor })

  return (
    <HashLink
      to={`${page}#${id}`}
      smooth={smooth ?? true}
      className={classes.iconButtonWrapper}
      style={{ width: '100%' }}
    >
      <button className={classes.root} style={{ ...style }}>
        {children}
      </button>
    </HashLink>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    appearance: 'none',
    color: constants.TEXT_GRAY_DARK,
    '&:hover': {
      color: props.hoverColor ?? undefined,
    },
  }),
}))
