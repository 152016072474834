import React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { HooksContext, BuildingHooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import {
  TeamBuilding,
  TeamBuildingStatus,
  TeamBuildingV1ResultQ3,
  ResultQ3RankingItem,
  TeamBuildingMember,
} from 'utils/generated'

import {
  Button,
  QuestionContainer,
  InputTextarea,
  FormError,
  ButtonContainer,
  WaitingForLeader,
  BackToIndex,
  UsefulContainer,
  FormLabel,
} from '../../components'
import { SelectItem as ISelectItem } from '../../components/SelectItems'

import { constants } from 'assets'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

interface Input {
  result: string
}

export const Q3Adjust: React.FC<Props> = (props) => {
  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const { control, errors, handleSubmit } = useForm<Input>()
  const [rankings, setRankings] = React.useState<ResultQ3RankingItem[]>([])
  const [displayedValue, setDisplayedValue] = React.useState<string>('')
  const [editValue, setEditValue] = React.useState<string>('')
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  //for editPage
  const [editPageOpen, setEditPageOpen] = React.useState<boolean>(false)
  const handleEditPage = () => {
    setEditPageOpen((prev) => !prev)
  }

  const onPreview = async () => {
    if (teamBuilding && teamBuilding.resultQ3) {
      const resultQ3 = JSON.parse(teamBuilding.resultQ3)

      const updated = await updateTeamBuilding({
        id: props.match.params.teamId,
        resultQ3: JSON.stringify({
          ...resultQ3,
          answer: editValue,
        }),
      })

      return !!updated
    }
  }

  const onSubmit = async () => {
    const updated = await onPreview()
    if (updated) {
      await updateTeamBuilding({
        id: props.match.params.teamId,
        status: TeamBuildingStatus.Q3End,
        timeQ3End: new Date().toISOString(),
      })
    }
  }

  const handleOnSave = () => {
    onPreview()
    handleEditPage()
  }
  const handleOnCancel = () => {
    setEditValue(displayedValue)
    handleEditPage()
  }

  const classes = useStyles({ isOpen: editPageOpen })

  React.useEffect(() => {
    if (teamBuilding && teamBuilding.resultQ3) {
      const resultQ3 = JSON.parse(teamBuilding.resultQ3) as TeamBuildingV1ResultQ3
      if (!(resultQ3.rankings.length > 0)) {
        const rankings = extractResult(resultQ3.selected, teamBuildingMembers)
        setRankings(rankings)
        updateTeamBuilding({
          id: teamBuilding.id,
          resultQ3: JSON.stringify({
            ...resultQ3,
            rankings,
          }),
        })
      } else {
        setRankings(resultQ3.rankings)
      }
      setDisplayedValue(resultQ3?.answer || rankings[0]?.answer)
      setEditValue(resultQ3?.answer || rankings[0]?.answer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding, teamBuildingMembers])

  React.useEffect(() => {
    //init values for mounting
    setDisplayedValue((prev) => prev || rankings[0]?.answer)
    setEditValue((prev) => prev || rankings[0]?.answer)
  }, [rankings])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QuestionContainer
          active={3}
          progressDotsProps={!editPageOpen ? { maxLength: 2, currentProgress: 2 } : {}}
          questionHeadBar={!editPageOpen}
          addPaddingSide={editPageOpen}
        >
          <div className={!isLeader ? classes.cardContainerWaitingControl : classes.cardContainer}>
            {isLeader ? (
              <>
                {editPageOpen ? ( //EditPage
                  <>
                    <div className={classes.headingWrap}>
                      <BackToIndex onClick={() => handleOnCancel()} />
                      <h2 className={classes.heading}>文言の編集</h2>
                      <div style={{ width: 40 }}></div>
                    </div>
                    <Controller
                      name={'result'}
                      control={control}
                      defaultValue={editValue}
                      rules={{ required: '入力してください' }}
                      render={({ onChange, name, value }) => (
                        <>
                          <InputTextarea
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e)
                              setEditValue(e.target.value)
                            }}
                            placeholder={'回答を入力する'}
                            style={{ height: 100 }}
                          />
                          <FormError error={errors.result} />
                        </>
                      )}
                    />
                    <div className={classes.buttonContainer}>
                      <Button key={'edit-1'} onClick={handleOnCancel} buttonType={'light'} width={100}>
                        キャンセル
                      </Button>
                      <Button key={'edit-2'} onClick={handleOnSave} disabled={editValue === displayedValue} width={100}>
                        保存
                      </Button>
                    </div>
                  </>
                ) : (
                  //TeamGoalConfirmPage
                  <>
                    <UsefulContainer>
                      <FormLabel
                        style={{
                          marginBottom: 16,
                          paddingBottom: 17,
                          borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
                        }}
                      >
                        チーム目標は下記に決定しました！
                      </FormLabel>
                      <div className={classes.teamGoal}>{displayedValue}</div>
                    </UsefulContainer>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        cursor: 'pointer',
                        paddingLeft: isSmDown ? 16 : 0,
                      }}
                      onClick={() => {
                        setEditPageOpen(true)
                      }}
                    >
                      <img
                        style={{ marginRight: 8 }}
                        src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_pen.svg'}
                        alt="pen"
                      />
                      <div
                        style={{
                          fontFamily: 'Hiragino Kaku Gothic Pro',
                          fontSize: 14,
                          color: constants.COLOR_ONBOARDING_MAIN,
                          fontWeight: 'bold',
                        }}
                      >
                        編集する
                      </div>
                    </div>
                    <ButtonContainer
                      fixed
                      buttons={[
                        <Button key={'1'} onClick={onSubmit}>
                          確定
                        </Button>,
                      ]}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <UsefulContainer>
                  <FormLabel
                    style={{
                      marginBottom: 16,
                      paddingBottom: 17,
                      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
                    }}
                  >
                    チーム目標は下記に決定しました！
                  </FormLabel>
                  <div className={classes.teamGoal}>{displayedValue}</div>
                </UsefulContainer>
                <WaitingForLeader wrapperBottom={0} />
              </>
            )}
          </div>
        </QuestionContainer>
      </form>
    </>
  )
}

type StyleProps = {
  isOpen: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    cardContainer: {
      width: '100%',
      marginBottom: 40,
    },
    cardContainerWaitingControl: {
      width: '100%',
      minHeight: 'calc(100vh - 174px)', // -150px is Container padding. 24px is progressDots.
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 166px)', // -128px is Container padding. -72px is header. 16px is progressDots. 50px is bottom adjustment.
        marginBottom: -50,
      },
    },
    buttonContainer: {
      marginBottom: 80,
      marginTop: 16,
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: 8,
    },
    headingWrap: ({ isOpen }) => ({
      marginTop: isOpen ? -8 : 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
    heading: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    teamGoal: {
      whiteSpace: 'pre-wrap',
      fontWeight: 'bold',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  }),
  { name: 'Q3Adjust' }
)

const extractResult = (
  selected: ResultQ3RankingItem[],
  teamBuildingMembers: TeamBuildingMember[]
): ResultQ3RankingItem[] => {
  const justArray: ResultQ3RankingItem[] = []

  for (const s of selected) {
    justArray.push({ id: s.id, answer: s.answer, count: 0, people: [] })
  }

  for (const m of teamBuildingMembers) {
    if (m.resultQ3) {
      const q3 = JSON.parse(m.resultQ3) as ISelectItem[]

      for (const q of q3) {
        justArray.push({ id: q.name, answer: q.label, count: 1, people: [] })
      }
    }
  }

  // group by
  const groupBy = justArray.reduce((res: ResultQ3RankingItem[], current: ResultQ3RankingItem) => {
    const element = res.find((p) => {
      return p.id === current.id
    })
    if (element) {
      element.count += current.count
    } else {
      res.push({
        id: current.id,
        answer: current.answer,
        count: current.count,
        people: [],
      })
    }
    return res
  }, [])

  return groupBy.sort(function (a, b) {
    return a.count < b.count ? 1 : -1
  })
}
