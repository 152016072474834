import * as React from 'react'

import { Collapse } from '@material-ui/core'
import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../assets/constants'

interface IOwnProps {
  body: React.ReactNode
  openLabel?: string
  full?: boolean
  onToggle?: () => void
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes, full } = props

  const [collapse, setCollapse] = React.useState(true)

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__collapseContent">
          <Collapse in={!collapse} className="content__collapse">
            {props.body}
          </Collapse>
        </div>

        <div
          className={`__collapseButtons ${!collapse ? 'opened' : ''} ${full ? 'full' : ''}`}
          onClick={() => {
            setCollapse(!collapse)
            if (props.onToggle) props.onToggle()
          }}
        >
          {!collapse ? (
            <div>
              <FontAwesome name="times" color={constants.TEXT_GRAY} size={14} />
              閉じる
            </div>
          ) : (
            <div>
              <FontAwesome name="plus" color={constants.COLOR_WHITE} size={14} />
              {props.openLabel || '開く'}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      '& .__collapseContent': {
        //
      },

      '& .__collapseButtons': {
        padding: '4px 0',
        background: '#8D8D8D',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        fontWeight: 100,

        '& i': {
          marginRight: 8,
        },

        '&.opened': {
          background: '#fff',
          color: constants.TEXT_GRAY,
          border: '1px solid #F5F5F5',
          borderTop: 'none',
        },
        '&.full': {
          borderRadius: 10,
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
