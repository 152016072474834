import * as React from 'react'

import { GhostContext } from '../../contexts/ghost'
import { Flexbox, UsefulCard } from '../atoms'

type Props = {
  childrenForLeft: React.ReactNode
  childrenForRight: React.ReactNode
  ownStyles?: React.CSSProperties
}

export const GhostTabs: React.FC<Props> = (props) => {
  const { leftVisible } = React.useContext(GhostContext)

  return (
    <>
      <Flexbox
        ownStyles={{ justifyContent: 'space-between' }}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      ></Flexbox>
      <Flexbox onClick={(e: React.MouseEvent) => e.stopPropagation()} ownStyles={{ maxWidth: 600, margin: '0 auto' }}>
        <UsefulCard
          backgroundColor="white"
          ownStyles={{ width: '100%', boxShadow: 'none', boxSizing: 'border-box', borderRadius: 8 }}
        >
          {leftVisible ? props.childrenForRight : props.childrenForLeft}
        </UsefulCard>
      </Flexbox>
    </>
  )
}
