import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  ownResponsiveStyles?: React.CSSProperties
}

export const BackStyleForGhostList: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classes.backStyle}>
      <div className={classes.responsive} style={props.ownResponsiveStyles}>
        {props.children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  backStyle: {
    backgroundColor: constGhost.COLOR_LIGHTGRAY,
    margin: 0,
    minHeight: '100vh',
  },
  responsive: {
    margin: '0 auto',
    maxWidth: constGhost.BACKCARD_WIDTH,
  },
}))
