import { useEffect } from 'react'

type UseIntersection = {
  targetRef: React.RefObject<HTMLDivElement>
  intersectionCallBack: () => Promise<void>
}

export const useIntersection = ({ targetRef, intersectionCallBack }: UseIntersection) => {
  useEffect(() => {
    const target = targetRef.current
    if (!target) return

    const callback = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) intersectionCallBack()
    }
    const options = {
      threshold: 0.25,
    }
    const observer = new IntersectionObserver(callback, options)

    observer.observe(target)

    return () => {
      observer.unobserve(target)
    }
  })
}
