import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { CircularProgress } from 'components/CircularProgress'
import { NoStyleButton } from 'components/NoStyleButton'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { useGhostTeam } from 'pages/ghost/contexts/ghostTeam'
import { useHistoryStore } from 'pages/ghost/contexts/history'
import { combineMembersAndNextActions } from 'pages/ghost/utils/combineMembersAndNextActions'
import { GhostPastListTable } from 'pages/teams/components'
import { useTeamMembers } from 'pages/teams/hooks'
import downloadIcon from 'pages/teams/pages/dashboard/assets/download.svg'
import { exportCSVGhostPastList } from 'pages/teams/pages/dashboard/components/tabs/contents/ghost/hooks/exportCSVGhostPastList'

import * as constants from 'assets/constants'

export const PastList: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { ghosts } = useContext(GhostContext)
  const { historyList, loading } = useHistoryStore(teamId, 'dummy-ghost-teamId')
  const { team: ghostTeam } = useGhostTeam(teamId)
  const { teamMembers } = useTeamMembers(teamId)
  const disabled = historyList.length === 0

  // slice(0, -1): 進行中のオバケがある場合、リストから除外する。
  // reverse(): バックエンドで日付を昇順にしているので、降順（新しい順）に並び替え、最新のオバケを find できるようにする。
  const completedGhostList = useMemo(() => {
    return historyList[historyList.length - 1]?.history?.id === ghostTeam?.id
      ? historyList.slice(0, -1).reverse()
      : historyList.slice().reverse()
  }, [historyList, ghostTeam])

  const inputDateList = completedGhostList.map((item) => {
    const history = item?.history
    const research = item?.research
    const nextActionList = research?.nextAction

    const ghost = ghosts.find((ghost) => ghost.id === item?.history?.ghostId)
    const ghostName = ghost?.name

    const memberWithNextActions = nextActionList ? combineMembersAndNextActions(nextActionList, teamMembers) : []

    return {
      createdAt: history?.createdAt,
      ghostName: ghostName,
      nextActionList: memberWithNextActions,
    }
  })

  const CsvDownload = async () => {
    await exportCSVGhostPastList(inputDateList)
  }

  return (
    <div>
      <div className={classes.flexArea}>
        <p className={classes.desc}>回ごとのDLと、全履歴履歴のDLができます。</p>
        <NoStyleButton
          type="button"
          onClick={CsvDownload}
          disabled={disabled}
          className={`${classes.button} ${disabled ? classes.disabled : ''}`}
        >
          {loading ? (
            <CircularProgress size={20} color={constants.COLOR_WHITE} margin="0px auto" />
          ) : (
            <>
              <img src={downloadIcon} alt="download" />
              <span>過去全履歴DL</span>
            </>
          )}
        </NoStyleButton>
      </div>

      <GhostPastListTable />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    flexArea: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    desc: {
      margin: 0,
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },
    button: {
      width: 137,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: constants.TEXT_WHITE,
      backgroundColor: constants.COLOR_MAIN_NEW,
      padding: '0 16px',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
      boxShadow: 'none',
      borderRadius: 40,
      fontWeight: 'bold',
      marginLeft: 'auto',
      fontSize: 12,
      [theme.breakpoints.down('xs')]: {
        marginTop: 46,
      },
      '& img': {
        marginRight: 8,
        width: 16,
      },
    },
    disabled: {
      cursor: 'not-allowed',
    },
  }),
  { name: 'PastList' }
)
