import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import {
  GrayBackGround,
  ButtonForGhost,
  BackResponsiveCard,
  Font,
  Flexbox,
  LeadText,
  UsefulCard,
} from '../components/atoms'
import {
  ButtonContainer,
  CircularProgressCount,
  ModalConfirmForCommon,
  WidthControlContainer,
} from '../components/modules'
import { WaitingContainer } from '../components/modules/WaitingContainer'
import { GhostReported } from '../components/organisms'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { PageManagerContext } from '../contexts/pageManger'
import { ResearchContext } from '../contexts/research'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

export const Done: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { currentHistory } = useContext(HistoryContext)
  const { researches1, researches2 } = useContext(ResearchContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { reportCategory } = useContext(PageManagerContext)
  const { runGoToNextPageWithTransition } = useContext(CommandContext)
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const pBForFixedUIs = usePBForFixedUIs({ isOnlyLeader: true })
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen((prev) => !prev)
  }

  const category = reportCategory(teamMember)

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const onClick = async () => {
    teamMember && (await runGoToNextPageWithTransition(teamMember, 'busy'))

    if (category === 'reason') {
      await updateGhostTeam({
        input: { id: teamId, progress: GhostProgress.S3SelectingPresentationOptionsReason },
      })
    }
    if (category === 'solution') {
      await updateGhostTeam({
        input: { id: teamId, progress: GhostProgress.S3SelectingPresentationOptions },
      })
    }
  }

  const skip = async () => {
    if (category === 'reason') {
      await updateGhostTeam({
        input: { id: teamId, progress: GhostProgress.S3DoneAggregateCause, timerS3Started2: new Date().toISOString() },
      })
    }
    if (category === 'solution') {
      await updateGhostTeam({
        input: { id: teamId, progress: GhostProgress.S3DoneRandomAnswers },
      })
    }
  }

  const r1 = useMemo(() => {
    return researches1.sort((a, b) => (a.like < b.like ? 1 : -1))
  }, [researches1])
  const r2 = useMemo(() => {
    return researches2.sort((a, b) => (a.like < b.like ? 1 : -1))
  }, [researches2])

  if (!(currentHistory && currentHistory.ghostId)) return <LoadingIndicator snapshot={{ vars: { currentHistory } }} />
  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <Flexbox ownStyles={{ justifyContent: 'flex-start' }}>
            <CircularProgressCount denominator={6} numerator={category === 'reason' ? 3 : 6} />
            <Font
              fontSize={16}
              bold
              color={constGhost.COLOR_MAIN_NEW}
              ownStyles={{
                letterSpacing: 0.8,
                marginLeft: 16,
              }}
            >
              集計結果
            </Font>
          </Flexbox>
          <LeadText ownStyles={{ fontWeight: 600 }}>
            {category === 'reason' ? (
              <>
                オバケが発生したと思われる原因の中で一番共感が多かったのは下記でした。
                <br /> 気づいたこと感じたことを自由に対話しましょう。
              </>
            ) : (
              <>
                オバケの対処方法の中で一番共感が多かったのは下記でした。
                <br /> 気づいたこと感じたことを自由に対話しましょう。
              </>
            )}
          </LeadText>

          <UsefulCard backgroundColor={'white'} ownStyles={{ margin: '24px 0 86px' }}>
            {category === 'reason' ? (
              <GhostReported
                researches={r1}
                thumbUpIconBehavior={'onlyView'}
                ownStyles={{ padding: isMdUp ? '40px' : '16px 16px 24px' }}
                balloonPadding={16}
                isLike
              />
            ) : (
              <GhostReported
                researches={r2}
                thumbUpIconBehavior={'onlyView'}
                ownStyles={{ padding: isMdUp ? '40px' : '16px 16px 24px' }}
                balloonPadding={16}
                isLike
              />
            )}
          </UsefulCard>

          {teamMember?.role === GhostMemberRole.Leader ? (
            <>
              <ForceProceed onClick={toggleModal} open={open} skip={skip} />
              <ButtonContainer
                fixed
                buttons={[
                  <ButtonForGhost
                    key="done"
                    buttonBodyColor="green"
                    bodyText="発表へ"
                    onClick={onClick}
                    isResizeBasic
                  />,
                  <ButtonForGhost
                    key="done"
                    buttonBodyColor="white"
                    bodyText="発表をスキップ"
                    onClick={toggleModal}
                    isResizeBasic
                  />,
                ]}
              />
            </>
          ) : (
            <WaitingContainer />
          )}
        </WidthControlContainer>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

export const ForceProceed: React.FC<{
  open: boolean
  onClick: () => void
  skip: () => Promise<void>
}> = ({ open, onClick, skip }) => (
  <ModalConfirmForCommon
    open={open}
    handleModalChange={onClick}
    firstLine="発表をスキップしますか？"
    info="発表をスキップして、次のステップに進みますか？"
    btnFirstLine="発表をスキップ"
    btnSecondLine="キャンセル"
    toHome={skip}
  />
)
