import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import dayjs from 'dayjs'

import { HooksContext } from 'pages/teams/contexts'
import { TeamBuildingAction, TeamBuildingActionDate } from 'utils/generated'

import 'dayjs/locale/ja'
import { ActionDescriptions } from './ActionDescriptions'
import { ActionStatusUpdater } from './ActionStatusUpdater'

import { InputCard, ButtonContainer, Button, BackToIndex, Timer } from './'

import { constants } from 'assets'

dayjs.locale('ja')

interface Props {
  layout: 'preview' | 'completed'
  teamBuildingAction: TeamBuildingAction
  teamBuildingActionDates: TeamBuildingActionDate[]
  handleBack(): void
  onSubmit(): void
  updatable?: boolean
  isFirstEdit?: boolean
}

export const ActionPreview: React.FC<Props> = ({
  layout,
  teamBuildingAction,
  teamBuildingActionDates = [],
  handleBack = () => undefined,
  onSubmit = () => undefined,
  updatable = false,
  isFirstEdit = false,
}) => {
  const classes = useStyles()

  const { teamBuilding } = React.useContext(HooksContext)

  const showSubmitButton = React.useMemo(() => {
    return layout === 'preview' || layout === 'completed'
  }, [layout])

  if (!teamBuildingAction) {
    return null
  }

  return (
    <div className={classes.root}>
      {layout === 'completed' && (
        <div className={classes.completedLabel}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/selectItem/success.svg'} alt="success" />
          <p>内容が保存されました</p>
        </div>
      )}
      {isFirstEdit ? <></> : <BackToIndex onClick={() => handleBack()} />}

      <div className={classes.actionTitleWrap}>
        <h1>{teamBuildingAction.name}</h1>
      </div>

      <div className={classes.cardContainer}>
        <InputCard>
          <ActionDescriptions
            action={teamBuildingAction}
            optional={
              <>
                <dt>終了日までの実施予定回数</dt>
                <dd>{teamBuildingActionDates.length}回</dd>
              </>
            }
          />
          <ul className={classes.actionScheduleList}>
            {teamBuildingActionDates.map((item, index) => (
              <li key={`action-preview-${item.id}-${index}`}>
                <span>{index + 1}回目</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{dayjs(item.date).format('YYYY月 MM月DD日 dddd')}</span>
                  {updatable && <ActionStatusUpdater item={item} />}
                </div>
              </li>
            ))}
          </ul>
          <dl className={classes.actionInfoList}>
            <dt>推進責任者</dt>
            <dd>{teamBuildingAction.assignedTeamMemberName}</dd>
          </dl>
        </InputCard>
      </div>
      {teamBuilding?.status !== 'COMPLETED' && teamBuilding?.timeQ5Actions && (
        <Timer from={teamBuilding.timeQ5Actions} time={180} container marginBottom={50} />
      )}
      {showSubmitButton && (
        <div>
          {isFirstEdit ? (
            <ButtonContainer
              fixed
              buildingStatus={teamBuilding?.status}
              buttons={[
                <Button key={`button-1`} onClick={onSubmit}>
                  アクション一覧へ戻る
                </Button>,
                <Button key={`button-1`} buttonType="secondary" onClick={() => handleBack()}>
                  編集に戻る
                </Button>,
              ]}
            />
          ) : (
            <ButtonContainer
              fixed
              buildingStatus={teamBuilding?.status}
              buttons={[
                <Button key={`button-1`} onClick={onSubmit}>
                  編集
                </Button>,
              ]}
            />
          )}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },

  cardContainer: {
    marginBottom: 100,
  },

  actionTitleWrap: {
    margin: '24px 0',

    '& > h1': {
      textAlign: 'center',
      fontSize: 16,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },

  actionInfoList: {
    margin: 0,
    fontSize: 14,

    '& > dt': {
      fontWeight: 'bold',
      lineHeight: 1.7,
    },

    '& > dd': {
      marginTop: 10,
      marginLeft: 0,
      lineHeight: 1.7,
    },

    '& > dd + dt': {
      marginTop: 24,
    },
  },
  actionScheduleList: {
    margin: '20px 0 24px',
    padding: '8px 0',
    borderTop: '1px solid #E9E9E9',
    borderBottom: '1px solid #E9E9E9',

    '& > li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '8px 0',
    },
  },

  completedLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    margin: '24px 0 0',
    padding: '12px 16px',
    width: '100%',

    '& > p': {
      margin: '0 16px',
      color: constants.COLOR_ONBOARDING_MAIN,
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
}))
