import * as React from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles } from '@material-ui/core/styles'

import { GhostDropdownList, DropdownBox } from '../modules'

type Props = {
  dataArray: Array<string[]>
  listOnClick: (event: React.MouseEvent<HTMLDivElement>) => void
  ownStyles?: React.CSSProperties
  ownBoxStyles?: React.CSSProperties
  selectedResearch: {
    hstIdx: number
    rptIdx: number
  }
}

export const GhostToolbar: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [visible, setVisible] = React.useState(false)

  const handleVisible = () => {
    setVisible((prev) => !prev)
  }
  const handleClose = () => {
    setVisible(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.root} style={props.ownStyles}>
        <DropdownBox
          onClick={() => undefined}
          handleVisible={() => handleVisible()}
          ownBoxStyles={props.ownBoxStyles}
          popupOpen={visible}
        >
          {props.children}
        </DropdownBox>
        <GhostDropdownList
          onClick={(event) => props.listOnClick(event)}
          handleVisible={() => handleClose()}
          visible={visible}
          dataArray={props.dataArray}
          selectedResearch={props.selectedResearch}
          ownStyles={{ margin: '0 0 30px' }}
        />
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      cursor: 'pointer',
    },
  }),
  { name: 'GhostToolbar' }
)
