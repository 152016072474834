import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { AnyAction } from 'typescript-fsa'

import { RootState } from '../../../../stores/reducers'
import { karteMemberGet } from '../api'

import action from './action'

const fetchEpic: Epic<AnyAction, AnyAction, RootState> = (action$, store) =>
  action$.pipe(
    filter(action.fetch.started.match),
    exhaustMap(({ payload }) =>
      from(karteMemberGet({ ...payload })).pipe(
        map((res) => {
          return action.fetch.done({ params: payload, result: res })
        }),
        catchError((error) => of(action.fetch.failed({ params: payload, error })))
      )
    )
  )

export default combineEpics(fetchEpic)
