import React, { useEffect, useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import Checked from '../assets/checked.svg'
import { constants } from 'assets'

type StatusDialogProps = {
  setIsShowDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const StatusDialog: React.FC<StatusDialogProps> = ({ setIsShowDialog }) => {
  const classes = useStyles()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
    const timer = setTimeout(() => {
      setIsVisible(false)
      setIsShowDialog(false)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [setIsShowDialog])

  if (!isVisible) return null

  return (
    <div className={classes.statusContainer}>
      <img src={Checked} className={classes.checkedIcon} alt="check icon" />
      <div className={classes.text}>ステータスが更新されました</div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      gap: 16,
      width: 260,
      padding: 16,
      right: 0,
      bottom: 103,
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: `0px 1px 6px 0px #00000029`,
      [theme.breakpoints.down('xs')]: {
        gap: 12,
        width: 220,
        padding: 12,
      },
    },
    checkedIcon: {
      width: 24,
      height: 24,
    },
    text: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  }),
  { name: 'StatusDialog' }
)
