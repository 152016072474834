import { format, differenceInDays, addDays } from 'date-fns'

import getSetupAnketFeedback from './api/getSetupAnketFeedback'
import getSetupItemFeedbacks from './api/getSetupItemFeedbacks'
import getSetupItems from './api/getSetupItems'

const getSetupItemList = async (setupStartedAt: string, week: number, username: string) => {
  const processingAnket: any[] = []
  const doneAnket: any[] = []

  for (let i = 1; i < week; i++) {
    const weekDate = format(addDays(new Date(setupStartedAt), 7 * i), 'yyyy-MM-dd')
    const resGetSetupAnketFeedback = await getSetupAnketFeedback(weekDate, setupStartedAt)
    const mission = `${i}週目のアンケート`
    const why = '現状を確認してみよう！'
    if (!resGetSetupAnketFeedback) {
      processingAnket.push({
        mission,
        why,
        period: differenceInDays(addDays(new Date(setupStartedAt), 7 * i), new Date(setupStartedAt)),
        anketValues: {
          id: `${username}-${weekDate}`,
          weekDate,
        },
      })
    } else {
      doneAnket.push({
        mission,
        why,
        anketValues: {
          weekDate,
          ...resGetSetupAnketFeedback,
        },
      })
    }
  }

  const resGetSetupItems: any[] = await getSetupItems(setupStartedAt)
  const resGetSetupItemFeedbacks: any[] = await getSetupItemFeedbacks(setupStartedAt)
  const actualSetupItems: any[] = []

  for (let i = 0; i < resGetSetupItems.length; i++) {
    const setupItemFeedbackId = username + '-' + resGetSetupItems[i].id
    actualSetupItems.push({
      ...resGetSetupItems[i],
      valuesId: setupItemFeedbackId,
    })
  }

  const processing = actualSetupItems
    .filter((actualSetupItem) => {
      return !resGetSetupItemFeedbacks.some((setupItemFeedback) => {
        if (setupItemFeedback.id === actualSetupItem.valuesId) {
          if (setupItemFeedback.value > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })
    })
    .reduce((result, actualSetupItem) => {
      const setupItemFeedback = resGetSetupItemFeedbacks.filter((current) => {
        return current.id === actualSetupItem.valuesId
      })[0]

      if (setupItemFeedback) {
        result.push({
          ...actualSetupItem,
          missionValues: { ...setupItemFeedback },
        })
      } else {
        result.push({
          ...actualSetupItem,
        })
      }
      return result
    }, [])

  const done = actualSetupItems
    .filter((actualSetupItem) => {
      return resGetSetupItemFeedbacks.some((setupItemFeedback) => {
        return setupItemFeedback.id === actualSetupItem.valuesId
      })
    })
    .reduce((result, actualSetupItem) => {
      const setupItemFeedback = resGetSetupItemFeedbacks.filter((current) => {
        return current.id === actualSetupItem.valuesId
      })[0]

      if (setupItemFeedback.value > 0) {
        result.push({
          ...actualSetupItem,
          missionValues: { ...setupItemFeedback },
        })
      }
      return result
    }, [])

  const processingMember: any[] = []
  const processingTeam: any[] = []
  const processingBoss: any[] = []
  const processingVision: any[] = []
  const processingOther: any[] = []

  const doneMember: any[] = []
  const doneTeam: any[] = []
  const doneBoss: any[] = []
  const doneVision: any[] = []
  const doneOther: any[] = []

  processing.forEach((d: any) => {
    switch (d.category) {
      case 'BOSS':
        processingBoss.push(d)
        break
      case 'MEMBER':
        processingMember.push(d)
        break
      case 'TEAM':
        processingTeam.push(d)
        break
      case 'VISION':
        processingVision.push(d)
        break
      case 'OTHER':
        processingOther.push(d)
    }
  })

  done.forEach((d: any) => {
    switch (d.category) {
      case 'BOSS':
        doneBoss.push(d)
        break
      case 'MEMBER':
        doneMember.push(d)
        break
      case 'TEAM':
        doneTeam.push(d)
        break
      case 'VISION':
        doneVision.push(d)
        break
      case 'OTHER':
        doneOther.push(d)
    }
  })

  return {
    processing: {
      anket: [...processingAnket],
      member: [...processingMember],
      team: [...processingTeam],
      boss: [...processingBoss],
      vision: [...processingVision],
      other: [...processingOther],
    },
    done: {
      anket: [...doneAnket],
      member: [...doneMember],
      team: [...doneTeam],
      boss: [...doneBoss],
      vision: [...doneVision],
      other: [...doneOther],
    },
  }
}

export default getSetupItemList
