import { connect, ConnectedProps } from 'react-redux'

import { ISetupAnketFeedbackUpdateInput } from '../../../services/api/updateSetupAnketFeedback'
import { RootStateType } from '../../../store'
import { SetupactionActions } from '../../../stores/setupaction/action'

const mapDispatchToProps = (dispatch: any) => ({
  submit: async (setupStartedAt: string, data: ISetupAnketFeedbackUpdateInput) => {
    dispatch(
      SetupactionActions.submitQuestionnaire.started({
        data: {
          weekDate: data.weekDate,
          boss: data.boss,
          vision: data.vision,
          member: data.member,
          team: data.team,
          other: data.other,
          comment: data.comment,
          setupStartedAt: data.setupStartedAt,
        },
        setupStartedAt,
      })
    )
  },
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    setup: { ...state.setup },
    setupaction: { ...state.setupaction },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
