import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { SocialsShare } from '../../components/result/socials/socialsShare/Index'
import { useSocials } from '../../components/result/socials/useSocials'

import { COLOR_MAIN } from 'assets/constants'

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    backgroundColor: COLOR_MAIN,
    padding: '2px 16px 0',
  },
}))

export const SocialsSnackbar = () => {
  const classes = useStyles()
  const socials = useSocials()
  const [open, setOpen] = React.useState(true)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar open={open} onClose={handleClose}>
        <Alert className={classes.alert} onClose={handleClose}>
          この内容をSNSでシェアしよう
          <div>
            <SocialsShare presenter={socials} />
          </div>
        </Alert>
      </Snackbar>
    </div>
  )
}
