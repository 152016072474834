import React from 'react'

import { useCookies } from 'react-cookie'
import { RouteComponentProps } from 'react-router'

import { teamCookieKeys } from 'pages/karte/member/static'

import { Container } from '../../components/Container'
import { Phase, TeamToolsKarteDiagnoseContext } from '../../contexts/TeamToolsKarteDiagnoseContext'

import { Diagnose } from './Diagnose'
import { Prepare } from './Prepare'


type Props = RouteComponentProps<{ teamId: string }>

export const TeamsToolsKarteDiagnosePage: React.FC<Props> = (props: Props) => {
  const { phase, setPhase } = React.useContext(TeamToolsKarteDiagnoseContext)

  const [cookies] = useCookies([teamCookieKeys.phase])

  React.useEffect(() => {
    const cookiePhase = cookies[teamCookieKeys.phase]

    if (cookiePhase) {
      setPhase(cookiePhase)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        {(() => {
          switch (phase) {
            case Phase.Prepare:
              return <Prepare />

            default:
              return (
                <React.Fragment>
                  <Diagnose />
                </React.Fragment>
              )
          }
        })()}
      </Container>
    </>
  )
}
