import React, { useContext } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { Break } from 'pages/ghost/pages/Break'
import { Complete } from 'pages/ghost/pages/Complete'
import { Done } from 'pages/ghost/pages/Done'
import { Dummy } from 'pages/ghost/pages/dummy/Dummy'
import { Pickghosts } from 'pages/ghost/pages/Pickghosts'
import { PresentationOptions } from 'pages/ghost/pages/PresentationOptions'
import { Report } from 'pages/ghost/pages/Report'
import { Result } from 'pages/ghost/pages/Result'
import { SelectLeader } from 'pages/ghost/pages/selectLeader'
import { StartedMessagePage } from 'pages/ghost/pages/StartedMessagePage'
import { Step1Presentation } from 'pages/ghost/pages/Step1Presentation'
import { Step1Results } from 'pages/ghost/pages/Step1Results'
import { Step1Start } from 'pages/ghost/pages/Step1Start'
import { Step3Presentation } from 'pages/ghost/pages/Step3Presentation'
import { Step3PresentationReason } from 'pages/ghost/pages/Step3PresentationReason'
import { Step4Presentation } from 'pages/ghost/pages/Step4Presentation'
import { Step4Report } from 'pages/ghost/pages/Step4Report'
import { User } from 'pages/ghost/pages/user'
import { Vote } from 'pages/ghost/pages/Vote'
import { VotingResults } from 'pages/ghost/pages/VotingResults'
import { Waiting } from 'pages/ghost/pages/waiting'
import { WorkResults } from 'pages/ghost/pages/WorkResults'

import { MemberImageForLeader } from './components/modules'
import { Header } from './components/organisms/Header'
import { LoadingIndicator } from './components/organisms/LoadingIndicator'
import { createteamContext, useCreateTeam } from './contexts/createGhostTeam'
import { GhostContext, useGhostStore } from './contexts/ghost'
import { ghostTeamContext, useGhostTeam } from './contexts/ghostTeam'
import { ghostTeamMemberContext, useTeamMember } from './contexts/ghostTeamMember'
import { HistoryContext, useHistoryStore } from './contexts/history'
import { PageManagerContext, usePageManager } from './contexts/pageManger'
import { ReportContext, useReportStore } from './contexts/report'
import { ResearchContext, useResearchStore } from './contexts/research'
import { useScreenLoader } from './hooks/loader'
import { useDefaultMasterTeam } from './hooks/masterTeam'
import { GhostModal } from './pages/ghosts/GhostModal'
import { SyncIndicator } from './pages/Indicator'
import { Intro } from './pages/Intro'
import { CommandContext, useCommands } from './service/commands'
import { useEventHandlers } from './service/eventHandlers'
import { useEvents } from './service/events'
import { useUnitOfWork } from './service/unitOfWork'

import { GP } from './assets/pages'

const Index: React.FC<{ UI: JSX.Element }> = ({ UI = <PageRoutes /> }) => {
  const { masterTeamId } = useDefaultMasterTeam()
  const ghostTeamStore = useGhostTeam(masterTeamId)
  const createTeamStore = useCreateTeam(masterTeamId)

  if (!masterTeamId) return <LoadingIndicator snapshot={{ vars: { masterTeamId } }} />
  return (
    <createteamContext.Provider value={createTeamStore}>
      <ghostTeamContext.Provider value={ghostTeamStore}>
        <GhostGame
          UI={UI}
          masterTeamId={masterTeamId}
          key={ghostTeamStore.team?.id || ''} // unmounts when id changes
        />
      </ghostTeamContext.Provider>
    </createteamContext.Provider>
  )
}

const GhostGame: React.FC<{ UI: JSX.Element; masterTeamId: string }> = ({ UI, masterTeamId }) => {
  //
  // Data Store
  //

  const ghostTeamStore = useContext(ghostTeamContext)
  const { team, updateGhostTeam } = ghostTeamStore

  const ghostTeamId = team?.id

  const ghostTeamMemberStore = useTeamMember(ghostTeamId)

  const { teamMember, updateMemberPage, updateGhostTeamMemberByAdmin, retire, dropMembers } = ghostTeamMemberStore

  const reportStore = useReportStore(ghostTeamId, teamMember)
  const ghostStore = useGhostStore(masterTeamId)
  const historyStore = useHistoryStore(masterTeamId, ghostTeamId)
  const researchStore = useResearchStore(ghostTeamId, teamMember)

  const history = useHistory()
  const { loadingPattern, setLoading } = useScreenLoader()
  const pageManger = usePageManager(history)

  //
  // Service layer
  //

  const uow = useUnitOfWork(masterTeamId, team, setLoading, updateGhostTeam, updateMemberPage, pageManger.jumpTo)

  const handlers = useEventHandlers(
    updateMemberPage,
    pageManger,
    uow.transitionExec,
    ghostStore.incrementFound,
    historyStore.setResult
  )
  useEvents(masterTeamId, history, ghostTeamStore, ghostTeamMemberStore, pageManger, handlers)

  const commandBus = useCommands(
    uow,
    ghostTeamStore,
    updateMemberPage,
    updateGhostTeamMemberByAdmin,
    retire,
    dropMembers,
    reportStore,
    researchStore.createResearch,
    pageManger,
    masterTeamId
  )

  //
  // UI
  //

  return (
    <>
      <GhostContext.Provider value={ghostStore}>
        <ReportContext.Provider value={reportStore}>
          <HistoryContext.Provider value={historyStore}>
            <GhostModal />
            <SyncIndicator loadingPattern={loadingPattern} />
            <Switch>
              <PageManagerContext.Provider value={pageManger}>
                <ResearchContext.Provider value={researchStore}>
                  <ghostTeamMemberContext.Provider value={ghostTeamMemberStore}>
                    <CommandContext.Provider value={commandBus}>
                      <Header />
                      {UI}
                      <MemberImageForLeader />
                    </CommandContext.Provider>
                  </ghostTeamMemberContext.Provider>
                </ResearchContext.Provider>
              </PageManagerContext.Provider>
            </Switch>
          </HistoryContext.Provider>
        </ReportContext.Provider>
      </GhostContext.Provider>
    </>
  )
}

// While testing, use intended component instead
const PageRoutes = () => (
  <>
    <Route exact={true} path={GP.waiting} component={Waiting} />
    <Route exact={true} path={GP.started} component={StartedMessagePage} />
    <Route exact={true} path={GP.selectLeader} component={SelectLeader} />
    <Route exact={true} path={GP.start} component={User} />
    <Route exact={true} path={GP.intro} component={Intro} />
    <Route exact={true} path={GP.pickGhosts} component={Pickghosts} />
    <Route exact={true} path={GP.complete} component={Complete} />
    <Route exact={true} path={GP.vote} component={Vote} />
    <Route exact={true} path={GP.break} component={Break} />
    <Route exact={true} path={GP.report} component={Report} />
    <Route exact={true} path={GP.result} component={Result} />
    <Route exact={true} path={GP.done} component={Done} />
    <Route exact={true} path={GP.step4Report} component={Step4Report} />
    <Route exact={true} path={GP.workResults} component={WorkResults} />
    <Route exact={true} path={GP.step1Start} component={Step1Start} />
    <Route exact={true} path={GP.step1Results} component={Step1Results} />
    <Route exact={true} path={GP.presentationOptions} component={PresentationOptions} />
    <Route exact={true} path={GP.step1Presentation} component={Step1Presentation} />
    <Route exact={true} path={GP.votingResults} component={VotingResults} />
    <Route exact={true} path={GP.step3PresentationReason} component={Step3PresentationReason} />
    <Route exact={true} path={GP.step3Presentation} component={Step3Presentation} />
    <Route exact={true} path={GP.step4Presentation} component={Step4Presentation} />
    {/* !!!!! WARNING !!!!! rm later */}
    <Route exact={true} path={GP.dummy} component={Dummy} />
  </>
)

export default Index
