export const formatDateTime = (inputDate: string, inputTime: string) => {
  const date = new Date(inputDate)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const week = date.getDay()
  const weekItems = ['日', '月', '火', '水', '木', '金', '土']
  const dayOfWeek = weekItems[week]
  const formattedDate = `${year}/${month}/${day}`
  const formattedDateJP = `${year}年${month}月${day}日`

  const hour = inputTime.split(':')[0].padStart(2, '0')
  const minute = inputTime.split(':')[1].padStart(2, '0')
  const formattedTime = `${hour}:${minute}`

  return {
    formattedDate,
    formattedDateJP,
    formattedTime,
    dayOfWeek,
  }
}

export const convertToDateTime = (getDateTime: string) => {
  const [reservedDay, reservedTime] = getDateTime.split(' ')
  const formattedTime = `${parseInt(reservedTime.split(':')[0], 10).toString()}:${reservedTime.split(':')[1]}`

  return {
    getReservedDate: reservedDay,
    getReservedTime: formattedTime,
  }
}
