const questions = [
  [
    { id: 1, title: '上から言われたことは、まずはやることが大事だと思っている', name: 'direction' },
    {
      id: 2,
      title: '自分自身がプレイヤーの時は、周りよりも成果を出しており、その業務にいまだ自信がある',
      name: 'bottomUp',
    },
    {
      id: 3,
      title: '決められたルールの徹底が大事であり、イレギュラーはできるだけ認めないようにしている',
      name: 'individually',
    },
    {
      id: 4,
      title: `今の役割や仕事内容に対して、"自分が望んでいるわけではないが、任されたので"、という気持ちがある`,
      name: 'influence',
    },
    { id: 5, title: 'チームを持つ際は、自分の色を出すためにも、新たな仕組みを導入しようとする', name: 'back' },
    { id: 6, title: '一人ひとりとの個別面談を何よりも大事だと思っている', name: 'relationInner' },
    { id: 7, title: '自分のチームは、他よりも良いチームでありたいと強く思っている', name: 'relationOuter' },
  ],
  [
    { id: 8, title: '自分の上司に対して、目標の修正等のお願いをすることに抵抗がある', name: 'direction' },
    { id: 9, title: '自分の基準で考えると、メンバーの今の成果や行動は物足りなく感じる', name: 'bottomUp' },
    { id: 10, title: 'メンバー一人ひとりに公平に同じように接しないといけないと思っている', name: 'individually' },
    { id: 11, title: '自分自身、仕事や会社に対して、不満な気持ちがある', name: 'influence' },
    { id: 12, title: '立ち止まってチームで進捗確認しあう時間がもったいないと考えてしまうことがある', name: 'back' },
    {
      id: 13,
      title: '複数人数ミーティングのファシリテーション（意見を引き出す運営）は、自分は得意ではないと思っている',
      name: 'relationInner',
    },
    { id: 14, title: '上司や他部署も忙しいし、簡単には助けてもらえないと思っている', name: 'relationOuter' },
  ],
  [
    {
      id: 15,
      title: '自部署（チーム）の目標について、上司とも積極的に意見交換を行い、今100％納得している状態である',
      name: 'direction',
    },
    {
      id: 16,
      title: '自分が過去に上手くいったやり方にこだわらず、今のメンバーの意向に合うやり方で行えるように指導している',
      name: 'bottomUp',
    },
    {
      id: 17,
      title: 'メンバーに対して、多少弱みがあっても、強みを見つけ活かせる仕事を任せようとしている',
      name: 'individually',
    },
    { id: 18, title: '今仕事に対して、やりがいを感じ、自分の力を100％注げている', name: 'influence' },
    {
      id: 19,
      title: 'チームで定期的に立ち止まり、メンバー同士で互いに手応えを感じあうような仕組みを行っている',
      name: 'back',
    },
    {
      id: 20,
      title: 'チームメンバー全員で集まり（オンライン含む）、メンバー同士で話す機会を十分に取れている',
      name: 'relationInner',
    },
    { id: 21, title: '上司や他部署に、自部門のメンバーの成果を積極的に伝えている', name: 'relationOuter' },
  ],
  [
    { id: 22, title: 'メンバー一人ひとりは、チーム目標に対して心から腹落ちしてる', name: 'direction' },
    { id: 23, title: 'メンバーは、チーム運営に関する改善やアイデアを提案してくる', name: 'bottomUp' },
    {
      id: 24,
      title: 'メンバー一人ひとりは、私に対して、仕事内容や働き方についての意向を本音で話せている',
      name: 'individually',
    },
    { id: 25, title: 'メンバー一人ひとりは、今の仕事に対して前向きで、やりがいを感じている', name: 'influence' },
    { id: 26, title: 'チームは発足当初から今までずっと活気がある', name: 'back' },
    { id: 27, title: 'チームメンバー同士の関係性は、互いに信頼関係があり、非常に良い', name: 'relationInner' },
    {
      id: 28,
      title: '関係するすべての他部署とは、いつでも協力してもらえるような良好な関係を築けている',
      name: 'relationOuter',
    },
  ],
]

export default questions
