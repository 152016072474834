import * as React from 'react'

import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import gql from 'graphql-tag'

import { OnboardingTeamMemberDeleteInput } from 'pages/onboarding/utils/domain'
import { emitTeamMemberUpdateEvent } from 'utils/event'
import {
  OnboardingTeamMember,
  OnboardingTeamMemberActivateInput,
  OnboardingTeamMemberCreateInput,
  OnboardingTeamMemberUpdateInput,
} from 'utils/generated'

export const useTeamManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createTeamMember = React.useCallback(async (input: OnboardingTeamMemberCreateInput) => {
    setLoading(true)
    try {
      const response: any = await API.graphql({
        query: gql`
          mutation CreateOnboardingTeamMember($input: OnboardingTeamMemberCreateInput!) {
            createOnboardingTeamMember(input: $input) {
              id
              teamId
              userId
              role
              status
              readTutorialAt
              stoppedAt
              # team {

              # }
              # user{

              # }
              createdAt
              updatedAt
              dammyHash
              reserveWillSendEmailAt
            }
          }
        `,
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })

      setLoading(false)
      return response.data.createOnboardingTeamMember as OnboardingTeamMember
    } catch (e) {
      console.log('e', e)

      const errors = (e as { errors: Error[] }).errors
      setErrors(errors)
      setLoading(false)
      return errors
    }
  }, [])

  const updateTeamMember = React.useCallback(async (input: OnboardingTeamMemberUpdateInput, userId?: string) => {
    setLoading(true)
    try {
      const response: any = await API.graphql({
        query: gql`
          mutation UpdateOnboardingTeamMember($input: OnboardingTeamMemberUpdateInput!, $userId: ID) {
            updateOnboardingTeamMember(input: $input, userId: $userId) {
              id
              reserveWillSendEmailAt
              # teamId
              # userId
              # role
              # status
              # position
              # readTutorialAt
              # dailyConfirmedAt
              # stoppedAt
              # targetFileNames
              # # team {

              # # }
              # # user{

              # # }
              # createdAt
              # updatedAt
              # dammyHash
            }
          }
        `,
        variables: { input, userId },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })

      emitTeamMemberUpdateEvent()

      return response.data.updateOnboardingTeamMember as OnboardingTeamMember
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  const updateTeamMemberWithoutRefresh = React.useCallback(
    async (input: OnboardingTeamMemberUpdateInput, userId?: string) => {
      setLoading(true)
      try {
        const response: any = await API.graphql({
          query: gql`
            mutation UpdateOnboardingTeamMember($input: OnboardingTeamMemberUpdateInput!, $userId: ID) {
              updateOnboardingTeamMember(input: $input, userId: $userId) {
                id
                # teamId
                # userId
                # role
                # status
                # readTutorialAt
                # stoppedAt
                # targetFileNames
                # # team {

                # # }
                # # user{

                # # }
                # createdAt
                # updatedAt
                # dammyHash
              }
            }
          `,
          variables: { input, userId },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        })

        return response.data.updateOnboardingTeamMember as OnboardingTeamMember
      } catch (e) {
        console.log('e', e)
        setErrors(e)
      }
      setLoading(false)
    },
    []
  )

  const deleteMember = React.useCallback(async (input: OnboardingTeamMemberDeleteInput) => {
    setLoading(true)
    try {
      const response: any = await API.graphql({
        query: gql`
          mutation DeleteOnboardingTeamMember($teamId: ID!, $userId: ID!) {
            deleteOnboardingTeamMember(teamId: $teamId, userId: $userId) {
              id
              teamId
              userId
              role
              status
              readTutorialAt
              stoppedAt
              # team {

              # }
              # user{

              # }
              createdAt
              updatedAt
              dammyHash
            }
          }
        `,
        variables: { teamId: input.teamId, userId: input.userId },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })

      return response.data.deleteOnboardingTeamMember as OnboardingTeamMember
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  const activateMember = React.useCallback(async (input: OnboardingTeamMemberActivateInput) => {
    setLoading(true)
    try {
      await API.graphql({
        query: gql`
          mutation ActivateOnboardingTeamMember($input: OnboardingTeamMemberActivateInput!) {
            activateOnboardingTeamMember(input: $input) {
              id
            }
          }
        `,
        variables: {
          input,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)
    }

    setLoading(false)
  }, [])

  const convertFakeUserOnboardingTeamMember = React.useCallback(async () => {
    setLoading(true)
    try {
      await API.graphql({
        query: gql`
          mutation ConvertFakeUserOnboardingTeamMember {
            convertFakeUserOnboardingTeamMember
          }
        `,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      })
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)
    }

    setLoading(false)
  }, [])

  return {
    createTeamMember,
    updateTeamMember,
    updateTeamMemberWithoutRefresh,
    deleteMember,
    activateMember,
    convertFakeUserOnboardingTeamMember,
    errors,
    loading,
  } as const
}
