import React from 'react'

import { makeStyles, Theme, Button } from '@material-ui/core'
import classNames from 'classnames'

import { constants } from 'assets'

interface Props {
  name: string
  label: string
  value?: boolean
  icon?: SelectItemIcon
  style?: React.CSSProperties
  onClick?: (name: string, value: boolean) => void
  prefixIcon?: React.ReactNode
  bottomNode?: React.ReactNode
  isFlexBottomNode?: boolean
  unclickable?: boolean
}

export type SelectItemIcon = 'check' | 'heart'

export const SelectItem: React.FC<Props> = (props) => {
  const classes = useStyles({ unclickable: props.unclickable })

  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick(props.name, !props.value)
    }
  }

  return (
    <Button className={classes.root} style={props.style} onClick={handleOnClick} disableRipple={props.unclickable}>
      <div
        className={classes.container}
        style={{
          marginBottom: props.bottomNode ? 16 : undefined,
        }}
      >
        <div className={classes.label}>
          {props.prefixIcon}
          {props.label}
        </div>
        <div className={classes.icon}>
          {props.icon && (
            <>
              {(() => {
                switch (props.icon) {
                  case 'check':
                    if (props.value) {
                      return (
                        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/selectItem/success.svg'} alt="" />
                      )
                    } else {
                      return <></>
                    }
                  case 'heart':
                    if (props.value) {
                      return (
                        <img
                          src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/selectItem/heartActive.svg'}
                          alt=""
                        />
                      )
                    } else {
                      return (
                        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/selectItem/heart.svg'} alt="" />
                      )
                    }

                  default:
                    return <></>
                }
              })()}
            </>
          )}
        </div>
      </div>
      {props.bottomNode && (
        <div className={classNames([classes.bottomNode, props.isFlexBottomNode && classes.bottomNodeFlex])}>
          {props.bottomNode}
        </div>
      )}
    </Button>
  )
}

type StyleProps = {
  unclickable?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ unclickable }) => ({
    cursor: unclickable ? 'default' : 'pointer',
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    borderRadius: 8,
    padding: '12px 16px',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: unclickable ? '#fff' : '#fafafa',
    },

    '& .MuiButton-label': {
      display: 'block',
    },
  }),

  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'none',
  },

  label: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 14,
    color: constants.COLOR_ONBOARDING_MAIN,
    textAlign: 'left',
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',
  },

  bottomNode: {
    borderTop: '1px solid #E9E9E9',
    paddingTop: 16,
  },
  bottomNodeFlex: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
}))
