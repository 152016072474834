import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { ColorQuestionTitles, QuestionTitles } from '../utils/constants'

import { TeamCircularProgress } from './'

import * as constants from 'assets/constants'

type Props = {
  step: 1 | 2 | 2.5 | 3 | 4 | 5
  isColorText?: boolean
  textBold?: boolean
  style?: React.CSSProperties
}

export const QuestionHeadBar: React.FC<Props> = ({ step, isColorText, textBold }) => {
  const classes = useStyles({ textBold: textBold })
  const questionText = () => {
    switch (step) {
      case 1:
        return QuestionTitles.Q1
      case 2:
        return QuestionTitles.Q2
      case 2.5:
        return QuestionTitles.Q2_2
      case 3:
        return QuestionTitles.Q3
      case 4:
        return QuestionTitles.Q4
      case 5:
        return QuestionTitles.Q5
    }
  }

  return (
    <div className={classes.questionHeadBarRoot}>
      <div className={classes.barWidth}>
        <TeamCircularProgress step={step} />
        {isColorText ? (
          <div className={classes.colorQuestionTitles}>
            <ColorQuestionTitles questionNumber={step} />
          </div>
        ) : (
          <div className={classes.questionTitles}> {questionText()}</div>
        )}
      </div>
    </div>
  )
}

type styleProps = {
  textBold?: boolean
}

const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => ({
  questionHeadBarRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    background: constants.COLOR_WHITE,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  barWidth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '16px',
    width: '100%',
    maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
  },
  colorQuestionTitles: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  questionTitles: ({ textBold }) => ({
    fontSize: 12,
    fontWeight: textBold ? 'bold' : undefined,
  }),
}))
