import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
}

export const FormLabel: React.FC<Props> = ({ children, style }) => {
  const classes = useStyles()

  return (
    <label className={classes.root} style={style}>
      {children}
    </label>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 14,
    color: constants.TEXT_GRAY_DARK,
    marginBottom: 13,
    display: 'block',
    whiteSpace: 'pre-wrap',
  },
}))
