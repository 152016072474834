import { TeamBuildingMember } from 'utils/generated'

export const pickupRandomMember = (datas: TeamBuildingMember[], excludeUserIds: string[]) => {
  let i = 0
  while (i < 100) {
    const data = pickup(datas)
    if (excludeUserIds.length === 0) {
      return data
    } else {
      let duplicate = false
      for (const e of excludeUserIds) {
        if (data.userId === e) {
          duplicate = true
        }
      }
      if (!duplicate) {
        return data
      }
    }
    i++
  }
}

const pickup = (datas: TeamBuildingMember[]): TeamBuildingMember => {
  return datas[Math.floor(Math.random() * datas.length)]
}
