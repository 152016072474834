import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'

import { getPoint } from '../../services/api/getPoint'
import getRadars from '../../services/api/getRadars'
import { RootState } from '../reducers'

import { SetupActions } from './action'

type Action = ActionType<typeof SetupActions>

const getPointEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SetupActions.fetchPoint.started.match),
    exhaustMap(({ payload }) =>
      from(getPoint({ week: payload.week, setupStartedAt: payload.setupStartedAt })).pipe(
        map((res) => {
          return SetupActions.fetchPoint.done({ params: payload, result: res })
        }),
        catchError((error) => of(SetupActions.fetchPoint.failed({ params: payload, error })))
      )
    )
  )

const getRadarEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SetupActions.fetchRadar.started.match),
    exhaustMap(({ payload }) =>
      from(getRadars(payload.week, payload.setupStartedAt)).pipe(
        map((res) => {
          return SetupActions.fetchRadar.done({ params: payload, result: res })
        }),
        catchError((error) => of(SetupActions.fetchRadar.failed({ params: payload, error })))
      )
    )
  )

export default [getPointEpic, getRadarEpic]
