import { useState } from 'react'

// -----------------------------
// interface
// -----------------------------
export type IuseCommentField = {
  setCommentValue: (value: string) => void
  getCommnetValue: () => string
}

// -----------------------------
// hooks
// -----------------------------
export const useCommentField = (): IuseCommentField => {
  const [value, setValue] = useState<string>('')

  /**
   * 現在のコメントフィールドの内容をセットする
   */
  const setCommentValue = (value: string): void => {
    setValue(value)
  }

  /**
   * 現在のコメントフィールドの内容を取得する
   */
  const getCommnetValue = (): string => {
    return value
  }

  return {
    setCommentValue,
    getCommnetValue,
  }
}
