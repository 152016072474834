import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core'

import * as constants from '../assets/constants'

interface Props {
  failure?: boolean
  success?: boolean
  handleClose?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  handlePage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  body?: any
  footer?: any
  bodyStyle?: React.CSSProperties
}

export const MessagePanel: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <div className="__msg__container">
        <div className="__msg__container__content">
          {props.handleClose && (
            <div className="__msg__container__content__close">
              <img
                className="__msg__container__content__close__icon"
                src={process.env.PUBLIC_URL + '/assets/svg/onboarding/Icons-48px-Colse.svg'}
                alt={'close'}
                onClick={props.handleClose}
              />
            </div>
          )}

          <div className="__msg__container__content__body" style={{ ...props.bodyStyle }}>
            {props.body}
          </div>

          <div className="__msg__container__content__footer">{props.footer}</div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    borderRadius: 8,

    '& .__msg__container': {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_SMALL,
      },

      '&__content': {
        position: 'relative',
        backgroundColor: constants.COLOR_WHITE,
        borderRadius: '10px',
        padding: '16px 16px 26px',
        boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

        '&__close': {
          position: 'absolute',
          top: 0,
          right: 0,
          fontSize: 0,

          '& img': {
            cursor: 'pointer',
            marginLeft: 'auto',
            marginRight: 0,
          },
        },
        '&__header': {
          display: 'flex',
          justifyContent: 'center',
          padding: '16px 0 0',
          '&__icon': {
            width: 36,
            height: 36,
          },
        },
        '&__body': {
          padding: '32px 0 26px',
        },
      },
    },
  },
}))
