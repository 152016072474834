import { PaymentMethod } from 'utils/generated'

export const paymentMethodLabel = (method?: PaymentMethod | null) => {
  switch (method) {
    case PaymentMethod.Invoice:
      return '請求書'
    case PaymentMethod.Card:
      return 'カード'
    default:
      return '未設定'
  }
}
