import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
  isBlueBackground?: boolean
}

export const SectionWrapper: React.FC<Props> = ({ children, isBlueBackground = false }) => {
  const classes = useStyles({ isBlueBackground })

  return (
    <div className={classes.root}>
      <div className={classes.contentsWidth}>{children}</div>
    </div>
  )
}

type StyleProps = {
  isBlueBackground: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({ isBlueBackground }) => ({
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: isBlueBackground ? constants.COLOR_BLUE_EXTRA_LIGHT3 : constants.COLOR_WHITE,
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
      },
    }),
    contentsWidth: {
      width: '100%',
      maxWidth: 1000,
    },
  }),
  { name: 'SectionWrapper' }
)
