type typeProps = 'diff' | 'time'

export const dateStringToLPassedTime = (dateString: string, type: typeProps = 'diff') => {
  const date = new Date(dateString)

  // 〇〇(秒 || 時間 || 日)前
  if (type === 'diff') {
    const diff = Date.now() - date.getTime()
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    if (days > 0) {
      return `${days}日前`
    } else if (hours > 0) {
      return `${hours}時間前`
    } else if (minutes > 0) {
      return `${minutes}分前`
    } else if (seconds > 0) {
      return '今'
    } else {
      return '再読み込みして下さい'
    }
  }

  // 〇月〇日 00:00
  if (type === 'time') {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minut = date.getMinutes()

    return `${month}月${day}日 ${hour}:${minut < 10 ? '0' + minut : minut}`
  }
}
