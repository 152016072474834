export const TOOL_DATA = [
  {
    alphabetName: 'Karte',
    japaneseName: 'カルテ',
    description:
      '個々の仕事の価値観を自己診断その結果をもとにチームで相互の「違い」について理解し合うことで関係性向上につながります​。',
    iconCaptions: ['働きがい診断', '診断結果一覧', '相互理解'],
    path: 'karte-feature',
    iconPath: 'karte',
  },
  {
    alphabetName: 'Kickoff',
    japaneseName: 'キックオフ',
    description:
      '理想の組織状態について自分の考えをアウトプットし、それぞれチームに何を求めているのか相互理解することで関係性向上につながります。​',
    iconCaptions: ['ファシリテーター', '良質な問い', '組織状態共有'],
    path: 'kickoff',
    iconPath: 'kickoff',
  },
  {
    alphabetName: 'Obake',
    japaneseName: 'オバケ',
    description: `組織内に存在する問題をオバケとして扱い対話を行います。\n踏み込んで組織の問題を皆で可視化し、解決することでチーム力が向上します。​`,
    iconCaptions: ['問題可視化', '意見交換', '解決方法共有'],
    path: 'obake',
    iconPath: 'obake',
  },
] as const

export const DIALOGUE_STEP_DATA = [
  {
    imagePath: 'ghost_step_01',
    description: '「違い」が生まれやすいように12体の組織課題オバケから自組織の課題を3つ選択',
  },
  {
    imagePath: 'ghost_step_02',
    description: '人が選んだオバケに共感等の反応を行う​​',
  },
  {
    imagePath: 'ghost_step_03',
    description: '共感数等から選出された1体のオバケの原因と解決策を無記名で記入し、共感しあう',
  },
] as const
