import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { BackgroundFramework } from 'pages/obakesagashi/components/BackgroundFramework'
import { PlayButton } from 'pages/obakesagashi/components/PlayButton'

import { constants } from 'assets'

export const ToSearch: React.FC = () => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'xs')

  return (
    <BackgroundFramework>
      <h2 className={classes.title}>
        <span>職場のオバケとは？</span>
      </h2>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/img/obakesagashi/search-description${isXsDown ? '-sp' : ''}.svg`}
          alt="職場のオバケについての説明"
        />
      </div>
      <div>
        <h3 className={classes.contentTextTitle}>
          <span>5分で</span>
          職場改善の <br />
          きっかけが掴めます！
        </h3>
      </div>
      <PlayButton backColor="primary" />
    </BackgroundFramework>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      color: constants.COLOR_YELLOW4,
      fontSize: 40,
      fontWeight: 'bold',
      marginBottom: 35,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
      },
    },
    contentTextTitle: {
      color: constants.COLOR_YELLOW4,
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 60,
      marginBottom: 40,
      lineHeight: 1.4,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        marginTop: 68,
        marginBottom: 24,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          fontSize: 32,
          paddingBottom: 13,
        },
        '&::before, &::after': {
          content: '""',
          width: '33px',
          height: '1.5px',
          backgroundColor: constants.COLOR_YELLOW4,
          marginRight: 12,
          marginLeft: 12,
          marginBottom: -2,
          [theme.breakpoints.down('sm')]: {
            marginRight: 4,
            marginLeft: 4,
            marginBottom: 0,
          },
        },
        '&::before': {
          transform: 'rotate(55deg)',
        },
        '&::after': {
          transform: 'rotate(-55deg)',
        },
      },
    },
  }),
  { name: 'ToSearch' }
)
