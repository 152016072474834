import { actionCreatorFactory } from 'typescript-fsa'

import { IKarteMemberSendInput } from '../interface'
const actionCreator = actionCreatorFactory('Pages/KarteMember')

export default {
  isValidMembership: actionCreator.async<{ leaderId: string }, any>('IS_VALID_MEMBERSHIP'),
  fetch: actionCreator.async<{ leaderId: string; id: string; successCallback: () => void }, any>('FETCH'),
  resetError: actionCreator<{}>('RESET_ERROR'),
  empty: actionCreator<{}>('empty'),
  sendResults: actionCreator.async<IKarteMemberSendInput, any>('SEND_RESULTS'),
}
