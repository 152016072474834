import React from 'react'

import { useTheme, useMediaQuery } from '@material-ui/core'

import ActionManage from 'pages/onboarding/pages/actions-manage'
import ActionManageForm from 'pages/onboarding/pages/actions-manage-form'
import { MemberManageAdd } from 'pages/onboarding/pages/member/add'
import Team from 'pages/onboarding/pages/team'
import Timeline from 'pages/onboarding/pages/timeline'

import { RoleTutorialProps } from '../Tutorial'

import { TimelinePostsDummy, dummyPromise } from '../assets/dummy'
import { callTx } from '../assets/text'

const LeaderTutorial: React.FC<RoleTutorialProps> = (props) => {
  const { stage, setStage } = props
  const text = callTx(props.teamMember.role)[stage]

  const theme = useTheme()
  const isXSDown = useMediaQuery(theme.breakpoints.down('xs'))

  switch (props.stage) {
    case 4:
      return (
        <Team
          override={{
            teamMembers: [],
            refresh: dummyPromise,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'メンバーリストへ',
              onClickButton: () => setStage(5),
              outerText: 'このチュートリアルは全部で約3分かかります',
              altButtonText: 'スキップ',
              onClickAltButton: () => setStage(8),
            },
          }}
        />
      )
    case 5:
      return (
        <Team
          override={{
            teamMembers: [],
            refresh: dummyPromise,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -260, y: 0 },
              pointer: { position: 'top' },
              onClickRef: () => setStage(6),
            },
          }}
        />
      )
    case 6:
      return (
        <MemberManageAdd
          key="tutorial-6"
          tutorial={{
            tooltip: {
              offset: { x: -2600, y: 0 }, //ツールチップを表示を画面外へ飛ばす
              pointer: { position: 'top', display: 'none' },
              onClickRef: () => undefined,
              onWhat: 'comment',
            },
            next: () => setStage(7),
            skip: () => setStage(8),
          }}
        />
      )

    case 7:
      return (
        <MemberManageAdd
          key="tutorial-7"
          tutorial={{
            modal: {
              ...text,
              buttonText: '次へ',
              onClickButton: () => setStage(8),
            },
          }}
        />
      )
    case 8:
      return (
        <ActionManage
          tutorial={{
            modal: {
              ...text,
              buttonText: 'アクション管理へ',
              onClickButton: () => setStage(9),
              altButtonText: 'スキップ',
              onClickAltButton: () => setStage(12),
            },
          }}
        />
      )
    case 9:
      return (
        <ActionManage
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -260, y: 0 },
              pointer: { position: 'top' },
              onClickRef: () => setStage(10),
            },
            skip: () => setStage(12),
          }}
        />
      )
    case 10:
      return (
        <ActionManageForm
          tutorialSkipFunc={() => setStage(12)}
          tutorial={{
            next: () => setStage(11),
            skip: () => setStage(12),
          }}
        />
      )
    case 11:
      return (
        <ActionManage
          tutorial={{
            modal: {
              ...text,
              buttonText: '次へ',
              onClickButton: () => setStage(12),
            },
          }}
        />
      )
    case 12:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(false),
            loading: false,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'タイムラインへ',
              onClickButton: () => setStage(13),
              altButtonText: 'スキップ',
              onClickAltButton: () => setStage(15),
            },
          }}
        />
      )
    case 13:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(false),
            loading: false,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -142, y: -5 },
              pointer: { position: 'bottom', offset: { x: 0, y: -5 } },
              onClickRef: () => undefined,
              onWhat: 'like',
            },
            next: () => setStage(14),
          }}
        />
      )
    case 14:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(true),
            loading: false,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 5, y: 10 },
              pointer: { position: 'bottom', offset: { x: isXSDown ? 5 : -105, y: 10 } },
              onClickRef: () => undefined,
              onWhat: 'comment',
            },
            next: () => setStage(15),
          }}
        />
      )
    case 15:
      return (
        <Timeline
          override={{
            posts: TimelinePostsDummy(true),
            loading: false,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'はじめる',
              onClickButton: () => {
                props.endTutorial()
              },
            },
          }}
        />
      )
    default:
      return null
  }
}

export default LeaderTutorial
