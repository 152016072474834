export const PatternEmailLowerCase =
  /^(([^<>()[\]\\.,;:\s@A-Zぁ-んァ-ヶ亜-熙"]+(\.[^<>()[\]\\.,;:\s@A-Zぁ-んァ-ヶ亜-熙"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
export const PatternEmailLowerCaseMessage =
  'メールアドレスの形式を確認してください。大文字のアルファベットはご利用になれません'

export const PatternEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export const PatternEmailMessage = 'メールアドレスの形式を確認してください。'

export const PatternPass =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_\\`{|}~ ])[a-zA-Z\d!-~]{8,24}$/
export const PatternZipCode = /^\d{3}([-]|\s*)?\d{4}?$/i
export const PatternPhone = /^\d{2,4}([-]|\s*)?\d{1,4}([-]|\s*)?\d{1,4}$/

export const PatternDateBySlashes = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])\/(0[1-9]|[1-9]|[12][0-9]|3[01])$/
