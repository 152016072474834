import * as React from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { Breadcrumb } from 'components/Breadcrumb'
import { ButtonRenewal } from 'components/ButtonRenewal'

import * as constants from '../../assets/constants'

const breakpoint = 700
export const ContactFinished: React.FC = () => {
  const classes = useStyles()
  const isMediaQueryUp = useMediaQuery(useTheme().breakpoints.up(breakpoint))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.topItemWrapper}>
      <div className={classes.breadcrumb}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="Cocolaboお問合せ完了" />
      </div>
      <div className={classes.topContainer}>
        <h1>
          お問合せ
          <br style={isMediaQueryUp ? { display: 'none' } : {}} />
          ありがとうございます！
        </h1>
        <p>
          弊社サービスにお問合せいただきありがとうございました。
          <br />
          追って、弊社担当よりご連絡いたします。
        </p>
      </div>
      <ButtonRenewal
        maxWidth={'none'}
        maxWidthSP={'none'}
        style={
          isMediaQueryUp
            ? { width: 200, height: 50, display: 'block', margin: '32px auto 0 ' }
            : { width: 200, height: 50, display: 'block', margin: '48px auto 0' }
        }
        onClick={() => (window.location.href = constants.PAGE_TOP)}
      >
        トップへ戻る
      </ButtonRenewal>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        fontSize: 32,
        fontWeight: 'bold',
        margin: '100px 0 0',
        color: constants.COLOR_MAIN_NEW,
        [theme.breakpoints.down(breakpoint)]: {
          padding: '0 20px',
          fontSize: 20,
          textAlign: 'center',
        },
      },
      '& p': {
        margin: '28px 0 0',
        textAlign: 'center',
        lineHeight: 1.8,
        fontSize: 14,
        [theme.breakpoints.down(breakpoint)]: {
          fontSize: 13,
          margin: '20px 0 0',
        },
      },
    },
    topItemWrapper: {
      position: 'relative',
    },
    breadcrumb: {
      position: 'absolute',
      top: 0,
      left: 24,
      [theme.breakpoints.up('md')]: {
        left: 'calc(7% + 24px)',
      },
    },
  }),
  { name: 'ContactFinished' }
)
