import { useLocation } from 'react-router-dom'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  setDocument: (title: string, description: string) => void
  onSetDocumentHint: (title: string, description: string) => void
  onSetDocumentConsultation: (title: string, description: string) => void
  onSetDocumentProfile: (title: string, description: string) => void
  onSetDocumentSearch: (title: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useDocument = (): IProps => {
  const location = useLocation()

  /**
   * document情報をセットする
   */
  const setDocument = (title: string, description: string): void => {
    document.title = title || 'Cocolabo'
    document
      ?.querySelector('meta[name="description"]')
      ?.setAttribute(
        'content',
        description ||
          '「Cocolabo」のひとつ「ヒント」のTips一覧です。ヒントは、管理職の悩みを解消するツールです。様々なシーンで役立つ管理職向けTipsをまとめています。'
      )
  }

  /**
   * Tips個別ページのDocumentを設定する
   */
  const onSetDocumentHint = (title: string, description: string): void => {
    const _title = title || ''
    const _description = description || ''

    setDocument(
      `[ ヒント ] ${_title} |  チームビルディング支援ツール Cocolabo (ココラボ)`,
      `「Cocolabo」のひとつ「ヒント」のTipsです。 ${_description}`
    )
  }

  /**
   * お悩み掲示板個別ページのDocumentを設定する
   */
  const onSetDocumentConsultation = (title: string, description: string): void => {
    const _title = title || ''
    const _description = description || ''

    setDocument(
      `[ ヒント ] ${_title} | チームビルディング支援ツール Cocolabo (ココラボ)`,
      `「Cocolabo」のひとつ「ヒント」のお悩み相談掲示板です。 ${_description}`
    )
  }

  /**
   * プロフィールページのDocumentを設定する
   */
  const onSetDocumentProfile = (title: string, description: string): void => {
    const _title = title || ''
    const _description = description || ''

    setDocument(
      `[ ヒント ] ${_title} | チームビルディング支援ツール Cocolabo (ココラボ)`,
      `「Cocolabo」のひとつ「ヒント」のプロフィールページです。 ${_description}`
    )
  }

  /**
   * 検索ページのDocumentを設定する
   */
  const onSetDocumentSearch = (title: string) => {
    const _tipsTitle =
      '「Cocolabo」のひとつ「ヒント」のTips一覧です。ヒントは、管理職の悩みを解消するツールです。様々なシーンで役立つ管理職向けTipsをまとめています。'
    const _faqTitle =
      '「Cocolabo」のひとつ「ヒント」のお悩み掲示板一覧です。ヒントは、管理職の悩みを解消するツールです。様々なシーンで役立つ管理職向けTipsをまとめています。'
    const _title = title || ''
    const _description = location.pathname.includes('tips') ? _tipsTitle : _faqTitle

    if (location.pathname.includes('tips')) {
      setDocument(`[ ヒント ] ${_title} | チームビルディング支援ツール Cocolabo (ココラボ)`, _description)
    }
  }

  return {
    setDocument,
    onSetDocumentHint,
    onSetDocumentConsultation,
    onSetDocumentProfile,
    onSetDocumentSearch,
  }
}
