import React, { useContext, useMemo } from 'react'

import {
  BackResponsiveCard,
  ButtonForGhost,
  GrayBackGround,
  LeadText,
  PageTitle,
  PresenterInfo,
} from 'pages/ghost/components/atoms'
import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { GhostReportCardList, LoadingIndicator } from 'pages/ghost/components/organisms'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { ReportContext } from 'pages/ghost/contexts/report'
import { usePBForFixedUIs } from 'pages/ghost/hooks/fixedUI'
import { useGetMembersWithReports } from 'pages/ghost/hooks/getMembersWithReports'
import { CommandContext } from 'pages/ghost/service/commands'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

type Props = {}

export const Step1Presentation: React.FC<Props> = () => {
  const { ghosts } = useContext(GhostContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList } = useContext(ghostTeamMemberContext)
  const { reports } = useContext(ReportContext)
  const { selectNextPresenter } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer' })

  const presenter = useMemo(() => {
    if (!team?.timerS1SelectUserIds?.length || !teamMemberList?.length) return null
    // timerS1SelectUserIds の先頭の要素が発表者の id
    const presenterId = team.timerS1SelectUserIds[0]

    return teamMemberList.find((m) => m.userId === presenterId)
  }, [team?.timerS1SelectUserIds, teamMemberList])

  const { membersWithReports: presenterWithReports } = useGetMembersWithReports(
    presenter ? [presenter] : [],
    reports,
    ghosts
  )

  const isLoading = !team || !teamMember || !presenterWithReports?.length

  const onNext = async () => {
    if (isLoading || !team.timerS1SelectNumber || !team.timerS1SelectUserIds?.length) return

    await selectNextPresenter({
      team,
      selectNumber: team.timerS1SelectNumber,
      selectUserIds: team.timerS1SelectUserIds,
      teamMemberList,
      currentMember: teamMember,
      nextProgress: GhostProgress.S1DoneRandomAnswers,
    })
  }

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <PageTitle>ランダムに解答者を指名します！</PageTitle>
          <LeadText ownStyles={{ marginBottom: 24 }}>なぜそのオバケを選んだのかお話しください。</LeadText>
          <PresenterInfo
            currentIndex={team?.timerS1SelectUserIds?.length}
            totalNumber={team?.timerS1SelectNumber}
            presenterName={presenter?.name}
          />

          {isLoading ? null : <GhostReportCardList ghosts={ghosts} membersWithReports={presenterWithReports} />}

          {isLoading ? (
            <LoadingIndicator snapshot={{}} />
          ) : teamMember?.role === GhostMemberRole.Leader ? (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="ghost-rule"
                  buttonBodyColor="green"
                  bodyText="次へ"
                  onClick={onNext}
                  isResizeBasic
                />,
              ]}
            />
          ) : null}

          {team && (
            <Timer
              from={team.timerS1SelectStarted ?? new Date().toISOString()}
              time={team.timerS1SelectMinute ? team.timerS1SelectMinute * 60 : 0}
              hasGhostStyle
            />
          )}
        </WidthControlContainer>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}
