import * as React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'

import { NoStyleButton } from 'components/NoStyleButton'
import { ButtonCSV } from 'pages/teams/components/GhostPastListTable/ButtonCSVDownload'
import { Ghost, GhostHistoryConnection, GhostResearch } from 'utils/generated'

import { constants } from 'assets'

type SpNextActionBoxProps = {
  ghostItem: GhostHistoryConnection
  times: number
  ghosts: Ghost[]
  actionFilter: (
    nextActionList: GhostResearch[] | undefined | null
  ) => Array<GhostResearch & { fullName: string; imageUrl: string | undefined }> | undefined
}

export const SpNextActionBox: React.FC<SpNextActionBoxProps> = ({ ghostItem, times, ghosts, actionFilter }) => {
  const classes = useStyles()
  const [isOpenBox, setIsOpenBox] = React.useState(false)

  const filteredActions = actionFilter(ghostItem?.research?.nextAction)

  const handleCloseBox = () => {
    setIsOpenBox(false)
  }
  const handleOpenBox = () => {
    setIsOpenBox(true)
  }

  return (
    <div className={classes.container}>
      <div className={classes.ghostTimesWrapper}>
        <p className={classes.times}>
          {times}回目の探索結果（
          {dayjs(ghostItem.history?.createdAt).format('YY/MM/DD')}）
        </p>
        <ButtonCSV ghostItem={ghostItem} times={times} ghosts={ghosts} actionFilter={actionFilter} />
      </div>

      <div className={classes.listOpenButton}>
        <NoStyleButton onClick={isOpenBox ? handleCloseBox : handleOpenBox}>
          <span>{isOpenBox ? '閉じる' : '続きを見る'}</span>
          <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow-${isOpenBox ? 'up' : 'down'}.svg`} alt="" />
        </NoStyleButton>
      </div>

      {isOpenBox && (
        <>
          <div className={classes.ghost}>
            <p className={classes.ghostInfo}>探索したオバケ</p>
            <div className={classes.ghostImgBox}>
              <img
                className={classes.ghostImg}
                src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${ghostItem.history?.ghostId}.svg`}
                alt="オバケ"
              />
              <p className={classes.ghostName}>
                {ghosts.find((ghost) => ghost.id === ghostItem.history?.ghostId)?.name}
              </p>
            </div>
          </div>

          <div className={classes.actions}>
            {!filteredActions || !filteredActions.length ? (
              <>
                <p className={classes.noneActionInfo}>明日から出来そうなアクション</p>
                <p className={classes.noneAction}>データが存在しません</p>
              </>
            ) : (
              filteredActions.map((action, i) => (
                <div key={action.id} className={classes.actionBox}>
                  {i === 0 && <p className={classes.actionInfo}>明日から出来そうなアクション</p>}
                  <div className={classes.member}>
                    <Avatar src={action.imageUrl} alt="プロフィール画像" className={classes.avatar} />
                    <p className={classes.memberName}>{action.fullName}</p>
                  </div>
                  <p className={classes.nextAction}>{action.report || '_'}</p>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    container: {
      background: '#fff',
      fontSize: 12,
    },
    ghostTimesWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    times: {
      margin: '20px 16px',
      fontWeight: 'bold',
    },
    listOpenButton: {
      borderTop: `1px solid ${constants.COLOR_WHITE2}`,
      '& button': {
        width: '100%',
        height: 32,
      },
      '& span': {
        color: constants.COLOR_MAIN_NEW,
        fontSize: 10,
        fontWeight: 'bold',
      },
      '& img': {
        position: 'relative',
        top: -1,
        marginLeft: 3,
        width: 10,
      },
    },
    ghost: {
      borderTop: `1px solid ${constants.COLOR_WHITE2}`,
      padding: 16,
    },
    ghostInfo: {
      margin: 0,
      color: constants.COLOR_GRAY_DARK3,
      fontSize: 10,
      fontWeight: 'bold',
    },
    ghostImgBox: {
      marginTop: 8,
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    ghostImg: {
      height: 32,
    },
    ghostName: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    actions: {
      borderTop: `1px solid ${constants.COLOR_WHITE2}`,
    },
    noneActionInfo: {
      margin: 0,
      padding: '16px 16px 0',
      color: constants.COLOR_GRAY_DARK3,
      fontSize: 10,
      fontWeight: 'bold',
    },
    noneAction: {
      margin: 0,
      padding: 16,
      fontSize: 10,
    },
    actionBox: {
      padding: 16,
      '&:not(:first-of-type)': {
        borderTop: `1px solid ${constants.COLOR_WHITE2}`,
      },
    },
    actionInfo: {
      margin: '0 0 8px',
      color: constants.COLOR_GRAY_DARK3,
      fontSize: 10,
      fontWeight: 'bold',
    },
    member: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    avatar: {
      width: 24,
      height: 24,
    },
    memberName: {
      margin: 0,
      fontWeight: 'bold',
    },
    nextAction: {
      margin: '8px 0 0',
      fontSize: 10,
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'SpNextActionBox' }
)
