import { useEffect, useCallback, createContext, useState } from 'react'

import { GhostTeam, MutationCreateGhostTeamArgs, MutationUpdateGhostTeamArgs } from 'utils/generated'

import * as handlers from '../api/team/handlers'

export type GhostTeamStore = ReturnType<typeof useGhostTeam>

export const ghostTeamContext = createContext<GhostTeamStore>({} as GhostTeamStore)

export const useGhostTeam = (masterTeamId?: string) => {
  const [team, setTeam] = useState<GhostTeam | undefined>(undefined)

  // init: true -> loading: true -> loaded: false
  const [loading, setLoading] = useState<boolean>(true)

  const fetchGhostTeamByMaster = useCallback(async (masterTeamId: string) => {
    try {
      const gt = await handlers.getGhostTeamByMaster({ masterTeamId })
      if (gt) {
        setTeam(gt)
      } else {
        // evt: GhostTeamNotFound
        setTeam(undefined)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [])

  // Init
  useEffect(() => {
    if (!masterTeamId) return
    fetchGhostTeamByMaster(masterTeamId)
  }, [fetchGhostTeamByMaster, masterTeamId])

  const createGhostTeam = async (args: MutationCreateGhostTeamArgs) => {
    const response = await handlers.createGhostTeam(args)
    setTeam(response)
    return response
  }

  const updateGhostTeam = async (args: MutationUpdateGhostTeamArgs) => {
    try {
      const response = await handlers.updateGhostTeam(args)
      setTeam(response)
    } catch (e) {
      console.log(e)
    }
  }

  const deleteGhostTeam = async () => {
    if (!team) return
    try {
      const res = await handlers.deleteGhostTeam({ id: team.id })
      setTeam(undefined)
      return res // why return??
    } catch (e) {
      console.log(e)
    }
  }

  // use this on update subscription
  const renewGhostTeamInfo = useCallback((newTeamData: GhostTeam) => {
    setTeam(newTeamData)
  }, [])

  return {
    fetchGhostTeamByMaster,
    team,
    loading,
    setTeam,
    createGhostTeam,
    updateGhostTeam,
    deleteGhostTeam,
    renewGhostTeamInfo,
  } as const
}
