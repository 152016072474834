import * as React from 'react'

type Props = {}

export const CheckedIcon: React.FC<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="グループ_4585" data-name="グループ 4585" transform="translate(-816 -308)">
        <rect
          id="長方形_1993"
          data-name="長方形 1993"
          width="24"
          height="24"
          rx="4"
          transform="translate(816 308)"
          fill="#00838c"
        />
        <g id="グループ_1178" data-name="グループ 1178" transform="translate(822 316)">
          <line
            id="線_69"
            data-name="線 69"
            x2="4"
            y2="4"
            transform="translate(0 4)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.8"
          />
          <line
            id="線_70"
            data-name="線 70"
            x1="8"
            y2="8"
            transform="translate(4)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.8"
          />
        </g>
      </g>
    </svg>
  )
}
