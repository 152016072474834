export const cookieKeys = {
  name: 'karteLeaderName',
}

export const getKarteTitleByTotal = (value: number) => {
  if (value === 100) {
    return '以心伝心状態'
  } else if (value >= 75) {
    return '相互理解良好状態'
  } else if (value >= 50) {
    return '概ね伝わっている状態'
  } else if (value >= 25) {
    return 'まだまだこれから状態'
  } else if (value >= 1) {
    return '期待チグハグ状態'
  } else {
    return '絶体絶命状態'
  }
}

export const getKarteResultTitleByTotal = (value: number) => {
  return `${getKarteTitleByTotal(value)}（${value}%）`
}

export const getKarteResultCommentTitleByTotal = (value: number) => {
  return `あなたとメンバーの関係性は「${getKarteTitleByTotal(value)}」です。`
}

export const getKarteResultCommentBodyByTotal = (value: number) => {
  if (value === 100) {
    return '相手のことをとても良く理解していると思われます。この関係性を継続的に続けていきながら、定期的に相互理解の機会を設けることで期待値調整を行うと良いでしょう。'
  } else if (value >= 75) {
    return '日頃からコミュニケーションがとられ、相手のことを比較的理解していると思われます。この関係性を継続的に続けていきながら、相互理解をより深めていきましょう。'
  } else if (value >= 50) {
    return '日頃からコミュニケーションがとられ、相手のことを概ね理解していると思われます。1on1などを通じてさらに深くコミュニケーションを取ることで、相互理解をさらに深めていきましょう。'
  } else if (value >= 25) {
    return 'コミュニケーションがやや不足しており、相互理解が十分とは言えないようです。日常会話や1on1などコミュニケーションを増やし、相手に質問をすることで相互理解を深めましょう。'
  } else if (value >= 1) {
    return '日頃のコミュニケーションが不足しているか、認識のずれが生じているようです。日常会話や1on1などで、相手に質問をすることで相互理解を深め期待値調整をすると良いでしょう。'
  } else {
    return 'メンバーとの相互理解が不足しているか、認識のずれが生じているようです。まずは、日常会話を意識的に増やし1on1などで、じっくり相互理解を深める機会を設けましょう。'
  }
}
