import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { FreetrialFlow } from 'pages/freetrial/components/FreetrialFlow'
import { FreetrialForm } from 'pages/freetrial/components/FreetrialForm'

import { constants } from 'assets'

export const Freetrial: React.FC = () => {
  const classes = useStyles()

  const FORM_ID = 'c6f98dc3-c7c3-460c-9fee-a26a0675da2b'

  const [rootWidth, setRootWidth] = React.useState<number>()
  const rootRef = React.useRef<HTMLDivElement>(null)

  // 画面幅が変わるタイミングで scrollbar が含まれない画面幅（clientWidth）を取得する。
  React.useEffect(() => {
    const onResize = () => {
      setRootWidth(rootRef.current?.clientWidth)
    }

    setRootWidth(rootRef.current?.clientWidth)
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div ref={rootRef} className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.contentWidth}>
          <FreetrialFlow clientWidth={rootWidth} />
          <FreetrialForm formId={FORM_ID} />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT3,
      width: '100%',
      margin: 0,
      overflow: 'hidden',
    },
    contentWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
      },
    },
    contentWidth: {
      width: '100%',
      maxWidth: 1000,
    },
  }),
  { name: 'Freetrial' }
)
