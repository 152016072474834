import React from 'react'

import { GhostProgress, GhostResearch, GhostTeam, GhostTeamMember } from 'utils/generated'

type Args = {
  team?: GhostTeam
  researchesFeeling: GhostResearch[]
  researchesNextAction: GhostResearch[]
  teamMemberList: GhostTeamMember[]
}

export const useGetUnansweredUserIds = ({ team, researchesFeeling, researchesNextAction, teamMemberList }: Args) => {
  const unansweredUserIds = React.useMemo(() => {
    switch (team?.progress) {
      case GhostProgress.S4SelectingPresentationOptions:
      case GhostProgress.S4DonePresentationOptions:
        return teamMemberList
          .filter(
            (m) =>
              !researchesFeeling.some((r) => r.userId === m.userId) &&
              !researchesNextAction.some((r) => r.userId === m.userId)
          )
          .map((m) => m.userId)
      default:
        return []
    }
  }, [team?.progress, researchesFeeling, researchesNextAction, teamMemberList])

  return {
    unansweredUserIds,
  }
}
