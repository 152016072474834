import { useMemo } from 'react'

import { Ghost, GhostReport, GhostTeamMember } from 'utils/generated'

export type MemberWithReports = GhostTeamMember & { reports: GhostReport[] }

export const useGetMembersWithReports = (
  teamMemberList?: GhostTeamMember[],
  reports?: GhostReport[],
  ghosts?: Ghost[]
): { membersWithReports: MemberWithReports[] } => {
  const membersWithReports = useMemo(() => {
    if (!teamMemberList || !reports?.length || !ghosts?.length) return []

    const getGhost = (ghostId: string) => ghosts.find((ghost) => ghost.id === ghostId)
    const sortedByGhostFileNoReports = reports.sort((a, b) => {
      return parseInt(getGhost(a.ghostId)?.fileNo ?? '0') - parseInt(getGhost(b.ghostId)?.fileNo ?? '0')
    })

    return teamMemberList.map((member) => {
      const memberReports = sortedByGhostFileNoReports.filter((report) => report.userId === member.id)
      return {
        ...member,
        reports: memberReports,
      }
    })
  }, [teamMemberList, reports, ghosts])

  return {
    membersWithReports,
  }
}
