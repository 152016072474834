import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { ISetupAnketFeedbackUpdateInput } from '../../services/api/updateSetupAnketFeedback'
import { filterAnket, filterMission } from '../../services/utils/setupactions'
import { UserActions } from '../user/action'

import { SetupactionActions } from './action'

export interface ISetupaction {
  processing: IActionList
  done: IActionList

  fetching?: boolean
  fetched?: boolean
  searched?: boolean
  refresh?: boolean
  submit?: boolean
}

interface IActionList {
  anket: IActionItem[]
  member: IActionItem[]
  team: IActionItem[]
  boss: IActionItem[]
  vision: IActionItem[]
  other: IActionItem[]
}

export interface IActionItem {
  id: string
  importance?: number
  mission: string
  why: string
  what?: string
  how?: string
  category?: string
  period?: number
  type?: string
  typeTitle?: string
  url?: string

  missionValues?: IMission
  anketValues?: IQuestionnaire
  point?: number
  weekDate?: string

  valuesId?: string

  isReactive?: boolean
}

export interface IMission {
  weekDate?: string
  setupItemId: string
  category: string
  point: number
  value: number | null
  comment: string
  settedAt: string
  setupStartedAt?: string
}

export interface IQuestionnaire extends ISetupAnketFeedbackUpdateInput {
  id: string
  total: number
}

export const initialState: ISetupaction = {
  processing: {
    anket: [],
    member: [],
    team: [],
    boss: [],
    vision: [],
    other: [],
  },
  done: {
    anket: [],
    member: [],
    team: [],
    boss: [],
    vision: [],
    other: [],
  },
  fetching: false,
  fetched: false,
  searched: false,
  submit: false,
}

export const SetupactionReducer = reducerWithInitialState<ISetupaction>(initialState)
  // fetch
  .case(SetupactionActions.fetch.started, (state, payload) => {
    return { ...state, fetching: true, error: null }
  })
  .case(SetupactionActions.fetch.done, (state, payload) => {
    return {
      ...state,
      ...payload.result,
      refresh: false,
      fetching: false,
      fetched: true,
      error: null,
    }
  })
  .case(SetupactionActions.fetch.failed, (state, error) => {
    return { ...state, fetching: false, fetched: true, ...error }
  })

  // search
  .case(SetupactionActions.search.started, (state, payload) => {
    return { ...state, searched: false, error: null }
  })
  .case(SetupactionActions.search.done, (state, payload) => {
    return { ...state, ...payload.result, searched: true, error: null }
  })
  .case(SetupactionActions.search.failed, (state, error) => {
    return { ...state, ...error }
  })

  // submitQuestionnaire
  .case(SetupactionActions.submitQuestionnaire.started, (state, payload) => {
    return { ...state, submit: false, refresh: true, error: null }
  })
  .case(SetupactionActions.submitQuestionnaire.done, (state, payload) => {
    const { processing, done } = filterAnket(state.processing.anket || [], state.done.anket || [], payload.result)
    return {
      ...state,
      submit: true,
      processing: { ...state.processing, anket: processing },
      done: { ...state.done, anket: done },
      refresh: false,
      error: null,
    }
  })
  .case(SetupactionActions.submitQuestionnaire.failed, (state, error) => {
    return { ...state, refresh: false, ...error }
  })

  // submitMission
  .case(SetupactionActions.submitMission.started, (state, payload) => {
    return { ...state, submit: false, refresh: true, error: null }
  })
  .case(SetupactionActions.submitMission.done, (state, payload) => {
    switch (payload.result.category) {
      case 'MEMBER':
        const { processing: pm, done: dm } = filterMission(state.processing.member, state.done.member, payload.result)
        return {
          ...state,
          submit: true,
          processing: { ...state.processing, member: pm },
          done: { ...state.done, member: dm },
          refresh: false,
          error: null,
        }
      case 'TEAM':
        const { processing: pt, done: dt } = filterMission(state.processing.team, state.done.team, payload.result)
        return {
          ...state,
          submit: true,
          processing: { ...state.processing, team: pt },
          done: { ...state.done, team: dt },
          refresh: false,
          error: null,
        }
      case 'BOSS':
        const { processing: pb, done: db } = filterMission(state.processing.boss, state.done.boss, payload.result)
        return {
          ...state,
          submit: true,
          processing: { ...state.processing, boss: pb },
          done: { ...state.done, boss: db },
          refresh: false,
          error: null,
        }
      case 'VISION':
        const { processing: pv, done: dv } = filterMission(state.processing.vision, state.done.vision, payload.result)
        return {
          ...state,
          submit: true,
          processing: { ...state.processing, vision: pv },
          done: { ...state.done, vision: dv },
          refresh: false,
          error: null,
        }
      case 'OTHER':
        const { processing: po, done: doneo } = filterMission(state.processing.other, state.done.other, payload.result)
        return {
          ...state,
          submit: true,
          processing: { ...state.processing, other: po },
          done: { ...state.done, other: doneo },
          refresh: false,
          error: null,
        }
    }

    return {
      ...state,
      submit: true,
      refresh: false,
      error: null,
    }
  })
  .case(SetupactionActions.submitMission.failed, (state, error) => {
    return { ...state, refresh: false, ...error }
  })

  // signout
  .case(UserActions.signout.done, (state, payload) => {
    return { ...initialState, signout: true, error: null }
  })
