import * as React from 'react'

type Props = {}

export const CheckedIcon: React.FC<Props> = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g
          id="楕円形_88"
          data-name="楕円形 88"
          fill="none"
          stroke="#00838c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <circle cx="12" cy="12" r="12" stroke="none" />
          <circle cx="12" cy="12" r="11.5" fill="none" />
        </g>
      </svg>
    </>
  )
}
