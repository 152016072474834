import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
  children?: React.ReactNode
}

export const CardFooter: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.children}</div>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
