import { useSelector } from 'react-redux'
import { RootStateType } from 'store'
import { v4 as uuidv4 } from 'uuid'

import * as s3 from '../../../apis/s3'
import * as utils from '../../../apis/utils'

// -----------------------------
// interface
// -----------------------------
export type IuseModalToolabrProps = {
  uploadCallback: (file: File) => Promise<unknown>
}

// -----------------------------
// hooks
// -----------------------------
export const useModalToolabr = () => {
  const modalSelector = useSelector((state: RootStateType) => state.hint.ui)

  /**
   * inlineImage挿入時のcallback処理
   */
  const uploadCallback = (file: File) => {
    return new Promise((resolve) => {
      const WIDTH = 1000
      const HEIGHT = 1000
      const MAX_SIZE = 600000

      const imgReader = new Image()
      imgReader.src = URL.createObjectURL(file)

      imgReader.onload = async () => {
        const canvas = document.createElement('canvas')
        canvas.width = WIDTH
        canvas.height = HEIGHT
        const ctx = canvas.getContext('2d')

        const imgWidth = imgReader.width, //画像の元の幅を取得
          imgHeight = imgReader.height, //画像の元の高さを取得
          imgRate = imgWidth / imgHeight //画像の比率を取得
        let imgPos = 0 //Canvas上での画像の位置を初期化

        if (ctx) {
          if (imgWidth > WIDTH || imgHeight > HEIGHT) {
            //画像が横長のとき
            if (imgRate >= 1) {
              imgPos = (WIDTH - WIDTH * imgRate) / 2
              ctx.drawImage(imgReader, imgPos, 0, WIDTH * imgRate, WIDTH)

              //画像が縦長のとき
            } else {
              imgPos = (HEIGHT - HEIGHT / imgRate) / 2
              ctx.drawImage(imgReader, 0, imgPos, HEIGHT, HEIGHT / imgRate)
            }
          } else {
            canvas.width = imgWidth
            canvas.height = imgHeight
            ctx.drawImage(imgReader, 0, 0)
          }
        }

        // 縮小後の画像情報をStateに詰める
        const resizeSize = utils.base64ToFile(canvas.toDataURL('image/jpeg'))
        let uploadBlob = resizeSize

        // 上限サイズより大きい場合は上限サイズを切るように縮小後の画像の容量を落とす
        if (MAX_SIZE <= resizeSize['size']) {
          const capacityRatio = MAX_SIZE / resizeSize['size']
          const processingBinary = canvas.toDataURL('image/jpeg', capacityRatio)
          uploadBlob = utils.base64ToFile(processingBinary)
        }

        const imageUrl = await s3.S3put(file, uploadBlob, `${modalSelector.modal.id}_${uuidv4()}`)

        resolve({ data: { link: imageUrl } })
      }
    })
  }

  return {
    uploadCallback,
  }
}

// MEMO: リサイズ方法一覧
// -------------------------------------------------
// 比率を保ってw1000 x hauto固定でクロップする場合
// -------------------------------------------------
// var cropWidth = WIDTH;
// var cropRatio = cropWidth / imgReader.width;
// var cropHeight = imgReader.height * cropRatio;
// const canvas = document.createElement('canvas');

// ↓ w1000 x h1000固定になる
// canvas.width = WIDTH;
// canvas.height = HEIGHT;

// canvas.width = WIDTH;
// canvas.height = imgReader.height * cropRatio;
// const ctx = canvas.getContext('2d');

// if (ctx) {
//   ctx.drawImage(imgReader, 0, 0, cropWidth, cropHeight);
// }

// -------------------------------------------------
// 比率を保ってw1000 x h1000でクロップする場合
// -------------------------------------------------
// const imageOriginRatio = imgReader.width / imgReader.height
// const longSide = imgReader.width - imgReader.height
// let left, top, width, height
// if (ctx) {
//   // 画像が横長の場合
//   if(longSide > 0) {
//     left = 0
//     width = canvas.width
//     height = canvas.width / imageOriginRatio;
//     top = (canvas.height - height) / 2;
//   // 画像が縦長の場合
//   } else {
//     top = 0;
//     height = canvas.height;
//     width = canvas.height * imageOriginRatio;
//     left = (canvas.width - width) / 2;
//   }
// ctx.drawImage(imgReader, 0, 0, imgReader.width, imgReader.height, left, top, width, height);

// -------------------------------------------------
// 比率を保ってw1000 x h1000のセンターでクロップする場合
// -------------------------------------------------
// const canvas = document.createElement('canvas');
// canvas.width = WIDTH;
// canvas.height = HEIGHT;
// const ctx = canvas.getContext('2d');

// let	imgWidth = imgReader.width, //画像の元の幅を取得
//     imgHeight = imgReader.height, //画像の元の高さを取得
//     imgRate = imgWidth / imgHeight, //画像の比率を取得
//     imgPos = 0; //Canvas上での画像の位置を初期化

// if (ctx) {
//    //画像が横長のとき
//   if(imgRate >= 1){
//     imgPos = (WIDTH - (WIDTH * imgRate)) / 2;
//     ctx.drawImage(imgReader, imgPos, 0, WIDTH * imgRate, WIDTH);

//   //画像が縦長のとき
//   } else {
//     imgPos = (HEIGHT - (HEIGHT / imgRate)) / 2;
//     ctx.drawImage(imgReader, 0, imgPos, HEIGHT, HEIGHT / imgRate);
//   }
// }
