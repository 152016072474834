import React from 'react'
import { useHistory as userHis } from 'react-router-dom'

import * as H from 'history'

export interface IRoute {
  replace: (path: string) => void
  push: (path: string, pathParams?: PlainObject | undefined) => void
  top: () => void
}

interface PlainObject {
  [key: string]: string | undefined
}

export const replacePathParams = (path: H.Path, pathParams?: PlainObject) => {
  let targetPath = path

  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      targetPath = targetPath.replace(`:${param}`, pathParams[param] || '')
    })
  }

  return targetPath
}

export const route = (history: H.History) => ({
  replace: (path: H.Path) => {
    history.replace(path)
    window.scrollTo(0, 0)
  },
  push: (path: H.Path, pathParams?: PlainObject) => {
    history.push(replacePathParams(path, pathParams))
    window.scrollTo(0, 0)
  },
  top: () => {
    window.scrollTo(0, 0)
  },
})

export const useHistory = () => {
  const history = userHis()

  const result = React.useMemo(() => ({ route: route(history) }), [history])
  return result
}
