import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { Container } from '../container/Index'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constants.COLOR_GRAY,
    [theme.breakpoints.down('md')]: {
      borderRadius: '30px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const Wrapper = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box pt={{ xs: 0, lg: '48px' }}>
        <Container maxWidth={1080}>
          <Box display="flex" alignItems="flex-start">
            {props.children}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
