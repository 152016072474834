import * as React from 'react'

import { Hidden } from '@material-ui/core'
import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import * as constants from '../../../../../assets/constants'
import Button from '../../../../../components/Button'
import SpeechBubbles from '../../../components/SpeechBubbles'
import {
  getKarteResultTitleByTotal,
  getKarteResultCommentBodyByTotal,
  getKarteResultCommentTitleByTotal,
} from '../../static'

interface IOwnProps {
  point: number
  gender: string
  name: string | null
  expects: IExpects
  handleOpen?: () => void
}

interface IExpects {
  goodTop: boolean
  badSome: boolean
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const imgURL = (point: number, gender: string): string => {
    const karteRank = getKarteRankByPoint(point)
    const imgStr = process.env.PUBLIC_URL + `/assets/svg/karte/${gender}_${karteRank}.svg`
    return imgStr
  }

  function getKarteRankByPoint(point: number): number {
    if (point === 100) {
      return 4
    } else if (point >= 75) {
      return 3
    } else if (point >= 50) {
      return 2
    } else if (point >= 25) {
      return 1
    } else {
      return 0
    }
  }

  return (
    <div className={classes.main}>
      <div className="bubblesContainer">
        <SpeechBubbles
          bubbles={[
            <>
              あなたと部下は…
              <br />
              <strong>{getKarteResultTitleByTotal(props.point)}</strong>
            </>,
          ]}
        />
        {props.point <= 50 && (
          <div className="bubblesContainer__buttonWrapper">
            <Button
              bgColor={constants.COLOR_RED}
              textColor={constants.COLOR_WHITE}
              fullWidth
              body={
                <div className="bubblesContainer__buttonWrapper__body">
                  <ErrorOutlineIcon className="bubblesContainer__buttonWrapper__body__icon" />
                  <div className="bubblesContainer__buttonWrapper__body__text">
                    すぐにメンバーと1on1を設定し
                    <Hidden smDown>
                      <br />
                    </Hidden>
                    相互理解を行いましょう
                  </div>
                </div>
              }
              onClick={props.handleOpen}
            />
          </div>
        )}
      </div>
      <div className="understandingContainer__img">
        <img src={imgURL(props.point, props.gender)} alt="understanding" />
      </div>
      <div className="understandingContainer__note">{`${getKarteResultCommentTitleByTotal(
        props.point
      )}${getKarteResultCommentBodyByTotal(props.point)}
      ${
        props.expects.goodTop
          ? `特に、今回あなたは${props.name}さんの一番重視している項目を捉えきれていないため、この点に関して、しっかりと対話し、信頼関係を深めていきましょう。`
          : ''
      }`}</div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      position: 'relative',
      '& .bubblesContainer': {
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '&__buttonWrapper': {
          [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            marginBottom: 24,
          },
          paddingRight: '16px',
          '&__body': {
            margin: '0 6px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
              margin: '0 10px',
            },
            '&__icon': {
              fontSize: 36,
              marginRight: 5,
              [theme.breakpoints.up('sm')]: {
                marginRight: 10,
              },
            },
            '&__text': {
              textTransform: 'lowercase',
              textAlign: 'left',
            },
          },
        },
      },
      '& .understandingContainer': {
        '&__img': {
          padding: '0 16px',
          '& img': {
            width: '100%',
          },
        },
        '&__note': {
          backgroundColor: '#F5F5F5',
          padding: 16,
          margin: 16,
          marginTop: 0,

          lineHeight: '20px',
          letterSpacing: '1px',
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
