import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { NoStyleButton } from './NoStyleButton'

import closeSvg from '../assets/svg/close.svg'

type CloseButtonProps = {
  onClose: () => void
} & StyleProps

type StyleProps = {
  size?: number
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClose, size }) => {
  const classes = useStyles({ size })

  return (
    <NoStyleButton className={classes.button} onClick={onClose}>
      <img src={closeSvg} alt="close" />
    </NoStyleButton>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    button: ({ size }) => ({
      padding: 8,

      '& img': {
        width: size,
        height: size,
        verticalAlign: 'top',
      },
    }),
  }),
  { name: 'CloseButton' }
)
