import { useState, useEffect, useRef, useCallback } from 'react'

export const useScrollXCheck = (scrollElId: string, scrollWidth: number) => {
  const [isActiveBtn, setIsActiveBtn] = useState({ left: false, right: true })
  const scrollEl = document.getElementById(scrollElId)
  const scrollElRef = useRef<HTMLDivElement>(null)
  const isProcessing = useRef(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollLeft = scrollEl?.scrollLeft
      const scrollWidth = scrollEl?.scrollWidth
      const clientWidth = scrollEl?.clientWidth

      if (scrollLeft === 0) {
        setIsActiveBtn((prev) => ({ ...prev, left: false }))
        // 画面の拡大・縮小によって小数点が発生するケースがあるので >= にて比較
      } else if (scrollLeft && clientWidth && scrollWidth && scrollLeft + clientWidth >= scrollWidth) {
        setIsActiveBtn((prev) => ({ ...prev, right: false }))
      } else {
        setIsActiveBtn({ left: true, right: true })
      }

      isProcessing.current = false
    }

    if (isProcessing.current) {
      return
    }

    const currentScrollEl = scrollElRef.current
    if (currentScrollEl && !isProcessing.current) {
      isProcessing.current = true
      currentScrollEl.addEventListener('scroll', handleScroll)
    }

    return () => {
      isProcessing.current = false
      if (currentScrollEl) {
        currentScrollEl.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollEl?.scrollLeft, scrollEl?.scrollWidth, scrollEl?.clientWidth, scrollElRef])

  const handleClickScroll = useCallback(
    (direction: 'left' | 'right') => () => {
      const amount = direction === 'left' ? -scrollWidth : scrollWidth

      if (scrollEl) {
        scrollEl.scrollBy({ left: amount, behavior: 'smooth' })
      }
    },
    [scrollEl, scrollWidth]
  )

  return { scrollElRef, isActiveBtn, handleClickScroll }
}
