import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { v4 as uuid } from 'uuid'

import {
  queryGetOnboardingPostList,
  queryGetOnboardingPostListOfOneUser,
  queryGetOnboardingPost,
  queryGetOnboardingPostCommentList,
  queryGetOnboardingPostSecondCommentList,
  mutationCreateOnboardingPostLikeToggle,
  mutationCreateOnboardingPostComment,
  mutationCreateOnboardingPostCommentLikeToggle,
  mutationUpdateOnboardingPostComment,
  mutationDeleteOnboardingPostComment,
  mutationCreateOnboardingPostSecondComment,
  mutationCreateOnboardingPostSecondCommentLikeToggle,
  mutationUpdateOnboardingPostSecondComment,
  mutationDeleteOnboardingPostSecondComment,
  mutationCreateOnboardingPostPost,
  mutationupdateOnboardingPostPost,
} from 'pages/onboarding/graphql'
import {
  OnboardingPostLikeToggleEvent,
  OnboardingPostCommentEvent,
  OnboardingPostCommentLikeToggleEvent,
  OnboardingPostSecondCommentEvent,
  OnboardingPostSecondCommentLikeToggleEvent,
  emitOnboardingPostLikeToggleEvent,
  emitOnboardingPostCommentEvent,
  emitOnboardingPostCommentLikeToggleEvent,
  emitOnboardingPostSecondCommentEvent,
  emitOnboardingPostSecondCommentLikeToggleEvent,
} from 'utils/event'
import {
  MutationUpdateOnboardingPostPostArgs,
  OnboardingPost,
  OnboardingPostComment,
  OnboardingPostSecondComment,
  OnboardingTeamMember,
} from 'utils/generated'



export const useOnboardingPosts = (teamId?: string) => {
  const [posts, setPosts] = useState<OnboardingPost[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()
  const [nextToken, setNextToken] = useState<string | null | undefined>()

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const pathname = useLocation().pathname
  const isMemberStatusPage = pathname.includes('memberstatus')

  const timelineItemsCount = !isMemberStatusPage ? 40 : isSmDown ? 5 : 10

  const refresh = useCallback(
    async (after = '') => {
      if (!teamId) {
        return
      }
      setLoading(true)
      setError(undefined)
      try {
        const response = await queryGetOnboardingPostList({ teamId, first: timelineItemsCount, after })
        const items = response.getOnboardingPostList?.items || []
        const newItems = [...posts]
        const alreadyFetched = posts.map((p) => p.id)
        for (const item of items) {
          if (!alreadyFetched.includes(item.id)) {
            newItems.push(item)
          } else {
            const idx = newItems.findIndex((i) => i.id === item.id)
            newItems.splice(idx, 1, item)
          }
        }
        setPosts(newItems)
        setNextToken(response.getOnboardingPostList?.nextToken || undefined)
      } catch (e) {
        console.log('e', e)
        setError(e as Error)
      }
      setLoading(false)
    },
    [teamId, posts, timelineItemsCount]
  )

  const morePostList = async () => {
    setLoading(true)
    setTimeout(async () => await refresh(nextToken), 500)
  }

  useEffect(() => {
    if (!posts.length) {
      refresh()
    }
  }, [teamId, refresh, posts])

  useEffect(() => {
    const handler = () => {
      // TODO: Fix this minor bug.
      // when like/comment on the post after the first 40, it doesn't update num of like/comments
      refresh()
    }
    window.addEventListener(OnboardingPostLikeToggleEvent, handler)
    window.addEventListener(OnboardingPostCommentEvent, handler)
    return () => {
      window.removeEventListener(OnboardingPostLikeToggleEvent, handler)
      window.removeEventListener(OnboardingPostCommentEvent, handler)
    }
  }, [refresh])

  const createPost = useCallback(
    async (
      comment: string,
      teamMember: OnboardingTeamMember,
      fileNames?: string[],
      id?: string,
      mentions?: string[]
    ) => {
      if (!teamId) return
      try {
        const newPost = await mutationCreateOnboardingPostPost({
          input: {
            teamId,
            comment,
            fileNames,
            mentions,
            id,
          },
        })
        const presentationPost = {
          ...newPost,
          teamMember, // required for appearing in timeline after this
        }
        setPosts([presentationPost, ...posts])
      } catch (e) {
        setError(e as Error)
        console.log(e)
      }
    },
    [teamId, posts]
  )

  const updatePost = useCallback(
    async (postId: string, params: { comment?: string; fileNames?: string[] }) => {
      try {
        const input: MutationUpdateOnboardingPostPostArgs['input'] = { id: postId }
        if (params.comment) {
          input['comment'] = params.comment
        }
        if (params.fileNames) {
          input['fileNames'] = params.fileNames
        }
        await mutationupdateOnboardingPostPost({ input })
        refresh()
      } catch (e) {
        setError(e as Error)
        console.log(e)
      }
    },
    [refresh]
  )

  return { posts, loading, error, nextToken, refresh, morePostList, createPost, updatePost } as const
}

export const useOnboardingPost = (id?: string) => {
  const [post, setPost] = useState<OnboardingPost | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id) {
        return
      }
      const response = await queryGetOnboardingPost({ id })
      setPost(response)
    } catch (e) {
      console.error(e)
      setError(e as Error)
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingPostLikeToggleEvent, handler)
    window.addEventListener(OnboardingPostCommentEvent, handler)
    return () => {
      window.removeEventListener(OnboardingPostLikeToggleEvent, handler)
      window.removeEventListener(OnboardingPostCommentEvent, handler)
    }
  }, [refresh])

  return { post, loading, error, refresh } as const
}

export const useOnboardingPostOfOneUser = (teamId: string | undefined, userId: string | undefined) => {
  const [posts, setPosts] = useState<OnboardingPost[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!teamId || !userId) {
        return
      }
      let response = await queryGetOnboardingPostListOfOneUser({ teamId, userId })
      if (response.getOnboardingPostListOfOneUser?.items) {
        setPosts(response.getOnboardingPostListOfOneUser?.items)
      }
      let errorCounter = 0
      while (response.getOnboardingPostListOfOneUser?.nextToken) {
        const after = response.getOnboardingPostListOfOneUser?.nextToken
        response = await queryGetOnboardingPostListOfOneUser({ teamId, userId, after })
        if (response.getOnboardingPostListOfOneUser?.items) {
          const items = [...response.getOnboardingPostListOfOneUser?.items]
          setPosts((prev) => [...prev, ...items])
        }
        errorCounter++
        if (errorCounter >= 20) {
          throw new Error('while syntax is overLimit in useOnboardingPostOfOneUser')
        }
      }
    } catch (e) {
      console.error(e)
      setError(e as Error)
    }
    setLoading(false)
  }, [teamId, userId])

  useEffect(() => {
    refresh()
  }, [refresh, teamId, userId])

  return { posts, loading, error, refresh } as const
}

export const useOnboardingPostComment = (id?: string) => {
  const [postComments, setPostComments] = useState<OnboardingPostComment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id) {
        return
      }
      const response = await queryGetOnboardingPostCommentList({ postId: id })
      setPostComments(response.getOnboardingPostCommentList?.items || [])
    } catch (e) {
      setError(e as Error)
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingPostCommentEvent, handler)
    window.addEventListener(OnboardingPostCommentLikeToggleEvent, handler)
    return () => {
      window.removeEventListener(OnboardingPostCommentEvent, handler)
      window.removeEventListener(OnboardingPostCommentLikeToggleEvent, handler)
    }
  }, [refresh])

  return { postComments, loading, error, refresh } as const
}

export const useOnboardingPostSecondComment = (id?: string) => {
  const [postSecondComments, setPostSecondComments] = useState<OnboardingPostSecondComment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined | unknown>()
  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id) {
        return
      }
      const response = await queryGetOnboardingPostSecondCommentList({ postCommentId: id })
      setPostSecondComments(response.getOnboardingPostSecondCommentList?.items || [])
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingPostSecondCommentEvent, handler)
    window.addEventListener(OnboardingPostSecondCommentLikeToggleEvent, handler)
    return () => {
      window.removeEventListener(OnboardingPostSecondCommentEvent, handler)
      window.removeEventListener(OnboardingPostSecondCommentLikeToggleEvent, handler)
    }
  }, [refresh])

  return { postSecondComments, loading, error, refresh } as const
}

export const useOnboardingPostFirstComment = (id?: string) => {
  const [postComments, setPostComments] = useState<OnboardingPostComment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id) {
        return
      }
      const response = await queryGetOnboardingPostCommentList({ postId: id, first: 1 })
      setPostComments(response.getOnboardingPostCommentList?.items || [])
    } catch (e) {
      setError(e as Error)
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  return { postComments, loading, error, refresh } as const
}

export const useOnboardingPostActions = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const likeToggleHandler = useCallback(async (postId: string, teamId: string, icon?: number) => {
    try {
      await mutationCreateOnboardingPostLikeToggle({ input: { postId, teamId, icon } })
      emitOnboardingPostLikeToggleEvent(postId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const createComment = useCallback(async (postId: string, text: string, teamId: string, mentions?: string[]) => {
    try {
      await mutationCreateOnboardingPostComment({ input: { id: uuid(), postId, text, teamId, mentions } })
      emitOnboardingPostCommentEvent(postId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const commentLikeToggleHandler = useCallback(async (postId: string, teamId: string) => {
    try {
      await mutationCreateOnboardingPostCommentLikeToggle({ input: { postId, teamId } })
      emitOnboardingPostCommentLikeToggleEvent(postId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const updateComment = useCallback(async (id: string, postId: string, text: string) => {
    setLoading(true)
    try {
      await mutationUpdateOnboardingPostComment({ input: { id, text } })
      emitOnboardingPostCommentEvent(postId)
    } catch (e) {
      console.log('e', e)
    }
    setLoading(false)
  }, [])

  const deleteComment = useCallback(async (id: string, postId: string) => {
    try {
      await mutationDeleteOnboardingPostComment({ id })
      emitOnboardingPostCommentEvent(postId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const createSecondComment = useCallback(
    async (postCommentId: string, text: string, teamId: string, mentions?: string[]) => {
      try {
        await mutationCreateOnboardingPostSecondComment({
          input: { id: uuid(), postCommentId, text, teamId, mentions },
        })
        emitOnboardingPostSecondCommentEvent(postCommentId)
      } catch (e) {
        console.log('e', e)
      }
    },
    []
  )

  const secondCommentLikeToggleHandler = useCallback(async (postSecondCommentId: string, teamId: string) => {
    try {
      await mutationCreateOnboardingPostSecondCommentLikeToggle({ input: { postSecondCommentId, teamId } })
      emitOnboardingPostSecondCommentLikeToggleEvent(postSecondCommentId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const updateSecondComment = useCallback(async (id: string, postCommentId: string, text: string) => {
    setLoading(true)
    try {
      await mutationUpdateOnboardingPostSecondComment({ input: { id, text } })
      emitOnboardingPostSecondCommentEvent(postCommentId)
    } catch (e) {
      console.log('e', e)
    }
    setLoading(false)
  }, [])

  const deleteSecondComment = useCallback(async (id: string, postCommentId: string) => {
    try {
      await mutationDeleteOnboardingPostSecondComment({ id })
      emitOnboardingPostSecondCommentEvent(postCommentId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  return {
    likeToggleHandler,
    createComment,
    commentLikeToggleHandler,
    updateComment,
    deleteComment,
    createSecondComment,
    secondCommentLikeToggleHandler,
    updateSecondComment,
    deleteSecondComment,
    loading,
  } as const
}

export const useIntersection = (ref: React.MutableRefObject<HTMLDivElement>) => {
  const [intersecting, setIntersecting] = useState(false)
  useEffect(() => {
    const refCurrent = ref.current
    if (ref && refCurrent) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting)
        },
        { threshold: 0.25 }
      )

      observer.observe(refCurrent)

      return () => {
        observer.unobserve(refCurrent)
      }
    }
  })
  return { intersecting } as const
}
