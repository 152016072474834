import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  isHome: () => boolean
  isShowPager: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useFaqIndex = (): IProps => {
  const location = useLocation()
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)
  const consultationListSelectors = useSelector((state: RootStateType) => state.hint.consultationList)

  /**
   * ページのHOMEかどうかを判定する
   */
  const isHome = (): boolean => {
    const isFirstPage = location.pathname.includes('/page/1')
    const isFaqPage = location.pathname === '/hint/faq'
    return isFirstPage || isFaqPage ? false : true
  }

  /**
   * ページングの表示を出し分けをする
   */
  const isShowPager = (): boolean => {
    return consultationListSelectors.nextToken || pagesSelectors.currentPages > 1 ? true : false
  }

  return {
    isHome,
    isShowPager,
  }
}
