import React, { useContext } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { NoStyleButton } from 'components/NoStyleButton'
import * as constGhost from 'pages/ghost/assets/constGhost'
import info from 'pages/ghost/assets/svg/info.svg'
import { MemberInfo } from 'pages/ghost/components/modules'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { MemberWithReports } from 'pages/ghost/hooks/getMembersWithReports'
import { Ghost, GhostMemberRole } from 'utils/generated'

type Props = {
  ghosts: Ghost[]
  membersWithReports: MemberWithReports[]
}

export const GhostReportCardList: React.FC<Props> = ({ ghosts, membersWithReports }) => {
  const { teamMember: currentTeamMember } = useContext(ghostTeamMemberContext)
  const classes = useStyles({ isLeader: currentTeamMember?.role === GhostMemberRole.Leader })
  if (ghosts?.length === 0 || !membersWithReports) return null

  return (
    <div className={classes.ghostReportCardList}>
      {membersWithReports.map((memberWithReports) => (
        <GhostReportCard key={memberWithReports.id} ghosts={ghosts} memberWithReports={memberWithReports} />
      ))}
    </div>
  )
}

const GhostReportCard: React.FC<{ ghosts: Ghost[]; memberWithReports: MemberWithReports }> = ({
  ghosts,
  memberWithReports,
}) => {
  const classes = useStyles({})
  const getGhost = (ghostId: string) => ghosts.find((ghost) => ghost.id === ghostId)

  return (
    <div className={classes.ghostReportCard}>
      <MemberInfo name={memberWithReports.name ?? ''} role={memberWithReports.role ?? ''} />
      <div className={classes.ghostList}>
        {memberWithReports.reports.map((report) => {
          const ghost = getGhost(report.ghostId)
          if (!ghost) return null

          return <ReportedGhost key={report.id} ghost={ghost} />
        })}
      </div>
    </div>
  )
}

const ReportedGhost: React.FC<{ ghost: Ghost }> = ({ ghost }) => {
  const classes = useStyles({})
  const { setModal, setDetail, setDirectClose } = useContext(GhostContext)
  const openGhostDetailModal = (ghostId: string) => {
    setDetail(ghostId)
    setModal('detail')
    setDirectClose(true)
  }

  return (
    <div>
      <img
        src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghost.id}.svg`}
        alt={ghost.name}
        className={classes.ghostImage}
      />
      <div className={classes.ghostInfo}>
        <div className={classes.ghostInfoTitle}>
          <div className={classes.ghostNumber}>{`FILE NO.${ghost.fileNo}`}</div>
          <div>{ghost.name}</div>
        </div>
        <NoStyleButton onClick={() => openGhostDetailModal(ghost.id)} className={classes.ghostInfoButton}>
          <img src={info} alt="ghost info icon" />
          <span>詳細</span>
        </NoStyleButton>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, { isLeader?: boolean }>(
  (theme: Theme) => ({
    // GhostReportCardList
    ghostReportCardList: ({ isLeader }) => ({
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      paddingBottom: isLeader ? 84 : 16,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: isLeader ? 40 : 16,
      },
    }),

    // GhostReportCard
    ghostReportCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 16,
      padding: '16px 24px',
      backgroundColor: constGhost.COLOR_WHITE,
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 16,
      },
    },
    ghostList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },

    // ReportedGhost
    reportedGhost: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '@media (hover: hover)': {
        cursor: 'pointer',
      },
    },
    ghostImage: {
      width: 148,
      height: 148,
      backgroundColor: constGhost.COLOR_WHITE,
      border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
      },
    },
    ghostInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    ghostInfoTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      fontSize: 10,
      color: constGhost.COLOR_BLACK3,
    },
    ghostNumber: {
      color: constGhost.COLOR_GRAY2,
    },
    ghostInfoButton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      width: 20,
      '& img': {
        width: 16,
        height: 16,
      },
      '& span': {
        fontSize: 10,
        color: constGhost.COLOR_MAIN_NEW,
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
  }),
  { name: 'GhostReportCardList' }
)
