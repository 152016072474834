import * as React from 'react'

import { faExchangeAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Paper } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'


import * as constants from '../../../../assets/constants'
import Button from '../../../../components/Button'
import deleteSetupStartedAtListItem from '../../../../services/api/deleteSetupStartedAtListItem'

import { connector, ContainerProps } from './ModalChangeSetupStartedAt.container'

interface IOwnProps {
  setOpenStartSetupModal(isOpen: boolean): void
  isOpen: boolean
  closeModal(): void
}

type Props = IOwnProps & ContainerProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [selected, setSelected] = React.useState<null | string>(null)

  const createSetupStartedAt = () => {
    props.closeModal()
    props.setOpenStartSetupModal(true)
  }

  const handleChangeSetupStartedAt = () => {
    if (!selected) return
    props.changeCurrentSetupStartedAt(selected)
    props.closeModal()
    setSelected(null)
  }

  const handleDeleteSetupStartedAt = async () => {
    if (!selected) return

    const updated = props.user.setupStartedAtList?.filter((item) => item !== selected) ?? []

    await deleteSetupStartedAtListItem(selected)

    props.updateAccount({ setupStartedAtList: updated })

    setSelected(updated?.length > 0 ? updated[0] : null)

    if (updated[0]) {
      props.changeCurrentSetupStartedAt(updated[0])
    }
  }

  React.useEffect(() => {
    if (props.isOpen && props.setup.currentSetupStartedAt) {
      setSelected(props.setup.currentSetupStartedAt)
    }
  }, [props.isOpen, props.setup.currentSetupStartedAt])

  return (
    <React.Fragment>
      <Modal open={props.isOpen} className={classes.modal}>
        <Paper className="__paper">
          <div className="__close">
            <div className="__closeButtonWrapper" onClick={() => props.closeModal()}>
              <img className="__closeButton" src="/assets/svg/close_message.svg" alt="" />
            </div>
          </div>
          <div className="__paperHeader">
            <h2 className="__heading">チームを切り替える</h2>
            <Button
              bgColor={constants.COLOR_MAIN}
              textColor={constants.COLOR_WHITE}
              body={<div className="__newTeamButton">新しくはじめる</div>}
              onClick={createSetupStartedAt}
            />
          </div>

          <div className="__dateList">
            {!!props.user.setupStartedAtList &&
              props.user.setupStartedAtList.map((date: string) => (
                <div
                  key={date}
                  className={`__dateItem ${selected === date ? 'selected' : ''}`}
                  onClick={() => setSelected(date)}
                >
                  {format(new Date(date), 'yyyy.MM.dd')} 開始分
                </div>
              ))}
          </div>

          <div className="__buttons">
            <Button
              disabled={selected === null}
              bgColor={constants.COLOR_YELLOW}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              body={
                <div>
                  <span className="__submitButtonText">選択したチームに切り替え</span>
                  <FontAwesomeIcon icon={faExchangeAlt} color={constants.COLOR_WHITE} />
                </div>
              }
              onClick={handleChangeSetupStartedAt}
            />
            {props.user.setupStartedAtList && props.user.setupStartedAtList.length > 1 && (
              <div className="__deleteButtonWrap">
                <div className="__deleteButton" onClick={handleDeleteSetupStartedAt}>
                  <FontAwesomeIcon icon={faTrashAlt} color={constants.COLOR_BLACK} />
                  <span className="__deleteButtonText">削除する</span>
                </div>
              </div>
            )}
          </div>

          <div className="__note">
            ＊別のチームでセットアップを利用する場合は[新しくはじめる]を押してください。
            <br />
            ＊一度追加した後はいつでも切り替えてそれぞれの進捗を確認できます。
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px',

      '& .__close': {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '28px',
      },

      '& .__closeButtonWrapper': {
        padding: '4px',
        cursor: 'pointer',
      },

      '& .__closeButton': {
        width: '12px',
      },

      '& .__submitButtonText': {
        display: 'inline-block',
        marginRight: '8px',
      },

      '& .__heading': {
        fontSize: '14px',
      },

      '& .__paper': {
        width: '100%',
        padding: '16px 16px 34px',
        backgroundColor: constants.COLOR_GRAY,
        borderRadius: '10px',
      },

      '& .__paperHeader': {
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& .__newTeamButton': {
        width: '148px',
      },

      '& .__dateList': {
        marginTop: '24px',
      },

      '& .__dateItem': {
        border: `solid 2px ${constants.COLOR_MAIN}`,
        borderRadius: '3px',
        padding: '16px 20px',
        marginTop: '8px',
        color: constants.COLOR_MAIN,
        fontWeight: 'bold',
        cursor: 'pointer',
        backgroundColor: '#fff',
        boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
      },

      '& .__dateItem.selected': {
        backgroundColor: '#efefef',
      },

      '& .__buttons': {
        marginTop: '32px',
      },

      '& .__note': {
        marginTop: '24px',
        padding: '16px',
        borderRadius: '5px',
        fontSize: '12px',
        backgroundColor: constants.COLOR_WHITE,
      },

      '& .__deleteButtonWrap': {
        display: 'flex',
        justifyContent: 'flex-end',
      },

      '& .__deleteButton': {
        marginTop: '8px',
        fontSize: '11px',
        cursor: 'pointer',
        borderBottom: `solid 1px ${constants.COLOR_BLACK}`,
      },

      '& .__deleteButtonText': {
        display: 'inline-block',
        marginLeft: '8px',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
