import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { HooksContext } from 'pages/teams/contexts'
import { TeamTool } from 'utils/generated'

import { constants } from 'assets'

interface Props {
  tool: TeamTool
  title: string
  rightNode?: React.ReactNode
  bottomNode?: React.ReactNode
  onClick?: () => void
  onClickTitle?: string
  fullWidth?: boolean
}

export const ToolItem: React.FC<Props> = (props) => {
  const classes = useStyles({ fullWidth: props.fullWidth })
  const { currentTeam } = React.useContext(HooksContext)

  if (!currentTeam) {
    return <></>
  }

  if (!currentTeam.teamTools.includes(props.tool)) {
    return <></>
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{props.title}</div>
        {props.onClick ? (
          <div onClick={props.onClick} className={classes.action}>
            {props.onClickTitle || '編集'}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>{props.children}</div>
    </div>
  )
}

type StyleProps = {
  fullWidth?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ fullWidth }: StyleProps) => ({
    marginBottom: 16,
    width: fullWidth ? '100%' : '48%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),

  titleContainer: {
    height: 35,
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  },

  title: {
    fontSize: 14,
    color: constants.COLOR_WHITE,
    fontWeight: 'bold',
  },

  action: {
    color: constants.COLOR_WHITE,
    fontSize: 12,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))
