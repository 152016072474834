import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { Backdrop, CircularProgress, Fade, Modal, IconButton, Hidden } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { makeEvenPoints } from 'pages/teams/pages/_tools/karte/pages/diagnose/utils'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import AlertDialog from '../../../../components/AlertDialog'
import Button from '../../../../components/Button'
import { KartePDF } from '../../../../components/pdf'
import UsefulCollapse from '../../../../components/UsefulCollapse'
import Graph from '../../components/result/Graph'
import RankingCard from '../../components/result/RankingCard'
import { SocialsSnackbar } from '../../components/result/SocialsSnackbar'

import MembersAnswer from './components/MembersAnswer'
import Understanding from './components/Understanding'
import { connector, ContainerProps } from './index.container'
import { buildResults } from './utils/calc'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const copyText = `先日、受けていただいたCocolaboカルテの
メンバー診断結果を踏まえて1on1面談を行いたいと思います。

■目的：相互に知らないことを理解しあう／お互いの認識のズレを埋める

■時間：30分～45分

■内容：メンバー診断結果を使った相互理解

■事前準備：
１．オープンな気持ち
２．自分のメンバー診断結果から感じたことを率直に教えて下さい
`

const Index: React.FC<Props> = (props: Props) => {
  const { classes, name, data, leaderData } = props

  React.useEffect(() => {
    props.onFetch(props.match.params.id)
    const timeoutId = setTimeout(() => {
      setIsSnackbarOpen((v) => !v)
    }, 7000)
    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isBubblesCollapsed, setIsBubblesCollapsed] = React.useState(true)
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [textCopied, setTextCopied] = React.useState(false)

  const handlePageKarteHome = () => {
    props.history.push(pages.KARTE_LEADER_HOME)
    window.scrollTo(0, 0)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setTextCopied(false)
  }

  // TODO: make error in common module
  const renderError = () => {
    if (!props.error) return null

    const onClose = () => {
      props.history.push(`/karte/leader/home`)
      props.onResetError()
    }

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  const [results, setResults] = React.useState<ReturnType<typeof buildResults> | undefined>()
  React.useEffect(() => {
    if (!props.isLoading) {
      if (data) {
        setResults(buildResults(data, leaderData))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading])

  const getGender = () => {
    let gender: 'man' | 'woman' = 'man'

    if (data) {
      const commonIndex = data.simple.findIndex((item: { group: string }) => item.group === 'common')
      if (commonIndex !== -1) {
        gender = data.simple[commonIndex].values['性別'] === '男性' ? 'man' : 'woman'
      }
    }
    return gender
  }

  const matchingText = () => {
    const a = `あなたと${name}さんとの間では、${name}さんが重視している項目と、${name}さんに対してあなたが予想をした項目で${
      results ? results.match.expect : '--'
    }個合致しています。`
    // const b = () => {
    //   if (results.match.hope + results.match.expect === 0) {
    //     return `${name}さんのことを全く理解できていないようです。理解するにはまず話を聞くことです。しっかりと話に耳を傾けましょう。`
    //   }
    //   if (results.match.hope + results.match.expect === 1) {
    //     return `${name}さんのことをあまり理解できていないようです。自分の話をするよりも話を聞くことを優先してみましょう。`
    //   }
    //   if (results.match.hope + results.match.expect === 2) {
    //     return `${name}さんのことを理解できているようです。さらに理解を深めるため合致した項目以外のことについて質問してみましょう。`
    //   }
    //   if (results.match.hope + results.match.expect > 2) {
    //     return `${name}さんのことをとてもよく理解できているようです。あなたの期待することと◯◯◯さんが重視していることについてもっと深くすり合わせていきましょう。`
    //   }
    // }
    // const c = `${name}さんの回答とあなたの予想と期待を見比べて面談に活かしてください。`

    return a // + b() + c
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        {!props.isLoading && results ? (
          <div className="__container">
            <>
              <div className="__container__header">
                <Hidden xsDown>
                  <div className="__container__header__blank" />
                </Hidden>
                <div style={{ width: '100%', textAlign: 'center' }}>診断結果 カルテNo. {props.match.params.id}</div>
                <KartePDF
                  gender={getGender()}
                  karteNo={props.match.params.id}
                  name={name ?? ''}
                  results={results}
                  leaderData={leaderData}
                />
              </div>
              <div className="__container__content">
                <div className="__container__content__base">
                  <Understanding
                    point={results.total}
                    gender={getGender()}
                    name={name}
                    expects={results.expects}
                    handleOpen={handleOpen}
                  />

                  <div className="__container__content__title">マッチング状況</div>

                  <p>{matchingText()}</p>
                </div>

                <div className="__container__content__compareContainer">
                  <div className="__container__content__compareContainer__title">{name}さんの回答</div>

                  <MembersAnswer
                    gender={getGender()}
                    goodLabel="重視している"
                    badLabel="重視していない"
                    answerGood={results.characteristics.good}
                    answerBad={results.characteristics.bad}
                  />

                  <div className="__container__content__compareContainer__title">あなた自身と相手への予想</div>

                  <MembersAnswer
                    gender="man"
                    goodLabel="あなた自身"
                    badLabel="相手への予想"
                    answerGood={[leaderData.hope1, leaderData.hope2, leaderData.hope3]}
                    answerBad={[leaderData.expectation1, leaderData.expectation2, leaderData.expectation3]}
                    compare={false}
                  />
                </div>

                <div className="__container__content__base">
                  <div className="__container__content__title">{name}さんの分析結果</div>

                  <p>
                    {name}さんの分析結果です。さらに深く{name}
                    さんへの理解を深めるための参考にしてください
                  </p>

                  <div className="__container__content__title">働く上で重視している項目</div>

                  {/*<p>*/}
                  {/*  あなたの働く上での優先順位は以下の「仕事のコントロール」が一番強く、「任された仕事の定義がはっきりしていて、進めやすいかどうか」を大事にしています。すべてを十分に満たすことは難しい中で、自分の重視すべきことを高め、低いものには優先度を落とすことが、仕事に対して前向きに取り組む上で重要です。*/}
                  {/*</p>*/}

                  <div className="__container__content__bubblesContainer">
                    <Graph
                      data={results.points.map((item) => ({
                        ...item,
                        value: makeEvenPoints(item.value),
                      }))}
                    />
                  </div>

                  <div className="__container__content__rankingContainer">
                    <div className="__container__content__rankingContainer__content">
                      {!isBubblesCollapsed
                        ? results.points.map((point, index) => (
                            <RankingCard
                              key={index}
                              index={index}
                              title={point.title}
                              point={makeEvenPoints(point.value)}
                              body={point.description}
                              color={point.color}
                              link={point.link}
                            />
                          ))
                        : null}
                    </div>
                    <UsefulCollapse
                      full={true}
                      body={<div></div>}
                      openLabel="詳しくみる"
                      onToggle={() => setIsBubblesCollapsed(!isBubblesCollapsed)}
                    />
                  </div>

                  <div className="__container__content__title">{name}さんの特徴的なこだわり</div>

                  <p>
                    全36問の質問文で見たときに、特にこだわりが見られたのが以下の通りであり、上司や周りの人にも共有しましょう。
                  </p>
                </div>

                <div className="__container__content__conclusionContainer">
                  <div className="__container__content__conclusionContainer__title">重視していること</div>
                  <ul>
                    {results.memberCharacteristics.good.map((item, index) => (
                      <li key={index}>
                        <img
                          className=""
                          src={process.env.PUBLIC_URL + '/assets/svg/karte/dotYellow.svg'}
                          alt={'dot'}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                  <div className="__container__content__conclusionContainer__title">重視していないこと</div>
                  <ul>
                    {results.memberCharacteristics.bad.map((item, index) => (
                      <li key={index}>
                        <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte/dotBlue.svg'} alt={'dot'} />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="__container__content__links">
                  <div className="__link">
                    <a
                      href="https://doc.cocolabo.club/wp-content/uploads/2021/03/type_1on1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      タイプ別でみる1on1のススメ実践編はこちら
                    </a>
                  </div>
                  <div className="__link">
                    <a
                      href="https://cocolabo.club/hint/tips/e4d62ec5-4210-40d0-849d-034055b436f5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      動画で見る1on1ノウハウはこちら
                    </a>
                  </div>
                </div>

                <div style={{ padding: 16, paddingTop: 40 }}>
                  <Button
                    bgColor={constants.COLOR_BLUE}
                    textColor={constants.COLOR_WHITE}
                    fullWidth={true}
                    body={<div>カルテのホームに戻る</div>}
                    onClick={handlePageKarteHome}
                  />
                </div>

                <div style={{ padding: 16 }}>
                  <Link to={pages.KARTE_LEADER_SEARCH} target="_blank" rel="noopener noreferrer">
                    <Button
                      bgColor={constants.COLOR_SECOND}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      body={<div>他のメンバーを診断する</div>}
                    />
                  </Link>
                </div>

                <div style={{ padding: 16 }}>
                  <Button
                    bgColor={constants.COLOR_MAIN}
                    textColor={constants.COLOR_WHITE}
                    fullWidth={true}
                    body={<div className={classes.textLower}>1on1の日程をメンバーに連絡しよう</div>}
                    onClick={handleOpen}
                  />
                </div>

                <Modal
                  aria-labelledby="1on1"
                  aria-describedby="text-for-1on1"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  className={classes.modal}
                >
                  <Fade in={open}>
                    <div className="__paper">
                      <div>1on1の招待</div>
                      <div className="__textBox">
                        <p>
                          先日、受けていただいたCocolaboカルテの
                          <br />
                          メンバー診断結果を踏まえて1on1面談を行いたいと思います。
                          <br />
                          <br />
                          ■目的：相互に知らないことを理解しあう／お互いの認識のズレを埋める
                          <br />
                          <br />
                          ■時間：30分～45分
                          <br />
                          <br />
                          ■内容：メンバー診断結果を使った相互理解
                          <br />
                          <br />
                          ■事前準備：
                          <br />
                          １．オープンな気持ち
                          <br />
                          ２．自分のメンバー診断結果から感じたことを率直に教えて下さい
                          <br />
                        </p>
                      </div>
                      <div>上記をコピーしてメンバーに送り、1on1の日程を調整して下さい。</div>

                      <div className="__buttonBlock">
                        <div className="__copyButton">
                          <CopyToClipboard text={copyText} onCopy={() => setTextCopied(true)}>
                            <Button
                              bgColor={constants.COLOR_MAIN}
                              textColor={constants.COLOR_WHITE}
                              body={<div className={classes.textLower}>1on1の招待をコピー</div>}
                              // onClick={handleClose}
                            />
                          </CopyToClipboard>
                        </div>
                        <div>
                          <Button
                            bgColor={constants.COLOR_GRAY}
                            textColor={constants.COLOR_BLACK}
                            body={<div>閉じる</div>}
                            onClick={handleClose}
                          />
                        </div>

                        <IconButton aria-label="close" className="__close" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>

                        {textCopied && <div className="__copiedMsg">クリップボードにコピーされました</div>}
                      </div>
                    </div>
                  </Fade>
                </Modal>

                {isSnackbarOpen && <SocialsSnackbar />}
              </div>
              <div className={classes.bottomKartePDFContainer}>
                {/* <KartePDF
                  gender={getGender()}
                  karteNo={props.match.params.id}
                  name={name ?? ''}
                  results={results}
                  leaderData={leaderData}
                /> */}
              </div>
            </>
          </div>
        ) : (
          <Modal
            open={props.isLoading || true}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disableBackdropClick={true}
          >
            <CircularProgress className={classes.loadingCircularProgress} disableShrink={true} />
          </Modal>
        )}

        {renderError()}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 48,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },

          '&__blank': {
            minWidth: 150,
          },
        },

        '&__content': {
          backgroundColor: '#fff',
          borderRadius: 30,
          padding: '16px 0 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__base': {
            padding: '0 16px',
          },

          '&__note': {
            backgroundColor: '#F5F5F5',
            padding: 16,
            margin: 16,
            marginTop: 0,
          },

          '&__circlesContainer': {
            marginBottom: 16,

            '&__content': {
              '&__circle': {
                //
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                padding: 16,
                backgroundColor: '#F5F5F5',
              },
            },
          },

          '&__bubblesContainer': {
            marginBottom: 16,
          },

          '&__rankingContainer': {
            marginBottom: 16,
            '&__content': {
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: '1px solid #f3f3f3',
              borderBottom: 'none',
              padding: 16,
            },
          },

          '&__conclusionContainer': {
            padding: 16,
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/karte/bg_heart.svg)`,
            backgroundSize: 'cover',
            '&__title': {
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 16,
            },

            '& ul': {
              paddingLeft: 8,
            },

            '& li': {
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              marginBottom: 8,

              '& img': {
                width: 8,
                height: 8,
                marginRight: 8,
              },
            },
          },

          '&__compareContainer': {
            backgroundColor: '#F5F5F5',
            padding: 16,
            '&__title': {
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 8,
            },
          },

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 20,
            fontWeight: 'bold',
            padding: '16px 0',
            borderTop: '1px dotted',
          },

          '&__buttons': {
            display: 'flex',
            padding: 16,
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },

          '&__links': {
            marginTop: 16,
            marginBottom: 16,
            padding: '0 16px',
            '& .__link': {
              padding: '4px 0',
              color: constants.COLOR_MAIN,
              textDecoration: 'underline',
            },
          },
        },
      },
    },

    userfulCircleBody: {
      padding: '8px 16px',
      borderLeft: '1px solid #f3f3f3',
      borderRight: '1px solid #f3f3f3',
      '& .userfulCircleBody': {
        '& p': {
          margin: 0,
          paddingBottom: 8,
        },

        '&__title': {
          display: 'block',
          fontSize: 16,
          fontWeight: 'bold',
          margin: '8px 0',

          '& label': {
            '&.one': {
              background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
              // fontWeight: 'bold',
            },
          },
        },

        '&__hr': {
          borderBottom: '1px solid #e5e5e5',
        },
      },
    },
    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },

    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .__paper': {
        position: 'relative',
        backgroundColor: constants.COLOR_WHITE,
        width: '90%',
        maxWidth: 650,
        maxHeight: '90%',
        borderRadius: 10,
        outline: 0,
        padding: '35px 20px 20px',
        overflow: 'auto',
        '& .__textBox': {
          margin: '15px 0',
          padding: '0 15px',
          backgroundColor: constants.COLOR_GRAY,
          border: '1px solid ' + constants.COLOR_GRAY_LIGHT2,
          borderRadius: 10,
          '& p': {
            textAlign: 'justify',
          },
        },
        '& .__buttonBlock': {
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20,
          '& .__copyButton': {
            marginRight: 15,
          },
        },
        '& .__close': {
          position: 'absolute',
          top: 5,
          right: 5,
        },
        '& .__copiedMsg': {
          position: 'absolute',
          marginTop: 15,
          padding: 10,
          backgroundColor: 'rgba(44, 182, 255, 0.65)',
          color: constants.COLOR_WHITE,
          fontWeight: 'bold',
          borderRadius: 10,
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },
      },
    },

    textLower: {
      textTransform: 'lowercase',
    },

    bottomKartePDFContainer: {
      margin: 8,
      marginRight: 16,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })

export default withStyles(useStyles)(connector(Index))
