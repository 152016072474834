import { useState } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { useHintModalEpic } from 'pages/hint/_shared/hooks/useHintModalEpic'

// -----------------------------
// interface
// -----------------------------
export type IuseDialogDeleteProps = {
  onOpenDialog: () => void
  onCloseDialog: () => void
  onDoDelete: () => Promise<void>
  onDoNotDelete: () => void
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useDialogDelete = (): IuseDialogDeleteProps => {
  const [open, setOpen] = useState<boolean>(false)
  const [id, setId] = useState<string>('')
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const modalSelector = useSelector((state: RootStateType) => state.hint.ui)
  const hintModalEpic = useHintModalEpic()

  /**
   * ダイアログをオープンする時の処理
   */
  const onOpenDialog = (): void => {
    setOpen(true)
    setId(modalSelector.modal.status === 'UPDATE' ? hintSelector.hint.id : modalSelector.modal.id)
  }

  /**
   * ダイアログをクローズする時の処理
   */
  const onCloseDialog = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の削除しないときのボタンを押した時
   */
  const onDoNotDelete = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の削除するボタンを押した時
   */
  const onDoDelete = async (): Promise<void> => {
    await hintModalEpic.deleteHintEpic(id)
  }

  /**
   * ダイアログの状態を返す
   */
  const getOpen = (): boolean => {
    return open
  }

  return {
    onOpenDialog,
    onCloseDialog,
    onDoNotDelete,
    onDoDelete,
    getOpen,
  }
}
