import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'

import ArrowLeftSvg from '../assets/svg/arrowLeft.svg'
import ArrowLeftInactiveSvg from '../assets/svg/arrowLeftInactive.svg'
import ArrowRightSvg from '../assets/svg/arrowRight.svg'
import ArrowRightInactiveSvg from '../assets/svg/arrowRightInactive.svg'
import ThreePointSvg from '../assets/svg/threePoint.svg'
import { constants } from 'assets'

type Props = {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  totalContentCount: number
  numPerPage: number
}

export const PaginationButtons: React.FC<Props> = ({ page, setPage, totalContentCount, numPerPage }) => {
  const classes = useStyles()

  const lastPage = Math.ceil(totalContentCount / numPerPage)

  const buttonPages = {
    one:
      lastPage === 5
        ? 2
        : lastPage === 4
        ? 2
        : page === 1
        ? 1
        : page === lastPage && lastPage !== 2
        ? page - 2
        : page - 1,
    two:
      lastPage === 5 ? 3 : lastPage === 4 ? 3 : page === 1 ? 2 : page === lastPage && lastPage !== 2 ? page - 1 : page,
    three: lastPage === 5 ? 4 : lastPage === 4 ? 4 : page === 1 ? 3 : page === lastPage ? page : page + 1,
  }

  const handlePageDown = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
      window.scrollTo(0, 0)
    }
  }

  const handlePageUp = () => {
    if (page < lastPage) {
      setPage((prev) => prev + 1)
      window.scrollTo(0, 0)
    }
  }

  const handlePage = (pageNum: number) => () => {
    if (pageNum >= 1 && pageNum <= lastPage && pageNum !== page) {
      setPage(pageNum)
      window.scrollTo(0, 0)
    }
  }

  if (totalContentCount <= numPerPage) {
    return null
  }

  return (
    <div>
      <div className={classes.paginationButtons}>
        {page > 1 ? (
          <NoStyleButton type="button" onClick={handlePageDown}>
            <img className={classes.arrowButton} src={ArrowLeftSvg} alt="left arrow button" />
          </NoStyleButton>
        ) : (
          <img className={classes.arrowButton} src={ArrowLeftInactiveSvg} alt="inactive left arrow button" />
        )}

        <div className={classes.numButtonWrapper}>
          {((page > 2 && lastPage !== 3) || lastPage === 4 || lastPage === 5) && (
            <NoStyleButton type="button" onClick={handlePage(1)}>
              <div className={classNames([classes.numButton, page === 1 && classes.numButtonActive])}>1</div>
            </NoStyleButton>
          )}
          {lastPage > 5 && page > 3 && (
            <div className={classes.threePoint}>
              <img src={ThreePointSvg} alt="three point" />
            </div>
          )}
          <NoStyleButton type="button" onClick={handlePage(buttonPages.one)}>
            <div className={classNames([classes.numButton, page === buttonPages.one && classes.numButtonActive])}>
              {buttonPages.one}
            </div>
          </NoStyleButton>
          <NoStyleButton type="button" onClick={handlePage(buttonPages.two)}>
            <div className={classNames([classes.numButton, page === buttonPages.two && classes.numButtonActive])}>
              {buttonPages.two}
            </div>
          </NoStyleButton>
          {lastPage > 2 && (
            <NoStyleButton type="button" onClick={handlePage(buttonPages.three)}>
              <div className={classNames([classes.numButton, page === buttonPages.three && classes.numButtonActive])}>
                {buttonPages.three}
              </div>
            </NoStyleButton>
          )}
          {lastPage > 5 && page < lastPage - 2 && (
            <div className={classes.threePoint}>
              <img src={ThreePointSvg} alt="three point" />
            </div>
          )}
          {((lastPage > 5 && page < lastPage - 1) || lastPage === 5) && (
            <NoStyleButton type="button" onClick={handlePage(lastPage)}>
              <div className={classNames([classes.numButton, page === lastPage && classes.numButtonActive])}>
                {lastPage}
              </div>
            </NoStyleButton>
          )}
        </div>

        {page < lastPage ? (
          <NoStyleButton type="button" onClick={handlePageUp}>
            <img className={classes.arrowButton} src={ArrowRightSvg} alt="right arrow button" />
          </NoStyleButton>
        ) : (
          <img className={classes.arrowButton} src={ArrowRightInactiveSvg} alt="inactive right arrow button" />
        )}
      </div>
      <div className={classes.activePage}>
        {page}/{lastPage}ページ
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    paginationButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        columnGap: 40,
      },
    },
    arrowButton: {
      padding: '5px 8px',
    },
    numButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    numButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 20,
    },
    numButtonActive: {
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: constants.COLOR_WHITE,
    },
    threePoint: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        verticalAlign: 'top',
      },
    },
    activePage: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 16,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
  }),
  { name: 'PaginationButtons' }
)
