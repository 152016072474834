import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  buttons: React.ReactNode[]
  fixed?: boolean
  ownRootStyles?: React.CSSProperties
  ownRootStylesDownXs?: React.CSSProperties
}

export const ButtonContainer: React.FC<Props> = (props) => {
  const classes = useStyles({ fixed: props.fixed })
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div
      className={classes.root}
      style={isXsDown ? { ...props.ownRootStyles, ...props.ownRootStylesDownXs } : { ...props.ownRootStyles }}
    >
      {(() => {
        switch (props.buttons.length) {
          case 1:
            return <div className={classes.base}>{props.buttons[0]}</div>

          case 2:
            return (
              <div className={`${classes.base} ${classes.double}`}>
                <div>{props.buttons[1]}</div>
                <div>{props.buttons[0]}</div>
              </div>
            )
          default:
            return <div className={classes.base}>{props.buttons}</div>
        }
      })()}
    </div>
  )
}

type StyleProps = {
  fixed?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({ fixed }) => ({
      width: fixed ? '100vw' : undefined,
      left: fixed ? 0 : undefined,
      bottom: fixed ? 0 : undefined,
      position: fixed ? 'fixed' : undefined,
      display: fixed ? 'flex' : undefined,
      justifyContent: 'center',
      padding: fixed ? 16 : '0 16px',
      backgroundColor: fixed ? `${constGhost.COLOR_WHITE}` : undefined,
      zIndex: 10,

      [theme.breakpoints.down('xs')]: {
        padding: fixed ? '12px 16px' : '0 16px',
      },
    }),

    base: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 16,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        gap: 12,
      },
    },

    double: {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        flexDirection: 'column-reverse',
      },
    },
  }),
  { name: 'ButtonContainer' }
)
