import { OnboardingDaily } from 'utils/generated'

const categoryNames: { [k in OnboardingDaily]: string } = {
  [OnboardingDaily.GreatShade]: '仕事が順調でいい感じ',
  [OnboardingDaily.BadHumanRelation]: 'プライベートも充実していい感じ',
  [OnboardingDaily.BadFeeling]: 'なぜかちょっと調子良い',
  [OnboardingDaily.BadWorkProgress]: '仕事の進み具合が不安',
  [OnboardingDaily.BadPrivateTime]: 'プライベートがちょっとモヤモヤ',
  [OnboardingDaily.BadFeelingSomewhat]: 'なぜかちょっと調子悪い',
  // ↓ no use
  [OnboardingDaily.YesterdaysMistake]: '-',
  [OnboardingDaily.NeedToRefresh]: '-',
  [OnboardingDaily.WantToGoHome]: '-',
  [OnboardingDaily.Normal]: '-',
}

export const OnboardingDailyChecks = (category?: string) => {
  switch (category) {
    case OnboardingDaily.GreatShade:
      return categoryNames[OnboardingDaily.GreatShade]
    case OnboardingDaily.BadHumanRelation:
      return categoryNames[OnboardingDaily.BadHumanRelation]
    case OnboardingDaily.BadFeeling:
      return categoryNames[OnboardingDaily.BadFeeling]
    case OnboardingDaily.BadWorkProgress:
      return categoryNames[OnboardingDaily.BadWorkProgress]
    case OnboardingDaily.BadPrivateTime:
      return categoryNames[OnboardingDaily.BadPrivateTime]
    case OnboardingDaily.BadFeelingSomewhat:
      return categoryNames[OnboardingDaily.BadFeelingSomewhat]
    case OnboardingDaily.YesterdaysMistake:
      return categoryNames[OnboardingDaily.YesterdaysMistake]
    case OnboardingDaily.NeedToRefresh:
      return categoryNames[OnboardingDaily.NeedToRefresh]
    case OnboardingDaily.WantToGoHome:
      return categoryNames[OnboardingDaily.WantToGoHome]
    case OnboardingDaily.Normal:
      return categoryNames[OnboardingDaily.Normal]
    default:
      return ''
  }
}
