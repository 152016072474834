import { connect, ConnectedProps } from 'react-redux'

import { UpdateAccountInput } from '../../../../services/api/updateAccount'
import { RootStateType } from '../../../../store'
import { UserActions } from '../../../../stores/user/action'

const mapDispatchToProps = (dispatch: any) => ({
  submit: (params: UpdateAccountInput) => dispatch(UserActions.updateAccount.started({ params })),
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
