import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { DefaultButton } from 'pages/teams/components/DefaultButton'

interface Props {
  type: 'team' | 'team-member' | undefined
  textCode: 'utf-8' | 'sift-jis'
}
interface StyleProps {
  marginBottom?: number
}

export const CSVTemplateDownloader: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles(props)
  const isUtf8 = props.textCode === 'utf-8'
  const isCodeURL = isUtf8 ? '' : 'sjis-'
  const isSmDown = useCustomMediaQuery('down', 'sm')

  const downloadCSV = () => {
    location.href = `/templates/${isCodeURL}add-${props.type}.csv`
  }

  if (!props.type) return null

  return (
    <div className={classes.CSVTemplateDownloaderRoot}>
      <DefaultButton
        title={isUtf8 ? 'macOS用' : 'Windows用'}
        color="team_white"
        style={{
          width: isSmDown ? '100%' : 100,
          minWidth: 100,
          height: 36,
          fontSize: 10,
          textTransform: 'none',
        }}
        onClick={downloadCSV}
      />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    CSVTemplateDownloaderRoot: {
      textAlign: 'center',
      marginBottom: (props: StyleProps) => props.marginBottom ?? 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'CSVTemplateDownloader' }
)
