import { GhostProgress, GhostTeam } from 'utils/generated'

type TeamKeys = keyof GhostTeam
type SelectTimerItem = { [key in 'number' | 'minute' | 'userIds' | 'started']: TeamKeys }

const s1SelectTimerItem: SelectTimerItem = {
  number: 'timerS1SelectNumber',
  minute: 'timerS1SelectMinute',
  userIds: 'timerS1SelectUserIds',
  started: 'timerS1SelectStarted',
}
const s2SelectTimerItem: SelectTimerItem = {
  number: 'timerS2SelectNumber',
  minute: 'timerS2SelectMinute',
  userIds: 'timerS2SelectUserIds',
  started: 'timerS2SelectStarted',
}
const s3SelectTimerItemReason: SelectTimerItem = {
  number: 'timerS3SelectNumberReason',
  minute: 'timerS3SelectMinuteReason',
  userIds: 'timerS3SelectUserIdsReason',
  started: 'timerS3SelectStartedReason',
}
const s3SelectTimerItem: SelectTimerItem = {
  number: 'timerS3SelectNumber',
  minute: 'timerS3SelectMinute',
  userIds: 'timerS3SelectUserIds',
  started: 'timerS3SelectStarted',
}
const s4SelectTimerItem: SelectTimerItem = {
  number: 'timerS4SelectNumber',
  minute: 'timerS4SelectMinute',
  userIds: 'timerS4SelectUserIds',
  started: 'timerS4SelectStarted',
}

export const progressSelectTimerItem = (team: GhostTeam | undefined): SelectTimerItem | null => {
  switch (team?.progress) {
    case GhostProgress.S1SelectingPresentationOptions:
    case GhostProgress.S1DonePresentationOptions:
      return s1SelectTimerItem
    case GhostProgress.S2SelectingPresentationOptions:
    case GhostProgress.S2DonePresentationOptions:
      return s2SelectTimerItem
    case GhostProgress.S3SelectingPresentationOptionsReason:
    case GhostProgress.S3DonePresentationOptionsReason:
      return s3SelectTimerItemReason
    case GhostProgress.S3SelectingPresentationOptions:
    case GhostProgress.S3DonePresentationOptions:
      return s3SelectTimerItem
    case GhostProgress.S4SelectingPresentationOptions:
    case GhostProgress.S4DonePresentationOptions:
      return s4SelectTimerItem
    default:
      return null
  }
}
