import { useState } from 'react'

// -----------------------------
// interface
// -----------------------------
export type IuseDialogCancelProps = {
  onCloseDialog: () => void
  onOpenDialog: () => void
  onDoNotCancel: () => void
  onDoCancel: () => void
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useDialogCancel = (): IuseDialogCancelProps => {
  const [open, setOpen] = useState<boolean>(false)

  /**
   * ダイアログをクローズする時の処理
   */
  const onCloseDialog = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログをオープンする時の処理
   */
  const onOpenDialog = (): void => {
    setOpen(true)
  }

  /**
   * ダイアログ内の「いいえ」ボタンを押した時
   */
  const onDoNotCancel = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の「はい」ボタンを押した時
   */
  const onDoCancel = (): void => {
    setOpen(true)
    window.location.reload()
  }

  /**
   * ダイアログの状態を返す
   */
  const getOpen = (): boolean => {
    return open
  }

  return {
    onCloseDialog,
    onOpenDialog,
    onDoNotCancel,
    onDoCancel,
    getOpen,
  }
}
