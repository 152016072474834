import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostMemberRole } from 'utils/generated'

type Props = {
  name: string
  role: GhostMemberRole
  ownStyles?: React.CSSProperties
}

export const MemberInfo: React.FC<Props> = ({ name, role, ownStyles }) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ ...ownStyles }}>
      <img
        src={process.env.PUBLIC_URL + '/assets/ghost/personIcon.svg'}
        alt="personIcon"
        className={classes.personIcon}
      />
      <div className={classes.nameWrapper}>
        <span className={classes.name}>{name}</span>
        <span className={classes.role}>{role === GhostMemberRole.Leader ? 'ファシリテーター' : 'メンバー'}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      flex: 1,
      width: 260,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    personIcon: {
      width: 32,
      height: 32,
    },
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    name: {
      fontSize: 16,
      fontWeight: 600,
      color: constGhost.COLOR_BLACK3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    role: {
      fontSize: 10,
      fontWeight: 300,
      color: constGhost.COLOR_GRAY2,
    },
  }),
  { name: 'MemberInfo' }
)
