import { useState, useCallback, useEffect } from 'react'

import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

export const useCognito = () => {
  const [cognito, setCognito] = useState<CognitoUser | undefined>()
  const [cognitoAttributes, setCognitoAttributes] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      setCognito(user)
      setCognitoAttributes(user.attributes)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }, [setCognito])

  useEffect(() => {
    refresh()
  }, [refresh])

  return [cognito, cognitoAttributes, loading, error, refresh] as const
}
