import React, { useCallback, useContext, useEffect, useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { queryGetOnboardingPostListOfOneUser } from 'pages/onboarding/graphql'
import { useDaily } from 'pages/onboarding/hooks/daily'
import { useTeam } from 'pages/onboarding/hooks/team'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { useLastVisitAction } from 'pages/onboarding/service/lastVisitAction'
import { OnboardingPost } from 'utils/generated'
import { OnboardingDaily } from 'utils/generated'

import { OnbContext } from '../PagesRoot'
import { useTutorial } from '../tutorial/logics/hooks'
import { TutorialProps } from '../tutorial/Tutorial'

import { CheckDailyModal } from './components/CheckDailyModal'
import { CompleteModal } from './components/CompleteModal'
import { ListBtn } from './components/ListBtn'
import { ToolbarTeams } from './components/ToolbarTeams'

export const DailyCheckBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { team } = useTeam(teamId)
    const { postDaily, createPost, loading } = useDaily(teamId)

    return {
      teamId,
      teamMember,
      team,
      postDaily,
      createPost,
      loading,
    }
  },
}

export const Index: React.FC<{ tutorial?: TutorialProps }> = ({ tutorial }) => {
  // tutorial
  useTutorial(tutorial)

  // deps
  const { teamId, teamMember, team, postDaily, loading, createPost } = DailyCheckBloc.useAdapter()

  const [created, setCreated] = useState(false)
  useLastVisitAction(teamMember?.teamId, teamMember.userId)

  // vague bl
  const onCreateDaily = async (daily: OnboardingDaily) => {
    if (postDaily?.daily) {
      return
    }
    if (!teamMember?.teamId) {
      return
    }
    try {
      await createPost(daily)
      setCreated(true)
    } catch (e) {
      console.log('error', e)
    }
  }

  const classes = useStyles()
  const handleClose = () => {
    setCreated(false)
    window.location.href = `/onboarding/${teamId}/timeline` //make re-render by it  not history.push
  }
  const [posts, setPosts] = useState<OnboardingPost[]>([])
  const { updateTeamMember } = useTeamManage()
  const getPostList = useCallback(async () => {
    const response = await queryGetOnboardingPostListOfOneUser({ teamId, userId: teamMember.userId, first: 1000 })
    if (response.getOnboardingPostListOfOneUser?.items) {
      setPosts(response.getOnboardingPostListOfOneUser?.items)
    }
  }, [teamId, teamMember.userId])
  useEffect(() => {
    getPostList()
  }, [getPostList])

  const DailyType = posts.filter((item: OnboardingPost) => {
    return item.type === 'DAILY'
  })

  const [isNotice, setIsNotice] = useState(!teamMember.dailyConfirmedAt)
  const [isChecked, setIsChecked] = useState(false)
  const payload = {
    teamId,
    dailyConfirmedAt: new Date().toISOString(),
  }

  const handleCloseNotice = async () => {
    if (isChecked) {
      await updateTeamMember(payload)
      setIsNotice((prev) => !prev)
    } else {
      setIsNotice((prev) => !prev)
    }
  }

  const handleCheck = async () => {
    setIsChecked((prev) => !prev)
  }

  return (
    <>
      {DailyType.length >= 1 && (
        <CheckDailyModal
          open={isNotice}
          handleCloseNotice={handleCloseNotice}
          handleCheck={handleCheck}
          isChecked={isChecked}
        />
      )}

      <div className={classes.title}>デイリーチェック</div>
      <div>
        <ToolbarTeams teamId={teamMember?.teamId} teamLabel={team ? `${team.title}(${team.memberCount || 0})` : ''} />
      </div>
      <div className={classes.contentBody}>
        <div className={classes.sentence}>今日もデイリーチェックをしてチームのみんなに今の気持ちを共有しよう！</div>
        <ListBtn onSelect={onCreateDaily} selected={postDaily?.daily} loading={loading} />
      </div>

      <CompleteModal open={created} handleClose={handleClose} condition={postDaily?.daily} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    paddingTop: 62,
    paddingBottom: 32,
    maxWidth: 560,
    margin: '0 auto',
    [theme.breakpoints.down(592)]: {
      margin: '0 16px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '24px 0',
    textAlign: 'center',
  },
  sentence: {
    fontSize: '14px',
    fontWeight: 300,
    margin: '24px 16px',
  },
  contentBody: {
    marginTop: 16,
    marginBottom: 16,
  },
}))

export default Index
