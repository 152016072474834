import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { render } from 'react-dom'

import { forceGetVoteResult } from 'pages/ghost/api/report/handlers'
import { GP } from 'pages/ghost/assets/pages'
import { ErrorSnapshot } from 'pages/ghost/utils/error'

import { ButtonForGhost, UsefulModal } from '../atoms'


interface DialogContent {
  title?: string
  mes?: string
  reload?: boolean
}

export type ErrorDialog = 'generic' | 'reload' | 'reconnect' | 'forceResult'

export const callDialog = (type: ErrorDialog) => {
  const node = document.getElementById('modal-tooltip-div')
  if (node) {
    switch (type) {
      case 'reconnect':
        render(<ReconnectDialog />, node)
        break
      case 'reload':
        render(<ReloadDialog />, node)
        break
      default:
        render(<BaseDialog />, node)
        break
    }
  }
}

/**
 * HOF component for diffent dialogs
 */
export const baseDialog =
  ({
    title = 'エラー',
    mes = '何らかの問題が発生しました。申し訳ございませんが、ページを更新して再度お試しください。くり返し動作しない場合、お問合せください。',
    reload = true,
  }: DialogContent): React.FC<{ snapshot?: ErrorSnapshot }> =>
  ({ snapshot }) => {
    const styles = useStyles()

    return (
      <UsefulModal open handleModalChange={() => undefined}>
        <div className={styles.head}>{title}</div>
        <div className={styles.body}>{mes}</div>
        {reload && (
          <ButtonForGhost
            buttonBodyColor="red"
            bodyText={<span>ページを更新する</span>}
            onClick={() => window.location.reload()}
          />
        )}
        {snapshot && (
          // thogh this should not be done by users,
          // just providing inteface to 'manually' exec.
          <ButtonForGhost
            buttonBodyColor="green"
            bodyText={<span>再試行する</span>}
            onClick={() => {
              console.log(snapshot.teamId)
              snapshot.teamId && forceGetVoteResult({ teamId: snapshot.teamId })
            }}
          />
        )}
        <div style={{ marginTop: 8, textAlign: 'center' }}>
          <a href={GP.start}>トップに戻る</a>
        </div>
      </UsefulModal>
    )
  }

export const BaseDialog: React.FC = baseDialog({})

export const ReloadDialog: React.FC = baseDialog({
  mes: 'データの取得に失敗しました。申し訳ございませんが、ページを更新して再度お試しください。繰り返し動作しない場合、お問合せください。',
})

export const ReconnectDialog: React.FC = baseDialog({
  title: '接続エラー',
  mes: 'サーバとの接続が途切れました。申し訳ございませんが、ページを更新して再度お試しください。繰り返し動作しない場合、お問合せください。',
})

export const ForceResultDialog: React.FC<{ snapshot?: ErrorSnapshot }> = baseDialog({
  title: 'サーバーエラー',
  mes: 'サーバー上で問題が発生しました。申し訳ありませんが、下記の再試行ボタンを押してください。繰り返し動作しない場合、お問合せください。',
  reload: false,
})

const useStyles = makeStyles((theme: Theme) => ({
  head: {
    width: '100%',
    fontWeight: 'bold',
    marginBottom: 12,
  },
  body: {
    width: '100%',
    marginBottom: 12,
  },
}))
