import * as constants from 'assets/constants'

// useful type for TopPage
export type topicAndText = {
  topic: string
  topic2?: string
  text: string
}

// text for TopPage
// because so long or reusable
export const topText: topicAndText = {
  topic: 'チームに「成果」を。メンバーに「喜び」を。',
  text: 'Cocolaboはエンゲージメントの高いチーム作りを支援します。「チームと人」「仕事と人」「人と人」をつなげます。\n高い成果を出すとともに、このチームにいてよかったとメンバーが心から思えるチーム作りを。',
}
export const concept1: topicAndText = {
  topic: '感情と価値観の\nコミュニケーション',
  text: '業務情報を共有するツールはメールやチャットなど多くあります。Cocolaboは一人ひとりの感情や価値観を共有し、互いにつなぐことで強いチームを作ります。',
}
export const concept2: topicAndText = {
  topic: 'チームに手応えをもたらす\n専用アシスタント',
  text: '多数の業務を推進しながら、チーム作りを行うことは難しく、また途中手応えも感じにくいものです。Cocolaboは行うべきことをアシストし、手応えを感じることを大事に設計しています。',
}
export const developerSP: topicAndText = {
  topic: 'あたらしい、個人と組織の関係をコンセプトに、各社のエンゲージメント向上支援を実施',
  text: 'ソフトバンク株式会社、カゴメ株式会社、株式会社バンダイ、株式会社野村総合研究所、など数100社に対して、管理職支援などを実施',
}
export const developerPC: topicAndText = {
  topic: 'あたらしい、個人と組織の関係をコンセプトに、\n各社のエンゲージメント向上支援を実施',
  text: 'ソフトバンク株式会社、カゴメ株式会社、株式会社バンダイ、株式会社野村総合研究所など数100社に対して、管理職支援などを実施',
}
export const recommendComment: topicAndText = {
  topic: '心理学に基づいた、新時代のマネジメントツール',
  text: 'Cocolabo（ココラボ）は，NEWONE社による管理職研修の豊富な実績と心理学の動機づけ理論をもとに生まれた，新しいマネジメントツールです。\nコロナ禍においてリモートワークが推奨されるなど，働き方が大きく変わる中，従業員は物理的に分散しながらも，自律的に働き，協働することが求められています。こうした従業員を支援する管理職も，新たな課題に直面していると言えるでしょう。Cocolabo（ココラボ）の活用により，従業員のワーク・エンゲージメントが向上し，活き活きとした社会の推進につながることを期待しています。',
}
export const recommendPerson: topicAndText = {
  topic: '島津 明人 氏',
  topic2: '慶應義塾大学 総合政策学部 教授',
  text: 'ワーク・エンゲイジメント研究の第一人者である 慶應義塾大学 総合政策学部 教授　島津 明人氏が顧問に就任。エンゲイジメントを軸にした研修プログラムの監修業務と、組織開発コンサルティングの支援体制を強化。',
}

// UsefulStyles for TopPage
export const COLOR_BUTTONORANGE = '#E8620D'
export const COLOR_BUTTONGREEN = '#00838C'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_HOVER = '#009DA8'
export const COLOR_GRAY = '#F8F8F8'
export const COLOR_TEXT_GRAY = '#9D9D9D'
export const COLOR_BACKGROUND_LIGHT = '#F4FBFC'

// responsive SP=>0~600px  PC=>1200px~
export const breakPointsPC = 1200
export const breakPointsSP = 600 + 48 //for margin 48px

// you can overwrite cssProperties at own pages
interface CSSProperties {
  [index: string]: string | number
}
export const topicStyles: CSSProperties = {
  fontSize: 24,
  fontWeight: 'bold',
}
export const smallTopicTextStyles: CSSProperties = {
  fontSize: 14,
}

export const fixAnchorLinkPosition: CSSProperties = {
  content: '""',
  display: 'block',
  height: constants.HEADER_HEIGHT,
  marginTop: constants.HEADER_HEIGHT * -1,
}

export const FONT_FAMILY_TOP_TITLE =
  '"Fjalla One", "Lato","Noto Sans JP", "游ゴシック Medium", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif'
export const FONT_FAMILY_TOP_TEXT =
  '"Lato","Noto Sans JP", "游ゴシック Medium", "YuGothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", sans-serif'

export const anchorLinkId = [
  'TopAbout',
  'UseScenes',
  'TopConcept',
  'TopUserVoice',
  'TopDeveloper',
  'TopRecommend',
  'TopFAQ',
]

export const anchorLinks: {
  id: string
  label: string
}[] = [
  {
    id: anchorLinkId[0],
    label: 'Cocolaboとは',
  },
  {
    id: anchorLinkId[1],
    label: '利用シーン',
  },
  {
    id: anchorLinkId[2],
    label: 'コンセプト',
  },
  {
    id: anchorLinkId[3],
    label: '導入事例',
  },
  {
    id: anchorLinkId[4],
    label: 'プロダクト開発者',
  },
  {
    id: anchorLinkId[5],
    label: 'プロダクト推薦者',
  },
  {
    id: anchorLinkId[6],
    label: 'よくある質問',
  },
]
