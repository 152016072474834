import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { withStyles, createStyles, Theme, StyleRules, WithStyles, IconButton } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Rating from '@material-ui/lab/Rating'
import 'date-fns'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../../../assets/constants'
import { calcAnketValue } from '../../../services/calcAnketValue'
import { isStatusCloseByObject, isStatusFailedByObject } from '../../../services/utils/weekday'
import { IActionItem } from '../../../stores/setupaction/reducer'

import { connector, ContainerProps } from './Card.container'
import ModalMission from './ModalMission.component'
import ModalQuestionnaire from './ModalQuestionnaire.component'




interface IOwnProps {
  item: IActionItem
  basePeriod: number
  checkOver: boolean
  handelOpenOver: () => void
}

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  // console.log('props.item', props.item)
  const { classes } = props

  // console.log('Card', props)

  // Collapse
  const [checked, setChecked] = React.useState(false)

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  // Modal
  const [open, setOpen] = React.useState(false)

  const handleAction = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  // Page
  const handleStartPage = (e: any) => {
    const type = e.currentTarget.getAttribute('data-type')
    const url = e.currentTarget.getAttribute('data-url')

    if (type === '動画') {
      window.location.href = url
    }

    if (type === 'ツール') {
      const path = url.replace('https://cocolabo.club', '')
      props.history.push(path)
      window.scrollTo(0, 0)
    }
  }

  const [anketTotal, setAnketTotal] = React.useState('')

  React.useEffect(() => {
    if (!props.setupaction.refresh) {
      if (
        props.item &&
        props.item.anketValues &&
        props.item.anketValues.weekDate &&
        props.user.setupStartedAtList &&
        props.setup.currentSetupStartedAt
      ) {
        calcAnketValue(props.item.anketValues.weekDate, props.setup.currentSetupStartedAt).then((res) => {
          setAnketTotal(res.toFixed(1))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setupaction.refresh])

  React.useEffect(() => {
    if (props.checkOver) return

    isStatusFailedByObject(props.basePeriod, props.item) && props.handelOpenOver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper className={classes.root}>
      <div
        className={`__list_card_container ${isStatusCloseByObject(props.basePeriod, props.item) ? 'statusClose' : ''} ${
          isStatusFailedByObject(props.basePeriod, props.item) ? 'statusFailed' : ''
        }`}
        onClick={handleAction}
      >
        <div className="__list_card_container__content">
          <div className="__list_card_container__content__image">
            <img
              src={
                process.env.PUBLIC_URL +
                `/assets/svg/setupList/${
                  props.item.category ? `search_${props.item.category.toLowerCase()}` : 'category_anket'
                }.svg`
              }
              alt={props.item.category ? props.item.category : 'anket'}
            />
          </div>
          <div className="__list_card_container__content__body">
            <div className="__list_card_container__content__body__ratings">
              {props.item.importance && <Rating name="size-small" value={props.item.importance} max={3} size="small" />}

              {isStatusCloseByObject(props.basePeriod, props.item) && (
                <>
                  <img
                    className="__list_card_container__content__body__ratings__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/setupList/statusClose.svg'}
                    alt={'close'}
                  />
                  <span className="__list_card_container__content__body__ratings__description statusClose">
                    期限間近
                  </span>
                </>
              )}
              {isStatusFailedByObject(props.basePeriod, props.item) && (
                <>
                  <img
                    className="__list_card_container__content__body__ratings__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/setupList/statusFailed.svg'}
                    alt={'failed'}
                  />
                  <span className="__list_card_container__content__body__ratings__description statusFailed">
                    期限超過
                  </span>
                </>
              )}
            </div>
            <div className="__list_card_container__content__body__title">{props.item.mission}</div>
            <div className="__list_card_container__content__body__description">{props.item.why}</div>
          </div>
          <div
            className={`__list_card_container__content__action ${
              props.item.missionValues || props.item.anketValues ? 'active' : ''
            }`}
          >
            {(props.item.missionValues && props.item.missionValues.value && props.item.missionValues.value > 0) ||
            (props.item.anketValues && props.item.anketValues.total !== undefined) ? (
              <>
                {props.item.missionValues && (
                  <img
                    className={`__list_card_container__content__action__valueIcon`}
                    src={`${process.env.PUBLIC_URL}/assets/svg/setupList/face-${props.item.missionValues.value}-a.svg`}
                    alt={'face'}
                  />
                )}

                {props.item.anketValues && (
                  <div className="__list_card_container__content__action__value">{anketTotal}</div>
                )}
              </>
            ) : (
              <FontAwesome name="check" color={constants.COLOR_MAIN_DARK} size={18} />
            )}
          </div>
        </div>
        {props.item.missionValues?.comment && (
          <div className="__list_card_container__comment">{props.item.missionValues.comment}</div>
        )}
      </div>

      <div
        className={`__list_card_container__moreContent  ${
          isStatusCloseByObject(props.basePeriod, props.item) ? 'statusClose' : ''
        } ${isStatusFailedByObject(props.basePeriod, props.item) ? 'statusFailed' : ''}`}
      >
        <Collapse in={checked} className="__list_card_container__moreContent__collapse">
          <div className="__list_card_container__moreContent__collapse__title">アクション</div>
          <div className="__list_card_container__moreContent__collapse__description">{props.item.what}</div>
          <div className="__list_card_container__moreContent__collapse__title">達成基準</div>
          <div className="__list_card_container__moreContent__collapse__description">{props.item.how}</div>
          {props.item.type && props.item.typeTitle && props.item.url && (
            <>
              <div className="__list_card_container__moreContent__collapse__title">参考</div>
              <div className="__list_card_container__moreContent__collapse__description">
                <span
                  onClick={handleStartPage}
                  data-type={props.item.type}
                  data-url={props.item.url}
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton className="__list_card_container__moreContent__collapse__description__iconButton">
                    <CheckCircleIcon
                      fontSize="small"
                      className="__list_card_container__moreContent__collapse__description__checkCircleIcon"
                    />
                  </IconButton>
                  <span>【{props.item.type}】</span>
                  <span className="__list_card_container__moreContent__collapse__description__typeTitle">
                    {props.item.typeTitle}
                  </span>
                </span>
              </div>
            </>
          )}
        </Collapse>
      </div>

      {(props.item.what || props.item.how) && (
        <div className={`__list_card_more ${checked ? '__checked' : ''}`} onClick={handleChange}>
          {checked ? (
            <div>
              <FontAwesome name="times" color={constants.TEXT_GRAY} size={14} />
              閉じる
            </div>
          ) : (
            <div>
              <FontAwesome name="plus" color={constants.COLOR_WHITE} size={14} />
              詳しくみる
            </div>
          )}
        </div>
      )}

      {props.item.what || props.item.how ? (
        <ModalMission open={open} handleClose={handleClose} item={props.item} values={props.item.missionValues} />
      ) : (
        <ModalQuestionnaire open={open} handleClose={handleClose} item={props.item} values={props.item.anketValues} />
      )}
    </Paper>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      // background: 'white',
      // boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
      // width: '300px',
      marginBottom: '16px',

      position: 'relative',

      '& .__list_card_container': {
        padding: '16px',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',

        '&.statusClose': {
          background: constants.COLOR_ACTION_CLOSE,
        },
        '&.statusFailed': {
          background: constants.COLOR_ACTION_FAILED,
        },

        '&__content': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // padding: '16px',

          '&__image': {
            width: '100px',
            height: '100px',
            display: 'flex',
            background: '#e5e5e5',
            color: '#b1b1b1',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100px',
            minHeight: '100px',
            marginRight: '16px',
            backgroundColor: '#fffed9',
            borderRadius: 10,
            padding: 4,

            '& img': {
              width: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
              borderRadius: 10,
            },
          },

          '&__body': {
            width: '100%',
            paddingRight: 8,

            '&__ratings': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4,
              '& .MuiRating-root': {
                marginRight: 8,
              },

              '&__icon': {
                // display:"flex",
                width: '10px',
                height: '10px',
                margin: '0 4px',
              },
              '&__description': {
                fontSize: '10px',
                fontWeight: 'bold',
                '&.statusClose': {
                  color: constants.COLOR_ACTION_CLOSE_DARK,
                },
                '&.statusFailed': {
                  color: constants.COLOR_ACTION_FAILED_DARK,
                },
              },
            },
            '&__title': {
              fontSize: '16px',
              fontWeight: 'bold',
            },
            '&__description': {
              // fontSize: '1px',
            },
          },

          '&__action': {
            background: constants.COLOR_MAIN_LIGHT,

            minWidth: '40px',
            minHeight: '40px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            // position: 'absolute',
            // right: '16px',
            // top: '16px',
            // border: '2px solid blue',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
            '&.active': {
              backgroundColor: '#B9FCFF',
            },
            '&__value': {
              fontSize: '18px',
              fontWeight: 'bold',
              color: constants.COLOR_MAIN_DARK,
            },
            '&__valueIcon': {
              width: '100%',
              padding: '4px',
            },
          },
        },

        '&__comment': {
          background: constants.COLOR_GRAY,
          marginTop: '16px',
          padding: '10px',
          whiteSpace: 'pre-wrap',
        },

        '&__moreContent': {
          '&.statusClose': {
            background: constants.COLOR_ACTION_CLOSE,
          },
          '&.statusFailed': {
            background: constants.COLOR_ACTION_FAILED,
          },

          '&__collapse': {
            '& .MuiCollapse-wrapper': {
              padding: '16px',
            },
            '&__title': {
              color: constants.COLOR_MAIN,
              borderBottom: '1px solid',
              marginBottom: '8px',
              paddingBottom: '4px',
            },
            '&__description': {
              paddingBottom: '16px',
              '&__iconButton': {
                padding: 4,
              },
              '&__checkCircleIcon': {
                color: constants.COLOR_MAIN,
              },
              '&__typeTitle': {
                color: constants.COLOR_MAIN,
                borderBottom: `1px solid ${constants.COLOR_MAIN_DARK}`,
              },
            },
          },
        },
      },

      '& .__list_card_more': {
        background: constants.TEXT_GRAY,
        padding: '4px 16px',
        color: constants.COLOR_WHITE,
        cursor: 'pointer',
        textAlign: 'center',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',

        '&.__checked': {
          color: constants.TEXT_GRAY,
          background: constants.COLOR_WHITE,
        },

        '& i': {
          marginRight: '4px',
        },
      },
    },

    paper: {
      margin: theme.spacing(1),
    },

    container: {
      display: 'flex',
    },

    // Modal
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .__modalPaper': {
        background: constants.COLOR_GRAY,
        padding: theme.spacing(2),
        // height: '80vh',
        width: '100%',
        margin: '0 16px',

        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },
        '&:focus': {
          outline: 'none',
        },

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: '18px',
          fontWeight: 'bold',
          padding: '16px 0',
          textAlign: 'center',
        },
        '&__actions': {
          display: 'flex',
          justifyContent: 'space-between',
          '&__action': {
            //
            '&__icon': {
              width: '50px',
              height: '50px',
              background: 'lightgray',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '8px',
              boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

              '&.activeAction': {
                backgroundColor: constants.COLOR_MAIN_LIGHT,
              },
            },

            '&__memo': {
              marginTop: '4px',
              textAlign: 'center',
              fontSize: '12px',
            },
          },
        },

        '&__textField': {
          width: '100%',
        },

        '&__buttons': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 0',
          '& :first-child': {
            marginRight: '4px',
          },
          '& :last-child': {
            marginLeft: '4px',
          },
        },
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
