import React from 'react'

import { useCurrentUser, useTeams } from 'pages/teams/hooks'

import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

export const TeamsLandingPage: React.FC = () => {
  const { route } = useHistory()

  const { user, loaded: loadedUser } = useCurrentUser()
  const { teams, loaded: loadedTeams } = useTeams()

  React.useEffect(() => {
    if (loadedUser && loadedTeams) {
      if (teams.length > 0) {
        route.push(Pages.TeamsDashboard, { teamId: teams[0].id })
      } else {
        if (user?.companyAddress1) {
          route.push(Pages.TeamsNew)
        } else {
          route.push(Pages.TeamsStart)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loadedUser, teams, loadedTeams])

  return <></>
}
