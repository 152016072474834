import React from 'react'

interface Props {
  onModal: (content: React.ReactNode) => void
  onClose: () => void
}

export const useModalBuildingContext = () => {
  const [open, setOpen] = React.useState(false)
  const [content, setContent] = React.useState<React.ReactNode>()
  const onModal = (content: React.ReactNode) => {
    setContent(content)
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
    setContent(undefined)
  }

  return {
    open,
    setOpen,
    content,
    onModal,
    onClose,
  }
}

export const ModalBuildingContext = React.createContext<Props>({} as Props)
