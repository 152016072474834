import { OnboardingActionCategory } from 'utils/generated'

export const categoryNames: { [k: string]: string } = {
  [OnboardingActionCategory.Category1]: 'チーム理解行動',
  [OnboardingActionCategory.Category2]: '関係構築行動',
  [OnboardingActionCategory.Category3]: '社会人自律行動',
  [OnboardingActionCategory.Category4]: '自己成長行動',
  [OnboardingActionCategory.Category5]: '意味づけ行動',
}

export const onboardingActionCategoryLabel = (category?: OnboardingActionCategory | null) => {
  switch (category) {
    case OnboardingActionCategory.Category1:
      return categoryNames[OnboardingActionCategory.Category1]
    case OnboardingActionCategory.Category2:
      return categoryNames[OnboardingActionCategory.Category2]
    case OnboardingActionCategory.Category3:
      return categoryNames[OnboardingActionCategory.Category3]
    case OnboardingActionCategory.Category4:
      return categoryNames[OnboardingActionCategory.Category4]
    case OnboardingActionCategory.Category5:
      return categoryNames[OnboardingActionCategory.Category5]
    default:
      return ''
  }
}
