import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  maxWidth: number
}

// -----------------------------
// Component
// -----------------------------
export const Container = (props: IProps) => {
  const classes = useStyles()

  return (
    <section className={classes.root} style={{ maxWidth: props.maxWidth ? `${props.maxWidth}px` : '1080px' }}>
      {props.children}
    </section>
  )
}
