import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'
import {
  BackResponsiveCard,
  GrayBackGround,
  ButtonForGhost,
  PageTitle,
  UsefulCard,
  Font,
  Flexbox,
} from 'pages/ghost/components/atoms'
import { ButtonContainer, WaitingContainer } from 'pages/ghost/components/modules'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { CommandContext } from 'pages/ghost/service/commands'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

export const Intro: React.FC = () => {
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { teamId } = useParams<{ teamId: string }>()
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const { runGoToNextPage } = useContext(CommandContext)

  const next = async () => {
    teamMember && (await runGoToNextPage(teamMember))
    await updateGhostTeam({
      input: { id: teamId, progress: GhostProgress.IntroDone },
    })
  }
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()

  const grandRules = [
    <>
      オバケとは、組織で問題が起こっている時、その要因を特定の人ではなく「関係性や環境から生まれた目に見えない象徴」として表現したものになります。
      否定や攻撃など誰かを責める行為をしないようにお願いします。
    </>,
    <>正解・不正解はありません。どのような内容も見え方の一つであると受け止めてください。</>,
    <>傾聴の姿勢を心がけてください。</>,
    <>
      「私が見かけた」「私はそう思う」と「私」を主語にしましょう。{isMdUp ? <br /> : null}
      「皆が言っている」など「誰か」を主語にしないでください。
    </>,
    <>チームを良くしていきたいというポジティブな気持ちを持って始めましょう。</>,
  ]

  return (
    <GrayBackGround fullVh>
      <BackResponsiveCard>
        <Flexbox>
          <UsefulCard
            backgroundColor="white"
            ownStyles={{ padding: isMdUp ? 24 : '32px 16px', width: '100%', maxWidth: 800, marginBottom: 86 }}
          >
            <PageTitle ownStyles={{ textAlign: 'center' }}>ルール</PageTitle>
            <Font fontSize={14} ownStyles={{ marginTop: 24 }}>
              オバケ対話を行う際のグランドルール
              <br />
              ※ファシリテーターの方が必ず最初に読み上げてください。
            </Font>
            <UsefulCard backgroundColor="lightGray" ownStyles={{ padding: isMdUp ? 24 : 16, marginTop: 24 }}>
              <ol className={classes.ol}>
                {grandRules.map((grandRule, index) => (
                  <li key={`ghost-grandRule-${index}`} className={classes.li}>
                    {grandRule}
                  </li>
                ))}
              </ol>
            </UsefulCard>
          </UsefulCard>
        </Flexbox>

        {teamMember?.role === GhostMemberRole.Leader ? (
          <ButtonContainer
            fixed
            buttons={[
              <ButtonForGhost
                key={'ghost-rule'}
                buttonBodyColor={'green'}
                bodyText="次へ"
                onClick={next}
                isResizeBasic
              />,
            ]}
          />
        ) : (
          <WaitingContainer />
        )}
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

const useStyles = makeStyles(
  () => ({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '94%',
      maxWidth: 350,
      outline: 'none',
    },
    modalPaper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: 371,
      padding: '47px 24px 24px',
      backgroundColor: constGhost.COLOR_WHITE,
      borderRadius: 8,
    },
    modalItem: {
      flex: 1,
    },
    modalTitle: {
      margin: '48px 0 0',
      display: 'flex',
      justifyContent: 'center',
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    modalBody: {
      margin: '24px 0 0',
      color: constGhost.COLOR_BLACK3,
      fontSize: 14,
      whiteSpace: 'pre-wrap',
    },
    modalInfo: {
      position: 'absolute',
      bottom: '-16%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: constGhost.COLOR_WHITE,
      fontSize: 14,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },

    ol: {
      counterReset: 'counter',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    li: {
      paddingLeft: 30,
      position: 'relative',
      color: constGhost.COLOR_BLACK3,
      '&:not(:last-of-type)': {
        paddingBottom: 16,
      },
      '&:before': {
        content: 'counter(counter)',
        counterIncrement: 'counter',
        position: 'absolute',
        top: 3,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 14,
        height: 14,
        color: constGhost.COLOR_BLACK3,
        fontSize: 13,
        border: `1px solid ${constGhost.COLOR_BLACK3}CC`,
        borderRadius: '50%',
      },
    },
  }),
  { name: 'Rule' }
)
