import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { HooksContext } from 'pages/teams/contexts'
import { useTeamToolUsageStatus } from 'pages/teams/hooks/teamToolUsageStatus'
import { TeamTool } from 'utils/generated'

import { PieChart, Tooltip } from './modules'

import arrowDownSvg from '../assets/arrow-down.svg'
import { constants } from 'assets'

const toolItemList = [
  { name: 'キックオフ', teamTool: TeamTool.Building, requiredTool: TeamTool.Building },
  { name: 'リフレクション', teamTool: TeamTool.BuildingReview, requiredTool: TeamTool.Building }, // リフレクションの表示はキックオフと連動させる。
  { name: 'カルテ', teamTool: TeamTool.Karte, requiredTool: TeamTool.Karte },
  { name: 'オバケ', teamTool: TeamTool.Ghost, requiredTool: TeamTool.Ghost },
  { name: 'TBチェック', teamTool: TeamTool.BuildingCheck, requiredTool: TeamTool.BuildingCheck },
]

export const ToolUsageList: React.FC = () => {
  const classes = useStyles()
  const { isAdmin, currentTeam: team, refreshTeam } = React.useContext(HooksContext)
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const [isOpenUsage, setIsOpenUsage] = React.useState(false)

  React.useEffect(() => {
    refreshTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUsageToggle = () => {
    setIsOpenUsage(!isOpenUsage)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      handleUsageToggle()
    }
  }

  if (!isAdmin) return null

  return (
    <div className={classes.container}>
      {isSmDown ? (
        // button in button を抑止するため div で代用。
        <div
          className={classes.usageToggle}
          onClick={handleUsageToggle}
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <TooltipText isSmDown={isSmDown} />
          <img
            className={`${classes.arrowIcon} ${isOpenUsage ? classes.arrowUp : ''}`}
            src={arrowDownSvg}
            alt="arrow icon"
          />
        </div>
      ) : (
        <TooltipText isSmDown={isSmDown} />
      )}

      <div className={`${classes.hideBox} ${isOpenUsage ? 'open' : ''}`}>
        <div className={classes.cardBox}>
          {toolItemList.map((item) =>
            team?.teamTools?.includes(item.requiredTool) ? (
              <ToolUsage key={item.name} teamTool={item.teamTool} toolName={item.name} />
            ) : (
              <div key={item.name} className={classes.offCard}>
                <div>{item.name}</div>
                <div className={classes.offText}>OFF</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

type ToolUsageProps = {
  teamTool: TeamTool
  toolName: string
}

const ToolUsage: React.FC<ToolUsageProps> = ({ teamTool, toolName }) => {
  const classes = useStyles()
  const { teamToolUsageStatus, isLoading, errorMessage, getTeamToolUsageStatus } = useTeamToolUsageStatus()

  React.useEffect(() => {
    getTeamToolUsageStatus(teamTool)
  }, [getTeamToolUsageStatus, teamTool])

  if (errorMessage) {
    return (
      <div className={`${classes.offCard} ${classes.errorCard}`}>
        <div className={classes.errorCardTitle}>{toolName}</div>
        <div className={classes.errorMessage}>{errorMessage}</div>
      </div>
    )
  }

  return (
    <div className={classes.card}>
      <div className={classes.toolName}>{toolName}</div>
      <PieChart
        name={toolName}
        numerator={teamToolUsageStatus?.playedTeamCount ?? 0}
        denominator={teamToolUsageStatus?.activeTeamCount ?? 0}
        isLoading={isLoading}
        teamTool={teamTool}
      />
    </div>
  )
}

type TooltipTextProps = {
  isSmDown: boolean
}

const TooltipText: React.FC<TooltipTextProps> = ({ isSmDown }) => {
  const classes = useStyles()

  return (
    <div className={classes.head}>
      <div className={classes.headContent}>
        <p className={classes.title}>全社ツール使用状況</p>
        <Tooltip
          balloonId="tooltip-teams-toolUsage"
          title="全社ツール使用状況"
          body="活動中のチームを対象に、1度でも使用した回数に基づいて使用状況を表示しています"
          width={isSmDown ? 300 : 463}
          positions={{ top: 30, left: isSmDown ? -110 : -134 }} // top 30 = 20（? icon の高さ） + 10（ballon の矢印の高さ）
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    usageToggle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: 48,
      padding: '0 16px',
      background: '#fff',
      cursor: 'pointer',
    },
    arrowIcon: {
      width: 11,
    },
    arrowUp: {
      transform: 'scale(1, -1)',
    },
    container: {
      marginBottom: 16,
      color: constants.TEXT_GRAY_DARK,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 8,
      },
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    title: {
      margin: 0,
      fontSize: 12,
      fontWeight: 'bold',
    },
    hideBox: {
      [theme.breakpoints.down('sm')]: {
        boxSizing: 'border-box',
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height .6s',

        '&.open': {
          maxHeight: 2000, // 100% にすると transition が効かないので注意。
        },
      },
    },
    cardBox: {
      // 1行に収まる場合は、flexbox によって同サイズの card で行を埋める。
      display: 'flex',
      gap: 16,
      marginTop: 14,

      [theme.breakpoints.down(1170)]: {
        // 1行に収まらない場合は、grid によって card のサイズが同じになるように調整する。
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(160px, 1fr))',
      },
      [theme.breakpoints.down('sm')]: {
        // sp サイズでは縦一列に並べる。
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        background: '#fff',
        marginTop: 8,
        marginBottom: 8,
        padding: 16,

        '&:not(:last-child)': {
          borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
        },
      },
    },
    card: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '16px 8px',
      height: 128,
      backgroundColor: constants.COLOR_WHITE,
      padding: 8,
      [theme.breakpoints.down('sm')]: {
        padding: 16,
        '&:not(:last-child)': {
          borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
        },
      },
    },
    toolName: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    offCard: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
      padding: 8,
      minHeight: 128,
      height: 128,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        padding: 16,
        minHeight: 112,
        height: 112,
      },
    },
    offText: {
      marginLeft: 16,
    },
    errorCard: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    errorCardTitle: {
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },
    errorMessage: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3, // この値を変更することで行数の制限範囲を変更
      overflow: 'hidden',
      color: constants.COLOR_DANGER,
      fontSize: 12,
    },
  }),
  { name: 'ToolUsage' }
)
