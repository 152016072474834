import { GhostTeamMember } from 'utils/generated'

/**
 * Ghost Pages list.
 * always put paths within this object to detect (un)available paths (type=GhostPageName).
 */
export const GP = {
  waiting: '/ghost/:teamId/waiting',
  selectLeader: '/ghost/:teamId/selectleader',
  start: '/ghost/:teamId/start',
  intro: '/ghost/:teamId/intro',
  pickGhosts: '/ghost/:teamId/pickghosts',
  complete: '/ghost/:teamId/complete',
  vote: '/ghost/:teamId/vote',
  break: '/ghost/:teamId/break',
  report: '/ghost/:teamId/report',
  result: '/ghost/:teamId/result',
  done: '/ghost/:teamId/done',
  started: '/ghost/started',
  step4Report: '/ghost/:teamId/step4report',
  workResults: '/ghost/:teamId/work-results',
  step1Start: '/ghost/:teamId/step1-start',
  step1Results: '/ghost/:teamId/step1-results',
  presentationOptions: '/ghost/:teamId/presentation-options',
  step1Presentation: '/ghost/:teamId/step1-presentation',
  votingResults: '/ghost/:teamId/voting-results',
  step3PresentationReason: '/ghost/:teamId/step3-presentationReason',
  step3Presentation: '/ghost/:teamId/step3-presentation',
  step4Presentation: '/ghost/:teamId/step4-presentation',

  dummy: '/ghost/:teamId/dummy', // !!!!! WARNING !!!!! rm later
} as const

/**
 * Please use this type amap to detect available pages.
 */
export type GhostPageName = keyof typeof GP

/**
 * also use this, not primitive strings
 */
export type GhostPagePath = typeof GP[GhostPageName]

/**
 * The order each page appear in the game.
 * We can swap these paths as we might want in the later update.
 */
export const indexedPagePath = [
  // 1
  GP.waiting,

  // 2
  GP.selectLeader,

  // 3
  GP.start,

  // 4
  GP.intro,

  // --------------------
  // STEP1

  // 5
  GP.step1Start,

  // 6
  GP.pickGhosts,

  // 7
  GP.break,

  // 8
  GP.step1Results,

  // 9
  GP.presentationOptions,

  // 10
  GP.step1Presentation,

  // 11
  GP.complete,

  // --------------------
  // STEP2

  // 12
  GP.vote,

  // 13
  GP.break,

  // 14
  GP.votingResults,

  // 15
  GP.complete,

  // --------------------
  // STEP3

  // 16
  GP.report,

  // 17
  GP.break,

  // 18
  GP.result,

  // 19
  GP.break,

  // 20
  GP.done,

  // 21
  GP.presentationOptions,

  // 22
  GP.step3PresentationReason,

  // 23
  GP.report,

  // 24
  GP.break,

  // 25
  GP.result,

  // 26
  GP.break,

  // 27
  GP.done,

  // 28
  GP.presentationOptions,

  // 29
  GP.step3Presentation,

  // 30
  GP.complete,

  // --------------------
  // STEP4

  // 31
  GP.step4Report,

  // 32
  GP.break,

  // 33
  GP.workResults,

  // 34
  GP.presentationOptions,

  // 35
  GP.step4Presentation,
  // --------------------

  // 36
  GP.complete,
] as const // (in the future, all paths should be in this array)

/**
 *
 * @param pageNum member.page
 * @returns dest path
 */
export const num2Path = (pageNum: number): GhostPagePath => {
  let p: GhostPagePath = '/ghost/:teamId/waiting'
  try {
    p = indexedPagePath[pageNum - 1]
  } catch {
    console.log('error', 'Path not found')
  }
  return p
}

/**
 * @param pageName name for each pages. Spec doesn't change this
 * @returns page number to update Member.page, implementation often change this order
 */
export const page2Num = (pageName: GhostPageName, teamMember?: GhostTeamMember): number => {
  const idx = indexedPagePath
    .map((item, index) => {
      if (item === GP[pageName]) {
        return index + 1
      } else {
        return null
      }
    })
    .filter((item) => item)
  if (!idx) return 0

  switch (idx.length) {
    case 0:
      return 0
    case 1:
      return idx[0] ? idx[0] : 0
    default:
      const nextNum = idx.find((item) => item && teamMember?.page && item >= teamMember?.page)
      const num = idx[0] ? idx[0] : 0
      return nextNum || num
  }
}

/**
 * @param pattern
 * @param teamId provide teamId if current path doesn't include it
 */
export const teamIdPath = (pattern: GhostPagePath, teamId?: string) => {
  if (!teamId) {
    teamId = window.location.pathname.split('/')[2]
  }
  const p = pattern.split('/')
  if (p.length > 3) {
    p.splice(2, 1, teamId)
    return p.join('/')
  }
  return pattern
}
