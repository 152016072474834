import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { DOCUMENTS } from './documents'

import { constants } from 'assets'

export const DocumentsDownload: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h1 className={classes.pageTitle}>Cocolabo お役立ち資料</h1>
        <div className={classes.cardContainer}>
          {DOCUMENTS.map((doc) => (
            <Card key={doc.title} title={doc.title} thumbnail={doc.thumbnail} fileName={doc.fileName} />
          ))}
        </div>
      </div>
    </div>
  )
}

// S3 URL
const FILE_URL = 'https://lp.cocolabo.club/download'

type CardProps = {
  title: string
  thumbnail: string
  fileName: string
}

export const Card: React.FC<CardProps> = ({ title, thumbnail, fileName }) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <div className="__contentWrapper">
        <div className="__imgWrapper">
          <img src={`${process.env.PUBLIC_URL}/img/documentsPage/${thumbnail}`} alt={thumbnail} className="__img" />
        </div>
        <div className="__textBox">
          <div className="__title">{title}</div>
        </div>
      </div>
      <a href={`${FILE_URL}/${fileName}`} target="_blank" rel="noopener noreferrer" className="__downloadLink">
        資料ダウンロード
      </a>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 40,
      },
    },

    container: {
      width: '100%',
      maxWidth: 1200,
    },

    pageTitle: {
      margin: '40px 0',

      [theme.breakpoints.down('sm')]: {
        margin: '32px 0',
        fontSize: 24,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '24px 0',
        fontSize: 16,
      },
    },

    cardContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 24,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },

    // Card Component Styles //
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      paddingBottom: 16,
      boxShadow: '0 3px 6px 0 #0000001E',
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,

      '& .__contentWrapper': {
        flex: '1 1 auto',
      },

      '& .__imgWrapper': {
        marginBottom: 8,
        overflow: 'hidden',
        borderRadius: '8px 8px 0 0',

        '& .__img': {
          width: '100%',
          objectFit: 'cover',
          aspectRatio: '1.6',
        },
      },

      '& .__textBox': {
        flexGrow: 1,
        margin: '0 16px auto',

        '& .__title': {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },

      '& .__downloadLink': {
        margin: ' 0 16px',
        padding: '8px 20px',
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
        borderRadius: 30,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',

        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.5,
          },
        },
      },
    },
  }),
  { name: 'DocumentsDownload' }
)
