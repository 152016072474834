import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { hintContext } from '../context/useHintContext'
import actions from '../stores/actions'
import { updateHint, deleteHint } from '../stores/apis'
import { createHint, ICreateHintParams } from '../stores/apis/createHint'
import { IUpdateHintParams } from '../stores/apis/updateHint'

import * as registers from 'assets/registers'



// -----------------------------
// interface
// -----------------------------
export type IProps = {
  createHintEpic: (params: ICreateHintParams, isPublished: number) => Promise<void>
  updateHintEpic: (params: ICreateHintParams, isPublished: number) => Promise<void>
  deleteHintEpic: (id: string) => Promise<void>
}

// -----------------------------
// hooks
// -----------------------------
export const useHintModalEpic = (): IProps => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userSelector = useSelector((state: RootStateType) => state.user)
  const context = useContext(hintContext)

  /**
   * Tipsの作成時の処理
   */
  const createHintEpic = async (params: ICreateHintParams, isPublished: number): Promise<void> => {
    await createHint({ ...params, isPublished: isPublished })
      .then((res) => {
        dispatch(actions.createHint.done({ params: { ...params, isPublished: isPublished }, result: res }))
      })
      .catch((e) => {
        dispatch(
          actions.createHint.failed({
            params: { ...params },
            error: registers.MODAL_TIPS_ACTIONS.CREATE.MESSAGE,
          })
        )
        throw e
      })
  }

  /**
   * Tipsの更新時の処理
   */
  const updateHintEpic = async (params: IUpdateHintParams, isPublished: number): Promise<void> => {
    await updateHint({ ...params, isPublished: isPublished })
      .then((res) => {
        dispatch(actions.updateHint.done({ params: { ...params, isPublished: isPublished }, result: res }))
      })
      .catch((e) => {
        dispatch(
          actions.updateHint.failed({
            params: { ...params },
            error: registers.MODAL_TIPS_ACTIONS.CREATE.MESSAGE,
          })
        )
        throw e
      })
  }

  /**
   * Tipsの削除時の処理
   */
  const deleteHintEpic = async (id: string): Promise<void> => {
    try {
      const hintRes = await deleteHint({ id: id })
      dispatch(actions.deleteHint.done({ params: { id: id }, result: hintRes }))
    } catch (e) {
      dispatch(
        actions.deleteHint.failed({
          params: { id: id },
          error: registers.MODAL_TIPS_ACTIONS.DELETE.MESSAGE,
        })
      )
      throw e
    } finally {
      dispatch(actions.closeModalActive())
      context.onCloseModal()
      history.replace(`/hint/tips/profile/${userSelector.id}`)
      window.location.href = `/hint/tips/profile/${userSelector.id}`
    }
  }

  return {
    createHintEpic,
    updateHintEpic,
    deleteHintEpic,
  }
}
