import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { constants } from 'assets'

type Props = {
  label: string
  onClick: () => void
  type?: 'normal' | 'border'
  icon?: React.ReactNode
  inline?: boolean
  disabled?: boolean
  minMargin?: boolean
}
export const ContentButton: React.FC<Props> = ({
  type = 'normal',
  label,
  onClick,
  icon,
  inline,
  disabled,
  minMargin,
}) => {
  const classes = useStyles()
  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }
  return (
    <div
      className={classNames([classes.root, type, inline && 'inline', disabled && 'disabled', minMargin && 'minMargin'])}
      onClick={handleClick}
    >
      {icon && icon}
      {label}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
    margin: '40px auto 0',
    padding: 16,
    fontWeight: 'bold',
    width: '100%',
    maxWidth: 382,
    borderRadius: 8,
    border: '1px solid #00838C',
    '&:hover': {
      opacity: 0.8,
    },
    '&.normal': {
      backgroundColor: '#00838C',
      color: '#FFFFFF',
    },
    '&.border': {
      color: '#00838C',
    },
    '&.inline': {
      margin: '0',
      padding: '8px',
    },
    '&.disabled': {
      PointerEvent: 'none',
      cursor: 'not-allowed',
      color: constants.TEXT_GRAY,
      border: `1px solid ${constants.COLOR_GRAY_LIGHT}`,
      backgroundColor: constants.COLOR_GRAY_LIGHT,
    },
    '&.minMargin': {
      margin: '16px auto 0',
    },
  },
}))
