import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import downArrowIcon from '../../assets/svg/downArrowIcon.svg'

type Props = {
  directionToUp: boolean
}

export const ArrowIcon: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classNames([classes.arrowIcon, props.directionToUp ? 'down' : ''])}>
      <img loading="lazy" src={downArrowIcon} alt="arrowIcon" />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    textAlign: 'center',
    width: 10,
    margin: '-4px 0 0 0',
    '&.down': {
      transformOrigin: '50% 60%',
      transform: 'rotate(180deg)',
    },
  },
}))
