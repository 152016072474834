import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'


import { AuthorName } from 'pages/hint/_shared/components/authorName/Index'
import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { Date } from 'pages/hint/_shared/components/date/Index'
import { Details } from 'pages/hint/_shared/components/details/Index'
import { ArticlesHeading } from 'pages/hint/_shared/components/heading/articlesHeading/Index'
import { Lead } from 'pages/hint/_shared/components/lead/Index'
import { ListArticleWrapper, ListInWrapper, DateWrapper } from 'pages/hint/_shared/layouts/articles/Index'
// import { Comment } from 'pages/hint/_shared/components/comment/Index'
import { IHintListProfileResult } from 'pages/hint/_shared/stores/apis/getHintListProfile'
import { IHintBase } from 'pages/hint/_shared/stores/models/hint'
import { formatDate } from 'pages/hint/_shared/utils/formatDate'

import { useArticle } from './useArticle'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },

  draft: {
    display: 'inline-block',
    fontSize: '12px',
    color: '#5C5C5C',
    backgroundColor: '#F5F5F5',
    borderRadius: '3px',
    padding: '4px 16px',
    marginBottom: '24px',

    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
      fontSize: '9px',
    },
  },

  list: {
    display: 'block',
    textDecoration: 'none',
    borderBottom: 'solid 1px #E8E8E8',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  datas: IHintListProfileResult
}

// -----------------------------
// Component
// -----------------------------
export const Articles = (props: IProps) => {
  const classes = useStyles()
  const article = useArticle()

  return (
    <ul className={classes.root}>
      {props.datas.items.map((data: IHintBase) => {
        return (
          <div className={classes.list} onClick={() => article.isDraftModal(data.id, data.isPublished)} key={data.id}>
            <ListArticleWrapper>
              {data.isPublished === 0 && (
                <Box className={classes.draft}>
                  <span>下書き</span>
                </Box>
              )}
              <ListInWrapper>
                <Box mr="24px">
                  <ArticlesHeading text={data.title} isPublished={data.isPublished} />
                  <Lead text={data.contentPreview} ellipsis />
                </Box>
                <Box>{data.user?.imageUrl && <Avatar src={data.user.imageUrl} alt="avator" size={76} />}</Box>
              </ListInWrapper>
              <Box mt={{ xs: '16px', lg: '24px' }}>
                <Details>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                      <DateWrapper>
                        <Date text={formatDate(data.updatedAt)} />
                      </DateWrapper>
                      {data?.user?.firstName && data?.user?.lastName && (
                        <AuthorName text={`${data.user.lastName} ${data.user.firstName}`} />
                      )}
                      {(!data?.user?.firstName || !data?.user?.lastName) && data?.user?.nickname && (
                        <AuthorName text={data.user.nickname} />
                      )}
                    </Box>
                    {/* <Comment count={data.commentCount} /> */}
                  </Box>
                </Details>
              </Box>
            </ListArticleWrapper>
          </div>
        )
      })}
    </ul>
  )
}
