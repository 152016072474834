import * as React from 'react'

import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'

import { useTeams } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMemberRole, OnboardingTeam } from 'utils/generated'

import { Dropdown } from './Dropdown'

import { OnbPages } from 'assets/pages'

interface IOwnProps {
  teamId?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, teamId }) => {
  const { teams: teamsOfAdminRole } = useTeams(OnboardingTeamMemberRole.Admin)
  const { teams: teamsOfSupporterRole } = useTeams(OnboardingTeamMemberRole.Supporter)
  const teams: OnboardingTeam[] = [...teamsOfAdminRole, ...teamsOfSupporterRole]
  const history = useOnbHistory()

  return (
    <div className={classes.container}>
      <Dropdown
        className={classes.teams}
        items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
        value={teamId}
        onChange={(teamId) => history.push(OnbPages.MemberManage, { teamId })}
        keyPrefix={'member-manage-three-person'}
      />
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      backdropFilter: 'blur(20px)',
      margin: '0 16px 24px 16px',
    },
    teams: {
      flex: 1,
    },
    persons: {
      flex: 1,
      marginLeft: 16,
    },
  })

export default withStyles(useStyles)(Index)
