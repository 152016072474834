import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man4: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-7813 -11500)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(7813 11500)" fill="#80d6dd" />
        <G transform="translate(8956.152 10025.946)">
          <G>
            <Path
              d="M-937.866,1613.412l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-935.89,1607.929-937.889,1610.4-937.866,1613.412Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(-897.041 1593.232) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-856.31 1542.183)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-951.23 1542.904)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(-940.929 1497.369)" fill="#ffe4d2" />
            <Path
              d="M-840.119,1834.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-899.719,1835.2a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M-905.2,1607.7l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M-888.87,1607.926l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(-893.433 1615.771) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M-905.337,1607.7l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M-871.453,1607.44l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M-889.282,1488.052c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-947.329,1515.97-921.456,1488.3-889.282,1488.052Z"
              fill="#522903"
            />
            <G>
              <Path
                d="M-720.232,1599.3l-54.528,12.977a10.127,10.127,0,0,1-12.2-7.5h0l-.157-.658c-1.3-5.439,1.79-12.621,7.233-13.916l54.529-12.977c5.439-1.3,11.18,3.783,12.475,9.225l.156.657A10.13,10.13,0,0,1-720.232,1599.3Z"
                fill="#ffe4d2"
              />
              <Path d="M-799.243,1595.256l69.737-18.988,6.491,24.94-69.736,18.988Z" fill="#545364" />
            </G>
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-936.878 1743.551) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-877.265 1743.098) rotate(-0.434)"
              fill="#545364"
            />
            <Path d="M-853.323,1609.756l55.608-13.622,5.329,24.734L-848,1634.493Z" fill="#545364" />
            <G>
              <Path
                d="M-989.143,1713.908l28.637-55.342a11.4,11.4,0,0,1,15.5-4.992l.072.039a11.8,11.8,0,0,1,4.723,15.828l-28.637,55.342a11.4,11.4,0,0,1-15.5,4.991l-.072-.038A11.8,11.8,0,0,1-989.143,1713.908Z"
                fill="#ffe4d2"
              />
              <Path d="M-913.34,1621.614l-49.431,95.526-22.91-12.278,49.431-95.526Z" fill="#545364" />
            </G>
          </G>
          <G>
            <Path
              d="M-704.021,1603.818h-88.028c-2.489,0-4.526,2.486-4.526,5.524V1747.1c0,.055.012.106.014.16v2.663H-699.5V1609.342C-699.5,1606.3-701.533,1603.818-704.021,1603.818Z"
              fill="#545364"
            />
            <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(-758.615 1589.267)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-813.506 1538.117)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-717.591 1538.117)" fill="#ffe4d2" />
            <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(-803.172 1492.47)" fill="#ffe4d2" />
            <Path
              d="M-796.574,1830.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.148.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-736.337,1830.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.149.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path d="M-766.647,1603.818l9.075,26.674h15.125l9.9-26.674Z" fill="#fff" />
            <Path
              d="M-755.1,1607.888l1.1,8.274-1.1,4.675-3.575,7.425,7.975,26.4,9.075-25.849-3.3-7.724.546-13.2-4.671-3.714Z"
              fill="#b15f78"
            />
            <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(-755.097 1612.068)" fill="#c07087" />
            <Path d="M-749.273,1603.818l11.229,15.033,5.637-15.033Z" fill="#e5e5e4" />
            <Path d="M-748.723,1603.818l-13.383,14.107-4.541-14.107Z" fill="#e5e5e4" />
            <Path
              d="M-690.681,1545.063c-12.367,1.479-18.661-9.536-21.808-18.191-37.765-2.36-59.009-3.147-72.384,0-2.36,9.442-23.6,27.538-22.134,9.1,3.662-23.7,24.949-52.918,57.46-52.918S-690.681,1510.814-690.681,1545.063Z"
              fill="#323333"
            />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-736.337 1740.846)" fill="#545364" />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-796.575 1740.846)" fill="#545364" />
            <G>
              <Rect
                x="0"
                y="0"
                width="91.351"
                height="25.061"
                rx="12.486"
                transform="translate(-834.652 1737.011) rotate(-73.125)"
                fill="#ffe4d2"
              />
              <Rect
                x="0"
                y="0"
                width="114.577"
                height="28.295"
                transform="translate(-829.183 1714.925) rotate(-73.125)"
                fill="#545364"
              />
            </G>
            <G>
              <Path
                d="M-772.252,1620.659a11.766,11.766,0,0,1,15.973-4.482l12.606,5.761a11.766,11.766,0,0,1,4.482,15.973l-.383.681a11.767,11.767,0,0,1-15.973,4.485l-12.6-5.762a11.767,11.767,0,0,1-4.483-15.973Z"
                fill="#ffe4d2"
              />
              <Path
                d="M-749.5,1618.19l66.494,39.83s1.136,11.25-.559,14.441c-1.869,3.518-12.849,9.424-12.849,9.424l-66.494-39.83Z"
                fill="#545364"
              />
            </G>
            <Path d="M-698.974,1605.062l16.595,54.8-24,8.01-16.6-54.794Z" fill="#545364" />
          </G>
          <G transform="translate(0 10)">
            <Path
              d="M-680.38,1480.419a66.7,66.7,0,0,1,32.068,42.866"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-664.1,1477.661a130.525,130.525,0,0,0-7.648,16.247"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-651.2,1491.459a102.351,102.351,0,0,0-14.357,9.918"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-645.554,1506.056l-15.231,5.027"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </G>
          <G>
            <Path
              d="M-975.326,1589.05a66.7,66.7,0,0,0-32.069,42.866"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-991.608,1586.292a130.542,130.542,0,0,1,7.648,16.247"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-1004.5,1600.09a102.348,102.348,0,0,1,14.357,9.919"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <Path
              d="M-1010.152,1614.687l15.231,5.027"
              fill="none"
              stroke="#f9d980"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Man4
