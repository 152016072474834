import { useState, useEffect, useCallback } from 'react'

import { v4 as uuid } from 'uuid'

import {
  queryGetOnboardingActions,
  queryGetOnboardingAction,
  queryGetOnboardingActionsFeedbacks,
  queryGetOnboardingActionCommentList,
  queryGetOnboardingActionsRadar,
  queryGetOnboardingActionList,
  mutationCreateOnboardingActionLikeToggle,
  mutationCreateOnboardingActionComment,
  mutationCreateOnboardingPostAction,
  mutationCreateOnboardingActionFeedback,
  mutationCreateOnboardingAction,
  mutationUpdateOnboardingAction,
  mutationUpdateOnboardingActionComment,
  mutationDeleteOnboardingActionComment,
  mutationDeleteOnboardingActionFeedback,
  mutationUpdateOnboardingPostAction,
  queryGetOnboardingActionLikeList,
  mutationDeleteOnboardingAction,
} from 'pages/onboarding/graphql'
import {
  OnboardingActionLikeToggleEvent,
  OnboardingActionCommentEvent,
  OnboardingActionFeedbackEvent,
  emitOnboardingActionLikeToggleEvent,
  emitOnboardingActionCommentEvent,
  emitOnboardingActionFeedbackEvent,
} from 'utils/event'
import {
  OnboardingAction,
  OnboardingActionCategory,
  OnboardingActionComment,
  OnboardingActionFeedback,
  OnboardingActionLike,
  OnboardingPostLabel,
  OnboardingRadarValues,
} from 'utils/generated'

export const useOnboardingActions = (teamId?: string, userId?: string) => {
  const [actions, setActions] = useState<OnboardingAction[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!teamId || !userId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingActions({ teamId, userId })

      const res = response.getOnboardingActions?.sort((a, b) => {
        return a.period + (a.deadline || 10) - (b.period + (b.deadline || 10))
      })

      setActions(res || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, userId])

  useEffect(() => {
    if (teamId && userId) {
      refresh()
    }
  }, [teamId, userId, refresh])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingActionCommentEvent, handler)
    window.addEventListener(OnboardingActionLikeToggleEvent, handler)
    window.addEventListener(OnboardingActionFeedbackEvent, handler)
    return () => {
      window.removeEventListener(OnboardingActionLikeToggleEvent, handler)
      window.removeEventListener(OnboardingActionLikeToggleEvent, handler)
      window.removeEventListener(OnboardingActionFeedbackEvent, handler)
    }
  }, [refresh])

  return { actions, loading, error, refresh } as const
}

export const useOnboardingAction = (id?: string, userId?: string) => {
  const [action, setAction] = useState<OnboardingAction | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id || !userId) {
        return
      }
      const response = await queryGetOnboardingAction({ id, userId })
      setAction(response.getOnboardingAction)
    } catch (e) {
      console.error(e)
      setError(e)
    }
    setLoading(false)
  }, [id, userId])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingActionCommentEvent, handler)
    window.addEventListener(OnboardingActionLikeToggleEvent, handler)
    return () => {
      window.removeEventListener(OnboardingActionCommentEvent, handler)
      window.removeEventListener(OnboardingActionLikeToggleEvent, handler)
    }
  }, [refresh])

  return { action, loading, error, refresh } as const
}

export const useOnboardingActionComment = (id?: string | undefined, userId?: string) => {
  const [actionComments, setActionComments] = useState<OnboardingActionComment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!id || !userId) {
        return
      }
      const response = await queryGetOnboardingActionCommentList({ actionId: id, actionOwnerId: userId })
      setActionComments(response.getOnboardingActionCommentList?.items || [])
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }, [id, userId])

  useEffect(() => {
    refresh()
  }, [refresh, id])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingActionCommentEvent, handler)
    return () => {
      window.removeEventListener(OnboardingActionCommentEvent, handler)
    }
  }, [refresh])

  return { actionComments, loading, error, refresh } as const
}

export const useOnboardingActionsFeedback = (teamId?: string, userId?: string) => {
  const [actionsFeedback, setActionsFeedback] = useState<OnboardingActionFeedback[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!teamId || !userId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingActionsFeedbacks({ teamId, userId })
      setActionsFeedback(response.getOnboardingActionsFeedbacks || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, userId])

  useEffect(() => {
    if (teamId && userId) {
      refresh()
    }
  }, [teamId, userId, refresh])

  useEffect(() => {
    const handler = () => {
      refresh()
    }
    window.addEventListener(OnboardingActionCommentEvent, handler)
    window.addEventListener(OnboardingActionLikeToggleEvent, handler)
    window.addEventListener(OnboardingActionFeedbackEvent, handler)
    return () => {
      window.addEventListener(OnboardingActionCommentEvent, handler)
      window.removeEventListener(OnboardingActionLikeToggleEvent, handler)
      window.removeEventListener(OnboardingActionFeedbackEvent, handler)
    }
  }, [refresh])

  return { actionsFeedback, loading, error, refresh } as const
}

export const useOnboardingActionsActions = () => {
  const likeToggleHandler = useCallback(async (actionId: string, actionOwnerId: string, teamId: string) => {
    try {
      await mutationCreateOnboardingActionLikeToggle({ input: { actionId, actionOwnerId, teamId } })
      emitOnboardingActionLikeToggleEvent(actionId, actionOwnerId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const createComment = useCallback(async (actionId: string, actionOwnerId: string, text: string, teamId: string) => {
    try {
      await mutationCreateOnboardingActionComment({ input: { id: uuid(), actionId, actionOwnerId, text, teamId } })
      emitOnboardingActionCommentEvent(actionId, actionOwnerId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const updateComment = useCallback(async (id: string, actionId: string, actionOwnerId: string, text: string) => {
    try {
      await mutationUpdateOnboardingActionComment({ input: { id, text } })
      emitOnboardingActionCommentEvent(actionId, actionOwnerId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const deleteComment = useCallback(async (id: string, actionId: string, actionOwnerId: string) => {
    try {
      await mutationDeleteOnboardingActionComment({ id })
      emitOnboardingActionCommentEvent(actionId, actionOwnerId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const createPostHandler = useCallback(
    async (teamId: string, actionId: string, comment: string, label: OnboardingPostLabel) => {
      try {
        await mutationCreateOnboardingPostAction({ input: { id: uuid(), teamId, actionId, comment, label } })
      } catch (e) {
        console.log('e', e)
      }
    },
    []
  )

  const updatePostHandler = useCallback(async (id: string, comment: string, label: OnboardingPostLabel) => {
    try {
      await mutationUpdateOnboardingPostAction({ input: { id, comment, label } })
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const createFeedbackHandler = useCallback(
    async (teamId: string, actionId: string, category: OnboardingActionCategory, value: number, importance: number) => {
      try {
        await mutationCreateOnboardingActionFeedback({
          input: { teamId, actionId, category, value, importance, settedAt: new Date().toISOString() },
        })
        emitOnboardingActionFeedbackEvent(teamId, actionId)
      } catch (e) {
        console.log('e', e)
      }
    },
    []
  )

  const deleteFeedbackHandler = useCallback(async (id: string) => {
    try {
      const feedback = await mutationDeleteOnboardingActionFeedback({ id })
      emitOnboardingActionFeedbackEvent(feedback.teamId, feedback.actionId)
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  return {
    likeToggleHandler,
    createComment,
    updateComment,
    deleteComment,
    createPostHandler,
    updatePostHandler,
    createFeedbackHandler,
    deleteFeedbackHandler,
  } as const
}

export const useOnboardingActionRadar = (teamId?: string, userId?: string) => {
  const [radar, setRadar] = useState<OnboardingRadarValues | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    setLoading(true)
    try {
      if (!teamId || !userId) {
        return
      }
      const response = await queryGetOnboardingActionsRadar({ teamId, userId })
      setRadar(response.getOnboardingActionsRadar)
    } catch (e) {
      console.error(e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, userId])

  useEffect(() => {
    refresh()
  }, [refresh, teamId, userId])

  useEffect(() => {
    const handler = () => {
      refresh()
    }

    window.addEventListener(OnboardingActionFeedbackEvent, handler)
    return () => {
      window.removeEventListener(OnboardingActionFeedbackEvent, handler)
    }
  }, [refresh])

  return { radar, loading, error, refresh } as const
}

export const useOnboardingActionManageList = (teamId?: string) => {
  const [actions, setActions] = useState<OnboardingAction[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!teamId) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingActionList({ teamId, first: 1000 })
      setActions(response.getOnboardingActionList?.items || [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { actions, loading, error, refresh } as const
}

// should be ported to somewhere
export type ActionCreateInput = {
  teamId: string
  category: OnboardingActionCategory
  mission: string
  why: string
  what: string
  how: string
  period: number
  point: number
  importance: number
}

export const useOnboardingActionCreater = () =>
  useCallback(async (input: ActionCreateInput) => {
    try {
      const res = await mutationCreateOnboardingAction({
        input: {
          ...input,
          id: uuid(),
          isPublished: true,
          publishAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      })
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

export type ActionUpdateInput = {
  id: string
  teamId?: string
  category?: OnboardingActionCategory
  mission?: string
  why?: string
  what?: string
  how?: string
  period?: number
  point?: number
  importance?: number
}

export const useOnboardingActionUpdater = () =>
  useCallback(async (input: ActionUpdateInput) => {
    try {
      const res = await mutationUpdateOnboardingAction({ input: input })
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

export const useOnboardingActionDelete = () =>
  useCallback(async (id: string) => {
    try {
      const res = await mutationDeleteOnboardingAction({ id })
      return res
    } catch (e) {
      console.log(e)
    }
  }, [])

export const useOnboardingActionLikes = (actionIdActionOwnerIdHash: string) => {
  const [actionLikes, setActionLikes] = useState<OnboardingActionLike[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const refresh = useCallback(async () => {
    if (!actionIdActionOwnerIdHash) {
      return
    }
    setError(undefined)
    setLoading(true)
    try {
      const response = await queryGetOnboardingActionLikeList({ actionIdActionOwnerIdHash })
      setActionLikes(response ? response.items : [])
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setLoading(false)
  }, [actionIdActionOwnerIdHash])

  useEffect(() => {
    refresh()
  }, [actionIdActionOwnerIdHash, refresh])

  return { actionLikes, loading, error, refresh } as const
}
