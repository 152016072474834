import React from 'react'

import { RouteComponentProps } from 'react-router'

import { TeamsPlans } from '../about/components/Plans'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsPlanPage: React.FC<Props> = (props) => {
  const teamId = props.match.params.teamId

  if (!teamId) {
    return <></>
  }

  return <TeamsPlans teamId={teamId} />
}
