import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { TeamsToolsKarteRootPage } from '..'
import { TeamToolsKarteDiagnoseContextProvider } from '../contexts/TeamToolsKarteDiagnoseContext'

import { TeamsToolsKarteDiagnosePage } from './diagnose'
import { TeamsToolsKarteHomePage } from './home'
import { TeamsToolsKarteResultPage } from './result'

import { Pages } from 'assets'

interface RouteParams {
  teamId?: string
}

export const TeamsToolsKarteRoute: React.FC = () => {
  const { teamId } = useParams<RouteParams>()

  const { setTeamId } = React.useContext(TeamsHooksContext)

  React.useEffect(() => {
    if (teamId) {
      setTeamId(teamId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return (
    <>
      <Route path={Pages.TeamsToolKarte}>
        {/* <Route exact={true} path={pages.Pages.TeamsToolKarte} component={TeamsToolsKarteRootPage} /> */}

        {/* <TeamBuildingContainer> */}
        <Switch>
          <Route exact={true} path={Pages.TeamsToolKarte} component={TeamsToolsKarteRootPage} />
          <Route exact={true} path={Pages.TeamsToolKarteHome} component={TeamsToolsKarteHomePage} />
          <Route exact={true} path={Pages.TeamsToolKarteResult} component={TeamsToolsKarteResultPage} />
          <TeamToolsKarteDiagnoseContextProvider>
            <Route exact={true} path={Pages.TeamsToolKarteDiagnose} component={TeamsToolsKarteDiagnosePage} />
          </TeamToolsKarteDiagnoseContextProvider>
        </Switch>
      </Route>
    </>
  )
}
