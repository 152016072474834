import * as React from 'react'

import { makeStyles, Modal, Paper, TextField, Theme } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { MessagePanel } from 'components/MessagePanelNew'
import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { OnboardingPostLabel } from 'utils/generated'

import * as constants from '../../../assets/constants'
import { ButtonNew } from '../../../components/ButtonNew'
import { useTutorial } from '../pages/tutorial/logics/hooks'
import { TutorialProps } from '../pages/tutorial/Tutorial'


type PickInput = Pick<IFormCharCountInput, 'labelText'>

interface IOwnProps {
  open: boolean
  handleClose: () => void
  onSubmit: (textFieldValue: string, selectedLabel: OnboardingPostLabel) => void
  closeModal: () => void

  // For update
  comment?: string
  label?: OnboardingPostLabel
  type?: string

  tutorial?: TutorialProps
}

type Props = IOwnProps

export const ModalShareAction: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { ttRef } = useTutorial(props.tutorial)
  const maxLength = 10000
  const inputName: keyof PickInput = 'labelText'

  const { register, handleSubmit, errors, watch, setValue } = useForm<PickInput>({ mode: 'onChange' })
  const watchInput = watch(inputName, '')

  React.useEffect(() => {
    if (props.open) {
      setValue(inputName, props.comment || '')
      setSelectedLabel(props.label || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const [selectedLabel, setSelectedLabel] = React.useState<OnboardingPostLabel | null>(props.label || null)

  const [hasErrorLabel, setHasErrorLabel] = React.useState<boolean>(false)
  const [hasErrorText, setHasErrorText] = React.useState<boolean>(false)

  const handleClose = () => {
    if (props.tutorial) return
    setHasErrorText(false)
    setHasErrorLabel(false)

    props.handleClose()
  }

  const onSubmit = (data: PickInput) => {
    setHasErrorLabel(false)
    setHasErrorText(false)

    if (!selectedLabel) {
      setHasErrorLabel(true)
    }

    if (!data.labelText) {
      setHasErrorText(true)
    }

    if (!selectedLabel || !data.labelText) {
      return
    }

    if (props.tutorial && props.tutorial.next) {
      props.tutorial.next()
      props.closeModal()
    } else {
      props.onSubmit(data.labelText, selectedLabel)
    }
  }

  const isValid = () => {
    return watchInput && !errors.labelText && selectedLabel ? true : false
  }

  return (
    <>
      <div ref={ttRef as React.MutableRefObject<HTMLDivElement>} className={classes.ttRefAnchor} />
      <Modal open={props.open} className={classes.modal} disableAutoFocus={true}>
        <Paper className={classes.modalPaper}>
          <MessagePanel
            failure={false}
            handleClose={handleClose}
            body={
              <>
                <p className={classes.title}>ラベル選択</p>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} data-testid="modal-share-action">
                  <div className={classes.modalSelections}>
                    <div>
                      {selectedLabel === OnboardingPostLabel.HelpMe ? (
                        <div
                          onClick={() => setSelectedLabel(null)}
                          className={props.type === 'time' ? '__selected __help' : classes.selectedAction}
                        >
                          今日やる
                        </div>
                      ) : (
                        <div
                          className={props.type === 'time' ? '__balloon' : classes.selectAction}
                          onClick={() => setSelectedLabel(OnboardingPostLabel.HelpMe)}
                        >
                          今日やる
                        </div>
                      )}
                      {selectedLabel === OnboardingPostLabel.Good ? (
                        <div
                          onClick={() => setSelectedLabel(null)}
                          className={props.type === 'time' ? '__selected __zyuntyo' : classes.selectedAction}
                        >
                          順調
                        </div>
                      ) : (
                        <div
                          className={props.type === 'time' ? '__balloon' : classes.selectAction}
                          onClick={() => setSelectedLabel(OnboardingPostLabel.Good)}
                        >
                          順調
                        </div>
                      )}
                      {selectedLabel === OnboardingPostLabel.Focus ? (
                        <div
                          onClick={() => setSelectedLabel(null)}
                          className={props.type === 'time' ? '__selected __kadai' : classes.selectedAction}
                        >
                          助言求む
                        </div>
                      ) : (
                        <div
                          className={props.type === 'time' ? '__balloon' : classes.selectAction}
                          onClick={() => setSelectedLabel(OnboardingPostLabel.Focus)}
                        >
                          助言求む
                        </div>
                      )}

                      {selectedLabel === OnboardingPostLabel.Struggling ? (
                        <div
                          onClick={() => setSelectedLabel(null)}
                          className={props.type === 'time' ? '__selected __kusen' : classes.selectedAction}
                        >
                          協力者募集
                        </div>
                      ) : (
                        <div
                          className={props.type === 'time' ? '__balloon' : classes.selectAction}
                          onClick={() => setSelectedLabel(OnboardingPostLabel.Struggling)}
                        >
                          協力者募集
                        </div>
                      )}
                    </div>
                  </div>
                  {hasErrorLabel && (
                    <small
                      style={{
                        color: 'red',
                        width: '100%',
                        display: 'block',
                      }}
                    >
                      ラベルを選択してください
                    </small>
                  )}
                  <TextField
                    name={inputName}
                    multiline
                    placeholder="コメントを追加する"
                    minRows={6}
                    variant="outlined"
                    focused={false}
                    className={classes.modalTextField}
                    inputRef={register({ required: true, maxLength })}
                  />
                  <FormCharCount length={watchInput.length} maxLength={maxLength} invisibleHeight={21} />
                  {hasErrorText && (
                    <small
                      style={{
                        color: 'red',
                        width: '100%',
                        display: 'block',
                      }}
                    >
                      ひとことを入力してください
                    </small>
                  )}
                  <div className={classes.modalButtons}>
                    <div style={{ marginBottom: 26 }}>
                      <ButtonNew body="投稿する" noShadow={true} disabled={!isValid()} submit />
                    </div>
                    <div
                      onClick={handleClose}
                      style={{
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: constants.COLOR_ONBOARDING_GRAY_DARK,
                      }}
                    >
                      キャンセル
                    </div>
                  </div>
                </form>
              </>
            }
            bodyStyle={{ paddingBottom: 0 }}
          />
        </Paper>
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  modalPaper: {
    width: '100%',
    maxWidth: 350,
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 29,
    fontWeight: 'bold',
    color: constants.COLOR_ONBOARDING_MAIN,
    textAlign: 'center',
    fontSize: 14,
  },
  modalTextField: {
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
    '& .MuiInputBase-multiline': {
      padding: 16,
    },
  },

  modalTextFieldAttension: {
    textAlign: 'right',
    marginTop: 0,
    marginBottom: 0,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
  },
  modalSelections: {
    marginBottom: 16,
    '& p': {
      fontWeight: 'bold',
      margin: '1%',
    },
    '& div': {
      fontWeight: 'bold',
      display: 'flex',
      textAlign: 'center',
      fontSize: 10,
      '& div': {
        borderRadius: 5,
        fontSize: 12,
        padding: '5px 0',
        width: '48%',
        marginRight: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 'initial',
        cursor: 'pointer',
        '&:last-child': {
          marginRight: 0,
        },
        '&.__balloon': {
          position: 'relative',
          backgroundColor: constants.COLOR_GRAY_LIGHT,
          color: constants.COLOR_GRAY_DARK,
          '&::before': {
            content: "''",
            position: 'absolute',
            top: -18,
            left: '50%',
            marginLeft: -25,
            border: '5px solid transparent',
            borderBottom: `15px solid ${constants.COLOR_GRAY_LIGHT}`,
            transform: 'rotate(20deg)',
          },
        },
      },
      '& .__selected': {
        position: 'relative',
        color: constants.COLOR_WHITE,
      },

      '& .__kadai': {
        backgroundColor: '#245C75',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: -18,
          left: '50%',
          marginLeft: -25,
          border: '5px solid transparent',
          borderBottom: `15px solid #245C75`,
          transform: 'rotate(20deg)',
        },
      },
      '& .__zyuntyo': {
        backgroundColor: '#1A8441',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: -18,
          left: '50%',
          marginLeft: -25,
          border: '5px solid transparent',
          borderBottom: `15px solid #1A8441`,
          transform: 'rotate(20deg)',
        },
      },
      '& .__kusen': {
        backgroundColor: '#664087',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: -18,
          left: '50%',
          marginLeft: -25,
          border: '5px solid transparent',
          borderBottom: `15px solid #664087`,
          transform: 'rotate(20deg)',
        },
      },
      '& .__help': {
        position: 'relative',
        backgroundColor: '#CE2C2C',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: -18,
          left: '50%',
          marginLeft: -25,
          border: '5px solid transparent',
          borderBottom: `15px solid #CE2C2C`,
          transform: 'rotate(20deg)',
        },
      },
    },
  },

  selectAction: {
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_DARK}`,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
  },
  selectedAction: {
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    color: constants.TEXT_WHITE,
    backgroundColor: constants.COLOR_ONBOARDING_MAIN,
  },
  modalButtons: {
    textAlign: 'center',
    paddingTop: 11,
  },

  ttRefAnchor: {
    position: 'fixed',
    top: 'calc(50vh - 12rem)',
    left: 0,
    width: '100vw',
  },
}))
