import { API } from '@aws-amplify/api'

import * as mutations from '../../graphql/mutations'

// -----------------------------
// Params
// -----------------------------
export type ICreateConsultationCommentParams = {
  id: string
  consultationId: string
  content: string
  type: string
}

// -----------------------------
// Result
// -----------------------------
export type ICreateConsultationCommentResult = {
  id: string
  consultationId: string
  username: string
  user: string
  content: string
  createdAt: string
  updatedAt: string
}

export const createConsultationComment = async (params: ICreateConsultationCommentParams) => {
  try {
    const res: any = await API.graphql({
      query: mutations.createConsultationComment,
      variables: {
        input: {
          id: params.id,
          consultationId: params.consultationId,
          content: params.content,
        },
      },
    })
    return res
  } catch (e) {
    throw e
  }
}
