import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  count: number
  isDisplay?: boolean
} & React.ComponentProps<'div'>

export const CountBatch: React.FC<Props> = ({ count, isDisplay, className, ...props }) => {
  const classes = useStyles()

  return isDisplay ?? count > 1 ? (
    <div className={`${classes.root} ${className}`} {...props}>
      {count > 99 ? '99+' : count}
    </div>
  ) : null
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'grid',
      placeItems: 'center',
      width: 16,
      height: 16,
      background: constants.COLOR_MAIN_NEW,
      borderRadius: '50%',
      color: '#fff',
      fontSize: 8,
      fontWeight: 'bold',
    },
  }),
  { name: 'CountBatch' }
)
