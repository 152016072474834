import React from 'react'

import { queryGetMaster } from 'pages/teams/hooks/graphql'
import { Maintain, MasterType } from 'utils/generated'

export const useMaintain = () => {
  const [maintain, setMaintain] = React.useState<Maintain | undefined>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMaster({ id: MasterType.Maintain })
      setMaintain(response)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { maintain, loading, loaded, error, refresh } as const
}
