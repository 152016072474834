import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import {
  QuestionContainer,
  Button,
  FormError,
  FormLabel,
  ButtonContainer,
  Waiting,
  UsefulContainer,
  SelectBox,
} from '../../components'
import { pickupRandomMember } from '../../utils/pickUpTeamBuildingMember'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

interface Input {
  answer: string
  minute: string
}

const MIN_COUNT = 1

export const Q2Select: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { control, errors, handleSubmit, watch } = useForm<Input>()

  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { isLeader, teamBuilding } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onSubmit = async (data: Input) => {
    const number = Number(data.answer)
    const minute = Number(data.minute)
    const picked = pickupRandomMember(teamBuildingMembers, [])

    if (picked && number >= MIN_COUNT) {
      await updateTeamBuilding({
        id: props.match.params.teamId,
        status: TeamBuildingStatus.Q2Presen,
        q2PresenNumber: number,
        q2PresenUserIds: [picked.userId],
        q2PresenMinute: minute,
        timeQ2Presen: new Date().toISOString(),
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QuestionContainer
          active={2}
          progressDotsProps={isLeader ? { maxLength: 6, currentProgress: 2 } : {}}
          questionHeadBar
        >
          {isLeader ? (
            <>
              <div className={classes.cardContainer}>
                <UsefulContainer>
                  <FormLabel>発表する人数を入力してください。</FormLabel>
                  <Controller
                    name={'answer'}
                    control={control}
                    defaultValue={1}
                    rules={{ required: '入力してください' }}
                    render={({ onChange, value }) => (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SelectBox maxNumber={teamBuildingMembers.length} value={value} onChange={onChange} />
                          <span style={{ marginLeft: 16 }}>人</span>
                        </div>
                        <FormError error={errors.answer} />
                      </>
                    )}
                  />
                </UsefulContainer>
                <UsefulContainer>
                  <FormLabel>1人あたりの発表時間</FormLabel>
                  <Controller
                    name={'minute'}
                    control={control}
                    rules={{ required: '入力してください' }}
                    defaultValue={3}
                    render={({ onChange, value }) => (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SelectBox maxNumber={60} value={value} onChange={onChange} />
                          <span style={{ marginLeft: 16 }}>分</span>
                        </div>
                        <FormError error={errors.minute} />
                      </>
                    )}
                  />
                </UsefulContainer>
              </div>
              <ButtonContainer
                fixed
                buttons={[
                  <Button
                    key="q2-select-1"
                    type={'submit'}
                    disabled={!(watch('answer') && Number(watch('answer')) >= MIN_COUNT)}
                  >
                    次へ
                  </Button>,
                ]}
              />
            </>
          ) : (
            <Waiting who={'member'} status={teamBuilding?.status} />
          )}
        </QuestionContainer>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    width: '100%',
    marginBottom: 40,
  },
}))
