import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding, useManageTeamBuildingMember } from 'pages/teams/hooks'
import { queryGetTeamMember } from 'pages/teams/hooks/graphql'
import {
  ResultQ3RankingItem,
  ResultQ3RankingItemPerson,
  TeamBuilding,
  TeamBuildingMember,
  TeamBuildingStatus,
} from 'utils/generated'

import {
  Button,
  ButtonContainer,
  FormLabel,
  InputCard,
  QuestionContainer,
  SelectItems,
  Timer,
  Waiting,
  QuestionPcLayout,
} from '../../components'
import { RankingItem } from '../../components/Ranking'
import { SelectItem } from '../../components/SelectItems'

import { constants } from 'assets'
import { TEAM_BUILDING_PRESEN_TIME } from 'assets/constants'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q3Vote2: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { currentTeamBuildingMember, refreshTeamBuildingMember, teamBuildingMembers } =
    React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const { updateTeamBuildingMember } = useManageTeamBuildingMember()

  const MAX_COUNT = 1
  const [keywordItems, setKeywordItems] = React.useState<SelectItem[]>([])

  React.useEffect(() => {
    ;(async () => {
      if (teamBuilding) {
        if (!teamBuilding.resultQ3) {
          const rankings = extractResultQ2(teamBuilding)
          const selected = await getResultQ3Selected(rankings, teamBuildingMembers)
          setKeywordItems(formatKeywordItems(selected, keywordItems))

          await updateTeamBuilding({
            id: teamBuilding.id,
            resultQ3: JSON.stringify({
              version: 'v1',
              selected,
              rankings: [],
              answer: '',
            }),
          })
        } else {
          const resultQ3 = JSON.parse(teamBuilding.resultQ3) as {
            version: string
            selected: ResultQ3RankingItem[]
            answer: string
          }
          setKeywordItems(formatKeywordItems(resultQ3.selected, keywordItems))
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding, teamBuildingMembers])

  const handleOnClickItem = (name: string, value: boolean) => {
    const updated = keywordItems.reduce((items: SelectItem[], i) => {
      if (i.name === name) {
        items.push({
          ...i,
          value,
        })
      } else {
        items.push({ ...i, value: false })
      }
      return items
    }, [])
    setKeywordItems(updated)
  }

  const onSubmit = async () => {
    const updated = await updateTeamBuildingMember({
      teamId: props.match.params.teamId,
      resultQ3: JSON.stringify(keywordItems.filter((i) => i.value)),
    })

    if (updated) {
      refreshTeamBuildingMember()
    }
  }

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q3Adjust,
      timeQ3Adjust: new Date().toISOString(),
    })
  }

  return (
    <>
      <QuestionContainer
        active={3}
        progressDotsProps={!currentTeamBuildingMember?.resultQ3 ? { maxLength: 2, currentProgress: 1 } : {}}
        questionHeadBar
        colorTitleText={!currentTeamBuildingMember?.resultQ3}
      >
        {!currentTeamBuildingMember?.resultQ3 ? (
          <>
            <div className={classes.cardContainer}>
              <InputCard noRadius>
                <QuestionPcLayout questionNumber={3} />
                <FormLabel style={{ marginBottom: 31 }}>
                  自チームの理想の組織状態は下記のうちどれにしますか？上位３つから決選投票します。
                </FormLabel>
                <FormLabel style={{ fontWeight: 'normal', marginBottom: 22 }}>
                  良いと感じる1つに”いいね“を押しましょう。
                  <br />
                  記入した方は一つずつ選んだ背景を説明してください。
                </FormLabel>
                <SelectItems items={keywordItems} icon={'heart'} isFlexBottomNode onClick={handleOnClickItem} />
              </InputCard>
            </div>

            {teamBuilding?.timeQ3Vote2 && (
              <Timer from={teamBuilding.timeQ3Vote2} time={TEAM_BUILDING_PRESEN_TIME} container />
            )}

            <ButtonContainer
              fixed
              marginTopPcLayout={40}
              buttons={[
                <Button
                  key="q3-vote-2-1"
                  onClick={onSubmit}
                  disabled={keywordItems.filter((i) => i.value).length !== MAX_COUNT}
                >
                  次へ
                </Button>,
              ]}
            />
          </>
        ) : (
          <Waiting
            who={isLeader ? 'leader' : 'member'}
            progress={{
              child: teamBuildingMembers.filter((t) => !!t.resultQ3).length,
              parent: teamBuildingMembers.length,
            }}
            onNext={onNext}
            status={teamBuilding?.status}
          />
        )}
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  cardContainer: {
    width: '100%',
    marginBottom: 150,
  },
}))

const AnsweredName: React.FC<{ userId?: string; name: string }> = ({ name }) => {
  const classes = useStylesAnsweredName()

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="human" />
      </div>
      <div className={classes.name}>{name}さん</div>
    </div>
  )
}

const useStylesAnsweredName = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    width: 'calc(50% - 4px)',
  },

  iconContainer: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    width: 12,
    height: 12,
    flexShrink: 0,
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
  },

  name: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 12,
    color: constants.TEXT_GRAY_DARK,
    overflowWrap: 'anywhere',
  },
}))

const extractResultQ2 = (teamBuilding: TeamBuilding) => {
  if (teamBuilding.resultQ2) {
    const resultQ2 = JSON.parse(teamBuilding.resultQ2) as RankingItem[]
    return resultQ2.reduce((items: RankingItem[], i) => {
      if (items.length >= 3) {
        return items
      }
      items.push(i)
      return items
    }, [])
  } else {
    return []
  }
}

const getResultQ3Selected = async (rankings: RankingItem[], teamBuildingMembers: TeamBuildingMember[]) => {
  const result: ResultQ3RankingItem[] = []
  for (const ranking of rankings) {
    const people: ResultQ3RankingItemPerson[] = []
    for (const m of teamBuildingMembers) {
      const teamMember = await queryGetTeamMember({ id: `${m.teamId}-${m.userId}` })

      if (teamMember && m.resultQ2) {
        const q2 = JSON.parse(m.resultQ2) as SelectItem[]

        for (const q of q2) {
          if (ranking.id === q.name) {
            people.push({ userId: teamMember.userId, fullName: teamMember.fullName })
          }
        }
      }
    }
    result.push({
      ...ranking,
      people,
    })
  }

  return result
}

const formatKeywordItems = (selected: ResultQ3RankingItem[], olds: SelectItem[]): SelectItem[] => {
  const result: SelectItem[] = []

  for (const s of selected) {
    const bottomNode: React.ReactNode[] = []
    for (const p of s.people) {
      if (p) {
        bottomNode.push(<AnsweredName userId={p.userId} name={p.fullName} key={p.userId} />)
      }
    }

    const old = olds.find((o) => o.name === s.id)
    if (old) {
      result.push({
        name: s.id,
        label: s.answer,
        value: old.value,
        bottomNode,
      })
    } else {
      result.push({
        name: s.id,
        label: s.answer,
        value: false,
        bottomNode,
      })
    }
  }

  return result
}
