import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'

import { constants } from 'assets'

type Props = {
  backColor: 'primary' | 'secondary' | 'tertiary'
} & React.ComponentProps<'button'>

export const BasicButton: React.FC<Props> = ({ className, onClick, children, backColor }) => {
  const classes = useStyles()

  return (
    <NoStyleButton className={classNames(classes.button, className, backColor)} type="button" onClick={onClick}>
      {children}
    </NoStyleButton>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      width: 400,
      height: 100,
      fontSize: 24,
      fontWeight: 'bold',
      borderRadius: 50,
      '&.primary': {
        background: constants.COLOR_YELLOW4,
        color: '#000',
      },
      '&.secondary': {
        background: '#fff',
        color: '#000',
      },
      '&.tertiary': {
        background: constants.COLOR_NAVY,
        color: constants.COLOR_YELLOW4,
      },
      [theme.breakpoints.down('sm')]: {
        width: 160,
        height: 50,
        fontSize: 16,
      },
    },
  }),
  { name: 'BasicButton' }
)
