import * as React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  path: string
  label: string
  ownStyles?: React.CSSProperties
}

export const TopLinkButton: React.FC<Props> = ({ path, label, ownStyles }) => {
  const classes = useStyles()

  return (
    <Link to={path}>
      <Button component="span" variant="contained" className={classes.root} style={{ ...ownStyles }}>
        {label}
      </Button>
    </Link>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      boxShadow: 'none',
      width: 250,
      backgroundColor: constants.COLOR_MAIN_NEW,
      border: `2px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 30,
      color: constants.TEXT_WHITE,
      fontSize: 14,
      fontWeight: 600,
      padding: '16px 0',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: constants.TEXT_WHITE,
        color: constants.COLOR_MAIN_NEW,
      },
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: constants.TEXT_WHITE,
          color: constants.COLOR_MAIN_NEW,
        },
      },
    },
  }),
  { name: 'TopButton' }
)
