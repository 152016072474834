import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { API } from 'aws-amplify'
import { Observable } from 'zen-observable-ts'

import {
  subscriptionCreateGhostTeamMember,
  subscriptionDeleteGhostTeamMember,
  subscriptionUpdateGhostTeamMember,
} from 'pages/ghost/api/member/graphql'
import { GP } from 'pages/ghost/assets/pages'
import { callDialog } from 'pages/ghost/components/organisms/Dialog'
import { GhostTeam, GhostTeamMember, VoteReportsResult } from 'utils/generated'

import { subscriptionForceGetVoteResult, subscriptionVoteGhostReports } from '../api/report/graphql'
import { subscriptionUpdateGhostTeam } from '../api/team/graphql'
import { GhostTeamStore } from '../contexts/ghostTeam'
import { GhostTeamMemberStore } from '../contexts/ghostTeamMember'
import { PageManager } from '../contexts/pageManger'
import { gqlOp, GqlSubRes } from '../utils/gql'

import { EventHandlers } from './eventHandlers'

export const useEvents = (
  masterTeamId: String,
  history: ReturnType<typeof useHistory>,
  { team, loading: loadingTeam, renewGhostTeamInfo }: GhostTeamStore,
  { teamMember, loading: loadingMember, renewTeamMemberList }: GhostTeamMemberStore,
  { jumpTo }: PageManager,
  { pageSync, assignVotedResult, handleMemberDeleted }: EventHandlers
) => {
  //
  // Vars to listen
  //

  const currentPath = history.location.pathname

  //
  // Initilizations
  //

  // GhostTeamNotFound
  useEffect(() => {
    if (!team && !loadingTeam) {
      // maybe create team here in next version
      jumpTo(`/teams/${masterTeamId}`)
    }
  }, [team, teamMember, loadingTeam, loadingMember, masterTeamId, jumpTo])

  // MemberDeleted
  useEffect(() => {
    if (team) {
      // team still exists
      if (!teamMember && !loadingMember) {
        // user maybe deleted
        jumpTo(GP.started) // not stable, should be scoped
      }
    }
  }, [team, teamMember, loadingMember, masterTeamId, jumpTo])

  //
  // Page Managements
  //

  // CurrentPathChanged
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [currentPath])

  // TeamOrMemberPageChanged
  useEffect(() => {
    if (team && teamMember) {
      pageSync(team, teamMember)
    }
  }, [team, teamMember, currentPath, pageSync])

  // BrowserBackButtonPressed
  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP' && team && teamMember) {
        pageSync(team, teamMember)
      }
    })
  }, [team, teamMember, currentPath, history, pageSync])

  //
  // Subscription
  // useSubscribe のカスタムフックから変更
  // useEffect でサブスクリプションを個別に登録する
  //

  // SubscriptionUpdateTeamDetected
  useEffect(() => {
    if (!team?.id) return

    const observer = API.graphql(gqlOp(subscriptionUpdateGhostTeam, { id: team.id })) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onUpdateGhostTeam', GhostTeam>) => {
        const data = value.value.data.onUpdateGhostTeam as GhostTeam
        if (data) {
          renewGhostTeamInfo(data)
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [team?.id, renewGhostTeamInfo])

  // SubscriptionUpdateMemberDetected
  useEffect(() => {
    if (!teamMember?.teamId) return

    const observer = API.graphql(
      gqlOp(subscriptionUpdateGhostTeamMember, { teamId: teamMember?.teamId })
    ) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onUpdateGhostTeamMember', GhostTeamMember>) => {
        const data = value.value.data.onUpdateGhostTeamMember as GhostTeamMember
        if (data) {
          renewTeamMemberList()
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [teamMember?.teamId, renewTeamMemberList])

  // SubscriptionCreateMemberDetected
  useEffect(() => {
    if (!teamMember?.teamId) return

    const observer = API.graphql(
      gqlOp(subscriptionCreateGhostTeamMember, { teamId: teamMember?.teamId })
    ) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onCreateGhostTeamMember', GhostTeamMember>) => {
        const data = value.value.data.onCreateGhostTeamMember as GhostTeamMember
        if (data) {
          renewTeamMemberList()
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [teamMember?.teamId, renewTeamMemberList])

  // SubscriptionDeleteMemberDetected
  useEffect(() => {
    if (!teamMember?.teamId) return

    const observer = API.graphql(
      gqlOp(subscriptionDeleteGhostTeamMember, { teamId: teamMember?.teamId })
    ) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onDeleteGhostTeamMember', GhostTeamMember>) => {
        const data = value.value.data.onDeleteGhostTeamMember as GhostTeamMember
        if (data) {
          if (data.id === teamMember?.id) {
            handleMemberDeleted()
          } else {
            renewTeamMemberList()
          }
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [teamMember?.teamId, renewTeamMemberList, teamMember?.id, handleMemberDeleted])

  // SubscriptionVoteResultDetected
  useEffect(() => {
    if (!team?.id) return

    const observer = API.graphql(gqlOp(subscriptionVoteGhostReports, { teamId: team.id })) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onVoteGhostReports', VoteReportsResult>) => {
        const data = value.value.data.onVoteGhostReports as VoteReportsResult
        if (data) {
          assignVotedResult(data)
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [team?.id, assignVotedResult])

  // SubscriptionForceVoteResultDetected
  useEffect(() => {
    if (!team?.id) return

    const observer = API.graphql(gqlOp(subscriptionForceGetVoteResult, { teamId: team.id })) as Observable<object>

    const subscription = observer.subscribe({
      next: (value: GqlSubRes<'onForceGetVoteResult', VoteReportsResult>) => {
        const data = value.value.data.onForceGetVoteResult as VoteReportsResult
        if (data) {
          assignVotedResult(data)
        }
      },
      error: (e) => {
        console.log(e)
        callDialog('reconnect')
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [team?.id, assignVotedResult])

  //
  // Other Events
  //
}
