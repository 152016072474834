import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { OnboardingDaily } from 'utils/generated'

import BAD_FEELING_SOMEWHAT from '../assets/BAD_FEELING_SOMEWHAT.svg'
import BAD_PRIVATE_TIME from '../assets/BAD_PRIVATE_TIME.svg'
import BAD_WORK_PROGRESS from '../assets/BAD_WORK_PROGRESS.svg'
import BAD_FEELING from '../assets/GOOD_FEELING_SOMEWHAT.svg'
import BAD_HUMAN_RELATION from '../assets/GOOD_PRIVATE_TIME.svg'
import GREAT_SHADE from '../assets/GOOD_WORK_PROGRESS.svg'

type CheckItem = {
  id: OnboardingDaily
  str: string
  color: string
}

const checkList: CheckItem[] = [
  { id: OnboardingDaily.GreatShade, str: '仕事が順調でいい感じ', color: '#CE2C2C' },
  { id: OnboardingDaily.BadHumanRelation, str: 'プライベートも充実していい感じ', color: '#966D27' },
  { id: OnboardingDaily.BadFeeling, str: 'なぜかちょっと調子良い', color: '#1A8441' },
  { id: OnboardingDaily.BadWorkProgress, str: '仕事の進み具合が不安', color: '#357895' },
  { id: OnboardingDaily.BadPrivateTime, str: 'プライベートがちょっとモヤモヤ', color: '#707070' },
  { id: OnboardingDaily.BadFeelingSomewhat, str: 'なぜかちょっと調子悪い', color: '#664087' },
]

export const DailyCheckLabels = Object.fromEntries(checkList.map((c) => [c.id, c.str])) as {
  [k in OnboardingDaily]: string
}

type Props = {
  onSelect: (value: OnboardingDaily) => void
  selected?: OnboardingDaily | null
  loading: boolean
}

export const ListBtn: React.FC<Props> = ({ onSelect, selected, loading }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.card}>
        <div className={classes.title}>今日の気分はどうですか？</div>
        <div className={classes.listBtnContainer}>
          {checkList.map((checkItem) => {
            const itemsImg = (item: OnboardingDaily): string => {
              switch (item) {
                case OnboardingDaily.GreatShade:
                  return GREAT_SHADE
                case OnboardingDaily.BadPrivateTime:
                  return BAD_PRIVATE_TIME
                case OnboardingDaily.BadWorkProgress:
                  return BAD_WORK_PROGRESS
                case OnboardingDaily.BadHumanRelation:
                  return BAD_HUMAN_RELATION
                case OnboardingDaily.BadFeeling:
                  return BAD_FEELING
                case OnboardingDaily.BadFeelingSomewhat:
                  return BAD_FEELING_SOMEWHAT
                default:
                  return ''
              }
            }

            const conditionImg: string = itemsImg(checkItem.id)

            return (
              <div
                className={`${classes.item} ${classes.conditions} ${checkItem.id} ${
                  !loading && selected === checkItem.id ? 'checkedItem' : ''
                }`}
                key={checkItem.id}
                onClick={() => onSelect(checkItem.id)}
              >
                <div className={classes.CImg}>
                  <img src={conditionImg} alt={checkItem.id} />
                </div>
                <div className={classes.listItem}>{checkItem.str}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  card: {
    padding: '24px 16px 13px 16px',
    backgroundColor: '#fff',
    borderRadius: 8,
    maxWidth: 560,
  },
  title: {
    fontWeight: 'bold',
  },
  listBtnContainer: {
    margin: '0',
    padding: '0',
  },
  item: {
    border: '1px solid lightgray',
    borderRadius: 10,
    margin: '24px 0',
    padding: '9px, 0px,0px,16px',
    maxWidth: 528,
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:first-child': {
      marginTop: 27,
    },
    '&:last-child': {
      marginBottom: 13,
    },
  },
  conditions: {
    '&.GREAT_SHADE': {
      color: checkList[0].color,
      borderColor: checkList[0].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[0].color}4F`,
      },
    },
    '&.BAD_HUMAN_RELATION': {
      color: checkList[1].color,
      borderColor: checkList[1].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[1].color}4F`,
      },
    },
    '&.BAD_FEELING': {
      color: checkList[2].color,
      borderColor: checkList[2].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[2].color}4F`,
      },
    },
    '&.BAD_WORK_PROGRESS': {
      color: checkList[3].color,
      borderColor: checkList[3].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[3].color}4F`,
      },
    },
    '&.BAD_PRIVATE_TIME': {
      color: checkList[4].color,
      borderColor: checkList[4].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[4].color}4F`,
      },
    },
    '&.BAD_FEELING_SOMEWHAT': {
      color: checkList[5].color,
      borderColor: checkList[5].color,
      '&.checkedItem': {
        backgroundColor: `${checkList[5].color}4F`,
      },
    },
  },
  CImg: {
    width: 28,
    marginTop: 7,
    marginLeft: 9,
    '& img': {
      height: 30,
      width: 30,
    },
  },
  listItem: {
    margin: '0 auto 0 16px',
    fontSize: 16,
    fontWeight: 'bold',
  },
})
