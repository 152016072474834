import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetSelfCheck } from './graphql'

const getSelfcheckResult = async () => {
  const response: any = await API.graphql(graphqlOperation(QueryGetSelfCheck))

  const { getSelfCheck } = response.data

  return getSelfCheck
}

export default getSelfcheckResult
