import * as React from 'react'

import AlertDialog from 'components/AlertDialog'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  open: boolean
  onClose: () => void
}

// -----------------------------
// Component
// -----------------------------
export const DialogCopy = (props: IProps) => {
  return <AlertDialog open={props.open} success={true} onClose={props.onClose} body={<p>URLをコピーしました</p>} />
}
