import React from 'react'

import { RouteComponentProps } from 'react-router'

import { TeamsTools } from '../about/components/Tools'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsToolsPage: React.FC<Props> = (props) => {
  const teamId = props.match.params.teamId

  if (!teamId) {
    return <></>
  }

  return <TeamsTools teamId={teamId} />
}
