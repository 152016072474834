import { createContext, useCallback, useEffect, useState } from 'react'

import { Ghost } from 'utils/generated'

import { getGhostList } from '../api/ghost/handlers'

export type GhostStore = ReturnType<typeof useGhostStore>

export type GhostModalContents =
  | ''
  | 'list'
  | 'detail'
  | 'select'
  | 'select-detail'
  | 'detail-no-tab'
  | 'detail-no-tab-no-select'

export const GhostContext = createContext<GhostStore>({} as GhostStore)

// TODO: hopefully use reducer for each state add/remove.

export const useGhostStore = (masterTeamId?: string) => {
  // store ghosts data here.
  const [ghosts, setGhosts] = useState<Ghost[]>([])

  // modal controll
  const [selectedGhostIds, setGhostIds] = useState<string[]>([])
  const [selectedGhostNames, setGhostNames] = useState<string[]>([])
  const [modal, setModal] = useState<GhostModalContents>('')
  const [detailId, setDetail] = useState<string>('1')

  const fetchGhostList = useCallback(async (masterTeamId: string) => {
    const res = await getGhostList({ masterTeamId })
    if (res) {
      res.sort((a, b) => {
        return Number(a.fileNo) - Number(b.fileNo)
      })
      setGhosts(res)
    }
  }, [])

  // Init
  useEffect(() => {
    fetchGhostList(masterTeamId)
  }, [masterTeamId, fetchGhostList])

  /**
   * handler for selecting ghosts on the ghost list page.
   * @param id
   */

  const selectGhost = (id: string, open = true) => {
    const maxSelected = selectedGhostIds.length === 3
    const newSelects = selectedGhostIds.slice()
    if (selectedGhostIds.includes(id)) {
      const idx = newSelects.findIndex((s) => s === id)
      newSelects.splice(idx, 1)
    } else {
      if (maxSelected) return
      newSelects.push(id)
    }
    setGhostIds(newSelects)
    const names = newSelects.map((id) => ghosts.find((ghost) => ghost.id === id)?.name || '')
    setGhostNames(names)
    if (open) {
      setModal('select')
    }
  }

  /**
   * call when voted result found
   */
  const incrementFound = useCallback(
    (ghostId: string) => {
      helpers.incrementFound(ghosts, ghostId)
    },
    [ghosts]
  )

  const resetSelectedGhosts = useCallback(() => {
    setGhostIds([])
    setGhostNames([])
  }, [])

  // TODO: Get this outta here.
  const [leftVisible, setLeftVisible] = useState(true) // idk what this is
  const [directClose, setDirectClose] = useState(false) // true when opening from result ghost pane

  useEffect(() => {
    if (modal === '') {
      setLeftVisible(true)
      setDirectClose(false)
    }
  }, [modal])

  return {
    ghosts,
    selectedGhostIds,
    selectedGhostNames,
    selectGhost,
    incrementFound,
    resetSelectedGhosts,
    modal,
    setModal,
    detailId,
    setDetail,
    leftVisible,
    setLeftVisible,
    directClose,
    setDirectClose,
  }
}

const incrementFound = (ghosts: Ghost[], ghostId: string) => {
  const idx = ghosts.findIndex((g) => g.id === ghostId) // is this ok? what if not found
  const g = ghosts[idx]
  const inserted = { ...g, found: g.found ? g.found + 1 : 1 }
  return [...ghosts.slice(0, idx), inserted, ...ghosts.slice(idx + 1)]
}

const helpers = {
  incrementFound,
}
