import { useState } from 'react'

// -----------------------------
// interface
// -----------------------------
export type IuseTitleProps = {
  onTitleEnter: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onUpdateSetTitle: (value: string) => void
  onPreventEnter: (e: any) => any
  getTitle: () => string
  getPlaceholder: () => string
  getMaxTitleLen: (maxNum: number) => number
}

// -----------------------------
// hooks
// -----------------------------
export const useTitle = (): IuseTitleProps => {
  const [title, setTitle] = useState<string>('')

  /**
   * タイトルの入力文字数を制限する
   */
  const onTitleEnter = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (e.target.value.length < 50) {
      setTitle(e.target.value)
    }
  }

  /**
   * アップデート時のタイトルを設定する
   */
  const onUpdateSetTitle = (value: string): void => {
    setTitle(value)
  }

  /**
   * 改行できないようにする
   */
  const onPreventEnter = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
    onTitleEnter(e)
  }

  /**
   * 入力中のタイトルを取得する
   */
  const getTitle = (): string => {
    return title
  }

  /**
   * Placeholderを取得する
   */
  const getPlaceholder = (): string => {
    return 'タイトル入力'
  }

  /**
   * 入力最大文字数を返す
   */
  const getMaxTitleLen = (maxNum: number): number => {
    return maxNum
  }

  return {
    onTitleEnter,
    onUpdateSetTitle,
    onPreventEnter,
    getMaxTitleLen,
    getTitle,
    getPlaceholder,
  }
}
