import { useCallback, useEffect, useState } from 'react'

import { getGhostHistoriesByMaster } from 'pages/ghost/api/history/handlers'
import { GhostHistory } from 'utils/generated'

export const useGetGhostHistoriesByMaster = ({
  masterTeamId,
  fetchAllData,
}: {
  masterTeamId: string
  fetchAllData?: boolean
}) => {
  const [ghostHisotries, setGhostHistories] = useState<GhostHistory[]>([])
  const [error, setError] = useState<Error | undefined>()
  const [isLoadingHistories, setIsLoadingHistories] = useState<boolean>(false)

  const fetchHistories = useCallback(
    async ({
      masterTeamId,
      nextToken,
      fetchAllData,
    }: {
      masterTeamId: string
      nextToken?: string
      fetchAllData?: boolean
    }) => {
      setIsLoadingHistories(true)
      setError(undefined)

      try {
        const response = await getGhostHistoriesByMaster({ masterTeamId, nextToken })
        setGhostHistories((prev) => [...prev, ...(response?.items ?? [])])

        // fetchAllData が true であり、nextToken が存在する場合は再帰的にデータをフェッチ
        if (response?.nextToken) {
          await fetchHistories({ masterTeamId, nextToken: response.nextToken, fetchAllData })
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setIsLoadingHistories(false)
      }
    },
    []
  )

  useEffect(() => {
    fetchHistories({ masterTeamId, fetchAllData })
  }, [fetchHistories, masterTeamId, fetchAllData])

  return { ghostHisotries, error, isLoadingHistories } as const
}
