import React, { useContext, Dispatch, SetStateAction, useState } from 'react'

import { makeStyles, Theme, TextField, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { MediaGrid } from 'pages/onboarding/components/timeline/MediaGrid'
import { usePreview } from 'pages/onboarding/components/timeline/PreviewBox'
import { mutationupdateOnboardingPostPost } from 'pages/onboarding/graphql'
import { CardContent } from 'pages/onboarding/pages/timeline/components/list-item/components/card-content-new'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { converts } from 'pages/onboarding/utils/convertText'
import { postBalloonLabel } from 'pages/onboarding/utils/postLabel'
import { getDailyCheckImage } from 'pages/onboarding/utils/timelineImage'
import { dateStringToLPassedTime } from 'utils/date'
import {
  OnboardingPost,
  OnboardingPostType,
  MutationUpdateOnboardingPostPostArgs,
  OnboardingTeamMember,
} from 'utils/generated'
import { OnboardingPostFile } from 'utils/generated'

import { AttachmentFileBox } from '../../../components/timeline/AttachmentFIleBox'
import { AttachmentImgBox } from '../../../components/timeline/AttachmentImgBox'
import { TeamStorageContext } from '../../timeline/hooks/teamStorage'

import NoAvatarSvg from './assets/noAvatar.svg'
import * as constants from 'assets/constants'

type Props = {
  post: OnboardingPost
  teamMembers: OnboardingTeamMember[]
  onAvatar?: () => void
  isEdit?: boolean
  setIsEdit: Dispatch<SetStateAction<boolean>>
  allEditClose?: boolean
}
type PickInput = Pick<IFormCharCountInput, 'editPost'>

export const Card: React.FC<Props> = ({ post, teamMembers, onAvatar, isEdit, setIsEdit, allEditClose }) => {
  const classes = useStyles()
  const [postFiles, setPostFiles] = useState<OnboardingPostFile[]>()
  const [postImgs, setPostImgs] = useState<OnboardingPostFile[]>()

  const { retrieveFiles } = useContext(TeamStorageContext)
  const inputName: keyof PickInput = 'editPost'
  const { register, handleSubmit, reset, watch, setValue } = useForm<PickInput>({ mode: 'onChange' })
  const maxLength = 10000
  const watchInput = watch(inputName, '')

  const onSubmit = async (data: PickInput) => {
    if (post && data.editPost) {
      const input: MutationUpdateOnboardingPostPostArgs['input'] = { id: post.id }
      if (data.editPost) {
        input['comment'] = data.editPost
      }
      await mutationupdateOnboardingPostPost({ input })
      location.reload()
      setIsEdit(false)
    }
  }

  const { setPreview } = usePreview()

  React.useEffect(() => {
    setIsEdit(false)
    setValue(inputName, post.comment)
  }, [allEditClose, post.comment, setIsEdit, setValue])

  React.useEffect(() => {
    if (isEdit) {
      setValue(inputName, post.comment)
    }
  }, [isEdit, post.comment, setValue])

  React.useEffect(() => {
    ;(async () => {
      const [files, imgs] = await retrieveFiles(post.fileNames || [], post.id)
      setPostFiles(files)
      setPostImgs(imgs)
    })()
  }, [post.fileNames, retrieveFiles, post.id])

  return (
    <>
      <div className={classes.cardHeaderContainer}>
        <div className={classes.cardUserContainer}>
          <div>
            {post.teamMember?.imageUrl ? (
              <img
                className={classes.cardUserAvatar}
                src={post.teamMember.imageUrl}
                alt={'avatar'}
                onClick={() => (onAvatar ? onAvatar() : {})}
              />
            ) : (
              <img
                className={classes.cardUserAvatar}
                src={NoAvatarSvg}
                alt={'no-avatar'}
                onClick={() => (onAvatar ? onAvatar() : {})}
              />
            )}
            {post.label ? (
              <div className={classes.cardPostLabel}>
                <img src={postBalloonLabel(post.label)} alt="post-label" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={classes.cardUserContent}>
              <div className={classes.cardUserName}>{post.teamMember?.nickname}</div>
              <div className={classes.cardUserTime}>
                {post.createdAt ? dateStringToLPassedTime(post.createdAt, 'time') : ''}
              </div>
            </div>
            {post.teamMember?.role ? (
              <div className={classes.cardRoleContent}>
                <div className={classes.userRole}>{teamMemberRoleLabel(post.teamMember.role)}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={classes.cardContentContainer}>
        {post.comment ? (
          isEdit ? (
            <>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <TextField
                  autoFocus
                  id="edit-comment"
                  className={classes.textArea}
                  placeholder={'コメントを追加する'}
                  multiline
                  maxRows={8}
                  name={inputName}
                  inputRef={register({ required: true, maxLength })}
                />
                <FormCharCount length={watchInput.length} maxLength={maxLength} />
                <>
                  {isEdit ? (
                    <div className={classes.wrapper}>
                      <Button
                        className={classes.editCancel}
                        onClick={() => {
                          setIsEdit(false)
                          reset({ [inputName]: '' })
                        }}
                        variant="text"
                      >
                        キャンセル
                      </Button>
                      <Button
                        className={classes.editSave}
                        variant="text"
                        type="submit"
                        disabled={watchInput ? false : true}
                      >
                        保存
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </form>
            </>
          ) : (
            <div
              className={classes.cardContentText}
              dangerouslySetInnerHTML={{ __html: converts(post.comment, teamMembers) }}
            />
          )
        ) : (
          <></>
        )}
        {post.type === OnboardingPostType.Action && post.action ? (
          <CardContent item={post.action} label={post.label} detail />
        ) : (
          <></>
        )}
        {post.type === OnboardingPostType.Daily && post.daily ? (
          <>
            <div className={classes.cardContentImageText}>今日の気分は</div>
            <img className={classes.cardContentImage} src={getDailyCheckImage(post.daily)} alt={'illustration'} />
          </>
        ) : (
          <></>
        )}
        {postFiles ? (
          <div style={{ margin: '7px 0 0 0' }}>
            {postFiles.map((item, i) => (
              <AttachmentFileBox
                key={`AttachmentFileBox+${i}`}
                fileName={item.fileName}
                src={item.signedUrl || undefined}
                boxType={'timeline'}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
        {postImgs && postImgs.length ? (
          <MediaGrid>
            {postImgs.map((item, i) => (
              <AttachmentImgBox
                key={`atc-${i}-${item.fileName}`}
                src={item.signedUrl || undefined}
                boxType={'timeline'}
                mime={item.mimeType}
                onClick={() => setPreview(item)}
                isDownload
              />
            ))}
          </MediaGrid>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardHeaderButtonContainer: {
    height: 40,
  },
  cardUserContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  cardUserAvatar: {
    marginRight: 16,
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: constants.COLOR_GRAY,
    objectFit: 'cover',
    cursor: 'pointer',
    verticalAlign: 'top',
  },
  cardPostLabel: {
    '& img': {
      width: 60,
      verticalAlign: 'top',
    },
  },
  cardUserContent: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  cardUserName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: constants.TEXT_GRAY_DARK,
    lineHeight: 1,
    marginRight: 8,
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
  },
  cardUserTime: {
    fontSize: 10,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    lineHeight: 1,
    marginTop: 6,
  },
  cardRoleContent: {
    display: 'inline-block',
    marginTop: 11,
  },
  userRole: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 10,
    paddingLeft: 8,
    paddingRight: 8,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    height: '20px',
    fontWeight: 'bold',
  },
  cardContentContainer: {
    marginTop: 16,
  },
  cardContentText: {
    fontSize: 14,
    color: constants.TEXT_GRAY_DARK,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    '& a': {
      color: '#2CBBC6',
      textDecoration: 'underline',
    },
  },
  cardContentImageText: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 12,
    marginBottom: 7,
  },
  cardContentImage: {
    width: '100%',
    verticalAlign: 'top',
  },
  actionContainer: {
    fontFamily: 'sans-serif',
    backgroundColor: constants.COLOR_GRAY,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& div': {
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 16,
      paddingLeft: 16,
      width: '50%',
    },
  },
  actionButton: {
    color: constants.COLOR_MAIN,
  },
  toGoodDetail: {
    textAlign: 'right',
    color: '#8D8D8D',
    fontSize: 14,
  },
  arrowRight: {
    marginLeft: 16,
  },
  textArea: {
    marginTop: 8,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    width: '100%',
    '& .MuiInputBase-root': {
      padding: 0,
    },
    '& .MuiInputBase-input': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      padding: 8,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  editCancel: {
    marginRight: 8,
    color: constants.COLOR_ONBOARDING_GRAY,
    fontSize: 14,
    fontWeight: 'bold',
    height: 24,
    '&.MuiButton-root': {
      padding: 0,
      minWidth: 0,
    },
  },
  editSave: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 14,
    fontWeight: 'bold',
    height: 24,
    '&.MuiButton-root': {
      padding: 4,
      minWidth: 0,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'right',
  },
}))
