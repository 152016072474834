import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import Amplify from 'aws-amplify'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './store'

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_URL,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
    // graphql_headers: async () => {
    //   const currentSession = await Auth.currentSession()
    //   return { Authorization: currentSession.getIdToken().getJwtToken() }
    // },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET_UPLOAD,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
