import React from 'react'
import { Link } from 'react-router-dom'

import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import * as constants from '../../../../assets/constants'

type Props = {
  children: React.ReactNode
  linkTo: string
  fontSize?: number
}

export const ArrowLink: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Link to={props.linkTo}>
      <div className={classes.topSubTitleWrapper}>
        <Typography
          variant="subtitle1"
          className={classes.topSubTitle}
          style={{
            fontSize: props.fontSize ? props.fontSize : 16,
          }}
        >
          {props.children}
        </Typography>
      </div>
    </Link>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topSubTitleWrapper: {
      marginTop: 20,
      display: 'inline-block',
      cursor: 'pointer',
      color: constants.COLOR_GRAY_DARK,
      '&:hover': {
        color: '#343434',
        fontWeight: 'bold',
      },
      '&::after': {
        content: '""',
        display: 'block',
        marginTop: -16,
        marginLeft: 12,
        width: 145,
        height: 20,
        borderBottom: '1px solid ' + constants.COLOR_GRAY_DARK,
        borderLeft: '1px solid ' + constants.COLOR_GRAY_DARK,
        transform: 'skew(-45deg)',
      },
      '&:hover::after': {
        borderColor: '#343434',
        borderWidth: 2,
      },
    },
    topSubTitle: {
      marginLeft: 48,
      color: 'inherit',
      fontWeight: 'inherit',
    },
  })
)
