import React from 'react'

import {
  TeamBuildingActionDate,
  QueryGetTeamBuildingActionDatesArgs,
  IndexGetTeamBuildingActionDates,
} from 'utils/generated'

import { queryGetTeamBuildingActionDates } from './graphql'

export const useTeamBuildingActionDates = (teamId?: string) => {
  const [teamBuildingActionDates, setTeamBuildingActionDates] = React.useState<TeamBuildingActionDate[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const getTeamBuildingActionDates = async (
    args: Partial<QueryGetTeamBuildingActionDatesArgs>,
    replaceRecords = false
  ) => {
    if (!teamId) {
      return
    }
    setLoading(true)
    const current = replaceRecords ? [] : teamBuildingActionDates

    const params: QueryGetTeamBuildingActionDatesArgs = {
      id: teamId,
      index: IndexGetTeamBuildingActionDates.TeamId,
    }
    if (args.startDate && args.endDate) {
      params.startDate = args.startDate
      params.endDate = args.endDate
    }
    if (nextToken) {
      params.nextToken = nextToken
    }
    try {
      const fetchedTeamBuildingActionDates = await queryGetTeamBuildingActionDates(params)
      if (fetchedTeamBuildingActionDates) {
        setTeamBuildingActionDates([...current, ...fetchedTeamBuildingActionDates.items])
        setNextToken(fetchedTeamBuildingActionDates.nextToken)
      }
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)

    try {
      const fetchedTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
        id: teamId,
        index: IndexGetTeamBuildingActionDates.TeamId,
        nextToken,
      })

      if (fetchedTeamBuildingActionDates) {
        setTeamBuildingActionDates([...fetchedTeamBuildingActionDates.items])
        setNextToken(fetchedTeamBuildingActionDates.nextToken)
      }
    } catch (e) {
      setError(e)
    }
    setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  return { teamBuildingActionDates, getTeamBuildingActionDates, nextToken, loading, error, refresh } as const
}
