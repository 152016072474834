import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import {
  TeamBuildingReviewSummaryHistory,
  TeamBuildingReviewSummaryHistoryAverage,
  TeamBuildingReviewTeamAnswerType,
} from 'utils/generated'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { Step2Chart } from './step2/Step2Chart'


type Props = {
  title: string
  subtitle: string
  histories: TeamBuildingReviewSummaryHistory[] | null | undefined
}

type ChartData = {
  goal: {
    label: string
    data: number[]
  }
  codeOfConducts: {
    label: string
    data: number[]
  }[]
}

export const Step2: React.FC<Props> = ({ title, subtitle, histories }) => {
  const classes = useStyles()
  const { summaryBuilding } = React.useContext(TeamBuildingReviewHooksContext)

  const initData = React.useMemo(() => {
    return {
      goal: {
        label: summaryBuilding?.goal,
        data: [],
      },
      codeOfConducts:
        summaryBuilding?.codeOfConducts?.map((label) => ({
          label,
          data: [],
        })) ?? [],
    } as ChartData
  }, [summaryBuilding])

  const chartData = React.useMemo(() => {
    return histories?.reverse().reduce((prev, history) => {
      const goal: TeamBuildingReviewSummaryHistoryAverage | undefined = history.averages?.find(
        (average) => average.type === TeamBuildingReviewTeamAnswerType.TeamGoal
      )

      const codeOfConductsAverages: TeamBuildingReviewSummaryHistoryAverage[] =
        history.averages?.filter((average) => average.type === TeamBuildingReviewTeamAnswerType.CodeOfConduct) ?? []

      return {
        goal: {
          label: initData.goal.label,
          data: goal ? [...prev.goal.data, goal.average] : prev.goal.data,
        },
        codeOfConducts:
          initData.codeOfConducts?.reduce((prev, initCodeOfConducts, i) => {
            const found: TeamBuildingReviewSummaryHistoryAverage | undefined = codeOfConductsAverages.find(
              (a) => a.index === i
            )
            prev[i] = {
              label: initCodeOfConducts.label,
              data: found ? [...prev[i].data, found.average] : prev[i].data,
            }

            return prev
          }, initData.codeOfConducts) ?? [],
      }
    }, initData)
  }, [histories, initData])

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection title="チーム目標" split>
        <Content>
          <div style={{ fontWeight: 'bold' }}>{chartData?.goal.label}</div>
          <Step2Chart type="step2" data={chartData?.goal?.data ?? []} />
        </Content>
      </ContentSection>
      <ContentSection title="行動規範" split>
        {chartData?.codeOfConducts.map((codeOfConduct, i) => {
          return (
            <Content key={`${TeamBuildingReviewTeamAnswerType.CodeOfConduct}-${i}`}>
              <div style={{ fontWeight: 'bold' }}>{codeOfConduct.label}</div>
              <Step2Chart type="step2" data={codeOfConduct.data} />
            </Content>
          )
        })}
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}))
