import React from 'react'

import { API, graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Observable } from 'zen-observable-ts'

import {
  QueryGetTeamBuildingReviewMembersArgs,
  TeamBuildingReview,
  TeamBuildingReviewMember,
  TeamBuildingReviewSummary,
  TeamBuildingReviewMemberConnection,
  UpdateTeamBuildingReviewInput,
  UpdateTeamBuildingReviewMemberInput,
  CreateTeamBuildingReviewInput,
} from 'utils/generated'

import {
  MutationUpdateTeamBuildingReview,
  MutationUpdateTeamBuildingReviewMember,
  QueryGetTeamBuildingReview,
  QueryGetTeamBuildingReviews,
  QueryGetTeamBuildingReviewLatest,
  QueryGetTeamBuildingReviewMember,
  QueryGetTeamBuildingReviewMembers,
  QueryGetTeamBuildingReviewSummary,
  MutationCreateTeamBuildingReview,
} from './graphql'

const getTeamBuildingReviewLatest = async (teamId: string) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReviewLatest, { teamId }))
  const { getTeamBuildingReviewLatest } = response.data

  return getTeamBuildingReviewLatest
}

const getTeamBuildingReviews = async (teamId: string) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReviews, { teamId }))
  const { getTeamBuildingReviews } = response.data

  return getTeamBuildingReviews
}

export const getTeamBuildingReviewsLimit = async (teamId: string, limit: number) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReviews, { teamId, limit }))
  const { getTeamBuildingReviews } = response.data

  return getTeamBuildingReviews.items as TeamBuildingReview[]
}

export const getTeamBuildingReviewSummary = async (teamId: string) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReviewSummary, { teamId }))
  const { getTeamBuildingReviewSummary } = response.data

  return getTeamBuildingReviewSummary
}

const getTeamBuildingReview = async (id: string) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReview, { id }))
  const { getTeamBuildingReview } = response.data

  return getTeamBuildingReview
}

export const useTeamBuildingReviewSummary = (teamId: string) => {
  const [teamBuildingReviewSummary, setTeamBuildingReviewSummaryt] = React.useState<TeamBuildingReviewSummary>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReviewSummary(teamId)

      if (response) {
        setTeamBuildingReviewSummaryt(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { teamBuildingReviewSummary, loading, loaded, error, dataRefresh } as const
}

export const useTeamBuildingReviews = (teamId: string) => {
  const [teamBuildingReviews, setTeamBuildingReviews] = React.useState<TeamBuildingReview[]>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReviews(teamId)

      if (response) {
        setTeamBuildingReviews(response.items)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { teamBuildingReviews, loading, loaded, error, dataRefresh } as const
}

export const useTeamBuildingReviewLatest = (teamId: string) => {
  const [teamBuildingReviewLatest, setTeamBuildingReviewLatest] = React.useState<TeamBuildingReview>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!teamId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReviewLatest(teamId)

      if (response) {
        setTeamBuildingReviewLatest(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return { teamBuildingReviewLatest, loading, loaded, error, dataRefresh } as const
}
export const getTeamBuildingReviewMember = async (id: string) => {
  const response: any = await API.graphql(graphqlOperation(QueryGetTeamBuildingReviewMember, { id }))
  const { getTeamBuildingReviewMember } = response.data

  return getTeamBuildingReviewMember
}
export const getTeamBuildingReviewMembers = async ({
  teamBuildingReviewId,
  limit,
  nextToken,
}: QueryGetTeamBuildingReviewMembersArgs) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetTeamBuildingReviewMembers, { teamBuildingReviewId, limit, nextToken })
  )
  const { getTeamBuildingReviewMembers } = response.data

  return getTeamBuildingReviewMembers
}
export const useTeamBuildingReview = (id: string) => {
  const [teamBuildingReview, setTeamBuildingReview] = React.useState<TeamBuildingReview>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!id) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReview(id)

      if (response) {
        setTeamBuildingReview(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { teamBuildingReview, loading, loaded, error, dataRefresh } as const
}
export const useTeamBuildingReviewMembers = (args: QueryGetTeamBuildingReviewMembersArgs) => {
  const [teamBuildingReviewMembers, setTeamBuildingReviewMembers] = React.useState<TeamBuildingReviewMemberConnection>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!args.teamBuildingReviewId) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReviewMembers(args)

      if (response) {
        setTeamBuildingReviewMembers(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.teamBuildingReviewId])

  return { teamBuildingReviewMembers, loading, loaded, error, dataRefresh } as const
}

export const useTeamBuildingReviewMember = (id: string) => {
  const [teamBuildingReviewMember, setTeamBuildingReviewMember] = React.useState<TeamBuildingReviewMember>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const dataRefresh = async () => {
    if (!id) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await getTeamBuildingReviewMember(id)

      if (response) {
        setTeamBuildingReviewMember(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  React.useEffect(() => {
    dataRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { teamBuildingReviewMember, loading, loaded, error, dataRefresh } as const
}

export const createTeamBuildingReview = async (input: CreateTeamBuildingReviewInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationCreateTeamBuildingReview, { input }))

  const { createTeamBuildingReview } = response.data
  return createTeamBuildingReview
}

export const updateTeamBuildingReview = async (input: UpdateTeamBuildingReviewInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationUpdateTeamBuildingReview, { input }))

  const { updateTeamBuildingReview } = response.data
  return updateTeamBuildingReview
}

export const updateTeamBuildingReviewMember = async (input: UpdateTeamBuildingReviewMemberInput) => {
  const response: any = await API.graphql(graphqlOperation(MutationUpdateTeamBuildingReviewMember, { input }))

  const { updateTeamBuildingReviewMember } = response.data
  return updateTeamBuildingReviewMember
}

export const subscriptionUpdatedTeamBuildingReview = () => {
  const subscribe = API.graphql({
    query: gql`
      subscription {
        updatedTeamBuildingReview {
          id
          date
          teamId
          status
          iceBreak
          iceBreakNumber
          iceBreakUserIds
          iceBreakMinute
          answers {
            type
            index
            average
            items {
              userId
              username
              value
            }
          }
          nextAt
        }
      }
    `,
  })

  return subscribe as Observable<object>
}

export const subscriptionUpdatedTeamBuildingReviewMember = () => {
  const subscribe = API.graphql({
    query: gql`
      subscription {
        updatedTeamBuildingReviewMember {
          id
          teamBuildingReviewId
          hash
          date
          teamId
          userId
          username
          teamMemberId
          answers {
            type
            index
            value
          }
          createdAt
          updatedAt
        }
      }
    `,
  })

  return subscribe as Observable<object>
}
