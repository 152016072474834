import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { constants } from 'assets'

type Props = {
  handleOpen: any
}

export const EditRanking: React.FC<Props> = (props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginBottom: 20,
          marginLeft: isSmDown ? 16 : 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            minWidth: 0,
          }}
          onClick={() => {
            props.handleOpen()
          }}
        >
          <div>
            <img
              style={{ marginTop: 6, marginRight: 6 }}
              src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_pen.svg'}
              alt="pen"
            />
          </div>
          <div
            style={{
              fontFamily: 'Hiragino Kaku Gothic Pro',
              fontSize: 14,
              color: constants.COLOR_ONBOARDING_MAIN,
              fontWeight: 'bold',
            }}
          >
            編集する
          </div>
        </div>
      </div>
    </>
  )
}
