import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  isHome: () => boolean
  isSelectFetch: () => boolean
  isShowHint: () => boolean
  isShowEmpty: () => boolean
  isShowPager: () => boolean
  isExistsArticles: () => boolean
  isShowNextButton: () => string
  getCatgory: () => string
  getType: () => string
}

// -----------------------------
// hooks
// -----------------------------
export const useSearchIndex = (): IProps => {
  const location = useLocation()
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)
  const hintSearchSelectors = useSelector((state: RootStateType) => state.hint.hintSearchList)
  const consultationSelectors = useSelector((state: RootStateType) => state.hint.consultationSearchList)

  /**
   * ページのHOMEかどうかを判定する
   */
  const isHome = (): boolean => {
    const isSearchHome = !location.pathname.includes('page') ? false : true
    return isSearchHome
  }

  /**
   * FetchするAPIをPathによって変更する
   */
  const isSelectFetch = (): boolean => {
    return location.pathname.includes('tips') || location.pathname.includes('profile') ? true : false
  }

  /**
   * Hint（Tips）の記事かどうかを判定する
   */
  const isShowHint = (): boolean => {
    return location.pathname.includes('tips') || location.pathname.includes('profile') ? true : false
  }

  /**
   * 投稿が空の場合の出し分け
   */
  const isShowEmpty = (): boolean => {
    const isHint = isShowHint() && hintSearchSelectors.items.length === 0
    const isConsultation = !isShowHint() && consultationSelectors.items.length === 0
    return isHint || isConsultation ? true : false
  }

  /**
   * TIPSとFAQ時のPagerを出し分け
   */
  const isShowPager = (): boolean => {
    if (isShowNextButton() === 'TIPS') {
      return hintSearchSelectors.nextToken || pagesSelectors.currentPages > 1 ? true : false
    }

    if (isShowNextButton() === 'FAQ') {
      return consultationSelectors.nextToken || pagesSelectors.currentPages > 1 ? true : false
    }

    return false
  }

  /**
   * 記事が存在するかどうかを判定
   */
  const isExistsArticles = (): boolean => {
    const isHint = hintSearchSelectors.items.length !== 0
    const isConsultation = consultationSelectors.items.length !== 0
    return isHint || isConsultation ? true : false
  }

  /**
   * NextButtonの表示を出し分け
   */
  const isShowNextButton = (): string => {
    return location.pathname.includes('tips') ? 'TIPS' : 'FAQ'
  }

  /**
   * TipsのカテゴリかFAQのカテゴリかを取得
   */
  const getCatgory = (): string => {
    const str = location.pathname
    if (str.includes('page')) {
      return location.pathname.split('/').slice(-3)[0]
    } else {
      return location.pathname.split('/').slice(-1)[0]
    }
  }

  /**
   * TipsかFAQを判定して文字列を取得する
   */
  const getType = (): string => {
    const str = location.pathname
    if (str.includes('tips')) {
      return `/hint/tips`
    } else {
      return `/hint/faq`
    }
  }

  return {
    isHome,
    isSelectFetch,
    isShowHint,
    isShowEmpty,
    isShowPager,
    isExistsArticles,
    isShowNextButton,
    getCatgory,
    getType,
  }
}
