import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { IuseHeroProps } from './useHero'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '320px',
    backgroundColor: '#F5F5F5',
  },

  heroWrapper: {
    position: 'relative',
    margin: 0,
  },

  heroContainer: {
    maxWidth: '1080px',
    height: '320px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: '0 auto',
  },

  heroInner: {
    position: 'relative',
    zIndex: 100,
    cursor: 'pointer',
    textAlign: 'right',
    marginTop: '32px',

    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
      marginRight: '15px',
    },
  },

  default: {
    zIndex: 2,
    display: 'block',
    width: '100%',
    height: '320px',
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },

  file: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'block',
    width: '100%',
    height: '320px',
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  input: {
    display: 'none',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseHeroProps
  uuid: string
}

// -----------------------------
// Component
// -----------------------------
export const Hero = (props: IProps) => {
  const classes = useStyles()

  React.useEffect(() => {
    props.presenter.setUpdateHero()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={classes.root}>
      <figure className={classes.heroWrapper}>
        {!props.presenter.getFile() && (
          <div className={classes.default} style={{ backgroundImage: 'url(/assets/svg/hint/selectImage_icn.svg)' }} />
        )}

        <label
          className={classes.file}
          htmlFor="heroFile"
          style={{ backgroundImage: props.presenter.resizeImage ? `url(${props.presenter.resizeImage})` : '' }}
        >
          <input
            className={classes.input}
            id="heroFile"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            onChange={props.presenter.onChangeFile}
          />
        </label>

        {props.presenter.resizeImage && (
          <div className={classes.heroContainer}>
            <div className={classes.heroInner} onClick={() => props.presenter.onCloseFile()}>
              <img src="/assets/svg/hint/hero_close.svg" alt="close" width="30px" height="30px" />
            </div>
          </div>
        )}
      </figure>
    </section>
  )
}
