import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

import Love from '../../assets/svg/likeOn.svg'

import { Font, Flexbox } from './'

type Props = {
  count: number
  ownStyles?: React.CSSProperties
}

export const GoodCount: React.FC<Props> = (props) => {
  return (
    <Flexbox
      ownStyles={{
        justifyContent: 'flex-end',
        color: constGhost.COLOR_MAINBLUE,
        fontSize: 12,
        ...props.ownStyles,
      }}
    >
      <img src={Love} alt="Love" style={{ marginRight: 8, verticalAlign: 'top' }} />
      <Font fontSize={16} ownStyles={{ color: '#CE2C2C', fontWeight: 'bold' }}>
        {props.count}
      </Font>
    </Flexbox>
  )
}
