import React, { useContext } from 'react'

import { Flexbox, UsefulCard } from '../../components/atoms'
import { GhostTemplateBoard } from '../../components/organisms'
import { GhostContext } from '../../contexts/ghost'

type Props = {
  ownStyles?: React.CSSProperties
}

export const GhostSelect: React.FC<Props> = () => {
  const { ghosts, selectedGhostIds, setModal, setDetail } = useContext(GhostContext)

  const onSelectGhost = (id: string) => {
    setDetail(id)
    setModal('select-detail')
  }

  return ghosts.length ? (
    <Flexbox onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <UsefulCard
        backgroundColor="white"
        borderRadius={8}
        ownStyles={{
          width: '100%',
          boxShadow: 'none',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '0 16px 40px',
        }}
      >
        <Flexbox ownStyles={{ fontSize: 14, fontWeight: 'bold', boxSizing: 'border-box', padding: '24px 0' }}>
          <div>オバケを3体選択してください</div>
        </Flexbox>
        <GhostTemplateBoard ghosts={ghosts} onTapCard={onSelectGhost} selectedIds={selectedGhostIds} />
      </UsefulCard>
    </Flexbox>
  ) : (
    <></>
  )
}
