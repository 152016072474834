import { useEffect, createContext, useState, useCallback } from 'react'

export interface TeamState {
  masterTeamId?: string
  startAt?: string
  endAt?: string
  members?: string[]
}

export type CreateTeam = ReturnType<typeof useCreateTeam>

export const createteamContext = createContext({} as CreateTeam)

export const useCreateTeam = (masterTeamId?: string) => {
  const [teamState, setTeamState] = useState<TeamState>({})

  const setIsTeamState = useCallback(
    (payload) => {
      setTeamState({ ...teamState, ...payload })
    },
    [teamState]
  )

  useEffect(() => {
    setIsTeamState({ masterTeamId })
  }, [masterTeamId]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    teamState,
    setIsTeamState,
    masterTeamId,
  }
}
