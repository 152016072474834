import * as React from 'react'
import { Link } from 'react-router-dom'

import { Theme, makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import { linkStateKey } from 'pages/notification'
import { Content } from 'pages/notification/hooks/useNotification'

import { constants } from 'assets'
import arrowRightSvg from 'assets/svg/arrowRight2.svg'

dayjs.extend(timezone)

type Props = {
  isLoading: boolean
  notification: Content | undefined
  error: Error | null
}

export const NotificationLink = ({ isLoading, notification, error }: Props) => {
  const classes = useStyles()

  if (error) {
    console.log('error:', error)
  }

  if (isLoading || (!error && !notification)) {
    return null
  }

  return (
    <div className={`${classes.root} ${!error ? 'hover' : ''}`}>
      {error ? (
        <div className={classes.error}>お知らせを取得できませんでした。時間をおいて再度お試しください。</div>
      ) : null}

      {!error && notification ? (
        <Link to={{ pathname: constants.PAGE_NOTIFICATION, state: { [linkStateKey]: true } }}>
          <div className={classes.desc}>
            <time className={classes.date} dateTime={notification.date}>
              {dayjs(notification.date).format('YYYY.MM.DD')}
            </time>
            <div className={classes.contentTitle}>{notification.title}</div>
          </div>
          <div className={classes.icon}>
            <img src={arrowRightSvg} alt="arrow right icon" />
          </div>
        </Link>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      width: '100%',
      height: 42,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      zIndex: 1000,
      boxSizing: 'border-box',
      animation: `$scrollLinkFadeIn 300ms ${theme.transitions.easing.easeOut}`,
      '@global': {
        '@keyframes scrollLinkFadeIn': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
      '&.hover': {
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: '#eee',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        top: 56,
        left: 0,
        boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
      },
      [theme.breakpoints.up('md')]: {
        bottom: 0,
        left: 0,
        width: 333,
        height: 70,
        borderTop: `1px solid ${constants.COLOR_GRAY_LIGHT5}`,
        borderRight: `1px solid ${constants.COLOR_GRAY_LIGHT5}`,
      },

      '& a': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px 12px',
        width: '100%',
        height: '100%',
        padding: '8px 24px',
        [theme.breakpoints.up('md')]: {
          padding: '12px 16px',
          gap: '8px 32px',
        },
      },
    },
    error: {
      width: '100%',
      height: '100%',
      padding: '0 16px',
      display: 'grid',
      placeItems: 'center',
      color: constants.COLOR_ALERT,
    },
    desc: {
      display: 'flex',
      gap: '8px 12px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
      },
    },
    date: {
      [theme.breakpoints.up('md')]: {
        fontWeight: 'bold',
        '&::before': {
          content: '"最新のお知らせ"',
          paddingRight: 10,
          color: constants.COLOR_MAIN_NEW,
        },
      },
    },
    contentTitle: {
      flex: 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      [theme.breakpoints.up('md')]: {
        fontSize: 10,
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 6,
      '& img': {
        width: '100%',
        verticalAlign: 'top',
      },
    },
  }),
  { name: 'NotificationLink' }
)
