import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { GhostResearch, MutationCreateGhostResearchArgs, MutationAddLikeOnGhostResearchArgs } from 'utils/generated'

import { mutationCreateGhostResearch, mutationAddLikeOnGhostResearch } from './graphql'

export const createGhostResearch = async (args: MutationCreateGhostResearchArgs) => {
  const res = await API.graphql({
    query: mutationCreateGhostResearch,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.createGhostResearch as GhostResearch
}

export const addLikeOnGhostResearch = async (args: MutationAddLikeOnGhostResearchArgs) => {
  const res = await API.graphql({
    query: mutationAddLikeOnGhostResearch,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.addLikeOnGhostResearch as GhostResearch
}
