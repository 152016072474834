import * as React from 'react'

import { Font } from '../atoms/index'

type Props = {
  pro: string
  con: string
  ownStyles?: React.CSSProperties
}

export const GhostBenefitAndRisk: React.FC<Props> = ({ pro, con, ownStyles }) => {
  return (
    <div style={ownStyles}>
      <Font fontSize={14} bold={true} ownStyles={{ marginBottom: 10 }}>
        オバケのご利益
      </Font>
      <Font fontSize={14} ownStyles={{ marginBottom: 24 }}>
        {pro}
      </Font>
      <Font fontSize={14} bold={true} ownStyles={{ marginBottom: 10 }}>
        オバケの悪さ
      </Font>
      <Font fontSize={14}>{con}</Font>
    </div>
  )
}
