import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { TextField } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { useUtils } from 'services/api/utils'

import { Breadcrumb } from 'components/Breadcrumb'

import * as constants from '../../assets/constants'
import * as errorCode from '../../assets/errorCode'
import Button from '../../components/Button'
import MessagePanel from '../../components/MessagePanel'

import { connector, ContainerProps } from './restore.container'

import { PatternEmail, PatternEmailMessage } from 'assets/patterns'



interface ICRestore {
  email: string
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const { register, handleSubmit, errors, clearErrors } = useForm<ICRestore>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [errors])

  const [processing, setProcessing] = React.useState(false)
  const onSubmit = (data: ICRestore) => {
    setProcessing(true)
    props.restore(data.email.toLowerCase())
  }

  const { getEncryptString } = useUtils()

  React.useEffect(() => {
    if (props.user?.restored && props.user?.email) {
      getEncryptString({ encrypt: true, text: props.user.email }).then((encrypted) => {
        if (encrypted) {
          props.history.push(`${constants.PAGE_RESET_PASSWORD}?email=${encodeURIComponent(encrypted)}`)
          window.scrollTo(0, 0)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.restored])

  const handleClose = () => {
    setProcessing(false)
    clearErrors()
    props.refreshErrors()
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.breadcrumb}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="パスワード再設定" />
        </div>
        <div className="__signin_index_container">
          <div
            style={{
              display: !errors.email && !props.user?.error ? 'block' : 'none',
            }}
          >
            <div className="__signin_index_container__header">パスワード再設定</div>

            <div className="__signin_index_container__body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="__labelWrapper">
                    <label>ログインID（メールアドレス）</label>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </div>
                  <TextField
                    name="email"
                    type="email"
                    inputMode="email"
                    className="__textField"
                    defaultValue={props.user?.params?.email}
                    inputRef={register({
                      required: 'メールアドレスを入力してください。',
                      pattern: {
                        value: PatternEmail,
                        message: PatternEmailMessage,
                      },
                    })}
                  />
                </div>
                <div className={classes.btnWrapper}>
                  <div className="__buttons">
                    <Button
                      bgColor={constants.COLOR_ORANGE_BTN}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      height={50}
                      submit={true}
                      disabled={processing}
                      body={<div>認証コードを送信</div>}
                      noShadow
                      hoverBgColor={constants.TEXT_WHITE}
                      hoverTextColor={constants.COLOR_ORANGE_BTN}
                      hoverBorderColor={constants.COLOR_ORANGE_BTN}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <Dialog
            open={!errors.email && !props.user?.error?.code ? false : true}
            onClose={handleClose}
            className={classes.errorDialog}
          >
            <div
              style={{
                display: !errors.email && !props.user?.error?.code ? 'none' : 'block',
              }}
            >
              <MessagePanel
                failure={true}
                handleClose={handleClose}
                body={
                  errors.email ? (
                    errors.email.message
                  ) : props.user && props.user?.error?.code === errorCode.USER_NOT_FOUND_EXCEPTION ? (
                    <>
                      <p>このログインIDは登録されていません。</p>
                      <p>ログインIDをご確認の上、もう一度入力し直してください。</p>
                    </>
                  ) : (
                    <>
                      <p>{props.user?.error?.code}</p>
                      <p>{props.user?.error?.message}</p>
                    </>
                  )
                }
              />
            </div>
          </Dialog>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      '& .MuiInputBase-input': {
        padding: 17,
      },

      '& .__signin_index_container': {
        margin: '0 auto',
        padding: '0 24px 123px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 1000,
        },

        '&__header': {
          marginTop: 80,
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bold',
          [theme.breakpoints.down('sm')]: {
            marginTop: 32,
            fontSize: 20,
          },
        },
        '&__body': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: 8,
          marginTop: 144,
          padding: '48px 24px 80px',
          boxShadow: '1px 3px 9px 4px rgba(21, 27, 38, 0.15)',
          [theme.breakpoints.up('md')]: {
            marginTop: 124,
            padding: '80px 200px 80px',
          },

          '&__description': {
            fontSize: '12px',
            padding: '16px 0',
          },

          '& form': {
            '& .__labelWrapper': {
              marginBottom: 12,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              '& label': {
                display: 'block',
                marginRight: 8,
                fontSize: 14,
                fontWeight: 'bold',
              },
              '& .__labelSVG': {
                width: 26,
                height: 15,
                '& img': {
                  width: '100%',
                },
              },
            },

            '& .__textField': {
              width: '100%',
              border: 'solid 2px #E2E6E2',
              borderRadius: 8,

              '& .MuiInput-underline:before': {
                border: constants.COLOR_MAIN, // Semi-transparent underline
              },
              '& .MuiInput-underline': {
                '&:after': {
                  border: constants.COLOR_MAIN, // Solid underline on focus
                },
              },
            },

            '& .__buttons': {
              margin: '40px auto 0',
              width: 200,
              [theme.breakpoints.down('sm')]: {
                width: 250,
              },
            },
          },
        },
      },
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },

    breadcrumb: {
      margin: '0 auto',
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },

    errorDialog: {
      '& .MuiDialog-paper': {
        margin: '32px 24px',
        borderRadius: 8,
        maxWidth: 327,
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
