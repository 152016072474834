import { useCallback, useEffect, useMemo } from 'react'
import { createContext, useState } from 'react'

import { Ghost, GhostHistory, GhostHistoryConnection } from 'utils/generated'

import { getGhostHistoryList } from '../api/history/handlers'

import { useGhostTeam } from './ghostTeam'

export const HistoryContext = createContext<HistoryStore>({} as HistoryStore)

export type HistoryStore = ReturnType<typeof useHistoryStore>

export const useHistoryStore = (masterTeamId?: string, ghostTeamId?: string, currentTeamMemberId?: string) => {
  const [historyList, setHistoryList] = useState<GhostHistoryConnection[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [latestGhost, setLatestGhost] = useState<GhostHistoryConnection>()
  const [selectedGhost, setSelectedGhost] = useState<Ghost | null>(null)
  const { team: ghostTeam } = useGhostTeam(masterTeamId)

  // slice(0, -1): 進行中のオバケがある場合、リストから除外する。
  // reverse(): バックエンドで日付を昇順にしているので、降順（新しい順）に並び替え、最新のオバケを find できるようにする。
  const completedGhostList = useMemo(
    () =>
      historyList[historyList.length - 1]?.history?.id === ghostTeam?.id
        ? historyList.slice(0, -1).reverse()
        : historyList.slice().reverse(),
    [historyList, ghostTeam]
  )

  // 該当のオバケが第何回目か、を取得する。
  // findIndex(): そのオバケが実施された直近の index。上記で reverse() しているので、最新のオバケが 0 となる。
  // completedGhostList.length: オバケのトータルの実施回数。
  const targetTimes = useMemo(() => {
    const index = completedGhostList.findIndex((item) => item.history?.ghostId === selectedGhost?.id)
    return index >= 0 ? completedGhostList.length - index : '_'
  }, [completedGhostList, selectedGhost])

  const targetHistory = useMemo(
    () => completedGhostList.find((item) => item.history?.ghostId === selectedGhost?.id),
    [completedGhostList, selectedGhost]
  )

  const hasHistoryDate = useMemo(() => !!targetHistory?.history?.createdAt, [targetHistory])

  const reports = useMemo(
    () => [
      {
        title: 'オバケが発生したと思われる原因',
        answer: targetHistory?.research?.first?.find((item) => item.userId === currentTeamMemberId)?.report,
      },
      {
        title: '今後の対処方法',
        answer: targetHistory?.research?.second?.find((item) => item.userId === currentTeamMemberId)?.report,
      },
      {
        title: '気づいたこと / 感じたこと',
        answer: targetHistory?.research?.feeling?.find((item) => item.userId === currentTeamMemberId)?.report,
      },
    ],
    [targetHistory, currentTeamMemberId]
  )

  // Init
  useEffect(() => {
    setLoading(true)
    async function fetch() {
      if (!masterTeamId || !ghostTeamId) {
        // require starting game. for next update
        setLoading(false)
        return
      }
      const res = await getGhostHistoryList({ masterTeamId })
      setHistoryList(res)
      setLoading(false)
      if (res.length > 0) {
        const filteredList = res.filter((item) => item.history?.createdAt && item.history.ghostId)
        const latestItem =
          filteredList.length > 0
            ? filteredList.reduce((prev, current) =>
                new Date(current.history?.createdAt ?? '') > new Date(prev.history?.createdAt ?? '') ? current : prev
              )
            : undefined

        setLatestGhost(latestItem)
      }
    }

    fetch()
    return () => {
      setLoading(false)
    }
  }, [masterTeamId, ghostTeamId])

  const findCurrentGameHistory = useCallback((): GhostHistory | undefined => {
    if (ghostTeamId) {
      const con = historyList.find((hc) => hc.history?.id === ghostTeamId)
      if (con && con.history) {
        return con?.history
      }
    }
  }, [historyList, ghostTeamId])

  // Received member's voted result.
  // Not necessary when reloaded.
  const [result, setResult] = useState<GhostHistory | undefined>(undefined)

  const currentHistory = result || findCurrentGameHistory()

  return {
    historyList,
    currentHistory,
    loading,
    setResult,
    latestGhost,
    selectedGhost,
    setSelectedGhost,
    completedGhostList,
    targetTimes,
    targetHistory,
    hasHistoryDate,
    reports,
  } as const
}
