import * as React from 'react'

import { TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { FormLabelMark } from 'pages/onboarding/components/FormLabelMark'

import * as constants from '../../../../../assets/constants'
import { Tooltip } from '../components/Tooltip'

type Props = {
  optionalIcon?: boolean
  toolTip?: () => void
  isOpen?: boolean
  titleFirst?: string
  messageFirst?: string
  titleSecond?: string
  messageSecond?: string
  target?: string
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Target: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.targetTitleWrapper}>
        <span className={classes.title}>目標</span>
        <div className={classes.optional}>
          <Tooltip
            toolTip={props.toolTip}
            isOpen={props.isOpen}
            titleFirst={props.titleFirst}
            messageFirst={props.messageFirst}
            titleSecond={props.titleSecond}
            messageSecond={props.messageSecond}
          />
        </div>
        {props.optionalIcon && <FormLabelMark markType={'optional'} />}
      </div>
      <TextField
        name={props.name}
        placeholder="目標"
        inputProps={{ 'aria-label': 'name' }}
        fullWidth={true}
        className={classes.input}
        value={props.target}
        onChange={props.onChange}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  targetTitleWrapper: {
    display: 'flex',
    justifyContent: 'baseline',
    margin: '20px 0 5px',
  },
  title: {
    fontWeight: 'bold',
  },
  optional: {
    marginLeft: 8,
    marginRight: 16,
    marginTop: 2,
  },
  box: {
    border: '1px solid #E9E9E9',
    padding: 16,
    margin: '9px 0',
    borderRadius: 4,
  },
  input: {
    fontSize: 14,
    padding: 16,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 5,
    '& .MuiInputBase-input': {
      padding: 0,
      fontSize: 14,
    },
    '& .MuiInput-underline::before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },
}))
