import * as React from 'react'

import { useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'
import { theme } from 'assets/theme'

export const FootMessage: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={`${classes.root} ${classes.hero}`}>
      <div className={classes.contentsWidth}>
        <div className={classes.flexWrapper}>
          <div className={classes.messageContainer}>
            <div className={classes.messageHeader}>
              まずは無料で
              {isMdUp ? null : <br />}
              ためしてみましょう
            </div>
            <div className={classes.messagePoints}>
              チームに「成果」を。
              <br />
              メンバーに「喜び」を。
            </div>
            <div className={classes.sentence}>
              Cocolaboはエンゲージメントの高いチーム作りを支援します。
              <br />
              「チームと人」「仕事と人」「人と人」をつなげます。
              <br />
              高い成果を出すとともに、このチームにいてよかったと
              {isMdUp ? <br /> : null}
              メンバーが心から思えるチーム作りを。
            </div>
            <div className={classes.sentenceWhiteBox}>
              Cocolabo（ココラボ）や関連サービスについての
              {isMdUp ? <br /> : null}
              ご質問・導入など、お気軽にお問い合わせください。
              {isMdUp ? <br /> : null}
              お客様の状況に合わせた最適なプランをご提案いたします。
            </div>
          </div>
          <div className={classes.mockupImageContainer}>
            <div>
              <img className={classes.mockup} src={process.env.PUBLIC_URL + '/img/footer/mockup.png'} alt="mocup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    hero: {
      position: 'relative',
      whiteSpace: 'pre-wrap',
      backgroundImage: `url(${process.env.PUBLIC_URL}/img/footer/background.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      animation: '$animationForFirstVisibleContent 700ms cubic-bezier(0,0.8,0.8,1) 0s 1 normal forwards running',
      '@global': {
        '@keyframes animationForFirstVisibleContent': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/footer/background-sp.png)`,
      },
    },
    contentsWidth: {
      width: '100%',
      maxWidth: 1200,
      padding: '130px 0 60px',
      [theme.breakpoints.down('md')]: {
        padding: '130px 24px 60px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '46px 24px 40px',
      },
    },
    flexWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        gap: 40,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    messageContainer: {
      [theme.breakpoints.up('md')]: {
        minWidth: 482,
      },
    },
    messageHeader: {
      marginBottom: 27,
      color: constants.COLOR_WHITE,
      fontSize: 32,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
      },
    },
    messagePoints: {
      marginBottom: 23,
      color: constants.COLOR_WHITE,
      fontSize: 33,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 28,
        fontSize: 24,
      },
    },
    sentence: {
      marginBottom: 17,
      color: constants.COLOR_WHITE,
      fontSize: 16,
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
      },
    },
    sentenceWhiteBox: {
      padding: '18px 38px',
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 10,
      fontSize: 14,
      lineHeight: 1.5,
      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
    },
    mockupImageContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    mockup: {
      maxWidth: 665,
      width: '100%',
    },
  }),
  { name: 'FootMessage' }
)
