import * as React from 'react'

import { Circle, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DotBlue: React.FC = () => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20">
      <Circle cx="10" cy="10" r="10" fill="#2cbbc6" />
    </Svg>
  )
}

export default DotBlue
