import * as React from 'react'

import { withStyles, createStyles, Theme, StyleRules, WithStyles } from '@material-ui/core'

interface IOwnProps {
  size: number
  chartData: IRadarProps | null
}

export interface IRadarProps {
  data: IRadarValProps[]
}

export interface IRadarValProps {
  val: number[]
  color: string
  dot?: boolean
}

const COLOR_GRAY = '#e0e0e0'

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const points = (points: [number[], number[]]) => {
    return points.map((point) => point[0].toFixed(4) + ',' + point[1].toFixed(4)).join(' ')
  }
  const noSmoothing = (points: number[][]) => {
    let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4)
    for (let i = 1; i < points.length; i++) {
      d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4)
    }
    return d + 'z'
  }
  const polarToX = (angle: number, distance: number) => Math.cos(angle - Math.PI / 2) * distance
  const polarToY = (angle: number, distance: number) => Math.sin(angle - Math.PI / 2) * distance

  return (
    <svg
      version="1"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={`-90 0 ${props.size + 180} ${props.size}`}
    >
      {/* base */}
      <g transform={`translate(${props.size / 2},${props.size / 2})`}>
        <g>
          <path
            d={noSmoothing(
              [1, 1, 1, 1, 1].map((data, index) => {
                return [
                  polarToX((Math.PI * 2 * (index + 1)) / 5, (data * props.size) / 2),
                  polarToY((Math.PI * 2 * (index + 1)) / 5, (data * props.size) / 2),
                ]
              })
            )}
            stroke={COLOR_GRAY}
            strokeWidth={8}
            fill={'none'}
          />
          <path
            d={noSmoothing(
              [1, 1, 1, 1, 1].map((data, index) => {
                return [
                  polarToX((Math.PI * 2 * (index + 1)) / 5, (data * props.size) / 2 / 2),
                  polarToY((Math.PI * 2 * (index + 1)) / 5, (data * props.size) / 2 / 2),
                ]
              })
            )}
            stroke={COLOR_GRAY}
            strokeWidth={1}
            fill={'none'}
          />
        </g>

        {/* line */}
        <g>
          {[1, 2, 3, 4, 5].map((point, index) => (
            <polyline
              key={'poliline-' + index}
              stroke={COLOR_GRAY}
              points={points([
                [0, 0],
                [
                  polarToX((Math.PI * 2 * (index + 1)) / 5, props.size / 2),
                  polarToY((Math.PI * 2 * (index + 1)) / 5, props.size / 2),
                ],
              ])}
            />
          ))}
        </g>

        {/* caption */}
        <g>
          {[
            { name: 'vision', translate: '20,-20' },
            { name: 'relation', translate: '0,20' },
            { name: 'boss', translate: '-80,20' },
            { name: 'team', translate: '-105,-20' },
            { name: 'member', translate: '-43,-60' },
          ].map((caption, index) => (
            <image
              key={'caption-' + index}
              href={`${process.env.PUBLIC_URL}/assets/png/radar/${caption.name}.png`}
              height="40px"
              x={polarToX((Math.PI * 2 * (index + 1)) / 5, ((props.size + 0) / 2) * 0.95).toFixed(4)}
              y={polarToY((Math.PI * 2 * (index + 1)) / 5, ((props.size + 0) / 2) * 0.95).toFixed(4)}
              transform={`translate(${caption.translate})`}
            />
          ))}
        </g>

        {/* data */}
        <g>
          {props.chartData &&
            props.chartData.data?.map((data: IRadarValProps, index) => (
              <g key={`shape-${index}`}>
                <path
                  d={noSmoothing(
                    data.val.map((v, index) => {
                      return [
                        polarToX((Math.PI * 2 * (index + 1)) / 5, ((v / 5) * props.size) / 2),
                        polarToY((Math.PI * 2 * (index + 1)) / 5, ((v / 5) * props.size) / 2),
                      ]
                    })
                  )}
                  stroke={data.color}
                  strokeWidth={1}
                  fill={data.color}
                  className={classes.shape}
                />

                {data.val.map((v, valIndex) => (
                  <React.Fragment key={`val-${index}-${valIndex}`}>
                    <circle
                      cx={polarToX((Math.PI * 2 * (valIndex + 1)) / 5, ((v / 5) * props.size) / 2)}
                      cy={polarToY((Math.PI * 2 * (valIndex + 1)) / 5, ((v / 5) * props.size) / 2)}
                      stroke={data.color}
                      fill={data.color}
                      className={classes.dot}
                    />
                    <text
                      x={polarToX((Math.PI * 2 * (valIndex + 1)) / 5, ((v / 5) * props.size) / 2)}
                      y={polarToY((Math.PI * 2 * (valIndex + 1)) / 5, ((v / 5) * props.size) / 2)}
                      dy={-10}
                      fill={data.color}
                    >
                      {v}
                    </text>
                  </React.Fragment>
                ))}
              </g>
            ))}
        </g>
        {/* dot */}
      </g>
    </svg>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    shape: {
      fillOpacity: 0.3,
    },
    dot: {
      r: 4,
    },
  })

export default withStyles(useStyles)(Index)
