import * as React from 'react'
import { Link } from 'react-router-dom'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { makeStyles, Theme } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import * as constants from 'assets/constants'

type Props = {
  items: {
    link: string
    name: string
  }[]
  endItem: string
}

export const Breadcrumb: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="inherit" />} aria-label="breadcrumb" className={classes.root}>
      {props.items.map((item, index) => (
        <div key={`Breadcrumb-item-key-${index}`}>
          <Link to={item.link}>{item.name}</Link>
        </div>
      ))}
      <div>{props.endItem}</div>
    </Breadcrumbs>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 24,
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& a, div': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
      margin: 0,
    },
    '& a': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiBreadcrumbs-separator': {
      margin: '0 3px',
    },
  },
}))
