import React, { useState } from 'react'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

import { ProgressBar } from 'pages/onboarding/components'
import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { OnboardingActionCategory } from 'utils/generated'

import { CircularProgressCount, WhiteCard, HandleDetailOpenText } from './atoms'

import * as constants from 'assets/constants'


type Props = {
  percents: { [k in OnboardingActionCategory]: number }
  overDeadlineActions: { [k in OnboardingActionCategory]: number }
  percentDoneActions: number
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const ActionOverViewBox: React.FC<Props & StyleProps> = ({
  percents,
  overDeadlineActions,
  percentDoneActions,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles: ownStyles })
  //---calc total-->
  const categories: OnboardingActionCategory[] = [
    OnboardingActionCategory.Category1,
    OnboardingActionCategory.Category2,
    OnboardingActionCategory.Category3,
    OnboardingActionCategory.Category4,
    OnboardingActionCategory.Category5,
  ]

  const totalOverDeadlineActions = () => {
    let returnValue = 0
    for (const category of categories) {
      returnValue = returnValue + overDeadlineActions[category]
    }
    return returnValue
  }

  //<--calc total---

  //  handleDetail
  const [visibleActionDetail, setVisibleActionDetail] = useState<boolean>(false)

  return (
    <WhiteCard ownStyles={{ padding: '27px 24px 24px', ...ownStyles }}>
      <div className={classes.headerWrapper}>
        <div className={classes.title}>全体進捗</div>
        <HandleDetailOpenText open={visibleActionDetail} setOpen={setVisibleActionDetail} />
      </div>
      <div className={classes.graphWrapper}>
        <CircularProgressCount numerator={percentDoneActions} ownStyles={{ margin: '0 24px 0 0' }} />
        <div className={classes.text}>期限切れアクション{totalOverDeadlineActions()}件</div>
      </div>
      {visibleActionDetail ? (
        <DetailBox percents={percents} overDeadlineActions={overDeadlineActions} classes={classes} />
      ) : (
        <></>
      )}
    </WhiteCard>
  )
}

type DetailBoxProps = {
  percents: { [k in OnboardingActionCategory]: number }
  overDeadlineActions: { [k in OnboardingActionCategory]: number }
  classes: ClassNameMap
}

const DetailBox: React.FC<DetailBoxProps> = ({ percents, overDeadlineActions, classes }) => {
  const categories = [
    OnboardingActionCategory.Category1,
    OnboardingActionCategory.Category2,
    OnboardingActionCategory.Category3,
    OnboardingActionCategory.Category4,
    OnboardingActionCategory.Category5,
  ]
  return (
    <div className={classes.detailBoxRoot}>
      {categories.map((category) => (
        <ActionGraphItem
          key={category}
          category={category}
          percent={percents[category]}
          overDeadlineAction={overDeadlineActions[category]}
          classes={classes}
        />
      ))}
    </div>
  )
}

type ActionGraphItemProps = {
  category: OnboardingActionCategory
  percent: number
  overDeadlineAction: number
  classes: ClassNameMap
}
const ActionGraphItem: React.FC<ActionGraphItemProps> = ({ category, percent, overDeadlineAction, classes }) => {
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <div className={classes.actionGraphItemRoot}>
        <div className={classes.actionLabels}>
          <div className={classes.actionTitle}>{onboardingActionCategoryLabel(category)}</div>
          <div className={classes.actionText}>
            {percent !== Infinity ? `期限切れアクション${overDeadlineAction}件` : ''}
          </div>
        </div>
        <ProgressBar
          barWidth={Math.round(percent * 100 * 10) / 10}
          counter={`${Math.round(percent * 100 * 10) / 10}％`}
          exceptionMsg={<div>該当するアクションが存在しません。</div>}
          ownStyles={isXsDown ? { margin: '16px 0 0' } : {}}
        />
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontWeight: 'bold',
    },
    graphWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '18px 0 0',
    },
    text: {
      fontSize: 12,
    },
    detailBoxRoot: {
      margin: '16px 0 0',
      padding: 0,
      borderTop: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    },
    actionGraphItemRoot: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: 24,
      margin: '24px 0 0',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        backgroundColor: constants.COLOR_ONBOARDING_WHITE,
        padding: '20px 8px 16px',
      },
    },
    actionLabels: {
      width: '45%',
    },
    actionTitle: {
      fontWeight: 'bold',
    },
    actionText: {
      margin: '8px 0 0',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ActionOverViewBox' }
)
