import dayjs from 'dayjs'

import { TeamBuildingActionDate, TeamBuildingActionDateStatus } from 'utils/generated'

export const useFormatTeamBuildingActionDates = (teamBuildingActionDates: TeamBuildingActionDate[]) => {
  const now = dayjs()
  const completedActionList: TeamBuildingActionDate[] = []
  const loomingDueActionList: TeamBuildingActionDate[] = []
  const overDueActionList: TeamBuildingActionDate[] = []

  let todaysDueActionCount = 0
  let overDueActionCount = 0

  const calcActionCount = (actionDate: TeamBuildingActionDate) => {
    const actionDateDay = dayjs(actionDate.date)

    if (now.isSame(actionDateDay, 'day')) {
      todaysDueActionCount++
    } else if (now.isAfter(actionDateDay)) {
      overDueActionCount++
    }
  }

  teamBuildingActionDates.map((item) => {
    calcActionCount(item)
    const actionDateDay = dayjs(item.date)

    if (
      item.status === TeamBuildingActionDateStatus.Completed ||
      (!now.isAfter(actionDateDay) && !now.isAfter(actionDateDay.subtract(1, 'day')))
    ) {
      // 実施済み
      return completedActionList.push(item)
    } else if (now.isAfter(actionDateDay)) {
      // 期限が過ぎているもの
      return overDueActionList.push(item)
    } else if (now.isAfter(actionDateDay.subtract(1, 'day')) || now.isSame(actionDateDay, 'day')) {
      // 期限が迫っているもの
      return loomingDueActionList.push(item)
    }
  })

  return { completedActionList, loomingDueActionList, overDueActionList, todaysDueActionCount, overDueActionCount }
}
