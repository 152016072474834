import { createContext, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Storage } from 'aws-amplify'
import { v4 as uuid } from 'uuid'

import { queryListOnboardingPostFiles } from 'pages/onboarding/graphql'
import { OnboardingPostFile } from 'utils/generated'

export const TeamStorageContext = createContext({} as OnbTeamStorageStore)

export interface OnbTeamStorageStore {
  userFiles: File[]
  userImgs: File[]
  handleFilePick: (fileList: FileList | null) => void
  removeUpload: (fileName: string) => void
  executeUpload: () => Promise<{ uploadedFileNames: string[]; postId: string } | undefined>
  resetUpload: () => void
  retrieveFiles: (fileNames: string[], postId: string) => Promise<OnboardingPostFile[][]>
  determineFileType: (fileName: string) => 'file' | 'img' | 'video' | undefined
}

export const useTeamStorage = (teamId: string) => {
  //
  // store team's storage items info here.
  // use this stored info as reference when downloading the actual file.
  //
  const [files, setFiles] = useState<OnboardingPostFile[]>([])
  const location = useLocation()

  const listTeamFiles = useCallback(async () => {
    try {
      const res = await queryListOnboardingPostFiles({ teamId })
      setFiles(res)
    } catch (e) {
      console.log(e)
    }
  }, [teamId])

  useEffect(() => {
    listTeamFiles()
  }, [listTeamFiles])

  useEffect(() => {
    setUploads([])
  }, [location])
  // util for switching preview
  const determineFileType = useCallback((fileName: string) => {
    const suffix = fileName.split('.').pop()
    if (!suffix) return
    const dotSuffix = `.${suffix}`
    if (FILE_MIME.includes(dotSuffix)) {
      return 'file'
    } else if (IMG_MIME.includes(dotSuffix)) {
      return 'img'
    } else if (VIDEO_MIME.includes(dotSuffix)) {
      return 'video'
    } else {
      alert('このファイル形式はアップロードできません。')
    }
  }, [])

  // find file data for posts
  const retrieveFiles = useCallback(
    async (fileNames: string[], postId: string) => {
      const postFiles = [],
        postImgs = []
      const fixS3PathFiles = await queryListOnboardingPostFiles({ teamId, postId })

      for (const fileName of fileNames) {
        const f = fixS3PathFiles.find((file) => file.fileName === fileName)

        if (f) {
          const t = determineFileType(fileName)

          if (t === 'file') {
            postFiles.push(f)
          } else if (t === 'img' || t === 'video') {
            postImgs.push(f)
          }
        } else {
          // for old logic files - nothing postId in s3 path
          const oldF = files.find((file) => file.fileName === fileName)

          if (oldF) {
            const t = determineFileType(fileName)
            if (t === 'file') {
              postFiles.push(oldF)
            } else if (t === 'img' || t === 'video') {
              postImgs.push(oldF)
            }
          }
        }
      }
      return [postFiles, postImgs]
    },
    [teamId, files, determineFileType]
  )

  //
  // store user's uploading files here.
  // bind with the hidden <input type="file" />.
  //
  const [uploads, setUploads] = useState<File[]>([])

  /**
   * @param fileList <input /> 's files property.
   */
  const handleFilePick = useCallback(
    (fileList: FileList | null) => {
      if (fileList) {
        const files = Array.from(fileList)
        const picked = [...uploads]
        for (const file of files) {
          const dupe = picked.find((p) => p.name === file.name)
          const typeSupported = determineFileType(file.name)
          if (!dupe && typeSupported) {
            picked.push(file)
          }
        }
        setUploads(picked)
      }
    },
    [uploads, determineFileType]
  )

  const removeUpload = (fileName: string) => {
    const newUploads = uploads.filter((u) => u.name !== fileName)
    setUploads(newUploads)
  }

  const resetUpload = () => setUploads([])

  // for preview on post modal
  const [userImgs, setUserImgs] = useState<File[]>([])
  const [userFiles, setUserFiles] = useState<File[]>([])
  useEffect(() => {
    const imgs = [],
      files = []
    for (const upload of uploads) {
      const type = determineFileType(upload.name)
      if (type === 'file') {
        files.push(upload)
      } else if (type === 'img' || type === 'video') {
        imgs.push(upload)
      }
    }
    setUserImgs(imgs)
    setUserFiles(files)
  }, [uploads, determineFileType])

  const executeUpload = async () => {
    if (!uploads.length) return

    const uploadedFileNames = []
    const postId = uuid()

    for (const upload of uploads) {
      const res = (await uploadTeamFile(upload, postId)) as { key: string }
      const fn = res.key.split('/').pop()
      fn && uploadedFileNames.push(fn)
    }
    await listTeamFiles() // update files list
    return { uploadedFileNames, postId } // store these fileNames on Dynamo.
  }

  const uploadTeamFile = async (file: File, postId: string) => {
    const res = await Storage.put(
      `/${teamId}/${postId}/${file.name}`, // s3 key
      file,
      {
        contentType: file.type.includes('image') ? 'application/octet-stream' : file.type,
        level: 'public',
        customPrefix: { public: 'onboarding-team-files' },
      }
    )
    return res
  }

  return {
    userFiles,
    userImgs,
    handleFilePick,
    removeUpload,
    executeUpload,
    resetUpload,
    retrieveFiles,
    determineFileType,
  } as const
}

export const FILE_MIME = ['.ppt', '.pptx', '.xls', '.xlsx', '.doc', '.docx', '.pdf', '.csv', '.zip']
export const IMG_MIME = ['.jpeg', '.jpg', '.png']
export const VIDEO_MIME = ['.mp4', '.mov'] // .MOV taken by iPhone won't play. Any workaround??
