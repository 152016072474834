import * as React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ja from 'date-fns/locale/ja'

import * as constants from '../../../../assets/constants'

interface IOwnProps {
  name: string
  value: string | any
  label?: string
  register: any
  setValue: any
  onChange?: any
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [selectedDate, setSelectedDate] = React.useState<Date | null>()

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
      props.setValue(props.name, date)
    }

    if (props.onChange) {
      props.onChange(date)
    }
  }

  const onValue = () => {
    if (selectedDate) {
      props.setValue(props.name, selectedDate)
      return selectedDate
    } else if (props.value) {
      props.setValue(props.name, props.value.toDate())
      return props.value.toDate()
    } else {
      props.setValue(props.name, new Date())
      return new Date()
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        margin="normal"
        id="date-picker-inline"
        label={props.label || '日付'}
        value={onValue()}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        name={props.name}
        ref={props.register}
        inputRef={props.register}
        inputVariant="outlined"
        className={classes.main}
        autoOk={true}
        minDate={new Date()}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      width: '100%',

      '& .MuiFormLabel-root': {
        color: constants.COLOR_MAIN,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${constants.COLOR_MAIN} !important`,
      },
      '& .MuiButtonBase-root': {
        color: `${constants.COLOR_MAIN} !important`,
      },
    },
  })

export default withStyles(useStyles)(Index)
