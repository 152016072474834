import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'

import { NoStyleButton } from 'components/NoStyleButton'
import { usePopover } from 'components/popover/hooks/popover'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { SlideArrow } from 'pages/onboarding/components'
import { Card, IconButtonWithLabel, IntersectLoading } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { scrollElements } from 'pages/teams/utils/scrollElements'
import { TeamMember, TeamMemberRole } from 'utils/generated'

import { Popover } from '../../../../components/popover/Popover'
import { useTeamMembers } from '../../hooks/teamMembers'
import { TableHeadCell } from '../list/components/atoms/TableHeadCell'

import { MemberTableRow } from './components/MemberTableRow'
import { SortMembersKey, useSortMembers } from './hooks/sort'

import { constants } from 'assets'
import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = RouteComponentProps<{ teamId: string }>

export type TeamMemberWithNum = TeamMember & { num: number }

type ListName = 'members' | 'reservation'

export const TeamsMembersPage: React.FC<Props> = (props) => {
  const { match } = props
  const classes = useStyles()
  const { currentTeamMemberRole } = React.useContext(HooksContext)
  const isMdUp = useCustomMediaQuery('up', 'md')

  const { teamMembers, loading, isMoreLoading, nextToken, more, filterMember, leaderToTop } = useTeamMembers(
    match.params.teamId
  )
  const reservedItem = useTeamMembers(match.params.teamId, { isReservedInvitation: true })
  const { sortKey, setSortKey, sortMembers } = useSortMembers()

  const { isOpenPopover, anchorEl, popoverId, handleOpenPopover, handleClosePopover } =
    usePopover('leader-icon-popover')

  const [initial, setInitial] = React.useState(true)
  const [editable, setEditable] = React.useState(false)
  const [activeListName, setActiveListName] = React.useState<ListName>('members')

  const baseTeamMembers = React.useMemo(() => {
    switch (activeListName) {
      case 'members':
        return teamMembers
      case 'reservation':
        return reservedItem.teamMembers

      default:
        return teamMembers
    }
  }, [activeListName, teamMembers, reservedItem.teamMembers])

  const activeTabStyle = React.useCallback(
    (listName: ListName) => (listName === activeListName ? 'active' : ''),
    [activeListName]
  )

  const sortedMembers: TeamMemberWithNum[] = React.useMemo(() => {
    const filteredMembers = leaderToTop(filterMember(baseTeamMembers, currentTeamMemberRole)).map((teamMember, i) => ({
      ...teamMember,
      num: i + 1,
    }))

    return sortMembers(filteredMembers) ?? []
  }, [filterMember, baseTeamMembers, currentTeamMemberRole, sortMembers, leaderToTop])

  const tableHeadClass = (keyName: string) => {
    switch (keyName) {
      case 'num':
        return classes.number
      case 'fullName':
        return classes.fullName
      default:
        return ''
    }
  }

  React.useEffect(() => {
    if (initial && !loading) {
      setInitial(false)
    }
  }, [initial, loading])

  React.useEffect(() => {
    switch (currentTeamMemberRole) {
      case TeamMemberRole.Admin:
      case TeamMemberRole.Leader:
        setEditable(true)
        break
      default:
        setEditable(false)
    }
  }, [currentTeamMemberRole])

  const handleActiveList = (listName: ListName) => setActiveListName(listName)

  const headerItems: { headerName: string; keyName: SortMembersKey }[] = [
    { headerName: 'NO.', keyName: 'num' },
    { headerName: '氏名', keyName: 'fullName' },
    { headerName: 'ユーザー名', keyName: 'userName' },
    { headerName: '部署名', keyName: 'organization' },
    { headerName: '役職', keyName: 'position' },
    { headerName: 'メールアドレス', keyName: 'email' },
    { headerName: '利用状況', keyName: activeListName === 'members' ? 'statusUsage' : 'invitationDateTime' },
    { headerName: '更新日時', keyName: 'updateAt' },
  ]

  return (
    <>
      <Card
        ownStyles={{ padding: 0 }}
        titleStyles={{ height: 40, fontSize: 14, display: 'flex', alignItems: 'center' }}
        titleContainerPb={isMdUp ? undefined : 0}
        headerStyles={{
          margin: 0,
          padding: isMdUp ? '16px 24px 0' : 0,
          height: isMdUp ? 88 : 53,
          borderBottom: isMdUp ? `2px solid ${constants.COLOR_WHITE2}` : 'none',
          display: 'flex',
          alignItems: 'center',
        }}
        headerTitle={'メンバーリスト'}
        headerRightNode={
          isMdUp && (
            <>
              <MemberAddButtons editable={editable} teamId={match.params.teamId} isMdUp={isMdUp} />
              <SlideArrow
                visible={isMdUp && !(initial || loading)}
                windowId={scrollElements.memberList}
                isLoading={initial || loading}
                ownStyles={{ marginLeft: 16, position: 'static', bottom: 0 }}
              />
            </>
          )
        }
        loading={initial}
      >
        {!isMdUp && editable && (
          <div className={classes.memberButtons}>
            <MemberAddButtons editable={editable} teamId={match.params.teamId} isMdUp={isMdUp} />
          </div>
        )}

        <div className={classes.listNav}>
          <NoStyleButton className={activeTabStyle('members')} onClick={() => handleActiveList('members')}>
            利用中/招待中
          </NoStyleButton>
          <NoStyleButton className={activeTabStyle('reservation')} onClick={() => handleActiveList('reservation')}>
            予約招待中
          </NoStyleButton>
        </div>

        <div id={scrollElements.memberList} className={classes.listContainer}>
          <>
            {sortedMembers.length > 0 ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headerItems.map(({ headerName, keyName }) => (
                        <TableHeadCell
                          key={`${keyName}-key`}
                          headerName={headerName}
                          keyName={keyName}
                          sortKey={sortKey}
                          setSortKey={setSortKey}
                          isMemberList
                          tableCellClassName={tableHeadClass(keyName)}
                        />
                      ))}
                      {/* 「編集」列のヘッダー */}
                      <TableCell className={classes.tableHeaderCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedMembers.map((teamMember) => (
                      <MemberTableRow
                        key={`team-list-${teamMember.id}`}
                        teamMember={teamMember}
                        editable={editable}
                        teamId={match.params.teamId}
                        popoverButton={{
                          isOpenPopover,
                          popoverId,
                          handleOpenPopover,
                          handleClosePopover,
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <div className={classes.noData}>メンバーはまだいません</div>
            )}
          </>
        </div>
      </Card>

      <IntersectLoading
        IntersectItem={
          activeListName === 'members'
            ? { isLoading: loading, isMoreLoading, nextToken, more }
            : {
                isLoading: reservedItem.loading,
                isMoreLoading: reservedItem.isMoreLoading,
                nextToken: reservedItem.nextToken,
                more: reservedItem.more,
              }
        }
      />

      <Popover
        className={classes.leaderPopover}
        popover={{
          isOpenPopover,
          anchorEl,
          popoverId,
          handleClosePopover,
          anchorOrigin: { horizontal: !isMdUp ? 'left' : undefined },
          transformOrigin: { horizontal: !isMdUp ? 'left' : undefined },
        }}
      >
        このチームのリーダーです。
      </Popover>
    </>
  )
}

type MemberAddButtonsProps = {
  editable: boolean
  teamId: string
  isMdUp: boolean
}

const MemberAddButtons: React.FC<MemberAddButtonsProps> = ({ editable, teamId, isMdUp }) => {
  const { route } = useHistory()

  return editable ? (
    <>
      <IconButtonWithLabel
        label="メンバー追加"
        icon="humanIcon2"
        onClick={() => route.push(Pages.TeamsMembersAdd, { teamId })}
        styles={
          isMdUp
            ? { marginRight: 16, padding: '8px 16px', fontSize: 12, minWidth: 135 }
            : { flex: 1, padding: '8px 12px', minWidth: 0, fontSize: 10 }
        }
      />
      <IconButtonWithLabel
        label="メンバーCSV一括追加"
        icon="icon_member_add_csv"
        onClick={() => route.push(Pages.TeamsMembersAddByCsv, { teamId })}
        styles={
          isMdUp
            ? { padding: '8px 16px', fontSize: 12, minWidth: 187 }
            : { flex: 1, padding: '8px 12px', minWidth: 0, fontSize: 10 }
        }
      />
    </>
  ) : (
    <></>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    memberButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      padding: '0 16px 16px',
      borderBottom: `2px solid ${constants.COLOR_WHITE2}`,
    },
    listNav: {
      margin: '16px 0 16px 24px',
      display: 'flex',
      height: 26,
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        height: 42,
        margin: '0 0 16px',
        paddingTop: 12,
      },

      '& button': {
        display: 'grid',
        placeItems: 'center',
        width: 100,
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        fontSize: 12,
        fontWeight: 'bold',
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        [theme.breakpoints.down('sm')]: {
          flex: 1,
        },

        '&.active': {
          color: constants.COLOR_MAIN_NEW,
          borderBottom: `2px solid ${constants.COLOR_MAIN_NEW}`,
        },
      },
    },
    listContainer: {
      overflow: 'auto',
    },
    noData: {
      padding: 50,
      textAlign: 'center',
      color: '#999',
    },
    tableHeaderCell: {
      position: 'sticky',
      top: 0,
      height: 50,
      background: '#fff',
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      borderBottom: 'none',
      padding: '0 15px',
      fontSize: 12,
    },
    number: {
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      backgroundColor: '#fff',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 0,
      },
    },
    fullName: {
      position: 'sticky',
      left: 80,
      maxWidth: 150,
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 130,
        left: 0,
      },
    },
    leaderPopover: {
      '& .MuiPopover-paper': {
        margin: '-16px 0 0 -36px', // icon に対する表示位置の調整。
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
        },
      },
    },
  }),
  { name: 'TeamsMembersPage' }
)
