import { API, graphqlOperation } from '@aws-amplify/api'

import { MutationUpdateSetupAnketFeedback } from './graphql'

export interface ISetupAnketFeedbackUpdateInput {
  weekDate: string
  member: number
  team: number
  boss: number
  vision: number
  other: number
  comment: string
  setupStartedAt?: String
}

export interface ISetupAnketFeedback {
  id: string
  username: string
  weekDate: string
  total: number
  boss: number
  vision: number
  member: number
  team: number
  other: number
  comment: string
  updatedAt: string
}

const updateSetupAnketFeedback = async (data: ISetupAnketFeedbackUpdateInput) => {
  try {
    const response: any = await API.graphql(
      graphqlOperation(MutationUpdateSetupAnketFeedback, {
        weekDate: data.weekDate,
        boss: data.boss,
        vision: data.vision,
        member: data.member,
        team: data.team,
        other: data.other,
        comment: data.comment,
        setupStartedAt: data.setupStartedAt,
      })
    )
    const { updateSetupAnketFeedback } = response.data

    return updateSetupAnketFeedback as ISetupAnketFeedback
  } catch (e) {
    console.log('api updateSetupAnketFeedback error', e)
    return {} as ISetupAnketFeedback
  }
}

export default updateSetupAnketFeedback
