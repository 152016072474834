import { RelatedTeamPlan, TeamPlan, RelatedTeamPlanStatus, TeamTool } from 'utils/generated'

export const getTeamPlanById = (plans: TeamPlan[], id?: string): TeamPlan | undefined => {
  if (!id) return undefined
  return plans.find((t) => t.id === id)
}

export const getTeamPlansByIds = (plans: TeamPlan[], relatedPlans: RelatedTeamPlan[]): TeamPlan[] => {
  return relatedPlans.reduce((acc: TeamPlan[], cur) => {
    if (cur.status === RelatedTeamPlanStatus.Enable) {
      const plan = plans.find((p) => p.id === cur.id)
      if (plan) {
        return [...acc, plan]
      } else {
        return acc
      }
    } else {
      return acc
    }
  }, [])
}

export const getEnableTeamTools = (plans: TeamPlan[]): TeamTool[] => {
  const result: TeamTool[] = []

  plans.forEach((plan) => {
    plan.tools.forEach((tool) => {
      if (result.indexOf(tool) === -1) {
        result.push(tool)
      }
    })
  })

  return result
}

export const filterTeamPlans = (plans: TeamPlan[], relatedTeamPlans: RelatedTeamPlan[]) => {
  return plans.filter((plan) => {
    const p = relatedTeamPlans.find((r) => {
      return plan.id === r.id
    })

    return !p || p.status === RelatedTeamPlanStatus.Disable
  })
}
