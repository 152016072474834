import { StringKeyObject } from '../../../member/questionnaire/index.container'
import { bubbleTexts, groupLabels, questionnaireAnswerPatterns, questionnaireBubbles } from '../../../member/static'

export const buildResults = (data: any, leaderData: any) => {
  console.log('1.....')
  const results = {
    match: {
      hope: 0,
      expect: 0,
    },
    about: {
      short: '',
      long: '',
    },
    motivation: '',
  }

  // Calculate about & motivation
  const formTotals: { group: string; weight: number; value: number }[] = []
  const formQuestionsTotals: {
    group: string
    key: string
    label: string
    weight: number
    value: number
  }[] = []

  const formValues: StringKeyObject = {}
  console.log('2.....')

  for (let formIndex = 0; formIndex < data.calculable.length; formIndex++) {
    const form = data.calculable[formIndex]

    for (let questionIndex = 0; questionIndex < form.questions.length; questionIndex++) {
      const question = form.questions[questionIndex]
      const addValue = parseInt(question.value, 0)
      const addedValue = formValues[form.group]
        ? formValues[form.group].value
          ? formValues[form.group].value + addValue
          : addValue
        : addValue
      formValues[form.group] = {
        value: addedValue,
      }
      formValues[form.group].value += parseInt(question.value, 0)

      formQuestionsTotals.push({
        group: form.group,
        key: question.key,
        label: question.label,
        weight: question.weight || 999,
        value: question.value,
      })
    }

    if (form.questions.length > 0) {
      formTotals.push({
        group: form.group,
        weight: form.weight || 999,
        value: formValues[form.group].value / form.questions.length,
      })
    }
  }

  formTotals.sort((a, b) => {
    if (a.value > b.value) return -1
    if (a.value === b.value) return a.weight > b.weight ? 1 : -1
    return 1
  })

  formQuestionsTotals.sort((a, b) => {
    if (a.value > b.value) return -1
    if (a.value === b.value) return a.weight > b.weight ? 1 : -1
    return 1
  })

  const patternIndex = questionnaireAnswerPatterns.findIndex(
    (pattern) => pattern.first === formTotals[0].group && pattern.second === formTotals[1].group
  )
  if (patternIndex !== -1) {
    const pattern = questionnaireAnswerPatterns[patternIndex]
    results.about = pattern.about
    results.motivation = pattern.motivation
  }
  console.log('3.....')

  // Calculate bubbles & points
  const bubbles = []
  let minPoint = 0
  const points: {
    title: string
    description: string
    value: number
    color: 'orange' | 'pink' | 'blue' | 'green'
    link: string
  }[] = []
  for (let formIndex = 0; formIndex < formTotals.length; formIndex++) {
    const form = formTotals[formIndex]
    const bubble = questionnaireBubbles[form.group] as {
      label: string
      color: 'orange' | 'pink' | 'blue' | 'green'
    }
    if (formIndex <= 2) {
      minPoint = form.value
    }
    bubbles.push({
      label: bubbleTexts[form.group].shortLabel,
      color: bubble.color,
      size: form.value >= minPoint ? 'large' : 'small',
    })
    points.push({
      title: bubbleTexts[form.group].label,
      description: bubbleTexts[form.group].description,
      value: form.value,
      color: bubble.color,
      link: bubbleTexts[form.group].linkLeader,
    })
  }

  // Calculate characteristics
  const characteristicsGood: string[] = []
  const characteristicsBad: string[] = []

  for (let characteristicIndex = 0; characteristicIndex < 3; characteristicIndex++) {
    const good = formTotals[characteristicIndex]
    const bad = formTotals[formTotals.length - 1 - characteristicIndex]

    const goodFormIndex = groupLabels.findIndex((form) => form.group === good.group)
    if (goodFormIndex !== -1) {
      characteristicsGood.push(groupLabels[goodFormIndex].label)
    }
    const badFormIndex = groupLabels.findIndex((form) => form.group === bad.group)
    if (badFormIndex !== -1) {
      characteristicsBad.push(groupLabels[badFormIndex].label)
    }
  }
  console.log('4.....')

  // Calc match values
  let numMatchHope = 0
  let numMatchExpect = 0

  characteristicsGood.forEach((good) => {
    if (good === leaderData.hope1) {
      numMatchHope++
    }
    if (good === leaderData.hope2) {
      numMatchHope++
    }
    if (good === leaderData.hope3) {
      numMatchHope++
    }
    if (good === leaderData.expectation1) {
      numMatchExpect++
    }
    if (good === leaderData.expectation2) {
      numMatchExpect++
    }
    if (good === leaderData.expectation3) {
      numMatchExpect++
    }
  })

  // calc total
  let total = 20
  let goodTop = false
  let badSome = false
  // Part 1
  if (characteristicsGood[0] === leaderData.expectation1) {
    total += 35
    goodTop = true
  } else if (characteristicsGood[0] === leaderData.expectation2 || characteristicsGood[0] === leaderData.expectation3) {
    total += 30
  }
  if (characteristicsGood[1] === leaderData.expectation2) {
    total += 25
  } else if (characteristicsGood[1] === leaderData.expectation1 || characteristicsGood[1] === leaderData.expectation3) {
    total += 20
  }
  if (characteristicsGood[2] === leaderData.expectation3) {
    total += 15
  } else if (characteristicsGood[2] === leaderData.expectation1 || characteristicsGood[2] === leaderData.expectation2) {
    total += 10
  }

  // Part 2
  if (characteristicsGood[0] === leaderData.hope1) {
    total += 6
    goodTop = true
  } else if (characteristicsGood[0] === leaderData.hope2 || characteristicsGood[0] === leaderData.hope3) {
    total += 3
  }

  if (characteristicsGood[1] === leaderData.hope2) {
    total += 4
  } else if (characteristicsGood[1] === leaderData.hope1 || characteristicsGood[1] === leaderData.hope3) {
    total += 2
  }

  if (characteristicsGood[2] === leaderData.hope3) {
    total += 2
  } else if (characteristicsGood[2] === leaderData.hope1 || characteristicsGood[2] === leaderData.hope2) {
    total += 1
  }
  console.log('5.....')

  // Part 3
  if (characteristicsBad[0] === leaderData.hope1) {
    total -= 6
    badSome = true
  } else if (characteristicsBad[0] === leaderData.hope2 || characteristicsBad[0] === leaderData.hope3) {
    total -= 3
    badSome = true
  }

  if (characteristicsBad[1] === leaderData.hope2) {
    total -= 4
    badSome = true
  } else if (characteristicsBad[1] === leaderData.hope1 || characteristicsBad[1] === leaderData.hope3) {
    total -= 2
    badSome = true
  }

  if (characteristicsBad[2] === leaderData.hope3) {
    total -= 2
    badSome = true
  } else if (characteristicsBad[2] === leaderData.hope1 || characteristicsBad[2] === leaderData.hope2) {
    total -= 1
    badSome = true
  }

  if (total > 100) {
    total = 100
  } else if (total < 0) {
    total = 0
  }

  // Calculate characteristics by member
  const memberCharacteristicsGood: string[] = []
  const memberCharacteristicsBad: string[] = []
  console.log('6.....')

  for (
    let memberCharacteristicIndex = 0;
    memberCharacteristicIndex < formQuestionsTotals.length;
    memberCharacteristicIndex++
  ) {
    // calculate good
    if (
      formQuestionsTotals[memberCharacteristicIndex].value === formQuestionsTotals[0].value &&
      memberCharacteristicsGood.length < 10
    ) {
      memberCharacteristicsGood.push(formQuestionsTotals[memberCharacteristicIndex].label)
    }

    // calculate bad
    if (
      formQuestionsTotals[memberCharacteristicIndex].value ===
        formQuestionsTotals[formQuestionsTotals.length - 1].value &&
      memberCharacteristicsBad.length < 10
    ) {
      memberCharacteristicsBad.push(formQuestionsTotals[memberCharacteristicIndex].label)
    }
  }

  const radomizer = (array: string[]) => {
    let count = array.length,
      randomnumber,
      temp
    while (count) {
      randomnumber = (Math.random() * count--) | 0
      temp = array[count]
      array[count] = array[randomnumber]
      array[randomnumber] = temp
    }
  }
  console.log('7.....')

  radomizer(memberCharacteristicsGood)
  radomizer(memberCharacteristicsBad)

  return {
    ...results,
    bubbles,
    points,
    match: {
      hope: numMatchHope,
      expect: numMatchExpect,
    },
    total,
    expects: {
      goodTop,
      badSome,
    },
    characteristics: {
      good: characteristicsGood,
      bad: characteristicsBad,
    },
    memberCharacteristics: {
      good: memberCharacteristicsGood,
      bad: memberCharacteristicsBad,
    },
    formQuestionsTotals,
  }
}
