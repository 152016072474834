import badFeelingSomewhat from 'pages/onboarding/assets/timeline_bad_feeling_somewhat.svg'
import badPrivateTime from 'pages/onboarding/assets/timeline_bad_private_time.svg'
import badWorkProgress from 'pages/onboarding/assets/timeline_bad_work_progress.svg'
import badFeeling from 'pages/onboarding/assets/timeline_good_feeling_somewhat.svg'
import badHumanRelation from 'pages/onboarding/assets/timeline_good_private_time.svg'
import greatShade from 'pages/onboarding/assets/timeline_good_work_progress.svg'
import { OnboardingDaily } from 'utils/generated'

export const getDailyCheckImage = (daily: OnboardingDaily): string => {
  switch (daily) {
    case OnboardingDaily.GreatShade:
      return greatShade
    case OnboardingDaily.BadPrivateTime:
      return badPrivateTime
    case OnboardingDaily.BadWorkProgress:
      return badWorkProgress
    case OnboardingDaily.BadHumanRelation:
      return badHumanRelation
    case OnboardingDaily.BadFeeling:
      return badFeeling
    case OnboardingDaily.BadFeelingSomewhat:
      return badFeelingSomewhat
    default:
      return ''
  }
}
