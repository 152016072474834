import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { GhostResearch } from 'utils/generated'

import { NextActionBox } from './NextActionBox'

type NextActionsProps = {
  filteredActions: Array<GhostResearch & { fullName: string; imageUrl: string | undefined }> | undefined
  isOpenList: boolean
}

export const NextActions: React.FC<NextActionsProps> = ({ filteredActions, isOpenList }) => {
  const classes = useStyles()

  if (!filteredActions || !filteredActions.length) {
    return <p className={classes.noneAction}>データが存在しません</p>
  }

  return isOpenList ? (
    <>
      {filteredActions.map((action, i) => (
        <NextActionBox key={action.id} action={action} hasDivision={i !== 0} isOpenList={isOpenList} />
      ))}
    </>
  ) : (
    <NextActionBox action={filteredActions[0]} hasDivision={false} isOpenList={isOpenList} />
  )
}

const useStyles = makeStyles(
  () => ({
    noneAction: {
      margin: 0,
      padding: 16,
      fontSize: 10,
    },
  }),
  { name: 'NextActions' }
)
