import React, { useContext, useEffect } from 'react'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { WaitingContainer } from 'pages/ghost/components/modules/WaitingContainer'
import { usePBForFixedUIs } from 'pages/ghost/hooks/fixedUI'
import { GhostTeamMember, GhostMemberRole } from 'utils/generated'

import { page2Num } from '../../assets/pages'
import { GrayBackGround, BackResponsiveCard, ButtonForGhost } from '../../components/atoms'
import { SelectLeaderFromMember, WhiteGhostImg, ButtonContainer, TooltipForGhost } from '../../components/modules'
import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'
import { CommandContext } from '../../service/commands'

export const SelectLeader: React.FC = () => {
  const classes = useStyles({})
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('sm'))

  const { teamMemberList, teamMember, updateMemberPage } = useContext(ghostTeamMemberContext)
  const { runGoToStart } = useContext(CommandContext)
  const IsAdmin = teamMember?.role === GhostMemberRole.Admin

  const memberList: Array<GhostTeamMember> = teamMemberList.sort((a, _) => (a?.role === GhostMemberRole.Admin ? -1 : 1))

  const [selectedValue, setSelectedValue] = React.useState<string>()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
  }

  const pBForFixedUIs = usePBForFixedUIs({ status: 'selectLeader' })

  useEffect(() => {
    teamMember?.id ? setSelectedValue(teamMember?.id) : ''
  }, [teamMember])

  const toStartPage = () => {
    const teamId = memberList.filter((item) => item.id === selectedValue)[0].teamId
    const leaderId = memberList.filter((item) => item.id === selectedValue)[0].id
    if (!leaderId) {
      alert('リーダーにチェックを入れてください')
      return
    }
    runGoToStart(teamId, leaderId)
  }

  useEffect(() => {
    const isLeader = teamMemberList.some((member) => member.role === GhostMemberRole.Leader)

    if (isLeader) {
      ;(async () => await updateMemberPage(page2Num('start')))()
    }
  }, [teamMemberList, updateMemberPage])

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <div className={classes.flex}>
          <div className={classes.mainTitle}>
            ファシリテーターの選択
            <TooltipForGhost />
          </div>
          {IsAdmin ? (
            <div>
              <div className={classes.textContent}>
                この対話のファシリテーターを決めましょう。
                <br />
                変更する場合は、対象者の方にチェックをしてください。
                <br />
                <span className={classes.textAttention}>※今回の対話のみファシリテーターとなります。</span>
              </div>
              <SelectLeaderFromMember
                currentMembers={memberList}
                clickable
                page={'else'}
                selectedValue={selectedValue}
                handleChange={handleChange}
                ownStyles={isBreakpointsUp ? { margin: '24px 0 120px' } : { margin: '20px 0 80px' }}
              />
            </div>
          ) : (
            <>
              <div className={classes.textContent}>
                代表者がファシリテーターを選択中です。 <br />
                しばらくお待ちください。
              </div>
              <WaitingContainer />
              <WhiteGhostImg
                ownStyles={{ position: 'absolute', bottom: 0, left: 'calc(50% - 83px)', width: 166, marginTop: 150 }}
              />
            </>
          )}

          {IsAdmin ? (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="q1-end-1"
                  buttonBodyColor={!selectedValue ? 'gray' : 'green'}
                  bodyText={<>確定する</>}
                  disabled={!selectedValue}
                  onClick={toStartPage}
                  isResizeBasic
                />,
              ]}
            />
          ) : (
            <></>
          )}
        </div>
      </BackResponsiveCard>
    </GrayBackGround>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
    },

    mainTitle: {
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
      paddingBottom: 24,
      display: 'flex',
      justifyContent: 'flexStart',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    textContent: {
      color: constGhost.COLOR_BLACK3,
      lineHeight: 1.6,
    },
    textAttention: {
      color: constGhost.COLOR_WARNING_RED,
    },
    textWaiting: {
      color: constGhost.COLOR_GRAY2,
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: 24,
    },
    spContainer: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 auto',
        maxWidth: 600,
      },
    },
    btnWrapper: {
      marginRight: 16,
    },
  }),
  { name: 'SelectLeader' }
)
