import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import {
  GhostReport,
  GhostReportConnection,
  MutationCreateGhostReportsArgs,
  MutationForceGetVoteResultArgs,
  MutationReserveGhostReportsArgs,
  MutationVoteGhostReportsArgs,
  QueryGetGhostReportsArgs,
  VoteReportsResult,
} from 'utils/generated'

import {
  mutationCreateGhostReports,
  mutationForceGetVoteResult,
  mutationReserveGhostReports,
  mutationVoteGhostReports,
  queryGetGhostReports,
} from './graphql'

export const getGhostReports = async (args: QueryGetGhostReportsArgs) => {
  const res = await API.graphql({
    query: queryGetGhostReports,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostReports as GhostReportConnection
}

export const createGhostReports = async (args: MutationCreateGhostReportsArgs) => {
  const res = await API.graphql({
    query: mutationCreateGhostReports,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.createGhostReports as GhostReport[]
}

export const reserveGhostReports = async (args: MutationReserveGhostReportsArgs) => {
  const res = await API.graphql({
    query: mutationReserveGhostReports,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.reserveGhostReports as GhostReport[]
}

export const voteGhostReports = async (args: MutationVoteGhostReportsArgs) => {
  const res = await API.graphql({
    query: mutationVoteGhostReports,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.voteGhostReports as VoteReportsResult
}

export const forceGetVoteResult = async (args: MutationForceGetVoteResultArgs) => {
  const res = await API.graphql({
    query: mutationForceGetVoteResult,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.voteGhostReports as VoteReportsResult
}
