import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { CategorizedIceBreak, useCategorizedIceBreak } from './hooks/useIcebreak'
import { IcebreakSelect } from './icebreak/IcebreakSelect'


export enum IcebreakThemes {
  theme1 = '今の自分の気持ち',
  theme2 = '最近嬉しかったことは？',
  theme3 = '最近はまっていることは？',
  theme4 = '週末何してた？',
  theme5 = '今月のGood News',
  theme6 = '今月のGood Job',
  theme7 = '今週の感謝',
  theme8 = '今週のwant',
  theme9 = '最近感動したこと',
}

type Props = {
  title: string
  subtitle: string
}

type CategoryProps = {
  data: CategorizedIceBreak
  icebreakTheme?: string | null
  setIcebreakTheme(theme: IcebreakThemes): void
}

const Category: React.FC<CategoryProps> = ({ data, icebreakTheme, setIcebreakTheme }) => {
  const options = data.icaBreak.map((icaBreak) => ({
    label: icaBreak.name ?? '',
    value: icaBreak.name ?? '',
  }))
  return (
    <Content>
      <div style={{ color: '#00838C', fontSize: 14, fontWeight: 'bold' }}>{data.name}</div>
      <div style={{ fontSize: 12, margin: '8px 0 26px' }}>{data.description}</div>
      <IcebreakSelect options={options} selectedString={icebreakTheme} setSelected={setIcebreakTheme} />
    </Content>
  )
}

export const Step1: React.FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles()
  const { categorizedIceBreak } = useCategorizedIceBreak()
  const { icebreak, setIceBreak } = React.useContext(TeamBuildingReviewHooksContext)
  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection>
        {categorizedIceBreak.map((category) => (
          <Category key={category.id} data={category} icebreakTheme={icebreak} setIcebreakTheme={setIceBreak} />
        ))}
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}))
