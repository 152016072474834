import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useObakesagashi } from '../hooks/obakesagashi'

import { BasicButton } from './BasicButton'

import { constants } from 'assets'

type Props = {
  activeGhostFileNo: number
  setActiveGhostFileNo: React.Dispatch<React.SetStateAction<number>>
  setSelectedGhostIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const buttonBoxHeight = { pc: 200, sp: 82 }

export const ObakeSelection: React.FC<Props> = ({ activeGhostFileNo, setActiveGhostFileNo, setSelectedGhostIds }) => {
  const classes = useStyles()
  const { ghosts } = useObakesagashi()

  const activeGhost = React.useMemo(
    () => ghosts.find((ghost) => Number(ghost.fileNo) === activeGhostFileNo),
    [activeGhostFileNo, ghosts]
  )

  const handleActiveGhostFileNo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveGhostFileNo((prev) => {
      if (prev === 12) {
        return 0 // 12体選択し終えたら0を返す。
      }
      return prev + 1
    })
  }
  const handleSelectedGhost = (id: string | undefined) => {
    if (!id) return
    setSelectedGhostIds((prev) => {
      return [...prev, Number(id)]
    })
    handleActiveGhostFileNo()
  }

  if (!activeGhost) return null

  return (
    <div className={classes.container}>
      <div className={classes.back}>
        <div className={classes.ghostBack}>
          <div className={classes.ghostNameBox}>
            <h1 className={classes.ghostName}>
              <span className={classes.fileNumber}>
                <span className="pre">File No</span>
                <span className="num">{`0${activeGhost.fileNo}`.slice(-2)}</span>
              </span>
              <span>{activeGhost.name}</span>
            </h1>
          </div>
          <div className={classes.ghostWrapper}>
            <img
              className={classes.ghostImg}
              src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${activeGhost.id}.svg`}
              alt={activeGhost.name}
            />
            <div className={classes.descBox}>
              <div className={classes.desc}>
                <h2>どんなオバケ？</h2>
                <p>{activeGhost.con}</p>
              </div>
              <div className={classes.desc}>
                <h2>こんなときに出てくるかも！</h2>
                <p>{activeGhost.scenes.join('\n')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.buttonBox}>
        <BasicButton backColor="secondary" onClick={handleActiveGhostFileNo}>
          職場にいないかも
        </BasicButton>
        <BasicButton backColor="primary" onClick={() => handleSelectedGhost(activeGhost?.id)}>
          職場にいるかも
        </BasicButton>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      animation: `$fadeIn 500ms forwards`,
      '@global': {
        '@keyframes fadeIn': {
          from: {
            opacity: '0',
          },
          to: {
            opacity: '1',
          },
        },
      },
    },
    back: {
      paddingBottom: buttonBoxHeight.pc,
      background: constants.COLOR_NAVY,
      minHeight: `calc(100vh - ${buttonBoxHeight.pc}px)`,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: buttonBoxHeight.sp,
        minHeight: `calc(100vh - ${buttonBoxHeight.sp}px)`,
      },
    },
    ghostBack: {
      background: `no-repeat bottom / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/brick-back.png)`,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/brick-back-sp.png)`,
        marginBottom: 38,
      },
    },
    ghostNameBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    ghostName: {
      position: 'relative',
      margin: '58px 0 0',
      color: '#fff',
      fontSize: 40,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 24,
        margin: '16px 16px 0',
        fontSize: 24,
      },
    },
    fileNumber: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexShrink: 0,
      width: 73,
      height: 98,
      color: '#000',
      fontFamily: '"MOBO SemiBold"',
      background: `no-repeat top / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/light-blue-fire.svg)`,
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: -38,
        left: -94,
      },
      '& .pre': {
        fontSize: 16,
        lineHeight: 1,
      },
      '& .num': {
        fontSize: 25,
        lineHeight: 1.2,
        paddingBottom: 4,
      },
    },
    ghostWrapper: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: 80,
      padding: '40px 24px 80px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 24,
        padding: '0 16px 38px',
      },
    },
    ghostImg: {
      marginLeft: 70,
      width: 300,
      [theme.breakpoints.down('sm')]: {
        width: 200,
        marginLeft: 0,
      },
    },
    descBox: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    desc: {
      position: 'relative',
      width: '100%',
      maxWidth: 527,
      padding: 24,
      // mixBlendMode を使うとテキスト分量の変化に対応しにくいため、透過度の設定で対応。
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: 17,
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
      '& h2': {
        margin: 0,
        color: constants.COLOR_YELLOW4,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.4,
      },
      '& p': {
        margin: '14px 0 0',
        color: '#fff',
        fontSize: 14,
        lineHeight: 1.7,
      },
    },
    buttonBox: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: 24,
      width: '100%',
      height: buttonBoxHeight.pc,
      background: constants.COLOR_NAVY_DARK,
      [theme.breakpoints.down('sm')]: {
        columnGap: 16,
        height: buttonBoxHeight.sp,
        padding: '0 16px',
      },
    },
  }),
  { name: 'ObakeSelection' }
)
