import * as React from 'react'

import { UsefulCard } from '../atoms'
import { CountOfGhost } from '../modules'

type Props = {
  countOf: 'ghostAmount' | 'ghostType'
  ownStyles?: React.CSSProperties
}

export const ReportedGhost: React.FC<Props> = (props) => {
  return (
    <>
      <UsefulCard backgroundColor="white" borderRadius={0} ownStyles={{ boxShadow: 'none' }}>
        <CountOfGhost countOf={props.countOf} />
      </UsefulCard>
    </>
  )
}
