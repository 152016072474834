import * as React from 'react'

import { getTeamMemberStatusUsageLabel } from 'utils/labels'

import { TeamMemberWithNum } from '..'
import { SortedInfoSort as SortMembersOrder } from '../../list/components/hooks/sortTeams'

export type SortMembersKey =
  | 'num' // NO.
  | 'fullName' // 氏名
  | 'userName' // ユーザー名
  | 'organization' // 部署名
  | 'position' // 役職
  | 'email' // メールアドレス
  | 'statusUsage' // 利用状況 ... 利用中/招待中のタブ
  | 'invitationDateTime' // 利用状況 ... 予約招待中のタブ
  | 'updateAt' // 更新日時

type El = string | number | undefined | null

export const useSortMembers = () => {
  const [sortKey, setSortKey] = React.useState<{
    key: SortMembersKey
    sort: SortMembersOrder
  } | null>(null)

  const sortMembers = (arr: TeamMemberWithNum[] | undefined) => {
    if (!arr) return

    const sortedList = sortKey === null ? arr : arr.sort(compareFn)

    return sortedList
  }

  const executeCompare = (a: El, b: El): number => {
    const isUp = sortKey?.sort === 'up'

    if (typeof a === 'number' && typeof b === 'number') {
      return isUp ? a - b : b - a
      // undefined など、値がない場合は昇順・降順関わらず末尾に表示。
    } else if (a !== 0 && !Boolean(a)) {
      return 1
      // undefined など、値がない場合は昇順・降順関わらず末尾に表示。
    } else if (b !== 0 && !Boolean(b)) {
      return -1
    } else if (typeof a === 'string' && typeof b === 'string') {
      return isUp ? a.localeCompare(b) : b.localeCompare(a)
    } else {
      return 0
    }
  }

  // compareFn で定義漏れがないか確認するためのダミー関数です。実際のロジックには関係ありません。
  const assertUnreachable = (x: undefined): never => {
    throw new Error(`Unexpected value!! ${x}`)
  }

  function compareFn(a: TeamMemberWithNum, b: TeamMemberWithNum) {
    const compareItem: { first: El; second: El } = { first: undefined, second: undefined }

    switch (sortKey?.key) {
      // NO.
      case 'num':
        compareItem.first = a.num
        compareItem.second = b.num
        break
      // 氏名
      case 'fullName':
        compareItem.first = a.fullName
        compareItem.second = b.fullName
        break

      // ユーザー名
      case 'userName':
        compareItem.first = a.username
        compareItem.second = b.username
        break
      // 部署名
      case 'organization':
        compareItem.first = a.organization
        compareItem.second = b.organization
        break
      // 役職
      case 'position':
        compareItem.first = a.position
        compareItem.second = b.position
        break
      // メールアドレス
      case 'email':
        compareItem.first = a.email
        compareItem.second = b.email
        break
      // 利用状況 ... 利用中/招待中のタブ
      case 'statusUsage':
        compareItem.first = getTeamMemberStatusUsageLabel(a.statusUsage)
        compareItem.second = getTeamMemberStatusUsageLabel(b.statusUsage)
        break
      // 利用状況 ... 予約招待中のタブ
      case 'invitationDateTime':
        compareItem.first = a.invitationDateTime
        compareItem.second = b.invitationDateTime
        break
      // 更新日時
      case 'updateAt':
        compareItem.first = a.updatedAt
        compareItem.second = b.updatedAt
        break

      default:
        // チェック用のダミー関数。定義漏れがあると type error が起きます。
        assertUnreachable(sortKey?.key)
        break
    }

    return executeCompare(compareItem.first, compareItem.second)
  }

  return { sortKey, setSortKey, sortMembers }
}
