import { useState } from 'react'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  setLoad: (isLoad: boolean) => void
  isLoad: () => boolean
  onDoneLoaded: (isLoad: boolean, time: number) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useLoading = (): IProps => {
  const [_isLoad, _setLoad] = useState<boolean>(true)

  /**
   * ローディング状態を更新する
   */
  const setLoad = (isLoad: boolean): void => {
    _setLoad(isLoad)
  }

  /**
   * ローディング状態を返す
   */
  const isLoad = (): boolean => {
    return _isLoad
  }

  /**
   * ローディング時間を制御する
   */
  const onDoneLoaded = (isLoad: boolean, time: number) => {
    setTimeout(() => {
      setLoad(isLoad)
    }, time)
  }

  return {
    setLoad,
    isLoad,
    onDoneLoaded,
  }
}
