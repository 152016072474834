export const SIGNIN_WRONG_EMAIL = 'ERR_SIGNIN_WRONG_EMAIL'
export const SIGNIN_WRONG_PASSWORD = 'ERR_SIGNIN_WRONG_PASSWORD'
export const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException'
export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException'
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException'
export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException'
export const USER_NOT_CONFIMED_EXCEPTION = 'UserNotConfirmedException'

export const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException'
export const EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException'
