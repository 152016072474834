import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { GhostScroll } from 'pages/obakesagashi/components/GhostScroll'

import { constants } from 'assets'

export const GhostGallery: React.FC = () => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  return (
    <section className={classes.root}>
      <h2 className={classes.title}>
        <span>オバケは{isSmDown ? <br /> : null}ぜんぶで12種類</span>
      </h2>
      <h3 className={classes.subtitle}>
        <span>こんなオバケが職場にいませんか？</span>
      </h3>
      <div className={classes.desc}>
        出てくるオバケは全部で12体。あなたの職場にもオバケがいないか特徴と出没シーンを見てみましょう。
      </div>
      <div>
        <GhostScroll />
      </div>
      <div className={classes.rightTree}>
        {!isSmDown && <img src={`${process.env.PUBLIC_URL}/img/obakesagashi/gallery-tree-right.svg`} alt="右端の木" />}
      </div>
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: constants.COLOR_WHITE3,
      background: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-gallery-left.png) no-repeat left top / 185px auto`,
      color: '#000',
      [theme.breakpoints.down('sm')]: {
        background: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-gallery-left-sp.png) no-repeat left top / 67px auto, url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-gallery-right-sp.png) no-repeat right top / 67px auto`,
      },
    },
    title: {
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 0,
      marginTop: 100,
      marginBottom: 20,
      lineHeight: 1.25,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        marginTop: 80,
        marginBottom: 8,
      },
    },
    subtitle: {
      fontSize: 24,
      margin: 0,
      marginBottom: 40,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    desc: {
      fontSize: 16,
      lineHeight: 2,
      padding: '0 16px',
    },
    rightTree: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }),
  { name: 'GhostGallery' }
)
