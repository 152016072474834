import * as React from 'react'

import { Circle, G, Line, Path, Rect, Svg, Text, Tspan } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man1: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-7813 -10294)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(7813 10294)" fill="#80d6dd" />
        <G transform="translate(8916.037 10085.946)">
          <G transform="translate(-50 0)">
            <Path
              d="M-568.021,337.818h-88.028c-2.489,0-4.526,2.486-4.526,5.524V481.1c0,.055.012.106.014.16v2.663H-563.5V343.342C-563.5,340.3-565.533,337.818-568.021,337.818Z"
              fill="#545364"
            />
            <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(-622.615 323.267)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-677.506 272.117)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-581.591 272.117)" fill="#ffe4d2" />
            <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(-667.172 226.47)" fill="#ffe4d2" />
            <Path
              d="M-660.574,564.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.148.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-600.337,564.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.149.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path d="M-630.647,337.818l9.075,26.674h15.125l9.9-26.674Z" fill="#fff" />
            <Path
              d="M-619.1,341.888l1.1,8.274-1.1,4.675-3.575,7.425,7.975,26.4,9.075-25.849-3.3-7.724.546-13.2-4.671-3.714Z"
              fill="#b15f78"
            />
            <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(-619.097 346.068)" fill="#c07087" />
            <Path d="M-613.273,337.818l11.229,15.033,5.637-15.033Z" fill="#e5e5e4" />
            <Path d="M-612.723,337.818l-13.383,14.107-4.541-14.107Z" fill="#e5e5e4" />
            <Path
              d="M-554.681,279.063c-12.367,1.479-18.661-9.536-21.808-18.191-37.765-2.36-59.009-3.147-72.384,0-2.36,9.442-23.6,27.538-22.134,9.1,3.662-23.7,24.949-52.918,57.46-52.918S-554.681,244.814-554.681,279.063Z"
              fill="#323333"
            />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-600.337 474.846)" fill="#545364" />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-660.575 474.846)" fill="#545364" />
            <G>
              <Path
                d="M-641.991,321.749a11.768,11.768,0,0,1,8.122,14.466l-4.842,12.986a11.766,11.766,0,0,1-14.467,8.121l-.753-.211a11.766,11.766,0,0,1-8.122-14.466l4.842-12.986a11.766,11.766,0,0,1,14.466-8.122Z"
                fill="#ffe4d2"
              />
              <Path d="M-634.228,343.275-653.1,418.454l-26.355-7.4,18.87-75.178Z" fill="#545364" />
            </G>
            <Path d="M-563.453,341.493l28.11,49.877-22.381,11.8-28.114-49.877Z" fill="#545364" />
            <G>
              <Path
                d="M-589.111,466.393a11.563,11.563,0,0,1-4.771-15.59l4.559-9.053a11.563,11.563,0,0,1,15.59-4.771l.678.36a11.562,11.562,0,0,1,4.771,15.59l-4.559,9.053a11.562,11.562,0,0,1-15.589,4.771Z"
                fill="#ffe4d2"
              />
              <Path
                d="M-593.36,446.564l36.264-63.193s5.839-3.566,15.164-.271c5.837,2.059,8.912,12.269,8.912,12.269l-36.261,63.2Z"
                fill="#545364"
              />
            </G>
          </G>
          <G>
            <Path
              d="M-943.866,347.412l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-941.89,341.929-943.889,344.4-943.866,347.412Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(-903.041 327.232) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-862.31 276.183)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-957.23 276.904)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(-946.929 231.369)" fill="#ffe4d2" />
            <Path
              d="M-846.119,568.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-905.719,569.205a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M-911.2,341.7l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M-894.87,341.926l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(-899.433 349.771) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M-911.337,341.7l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M-877.453,341.44l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M-895.282,222.052c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-953.329,249.97-927.456,222.3-895.282,222.052Z"
              fill="#522903"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-942.878 477.551) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-883.265 477.098) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M-771.988,372.776l-48.126,28.734A10.128,10.128,0,0,1-834,398.01h0l-.347-.58c-2.868-4.8-2.078-12.577,2.727-15.445l48.125-28.734c4.8-2.868,11.8.256,14.669,5.059l.346.58A10.129,10.129,0,0,1-771.988,372.776Z"
                fill="#ffe4d2"
              />
              <Path d="M-848.575,392.612l60.831-39.029,13.673,21.845L-834.9,414.457Z" fill="#545364" />
            </G>
            <Path d="M-847.965,341.178l28.11,49.877-22.381,11.8-28.114-49.877Z" fill="#545364" />
            <G>
              <Path
                d="M-1023.6,362.763l.346-.58c2.868-4.8,9.868-7.927,14.668-5.059l48.126,28.734c4.8,2.868,5.6,10.645,2.727,15.445l-.347.58h0a10.128,10.128,0,0,1-13.889,3.5l-48.126-28.734A10.129,10.129,0,0,1-1023.6,362.763Z"
                fill="#ffe4d2"
              />
              <Path d="M-957.186,418.33l-60.831-39.029,13.673-21.845,60.831,39.029Z" fill="#545364" />
            </G>
            <Path d="M-921.738,356.851l-28.114,49.877-22.381-11.8,28.11-49.877Z" fill="#545364" />
          </G>
          <Text
            transform="translate(-580.412 249.416) rotate(18.094)"
            fill="#f4805d"
            // font-size="38.187"
            // font-family="Helvetica-Bold, Helvetica"
            // font-weight="700"
          >
            <Tspan x="0" y="0">
              ?
            </Tspan>
          </Text>
          <Line
            x1="0"
            x2="0"
            y1="0"
            y2="17.19"
            transform="translate(-942.643 260.69)"
            fill="none"
            stroke="#4441e5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.714"
          />
          <Line
            x1="0"
            x2="0"
            y1="0"
            y2="35.286"
            transform="translate(-931.786 260.69)"
            fill="none"
            stroke="#4441e5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.714"
          />
          <Line
            x1="0"
            x2="0"
            y1="0"
            y2="24.429"
            transform="translate(-920.024 260.69)"
            fill="none"
            stroke="#4441e5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2.714"
          />
        </G>
      </G>
    </Svg>
  )
}

export default Man1
