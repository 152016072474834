import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'
import * as options from 'assets/options'
import { IOptions } from 'assets/options'


// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '50px',
    backgroundColor: constants.COLOR_MAIN,
    borderRadius: '4px 4px 0 0',
  },

  result: {
    color: constants.COLOR_WHITE,
    fontSize: '14px',
    lineHeight: '50px',
    textAlign: 'center',
    fontWeight: 600,
    margin: 0,
    padding: 0,

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      paddingLeft: '16px',
      textAlign: 'left',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  searchCount: number | undefined
  searchCategory: string
}

// -----------------------------
// Component
// -----------------------------
export const Result = (props: IProps) => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <Box className={classes.root}>
      {location.pathname.includes('tips') ? (
        <>
          {options.optionHintCategory.map((optionHintCategory: IOptions, index: number) => {
            return (
              <Box key={index}>
                {props.searchCategory === optionHintCategory.value && (
                  <p className={classes.result}>
                    「({optionHintCategory.label})」の検索結果 （{props.searchCount}件）
                  </p>
                )}
              </Box>
            )
          })}
        </>
      ) : (
        <>
          {options.optionHintCategory.map((optionHintCategory: IOptions, index: number) => {
            return (
              <Box key={index}>
                {props.searchCategory === optionHintCategory.value && (
                  <p className={classes.result}>
                    「({optionHintCategory.label})」の検索結果 （{props.searchCount}件）
                  </p>
                )}
              </Box>
            )
          })}
        </>
      )}
    </Box>
  )
}
