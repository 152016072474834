import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button, CircularProgress, Modal, Tab, Tabs } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import MessagePanel from '../../../components/MessagePanel'
import { getBasePeriod, getWeeksByBasePeriod } from '../../../services/utils/weekday'
import { IActionItem } from '../../../stores/setupaction/reducer'

import Card from './Card'
import { connector, ContainerProps } from './index.container'


type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    if (!props.setup.currentSetupStartedAt && props.user?.setupStartedAtList) {
      props.changeCurrentSetupStartedAt(props.user.setupStartedAtList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.fetched])

  const [invoked, setInvoked] = React.useState(false)

  React.useEffect(() => {
    if (props.user.fetched && props.setup.currentSetupStartedAt) {
      if (!invoked) {
        fetchList()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.setup, invoked])

  const { classes } = props

  const weeks = React.useMemo(() => {
    if (props.setup.currentSetupStartedAt) {
      const basePeriod = getBasePeriod(new Date(props.setup.currentSetupStartedAt))
      const result = getWeeksByBasePeriod(basePeriod)
      return result
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.setup])

  const fetchList = () => {
    setInvoked(true)

    if (props.setup.currentSetupStartedAt && weeks !== undefined && props.user.id) {
      // console.log('fetchList', {
      //   date: props.setup.currentSetupStartedAt,
      //   weeks,
      //   id: props.user.id,
      // })
      props.fetch(props.setup.currentSetupStartedAt, weeks, props.user.id)
    } else {
      props.history.push(pages.PAGE_SETUP_HOME)
      window.scrollTo(0, 0)
    }
  }
  const [tabValue, setTabValue] = React.useState('one')

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue)
  }

  const handlePage = (e: React.MouseEvent<HTMLElement>) => {
    props.history.push(e.currentTarget.getAttribute('data-page') || '/')
    window.scrollTo(0, 0)
  }

  // searchMenu
  const handleSearchMenu = () => {
    const o = !openMenu
    setOpenMenu(!openMenu)
    if (!activeMenu) {
      setActiveMenu(true)
    }

    if (o) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = ''
    }
  }

  const handleSearch = (value: number) => {
    if (valueSearch === value) {
      setValueSearch(null)
    } else {
      setValueSearch(value)
    }
    setOpenMenu(false)
    document.body.style.overflowY = ''
  }

  const [activeMenu, setActiveMenu] = React.useState(false)
  const [openMenu, setOpenMenu] = React.useState(false)
  const [valueSearch, setValueSearch] = React.useState<number | null>(null)

  const handleSearchAll = () => {
    setValueSearch(null)
    setOpenMenu(false)
    document.body.style.overflowY = ''
  }

  const [checkOver, setCheckOver] = React.useState<boolean>(false)
  const [openOver, setOpenOver] = React.useState<boolean>(false)

  const handelOpenOver = () => {
    if (window.localStorage) {
      const date = getDate()
      const strageDate = localStorage.getItem('date')

      if (strageDate === date) return

      localStorage.setItem('date', date)
      setCheckOver(true)
      setOpenOver(true)

      return
    }

    setCheckOver(true)
    setOpenOver(true)
  }

  const getDate = () => {
    const date = new Date()
    const MM = date.getMonth() + 1
    const DD = date.getDate()

    return `${MM}/${DD} `
  }

  const handleStartPage = () => {
    props.history.push(constants.PAGE_COACH)
    window.scrollTo(0, 0)
  }

  const cards = (data: IActionItem[]) => {
    if (!props.setup.currentSetupStartedAt) {
      return null
    }

    return (
      <div className="__setup_list_container__content__tabPanels__lists">
        {data.map((item, index) => (
          <React.Fragment key={'action-' + index + item.id}>
            <Card
              item={item}
              handelOpenOver={handelOpenOver}
              checkOver={checkOver}
              basePeriod={getBasePeriod(new Date(props.setup.currentSetupStartedAt as any)) || 0}
            />
          </React.Fragment>
        ))}
      </div>
    )
  }

  const noAction = () => {
    return (
      <div className="__setup_list_container__content__tabPanels__noList">
        <div className="__setup_list_container__content__tabPanels__noList__description">
          <p>アクションがありません</p>
        </div>
      </div>
    )
  }
  const processingList = () => {
    if (props.setupaction.processing) {
      if (
        props.setupaction.processing.anket.length > 0 ||
        props.setupaction.processing.member.length > 0 ||
        props.setupaction.processing.team.length > 0 ||
        props.setupaction.processing.boss.length > 0 ||
        props.setupaction.processing.vision.length > 0 ||
        props.setupaction.processing.other.length > 0
      ) {
        if (valueSearch) {
          switch (valueSearch) {
            case 1:
              if (props.setupaction.processing.boss.length > 0) {
                return cards(props.setupaction.processing.boss)
              } else {
                return noAction()
              }
            case 2:
              if (props.setupaction.processing.member.length > 0) {
                return cards(props.setupaction.processing.member)
              } else {
                return noAction()
              }
            case 3:
              if (props.setupaction.processing.team.length > 0) {
                return cards(props.setupaction.processing.team)
              } else {
                return noAction()
              }
            case 4:
              if (props.setupaction.processing.vision.length > 0) {
                return cards(props.setupaction.processing.vision)
              } else {
                return noAction()
              }
            case 5:
              if (props.setupaction.processing.other.length > 0) {
                return cards(props.setupaction.processing.other)
              } else {
                return noAction()
              }
          }
        } else {
          return cards([
            ...props.setupaction.processing.anket,
            ...props.setupaction.processing.boss,
            ...props.setupaction.processing.member,
            ...props.setupaction.processing.team,
            ...props.setupaction.processing.vision,
            ...props.setupaction.processing.other,
          ])
        }
      } else {
        return (
          <div className="__setup_list_container__content__tabPanels__noList">
            <div className="__setup_list_container__content__tabPanels__noList__description">
              <p>おめでとうございます！</p>
              <p>全てのアクションをクリアしました。</p>
              <p>クリアリストでは得意不得意がわかります。</p>
              <p>今後の活動にも活かしてください。</p>
            </div>
            <img
              className="__img"
              src={process.env.PUBLIC_URL + '/assets/svg/setupList/complete.svg'}
              alt={'complete'}
            />
          </div>
        )
      }
    } else {
      return <></>
    }
  }

  const doneList = () => {
    if (props.setupaction.done) {
      if (
        props.setupaction.done.anket.length > 0 ||
        props.setupaction.done.member.length > 0 ||
        props.setupaction.done.team.length > 0 ||
        props.setupaction.done.boss.length > 0 ||
        props.setupaction.done.vision.length > 0 ||
        props.setupaction.done.other.length > 0
      ) {
        if (valueSearch) {
          switch (valueSearch) {
            case 1:
              if (props.setupaction.done.boss.length > 0) {
                return cards(props.setupaction.done.boss)
              } else {
                return noAction()
              }
            case 2:
              if (props.setupaction.done.member.length > 0) {
                return cards(props.setupaction.done.member)
              } else {
                return noAction()
              }
            case 3:
              if (props.setupaction.done.team.length > 0) {
                return cards(props.setupaction.done.team)
              } else {
                return noAction()
              }
            case 4:
              if (props.setupaction.done.vision.length > 0) {
                return cards(props.setupaction.done.vision)
              } else {
                return noAction()
              }
            case 5:
              if (props.setupaction.done.other.length > 0) {
                return cards(props.setupaction.done.other)
              } else {
                return noAction()
              }
          }
        } else {
          return cards([
            ...props.setupaction.done.anket,
            ...props.setupaction.done.boss,
            ...props.setupaction.done.member,
            ...props.setupaction.done.team,
            ...props.setupaction.done.vision,
            ...props.setupaction.done.other,
          ])
        }
      } else {
        return (
          <div className="__setup_list_container__content__tabPanels__noList">
            <div className="__setup_list_container__content__tabPanels__noList__description">
              <p>まだクリアした項目はありません。</p>
              <p>進行中のアクションに挑戦して</p>
              <p>どんどんクリアしていきましょう。</p>
            </div>
            <img className="__img" src={process.env.PUBLIC_URL + '/assets/svg/setupList/noClear.svg'} alt={'noClear'} />
          </div>
        )
      }
    } else {
      return <></>
    }
  }

  const messagePanel = () => {
    return (
      <MessagePanel
        failure={true}
        handleClose={() => {
          setOpenOver(false)
        }}
        body={
          <div>
            <p>
              期限超過があります
              <br />
              「困っていること、上手くいかないことをプロのコーチに相談してみよう」
            </p>
          </div>
        }
        footer={
          <div className={classes.modalButtons}>
            <Button
              style={{ backgroundColor: 'black', color: 'white' }}
              fullWidth={true}
              variant="contained"
              onClick={() => setOpenOver(false)}
            >
              後で
            </Button>
            <Button
              style={{ backgroundColor: '#FCCF00', color: 'white' }}
              fullWidth={true}
              variant="contained"
              onClick={handleStartPage}
            >
              相談する
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__setup_list_container">
          <div className="__setup_list_container__content">
            <div className="__setup_list_container__content__header">
              <Button
                className="__setup_list_container__content__header__button"
                onClick={handlePage}
                data-page={pages.PAGE_SETUP_HOME}
              >
                <FontAwesome name="chevron-left" color={constants.COLOR_BLACK} size={16} />
                戻る
              </Button>
              <div>アクションリスト</div>
              <Button className="__setup_list_container__content__header__button" onClick={handleSearchMenu}>
                <FontAwesome name="search" color={constants.COLOR_BLACK} size={16} />
                絞込み
              </Button>
            </div>
            <div className="__setup_list_container__content__tabs">
              <Tabs
                className={classes.tabs}
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="wrapped label tabs example"
                classes={{ indicator: classes.indicator }}
              >
                <Tab
                  value="one"
                  label="進行中"
                  wrapped={true}
                  id={`wrapped-tab-one`}
                  className="__tab __tabLeft"
                  classes={{
                    selected: '__tabSelected',
                    textColorInherit: '__textColorInherit',
                  }}
                />
                <Tab
                  value="two"
                  label="達成済"
                  className="__tab __tabRight"
                  classes={{
                    selected: '__tabSelected',
                    textColorInherit: '__textColorInherit',
                  }}
                />
              </Tabs>
            </div>

            <div className="__setup_list_container__content__tabPanels">
              <TabPanel value={tabValue} index="one">
                <>
                  {!props.setupaction?.fetching && props.setupaction?.fetched ? (
                    <>{processingList()}</>
                  ) : (
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  )}
                </>
              </TabPanel>
              <TabPanel value={tabValue} index="two">
                {!props.setupaction?.fetching && props.setupaction?.fetched ? (
                  <>{doneList()}</>
                ) : (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                )}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classes.search + ` ${openMenu ? 'open' : ''} `}
        style={{ display: activeMenu ? 'block' : 'none' }}
      >
        <ul>
          <li className={classes.searchHeader}>
            <Button onClick={handleSearchAll} disabled={!valueSearch ? true : false} style={{ fontWeight: 'bold' }}>
              すべて
            </Button>
            <Button onClick={handleSearchMenu}>
              <FontAwesome name="times" color={constants.COLOR_BLACK} size={16} />
              閉じる
            </Button>
          </li>
          <li onClick={() => handleSearch(1)} className={`${valueSearch === 1 ? 'activeSearch' : ''}`}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/setupList/search_boss.svg'} alt="member" />
            <div className="__title">上司との関係性</div>
            <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
          </li>
          <li onClick={() => handleSearch(2)} className={`${valueSearch === 2 ? 'activeSearch' : ''}`}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/setupList/search_member.svg'} alt="member" />
            <div className="__title">メンバーとの関係性</div>
            <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
          </li>
          <li onClick={() => handleSearch(3)} className={`${valueSearch === 3 ? 'activeSearch' : ''}`}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/setupList/search_team.svg'} alt="member" />
            <div className="__title">チーム作り</div>
            <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
          </li>
          <li onClick={() => handleSearch(4)} className={`${valueSearch === 4 ? 'activeSearch' : ''}`}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/setupList/search_vision.svg'} alt="member" />
            <div className="__title">ビジョン浸透</div>
            <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
          </li>
          <li onClick={() => handleSearch(5)} className={`${valueSearch === 5 ? 'activeSearch' : ''}`}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/setupList/search_other.svg'} alt="member" />
            <div className="__title">関係者とのつながり</div>
            <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
          </li>
        </ul>
      </div>

      <Modal open={props.setupaction.refresh || false} className={classes.modal} disableBackdropClick={true}>
        <CircularProgress className={classes.progress} />
      </Modal>

      <Modal
        open={(openOver && props.setupaction.fetched) || false}
        className={classes.modal}
        disableBackdropClick={true}
      >
        <>{messagePanel()}</>
      </Modal>
    </React.Fragment>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      height: `calc(100vh - ${constants.HEADER_HEIGHT}px)`,
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progress: {
      '&:focus': {
        outline: 'none',
      },
    },
    main: {
      backgroundColor: '#F5F5F5',

      '& .__setup_list_container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__content': {
          backgroundColor: constants.COLOR_GRAY,

          '&__header': {
            //
            padding: '8px 16px',

            backgroundColor: constants.COLOR_SECOND_LIGHT,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '&__button': {
              fontWeight: 'bold',
              '& i': {
                marginRight: '4px',
              },
            },
          },

          '&__tabs': {
            //
          },

          '&__tabPanels': {
            //
            '&__lists': {
              padding: '36px 16px',
            },
            '&__noList': {
              padding: '36px 0',

              color: constants.TEXT_GRAY,
              textAlign: 'center',
              fontWeight: 'bold',
              '& img': {
                width: '100%',
                height: '200px',
              },

              '&__description': {
                marginBottom: '16px',
              },
            },
          },
        },
      },
    },

    tabs: {
      backgroundColor: constants.COLOR_SECOND_LIGHT,

      '& .__tab': {
        fontSize: '18px',
        fontWeight: 'bold',
        width: 'calc(50% - 4px)',
        minWidth: 'calc(50% - 4px)',
        backgroundColor: constants.COLOR_MAIN_DARK,
        color: constants.COLOR_WHITE,
        // opacity: 1,
        // '& .MuiTab-textColorInherit': {
        //   opacity: 0,
        // },
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        boxShadow: '1px 0px 1px 0px rgba(21, 27, 38, 0.15)',
        marginTop: '1px',
      },

      '& .__tabLeft': {
        marginRight: '4px',
      },
      '& .__tabRight': {
        marginLeft: '4px',
      },

      '& .__tabSelected': {
        // width: '50%',
        backgroundColor: constants.COLOR_GRAY,
        color: constants.COLOR_MAIN,
        // opacity: 1,
        // '& .MuiTab-textColorInherit': {
        //   opacity: 0,
        // },
      },

      '& .__textColorInherit': {
        opacity: 1,
      },
    },

    indicator: {
      backgroundColor: 'transparent',
    },

    search: {
      opacity: 0,
      position: 'fixed',
      top: constants.HEADER_HEIGHT + 'px',
      width: '100%',
      zIndex: -1,
      boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
      height: `calc(100vh -  54px)`,
      background: 'rgba(10,10,10,0.5)',
      animation: `$slideClose 500ms ${theme.transitions.easing.easeInOut}`,

      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        background: 'white',

        '& li': {
          padding: '8px 16px',
          borderBottom: '2.5px solid ' + constants.COLOR_GRAY,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          '&.activeSearch': {
            backgroundColor: constants.COLOR_MAIN_LIGHT,
          },

          '& img': {
            height: '60px',
          },
          '& .__title': {
            width: '100%',
            padding: '0 32px',
            fontWeight: 'bold',
          },
        },
      },

      '&.open': {
        zIndex: 1,
        opacity: 1,
        animation: `$slide 500ms ${theme.transitions.easing.easeInOut}`,
      },
      '@global': {
        '@keyframes slide': {
          '0%': {
            opacity: 0,
            transform: 'translateX(100%)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateX(0px)',
          },
        },
        '@keyframes slideClose': {
          '0%': {
            opacity: 1,
            zIndex: 1,
            display: 'block',
            transform: 'translateX(0px)',
          },
          '1%': {
            display: 'block',
          },
          '100%': {
            opacity: 0,
            zIndex: -1,
            display: 'none',
            transform: 'translateX(100%)',
          },
        },
      },
    },
    searchHeader: {
      backgroundColor: constants.COLOR_SECOND_LIGHT,
      height: '54px',
      display: 'flex',
      fontWeight: 'bold',
      justifyContent: 'space-between !important',

      '& i': {
        marginRight: '4px',
      },

      '& div': {
        alignItems: 'center',
        display: 'flex',
      },
    },

    modalButtons: {
      padding: '8px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& :first-child': {
        marginRight: '4px',
      },
      '& :last-child': {
        marginLeft: '4px',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
