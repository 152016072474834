import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

export const CssTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '0px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '0px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset, & textarea': {
        padding: 16,
        borderWidth: 0,
      },

      '&:hover fieldset': {
        borderWidth: 0,
      },

      '&.Mui-focused fieldset': {
        borderWidth: 0,
      },
    },
  },
})(TextField)
