export const random = (characters: string, length: number) => {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const randomAlphabetDigitsString = (length: number) => {
  return random('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', length)
}

export const randomDigitsString = (length: number) => {
  return random('0123456789', length)
}
