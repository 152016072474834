import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Flexbox, Font, ButtonForGhost, UsefulCard } from '../../components/atoms'
import { GhostMageImg, GhostFeaturesAndThemes, GhostFileNumberAndTitle } from '../../components/modules'
import { GhostDetailBoard, GhostBackgroundAndBenefitsAndRisks, GhostActionAndEffect } from '../../components/organisms'
import { LoadingIndicator } from '../../components/organisms/LoadingIndicator'
import { GhostContext } from '../../contexts/ghost'

type Props = {
  select?: boolean
}

export const GhostDetails: React.FC<Props> = ({ select }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const { ghosts, detailId, setModal, selectGhost, selectedGhostIds, directClose } = React.useContext(GhostContext)

  const alreadySelected = selectedGhostIds.includes(detailId)

  const ghost = ghosts.find((g) => g.id === detailId)
  if (!ghost) return <LoadingIndicator snapshot={{}} />

  return (
    <div key={detailId} className={classes.container} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <div className={classes.topContainer}>
        <div className={classes.titleContainer}>
          <GhostFileNumberAndTitle
            filenumber={ghost.fileNo}
            name={ghost.name}
            ghostFoundedCount={ghost.found}
            isShowFoundedCount={isMdUp}
            ownStyles={{ textAlign: 'left' }}
          />
          <UsefulCard
            backgroundColor="white"
            borderRadius={0}
            ownStyles={{
              margin: isMdUp ? '0 40px 0 0' : '0 auto 22px',
              width: 148,
              height: 148,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GhostMageImg ghostId={ghost.id} />
          </UsefulCard>
        </div>
        {isMdUp ? (
          <></>
        ) : (
          <Font
            fontSize={14}
            ownStyles={{
              textAlign: 'center',
              position: 'initial',
            }}
          >
            これまでの発見数
            <span style={{ padding: '0 8px', fontSize: 24, fontWeight: 'bold', color: constGhost.COLOR_MAIN_NEW }}>
              {ghost.found || 0}
            </span>
            回
          </Font>
        )}
      </div>
      <GhostDetailBoard
        basicInformation={<GhostFeaturesAndThemes scenes={ghost.scenes} sympton={ghost.sympton} />}
        backgroundAndFeatures={<GhostBackgroundAndBenefitsAndRisks pro={ghost.pro} con={ghost.con} why={ghost.why} />}
        action={
          <GhostActionAndEffect
            solution={ghost.solution}
            actions={ghost.actions}
            anti={ghost.anti}
            caveats={ghost.caveats}
            gapMargin={10}
          />
        }
        reported={true}
        found={ghost.found || 0}
        ownStyles={{ padding: '8px', boxSizing: 'border-box' }}
        selectButton={
          select ? (
            <Flexbox
              ownStyles={{
                flexFlow: isMdUp ? 'initial' : 'column-reverse',
                boxSizing: 'border-box',
                padding: '0 16px',
              }}
            >
              <ButtonForGhost
                buttonBodyColor={'transparent'}
                bodyText={<div>キャンセル</div>}
                ownStyles={{
                  border: `1px solid ${constGhost.COLOR_MAIN_NEW}`,
                  margin: isMdUp ? '0 16px 0 0' : 0,
                }}
                onClick={() => setModal('select')}
              />
              <ButtonForGhost
                buttonBodyColor={'green'}
                bodyText={!alreadySelected ? <div>このオバケを選択する</div> : <div>選択済み(ボタンを押して解除)</div>}
                ownStyles={{ margin: isMdUp ? 0 : '0 0 16px 0' }}
                onClick={() => selectGhost(detailId)}
              />
            </Flexbox>
          ) : (
            <ButtonForGhost
              buttonBodyColor="white"
              bodyText="閉じる"
              isResizeBasic
              ownStyles={{ margin: '0 auto', padding: '0 10', maxWidth: isXsDown ? '90%' : '240px' }}
              onClick={directClose ? () => setModal('') : () => setModal('list')}
            />
          )
        }
      />
    </div>
  )
}

export const ghostNotFoundStyle = {
  width: '100%',
  opacity: 0.3,
  WebkitFilter: 'grayscale(100%) brightness(0)',
  filter: 'grayscale(100%) brightness(0)',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: constGhost.COLOR_WHITE,
    maxWidth: 780,
    margin: '0 auto',
    boxSizing: 'border-box',
    padding: '19px 0 40px',
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      padding: '24px 39px 40px',
    },
  },
  topContainer: {
    position: 'relative',
    marginBottom: 20,
    boxSizing: 'border-box',
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  titleContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
}))
