import React from 'react'

import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

type Props = {
  open: boolean
  handleModalChange: () => void
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const UsefulModal: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles(props)
  const { open, handleModalChange, children } = props

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.usefulModalRoot}>
          {children}
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles((props: StyleProps) => ({
  usefulModalRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 350,
    background: constants.COLOR_WHITE,
    borderRadius: 8,
    outline: 'none',
    boxSizing: 'border-box',
    padding: 24,
    textAlign: 'center',
    ...props.ownStyles,
  },
}),
 { name: 'UsefulModal' }
)
