import * as React from 'react'

import { TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Control, Controller, useWatch } from 'react-hook-form'

import {
  MIN_PRESENTATION_TIME,
  TeamBuildingReviewHooksContext,
} from 'pages/teams/contexts/HooksContextTeamBuildingReview'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'

type Props = {
  title: string
  subtitle: string
  control: Control<{
    iceBreakNumber: number
    iceBreakMinute: number
  }>
}

export const Step1Select: React.FC<Props> = ({ title, subtitle, control }) => {
  const classes = useStyles()
  const { iceBreakNumber, iceBreakMinute, teamBuildingReviewMembers } = React.useContext(TeamBuildingReviewHooksContext)
  const { iceBreakNumber: watchNumber, iceBreakMinute: watchMinute } = useWatch<{
    iceBreakNumber: number
    iceBreakMinute: number
  }>({ control })

  const numberValue = React.useMemo(() => {
    const validated = watchNumber && watchNumber >= 1
    return validated ? watchNumber : 1
  }, [watchNumber])

  const minuteValue = React.useMemo(() => {
    const validated = watchMinute && watchMinute >= MIN_PRESENTATION_TIME
    return validated ? watchMinute : MIN_PRESENTATION_TIME
  }, [watchMinute])

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={2} />
      <ContentSection split>
        <Content>
          <div className={classes.inputWrap}>
            <div className={classes.inputTitle}>発表する人数</div>
            <div className={classes.input}>
              <Controller
                name={'iceBreakNumber'}
                control={control}
                defaultValue={iceBreakNumber || 1}
                value={numberValue}
                render={({ onChange }) => (
                  <>
                    <TextField
                      className={classes.text}
                      type="number"
                      onChange={onChange}
                      value={numberValue}
                      variant="outlined"
                      inputProps={{
                        max: teamBuildingReviewMembers?.items.length,
                        style: { width: 100, padding: '14px', textAlign: 'right' },
                      }}
                      aria-labelledby="input-slider"
                    />
                    <span className={classes.inputLabel}>人</span>
                  </>
                )}
              />
            </div>
          </div>
        </Content>
        <Content>
          <div className={classes.inputWrap}>
            <div className={classes.inputTitle}>１人当たりの発表時間</div>
            <div className={classes.input}>
              <Controller
                name={'iceBreakMinute'}
                control={control}
                defaultValue={iceBreakMinute || MIN_PRESENTATION_TIME}
                value={minuteValue}
                render={({ onChange }) => (
                  <>
                    <TextField
                      className={classes.text}
                      type="number"
                      onChange={onChange}
                      value={minuteValue}
                      variant="outlined"
                      inputProps={{
                        style: { width: 100, padding: '14px', textAlign: 'right' },
                      }}
                      aria-labelledby="input-slider"
                    />
                    <span className={classes.inputLabel}>分</span>
                  </>
                )}
              />
            </div>
          </div>
        </Content>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  inputWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  inputTitle: {
    fontWeight: 'bold',
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  text: {},
  inputLabel: {},
}))
