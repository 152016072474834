import * as React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ja from 'date-fns/locale/ja'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'

type Props = {
  name: string
  value: string | Date
  onChange: () => void
  readOnly?: boolean
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  readOnly?: boolean
}

export const FormDate: React.FC<Props & StyleProps & StyleSubProps> = ({
  name,
  value,
  onChange,
  readOnly = false,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles: ownStyles, readOnly: readOnly })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
      <KeyboardDatePicker
        name={name}
        value={value}
        onChange={onChange}
        disableToolbar
        readOnly={readOnly}
        variant="inline"
        format="yyyy/MM/dd"
        margin="normal"
        id="date-picker-inline"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        className={classes.main}
        autoOk={true}
        keyboardIcon={<img src={CalendarSvg} alt="calendar" style={{ padding: 0 }} />}
        PopoverProps={{ classes: { paper: classes.popover } }}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles({
  main: ({ ownStyles, readOnly }: StyleProps & StyleSubProps) => ({
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: readOnly ? constants.COLOR_ONBOARDING_WHITE : '',
    ...ownStyles,

    '& .MuiFormLabel-root': {
      color: constants.COLOR_MAIN,
    },

    '& .MuiInputBase-root': {
      paddingRight: 0,
      borderRadius: 8,
      border: '1px solid ' + constants.COLOR_ONBOARDING_GRAY_LIGHT,
      height: 42,
    },
    '& .MuiInputBase-input': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      padding: 0,
      marginLeft: 16,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `0 !important`,
    },
    '& .MuiButtonBase-root': {
      color: `${constants.COLOR_MAIN} !important`,
      padding: 8,
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  }),
  popover: {
    borderRadius: 8,
    '& .MuiPickersDay-current': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },

    '& .MuiPickersDay-daySelected': {
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      color: constants.TEXT_WHITE,
    },
  },
})
