import { I18n } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
I18n.setLanguage('ja')

export interface IResetPassword {
  email: string
  code: string
  password: string
}

const resetPassword = async (user: IResetPassword) => {
  await Auth.forgotPasswordSubmit(user.email, user.code, user.password)

  return {}
}

export default resetPassword
