export const DOCUMENTS = [
  {
    title: 'Cocolaboリーダーマニュアル受講者用（全機能）',
    thumbnail: 'cocolabo_leader_manual.png',
    fileName: 'cocolabo_leader_manual.pdf',
  },
  {
    title: 'Cocolaboリーダーマニュアル受講者用（カルテのみ）',
    thumbnail: 'cocolabo_leader_manual_karte.png',
    fileName: 'cocolabo_leader_manual_karte.pdf',
  },
] as const
