import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { NoStyleButton } from 'components/NoStyleButton'

import { ReactionProps } from '../reaction-selections/hooks/useReactionSelections'

import { useReactionIcon } from './hooks/useReactionIcon'

import { constants } from 'assets'

type Props = {
  reactionNum: number
  reactionCounts: { [key: string]: number }
  myIcon: number | null
} & ReactionProps

export const ReactionIcon = ({ reactionNum, reactionCounts, myIcon, postId, teamId }: Props) => {
  const classes = useStyles()
  const { handleMyIconClick } = useReactionIcon()

  const Icon = () => (
    <div key={`reaction-count-${reactionNum}`} className={classes.reaction}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/svg/onboarding/reaction_${reactionNum}.svg`}
        alt={`reaction icon No.${reactionNum}`}
      />
      <span className={classes.counts}>{reactionCounts[reactionNum]}</span>
    </div>
  )

  return (
    <>
      {reactionNum === myIcon ? (
        <NoStyleButton className={classes.myIconButton} onClick={handleMyIconClick(postId, teamId)}>
          <Icon />
        </NoStyleButton>
      ) : (
        <Icon />
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    reaction: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        width: 24,
        verticalAlign: 'top',
      },
    },
    counts: {
      marginLeft: 8,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },
    myIconButton: {
      backgroundColor: `${constants.COLOR_MAIN_NEW}4D`,
      boxShadow: `0 0 0 1px ${constants.COLOR_MAIN_NEW} inset`,
      borderRadius: 12,
    },
  }),
  { name: 'ReactionIcon' }
)
