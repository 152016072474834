import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import dayjs from 'dayjs'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding } from 'utils/generated'

import { Datepicker, BuildingModal, Button } from '../../../components'

import { constants } from 'assets'

type Props = {
  teamBuilding: TeamBuilding
}

export const EditEndAt: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { teamBuilding } = props

  const { refreshTeamBuilding } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const [open, setOpen] = React.useState(false)

  const [modalSelectedDate, setModalSelectedDate] = React.useState<dayjs.Dayjs | undefined>(dayjs(teamBuilding.endAt))

  const onClickEndDate = () => {
    setOpen(true)
  }

  const handleOnSubmit = async () => {
    if (!modalSelectedDate) {
      return
    }

    const updated = await updateTeamBuilding({
      id: teamBuilding.id,
      endAt: modalSelectedDate.toISOString(),
    })

    if (updated) {
      refreshTeamBuilding()
      setOpen(false)
    }
  }

  const handleOnCancel = () => {
    setOpen(false)
    setModalSelectedDate(dayjs(teamBuilding.endAt))
  }

  return (
    <>
      <h3 className={classes.teamInfoEndDate} onClick={onClickEndDate}>
        <span>{teamBuilding?.endAt ? dayjs(teamBuilding.endAt).format('YYYY年MM月DD日') : ''}</span>
        <EditOutlinedIcon
          style={{
            height: 20,
            color: constants.COLOR_ONBOARDING_MAIN,
          }}
        />
      </h3>

      <BuildingModal open={open} setOpen={setOpen}>
        <h2 className={classes.modalHeading}>終了日の変更</h2>
        <div className={classes.modalDatepickerWrap}>
          <span>終了日</span>
          <Datepicker
            defaultDate={modalSelectedDate}
            onSelectDate={(date: dayjs.Dayjs) => setModalSelectedDate(date)}
          />
        </div>
        <div className={classes.modalButtonWrap}>
          <Button full onClick={handleOnSubmit}>
            保存
          </Button>
          <Button full buttonType="secondary" onClick={handleOnCancel}>
            キャンセル
          </Button>
        </div>
      </BuildingModal>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  teamInfoEndDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'normal',
    margin: '0',
  },
  modalHeading: {
    margin: 0,
    fontSize: '14px',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    textAlign: 'center',
  },
  modalButtonWrap: {
    marginTop: '24px',

    '& > button + button': {
      marginTop: '16px',
    },
  },
  modalDatepickerWrap: {
    marginTop: '24px',

    '& > span': {
      display: 'block',
      fontSize: '14px',
      marginBottom: '10px',
    },
  },
}))
