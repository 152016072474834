import { useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'

import actions from '../stores/actions'

import * as pages from 'assets/pages'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  setCurrentLen: () => string
  setNextLen: () => string
  setPrevLen: () => string
  setBackLen: () => string
  onNextToken: (token: any) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useNextToken = (): IProps => {
  const location = useLocation()
  const dispatch = useDispatch()
  const tokenSelectors = useSelector((state: RootStateType) => state.hint.token)
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)

  const isTips = location.pathname === pages.HINT_TIPS_HOME
  const isFaq = location.pathname === pages.HINT_FAQ_HOME
  const isSearch = location.pathname.includes('/search')
  const isProfile = !location.pathname.includes('/page')

  /**
   * 記事一覧初期ページのnextTokenを設定する
   */
  const setCurrentLen = (): string => {
    return tokenSelectors.nextToken[-1]
  }

  /**
   * 記事一覧の次ページへ進んだときのnextTokenを設定する
   */
  const setNextLen = (): string => {
    return tokenSelectors.nextToken[tokenSelectors.nextToken.length - 1]
  }

  /**
   * 記事一覧の前ページへ進んだときのnextTokenを設定する
   */
  const setPrevLen = (): string => {
    if (isTips || isFaq || isSearch || isProfile) {
      return tokenSelectors.nextToken[-1]
    }
    return tokenSelectors.nextToken[pagesSelectors.currentPages - 2]
  }

  /**
   * 記事詳細から一覧へ戻ってきたときのnextTokenを設定する
   */
  const setBackLen = (): string => {
    return tokenSelectors.nextToken[pagesSelectors.currentPages - 2]
  }

  /**
   * Tokenを受けとったあとのEpic処理
   */
  const onNextToken = (token: any): void => {
    const currentToken = tokenSelectors.nextToken

    // ''だった場合配列の末尾にpushする
    if (pagesSelectors.pageAction === '') {
      dispatch(actions.getToken({ nextToken: [token], lastPage: false }))
    }

    // BACKだった場合既存の配列の維持する
    if (pagesSelectors.pageAction === 'BACK') {
      return
    }

    // NEXTだった場合配列の末尾にpushする
    if (pagesSelectors.pageAction === 'NEXT') {
      if (token === null) {
        dispatch(actions.getToken({ nextToken: [...currentToken], lastPage: true }))
        return
      }
      dispatch(actions.getToken({ nextToken: [...currentToken, token], lastPage: false }))
    }

    // PREVIOUSだった場合配列の末尾を削除する
    if (pagesSelectors.pageAction === 'PREVIOUS') {
      if (!tokenSelectors.lastPage) {
        currentToken.pop()
      }

      if (isTips || isFaq || isSearch || isProfile) {
        dispatch(actions.getToken({ nextToken: [token], lastPage: false }))
        return
      }

      dispatch(actions.getToken({ nextToken: [...currentToken], lastPage: false }))
    }
  }

  return {
    setCurrentLen,
    setNextLen,
    setPrevLen,
    setBackLen,
    onNextToken,
  }
}
