import { useContext, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useHintModalEpic } from 'pages/hint/_shared/hooks/useHintModalEpic'
import { ICreateHintParams } from 'pages/hint/_shared/stores/apis/createHint'

// -----------------------------
// interface
// -----------------------------
export type IuseDialogPublishProps = {
  onClose: () => void
  onOpenDialog: () => void
  onDoNotPublish: () => void
  onDoPublish: (params: ICreateHintParams) => void
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useDialogPublish = (): IuseDialogPublishProps => {
  const [open, setOpen] = useState<boolean>(false)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)
  const userSelector = useSelector((state: RootStateType) => state.user)
  const modalSelector = useSelector((state: RootStateType) => state.hint.ui.modal)
  const context = useContext(hintContext)
  const hintModalEpic = useHintModalEpic()

  /**
   * ダイアログをクローズする時の処理
   */
  const onClose = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログをオープンする時の処理
   */
  const onOpenDialog = (): void => {
    setOpen(true)
  }

  /**
   * ダイアログ内の記事公開ボタンをキャンセルした時
   */
  const onDoNotPublish = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の記事公開ボタンを押した時
   */
  const onDoPublish = async (params: ICreateHintParams): Promise<void> => {
    if (modalSelector.status === 'UPDATE') {
      await hintModalEpic.updateHintEpic(params, 1)
    } else {
      await hintModalEpic.createHintEpic(params, 1)
    }

    if (!commonSelector.isLoading) {
      setOpen(false)
      context.onCloseModal()
      window.location.href = `/hint/tips/profile/${userSelector.id}`
    }
  }

  /**
   * ダイアログの状態を返す
   */
  const getOpen = (): boolean => {
    return open
  }

  return {
    onClose,
    onOpenDialog,
    onDoNotPublish,
    onDoPublish,
    getOpen,
  }
}
