import { combineEpics } from 'redux-observable'

import PagesEpic from '../pages/epic'

import helpEpic from './help/epic'
import selfchekEpic from './selfcheck/epic'
import setupEpic from './setup/epic'
import setupactionEpic from './setupaction/epic'
import userEpic from './user/epic'


const epics = combineEpics(...userEpic, ...setupEpic, ...setupactionEpic, ...helpEpic, PagesEpic, ...selfchekEpic)

export default epics
