export const toolbarConfig = {
  options: ['inline', 'list', 'blockType', 'link', 'textAlign', 'image'],
  inline: {
    options: ['bold', 'underline'],
    className: 'inline-class',
  },
  blockType: {
    inDropdown: false,
    visible: false,
    options: ['H1', 'H2', 'Blockquote', 'Code'],
    className: 'heading-class',
  },
  list: {
    options: ['unordered', 'ordered'],
    className: 'list-class',
  },
  link: {
    inDropdown: false,
    options: ['link'],
    className: 'link-class',
    popupClassName: 'link-modal-class',
  },
}
