import * as React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = { ownStyles?: React.CSSProperties }

export const LoadingCircularProgress: React.FC<Props> = ({ ownStyles }) => {
  const classes = useStyles({})

  return (
    <div className={classes.loading} style={ownStyles}>
      <CircularProgress size={34} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      color: constGhost.COLOR_MAINBLUE,
    },
  },
}))
