import * as React from 'react'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { mergeClasses } from 'pages/onboarding/utils/styles'

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={mergeClasses([classes.container, className])}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="11.625" viewBox="0 0 11.625 11.625">
        <path
          d="M5.812-10.312A5.811,5.811,0,0,0,0-4.5,5.811,5.811,0,0,0,5.812,1.312,5.811,5.811,0,0,0,11.625-4.5,5.811,5.811,0,0,0,5.812-10.312ZM7.687-6.375a.749.749,0,0,1,.75.75.749.749,0,0,1-.75.75.749.749,0,0,1-.75-.75A.749.749,0,0,1,7.687-6.375Zm-3.75,0a.749.749,0,0,1,.75.75.749.749,0,0,1-.75.75.749.749,0,0,1-.75-.75A.749.749,0,0,1,3.937-6.375ZM8.5-2.386A3.492,3.492,0,0,1,5.812-1.125,3.492,3.492,0,0,1,3.122-2.386a.375.375,0,0,1,.577-.48,2.739,2.739,0,0,0,2.114.989,2.746,2.746,0,0,0,2.114-.989A.375.375,0,0,1,8.5-2.386Z"
          transform="translate(0 10.312)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
