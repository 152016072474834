import * as React from 'react'

import { Button, IconButton, makeStyles, Popover, Theme } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { FontAwesome } from 'react-web-vector-icons'

import { useOnboardingActionLikes } from 'pages/onboarding/hooks/action'
import { OnboardingAction } from 'utils/generated'

import * as constants from 'assets/constants'

export type OwnProps = {
  action: OnboardingAction
  userId: string
  onLike: () => void
}

type Props = OwnProps

export const Likes: React.FC<Props> = ({ action, onLike, userId }) => {
  const classes = useStyles()

  const { actionLikes, refresh } = useOnboardingActionLikes(`${action.id}-${userId}`)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleShowLikes = (event: React.MouseEvent<HTMLButtonElement>) => {
    refresh()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.actionContainer}>
      <Button onClick={onLike} className={classes.actionButton}>
        {action.isLiked ? (
          <>いいね済</>
        ) : (
          <>
            <FontAwesome name="smile-o" color={constants.COLOR_MAIN} size={14} style={{ marginRight: 8 }} /> いいね
          </>
        )}
      </Button>
      <div className={classes.toGoodDetail}>
        {action.likeCount || 0}

        <IconButton onClick={handleShowLikes} className={classes.showLikes} size={'small'}>
          <ChevronRightRoundedIcon color={'primary'} />
        </IconButton>
        <Popover
          id={Boolean(anchorEl) ? 'action-likes-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.likesContainer}>
            {actionLikes.length > 0 ? (
              <>
                {actionLikes.map((actionLike, index) => (
                  <div key={`${action.id}-likes-${index}`}>
                    <div className={classes.likeUserContainer}>
                      {actionLike.teamMember?.imageUrl ? (
                        <img className={classes.likeUserAvatar} src={actionLike.teamMember.imageUrl} alt={'avatar'} />
                      ) : (
                        <>
                          {actionLike.user?.imageUrl ? (
                            <img className={classes.likeUserAvatar} src={actionLike.user.imageUrl} alt={'avatar'} />
                          ) : (
                            <div className={classes.likeUserAvatar} />
                          )}
                        </>
                      )}
                      <div className={classes.likeUserContent}>
                        <div className={classes.likeUserName}>
                          {actionLike.teamMember?.nickname || actionLike.user?.nickname}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={classes.likeUserName}>いいねはありません</div>
            )}
          </div>
        </Popover>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    fontFamily: 'sans-serif',
    backgroundColor: constants.COLOR_GRAY,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& div': {
      paddingTop: 8,
      paddingBottom: 8,
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  actionButton: {
    color: constants.COLOR_MAIN,
  },
  toGoodDetail: {
    textAlign: 'right',
    color: '#8D8D8D',
    fontSize: 14,
  },
  // arrowRight: {
  //   marginLeft: 16,
  // },

  showLikes: {
    //
  },

  likesContainer: {
    maxHeight: 200,
    overflow: 'scroll',
    padding: 8,
  },

  likeUserContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  likeUserAvatar: {
    marginRight: 8,
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: constants.COLOR_GRAY,
    objectFit: 'cover',
  },
  likeUserContent: {},
  likeUserName: {},
}))
