import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { TextField } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'

import { DefaultButton } from 'pages/teams/components'

import * as constants from '../../../assets/constants'
import { InputComment } from '../index.component'

import { PAGE_SETUP_EMAILNOTIFY } from 'assets/pages'

type Props = {
  handleNextButtonClick: (data?: InputComment) => void
}

export const AnketWithdraw: React.FC<Props> = ({ handleNextButtonClick }) => {
  const classes = useStyles()
  const history = useHistory()

  const handlePrevButtonClick = () => {
    history.push(constants.PAGE_HELP)
    window.scrollTo(0, 0)
  }

  const { register, handleSubmit } = useForm<InputComment>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data: InputComment) => {
    handleNextButtonClick(data)
  }

  return (
    <div>
      <h2 className={classes.heading}>退会手続きの前にご確認下さい</h2>
      <div className={classes.textContainer}>
        <p>
          退会すると今までCocolaboで利用したデータが<strong>すべて削除</strong>されます。
        </p>
        <p>
          Cocolabo運営事務局からのメールを受信しないようにしたい場合は、
          <Link to={PAGE_SETUP_EMAILNOTIFY}>メール通知設定</Link>
          の変更も可能です。
        </p>
        <p>退会する場合は以下に理由をご入力の上、退会処理を行ってください。</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          multiline
          variant="outlined"
          classes={{ root: classes.textArea }}
          name="comment"
          inputRef={register({})}
          minRows={4}
        />
        <div className={classes.buttons}>
          <DefaultButton
            title="キャンセル"
            color="team_white"
            onClick={() => handlePrevButtonClick()}
            style={{ maxWidth: 180, height: 56, borderRadius: 28, borderWidth: 2 }}
          />
          <DefaultButton
            title="退会する"
            color="team_main"
            type="submit"
            style={{ maxWidth: 180, height: 56, borderRadius: 28 }}
          />
        </div>
      </form>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    heading: {
      textAlign: 'center',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },

    textContainer: {
      marginBottom: 48,
      '& p': {
        margin: '0 0 8px',
        '& strong': {
          color: constants.COLOR_RED,
          fontWeight: 'bold',
        },
      },
      '& a': {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        cursor: 'pointer',
        fontWeight: 'bold',
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.8,
          },
        },
      },
    },

    textArea: {
      width: '100%',
    },

    buttons: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      marginTop: 48,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
      },
    },
  }),
  { name: 'AnketWithdraw' }
)
