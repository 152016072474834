import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import { IuseSocials } from '../useSocials'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 0,
    padding: 0,
  },

  icons: {
    marginTop: 8,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseSocials
}

// -----------------------------
// Component
// -----------------------------
export const SocialsShare = (props: IProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.icons}>
        <TwitterShareButton url={`${window.location.protocol}//${window.location.host}`}>
          <img src={'/assets/svg/setup/twitter_icn.svg'} alt={'twitter'} />
        </TwitterShareButton>
      </div>
      <div className={classes.icons}>
        <FacebookShareButton url={`${window.location.protocol}//${window.location.host}`}>
          <img src={'/assets/svg/setup/facebook_icn.svg'} alt={'facebook'} />
        </FacebookShareButton>
      </div>
    </div>
  )
}
