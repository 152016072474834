import React, { Dispatch, SetStateAction } from 'react'

import { Theme, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

import { NoStyleButton } from 'components/NoStyleButton'
import modalCautionIcon from 'pages/onboarding/assets/modalCautionIcon.svg'
import modalCrossIcon from 'pages/onboarding/assets/modalCrossIcon.svg'

import { useOnbHistory } from '../navigation/route'

import { useHistory } from 'assets'
import * as constants from 'assets/constants'
import { OnbPages, Pages } from 'assets/pages'




//handle importPage
type ImportTypeOnb = 'action-add' | 'member-add'
type ImportType = ImportTypeOnb | 'team-add'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  importPage: ImportType
  teamId?: string
}
type ConfirmProps = {
  uploadedCounts: number
}
type ErrorProps = {
  uploadedCounts: number
  errRows: string[]
}
type StyleSubProps = {
  // only use in this component
  classes: ClassNameMap
}

export const CSVUploadedModal: React.FC<Props & ErrorProps & ConfirmProps> = ({
  importPage,
  open,
  setOpen,
  teamId,
  uploadedCounts,
  errRows,
}) => {
  // set contentsType
  type Contents = {
    linkTo: OnbPages
  }
  const contents: { [k in ImportTypeOnb]: Contents } = {
    'action-add': { linkTo: OnbPages.ActionManage },
    'member-add': { linkTo: OnbPages.Team },
  }

  const history = useOnbHistory()
  const { route } = useHistory()
  const classes = useStyles()
  const isError = errRows.length ? true : false

  const handleClose = () => {
    setOpen(false)
    if (isError) {
      undefined
    } else if (importPage === 'action-add' || importPage === 'member-add') {
      history.push(contents[importPage].linkTo, { teamId })
    } else if (importPage === 'team-add') {
      route.push(Pages.TeamsList)
    }
  }

  if (!open) return null
  return (
    <div className={classes.root} onClick={handleClose}>
      <div className={classes.modalRoot} onClick={(e) => e.stopPropagation()}>
        <img src={modalCrossIcon} alt={'close'} className={classes.img} onClick={handleClose} />
        {isError ? (
          <ErrorChildren uploadedCounts={uploadedCounts} errRows={errRows} classes={classes} />
        ) : (
          <ConfirmChildren uploadedCounts={uploadedCounts} classes={classes} />
        )}
        <NoStyleButton className={classes.button} onClick={handleClose}>
          確認
        </NoStyleButton>
      </div>
    </div>
  )
}

const ConfirmChildren: React.FC<ConfirmProps & StyleSubProps> = ({ uploadedCounts, classes }) => {
  return (
    <>
      <div className={classes.confirmTitle}> アップロード完了</div>
      {uploadedCounts ? (
        <>
          <div className={classes.confirmText}> データが正常に登録されました。</div>
          <div className={classes.confirmDesc}>（{uploadedCounts}件）</div>
        </>
      ) : (
        <>
          <div className={classes.confirmText}> 登録するデータがありませんでした。</div>
          <div className={classes.confirmDesc}>行の先頭に（例）が付いているサンプルデータは無視されます。</div>
        </>
      )}
    </>
  )
}
const ErrorChildren: React.FC<ErrorProps & StyleSubProps> = ({ uploadedCounts, errRows, classes }) => {
  return (
    <>
      <img src={modalCautionIcon} alt={'caution'} />
      <div className={classes.errorTitle}>アップロードエラー</div>
      <div className={classes.errorText}>
        データを登録できませんでした。ファイルの内容を確認してください。
        <p className={classes.detailTitle}>エラー内容</p>
        <div className={classes.errorDetail}>
          {errRows?.map((row, i) => (
            <div key={`csv-upload-err-${i}`} className={classes.errRow}>
              {row}
            </div>
          ))}
        </div>
        {uploadedCounts ? (
          <>
            <div className={classes.confirmed}>エラーのなかった行は正常に登録されました。</div>
            <div>（{uploadedCounts}件）</div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: `${constants.COLOR_ONBOARDING_GRAY2}B3`,
      zIndex: 1000,
    },
    modalRoot: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px 16px 16px',
      width: 350,
      maxHeight: '70%',
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
    },
    img: {
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'block',
      padding: 14,
      cursor: 'pointer',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 16,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 8,
      width: '100%',
      height: 48,
    },
    //for Error Children
    errorImg: {
      display: 'block',
    },
    errorTitle: {
      color: constants.COLOR_ONBOARDING_ALERT,
      fontSize: 14,
      fontWeight: 'bold',
      margin: '16px 0 0',
    },
    errorText: {
      textAlign: 'center',
      margin: '21px 0 24px',
    },
    detailTitle: {
      margin: '8px 0',
    },
    errorDetail: {
      padding: 8,
      textAlign: 'left',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      maxHeight: 125,
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: 105,
      },
    },
    errRow: {
      whiteSpace: 'pre-wrap',
      marginBottom: 4,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    //for Confirm Children
    confirmTitle: {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 14,
      fontWeight: 'bold',
      margin: '16px 0 0',
    },
    confirmText: {
      margin: '21px 0 0',
    },
    confirmDesc: {
      margin: '4px 0 35px',
      textAlign: 'center',
    },
    confirmed: {
      margin: '16px 0 4px',
    },
  }),
  { name: 'CSVErrorModal' }
)
