import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useIntersection } from 'hooks/intersectionObserver'

import { LoadingCircular } from './LoadingCircular'

type Props = {
  IntersectItem: {
    isLoading: boolean
    isMoreLoading: boolean
    nextToken: string | null | undefined
    more: () => Promise<void>
  }
}

export const IntersectLoading: React.FC<Props> = ({ IntersectItem }) => {
  const { isLoading, isMoreLoading, nextToken, more } = IntersectItem
  const classes = useStyles()
  const targetRef = React.useRef<HTMLDivElement>(null)
  useIntersection({ targetRef, intersectionCallBack: more })

  if (isLoading || !nextToken) {
    return null
  }

  return (
    <div className={classes.intersectLoading}>
      {isMoreLoading ? (
        <LoadingCircular loading={isMoreLoading} size={32} wrapperStyles={{ padding: 0 }} />
      ) : (
        <div ref={targetRef}></div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    intersectLoading: {
      marginTop: 24,
    },
  }),
  { name: 'IntersectLoading' }
)
