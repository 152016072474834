import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { NoStyleButton } from 'components/NoStyleButton'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { GhostHistorySelectInTable } from './GhostHistorySelectInTable'
import { MemberInfo } from './MemberInfo'
import { MemberGhostHistories } from './useFormatGhostHistories'

import { TableFilter } from './index'

import { constants } from 'assets'

type Props = {
  memberGhostHistories: MemberGhostHistories
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
}

export const SelectedGhostsTableForSp: React.FC<Props> = ({ memberGhostHistories, setFilter }) => {
  const classes = useStyles()
  const [isOpenList, setIsOpenList] = React.useState(false)
  const hasExpandData = memberGhostHistories.histories.length > 1
  const historyLength = memberGhostHistories.histories.length ?? 0
  const { ghosts } = React.useContext(GhostContext)

  return (
    <table className={classes.spTable}>
      <thead>
        <tr>
          <th colSpan={2}>
            <div className="__memberNameCell">
              <MemberInfo id={memberGhostHistories.teamMemberId} />
              {hasExpandData && (
                <NoStyleButton
                  onClick={() => setIsOpenList(!isOpenList)}
                  aria-label="過去のオバケ履歴を開く"
                  className="__arrowIconButton"
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow-${isOpenList ? 'up' : 'down'}.svg`} alt="" />
                </NoStyleButton>
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {memberGhostHistories.histories.map((history, i) => {
          // 選択したオバケがない場合は "ー" を表示
          const selectedGhostIds =
            history.selectedGhostIds.length > 0
              ? history.selectedGhostIds
              : Array.from({ length: 3 }, (_, index) => `dummy-${index}`)

          // 最新の5回分を表示
          // TODO: フィルタリング処理を追加する
          return i > 4 || (i > 0 && !isOpenList) ? null : (
            <tr key={`${memberGhostHistories.teamMemberId}-${history.playDate}`} style={{ height: 93 }}>
              <td className="__countCell">
                {i === 0 ? (
                  <GhostHistorySelectInTable
                    histories={memberGhostHistories.histories}
                    memberId={memberGhostHistories.teamMemberId}
                    setFilter={setFilter}
                  />
                ) : (
                  `${historyLength - i}回目`
                )}
              </td>
              <td>
                <ul>
                  {selectedGhostIds.map((ghostId) => {
                    const ghost = ghosts.find((g) => g.id === ghostId.toString())
                    return <li key={ghostId}>{ghost ? ghost.name : '－'}</li>
                  })}
                </ul>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const useStyles = makeStyles(
  () => ({
    spTable: {
      width: '100%',
      textAlign: 'left',
      borderCollapse: 'collapse',

      '& tr': {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
        backgroundColor: constants.COLOR_TEAMBUILDING_WHITE,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      '& th, td': {
        padding: 16,
      },
      '& th': {
        fontSize: 12,
      },
      '& td': {
        fontSize: 10,
      },
      '& thead th': {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      },
      '& .__memberNameCell': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
      },
      '& .__arrowIconButton': {
        width: 16,
        height: 16,
      },
      '& .__countCell': {
        verticalAlign: 'top',
        width: 100,
      },
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        fontSize: 10,
        fontWeight: 'bold',
      },
      '& ul li': {
        marginBottom: 8,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
  { name: 'SelectedGhostsTableForSp' }
)
