import * as React from 'react'

import { Imgcard } from 'pages/ghost/components/atoms/index'

type Props = {
  ownStyles?: React.CSSProperties
}
export const WhiteGhostImg: React.FC<Props> = ({ ownStyles }) => {
  return (
    <>
      <Imgcard
        src={process.env.PUBLIC_URL + '/assets/ghost/ghostTransparentNew.svg'}
        alt={'ghost'}
        ownStyles={{ margin: '30px 0 -5px', maxWidth: 365, width: '100%', ...ownStyles }}
      />
    </>
  )
}
