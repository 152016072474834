import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import UsefulButton from '../../../../components/Button'
import SpeechBubble from '../../components/SpeechBubble'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const handlePage = (e: React.MouseEvent<HTMLElement>) => {
    props.history.push(e.currentTarget.getAttribute('data-page') || '/')
    window.scrollTo(0, 0)
  }

  const handleStart = () => {
    props.history.push(pages.KARTE_LEADER_SEARCH)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__header">
            <Button className="__container__header__button" onClick={handlePage} data-page={pages.KARTE_LEADER_HOME}>
              <FontAwesome name="chevron-left" color={constants.COLOR_BLACK} size={16} />
              戻る
            </Button>
            <div style={{ width: '100%', textAlign: 'center' }}>リーダー診察室</div>
            <div style={{ width: 100 }} />
          </div>
          <div className="__container__content">
            <div className="__container__content__description">
              <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
              <div>
                <SpeechBubble>こちらはリーダー診察室です。</SpeechBubble>
                <SpeechBubble>下記注意事項をよく確認した上で​、 診断をスタートしてください。</SpeechBubble>
              </div>
            </div>

            <div className="__container__content__cautions">
              <div className="__container__content__cautions__title">注意事項</div>
              <p>所要時間は約3分です。</p>
              <p>あまり1問毎に時間をかけず回答していきましょう。</p>
              <div className="__container__content__cautions__hr" />
              <p>必ずメンバーのカルテNoをもらってから診断を開始してください。​</p>
              <div className="__container__content__cautions__hr" />
              <p>回答は部下に共有されません。</p>
              <p>率直に今の気持ちでお答えください。​</p>
              <div className="__container__content__cautions__hr" />
              <p>診断後、カルテNoを忘れずに保存してください。</p>
              <p>いつでも読み出すことができます。それぞれのメンバーとの面談時に活用してください。</p>
            </div>

            <div className="__container__content__buttons">
              <UsefulButton
                bgColor={constants.COLOR_BLUE}
                textColor={constants.COLOR_WHITE}
                fullWidth={true}
                body={<div>診断スタート</div>}
                onClick={handleStart}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__content': {
          backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__description': {
            display: 'flex',
            padding: '16px 16px 8px',

            '& img': {
              width: 50,
              height: 50,
              marginRight: 16,
            },
          },

          '&__cautions': {
            padding: 16,
            background: '#fff',
            borderRadius: 5,
            marginBottom: 24,

            '& p': {
              marginTop: 8,
              marginBottom: 8,
            },

            '&__title': {
              color: constants.COLOR_MAIN,
              fontSize: 20,
              fontWeight: 'bold',
            },

            '&__hr': {
              borderBottom: `1px dotted ${constants.COLOR_MAIN}`,
            },
          },

          '&__buttons': {
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
