import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'

import { GhostList } from './GhostList'
import { MembersList } from './MembersList/index'
import { PastList } from './PastList'

import { constants } from 'assets'

type Tablist = typeof tablist[number]
type TabItem = { [key in Tablist]: { label: string; content: React.FC } }

const tablist = ['pastList', 'ghostList', 'membersList'] as const
const tabItem: TabItem = {
  pastList: { label: '過去のオバケ一覧', content: PastList },
  ghostList: { label: 'オバケリスト', content: GhostList },
  membersList: { label: 'みんなの探したオバケ', content: MembersList },
}

export const GhostDashboardTabs: React.FC = () => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = React.useState<Tablist>('pastList')

  const handleTabChange = (tab: Tablist) => () => {
    setActiveTab(tab)
  }

  const Content = tabItem[activeTab].content

  return (
    <div className={classes.root}>
      <div className={classes.tablistWrapper}>
        <div className={classes.tablist} role="tablist">
          {tablist.map((tab) => (
            <NoStyleButton
              key={tab}
              role="tab"
              onClick={handleTabChange(tab)}
              className={classNames([classes.tab, tab === activeTab && classes.activeTab])}
            >
              <span>{tabItem[tab].label}</span>
            </NoStyleButton>
          ))}
        </div>
      </div>

      <div role="tabpanel" className={classes.tabpanel}>
        <Content />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: -18,
      color: constants.TEXT_GRAY_DARK,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    tablistWrapper: {
      display: 'inline-block',
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tablist: {
      display: 'flex',
      columnGap: 40,
      [theme.breakpoints.down('sm')]: {
        columnGap: 0,
        justifyContent: 'space-between',
      },
    },
    tab: {
      color: '#6C6C6C',
      paddingBottom: 4,
      fontSize: 12,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        fontSize: 9,
      },
    },
    activeTab: {
      position: 'relative',
      color: constants.COLOR_MAIN_NEW,
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: -1,
        width: '100%',
        height: 0,
        borderBottom: `2px solid ${constants.COLOR_MAIN_NEW}`,
        zIndex: 10,
      },
    },
    tabpanel: {
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
  }),
  { name: 'GhostDashboardTabs' }
)
