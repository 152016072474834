import { Phase } from '../../contexts/TeamToolsKarteDiagnoseContext'

export const getNextPhase = (current: Phase) => {
  switch (current) {
    case Phase.Prepare:
      return Phase.Q1
    case Phase.Q1:
      return Phase.Q2
    case Phase.Q2:
      return Phase.Q3
    case Phase.Q3:
      return Phase.Q4
    case Phase.Q4:
      return Phase.Q5
    case Phase.Q5:
      return Phase.Q6
    case Phase.Q6:
      return Phase.Q7
    case Phase.Q7:
      return Phase.Q8
    case Phase.Q8:
      return Phase.Q9
    case Phase.Q9:
      return Phase.Q10
    case Phase.Q10:
      return Phase.Q11
    case Phase.Q11:
      return null
    default:
      return null
  }
}

export const getPreviousPhase = (current: Phase) => {
  switch (current) {
    case Phase.Prepare:
      return null
    case Phase.Q1:
      return Phase.Prepare
    case Phase.Q2:
      return Phase.Q1
    case Phase.Q3:
      return Phase.Q2
    case Phase.Q4:
      return Phase.Q3
    case Phase.Q5:
      return Phase.Q4
    case Phase.Q6:
      return Phase.Q5
    case Phase.Q7:
      return Phase.Q6
    case Phase.Q8:
      return Phase.Q7
    case Phase.Q9:
      return Phase.Q8
    case Phase.Q10:
      return Phase.Q9
    case Phase.Q11:
      return Phase.Q10
    default:
      return null
  }
}

export const getPhaseIndex = (current: Phase) => {
  switch (current) {
    case Phase.Prepare:
      return -1
    case Phase.Q1:
      return 0
    case Phase.Q2:
      return 1
    case Phase.Q3:
      return 2
    case Phase.Q4:
      return 3
    case Phase.Q5:
      return 4
    case Phase.Q6:
      return 5
    case Phase.Q7:
      return 6
    case Phase.Q8:
      return 7
    case Phase.Q9:
      return 8
    case Phase.Q10:
      return 9
    case Phase.Q11:
      return 10
    default:
      return -1
  }
}
