import React, { useEffect, useState, useCallback, useMemo, SetStateAction } from 'react'

import { makeStyles, Theme, useMediaQuery, useTheme, ClickAwayListener } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'
import format from 'date-fns/format'

import { SlideArrow } from 'pages/onboarding/components/SlideArrow'
import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { onboardingFeedbackLabel } from 'pages/onboarding/utils/onboardingFeedbackLabel'
import { OnboardingAction, OnboardingActionFeedback, OnboardingActionCategory } from 'utils/generated'

import { WhiteCard } from '../../../components/atoms'

import { HandleDetailOpenText } from './detailBox/HandleDetailOpenText'



import checkedIcon from '../assets/checkedIcon.svg'
import filterIcon from '../assets/filterIcon.svg'
import upAndDownArrow from '../assets/upAndDownArrow.svg'
import * as constants from 'assets/constants'

type Props = {
  totalActions: OnboardingAction[]
  visibleActions: OnboardingAction[]
  actionsFeedback: OnboardingActionFeedback[]
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

//helpers in this file
type ActionStatus = 'inActive' | 'achieve' | 'notYet'
type ActionsSummary = {
  achieveAt: string
  title: string
  selfScore: string
  status: ActionStatus
  //only use it for Filter by categories
  category: OnboardingActionCategory
}
const categoriesText: string[] = [
  onboardingActionCategoryLabel(OnboardingActionCategory.Category1),
  onboardingActionCategoryLabel(OnboardingActionCategory.Category2),
  onboardingActionCategoryLabel(OnboardingActionCategory.Category3),
  onboardingActionCategoryLabel(OnboardingActionCategory.Category4),
  onboardingActionCategoryLabel(OnboardingActionCategory.Category5),
]
const actionsWrapperId = 'actionsWrapper'
const columns = ['達成日', 'アクション内容', '自己評価', 'アクション状況'] as const
const orders = ['昇順', '降順'] as const
type Columns = typeof columns[number]
type Orders = typeof orders[number]
const isTypeColumn = (arg: unknown): arg is Columns => Boolean(columns.find((item) => item === arg))
const isTypeOrders = (arg: unknown): arg is Orders => Boolean(orders.find((item) => item === arg))

export const ActionStatusBox: React.FC<Props & StyleProps> = ({
  totalActions,
  visibleActions,
  actionsFeedback,
  ownStyles,
}) => {
  //helpers-->
  const getInactiveActions = useCallback(() => {
    const visibleActionIds = visibleActions.map((item) => item.id)
    return totalActions.filter((item) => !visibleActionIds.includes(item.id))
  }, [totalActions, visibleActions])
  const getActionByActionId = useCallback(
    (actionId: string) => totalActions.find((item) => item.id === actionId) || ({} as OnboardingAction),
    [totalActions]
  )

  const calcActionsData = useCallback((): ActionsSummary[] => {
    const returnValue: ActionsSummary[] = []
    for (const inActiveAction of getInactiveActions()) {
      returnValue.push({
        achieveAt: '-',
        title: inActiveAction.mission,
        selfScore: '-',
        status: 'inActive',
        category: inActiveAction.category,
      })
    }
    for (const visibleAction of visibleActions) {
      returnValue.push({
        achieveAt: '-',
        title: visibleAction.mission,
        selfScore: '-',
        status: 'notYet',
        category: visibleAction.category,
      })
    }
    for (const actionFeedback of actionsFeedback) {
      returnValue.push({
        achieveAt: format(new Date(actionFeedback.settedAt), 'Y年M月d日'),
        title: getActionByActionId(actionFeedback.actionId)?.mission,
        selfScore: onboardingFeedbackLabel(actionFeedback.value),
        status: 'achieve',
        category: actionFeedback.category,
      })
    }
    return returnValue
  }, [actionsFeedback, visibleActions, getActionByActionId, getInactiveActions])
  const initActionsData = useMemo(
    () => calcActionsData(),
    //because of rejecting more re-calc
    // eslint-disable-next-line
    [actionsFeedback, visibleActions, getInactiveActions]
  )

  //<--helpers
  // handle data and UI -->
  //arrange actions data
  const [actionsData, setActionsData] = useState<ActionsSummary[]>([])
  useEffect(() => {
    setActionsData(initActionsData)
  }, [initActionsData])

  //handle appear details for tab
  const [visibleActionOverView, setVisibleActionOverView] = useState<boolean>(false)

  // handle filter action by categories
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false)
  const handleVisibleFilter = () => {
    setVisibleFilter((prev) => !prev)
  }
  const [filters, setFilters] = useState<string[]>(categoriesText)
  useEffect(() => {
    const newValue: ActionsSummary[] = []
    if (filters?.length) {
      for (const filter of filters) {
        newValue.push(...calcActionsData().filter((item) => onboardingActionCategoryLabel(item.category) === filter))
      }
    }
    setActionsData(newValue)
  }, [filters, calcActionsData])

  //handle sort of actions
  const [visibleSorts, setVisibleSorts] = useState<Array<Columns>>([])
  const [sort, setSort] = useState<{ column: Columns; order: Orders }>({} as { column: Columns; order: Orders })
  const onArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentColumn = e.currentTarget.parentElement?.parentElement?.textContent
    if (currentColumn && isTypeColumn(currentColumn)) {
      setVisibleSorts((prev) => [...prev, currentColumn])
    }
  }
  useEffect(() => {
    const isUpDown = () => {
      if (sort.order === '昇順') {
        return { sign: 1 }
      } else {
        return { sign: -1 }
      }
    }
    switch (sort.column) {
      case '達成日': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.achieveAt === b.achieveAt) return 0
          return a.achieveAt < b.achieveAt ? isUpDown().sign * 1 : isUpDown().sign * -1
        })
        return setActionsData(newValue)
      }
      case 'アクション内容': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.title === b.title) return 0
          return a.title < b.title ? isUpDown().sign * 1 : isUpDown().sign * -1
        })
        return setActionsData(newValue)
      }
      case '自己評価': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.selfScore === b.selfScore) return 0
          return a.selfScore > b.selfScore ? isUpDown().sign * 1 : isUpDown().sign * -1
        })
        return setActionsData(newValue)
      }
      case 'アクション状況': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.status === b.status) return 0
          const compareA = a.status === 'inActive' ? 1 : a.status === 'notYet' ? 2 : 3
          const compareB = b.status === 'inActive' ? 1 : b.status === 'notYet' ? 2 : 3
          return isUpDown().sign * (compareA - compareB)
        })
        return setActionsData(newValue)
      }
    }
    //because of avoid circular dependency to update actionsData(itself)
    // eslint-disable-next-line
  }, [sort])
  // <-- handle data
  const classes = useStyles()

  const theme = useTheme()
  const isBreakpointsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <WhiteCard
      ownStyles={{
        marginBottom: 22,
        overflowY: 'scroll',
        position: isBreakpointsDown ? 'relative' : 'static',
        width: isBreakpointsDown ? 'calc(100vw -32px)' : 'auto',
        minHeight: '370px',
        ...ownStyles,
      }}
    >
      <div className={classes.headerWrapper}>
        <div className={classes.title}>アクション状況</div>
        <HandleDetailOpenText open={visibleActionOverView} setOpen={setVisibleActionOverView} />
      </div>
      <div className={classes.SPViewportWrapper} id={actionsWrapperId}>
        <div className={classes.actionsWrapper}>
          {columns.map((item) => (
            <div key={`columns-${item}`} className={classes.titleTextWrapper}>
              <div className={classes.titleText}>
                <div>{item}</div>
                <div className={classes.arrowWrapper}>
                  <img src={upAndDownArrow} alt="arrowIcon" className={classes.arrowImg} onClick={onArrowClick} />
                  {/* if open sortPopup---> */}
                  {visibleSorts.includes(item) ? (
                    <SortList
                      parent={item}
                      items={orders}
                      sort={sort}
                      setSort={setSort}
                      setVisibleSorts={setVisibleSorts}
                      classes={classes}
                    />
                  ) : (
                    <></>
                  )}
                  {/* <---if open sortPopup */}
                </div>
              </div>
            </div>
          ))}
          <div className={classes.filterWrapper}>
            <img src={filterIcon} alt="filter" className={classes.filterImg} onClick={handleVisibleFilter} />
            {/* if open filterPopup--->*/}
            {visibleFilter ? (
              <FiltersList
                items={categoriesText}
                filters={filters}
                setFilters={setFilters}
                setVisibleFilter={setVisibleFilter}
                classes={classes}
              />
            ) : (
              <></>
            )}
            {/* <---if open filterPopup */}
            {/* if viewport width is under 600---> */}
            <SlideArrow
              visible={isBreakpointsDown}
              windowId={actionsWrapperId}
              ownStyles={{
                position: 'absolute',
                bottom: undefined,
                top: 0,
                right: -70,
              }}
            />
            {/* <---if viewport width is under 600 */}
          </div>
          {actionsData.length > 0 ? (
            <>
              {actionsData.map((item, i) => {
                if (!visibleActionOverView && i > 4) return
                return <ActionStatusItem key={`${item.achieveAt}${item.title}${i}`} {...item} classes={classes} />
              })}
            </>
          ) : (
            <>
              表示するアクションは
              <br />
              ありません。
            </>
          )}
        </div>
      </div>
    </WhiteCard>
  )
}

const ActionStatusItem: React.FC<{ classes: ClassNameMap } & ActionsSummary> = ({
  achieveAt,
  title,
  selfScore,
  status,
  classes,
}) => {
  const getAchievementText = (status: ActionStatus) => {
    switch (status) {
      case 'inActive':
        return '未出現'
      case 'notYet':
        return '未着手'
      case 'achieve':
        return '達成'
    }
  }

  return (
    <>
      <div className={classes.items}>{achieveAt}</div>
      <div className={classes.items}>{title}</div>
      <div className={classes.items}>{selfScore}</div>
      <div className={classes.items}>{getAchievementText(status)}</div>
    </>
  )
}

const SortList: React.FC<{
  parent: string
  items: readonly string[]
  sort: { column: Columns; order: Orders }
  setSort: React.Dispatch<SetStateAction<{ column: Columns; order: Orders }>>
  setVisibleSorts: React.Dispatch<SetStateAction<Array<Columns>>>
  classes: ClassNameMap
}> = ({ parent, items, sort, setSort, setVisibleSorts, classes }) => {
  const onsortClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentOrders = e.currentTarget.textContent
    const currentColumn = e.currentTarget.parentElement?.parentElement?.parentElement?.textContent?.slice(0, -4)
    if (!isTypeOrders(currentOrders) || !isTypeColumn(currentColumn)) return
    setSort({ column: currentColumn, order: currentOrders })
  }
  const onClose = () => setVisibleSorts([])

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.sortListRoot}>
        {items.map((item) => {
          const isChecked = sort.column === parent && sort.order === item
          return (
            <div
              key={`menuItem-${item}`}
              className={classNames([classes.sortListItemRoot, isChecked ? 'checked' : ''])}
              onClick={onsortClick}
            >
              {item}
            </div>
          )
        })}
      </div>
    </ClickAwayListener>
  )
}

const FiltersList: React.FC<{
  items: string[]
  filters: string[]
  setFilters: React.Dispatch<SetStateAction<string[]>>
  setVisibleFilter: React.Dispatch<SetStateAction<boolean>>
  classes: ClassNameMap
}> = ({ items, filters, setFilters, setVisibleFilter, classes }) => {
  const onFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const key: string = e.currentTarget.textContent ?? ''
    if (!items.includes(key)) return
    if (filters.includes(key)) {
      setFilters((prev) => prev.filter((item) => item !== key))
    } else {
      setFilters((prev) => [...prev, key])
    }
  }
  const onClose = () => setVisibleFilter(false)

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.filtersListRoot}>
        {items.map((item) => {
          const isChecked = filters.includes(item)
          return (
            <div
              key={`menuItem-${item}`}
              className={classNames([classes.filterListItemRoot, isChecked ? 'checked' : ''])}
              onClick={onFilterClick}
            >
              {item}
            </div>
          )
        })}
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontWeight: 'bold',
    },
    SPViewportWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        overflowX: 'scroll',
      },
    },
    actionsWrapper: {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '3fr 3fr 2fr 3fr',
      margin: '30px 0 0',
      [theme.breakpoints.down('xs')]: {
        margin: '60px 0 0',
        position: 'static',
        width: '100%',
        minWidth: 600,
      },
    },
    titleTextWrapper: {
      fontSize: 12,
      fontWeight: 'bold',
      fontColor: constants.TEXT_GRAY_DARK,
      borderBottom: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      padding: '0 0 16px 0 ',
      margin: '0 0 18px 0 ',
    },
    titleText: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
        padding: '13px 6px 8px 11px',
        margin: '0 4px 16px 4px',
        backgroundColor: constants.COLOR_ONBOARDING_WHITE,
      },
    },
    arrowWrapper: {
      position: 'relative',
      margin: '0 0 0 8px',
    },
    arrowImg: {
      display: 'block',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    filterWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      [theme.breakpoints.down('xs')]: {
        top: 70,
        right: 90,
      },
    },
    filterImg: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    //for SortList
    sortListRoot: {
      position: 'absolute',
      top: 30,
      right: -40,
      width: 100,
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: '0px 3px 6px #00000029',
      zIndex: 1000,
      [theme.breakpoints.down('xs')]: {
        right: 0,
      },
    },
    sortListItemRoot: {
      padding: '14px 0 14px 37px',
      fontSize: 14,
      fontWeight: 'normal',
      '&.checked': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:before': {
          content: '" "',
          backgroundImage: `url(${checkedIcon})`,
          display: 'inline-block',
          height: '1rem',
          width: '1rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
    },
    //for FiltersList
    filtersListRoot: {
      position: 'absolute',
      top: 30,
      right: 0,
      width: 200,
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: '0px 3px 6px #00000029',
      overflow: 'hidden',
      zIndex: 1000,
    },
    filterListItemRoot: {
      padding: '14px 0 14px 37px',
      fontSize: 14,
      fontWeight: 'normal',
      '&.checked': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:before': {
          content: '" "',
          backgroundImage: `url(${checkedIcon})`,
          display: 'inline-block',
          height: '1rem',
          width: '1rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
    },
    // for ActionStatusItem
    items: {
      margin: '0 8px 12px 0',
      fontColor: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
    },
  }),
  { name: 'ActionStatusBox' }
)
