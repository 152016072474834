import { useState, useEffect, useCallback } from 'react'

import { createClient } from 'microcms-js-sdk'

const client = createClient({
  serviceDomain: 'cocolabo',
  apiKey: process.env.REACT_APP_MICROCMS_API_KEY ?? '',
})

export type Content = {
  title: string
  body: string
  date: string
}

export const useNotification = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [notifications, setNotifications] = useState<Content[]>([])
  const [error, setError] = useState<Error | null>(null)

  /**
   * microCMS の仕様で、一度に取得できるデータは最大 100 件まで。
   * 101件以上のデータがある場合、リクエストを分割して取得する必要がある。
   *
   * 参照
   * https://help.microcms.io/ja/knowledge/fetch-big-data
   */
  const getAllContents = useCallback(async (limit = 100, offset = 0, allContents = []): Promise<Content[]> => {
    try {
      const res = await client.getList<Content>({
        endpoint: 'notification',
        queries: { limit, offset, orders: '-date' }, // orders は - を付けることで降順になる
      })

      allContents.push(...res.contents)

      if (allContents.length < res.totalCount) {
        // まだ全てのデータを取得していない場合、再帰呼び出し
        return getAllContents(res.limit, res.offset + res.limit, allContents)
      } else {
        return allContents
      }
    } catch (err) {
      setError(err as Error)
      return allContents
    }
  }, [])

  useEffect(() => {
    const fetchContents = async () => {
      const allContents = await getAllContents()

      setNotifications(allContents)
      setIsLoading(false)
    }

    fetchContents()
  }, [getAllContents])

  return { isLoading, notifications, error }
}

export const useNotificationLatest = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [notification, setNotification] = useState<Content>()
  const [error, setError] = useState<Error | null>(null)

  const getLatestContent = useCallback(async () => {
    try {
      const res = await client.getList<Content>({
        endpoint: 'notification',
        queries: { limit: 1, orders: '-date' },
      })

      if (res.contents[0]) {
        const today = new Date()
        const oneMonthAgo = new Date().setMonth(today.getMonth() - 1)

        // 1ヶ月以内のデータのみ取得し、それよりも過去日だったら無視する。
        if (new Date(res.contents[0].date) > new Date(oneMonthAgo)) {
          setNotification(res.contents[0])
        }
      }
    } catch (err) {
      setError(err as Error)
    }
  }, [])

  useEffect(() => {
    const fetchContent = async () => {
      await getLatestContent()
      setIsLoading(false)
    }

    fetchContent()
  }, [getLatestContent])

  return { isLoading, notification, error }
}
