import * as React from 'react'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'

interface IOwnProps {}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, children }) => {
  return <div className={classes.container}>{children}</div>
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    },
  })

export default withStyles(useStyles)(Index)
