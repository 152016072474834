import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// -----------------------------
// interface
// -----------------------------
export type IuseCategory = {
  getResultCategory: () => string
}

// -----------------------------
// hooks
// -----------------------------
export const useResult = (): IuseCategory => {
  const location = useLocation()
  const [_resultCategory, _setResultCategory] = useState<string>('')

  useEffect(() => {
    setResultCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  /**
   * 現在のカテゴリの文字列をセットする
   */
  const setResultCategory = (): void => {
    _setResultCategory(location.pathname.split('/').slice(5)[0])
  }

  /**
   * 現在のカテゴリの文字列を取得する
   */
  const getResultCategory = (): string => {
    return _resultCategory
  }

  return {
    getResultCategory,
  }
}
