import React from 'react'

import { useMaintain } from '../hooks/maintain'

interface Props {}

// export const useMaintainContext = () => {
//   const { maintain, loaded } = useMaintain()

//   return {
//     maintain,
//     loaded,
//   }
// }

export const MaintainContext = React.createContext<Props>({} as Props)

export const MaintainContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { maintain, loaded } = useMaintain()

  if (!loaded) {
    return <></>
  }

  return (
    <MaintainContext.Provider value={{}}>
      {maintain && maintain.publicRange === 1 && maintain.title && maintain.timing && maintain.description ? (
        <>
          <div className="flex flex-col justify-center items-center w-screen h-screen">
            <div className="mb-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="337" height="42" viewBox="0 0 337 42">
                <g id="レイヤー_2" data-name="レイヤー 2" transform="translate(0 0)">
                  <g id="レイヤー_1" data-name="レイヤー 1" transform="translate(0 0)">
                    <path
                      id="Path_115"
                      data-name="Path 115"
                      d="M90.906,41.721c-11.549,0-21.5-7.836-21.5-20.61S79.357.5,90.906.5c5.151,0,10.37,1.449,13.719,3.841a.525.525,0,0,1,.234.45v5.871a.548.548,0,0,1-.308.491.563.563,0,0,1-.583-.052A19.553,19.553,0,0,0,91.729,6.953c-7.944,0-13.819,5.268-13.819,14.157s5.875,14.157,13.819,14.157a19.553,19.553,0,0,0,12.239-4.148.563.563,0,0,1,.583-.052.548.548,0,0,1,.308.491v5.86a.536.536,0,0,1-.234.45C101.275,40.272,96.057,41.721,90.906,41.721Z"
                      transform="translate(7.638 0.056)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_116"
                      data-name="Path 116"
                      d="M120.912,41.375c-9.346,0-16.222-6.742-16.222-15.488S111.566,10.4,120.912,10.4s16.155,6.809,16.155,15.488S130.191,41.375,120.912,41.375Zm0-25.3c-5.207,0-8.612,4.005-8.612,9.813s3.471,9.813,8.612,9.813,8.6-3.939,8.6-9.813-3.46-9.813-8.6-9.813Z"
                      transform="translate(11.522 0.602)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_117"
                      data-name="Path 117"
                      d="M152.131,41.082c-8.678,0-15.221-6.275-15.221-15.287s6.542-15.154,15.221-15.154a17.8,17.8,0,0,1,10.091,2.7.534.534,0,0,1,.256.467v4.573a.545.545,0,0,1-.868.445,13.218,13.218,0,0,0-7.788-2.314,8.834,8.834,0,0,0-9.146,9.279c0,5.674,3.805,9.413,9.146,9.413a12.918,12.918,0,0,0,7.788-2.348.556.556,0,0,1,.879.445v4.606a.556.556,0,0,1-.245.467,17.379,17.379,0,0,1-10.114,2.7Z"
                      transform="translate(15.076 0.628)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_118"
                      data-name="Path 118"
                      d="M179.232,41.375c-9.346,0-16.222-6.742-16.222-15.488S169.886,10.4,179.232,10.4s16.155,6.809,16.155,15.488S188.511,41.375,179.232,41.375Zm0-25.3c-5.207,0-8.612,4.005-8.612,9.813s3.471,9.813,8.612,9.813,8.545-3.939,8.545-9.813-3.4-9.813-8.545-9.813Z"
                      transform="translate(17.941 0.602)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_119"
                      data-name="Path 119"
                      d="M196.67,40.217V1.828a.552.552,0,0,1,.556-.548H203.9a.552.552,0,0,1,.556.548V40.217a.552.552,0,0,1-.556.548h-6.676A.552.552,0,0,1,196.67,40.217Z"
                      transform="translate(21.683 0.144)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_120"
                      data-name="Path 120"
                      d="M232.9,39.757l-.145-2.626a.556.556,0,0,0-.968-.323,13.351,13.351,0,0,1-9.869,4.272c-7.788,0-13.552-6.542-13.552-15.221s5.741-15.221,13.552-15.221a13.351,13.351,0,0,1,9.869,4.272.556.556,0,0,0,.968-.334l.145-2.615a.567.567,0,0,1,.556-.523h5.93a.556.556,0,0,1,.556.556V39.724a.556.556,0,0,1-.556.556h-5.93a.567.567,0,0,1-.556-.523Zm-8.578-23.365c-4.539,0-8.211,3.538-8.211,9.479s3.672,9.479,8.211,9.479,8.211-3.538,8.211-9.479-3.672-9.491-8.211-9.491Z"
                      transform="translate(22.935 0.629)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_121"
                      data-name="Path 121"
                      d="M261.014,41.566a13.44,13.44,0,0,1-9.869-4.213.562.562,0,0,0-.59-.154.55.55,0,0,0-.378.472l-.145,2.589a.564.564,0,0,1-.556.516h-5.93a.552.552,0,0,1-.556-.549V1.829a.552.552,0,0,1,.556-.549h6.676a.552.552,0,0,1,.556.549V14.8a.537.537,0,0,0,.343.508.551.551,0,0,0,.6-.135,13.455,13.455,0,0,1,9.335-3.62c7.788,0,13.552,6.451,13.552,15.009S268.825,41.566,261.014,41.566ZM258.578,17.21c-4.539,0-8.211,3.489-8.211,9.347s3.672,9.347,8.211,9.347,8.211-3.489,8.211-9.347S263.151,17.21,258.578,17.21Z"
                      transform="translate(26.663 0.144)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_122"
                      data-name="Path 122"
                      d="M290.642,41.375c-9.346,0-16.222-6.742-16.222-15.488S281.307,10.4,290.642,10.4,306.8,17.209,306.8,25.888,299.932,41.375,290.642,41.375Zm0-25.3c-5.207,0-8.612,4.005-8.612,9.813S285.5,35.7,290.642,35.7s8.545-3.939,8.545-9.813-3.393-9.813-8.545-9.813Z"
                      transform="translate(30.203 0.602)"
                      fill="#1e1717"
                    />
                    <path
                      id="Path_123"
                      data-name="Path 123"
                      d="M41.348,20.381a21.14,21.14,0,0,1-9.557,16.2,21.774,21.774,0,0,1-5.741-5.563,13.652,13.652,0,0,0,7.61-10.759.623.623,0,0,1,.623-.579h6.409a.656.656,0,0,1,.656.7Z"
                      transform="translate(2.934 1.648)"
                      fill="#f2c81d"
                    />
                    <path
                      id="Path_124"
                      data-name="Path 124"
                      d="M7.677,20.978c0,7.4,6.5,13.425,14.464,13.425a15.311,15.311,0,0,0,6.82-1.592A21.645,21.645,0,0,0,34.7,38.3,22.908,22.908,0,0,1,22.141,42C9.925,41.978,0,32.57,0,20.978S9.925,0,22.163,0A22.907,22.907,0,0,1,34.725,3.71a20.744,20.744,0,0,1,9.557,15.983.643.643,0,0,1-.184.472.661.661,0,0,1-.472.2H37.217a.631.631,0,0,1-.623-.582,13.445,13.445,0,0,0-7.6-10.626,15.345,15.345,0,0,0-6.831-1.581C14.175,7.574,7.677,13.579,7.677,20.978Z"
                      transform="translate(0 0)"
                      fill="#f2c81d"
                    />
                    <path
                      id="Path_125"
                      data-name="Path 125"
                      d="M66.895,20.989c0,11.592-9.913,20.989-22.152,20.989a22.874,22.874,0,0,1-12.561-3.7,21.644,21.644,0,0,1-5.741-5.489,20.077,20.077,0,0,1,0-23.656,13.445,13.445,0,0,1,7.61,10.626.631.631,0,0,0,.623.582h6.409a.661.661,0,0,0,.472-.2.643.643,0,0,0,.184-.472A20.745,20.745,0,0,0,32.181,3.71,22.8,22.8,0,0,1,44.743,0C56.981,0,66.895,9.408,66.895,20.989Z"
                      transform="translate(2.544 0)"
                      fill="#2eb1ba"
                      style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h1 className="text-center text-2xl font-bold">{maintain.title}</h1>
            <dl className="text-center text-lg font-bold mt-4">
              <dt>【メンテナンス日時】</dt>
              <dd className="m-0 mt-2 text-red-600">{maintain.timing}</dd>
            </dl>
            <p className="text-center mt-4 whitespace-pre-wrap">{maintain.description}</p>
          </div>
        </>
      ) : (
        children
      )}
    </MaintainContext.Provider>
  )
}
