import React, { useContext, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Button } from '@material-ui/core'
import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'


import { SummaryBox } from 'pages/onboarding/components/'
import { MessageInput } from 'pages/onboarding/components/message-input-new'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { mutationDeleteOnboardingPostPost } from 'pages/onboarding/graphql'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useOnboardingPost, useOnboardingPostComment, useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPostType, OnboardingTeamMemberRole } from 'utils/generated'

import { OnbContext } from '../PagesRoot'

import { CommentListItem, Likes, UsefulModal, Card } from './components'

import MoreHorizontalSvg from './assets/more-horizontal.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

export const TimelinePostBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)
    const { postId } = useParams<{ postId: string }>()
    const { post } = useOnboardingPost(postId)
    const { postComments } = useOnboardingPostComment(postId)

    return {
      teamId,
      teamMember,
      teamMembers,
      postId,
      post,
      postComments,
    }
  },
}

const Index: React.FC<WithStyles<typeof useStyles>> = ({ classes }) => {
  // deps
  const { teamId, teamMember, teamMembers, postId, post, postComments } = TimelinePostBloc.useAdapter()

  // BL
  const { createComment } = useOnboardingPostActions()

  // UI control & events
  const [open, setOpen] = useState(false)
  const [modalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [allEditClose, setAllEditClose] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((open) => !open)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }
  const handleIsEdit = () => {
    setIsEdit((isEdit) => !isEdit)
  }
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async () => {
    toggleModal()
    await mutationDeleteOnboardingPostPost({ id: postId })
    history.push(OnbPages.Timeline, { teamId })
  }

  const history = useOnbHistory()
  const { state } = useLocation<{ isFocus: boolean } | undefined>()

  return (
    <div className={classes.container}>
      <TitleWithBack
        title="投稿詳細"
        rightItem={
          post && post.type === OnboardingPostType.Post && post.userId === teamMember?.userId ? (
            <>
              <div
                className={classes.actionEdit}
                aria-label="dropdown-list"
                ref={anchorRef}
                aria-controls={open ? 'dropdown-list-grow' : undefined}
                aria-haspopup="true"
              >
                <img src={MoreHorizontalSvg} alt="more-horizontal" onClick={handleToggle} />
              </div>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-end"
                style={{ marginTop: 2 }}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper className={classes.paper} elevation={3} classes={{ elevation3: classes.elevation3 }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="menu-list-grow">
                          <MenuItem className={classes.menu} onClick={handleIsEdit}>
                            <span className={classes.menuLabel}>投稿を編集する</span>
                          </MenuItem>
                          <MenuItem className={classes.menu} onClick={toggleModal}>
                            <span className={classes.menuLabelDelete}>投稿を削除する</span>
                          </MenuItem>
                          <div>
                            <UsefulModal open={modalOpen} handleModalChange={toggleModal}>
                              <div className={classes.iconWrapper}>
                                <img
                                  className={classes.icon}
                                  src={process.env.PUBLIC_URL + '/assets/svg/close.svg'}
                                  onClick={toggleModal}
                                  alt={'close'}
                                />
                              </div>
                              <img src={process.env.PUBLIC_URL + '/assets/svg/exclamationTeam.svg'} alt="注意" />
                              <div className={classes.modalText}>投稿を削除してもよろしいですか?</div>
                              <div className={classes.modalContainer}>
                                <div>
                                  <Button onClick={handleDelete} fullWidth={true} className={classes.button}>
                                    はい
                                  </Button>
                                </div>
                                <Button onClick={closeModal} className={classes.buttonCancel} fullWidth={true}>
                                  いいえ
                                </Button>
                              </div>
                            </UsefulModal>
                          </div>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          ) : (
            <></>
          )
        }
      />

      {post ? (
        <div className={classes.list}>
          <div className={classes.contentContainer}>
            {/* render different components from postType===Summary and others */}
            {post.type === OnboardingPostType.Ranking ? (
              <SummaryBox renderType={'detail'} post={post} teamId={teamId} />
            ) : (
              <Card
                post={post}
                teamMembers={teamMembers}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                allEditClose={allEditClose}
                onAvatar={() => {
                  teamMember.role === OnboardingTeamMemberRole.Member
                    ? history.push(OnbPages.MemberManageActions, { teamId, userId: post.userId })
                    : history.push(OnbPages.TeamMember, { teamId, userId: post.userId })
                }}
              />
            )}
            <Likes post={post} teamId={teamId} />
            <div
              className={classes.containerBorder}
              style={{
                marginTop: 16,
              }}
            ></div>

            {/* comments */}

            {post.type !== 'RANKING' && (
              <>
                {postComments.length ? (
                  <div className={classes.commentsCount}>{`コメント ${postComments.length}件`}</div>
                ) : (
                  <></>
                )}
                {postComments.map((comment, index) => (
                  <CommentListItem
                    teamMembers={teamMembers}
                    key={index}
                    teamId={teamId}
                    item={comment}
                    userId={teamMember?.userId}
                    first={index === 0}
                    allEditClose={allEditClose}
                    setAllEditClose={setAllEditClose}
                  />
                ))}
                {postComments.length > 0 ? (
                  <div
                    className={classes.containerBorder}
                    style={{
                      marginTop: 24,
                    }}
                  ></div>
                ) : (
                  <></>
                )}
                <div className={classes.msgInput}>
                  <MessageInput
                    teamId={teamId}
                    onSend={(text, mentions) => createComment(post.id, text, teamId, mentions)}
                    imageUrl={teamMember?.imageUrl ?? ''}
                    teamMembers={teamMembers}
                    isFocus={state?.isFocus ?? false}
                    setAllEditClose={setAllEditClose}
                    postId={post.id}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      marginBottom: 34,
    },
    actionEdit: {
      width: 42,
      height: 42,
      borderRadius: '50%',
      cursor: 'pointer',
      '& img': {
        verticalAlign: 'top',
        width: '100%',
      },
    },
    paper: {
      borderRadius: 8,
      width: 170,
    },
    elevation3: {
      boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
    },
    menu: {
      paddingLeft: 37,
    },
    menuLabel: {
      fontSize: 14,
      color: constants.TEXT_GRAY_DARK,
    },
    menuLabelDelete: {
      fontSize: 14,
      color: constants.COLOR_ONBOARDING_ALERT,
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 16,
      paddingRight: 16,
    },
    contentContainer: {
      backgroundColor: constants.COLOR_WHITE,
      padding: 16,
    },
    containerBorder: {
      marginLeft: -16,
      marginRight: -16,
      borderTopColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
    },
    commentsCount: {
      marginTop: 16,
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },
    msgInput: {
      marginTop: 16,
    },
    modalContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    modalText: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      margin: '16px 0 24px 0',
    },
    button: {
      width: 145,
      color: constants.COLOR_MAIN_NEW,
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 8,
    },
    buttonCancel: {
      width: 145,
      color: constants.COLOR_GRAY_DARK,
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 'bold',
      border: `1px solid ${constants.COLOR_GRAY_DARK}`,
      borderRadius: 8,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'right',
    },
    icon: {
      cursor: 'pointer',
      top: 319,
      left: 783,
      width: 16,
      height: 16,
    },
  })

export default withStyles(useStyles)(Index)
