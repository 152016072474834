import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import Slider from '@material-ui/core/Slider'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'
import { SelfCheckComment } from '../../../utils/generated'

import { connector, ContainerProps } from './index.container'

// char values
const marks = [
  [
    {
      value: 1,
      label: ' ',
    },
    {
      value: 2,
      label: '方向性浸透',
    },
    {
      value: 3,
      label: 'ボトムアップ',
    },
    {
      value: 4,
      label: '個別化対応',
    },
    {
      value: 5,
      label: '良い影響力',
    },
    {
      value: 6,
      label: '振り返り機会',
    },
    {
      value: 7,
      label: '関係性（チーム内）',
    },
    {
      value: 8,
      label: '関係性（チーム外）',
    },
    {
      value: 9,
      label: ' ',
    },
  ],
]

const marksVirtical = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 13,
    label: '13',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 17,
    label: '17',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 19,
    label: '19',
  },
  {
    value: 20,
    label: '20',
  },
]

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [state, setState] = React.useState<number[][]>([])
  const [max, setMax] = React.useState<number[]>([])
  const [min, setMin] = React.useState<number[]>([])
  const [selfCheckComments, setSelfCheckComments] = React.useState<SelfCheckComment[]>([])

  const handleStartPush = (url?: string | undefined) => {
    if (url) {
      props.history.push(url)
      window.scrollTo(0, 0)
    }
  }
  const setUrl = (url: String | undefined) => {
    if (url !== undefined) {
      const push = url.replace('https://cocolabo.club', '')
      return push
    }
  }
  const setButtonValue = (url: string) => {
    let name

    switch (url) {
      case 'https://cocolabo.club/karte':
        name = 'カルテを見る'
        break

      case 'https://cocolabo.club/setup':
        name = 'セットアップを見る'
        break

      case 'https://cocolabo.club/team':
        name = 'チームを見る'
        break

      default:
        name = ''
        break
    }

    return name
  }
  //fetchResults
  React.useEffect(() => {
    if (!props.selfCheck.fetched) {
      props.fetch(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selfCheck.fetched])
  //fetchComments
  React.useEffect(() => {
    props.fetchSelfCheckComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //setCommnetsList
  React.useEffect(() => {
    if (props.selfCheck.comments) {
      let commentsList = props.selfCheck.comments.items

      if (commentsList !== undefined) {
        commentsList = commentsList?.sort((a, b) => {
          if (a?.id !== undefined && b?.id !== undefined) {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          }
          return 0
        })
        setSelfCheckComments(commentsList as SelfCheckComment[])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selfCheck.comments])
  React.useEffect(() => {
    const rangeResults: number[][] = []
    if (props.selfCheck.result) {
      if (props.selfCheck.result?.data !== undefined && props.selfCheck.result?.data !== null) {
        const parseResult = JSON.parse(props.selfCheck.result.data)
        if (parseResult.length === 0) return
        const historyNum = props.selfCheck.currentHistory
        const resultData = parseResult.slice(historyNum, historyNum + 3)

        resultData.forEach((item: any, index: any) => {
          let keys = Object.keys({ ...item })
          keys = keys.filter((word) => word !== 'createAt')
          const value = keys.map((key) => item[key])

          rangeResults.push(value)

          if (index === 0) {
            //max
            const max = Math.max(...value)
            const newMax = value.flatMap((s, i) => (s === max ? i : []))
            setMax(newMax)
            // min
            const min = Math.min(...value)
            const newMin = value.flatMap((s, i) => (s === min ? i : []))
            setMin(newMin)
          }
        })
      }
      setState(rangeResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selfCheck.result])

  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && true && (
          <>
            <div className="__container">
              <h1 className="__title">セルフチェック</h1>

              <div className="__content">
                <div className="__baloon">
                  <p className="__txt">チームのエンゲージメントを高める上で、あなたの傾向は以下の通りです。</p>
                </div>

                {selfCheckComments.length !== 0 && (
                  <div className="__answer">
                    <h2 className="__title">あなたの強み</h2>

                    {max.map((item: any, index: any) => (
                      <div className="__card" key={'answerGood' + index}>
                        <p>{selfCheckComments[item].titleGoodFirst}</p>
                        <div className="__title">
                          <h2 className="__txt">{selfCheckComments[item].head}</h2>
                        </div>
                        {selfCheckComments[item].messageGood && <p>{selfCheckComments[item].messageGood}</p>}
                      </div>
                    ))}
                  </div>
                )}
                <div className="__slider">
                  <QuestionnaireSlider
                    name="member"
                    defaultValue={0}
                    setValue={0}
                    register={0}
                    state={state}
                    comments={selfCheckComments}
                  />
                  <ul className="__list">
                    <li className="__item">今回</li>
                    <li className="__item">前回</li>
                    <li className="__item">前々回</li>
                  </ul>
                </div>

                {selfCheckComments.length !== 0 && (
                  <div className="__answer">
                    {min?.map((item, index) => (
                      <div className="__inner" key={'answerBad' + index}>
                        <h2 className="__title">あなたの課題</h2>
                        <div className="__card">
                          {index === 0 && <p>あなたには以下の傾向が強い可能性があります。</p>}
                          <div className="__title">
                            <h2 className="__txt">{selfCheckComments[item].titleBad}</h2>
                          </div>
                          <p>{selfCheckComments[item].messageBad}</p>
                        </div>

                        <div className="">
                          <h2 className="__title">アドバイス</h2>

                          <div className="__card">
                            <p>
                              <span className="__number">1.</span>
                              {selfCheckComments[item].adviceFirst}
                            </p>

                            {selfCheckComments[item].urlFirst && (
                              <div className="__buttons">
                                <Button
                                  bgColor={constants.COLOR_BLUE}
                                  textColor={constants.COLOR_WHITE}
                                  fullWidth={true}
                                  body={<div>{setButtonValue(selfCheckComments[item].urlFirst as string)}</div>}
                                  onClick={() => handleStartPush(setUrl(selfCheckComments[item].urlFirst as string))}
                                />
                              </div>
                            )}
                          </div>

                          <div className="__card">
                            <p>
                              <span className="__number">2.</span>
                              {selfCheckComments[item].adviceSecond}
                            </p>

                            {selfCheckComments[item].urlSecond && (
                              <div className="__buttons">
                                <Button
                                  bgColor={constants.COLOR_GREEN_DARK}
                                  textColor={constants.COLOR_WHITE}
                                  fullWidth={true}
                                  body={<div>{setButtonValue(selfCheckComments[item].urlSecond as string)}</div>}
                                  onClick={() => handleStartPush(setUrl(selfCheckComments[item].urlSecond as string))}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="__link">
                  <p className="__txt" onClick={() => handleStartPush(pages.SELFCHECK)}>
                    セルフチェックTOPページへ戻る
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

interface IQuestionnaireSliderProps {
  name: string
  defaultValue?: number
  setValue: any
  register: any
  state: number[][]
  comments: SelfCheckComment[]
}
const QuestionnaireSlider: React.FC<IQuestionnaireSliderProps> = (props) => {
  return (
    <div className="__slider__values__value">
      <div className="__slider__values__value__label">
        <span className="__slider__values__value__vertical">
          <Slider
            disabled
            orientation="vertical"
            defaultValue={20}
            aria-labelledby="vertical-slider"
            step={1}
            min={0}
            max={20}
            marks={marksVirtical}
            classes={{
              thumb: '__thumb',
              track: '__track',
              rail: '__rail',
              mark: '__mark',
              markLabel: '__markLabel',
              vertical: '__vertical',
            }}
          />
        </span>
        {props.comments.map((item, idx) => (
          <span className="__slider__values__value__slidebar" key={'resultTxt' + idx}>
            {props.state &&
              props.state.map((item, index) => (
                <React.Fragment key={'chart' + index}>
                  <Slider
                    disabled
                    valueLabelDisplay="auto"
                    orientation="vertical"
                    value={item[idx]}
                    aria-labelledby="vertical-slider"
                    step={1}
                    min={0}
                    max={20}
                    classes={{
                      thumb: '__thumb',
                      track: '__track',
                      rail: '__rail',
                      mark: '__mark',
                      markLabel: '__markLabel',
                      vertical: '__vertical',
                    }}
                  />
                </React.Fragment>
              ))}
          </span>
        ))}
        <span></span>
      </div>
      <div className="__slider__values__value__slider">
        <Slider
          disabled
          defaultValue={9}
          aria-labelledby={props.name}
          valueLabelDisplay="off"
          step={1}
          marks={marks[0]}
          min={1}
          max={9}
          classes={{
            root: '__root',
            thumb: '__thumb',
            track: '__track',
            rail: '__rail',
            mark: '__mark',
            markLabel: '__markLabel',
          }}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        boxSizing: 'border-box',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__textcenter': {
          textAlign: 'center',
          '& .__txt': {
            color: constants.COLOR_MAIN,
            borderBottom: `1px solid ${constants.COLOR_MAIN}`,
          },
        },
        '& .__title': {
          textAlign: 'center',
        },
        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '40px 16px 40px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '& .__baloon': {
            backgroundColor: constants.COLOR_WHITE,
            maxWidth: 315,
            width: '100%',
            border: '1px solid #C9C9C9',
            borderRadius: '20px',
            padding: '20px 20px',
            marginLeft: '16px',
            marginBottom: '30px',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 58,
              left: -13,
              width: 7,
              height: 0,
              margin: 'auto',
              borderStyle: 'solid',
              borderColor: 'transparent transparent #C9C9C9 transparent',
              borderWidth: '0 10px 17px 0',
              transform: 'rotate(-95deg)',
            },
            '&:after': {
              content: '""',
              position: 'absolute',
              top: 57,
              left: -12,
              width: 0,
              height: 0,
              margin: 'auto',
              borderStyle: 'solid',
              borderColor: 'transparent transparent #fff transparent',
              borderWidth: '0 10px 18px 0',
              transform: 'rotate(-97deg)',
              zIndex: 10,
            },
            '& .__txt': {
              margin: 0,
            },
          },

          '& .__answer': {
            backgroundColor: constants.COLOR_WHITE,
            borderTop: `1px dashed ${constants.COLOR_MAIN}`,
            padding: '20px 16px 30px',
            marginBottom: '30px',
            '& .__inner': {
              paddingTop: '20px',
              paddingBottom: '20px',
              borderBottom: `1px dashed ${constants.COLOR_MAIN}`,
            },
            '& div:first-child': {
              paddingTop: 0,
            },
            '& div:last-child': {
              borderBottom: 'unset',
            },
            '& .__title': {
              fontSize: '20px',
              color: constants.COLOR_MAIN,
              backgroundColor: constants.COLOR_WHITE,
              margin: 0,
              marginBottom: 20,
              textAlign: 'left',
              '& .__txt': {
                display: 'inline-block',
                borderBottom: `5px solid ${constants.COLOR_MAIN}`,
              },
            },
            '& .__card': {
              backgroundColor: '#F7F7F7',
              padding: '20px 17px',
              marginBottom: 10,
              '& .__title': {
                border: '1px dashed ' + constants.COLOR_MAIN,
                textAlign: 'center',
                '& .__txt': {
                  fontSize: 16,
                },
              },
              '& .__number': {
                color: constants.COLOR_MAIN,
              },
            },
          },

          '& .__slider': {
            marginBottom: 40,
            '&__values': {
              '&__title': {
                paddingBottom: '2px',
                fontWeight: 'bold',
                borderBottom: `1px solid ${constants.COLOR_MAIN}`,
              },
              '&__value': {
                marginBottom: 110,
                '&__label': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '16px 14px 0 40px',
                  '& span': {
                    fontSize: '8px',
                  },
                },
                '&__slidebar': {
                  display: 'flex',
                  marginLeft: -8,
                  '& .__vertical': {
                    '& .__thumb': {
                      backgroundColor: 'unset',
                    },
                    '& .__track': {
                      width: 10,
                    },
                    '&:nth-of-type(1) .__track': {
                      backgroundColor: constants.COLOR_BLUE,
                    },
                    '&:nth-of-type(2) .__track': {
                      backgroundColor: constants.COLOR_ORANGE_DARK,
                    },
                    '& .__rail': {
                      // height: 2,
                      backgroundColor: 'unset',
                      opacity: 1,
                    },
                    '& .__mark': {
                      backgroundColor: 'black',
                      height: 2,
                      width: '100%',
                    },
                    '& .__markLabel': {
                      writingMode: 'unset',
                    },
                  },
                },
                '&__slider': {
                  padding: '0 16px 40px 40px',
                },
                '& .__root': {
                  padding: 0,
                },
                '& .__thumb': {
                  height: 20,
                  width: 20,
                  backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                  marginTop: -10,
                  marginLeft: -14,
                },
                '& .__track': {
                  width: 10,
                  color: constants.COLOR_GRAY_LIGHT_DARK,
                },
                '& .__rail': {
                  // height: 2,
                  opacity: 1,
                  backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                },
                '& .__mark': {
                  backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                  height: 2,
                  width: 2,
                },
                '& .__markLabel': {
                  fontSize: '15px',
                  fontWeight: 'bold',
                  color: constants.COLOR_MAIN,
                  left: -35,
                  writingMode: 'vertical-rl',
                },
                '& .__vertical': {
                  height: 400,
                  padding: 0,
                  marginRight: 13,
                  marginBottom: -3,
                  '& .__thumb': {
                    marginLeft: -8,
                    backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                  },
                  '& .__track': {
                    width: 5,
                  },
                  '& .__rail': {
                    // height: 2,
                    opacity: 1,
                    backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                  },
                  '& .__mark': {
                    backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                    height: 2,
                    width: 17,
                    marginLeft: -7,
                  },
                  '& .__markLabel': {
                    writingMode: 'unset',
                  },
                },
              },
            },
            '& .__list': {
              display: 'flex',
              justifyContent: 'space-evenly',
              '& .__item': {
                listStyle: 'none',
                '&:before': {
                  content: '""',
                  width: 10,
                  height: 10,
                  display: 'inline-block',
                  borderRadius: '50%',
                  marginRight: 5,
                },
                '&:nth-of-type(1):before': {
                  backgroundColor: constants.COLOR_BLUE,
                },
                '&:nth-of-type(2):before': {
                  backgroundColor: constants.COLOR_ORANGE_DARK,
                },
                '&:nth-of-type(3):before': {
                  backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
                },
              },
            },
          },

          '& .__link': {
            textAlign: 'center',
            '& .__txt': {
              display: 'inline-block',
              color: constants.COLOR_MAIN,
              borderBottom: '1px solid ' + constants.COLOR_MAIN,
              cursor: 'pointer',
            },
          },

          '& .__buttons': {
            display: 'flex',
            marginTop: '8px',
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
