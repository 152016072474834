import * as React from 'react'

import { TextField, makeStyles, Theme, InputAdornment } from '@material-ui/core'

import * as constants from 'assets/constants'

interface Props {
  placeholder?: string
  borderColor?: string
  borderRadius?: number
  iconMarginLeft?: number
  iconMarginRight?: number
  onClick: () => void
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPressInput: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputValue: string
  inputRef?: React.RefObject<HTMLDivElement>
}

export const MessageInput: React.FC<Props> = ({
  placeholder,
  borderColor,
  borderRadius,
  onClick,
  onChangeInput,
  onKeyPressInput,
  inputValue,
  inputRef,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        border: borderColor ? `1px solid ${borderColor}` : 'none',
        borderRadius: borderRadius ?? 0,
      }}
    >
      <TextField
        type="search"
        className={classes.input}
        placeholder={placeholder ?? ''}
        value={inputValue}
        onChange={onChangeInput}
        onKeyPress={onKeyPressInput}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment className={classes.adorment} position="end">
              {inputValue ? (
                <span
                  className={classes.button}
                  onClick={() => {
                    onClick()
                  }}
                >
                  送信
                </span>
              ) : (
                <></>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 42,
    backgroundColor: constants.COLOR_WHITE,
    display: 'flex',
    alignItems: 'center',
    '& input[type="search"]::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  input: {
    width: '100%',
    fontSize: 14,
    paddingLeft: 16,
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  adorment: {
    marginRight: 16,
  },
  button: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    marginRight: 6,
    padding: 10,
  },
}))
