import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Flexbox, Font, LinearProgressWaiting } from '../atoms'

type Props = { ownStyles?: React.CSSProperties }

export const WaitingMessage: React.FC<Props> = ({ ownStyles }) => {
  return (
    <>
      <Flexbox
        flexDirection="column"
        ownStyles={{
          alignItems: 'center',
          ...ownStyles,
        }}
      >
        <Font fontSize={16} bold color={constGhost.COLOR_MAIN_NEW} ownStyles={{ letterSpacing: '0.1em' }}>
          PLEASE WAIT
        </Font>
        <LinearProgressWaiting />
      </Flexbox>
    </>
  )
}
