import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { CircularProgress, Modal, Fade, Backdrop, Grid } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { format, parseISO } from 'date-fns'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import AlertDialog from '../../../components/AlertDialog'
import Button from '../../../components/Button'
import SpeechBubble from '../components/SpeechBubble'
import { IKarte } from '../interface'

import { connector, ContainerProps } from './index.container'
import { getKarteList } from './questionnaire/api'
import { buildResults } from './results/utils/calc'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const [urlCopied, setUrlCopied] = React.useState(false)
  const [listModalState, setListModalState] = React.useState(false)
  const [karteList, setKarteList] = React.useState<{
    isFetching: boolean
    fetched: boolean
    records: IKarte[]
  }>({
    isFetching: false,
    fetched: false,
    records: [],
  })
  const [selectedKarteId, setSelectedKarteId] = React.useState<string | null>(null)

  const greeting = () => {
    // TODO: fetch greeting message
    return '診断結果を活かして​チーム力を向上していきましょう。'
  }

  const fetchURL = () => {
    return `https://${window.location.host}/karte/member/${props.user.id}`
  }

  const onPreview = () => {
    window.open(`https://${window.location.host}/karte/member/${props.user.id}`, '_blank')
  }

  const onClickForModal = () => {
    setListModalState(true)
  }

  const handleKarteLeader = () => {
    props.history.push(pages.KARTE_LEADER_PREPARE)
    window.scrollTo(0, 0)
  }

  const renderLoading = () => {
    if (!props.isLoading) return null
    return (
      <Modal
        open={props.isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disableBackdropClick={true}
      >
        <CircularProgress className={classes.loadingCircularProgress} />
      </Modal>
    )
  }

  const renderError = () => {
    if (!props.error) return null

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onClose = () => {}

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  const fetchKarteList = async (leaderId: string): Promise<void> => {
    setKarteList((current) => ({ ...current, isFetching: true }))
    const fetchedKarteList = await getKarteList({
      leaderId,
      first: -1,
      nextToken: null,
    })
    setKarteList({
      isFetching: false,
      fetched: true,
      records: fetchedKarteList.items,
    })
  }

  React.useEffect(() => {
    ;(async () => {
      if (props.user.id && !karteList.fetched) {
        await fetchKarteList(props.user.id)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, karteList.fetched])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onClick = () => {}

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__description">
            <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
            <div>
              <SpeechBubble>カルテへようこそ。</SpeechBubble>
              <SpeechBubble>{greeting()}</SpeechBubble>
            </div>
          </div>
          <div className="__container__content">
            <div className="__container__content__top">
              <span>
                リーダーのカルテはメンバーのカルテと照合して出力されます。
                <br />
                1on1する相手に診断してもらい（STEP1）、カルテNo.を聞いた上で、ご自身の診断（STEP2）を行ってください。
              </span>
            </div>

            <div className="__container__content__step">
              <span className="__container__content__step__title">STEP1</span>
              <span className="__container__content__step__description">
                1on1するメンバーにメンバー診断を実施してもらう。
              </span>
              <CopyToClipboard text={fetchURL()} onCopy={() => setUrlCopied(true)}>
                <Button
                  bgColor={constants.COLOR_BLUE}
                  textColor={constants.COLOR_WHITE}
                  fullWidth={true}
                  body={
                    <div>
                      <span className="__container__content__step__buttonLabel">案内ページのURLをコピー​する</span>
                      <FontAwesome name="copy" color={constants.COLOR_WHITE} size={16} />
                    </div>
                  }
                  onClick={onClick}
                />
              </CopyToClipboard>

              <Button
                bgColor={constants.COLOR_GRAY_LIGHT}
                textColor={constants.COLOR_BLACK}
                fullWidth={true}
                body={
                  <div>
                    <span className="__container__content__step__buttonLabel">案内ページをプレビュー</span>
                    <FontAwesome name="binoculars" color={constants.COLOR_BLACK} size={16} />
                  </div>
                }
                onClick={onPreview}
              />
            </div>

            <div className="__container__content__step">
              <span className="__container__content__step__title">STEP2</span>
              <span className="__container__content__step__description">
                メンバーにカルテNo.をもらった上で自身の診断を行う。
              </span>
              <Button
                bgColor={constants.COLOR_BLUE}
                textColor={constants.COLOR_WHITE}
                fullWidth={true}
                body={
                  <div>
                    <span className="__container__content__step__buttonLabel">リーダー診察室へ行く</span>
                    <FontAwesome name="stethoscope" color={constants.COLOR_WHITE} size={16} />
                  </div>
                }
                onClick={handleKarteLeader}
              />
              <Button
                bgColor={constants.COLOR_GRAY_LIGHT}
                textColor={constants.COLOR_BLACK}
                fullWidth={true}
                body={
                  <div>
                    <span className="__container__content__step__buttonLabel">過去の診断を見る</span>
                    <FontAwesome name="binoculars" color={constants.COLOR_BLACK} size={16} />
                  </div>
                }
                onClick={onClickForModal}
              />
            </div>
          </div>
        </div>
        {renderLoading()}
        {renderError()}
      </div>

      <Modal
        aria-labelledby="copied-modal"
        aria-describedby="url-copied"
        className={classes.copiedModal}
        open={urlCopied}
        onClose={() => setUrlCopied(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={urlCopied}>
          <div className="__paper">
            <div className="__paper__text">コピーされました</div>
            <Button
              bgColor={constants.COLOR_MAIN}
              textColor={constants.COLOR_WHITE}
              body={<div>閉じる</div>}
              onClick={() => {
                setUrlCopied(false)
              }}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="list-modal"
        aria-describedby="url-copied"
        className={classes.listModal}
        open={listModalState}
        onClose={() => setListModalState(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={listModalState}>
          <div className="__paper">
            <div
              className="__paper__closeButton"
              onClick={() => {
                setListModalState(false)
              }}
            >
              <FontAwesome name="times" color={constants.COLOR_GRAY_DARK} size={20} />
            </div>
            <div className="__paper__title">過去の診断</div>
            <Grid className="__paper__th" container spacing={0} justify="center">
              <Grid className="__paper__text" item xs={3}>
                メンバー名
              </Grid>
              <Grid className="__paper__text" item xs={3}>
                カルテNo.
              </Grid>
              <Grid className="__paper__text" item xs={3}>
                実施日
              </Grid>
              <Grid className="__paper__text" item xs={3}>
                結果
              </Grid>
            </Grid>
            <div className="__paper__data">
              {karteList.isFetching ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : karteList && karteList.records.length > 0 ? (
                karteList.records
                  .filter((karte) => (karte.name ? true : false))
                  .map((karte, index) => {
                    return (
                      <Grid
                        key={index}
                        className="__paper__td"
                        container
                        spacing={0}
                        justify="center"
                        style={
                          selectedKarteId === karte.id
                            ? { backgroundColor: constants.COLOR_GRAY }
                            : { backgroundColor: constants.COLOR_WHITE }
                        }
                        onClick={() => {
                          setSelectedKarteId(karte.id)
                        }}
                      >
                        <Grid className="__paper__textLeft" item xs={3}>
                          {karte.name}
                        </Grid>
                        <Grid className="__paper__text" item xs={3}>
                          {karte.id}
                        </Grid>
                        <Grid className="__paper__text" item xs={3}>
                          {typeof karte.createdAt === 'string' ? format(parseISO(karte.createdAt), 'yyyy/MM/dd') : '-'}
                        </Grid>
                        <Grid className="__paper__textRight" item xs={3}>
                          {typeof karte.leaderData === 'string'
                            ? buildResults(JSON.parse(karte.data), JSON.parse(karte.leaderData)).total
                            : '-'}
                          %
                        </Grid>
                      </Grid>
                    )
                  })
              ) : (
                <div className="__paper__textCenter">データがありません</div>
              )}
            </div>
            <div className="__paper__moreButton">
              <Button
                bgColor={constants.COLOR_BLUE}
                textColor={constants.COLOR_WHITE}
                body={<div>MORE</div>}
                fullWidth={true}
                onClick={() => {
                  if (typeof selectedKarteId === 'string') {
                    window.open(
                      `https://${window.location.host}/karte/leader/questionnaire/${selectedKarteId}/results`,
                      '_blank'
                    )
                    window.scrollTo(0, 0)
                  }
                }}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__description': {
          display: 'flex',
          padding: '0 16px',

          '& img': {
            width: 50,
            height: 50,
            marginRight: 16,
          },
        },

        '&__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          marginTop: 16,
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__top': {
            margin: '16px 0 32px 0',

            '& span': {
              display: 'block',
              fontSize: 14,
              marginBottom: 8,
            },
          },
          '&__step': {
            boxShadow: '0 1px 5px 0 rgba(21, 27, 38, 0.15)',
            padding: 16,
            margin: '8px 0',

            '& button': {
              height: 50,
              borderRadius: 50,
              marginBottom: 12,
            },

            '&__buttonLabel': {
              fontSize: 14,
              marginRight: 16,
            },

            '&__title': {
              color: constants.COLOR_BLUE_LIGHT,
              display: 'block',
              fontSize: 18,
              marginBottom: 8,
            },

            '&__description': {
              fontSize: 14,
              display: 'block',
              margin: '8px 0 24px 0',
            },
          },
        },
      },
    },

    blindInput: {
      background: 'transparent',
      border: 'none',
      color: 'transparent',
      textAlign: 'center',
      fontSize: 1,
      '&:focus': {
        outline: 'none',
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },

    copiedModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .__paper': {
        paddingTop: 38,
        paddingBottom: 24,
        width: 240,
        backgroundColor: '#fff',
        borderRadius: 10,
        outline: 0,
        textAlign: 'center',
        '&__text': {
          marginBottom: 24,
          fontSize: 16,
        },
      },
    },

    listModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .__paper': {
        paddingBottom: '24',
        width: '90%',
        backgroundColor: constants.COLOR_WHITE,
        borderRadius: 10,
        outline: 0,
        '&__closeButton': {
          textAlign: 'right',
          margin: '8px 16px',
        },

        '&__title': {
          color: constants.COLOR_BLACK,
          display: 'block',
          fontSize: 14,
          margin: '8px 16px',
        },

        '&__th': {
          textAlign: 'center',
          color: constants.COLOR_WHITE,
          backgroundColor: constants.COLOR_BLUE,
          fontSize: 11,
          height: 32,
        },

        '&__data': {
          overflow: 'scroll',
          height: 320,
        },

        '&__td': {
          textAlign: 'center',
          borderBottom: `1px solid ${constants.COLOR_GRAY}`,
          fontSize: 11,
          height: 32,
          margin: 'auto',
        },

        '&__text': {
          margin: 'auto',
        },

        '&__textRight': {
          textAlign: 'right',
          paddingRight: 16,
          margin: 'auto',
        },

        '&__textLeft': {
          textAlign: 'left',
          paddingLeft: 16,
          margin: 'auto',
        },

        '&__textCenter': {
          textAlign: 'center',
          paddingLeft: 16,
          margin: 'auto',
        },

        '&__moreButton': {
          fontSize: 14,
          alignItems: 'center',
          margin: 16,
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
