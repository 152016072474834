import { API } from '@aws-amplify/api'

import * as mutations from '../../graphql/mutations'

// -----------------------------
// Params
// -----------------------------
export type IDeleteHintParams = {
  id: string
}

// -----------------------------
// Result
// -----------------------------
export type IDeleteHintResult = {
  category: string
  commentCount: number
  content: string
  contentPreview: string
  id: string
  imageUrl: string | null
  isPublished: number
  title: string
  createdAt: string
  updatedAt: string
  user: {
    id: string
  }
  username: string
}

export const deleteHint = async (params: IDeleteHintParams) => {
  try {
    const res: any = await API.graphql({
      query: mutations.deleteHint,
      variables: {
        id: params.id,
      },
    })

    const { deleteHint } = res.data
    if (deleteHint) return deleteHint
  } catch (e) {
    throw e
  }
}
