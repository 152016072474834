import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/styles'

import { DownloadDrop } from 'pages/onboarding/components/file/DownloadDrop'
import { useFiles } from 'pages/onboarding/utils/files'
import { OnboardingTeamMember } from 'utils/generated'

import { WhiteCard } from './atoms'

import questionIcon from '../assets/QuestionIcon.svg'
import * as constants from 'assets/constants'



type Props = {
  teamMember: OnboardingTeamMember
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const TargetBox: React.FC<Props & StyleProps> = ({ teamMember, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false)

  const { file } = useFiles(
    teamMember?.targetFileNames ? teamMember?.targetFileNames[0] : undefined,
    teamMember.teamId,
    teamMember?.userId
  )

  const onTouchStart = () => {
    setVisibleTooltip((prev) => !prev)
  }
  const onMouseOver = () => {
    setVisibleTooltip(true)
  }
  const onMouseLeave = () => {
    setVisibleTooltip(false)
  }

  return (
    <WhiteCard ownStyles={{ position: 'relative', padding: '27px 24px 24px', ...ownStyles }}>
      <div className={classes.titleWrapper}>
        <div className={classes.targetTitle}>目標</div>
        <img
          className={classes.img}
          onTouchStart={onTouchStart}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          src={questionIcon}
          alt={'questionIcon'}
        />
      </div>
      {visibleTooltip ? <Tooltip classes={classes} /> : <></>}
      <div className={classes.targetText}>
        {teamMember.target || '目標が設定されていません。マイページより記載しましょう。'}
      </div>
      {file ? (
        <DownloadDrop uploadedFile={file} ownStyles={{ margin: '16px 0 0' }} />
      ) : (
        <div className={classes.targetNoText}>
          ※目標ファイルがアップロードされておりません。マイページからアップロードしましょう。
        </div>
      )}
    </WhiteCard>
  )
}

type TooltipProps = {
  classes: ClassNameMap
}

const Tooltip: React.FC<TooltipProps> = ({ classes }) => {
  return (
    <div className={classes.tooltipRoot}>
      <div className={classes.tooltipArrow}></div>
      <div className={classes.tooltipBox}>
        <div className={classes.tooltipTitle}>目標</div>
        <div className={classes.tooltipText}>
          半年後や1年後の自分のあるべき姿・ありたい姿を上司やOJTトレーナーと相談し記入しましょう。
        </div>
        <div className={classes.tooltipTitle}>添付ファイル</div>
        <div className={classes.tooltipText}>
          PPTやExcelなどで目標を作成している方はこちらでデータ添付を行うことで誰でも閲覧できる状態となります。
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  {
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    targetTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      margin: '0 8px 0 0',
    },
    img: {
      display: 'block',
    },
    tooltipRoot: {
      position: 'absolute',
      left: 0,
      zIndex: 100,
    },
    tooltipArrow: {
      position: 'relative',
      left: 60,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 10px 8px',
      borderColor: `transparent transparent ${constants.COLOR_TOOLTIP_CONTENT} transparent`,
    },
    tooltipBox: {
      backgroundColor: constants.COLOR_TOOLTIP_CONTENT,
      padding: '4px 16px 20px',
      boxShadow: '0px 4px 10px -5px rgba(0,0,0,0.5)',
      borderRadius: 8,
    },
    tooltipTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
      padding: '12px 0 0',
    },
    tooltipText: {
      fontSize: 14,
      padding: '8px 0 0',
      color: constants.TEXT_GRAY_DARK,
    },
    targetText: {
      margin: '16px 0 0',
      overflowWrap: 'break-word',
    },
    targetNoText: {
      color: constants.COLOR_ONBOARDING_ALERT,
    },
  },
  { name: 'TargetBox' }
)
