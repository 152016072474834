import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Font, Flexbox } from '../atoms'

type Props = {
  countOf: 'ghostAmount' | 'ghostType'
  ownStyles?: React.CSSProperties
}

export const CountOfGhost: React.FC<Props> = (props) => {
  return (
    <Flexbox
      ownStyles={{ alignItems: 'baseline', flexWrap: 'wrap', margin: '24px 16px', gap: '0 10px', ...props.ownStyles }}
    >
      <Font fontSize={16} bold ownStyles={{ margin: '0 0 0 0', color: constGhost.COLOR_BLACK3 }}>
        オバケリスト
      </Font>
    </Flexbox>
  )
}
