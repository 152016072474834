import { API, graphqlOperation } from '@aws-amplify/api'

import { MutationDeleteSetupStartedAtListItem } from './graphql'

const deleteSetupStartedAtListItem = async (setupStartedAt: string) => {
  const response: any = await API.graphql(
    graphqlOperation(MutationDeleteSetupStartedAtListItem, {
      setupStartedAt,
    })
  )

  return response.data
}

export default deleteSetupStartedAtListItem
