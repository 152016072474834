import { createContext, useCallback } from 'react'

import { History } from 'react-router-dom/node_modules/@types/history'

import { GhostProgress, GhostTeam, GhostTeamManage, GhostTeamMember } from 'utils/generated'

import { teamIdPath, num2Path, GP, page2Num } from '../assets/pages'

export type PageManager = ReturnType<typeof usePageManager>

export const PageManagerContext = createContext({} as PageManager)

export const usePageManager = (history: History) => {
  const currentPath = history.location.pathname

  const reportCategory = useCallback(
    (teamMember: GhostTeamMember | undefined): 'reason' | 'solution' | 'nextAction' | undefined => {
      if (teamMember && teamMember?.page) {
        if (teamMember?.page <= 20) return 'reason'
        else if (teamMember?.page > 20 && teamMember?.page <= 27) return 'solution'
        else if (teamMember?.page > 27) return 'nextAction'
      } else return
    },
    []
  )

  const completePageManage = useCallback(
    (teamMember: GhostTeamMember | undefined): 'first' | 'second' | 'step4' | 'third' | undefined => {
      if (teamMember && teamMember?.page) {
        switch (teamMember?.page) {
          case 11:
            return 'first'
          case 15:
            return 'second'
          case 30:
            return 'step4'
          case 36:
            return 'third'
          default:
            return
        }
      } else return
    },
    []
  )

  const jumpTo = useCallback(
    (path: string) => {
      history.push(path)
    },
    [history]
  )

  const getPathChangeInfo = useCallback(
    (team: GhostTeam, pageNum: number) => {
      return helpers.determineUpdatePath(currentPath, team, pageNum)
    },
    [currentPath]
  )

  const checkPgeRange = useCallback((teamMember: GhostTeamMember, start: number, end: number) => {
    if (teamMember.page) {
      return start <= teamMember.page && teamMember.page <= end ? true : false
    }
    return false
  }, [])

  return {
    reportCategory,
    completePageManage,
    jumpTo,
    getPathChangeInfo,
    checkPgeRange,
  }
}

interface PathChangeInfo {
  type: 'jump' | 'transJump'
  dest: string
}

/**
 * detemine if current state should change path and how
 */
export const determineUpdatePath = (
  currentPath: string,
  team: GhostTeam,
  pageNum: number
): PathChangeInfo | undefined => {
  const isAtStarted = Boolean(currentPath.match(/started?/g))
  const genericPath = num2Path(pageNum)
  const destination = teamIdPath(genericPath, team.id)

  if (destination !== currentPath && !isAtStarted) {
    const jumpType = helpers.howShouldIJump(genericPath)
    if (jumpType) {
      return {
        type: jumpType,
        dest: destination,
      }
    }
  }
}

/**
 * check team's current page,
 * Late users should obey team's page with this result
 * Each 'Manage' attribute is defined in the backend's keyManage and manageParamBuilder
 */
export const checkTeamProgress = (team: GhostTeam, teamMember: GhostTeamMember): number => {
  if (!teamMember) return 1
  let pageNum = 1
  if (team.status === GhostTeamManage.Prepare) pageNum = page2Num('selectLeader')
  if (team.status === GhostTeamManage.Doing) pageNum = page2Num('intro')
  if (team.selectListManage) pageNum = page2Num('step1Results')
  if (team.reportManage) pageNum = 14 //CHANGE RATER
  if (team.researchManage) pageNum = page2Num('result')
  if (team.likeManage) pageNum = page2Num('done')
  if (team.researchSecondManage) pageNum = page2Num('result', teamMember)
  if (team.likeSecondManage) pageNum = page2Num('done', teamMember)
  if (team.feelingNextActionManage) pageNum = page2Num('workResults', teamMember)
  // if team's state surpasses member's, override it

  if (
    team.progress &&
    [
      GhostProgress.S1SelectingPresentationOptions,
      GhostProgress.S2SelectingPresentationOptions,
      GhostProgress.S3SelectingPresentationOptionsReason,
      GhostProgress.S3SelectingPresentationOptions,
      GhostProgress.S4SelectingPresentationOptions,
    ].includes(team.progress)
  ) {
    // presentationOptions は複数存在するため、teamMember を引数に渡すことで正しいページを取得する。
    pageNum = page2Num('presentationOptions', teamMember)
  } else if (
    team.progress &&
    [
      GhostProgress.S1DoneRandomAnswers,
      GhostProgress.S2DoneRandomAnswers,
      GhostProgress.S3DoneRandomAnswers,
      GhostProgress.S4DoneRandomAnswers,
    ].includes(team.progress)
  ) {
    // complete は複数存在するため、teamMember を引数に渡すことで正しいページを取得する。
    pageNum = page2Num('complete', teamMember)
  } else if (team.progress === GhostProgress.IntroDone) {
    pageNum = page2Num('step1Start')
    // !== 7 は、STEP1 の Break ページでこの条件を適用しないために設定。
  } else if (team.progress === GhostProgress.S1Started && teamMember.page !== 7) {
    pageNum = page2Num('pickGhosts')
  } else if (team.progress === GhostProgress.S1DonePresentationOptions) {
    pageNum = page2Num('step1Presentation')
    // !== 13 は、STEP2 の Break ページでこの条件を適用しないために設定。
  } else if (team.progress === GhostProgress.S2Started && teamMember.page !== 13) {
    pageNum = page2Num('vote')
    // ![].includes は、STEP3 の Break ページでこの条件を適用しないために設定。
  } else if (team.progress === GhostProgress.S3Started && ![17, 19].includes(teamMember.page ?? 0)) {
    pageNum = page2Num('report')
  } else if (team.progress === GhostProgress.S3DonePresentationOptionsReason) {
    pageNum = page2Num('step3PresentationReason')
    // report は複数存在するため、teamMember を引数に渡すことで正しいページを取得する。
    // ![].includes は、STEP3 の Break ページでこの条件を適用しないために設定。
    // ※ 1つ上の条件とまとめないのは、S3DoneAggregateCause に到達する前に2つめの report に遷移するのを防ぐため。
  } else if (team.progress === GhostProgress.S3DoneAggregateCause && ![24, 26].includes(teamMember.page ?? 0)) {
    pageNum = page2Num('report', teamMember)
  } else if (team.progress === GhostProgress.S3DonePresentationOptions) {
    pageNum = page2Num('step3Presentation')
    // !== 32 は、STEP4 の Break ページでこの条件を適用しないために設定。
  } else if (team.progress === GhostProgress.S4Started && teamMember.page !== 32) {
    pageNum = page2Num('step4Report')
  } else if (team.progress === GhostProgress.S4DonePresentationOptions) {
    pageNum = page2Num('step4Presentation')
  }

  if (!teamMember.page) return pageNum
  return pageNum > teamMember?.page ? pageNum : teamMember?.page
}

export const howShouldIJump = (genericPath: string): 'jump' | 'transJump' | undefined => {
  switch (genericPath) {
    // change with transition effect.
    case GP.selectLeader:
    case GP.start:
    case GP.complete:
    case GP.result:
    case GP.done:
      return 'transJump'

    // just change the path
    default:
      return 'jump'
  }
}

export const helpers = {
  determineUpdatePath,
  checkTeamProgress,
  howShouldIJump,
}
