import React from 'react'
import { useLocation } from 'react-router-dom'

import { useMediaQuery } from '@material-ui/core'
import { CheckCircleRounded } from '@material-ui/icons'
import { RouteComponentProps } from 'react-router'

import { Card, DefaultInputWithLabel } from 'pages/teams/components'
import { AdminSwitchTab } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { paymentMethodLabel } from 'pages/teams/utils/paymentMethod'
import { statusPaymentLabel } from 'pages/teams/utils/statusPayment'
import { TeamStatusAdmin } from 'utils/generated'

import { constants } from 'assets'
import { theme } from 'assets/theme'


type Props = RouteComponentProps<{ teamId: string }>

export const TeamsPaymentPage: React.FC<Props> = (props) => {
  const { currentTeam, currentTeamAdmin } = React.useContext(HooksContext)
  const isMdUP = useMediaQuery(theme.breakpoints.up('md'))
  const params = useLocation().pathname
  const array = params.split('/')
  const url = array[3]

  return (
    <>
      {isMdUP ? <></> : <AdminSwitchTab url={url} />}
      <Card headerTitle={'決済情報'} loading={!currentTeam ? true : false} style={{ padding: '30px 24px 40px' }}>
        <DefaultInputWithLabel
          label={'決済方法'}
          name={'paymentMethod'}
          readOnly
          defaultValue={paymentMethodLabel(currentTeam?.paymentMethod)}
        />
        <DefaultInputWithLabel
          label={'運営確認'}
          name={'statusPayment'}
          readOnly
          hasCheckMark
          defaultValue={statusPaymentLabel(currentTeamAdmin?.statusAdmin)}
          endAdornment={
            currentTeamAdmin?.statusAdmin !== TeamStatusAdmin.Confirming ? (
              <CheckCircleRounded
                style={{
                  fill: constants.COLOR_TEAMBUILDING_PRIMARY,
                  fontSize: 20,
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </Card>
    </>
  )
}
