// -----------------------------
// Tipsの一覧を取得する
// -----------------------------
export const getHintList = /* GraphQL */ `
  query GetHintList($filter: HintFilter, $first: Int, $after: String) {
    getHintList(filter: $filter, first: $first, after: $after) {
      items {
        id
        username
        user {
          id
          firstName
          lastName
          nickname
          imageUrl
          shortDescription
          description
        }
        title
        imageUrl
        content
        contentPreview
        category
        isPublished
        commentCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

// -----------------------------
// Tipsの個別記事を取得する
// -----------------------------
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      username
      user {
        id
        firstName
        lastName
        nickname
        imageUrl
        shortDescription
        description
      }
      title
      imageUrl
      content
      contentPreview
      category
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// Tipsのコメント一覧を取得する
// -----------------------------
export const getHintCommentList = /* GraphQL */ `
  query GetHintCommentList($hintId: ID!, $first: Int, $after: String) {
    getHintCommentList(hintId: $hintId, first: $first, after: $after) {
      items {
        id
        hintId
        username
        user {
          id
          firstName
          lastName
          nickname
          imageUrl
          shortDescription
          description
        }
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

// -----------------------------
// お悩み掲示板の一覧を取得する
// -----------------------------
export const getConsultationList = /* GraphQL */ `
  query GetConsultationList($filter: ConsultationFilter, $first: Int, $after: String) {
    getConsultationList(filter: $filter, first: $first, after: $after) {
      items {
        id
        username
        user {
          id
          firstName
          lastName
          nickname
          imageUrl
          shortDescription
          description
        }
        title
        content
        category
        isPublished
        contentPreview
        commentCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

// -----------------------------
// お悩み掲示板の個別記事を取得する
// -----------------------------
export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      username
      user {
        id
        firstName
        lastName
        nickname
        imageUrl
        shortDescription
        description
      }
      title
      content
      category
      contentPreview
      isPublished
      commentCount
      createdAt
      updatedAt
    }
  }
`

// -----------------------------
// お悩み掲示板のコメント一覧を取得する
// -----------------------------
export const getConsultationCommentList = /* GraphQL */ `
  query GetConsultationCommentList($consultationId: ID!, $first: Int, $after: String) {
    getConsultationCommentList(consultationId: $consultationId, first: $first, after: $after) {
      items {
        id
        consultationId
        username
        user {
          id
          firstName
          lastName
          nickname
          imageUrl
          shortDescription
          description
        }
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

// -----------------------------
// ユーザー詳細を取得する
// -----------------------------
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      nickname
      imageUrl
      shortDescription
      description
    }
  }
`
