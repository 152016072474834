import { actionCreatorFactory } from 'typescript-fsa'

import { ISetupAnketFeedbackUpdateInput } from '../../services/api/updateSetupAnketFeedback'
import { ISetupItemFeedbackUpdateInput } from '../../services/api/updateSetupItemFeedback'

import { IActionItem } from './reducer'
import { FETCH_SETUPACTION, SUBMIT_QUESTIONNAIRE_SETUPACTION, SUBMIT_MISSON_SETUPACTION } from './types'

const actionCreator = actionCreatorFactory()
export const SetupactionActions = {
  fetch: actionCreator.async<
    {
      params: {
        setupStartedAt: string
        week: number
        username: string
      }
    },
    {}
  >(FETCH_SETUPACTION),
  submitQuestionnaire: actionCreator.async<
    { data: ISetupAnketFeedbackUpdateInput; setupStartedAt: string },
    IActionItem
  >(SUBMIT_QUESTIONNAIRE_SETUPACTION),
  submitMission: actionCreator.async<{ data: ISetupItemFeedbackUpdateInput; item: IActionItem }, IActionItem>(
    SUBMIT_MISSON_SETUPACTION
  ),
  search: actionCreator.async<{}, {}>('SEARCH_SETUPACTION'),
}
