import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { ActionsType, RootStateType } from 'store'
import { UserActions } from 'stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onMount: () => dispatch(UserActions.fetch.started({ params: {} })),
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
