import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import EditIcon from '../../assets/edit.svg'
import ExclamationIcon from '../../assets/exclamation.svg'
import WhiteCheck from '../../assets/whiteCheck.svg'
import { ContentButton } from '../generic/ContentButton'

type Props = {
  type: 'done' | 'caution'
  date: string
  name?: string
  label?: string
  actionId: string
  teamBuildingActionId: string
  completed?: boolean
  onEditAction(actionId: string): void
  onComplete?(actionId: string): void
}

export const ActionContent: React.FC<Props> = ({
  type,
  date,
  name,
  label,
  actionId,
  teamBuildingActionId,
  onComplete,
  onEditAction,
  completed = false,
}) => {
  const classes = useStyles()
  const iconSource = type === 'caution' ? ExclamationIcon : WhiteCheck
  const edit = () => {
    onEditAction(teamBuildingActionId)
  }
  const complete = () => {
    if (onComplete) {
      onComplete(actionId)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 12 }}>
          <div className={`${classes.badge} ${type}`}>
            <img height={13} alt="" src={iconSource} />
            <span>{date}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <img height={12} src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
            <span>{name ?? ''}</span>
          </div>
        </div>
        <div className={classes.label}>{label ?? ''}</div>
      </div>
      <div className={classes.buttons}>
        <ContentButton type="border" icon={<img alt="" src={EditIcon} />} label="内容を編集" onClick={edit} inline />
        <ContentButton disabled={completed} label="アクション完了" onClick={complete} inline />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    justifyContent: 'space-between',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '2px 4px',
    borderRadius: 24,
    color: '#FFFFFF',
    '&.caution': {
      backgroundColor: '#CE2C2C',
    },
    '&.done': {
      backgroundColor: '#00838C',
    },
  },

  label: {
    fontWeight: 'bold',
  },
  buttons: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    '@media (max-width: 632px)': {
      flexDirection: 'row',
    },
  },
}))
