import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { Storage } from 'aws-amplify'

import { teamConstants } from 'pages/teams/assets/constants'

interface Props {
  name?: string | null
  kind: 'companies' | 'users'
  keyPrefix: string
  imageUrl?: string
  iconLabel?: string
  onChange: (imageUrl: string) => void
}

export const IconEditor: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { kind, keyPrefix, imageUrl, iconLabel, onChange } = props

  const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const key = `${kind}/${keyPrefix}-${new Date().toISOString()}.${file.name.split('.').pop()}`
    await Storage.put(key, file, {
      contentType: file.type,
      level: 'public',
    })

    onChange(
      `https://${process.env.REACT_APP_AWS_S3_BUCKET_UPLOAD}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${key}`
    )
  }

  return (
    <div className={classes.root}>
      <p className={classes.label}>{iconLabel ?? 'アイコン'}</p>
      <label style={{ cursor: 'pointer' }} htmlFor={`icon-editor-${kind}`}>
        <input
          data-testid="iconEditor"
          hidden
          id={`icon-editor-${kind}`}
          type="file"
          accept="image/*"
          onChange={onFileInputChange}
        />
        {imageUrl ? (
          <Avatar alt="avatar" src={imageUrl} className={classes.avatar} />
        ) : (
          <div className={classes.editIcon}>
            <img src={`${process.env.PUBLIC_URL}/assets/svg/teams/image_edit.svg`} alt="" />
          </div>
        )}
      </label>
    </div>
  )
}

const ICON_SIZE = 60

const useStyles = makeStyles(
  {
    root: {
      maxWidth: ICON_SIZE, // input が画面横幅いっぱいに反応するのを防ぐ
    },

    avatar: {
      width: ICON_SIZE,
      height: ICON_SIZE,
    },

    editIcon: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: ICON_SIZE,
      height: ICON_SIZE,
      borderRadius: 50,
    },

    label: {
      ...teamConstants.inputLabelStyles,
      marginTop: 0,
    },
  },
  { name: 'IconEditor' }
)
