import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { Menu } from '../../../hooks/useHamburgerBoxStyle'

type Props = {
  menu: Menu
  visibleDetail: boolean
}
type StyleProps = {}

export const HamburgerItem: React.FC<Props & StyleProps> = ({ menu, visibleDetail }) => {
  const [isHover, setIsHover] = useState(false)
  const pathname = useLocation().pathname
  const isOverView = pathname.includes('overview')
  const showName = isHover && isOverView
  const classesScoped = useStyles()

  return (
    <div style={{ position: 'relative' }}>
      <li
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={menu.onClick}
        style={{ ...menu.style, justifyContent: visibleDetail ? undefined : 'center' }}
      >
        <img src={menu.imgSrc} alt={'Icon'} />
        {visibleDetail ? <span>{menu.labelText}</span> : <></>}
      </li>
      {showName && (
        <div
          className={classesScoped.labelText}
          style={{ left: menu.labelText.length >= 7 ? 10 : menu.labelText.length <= 5 ? 20 : 15 }}
        >
          {menu.labelText}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  labelText: {
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: 6,
    textAlign: 'center',
    position: 'absolute',
    top: 50,
    left: 15,
    pointerEvents: 'none',
  },
}))
