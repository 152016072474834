export const QueryGetKarte = `query GetKarte($id: ID!, $leaderId: ID) {
  getKarte(id: $id, leaderId: $leaderId) {
    id
    leaderId
    data
    name
    leaderNumber
    leaderData
    createdAt
    updatedAt
  }
}`

export const MutationKarteMemberSend = `mutation KarteMemberStore($input: KarteMemberSendInput!) {
  karteMemberSend(input: $input) {
    id
    leaderId
    data
    createdAt
    updatedAt
  }
}`

export const QueryGetKarteList = /* GraphQL */ `
  query GetKarteList($filter: KarteFilter, $first: Int, $after: String) {
    getKarteList(filter: $filter, first: $first, after: $after) {
      items {
        id
        leaderId
        data
        name
        leaderNumber
        leaderData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
