import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../store'
import { SelfcheckActions } from '../../../stores/selfcheck/action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => {
  return {
    fetch: async (currentHistory: number) =>
      dispatch(SelfcheckActions.fetch.started({ currentHistory: currentHistory })),
    fetchSelfCheckComments: async () => dispatch(SelfcheckActions.fetchSelfCheckComments.started({})),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    selfCheck: { ...state.selfcheck },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
