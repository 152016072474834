import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { useGetHintEpic } from 'pages/hint/_shared/hooks/useGetHintEpic'
import { useNextToken } from 'pages/hint/_shared/hooks/useNextToken'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getTokenWithFetch: (username: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useProfileNextToken = (): IProps => {
  const nextToken = useNextToken()
  const getHintEpic = useGetHintEpic()
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)

  /**
   * ページネーションによるnextTokenを制御してfetchする
   */
  const getTokenWithFetch = (username: string): void => {
    if (pagesSelectors.pageAction === '') {
      getHintEpic.getProfileHintListEpic({ username: username, nextToken: nextToken.setCurrentLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'NEXT') {
      getHintEpic.getProfileHintListEpic({ username: username, nextToken: nextToken.setNextLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'PREVIOUS') {
      getHintEpic.getProfileHintListEpic({ username: username, nextToken: nextToken.setPrevLen() || '' })
      return
    }

    if (pagesSelectors.pageAction === 'BACK') {
      getHintEpic.getProfileHintListEpic({ username: username, nextToken: nextToken.setBackLen() || '' })
      return
    }
  }

  return {
    getTokenWithFetch,
  }
}
