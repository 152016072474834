import React, { useContext } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'

import { Font, Flexbox, UsefulCard } from '../atoms'

type Props = {
  fontColor?: 'red' | 'black'
  ownStyles?: React.CSSProperties
  currentNum: number
}

export const CountOfReportedMembers: React.FC<Props> = (props) => {
  const classes = useStyles({})

  CountOfReportedMembers.defaultProps = { fontColor: 'black' }
  const fontColor = props.fontColor === 'red' ? constGhost.COLOR_RED : constGhost.COLOR_BLACKFORTEXT
  const { teamMemberList } = useContext(ghostTeamMemberContext)

  return (
    <>
      <div className={classes.backWhiteCard}>
        <UsefulCard
          backgroundColor="white"
          borderRadius={0}
          ownStyles={{ alignItems: 'center', minWidth: 311, margin: '8px auto', padding: '24px 0' }}
        >
          <Flexbox ownStyles={{ alignItems: 'baseline', ...props.ownStyles }}>
            <Font fontSize={16} ownStyles={{ margin: '0', color: fontColor, fontWeight: 'bold' }}>
              これまでの探索報告数
            </Font>
          </Flexbox>
          <Flexbox ownStyles={{ alignItems: 'baseline', ...props.ownStyles }}>
            <Font fontSize={24} bold ownStyles={{ color: constGhost.COLOR_MAIN_NEW, margin: '0 8px 0 0' }}>
              {props.currentNum || 0}
            </Font>
            <Font fontSize={14} ownStyles={{ margin: '0 0 0 0', color: fontColor }}>
              人 / {teamMemberList.length}人
            </Font>
          </Flexbox>
        </UsefulCard>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    backWhiteCard: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
  { name: 'CountOfReportedMembers' }
)
