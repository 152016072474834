import * as React from 'react'

import { Circle, G, Line, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man3: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-60 -454)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(60 454)" fill="#80d6dd" />
        <G transform="translate(1145.7 -601.051)">
          <G>
            <Path
              d="M-617.021,1186.818h-88.028c-2.489,0-4.526,2.486-4.526,5.524V1330.1c0,.055.012.106.014.16v2.663H-612.5V1192.342C-612.5,1189.3-614.533,1186.818-617.021,1186.818Z"
              fill="#545364"
            />
            <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(-671.615 1172.267)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-726.506 1121.117)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(-630.591 1121.117)" fill="#ffe4d2" />
            <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(-716.172 1075.47)" fill="#ffe4d2" />
            <Path
              d="M-709.574,1413.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.148.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-649.337,1413.613c0,.175-.013.349-.013.524,0,10.171,8.245,10.271,18.416,10.271,10.01,0,18.149.158,18.4-9.791h.035v-1a2.57,2.57,0,0,0-2.57-2.57h-31.7a2.571,2.571,0,0,0-2.571,2.569h0Z"
              fill="#2a2a2a"
            />
            <Path d="M-679.647,1186.818l9.075,26.674h15.125l9.9-26.674Z" fill="#fff" />
            <Path
              d="M-668.1,1190.888l1.1,8.274-1.1,4.675-3.575,7.425,7.975,26.4,9.075-25.849-3.3-7.724.546-13.2-4.671-3.714Z"
              fill="#b15f78"
            />
            <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(-668.097 1195.068)" fill="#c07087" />
            <Path d="M-662.273,1186.818l11.229,15.033,5.637-15.033Z" fill="#e5e5e4" />
            <Path d="M-661.723,1186.818l-13.383,14.107-4.541-14.107Z" fill="#e5e5e4" />
            <Path
              d="M-603.681,1128.063c-12.367,1.479-18.661-9.536-21.808-18.191-37.765-2.36-59.009-3.147-72.384,0-2.36,9.442-23.6,27.538-22.134,9.1,3.662-23.7,24.949-52.918,57.46-52.918S-603.681,1093.814-603.681,1128.063Z"
              fill="#323333"
            />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-649.337 1323.846)" fill="#545364" />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(-709.575 1323.846)" fill="#545364" />
            <G>
              <Path
                d="M-700.783,1184.1a11.769,11.769,0,0,1,9.925,13.294l-3.122,13.5a11.766,11.766,0,0,1-13.3,9.924l-.774-.111a11.766,11.766,0,0,1-9.925-13.294l3.122-13.5a11.765,11.765,0,0,1,13.294-9.925Z"
                fill="#ffe4d2"
              />
              <Path
                d="M-690.3,1204.446l-8.985,76.988s-11.377,5.1-15.3,4.436c-3.563-.6-11.786-8.364-11.786-8.364l8.986-76.988Z"
                fill="#545364"
              />
            </G>
            <G>
              <Path
                d="M-623.292,1184.183a11.768,11.768,0,0,1,13.136,10.133l.627,13.845a11.766,11.766,0,0,1-10.134,13.136l-.776.1a11.767,11.767,0,0,1-13.136-10.133l-.627-13.845a11.766,11.766,0,0,1,10.133-13.136Z"
                fill="#ffe4d2"
              />
              <Path
                d="M-607.723,1200.953l12.062,76.567s-9.585,7.973-13.546,8.39c-3.594.379-13.6-4.884-13.6-4.884l-12.062-76.566Z"
                fill="#545364"
              />
            </G>
          </G>
          <G>
            <Path
              d="M-930.866,1191.412l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-928.89,1185.929-930.889,1188.4-930.866,1191.412Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(-890.041 1171.232) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-849.31 1120.183)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(-944.23 1120.904)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(-933.929 1075.369)" fill="#ffe4d2" />
            <Path
              d="M-833.119,1412.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M-892.719,1413.2a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M-898.2,1185.7l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M-881.87,1185.926l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(-886.433 1193.771) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M-898.337,1185.7l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M-864.453,1185.44l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M-882.282,1066.052c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-940.329,1093.97-914.456,1066.3-882.282,1066.052Z"
              fill="#522903"
            />
            <G>
              <Path
                d="M-759.741,1219.073l-48.126,28.734a10.128,10.128,0,0,1-13.889-3.5h0l-.347-.58c-2.868-4.8-2.077-12.577,2.727-15.445l48.126-28.734c4.8-2.868,11.8.256,14.668,5.059l.346.58A10.128,10.128,0,0,1-759.741,1219.073Z"
                fill="#ffe4d2"
              />
              <Path d="M-836.328,1238.909l60.831-39.029,13.673,21.845-60.831,39.029Z" fill="#545364" />
            </G>
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-929.878 1321.551) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(-870.265 1321.098) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M-857.444,1205.09a11.768,11.768,0,0,1-4.483,15.973l-12.6,5.761a11.766,11.766,0,0,1-15.973-4.484l-.384-.681a11.766,11.766,0,0,1,4.483-15.973l12.6-5.762a11.767,11.767,0,0,1,15.973,4.483Z"
                fill="#ffe4d2"
              />
              <Path
                d="M-867.172,1225.8l-66.494,39.831s-10.98-5.906-12.849-9.424c-1.7-3.191-.559-14.442-.559-14.442l66.494-39.829Z"
                fill="#545364"
              />
            </G>
            <Path d="M-835.718,1187.475l28.11,49.877-22.381,11.8-28.114-49.877Z" fill="#545364" />
            <Path d="M-907.1,1196.823l-16.6,54.794-24-8.01,16.594-54.8Z" fill="#545364" />
          </G>
          <G>
            <Line
              x1="0.755"
              x2="0"
              y1="0"
              y2="7.023"
              transform="translate(-606.226 1173.933)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="5.836"
              x2="0"
              y1="0"
              y2="4.352"
              transform="translate(-601.925 1178.932)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="0"
              x2="5.739"
              y1="0"
              y2="1.032"
              transform="translate(-599.217 1187.604)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </G>
          <G>
            <Line
              x1="1.003"
              x2="0"
              y1="0"
              y2="6.992"
              transform="translate(-714.483 1170.821)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="0"
              x2="4.58"
              y1="0"
              y2="5.659"
              transform="translate(-723.806 1173.347)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <Line
              x1="5.816"
              x2="0"
              y1="0.417"
              y2="0"
              transform="translate(-728.733 1182.106)"
              fill="none"
              stroke="#fff385"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Man3
