import { useEffect } from 'react'
import { createContext, useState } from 'react'

import { GhostResearch, GhostResearchType, GhostTeamMember } from 'utils/generated'

import { getGhostHistory } from '../api/history/handlers'
import { createGhostResearch } from '../api/research/handlers'

import { page2Num } from '../assets/pages'

export const ResearchContext = createContext<ResearchStore>({} as ResearchStore)

export type ResearchStore = ReturnType<typeof useResearchStore>

export const useResearchStore = (teamId?: string, teamMember?: GhostTeamMember) => {
  const historyId = teamId // remember this

  const [loading, setLoading] = useState<boolean>(false)
  const [researches1, setResearches1] = useState<GhostResearch[]>([])
  const [researches2, setResearches2] = useState<GhostResearch[]>([])
  const [researchesFeeling, setResearchesFeeling] = useState<GhostResearch[]>([])
  const [researchesNextAction, setResearchesNextAction] = useState<GhostResearch[]>([])

  const fetchResearches = async (historyId: string) => {
    setLoading(true)
    const { research } = await getGhostHistory({ id: historyId })

    if (research && research.first && research.second) {
      setResearches1(research.first)
      setResearches2(research.second)
    }
    research && research.feeling && setResearchesFeeling(research.feeling)
    research && research.nextAction && setResearchesNextAction(research.nextAction)
    setLoading(false)
  }

  // Init
  useEffect(() => {
    if (!teamId || !teamMember) return
    const isFetch = (): boolean => {
      switch (teamMember.page) {
        case page2Num('result'):
          return true
        case page2Num('done'):
          return true
        case page2Num('report', teamMember):
          return true
        case page2Num('result', teamMember):
          return true
        case page2Num('done', teamMember):
          return true
        case page2Num('complete', teamMember):
          return true
        case page2Num('step3Presentation', teamMember):
          return true
        case page2Num('step4Report', teamMember):
          return true
        case page2Num('workResults', teamMember):
          return true
        case page2Num('step4Presentation', teamMember):
          return true
        case page2Num('presentationOptions', teamMember):
          return true
        default:
          return false
      }
    }
    if (isFetch()) {
      fetchResearches(teamId) // fetch researches to render, each time (the latter includes likes)
    }
  }, [teamId, teamMember])

  const createResearch = async (report: string, type: GhostResearchType) => {
    if (!teamId || !historyId) return
    const userId = teamMember?.userId
    const payload = { teamId, report, type, historyId, userId }
    await createGhostResearch({ input: payload })
  }

  return {
    researches1,
    researches2,
    researchesFeeling,
    researchesNextAction,
    loading,
    createResearch,
  } as const
}
