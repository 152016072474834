import * as React from 'react'

import { Font, UsefulCard } from '../atoms/index'

type Props = {
  text: string
  noBack?: boolean
}

export const GhostBackground: React.FC<Props> = ({ text, noBack }) => {
  return (
    <>
      <UsefulCard
        backgroundColor={noBack ? 'white' : 'lightYellow'}
        borderRadius={'none'}
        ownStyles={{ padding: noBack ? 0 : '20px 9px', marginBottom: 24 }}
      >
        <Font fontSize={14} bold={true} ownStyles={{ marginBottom: 10 }}>
          オバケの出没経緯
        </Font>
        <Font fontSize={14}>{text}</Font>
      </UsefulCard>
    </>
  )
}
