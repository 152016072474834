import { useState } from 'react'
import { useLocation } from 'react-router-dom'

// -----------------------------
// interface
// -----------------------------
export type IuseSocials = {
  onOpenSharePopup: (provider: string) => void
  onCloseDialog: () => void
  onOpenProfileSocial: (uel: string) => void
  createShareURL: (shareUrl?: string) => string
  getWeb: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useSocials = (): IuseSocials => {
  const location = useLocation()
  const [isWeb, setWeb] = useState<boolean>(false)

  /**
   * Providerを判定して其々のポップアップを開く
   */
  const onOpenSharePopup = (provider: string): void => {
    if (provider === 'web') {
      setWeb(true)
    }
  }

  /**
   * シェアしたいURLを生成する
   */
  const createShareURL = (shareUrl?: string): string => {
    return shareUrl ? shareUrl : location.pathname
  }

  /**
   * webのポップアップが押されたときに返す
   */
  const getWeb = (): boolean => {
    return isWeb
  }
  /**
   * ダイアログを閉じる
   */
  const onCloseDialog = (): void => {
    setWeb(false)
  }

  /**
   * シェアではないソーシャルアイコンの場合はリンクに遷移する
   */
  const onOpenProfileSocial = (url: string): void => {
    window.open(url, '_blank')
    window.focus()
  }

  return {
    onOpenSharePopup,
    onCloseDialog,
    onOpenProfileSocial,
    createShareURL,
    getWeb,
  }
}
