import * as React from 'react'
import { Link } from 'react-router-dom'

import { Theme, makeStyles, Avatar, AppBar, Toolbar, Box, useMediaQuery, useTheme } from '@material-ui/core'

import Logo from 'components/icon/Logo'
import { TeamMemberRole } from 'utils/generated'
import { getTeamMemberRoleLabel } from 'utils/labels'
import { strLimit } from 'utils/string'

import { HooksContext } from '../contexts'

import { HeaderMenu } from './HeaderMenu'

import { ContentHeader } from '.'

import { constants, Pages, useHistory } from 'assets'
interface Props {
  teamId?: string
}

export const HEADER_HEIGHT = 60

export const Header: React.FC<Props> = React.memo((props) => {
  const { teamId } = props
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles({ teamId })
  const { currentTeamAdmin, currentUser, currentTeamMember, isAdmin, isLeader } = React.useContext(HooksContext)
  const isAdminOrLeader = (isAdmin || isLeader) ?? false
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const currentTeamMemberRole = currentTeamMember?.role
    .map((role) => getTeamMemberRoleLabel(role))
    // SP サイズでの短縮表示を考慮し、メインの role 名が先頭にくるようにする。
    .sort((a, b) => {
      if (a === getTeamMemberRoleLabel(TeamMemberRole.Admin)) {
        return -1
      } else if (
        a === getTeamMemberRoleLabel(TeamMemberRole.Leader) &&
        b === getTeamMemberRoleLabel(TeamMemberRole.Admin)
      ) {
        return 1
      } else if (a === getTeamMemberRoleLabel(TeamMemberRole.Leader)) {
        return -1
      } else {
        return 1
      }
    })
    .join('/')
  const { route } = useHistory()
  const toUsagePage = () => {
    route.push(Pages.TeamsUsageMovie, { teamId: currentTeamMember?.teamId })
  }
  return (
    <>
      <AppBar className={classes.appBar} color={'inherit'} position={isMdUp ? 'fixed' : 'relative'}>
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.container}>
            <Box
              className={classes.containerBox}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              height={isMdUp ? 78 : 64}
            >
              <Link to={constants.PAGE_TOP}>
                <Logo width={isMdUp ? 150 : 140} height={isMdUp ? 19 : 18} />
              </Link>
              {currentTeamMember ? (
                <div className={classes.userBoxWrapper}>
                  {isMdUp && (
                    <div className={classes.movieLinkWrapper} onClick={toUsagePage}>
                      <div>
                        <img src={process.env.PUBLIC_URL + '/assets/svg/movie.svg'} alt="arrow" />
                      </div>
                      <div className={classes.movieLink}>お役立ち動画</div>
                    </div>
                  )}

                  <Box className={classes.userBox} onClick={handleClick}>
                    <Avatar
                      className={`${classes.avatar} ${isAdminOrLeader ? classes.strongAvatar : ''}`}
                      src={currentUser?.imageUrl || ''}
                    >
                      {currentTeamMember?.fullName?.charAt(0)}
                    </Avatar>
                    <div className={classes.nameContainer}>
                      <div className={classes.companyName}>
                        {isMdUp
                          ? strLimit(24, currentTeamAdmin?.companyName)
                          : strLimit(14, currentTeamAdmin?.companyName)}
                      </div>
                      <div className={classes.userNameWrapper}>
                        <div className={classes.userName}>
                          {isMdUp
                            ? strLimit(24, currentTeamMember?.fullName)
                            : strLimit(12, currentTeamMember?.fullName)}
                        </div>

                        <div className={classes.userName}>
                          &nbsp;/&nbsp;{isMdUp ? currentTeamMemberRole : strLimit(5, currentTeamMemberRole)}
                        </div>
                      </div>
                    </div>

                    <HeaderMenu isMdup={isMdUp} open={open} anchorEl={anchorEl} handleClose={handleClose} />
                  </Box>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </div>
        </Toolbar>

        {isMdUp && (
          <div className={classes.contentHeader}>
            <ContentHeader teamId={teamId} />
          </div>
        )}
      </AppBar>
    </>
  )
})

const useStyles = makeStyles(
  (theme: Theme) => ({
    appBar: {
      backgroundColor: '#fff',
      boxShadow: 'none',
      zIndex: 1290,
      transition: 'background .4s',
    },

    toolbar: {
      '&.MuiToolbar-regular': {
        minHeight: 64,
      },
    },

    container: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
        boxShadow: '0px 3px 6px #00000029',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
      },
    },

    containerBox: {
      width: '100%',
      gap: 8,
    },

    avatar: {
      width: 24,
      height: 24,
      marginRight: 8,
      border: '1px solid #f3f3f3',
      [theme.breakpoints.up('md')]: {
        width: 50,
        height: 50,
        marginRight: 16,
      },
    },
    strongAvatar: {
      [theme.breakpoints.down('sm')]: {
        border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      },
    },

    nameContainer: {
      marginRight: 16,
      [theme.breakpoints.up('md')]: {
        marginRight: 40,
      },
    },

    companyName: {
      fontSize: 10,
      color: constants.TEXT_BLACK,
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        minHeight: 22,
      },
    },

    userNameWrapper: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 128,
      },
    },

    userName: {
      fontSize: 10,
      color: '#8D8D8D',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    userBoxWrapper: {
      minWidth: 0,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    movieLinkWrapper: {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      marginRight: 30,
    },
    movieLink: {
      fontWeight: 'bold',
      fontSize: 10,
      color: constants.COLOR_GRAY_DARK,
    },

    userBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        marginRight: 10,
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: 16,
      },
    },

    contentHeader: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE2,
        borderBottom: `2px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      },
    },
  }),
  { name: 'Header' }
)
