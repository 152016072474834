import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Grid, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'

import * as constants from '../../assets/constants'

import ApplyButton from './components/ApplyButton'
import SectionTitle from './components/SectionTitle'
// import DisabledButton from './components/DisabledButton'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const items = [
  {
    profile: `40代男性　課長`,
    text: `360度フィードバックで、部下から「話を聞いてもらっていない」という評価を受けました。普段から意識して「傾聴」を実践していたつもりだったので何が問題かわからず客観的にアドバイスをいただきたいと思い今回相談しました。自分では気づけない口癖や他者をコントロールしたいという想いが強くあったことを改めて自覚しました。薄々感じていたことを、客観的に言ってもらい非常に貴重な機会となりました。`,
  },
  {
    profile: `40代女性　課長`,
    text: `部下との関係性に問題を感じており、今回相談しました。特に部下から本音を言ってもらっていないと感じており、自分の何が問題で本音を言ってもらえないのか知りたくて話を聞いていただきました。話をしながら、部下が本音を言っていない以上に、自分が本音を言っていないことに気づき、まずは率直に相手に期待していることや、相手と築きたい関係性を伝えようと思いました。`,
  },
  {
    profile: `50代男性　課長`,
    text: `自分が部署で掲げているビジョンがどのように他者から聞こえるのか、課題があれば改善したいと思い相談しました。ビジョンについてプレゼンしている様を見てもらいましたが、自分が一部のメンバーに対して少し気にしている点が垣間見えたようでズバリと指摘され、やはり自分で気にしている点は他者にも見えるのだと改めて感じました。ビジョンを語る前にメンバーとの間で気にしてる点を解消したいと思います。`,
  },
  {
    profile: `50代男性　部長`,
    text: `一部の部下の受け身姿勢が改善されないので、どうしたらよいかと思い相談しました。自分でも自覚していましたが、話しているうちに、自分のアンガーマネジメントに課題があることに改めて気づきました。どうしても、思い通りに物事が進まないとイライラしてしまいます。一方でいろいろと問いかけられ内省するうちに、イライラを収めようと何とかしようとしている自分もいることに気づいたので、継続的にこういう機会を持ちたいです。`,
  },
  {
    profile: `30代男性　係長`,
    text: `今度初めてリーダーになるので、今から備えておきたいと思い相談しました。話しているうちに、テレワークの影響で気軽に相談しやすい環境が自分の周りになく、不安に感じていたことに気づきました。正直、始まる前から不安に思うことはなく、起きてから対処することもできるので、一旦は気楽に構えようという気持ちになりましたが、こういうことを同僚や上司に相談していくことが自分にとっては必要なのだと気づかされました。`,
  },
  {
    profile: `40代女性　課長`,
    text: `今度1on1施策を全社で取り入れることになったものの、どのように進めればよいかは現場に丸投げなため、ノウハウが欲しくて相談しました。本などである程度は知識習得していたつもりでしたが、全くスタート地点にも立っていないことに気づきました。そもそも自分はマネジメントに対する苦手意識があり、その意識から変えていく必要があると思いました。まずは一つずつできることからスタートしたいと思います。`,
  },
]

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={classes.root}>
        <div className="__topBlock">
          <div className="__topBoxWrapper">
            <div className="__topBox"></div>
          </div>
          <div className="__topImg">
            <Hidden smUp>
              <img src={process.env.PUBLIC_URL + '/assets/landing/coach/top_img_sp.jpg'} alt="top_img" />
            </Hidden>
            <Hidden xsDown>
              <img src={process.env.PUBLIC_URL + '/assets/landing/coach/top_img.jpg'} alt="top_img" />
            </Hidden>
          </div>
          <div className="__topTitleBlock">
            <Typography variant="h1" className="__title">
              COACH
            </Typography>
            <Typography variant="subtitle1" className="__subTitle">
              プロの専門家にZoomでいつでもマネジメントの相談ができる
              <br />
              管理職向け1on1コーチ
              <div>
                <span>お試し版</span>
              </div>
            </Typography>
          </div>
        </div>

        <section className="__RightRadiusSection">
          <div className="__sectionBack">
            <SectionTitle
              topTitle="SOLUTION"
              mainTitle="こんなお悩みをお持ちの"
              mainTitleLine2="管理職のための1on1コーチング"
              caption="1つでも該当する方はこちらをクリック"
              setMarginBottom={0}
            />
            <Hidden smUp>
              <ApplyButton height={45} leftSide />
              {/* <DisabledButton height={45} leftSide /> */}
            </Hidden>
            <Hidden xsDown>
              <ApplyButton height={56} leftSide />
              {/* <DisabledButton height={56} leftSide /> */}
            </Hidden>

            <Grid className="__solutions" container spacing={0} justify="center">
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message_sp01.svg'} alt="solution01" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message01.svg'} alt="solution01" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/coach/solution01_img.png'}
                  alt="solution01"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message_sp02.svg'} alt="solution02" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message02.svg'} alt="solution02" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/coach/solution02_img.png'}
                  alt="solution02"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message_sp03.svg'} alt="solution03" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message03.svg'} alt="solution03" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/coach/solution03_img.png'}
                  alt="solution03"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message_sp04.svg'} alt="solution04" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message04.svg'} alt="solution04" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/coach/solution04_img.png'}
                  alt="solution04"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message_sp05.svg'} alt="solution05" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/coach/message05.svg'} alt="solution05" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/coach/solution05_img.png'}
                  alt="solution05"
                />
              </Grid>
            </Grid>
            <Hidden smUp>
              <ApplyButton height={45} />
              {/* <DisabledButton height={45} /> */}
            </Hidden>
            <Hidden xsDown>
              <ApplyButton height={56} />
              {/* <DisabledButton height={56} /> */}
            </Hidden>
          </div>
        </section>

        <section className="__LeftRadiusSection">
          <div className="__sectionBack">
            <SectionTitle topTitle="POINT" mainTitle="管理職向け1on1コーチ" mainTitleLine2="3つのポイント" />
            <Grid className="__points" container spacing={0} justify="center">
              <Grid className="__point" item xs={12} sm={6} md={4}>
                <Typography className="__pointTitle01">POINT 01</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/coach/point01.svg'} alt="point01" />
                </div>
                <p className="__pointDescription">
                  話すことで自分の心の中を
                  <br />
                  整理しよう
                </p>
              </Grid>
              <Grid className="__pointMiddle" item xs={12} sm={6} md={4}>
                <Typography className="__pointTitle02">POINT 02</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/coach/point02.svg'} alt="point02" />
                </div>
                <p className="__pointDescription">
                  自分のマネジメントを
                  <br />
                  客観的にとらえる機会を持とう
                </p>
              </Grid>
              <Grid className="__point" item xs={12} sm={6} md={4}>
                <Typography className="__pointTitle03">POINT 03</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/coach/point03.svg'} alt="point03" />
                </div>
                <p className="__pointDescription">
                  他者事例やマネジメントの
                  <br />
                  ポイントから
                  <br />
                  自分のやり方を探そう
                </p>
              </Grid>
            </Grid>
          </div>
        </section>

        <div className="__fullWidthBackBLTop">
          <section className="__fullWidthSection">
            <SectionTitle topTitle="VOICE" mainTitle="お試し版利用者の声" />
            <Grid className={classes.items} container spacing={isMdUp ? 4 : isSmUp ? 2 : 4}>
              {items.map((item, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <div className="__item">
                      <div className="__itemHead">
                        <div className="__iconWrapper">
                          <PersonIcon className="__icon" />
                        </div>
                        <div className="__profile">{item.profile}</div>
                      </div>
                      <div className="__text">{item.text}</div>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </section>
        </div>

        <div className="__fullWidthBackWH">
          <section className="__fullWidthSection">
            <SectionTitle topTitle="CONTACT" mainTitle="管理職向け1on1コーチの" mainTitleLine2="料金とお問い合わせ" />
            <Grid className="__contact" container spacing={0} justify="center">
              <Grid className="__table01" item sm={12} md={6}>
                <div className="__th">料金</div>
                <div className="__tb">
                  <div className="__label">
                    <div className="__labelLeft">お試し期間</div>
                    <div className="__labelRight">
                      <span>無料</span>
                      <Hidden smUp>
                        <br />
                      </Hidden>
                      （1回30分×2回まで）
                    </div>
                  </div>
                  <div className="__borderBox">
                    <div className="__border"></div>
                  </div>
                  <p className="__annotation">
                    ※お試し期間中につき、月5名まで。
                    <br />
                    申し込み先着順とさせていただきます。結果につきましてはお申込みの方全員にお申込日より3営業日以内に必ずご連絡させていただきます。
                  </p>
                </div>
              </Grid>
              <Grid className="__table02" item sm={12} md={6}>
                <div className="__th">お申し込み</div>
                <div className="__tb">
                  <p>
                    申し込みフォームよりお申込み下さい。追って日程の調整のご連絡をさせていただきます。
                    <br />
                    コーチングはZoomを使用したオンラインとなります。あらかじめご了承下さい。
                  </p>
                </div>
              </Grid>
            </Grid>
          </section>
        </div>

        <div className="__fullWidthBackBL">
          <section className="__fullWidthSection">
            <SectionTitle topTitle="PROFILE" mainTitle="コーチプロフィール" />
            <Grid className="__profile" container spacing={0} justify="center">
              <Grid className="__profileImg" item sm={12} md={6}>
                <img src={process.env.PUBLIC_URL + '/assets/landing/coach/coach_profile.jpg'} alt="coach_profile" />
              </Grid>
              <Grid className="__profileDescription" item sm={12} md={6}>
                <Typography className="__name">
                  桐山　恭子（きりやま　きょうこ）
                  <br />
                  <span>米国CCE.Inc.認定　キャリアカウンセラー</span>
                </Typography>
                <p>
                  大学卒業後、採用コンサルタントとして多種多様な企業の新卒採用企画及び新人研修をプロデュースする他、採用セミナー講師、キャリアカウンセリングなどの経験を経て2006年より人材開発コンサルタントとして主に企業の人材育成・人材開発の研修プログラム開発責任者として従事するとともに、新人研修から管理職研修まで幅広い階層にてファシリテーターとして活躍。
                  <br />
                  今までの自分の仕事の仕方や自分自身の在り方など自己と向き合い、新しい役割・新しい自分に対して能力を発揮していくための内省系の研修を主に得意としている。他、アセスメント研修での幹部候補・役員候補のアセッサーや、360度研修などのグループコーチ、個別面談におけるコーチングなども多数実施。3人の子供の育休からの復帰経験あり。
                </p>
              </Grid>
            </Grid>
          </section>
        </div>

        <div className="__endButton">
          <Hidden smUp>
            <ApplyButton height={45} />
            {/* <DisabledButton height={45} /> */}
          </Hidden>
          <Hidden xsDown>
            <ApplyButton height={56} />
            {/* <DisabledButton height={56} /> */}
          </Hidden>
        </div>
      </div>
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      backgroundColor: constants.COLOR_GRAY,
      '& .__topBlock': {
        position: 'relative',
        '& .__topBoxWrapper': {
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          '& .__topBox': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        '& .__topBoxWrapper:before': {
          content: "''",
          display: 'block',
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            paddingTop: '55%',
          },
          [theme.breakpoints.up('sm')]: {
            paddingTop: '28%',
          },
        },
        '& .__topImg': {
          position: 'relative',
          zIndex: 2,
          textAlign: 'right',
          '& img': {
            [theme.breakpoints.down('xs')]: {
              width: '69%',
            },
            [theme.breakpoints.up('sm')]: {
              width: '80%',
            },
            borderRadius: '0 0 0 28px',
          },
        },
        '& .__topTitleBlock': {
          position: 'absolute',
          zIndex: 3,
          [theme.breakpoints.down('xs')]: {
            top: '30%',
            left: '4%',
          },
          [theme.breakpoints.up('sm')]: {
            top: '35%',
            left: '11%',
          },
          '& .__title': {
            [theme.breakpoints.down('xs')]: {
              fontSize: 64,
              letterSpacing: 1.2,
            },
            [theme.breakpoints.up('sm')]: {
              fontSize: 80,
              letterSpacing: 1.7,
            },
            fontWeight: 'bold',
            color: constants.COLOR_MAIN,
          },
          '& .__subTitle': {
            marginLeft: 8,
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
              letterSpacing: 1.4,
            },
            [theme.breakpoints.up('sm')]: {
              fontSize: 18,
              letterSpacing: 1.5,
            },
            fontWeight: 'bold',
            color: constants.COLOR_MAIN,
            '& div': {
              marginLeft: 15,
              display: 'inline-block',
              transform: 'skewX(30deg)',
              backgroundColor: '#FC9B00',
              [theme.breakpoints.down('xs')]: {
                padding: '.03em .54em',
                fontSize: 12,
              },
              [theme.breakpoints.up('sm')]: {
                padding: '.03em .8em',
                fontSize: 14,
              },
              color: constants.COLOR_WHITE,
              '& span': {
                display: 'inline-block',
                transform: 'skewX(-30deg)',
              },
            },
          },
        },
      },

      '& .__RightRadiusSection': {
        [theme.breakpoints.down('xs')]: {
          padding: '50px 7% 0 0',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '80px 3% 0 0',
        },
        [theme.breakpoints.up('md')]: {
          padding: '80px 8% 0 0',
        },
        '& .__sectionBack': {
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            padding: '14% 5% 14% 5%',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '5% 10% 5% 13%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '5% 10% 5% 18%',
          },
          borderRadius: '0 30px 30px 0',
        },
      },
      '& .__LeftRadiusSection': {
        [theme.breakpoints.down('xs')]: {
          padding: '50px 0 0 7%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '80px 0 0 3%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '80px 0 0 8%',
        },
        '& .__sectionBack': {
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            padding: '14% 5% 14% 5%',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '5% 13% 5% 10%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '5% 18% 5% 10%',
          },
          borderRadius: '30px 0 0 30px',
        },
      },
      '& .__fullWidthBackWH': {
        backgroundColor: constants.COLOR_WHITE,
      },
      '& .__fullWidthBackBL': {
        backgroundColor: '#F4FCFC',
      },
      '& .__fullWidthBackBLTop': {
        backgroundColor: '#F4FCFC',
        [theme.breakpoints.down('xs')]: {
          marginTop: '50px',
        },
        [theme.breakpoints.up('sm')]: {
          marginTop: '80px',
        },
      },
      '& .__fullWidthSection': {
        [theme.breakpoints.down('xs')]: {
          padding: '50px 5%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '5% 13% 5% 13%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '5% 18% 5% 18%',
        },
        borderRadius: '30px 0 0 30px',
      },

      '& .__solutions': {
        backgroundColor: '#E9F8F9',
        borderRadius: '10px',
        [theme.breakpoints.down('xs')]: {
          padding: '3%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '2%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '3%',
        },
        '& .__solution': {
          position: 'relative',
          [theme.breakpoints.down('xs')]: {
            padding: '5px 5px 20px 5px',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '20px',
          },
          '& .__balloon': {
            position: 'relative',
            '& img': {
              width: '100%',
            },
          },
          '& .__img': {
            position: 'absolute',
            [theme.breakpoints.down('xs')]: {
              top: '60%',
              left: '21%',
              width: '33%',
            },
            [theme.breakpoints.up('sm')]: {
              top: '50%',
              left: '50%',
              width: '40%',
            },
            transform: 'translateX(40%) translateY(0%)',
          },
        },
      },

      '& .__points': {
        [theme.breakpoints.down('xs')]: {
          padding: '0 1%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '0 2%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '0 2%',
        },
        '& .__point': {
          textAlign: 'center',
          padding: '0 4%',
          [theme.breakpoints.down('xs')]: {
            boxShadow: '1px 1px 5px #dadada',
            borderRadius: '10px',
            padding: '8% 14%',
            marginBottom: 16,
          },
          [theme.breakpoints.up('sm')]: {
            padding: '3% 5%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '0 4%',
          },

          '& .__pointTitle01': {
            [theme.breakpoints.down('xs')]: {
              marginBottom: '10px',
            },
            [theme.breakpoints.up('sm')]: {
              marginBottom: '16px',
            },
            color: '#FFCF00',
            fontSize: 20,
            fontWeight: 'bold',
          },
          '& .__pointTitle03': {
            marginBottom: '16px',
            color: '#48C13A',
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        '& .__pointMiddle': {
          textAlign: 'center',
          [theme.breakpoints.down('xs')]: {
            boxShadow: '1px 1px 5px #dadada',
            borderRadius: '10px',
            padding: '8% 14%',
            marginBottom: 16,
          },
          [theme.breakpoints.up('sm')]: {
            padding: '3% 5%',
          },
          [theme.breakpoints.up('md')]: {
            borderRight: 'solid 1px #DDDDDD',
            borderLeft: 'solid 1px #DDDDDD',
            padding: '0 4%',
          },
          '& .__pointTitle02': {
            marginBottom: '16px',
            color: '#2CB6FF',
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        '& img': {
          width: '100%',
        },
        '& .__pointDescription': {
          marginTop: '15px',
          marginBottom: 0,
        },
      },

      '& .__contact': {
        '& .__table01': {
          border: `solid 1px #2CBBC6`,
          [theme.breakpoints.down('sm')]: {
            borderRadius: '10px 10px 0 0',
          },
          [theme.breakpoints.up('md')]: {
            borderRight: 'solid 1px #ffffff',
            borderRadius: '10px 0 0 10px',
          },
          '& .__th': {
            backgroundColor: constants.COLOR_MAIN,
            color: constants.COLOR_WHITE,
            fontSize: '17px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '18px 0',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '10px 10px 0 0',
            },
            [theme.breakpoints.up('md')]: {
              borderRadius: '10px 0 0 0',
            },
          },
          '& .__tb': {
            [theme.breakpoints.down('xs')]: {
              padding: '9% 4.5%',
            },
            [theme.breakpoints.up('sm')]: {
              padding: '8%',
            },
            '& .__label': {
              width: '100%',
              [theme.breakpoints.down('xs')]: {
                height: '80px',
              },
              [theme.breakpoints.up('sm')]: {
                height: '60px',
              },
              display: 'table',
              '& .__labelLeft': {
                position: 'relative',
                padding: '20px 1px',
                display: 'table-cell',
                textAlign: 'center',
                verticalAlign: 'middle',
                backgroundColor: '#FC9B00',
                color: constants.COLOR_WHITE,
                fontSize: 13,
                fontWeight: 'bold',
              },
              '& .__labelLeft:after': {
                position: 'absolute',
                content: '""',
                top: '50%',
                right: '-12px',
                marginTop: '-6px',
                border: '14px solid transparent',
                borderLeft: '14px solid #FC9B00',
                borderWidth: '7px 0 7px 14px',
                zIndex: '1',
              },
              '& .__labelRight': {
                padding: '8px auto',
                display: 'table-cell',
                textAlign: 'center',
                verticalAlign: 'middle',
                backgroundColor: 'rgba(252,155,0,0.1)',
                [theme.breakpoints.down('xs')]: {
                  fontSize: 14,
                },
                [theme.breakpoints.up('sm')]: {
                  fontSize: 16,
                },
                '& span': {
                  fontSize: 18,
                  color: '#FC9B00',
                  fontWeight: 'bold',
                },
              },
            },
            '& .__borderBox': {
              display: 'flex',
              alignItems: 'center',
              padding: '30px 0 13px 0',
              '& .__border': {
                borderTop: '2px dotted #DDDDDD',
                content: '',
                width: '100%',
              },
            },
            '& .__annotation': {
              marginBottom: 0,
              textAlign: 'justify',
            },
          },
        },
        '& .__table02': {
          border: `solid 1px #2CBBC6`,
          [theme.breakpoints.up('md')]: {
            borderRadius: '0 10px 10px 0',
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: '0 0 10px 10px',
          },
          '& .__th': {
            backgroundColor: constants.COLOR_MAIN,
            color: constants.COLOR_WHITE,
            fontSize: '17px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '18px 0',
            [theme.breakpoints.up('md')]: {
              borderRadius: '0 10px 0 0',
            },
          },
          '& .__tb': {
            [theme.breakpoints.down('xs')]: {
              padding: '9% 4.5%',
            },
            [theme.breakpoints.up('sm')]: {
              padding: '8%',
            },
            '& p': {
              margin: 0,
              textAlign: 'justify',
            },
          },
        },
      },

      '& .__profile': {
        '& .__profileImg': {
          [theme.breakpoints.up('md')]: {
            paddingRight: 30,
          },
          [theme.breakpoints.down('sm')]: {
            paddingBottom: 30,
          },
          '& img': {
            width: '100%',
          },
        },
        '& .__profileDescription': {
          '& .__name': {
            fontWeight: 'bold',
            '& span': {
              color: constants.COLOR_MAIN,
              fontWeight: 'normal',
            },
          },
          '& p': {
            textAlign: 'justify',
          },
        },
      },

      '& .__buttonBox': {
        paddingTop: 33,
        display: 'flex',
        justifyContent: 'center',
        '& .__button': {
          width: '100%',
          maxWidth: 337,
        },
      },
      '& .__buttonBoxLeft': {
        [theme.breakpoints.down('xs')]: {
          paddingTop: 5,
          paddingBottom: 20,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: 10,
          paddingBottom: 50,
        },
        '& .__button': {
          width: '100%',
          maxWidth: 337,
        },
      },
      '& .__endButton': {
        backgroundColor: constants.COLOR_WHITE,
        paddingBottom: 100,
        [theme.breakpoints.down('xs')]: {
          padding: '0 10% 84px',
        },
      },
      '& .__disabledText': {
        color: constants.COLOR_RED2,
        textAlign: 'center',
      },
    },
    items: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .__item': {
        backgroundColor: constants.COLOR_WHITE,
        border: '1px solid ' + constants.COLOR_GRAY_LIGHT,
        padding: 18,
        height: '100%',
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
        },
      },
      '& .__itemHead': {
        display: 'flex',
        justifyContent: 'center',
        '& .__iconWrapper': {
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          '& .__icon': {
            fontSize: 48,
            color: '#524141',
          },
        },
        '& .__profile': {
          flex: 3,
          display: 'flex',
          alignItems: 'center',
        },
      },
      '& .__text': {
        margin: '15px 0 0',
        fontSize: '14px',
        lineHeight: 1.643,
        textAlign: 'justify',
        whiteSpace: 'pre-wrap',
      },
    },
  })

export default withStyles(useStyles)(Index)
