import Auth from '@aws-amplify/auth'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../store'
import { UserActions } from '../stores/user/action'

import Component from './SignoutButtonNew.component'

export interface ISignOutButtonActions {
  signOut: () => void
}

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => {
  return {
    signOut: () => {
      dispatch(UserActions.signout.started({ params: {} }))

      Auth.signOut()
        .then(() => {
          dispatch(
            UserActions.signout.done({
              result: {},
              params: {},
            })
          )
        })
        .catch((err) => {
          dispatch(
            UserActions.signout.failed({
              error: err,
              params: {},
            })
          )
        })
    },
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
