import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import 'date-fns'
import format from 'date-fns/format'

import * as constants from '../../../../../assets/constants'
import { IActionItem } from '../../../../../stores/setupaction/reducer'
import { OnboardingActionCategory } from '../../../../../utils/generated'
import { OnboardingActionDeadline } from '../../../utils/domain'
import { onboardingActionCategoryLabel } from '../../../utils/onboardingActionCategoryLabel'
import { onboardingFeedbackLabel } from '../../../utils/onboardingFeedbackLabel'

import checkCircle from '../assets/check-circle.svg'
import clockBlack from '../assets/clock-black.svg'
import clockWhite from '../assets/clock-white.svg'
import hourglass from '../assets/hourglass.svg'
import IconLeft from '../assets/Icons-ArrowLeft.svg'

interface Props {
  item: IActionItem
  basePeriod: number
  onActionDetail?: () => void
  ttRef?: React.MutableRefObject<HTMLElement | null>
  addDay?: number
  isBeforeStart?: boolean
}

export const Card: React.FC<Props> = (props) => {
  const classes = useStyles()

  const getAlert = () => {
    if (props.item.period) {
      const dayBeforePeriod = new Date(props.item.period)
      const period = new Date(dayBeforePeriod.setDate(dayBeforePeriod.getDate() + 1)) // that day is WARNING, not ALERT.
      const warning = new Date(props.item.period - 1000 * 60 * 60 * 24)
      const basePeriod = new Date(props.basePeriod)

      if (period <= basePeriod) {
        return OnboardingActionDeadline.ALERT
      }
      if (warning <= basePeriod && period >= basePeriod) {
        return OnboardingActionDeadline.WARNING
      }

      return OnboardingActionDeadline.DEFAULT
    }
  }

  const actionDetail = (
    <div className={classes.bottom}>
      アクション詳細
      <span>
        <img src={IconLeft} alt="icon" />
      </span>
    </div>
  )

  return (
    <Paper
      className={classes.root}
      onClick={props.onActionDetail}
      ref={props.ttRef ? (props.ttRef as React.MutableRefObject<HTMLDivElement>) : null}
    >
      <div className={`__list_card_container`}>
        <div className="__list_card_container__content">
          <div className="__list_card_container__content__body">
            <div className={`${classes.top}${props.addDay !== undefined ? ' ' + classes.topStandby : ''}`}>
              <div className={classes.categoryBox}>
                <div className="__category">
                  {onboardingActionCategoryLabel(props.item.category as OnboardingActionCategory)}
                </div>
                {props.addDay !== undefined ? (
                  <div className={classes.standbyWrapper}>
                    <div className={classes.missionStandby}>{props.item.mission}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {props.isBeforeStart ? (
                actionDetail
              ) : (
                <>
                  {props.item.missionValues ? (
                    <div className="__period __evaluation">
                      自己評価：{onboardingFeedbackLabel(props.item.missionValues.value)}
                    </div>
                  ) : (
                    <>
                      {props.addDay !== undefined ? (
                        <div className={classes.addDayWrapper}>
                          <div className="__period __standby">
                            <img src={hourglass} alt="hourglass icon" className={classes.periodIcon} />
                            追加日：{format(props.addDay, 'yyyy年MM月dd日')}
                          </div>
                        </div>
                      ) : getAlert() === OnboardingActionDeadline.ALERT ? (
                        <div className="__period __aliert">
                          <img src={clockWhite} alt="clock icon" className={classes.periodIcon} />
                          期限：{props.item.period ? format(props.item.period, 'yyyy年MM月dd日') : '____'}
                        </div>
                      ) : getAlert() === OnboardingActionDeadline.WARNING ? (
                        <div className="__period __warning">
                          <img src={clockBlack} alt="clock icon" className={classes.periodIcon} />
                          期限：{props.item.period ? format(props.item.period, 'yyyy年MM月dd日') : '____'}
                        </div>
                      ) : (
                        <div className="__period __normal">
                          <img src={clockBlack} alt="clock icon" className={classes.periodIcon} />
                          期限：{props.item.period ? format(props.item.period, 'yyyy年MM月dd日') : '____'}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {props.addDay === undefined ? (
              <>
                <div className={classes.center}>
                  {props.item.missionValues ? (
                    <img src={checkCircle} alt="check icon" className={classes.checked} />
                  ) : (
                    <></>
                  )}
                  {props.item.mission}
                </div>
                {actionDetail}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '16px',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 8,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    minWidth: 0,
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .__list_card_container': {
      padding: '16px',

      '&.statusClose': {
        background: constants.COLOR_ACTION_CLOSE,
      },
      '&.statusFailed': {
        background: constants.COLOR_ACTION_FAILED,
      },

      '&__content': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '&__body': {
          width: '100%',

          '&__ratings': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 4,
            '& .MuiRating-root': {
              marginRight: 8,
            },

            '&__icon': {
              width: '10px',
              height: '10px',
              margin: '0 4px',
            },
            '&__description': {
              fontSize: '10px',
              fontWeight: 'bold',
              '&.statusClose': {
                color: constants.COLOR_ACTION_CLOSE_DARK,
              },
              '&.statusFailed': {
                color: constants.COLOR_ACTION_FAILED_DARK,
              },
            },
          },
          '&__title': {
            fontSize: '16px',
            fontWeight: 'bold',
          },
        },

        '&__action': {
          background: constants.COLOR_MAIN_LIGHT,

          minWidth: '40px',
          minHeight: '40px',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
          '&.active': {
            backgroundColor: '#B9FCFF',
          },
          '&__value': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: constants.COLOR_MAIN_DARK,
          },
          '&__valueIcon': {
            width: '100%',
            padding: '4px',
          },
        },
      },

      '&__moreContent': {
        '&.statusClose': {
          background: constants.COLOR_ACTION_CLOSE,
        },
        '&.statusFailed': {
          background: constants.COLOR_ACTION_FAILED,
        },

        '&__collapse': {
          '& .MuiCollapse-wrapper': {
            padding: '16px',
          },
          '&__title': {
            color: constants.COLOR_MAIN,
            borderBottom: '1px solid',
            marginBottom: '8px',
            paddingBottom: '4px',
          },
          '&__description': {
            paddingBottom: '16px',
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },

    '& .__list_card_more': {
      background: constants.TEXT_GRAY,
      padding: '4px 16px',
      color: constants.COLOR_WHITE,
      cursor: 'pointer',
      textAlign: 'center',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',

      '& i': {
        marginRight: '4px',
      },
    },
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 14,
    marginBottom: 8,
    '& .__category': {
      boxSizing: 'border-box',
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 4,
      padding: '5px 8px',
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 10,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    '& .__period': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
      padding: '5px 8px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      '&.__evaluation': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        color: constants.COLOR_WHITE,
      },
      '&.__standby': {
        backgroundColor: constants.COLOR_WHITE2,
      },
      '&.__aliert': {
        backgroundColor: constants.COLOR_ONBOARDING_ALERT,
        color: constants.COLOR_WHITE,
      },
      '&.__warning': {
        backgroundColor: constants.COLOR_ONBOARDING_WARNING,
      },
      '&.__normal': {
        backgroundColor: constants.COLOR_ONBOARDING_SKY_BLUE,
      },
    },
  },
  topStandby: {
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  categoryBox: {
    flex: 1,
    display: 'flex',
    columnGap: 14,
    minWidth: 0,
  },
  standbyWrapper: {
    minWidth: 0,
  },
  missionStandby: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  addDayWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 4,
    },
  },
  periodIcon: {
    marginRight: 4,
  },
  center: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 24,
  },
  bottom: {
    textAlign: 'right',
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 12,
    fontWeight: 'bold',
    '& span': {
      marginLeft: 8,
    },
  },

  checked: {
    position: 'relative',
    top: 3,
    marginRight: 6,
  },

  paper: {
    margin: theme.spacing(1),
  },

  container: {
    display: 'flex',
  },

  reactionSummary: {
    color: constants.COLOR_MAIN,
    marginBottom: 8,
    marginRight: 16,
    textAlign: 'right',
    '& i': {
      marginRight: 4,
    },
    '& span': {
      marginRight: 12,
    },
  },

  reactions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  actionItemLeft: {
    flex: 1,
    marginRight: 4,
  },
  actionItemRight: {
    flex: 1,
    marginLeft: 4,
  },

  toTimeline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },

  reportButtonContainer: {
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,

    '& .__modalPaper': {
      background: constants.COLOR_GRAY,
      padding: theme.spacing(2),
      width: '100%',
      margin: '0 16px',
      maxHeight: '90vh',
      overflow: 'scroll',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_SMALL,
      },
      '&:focus': {
        outline: 'none',
      },

      '&__title': {
        color: constants.COLOR_MAIN,
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '16px 0',
        textAlign: 'center',
      },
    },
  },
}))
