import React, { Dispatch, SetStateAction } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import arrowUnder from '../../assets/arrowUnder.svg'
import arrowUp from '../../assets/arrowUp.svg'

import * as constants from 'assets/constants'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const HandleDetailOpenText: React.FC<Props & StyleProps> = ({ open, setOpen, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  //  handleDetail
  const onClick = () => {
    setOpen((prev) => !prev)
  }

  return (
    <div className={classes.detailClickText} onClick={onClick}>
      <div className={classes.text}>{open ? '閉じる' : '詳細'}</div>
      <img className={classes.img} src={open ? arrowUp : arrowUnder} alt={'arrowIcon'} />
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    detailClickText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      margin: '0 0 0 8px',
      whiteSpace: 'nowrap',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    text: {
      fontWeight: 'bold',
      margin: '0 8px 0 0',
    },
    img: {
      display: 'block',
    },
  }),
  { name: 'HandleDetailOpenText' }
)
