import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type Props = {
  buttons: React.ReactNode[]
  fixed?: boolean
  marginTopPcLayout?: number
}

export const ButtonContainer: React.FC<Props> = (props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ fixed: props.fixed, isSmDown, marginTop: props.marginTopPcLayout ?? 6 })

  return (
    <div className={classes.root}>
      {(() => {
        switch (props.buttons.length) {
          case 1:
            return <div className={classes.base}>{props.buttons[0]}</div>

          case 2:
            return (
              <div className={`${classes.base} ${classes.double}`}>
                <div className={classes.doubleLeft}>{props.buttons[1]}</div>
                <div className={classes.doubleRight}>{props.buttons[0]}</div>
              </div>
            )
          default:
            return <div className={classes.base}>{props.buttons}</div>
        }
      })()}
    </div>
  )
}

type StyleProps = {
  fixed?: boolean
  isSmDown?: boolean
  marginTop?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ fixed, marginTop, isSmDown }: StyleProps) => ({
    width: fixed && isSmDown ? '100vw' : undefined,
    left: fixed && isSmDown ? 0 : undefined,
    bottom: fixed && isSmDown ? 0 : undefined,
    position: fixed && isSmDown ? 'fixed' : undefined,
    display: fixed && isSmDown ? 'flex' : undefined,
    justifyContent: 'center',
    padding: fixed && isSmDown ? '30px 16px 30px' : '0 16px',
    backgroundColor: fixed && isSmDown ? '#eeeeee70' : undefined,

    [theme.breakpoints.down('xs')]: {
      padding: fixed ? '16px 16px 16px' : '0 16px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop,
    },
  }),

  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  double: {
    [theme.breakpoints.up('sm')]: {
      gap: '32px 24px',
      flexWrap: 'wrap-reverse',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },

  doubleLeft: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  doubleRight: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 16,
      width: '100%',
    },
  },
}))
