import React from 'react'

import { mutationConvertTeamMember } from './graphql'

export const useConvertTeamMember = () => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const convertTeamMember = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await mutationConvertTeamMember()
      return response
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return { convertTeamMember, loading, error } as const
}
