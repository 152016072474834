import { useCallback, useState } from 'react'

import { GhostReport } from 'utils/generated'

import { getGhostReports } from '../api/report/handlers'
import { callDialog } from '../components/organisms/Dialog'

export const useFetchReports = () => {
  const [reports, setReports] = useState<GhostReport[]>([])
  const [isFetchingReports, setIsFetchingReports] = useState<boolean>(false)

  const fetchReports = useCallback(async (teamId: string) => {
    if (!teamId) return

    setIsFetchingReports(true)

    try {
      const response = await getGhostReports({ teamId })
      if (response?.items && response.items.length > 0) {
        setReports(response.items as GhostReport[])
      }
    } catch (e) {
      callDialog('reload')
    } finally {
      setIsFetchingReports(false)
    }
  }, [])
  return { fetchReports, reports, isFetchingReports } as const
}
