import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { AnyAction } from 'typescript-fsa'

import { RootState } from '../../../../stores/reducers'

import action from './action'
import { karteGet } from './api'

const fetchEpic: Epic<AnyAction, AnyAction, RootState> = (action$, store) =>
  action$.pipe(
    filter(action.fetch.started.match),
    exhaustMap(({ payload }) =>
      from(karteGet({ id: payload.id })).pipe(
        map((res) => {
          return action.fetch.done({ params: payload, result: res })
        }),
        catchError((error) => of(action.fetch.failed({ params: payload, error })))
      )
    )
  )

const fetchSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (action$, store) =>
  action$.pipe(
    filter(action.fetch.done.match),
    filter(({ payload }) => payload.result && !payload.result.name),
    map(({ payload }) => {
      payload.params.successCallback() // TODO: make direct push to navigation history
      return action.empty({})
    })
  )

export default combineEpics(fetchEpic, fetchSuccessEpic)
