import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  ownStyles?: React.CSSProperties
}

export const Balloon: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        color: constGhost.COLOR_BLACK3,
        fontSize: 14,
        backgroundColor: constGhost.COLOR_LIGHTGRAY2,
        padding: 16,
        borderRadius: 8,
        textAlign: 'left',
        wordBreak: 'break-all',
        ...props.ownStyles,
      }}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: 'pre-line',
  },
}))
