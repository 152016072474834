import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import format from 'date-fns/format'

import { NoStyleButton } from 'components/NoStyleButton'
import ProfileIconSmall from 'pages/onboarding/components/header/assets/ProfileIconSmall.svg'
import { useTeamBuildingMembers } from 'pages/teams/hooks'
import { TeamBuilding } from 'utils/generated'

import { constants, Pages } from 'assets'
import { replacePathParams } from 'assets/history'

type Props = {
  teamBuilding: TeamBuilding
  teamId: string
}

export const TeamBuildingInProgress: React.FC<Props> = ({ teamBuilding, teamId }) => {
  const classes = useStyles()
  const {
    teamBuildingMembers,
    refresh: refreshTeamBuilding,
    loading: isLoadingMembers,
  } = useTeamBuildingMembers(teamId)

  React.useEffect(() => {
    refreshTeamBuilding()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.headingWrapper}>
        <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_alert_red.svg`} alt="" />
        <span>実施中のキックオフがあります</span>
      </div>

      <div className={classes.contentsWrapper}>
        <div className={classes.statusWrapper}>
          <div className={classes.chipWrapper}>
            {teamBuilding?.createdAt && (
              <div className={`${classes.chip} ${classes.dateChip}`}>
                <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/icon_clock.svg`} alt="" />
                <span>{format(new Date(teamBuilding.createdAt), 'yyyy/MM/dd hh:mm')}</span>
              </div>
            )}
            <div className={`${classes.chip} ${classes.inProgressChip}`}>実施中</div>
          </div>
          <div className={classes.membersWrapper}>
            <div className={classes.memberListHeading}>実施メンバー：</div>
            {isLoadingMembers ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress size={20} className="root" />
              </div>
            ) : (
              <div className={classes.memberList}>
                {teamBuildingMembers.map(
                  (member) =>
                    member.teamMember?.fullName && (
                      <div key={member.id} className={classes.member}>
                        <img src={member.user?.imageUrl ?? ProfileIconSmall} alt="" />
                        <span>{member.teamMember?.fullName}</span>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.linkButtonWrapper}>
          <NoStyleButton
            className={classes.linkButton}
            onClick={() => window.open(replacePathParams(Pages.TeamsToolBuilding, { teamId }))}
          >
            キックオフを再開する
          </NoStyleButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontWeight: 'bold',
    },

    headingWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginBottom: 16,

      '& span': {
        color: constants.COLOR_DANGER,
      },
    },

    contentsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 16,
      padding: 16,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 24,
        backgroundColor: constants.COLOR_WHITE,
      },
    },

    statusWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    // キックオフ開始日時・実施中 chip
    chipWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    chip: {
      padding: '4px 8px',
      borderRadius: 50,
      fontSize: 10,
    },
    dateChip: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      backgroundColor: constants.COLOR_WHITE,

      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      },
    },
    inProgressChip: {
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },

    // 実施メンバー
    membersWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    memberListHeading: {
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
    memberList: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 24,
      fontSize: 12,
    },
    member: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,

      '& img': {
        width: 24,
        height: 24,
        borderRadius: '50%',
      },
      '& span': {
        width: '100%',
      },
    },

    loadingWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },

      '& .root': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
    },

    // キックオフ再開ボタン
    linkButtonWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
    linkButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 16px',
      maxWidth: 160,
      width: 160,
      height: 40,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      backgroundColor: constants.COLOR_WHITE,
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 20,

      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.7,
        },
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 0,
      },
    },
  }),
  { name: 'TeamBuildingInProgress' }
)
