import * as React from 'react'
import { useContext } from 'react'

import { makeStyles } from '@material-ui/core'

import { ButtonForGhost } from '../../components/atoms'
import { GhostTemplateBoard, GhostTabs, ReportedGhost } from '../../components/organisms'
import { GhostContext } from '../../contexts/ghost'

type Props = {
  ownStyles?: React.CSSProperties
  closeModal: () => void
}

export const GhostList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { ghosts } = useContext(GhostContext)
  const foundGhostsOnly = ghosts.filter((g) => g.found)

  return (
    <GhostTabs
      childrenForLeft={
        <div className={classes.root}>
          <ReportedGhost countOf="ghostAmount" />
          <GhostTemplateBoard ghosts={foundGhostsOnly} />
          <ButtonForGhost
            buttonBodyColor="white"
            bodyText="閉じる"
            ownStyles={{ marginBottom: 24 }}
            isResizeBasic
            onClick={props.closeModal}
          />
        </div>
      }
      childrenForRight={
        <div className={classes.root}>
          <ReportedGhost countOf="ghostType" />
          <GhostTemplateBoard ghosts={ghosts} />
          <ButtonForGhost
            buttonBodyColor="white"
            bodyText="閉じる"
            ownStyles={{ marginBottom: 24 }}
            isResizeBasic
            onClick={props.closeModal}
          />
        </div>
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}))
