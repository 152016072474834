import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { useForm, useFieldArray } from 'react-hook-form'

import { convertMasterItemToTeamBuildingAction } from 'pages/teams/contexts/HooksContextBuilding'
import { useTeamBuildingMembers } from 'pages/teams/hooks'
import {
  mutationCreateTeamBuildingAction,
  mutationUpdateTeamBuildingAction,
  mutationCreateTeamBuildingActionDates,
  queryGetTeamBuildingAction,
  queryGetTeamBuildingActionDates,
  mutationRefreshTeamBuilding,
} from 'pages/teams/hooks/graphql'
import {
  TeamBuildingActionDate,
  TeamBuildingAction,
  TeamBuildingActionDateStatus,
  IndexGetTeamBuildingActionDates,
  TeamBuildingMember,
  Master,
} from 'utils/generated'

import {
  Select,
  InputCard,
  ScheduleForm,
  FormLabel,
  FormError,
  InputText,
  InputTextarea,
  Button,
  BackToIndex,
  ActionDescriptions,
} from '../../../buildings/components'
import { ContentButton } from '../generic/ContentButton'


import { constants } from 'assets'

type Props = {
  teamId: string
  actionId?: string
  defaultActionMasters: Master[]
  onEditClose: () => void
  onEditEnd: () => void
}

export const ActionEdit: React.FC<Props> = (props) => {
  const classes = useStyles()

  const [teamBuildingAction, setTeamBuildingAction] = React.useState<TeamBuildingAction | undefined>(undefined)
  const [teamBuildingActionDates, setTeamBuildingActionDates] = React.useState<TeamBuildingActionDate[]>([])
  const [selectedActionId, setSelectedActionId] = React.useState<any | null>(null)

  const onChangeSelectMaterAction = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedActionId(e.target.value)
  }, [])
  const converted = React.useMemo(
    () => convertMasterItemToTeamBuildingAction(props.defaultActionMasters, props.teamId),
    [props]
  )

  const masterActionOptions = React.useMemo(() => {
    return [
      { label: '自分で入力する', value: '' },
      ...converted.map((action) => {
        return {
          label: action.name ?? '',
          value: action.id,
        }
      }),
    ]
  }, [converted])

  const getData = async (teamBuildingActionId: string) => {
    const fetchedTeamBuildingAction = await queryGetTeamBuildingAction({ id: teamBuildingActionId })

    if (fetchedTeamBuildingAction) {
      setTeamBuildingAction(fetchedTeamBuildingAction)
    }

    const fetchedTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
      id: teamBuildingActionId,
      index: IndexGetTeamBuildingActionDates.TeamBuildingActionId,
    })

    if (fetchedTeamBuildingActionDates) {
      setTeamBuildingActionDates(fetchedTeamBuildingActionDates?.items)
    }
  }

  React.useEffect(() => {
    if (props.actionId) {
      return
    }
    setTeamBuildingAction(converted.find(({ id }) => id === selectedActionId))
  }, [props, converted, selectedActionId])

  React.useEffect(() => {
    if (props.actionId) {
      getData(props.actionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { teamBuildingMembers } = useTeamBuildingMembers(props.teamId)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = () => {
    console.log('handleBack')
    props.onEditClose()
  }
  const handleEditEnd = () => {
    console.log('handleEditEnd')
    props.onEditEnd()
  }
  // if (!loadedTeamBuilding || loadingTeam) {
  //   return <LoadingCircular loading={true} />
  // }

  return (
    <>
      <div className={classes.container}>
        <main className={classes.main}>
          <div className={classes.headingWrap}>
            <BackToIndex onClick={() => handleBack()} />
            <h2 className={classes.heading}>{props.actionId ? 'アクション編集' : '新規アクション追加'}</h2>
            <div style={{ width: 40 }}></div>
          </div>

          {/* Edit */}
          {props.actionId && teamBuildingAction && teamBuildingActionDates.length > 0 && (
            <TeamsToolsBuildingDashboardActionFrom
              isEdit={true}
              teamId={props.teamId}
              teamBuildingAction={teamBuildingAction}
              teamBuildingActionDates={teamBuildingActionDates}
              teamBuildingMembers={teamBuildingMembers}
              handleBack={handleBack}
              handleEditEnd={handleEditEnd}
            />
          )}
          {/* Create */}
          {!props.actionId && teamBuildingActionDates.length === 0 && (
            <>
              <FormLabel>キックオフで設定したアクションから作成</FormLabel>
              <Select options={masterActionOptions} onChange={onChangeSelectMaterAction} />
              <TeamsToolsBuildingDashboardActionFrom
                isEdit={false}
                teamId={props.teamId}
                teamBuildingAction={teamBuildingAction}
                teamBuildingMembers={teamBuildingMembers}
                defaultActionMasters={props.defaultActionMasters}
                handleBack={handleBack}
                handleEditEnd={handleEditEnd}
              />
            </>
          )}
        </main>
      </div>
    </>
  )
}

interface Input {
  name: string
  reason: string
  content: string
  timing: string
  assignedTeamMemberId: string
  dates: any[]
}

type FormProps = {
  isEdit: boolean
  teamId: string | undefined
  teamBuildingMembers: TeamBuildingMember[]
  teamBuildingAction?: TeamBuildingAction
  teamBuildingActionDates?: TeamBuildingActionDate[]
  defaultActionMasters?: Master[]
  handleBack(): void
  handleEditEnd: () => void
}

const TeamsToolsBuildingDashboardActionFrom: React.FC<FormProps> = ({
  isEdit,
  teamBuildingMembers,
  teamBuildingAction,
  teamBuildingActionDates = [],
  teamId,
  handleBack,
  handleEditEnd,
}) => {
  const classes = useStyles()

  const [showAlert, setShowAlert] = React.useState<boolean>(false)
  const openModal = () => {
    setShowAlert(true)
  }
  const onClose = () => {
    setShowAlert(false)
  }

  const isMasterAction = React.useMemo(() => {
    return teamBuildingAction && !teamBuildingAction.editable
  }, [teamBuildingAction])
  React.useEffect(() => {
    if (teamBuildingActionDates.length === 0) {
      append({ date: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectMemberOptions = React.useMemo(() => {
    if (!teamBuildingMembers) {
      return []
    } else {
      return teamBuildingMembers.map((member) => ({
        value: member.id,
        label: member.teamMember?.username || '',
      }))
    }
  }, [teamBuildingMembers])

  const defaultValues = React.useMemo(() => {
    if (teamBuildingAction) {
      return {
        name: teamBuildingAction.name,
        reason: teamBuildingAction.reason,
        content: teamBuildingAction.content,
        timing: teamBuildingAction.timing,
        assignedTeamMemberId: teamBuildingAction.assignedTeamMemberId,
        dates: teamBuildingActionDates.map((item) => ({ date: item.date })),
      }
    } else {
      return {}
    }
  }, [teamBuildingAction, teamBuildingActionDates])

  const { register, control, errors, getValues, trigger } = useForm<Input>({
    mode: 'onChange',
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates',
  })

  const refreshTeamBuilding = async () => {
    if (teamId) {
      await mutationRefreshTeamBuilding({ input: { id: teamId } })
      handleEditEnd()
    }
  }
  const onSubmit = async () => {
    const data = getValues()
    if (!teamId) {
      return
    }

    if (isEdit) {
      if (!teamBuildingAction) {
        return
      }

      const fetchedTeamBuildingAction = await queryGetTeamBuildingAction({ id: teamBuildingAction.id })
      const isUpdatedByOtherUser = teamBuildingAction.updatedAt !== fetchedTeamBuildingAction?.updatedAt

      if (isUpdatedByOtherUser) {
        openModal()
      } else {
        update(data)
      }
    } else {
      const inputBase = {
        teamId: teamId,
        assignedTeamMemberId: data.assignedTeamMemberId,
        assignedTeamMemberName:
          teamBuildingMembers.find((member) => member.id === data.assignedTeamMemberId)?.teamMember?.username || '',
      }
      const input = {
        ...inputBase,
        editable: isMasterAction && teamBuildingAction ? 0 : 1,
        name: isMasterAction && teamBuildingAction ? teamBuildingAction.name : data.name,
        reason: isMasterAction && teamBuildingAction ? teamBuildingAction.reason : data.reason,
        content: isMasterAction && teamBuildingAction ? teamBuildingAction.content : data.content,
        timing: isMasterAction && teamBuildingAction ? teamBuildingAction.timing : data.timing,
      }

      const createdAction = await mutationCreateTeamBuildingAction({ input })

      if (createdAction) {
        const datesInput = {
          teamBuildingActionId: createdAction.id,
          dates: data.dates.map((item) => ({
            teamBuildingActionId: createdAction.id,
            date: item.date,
            status: TeamBuildingActionDateStatus.Created,
          })),
        }

        await mutationCreateTeamBuildingActionDates({ input: datesInput })
        handleEditEnd()
      }
      await refreshTeamBuilding()
    }
  }

  const update = async (data: Input) => {
    if (!teamBuildingAction) {
      return
    }

    const input = {
      id: teamBuildingAction.id,
      name: data.name,
      reason: data.reason,
      content: data.content,
      timing: data.timing,
      assignedTeamMemberId: data.assignedTeamMemberId,
      assignedTeamMemberName:
        teamBuildingMembers.find((member) => member.id === data.assignedTeamMemberId)?.teamMember?.username || '',
    }

    const updatedAction = await mutationUpdateTeamBuildingAction({ input })

    if (updatedAction) {
      const currentTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
        id: teamBuildingAction.id,
        index: IndexGetTeamBuildingActionDates.TeamBuildingActionId,
      })

      if (!currentTeamBuildingActionDates?.items) {
        return
      }

      const deleteRequests = currentTeamBuildingActionDates.items
        .filter((item) => {
          const currentIds = data.dates.map(({ date }) => `${updatedAction.id}-${date}`)
          return !currentIds.includes(item.id)
        })
        .map((item) => ({
          teamBuildingActionDateId: item.id,
        }))
      const createRequests = data.dates
        .filter(({ date }) => {
          const oldIds = currentTeamBuildingActionDates.items.map(({ id }) => id)
          return !oldIds.includes(`${updatedAction.id}-${date}`)
        })
        .map((item) => ({
          teamBuildingActionId: updatedAction.id,
          date: item.date,
          status: TeamBuildingActionDateStatus.Created,
        }))
      const datesInput = {
        teamBuildingActionId: updatedAction.id,
        dates: [...deleteRequests, ...createRequests],
      }

      await mutationCreateTeamBuildingActionDates({ input: datesInput })
    }
    await refreshTeamBuilding()
  }

  const submitModal = () => {
    const data = getValues()

    update(data)
    onClose()
  }

  const cancelModal = () => {
    onClose()
  }

  return (
    <>
      <div>
        {isMasterAction ? (
          <>
            <div className={classes.actionTitleWrap}>
              <h1>{teamBuildingAction?.name}</h1>
            </div>

            <InputCard>
              <ActionDescriptions action={teamBuildingAction} />
            </InputCard>
          </>
        ) : (
          <>
            <div className={classes.formItem}>
              <FormLabel>アクション名</FormLabel>
              <InputText
                ref={register({ required: 'アクション名を入力してください' })}
                name="name"
                placeholder="アクション名を入力してください"
                style={{ width: '100%', borderRadius: 4 }}
              />
              <FormError error={errors.name} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>実施する理由</FormLabel>
              <InputTextarea
                ref={register({ required: '実施する理由を入力してください' })}
                name="reason"
                placeholder="アクションを実施する理由を入力してください"
              />
              <FormError error={errors.reason} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>実施する内容</FormLabel>
              <InputTextarea
                ref={register({ required: '実施する内容を入力してください' })}
                name="content"
                placeholder="アクションを実施する内容を入力してください"
              />
              <FormError error={errors.content} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>推奨タイミング</FormLabel>
              <InputTextarea
                ref={register({ required: '推奨タイミングを入力してください' })}
                name="timing"
                placeholder="アクションを実施する内容を入力してください"
              />
              <FormError error={errors.timing} />
            </div>
          </>
        )}

        <div className={classes.formItem}>
          <FormLabel>終了日までの実施予定回数</FormLabel>
          {!!fields.length && (
            <div style={{ marginTop: 16 }}>
              <InputCard>
                <ScheduleForm
                  fields={fields}
                  getValues={getValues}
                  errors={errors}
                  trigger={trigger}
                  control={control}
                  remove={remove}
                  append={append}
                />
              </InputCard>
            </div>
          )}
        </div>
        <div className={classes.formItem}>
          <FormLabel>推進責任者</FormLabel>
          <Select
            name={'assignedTeamMemberId'}
            options={selectMemberOptions}
            ref={register({ required: '推進責任者を選択してください' })}
          />
          <FormError error={errors.assignedTeamMemberId} />
        </div>

        <div className={classes.buttonWrap}>
          <ContentButton type="border" onClick={handleBack} label="キャンセル" />
          <ContentButton onClick={() => onSubmit()} label={isEdit ? '保存' : '追加'} />
        </div>
      </div>
      {showAlert && (
        <div className={classes.inlineModal}>
          <h2 className={classes.modalHeading}>直前で誰かが更新しています。こちらの変更を優先して保存しますか？</h2>
          <div className={classes.modalButtonWrap}>
            <Button full onClick={() => submitModal()}>
              はい
            </Button>
            <Button full buttonType="secondary" onClick={() => cancelModal()}>
              いいえ
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inlineModal: {
    position: 'absolute',
    zIndex: 1000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#F9F9F9',
    padding: 16,
    width: '100%',
    maxWidth: 512,
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '16px',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
      // justifyContent: center
    },
  },
  modalButtonWrap: {
    marginTop: '24px',

    '& > button + button': {
      marginTop: '16px',
    },
  },
  modalHeading: {
    margin: 0,
    fontSize: '14px',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    textAlign: 'center',
  },

  actionTitleWrap: {
    margin: '24px 0',

    '& > h1': {
      textAlign: 'center',
      fontSize: 16,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },

  formItem: {
    marginTop: 24,
  },

  headingWrap: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '16px',
    fontWeight: 'bold',
  },

  root: {},
  container: {
    minHeight: '100vh',
    padding: '16px 16px',
    backgroundColor: '#F9F9F9',
    color: '#333',
  },
  main: {
    maxWidth: '600px',
    margin: 'auto',
  },
}))
