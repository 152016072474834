import { API, graphqlOperation } from '@aws-amplify/api'

import { MutationUpdateSetupItemFeedback } from './graphql'

export interface ISetupItemFeedbackUpdateInput {
  id: string
  setupItemId: string
  category: string
  value: number
  point: number
  comment: string
  settedAt: string
  setupStartedAt?: string
}

export interface ISetupItemFeedback {
  id: string
  username: string
  setupItemId: string
  category: string
  value: number
  point: number
  comment: string
  updatedAt: string
  setupItem: string
  settedAt: string
}

const updateSetupItemFeedback = async (data: ISetupItemFeedbackUpdateInput) => {
  const input = {
    id: data.id,
    setupItemId: data.setupItemId,
    category: data.category,
    value: data.value,
    point: data.point,
    comment: data.comment,
    settedAt: data.settedAt,
    setupStartedAt: data.setupStartedAt,
  }

  try {
    const response: any = await API.graphql(graphqlOperation(MutationUpdateSetupItemFeedback, { input }))
    const { updateSetupItemFeedback } = response.data

    return updateSetupItemFeedback as ISetupItemFeedback
  } catch (e) {
    console.log('updateSetupItemFeedback error', e)
    return {} as ISetupItemFeedback
  }
}

export default updateSetupItemFeedback
