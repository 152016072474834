import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { Addresses } from 'utils/generated'

import { useUtilAddress } from '../hooks/util'

import { constants } from 'assets'

interface Props {
  zipcode?: string | null
  callBack: (result: Addresses) => void
}

export const SearchAddressButton: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { getAddresses } = useUtilAddress()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleOnClick = async () => {
    setError(false)

    if (props.zipcode) {
      setLoading(true)
      const addresses = await getAddresses(props.zipcode)

      if (addresses) {
        props.callBack(addresses)
      } else {
        setError(true)
      }
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        className={`${classes.root} ${loading ? classes.loading : ''}`}
        onClick={!loading ? handleOnClick : undefined}
      >
        住所検索
      </Button>
      {error ? <label className={classes.errorLabel}>住所が取得できませんでした</label> : <></>}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: () => ({
    height: 48,
    fontSize: 14,
    fontWeight: 'bold',
    color: constants.TEXT_WHITE,
    padding: '2px 12px',
    backgroundColor: constants.COLOR_MAIN_NEW,
    borderRadius: 24,
    margin: '0 0 20px 24px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: constants.COLOR_MAIN_NEW,
    },
  }),

  loading: {
    opacity: 0.3,
  },

  errorLabel: {
    fontSize: 11,
    color: constants.COLOR_RED,
    marginLeft: 8,
  },
}))
