import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { UpdateAccountInput } from '../../../../services/api/updateAccount'
import { ActionsType, RootStateType } from '../../../../store'
import { SetupActions } from '../../../../stores/setup/action'
import { UserActions } from '../../../../stores/user/action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  updateAccount: (params: UpdateAccountInput) => dispatch(UserActions.updateAccount.started({ params })),
  changeCurrentSetupStartedAt: (date: string) => dispatch(SetupActions.changeCurrentSetupStartedAt({ date })),
})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    setup: { ...state.setup },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
