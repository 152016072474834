import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import {
  GhostHistory,
  GhostHistoryConnection,
  GhostHistoryItems,
  MutationUpdateGhostHistoryArgs,
  QueryGetGhostHistoriesByMasterArgs,
  QueryGetGhostHistoryArgs,
  QueryGetGhostHistoryListArgs,
} from 'utils/generated'

import {
  mutationUpdateGhostHistory,
  queryGetGhostHistoriesByMaster,
  queryGetGhostHistory,
  queryGetGhostHistoryList,
} from './graphql'

export const getGhostHistory = async (args: QueryGetGhostHistoryArgs) => {
  const res = await API.graphql({
    query: queryGetGhostHistory,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostHistory as GhostHistoryConnection
}

export const getGhostHistoryList = async (args: QueryGetGhostHistoryListArgs) => {
  const res = await API.graphql({
    query: queryGetGhostHistoryList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostHistoryList as GhostHistoryConnection[]
}

export const getGhostHistoriesByMaster = async (args: QueryGetGhostHistoriesByMasterArgs) => {
  const res = await API.graphql({
    query: queryGetGhostHistoriesByMaster,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostHistoriesByMaster as GhostHistoryItems
}

export const updateGhostHistory = async (args: MutationUpdateGhostHistoryArgs) => {
  const res = await API.graphql({
    query: mutationUpdateGhostHistory,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.updateGhostHistory as GhostHistory
}
