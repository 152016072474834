import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import download from './assets/download.svg'
import { constants } from 'assets'

type Props = {
  src?: string
  boxType: 'post' | 'timeline'
  mime: string
  onClick: () => void
  cancel?: () => void
  isDownload?: boolean
}

export const AttachmentImgBox: React.FC<Props> = ({ src, boxType, mime, onClick, cancel: remove, isDownload }) => {
  const classes = useStyles()

  const [videoDur, setVideoDur] = useState<string>()
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    const vr = videoRef.current
    let live = true
    if (vr) {
      vr.preload = 'metadata'
      vr.onloadedmetadata = () => {
        if (vr.duration) {
          const [m, s] = [Math.floor(vr.duration / 60), Math.floor(vr.duration % 60)]
          live && setVideoDur(`${m}:${s}`)
        }
      }
    }
    return () => {
      live = false
    }
  }, [videoRef])

  return src ? (
    <div className={classes.root}>
      {boxType === 'post' && remove && (
        <img
          className={classes.removeButton}
          src={require('./assets/removeButton.svg').default}
          alt="removeButton"
          onClick={remove}
        />
      )}
      {mime.includes('application/octet-stream') || // for download
      mime.includes('image') ? ( // for upload
        <>
          <img
            src={src}
            alt={'attachmentImg'}
            className={classes.media}
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          />
          {isDownload && (
            <a
              className={classes.downloadBtn}
              href={src}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <div className={classes.download}>
                <img className={classes.downloadImg} src={download} alt="ダウンロード" />
              </div>
            </a>
          )}
        </>
      ) : (
        <>
          <video
            key="video"
            src={`${src}#t=0.1`}
            ref={videoRef}
            className={classes.media}
            muted
            playsInline
            preload="metadata"
            controls={false}
            autoPlay={false}
          />
          <div
            className={classes.videoLayer}
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            <img className={classes.playButton} src={require('./assets/play.svg').default} alt="playbutton" />
            <span className={classes.duration}>{videoDur}</span>
          </div>
        </>
      )}
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  media: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: 8,
    cursor: 'pointer',
  },
  downloadBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  download: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 12,
  },
  downloadImg: {
    width: 16,
    height: 16,
  },
  videoLayer: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    '&img,span': {
      display: 'block',
      zIndex: 200,
    },
  },
  removeButton: {
    width: 42,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 200,
  },
  playButton: {
    width: 'clamp(20px, 10%, 40px)',
  },
  duration: {
    color: '#FFF',
    fontSize: 'clamp(12px, 5%, 14px)',
  },
}))
