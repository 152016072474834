import * as React from 'react'

import { Box } from '@material-ui/core'

import { Answer } from 'pages/hint/_shared/components/answer/Index'
import { Content } from 'pages/hint/_shared/components/content/Index'
import { Date } from 'pages/hint/_shared/components/date/Index'
import { Details } from 'pages/hint/_shared/components/details/Index'
import { ArticleHeading } from 'pages/hint/_shared/components/heading/articleHeading/Index'
import { Category } from 'pages/hint/_shared/components/label/category/Index'
import { IConsultationResult } from 'pages/hint/_shared/stores/apis/getConsultation'
import { formatDate } from 'pages/hint/_shared/utils/formatDate'

import * as options from 'assets/options'
import { IOptions } from 'assets/options'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  consultationData: IConsultationResult
}

// -----------------------------
// Component
// -----------------------------
export const Article = (props: IProps) => {
  return (
    <Box>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection="column">
        <Box mb={{ xs: '12px', md: '24px' }}>
          {options.optionConsultationCategory.map((optionConsultationCategory: IOptions, index: number) => {
            return (
              <Box key={index}>
                {optionConsultationCategory.value === props.consultationData.category && (
                  <Category text={optionConsultationCategory.label} />
                )}
              </Box>
            )
          })}
        </Box>
        <ArticleHeading text={props.consultationData.title} />
      </Box>

      <Box mt="24px">
        <Details>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box mr={{ xs: '16px', md: '24px' }}>
              <Date text={formatDate(props.consultationData.createdAt)} />
            </Box>
            <Box width={{ xs: '60px', md: '80px' }}>
              <Answer count={props.consultationData.commentCount} />
            </Box>
          </Box>
        </Details>
      </Box>

      <Box>
        <Box mt={{ xs: '24px', lg: '64px' }} minHeight={'20vh'}>
          <Content text={props.consultationData.content} ellipsis={false} lineHeight={1.85} />
        </Box>
      </Box>
    </Box>
  )
}
