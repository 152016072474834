import React from 'react'
import { useParams } from 'react-router-dom'

import { CircularProgress, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTeamAnketBuildingSummaryListByTeam } from 'services/api/anket'

import { NoStyleButton } from 'components/NoStyleButton'
import { useTeamMembers } from 'pages/teams/hooks'
import closeSvg from 'pages/teams/pages/dashboard/assets/close.svg'
import { TeamMember, TeamMemberStatusUsage } from 'utils/generated'

import { Button } from './Button'

import { constants } from 'assets'

type Props = {
  isOpen: boolean
  handleCloseDialog: () => void
  handleCreateSummaryBySendEmail: () => Promise<boolean>
}

export const SendEmailDialog: React.FC<Props> = ({ isOpen, handleCloseDialog, handleCreateSummaryBySendEmail }) => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { teamMembers, loading: isLoadingTeamMembers } = useTeamMembers(teamId)
  const { loadingEvent } = useTeamAnketBuildingSummaryListByTeam(teamId)

  const handleClickSendEmail = async () => {
    const isEmailSent = await handleCreateSummaryBySendEmail()
    if (isEmailSent) handleCloseDialog()
  }

  const activeTeamMembers = React.useMemo(
    () => teamMembers.filter((teamMember) => teamMember.statusUsage === TeamMemberStatusUsage.Active),
    [teamMembers]
  )

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} fullWidth maxWidth="xs" className={classes.root}>
      <DialogTitle disableTypography className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>回答依頼メールを送信する</div>
        <NoStyleButton type="button" onClick={handleCloseDialog} className={classes.closeButton}>
          <img src={closeSvg} alt="closeDialog" className={classes.closeButton} />
        </NoStyleButton>
      </DialogTitle>
      <div className={classes.dialogTitleCaption}>
        {`以下${activeTeamMembers.length ?? 0}名のメンバーにアンケートの回答依頼メールを送信します。`}
      </div>

      <DialogContent dividers className={classes.dialogContent}>
        {isLoadingTeamMembers ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress size={20} />
          </div>
        ) : (
          <TeamMemberList teamMembers={activeTeamMembers} />
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClickSendEmail} disabled={loadingEvent}>
          送信する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const TeamMemberList: React.FC<{ teamMembers: TeamMember[] }> = ({ teamMembers }) => {
  const classes = useStyles()

  return (
    <List dense className={classes.list}>
      {teamMembers.map((teamMember) => {
        return (
          <ListItem key={teamMember.id} className={classes.listItem}>
            <ListItemText primary={teamMember.fullName} secondary={teamMember.email} className={classes.listItemText} />
          </ListItem>
        )
      })}
    </List>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDialog-paper': {
        margin: 0,
      },
    },
    closeButton: {
      position: 'relative',
      top: -4,
      width: 14,
      height: 14,
    },
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 4,
      padding: '24px 16px 16px',
    },
    dialogTitle: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      fontSize: 16,
      fontWeight: 'bold',
    },
    dialogTitleCaption: {
      padding: '0 16px 16px',
    },

    dialogContent: {
      padding: 16,
    },
    circularProgressWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px 0px 16px',
    },
    list: {
      padding: 0,
      backgroundColor: constants.COLOR_GRAY_LIGHT3,
      borderRadius: 8,
    },
    listItem: {
      padding: '4px 8px',
    },
    listItemText: {
      '& .MuiListItemText-primary': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .MuiListItemText-secondary': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },

    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 16,
    },
  }),
  { name: 'SendEmailDialog' }
)
