import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import {
  GhostTeamMember,
  GhostTeamMemberConnection,
  MutationCreateGhostTeamMemberArgs,
  MutationDeleteGhostTeamMemberArgs,
  MutationUpdateGhostTeamMemberArgs,
  QueryGetGhostTeamMemberArgs,
  QueryGetGhostTeamMemberListArgs,
} from 'utils/generated'

import {
  mutationCreateGhostTeamMember,
  mutationDeleteGhostTeamMember,
  mutationUpdateGhostTeamMember,
  mutationUpdateGhostTeamMemberByAdmin,
  queryGetGhostTeamMember,
  queryGetGhostTeamMemberList,
} from './graphql'

export const getGhostTeamMember = async (args: QueryGetGhostTeamMemberArgs) => {
  const res = await API.graphql({
    query: queryGetGhostTeamMember,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostTeamMember as GhostTeamMember
}

export const getGhostTeamMemberList = async (args: QueryGetGhostTeamMemberListArgs) => {
  const res = await API.graphql({
    query: queryGetGhostTeamMemberList,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.getGhostTeamMemberList as GhostTeamMemberConnection
}

export const createGhostTeamMember = async (args: MutationCreateGhostTeamMemberArgs) => {
  const res = await API.graphql({
    query: mutationCreateGhostTeamMember,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.createGhostTeamMember as GhostTeamMember
}

export const updateGhostTeamMember = async (args: MutationUpdateGhostTeamMemberArgs) => {
  const res = await API.graphql({
    query: mutationUpdateGhostTeamMember,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.updateGhostTeamMember as GhostTeamMember
}

export const updateGhostTeamMemberByAdmin = async (args: MutationUpdateGhostTeamMemberArgs) => {
  const res = await API.graphql({
    query: mutationUpdateGhostTeamMemberByAdmin,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.updateGhostTeamMember as GhostTeamMember
}

export const deleteGhostTeamMember = async (args: MutationDeleteGhostTeamMemberArgs) => {
  const res = await API.graphql({
    query: mutationDeleteGhostTeamMember,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return res.data.deleteGhostTeamMember as GhostTeamMember
}
