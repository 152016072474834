import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'

// -----------------------------
// Params
// -----------------------------
export type IUserParams = {
  id: string
}

// -----------------------------
// Result
// -----------------------------
export type IUserResult = {
  description: string
  firstName: string
  id: string
  imageUrl: string
  lastName: string
  shortDescription: string
}

export const getUser = async (params: IUserParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getUser,
      variables: { id: params.id },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    const { getUser } = res?.data
    return getUser
  } catch (e) {
    throw e
  }
}
