import { useMemo } from 'react'

import { IKarteMemberResults, StringKeyObject } from 'pages/hataraku/pages/results/interface'
import {
  bubbleTexts,
  circleGraph,
  questionnaireAnswerPatterns,
  questionnaireBubbles,
  tendencyMappings,
} from 'pages/hataraku/pages/static'

export const useBuildHatarakuResults = (data?: any) => {
  const buildResults = (karteData: any) => {
    const results = {
      about: {
        short: '',
        long: '',
      },
      motivation: '',
      themeColor: '',
    }

    // Calculate about & motivation
    const formTotals: { group: string; weight: number; value: number }[] = []
    const formQuestionsTotals: {
      group: string
      key: string
      label: string
      weight: number
      value: number
    }[] = []
    const formCircleTotals: {
      circleGroup: string
      label: string
      description: string
      value: number
    }[] = circleGraph.map((item) => ({
      circleGroup: item.key,
      label: item.label,
      description: item.description,
      value: 0,
    }))

    const formValues: StringKeyObject = {}

    for (let formIndex = 0; formIndex < karteData.calculable.length; formIndex++) {
      const form = karteData.calculable[formIndex]

      for (let questionIndex = 0; questionIndex < form.questions.length; questionIndex++) {
        const question = form.questions[questionIndex]
        const addValue = parseInt(question.value, 0)
        const addedValue = formValues[form.group]
          ? formValues[form.group].value
            ? formValues[form.group].value + addValue
            : addValue
          : addValue
        formValues[form.group] = {
          value: addedValue,
        }
        formValues[form.group].value += parseInt(question.value, 0)

        formQuestionsTotals.push({
          group: form.group,
          key: question.key,
          label: question.label,
          weight: question.weight || 999,
          value: question.value,
        })

        const circleGraphIndex = circleGraph.findIndex((item) => item.groups.indexOf(form.group) !== -1)
        const circleDataGroupIndex = formCircleTotals.findIndex(
          (item) => item.circleGroup === circleGraph[circleGraphIndex].key
        )

        formCircleTotals[circleDataGroupIndex].value += parseInt(question.value, 0)
      }

      if (form.questions.length > 0) {
        formTotals.push({
          group: form.group,
          weight: form.weight || 999,
          value: formValues[form.group].value / form.questions.length,
        })
      }
    }

    formTotals.sort((a, b) => {
      if (a.value > b.value) return -1
      if (a.value === b.value) return a.weight > b.weight ? 1 : -1
      return 1
    })

    formQuestionsTotals.sort((a, b) => {
      if (a.value > b.value) return -1
      if (a.value === b.value) return a.weight > b.weight ? 1 : -1
      return 1
    })

    formCircleTotals.sort((a, b) => {
      return a.value < b.value ? 1 : -1
    })
    const patternIndex = questionnaireAnswerPatterns.findIndex(
      (pattern) => pattern.first === formTotals[0].group && pattern.second === formTotals[1].group
    )
    if (patternIndex !== -1) {
      const pattern = questionnaireAnswerPatterns[patternIndex]
      const themeColor = questionnaireBubbles[pattern.first].color
      results.about = pattern.about
      results.motivation = pattern.motivation
      results.themeColor = themeColor
    }

    // Calculate bubbles & points
    const bubbles = []
    let minPoint = 0
    const points: { title: string; description: string; value: number; color: string; link: string }[] = []
    for (let formIndex = 0; formIndex < formTotals.length; formIndex++) {
      const form = formTotals[formIndex]
      const bubble = questionnaireBubbles[form.group as keyof typeof questionnaireBubbles] as {
        label: string
        color: string
      }
      if (formIndex <= 2) {
        minPoint = form.value
      }
      bubbles.push({
        label: bubbleTexts[form.group] && bubbleTexts[form.group].shortLabel,
        color: bubble.color,
        size: form.value >= minPoint ? 'large' : 'small',
      })
      points.push({
        title: bubbleTexts[form.group] && bubbleTexts[form.group].label,
        description: bubbleTexts[form.group].description,
        value: form.value,
        color: bubble.color,
        link: bubbleTexts[form.group].linkMember,
      })
    }

    // Calculate characteristics
    const characteristicsGood: string[] = []
    const characteristicsBad: string[] = []

    for (let characteristicIndex = 0; characteristicIndex < formQuestionsTotals.length; characteristicIndex++) {
      // calculate good
      if (
        formQuestionsTotals[characteristicIndex].value === formQuestionsTotals[0].value &&
        characteristicsGood.length < 10
      ) {
        characteristicsGood.push(formQuestionsTotals[characteristicIndex].label)
      }

      // calculate bad
      if (
        formQuestionsTotals[characteristicIndex].value === formQuestionsTotals[formQuestionsTotals.length - 1].value &&
        characteristicsBad.length < 10
      ) {
        characteristicsBad.push(formQuestionsTotals[characteristicIndex].label)
      }
    }

    const radomizer = (array: string[]) => {
      let count = array.length,
        randomnumber,
        temp
      while (count) {
        randomnumber = (Math.random() * count--) | 0
        temp = array[count]
        array[count] = array[randomnumber]
        array[randomnumber] = temp
      }
    }

    radomizer(characteristicsGood)
    radomizer(characteristicsBad)

    return {
      ...results,
      circleItems: formCircleTotals,
      bubbles,
      points,
      characteristics: {
        good: characteristicsGood,
        bad: characteristicsBad,
      },
    }
  }

  const getTendencyId = (tendency?: string) => {
    return Object.keys(tendencyMappings).find(
      (key) => tendencyMappings[key as keyof typeof tendencyMappings] === tendency
    )
  }

  const results: IKarteMemberResults | null = useMemo(() => {
    if (!data) return null
    return buildResults(data)
  }, [data])

  return { buildResults, results, getTendencyId }
}
