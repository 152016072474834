import { OnboardingPostLabel } from 'utils/generated'

import AdviceSvg from '../assets/timeline-label-advice.svg'
import CooperatorSvg from '../assets/timeline-label-cooperator.svg'
import GoodSvg from '../assets/timeline-label-good.svg'
import TodaySvg from '../assets/timeline-label-today.svg'

export const postBalloonLabel = (label: OnboardingPostLabel) => {
  switch (label) {
    case OnboardingPostLabel.Struggling:
      return CooperatorSvg
    case OnboardingPostLabel.Focus:
      return AdviceSvg
    case OnboardingPostLabel.HelpMe:
      return TodaySvg
    case OnboardingPostLabel.Good:
      return GoodSvg
  }
}
