import {
  PaymentMethod,
  TeamMemberRole,
  TeamMemberStatusUsage,
  TeamStatusAdmin,
  TeamStatusUsage,
  TeamTool,
} from 'utils/generated'

export const getPaymentMethodLabel = (value?: PaymentMethod | string | null): string => {
  switch (value) {
    case PaymentMethod.Card:
      return 'カード'
    case PaymentMethod.Invoice:
      return '請求書'
    case PaymentMethod.None:
      return '未設定'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusAdminLabel = (value?: TeamStatusAdmin | string | null): string => {
  switch (value) {
    case TeamStatusAdmin.Active:
      return '活動中'
    case TeamStatusAdmin.Confirming:
      return '運営確認中'
    case TeamStatusAdmin.NonActive:
      return '活動休止'
    case TeamStatusAdmin.Banned:
      return '運営禁止'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusAdminColor = (value?: TeamStatusAdmin | string | null): string => {
  switch (value) {
    case TeamStatusAdmin.Active:
      return '#87ceeb'
    case TeamStatusAdmin.Confirming:
      return '#90ee90'
    case TeamStatusAdmin.NonActive:
      return '#f3f3f3'
    case TeamStatusAdmin.Banned:
      return '#f08080'
    default:
      return '#fff'
  }
}

export const getTeamStatusUsageLabel = (value?: TeamStatusUsage | string): string => {
  switch (value) {
    case TeamStatusUsage.InUse:
      return '活動中'
    case TeamStatusUsage.NonUse:
      return '終了'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusUsageColor = (value?: TeamStatusUsage | string): string => {
  switch (value) {
    case TeamStatusUsage.InUse:
      return '#87ceeb'
    case TeamStatusUsage.NonUse:
      return '#f3f3f3'
    default:
      return '#fff'
  }
}

export const getTeamToolLabel = (value?: TeamTool | string): string => {
  switch (value) {
    case TeamTool.Building:
      return 'キックオフ'
    case TeamTool.Onboarding:
      return 'オンボーディング'
    case TeamTool.Ghost:
      return 'おばけ'
    case TeamTool.Karte:
      return 'カルテ'
    case TeamTool.Meeting:
      return 'ミーティング'
    default:
      return value?.toString() || ''
  }
}

export const getTeamToolColor = (value: TeamTool): string | undefined => {
  switch (value) {
    case TeamTool.Building:
      return '#b2fbc4'
    case TeamTool.Onboarding:
      return '#adcffa'
    case TeamTool.Ghost:
      return '#eea8fa'
    case TeamTool.Karte:
      return '#fad2ad'
  }
}

export const getTeamMemberRoleLabel = (value: TeamMemberRole): string => {
  switch (value) {
    case TeamMemberRole.Admin:
      return '管理者'
    case TeamMemberRole.Leader:
      return 'リーダー'
    case TeamMemberRole.Member:
      return 'メンバー'
  }
}

export const getTeamMemberRoleLabelFromList = (values: TeamMemberRole[]): string => {
  if (values.includes(TeamMemberRole.Admin)) {
    return '管理者'
  } else if (values.includes(TeamMemberRole.Leader)) {
    return 'リーダー'
  } else if (values.includes(TeamMemberRole.Member)) {
    return 'メンバー'
  } else {
    return ''
  }
}

export const getTeamMemberRoleColor = (value: TeamMemberRole): string => {
  switch (value) {
    case TeamMemberRole.Admin:
      return '#f08080'
    case TeamMemberRole.Leader:
      return '#40e0d0'
    case TeamMemberRole.Member:
      return '#f5f5f5'
  }
}

export const getTeamMemberStatusUsageLabel = (value?: TeamMemberStatusUsage | string): string => {
  switch (value) {
    case TeamMemberStatusUsage.Invited:
      return '招待中'
    case TeamMemberStatusUsage.Active:
      return '利用中'
    case TeamMemberStatusUsage.Stop:
      return '停止中'
    case TeamMemberStatusUsage.Reserved:
      return '予約招待'
    default:
      return value?.toString() || ''
  }
}

export const getTeamMemberStatusUsageColor = (value?: TeamMemberStatusUsage | string): string => {
  switch (value) {
    case TeamMemberStatusUsage.Invited:
      return '#87ceeb'
    case TeamMemberStatusUsage.Active:
      return '#90ee90'
    case TeamMemberStatusUsage.Stop:
      return '#f08080'
    default:
      return '#f3f3f3'
  }
}
