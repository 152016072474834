import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { ContentHead } from './ContentHead'
import { ContentSection } from './ContentSection'

export const Landing: React.FC<{ title: string; hidePeople?: boolean }> = ({ title, hidePeople }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle="リーダーの進行をお待ちください" />
      <ContentSection>
        <div className={classes.wrap}>
          {!hidePeople && (
            <div
              style={{
                height: 138,
              }}
            >
              <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/waitingPeople.svg'} alt="" />
            </div>
          )}
        </div>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  wrap: { padding: '64px 0 32px', width: '100%', textAlign: 'center' },
}))
