import type { GhostTeamMember } from 'utils/generated'

export const pickupRandomMember = (dataList: GhostTeamMember[], excludeUserIds: string[]) => {
  let i = 0
  while (i < 100) {
    const data = pickup(dataList)
    if (excludeUserIds.length === 0) {
      return data
    } else {
      let duplicate = false
      for (const e of excludeUserIds) {
        if (data.userId === e) {
          duplicate = true
        }
      }
      if (!duplicate) {
        return data
      }
    }
    i++
  }
}

const pickup = (dataList: GhostTeamMember[]): GhostTeamMember => {
  const index = Math.floor(Math.random() * dataList.length)
  return dataList[index]
}
