import React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import { FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterShareButton, XIcon } from 'react-share'

import { constants } from 'assets'

type Props = {
  tendency: string
}

export const Socials: React.FC<Props> = ({ tendency }) => {
  const classes = useStyles()
  const currentUrl = window.location.href
  const shareText = `私が働くときに大事にしている「はたらく価値観」の傾向は、『${tendency}』でした！`

  return (
    <div className={classes.root}>
      <div className={classes.heading}>SNSでシェアしてみよう</div>
      <div className={classes.content}>
        <div className={classes.description}>
          <p>
            あなたが働く動機の傾向と診断結果のURLを、SNSにシェアできます。
            <br />
            ぜひ、職場の上司や同僚にも「はたらく価値観」を共有してみてください！
          </p>
        </div>
        <div className={classes.socials}>
          <TwitterShareButton title={shareText} url={currentUrl} hashtags={['はたらく価値観診断,Cocolabo,推せる職場']}>
            <XIcon round size={40} />
          </TwitterShareButton>
          {/* localhostのURLを渡した際は、Facebookでのシェアに失敗する */}
          <FacebookShareButton url={currentUrl} hashtag="#はたらく価値観診断">
            <FacebookIcon round size={40} />
          </FacebookShareButton>
          {/* スマートフォンでのシェア内容には、titleが含まれず、urlのみとなる */}
          <LineShareButton title={shareText} url={currentUrl}>
            <LineIcon round size={40} />
          </LineShareButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 16,
      padding: 40,
      backgroundColor: '#fff',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },
    },

    heading: {
      marginBottom: 16,
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 16,
      },
    },

    description: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        paddingRight: 16,
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: 1,
          height: '100%',
          borderRight: `1px solid ${constants.COLOR_GRAY4}`,
        },
      },
      '& p': {
        margin: 0,
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
      },
    },

    socials: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.up('md')]: {
        marginLeft: 16,
      },
    },
  }),
  { name: 'Socials' }
)
