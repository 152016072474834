import { useCallback, useEffect, useState, createContext } from 'react'

import { Auth } from 'aws-amplify'

import {
  GhostTeamMember,
  MutationUpdateGhostTeamMemberArgs,
  MutationDeleteGhostTeamMemberArgs,
  MutationCreateGhostTeamMemberArgs,
  MutationUpdateGhostTeamMemberByAdminArgs,
  GhostMemberManage,
  GhostMemberRole,
} from '../../../utils/generated'
import * as handlers from '../api/member/handlers'
import { NextPagesAfterBreak } from '../pages/Break'

import { page2Num } from '../assets/pages'

export type GhostTeamMemberStore = ReturnType<typeof useTeamMember>

export const ghostTeamMemberContext = createContext({} as GhostTeamMemberStore)

export const useTeamMember = (teamId?: string) => {
  // store data about current user
  const [teamMember, setTeamMember] = useState<GhostTeamMember | undefined>(undefined)

  // user's team
  const [teamMemberList, setTeamMemberList] = useState<GhostTeamMember[]>([])
  const acceptedMemberCount = teamMemberList.filter((member) => member.manager === GhostMemberManage.Accepted).length

  // init: true -> loading: true -> loaded: false
  const [loading, setLoading] = useState<boolean>(true)

  const getCurrentGhostTeamMember = async (teamId: string) => {
    const cognito = await Auth.currentAuthenticatedUser()
    if (!teamId || !cognito || (teamId && teamId.length < 30)) {
      return
    }
    try {
      const response = await handlers.getGhostTeamMember({ id: `${teamId}-${cognito.getUsername()}` })
      setTeamMember(response)
      return response
    } catch (e) {
      // evt -> MemberDeleted
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const renewTeamMemberList = useCallback(async () => {
    if (!teamId) return
    const response = await handlers.getGhostTeamMemberList({ teamId })
    const memberList = response.items as GhostTeamMember[]
    setTeamMemberList(memberList)
    return memberList
  }, [teamId])

  // Init
  useEffect(() => {
    if (teamId) {
      getCurrentGhostTeamMember(teamId)
      renewTeamMemberList()
    }
  }, [teamId, renewTeamMemberList])

  const createGhostTeamMember = async (args: MutationCreateGhostTeamMemberArgs) => {
    const response = await handlers.createGhostTeamMember(args)
    const memberList = response as GhostTeamMember
    return memberList
  }

  const updateGhostTeamMember = useCallback(
    async (args: MutationUpdateGhostTeamMemberArgs) => {
      const response = await handlers.updateGhostTeamMember(args)
      setTeamMember(response)
      await renewTeamMemberList()
      return response
    },
    [renewTeamMemberList]
  )

  const updateGhostTeamMemberByAdmin = useCallback(async (args: MutationUpdateGhostTeamMemberByAdminArgs) => {
    const response = await handlers.updateGhostTeamMemberByAdmin(args)
    return response
  }, [])

  const updateMemberPage = useCallback(
    async (pageNum: number) => {
      if (!teamId) return
      await updateGhostTeamMember({ input: { id: teamId, page: pageNum } })
    },
    [teamId, updateGhostTeamMember]
  )

  // TODO drop
  const deleteGhostTeamMember = useCallback(async (args: MutationDeleteGhostTeamMemberArgs) => {
    return await handlers.deleteGhostTeamMember(args)
  }, [])

  const dropMembers = useCallback(
    async (stage: 'waiting' | 'report' | NextPagesAfterBreak) => {
      if (
        teamId &&
        teamMember &&
        ((stage === 'waiting' && teamMember?.role === GhostMemberRole.Admin) ||
          teamMember?.role === GhostMemberRole.Leader)
      ) {
        const membersToBeDeleted = await listStuckMembers(teamId, stage, teamMember)
        for (const member of membersToBeDeleted) {
          await handlers.deleteGhostTeamMember({ id: member.id })
        }
      }
    },
    [teamId, teamMember]
  )

  const retire = useCallback(async () => {
    if (teamId) {
      const response = await updateGhostTeamMember({ input: { id: teamId, manager: GhostMemberManage.Pending } })
      return response
    }
  }, [updateGhostTeamMember, teamId])

  return {
    teamMember,
    teamMemberList,
    acceptedMemberCount,
    loading,
    createGhostTeamMember,
    updateGhostTeamMember,
    updateGhostTeamMemberByAdmin,
    deleteGhostTeamMember,
    renewTeamMemberList,
    dropMembers,
    retire,
    updateMemberPage,
  } as const
}

export const listStuckMembers = async (
  teamId: string,
  stage: 'waiting' | 'report' | NextPagesAfterBreak,
  teamMember: GhostTeamMember
): Promise<GhostTeamMember[]> => {
  const teamMemberList = (await handlers.getGhostTeamMemberList({ teamId })).items
  let membersToBeDeleted = []
  if (stage === 'waiting') {
    membersToBeDeleted = teamMemberList.filter((member) => member.manager === GhostMemberManage.Pending)
  } else {
    const isMemberStuck = (member: GhostTeamMember) => !member.page || member.page < page2Num(stage, teamMember) - 1
    membersToBeDeleted = teamMemberList.filter(isMemberStuck)
  }
  return membersToBeDeleted
}
