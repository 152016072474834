import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { Flexbox, UsefulCard } from '../atoms'
import { ClickableCard } from '../modules'

type Props = { ownStyles?: React.CSSProperties }

export const GhostBoard: React.FC<Props> = (props) => {
  const classes = useStyles({})
  const [firstReportVisible, setFirstReportVisible] = React.useState(true)
  const onClick = () => setFirstReportVisible((prev) => !prev)

  return (
    <>
      <Flexbox>
        <ClickableCard onClick={onClick} clickable={!firstReportVisible}>
          発見したオバケ
        </ClickableCard>
        <ClickableCard onClick={onClick} clickable={firstReportVisible}>
          すべてのオバケ
        </ClickableCard>
      </Flexbox>
      <UsefulCard backgroundColor="white">
        <div className={classes.firstReportBox} style={firstReportVisible ? {} : { display: 'none' }}>
          first
        </div>
        <div className={classes.secondReportBox} style={firstReportVisible ? { display: 'none' } : {}}>
          second
        </div>
      </UsefulCard>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  firstReportBox: {},
  secondReportBox: {},
}))
