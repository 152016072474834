import { IKarteMemberQuestionnaireForm } from './questionnaire/interface'

export const cookieKeys = {
  number: 'karteMemberNumber',
  data: 'karteMemberData',
}

export const teamCookieKeys = {
  phase: 'teamKartePhase',
  data: 'teamKarteData',
}

const formsGeneralOptions = [
  { key: '1', label: '重視しない' },
  { key: '2', label: 'あまり\n重視しない' },
  { key: '3', label: 'やや\n重視する' },
  { key: '4', label: '重視する' },
  { key: '5', label: 'とても\n重視する' },
  { key: '6', label: '譲れないもの\nとして\n重視する' },
  { key: '7', label: '絶対に\n譲れない' },
]

const formsAnketOptions = [
  { key: '1', label: 'そう思わない' },
  { key: '2', label: 'やや\nそう思わない' },
  { key: '3', label: 'どちらとも\n言えない' },
  { key: '4', label: 'やや\nそう思う' },
  { key: '5', label: 'そう思う' },
]

export const groupLabels = [
  { group: 'GROUP-1', label: '仕事の明確さ' },
  { group: 'GROUP-2', label: '仕事のコントロール' },
  { group: 'GROUP-3', label: '自律性尊重の風土' },
  { group: 'GROUP-4', label: '能力発揮の実感' },
  { group: 'GROUP-5', label: '成長につながる仕事' },
  { group: 'GROUP-6', label: '成長を後押しする環境' },
  { group: 'GROUP-7', label: '社会・顧客とのつながり' },
  { group: 'GROUP-8', label: '上司とのつながり' },
  { group: 'GROUP-9', label: '職場の仲間とのつながり' },
]

export const forms: IKarteMemberQuestionnaireForm[] = [
  {
    label: 'はじめに以下の質問に答えてください',
    group: 'common',
    fields: [
      {
        number: 1,
        group: 'common',
        key: '性別',
        label: '性別',
        type: 'radio',
        options: [
          { key: '女性', label: '女性' },
          { key: '男性', label: '男性' },
          { key: 'その他', label: 'その他' },
        ],
      },
      {
        number: 2,
        group: 'common',
        key: '年代',
        label: '年代',
        type: 'radio',
        options: [
          { key: '20歳未満', label: '20歳未満' },
          { key: '20-24', label: '20-24' },
          { key: '25-29', label: '25-29' },
          { key: '30-34', label: '30-34' },
          { key: '35-39', label: '35-39' },
          { key: '40-44', label: '40-44' },
          { key: '45-49', label: '45-49' },
          { key: '50歳以上', label: '50歳以上' },
        ],
      },
      {
        number: 3,
        group: 'common',
        key: '職種',
        label: '職種',
        type: 'select',
        options: [
          { key: '管理職', label: '管理職' },
          { key: '営業職', label: '営業職' },
          { key: '事務職', label: '事務職' },
          { key: '専門･技術職', label: '専門･技術職' },
          { key: 'サービス職', label: 'サービス職' },
          { key: 'その他', label: 'その他' },
        ],
      },
      {
        number: 4,
        group: 'common',
        key: '従業員規模',
        label: '従業員規模',
        type: 'select',
        options: [
          { key: '1人', label: '1人' },
          { key: '2~10人', label: '2~10人' },
          { key: '11人～99人', label: '11人～99人' },
          { key: '100人～999人', label: '100人～999人' },
          { key: '1000人～4999人', label: '1000人～4999人' },
          { key: '5000人～', label: '5000人～' },
        ],
      },
    ],
  },
  {
    label: '',
    weight: 1,
    fields: [
      {
        number: 1,
        group: 'GROUP-1',
        key: 'GROUP-1-QUESTION-1',
        label: '自分の職務や責任の範囲を明確にしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 1,
      },
      {
        number: 2,
        group: 'GROUP-4',
        key: 'GROUP-4-QUESTION-1',
        label: '自分の持っている能力を十二分に発揮できる仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 2,
      },
      {
        number: 3,
        group: 'GROUP-7',
        key: 'GROUP-7-QUESTION-1',
        label: '社会にとって意味がある仕事をしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 3,
      },
      {
        number: 4,
        group: 'GROUP-2',
        key: 'GROUP-2-QUESTION-1',
        label: '仕事の進め方は自分でコントロールしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 4,
      },
    ],
  },
  {
    label: '',
    weight: 2,
    fields: [
      {
        number: 5,
        group: 'GROUP-5',
        key: 'GROUP-5-QUESTION-1',
        label: 'この仕事を通じて成長すると予感したい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 5,
      },
      {
        number: 6,
        group: 'GROUP-8',
        key: 'GROUP-8-QUESTION-1',
        label: '上司としっかりとした信頼関係を築きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 6,
      },
      {
        number: 7,
        group: 'GROUP-3',
        key: 'GROUP-3-QUESTION-1',
        label: '一人ひとりの個性やスタイルを認め合う風土で仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 7,
      },
      {
        number: 8,
        group: 'GROUP-6',
        key: 'GROUP-6-QUESTION-1',
        label: '成長を後押ししてくれる風土で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 8,
      },
    ],
  },
  {
    label: '',
    weight: 3,
    fields: [
      {
        number: 9,
        group: 'GROUP-9',
        key: 'GROUP-9-QUESTION-1',
        label: '職場全員で一丸となって働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 9,
      },
      {
        number: 10,
        group: 'GROUP-1',
        key: 'GROUP-1-QUESTION-2',
        label: '最初に決めた計画をしっかりと遂行する仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 10,
      },
      {
        number: 11,
        group: 'GROUP-4',
        key: 'GROUP-4-QUESTION-2',
        label: '自分の強みが発揮できる仕事をしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 11,
      },
      {
        number: 12,
        group: 'GROUP-7',
        key: 'GROUP-7-QUESTION-2',
        label: '社会やお客様への貢献につながる仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 12,
      },
    ],
  },
  {
    label: '',
    weight: 4,
    fields: [
      {
        number: 13,
        group: 'GROUP-2',
        key: 'GROUP-2-QUESTION-2',
        label: '自分で仕事の順番・やり方を決めたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 13,
      },
      {
        number: 14,
        group: 'GROUP-5',
        key: 'GROUP-5-QUESTION-2',
        label: '成長できるなら、少し難しい仕事でも取り組みたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 14,
      },
      {
        number: 15,
        group: 'GROUP-8',
        key: 'GROUP-8-QUESTION-2',
        label: '上司と率直に会話できる関係でありたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 15,
      },
      {
        number: 16,
        group: 'GROUP-3',
        key: 'GROUP-3-QUESTION-2',
        label: '働く時間や場所などの融通が利く職場で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 16,
      },
    ],
  },
  {
    label: '',
    weight: 5,
    fields: [
      {
        number: 17,
        group: 'GROUP-6',
        key: 'GROUP-6-QUESTION-2',
        label: '挑戦や成長に対して応援してくれる環境で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 17,
      },
      {
        number: 18,
        group: 'GROUP-9',
        key: 'GROUP-9-QUESTION-2',
        label: '共通の目標に向かって、志が同じメンバーと仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 18,
      },
      {
        number: 19,
        group: 'GROUP-1',
        key: 'GROUP-1-QUESTION-3',
        label: '任された仕事だけに集中できる役割でありたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 19,
      },
      {
        number: 20,
        group: 'GROUP-4',
        key: 'GROUP-4-QUESTION-3',
        label: '「自分ならでは」を活かせる仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 20,
      },
    ],
  },
  {
    label: '',
    weight: 6,
    fields: [
      {
        number: 21,
        group: 'GROUP-7',
        key: 'GROUP-7-QUESTION-3',
        label: 'お客様の喜ぶ声など反応が得られる仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 21,
      },
      {
        number: 22,
        group: 'GROUP-2',
        key: 'GROUP-2-QUESTION-3',
        label: '自分で進める裁量権がある仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 22,
      },
      {
        number: 23,
        group: 'GROUP-5',
        key: 'GROUP-5-QUESTION-3',
        label: '今までよりも成長できる仕事に携わりたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 23,
      },
      {
        number: 24,
        group: 'GROUP-8',
        key: 'GROUP-8-QUESTION-3',
        label: '上司と相互補完の関係である感覚を持ちたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 24,
      },
    ],
  },
  {
    label: '',
    weight: 7,
    fields: [
      {
        number: 25,
        group: 'GROUP-3',
        key: 'GROUP-3-QUESTION-3',
        label: '細かな管理や報告義務が少ない職場で仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 25,
      },
      {
        number: 26,
        group: 'GROUP-6',
        key: 'GROUP-6-QUESTION-3',
        label: '成長のためのフィードバックがある職場で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 26,
      },
      {
        number: 27,
        group: 'GROUP-9',
        key: 'GROUP-9-QUESTION-3',
        label: '互いに励まし合える職場で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 27,
      },
      {
        number: 28,
        group: 'GROUP-1',
        key: 'GROUP-1-QUESTION-4',
        label: '任された仕事には、自分にとって最適な仕事量の中で取り組みたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 28,
      },
    ],
  },
  {
    label: '',
    weight: 8,
    fields: [
      {
        number: 29,
        group: 'GROUP-4',
        key: 'GROUP-4-QUESTION-4',
        label: '自分の蓄積した知識やスキルを活用したい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 29,
      },
      {
        number: 30,
        group: 'GROUP-7',
        key: 'GROUP-7-QUESTION-4',
        label: '自社のサービスは社会にとって意味があると思いたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 30,
      },
      {
        number: 31,
        group: 'GROUP-2',
        key: 'GROUP-2-QUESTION-4',
        label: '自分で決めることができる機会の多い仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 31,
      },
      {
        number: 32,
        group: 'GROUP-5',
        key: 'GROUP-5-QUESTION-4',
        label: '学びの機会が多い仕事に取り組みたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 32,
      },
    ],
  },
  {
    label: '',
    weight: 9,
    fields: [
      {
        number: 33,
        group: 'GROUP-8',
        key: 'GROUP-8-QUESTION-4',
        label: '上司からの期待をしっかりと捉えたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 33,
      },
      {
        number: 34,
        group: 'GROUP-3',
        key: 'GROUP-3-QUESTION-4',
        label: '仕事の進め方を本人に任せる風土で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 34,
      },
      {
        number: 35,
        group: 'GROUP-6',
        key: 'GROUP-6-QUESTION-4',
        label: '視野が広がる機会が多い職場で働きたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 35,
      },
      {
        number: 36,
        group: 'GROUP-9',
        key: 'GROUP-9-QUESTION-4',
        label: '個人プレーではなくチームとして成果が出せる仕事がしたい',
        type: 'slider',
        options: formsGeneralOptions,
        weight: 36,
      },
    ],
  },
  {
    label: '',
    group: 'anket',
    color: '#ffffe0',
    description: 'この設問は運営側の統計データに利用します。\n回答とも切り離しますので分析結果にも反映されません。',
    fields: [
      {
        number: 37,
        group: 'anket',
        key: '現状の仕事内容に満足している',
        label: '現状の仕事内容に満足している',
        type: 'slider',
        options: formsAnketOptions,
      },
      {
        number: 38,
        group: 'anket',
        key: '自組織で、長く働き続けたいと思っている',
        label: '自組織で、長く働き続けたいと思っている',
        type: 'slider',
        options: formsAnketOptions,
      },
      {
        number: 39,
        group: 'anket',
        key: '仕事をしている自分自身に自信がある',
        label: '仕事をしている自分自身に自信がある',
        type: 'slider',
        options: formsAnketOptions,
      },
      {
        number: 40,
        group: 'anket',
        key: '仕事に対して、夢中になって働きたいと思っている',
        label: '仕事に対して、夢中になって働きたいと思っている',
        type: 'slider',
        options: formsAnketOptions,
      },
    ],
  },
]

export const circleGraph = [
  {
    key: 'CIRCLE_GROUP_1',
    label: '自律性',
    description: '自己の行動を自分自身で決めたいという欲求',
    groups: ['GROUP-1', 'GROUP-2', 'GROUP-3'],
  },
  {
    key: 'CIRCLE_GROUP_2',
    label: '有能感',
    description: '自己の能力を発揮し、証明したいという欲求',
    groups: ['GROUP-4', 'GROUP-5', 'GROUP-6'],
  },
  {
    key: 'CIRCLE_GROUP_3',
    label: '関係性',
    description: '周囲や社会と友好な関係を持ちたいという欲求',
    groups: ['GROUP-7', 'GROUP-8', 'GROUP-9'],
  },
]

export const bubbleTexts: {
  [key: string]: { label: string; shortLabel: string; description: string; linkMember: string; linkLeader: string }
} = {
  'GROUP-1': {
    label: '仕事の明確さ',
    shortLabel: '仕事の\n明確さ',
    description: '任された仕事の範囲が明確で、進めやすいかどうか',
    linkMember: 'https://cocolabo.club/hint/tips/3e0b7322-8ae0-412d-861b-106031dc2d44',
    linkLeader: 'https://cocolabo.club/hint/tips/5ea58589-a7f2-46c6-bd9b-224360d5e13c',
  },
  'GROUP-2': {
    label: '仕事のコントロール',
    shortLabel: '仕事の\nコントロール',
    description: '進め方を自分でコントロールしやすいかどうか',
    linkMember: 'https://cocolabo.club/hint/tips/59bc44d0-7e1d-4f3b-ba32-5e0016f86a10',
    linkLeader: 'https://cocolabo.club/hint/tips/9dcf4761-f291-4051-9501-e19f6fabc083',
  },
  'GROUP-3': {
    label: '自律性尊重の風土',
    shortLabel: '自律性尊重の\n風土',
    description: '個々が自律的に働くことを尊重している風土かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/bd87a1b5-c2fe-4cc2-a560-80abadc6f675',
    linkLeader: 'https://cocolabo.club/hint/tips/1b9a8835-49b2-417a-9708-32de428de973',
  },
  'GROUP-4': {
    label: '能力発揮の実感',
    shortLabel: '能力発揮の\n実感',
    description: '自分らしさや強みを発揮することができるかどうか',
    linkMember: 'https://cocolabo.club/hint/tips/e5d90c3d-f8a9-4b5b-a4b2-4226b5072f1e',
    linkLeader: 'https://cocolabo.club/hint/tips/e065ca6c-6366-4728-8709-0bb146fbd220',
  },
  'GROUP-5': {
    label: '成長につながる仕事',
    shortLabel: '成長につな\nがる仕事',
    description: '自分の成長につながる仕事かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/3002f2e7-79b0-4ab0-bf04-e28e7a9a7d19',
    linkLeader: 'https://cocolabo.club/hint/tips/f5a2d85a-49ab-4948-906c-32b3bb8ab9d3',
  },
  'GROUP-6': {
    label: '成長を後押しする環境',
    shortLabel: '成長を後押し\nする環境',
    description: '自分が成長していくことを後押しする環境かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/b763b4c0-00c0-42d1-929b-9aa04a5ebacc',
    linkLeader: 'https://cocolabo.club/hint/tips/97c641ec-fbab-40f1-8297-7f803e72632b',
  },
  'GROUP-7': {
    label: '社会・顧客とのつながり',
    shortLabel: '社会・顧客\nとのつながり',
    description: '社会や顧客にとって意味があると感じる仕事かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/54beb5af-630e-4050-acff-a86ac1462b8c',
    linkLeader: 'https://cocolabo.club/hint/tips/e8466402-501b-4cb2-9711-03a0595cb0d9',
  },
  'GROUP-8': {
    label: '上司とのつながり',
    shortLabel: '上司との\nつながり',
    description: '上司と良好な関係性かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/bf378017-a784-46e2-9132-21850fdb5131',
    linkLeader: 'https://cocolabo.club/hint/tips/1a91ab53-0b53-4aea-b539-7d45dcc2ffa6',
  },
  'GROUP-9': {
    label: '職場の仲間とのつながり',
    shortLabel: '職場の仲間と\nのつながり',
    description: '職場の仲間と良好な関係性かどうか',
    linkMember: 'https://cocolabo.club/hint/tips/c15ad902-dca4-4a0a-8036-7d1a12572a09',
    linkLeader: 'https://cocolabo.club/hint/tips/60c5cf9e-19d7-4efa-89b8-dc6976840e87',
  },
}

export const questionnaireAnswerPatterns = [
  {
    first: 'GROUP-1',
    second: 'GROUP-1',
    about: {
      short: 'テキパキお仕事処理傾向',
      long: '与えられた明確な職務に対して、自分のペースで進めることを大事にしています。',
    },
    motivation:
      '責任感が強いタイプだと思われます。\nその良さを活かすためにも、仕事の受け方や上司との関係性が大事になってきます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-2',
    about: {
      short: 'テキパキお仕事処理傾向',
      long: '与えられた明確な職務に対して、自分のペースで進めることを大事にしています。',
    },
    motivation:
      '責任感が強いタイプだと思われます。\nその良さを活かすためにも、仕事の受け方や上司との関係性が大事になってきます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-3',
    about: {
      short: 'テキパキお仕事処理傾向',
      long: '与えられた明確な職務に対して、自分のペースで進めることを大事にしています。',
    },
    motivation:
      '責任感が強いタイプだと思われます。\nその良さを活かすためにも、仕事の受け方や上司との関係性が大事になってきます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-4',
    about: {
      short: '手堅い成長重視傾向',
      long: '明確な仕事を通じて、自分を成長させることを大事にしています。',
    },
    motivation:
      '自責思考と着実さがあるタイプだと思われます。\n日々、振り返りの習慣をつけると更なる成長が期待できます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-5',
    about: {
      short: '手堅い成長重視傾向',
      long: '明確な仕事を通じて、自分を成長させることを大事にしています。',
    },
    motivation:
      '自責思考と着実さがあるタイプだと思われます。\n日々、振り返りの習慣をつけると更なる成長が期待できます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-6',
    about: {
      short: '手堅い成長重視傾向',
      long: '明確な仕事を通じて、自分を成長させることを大事にしています。',
    },
    motivation:
      '自責思考と着実さがあるタイプだと思われます。\n日々、振り返りの習慣をつけると更なる成長が期待できます。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-7',
    about: {
      short: '自分の役割遂行傾向',
      long: '求められていることを理解し、仕事を進めることを大事にしています。',
    },
    motivation:
      '仕事を進める際に、問題が起こりにくいタイプだと思われます。\n関係者へのこまめなホウレンソウを行い、より良い状況を作っていきましょう。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-8',
    about: {
      short: '自分の役割遂行傾向',
      long: '求められていることを理解し、仕事を進めることを大事にしています。',
    },
    motivation:
      '仕事を進める際に、問題が起こりにくいタイプだと思われます。\n関係者へのこまめなホウレンソウを行い、より良い状況を作っていきましょう。',
  },
  {
    first: 'GROUP-1',
    second: 'GROUP-9',
    about: {
      short: '自分の役割遂行傾向',
      long: '求められていることを理解し、仕事を進めることを大事にしています。',
    },
    motivation:
      '仕事を進める際に、問題が起こりにくいタイプだと思われます。\n関係者へのこまめなホウレンソウを行い、より良い状況を作っていきましょう。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-1',
    about: {
      short: '自己決定が大切傾向',
      long: '決められた成果に対して、自分で決めたプロセスで進めることを大事にしています。',
    },
    motivation:
      '個人で動くことが得意なタイプだと思われます。\n先手を打って早く成果を出すことが、良い環境をキープする上でのポイントです。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-2',
    about: {
      short: '自己決定が大切傾向',
      long: '決められた成果に対して、自分で決めたプロセスで進めることを大事にしています。',
    },
    motivation:
      '個人で動くことが得意なタイプだと思われます。\n先手を打って早く成果を出すことが、良い環境をキープする上でのポイントです。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-3',
    about: {
      short: '自己決定が大切傾向',
      long: '決められた成果に対して、自分で決めたプロセスで進めることを大事にしています。',
    },
    motivation:
      '個人で動くことが得意なタイプだと思われます。\n先手を打って早く成果を出すことが、良い環境をキープする上でのポイントです。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-4',
    about: {
      short: '自分で成長します傾向',
      long: '自分で判断することで成果や成長につなげていくことを大事にしています。',
    },
    motivation:
      '何に対しても、自分で何とかしたいという気持ちが強いタイプだと思われます。\n周りを活かすことを意識すると、さらに活躍の範囲が広がります。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-5',
    about: {
      short: '自分で成長します傾向',
      long: '自分で判断することで成果や成長につなげていくことを大事にしています。',
    },
    motivation:
      '何に対しても、自分で何とかしたいという気持ちが強いタイプだと思われます。\n周りを活かすことを意識すると、さらに活躍の範囲が広がります。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-6',
    about: {
      short: '自分で成長します傾向',
      long: '自分で判断することで成果や成長につなげていくことを大事にしています。',
    },
    motivation:
      '何に対しても、自分で何とかしたいという気持ちが強いタイプだと思われます。\n周りを活かすことを意識すると、さらに活躍の範囲が広がります。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-7',
    about: {
      short: '自分が何とかしたい傾向',
      long: '自分から周りに影響力を発揮して、良いつながりを作ることを大事にしています。',
    },
    motivation:
      'バランス感覚が大事になってくるタイプだと思われます。\n周りの人の良い面を捉え、日々感謝することがより良いつながりを作るポイントです。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-8',
    about: {
      short: '自分が何とかしたい傾向',
      long: '自分から周りに影響力を発揮して、良いつながりを作ることを大事にしています。',
    },
    motivation:
      'バランス感覚が大事になってくるタイプだと思われます。\n周りの人の良い面を捉え、日々感謝することがより良いつながりを作るポイントです。',
  },
  {
    first: 'GROUP-2',
    second: 'GROUP-9',
    about: {
      short: '自分が何とかしたい傾向',
      long: '自分から周りに影響力を発揮して、良いつながりを作ることを大事にしています。',
    },
    motivation:
      'バランス感覚が大事になってくるタイプだと思われます。\n周りの人の良い面を捉え、日々感謝することがより良いつながりを作るポイントです。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-1',
    about: {
      short: '公私充実するぞ傾向',
      long: '自分のペースで物事を進めることを大事にしています。',
    },
    motivation:
      '公私共に予定を充実させることを大切にするタイプだと思われます。\nそのため自分で抱え込みすぎず、自分の情報をオープンにすることを心がけましょう。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-2',
    about: {
      short: '公私充実するぞ傾向',
      long: '自分のペースで物事を進めることを大事にしています。',
    },
    motivation:
      '公私共に予定を充実させることを大切にするタイプだと思われます。\nそのため自分で抱え込みすぎず、自分の情報をオープンにすることを心がけましょう。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-3',
    about: {
      short: '公私充実するぞ傾向',
      long: '自分のペースで物事を進めることを大事にしています。',
    },
    motivation:
      '公私共に予定を充実させることを大切にするタイプだと思われます。\nそのため自分で抱え込みすぎず、自分の情報をオープンにすることを心がけましょう。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-4',
    about: {
      short: '自己研鑽好き傾向',
      long: '公私ともに成長することを大事にしています。',
    },
    motivation:
      '自己研鑽に励むタイプだと思われます。\n研鑽で得たスキルや経験を、日々少しでも発揮することがやりがいにつながります。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-5',
    about: {
      short: '自己研鑽好き傾向',
      long: '公私ともに成長することを大事にしています。',
    },
    motivation:
      '自己研鑽に励むタイプだと思われます。\n研鑽で得たスキルや経験を、日々少しでも発揮することがやりがいにつながります。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-6',
    about: {
      short: '自己研鑽好き傾向',
      long: '公私ともに成長することを大事にしています。',
    },
    motivation:
      '自己研鑽に励むタイプだと思われます。\n研鑽で得たスキルや経験を、日々少しでも発揮することがやりがいにつながります。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-7',
    about: {
      short: '絶妙な距離感重視傾向',
      long: '個が尊重された風土の中で、様々な人と良好な関係を築くことを大事にしています。',
    },
    motivation:
      '人と適度な距離感を作ることが上手なタイプだと思われます。\n自ら周りに働きかけることを大事にすると、更に良好な関係を築くことにつながります。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-8',
    about: {
      short: '絶妙な距離感重視傾向',
      long: '個が尊重された風土の中で、様々な人と良好な関係を築くことを大事にしています。',
    },
    motivation:
      '人と適度な距離感を作ることが上手なタイプだと思われます。\n自ら周りに働きかけることを大事にすると、更に良好な関係を築くことにつながります。',
  },
  {
    first: 'GROUP-3',
    second: 'GROUP-9',
    about: {
      short: '絶妙な距離感重視傾向',
      long: '個が尊重された風土の中で、様々な人と良好な関係を築くことを大事にしています。',
    },
    motivation:
      '人と適度な距離感を作ることが上手なタイプだと思われます。\n自ら周りに働きかけることを大事にすると、更に良好な関係を築くことにつながります。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-1',
    about: {
      short: '日々の手応え大切傾向',
      long: '常に自分が価値を発揮している実感を得ることを大事にしています。',
    },
    motivation:
      '短期的な思考が強いタイプだと思われます。\n振り返りを通じて自己肯定し、将来の自分の可能性を信じて進むのがポイントです。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-2',
    about: {
      short: '日々の手応え大切傾向',
      long: '常に自分が価値を発揮している実感を得ることを大事にしています。',
    },
    motivation:
      '短期的な思考が強いタイプだと思われます。\n振り返りを通じて自己肯定し、将来の自分の可能性を信じて進むのがポイントです。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-3',
    about: {
      short: '日々の手応え大切傾向',
      long: '常に自分が価値を発揮している実感を得ることを大事にしています。',
    },
    motivation:
      '短期的な思考が強いタイプだと思われます。\n振り返りを通じて自己肯定し、将来の自分の可能性を信じて進むのがポイントです。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-4',
    about: {
      short: 'アスリート志向な傾向',
      long: 'どんどん次のステージへ進み、能力発揮していくことを大事にしています。',
    },
    motivation:
      'バイタリティーがあり、ややせっかちなタイプだと思われます。\n定期的に立ち止まり、自分自身を俯瞰して見る機会を作ることが能力発揮につながります。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-5',
    about: {
      short: 'アスリート志向な傾向',
      long: 'どんどん次のステージへ進み、能力発揮していくことを大事にしています。',
    },
    motivation:
      'バイタリティーがあり、ややせっかちなタイプだと思われます。\n定期的に立ち止まり、自分自身を俯瞰して見る機会を作ることが能力発揮につながります。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-6',
    about: {
      short: 'アスリート志向な傾向',
      long: 'どんどん次のステージへ進み、能力発揮していくことを大事にしています。',
    },
    motivation:
      'バイタリティーがあり、ややせっかちなタイプだと思われます。\n定期的に立ち止まり、自分自身を俯瞰して見る機会を作ることが能力発揮につながります。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-7',
    about: {
      short: '誰かの役に立ちたい傾向',
      long: '社会や関係者に対して、常に能力を発揮する実感を持つことを大事にしています。',
    },
    motivation:
      '利他的なスタンスが強いタイプだと思われます。\n上司や周りと対話を増やし、貢献への手応えを掴むのがポイントです。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-8',
    about: {
      short: '誰かの役に立ちたい傾向',
      long: '社会や関係者に対して、常に能力を発揮する実感を持つことを大事にしています。',
    },
    motivation:
      '利他的なスタンスが強いタイプだと思われます。\n上司や周りと対話を増やし、貢献への手応えを掴むのがポイントです。',
  },
  {
    first: 'GROUP-4',
    second: 'GROUP-9',
    about: {
      short: '誰かの役に立ちたい傾向',
      long: '社会や関係者に対して、常に能力を発揮する実感を持つことを大事にしています。',
    },
    motivation:
      '利他的なスタンスが強いタイプだと思われます。\n上司や周りと対話を増やし、貢献への手応えを掴むのがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-1',
    about: {
      short: '成長できる仕事求む傾向',
      long: '自分の力で仕事を進め、成長することを大事にしています。',
    },
    motivation:
      '一歩ずつ進んでいく着実性があるタイプだと思われます。\nだからこそ、周りの人から学ぶ意識を強化することが成長を加速させるポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-2',
    about: {
      short: '成長できる仕事求む傾向',
      long: '自分の力で仕事を進め、成長することを大事にしています。',
    },
    motivation:
      '一歩ずつ進んでいく着実性があるタイプだと思われます。\nだからこそ、周りの人から学ぶ意識を強化することが成長を加速させるポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-3',
    about: {
      short: '成長できる仕事求む傾向',
      long: '自分の力で仕事を進め、成長することを大事にしています。',
    },
    motivation:
      '一歩ずつ進んでいく着実性があるタイプだと思われます。\nだからこそ、周りの人から学ぶ意識を強化することが成長を加速させるポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-4',
    about: {
      short: '自分の理想追求傾向',
      long: '自分の成長のための環境と機会を大事にしています。',
    },
    motivation:
      '自分が強く、周りが見えにくいタイプだと思われます。\n自分自身は何を目指すのか定期的に立ち戻り、日々改善していくことがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-5',
    about: {
      short: '自分の理想追求傾向',
      long: '自分の成長のための環境と機会を大事にしています。',
    },
    motivation:
      '自分が強く、周りが見えにくいタイプだと思われます。\n自分自身は何を目指すのか定期的に立ち戻り、日々改善していくことがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-6',
    about: {
      short: '自分の理想追求傾向',
      long: '自分の成長のための環境と機会を大事にしています。',
    },
    motivation:
      '自分が強く、周りが見えにくいタイプだと思われます。\n自分自身は何を目指すのか定期的に立ち戻り、日々改善していくことがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-7',
    about: {
      short: '成長とのバランス重視傾向',
      long: '自分自身の成長だけでなく、周りへの貢献も大事にしています。',
    },
    motivation:
      '日々、小さくても刺激があることを望むタイプだと思われます。\nこまめに振り返りを行い、変化等を直視していくのがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-8',
    about: {
      short: '成長とのバランス重視傾向',
      long: '自分自身の成長だけでなく、周りへの貢献も大事にしています。',
    },
    motivation:
      '日々、小さくても刺激があることを望むタイプだと思われます。\nこまめに振り返りを行い、変化等を直視していくのがポイントです。',
  },
  {
    first: 'GROUP-5',
    second: 'GROUP-9',
    about: {
      short: '成長とのバランス重視傾向',
      long: '自分自身の成長だけでなく、周りへの貢献も大事にしています。',
    },
    motivation:
      '日々、小さくても刺激があることを望むタイプだと思われます。\nこまめに振り返りを行い、変化等を直視していくのがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-1',
    about: {
      short: '環境を活かした自己成長重視傾向',
      long: '周りの環境を活かしながら、自分で成長していくことを大事にしています。',
    },
    motivation:
      'やや頑張り屋さんなタイプだと思われます。\n周りを信頼し、弱みを見せたり、頼ったりすることがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-2',
    about: {
      short: '環境を活かした自己成長重視傾向',
      long: '周りの環境を活かしながら、自分で成長していくことを大事にしています。',
    },
    motivation:
      'やや頑張り屋さんなタイプだと思われます。\n周りを信頼し、弱みを見せたり、頼ったりすることがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-3',
    about: {
      short: '環境を活かした自己成長重視傾向',
      long: '周りの環境を活かしながら、自分で成長していくことを大事にしています。',
    },
    motivation:
      'やや頑張り屋さんなタイプだと思われます。\n周りを信頼し、弱みを見せたり、頼ったりすることがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-4',
    about: {
      short: '高い成長欲求傾向',
      long: '成長できる環境で、しっかりと成長していくことを大事にしています。',
    },
    motivation:
      '周りから刺激を受けることが好きなタイプだと思われます。\n自分が出来るようになったことを定期的に直視し、自信につなげていくことがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-5',
    about: {
      short: '高い成長欲求傾向',
      long: '成長できる環境で、しっかりと成長していくことを大事にしています。',
    },
    motivation:
      '周りから刺激を受けることが好きなタイプだと思われます。\n自分が出来るようになったことを定期的に直視し、自信につなげていくことがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-6',
    about: {
      short: '高い成長欲求傾向',
      long: '成長できる環境で、しっかりと成長していくことを大事にしています。',
    },
    motivation:
      '周りから刺激を受けることが好きなタイプだと思われます。\n自分が出来るようになったことを定期的に直視し、自信につなげていくことがポイントです。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-7',
    about: {
      short: '共に成長しよう傾向',
      long: '切磋琢磨できる職場環境に身を置くことを大事にしています。',
    },
    motivation:
      '日々改善していくことが得意なタイプだと思われます。\nだからこそ、自分はどうありたいのかをはっきりと描くと、さらに成長が加速されます。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-8',
    about: {
      short: '共に成長しよう傾向',
      long: '切磋琢磨できる職場環境に身を置くことを大事にしています。',
    },
    motivation:
      '日々改善していくことが得意なタイプだと思われます。\nだからこそ、自分はどうありたいのかをはっきりと描くと、さらに成長が加速されます。',
  },
  {
    first: 'GROUP-6',
    second: 'GROUP-9',
    about: {
      short: '共に成長しよう傾向',
      long: '切磋琢磨できる職場環境に身を置くことを大事にしています。',
    },
    motivation:
      '日々改善していくことが得意なタイプだと思われます。\nだからこそ、自分はどうありたいのかをはっきりと描くと、さらに成長が加速されます。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-1',
    about: {
      short: '価値貢献に携わりたい傾向',
      long: '社会に対して意味ある価値を出すことに自分が携われることを何よりも大事にしています。',
    },
    motivation:
      '「自分ならでは」を大事にしているタイプだと思われます。\n影響力を広げるためにも、自ら周りを巻き込むことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-2',
    about: {
      short: '価値貢献に携わりたい傾向',
      long: '社会に対して意味ある価値を出すことに自分が携われることを何よりも大事にしています。',
    },
    motivation:
      '「自分ならでは」を大事にしているタイプだと思われます。\n影響力を広げるためにも、自ら周りを巻き込むことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-3',
    about: {
      short: '価値貢献に携わりたい傾向',
      long: '社会に対して意味ある価値を出すことに自分が携われることを何よりも大事にしています。',
    },
    motivation:
      '「自分ならでは」を大事にしているタイプだと思われます。\n影響力を広げるためにも、自ら周りを巻き込むことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-4',
    about: {
      short: 'ストイック傾向',
      long: '社会に対する価値提供を高めるために成長していくことを大事にしています。',
    },
    motivation:
      '「まだまだ」と自分に対して厳しい面があるタイプだと思われます。\n改善点だけでなく、物事の良い面も見るようにするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-5',
    about: {
      short: 'ストイック傾向',
      long: '社会に対する価値提供を高めるために成長していくことを大事にしています。',
    },
    motivation:
      '「まだまだ」と自分に対して厳しい面があるタイプだと思われます。\n改善点だけでなく、物事の良い面も見るようにするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-6',
    about: {
      short: 'ストイック傾向',
      long: '社会に対する価値提供を高めるために成長していくことを大事にしています。',
    },
    motivation:
      '「まだまだ」と自分に対して厳しい面があるタイプだと思われます。\n改善点だけでなく、物事の良い面も見るようにするのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-7',
    about: {
      short: 'チームミッション追求傾向',
      long: 'チーム全体で社会に新たな価値を提供することを大事にしています。',
    },
    motivation:
      'チームのミッションやビジョンを大切にするタイプだと思われます。\nだからこそ、周りに対して積極的にコミュニケーションをとるのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-8',
    about: {
      short: 'チームミッション追求傾向',
      long: 'チーム全体で社会に新たな価値を提供することを大事にしています。',
    },
    motivation:
      'チームのミッションやビジョンを大切にするタイプだと思われます。\nだからこそ、周りに対して積極的にコミュニケーションをとるのがポイントです。',
  },
  {
    first: 'GROUP-7',
    second: 'GROUP-9',
    about: {
      short: 'チームミッション追求傾向',
      long: 'チーム全体で社会に新たな価値を提供することを大事にしています。',
    },
    motivation:
      'チームのミッションやビジョンを大切にするタイプだと思われます。\nだからこそ、周りに対して積極的にコミュニケーションをとるのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-1',
    about: {
      short: '上司とタッグ傾向',
      long: '上司とより良い関係を築き、その上で自分のペースで仕事を進められることを大事にしています。',
    },
    motivation:
      'きっちりとしたいタイプだと思われます。\n先を見据えると、少しずつ影響範囲を広げていくことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-2',
    about: {
      short: '上司とタッグ傾向',
      long: '上司とより良い関係を築き、その上で自分のペースで仕事を進められることを大事にしています。',
    },
    motivation:
      'きっちりとしたいタイプだと思われます。\n先を見据えると、少しずつ影響範囲を広げていくことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-3',
    about: {
      short: '上司とタッグ傾向',
      long: '上司とより良い関係を築き、その上で自分のペースで仕事を進められることを大事にしています。',
    },
    motivation:
      'きっちりとしたいタイプだと思われます。\n先を見据えると、少しずつ影響範囲を広げていくことにトライするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-4',
    about: {
      short: '可愛い部下傾向',
      long: '上司とのより良い関係と成長を支援してもらうことを大事にしています。',
    },
    motivation:
      '上司と仲が良くなるタイプだと思われます。\n何を期待されていて、これから何を目指すのか定期的に上司と話をするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-5',
    about: {
      short: '可愛い部下傾向',
      long: '上司とのより良い関係と成長を支援してもらうことを大事にしています。',
    },
    motivation:
      '上司と仲が良くなるタイプだと思われます。\n何を期待されていて、これから何を目指すのか定期的に上司と話をするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-6',
    about: {
      short: '可愛い部下傾向',
      long: '上司とのより良い関係と成長を支援してもらうことを大事にしています。',
    },
    motivation:
      '上司と仲が良くなるタイプだと思われます。\n何を期待されていて、これから何を目指すのか定期的に上司と話をするのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-7',
    about: {
      short: '部下として安心傾向',
      long: '上司を中心に関係者とより良い関係であることを大事にしています。',
    },
    motivation:
      'フラットで誠実なタイプだと思われます。\nその良さを活かし、自分の意向や意見を少しずつ発信していくのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-8',
    about: {
      short: '部下として安心傾向',
      long: '上司を中心に関係者とより良い関係であることを大事にしています。',
    },
    motivation:
      'フラットで誠実なタイプだと思われます。\nその良さを活かし、自分の意向や意見を少しずつ発信していくのがポイントです。',
  },
  {
    first: 'GROUP-8',
    second: 'GROUP-9',
    about: {
      short: '部下として安心傾向',
      long: '上司を中心に関係者とより良い関係であることを大事にしています。',
    },
    motivation:
      'フラットで誠実なタイプだと思われます。\nその良さを活かし、自分の意向や意見を少しずつ発信していくのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-1',
    about: {
      short: '自分のできることから貢献するぞ傾向',
      long: '良いチームの中で、自分に与えられた役割を通じて貢献することを大事にしています。',
    },
    motivation:
      '縁の下の力持ち的なタイプだと思われます。\nだからこそ、自分から成果を発信し、周りとコラボレーションをはかるのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-2',
    about: {
      short: '自分のできることから貢献するぞ傾向',
      long: '良いチームの中で、自分に与えられた役割を通じて貢献することを大事にしています。',
    },
    motivation:
      '縁の下の力持ち的なタイプだと思われます。\nだからこそ、自分から成果を発信し、周りとコラボレーションをはかるのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-3',
    about: {
      short: '自分のできることから貢献するぞ傾向',
      long: '良いチームの中で、自分に与えられた役割を通じて貢献することを大事にしています。',
    },
    motivation:
      '縁の下の力持ち的なタイプだと思われます。\nだからこそ、自分から成果を発信し、周りとコラボレーションをはかるのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-4',
    about: {
      short: '目指せ甲子園傾向',
      long: '職場の仲間とまっすぐ進み、共に成長することを大事にしています。',
    },
    motivation: '活動的なタイプだと思われます。\n周りの期待を自ら捉え、超えていくことを意識するのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-5',
    about: {
      short: '目指せ甲子園傾向',
      long: '職場の仲間とまっすぐ進み、共に成長することを大事にしています。',
    },
    motivation: '活動的なタイプだと思われます。\n周りの期待を自ら捉え、超えていくことを意識するのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-6',
    about: {
      short: '目指せ甲子園傾向',
      long: '職場の仲間とまっすぐ進み、共に成長することを大事にしています。',
    },
    motivation: '活動的なタイプだと思われます。\n周りの期待を自ら捉え、超えていくことを意識するのがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-7',
    about: {
      short: 'チーム作り重視傾向',
      long: '職場の仲間や、社会や上司などの関係者とのつながりを大事にしています。',
    },
    motivation:
      '職場の人と円滑なコミュニケーションを取れるタイプだと思われます。\nチームの目指す方向性を常に意識して、積極的に周りとコミュニケーションとることがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-8',
    about: {
      short: 'チーム作り重視傾向',
      long: '職場の仲間や、社会や上司などの関係者とのつながりを大事にしています。',
    },
    motivation:
      '職場の人と円滑なコミュニケーションを取れるタイプだと思われます。\nチームの目指す方向性を常に意識して、積極的に周りとコミュニケーションとることがポイントです。',
  },
  {
    first: 'GROUP-9',
    second: 'GROUP-9',
    about: {
      short: 'チーム作り重視傾向',
      long: '職場の仲間や、社会や上司などの関係者とのつながりを大事にしています。',
    },
    motivation:
      '職場の人と円滑なコミュニケーションを取れるタイプだと思われます。\nチームの目指す方向性を常に意識して、積極的に周りとコミュニケーションとることがポイントです。',
  },
]

export const questionnaireBubbles: { [key: string]: any } = {
  'GROUP-1': { label: 'GROUP-1', color: 'orange' },
  'GROUP-2': { label: 'GROUP-2', color: 'orange' },
  'GROUP-3': { label: 'GROUP-3', color: 'orange' },
  'GROUP-4': { label: 'GROUP-4', color: 'pink' },
  'GROUP-5': { label: 'GROUP-5', color: 'pink' },
  'GROUP-6': { label: 'GROUP-6', color: 'pink' },
  'GROUP-7': { label: 'GROUP-7', color: 'blue' },
  'GROUP-8': { label: 'GROUP-8', color: 'blue' },
  'GROUP-9': { label: 'GROUP-9', color: 'blue' },
}
