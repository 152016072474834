import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { useGetConsultationEpic } from 'pages/hint/_shared/hooks/useGetConsultationEpic'
import { useGetHintEpic } from 'pages/hint/_shared/hooks/useGetHintEpic'
import { useNextToken } from 'pages/hint/_shared/hooks/useNextToken'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getHintTokenWithFetch: (category: string) => void
  getConsultationTokenWithFetch: (category: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useSearchNextToken = (): IProps => {
  const nextToken = useNextToken()
  const getHintEpic = useGetHintEpic()
  const getConsultationEpic = useGetConsultationEpic()
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)

  /**
   * ページネーションによるnextTokenを制御してfetchする
   */
  const getHintTokenWithFetch = (category: string): void => {
    if (pagesSelectors.pageAction === '') {
      getHintEpic.getHintListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setCurrentLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'NEXT') {
      getHintEpic.getHintListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setNextLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'PREVIOUS') {
      getHintEpic.getHintListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setPrevLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'BACK') {
      getHintEpic.getHintListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setBackLen() || '',
      })
      return
    }
  }

  /**
   * ページネーションによるnextTokenを制御してfetchする
   */
  const getConsultationTokenWithFetch = (category: string): void => {
    if (pagesSelectors.pageAction === '') {
      getConsultationEpic.getConsultationListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setCurrentLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'NEXT') {
      getConsultationEpic.getConsultationListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setNextLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'PREVIOUS') {
      getConsultationEpic.getConsultationListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setPrevLen() || '',
      })
      return
    }

    if (pagesSelectors.pageAction === 'BACK') {
      getConsultationEpic.getConsultationListSearchListEpic({
        isPublished: 1,
        category: category,
        nextToken: nextToken.setBackLen() || '',
      })
      return
    }
  }

  return {
    getHintTokenWithFetch,
    getConsultationTokenWithFetch,
  }
}
