import { API, graphqlOperation } from '@aws-amplify/api'

import { MutationUpdateAccount } from './graphql'

export type UpdateAccountInput = {
  setupStartedAt?: string
  setupStartedAtList?: string[]
  emailNotifySetup?: 0 | 1
}

const updateAccount = async (params: UpdateAccountInput) => {
  try {
    const response: any = await API.graphql(graphqlOperation(MutationUpdateAccount, { input: params }))
    const { updateAccount } = response.data

    return updateAccount
  } catch (e) {
    console.log('error', e)
  }
}

export default updateAccount
