import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  visible: boolean
  right?: string | 'auto'
  left?: string | 'auto'
  top?: string | 'auto'
  bottom?: string | 'auto'
  ownStyles?: React.CSSProperties
}

export const Popup: React.FC<Props> = (props) => {
  const classes = useStyles({})
  Popup.defaultProps = { right: 'auto', left: 'auto', top: 'auto', bottom: 'auto' }

  return (
    <div
      className={classNames([classes.popup, props.visible ? 'visible' : 'inVisible'])}
      style={{ top: props.top, right: props.right, left: props.left, bottom: props.bottom, ...props.ownStyles }}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles({
  popup: {
    '&.visible': {
      position: 'absolute',
      backgroundColor: constGhost.COLOR_WHITE,
      border: `1px solid ${constGhost.COLOR_BORDERLIGHTGRAY}`,
      borderRadius: 5,
      zIndex: 1000,
      padding: '16px 8px',
      fontSize: 11,
    },

    '&.inVisible': {
      display: 'none',
    },
  },
})
