import dayjs from 'dayjs'
import iconv from 'iconv-lite'

import { GhostResearch } from 'utils/generated'

type InputDate = {
  times: number | undefined
  ghostName: string | undefined
  createdAt: string | undefined
  filteredActions: Array<GhostResearch & { fullName: string; imageUrl: string | undefined }> | undefined
}

export const exportCSVGhostIndividualList = async (inputDate: InputDate) => {
  // CSV への出力データを作成
  const outputCsv = []
  outputCsv.push(['回（日付）', '探索したオバケ', '氏名', '明日から出来そうなアクション'].join(','))

  outputCsv.push(
    [`${inputDate.times}回目（${dayjs(inputDate.createdAt).format('YY/MM/DD')}）`, inputDate.ghostName, '', ''].join(
      ','
    )
  )

  if (inputDate.filteredActions === undefined || inputDate.filteredActions.length === 0) {
    outputCsv.push([
      '', // 回（日付）
      '', // 探索したオバケ
      'データが存在しません',
      '', // 明日から出来そうなアクション
    ])
  } else {
    for (const nextAction of inputDate.filteredActions) {
      const name = nextAction.fullName
      const report = nextAction.report

      outputCsv.push([
        '', // 回（日付）
        '', // 探索したオバケ
        name,
        escapeCsvValue(report),
      ])
    }
  }

  // Shift-JIS 形式で CSV としてエンコード
  const csvBuffer = iconv.encode(outputCsv.join('\n'), 'Shift_JIS')
  const filename = `ghostlist-individual-${dayjs().format('YYYYMMDD_HHmmss')}.csv`

  // CSVファイルをダウンロード
  downloadCSVFile(csvBuffer, filename)
}

// CSV ファイルをダウンロードする関数
const downloadCSVFile = (buffer: Buffer, filename: string) => {
  const blob = new Blob([buffer], { type: 'text/csv; charset=shift_jis' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 改行コードやダブルクオーテーション、カンマが含まれていても
// 1セルとして認識させるためにエスケープする関数
const escapeCsvValue = (value: string): string => {
  return `"${value.replace(/"/g, '""')}"`
}
