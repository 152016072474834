import React from 'react'

import * as constants from 'assets/constants'

export enum QuestionTitles {
  Q1 = '今まで属した中で、良かったチームのエピソードをひとつ具体的に教えてください',
  Q2 = '自チームの組織状態面として、自分たちはどのようなチームであれば、”このチームで良かった”と感じますか？',
  Q2_2 = '議論後の順位を踏まえて、自チームの組織状態面として、自分たちはどのようなチームであれば、”このチームで良かった”と感じますか？',
  Q3 = '自チームの理想の組織状態をすり合わせましょう',
  Q4 = '目標に向かって進めるにあたり、全員で順守したい行動規範を選択しましょう',
  Q5 = '良いチームであるために、\n今後のスケジュールを立てましょう',
}

export const ColorQuestionTitles = ({ questionNumber }: { questionNumber: 1 | 2 | 2.5 | 3 | 4 | 5 }) => {
  switch (questionNumber) {
    case 1:
      return (
        <>
          今まで属した中で、
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>良かったチームのエピソード</span>
          をひとつ具体的に教えてください
        </>
      )
    case 2:
      return (
        <>
          自チームの組織状態面として、自分たちはどのようなチームであれば、
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>”このチームで良かった”</span>と感じますか？
        </>
      )
    case 2.5:
      return (
        <>
          議論後の順位を踏まえて、自チームの組織状態面として、自分たちはどのようなチームであれば、
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>”このチームで良かった”</span>と感じますか？
        </>
      )
    case 3:
      return (
        <>
          自チームの
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>理想の組織状態</span>
          をすり合わせましょう
        </>
      )
    case 4:
      return (
        <>
          目標に向かって進めるにあたり、
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>全員で順守したい行動規範</span>
          を選択しましょう
        </>
      )
    case 5:
      return (
        <>
          良いチームであるために、
          <span style={{ color: constants.COLOR_ONBOARDING_MAIN }}>今後のスケジュール</span>
          を立てましょう
        </>
      )
  }
}
