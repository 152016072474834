import * as React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'

import { ISignOutButtonActions } from './SignoutButton.container'

import * as constants from '../assets/constants'

interface IOwnProps {
  signOutCallback: () => Promise<void> | void
  ownStyles?: React.CSSProperties
}

type Props = ISignOutButtonActions & IOwnProps

const Index: React.FC<Props> = (props) => {
  const classes = useStyles()
  const handleSignOut = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await props.signOut()
    await props.signOutCallback()
  }

  return (
    <Button fullWidth={true} onClick={handleSignOut} className={classes.signoutButton} style={{ ...props.ownStyles }}>
      ログアウト
    </Button>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    signoutButton: {
      height: 44,
      borderRadius: 22,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      boxShadow: 'none',
      backgroundColor: constants.COLOR_WHITE,
      margin: '0 0 0 48px',
      [theme.breakpoints.up('md')]: {
        margin: 0,
        padding: '0 40px',
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: `${constants.COLOR_MAIN_NEW}1A`,
        },
      },
    },
  }),
  { name: 'SignoutButtonNew' }
)

export default Index
