import { combineReducers } from 'redux'

import { IHintState } from 'pages/hint/_shared/stores/models'
import { HintReducers } from 'pages/hint/_shared/stores/reducer'

import PagesReducer, { State as PagesState } from '../pages/reducer'

import { IHelp, HelpReducer } from './help/reducer'
import { SelfcheckReducer, IResult } from './selfcheck/reducer'
import { ISetup, SetupReducer } from './setup/reducer'
import { ISetupaction, SetupactionReducer } from './setupaction/reducer'
import { IUser } from './user/model'
import { UserReducer } from './user/reducer'

export type RootState = {
  user: IUser
  setup: ISetup
  setupaction: ISetupaction
  help: IHelp
  pages: PagesState
  hint: IHintState
  selfcheck: IResult
}

const reducers = combineReducers({
  user: UserReducer,
  setup: SetupReducer,
  setupaction: SetupactionReducer,
  help: HelpReducer,
  pages: PagesReducer,
  hint: HintReducers,
  selfcheck: SelfcheckReducer,
})

export default reducers
