export const queryGetGhostReports = /* GraphQL */ `
  query GetGhostReports($teamId: ID!) {
    getGhostReports(teamId: $teamId) {
      items {
        id
        teamId
        userId
        ghostId
        reserveId
        score
        createdAt
        scoreVotingStatus
      }
    }
  }
`

export const mutationCreateGhostReports = /* GraphQL */ `
  mutation CreateGhostReports($input: CreateGhostReportsInput!) {
    createGhostReports(input: $input) {
      id
      teamId
      userId
      ghostId
      score
      createdAt
    }
  }
`

export const mutationReserveGhostReports = /* GraphQL */ `
  mutation ReserveGhostReports($input: ReserveGhostReportsInput!) {
    reserveGhostReports(input: $input) {
      id
      teamId
      userId
      ghostId
      score
      createdAt
    }
  }
`

export const mutationVoteGhostReports = /* GraphQL */ `
  mutation VoteGhostReports($input: VoteGhostReportsInput!) {
    voteGhostReports(input: $input) {
      teamId
      historyWithGhost {
        id
        masterTeamId
        ghostId
        createdAt
      }
    }
  }
`

export const mutationForceGetVoteResult = /* GraphQL */ `
  mutation ForceGetVoteResult($teamId: String!) {
    forceGetVoteResult(teamId: $teamId) {
      teamId
      historyWithGhost {
        id
        masterTeamId
        ghostId
        createdAt
      }
    }
  }
`

export const subscriptionVoteGhostReports = /* GraphQL */ `
  subscription OnVoteGhostReports($teamId: ID!) {
    onVoteGhostReports(teamId: $teamId) {
      teamId
      historyWithGhost {
        id
        masterTeamId
        ghostId
        createdAt
      }
    }
  }
`

export const subscriptionForceGetVoteResult = /* GraphQL */ `
  subscription OnVoteGhostReports($teamId: ID!) {
    onForceGetVoteResult(teamId: $teamId) {
      teamId
      historyWithGhost {
        id
        masterTeamId
        ghostId
        createdAt
      }
    }
  }
`
