import React from 'react'

import * as constants from '../../../assets/constants'
import Button from '../../../components/Button'

interface Props {
  height: number
  leftSide?: true
}

const ApplyButton: React.FC<Props> = ({ height, leftSide }) => {
  return (
    <div className={leftSide ? '__buttonBoxLeft' : '__buttonBox'}>
      <div className="__button">
        <a href="https://new-one.co.jp/coach-foam/" target="_blank" rel="noopener noreferrer">
          <Button
            bgColor={constants.COLOR_SECOND}
            textColor={constants.COLOR_WHITE}
            height={height}
            fullWidth={true}
            submit={false}
            body={<div style={{ fontSize: 16 }}>無料で申し込みする</div>}
          ></Button>
        </a>
      </div>
    </div>
  )
}

export default ApplyButton
