import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { WidthControlWrapper } from 'components/WidthControlWrapper'

import { constants } from 'assets'

type StyleProps = {
  isGhost?: boolean
}

const noteTextList = [
  '本サービスは株式会社NEWONE（以下、当社）が提供するサービスです。本診断のご利用にあたっては、以下の記載内容に同意してご利用いただいたものとみなさせていただきます。',
  '本診断における診断結果は、回答結果によって異なる結果が表示されます。',
  '本サービスに掲載されている情報、内容に関しては、当社が著作権等の権利を所有しております。診断項目や診断結果などの内容を著作権等の権利が認めている範囲や一般的な利用が認められている範囲の外において、無断で2次利用・複製等をしてはならないものとします。',
  '当社では、本診断結果を集計し、個人が特定できない範囲まで加工された情報を当社における企業活動およびマーケティング活動、PR活動、営業活動等に使用する場合がございます。',
]

export const UsageNotes: React.FC<StyleProps> = ({ isGhost }) => {
  const classes = useStyles({ isGhost })

  return (
    <section className={classes.root}>
      <WidthControlWrapper>
        <div className={classes.box}>
          <h2 className={classes.title}>ご利用にあたって</h2>
          <div className={classes.bodyWrapper}>
            {noteTextList.map((noteText, i) => (
              <p key={i} className={classes.body}>
                ・{noteText}
              </p>
            ))}
          </div>
        </div>
      </WidthControlWrapper>
    </section>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({ isGhost }) => ({
      padding: isGhost ? '80px 0 0' : '120px 0 0',
      [theme.breakpoints.down('sm')]: {
        padding: isGhost ? '40px 0 0' : '120px 0 0',
      },
    }),
    box: ({ isGhost }) => ({
      padding: 24,
      width: '100%',
      height: 250,
      background: '#fff',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      overflowY: 'auto',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px',
        height: isGhost ? 400 : 250,
      },
    }),
    title: {
      margin: 0,
      fontSize: 24,
      fontWeight: 'bold',
    },
    bodyWrapper: {
      margin: '28px 0 0',
    },
    body: {
      margin: 0,
      fontSize: 14,
      lineHeight: '28px',
      letterSpacing: 1.2,
      whiteSpace: 'pre-wrap',
      textIndent: -16,
    },
  }),
  { name: 'UsageNotes' }
)
