import * as React from 'react'

import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'

import * as constants from '../../../../../assets/constants'

interface IProps {
  name: string
  value?: number | undefined
  options: { label: string; value: number }[]
  onChange: (value: number) => void
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes, options, onChange } = props

  if (options.length === 0) return null
  const lastPage = options.length === 5

  return (
    <div className={classes.container}>
      <div className="__slider">
        <Slider
          value={props.value || -1}
          valueLabelDisplay="off"
          step={1}
          marks={options}
          min={options[0].value}
          max={options[options.length - 1].value}
          classes={{
            thumb: props.value ? '__thumb' : '__thumb__hidden',
            track: '__track',
            rail: '__rail',
            mark: '__mark',
            markLabel: `__markLabel ${lastPage ? ' __LastPage' : ''}`,
          }}
          onChange={(e, newValue) => onChange(newValue as number)}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      '& .__thumb': {
        margin: '-20px 0 0 -20px',
        width: 40,
        height: 40,
        backgroundColor: constants.COLOR_BLUE_DARK2,
        border: `1px solid ${constants.COLOR_BLUE_DARK2}`,
        [theme.breakpoints.down('xs')]: {
          margin: '-15px 0 0 -15px',
          height: 30,
          width: 30,
        },
      },
      '& .__thumb__hidden': {
        display: 'none',
      },
      // 選択位置より手前のラインの色なので無効化。
      '& .__track': {
        opacity: 0,
      },
      '& .__rail': {
        height: 1,
        backgroundColor: constants.COLOR_BLUE_DARK2,
        opacity: 0.5,
      },
      '& .__mark': {
        margin: '-20px 0 0 -20px',
        width: 40,
        height: 40,
        backgroundColor: '#fff',
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        opacity: 1,
        [theme.breakpoints.down('xs')]: {
          margin: '-15px 0 0 -15px',
          height: 30,
          width: 30,
        },
      },
      '& .__markLabel': {
        marginTop: 16,
        color: constants.TEXY_GRAY3,
        fontSize: 12,
        lineHeight: '14px',
        whiteSpace: 'pre',
        textAlign: 'center',
        '&[data-index="0"]': {
          fontWeight: 'bold',
        },
        '&[data-index="6"]': {
          fontWeight: 'bold',
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: 8,
          fontSize: 9,
          lineHeight: '12px',
        },
      },
      '& .__LastPage': {
        '&[data-index="4"]': {
          fontWeight: 'bold',
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
