import { useState } from 'react'

import { IOptions, optionHintCategory } from 'assets/options'

// -----------------------------
// interface
// -----------------------------
export type IuseCategory = {
  getCategories: () => IOptions[]
  getCurrentCategory: () => string
  setCurrentCategory: (e: React.ChangeEvent<HTMLSelectElement>) => void
  setUpdateCategory: (value: string) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useCategory = (): IuseCategory => {
  const [curerentCategory, setCurrentCategories] = useState<string>('')

  /**
   * カテゴリのオプション一覧を取得する
   */
  const getCategories = (): IOptions[] => {
    return optionHintCategory
  }

  /**
   * 現在セレクト中のカテゴリーを返す
   */
  const getCurrentCategory = (): string => {
    return curerentCategory
  }

  /**
   * 現在セレクト中のカテゴリーをセットする
   */
  const setCurrentCategory = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrentCategories(e.target.value)
  }

  /**
   * 投稿編集時のカテゴリをセットする
   */
  const setUpdateCategory = (value: string): void => {
    setCurrentCategories(value)
  }

  return {
    getCategories,
    getCurrentCategory,
    setCurrentCategory,
    setUpdateCategory,
  }
}
