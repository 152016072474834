import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme, makeStyles, Divider } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { TeamMemberRole } from 'utils/generated'

import { HooksContext } from '../contexts'

import { HEADER_HEIGHT } from './ContentHeader'

import { constants } from 'assets'
import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

interface Props {
  teamId: string
  activeMenuCategory?: TeamMenuCategories
  activeMenu?: TeamMenus
}

export const MenuWidth = 260
const MenuItemHeight = 42

export enum TeamMenuCategories {
  Dashboard = 'dashboard',
  ManageTeams = 'manage-teams',
  ManageMembers = 'manage-members',
  Setting = 'setting',
}

export enum TeamMenus {
  About = 'about',
  Tools = 'tools',
  List = 'list',
  Members = 'members',
  MembersAdd = 'members-add',
  Corp = 'corp',
  Payment = 'payment',
  Plan = 'plan',
  Admin = 'admin',
}

const menuLabel = {
  dashboard: 'ダッシュボード',
  team: 'チーム管理',
  member: 'メンバー管理',
  corp: '企業情報管理',
}

export const Menus: React.FC<Props> = (props) => {
  const { teamId: _teamId, activeMenu, activeMenuCategory } = props

  const { route } = useHistory()
  const { currentTeam, currentTeamMember, isAdmin } = React.useContext(HooksContext)
  const hasTeamIdInProps = _teamId && !['start', 'list', 'new', 'new-by-csv'].includes(_teamId)
  const activeLinks = hasTeamIdInProps || !!currentTeam?.id
  const teamId = hasTeamIdInProps ? _teamId : currentTeam?.id

  const classes = useStyles({ headerHeight: HEADER_HEIGHT, isAdmin })
  const isMdUp = useCustomMediaQuery('up', 'md')

  const handleOnClickLink = (page: Pages) => {
    if (activeLinks) {
      route.push(page, { teamId })
    }
  }

  const getCategoryItemAuthRoles = (category: TeamMenuCategories): TeamMemberRole[] => {
    switch (category) {
      case TeamMenuCategories.Dashboard:
        return []
      case TeamMenuCategories.ManageTeams:
        return []
      case TeamMenuCategories.ManageMembers:
        return []
      case TeamMenuCategories.Setting:
        return [TeamMemberRole.Admin]
    }
  }

  const activeSuffix = (category: TeamMenuCategories | undefined) => (activeMenuCategory === category ? '_active' : '')

  const CategoryItem = ({ category, children }: { category: TeamMenuCategories; children?: React.ReactNode }) => {
    const auth = getCategoryItemAuthRoles(category)

    if (auth && auth.length > 0) {
      let authed = false
      for (const a of auth) {
        if (currentTeamMember?.role.includes(a)) {
          authed = true
        }
      }

      if (!authed) {
        return <></>
      }
    }

    const getIcon = () => {
      switch (category) {
        case TeamMenuCategories.ManageTeams:
          return 'manage_team'
        case TeamMenuCategories.ManageMembers:
          return 'manage_member'
        case TeamMenuCategories.Setting:
          return 'setting'
        default:
          return ''
      }
    }

    return (
      <>
        <Divider className={classes.divider} />
        <div className={`${classes.category} ${activeMenuCategory === category ? classes.categoryActive : ''}`}>
          <img src={`/assets/svg/teams/${getIcon()}${activeSuffix(category)}.svg`} alt={'i'} className={classes.icon} />
          {children}
        </div>
      </>
    )
  }

  const getLinkItemAuthRoles = (menu?: TeamMenus): TeamMemberRole[] => {
    switch (menu) {
      case TeamMenus.About:
        return []
      case TeamMenus.Tools:
        return [TeamMemberRole.Admin]
      case TeamMenus.Plan:
        return [TeamMemberRole.Admin]
      case TeamMenus.Members:
        return []
      case TeamMenus.MembersAdd:
        return [TeamMemberRole.Admin, TeamMemberRole.Leader]
      case TeamMenus.Corp:
        return [TeamMemberRole.Admin]
      case TeamMenus.Payment:
        return [TeamMemberRole.Admin]
      case TeamMenus.Admin:
        return [TeamMemberRole.Admin]
      default:
        return []
    }
  }

  const LinkItem = ({
    page,
    menu,
    children,
    category,
    isTeamList,
  }: {
    page: Pages
    menu?: TeamMenus
    children?: React.ReactNode
    category?: TeamMenuCategories
    isTeamList?: boolean
  }) => {
    const auth = getLinkItemAuthRoles(menu)

    if (auth && auth.length > 0) {
      let authed = false
      for (const a of auth) {
        if (currentTeamMember?.role.includes(a)) {
          authed = true
        }
      }

      if (!authed) {
        return <></>
      }
    }

    return isTeamList ? (
      <>
        <div
          className={`${classes.item} ${activeMenu === menu ? classes.itemActive : ''} ${
            !activeLinks ? classes.itemDisabled : ''
          } ${page === Pages.TeamsDashboard ? classes.itemDashboard : ''}`}
          onClick={() => route.push(`${Pages.TeamsList}?hidden=${true}`, { teamId: currentTeam?.id })}
        >
          {children}
          <ChevronRightIcon className={classes.chevronRightIcon} />
        </div>
      </>
    ) : (
      <>
        <div
          className={`${classes.item} ${activeMenu === menu ? classes.itemActive : ''} ${
            !activeLinks ? classes.itemDisabled : ''
          } ${page === Pages.TeamsDashboard ? classes.itemDashboard : ''}`}
          onClick={() => handleOnClickLink(page)}
        >
          {page === Pages.TeamsDashboard ? (
            <div className={classes.wrapperDashboard}>
              <img src={`/assets/svg/teams/dashboard${activeSuffix(category)}.svg`} alt="i" className={classes.icon} />
              {children}
            </div>
          ) : (
            children
          )}
          <ChevronRightIcon className={classes.chevronRightIcon} />
        </div>
      </>
    )
  }

  const BottomMenu = () => {
    const params = useLocation().pathname
    const array = params.split('/')
    const url = array[3] ?? ''
    const menuItems = [
      {
        label: menuLabel.dashboard,
        activeUrl: [''],
        path: 'dashboard',
        onClick: () => route.push(Pages.TeamsDashboard, { teamId }),
        category: TeamMenuCategories.Dashboard,
      },
      {
        label: menuLabel.team,
        activeUrl: ['about'],
        path: 'teamController',
        onClick: () => route.push(Pages.TeamsAbout, { teamId }),
        category: TeamMenuCategories.ManageTeams,
      },
      {
        label: menuLabel.member,
        activeUrl: ['members', 'members-add'],
        path: 'memberAdmin',
        onClick: () => route.push(Pages.TeamsMembers, { teamId }),
        category: TeamMenuCategories.ManageMembers,
      },
      {
        label: menuLabel.corp,
        activeUrl: ['corp', 'admin', 'payment'],
        path: 'admin',
        onClick: () => route.push(Pages.TeamsCorp, { teamId }),
        category: TeamMenuCategories.Setting,
      },
    ]

    const BottomMenuItem = ({
      label,
      activeUrl,
      path,
      onClick,
      category,
    }: {
      label: string
      activeUrl: string[]
      path: string
      onClick: () => void
      category: TeamMenuCategories
    }) => {
      const auth = getCategoryItemAuthRoles(category)

      if (auth && auth.length > 0) {
        let authed = false
        for (const a of auth) {
          if (currentTeamMember?.role.includes(a)) {
            authed = true
          }
        }

        if (!authed) {
          return <></>
        }
      }

      return (
        <div className={classes.bottomMenu} onClick={onClick}>
          <div className={classes.bottomIcon}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/${path}${activeUrl.indexOf(url) >= 0 ? 'Green' : ''}.svg`}
              alt={path}
            />
          </div>
          <div className={activeUrl.indexOf(url) >= 0 ? classes.textGreen : classes.text}>{label}</div>
        </div>
      )
    }

    return (
      <div className={classes.sidebarContainer}>
        {menuItems.map((item) => (
          <BottomMenuItem key={`footer-menu-${item.label}`} {...item} />
        ))}
      </div>
    )
  }

  return (
    <>
      {isMdUp ? (
        <div className={classes.root}>
          <LinkItem page={Pages.TeamsDashboard}>{menuLabel.dashboard}</LinkItem>

          <CategoryItem category={TeamMenuCategories.ManageTeams}>{menuLabel.team}</CategoryItem>
          <LinkItem page={Pages.TeamsAbout} menu={TeamMenus.About}>
            チーム情報
          </LinkItem>
          <LinkItem page={Pages.TeamsAbout} menu={TeamMenus.List} isTeamList>
            チームリスト
          </LinkItem>
          {/* <LinkItem page={Pages.TeamsTools} menu={TeamMenus.Tools}>
          利用ツール
          </LinkItem>
          <LinkItem page={Pages.TeamsPlan} menu={TeamMenus.Plan}>
          プラン
        </LinkItem> */}

          <CategoryItem category={TeamMenuCategories.ManageMembers}>{menuLabel.member}</CategoryItem>
          <LinkItem page={Pages.TeamsMembers} menu={TeamMenus.Members}>
            メンバーリスト
          </LinkItem>
          <LinkItem page={Pages.TeamsMembersAdd} menu={TeamMenus.MembersAdd}>
            メンバー追加
          </LinkItem>

          <CategoryItem category={TeamMenuCategories.Setting}>{menuLabel.corp}</CategoryItem>
          <LinkItem page={Pages.TeamsCorp} menu={TeamMenus.Corp}>
            企業情報
          </LinkItem>
          <LinkItem page={Pages.TeamsPayment} menu={TeamMenus.Payment}>
            決済情報
          </LinkItem>
          <LinkItem page={Pages.TeamsAdmin} menu={TeamMenus.Admin}>
            管理者情報
          </LinkItem>
        </div>
      ) : (
        <BottomMenu />
      )}
    </>
  )
}

interface StyleProps {
  headerHeight: number
  isAdmin?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minWidth: MenuWidth,
      maxWidth: MenuWidth,
      backgroundColor: constants.COLOR_WHITE,
      minHeight: `calc(100% - 150px)`,
      maxHeight: `calc(100% - 150px)`,
      overflow: 'auto',
      paddingBottom: 32,
    },

    title: (props: StyleProps) => ({
      fontSize: 14,
      height: 72,
      color: props.isAdmin ? constants.COLOR_WHITE : constants.COLOR_TEAMBUILDING_TEXT,
      display: 'flex',
      alignItems: 'center',
      padding: '0 40px',
      backgroundColor: props.isAdmin ? constants.COLOR_TEAMBUILDING_PRIMARY : constants.COLOR_WHITE,
      cursor: 'pointer',
    }),

    category: {
      fontSize: 14,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      padding: '26px 24px 8px',
    },

    categoryActive: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },

    item: {
      fontSize: 14,
      cursor: 'pointer',
      height: MenuItemHeight,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px 0 64px',
      justifyContent: 'space-between',

      '&:hover': {
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      },
    },

    itemActive: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },

    itemDisabled: {
      opacity: '0.3',
      cursor: 'not-allowed',
      '&:hover': {
        color: '#fff',
      },
    },

    itemDashboard: {
      marginTop: 10,
      padding: '0 16px 0 24px',
      fontWeight: 'bold',
    },

    wrapperDashboard: {
      display: 'flex',
      alignItems: 'center',
    },

    icon: {
      marginRight: 16,
      width: 24,
    },

    chevronRightIcon: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      height: 20,
    },

    companyIcon: {
      width: 40,
      minWidth: 40,
      height: 40,
      minHeight: 40,
      borderRadius: '50%',
      marginRight: 16,
    },

    companyTitle: {
      fontSize: 16,
      fontWeight: 500,
      whiteSpace: 'nowrap',
      width: 300,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    divider: {
      marginTop: 10,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },

    sidebarContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      background: 'white',
      boxShadow: '0 -5px 10px -9px #000000',
      padding: 12,
      boxSizing: 'border-box',
      position: 'fixed',
      bottom: 0,
    },
    bottomMenu: {
      cursor: 'pointer',
    },
    bottomIcon: {
      display: 'flex',
      justifyContent: 'center',
    },
    text: {
      color: constants.TEXT_BLACK,
      margin: '0 auto',
      fontWeight: 'bold',
      paddingTop: 5,
      fontSize: 10,
    },
    textGreen: {
      color: constants.COLOR_MAIN_NEW,
      margin: '0 auto',
      fontWeight: 'bold',
      paddingTop: 5,
      fontSize: 10,
    },
  }),
  { name: 'Menus' }
)
