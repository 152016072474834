import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { IKarte } from '../../interface'

const MutationKarteMemberStore = `mutation KarteMemberStore($input: KarteMemberInput!) {
  karteMemberStore(input: $input) {
    id
    leaderId
    data
    createdAt
    updatedAt
  }
}`

interface IKarteMemberStoreInput {
  id: string
  leaderId: string
  data: string
}

export const karteMemberStore = async (input: IKarteMemberStoreInput) => {
  const response: any = await API.graphql({
    query: MutationKarteMemberStore,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })

  return response.data.karteMemberStore as IKarte
}
