import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { CardHeader, LoadingCircular } from '.'

import { constants } from 'assets'

interface Props {
  children?: React.ReactNode
  headerTitle?: string
  headerBottomNode?: React.ReactNode
  headerRightNode?: React.ReactNode
  ownStyles?: React.CSSProperties
  headerStyles?: React.CSSProperties
  titleStyles?: React.CSSProperties
  loading?: boolean
}
interface StyleProps {
  style?: {
    padding?: string | number
    flexDirection?: 'column'
    justifyContent?: 'center' | 'space-between'
    alignItems?: 'center' | 'flex-start'
    marginBottom?: number
  }
  headerMb?: number
  titleContainerPb?: number
}

export const Card: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.cardRoot} style={{ ...props.ownStyles }}>
      {props.headerTitle ? (
        <CardHeader
          title={props.headerTitle}
          rightNode={props.headerRightNode}
          style={{
            padding: props.style?.padding,
            flexDirection: props.style?.flexDirection,
            justifyContent: props.style?.justifyContent,
            alignItems: props.style?.alignItems,
            marginBottom: props.style?.marginBottom,
          }}
          headerMb={props.headerMb}
          titleContainerPb={props?.titleContainerPb}
          ownStyles={props.headerStyles}
          titleStyles={props.titleStyles}
        />
      ) : (
        <></>
      )}
      {props.headerBottomNode ? <div className={classes.buttonContainer}>{props.headerBottomNode}</div> : <></>}
      {!props.loading ? props.children : <LoadingCircular loading={props.loading} />}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    backgroundColor: constants.COLOR_WHITE,
    padding: (props: StyleProps) => props.style?.padding ?? 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 16,
  },
}))
