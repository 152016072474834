import * as React from 'react'

import { queryGetTeams } from 'pages/teams/hooks/graphql'
import { TeamConnection } from 'utils/generated'

export const useIsTeam = () => {
  const [teams, setTeams] = React.useState<TeamConnection | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const refresh = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const getTeams = await queryGetTeams({ limit: 1 })
      setTeams(getTeams)
    } catch (e) {
      setError(e as Error)
    }

    setLoading(false)
  }
  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { teams, loading, error } as const
}
