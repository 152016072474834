import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { Karte } from 'utils/generated'

const QueryGetKarte = `query GetKarte($id: ID!, $leaderId: ID) {
  getKarte(id: $id, leaderId: $leaderId) {
    id
    leaderId
    data
    name
    leaderNumber
    leaderData
    createdAt
    updatedAt
  }
}`

export const queryGetKarte = async (variables: { id: string; leaderId: string }) => {
  const response: any = await API.graphql({
    query: QueryGetKarte,
    variables,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })

  return response.data?.getKarte as Karte
}
