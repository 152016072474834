import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '12px',
    color: '#A8A8A8',
    margin: 0,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
}

// -----------------------------
// Component
// -----------------------------
export const Date = (props: IProps) => {
  const classes = useStyles()
  return <span className={classes.root}>{props.text}</span>
}
