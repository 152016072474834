import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  buttonBodyColor:
    | 'white'
    | 'green'
    | 'yellow'
    | 'gray'
    | 'grayLine'
    | 'black'
    | 'blue'
    | 'red'
    | 'outlineRed'
    | 'transparent'
  bodyText: React.ReactNode
  onClick?: () => void
  ownStyles?: React.CSSProperties
  disabled?: boolean
  noFullWidth?: boolean
  isResizeBasic?: boolean
  type?: 'button' | 'submit'
}

export const ButtonForGhost: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <>
      <Button
        type={props.type ?? 'button'}
        disabled={props.disabled}
        variant="contained"
        className={classNames([
          classes.button,
          props.buttonBodyColor,
          props.disabled ? 'disabled' : '',
          props.isResizeBasic && classes.resizeBasic,
        ])}
        onClick={props.onClick}
        fullWidth={props.noFullWidth ? false : true}
        disableRipple={props.buttonBodyColor === 'transparent' ? true : false}
        style={{
          ...props.ownStyles,
        }}
      >
        {props.bodyText}
      </Button>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'block',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#FFFFFF00',
    color: '#FFFFFF00',
    maxWidth: 400,
    height: 48,
    [theme.breakpoints.up('md')]: {
      maxWidth: 250,
    },
    borderRadius: 30,
    fontSize: 14,
    fontWeight: 'bold',
    margin: '0 auto',
    '&.white': {
      backgroundColor: constGhost.COLOR_WHITE,
      color: constGhost.COLOR_MAIN_NEW,
      border: `2px solid ${constGhost.COLOR_MAIN_NEW}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constGhost.COLOR_MAIN_NEW,
          color: constGhost.COLOR_WHITE,
          boxShadow: 'none',
        },
      },
    },
    '&.green': {
      backgroundColor: constGhost.COLOR_MAIN_NEW,
      color: constGhost.COLOR_WHITE,
      border: `2px solid ${constGhost.COLOR_MAIN_NEW}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constGhost.COLOR_WHITE,
          color: constGhost.COLOR_MAIN_NEW,
          boxShadow: 'none',
        },
      },
    },
    '&.gray': {
      backgroundColor: constGhost.COLOR_LIGHTGRAY3,
      color: constGhost.COLOR_GRAYTEXT,
      border: `2px solid ${constGhost.COLOR_LIGHTGRAY3}`,
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
          cursor: 'not-allowed',
        },
      },
    },
    '&.grayLine': {
      backgroundColor: constGhost.COLOR_WHITE,
      color: constGhost.COLOR_GRAYTEXT,
      border: `2px solid ${constGhost.COLOR_GRAYTEXT}`,
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
          cursor: 'not-allowed',
        },
      },
    },
    '&.yellow': {
      backgroundColor: constGhost.COLOR_YELLOW,
      color: constGhost.COLOR_WHITE,
      boxShadow: '0px 2px 0px 0px #E5BC00',
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: '0px 2px 2px 0 #0000006E',
        },
      },
    },
    '&.black': {
      backgroundColor: constGhost.COLOR_BLACK,
      color: constGhost.COLOR_WHITE,
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: '0px 2px 2px 0 #0000006E',
        },
      },
    },
    '&.blue': {
      backgroundColor: constGhost.COLOR_MAINBLUE,
      color: constGhost.COLOR_WHITE,
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: '0px 2px 2px 0 #0000006E',
        },
      },
    },
    '&.red': {
      backgroundColor: constGhost.COLOR_WARNING_RED,
      color: constGhost.COLOR_WHITE,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constGhost.COLOR_WHITE,
          color: constGhost.COLOR_WARNING_RED,
          border: `2px solid ${constGhost.COLOR_WARNING_RED}`,
          boxShadow: 'none',
        },
      },
    },
    '&.outlineRed': {
      backgroundColor: constGhost.COLOR_WHITE,
      color: constGhost.COLOR_RED,
      border: `2px solid ${constGhost.COLOR_WARNING_RED}`,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constGhost.COLOR_WARNING_RED,
          color: constGhost.COLOR_WHITE,
          boxShadow: 'none',
        },
      },
    },
    '&.transparent': {
      backgroundColor: '#FFFFFF00',
      color: constGhost.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
  },
  resizeBasic: {
    margin: 0,
    width: 240,
    height: 54,
    [theme.breakpoints.down('xs')]: {
      height: 48,
    },
  },
}))
