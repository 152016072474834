import { createStore, compose, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { ActionType } from 'typesafe-actions'

import actions from './stores/actions'
import epics from './stores/epics'
import reducers, { RootState } from './stores/reducers'


interface IExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
}
declare let window: IExtendedWindow

export type RootStateType = RootState
export type ActionsType = ActionType<typeof actions>

const epicMiddleware = createEpicMiddleware<ActionsType, ActionsType, RootState>()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState?: RootStateType) {
  const middlewares = [epicMiddleware]
  const enhancer = composeEnhancers(applyMiddleware(...middlewares))
  return createStore(reducers, initialState, enhancer)
}

const store = configureStore()
epicMiddleware.run(epics)

export { store, actions }
