import { NotifycationType } from 'utils/generated'

export const setNotificationMsg = (type: NotifycationType, teamName?: String, username?: string) => {
  switch (type) {
    case NotifycationType.Comment:
      return `${username}さんが投稿にコメントしました`
    case NotifycationType.Secondcomment:
      return `${username}さんがコメントに返信しました`
    case NotifycationType.Like:
      return `${username}さんが投稿にいいねしました`
    case NotifycationType.Commentlike:
      return `${username}さんがコメントにいいねしました`
    case NotifycationType.Secondcommentlike:
      return `${username}さんがコメントの返信にいいねしました`
    case NotifycationType.Action:
      return 'アクションが追加されました'
    case NotifycationType.Leader:
      return '運営事務局が投稿しました'
    case NotifycationType.Supporter:
      return 'サポーターが投稿しました'
    case NotifycationType.Member:
      return `${teamName}チームにメンバーが追加されました`
    case NotifycationType.Mention:
      return `${username}さんにメンションされました`
    default:
      return ''
  }
}

export const setNotificationUrl = (type: NotifycationType, teamId: string, userId: string, actionId: string) => {
  switch (type) {
    case NotifycationType.Comment:
    case NotifycationType.Secondcomment:
      return `/onboarding/${teamId}/timeline/${actionId}`
    case NotifycationType.Like:
    case NotifycationType.Commentlike:
    case NotifycationType.Secondcommentlike:
      return `/onboarding/${teamId}/timeline/${actionId}`
    case NotifycationType.Action:
      return `/onboarding/${teamId}/actions/${userId}/${actionId}`
    case NotifycationType.Leader:
      return `/onboarding/${teamId}/timeline/${actionId}`
    case NotifycationType.Supporter:
      return `/onboarding/${teamId}/timeline/${actionId}`
    case NotifycationType.Member:
      return `/onboarding/${teamId}/teammember/${actionId}`
    case NotifycationType.Mention:
      return `/onboarding/${teamId}/timeline/${actionId}`
    default:
      return `/onboarding/${teamId}/timeline`
  }
}
