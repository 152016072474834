import * as React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'

import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { SectionWrapper } from 'pages/landing/components/SectionWrapper'
import { TopAbout } from 'pages/landing/components/TopAbout'
import { TopDeveloper } from 'pages/landing/components/TopDeveloper'
import { TopFAQ } from 'pages/landing/components/TopFAQ'
import { TopHero } from 'pages/landing/components/TopHero'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'
import { TopRecommend } from 'pages/landing/components/TopRecommend'
import { TopSupport } from 'pages/landing/components/TopSupport'
import { TopTools } from 'pages/landing/components/TopTools'
import { TopUsefulDocs } from 'pages/landing/components/TopUsefulDocs'
import { TopUserVoice } from 'pages/landing/components/TopUserVoice'
import { connector, ContainerProps } from 'pages/landing/index.container'
import { useNotificationLatest } from 'pages/notification/hooks/useNotification'

import { NotificationLink } from './components/NotificationLink'

import { constants } from 'assets'

const Arrow: React.FC<{ color: '#fff' | '#333' }> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6.121" height="9.414" viewBox="0 0 6.121 9.414">
    <path d="M0,0,4,4,0,8" transform="translate(0.707 0.707)" fill="none" stroke={color} strokeWidth="2" />
  </svg>
)

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const notificationItem = useNotificationLatest()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      {/* <Onboarding100DaysNav />  Maybe it's used for next year so that it's NOT deleted BUT commentOut. */}
      <section>
        <TopHero />
      </section>
      <SectionWrapper isBlueBackground>
        <section>
          <TopAbout />
        </section>
      </SectionWrapper>
      <SectionWrapper>
        <section>
          <TopTools />
        </section>
      </SectionWrapper>
      <SectionWrapper isBlueBackground>
        <section>
          <TopSupport />
        </section>
      </SectionWrapper>
      <section>
        <TopUserVoice />
      </section>
      <SectionWrapper>
        <section>
          <TopUsefulDocs />
        </section>
      </SectionWrapper>
      <SectionWrapper isBlueBackground>
        <section className={classes.topDeveloper}>
          <TopDeveloper />
        </section>
      </SectionWrapper>
      <SectionWrapper isBlueBackground>
        <section className={classes.topRecommend}>
          <TopRecommend />
        </section>
      </SectionWrapper>
      <section className={classes.topFAQ}>
        <TopFAQ />
      </section>

      {props.user.fetched && (
        <div className={classNames([classes.scrollButton, props.user.id && classes.scrollButtonLogin])}>
          {!props.user.id && (
            <Link className="contact" to={constants.PAGE_FREETRIAL}>
              無料でお試し
              <div className="arrow">
                <Arrow color="#fff" />
              </div>
            </Link>
          )}
          <Link className="document" to={constants.PAGE_DOCUMENTS}>
            3分でわかる組織開発{'\n'}ダウンロード資料
            <div className="arrow">
              <Arrow color="#333" />
            </div>
          </Link>
        </div>
      )}

      <NotificationLink {...notificationItem} />
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: '#fff',
      width: '100%',
      margin: 0,
      overflow: 'hidden',
      fontFamily: constTop.FONT_FAMILY_TOP_TEXT,
      scrollBehavior: 'smooth',
    },
    topAbout: {
      marginTop: 'calc(100vw * -0.69 + 88px + 16px + 10px)',
      [theme.breakpoints.up(constTop.breakPointsPC)]: {
        marginTop: 100,
      },
    },
    topDeveloper: {
      marginTop: 100,
    },
    topRecommend: {
      marginTop: 54,
    },
    topFAQ: {
      marginTop: 100,
    },

    scrollButton: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      right: 0,
      backgroundColor: constants.COLOR_WHITE,
      fontSize: 16,
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
      zIndex: 1000,
      animation: `$scrollButtonFadeIn 300ms ${theme.transitions.easing.easeOut}`,
      '@global': {
        '@keyframes scrollButtonFadeIn': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& a': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: 333,
        height: 70,
        [theme.breakpoints.down('sm')]: {
          width: 280,
        },
        [theme.breakpoints.down('xs')]: {
          flex: 1,
        },
      },
      '& .contact': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: constants.COLOR_WHITE,
        '@media (hover: hover)': {
          '&:hover': {
            opacity: '80%',
          },
        },
      },
      '& .document': {
        backgroundColor: constants.COLOR_YELLOW2,
        color: constants.TEXT_GRAY_DARK,
        '@media (hover: hover)': {
          '&:hover': {
            opacity: '80%',
          },
        },
      },
      '& .arrow': {
        position: 'absolute',
        top: '50%',
        right: 16,
        transform: 'translateY(-50%)',
      },
    },
    scrollButtonLogin: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
