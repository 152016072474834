import { I18n } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
I18n.setLanguage('ja')

const resendSignUp = async (email: string) => {
  await Auth.resendSignUp(email)

  return {}
}

export default resendSignUp
