import * as React from 'react'

import { createStyles, StyleRules, withStyles, WithStyles } from '@material-ui/core/styles'

import { mergeClasses } from 'pages/onboarding/utils/styles'

interface IOwnProps {
  containerClassName?: string
  leftItem?: JSX.Element
  centerItem?: JSX.Element
  rightItem?: JSX.Element
}

type Props = IOwnProps & WithStyles<typeof useStyles>

/**
 * Header Row that apperas on top of each pages' content.
 * pass JSX Element each for left, center , and right.
 * Typically leftItem is Go-back button, center for the title of that page, and right for buttons, filters, etc.
 * Center Item is absolutely centered, and left and right are flex-between-ed.
 */
const Index: React.FC<Props> = ({ classes, containerClassName, leftItem, rightItem, centerItem }) => {
  return (
    <div className={mergeClasses([classes.container, containerClassName])}>
      <div className={classes.centerItem}>{centerItem}</div>
      <div>{leftItem}</div>
      <div>{rightItem}</div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 18,
      paddingRight: 16,
      paddingTop: 10,
      paddingBottom: 10,
      minHeight: 60,
    },

    centerItem: {
      position: 'absolute',
      bottom: 0,
      top: 0,

      // set some margin to avoid covering over side items.
      left: 50,
      right: 50,

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',

      '& > span': {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  })

export default withStyles(useStyles)(Index)
