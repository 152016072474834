import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

export const AlertContainer: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.iconContainer}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/alert.svg'} alt="" />
      </div>
      {children}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    height: 48,
    textAlign: 'center',
    marginBottom: 16,
  },
}))
