import { OnboardingTeamMemberRole } from 'utils/generated'

export const roleNames: { [k: string]: string } = {
  [OnboardingTeamMemberRole.Admin]: '運営事務局',
  [OnboardingTeamMemberRole.Supporter]: 'サポーター',
  [OnboardingTeamMemberRole.Member]: 'メンバー',
}

export const teamMemberRoleLabel = (role?: OnboardingTeamMemberRole | string) => {
  switch (role) {
    case OnboardingTeamMemberRole.Admin:
      return roleNames[OnboardingTeamMemberRole.Admin]
    case OnboardingTeamMemberRole.Supporter:
      return roleNames[OnboardingTeamMemberRole.Supporter]
    case OnboardingTeamMemberRole.Member:
      return roleNames[OnboardingTeamMemberRole.Member]
    default:
      return ''
  }
}
