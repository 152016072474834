import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'

import action from './action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onFetch: (leaderNumber: string) => dispatch(action.fetch.started({ leaderNumber })),
  onResetError: () => dispatch(action.resetError({})),
})

const mapStateToProps = (state: RootStateType) => {
  const { isLoading, error, name } = state.pages.karteLeaderResults
  const data = state.pages.karteLeaderResults.data ? JSON.parse(state.pages.karteLeaderResults.data) : null
  const leaderData = state.pages.karteLeaderResults.leaderData
    ? JSON.parse(state.pages.karteLeaderResults.leaderData)
    : null
  return {
    user: { ...state.user },
    name,
    data,
    leaderData,
    isLoading,
    error,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
