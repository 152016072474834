import React from 'react'

import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import RefreshIcon from '@material-ui/icons/Refresh'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ButtonForGhost, Font, UsefulModal } from 'pages/ghost/components/atoms'

const MOBILE_SCREEN_WIDTH = 375

export const ReloadButton: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isXsUp = useMediaQuery(theme.breakpoints.up(MOBILE_SCREEN_WIDTH))

  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const handleModalOpen = () => {
    setIsOpenModal(true)
  }
  const handleModalClose = () => {
    setIsOpenModal(false)
  }
  const handlePageReload = () => {
    handleModalClose()
    window.location.reload()
  }

  return (
    <>
      <div onClick={handleModalOpen} className={classes.root}>
        <RefreshIcon className={classes.reloadIcon} />
        {isXsUp && (
          <Font fontSize={10} bold color={constGhost.COLOR_MAIN_NEW} ownStyles={{ cursor: 'pointer' }}>
            再読み込み
          </Font>
        )}
      </div>

      <UsefulModal open={isOpenModal} handleModalChange={handleModalClose} ownStyles={{ padding: 24 }}>
        <img className={classes.img} src={process.env.PUBLIC_URL + '/assets/svg/exclamation.svg'} alt="注意" />
        <div className={classes.modalHeader}>
          ブラウザをリロードし、
          <br />
          データを再読み込みしますか？
        </div>
        <div className={classes.modalButtonContainer}>
          <Button
            onClick={handlePageReload}
            startIcon={<RefreshIcon />}
            disableElevation
            className={classes.reloadButton}
          >
            再読み込みする
          </Button>
          <ButtonForGhost buttonBodyColor="white" bodyText="キャンセル" onClick={handleModalClose} isResizeBasic />
        </div>
      </UsefulModal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
  },

  reloadIcon: {
    width: 18,
    height: 18,
    color: constGhost.COLOR_MAIN_NEW,
    fontWeight: 'bold',
    [theme.breakpoints.down(MOBILE_SCREEN_WIDTH)]: {
      width: 24,
      height: 24,
    },
  },
  img: {
    width: 80,
    height: 80,
    verticalAlign: 'top',
  },
  modalHeader: {
    margin: '24px 0',
    color: constGhost.COLOR_MAIN_NEW,
    fontSize: 14,
    fontWeight: 'bold',
  },
  modalButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 16,
  },

  modalButtonText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 8,
  },
  reloadIconWithText: {
    width: 18,
    height: 18,
  },

  reloadButton: {
    width: 240,
    height: 54,
    border: `2px solid ${constGhost.COLOR_MAIN_NEW}`,
    borderRadius: 30,
    boxShadow: 'none',
    backgroundColor: constGhost.COLOR_MAIN_NEW,
    color: constGhost.COLOR_WHITE,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      height: 48,
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: constGhost.COLOR_WHITE,
        color: constGhost.COLOR_MAIN_NEW,
      },
    },
  },
}))
