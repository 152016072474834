import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type CircularProgressProps = {
  numerator: number
  denominator: number
} & StyleProps

type StyleProps = {
  mainColor?: string
  backColor?: string
  cutOutColor?: string
  size?: number
  percentageRadius?: number
}

const calcRatio = (numerator: number, denominator: number) => {
  return Math.round((numerator / denominator) * 100)
}

export const CircleGraph: React.FC<CircularProgressProps> = ({ numerator, denominator, ...props }) => {
  const classes = useStyles({ ratio: calcRatio(numerator, denominator), ...props })

  return (
    <div className={classes.circle}>
      <div className={classes.graphInfo}>
        <div className={classes.ratio}>
          <span className="numerator">{numerator}</span>/<span className="denominator">{denominator}</span>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps & { ratio: number }>(
  (theme: Theme) => ({
    circle: ({
      ratio,
      mainColor = constants.COLOR_BLUE_DARK2,
      backColor = '#E6F9FB',
      cutOutColor = constants.COLOR_BLUE_LIGHT3,
      size = 80,
      percentageRadius = 52,
    }) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: size,
      width: size,
      height: size,
      // radial-gradient の % は、中心からの四隅の角までの距離を基準に計算し、円を描画する。
      backgroundImage: `radial-gradient(${cutOutColor} ${percentageRadius}%, transparent ${
        percentageRadius + 1
      }%), conic-gradient(${mainColor} 0% ${ratio}%, ${backColor} ${ratio}% 100%)`,
      borderRadius: '50%',
    }),
    graphInfo: {
      fontSize: 10,
      textAlign: 'center',
    },
    ratio: {
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
      '& .numerator': {
        fontSize: 24,
      },
      '& .denominator': {
        fontSize: 16,
      },
    },
  }),
  { name: 'CircleGraph' }
)
