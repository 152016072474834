import * as React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from 'assets/constants'

interface Props {
  label: string
  ownStyles?: React.CSSProperties
  submit?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

export const LoginButton: React.FC<Props> = ({ submit, label, ownStyles, onClick }) => {
  const classes = useStyles({})
  return (
    <>
      <Button
        variant="contained"
        type={submit ? 'submit' : 'button'}
        style={{
          ...ownStyles,
        }}
        className={classes.whiteButton}
        onClick={onClick}
      >
        <div className={classes.buttonBody}>{label}</div>
      </Button>
    </>
  )
}

const useStyles = makeStyles({
  buttonBody: {
    height: '100%',
  },
  whiteButton: {
    boxShadow: 'none',
    width: 200,
    height: 50,
    borderRadius: 25,
    fontSize: 14,
    fontWeight: 'bold',
    border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
    background: constTop.COLOR_WHITE,
    color: constTop.COLOR_BUTTONORANGE,
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${constTop.COLOR_WHITE}`,
      background: constTop.COLOR_BUTTONORANGE,
      color: constTop.COLOR_WHITE,
      boxShadow: 'none',
      '&.hoverAnimation': {
        '&:hover': {
          background: constants.COLOR_WHITE,
          animation: '$signInButtonAnime 300ms cubic-bezier(0,0.5,0.5,1) 0s 1 normal forwards running',
          '@global': {
            '@keyframes signInButtonAnime': {
              '0%': {
                border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
                background: constTop.COLOR_WHITE,
                color: constTop.COLOR_BUTTONORANGE,
              },
              '100%': {
                border: `2px solid ${constTop.COLOR_WHITE}`,
                background: constTop.COLOR_BUTTONORANGE,
                color: constTop.COLOR_WHITE,
              },
            },
          },
        },
      },
    },
  },
})
