import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

type Props = {
  current: number
  amount: number
}

export const PeopleCounter: React.FC<Props> = ({ current, amount }) => {
  const currentMember = current <= amount ? current : amount
  const classes = useStyles({ current: currentMember, amount: amount })
  if (current < 0 || amount < 0) return null

  return (
    <>
      <div className={classes.progressContainer}>
        <div className={classes.counterBar} />
      </div>
      <div className={classes.amountText}>
        {currentMember}/{amount}人完了
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    Root: (props: Props) => ({}),
    progressContainer: {
      backgroundColor: constants.COLOR_WHITE,
      width: 350,
      height: 8,
      borderRadius: 8,
      overflow: 'hidden',
      marginBottom: 18,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 32px)',
      },
    },
    counterBar: ({ current, amount }) => ({
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      width: `${(current / amount) * 100}%`,
      height: '100%',
    }),
    amountText: {
      fontFamily: 'Hiragino Kaku Gothic Pro',
      fontSize: 14,
      lineHeight: '24px',
      color: constants.COLOR_ONBOARDING_MAIN,
      textAlign: 'center',
      marginBottom: 25,
    },
  }),
  { name: 'PeopleCounter' }
)
