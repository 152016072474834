export const ARTICLE_COMMENT = {
  maxLength: { value: 1000, message: '文字数が1000文字を越えています。' },
}

export const MODAL_FAQ = {
  TITLE: {
    required: { value: true, message: 'タイトルの入力は必須です' },
    maxLength: { value: 50, message: '文字数が50文字を越えています。' },
  },
  BODY: {
    maxLength: { value: 5000, message: '文字数が5000文字を越えています。' },
  },
}

export const MODAL_TIPS = {
  TITLE: {
    required: { value: true, message: 'タイトルの入力は必須です' },
    maxLength: { value: 50, message: '文字数が50文字を越えています。' },
  },
  BODY: {
    required: { value: true, message: '本文の入力は必須です' },
    maxLength: { value: 5000, message: '文字数が5000文字を越えています。' },
  },
}

export const MODAL_TIPS_ACTIONS = {
  CREATE: { MESSAGE: '記事の投稿に失敗しました。' },
  UPDATE: { MESSAGE: '記事の下書きに失敗しました。' },
  DELETE: { MESSAGE: '記事の削除に失敗しました。' },
}

export const MODAL_CONSULTATION_ACTIONS = {
  CREATE: { MESSAGE: 'お悩み相談の投稿に失敗しました。' },
}

export const COMMENT_ACTIONS = {
  CREATE: { MESSAGE: '投稿に失敗しました。' },
}
