import { useEffect, useState } from 'react'

import { queryGetTeams } from 'pages/teams/hooks/graphql'

/**
 * retrieve master team id for logged-in user.
 */
export const useDefaultMasterTeam = () => {
  const [masterTeamId, setMasterTeamId] = useState<string>()

  useEffect(() => {
    ;(async () => {
      if (masterTeamId) return
      const res = await queryGetTeams({ limit: 1 })
      res && res.items.length && setMasterTeamId(res.items[0].id)
    })()
  }, [setMasterTeamId, masterTeamId])

  return { masterTeamId }
}

export type DefaultMasterTeam = ReturnType<typeof useDefaultMasterTeam>
