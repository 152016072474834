import React from 'react'

import * as constants from '../../../assets/constants'
import Button from '../../../components/Button'

interface Props {
  height: number
  leftSide?: true
}

const ApplyButton: React.FC<Props> = ({ height, leftSide }) => {
  return (
    <div className={leftSide ? '__buttonBoxLeft' : '__buttonBox'}>
      <div className="__button">
        <a href="https://www.jooto.com/" target="_blank" rel="noopener noreferrer">
          <Button
            bgColor={constants.COLOR_ORANGE}
            textColor={constants.COLOR_WHITE}
            height={height}
            fullWidth={true}
            submit={false}
            body={<div style={{ fontSize: 16, textTransform: 'none' }}>Jootoサイトはこちら</div>}
          ></Button>
        </a>
      </div>
    </div>
  )
}

export default ApplyButton
