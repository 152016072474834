import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { getRoundedValue } from '../../lib'
import { ContentButton } from '../generic/ContentButton'
import { PointData } from '../hooks/useReviewPoints'

import { Step2Chart } from './Step2Chart'

type Props = {
  pointData: PointData | undefined
  setModalOpen: (open: boolean) => void
}
export const Step2ConfirmModalContent: React.FC<Props> = ({ pointData, setModalOpen }) => {
  const classes = useStyles()
  const closeDetailModal = () => {
    setModalOpen(false)
  }
  return (
    <div className={classes.root}>
      <div className={classes.modalSection}>
        <div>
          <div style={{ marginBottom: 8, fontWeight: 'bold' }}>{pointData?.label}</div>

          <Step2Chart type="step2Confirm" data={pointData?.points ?? []} />
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div>
              <span>平均点数 </span>
              <span className={classes.point}>
                {pointData !== undefined ? getRoundedValue(pointData.average) : '-'}
              </span>
              <span className={classes.label}>点</span>
            </div>
            <div>
              <span>
                (前回点数
                <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {typeof pointData?.prevAverage === 'number' ? getRoundedValue(pointData.prevAverage) : '-'}
                </span>
                点)
              </span>
            </div>
          </div>
        </div>
        <div className={classes.pointList}>
          {pointData?.pointsWithName.map(({ name, point, prevPoint }, i) => (
            <div
              key={`point-detail-${i}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <img height={12} src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
                <span>{name}さん</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {typeof prevPoint === 'number' && (
                  <>
                    <div>{prevPoint}点</div>
                    <div>{`->`}</div>
                  </>
                )}
                <div className={classes.point}>{point}点</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContentButton label={'閉じる'} onClick={closeDetailModal} inline />
    </div>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalSection: {
    display: 'flex',
    marginBottom: 16,
    gap: '16px',
    '@media (max-width: 632px)': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  pointList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontSize: 12,
    height: 176,
    maxHeight: 176,
    overflow: 'auto',
    flex: 1,
    minWidth: 234,
    textAlign: 'center',
    padding: '16px',
    backgroundColor: '#F9F9F9',
    borderRadius: 8,
  },

  label: {
    color: '#00838C',
  },
  point: {
    width: 26,
    color: '#00838C',
    fontWeight: 'bold',
  },
}))
