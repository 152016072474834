import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import { mutationDeleteAccount } from './api/graphql'
import { ConfirmWithdraw, CompleteWithdraw, AnketWithdraw } from './components/index'
import { connector, ContainerProps } from './index.container'

import { constants } from 'assets'

export type InputComment = {
  comment?: string
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

type WithDrawStep = 'anket' | 'confirm' | 'complete'

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const [currentStep, setCurrentStep] = React.useState<WithDrawStep>('anket')
  const [comment, setComment] = React.useState<InputComment['comment']>(undefined)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleNextButtonClick = async (data?: InputComment) => {
    if (currentStep === 'anket') {
      setCurrentStep('confirm')
      setComment(data?.comment)
      window.scrollTo(0, 0)
    }

    if (currentStep === 'confirm') {
      if (!props.user?.id) return
      setIsLoading(true)

      const res = await mutationDeleteAccount({ input: { userId: props.user.id, comment } })
      if (!res) {
        setIsLoading(false)
        return
      }

      await props.signout()
      setIsLoading(false)
      setCurrentStep('complete')
      window.scrollTo(0, 0)
    }
  }

  const Content = () => {
    switch (currentStep) {
      case 'anket':
        return <AnketWithdraw handleNextButtonClick={handleNextButtonClick} />
      case 'confirm':
        return (
          <ConfirmWithdraw
            handleNextButtonClick={handleNextButtonClick}
            email={props.user.email}
            isLoading={isLoading}
          />
        )
      case 'complete':
        return <CompleteWithdraw />
      default:
        return null
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!props.user?.fetched) return null

  return (
    <div className={classes.main}>
      <div className="__container">
        <h1 className="__title">退会</h1>
        <div className="__content">
          <Content />
        </div>
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      padding: '32px 16px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },
        '& .__title': {
          margin: '0 0 16px',
          textAlign: 'center',
        },
        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: 8,
          padding: 32,
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
          [theme.breakpoints.down('xs')]: {
            padding: 16,
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
