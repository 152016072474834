import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import { formatDate } from 'pages/hint/_shared/utils/formatDate'

import { IConsultationCommentListResult } from '../../stores/apis/getConsultationCommentList'
import { IHintCommentListResult } from '../../stores/apis/getHintCommentList'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    paddingBottom: '16px',
    borderBottom: 'solid 1px #2CBBC6',
    margin: 0,
  },

  list: {
    margin: 0,
    padding: 0,
  },

  listin: {
    listStyle: 'none',
    padding: '32px 0',
    borderBottom: 'solid 1px #E8E8E8',
    fontSize: '14px',
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap',

    [theme.breakpoints.down('md')]: {
      padding: '16px 0',
    },
  },

  detail: {
    marginTop: '16px',

    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
    },
  },

  date: {
    color: '#A8A8A8',
    marginRight: '24px',
    fontSize: '12px',

    [theme.breakpoints.down('md')]: {
      marginRight: '16px',
    },
  },

  name: {
    fontSize: '12px',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  commentsNum: number | undefined
  commentsHeading: string
  comments: IHintCommentListResult[] | IConsultationCommentListResult[] | null
}

// -----------------------------
// Component
// -----------------------------
export const CommentList = (props: IProps) => {
  const classes = useStyles()
  return (
    <Box>
      <p className={classes.heading}>
        {props.commentsHeading}
        <span>（{props?.comments?.length}件）</span>
      </p>
      <ul className={classes.list}>
        {(props.comments as Array<IHintCommentListResult | IConsultationCommentListResult>).map(
          (comment, index: number) => {
            return (
              <li className={classes.listin} key={index}>
                <span>{comment.content}</span>
                <Box className={classes.detail}>
                  <span className={classes.date}>{formatDate(comment.createdAt)}</span>
                </Box>
              </li>
            )
          }
        )}
      </ul>
    </Box>
  )
}
