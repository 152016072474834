import { useCallback, useEffect, useRef } from 'react'

const toolbarClass = 'js-toolbar-ref'
const toolbarWrapperClass = 'js-toolbar-wrapper'
const fixedAdditionalClass = 'fixed'

export const useToolbar = () => {
  const toolbarRef = useRef<HTMLDivElement | null>()
  const toolbarWrapperRef = useRef<HTMLDivElement | null>()
  const scrollableBoxRef = useRef<HTMLDivElement | null>()

  const onScroll = useCallback(() => {
    const offsetTop = toolbarWrapperRef?.current?.getBoundingClientRect().top ?? null
    const shouldFixToolbar = offsetTop == null ? false : offsetTop < 0
    const toolbarHeight = shouldFixToolbar ? toolbarRef?.current?.getBoundingClientRect().height ?? 0 : 0
    toolbarRef?.current?.classList[shouldFixToolbar ? 'add' : 'remove'](fixedAdditionalClass)
    if (toolbarWrapperRef.current) {
      toolbarWrapperRef.current.style.paddingTop = (shouldFixToolbar ? toolbarHeight + 'px' : 0).toString()
    }
  }, [])

  useEffect(() => {
    toolbarWrapperRef.current = window.document.querySelector<HTMLDivElement>(`.${toolbarWrapperClass}`)
    toolbarRef.current = window.document.querySelector<HTMLDivElement>(`.${toolbarClass}`)
    if (!toolbarRef || !scrollableBoxRef) return
    scrollableBoxRef?.current?.addEventListener('scroll', onScroll)
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => scrollableBoxRef?.current?.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return {
    scrollableBoxRef,
    toolbarClass,
    toolbarWrapperClass,
  }
}
