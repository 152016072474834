import * as React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { createteamContext } from '../../contexts/createGhostTeam'
import { Font } from '../atoms'

import { CalendarSvg } from 'assets/svg'

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
}

type Props = {
  whichDate: 'start' | 'finish'
  ownStyles?: React.CSSProperties
}

export const SetDateBar: React.FC<Props> = (props) => {
  const dateType = props.whichDate === 'start'
  const startAttr = 'startAt'
  const endAttr = 'endAt'

  const classes = useStyles({})

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const { teamState, setIsTeamState } = React.useContext(createteamContext)
  const [displayValue, setDisplayValue] = React.useState<string | null>(null)

  const handleDateChange = (date: Date | null) => {
    if (!date) {
      return
    }
    const yearValue = date.getFullYear()
    const monthValue = `0${date.getMonth() + 1}`.slice(-2)
    const dateValue = `0${date.getDate()}`.slice(-2)
    setIsTeamState(setValue(`${yearValue}-${monthValue}-${dateValue}`))
    setModalOpen(false)
  }

  const setValue = (value: string) => {
    return dateType ? { startAt: value } : { endAt: value }
  }

  React.useEffect(() => {
    dateType ? setDisplayValue(teamState.startAt || null) : setDisplayValue(teamState.endAt || null)
  }, [teamState, dateType])

  return (
    <div style={props.ownStyles}>
      <Font fontSize={14} bold ownStyles={{ margin: '0 0 10px' }}>
        {props.whichDate === 'start' ? '探索開始日' : '探索終了日'}
      </Font>
      <form action="" method="POST">
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ja}>
          <KeyboardDatePicker
            disableToolbar
            onClick={handleModalOpen}
            onClose={handleModalClose}
            open={modalOpen}
            variant="inline"
            format="yyyy年M月d日　E曜日"
            margin="normal"
            id={dateType ? startAttr : endAttr}
            onChange={handleDateChange}
            value={displayValue}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            name="trip-start"
            inputVariant="outlined"
            className={classes.main}
            autoOk
            disablePast
            keyboardIcon={<img src={CalendarSvg} alt="calendar" style={{ padding: 0 }} />}
            PopoverProps={{ classes: { paper: classes.popover } }}
          />
        </MuiPickersUtilsProvider>
      </form>
    </div>
  )
}

const useStyles = makeStyles({
  main: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& .MuiFormLabel-root': {
      color: constGhost.COLOR_MAINBLUE,
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
      borderRadius: 8,
      border: '1px solid ' + constGhost.COLOR_LIGHTGRAY3,
      height: 42,
      pointerEvents: 'none',
    },
    '& .MuiInputBase-input': {
      color: constGhost.COLOR_BLACK3,
      fontSize: 14,
      padding: 0,
      marginLeft: 16,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiButtonBase-root': {
      color: constGhost.COLOR_MAINBLUE,
      padding: 8,
    },
  },
  popover: {
    borderRadius: 8,
    '& .MuiPickersDay-current': {
      color: constGhost.COLOR_MAIN_NEW,
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: constGhost.COLOR_MAIN_NEW,
      color: constGhost.COLOR_WHITE,
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 225,
    },
  },
})
