import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'

import { Karte, KarteMemberInput } from 'utils/generated'

const MutationCreateKarteMember = `mutation KarteMemberStore($input: KarteMemberInput!) {
  karteMemberStore(input: $input) {
    id
    leaderId
    data
    createdAt
    updatedAt
  }
}`

export const mutationCreateKarteMember = async (input: KarteMemberInput) => {
  const response = (await API.graphql({
    query: MutationCreateKarteMember,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ karteMemberStore: Karte }>

  return response.data?.karteMemberStore as Karte
}
