import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

type Props = {}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const WhiteCard: React.FC<Props & StyleProps> = ({  children, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  return <div className={classes.whiteCardRoot}>{children}</div>
}

const useStyles = makeStyles(
  {
    whiteCardRoot: ({ ownStyles }: StyleProps) => ({
      margin: '24px 0 0',
      padding: '27px 24px 34px',
      backgroundColor: constants.COLOR_WHITE,
      ...ownStyles,
    }),
  },
  { name: 'WhiteCard' }
)
