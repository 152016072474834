import * as React from 'react'

import { Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { useHandleTeamAnketStart } from 'services/api/anket'
import { createTeamBuildingReview, useTeamBuildingReviews } from 'services/api/review'

import { NoStyleButton } from 'components/NoStyleButton'
import { TeamListMenu } from 'pages/teams/components/ContentHeader/TeamListMenu'
import { HooksContext } from 'pages/teams/contexts'
import { useTeamBuilding } from 'pages/teams/hooks'
import { TeamBuildingReview, TeamBuildingReviewStatus, TeamBuildingStatus, TeamStatusAdmin } from 'utils/generated'
import { getTeamStatusAdminLabel } from 'utils/labels'

import { DefaultButton } from '../DefaultButton'

import { CategoryButton } from './CategoryButton'
import { useContentHeader } from './hooks/contentHeader'

import { constants } from 'assets'
import { replacePathParams } from 'assets/history'
import { Pages } from 'assets/pages'

type ReflectionButtonProps = {
  teamId: string
  isAdmin?: boolean
  isMdUp: boolean
  isSp?: boolean
}

export const ReflectionButton: React.FC<ReflectionButtonProps> = ({ teamId, isAdmin, isMdUp, isSp }) => {
  const classes = useStyles({ teamId, isAdmin, isMdUp })
  const { currentTeam, teamBuilding } = React.useContext(HooksContext)
  const [isClicked, setIsClicked] = React.useState(false)

  const { teamBuildingReviews, dataRefresh, loading } = useTeamBuildingReviews(teamId)
  const [teamBuildingReviewLatest] = teamBuildingReviews ?? []

  const handleClickJoinButton = React.useCallback(async () => {
    await dataRefresh()
    setIsClicked(true)
  }, [dataRefresh])

  const openReflectionPage = React.useCallback(
    (reviewId: string) => {
      window.open(
        replacePathParams(Pages.TeamsReview, {
          teamId,
          reviewId,
        }),
        '_blank'
      )
    },
    [teamId]
  )

  const startReflection = React.useCallback(async () => {
    if (!teamBuildingReviewLatest || teamBuildingReviewLatest.status === TeamBuildingReviewStatus.Completed) {
      const createdTeamBuildingReview: TeamBuildingReview = await createTeamBuildingReview({ teamId })
      openReflectionPage(createdTeamBuildingReview.id)
    } else {
      openReflectionPage(teamBuildingReviewLatest.id)
    }
  }, [openReflectionPage, teamBuildingReviewLatest, teamId])

  React.useEffect(() => {
    isClicked && startReflection()
    setIsClicked(false)
  }, [isClicked, startReflection])

  if (!currentTeam || teamBuilding?.status !== TeamBuildingStatus.Completed) return <></>

  if (isSp) {
    return (
      <NoStyleButton onClick={handleClickJoinButton} disabled={loading}>
        リフレクション
      </NoStyleButton>
    )
  }

  return (
    <div className={isMdUp ? '' : classes.menuContainerWrapper}>
      <DefaultButton
        disabled={loading}
        style={{
          color: constants.COLOR_MAIN_NEW,
          backgroundColor: constants.COLOR_WHITE,
          fontSize: isMdUp ? 12 : 10,
          padding: '12px 16px',
          fontWeight: 'bold',
          border: isMdUp ? `1px solid ${constants.COLOR_MAIN_NEW}` : undefined,
          boxShadow: 'none',
          minWidth: 'auto',
          width: isMdUp ? 140 : '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        endIcon={
          <img src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'} alt="change" className={classes.menuIcon} />
        }
        onClick={handleClickJoinButton}
      >
        リフレクション
      </DefaultButton>
    </div>
  )
}
interface Props {
  teamId?: string
}

export const HEADER_HEIGHT = 72

export const ContentHeader: React.FC<Props> = React.memo((props) => {
  const { teamId } = props
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { canGhostTeamCreate, toWaiting, toolsStates } = useContentHeader({ teamId })
  const { isTB, isGhost, isKarte, isBuilding, isBuildingReview } = toolsStates

  const { loaded: loadedTeamBuilding } = useTeamBuilding(teamId)
  const { currentTeam, currentUser, isAdmin, currentTeamMember } = React.useContext(HooksContext)

  const classes = useStyles({ teamId, isAdmin, isMdUp, isAdminOrLeader: canGhostTeamCreate })

  const { handleTeamAnketStart, isLoading: isLoadingTB } = useHandleTeamAnketStart(currentTeamMember, currentTeam?.id)

  return (
    <>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          {teamId && <TeamListMenu teamId={teamId} />}
          {currentTeam?.statusBarComment ? (
            <div className={classes.planContainer}>
              {currentTeam?.statusBarCommentIcon ? (
                <img
                  className={classes.planIcon}
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/svg/teams/status_bar_${currentTeam.statusBarCommentIcon.toLocaleLowerCase()}.svg`
                  }
                  alt={'i'}
                />
              ) : (
                <></>
              )}
              {currentTeam.statusBarComment}
            </div>
          ) : (
            <></>
          )}

          {teamId && teamId.length > 30 ? (
            <></>
          ) : (
            <>
              {currentUser && currentUser.statusAdmin && currentUser.statusAdmin !== TeamStatusAdmin.Active ? (
                <div className={classes.statusAdmin}>
                  <img
                    className={classes.statusAdminIcon}
                    src={process.env.PUBLIC_URL + '/assets/svg/failure.svg'}
                    alt={'failure'}
                  />

                  {getTeamStatusAdminLabel(currentUser.statusAdmin)}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {isMdUp && (
          <div className={classes.menuContainer}>
            {isKarte && (
              <div
                onClick={() =>
                  window.open(
                    replacePathParams(Pages.TeamsToolKarteDiagnose, { teamId: currentTeam?.id }),
                    '_team-karte'
                  )
                }
              >
                <CategoryButton
                  linkTo="カルテ"
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'}
                      alt="change"
                      className={classes.menuIcon}
                    />
                  }
                />
              </div>
            )}
            {isBuilding && (
              <div
                onClick={
                  loadedTeamBuilding
                    ? () => window.open(replacePathParams(Pages.TeamsToolBuilding, { teamId }), '_team-building')
                    : undefined
                }
              >
                <CategoryButton
                  linkTo="キックオフ"
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'}
                      alt="change"
                      className={classes.menuIcon}
                    />
                  }
                />
              </div>
            )}

            {isGhost && (
              <div onClick={toWaiting}>
                <CategoryButton
                  linkTo="オバケ"
                  endIcon={
                    <img
                      src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'}
                      alt="change"
                      className={classes.menuIcon}
                    />
                  }
                />
              </div>
            )}

            {isBuildingReview && <ReflectionButton teamId={teamId as string} isMdUp={isMdUp} />}

            {isTB && (
              <CategoryButton
                onClick={handleTeamAnketStart}
                isLoading={isLoadingTB}
                linkTo="TBチェック"
                endIcon={
                  <img
                    src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'}
                    alt="change"
                    className={classes.menuIcon}
                  />
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  )
})

interface StyleProps {
  teamId?: string
  isAdmin?: boolean
  isMdUp: boolean
  isAdminOrLeader?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: StyleProps) => ({
    margin: props.isMdUp ? 0 : '24px 16px 0',
    padding: props.isMdUp ? '0 40px 0 0' : 0,
    height: props.isMdUp ? HEADER_HEIGHT : 'auto',
    display: props.teamId ? 'flex' : 'none',
    backgroundColor: props.isAdminOrLeader ? constants.COLOR_TEAMBUILDING_PRIMARY : constants.COLOR_WHITE,

    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'visible',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: constants.COLOR_WHITE2,
    },
  }),
  titleContainer: {
    width: '100%',
  },

  planContainer: {
    padding: '8px 20px',
    color: '#34C759',
    fontSize: 16,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
  },
  planIcon: {
    marginRight: 6,
  },

  menuContainer: {
    display: 'flex',
    gap: 16,
  },
  menuContainerSP: {
    display: 'flex',
    margin: '16px',
    flexWrap: 'wrap',
    gap: 6,
  },
  menuContainerWrapper: {
    width: 'calc(50% - 6px)',
  },
  menuIcon: {
    marginRight: 9,
  },

  statusAdmin: {
    padding: '8px 20px',
    color: 'rgb(185, 82, 71)',
    fontSize: 16,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
  },

  statusAdminIcon: {
    width: 17,
    marginRight: 10,
  },
}))
