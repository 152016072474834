import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

type Props = {
  top: number
}

export const Sticky: React.FC<Props> = ({ top, children }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.sticky} style={{ top }}>
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  sticky: {
    position: 'sticky',
  },
}))
