import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  // arrayIndex: number
  // keys: 'themeExample' | 'actionDetails' | 'effectDetails' //it's key of string[] of ghostTypeTemplates
  items: string[]
  fieldType: 'scenes' | 'actions' | 'caveats'
  fontSize: number
  listStyles?: React.CSSProperties
  itemStyles?: React.CSSProperties
  listMarginBottom?: number
  listTop?: number
}

export const GhostArrayData: React.FC<Props> = ({
  items,
  fieldType,
  fontSize,
  listStyles,
  itemStyles,
  listMarginBottom,
  listTop,
}) => {
  const classes = useStyles({ listMarginBottom, listTop })

  return (
    <ul
      style={
        fieldType === 'scenes'
          ? { listStyleType: 'none', padding: '0 0 0 12px', ...listStyles }
          : {
              listStyleType: 'disc',
              listStylePosition: 'inside',
              padding: 0,
              margin: 0,
              ...listStyles,
            }
      }
    >
      {items.map((item) => (
        <li
          key={item}
          style={{
            color: constGhost.COLOR_BLACK3,
            fontSize: fontSize,
            letterSpacing: 0.1,
            lineHeight: 1.6,
            marginBottom: 8,
            ...itemStyles,
          }}
          className={fieldType === 'scenes' ? classes.scenesList : undefined}
        >
          {item}
        </li>
      ))}
    </ul>
  )
}

const useStyles = makeStyles<Theme, { listMarginBottom: number | undefined; listTop: number | undefined }>(
  (theme: Theme) => ({
    scenesList: ({ listMarginBottom, listTop }) => ({
      position: 'relative',
      marginBottom: listMarginBottom ?? 0,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: listTop ?? 11,
        left: -10,
        display: 'inline-block',
        width: 3,
        height: 3,
        borderRadius: '50%',
        backgroundColor: constGhost.COLOR_BLACK3,
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    }),
  })
)
