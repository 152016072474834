import * as React from 'react'

import { Avatar } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

import { OnboardingTeamMember } from 'utils/generated'

import NoImage from '../../../assets/noImage.svg'
import { useOnbHistory } from '../../../navigation/route'

import { OnbPages } from 'assets/pages'

type Props = {
  teamId: string | undefined
  teamMember: OnboardingTeamMember | undefined
}
type StyleProps = {
  classes: ClassNameMap
  visibleDetail: boolean
}

export const IconBox: React.FC<Props & StyleProps> = ({ teamId, teamMember, classes, visibleDetail }) => {
  const history = useOnbHistory()
  const nickname =
    teamMember?.nickname && teamMember.nickname !== '名無し' ? teamMember.nickname : teamMember?.email ?? ''
  const isLengthOver10 = Number(nickname.length) > 10

  return (
    <>
      <div className={classes.profile}>
        <Avatar
          alt="avatar"
          src={teamMember?.imageUrl || NoImage}
          className={classes.avatar}
          onClick={() => history.push(OnbPages.Mypage, { teamId })}
        />
        {visibleDetail ? (
          <div>
            <div className={classes.profileName}>{isLengthOver10 ? nickname.slice(0, 9) + '…' : nickname}</div>
            <div className={classes.profilePosition}>{teamMember?.position}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
