import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

export const Content: React.FC<{ useMobileOnly?: boolean }> = ({ children, useMobileOnly }) => {
  const classes = useStyles()

  return <div className={`${useMobileOnly ? classes.useMobileOnly : classes.root}`}>{children} </div>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxHeight: '100vh',
    borderRadius: 8,
    padding: '24px 16px',
    backgroundColor: '#FFFFFF',
    overflow: 'auto',
  },
  useMobileOnly: {
    overflow: 'auto',
    '@media (max-width: 632px)': {
      width: '100%',
      maxHeight: '100vh',
      padding: '24px 16px',
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
    },
  },
}))
