import * as React from 'react'

import { Circle, G, Line, Path, Rect, Svg, Text, Tspan } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Woman1: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-1407.31 -75.431)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(1407.31 75.43)" fill="#80d6dd" />
        <Line
          x1="0"
          x2="0"
          y1="0"
          y2="17.19"
          transform="translate(1567.704 128.067)"
          fill="none"
          stroke="#4441e5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.714"
        />
        <Line
          x1="0"
          x2="0"
          y1="0"
          y2="35.286"
          transform="translate(1578.561 128.067)"
          fill="none"
          stroke="#4441e5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.714"
        />
        <Line
          x1="0"
          x2="0"
          y1="0"
          y2="24.429"
          transform="translate(1590.323 128.067)"
          fill="none"
          stroke="#4441e5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.714"
        />
        <G>
          <G>
            <Path
              d="M1565.981,214.789l.771,101.539.263,34.786.021,2.793,96.06-.729-.021-2.635c0-.051.013-.1.013-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C1567.957,209.306,1565.958,211.777,1565.981,214.789Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(1606.806 194.609) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1647.536 143.56)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1552.617 144.281)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(1562.917 98.746)" fill="#ffe4d2" />
            <Path
              d="M1663.728,436.13a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.542,2.542,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M1604.128,436.582a2.547,2.547,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.524,2.563h0l.008.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.018-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M1598.647,209.077l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M1614.977,209.3l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(1610.414 217.148) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M1598.51,209.077l5.692,14.835,11-14.962Z" fill="#e5e5e4" />
            <Path d="M1632.394,208.817l-4.388,13.994-13.35-13.86Z" fill="#e5e5e4" />
            <Path
              d="M1614.565,89.429c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C1556.518,117.347,1582.391,89.677,1614.565,89.429Z"
              fill="#522903"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1566.969 344.928) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1626.582 344.475) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M1737.859,240.153l-48.126,28.734a10.127,10.127,0,0,1-13.886-3.5h0l-.347-.58c-2.868-4.8-2.078-12.577,2.727-15.445l48.125-28.734c4.8-2.868,11.8.256,14.669,5.059l.346.58A10.13,10.13,0,0,1,1737.859,240.153Z"
                fill="#ffe4d2"
              />
              <Path d="M1661.272,259.989,1722.1,220.96l13.673,21.845-60.829,39.029Z" fill="#545364" />
            </G>
            <Path d="M1661.882,208.555l28.11,49.877-22.381,11.8L1639.5,220.355Z" fill="#545364" />
            <G>
              <Path
                d="M1486.247,230.14l.346-.58c2.868-4.8,9.868-7.927,14.668-5.059l48.126,28.734c4.8,2.868,5.6,10.645,2.727,15.445l-.347.58h0a10.128,10.128,0,0,1-13.889,3.5h0l-48.126-28.734A10.128,10.128,0,0,1,1486.247,230.14Z"
                fill="#ffe4d2"
              />
              <Path d="M1552.661,285.707l-60.831-39.029,13.673-21.845,60.831,39.029Z" fill="#545364" />
            </G>
            <Path d="M1588.109,224.228l-28.114,49.877-22.381-11.8,28.11-49.877Z" fill="#545364" />
          </G>
          <G
          // style="isolation: isolate"
          >
            <Text
              transform="translate(1929.421 116.832) rotate(18.094)"
              fill="#f4805d"
              // font-size="38.187"
              // font-family="Helvetica-Bold, Helvetica"
              // font-weight="700"
              // style="isolation: isolate"
            >
              <Tspan x="0" y="0">
                ?
              </Tspan>
            </Text>
          </G>
          <G>
            <Path
              d="M1810.054,436.737v.517c0,10.075,8.165,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.035v-.995a2.546,2.546,0,0,0-2.546-2.546h-31.389a2.546,2.546,0,0,0-2.546,2.546Z"
              fill="#72642a"
            />
            <Path
              d="M1869.7,436.737v.517c0,10.075,8.166,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.035v-.995a2.546,2.546,0,0,0-2.546-2.546h-31.388a2.546,2.546,0,0,0-2.547,2.546Z"
              fill="#72642a"
            />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1869.7 344.721)" fill="#ffe5d2" />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1810.041 344.721)" fill="#ffe5d2" />
            <Path
              d="M1859.068,141.127s-15.614,25.42-15.614,35.225a26.05,26.05,0,0,0,8.351,18.519c2.9,3.267-10.495,17.768-2.8,32.467,7.989,15.252,5.4,5.044,36.251,5.769s-7.608,32.9,6.19,13.652c6.759-9.43,21.759-29.779,25.711-52.5,3.4-19.563-6.914-63.219-6.914-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1853.856,141.127s15.614,25.42,15.614,35.225a26.05,26.05,0,0,1-8.351,18.519c-2.9,3.267,10.495,17.768,2.8,32.467-7.989,15.252-5.4,5.044-36.251,5.769s7.608,32.9-6.19,13.652c-6.759-9.43-21.764-29.779-25.711-52.5-3.4-19.563,6.914-63.219,6.914-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1901.712,209.005h-87.189a4.535,4.535,0,0,0-4.482,4.558V329.575h96.136V213.563A4.536,4.536,0,0,0,1901.712,209.005Z"
              fill="#7192cc"
            />
            <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(1847.636 194.593)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1793.272 143.93)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1888.268 143.93)" fill="#ffe5d2" />
            <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(1803.507 98.718)" fill="#ffe5d2" />
            <Path
              d="M1914.921,150.813c-18.3,0-12.283,3.232-25.758-18.873-23.807,25.615-76.5,17.251-89.472,9.881,3.63-23.475,24.713-52.414,56.91-52.414S1914.921,116.887,1914.921,150.813Z"
              fill="#6d614a"
            />
            <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(1810.041 309.677)" fill="#7192cc" />
            <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(1832.468 209.005)" fill="#ffe5d2" />
            <G>
              <Path
                d="M1825.829,190.744a11.768,11.768,0,0,1,8.122,14.466l-4.842,12.986a11.766,11.766,0,0,1-14.467,8.121l-.753-.211a11.767,11.767,0,0,1-8.122-14.466l4.842-12.986a11.766,11.766,0,0,1,14.466-8.122Z"
                fill="#ffe4d2"
              />
              <Path
                d="M1833.592,212.27l-18.872,75.179s-11.457,1.9-14.843.958c-3.218-.9-11.512-8.358-11.512-8.358l18.87-75.178Z"
                fill="#7192cc"
              />
            </G>
            <Path d="M1904.367,210.488l28.11,49.877-22.381,11.8-28.114-49.877Z" fill="#7192cc" />
            <G>
              <G>
                <Path
                  d="M1878.709,335.388a11.563,11.563,0,0,1-4.771-15.59l4.559-9.053a11.564,11.564,0,0,1,15.59-4.771l.678.36a11.562,11.562,0,0,1,4.771,15.59l-4.559,9.053a11.562,11.562,0,0,1-15.589,4.771Z"
                  fill="#7192cc"
                />
                <Path
                  d="M1874.46,315.559l36.264-63.193s5.839-3.566,15.164-.271c5.837,2.059,8.912,12.269,8.912,12.269l-36.261,63.2Z"
                  fill="#7192cc"
                />
              </G>
              <G>
                <Path
                  d="M1878.709,335.388a11.563,11.563,0,0,1-4.771-15.59l4.559-9.053a11.564,11.564,0,0,1,15.59-4.771l.678.36a11.562,11.562,0,0,1,4.771,15.59l-4.559,9.053a11.562,11.562,0,0,1-15.589,4.771Z"
                  fill="#ffe4d2"
                />
                <Path
                  d="M1874.46,315.559l36.264-63.193s5.839-3.566,15.164-.271c5.837,2.059,8.912,12.269,8.912,12.269l-36.261,63.2Z"
                  fill="#7192cc"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman1
