import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import { ButtonRenewal } from 'components/ButtonRenewal'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'
import { convertUrl } from 'pages/onboarding/utils/convertText'

import PlusIcon from './topPageModules/assets/plusIcon.svg'
import { FAQData } from './topPageModules/data/FAQ.data'
import { TopSectionTitle } from './topPageModules/TopSectionTitle'

import * as constants from 'assets/constants'

export const TopFAQ: React.FC = () => {
  const classes = useStyles({})
  const history = useHistory()
  const theme = useTheme()
  const is600Up = useMediaQuery(theme.breakpoints.up(constTop.breakPointsSP))

  return (
    <div className={classes.root} id={constTop.anchorLinkId[6]}>
      <TopSectionTitle title="よくある質問" />
      <div className={classes.accordionWrap}>
        {FAQData.map((data, i) => (
          <Accordion
            key={`FAQData${i}`}
            className={classes.accordion}
            square={true}
            classes={{
              root: classes.MUIRoot,
              expanded: classes.expanded,
              disabled: classes.disabled,
            }}
          >
            <AccordionSummary
              className={classes.summary}
              classes={{
                root: classes.MUIRootSummary,
                content: classes.content,
                expandIcon: classes.MUISummaryExpandIcon,
              }}
              expandIcon={<img src={PlusIcon} alt="plus-icon" />}
            >
              <div className={classes.Q}>Q</div>
              <div className={classes.question}>{data.Q}</div>
            </AccordionSummary>
            <AccordionDetails
              className={classes.details}
              classes={{
                root: classes.MUIRootDetails,
              }}
            >
              <div className={classes.A}>A</div>
              <div className={classes.answer}>
                <div className={classes.answerText} dangerouslySetInnerHTML={{ __html: convertUrl(data.A) }} />
                <a
                  href={data.URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: constants.COLOR_ONBOARDING_MAIN, textDecoration: 'underline' }}
                >
                  {data.URL}
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <ButtonRenewal
        onClick={() => history.push(constants.PAGE_HELP)}
        width={250}
        widthSP={176}
        height={50}
        heightSP={46}
        style={
          is600Up ? { display: 'block', margin: '56px auto 140px' } : { display: 'block', margin: '48px auto 100px' }
        }
      >
        もっと見る
      </ButtonRenewal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1000,
    margin: '0 auto',
    '&::before': {
      ...constTop.fixAnchorLinkPosition,
    },
  },
  accordionWrap: {
    margin: '26px 0 0 0',
  },
  MUIRoot: {
    margin: '0 0 24px 0',
    position: 'static',
    borderRadius: '8px',
    boxShadow: '0 0 6px 0 #0000001E',
    '&::before': {
      opacity: 0,
    },
    '&.Mui-expanded': {
      boxShadow: '0 0 6px 0 #0000001E',
    },
  },
  expanded: {
    backgroundColor: constants.COLOR_WHITE,
    height: 'auto',
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0  16px 0',
  },
  MUISummaryExpandIcon: {
    '&.Mui-expanded': {
      transform: 'rotate(45deg) scale(1.15)',
    },
  },
  summary: {
    height: 'auto',
    padding: '0 36px',
  },
  Q: {
    fontSize: 24,
    margin: '-5px 0 0 0',
    alignSelf: 'start',
    fontWeight: 'bold',
    fontFamily: '"DIN 2014"',
  },
  question: {
    fontSize: 14,
    fontWeight: 600,
    margin: '0 24px 0 24px',
  },
  plusIcon: {
    margin: '0 0 0 auto',
    '&.moveToRight': {
      animation: `slideRight 300ms ${theme.transitions.easing.easeInOut}`,
      '@global': {
        '@keyframes slideRight': {
          '0%': {
            transform: 'translateX(288px)',
          },
          '100%': {},
        },
      },
    },
  },
  MUIRootDetails: {
    margin: '0 36px 28px 36px',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  },
  details: {},
  answer: {
    padding: '0 0 0 24px',
    fontSize: 14,
  },
  answerText: {
    '& a': {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
    },
  },
  A: {
    fontSize: 24,
    padding: '-5px 0 0 0',
    fontWeight: 'bold',
    alignSelf: 'start',
    fontFamily: '"DIN 2014"',
    color: constants.COLOR_MAIN_NEW,
  },
  crossIcon: {
    margin: '0 0 0 auto',
  },
  [theme.breakpoints.up(constTop.breakPointsSP)]: {
    summary: {
      margin: '0 0 0 0',
      height: 80,
    },
  },
}))
