import * as React from 'react'

import { Stepper, Step, StepLabel, StepConnector, makeStyles } from '@material-ui/core'
import { WithStyles, withStyles, createStyles, StyleRules, Theme } from '@material-ui/core/styles'

import * as constants from '../../../../../assets/constants'

interface IOwnProps {
  stepCount: number
  activeStepIndex: number
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes, stepCount, activeStepIndex } = props
  return (
    <Stepper activeStep={activeStepIndex} className={classes.root} connector={<StepperConnector />}>
      {Array.from(Array(stepCount).keys()).map((label) => (
        <Step key={label} classes={{ root: 'stepRoot' }}>
          <StepLabel StepIconComponent={StepIcon}>{label + 1}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const StepperConnector = withStyles({
  line: {
    borderColor: constants.COLOR_SECOND,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

function StepIcon(props: any) {
  const classes = useStepIconStyles()
  const { active, children } = props

  return (
    <>
      {active ? (
        <div className={classes.active}>{children}</div>
      ) : (
        <div className={classes.root}>
          <div className={classes.circle} />
        </div>
      )}
    </>
  )
}

const useStepIconStyles = makeStyles({
  root: {
    color: constants.COLOR_SECOND,
    display: 'flex',
    height: 22,
    alignItems: 'center',

    '& .Mui-disabled': {
      color: 'red',
    },
  },
  active: {
    color: constants.COLOR_SECOND,
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
})

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      padding: 0,
      background: 'transparent',

      '& .stepRoot': {
        padding: 0,

        '& .stepLabelRoot': {
          '& span': {
            padding: 0,
          },
        },

        '& .MuiStepIcon-root': {
          color: constants.COLOR_SECOND,
        },
        '& .MuiStepLabel-iconContainer': {
          padding: 0,
        },

        '& .Mui-disabled': {
          '& .MuiStepLabel-labelContainer': {
            display: 'none',
          },
          color: constants.COLOR_SECOND,
        },
      },
      '& .MuiStep-completed': {
        '& .MuiStepLabel-labelContainer': {
          display: 'none',
        },
      },

      '& .MuiTypography-root': {
        color: '#fff',
        background: constants.COLOR_SECOND,
        width: 35,
        height: 35,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 18,
        fontWeight: 'bold',
      },
    },
  })

export default withStyles(useStyles)(Index)
