import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

import * as constants from 'assets/constants'

type Props = {
  numerator: number
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  // should not set props because of calculate itself
  angle: number
}

export const CircularProgressCount: React.FC<Props & StyleProps> = ({ numerator, ownStyles }) => {
  const denominator = 100
  const angle = (360 / denominator) * numerator
  const classes = useStyles({ ownStyles: ownStyles, angle: angle })

  if (!(numerator >= 0 && numerator <= 100))
    return (
      <div style={{ fontSize: 12, color: constants.COLOR_ONBOARDING_GRAY_DARK }}>該当するデータが存在しません。</div>
    )

  return (
    <div className={classes.circle}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: 55,
          height: 55,
          backgroundColor: constGhost.COLOR_WHITE,
          borderRadius: '50%',
          fontSize: 12,
          fontWeight: 'bold',
          zIndex: 4,
        }}
      >
        <div>
          <span className={classes.numerator}>{Math.round(numerator * 10) / 10}</span>
          <span>%</span>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps & StyleSubProps>(
  () => ({
    circle: ({ angle, ownStyles }) => ({
      position: 'relative',
      width: 80,
      height: 80,
      background: constGhost.COLOR_MAIN_NEW,
      borderRadius: '50%',
      textAlign: 'center',
      overflow: 'hidden',
      zIndex: 1,
      ...ownStyles,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -40,
        width: 80,
        height: 80,
        display: 'block',
        background: constGhost.COLOR_LIGHTGRAY3,
        transformOrigin: 'right 40px',
        transform: angle > 180 ? `rotate(${angle - 180}deg)` : 'none',
        zIndex: 2,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 40,
        width: 80,
        height: 80,
        display: 'block',
        background: angle > 180 ? constGhost.COLOR_MAIN_NEW : constGhost.COLOR_LIGHTGRAY3,
        transformOrigin: 'left 40px',
        transform: angle > 180 ? 'none' : `rotate(${angle}deg)`,
        zIndex: 3,
      },
    }),
    numerator: {
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 14,
    },
  }),
  { name: 'CircularProgressCount' }
)
