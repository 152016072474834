import * as React from 'react'

import { Imgcard } from 'pages/ghost/components/atoms'

type Props = {
  largeSize?: boolean
  onClick?: () => void
  ownStyles?: React.CSSProperties
  ghostId: string
}

export const GhostMageImg: React.FC<Props> = (props) => {
  const imgSize = { maxWidth: 250, height: 148 }
  return (
    <>
      <Imgcard
        src={process.env.PUBLIC_URL + `/assets/ghost/ghost${props.ghostId}.svg`}
        alt={'ghost'}
        height={imgSize.height}
        onClick={props.onClick}
        ownStyles={{
          maxWidth: imgSize.maxWidth,
          verticalAlign: 'top',
          backgroundColor: 'white',
          border: '1px solid #E9E9E9',
          ...props.ownStyles,
        }}
      />
    </>
  )
}
