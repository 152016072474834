import { useState } from 'react'

import { GhostTeamMember } from 'utils/generated'

import { page2Num } from '../assets/pages'

export const LoaderPatternsMessage = {
  idle: '',

  selectLeader: 'メンバーが集まりました。\nみんなでオバケ探索を開始しよう',
  start: 'ファシリテーターが決まりました。\n画面の指示に従ってオバケ探索を進めよう',
  selectGhost: '他のメンバーが見かけたオバケを取得中...\n自分も見かけたか確認しよう', //5（pickGhosts）→6（complete）から6→7（vote）へ
  report: '投票を送信中...', //7（vote）→8（break）へ
  report2complete: 'チーム全員のオバケ探索が終了しました。', //8（break）→9（complete）へ
  complete2Result: 'みんなの投票結果を集計中...', //9（complete）→10（report）へ
  research: '個人ワーク送信中…...',
  researchResult: 'チーム全員のオバケ調査が完了しました。\nみんなの意見を見てみよう',
  like: '送信中',
  likeResult: 'みんなの\nを集計中です。\n果たして賛成の多かった意見は...？',
  done2Report: '次は「今後の対処方法」を考えましょう', //14（done）→15（report）へ

  busy: '通信待機中...',

  custom: '',
}
export const LoaderPatternsMessageSP = {
  ...LoaderPatternsMessage,
  selectGhost: '他のメンバーが見かけた\nオバケを取得中...\n自分も見かけたか確認しよう',
  report2complete: 'チーム全員のオバケ探索が\n終了しました。',
  complete2Result: 'みんなの投票結果を集計中...',
  researchResult: 'チーム全員のオバケ調査が\n完了しました。\nみんなの意見を見てみよう',
}

export const getLoadinPattern = (path: string, teamMember: GhostTeamMember): LoaderPatterns => {
  if (path.includes('selectleader')) {
    return 'selectLeader'
  } else if (path.includes('start')) {
    return 'start'
  } else if (path.includes('complete')) {
    return page2Num('complete', teamMember) === 11
      ? 'selectGhost'
      : page2Num('complete', teamMember) === 30
      ? 'busy'
      : page2Num('complete', teamMember) >= 36
      ? 'busy'
      : 'complete2Result'
  } else if (path.includes('break')) {
    return 'report2complete'
  } else if (path.includes('result')) {
    return 'researchResult'
  } else if (path.includes('done')) {
    return 'likeResult'
  } else {
    return 'idle'
  }
}

export type LoaderPatterns = keyof typeof LoaderPatternsMessage

export type ScreenLoaderManager = ReturnType<typeof useScreenLoader>

export const useScreenLoader = () => {
  const [loadingPattern, setLoading] = useState<LoaderPatterns>('idle')

  return {
    loadingPattern,
    setLoading,
  } as const
}
