import {
  OnboardingActionCategory,
  OnboardingPost,
  OnboardingPostLabel,
  OnboardingPostType,
  OnboardingTeamMemberRole,
  OnboardingTeamMemberStatus,
  OnboardingAction,
  OnboardingActionFeedback,
} from 'utils/generated'

const oneMinuteAgo = () => {
  const d = new Date()
  d.setMinutes(d.getMinutes() - 1)
  return d.toISOString()
}

export const TimelinePostsDummy = (liked: boolean): OnboardingPost[] => [
  {
    id: 'timeline-post-dummy',
    teamId: 'dummy-team-id',
    userId: 'dummy-user-id',
    type: OnboardingPostType.Action,

    comment: '初めまして！オンボーディング花子です！こちらの投稿にいいねとコメントをしてみましょう！',
    label: OnboardingPostLabel.HelpMe,
    createdAt: oneMinuteAgo(),

    likeCount: liked ? 1 : 0,
    commentCount: 0,

    isLiked: liked || null,
    isCommented: false,

    action: {
      id: 'dummy-action-id',
      teamId: 'dummy-team-id',
      category: OnboardingActionCategory.Category1,
      mission: 'タイムラインの使い方を覚えよう！',
      why: '',
      what: '',
      how: '',
      period: 0,
      importance: 0,
      point: 0,
      isPublished: true,
      createdAt: '',
      updatedAt: '',
      publishAt: '',
      dammyHash: '',
      commentCount: 0,
      likeCount: 0,
    },

    teamMember: {
      id: 'dummy-member-id',
      nickname: 'オンボーディング花子',
      teamId: 'dummy-team-id',
      userId: 'dummy-user-id',
      role: OnboardingTeamMemberRole.Member,
      status: OnboardingTeamMemberStatus.Accepted,
      createdAt: '',
      updatedAt: '',
      dammyHash: '',
    },
  },
]

export const MemberTimelinePostsDummy = (): OnboardingPost[] => [
  {
    id: 'timeline-post-dummy',
    teamId: 'dummy-team-id',
    userId: 'dummy-user-id',
    type: OnboardingPostType.Action,

    comment: 'はじめまして、本日より○○部に配属されました山田と申します。 比較的△△の分野は詳しいので、いつでも聞いて下さい。宜しくお願いいたします！',
    label: OnboardingPostLabel.HelpMe,
    createdAt: oneMinuteAgo(),

    likeCount: 0,
    commentCount: 0,

    isLiked: null,
    isCommented: false,

    action: {
      id: 'dummy-action-id',
      teamId: 'dummy-team-id',
      category: OnboardingActionCategory.Category1,
      mission: 'タイムラインの使い方を覚えよう！',
      why: '',
      what: '',
      how: '',
      period: 0,
      importance: 0,
      point: 0,
      isPublished: true,
      createdAt: '',
      updatedAt: '',
      publishAt: '',
      dammyHash: '',
      commentCount: 0,
      likeCount: 0,
    },

    teamMember: {
      id: 'dummy-member-id',
      nickname: '山田 太郎',
      teamId: 'dummy-team-id',
      userId: 'dummy-user-id',
      role: OnboardingTeamMemberRole.Member,
      status: OnboardingTeamMemberStatus.Accepted,
      createdAt: '',
      updatedAt: '',
      dammyHash: '',
    },
  },
]

export const actionsFeedbackDummy: (action: OnboardingAction | undefined) => OnboardingActionFeedback[] = (action) => {
  return [
    {
      id: '',
      teamId: '',
      userId: '',
      teamMemberId: '',
      actionId: '',
      category: OnboardingActionCategory.Category2,
      value: NaN,
      importance: 5,
      settedAt: '2021-11-10T01:45:32.487Z',
      updatedAt: '2021-11-10T01:45:32.817Z',
      action: action ? { ...action } : undefined,
    },
  ]
}

export const dummyPromise: () => Promise<void> = async () => {
  await new Promise<void>((resolve) => resolve())
}
