import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPostComment, OnboardingPostSecondComment } from 'utils/generated'

import NoAvatarSvg from '../../assets/noAvatar.svg'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


export type CommentAvatarProps = {
  teamId: string
  item: OnboardingPostComment | OnboardingPostSecondComment
}

export const CommentAvatar: React.FC<CommentAvatarProps> = ({ teamId, item }) => {
  const classes = useStyles()
  const history = useOnbHistory()

  return (
    <img
      className={classes.userAvatar}
      src={item.teamMember?.imageUrl ? item.teamMember.imageUrl : NoAvatarSvg}
      alt={'avatar'}
      onClick={() => history.push(OnbPages.TeamMember, { teamId, userId: item.userId })}
    />
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    userAvatar: {
      marginRight: 16,
      width: 32,
      height: 32,
      borderRadius: 16,
      backgroundColor: constants.COLOR_WHITE,
      objectFit: 'cover',
      cursor: 'pointer',
    },
  }),
  { name: 'CommentAvatar' }
)
