import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Man0: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-7813 -9879)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(7813 9879)" fill="#80d6dd" />
        <G transform="translate(8914.104 10170)">
          <G transform="translate(-804.761 -274.946)">
            <Path
              d="M-711.293-73.658V66.921h97.065V64.258c0-.054.014-.105.014-.16V-73.658c0-3.038-2.037-5.524-4.526-5.524h-88.028C-709.256-79.182-711.293-76.7-711.293-73.658Z"
              transform="translate(804.761 199.946)"
              fill="#545364"
            />
            <Rect x="0" y="0" width="17.715" height="14.551" transform="translate(134.872 106.213)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(176.3 55.063)" fill="#ffe4d2" />
            <Circle cx="15.589" cy="15.589" r="15.589" transform="translate(80.385 55.063)" fill="#ffe4d2" />
            <Circle cx="53.144" cy="53.144" r="53.144" transform="translate(90.856 9.416)" fill="#ffe4d2" />
            <Path
              d="M-614.211,147.617h0a2.571,2.571,0,0,0-2.571-2.569h-31.7a2.57,2.57,0,0,0-2.57,2.57v1h.035c.252,9.949,8.39,9.791,18.4,9.791,10.171,0,18.416-.1,18.416-10.271,0-.175-.013-.349-.013-.524Z"
              transform="translate(804.761 199.946)"
              fill="#2a2a2a"
            />
            <Path
              d="M-674.448,147.617h0a2.571,2.571,0,0,0-2.571-2.569h-31.7a2.57,2.57,0,0,0-2.57,2.57v1h.035c.251,9.949,8.39,9.791,18.4,9.791,10.171,0,18.416-.1,18.416-10.271,0-.175-.013-.349-.013-.524Z"
              transform="translate(804.761 199.946)"
              fill="#2a2a2a"
            />
            <Path
              d="M-678.242-79.182l9.9,26.674h15.125l9.075-26.674Z"
              transform="translate(804.761 199.946)"
              fill="#fff"
            />
            <Path
              d="M-661.742-78.825l-4.671,3.714.546,13.2-3.3,7.724,9.075,25.849,7.975-26.4-3.575-7.425-1.1-4.675,1.1-8.274Z"
              transform="translate(804.761 199.946)"
              fill="#b15f78"
            />
            <Rect x="0" y="0" width="10.725" height="8.8" transform="translate(138.344 129.014)" fill="#c07087" />
            <Path
              d="M-678.382-79.182l5.637,15.033,11.229-15.033Z"
              transform="translate(804.761 199.946)"
              fill="#e5e5e4"
            />
            <Path
              d="M-644.142-79.182l-4.541,14.107-13.383-14.107Z"
              transform="translate(804.761 199.946)"
              fill="#e5e5e4"
            />
            <Path
              d="M-661.242-199.946c32.511,0,53.8,29.218,57.46,52.918,1.466,18.438-19.774.342-22.134-9.1-13.375-3.147-34.619-2.36-72.384,0-3.147,8.655-9.441,19.67-21.808,18.191C-720.108-172.186-693.753-199.946-661.242-199.946Z"
              transform="translate(804.761 199.946)"
              fill="#323333"
            />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(93.467 257.792)" fill="#545364" />
            <Rect x="0" y="0" width="36.842" height="92.672" transform="translate(153.705 257.792)" fill="#545364" />
            <G transform="translate(0 167.255)">
              <Path
                d="M-802.708,26.548A11.564,11.564,0,0,1-799.831,10.5l8.191-5.97a11.561,11.561,0,0,1,16.047,2.877l.44.63a11.562,11.562,0,0,1-2.877,16.047l-8.191,5.971a11.561,11.561,0,0,1-16.047-2.876Z"
                transform="translate(804.761 32.691)"
                fill="#ffe4d2"
              />
              <Path
                d="M-797.431,6.968l61.125-39.651s6.823-.505,13.614,6.685c4.252,4.5,2.323,14.985,2.323,14.985l-61.123,39.655Z"
                transform="translate(804.761 32.691)"
                fill="#545364"
              />
            </G>
            <Path
              d="M-743.875-23.524l33.38-53.647,23.429,13.215-33.38,53.647Z"
              transform="translate(804.761 199.946)"
              fill="#545364"
            />
            <Path
              d="M-599.457-12.911l-37.172-47.252,21.864-15.669,37.172,47.252Z"
              transform="translate(804.761 199.946)"
              fill="#545364"
            />
            <G transform="translate(151.177 160.816)">
              <Path
                d="M-650.457,26.559c4.046,4.883,5.837.757,10.72-3.289l7.945-6.294c4.882-4.046,11.081-6.538,7.034-11.421l-.49-.592A11.563,11.563,0,0,0-641.48,3.441l-7.945,6.3a11.561,11.561,0,0,0-1.522,16.231Z"
                transform="translate(653.584 39.13)"
                fill="#ffe4d2"
              />
              <Path
                d="M-630.181,26.711l55.13-44.689s.513-7.072-3.317-12.574c-3.7-5.317-13.3-8.578-13.3-8.578L-646.8,5.558Z"
                transform="translate(653.584 39.13)"
                fill="#545364"
              />
            </G>
          </G>
          <G transform="translate(-983.19 -274.95)">
            <Path
              d="M-986.866-74.588l.771,101.539.264,34.786.021,2.793,96.059-.729-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.009-2.057-5.452-4.519-5.434l-87.116.661C-984.89-80.071-986.889-77.6-986.866-74.588Z"
              transform="translate(1013.19 199.95)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(67.149 105.182) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(107.88 54.133)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(12.96 54.854)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(23.261 9.319)" fill="#ffe4d2" />
            <Path
              d="M-889.119,146.753a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.173-.011-.346-.017-.519Z"
              transform="translate(1013.19 199.95)"
              fill="#2a2a2a"
            />
            <Path
              d="M-948.719,147.205a2.546,2.546,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              transform="translate(1013.19 199.95)"
              fill="#2a2a2a"
            />
            <Path
              d="M-954.2-80.3l9.994,26.323,14.968-.114,8.784-26.465Z"
              transform="translate(1013.19 199.95)"
              fill="#fff"
            />
            <Path
              d="M-937.87-80.074l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              transform="translate(1013.19 199.95)"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(70.757 127.721) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M-954.337-80.3l5.692,14.835,11-14.962Z" transform="translate(1013.19 199.95)" fill="#e5e5e4" />
            <Path d="M-920.453-80.56l-4.388,13.994-13.35-13.86Z" transform="translate(1013.19 199.95)" fill="#e5e5e4" />
            <Path
              d="M-938.282-199.948c32.174-.246,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C-996.329-172.03-970.456-199.7-938.282-199.948Z"
              transform="translate(1013.19 199.95)"
              fill="#522903"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(27.312 255.501) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(86.925 255.048) rotate(-0.434)"
              fill="#545364"
            />
            <G transform="translate(147.656 30.607)">
              <Path
                d="M-812.23-167.494a11.562,11.562,0,0,1,4.393,15.7L-813-143.072a11.562,11.562,0,0,1-15.7,4.393l-.669-.377a11.562,11.562,0,0,1-4.393-15.7l5.163-8.723a11.564,11.564,0,0,1,15.7-4.393Z"
                transform="translate(865.534 169.343)"
                fill="#ffe4d2"
              />
              <Path
                d="M-808.793-146.952l-33.65,64.624s-10.256,2.919-15.139-.885c-7.8-6.075-7.952-12.915-7.952-12.915l33.646-64.625Z"
                transform="translate(865.534 169.343)"
                fill="#545364"
              />
            </G>
            <Path
              d="M-840.852-84.714l-52.956,34.466L-907.5-73.4l52.956-34.466Z"
              transform="translate(1013.19 199.95)"
              fill="#545364"
            />
            <Path
              d="M-1012.88-22.82l25.926-54.243,24.764,10.5-25.926,54.243Z"
              transform="translate(1013.19 199.95)"
              fill="#545364"
            />
            <G transform="translate(0 164.742)">
              <Path
                d="M-958.38,46.964a11.562,11.562,0,0,0,4.464-15.68l-5.123-8.746a11.564,11.564,0,0,0-15.681-4.464l-.671.374c-5.541,3.085-.667,7.654,2.417,13.193l5.123,8.746c3.085,5.541,3.259,10.036,8.8,6.951Z"
                transform="translate(1013.19 35.208)"
                fill="#ffe4d2"
              />
              <Path
                d="M-954.85,26.439l-35.186-61.63s-10.128-.44-15.505,3.174c-5.563,3.74-7.649,10.517-7.649,10.517L-978,40.131Z"
                transform="translate(1013.19 35.208)"
                fill="#545364"
              />
            </G>
          </G>
          <Path
            d="M-824.654-208.427l-30.33-23.018,17.907-24.123-23.138-29.12L-885.1-258.625l28.247,10.607-18.331,20.139Z"
            transform="translate(-170 49.995)"
            fill="#ffd04d"
          />
          <Path
            d="M-789.4-163.471l23.027-17.475-13.595-18.314,17.567-22.108,18.9,19.788-21.445,8.053,13.917,15.289Z"
            transform="translate(226 6.821)"
            fill="#ef4351"
          />
          <Path d="M-670-286" fill="#ffd04d" />
        </G>
      </G>
    </Svg>
  )
}

export default Man0
