export enum FreeCommentTarget {
  all = 'All',
  member = 'メンバー',
}
export enum TeamBuildingQuestionTitle {
  q1 = '貢献実感',
  q2 = '安心感',
  q3 = '納得感',
  q4 = '一体感',
  q5 = '能力発揮',
  q6 = '働きがい',
  q7 = '愛情',
}

export type TeamBuildingAnketInputs = 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7' | 'comment' | 'freeComment'

export type TeamBuildingAnketCommentInput = {
  target?: string // コメントの対象。例：q1 | q2、空の場合もあり
  targetId?: string // 対象のユーザーIDが設定されている場合は、対象の人のみが取得出来る フリーコメント用
  title?: string // 例:安心感 4>2
  message: string
}

export type CreateTeamBuildingAnketInput = {
  teamId: string
  q1?: number
  q2?: number
  q3?: number
  q4?: number
  q5?: number
  q6?: number
  q7?: number
  comment: TeamBuildingAnketCommentInput[]
  freeComment: TeamBuildingAnketCommentInput[]
}

export type Question = {
  value: number
  comment: {
    title: string
    message: string
  }
}

export type FormInput = {
  q1?: Question
  q2?: Question
  q3?: Question
  q4?: Question
  q5?: Question
  q6?: Question
  q7?: Question
  freeComment: {
    targetId?: string
    message?: string
  }
}
