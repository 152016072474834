import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { OutlinedInput } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import UsefulButton from '../../../../components/Button'
import { cookieKeys } from '../static'

import { connector, ContainerProps } from './index.container'

interface IInput {
  id: string
  name: string
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [, setCookie] = useCookies([cookieKeys.name])

  const handleBack = () => {
    props.history.push(pages.KARTE_LEADER_PREPARE)
    window.scrollTo(0, 0)
  }

  // submit
  const { register, handleSubmit, errors } = useForm<IInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data: IInput) => {
    props.onKarteSearch(data.id, () => {
      setCookie(cookieKeys.name, data.name)
      props.history.push(`/karte/leader/questionnaire/${data.id}`)
      window.scrollTo(0, 0)
    })
  }

  const renderContent = () => {
    return (
      <div className="__container__content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="__container__content__title">
            診断するメンバーのカルテNo.と
            <br />
            表示名称を設定してください
          </div>

          <div className="__container__content__body">
            <div className="__container__content__body__title">１. カルテNo.を入力してください。</div>
            <OutlinedInput
              autoComplete="off"
              onChange={() => {
                if (props.isKarteNotFound) {
                  props.onResetError()
                }
              }}
              name="id"
              placeholder="カルテNo."
              className="__container__content__body__input"
              inputRef={register({ required: 'カルテNoは必須です' })}
            />
            {errors.id && <small style={{ color: 'red' }}>{errors.id.message}</small>}
            {props.isKarteNotFound && (
              <small style={{ color: 'red' }}>入力されたNoのカルテは見つかりませんでした</small>
            )}

            <div className="__container__content__body__title">２. メンバーの名前を入力してください。</div>

            <OutlinedInput
              autoComplete="off"
              name="name"
              placeholder="名前"
              className="__container__content__body__input"
              inputRef={register({ required: 'メンバー名は必須です' })}
            />
            {errors.name && <small style={{ color: 'red' }}>{errors.name.message}</small>}
            <div className="__container__content__body__caution">
              ※回答フォームと分析結果ページの表示用ですので、呼称でもなんでも問題ありません。
            </div>
          </div>

          <div className="__container__content__buttons">
            <UsefulButton
              bgColor={constants.COLOR_BLUE}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              body={<div>戻る</div>}
              onClick={handleBack}
            />

            <UsefulButton
              bgColor={constants.COLOR_GRAY}
              textColor={constants.COLOR_BLACK}
              fullWidth={true}
              body={<div>次へ</div>}
              submit={true}
            />
          </div>
        </form>
      </div>
    )
  }

  // TODO: Add loading
  // TODO: Add error

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__header">
            <div style={{ width: '100%', textAlign: 'center' }}>リーダー診察室</div>
          </div>
          {renderContent()}
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 48,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__content': {
          backgroundColor: '#fff',
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px 0',
          },

          '&__body': {
            padding: 16,
            paddingBottom: 32,

            '&__title': {
              fontWeight: 'bold',
              borderBottom: `1px solid ${constants.COLOR_MAIN}`,
              paddingBottom: '4px',
              marginBottom: '4px',
            },

            '&__input': {
              width: '100%',
              background: '#fff',
              height: 40,
              borderRadius: 0,
              marginTop: 16,
              marginBottom: 8,
            },

            '&__caution': {
              fontSize: 10,
            },
          },

          '&__buttons': {
            display: 'flex',
            padding: 16,
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
