import React from 'react'

import { queryGetMasters } from 'pages/teams/hooks/graphql'
import { IceBreak, IceBreakCategory, MasterType, Maybe } from 'utils/generated'

export type CategorizedIceBreak = {
  icaBreak: IceBreak[]
  id: string
  sort: number
  nameShort?: Maybe<string> | undefined
  name?: Maybe<string> | undefined
  nameLong?: Maybe<string> | undefined
  description?: Maybe<string> | undefined
}

export const useIceBreak = () => {
  const limit = 100
  const [iceBreak, setIceBreak] = React.useState<IceBreak[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()
  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMasters({ type: MasterType.TeamBuildingReviewIceBreak, limit })

      if (response) {
        setIceBreak((response.items as IceBreak[]) || [])
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { iceBreak, nextToken, loading, error, refresh } as const
}

export const useIceBreakCategory = () => {
  const limit = 100
  const [iceBreakCategory, setIceBreakCategory] = React.useState<IceBreakCategory[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()
  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMasters({ type: MasterType.TeamBuildingReviewIceBreakCategory, limit })

      if (response) {
        setIceBreakCategory((response.items as IceBreakCategory[]) || [])
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      setError(e.errors)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { iceBreakCategory, nextToken, loading, error, refresh } as const
}

export const useCategorizedIceBreak = () => {
  const { iceBreak } = useIceBreak()
  const { iceBreakCategory } = useIceBreakCategory()
  const categorizedIceBreak: CategorizedIceBreak[] = iceBreakCategory.map((category) => {
    return {
      ...category,
      icaBreak: iceBreak.filter((theme) => theme.parentId === category.id),
    }
  })
  return { categorizedIceBreak } as const
}
