import React, { useContext } from 'react'
import { useState } from 'react'

import Box from '@material-ui/core/Box'

import { Timer } from 'pages/teams/pages/_tools/buildings/components'
import { GhostHistory, GhostMemberRole, GhostTeamMember } from 'utils/generated'

import { ButtonForGhost, SpanBold } from '../components/atoms'
import { GrayBackGround, BackResponsiveCard, Font, LeadText, Flexbox } from '../components/atoms'
import { ButtonContainer, CircularProgressCount, WidthControlContainer } from '../components/modules'
import { ReportBox } from '../components/modules/ReportBox'
import { GhostSawBox } from '../components/organisms'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { PageManagerContext } from '../contexts/pageManger'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'
import { ErrorSnapshot } from '../utils/error'

import * as constGhost from '../assets/constGhost'

export const Report: React.FC = () => {
  const { currentHistory } = useContext(HistoryContext)
  const { reportCategory } = useContext(PageManagerContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { runCreateResearch } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer' })

  const [formState, setFormState] = useState({ 1: '', 2: '' })
  const num: 1 | 2 = reportCategory(teamMember) === 'reason' ? 1 : 2 //1 is reason,2 is solution
  const [err, setErr] = useState<boolean>(false)

  const isLeader = teamMember?.role === GhostMemberRole.Leader

  const onChangeComment = (val: string) => {
    if (val.length > 499) return window.alert('調査報告は500文字以内で入力してください。')
    if (err && formState[1] && formState[2]) {
      setErr(false)
    }
    setFormState({ ...formState, [num]: val })
  }

  const [updating, setUpdating] = useState<boolean>(false)
  const onSubmit = () => {
    const reportData = num === 1 ? formState[1] : formState[2]
    if (!isLeader && !reportData) {
      setErr(true)
      return
    }
    if (updating) return
    setUpdating(true)

    const runUpdate = async () => {
      await runCreateResearch(formState[1], formState[2], undefined, undefined, teamMember)
      setUpdating(false)
    }
    runUpdate()
  }

  if (!teamMember || !(currentHistory && currentHistory.ghostId))
    return <LoadingIndicator snapshot={inspectErrorState(teamMember, currentHistory)} />
  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <Flexbox ownStyles={{ justifyContent: 'flex-start' }}>
            <CircularProgressCount denominator={6} numerator={num === 1 ? 1 : 4} />
            <Font
              fontSize={16}
              bold
              color={constGhost.COLOR_MAIN_NEW}
              ownStyles={{
                letterSpacing: 0.8,
                marginLeft: 16,
              }}
            >
              個人ワーク
            </Font>
          </Flexbox>
          <LeadText>
            {num === 1 ? (
              <>
                メンバーの投票の結果、12体のオバケの中から1体のオバケが選択されました。
                <br />
                メンバーが選んだ下記の「オバケの出没経緯」「オバケのご利益」「オバケの悪さ」を参考にし、
                <SpanBold>オバケが発生したと思われる原因</SpanBold>を考えましょう。
              </>
            ) : (
              <>
                原因を踏まえて、「オバケを退治するアクション例」「オバケがいなくなった時の副作用」を参考にし、
                <SpanBold>今後の対処方法</SpanBold>を考えてください。
              </>
            )}
          </LeadText>
          <GhostSawBox
            ghostId={currentHistory.ghostId}
            num={num}
            bodyDetails={num === 1 ? 'backgroundAndBenefitsAndRisks' : 'actionsAndEffects'}
            ownStyles={{ marginTop: 24 }}
          />
          <ReportBox reportNumber={num} onChange={onChangeComment} formState={formState} />
          {err ? (
            <Box style={{ textAlign: 'center', color: constGhost.COLOR_RED, marginTop: 24, marginBottom: 86 }}>
              {num === 1 ? <>「オバケが発生したと思われる原因」</> : <>「今後の対処方法」</>}
              を記入してください
            </Box>
          ) : (
            <Box style={{ height: 86 }} />
          )}

          <ButtonContainer
            fixed
            buttons={[
              <ButtonForGhost
                key="report"
                buttonBodyColor={'green'}
                bodyText="個人ワーク完了"
                onClick={onSubmit}
                isResizeBasic
              />,
            ]}
          />
        </WidthControlContainer>
      </BackResponsiveCard>

      {team && (
        <Timer
          from={(num === 1 ? team.timerS3Started : team.timerS3Started2) ?? new Date().toISOString()}
          time={num === 1 ? 180 : 240}
          hasGhostStyle
        />
      )}
    </GrayBackGround>
  )
}

const inspectErrorState = (
  teamMember: GhostTeamMember | undefined,
  currentHistory: GhostHistory | undefined
): ErrorSnapshot => {
  const snapshot: ErrorSnapshot = {
    teamId: teamMember?.teamId,
    teamMember: teamMember,
    because: 'undefined',
    vars: { teamMember, currentHistory },
  }
  if (!teamMember) {
    snapshot.because = 'member-not-fetched'
  } else {
    if (!currentHistory) {
      snapshot.because = 'history-not-fetched'
    } else if (currentHistory && !currentHistory.ghostId) {
      snapshot.because = 'history-missing-ghost'
    }
  }
  return snapshot
}
