import React from 'react'

import { queryGetTeamToolUsageStatus } from 'pages/teams/hooks/graphql'
import { TeamTool, TeamToolUsageStatus } from 'utils/generated'

export const useTeamToolUsageStatus = () => {
  const [teamToolUsageStatus, setTeamToolUsageStatus] = React.useState<TeamToolUsageStatus | undefined>()
  const [isLoading, setIsLoading] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()

  const getTeamToolUsageStatus = React.useCallback(
    async (teamTool: TeamTool): Promise<TeamToolUsageStatus | undefined> => {
      setIsLoading(true)
      setErrorMessage(undefined)

      try {
        const response = await queryGetTeamToolUsageStatus({ teamTool })
        setTeamToolUsageStatus(response)
        return response
      } catch (e: any) {
        setErrorMessage(e?.errors[0]?.message || 'データの取得に失敗しました。')
      } finally {
        setIsLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  )

  return { teamToolUsageStatus, isLoading, errorMessage, getTeamToolUsageStatus } as const
}
