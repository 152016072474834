import { queryGetAddressByZipcode } from './graphql'

export const useUtilAddress = () => {
  const getAddresses = async (zipcode?: string) => {
    if (!zipcode) {
      return
    }

    try {
      return await queryGetAddressByZipcode({
        input: {
          zipcode,
        },
      })
    } catch (e) {
      return
    }
  }

  return { getAddresses } as const
}
