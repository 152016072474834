import * as React from 'react'

function LogoWhiteTextComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={142} height={18} viewBox="0 0 142 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M41.4302 17.79C36.5802 17.79 32.4102 14.46 32.4102 9.02C32.4102 3.58 36.5802 0.25 41.4302 0.25C43.5902 0.25 45.7802 0.869999 47.1902 1.89C47.2502 1.93 47.2902 2.01 47.2902 2.08V4.57C47.2902 4.76 47.0702 4.88 46.9202 4.76C45.6202 3.74 43.9202 3 41.8002 3C38.4702 3 36.0002 5.24 36.0002 9.02C36.0002 12.8 38.4702 15.04 41.8002 15.04C43.9202 15.04 45.6202 14.3 46.9202 13.28C47.0702 13.16 47.2902 13.28 47.2902 13.47V15.96C47.2902 16.04 47.2602 16.11 47.1902 16.15C45.7802 17.17 43.5902 17.79 41.4302 17.79Z"
        fill="white"
      />
      <path
        d="M55.6889 17.8711C51.7689 17.8711 48.8789 15.0411 48.8789 11.3711C48.8789 7.70109 51.7689 4.87109 55.6889 4.87109C59.6089 4.87109 62.4689 7.73109 62.4689 11.3711C62.4689 15.0111 59.5789 17.8711 55.6889 17.8711ZM55.6889 7.25109C53.4989 7.25109 52.0789 8.93109 52.0789 11.3711C52.0789 13.8111 53.5389 15.4911 55.6889 15.4911C57.8389 15.4911 59.2789 13.8411 59.2789 11.3711C59.2789 8.90109 57.8489 7.25109 55.6889 7.25109Z"
        fill="white"
      />
      <path
        d="M70.3119 17.7605C66.6719 17.7605 63.9219 15.1305 63.9219 11.3405C63.9219 7.55047 66.6719 4.98047 70.3119 4.98047C72.0019 4.98047 73.3919 5.35047 74.5519 6.11047C74.6219 6.15047 74.6619 6.23047 74.6619 6.31047V8.23047C74.6619 8.42047 74.4519 8.53047 74.2919 8.42047C73.3019 7.71047 72.1619 7.45047 71.0119 7.45047C68.7719 7.45047 67.1719 9.05047 67.1719 11.3405C67.1719 13.6305 68.7719 15.2905 71.0119 15.2905C72.1519 15.2905 73.3119 15.0305 74.2819 14.3105C74.4319 14.2005 74.6519 14.3105 74.6519 14.5005V16.4305C74.6519 16.5105 74.6119 16.5805 74.5519 16.6305C73.3919 17.4205 72.0019 17.7605 70.3119 17.7605Z"
        fill="white"
      />
      <path
        d="M82.9194 17.8711C78.9994 17.8711 76.1094 15.0411 76.1094 11.3711C76.1094 7.70109 78.9994 4.87109 82.9194 4.87109C86.8394 4.87109 89.6994 7.73109 89.6994 11.3711C89.6994 15.0111 86.8094 17.8711 82.9194 17.8711ZM82.9194 7.25109C80.7294 7.25109 79.3094 8.93109 79.3094 11.3711C79.3094 13.8111 80.7694 15.4911 82.9194 15.4911C85.0694 15.4911 86.5094 13.8411 86.5094 11.3711C86.5094 8.90109 85.0794 7.25109 82.9194 7.25109Z"
        fill="white"
      />
      <path
        d="M91.8281 17.1911V0.851093C91.8281 0.721093 91.9281 0.621094 92.0581 0.621094H94.8381C94.9681 0.621094 95.0681 0.721093 95.0681 0.851093V17.1911C95.0681 17.3211 94.9681 17.4211 94.8381 17.4211H92.0581C91.9281 17.4211 91.8281 17.3211 91.8281 17.1911Z"
        fill="white"
      />
      <path
        d="M107.589 17.2007L107.529 16.1007C107.519 15.8907 107.259 15.8107 107.119 15.9607C106.079 17.1407 104.499 17.7507 102.979 17.7507C99.6991 17.7507 97.2891 15.0007 97.2891 11.3607C97.2891 7.7207 99.6991 4.9707 102.979 4.9707C104.499 4.9707 106.079 5.5907 107.119 6.7607C107.259 6.9207 107.509 6.8307 107.529 6.6207L107.589 5.5207C107.589 5.4007 107.699 5.3007 107.819 5.3007H110.309C110.439 5.3007 110.539 5.4007 110.539 5.5307V17.1707C110.539 17.3007 110.439 17.4007 110.309 17.4007H107.819C107.699 17.4007 107.589 17.3007 107.589 17.1807V17.2007ZM103.989 7.3907C102.079 7.3907 100.539 8.8707 100.539 11.3707C100.539 13.8707 102.079 15.3507 103.989 15.3507C105.899 15.3507 107.439 13.8707 107.439 11.3707C107.439 8.8707 105.899 7.3907 103.989 7.3907Z"
        fill="white"
      />
      <path
        d="M121.021 17.7589C119.501 17.7589 117.921 17.1389 116.881 15.9689C116.741 15.8089 116.491 15.8989 116.471 16.1089L116.411 17.2089C116.411 17.3289 116.301 17.4289 116.181 17.4289H113.691C113.561 17.4289 113.461 17.3289 113.461 17.1989V0.858906C113.461 0.728906 113.561 0.628906 113.691 0.628906H116.471C116.601 0.628906 116.701 0.728906 116.701 0.858906V6.37891C116.701 6.58891 116.951 6.68891 117.101 6.53891C118.131 5.51891 119.591 4.99891 121.021 4.99891C124.301 4.99891 126.711 7.7489 126.711 11.3889C126.711 15.0289 124.301 17.7789 121.021 17.7789V17.7589ZM120.011 7.3889C118.111 7.3889 116.561 8.8689 116.561 11.3689C116.561 13.8689 118.101 15.3489 120.011 15.3489C121.921 15.3489 123.461 13.8689 123.461 11.3689C123.461 8.8689 121.921 7.3889 120.011 7.3889Z"
        fill="white"
      />
      <path
        d="M134.951 17.8711C131.031 17.8711 128.141 15.0411 128.141 11.3711C128.141 7.70109 131.031 4.87109 134.951 4.87109C138.871 4.87109 141.731 7.73109 141.731 11.3711C141.731 15.0111 138.841 17.8711 134.951 17.8711ZM134.951 7.25109C132.761 7.25109 131.341 8.93109 131.341 11.3711C131.341 13.8111 132.801 15.4911 134.951 15.4911C137.101 15.4911 138.541 13.8411 138.541 11.3711C138.541 8.90109 137.111 7.25109 134.951 7.25109Z"
        fill="white"
      />
      <path
        d="M3.22 8.93953C3.22 12.0895 5.95 14.6495 9.3 14.6495C10.34 14.6495 11.31 14.3995 12.16 13.9695C12.8 14.8795 13.63 15.6695 14.57 16.2995C13.07 17.2895 11.25 17.8695 9.3 17.8695C4.17 17.8695 0 13.8695 0 8.93953C0 4.00953 4.17 0.0195312 9.3 0.0195312C11.25 0.0195312 13.08 0.609529 14.57 1.59953C16.86 3.10953 18.4 5.57953 18.58 8.39953C18.58 8.54953 18.46 8.68953 18.31 8.68953H15.62C15.48 8.68953 15.37 8.57953 15.36 8.43953C15.18 6.47953 13.93 4.80953 12.17 3.91953C11.32 3.48953 10.34 3.24953 9.3 3.24953C5.95 3.24953 3.22 5.80953 3.22 8.94953V8.93953Z"
        fill="#F2C81D"
      />
      <path
        d="M29.1391 8.95C29.1391 13.88 24.9791 17.87 19.8391 17.87C17.8791 17.87 16.0591 17.29 14.5691 16.3C13.6191 15.68 12.7991 14.89 12.1591 13.97C11.1391 12.54 10.5391 10.81 10.5391 8.94C10.5391 7.07 11.1391 5.34 12.1591 3.9C13.9191 4.79 15.1691 6.47 15.3491 8.42C15.3591 8.56 15.4691 8.67 15.6091 8.67H18.2991C18.4491 8.67 18.5791 8.54 18.5691 8.38C18.3891 5.56 16.8491 3.1 14.5591 1.58C16.0591 0.579998 17.8691 0 19.8291 0C24.9591 0 29.1291 4 29.1291 8.93L29.1391 8.95Z"
        fill="#2EB1BA"
      />
    </svg>
  )
}

export const LogoWhiteText = React.memo(LogoWhiteTextComponent)
