import React, { useEffect } from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { TxInfoTop, TxInfoBottom } from '../../pages/tutorial/assets/text'
import { SubmitButton } from '../button-large'

import { constants } from 'assets'

type Coords = { x: number; y: number }

type TooltipProps = {
  ttRefElm: HTMLElement
  head?: string
  body?: string
  offset: Coords
  center?: boolean
  pointer: TooltipPointer
  display?: 'none' | undefined
  scrollTarget?: 'tooltip'
  autoHeight?: boolean
  msg?: { top?: keyof typeof TxInfoTop; bottom?: keyof typeof TxInfoBottom }
  button?: { text: string; onClick: () => void }
  ownControl?: 'timelineSample' | 'actionAchieved' | 'actionSelect' // handle additional control like events,css...etc
}

type TooltipPointer = {
  position: 'top' | 'bottom'
  offset?: Coords
  display?: 'none' | undefined
}

const tooltipId = 'tooltip'

export const ToolTip = ({
  ttRefElm,
  head,
  body,
  offset,
  pointer,
  center,
  scrollTarget,
  autoHeight,
  msg,
  button,
  ownControl,
}: TooltipProps) => {
  const refRect: DOMRect = ttRefElm.getBoundingClientRect()

  const styles = useTooltipStyle(refRect, offset, pointer, center, autoHeight, button, ownControl)()

  // when tooltip out of sight (especially on sp), scroll
  useEffect(() => {
    if (refRect.y >= window.innerHeight) {
      window.scrollTo({ top: window.innerHeight - 110, behavior: 'smooth' })
    } else if (scrollTarget === 'tooltip') {
      const tooltipRect = document.getElementById(tooltipId)?.getBoundingClientRect()

      window.scrollTo({ top: tooltipRect ? tooltipRect.bottom : 0, behavior: 'smooth' })
    }
  }, [refRect, scrollTarget])

  return (
    <div className={styles.container}>
      {msg?.top ? (
        <div className={styles.msgTop}>
          <img src={require(`./assets/info.svg`).default} alt="information" />
          <span>{TxInfoTop[msg.top]}</span>
        </div>
      ) : (
        <></>
      )}

      <img
        className={styles.pointer}
        src={require(`./assets/${pointer.position}.svg`).default}
        alt={pointer.position}
      />
      <div className={styles.tooltipWrapper}>
        <div className={styles.tooltip} id={tooltipId}>
          <div className={styles.head}>{head}</div>
          <div className={styles.body}>{body}</div>
        </div>
        {msg?.bottom ? (
          <div className={styles.msgBottom}>
            <span>{TxInfoBottom[msg.bottom]}</span>
          </div>
        ) : (
          <></>
        )}
      </div>

      {button ? (
        <div className={styles.button}>
          <SubmitButton placeholder={button.text} onPress={button.onClick} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.grayScreen} />
    </div>
  )
}

const useTooltipStyle = (
  refRect: DOMRect,
  offset: Coords,
  pointer: TooltipPointer,

  center?: boolean,
  autoHeight?: boolean,
  button?: { text: string; onClick: () => void },
  ownControl?: 'timelineSample' | 'actionAchieved' | 'actionSelect'
) =>
  makeStyles((theme: Theme) => {
    const ptOffset = pointer.offset || { x: 0, y: 0 }
    const isOwnControl = ownControl === 'timelineSample' || ownControl === 'actionAchieved'
    return {
      container: {
        zIndex: 100,
        // MAYBE: fade animation here.
      },

      msgTop: {
        position: 'absolute',
        top: refRect.y + window.scrollY - (isOwnControl ? 30 : 20),
        left: refRect.x,
        display: 'flex',
        alignItems: 'center',
        color: constants.TEXT_WHITE,
        fontSize: 14,
        fontWeight: 'bold',
        zIndex: 11500,
        '& span': {
          paddingLeft: 8,
        },
      },

      pointer: {
        position: 'absolute',

        // defaults to the center of the ref. provide ptOffset if it needs to be moved.
        left: refRect.x + refRect.width / 2 - 8 + ptOffset.x,

        // defaults to jsut below or above the ref. ptOffset.y to adjust.
        top:
          pointer.position === 'top'
            ? refRect.y + window.scrollY + refRect.height + ptOffset.y
            : refRect.y + window.scrollY - 10 + ptOffset.y,

        width: 16,
        height: 10,
        zIndex: pointer.display === 'none' ? -10 : 11500,
      },

      tooltipWrapper: {
        position: 'absolute',
        left: center ? refRect.x + refRect.width / 2 - 151 + offset.x : refRect.x + offset.x,
        top:
          pointer.position === 'top'
            ? refRect.y + window.scrollY + refRect.height + 10 + offset.y
            : refRect.y + window.scrollY - 110 + offset.y,
        paddingBottom: button ? 110 : 0, // for bottom button space
        zIndex: 11400,
      },

      tooltip: {
        width: center ? 303 : undefined,
        maxWidth: center ? undefined : 528,
        height: autoHeight ? undefined : 100,

        backgroundColor: constants.COLOR_TOOLTIP_CONTENT,
        marginRight: 16,
        padding: 16,
        borderRadius: 8,
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
      },

      head: {
        color: constants.COLOR_MAIN_NEW,
        fontSize: 14,
        fontWeight: 'bold',
      },

      body: {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
        lineHeight: '24px',
        marginTop: 3,
        height: autoHeight ? undefined : 38, // 24 + 14
        whiteSpace: 'pre-wrap',
      },

      msgBottom: {
        margin: '8px 16px 0 0',
        color: constants.TEXT_WHITE,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'right',
        zIndex: 11500,
      },

      button: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 80,
        backgroundColor: constants.COLOR_WHITE,
        zIndex: 11600,
        '& button': {
          width: 'calc(100% - 32px)',
          [theme.breakpoints.up('sm')]: {
            width: 318,
          },
        },
      },

      grayScreen: {
        opacity: 0.7,
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: constants.TEXT_GRAY_DARK,
        height: '100vh',
        width: '100vw',
      },
    }
  })
