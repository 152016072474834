import * as constants from 'assets/constants'

export const requierdMarkStyles = {
  content: '"必須"',
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: 9,
  color: constants.COLOR_WHITE,
  backgroundColor: constants.COLOR_ALERT,
  borderRadius: 4,
  padding: 3,
  lineHeight: 1,
}
export const selectArrowIconStyles = {
  //親要素にposition: relativeをつける
  content: '""',
  width: 10,
  height: 10,
  display: 'block',
  borderTop: `solid 2px ${constants.TEXT_GRAY_DARK}`,
  borderRight: `solid 2px ${constants.TEXT_GRAY_DARK}`,
  transform: 'rotate(135deg)',
  position: 'absolute',
  top: 17,
  right: 18,
  margin: 'auto',
  pointerEvents: 'none',
}
