import * as React from 'react'

import { Box } from '@material-ui/core'

import AlertDialog from 'components/AlertDialog'
import Button from 'components/Button'
import { ICreateHintParams } from 'pages/hint/_shared/stores/apis/createHint'

import { IuseDialogSuspension } from './useDialogSuspension'

import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseDialogSuspension
  params: ICreateHintParams
}

// -----------------------------
// Component
// -----------------------------
export const DialogSuspension = (props: IProps) => {
  return (
    <AlertDialog
      open={props.presenter.getOpen()}
      failure={true}
      onClose={props.presenter.onCloseDialog}
      body={<p>記事の作成を中断し、下書きに保存します。</p>}
      footer={
        <Box width="100%">
          <Button
            bgColor={constants.COLOR_SECOND}
            textColor={constants.TEXT_BLACK}
            fullWidth={true}
            onClick={() => props.presenter.onDoSuspensionHandler(props.params)}
            body={<div>はい</div>}
          />
        </Box>
      }
    />
  )
}
