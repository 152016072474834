import React from 'react'
import { useParams } from 'react-router-dom'

import { Theme, makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

type Props = {
  answeredAt?: string
}

export const DisplayId: React.FC<Props> = ({ answeredAt }) => {
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const isXsDown = useCustomMediaQuery('down', 'xs')
  const classes = useStyles()
  const currentUrl = window.location.href
  const urlParams = useParams<{ id: string }>()
  const [buttonlabel, setButtonLabel] = React.useState<'URLをコピー' | 'コピーしました'>('URLをコピー')

  const answeredAtDate = new Date(answeredAt ?? '')
  // 閲覧可能期間が30日なので、回答日時から29日後の日付を取得
  const expirationDate = answeredAtDate.setDate(answeredAtDate.getDate() + 29)
  const expirationDateTime = format(expirationDate, 'yyyy年MM月dd日 23:59')

  const onCopy = async () => {
    // iOSの仕様上、localhostかhttpで実行した場合、エラーが発生する
    await navigator.clipboard.writeText(currentUrl)
    setButtonLabel('コピーしました')
  }

  // onClickでコピーした際に、ボタンのラベルを元に戻す
  React.useEffect(() => {
    if (buttonlabel === 'コピーしました') {
      const timer = setTimeout(() => {
        setButtonLabel('URLをコピー')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [buttonlabel])

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        あなたの診断IDは、
        <br />
        <strong className={classes.id}>{urlParams.id}</strong>
        {isSmDown && <br />}
        です。
      </div>
      <div className={classes.body}>
        <div className={classes.deadline}>
          診断IDの有効期限：
          {isXsDown && <br />}
          {expirationDateTime}まで
        </div>
        <div>
          <p className={classes.description}>
            診断日から30日間は診断ページTOPから、診断IDを使って、結果を閲覧することができます。
            <br />
            または、以下のURLにアクセスすることでも閲覧できます
          </p>
        </div>
        <div className={classes.urlContainer}>
          <div className={classes.url}>{currentUrl}</div>
          <button disabled={buttonlabel === 'コピーしました'} onClick={() => onCopy()}>
            {buttonlabel}
          </button>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 16,
      padding: 40,
      backgroundColor: '#fff',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        padding: 24,
      },
    },

    heading: {
      color: '#000',
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      marginBottom: 32,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 16,
      },
    },

    id: {
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 30,
      fontWeight: 'bold',
      wordWrap: 'break-word',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },

    body: {
      width: '100%',
      maxWidth: 650,
    },

    deadline: {
      marginBottom: 16,
      padding: '8px 16px',
      backgroundColor: constants.COLOR_GRAY,
      fontSize: 14,
      textAlign: 'center',
    },

    description: {
      margin: 0,
      marginBottom: 24,
      lineHeight: '24px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 16,
      },
    },

    urlContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
      padding: '4px 4px 4px 24px',
      backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT3,
      [theme.breakpoints.down('sm')]: {
        padding: 4,
      },

      '& button': {
        width: 120,
        height: 32,
        flexShrink: 0,
        backgroundColor: constants.COLOR_BLUE_DARK2,
        border: 'none',
        borderRadius: 4,
        cursor: 'pointer',
        color: '#fff',
        fontSize: 14,
        fontWeight: 'bold',
      },
    },

    url: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
    },
  }),
  { name: 'DisplayId' }
)
