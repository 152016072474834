import { TeamStatusAdmin } from 'utils/generated'

export const statusPaymentLabel = (status?: TeamStatusAdmin | null) => {
  switch (status) {
    case TeamStatusAdmin.Confirming:
      return '確認中'
    case TeamStatusAdmin.Active:
    case TeamStatusAdmin.NonActive:
      return '承認済'
    case TeamStatusAdmin.Banned:
      return '利用禁止'
    default:
      return ''
  }
}
