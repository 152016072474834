import * as React from 'react'

import { Modal as MuiModal, Paper, Fade, Backdrop } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { ButtonNew as Button } from 'components/ButtonNew'

import resetErrorSvg from '../assets/reset_error.svg'
import * as constants from 'assets/constants'

type Props = {
  open: boolean
  msg: { main: string; sub?: string }
  firstBtnBody: string
  firstBtnHandleClick: () => void
}

export const ErrorModal = ({ open, msg, firstBtnBody, firstBtnHandleClick }: Props) => {
  const classes = useStyles()

  return (
    <MuiModal
      open={open}
      disableAutoFocus={true}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: 'rgba(51, 51, 51, 0.7' },
      }}
    >
      <Fade in={open}>
        <Paper className={classes.modalPaper}>
          <div className={classes.centeredContent}>
            <img src={resetErrorSvg} alt="resetErrorSvg" />
          </div>
          <div className={classes.msgWrapper}>
            <p className={classes.modalMsg}>{msg.main}</p>
          </div>
          {msg.sub ? <div className={classes.modalMsgSub}>{msg.sub}</div> : <></>}
          <div className={classes.buttonContainer}>
            <div className={classes.fullButton}>
              <Button
                body={firstBtnBody}
                onClick={firstBtnHandleClick}
                textColor={constants.COLOR_TEAMBUILDING_NEUTRAL_400}
                borderColor={constants.COLOR_TEAMBUILDING_NEUTRAL_400}
                noShadow
              />
            </div>
          </div>
        </Paper>
      </Fade>
    </MuiModal>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalPaper: {
      position: 'relative',
      padding: '40px 26px 26px',
      width: '95%',
      maxWidth: 350,
      borderRadius: 8,
      '&:focus': {
        outline: 'none',
      },
    },
    centeredContent: {
      textAlign: 'center',
      height: 48,
    },
    msgWrapper: {
      '& p:last-of-type': {
        marginBottom: 10,
      },
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
    fullButton: {
      width: '100%',
    },
    modalMsg: {
      color: constants.COLOR_TEAMBUILDING_RED,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 16,
      '& p': {
        marginTop: '0',
        marginBottom: '0',
      },
      '& p:last-of-type': {
        marginBottom: '16',
      },
    },
    modalMsgSub: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
      whiteSpace: 'pre-wrap',
      '& p': {
        marginTop: '0',
        marginBottom: '0',
      },
    },
  }),
  { name: 'ErrorModal' }
)
