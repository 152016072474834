import * as React from 'react'

import { COLOR_MAIN, COLOR_SECOND } from '../../../../assets/constants'
import Radar, { IRadarProps } from '../../../../components/radar/Radar'

import { connector, ContainerProps } from './Radar.container'

interface IOwnProps {
  week: number
}

type Props = IOwnProps & ContainerProps

const Index: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    if (props.setup.currentSetupStartedAt) {
      props.onMount(props.week, props.setup.currentSetupStartedAt)
    } else {
      props.onMount(props.week)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.week, props.setup.currentSetupStartedAt])

  const [chartData, setChartData] = React.useState<IRadarProps | null>(null)

  React.useEffect(() => {
    if (props.setup && props.setup.radar) {
      const reaction = props.setup.radar.reaction
      const reactionVal = [
        Math.round(reaction.vision * 10) / 10,
        Math.round(reaction.other * 10) / 10,
        Math.round(reaction.boss * 10) / 10,
        Math.round(reaction.team * 10) / 10,
        Math.round(reaction.member * 10) / 10,
      ]
      const anket = props.setup.radar.anket
      const anketVal = [
        Math.round(anket.vision * 10) / 10,
        Math.round(anket.other * 10) / 10,
        Math.round(anket.boss * 10) / 10,
        Math.round(anket.team * 10) / 10,
        Math.round(anket.member * 10) / 10,
      ]

      const data = [
        {
          val: reactionVal,
          color: COLOR_SECOND,
        },
        {
          val: anketVal,
          color: COLOR_MAIN,
        },
      ]

      setChartData({ data })
    }
  }, [props.setup])

  return <Radar size={300} chartData={chartData && chartData} />
}

export default connector(Index)
