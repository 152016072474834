import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '260px',
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 3,
    border: `solid 1px ${constants.COLOR_MAIN}`,
    padding: '24px 16px',
    position: 'relative',
    zIndex: 0,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const Sidebar = (props: IProps) => {
  const classes = useStyles()

  return <aside className={classes.root}>{props.children}</aside>
}
