import React from 'react'

import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js'
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'
import { Chart as ReactChart } from 'react-chartjs-2'
import { TeamBuildingQuestionTitle } from 'stores/anket/types'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { TeamAnketBuildingSummary } from 'utils/generated'

import {
  COLOR_TEAMBUILDING_PRIMARY,
  COLOR_TEAMBUILDING_RED2,
  COLOR_TEAMBUILDING_TEXT,
  COLOR_GRAY_DARK,
  COLOR_TEAMBUILDING_NEUTRAL_500,
  COLOR_WHITE2,
  ONBOARDING_FONT_FAMILY,
} from 'assets/constants'
ChartJS.register(...registerables)
type Props = {
  currentAnket: TeamAnketBuildingSummary
  previousAnket?: TeamAnketBuildingSummary
}

const roundForChartValues = (values: (number | null | undefined)[]) => {
  return values.map((value) => (typeof value === 'number' ? Math.round(value) : value))
}

const getColorFromValue = (v: number | null | undefined) => {
  return typeof v === 'number' && v >= 0 ? COLOR_TEAMBUILDING_PRIMARY : COLOR_TEAMBUILDING_RED2
}

const getLabelPositionFromValue = (v: number | null | undefined) => {
  return typeof v === 'number' && v >= 0 ? 'end' : 'start'
}

const AnketBuildingSummary: React.FC<Props> = ({ currentAnket, previousAnket }) => {
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const isXsDown = useCustomMediaQuery('down', 'xs')

  // Chart
  const currentValue = React.useMemo(() => {
    return currentAnket
      ? [
          currentAnket.averageQ3,
          currentAnket.averageQ1,
          currentAnket.averageQ2,
          currentAnket.averageQ4,
          currentAnket.averageQ5,
          currentAnket.averageQ6,
          // currentAnket.averageQ7,
        ]
      : []
  }, [currentAnket])

  const previousList = React.useMemo(() => {
    return previousAnket
      ? [
          {
            label: '前回の項目別評価',
            data: roundForChartValues([
              previousAnket.averageQ3,
              previousAnket.averageQ1,
              previousAnket.averageQ2,
              previousAnket.averageQ4,
              previousAnket.averageQ5,
              previousAnket.averageQ6,
              // previousAnket.averageQ7,
            ]),
            barPercentage: isSmDown ? 0.7 : 0.9,
            categoryPercentage: 0.4,
            backgroundColor: '#ccc',
          },
        ]
      : []
  }, [previousAnket, isSmDown])

  const currentData = React.useMemo(
    () => ({
      labels: [
        TeamBuildingQuestionTitle.q3,
        TeamBuildingQuestionTitle.q1,
        TeamBuildingQuestionTitle.q2,
        TeamBuildingQuestionTitle.q4,
        TeamBuildingQuestionTitle.q5,
        TeamBuildingQuestionTitle.q6,
      ],
      datasets: [
        ...previousList,
        {
          label: '項目別評価',
          data: roundForChartValues(currentValue),
          // previousList なし
          //  → バーの幅が広くなりすぎるので、barThickness を指定。
          // previousList あり
          //  → barThickness を undefined にして barPercentage と categoryPercentage を有効化。
          //  → それによって、前回のスコアと今回のスコアの間に余白をつくる。
          barThickness: previousList.length ? undefined : isSmDown ? 8 : 24,
          barPercentage: isSmDown ? 0.7 : 0.9,
          categoryPercentage: 0.4,
          backgroundColor: currentValue.map(getColorFromValue),
        },
      ],
    }),
    [currentValue, previousList, isSmDown]
  )

  const barOptions: ChartOptions = {
    layout: {
      // left のマイナス値は y.ticks.padding に対する調整
      padding: isSmDown ? 0 : { right: 16, left: -20 },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        anchor: (ctx: Context) =>
          getLabelPositionFromValue(ctx.dataset.data?.[ctx.dataIndex] as number | null | undefined),
        align: (ctx: Context) =>
          getLabelPositionFromValue(ctx.dataset.data?.[ctx.dataIndex] as number | null | undefined),
        color: (ctx: Context) =>
          ctx.dataset.label === '前回の項目別評価'
            ? `${COLOR_GRAY_DARK}80`
            : getColorFromValue(ctx.dataset.data?.[ctx.dataIndex] as number | null | undefined),
        font: {
          family: ONBOARDING_FONT_FAMILY,
          size: isSmDown ? 8 : 14,
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            family: ONBOARDING_FONT_FAMILY,
            size: isSmDown ? 10 : 12,
          },
          color: COLOR_TEAMBUILDING_TEXT,
        },
      },
      xAxis2: {
        type: 'category',
        labels: ['成果面', '環境面', '充実感'],
        offset: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          color: COLOR_TEAMBUILDING_PRIMARY,
          align: 'center',
          padding: 12,
          showLabelBackdrop: true,
          backdropColor: isSmDown ? COLOR_WHITE2 : '#fff',
          backdropPadding: isXsDown
            ? { top: 4, right: 24, bottom: 4, left: 24 }
            : { top: 4, right: 46, bottom: 4, left: 46 },
          font: {
            family: ONBOARDING_FONT_FAMILY,
            size: isSmDown ? 10 : 12,
          },
        },
      },
      y: {
        min: -5,
        max: 5,
        ticks: {
          color: ({ tick }) => {
            if (tick.value > 0) {
              return COLOR_TEAMBUILDING_PRIMARY
            }
            if (tick.value < 0) {
              return COLOR_TEAMBUILDING_RED2
            }
            return COLOR_TEAMBUILDING_TEXT
          },
          font: {
            family: ONBOARDING_FONT_FAMILY,
            size: isSmDown ? 8 : 14,
            weight: 'bold',
          },
          padding: isSmDown ? 2 : 24,
          stepSize: 1,
          callback: (value) => {
            if (typeof value === 'number' && value % 5 === 0) {
              return value > 0 ? `+${value}` : value
            } else {
              return ''
            }
          },
        },
        grid: {
          color: (ctx) =>
            ctx.tick.value === 0 ? COLOR_TEAMBUILDING_NEUTRAL_500 : `${COLOR_TEAMBUILDING_NEUTRAL_500}4D`,
          lineWidth: (ctx) => (ctx.tick.value === 0 || ctx.tick.value === 5 || ctx.tick.value === -5 ? 1.4 : 0.7),
          drawBorder: false,
        },
        offset: true,
      },
    },
    maintainAspectRatio: false,
  }

  return <ReactChart type="bar" data={currentData} options={barOptions} plugins={[ChartDataLabels]} />
}

export default AnketBuildingSummary
