import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { HorizontalLine } from './HorizontalLine'

type Props = {
  children: React.ReactNode
}

export const ResultFramework: React.FC<Props> = ({ children }) => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  return (
    <div className={classes.root}>
      <HorizontalLine style={{ marginTop: isSmDown ? 16 : 24, marginBottom: isSmDown ? 24 : 40 }} />
      {children}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        marginBottom: 16,
      },
    },
  }),
  { name: 'ResultFramework' }
)
