import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  loading: boolean
  size?: number
  wrapperStyles?: React.CSSProperties
  circularStyles?: React.CSSProperties
}

export const LoadingCircular: React.FC<Props> = ({ loading, size, wrapperStyles, circularStyles }) => {
  const classes = useStyles()

  if (!loading) return null

  return (
    <div className={classes.root} style={wrapperStyles}>
      <CircularProgress className={classes.progress} style={circularStyles} size={size ?? 20} />
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 50,
    },
    progress: {
      color: constants.COLOR_MAIN_NEW,
    },
  }),
  { name: 'LoadingCircular' }
)
