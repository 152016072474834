import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { useContext } from 'react'

import { CircularProgress, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import { FormCharCount } from 'pages/onboarding/components/formCharCount'
import { MediaGrid } from 'pages/onboarding/components/timeline/MediaGrid'
import { OnboardingTeamMember } from 'utils/generated'

import { AttachmentFileBox } from '../../../components/timeline/AttachmentFIleBox'
import { AttachmentImgBox } from '../../../components/timeline/AttachmentImgBox'
import { useMentions } from '../../../hooks/mentions'
import { FILE_MIME, IMG_MIME, TeamStorageContext, VIDEO_MIME } from '../hooks/teamStorage'

import addFileIcon from '../assets/addFileIcon.svg'
import crossIcon from '../assets/crossIcon.svg'
import pictureIcon from '../assets/pictureIcon.svg'
import * as constants from 'assets/constants'

interface IInput {
  newPostText: string
}

const FILE_ACCEPT_MIME = FILE_MIME.join(',')
const IMG_ACCEPT_MIME = IMG_MIME.concat(VIDEO_MIME).join(',')
const ALL_ACCEPT_MIME = [FILE_MIME, IMG_MIME, VIDEO_MIME].join(',')

type Props = {
  isNewPostModalOpen: boolean
  popupClose: () => void
  createPost?: (
    comment: string,
    teamMember: OnboardingTeamMember,
    fileNames?: string[],
    id?: string,
    mentions?: string[]
  ) => Promise<void>
  teamMember: OnboardingTeamMember
  teamMemberList: OnboardingTeamMember[]
}

export const NewPostModal: React.FC<Props> = ({
  isNewPostModalOpen,
  popupClose,
  createPost,
  teamMember,
  teamMemberList,
}) => {
  const classes = useStyles()
  const { userFiles, userImgs, executeUpload, handleFilePick, resetUpload, removeUpload } =
    useContext(TeamStorageContext)
  const { register, watch, setValue, handleSubmit, reset } = useForm<IInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const formTextValue: string = watch('newPostText') ?? ''
  const element = document.getElementById('add-comment-input') as HTMLInputElement

  const {
    checkCorrectMentions,
    clearMentions,
    SuggestBox,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
    convertMentionsStylesForInput,
  } = useMentions<keyof IInput>('newPostText', formTextValue, teamMemberList, setValue, element)

  const onKeyDown = (e: KeyboardEvent) => {
    openSuggestionByTypeText(e)
  }
  useEffect(() => {
    closeSuggestionByTypeText()
  }, [formTextValue, closeSuggestionByTypeText])

  const [uploading, setUploading] = useState(false)

  const fileInput = useRef<HTMLInputElement>(null)
  const imgInput = useRef<HTMLInputElement>(null)

  // for some reason only use fileInput when pc
  const pcUpload = window.innerWidth > 780

  // removes <input /> FileList, so that it listens change again
  const resetInput = (input: 'file' | 'img') => {
    if (fileInput.current && (input === 'file' || pcUpload)) {
      fileInput.current.value = ''
    } else if (input === 'img' && imgInput.current) {
      imgInput.current.value = ''
    }
  }

  const onSubmit = async () => {
    if (!createPost) return
    setUploading(true)
    const comment = formTextValue
    const res = await executeUpload()
    const mentions = await checkCorrectMentions()
    await createPost(comment, teamMember, res?.uploadedFileNames, res?.postId, mentions)
    resetUpload()
    resetInput('img')
    resetInput('file')
    reset()
    popupClose()
    setUploading(false)
    clearMentions()
  }

  const pickFiles = (input: 'file' | 'img') => {
    if (input === 'file') {
      fileInput.current && fileInput.current.click()
    } else {
      imgInput.current && imgInput.current.click()
    }
  }

  const cancel = (input: 'file' | 'img', fileName: string) => {
    resetInput(input)
    removeUpload(fileName)
  }

  return (
    <div className={classes.root} style={isNewPostModalOpen ? {} : { display: 'none' }}>
      <div className={classes.newPostModal}>
        <img className={classes.crossIcon} src={crossIcon} alt="close" onClick={popupClose} />
        <div className={classes.newPostTitle}>新規投稿</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formBox}>
            <div
              className={classes.textareaBack}
              dangerouslySetInnerHTML={{ __html: convertMentionsStylesForInput() }}
            />
            <textarea
              id="add-comment-input"
              name="newPostText"
              className={classes.textarea}
              placeholder={'投稿内容をこちらに書いてください'}
              onKeyDown={onKeyDown as unknown as KeyboardEventHandler<HTMLTextAreaElement>}
              autoFocus
              rows={Math.max(formTextValue.split('\n').length, 8)}
              required
              maxLength={300}
              ref={register}
            />
            <SuggestBox />
            <div style={{ padding: 8 }}>
              {userFiles.map((item, i) => (
                <AttachmentFileBox
                  key={`file${i}-${item.name}`}
                  fileName={item.name}
                  boxType={'post'}
                  cancel={() => cancel('file', item.name)}
                />
              ))}
            </div>
            <div className={classes.attachments}>
              <MediaGrid>
                {userImgs.map((item, i) => (
                  <AttachmentImgBox
                    key={`img${i}-${item.name}`}
                    src={URL.createObjectURL(item)}
                    mime={item.type}
                    boxType={'post'}
                    cancel={() => cancel('img', item.name)}
                    onClick={() => undefined}
                  />
                ))}
              </MediaGrid>
            </div>
          </div>

          <div className={classes.iconBox}>
            <img className={classes.addFileIcon} src={addFileIcon} alt="fileUpload" onClick={() => pickFiles('file')} />
            <input
              ref={fileInput}
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={(e) => handleFilePick(e.target.files)}
              accept={pcUpload ? ALL_ACCEPT_MIME : FILE_ACCEPT_MIME}
            />
            {!pcUpload && (
              <>
                <img
                  className={classes.pictureIcon}
                  src={pictureIcon}
                  alt="imgUpload"
                  onClick={() => pickFiles('img')}
                />
                <input
                  ref={imgInput}
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  onChange={(e) => handleFilePick(e.target.files)}
                  accept={IMG_ACCEPT_MIME}
                />
              </>
            )}
          </div>
          <FormCharCount length={formTextValue.length} maxLength={300} />
          {uploading ? (
            <CircularProgress size={16} style={{ marginTop: 8 }} />
          ) : (
            <Button
              body={'投稿する'}
              submit
              style={{
                margin: '32px 0 0 0',
                width: 318,
                height: 48,
                borderRadius: 8,
                background: constants.COLOR_WHITE,
                border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
                boxShadow: 'none',
                color: constants.COLOR_ONBOARDING_MAIN,
              }}
            />
          )}
        </form>
        <div className={classes.cancelText} onClick={popupClose}>
          キャンセル
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#0000003E',
    width: '100vw',
    height: '100vh',
    overflowY: 'hidden',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1300,
  },
  newPostModal: {
    width: 350,
    borderRadius: 10,
    padding: '16px 16px 26px',
    position: 'absolute',
    background: constants.COLOR_WHITE,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    maxHeight: '90vh',
    overflowY: 'scroll',
  },
  crossIcon: {
    display: 'block',
    margin: '0 0 0 auto',
    cursor: 'pointer',
    opacity: 0.9,
  },
  formBox: {
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    margin: '29px 0 0 0',
    padding: '0 0 10px 0',
    //for mentions
    position: 'relative',
  },
  textareaBack: {
    // for use mention highlight
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'transparent',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    overflow: 'hidden',
    margin: '10px 16px',
    // for same as textarea
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: 1.6,
  },
  textarea: {
    resize: 'none',
    border: 'none',
    width: '100%',
    height: '100%',
    padding: '10px 16px 0',
    // for mentions
    background: 'transparent',
    position: 'relative',
    zIndex: 2,
    // for same as textareaBack
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: 1.6,
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  },
  attachments: {
    maxHeight: 400,
    padding: '0 8px',
    overflow: 'auto',
  },
  newPostTitle: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontWeight: 600,
    fontSize: 14,
    margin: '16px auto 0',
  },
  newPostTextBox: {
    color: constants.COLOR_GRAY_DARK,
    fontWeight: 300,
    fontSize: 14,
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },

  iconBox: {
    display: 'flex',
    alignItems: 'center',
  },
  addFileIcon: {
    margin: '24px 0 0',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  pictureIcon: {
    margin: '24px 0 0 32px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  cancelText: {
    fontWeight: 600,
    fontSize: 12,
    cursor: 'pointer',
    margin: '26px 0 0',
    color: '#707070',
  },
}))
