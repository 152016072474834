import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { MuiModal } from 'components/Modal'
import { NoStyleButton } from 'components/NoStyleButton'

import { DiagnosisResult } from './DiagnosisResult'

import { constants } from 'assets'

export const ResultSearchModal: React.FC = () => {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={classes.modalButtonWrapper}>
        <NoStyleButton type="button" className={classes.modalButton} onClick={handleModalOpen}>
          <span>過去に診断した{'\n'}結果はこちら</span>
          <img className={classes.arrow} src={`${process.env.PUBLIC_URL}/img/hataraku/arrow-right.svg`} alt="" />
        </NoStyleButton>
      </div>

      <MuiModal isModalOpen={isModalOpen} handleModalClose={handleModalClose}>
        <div className={classes.modalPaper}>
          <DiagnosisResult handleClose={handleModalClose} />
        </div>
      </MuiModal>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    modalButtonWrapper: {
      position: 'sticky',
      bottom: 90,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 90,
      pointerEvents: 'none',
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        bottom: 16,
        paddingRight: 16,
      },
    },
    modalButton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 8,
      width: 122,
      height: 122,
      margin: '0 0 6px',
      padding: 4,
      background: constants.COLOR_SECOND,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 1.4,
      borderRadius: '50%',
      boxShadow: `0px 6px 0px 0px ${constants.TEXT_GRAY_DARK}`,
      whiteSpace: 'pre-wrap',
      pointerEvents: 'auto',
      '@media (hover: hover)': {
        '&:hover': {
          background: '#FDD933',
        },
      },
      '&:active': {
        margin: '6px 0 0',
        boxShadow: 'none',
      },
    },
    arrow: {
      width: 24,
    },
    modalPaper: {
      width: '100%',
      maxWidth: 1000,
      height: 172,
      background: '#fff',
      color: constants.TEXT_GRAY_DARK,
      borderRadius: 8,
      boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.16)',
      '&:focus': {
        outline: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        height: 200,
      },
    },
  }),
  { name: 'ResultSearchModal' }
)
