import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

export const Header: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <img
        className={classes.headerLogo}
        src={process.env.PUBLIC_URL + '/assets/svg/teamKarte/logo_karte.svg'}
        alt="Cocolabo カルテ"
      />
    </header>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    height: '48px',
    padding: '0 16px',
    backgroundColor: '#fff',
  },
  headerLogo: {
    width: '140px',
  },
}))
