import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'
import { IConsultationBase } from '../models/consultation'

// -----------------------------
// Params
// -----------------------------
export type IConsultationListParams = {
  isPublished: number
  nextToken: string
}

// -----------------------------
// Result
// -----------------------------
export type IConsultationListResult = {
  items: IConsultationBase[]
  nextToken: string
}

export const getConsultationList = async (params: IConsultationListParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getConsultationList,
      variables: {
        filter: { isPublished: params.isPublished },
        first: 6,
        after: params.nextToken,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return {
      items: [...res?.data?.getConsultationList?.items],
      nextToken: res?.data?.getConsultationList?.nextToken,
    }
  } catch (e) {
    throw e
  }
}
