import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { Modal } from '@material-ui/core'

import * as constants from '../../../assets/constants'

type ToolName = 'teamBuilding' | 'onboarding'

interface Props {
  toolName: ToolName
  version: number
  userId?: string
  isActive: boolean
}

export const ModalInfomation: React.FC<Props> = ({ toolName, version, userId, isActive }) => {
  const key = `${toolName}_${userId}_${version}`
  const prev = `${toolName}_${userId}_${version - 1}`

  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (open || !userId || !isActive) return

    const storage = localStorage.getItem(key)

    if (!storage) {
      setOpen(true)
      localStorage.setItem(key, userId)
      localStorage.removeItem(prev)
    }
  }, [open, key, prev, userId, toolName, isActive])

  return (
    <>
      <Modal
        open={open}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 16,
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(51,51,51, 0.7)' } }}
        onClose={() => setOpen(false)}
      >
        <div className={classes.root}>
          <img
            className={classes.close}
            src={process.env.PUBLIC_URL + '/assets/svg/close_message.svg'}
            alt={'close'}
            onClick={() => setOpen(false)}
          />
          <div className={classes.title}>機能改善のお知らせ</div>
          <div style={{ overflowY: 'hidden' }}>
            <img
              style={{ marginBottom: -100 }}
              src={process.env.PUBLIC_URL + '/assets/svg/infomation.svg'}
              alt={'infomation'}
            />
          </div>
          <div className={classes.content}>
            <div style={{ marginBottom: 23, fontSize: 14, fontWeight: 'bold' }}>一部機能をアップデートしました</div>
            <div style={{ fontSize: 12 }}>
              不明点はお問い合わせください。
              <br />
              <a
                style={{ color: constants.COLOR_MAIN_NEW }}
                href="https://cocolabo.club/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://cocolabo.club/contact
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    padding: 40,
    textAlign: 'center',
    position: 'relative',
    borderRadius: 8,
    backgroundColor: constants.COLOR_WHITE,
    outline: 'none',
  },
  close: {
    width: 12,
    height: 12,
    position: 'absolute',
    top: 14,
    right: 14,
    cursor: 'pointer',
  },
  title: {
    backgroundColor: constants.COLOR_MAIN_NEW,
    color: constants.COLOR_WHITE,
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 30,
    padding: '14px 0',
    marginBottom: 40,
  },
  content: {
    backgroundColor: constants.COLOR_WHITE2,
    boxSizing: 'border-box',
    paddingTop: 23,
    paddingBottom: 20,
    borderRadius: 8,
  },
}))
