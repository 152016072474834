import * as React from 'react'
import { Link, RouteComponentProps, withRouter, useLocation } from 'react-router-dom'

import {
  AppBar,
  Box,
  createStyles,
  StyleRules,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import classNames from 'classnames'

import HeaderAdditionalActions from 'components/header/HeaderAdditionalActions.component'
import { useHeader } from 'components/header/useHeader'
import Logo from 'components/icon/Logo'
import SignoutButtonNew from 'components/SignoutButtonNew.container'
import { TopButton } from 'components/TopButton'
import { queryGetOnboardingOwnTeamMemberList } from 'pages/onboarding/graphql'
import { queryGetTeams, queryGetTeamMember } from 'pages/teams/hooks/graphql'
import { OnboardingTeam, OnboardingTeamMemberStatus, OnboardingUsageStatus, Team } from 'utils/generated'

import { connector, ContainerProps } from './Header.container'
import { useIsTeam } from './hooks/useIsTeam'

import * as constants from 'assets/constants'
import * as pages from 'assets/pages'

interface IOwnProps {
  title?: string
  routeName?: string
}

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Header: React.FC<Props> = (props) => {
  const { classes, location } = props
  const { logoTitle } = useHeader({ location })
  const { teams } = useIsTeam(props.user.fetched, props.user.id)
  const [openMenu, setOpenMenu] = React.useState(false)
  const [isScrolled, setIsScrolled] = React.useState(true)
  const [touchedMenuState, setTouchedMenuState] = React.useState(false)

  const theme = useTheme()
  const isBreakPointsUp = useMediaQuery(theme.breakpoints.up('md'))

  const { pathname } = useLocation()
  const isNarrowShadow = pathname === '/' && !isBreakPointsUp

  const handleMenu = () => {
    const nextOpenMenu = !openMenu
    setOpenMenu(nextOpenMenu)
    if (nextOpenMenu) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = ''
    }
    setTouchedMenuState(true)
  }

  const signOutCallback = () => {
    props.history.push(constants.PAGE_TOP)
    window.scrollTo(0, 0)
    setOpenMenu(false)
    document.body.style.overflowY = ''
  }

  React.useEffect(() => {
    // 新規登録後の確認コード入力画面からの遷移に対応するために、fetched が false だけでなく、id が false でも onMount を実行。
    if (!props.user.fetched || !props.user.id) {
      props.onMount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  React.useEffect(() => {
    window.scrollY !== 0 ? setIsScrolled(true) : setIsScrolled(false)
  }, [])

  window.addEventListener('scroll', () => {
    window.scrollY !== 0 ? setIsScrolled(true) : setIsScrolled(false)
  })

  React.useEffect(() => {
    document.body.style.overflowY = ''
    setOpenMenu(false)
    setTouchedMenuState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const limit = 1000

  React.useEffect(() => {
    if (props.user.email?.indexOf('@new-one.co.jp') === -1 && props.user.id) {
      const cognitoUserName: string = props.user.id

      // オンボーディングかチームビルディングに有料があるかないか判定
      const isPaid = async () => {
        // カレントユーザのチームビルディングに関する情報
        const getTeams = await queryGetTeams({ limit: 100 })
        const teams = getTeams?.items.map((item) => item.id)

        const teamBuildingTeamIdAndRole = []
        if (teams && teams?.length) {
          for (let i = 0; i < teams.length; i++) {
            const res = await queryGetTeamMember({ id: `${teams[i]}-${props.user.id}` })
            teamBuildingTeamIdAndRole.push(`teamId:${teams[i]}/role:[${res?.role}]/teamStatus:${res?.statusUsage}`)
          }
        }

        let ownerUserId: string[] | string | undefined = ''
        if (getTeams) {
          ownerUserId = getTeams?.items[0]?.ownerUserId ? getTeams?.items[0]?.ownerUserId : ''
        }
        //自分の所属してるチームの誰かに有料がいるかどうか？
        const response = await queryGetTeams({ limit })
        const items = response?.items
        const TBmembers: Team[] | never = []
        items?.forEach((member: Team) => {
          if (member.statusUsage === 'IN_USE') {
            TBmembers.push(member)
          }
        })
        const teamTools = TBmembers?.map((item: Team) => item.teamTools)
        const hasPaidPlan = teamTools?.map((item) => item.length > 0)

        // ダッシュボード関連に有料サービスを含むならtrueを返却fasle
        const isPremium: boolean | undefined = hasPaidPlan?.some((content: boolean) => content === true)
        const isPaidPropaty = isPremium ? 1 : 0

        // オンボーディングの情報
        const responseForOnb = await queryGetOnboardingOwnTeamMemberList({})
        const localTeams: OnboardingTeam[] = []
        const OwnTemasForOnb = responseForOnb.getOnboardingOwnTeamMemberList?.items
        let OnbTeamIdsAndRoles: string[] | string | undefined = ''

        if (OwnTemasForOnb) {
          OnbTeamIdsAndRoles = OwnTemasForOnb?.map(
            (item) => `temaId:${item.teamId}/role:[${item.role}]/teamStatus:${item?.team?.usageStatus}`
          )
        }
        responseForOnb?.getOnboardingOwnTeamMemberList?.items.forEach((member) => {
          if (
            member.team &&
            member.status !== OnboardingTeamMemberStatus.Stopped &&
            member.team.usageStatus === OnboardingUsageStatus.Active
          ) {
            localTeams.push(member.team)
          }
        })
        const activeOnbTeams = localTeams.filter((t) => t.usageStatus === OnboardingUsageStatus.Active)
        const checkUserTeamPlan = activeOnbTeams.length > 0 ? 1 : isPaidPropaty

        const scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        // オンボーディングのteamIdとRoleの複数のオブジェクト
        const OnbTeamIdVariable = document.createTextNode(`var OnbTeamIdsAndRoles = "${OnbTeamIdsAndRoles}";`)
        const teamBuildingTeamIdAndRoleVariable = document.createTextNode(
          `var teamBuildingTeamIdsAndRoles = "${teamBuildingTeamIdAndRole}";`
        )

        //ユーザーID
        const idVariable = document.createTextNode(`var userID = "${cognitoUserName}";`)
        //ログインしてるユーザーのAdminが有料か無料か
        const BillingVariable = document.createTextNode(`var TeamBilling = "${checkUserTeamPlan}";`)

        // ログインユーザーのチームビルディングのの親ID
        const teamBuildOwnerIdVariable = document.createTextNode(`var BuildingOwnerId = "${ownerUserId}";`)

        scriptTag.appendChild(idVariable)
        scriptTag.appendChild(BillingVariable)
        scriptTag.appendChild(OnbTeamIdVariable)
        scriptTag.appendChild(teamBuildingTeamIdAndRoleVariable)
        scriptTag.appendChild(teamBuildOwnerIdVariable)

        const body = document.getElementsByTagName('body')[0] as HTMLElement
        body.appendChild(scriptTag)
      }
      isPaid()
    }
  }, [props.user.id, props.user.email])

  const LogoArea = React.useMemo(() => {
    if (logoTitle) {
      return (
        <div>
          <div className={classes.logoTitle}>{logoTitle}</div>
          <Link to={constants.PAGE_TOP}>
            <Logo width={82} height={10} />
          </Link>
        </div>
      )
    } else {
      return (
        <Link to={constants.PAGE_TOP}>
          <Logo width={isBreakPointsUp ? 140 : 169} height={isBreakPointsUp ? 18 : 21} />
        </Link>
      )
    }
  }, [classes.logoTitle, isBreakPointsUp, logoTitle])

  // render しないページ
  const paths = [
    `${pages.ONBOARDING_LANDING}/.+`,
    `${pages.Pages.TeamsLanding}/.+`,
    '/ghost/.+',
    '/signup/confirm?.+',
    `${pages.Pages.Hataraku}.*`,
    `${pages.Pages.Obakesagashi}.*`,
  ]

  if (paths.some((path) => location.pathname.match(new RegExp(path)))) {
    return <></>
  }

  return (
    <>
      <AppBar
        className={classNames([
          classes.appbar,
          openMenu ? 'open' : '',
          isScrolled ? 'isScrolled' : '',
          window.location.pathname !== '/' ? 'notTopPage' : '',
          isNarrowShadow && classes.narrowShadow,
        ])}
        position="static"
        color={'inherit'}
      >
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.container}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} height="100%">
              {LogoArea}
              {isBreakPointsUp && (
                <>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} ml={'18px'} mr={'auto'}>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_FEATURES}>機能一覧</Link>
                    </div>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_PLAN}>料金プラン</Link>
                    </div>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_CASE}>導入事例</Link>
                    </div>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_RESOURCES}>お役立ち資料</Link>
                    </div>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_HELP}>ヘルプ</Link>
                    </div>
                    <div className={classes.topMenu}>
                      <Link to={constants.PAGE_NOTIFICATION}>お知らせ</Link>
                    </div>
                  </Box>

                  <div className={classes.headerButtonWrap}>
                    {props.user?.fetched && !props.user?.id && (
                      <>
                        <Link
                          className={classes.loginText}
                          to={
                            location.pathname === pages.KARTE_LANDING || location.pathname === pages.PAGE_SETUP_LANDING
                              ? `${constants.PAGE_SIGNIN}?r=${location.pathname}`
                              : constants.PAGE_SIGNIN
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ログイン
                        </Link>
                        <TopButton
                          className={classes.buttonRight}
                          label="お問い合わせ"
                          type="contact"
                          teams={teams}
                          isMoveAnimation={false}
                        />
                      </>
                    )}
                    <div className={classes.hintCounseling}>
                      <HeaderAdditionalActions />
                    </div>
                    {props.user?.fetched && props.user?.id && (
                      <>
                        <SignoutButtonNew
                          signOutCallback={signOutCallback}
                          ownStyles={{
                            height: 64,
                            margin: 0,
                            border: 'none',
                            borderRadius: 0,
                          }}
                        />
                        <TopButton
                          className={classes.buttonRight}
                          label={teams && teams.items.length ? 'ダッシュボードへ' : 'お問い合わせ'}
                          type={teams && teams.items.length ? 'toDashboard' : 'contact'}
                          teams={teams}
                          isMoveAnimation={false}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {!isBreakPointsUp && (
                <Box display="flex" justifyContent="center" alignItems="center" className="__actions" ml={'auto'}>
                  <HeaderAdditionalActions />
                  {/* sp menu icon */}
                  {!openMenu && (
                    <img
                      className="__icon burgerGreenIcon"
                      src={process.env.PUBLIC_URL + '/assets/svg/burgerGreen.svg'}
                      onClick={handleMenu}
                      alt={'burger'}
                    />
                  )}
                  {openMenu && <></>}
                </Box>
              )}
            </Box>
          </div>
        </Toolbar>
      </AppBar>

      {/* opened menu */}
      <div
        className={classNames([
          classes.menus,
          touchedMenuState && openMenu && 'open',
          touchedMenuState && !openMenu && 'close',
        ])}
      >
        <div className={classes.menusLeft} onClick={handleMenu}></div>
        <div className={classes.menusRight}>
          <ul>
            <li>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.humHeader}
                ml={'auto'}
              >
                <div className={classes.cocolaboLogo}>{LogoArea}</div>
                <div className={classes.icons}>
                  {!openMenu && (
                    <img
                      className="__icon"
                      src={process.env.PUBLIC_URL + '/assets/svg/burger.svg'}
                      onClick={handleMenu}
                      alt={'burger'}
                    />
                  )}
                  {openMenu && (
                    <img
                      className="__icon"
                      src={process.env.PUBLIC_URL + '/assets/svg/close.svg'}
                      onClick={handleMenu}
                      alt={'close'}
                      style={!openMenu ? { display: 'none' } : undefined} // to load img file before display
                    />
                  )}
                </div>
              </Box>
            </li>
            <li>
              <Link to={constants.PAGE_FEATURES}>
                <span className={classes.humMenuList}>機能一覧</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_PLAN}>
                <span className={classes.humMenuList}>料金プラン</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_CASE}>
                <span className={classes.humMenuList}>導入事例</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_RESOURCES}>
                <span className={classes.humMenuList}>お役立ち資料</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_HELP}>
                <span className={classes.humMenuList}>ヘルプ</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_NOTIFICATION}>
                <span className={classes.humMenuList}>お知らせ</span>
              </Link>
            </li>
            <li>
              <Link to={constants.PAGE_CONTACT}>
                <span className={classes.humMenuList}>お問い合わせ</span>
              </Link>
            </li>
          </ul>
          {props.user?.fetched && props.user?.id && (
            <div className="__buttons">
              <SignoutButtonNew
                signOutCallback={signOutCallback}
                ownStyles={{
                  height: 46,
                  width: 252,
                  margin: '24px 24px 0 24px',
                  border: '2px solid #00838C',
                  borderRadius: '25px',
                }}
              />

              <TopButton
                className={classes.buttonRight}
                label={teams && teams.items.length ? 'ダッシュボードへ' : 'お問い合わせ'}
                type={teams && teams.items.length ? 'toDashboard' : 'contact'}
                teams={teams}
                isMoveAnimation={false}
                ownStyles={{ height: 46, width: 252, margin: '16px 24px 48px 20px' }}
              />
            </div>
          )}
          {((!isBreakPointsUp && !logoTitle) || (props.user?.fetched && !props.user?.id)) && (
            <div className="__additionalMenu">
              {props.user?.fetched && !props.user?.id && (
                <div className="__buttons">
                  <TopButton
                    className={classes.buttonLeft}
                    label={'ログイン'}
                    type={'login'}
                    teams={teams}
                    isMoveAnimation={false}
                    ownStyles={{ height: 46, width: 252, margin: '24px 24px 0 24px' }}
                  />
                  <TopButton
                    className={classes.buttonRight}
                    label={'お問い合わせ'}
                    type={'contact'}
                    teams={teams}
                    isMoveAnimation={false}
                    ownStyles={{ height: 46, width: 252, margin: '16px 24px 48px 20px' }}
                  />
                </div>
              )}
              <div className={classes.humFooter}>
                <div className={classes.footerIcons}>
                  <div className="__icon1">
                    <a href="https://twitter.com/cocolabo_club" target="_blank" rel="noopener noreferrer">
                      <img
                        className={classes.twitterIcon}
                        src="/assets/svg/landing/twitter_icn_black.svg"
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div className="__icon2">
                    <a href="https://www.facebook.com/cocolabo.club/" target="_blank" rel="noopener noreferrer">
                      <img
                        className={classes.facebookIcon}
                        src="/assets/svg/landing/facebook_icn_black.svg"
                        alt="facebook"
                      />
                    </a>
                  </div>
                </div>

                <div className={classes.__menus2}>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_ABOUT}>運営会社</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_TERMS}>利用規約</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_PRIVACY_POLICY}>プライバシーポリシー</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_LEGAL}>特定商取引法表示</Link>
                  </div>
                </div>
                <a href="https://new-one.co.jp/" target="_blank" rel="noopener noreferrer">
                  <img
                    className={classes.logo}
                    src={process.env.PUBLIC_URL + '/img/topPage/newoneLogo.svg'}
                    alt="newone"
                  />
                </a>
                <div className={classes.copyright}>&copy;NEWONE,Inc.</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      padding: '0 12px 0 24px',
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,
      [theme.breakpoints.up('md')]: {
        padding: '0 0 0 40px',
      },
    },
    appbar: {
      position: 'fixed',
      boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
      backgroundColor: constants.COLOR_WHITE,
      height: constants.HEADER_HEIGHT_SP,
      zIndex: 1290,
      [theme.breakpoints.up('md')]: {
        height: constants.HEADER_HEIGHT,
      },
    },
    narrowShadow: {
      boxShadow: '0 1px 1px rgba(100, 100, 100, 0.1)',
    },
    toolbar: {
      minHeight: 0,
      height: '100%',
    },
    topMenu: {
      margin: '0 10px',
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
    headerButtonWrap: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    hintCounseling: {
      display: 'flex',
      alignItems: 'center',
    },

    logoTitle: {
      fontSize: '22px',
    },
    loginText: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 40px',
      height: 64,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      whiteSpace: 'nowrap',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: `${constants.COLOR_MAIN_NEW}1A`,
        },
      },
    },
    buttonRight: {
      width: 146,
      height: 64,
      padding: '0 8px',
      backgroundColor: constants.COLOR_MAIN_NEW,
      [theme.breakpoints.up('md')]: {
        borderRadius: 0,
      },
      // TopButton の hover スタイルを上書き
      '&:hover': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        border: 'none',
        color: constants.COLOR_WHITE,
      },
      '@media (hover: hover)': {
        '&:hover': {
          opacity: '80%',
        },
      },
    },
    buttonLeft: {
      width: 146,
      height: 64,
      padding: '0 8px',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_MAIN_NEW,
      border: `2px solid ${constants.COLOR_MAIN_NEW}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 0,
      },
      // TopButton の hover スタイルを上書き
      '&:hover': {
        backgroundColor: constants.COLOR_WHITE,
        border: `2px solid ${constants.COLOR_MAIN_NEW}`,
        color: constants.COLOR_MAIN_NEW,
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: `${constants.COLOR_MAIN_NEW}1A`,
        },
      },
    },
    menus: {
      opacity: 0,
      position: 'fixed',
      top: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      margin: '0 0 0 0',
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,

      padding: '0',
      zIndex: -1,
      boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        background: 'white',

        '& li > a, & li > button': {
          padding: '14px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textDecoration: 'none',
          color: theme.palette.text.primary,

          // for button reset
          backgroundColor: 'transparent',
          border: 0,
          width: '100%',
          cursor: 'pointer',
          '&:focus': {
            outline: 'none',
          },
        },
      },
      '& .__additionalMenu': {
        backgroundColor: constants.COLOR_WHITE,
      },
      '& .__buttons': {
        backgroundColor: constants.COLOR_WHITE,

        '& a': {
          width: '100%',
          textDecoration: 'none',
        },

        '& :first-child': {
          marginRight: '4px',
        },
        '& :last-child': {
          marginLeft: '4px',
        },
      },
      '&.open': {
        zIndex: 1291,
        opacity: 1,
        animation: `$slide 400ms ${theme.transitions.easing.easeInOut}`,
        overflowY: 'scroll',
        overflowX: 'hidden',
      },
      '&.close': {
        animation: `$slideClose 400ms ${theme.transitions.easing.easeInOut}`,
      },
      '@global': {
        '@keyframes slide': {
          '0%': {
            opacity: 0,
            transform: 'translateX(100%)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateX(0px)',
          },
        },
        '@keyframes slideClose': {
          '0%': {
            opacity: 1,
            zIndex: 1,
            display: 'block',
            transform: 'translateX(0px)',
          },
          '1%': {
            display: 'block',
          },
          '100%': {
            opacity: 0,
            zIndex: -1,
            display: 'none',
            transform: 'translateX(100%)',
          },
        },
      },
    },
    menusLeft: {
      background: 'rgba(10,10,10,0.5)',
      height: '954px',
      width: 'calc(100% - 312px)',
    },
    menusRight: {
      width: 312,
      height: '954px',
      backgroundColor: constants.COLOR_WHITE,
    },
    humHeader: {
      height: constants.HEADER_HEIGHT,
    },
    logo: {
      margin: '48px auto 0 0',
      width: '153px',
      height: '30px',
    },
    icons: {
      margin: '22px 25px 22px 0',
      '& img': {
        height: '16px',
        width: '16px',
      },
    },
    cocolaboLogo: {
      margin: '19px auto 19px 24px',
      hright: 17,
      width: 140,
    },
    humFooter: {
      margin: '0 26px 0 22px',
      borderTop: '1px solid #E3E3E3',
    },
    humMenuList: {
      fontSize: 14,
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'pointer',
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
    footerIcons: {
      margin: '48px 0 0 0',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    __icon1: {
      margin: '0 0 0 0',
    },
    twitterIcon: {
      width: 20,
      height: 20,
    },
    facebookIcon: {
      width: 20,
      height: 20,
      margin: '0 0 0 20px',
    },
    __icon2: {
      margin: '0 0 0 24px',
      fontSize: 13,
    },
    __menus2: {
      margin: '48px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      width: '120%',
      '& :first-child': {
        marginLeft: 0,
      },
      '& :last-child': {
        margin: '0 0 0 0',
      },
    },
    __menusLink2: {
      margin: '0 0 24px 16px',
      fontSize: 12,
      '&:hover': {
        color: constants.COLOR_ONBOARDING_MAIN,
        cursor: 'pointer',
      },
    },
    copyright: {
      margin: '24px 0 0 0',
      padding: '0 0 100px 0',
      fontSize: 10,
    },
  })

export default withRouter(withStyles(useStyles)(connector(Header)))
