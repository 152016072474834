import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import arrowDownSvg from 'pages/teams/pages/_tools/karte/assets/arrowDown.svg'

import { GhostHistoryData } from './useFormatGhostHistories'

import { TableFilter } from './index'

import { constants } from 'assets'

type Props = {
  histories: GhostHistoryData[]
  memberId: string
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
}

export const GhostHistorySelectInTable: React.FC<Props> = ({ histories, memberId, setFilter }) => {
  const classes = useStyles()

  return (
    <select
      name="history"
      onChange={(e) => setFilter((prev) => ({ ...prev, memberHistory: { memberId, targetHistoryId: e.target.value } }))}
      className={classes.root}
    >
      {histories.map((history, i) => (
        <option key={i} value={history.historyId}>
          {history.times}回目
        </option>
      ))}
    </select>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      width: 63,
      height: 24,
      padding: '4px 8px',
      backgroundColor: '#fff', // ブラウザごとの背景色を上書き
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 10,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 4,
      cursor: 'pointer',
      backgroundImage: `url(${arrowDownSvg})`,
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 10,
    },
  }),
  { name: 'GhostHistorySelectInTable' }
)
