import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Typography, CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { ProfileBox, TargetBox, ActionOverViewBox } from 'pages/onboarding/components'
import {
  useOnboardingActions,
  useOnboardingActionsFeedback,
  useOnboardingActionRadar,
  useOnboardingActionManageList,
} from 'pages/onboarding/hooks/action'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useOnboardingPostOfOneUser } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import {
  countOfActions,
  countOfOverDeadlineActions,
  calcPercent,
} from 'pages/onboarding/utils/onboardingActionOverView'
import {
  OnboardingTeamMember,
  OnboardingActionCategory,
  OnboardingTeamMemberRole,
  OnboardingTeamMemberStatus,
} from 'utils/generated'




import * as constants from '../../../../assets/constants'
import { OnbContext } from '../PagesRoot'

import { ActionStatusBox, DailyCheckBox, PersonalTimelineBox } from './components'

import ArrowLeft from './assets/arrow-left.svg'



type Props = {}
type StyleProps = {}

export const MemberStatusDetailBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { userId: targetUserId } = useParams<{ userId: string }>()
    const { posts } = useOnboardingPostOfOneUser(teamId, targetUserId)
    const { actions: visibleActions } = useOnboardingActions(teamId, targetUserId)
    const { actionsFeedback } = useOnboardingActionsFeedback(teamId, targetUserId)
    const { radar } = useOnboardingActionRadar(teamId, targetUserId)
    const { actions: totalActions } = useOnboardingActionManageList(teamId)
    const { teamMember, loading } = useTeamMember(teamId, targetUserId)

    //ActionData
    const actionProgressPercent = () => {
      const total: { [k in OnboardingActionCategory]: number } = countOfActions(totalActions)
      const isVisible: { [k in OnboardingActionCategory]: number } = countOfActions(visibleActions)
      const done: { [k in OnboardingActionCategory]: number } = countOfActions(actionsFeedback)
      const overDeadline: { [k in OnboardingActionCategory]: number } = countOfOverDeadlineActions(
        visibleActions,
        teamMember
      )
      const donePerTotal: { [k in OnboardingActionCategory]: number } = calcPercent(done, total)

      return { isVisible, overDeadline, donePerTotal }
    }
    const { isVisible, overDeadline, donePerTotal } = actionProgressPercent()

    const percentDoneActions = Math.round((actionsFeedback.length / totalActions.length) * 100 * 10) / 10

    return {
      teamMember,
      loading,
      posts,
      radar,
      visibleActions,
      actionsFeedback,
      totalActions,
      isVisible,
      overDeadline,
      donePerTotal,
      targetUserId,
      percentDoneActions,
    }
  },
}

export const MemberStatusDetailPage: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles()
  const history = useOnbHistory()
  const {
    teamMember,
    loading,
    totalActions,
    posts,
    visibleActions,
    actionsFeedback,
    overDeadline,
    donePerTotal,
    targetUserId,
    percentDoneActions,
  } = MemberStatusDetailBloc.useAdapter()

  if (!teamMember && !loading) return <div className={classes.noMember}>該当するユーザーが存在しません。</div>
  return (
    <div className={classes.MemberStatusDetailPageRoot}>
      <div
        className={classes.toBack}
        onClick={() => {
          history.goBack()
        }}
      >
        <img src={ArrowLeft} alt="arrow-left" />
        戻る
      </div>
      <Typography variant="h6" className={classes.title}>
        個別実施状況
      </Typography>

      {!loading ? (
        <>
          <ProfileBox teamMember={teamMember || ({} as OnboardingTeamMember)} ownStyles={{ margin: '26px 0 0' }} />
          <TargetBox teamMember={teamMember || ({} as OnboardingTeamMember)} ownStyles={{ margin: '24px 0 0' }} />
          {/* appear contents if targetMemberRole is member and status is 'ACCEPTED' */}
          {teamMember?.role === OnboardingTeamMemberRole.Member &&
          teamMember.status === OnboardingTeamMemberStatus.Accepted ? (
            <>
              <ActionOverViewBox
                percents={donePerTotal}
                overDeadlineActions={overDeadline}
                percentDoneActions={percentDoneActions}
                ownStyles={{ margin: '24px 0 0' }}
              />
              <ActionStatusBox
                totalActions={totalActions}
                visibleActions={visibleActions}
                actionsFeedback={actionsFeedback}
                ownStyles={{ margin: '24px 0 0' }}
              />
              <DailyCheckBox posts={posts} ownStyles={{ margin: '24px 0 0' }} />
            </>
          ) : (
            <></>
          )}
          <PersonalTimelineBox posts={posts} userId={targetUserId} ownStyles={{ margin: '24px 0 0' }} />
        </>
      ) : (
        <div className={classes.memberLoading}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    MemberStatusDetailPageRoot: {
      margin: '27px 0 0 24px',
      [theme.breakpoints.down(680 + 16 + 16)]: {
        margin: '27px 16px 0',
      },
    },
    title: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 21,
      marginBottom: 26,
    },
    noMember: {
      textAlign: 'center',
      margin: '100px 0px',
    },
    toBack: {
      position: 'absolute',
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      padding: 5,
      cursor: 'pointer',
      '& img': {
        position: 'relative',
        top: 1,
        right: 8,
      },
    },
    memberLoading: {
      width: '100%',
      height: 120,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiCircularProgress-svg': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },
  }),
  { name: 'MemberStatusDetailPage' }
)
