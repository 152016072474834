import { useCallback } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import actions from '../../stores/actions'

import { IOptions, optionHintCategory } from 'assets/options'

// -----------------------------
// interface
// -----------------------------
export type IuseCategory = {
  getCategories: () => IOptions[]
  getCurrentCategory: () => string
  onSetCurrentCategory: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSearchTipsSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSearchConsultationSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

// -----------------------------
// hooks
// -----------------------------
export const useCategory = (): IuseCategory => {
  const [curerentCategory, setCurrentCategories] = useState<string>('')
  const location = useLocation()
  const dispatch = useDispatch()

  /**
   * カテゴリのオプション一覧を取得する
   */
  const getCategories = (): IOptions[] => {
    return optionHintCategory
  }

  /**
   * 現在セレクト中のカテゴリーを返す
   */
  const getCurrentCategory = (): string => {
    return curerentCategory
  }

  /**
   * 現在セレクト中のカテゴリーをセットする
   */
  const onSetCurrentCategory = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrentCategories(e.target.value)
    dispatch(actions.resetFetch())
    dispatch(actions.resetToken())

    if (location.pathname.includes('tips') || location.pathname.includes('profile')) {
      dispatch(actions.getCategory({ type: 'HINT', category: e.target.value }))
      onSearchTipsSelect(e)
    } else {
      dispatch(actions.getCategory({ type: 'CONSULTATION', category: e.target.value }))
      onSearchConsultationSelect(e)
    }
  }

  /**
   * セレクトボックスで選択したときにカテゴリーサーチページに遷移する
   */
  const onSearchTipsSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
    window.location.href = `/hint/tips/search/category/${e.target.value}`
  }, [])

  /**
   * セレクトボックスで選択したときにカテゴリーサーチページに遷移する
   */
  const onSearchConsultationSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
    window.location.href = `/hint/faq/search/category/${e.target.value}`
  }, [])

  return {
    getCategories,
    getCurrentCategory,
    onSetCurrentCategory,
    onSearchTipsSelect,
    onSearchConsultationSelect,
  }
}
