import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

import { Container, ProgressDots } from '.'

type Props = {
  children: React.ReactNode
  active?: 1 | 2 | 2.5 | 3 | 4 | 5
  progressDotsProps?: {
    maxLength?: number
    currentProgress?: number
  }
  header?: boolean
  questionHeadBar?: boolean
  colorTitleText?: boolean
  titleTextBold?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  addPaddingSide?: boolean
  isWaiting?: boolean
  addMarginBottom?: boolean
}

export const QuestionContainer: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Container
      header={props.header}
      questionHeadBar={{
        visible: props.questionHeadBar,
        props: { step: props.active, isColorText: props.colorTitleText, textBold: props.titleTextBold },
      }}
      noPadding={props.noPadding}
      fullWidth={props.fullWidth}
      addPaddingSide={props.addPaddingSide}
      addMarginBottom={props.addMarginBottom}
    >
      <div className={classNames([classes.wrapper, props.isWaiting && classes.waitingControl])}>
        {props.active && (
          <ProgressDots
            maxLength={props.progressDotsProps?.maxLength}
            currentProgress={props.progressDotsProps?.currentProgress}
          />
        )}
        {props.children}
      </div>
    </Container>
  )
}

const useStyles = makeStyles<Theme>(
  (theme: Theme) => ({
    wrapper: {
      width: '100%',
    },
    waitingControl: {
      minHeight: 'calc(100vh - 112px)', // -72px is header. -40px is bottom adjustment.
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '-80px 0 -70px',
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 130px)', // -150px is Container padding. 20px is bottom adjustment.
        marginBottom: -20,
      },
    },
  }),
  { name: 'QuestionContainer' }
)
