import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormProvider, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { teamConstants } from 'pages/teams/assets/constants'
import {
  DefaultInputWithLabel,
  DefaultButton,
  HookErrors,
  CardFooter,
  Card,
  ButtonContainer,
  HorizontalLine,
  ResultFramework,
  ResultWithIcon,
  ReservationFramework,
} from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamMembers, useTeamMembers } from 'pages/teams/hooks'
import { formatDateTime } from 'pages/teams/utils/dateTimeHandler'
import { UpdateTeamMemberInput } from 'utils/generated'

import { useManageTeam, useTeam } from '../../hooks/teams'
import { Modal } from '../list/components/atoms'

import { constants } from 'assets'
import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'
interface IInput {
  [key: string]: string | undefined
  name: string
  sendEmailDateAt?: string
  sendEmailTimeAt?: string
}

type DateBox = {
  date: string
  dayOfWeek: string
  time: string
}

type Props = RouteComponentProps<{ teamId: string; editTeamId: string }>

export const TeamsEditPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { teamId, editTeamId } = props.match.params
  const { route } = useHistory()
  const { loading, error, updateTeam } = useManageTeam()
  const methods = useForm<IInput>()
  const { refreshTeam } = React.useContext(HooksContext)
  // 編集対象のチーム情報を取得
  const { team } = useTeam(editTeamId)
  const { teamMembers: reservedTeamMembers } = useTeamMembers(editTeamId, { isReservedInvitation: true })
  const { updateTeamMemberList, loading: isUpdateLoading, error: updateError } = useManageTeamMembers()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isUpdated, setIsUpdated] = React.useState(false)
  const [dateBox, setDateBox] = React.useState<DateBox>()

  const { handleSubmit, control, errors, setValue } = methods
  const defaultValues = {
    sendEmailDateAt: '',
    sendEmailTimeAt: '',
  }

  React.useEffect(() => {
    if (team) {
      setValue('name', team.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team])

  const onSubmit = async (input: IInput) => {
    if (!input.name) {
      alert('チーム名を入力してください。')
      return
    }
    if (input.sendEmailDateAt && !input.sendEmailTimeAt) {
      alert('招待メールの送信時間を入力してください。')
      return
    }
    if (!input.sendEmailDateAt && input.sendEmailTimeAt) {
      alert('招待メールの送信日を入力してください。')
      return
    }

    if (input.sendEmailDateAt && input.sendEmailTimeAt) {
      const { formattedDate, formattedTime } = formatDateTime(input.sendEmailDateAt, input.sendEmailTimeAt)
      const inputDateTime = `${formattedDate} ${formattedTime}`

      // 予約日時が過去日の場合、アラートを表示。
      if (new Date(inputDateTime) < new Date()) {
        alert('予約の時間は既に過ぎております。')
        return
      }
      // 予約日時が未来日の場合、確認モーダルを表示。
      else if (!isModalOpen) {
        setIsModalOpen(true)
        return
      }
    }

    setIsModalOpen(false)

    const updated = await updateTeam({
      id: editTeamId,
      name: input.name,
    })
    if (updated) {
      refreshTeam()
    }

    if (!(input.sendEmailDateAt && input.sendEmailTimeAt)) {
      setIsUpdated(true)
      return
    }

    // 以降、予約招待日時が入力されている場合の処理。

    let sendEmailAt = ''
    const { formattedDate, formattedDateJP, formattedTime, dayOfWeek } = formatDateTime(
      input.sendEmailDateAt,
      input.sendEmailTimeAt
    )
    sendEmailAt = `${formattedDate} ${formattedTime}`

    setDateBox({
      date: formattedDateJP,
      dayOfWeek: `${dayOfWeek}曜日`,
      time: formattedTime,
    })

    if (sendEmailAt) {
      const updateMembers: UpdateTeamMemberInput[] = reservedTeamMembers.map((member) => ({
        id: member.id,
        invitationDateTime: sendEmailAt,
      }))

      const res = await updateTeamMemberList(editTeamId, updateMembers)
      if (res) setIsUpdated(true)
    }
  }

  const handleToTeamList = () => {
    route.push(`${Pages.TeamsList}?hidden=${true}`, { teamId })
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <FormProvider {...methods}>
      <form id="team-edit-form" onSubmit={handleSubmit(onSubmit)}>
        <Card
          headerTitle="チーム編集"
          style={{ padding: isSmDown ? '16px 16px 24px' : 24 }}
          titleStyles={{ height: isSmDown ? 22 : 40, fontSize: 14, display: 'flex', alignItems: 'center' }}
          headerMb={0}
          titleContainerPb={0}
        >
          {!isUpdated ? (
            <>
              <HorizontalLine style={{ marginTop: isSmDown ? 16 : 24, marginBottom: 24 }} />
              <ReservationFramework
                defaultValues={defaultValues}
                isShowForm={!!team?.countReservedMember}
                isReserved={false}
                isHideRightNode={!team?.countReservedMember}
              >
                <div className={classes.inputBox}>
                  <DefaultInputWithLabel
                    label="チーム名"
                    name="name"
                    control={control}
                    rules={{ required: 'チーム名は必須です' }}
                    error={errors.name}
                    defaultValue={team?.name ?? ''}
                    htmlFor={'teamName'}
                    rootStyle={{ flex: 1, marginBottom: 24 }}
                  />
                </div>
              </ReservationFramework>
              {(!isSmDown || !team?.countReservedMember) && <HorizontalLine style={{ marginBottom: 24 }} />}

              <CardFooter>
                <ButtonContainer
                  buttons={[
                    <DefaultButton
                      key="buttons-teams-editPage-1"
                      title="更新"
                      color={!!errors.name ? 'team_disabled' : 'team_main'}
                      type="submit"
                      disabled={!!errors.name}
                      loading={loading || isUpdateLoading}
                      style={{
                        ...teamConstants.cardFooterButtonStlyes,
                        border: !!errors.name ? teamConstants.disabledBorder : undefined,
                      }}
                    />,
                    <DefaultButton
                      key="buttons-teams-editPage-2"
                      title="キャンセル"
                      color="team_white"
                      type="button"
                      disabled={loading || isUpdateLoading}
                      onClick={handleToTeamList}
                      style={teamConstants.cardFooterButtonStlyes}
                    />,
                  ]}
                  marginTopPcLayout={0}
                />
              </CardFooter>

              {updateError &&
                updateError.map((err, i) => (
                  <div key={`update-err-${i}`} className={classes.errors}>
                    {err.message}
                  </div>
                ))}

              <HookErrors errors={error} />
            </>
          ) : (
            <ResultFramework>
              <ResultWithIcon
                topText="チームを更新しました"
                iconType="success"
                message="チームリストから内容をご確認ください"
                dateBox={dateBox}
                button={{
                  label: 'チームリストに戻る',
                  onClick: handleToTeamList,
                }}
              />
            </ResultFramework>
          )}
        </Card>
      </form>

      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        paperStyle={{
          width: '100%',
          maxWidth: 346,
        }}
      >
        <div className={classes.modalBox}>
          <img
            className={classes.modalIcon}
            src={process.env.PUBLIC_URL + '/assets/svg/exclamationTeam.svg'}
            alt="exclamation icon"
          />
          <p className={classes.modalMessage}>一括でメンバー全員の予約日時が{'\n'}変更されますがよろしいですか？</p>

          <DefaultButton
            title="更新"
            color="team_main"
            type="submit"
            form="team-edit-form"
            loading={loading || isUpdateLoading}
            style={teamConstants.cardFooterButtonStlyes}
          />
          <DefaultButton
            title="キャンセル"
            color="team_white"
            type="button"
            onClick={handleModalClose}
            style={teamConstants.cardFooterButtonStlyes}
          />
        </div>
      </Modal>
    </FormProvider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    inputBox: {
      display: 'flex',
    },
    errors: {
      marginTop: 16,
      color: constants.COLOR_ALERT,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
    },
    modalIcon: {
      width: 46,
      height: 46,
    },
    modalMessage: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'TeamsEditPage' }
)
