import React from 'react'
import { useParams } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useTeamAnketBuildingSummaryListByTeam } from 'services/api/anket'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { LoadingCircular } from 'pages/teams/components'
import refreshSvg from 'pages/teams/pages/dashboard/assets/refresh.svg'
import AnketBuildingSummary from 'pages/teams/pages/list/components/modules/AnketBuildingSummary'
import { Team, TeamTool, TeamAnketBuildingSummaryStatus as SummaryStatus } from 'utils/generated'

import { Description, AnketOpen, AnketClose, Tooltip } from './components'

import { constants } from 'assets'

dayjs.extend(utc)
dayjs.extend(timezone)

const balloonId = 'refresh-balloon-id'

type TBProps = {
  summaryByTeam: ReturnType<typeof useTeamAnketBuildingSummaryListByTeam>
  currentTeam: Team | undefined
  isLeader: boolean | undefined
  isMember: boolean | undefined
}

export const TB: React.FC<TBProps> = ({ summaryByTeam, currentTeam, isLeader, isMember }) => {
  const {
    teamSummaryItem: item,
    loadingRefresh,
    loadingEvent,
    isSendingMail,
    refresh,
    handleClickCreateSummary,
    handleCreateSummaryBySendEmail,
    handleSendEmailToUnansweredTeamAnketMembers,
    handleClickCloseSummary,
    graphSummaryList,
  } = summaryByTeam

  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')
  const { teamId } = useParams<{ teamId: string }>()

  const latestCreatedAt = item?.closedSummaryList ? dayjs(item.closedSummaryList[0].createdAt).format('YYYY/M/D') : '_'

  const handleRefresh = React.useCallback(() => {
    refresh()
  }, [refresh])

  const handleOnTooltip = () => {
    if (isMdUp) {
      const balloon = document.getElementById(balloonId)
      balloon?.style.setProperty('display', 'block')
    }
  }

  const handleOffTooltip = () => {
    if (isMdUp) {
      const balloon = document.getElementById(balloonId)
      balloon?.style.setProperty('display', 'none')
    }
  }

  if (loadingRefresh || loadingEvent) {
    return <LoadingCircular loading={loadingRefresh || loadingEvent} />
  }

  if (!teamId || !currentTeam || !currentTeam.teamTools.includes(TeamTool.BuildingCheck) || !item) {
    return <></>
  }

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <NoStyleButton
          className={classes.refreshButton}
          onClick={handleRefresh}
          onMouseEnter={handleOnTooltip}
          onMouseLeave={handleOffTooltip}
        >
          <img className={classes.refreshIcon} src={refreshSvg} alt="refresh icon" />
        </NoStyleButton>
        <Tooltip balloonId={balloonId} message="更新" />

        {/* 過去の実施がない場合、説明文を表示 */}
        {!item.summaryCount ? (
          <Description />
        ) : (
          <>
            <div className={classes.message}>
              <div>{`前回の実施日: ${latestCreatedAt}`}</div>
            </div>
            <div className={classes.message}>
              <div>{`実施回数: ${item.summaryCount}回`}</div>
            </div>
          </>
        )}

        <div className={classes.wrapper}>
          {item.summaryStatus === SummaryStatus.Open ? (
            // アンケート開催中
            <AnketOpen
              isLeader={isLeader}
              isMember={isMember}
              summary={{
                summaryCount: item.summaryCount,
                answerStatus: item.answerStatus,
                unansweredMemberList: item.unansweredMemberList,
                handleClickCloseSummary,
                handleSendEmailToUnansweredTeamAnketMembers,
                isSendingMail,
              }}
            />
          ) : (
            // アンケート未開催
            <AnketClose
              isLeader={isLeader}
              handleClickCreateSummary={handleClickCreateSummary}
              handleCreateSummaryBySendEmail={handleCreateSummaryBySendEmail}
            />
          )}
        </div>
      </div>

      {/* 過去の実施がある場合、グラフを表示 */}
      {item.summaryCount ? (
        <div className={classes.summary}>
          <AnketBuildingSummary
            teamId={teamId}
            summaryList={graphSummaryList}
            loading={loadingRefresh || loadingEvent}
          />
        </div>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontFamily: 'Hiragino Sans',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        margin: '0 -8px',
      },
    },
    info: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
        padding: '24px 16px',
      },
    },
    refreshButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 6,
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      borderRadius: 6,
      [theme.breakpoints.down('sm')]: {
        top: 24,
        right: 16,
      },

      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    refreshIcon: {
      width: 26,
      height: 26,
      color: constants.COLOR_MAIN_NEW,
      verticalAlign: 'top',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.8em',
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    wrapper: {
      marginTop: 32,
    },
    summary: {
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
  }),
  { name: 'TB' }
)
