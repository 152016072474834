import { useState, useEffect, useCallback } from 'react'

import { Auth } from 'aws-amplify'

import { queryGetOnboardingPost, mutationCreateOnboardingPostDaily } from 'pages/onboarding/graphql'
import { OnboardingDaily, OnboardingPost } from 'utils/generated'

export const useDaily = (teamId?: string, cancel?: boolean) => {
  const [postDaily, setPostDaily] = useState<OnboardingPost | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const date = new Date()
  const keyDate = `${date.getFullYear()}${`${date.getMonth() + 1}`.padStart(2, '0')}${`${date.getDate()}`.padStart(
    2,
    '0'
  )}`

  const refresh = useCallback(async () => {
    if (!teamId || cancel) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const cognito = await Auth.currentAuthenticatedUser()
      const id = `${teamId}-${cognito.getUsername()}-daily-${keyDate}`

      const response = await queryGetOnboardingPost({ id })
      setPostDaily(response)
    } catch (e) {
      console.log('e', e)
      setError(e as Error)
    }
    setLoading(false)
  }, [teamId, keyDate, cancel])

  useEffect(() => {
    refresh()
  }, [teamId, refresh])

  const createPost = async (daily: OnboardingDaily) => {
    if (!teamId) {
      return
    }
    const cognito = await Auth.currentAuthenticatedUser()
    const id = `${teamId}-${cognito.getUsername()}-daily-${keyDate}`

    await mutationCreateOnboardingPostDaily({
      input: {
        id,
        teamId,
        daily,
      },
    })
    refresh()
  }

  return { postDaily, loading, error, refresh, createPost } as const
}
