import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../../../assets/constants'
import { OnbContext } from '../../../pages/PagesRoot'

import email from '../assets/email.svg'
import hatena from '../assets/hatena.svg'
import movie from '../assets/movie.svg'
import { replacePathParams } from 'assets/history'
import { OnbPages } from 'assets/pages'


interface IOwnProps {
  handleOnClickLink: () => void
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, handleOnClickLink }) => {
  const history = useHistory()
  const { teamId } = useContext(OnbContext)
  const pushPath = () => {
    handleOnClickLink()
    history.push(replacePathParams(OnbPages.ONBOARDING_PAGE_USAGE, { teamId }))
  }
  return (
    <>
      <div className={classes.GridItems}>
        <div className={classes.itemPlan} onClick={() => pushPath()}>
          <img src={movie} alt="使い方" />
          <span className={classes.iconText}>使い方</span>
        </div>
        <div className={classes.itemPlan} onClick={() => history.push(constants.PAGE_HELP)}>
          <img className={classes.icon} src={hatena} alt="ヘルプ" />
          <span className={classes.iconText}>ヘルプ</span>
        </div>
        <div className={classes.itemPlan} onClick={() => history.push(constants.PAGE_CONTACT)}>
          <img src={email} alt="問い合わせ" />
          <span className={classes.iconText}>問い合わせ</span>
        </div>
      </div>
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    iconText: {
      marginLeft: 22,
      color: constants.COLOR_GRAY_DARK,
      fontWeight: 'bold',
      fontSize: 14,
    },
    itemPlan: {
      padding: '23px 16px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    icon: {
      marginLeft: 5,
      marginRight: 2,
    },
    GridItems: {
      width: '300px',
      margin: '2px 0 8px 0',
      backgroundColor: constants.COLOR_WHITE,
      '& .space': {
        margin: '0',
        padding: '6px 0',
      },
    },
  })

export const OtherLinks = withStyles(useStyles)(Index)
