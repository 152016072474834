import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

export const WaveBack: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.wave}>
      <img className="woman-desk" src={`${process.env.PUBLIC_URL}/img/obakesagashi/woman-desk.svg`} alt="王冠" />
      <img className="man-desk" src={`${process.env.PUBLIC_URL}/img/obakesagashi/man-desk.svg`} alt="王冠" />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    wave: {
      position: 'relative',
      width: '100%',
      height: 27,
      background: `transparent repeat-x bottom left / 156px 30px url(${process.env.PUBLIC_URL}/img/obakesagashi/wave.svg)`,
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'bottom right',
      },
      '& .woman-desk': {
        position: 'absolute',
        bottom: 'calc(100% - 70px)',
        left: 87,
        width: 269,
        [theme.breakpoints.down('sm')]: {
          left: 16,
          width: 200,
        },
        [theme.breakpoints.down('xs')]: {
          bottom: 'calc(100% - 50px)',
          width: 143,
        },
      },
      '& .man-desk': {
        position: 'absolute',
        bottom: 'calc(100% - 76px)',
        right: 96,
        width: 252,
        [theme.breakpoints.down('sm')]: {
          right: 16,
          width: 190,
        },
        [theme.breakpoints.down('xs')]: {
          bottom: 'calc(100% - 56px)',
          width: 134,
        },
      },
    },
  }),
  { name: 'WaveBack' }
)
