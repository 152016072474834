import React, { useContext, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'

import {
  GrayBackGround,
  ButtonForGhost,
  BackResponsiveCard,
  PageTitle,
  LeadText,
  UsefulCard,
  Font,
  Flexbox,
  Imgcard,
} from '../components/atoms'
import { ButtonContainer } from '../components/modules'
import { WidthControlContainer } from '../components/modules/WidthControlContainer'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { GhostContext } from '../contexts/ghost'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

import { page2Num } from '../assets/pages'
import info from '../assets/svg/info.svg'

export const Pickghosts: React.FC = () => {
  const { selectedGhostIds, ghosts, setDetail, setModal, setDirectClose, selectGhost } = useContext(GhostContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { runCreateReports } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer' })

  const [clicked, setClicked] = useState(false)

  const checkActive = (ghostId: string) => selectedGhostIds.some((id) => id === ghostId)

  const handleStartButtonClick = () => {
    if (!clicked && teamMember?.page === page2Num('pickGhosts')) {
      runCreateReports(selectedGhostIds)
    }
    setClicked(true)
  }

  const handleClickInfo = (ghostId: string) => () => {
    setDetail(ghostId)
    setModal('detail-no-tab')
    setDirectClose(true)
  }

  const handleClickSelect = (ghostId: string) => () => selectGhost(ghostId, false)

  // UI

  const classes = useStyles({})
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const ready = selectedGhostIds.length === 3

  if (!teamMember) return <LoadingIndicator snapshot={{ teamMember, vars: { team, teamMember } }} />
  return (
    <div id="searching">
      <GrayBackGround>
        <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
          <WidthControlContainer>
            <PageTitle>個人ワーク</PageTitle>
            <LeadText>
              はじめに12体のオバケリストから、自分の組織にいると思うオバケを3体選んでチェックをつけてください。
              <br />
              詳細を見たい方は、詳細ボタンを押すと、オバケの詳細が見ることができます。
              <br />
              ３体選択したら、オバケ選択完了ボタンを押して、次に進んでください。
            </LeadText>

            <UsefulCard
              backgroundColor="white"
              borderRadius={8}
              ownStyles={{ marginTop: 24, marginBottom: 86, padding: isSmDown ? '24px 16px 16px' : '40px 160px' }}
            >
              <Font fontSize={14} textAlign="center" bold ownStyles={{ marginBottom: isSmDown ? 24 : 30 }}>
                オバケリスト
              </Font>
              <Grid container spacing={isSmDown ? 2 : 3}>
                {ghosts.map((ghost, index) => (
                  <Grid item xs={6} sm={4} key={`pick-ghosts-list-${index}`} className={classes.gridItem}>
                    <div
                      className={classNames(
                        classes.imgCardWrapper,
                        checkActive(ghost.id) ? classes.activeBorder : classes.normalBorder
                      )}
                    >
                      {selectedGhostIds.indexOf(ghost.id) > -1 && (
                        <div className={classes.activeBatch}>{selectedGhostIds.indexOf(ghost.id) + 1}</div>
                      )}
                      <UsefulCard backgroundColor="white" borderRadius="none" onClick={handleClickSelect(ghost.id)}>
                        <img
                          src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghost.id}.svg`}
                          alt={ghost.name}
                          className={classNames(classes.ghostImg, {
                            [classes.ghostImgActive]: checkActive(ghost.id),
                          })}
                        />
                      </UsefulCard>
                    </div>
                    <Flexbox>
                      <div className={classes.ghostTextBox}>
                        <Font
                          fontSize={10}
                          ownStyles={{ marginTop: 8, color: constGhost.COLOR_GRAY2 }}
                        >{`FILE NO.${ghost.fileNo}`}</Font>
                        <Font fontSize={10}>{ghost.name}</Font>
                      </div>
                      <Flexbox
                        onClick={handleClickInfo(ghost.id)}
                        ownStyles={{
                          width: 39,
                          justifyContent: 'flex-end',
                          paddingTop: 12,
                          cursor: 'pointer',
                        }}
                      >
                        <div className={classes.iconBox}>
                          <Imgcard
                            src={info}
                            alt={'ghost info'}
                            ownStyles={{ width: '100%', verticalAlign: 'top' }}
                            ownWrapStyles={{ width: 16, height: 16 }}
                          />
                          <Font
                            fontSize={10}
                            ownStyles={{
                              color: constGhost.COLOR_MAIN_NEW,
                              fontWeight: 'bold',
                              margin: '4px 0 0',
                              cursor: 'pointer',
                            }}
                          >
                            詳細
                          </Font>
                        </div>
                      </Flexbox>
                    </Flexbox>
                  </Grid>
                ))}
              </Grid>
            </UsefulCard>

            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="pick-ghosts"
                  onClick={handleStartButtonClick}
                  buttonBodyColor={ready ? 'green' : 'gray'}
                  bodyText={'オバケ選択完了'}
                  disabled={!ready}
                  isResizeBasic
                />,
              ]}
            />
          </WidthControlContainer>
        </BackResponsiveCard>

        {team && <Timer from={team.timerS1Started ?? new Date().toISOString()} time={300} hasGhostStyle />}
      </GrayBackGround>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    backCard: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        padding: '0 110px',
      },
    },
    mainItem: {
      flexGrow: constGhost.MAIN_CONTENT_WIDTH,
    },
    gridItem: {
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 18,
      },
    },
    imgCardWrapper: {
      position: 'relative',
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    normalBorder: {
      border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
    },
    activeBorder: {
      border: `4px solid ${constGhost.COLOR_MAIN_NEW}`,
    },
    activeBatch: {
      position: 'absolute',
      top: 3,
      right: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 21,
      height: 21,
      backgroundColor: constGhost.COLOR_MAIN_NEW,
      borderRadius: '50%',
      color: constGhost.COLOR_WHITE,
      fontSize: 12,
      fontWeight: 'bold',
      zIndex: 10,
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    ghostImg: {
      width: '100%',
      height: '100%',
      verticalAlign: 'top',
    },
    ghostImgActive: {
      backgroundColor: '#00000080',
      WebkitFilter: 'grayscale(100%) brightness(50%)',
      filter: 'grayscale(100%) brightness(50%)',
    },
    ghostTextBox: {
      flex: 1,
    },
  }),
  { name: 'Pickghosts' }
)
