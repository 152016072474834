import * as React from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'

import { Typography, useTheme, useMediaQuery } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'
import { interviews } from '../../../components/casePageData/static'

import { ArrowLink } from './components/ArrowLink'
import { ArrowLinkRight } from './components/ArrowLinkRight'
import { ScrollTop } from './components/ScrollTop'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const location = useLocation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const userNumber = Number(location.pathname.split('/').slice(-1)[0])
  const interview = interviews[userNumber - 1]

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [userNumber])

  if (!interview || !interview.title) {
    return <></>
  }

  return (
    <>
      {!Number.isNaN(userNumber) && userNumber > 0 && userNumber <= interviews.length ? (
        <div className={classes.back}>
          <div className={classes.container}>
            <div className={classes.head} id="back-to-top-anchor">
              <div className={classes.topTitleWrapper}>
                <Typography variant="h1" className={classes.topTitle}>
                  個人ユーザー使用事例
                </Typography>
                <ArrowLink linkTo={constants.PAGE_CASE} fontSize={isMdUp ? 20 : 16}>
                  事例紹介一覧に戻る
                </ArrowLink>
              </div>
              <div className={classes.user}>
                {interview.userFullName.map((el, index) => (
                  <div key={`case-user-title-${index}`}>{el}</div>
                ))}
              </div>
              <div className={classes.userImg}>
                <img src={`${process.env.PUBLIC_URL}/assets/landing/case/interview-${userNumber}.jpg`} alt="top-img" />
              </div>
            </div>

            <Typography variant="h2" className={classes.qaTitle}>
              {interview.title}
            </Typography>
            <div className={classes.qaTitleLine}></div>
            {interview.body.map((qa, index) => (
              <div key={`case-user-qa-${index}`} className={classes.qa}>
                <Typography className={classes.question}>Q：{qa.question}</Typography>
                <Typography className={classes.answer}>{qa.answer}</Typography>
              </div>
            ))}

            <div className={classes.scrollButton}>
              <ScrollTop />
            </div>
            <Typography className={classes.scrollButtonText}>ページ上部に戻る</Typography>

            <div className={classes.arrowLinks}>
              <div className={classes.arrowLink}>
                {userNumber > 1 && (
                  <ArrowLink linkTo={`${constants.PAGE_CASE}/${userNumber - 1}`}>
                    {interviews[userNumber - 2].title}
                  </ArrowLink>
                )}
              </div>
              <div className={classes.arrowLink}>
                {userNumber < interviews.length && interviews[userNumber].title && (
                  <ArrowLinkRight linkTo={`${constants.PAGE_CASE}/${userNumber + 1}`}>
                    {interviews[userNumber].title}
                  </ArrowLinkRight>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    back: {
      backgroundColor: constants.COLOR_WHITE,
      paddingBottom: 100,
    },
    container: {
      margin: '0 4%',
      textAlign: 'justify',
      [theme.breakpoints.up('md')]: {
        margin: '0 18%',
      },
    },

    head: {
      position: 'relative',
      marginBottom: 32,
      paddingTop: 50,
      [theme.breakpoints.up('md')]: {
        height: 400,
        marginBottom: 0,
      },
    },

    topTitleWrapper: {
      position: 'relative',
      zIndex: 10,
      [theme.breakpoints.up('md')]: {
        marginLeft: '-12%',
      },
    },
    topTitle: {
      color: constants.COLOR_MAIN_SECOND,
      fontFamily: '"Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif',
      fontSize: 18,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },

    user: {
      position: 'relative',
      padding: '10px',
      zIndex: 8,
      backgroundColor: constants.COLOR_MAIN,
      color: constants.TEXT_WHITE,
      fontFamily: '"Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif',
      fontSize: 18,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        margin: '40px auto 0',
        maxWidth: 480,
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        padding: '20px 70px 20px 50px',
        marginTop: 42,
        marginLeft: -50,
        fontSize: 24,
      },
    },
    userImg: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      '& img': {
        width: '100%',
        top: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 480,
        },
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
          width: '65.5%',
          maxWidth: 600,
        },
      },
    },

    qaTitle: {
      position: 'relative',
      zIndex: 10,
      fontFamily: '"Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif',
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.03em',
      lineHeight: 1.6,
      [theme.breakpoints.up('sm')]: {
        lineHeight: 1.3,
        marginRight: '23%',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
    qaTitleLine: {
      margin: '0 0 60px -4%',
      borderBottom: '5px solid #F6CF24',
      [theme.breakpoints.up('sm')]: {
        margin: '0 21% 60px -39%',
      },
    },
    qa: {
      whiteSpace: 'pre-wrap',
    },
    question: {
      marginBottom: 30,
      color: constants.COLOR_MAIN_SECOND,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    answer: {
      marginBottom: 60,
      fontSize: 16,
      lineHeight: 1.7,
      [theme.breakpoints.up('sm')]: {
        letterSpacing: '0.08em',
      },
    },

    scrollButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    scrollButtonText: {
      marginTop: 8,
      fontSize: 16,
      textAlign: 'center',
    },

    arrowLinks: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
    },
    arrowLink: {
      [theme.breakpoints.up('sm')]: {
        width: '42%',
      },
    },
  })

export default withStyles(useStyles)(Index)
