import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Observable } from 'zen-observable-ts'

import {
  Addresses,
  MasterConnection,
  MutationCreateTeamArgs,
  MutationCreateTeamMemberArgs,
  MutationDeleteTeamMemberArgs,
  MutationTransferTeamAdminAcceptArgs,
  MutationTransferTeamAdminArgs,
  MutationUpdateAccountArgs,
  MutationUpdateTeamAdminArgs,
  MutationUpdateTeamArgs,
  MutationUpdateTeamBuildingArgs,
  MutationUpdateTeamBuildingMemberArgs,
  MutationUpdateTeamMemberArgs,
  MutationCreateTeamBuildingActionArgs,
  MutationUpdateTeamBuildingActionArgs,
  MutationCreateTeamBuildingActionDatesArgs,
  QueryGetAddressByZipcodeArgs,
  QueryGetMastersArgs,
  QueryGetTeamArgs,
  QueryGetTeamBuildingArgs,
  QueryGetTeamBuildingMemberArgs,
  QueryGetTeamBuildingMembersArgs,
  QueryGetTeamMemberArgs,
  QueryGetTeamMembersArgs,
  QueryGetTeamsArgs,
  QueryGetTeamSummaryBuildingArgs,
  QueryGetUserArgs,
  QueryGetTeamBuildingActionArgs,
  QueryGetTeamBuildingActionsArgs,
  QueryGetTeamBuildingActionDatesArgs,
  Team,
  TeamBuilding,
  TeamBuildingMember,
  TeamBuildingAction,
  TeamBuildingMemberConnection,
  TeamBuildingActionConnection,
  TeamBuildingActionDateConnection,
  TeamConnection,
  TeamMember,
  TeamMemberConnection,
  TeamSummaryBuilding,
  User,
  MutationUpdateTeamBuildingActionDateArgs,
  TeamBuildingActionDate,
  MutationUpdateTeamPlanArgs,
  QueryGetTeamSummaryGhostsArgs,
  Ghost,
  MutationRefreshTeamBuildingArgs,
  MutationResetTeamBuildingArgs,
  QueryGetMasterArgs,
  Master,
  TeamBuildingResetConnection,
  TeamToolUsageStatus,
  QueryGetTeamToolUsageStatusArgs,
  MutationUpdateTeamMembersArgs,
  MutationCreateTeamMembersArgs,
  CreateTeamMembersResponse,
} from 'utils/generated'

export const queryGetUser = async (args: QueryGetUserArgs): Promise<User | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getUser($id: ID!) {
        getUser(id: $id) {
          id
          username
          fullName
          imageUrl
          email
          phone
          division
          position

          statusAdmin
          companyIconUrl
          companyName
          companyZipCode
          companyPrefecture
          companyAddress1
          companyAddress2
          companyPhone
          companyNumberOfEmployees
          companyManagerName
          contractAt
          onboardingContractAt

          billingCode
          accountType
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getUser: User }>
  return response.data?.getUser
}

export const mutationUpdateUser = async (args: MutationUpdateAccountArgs): Promise<User | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateAccount($input: UserUpdateInput!) {
        updateAccount(input: $input) {
          id
          imageUrl
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateAccount: User }>

  return response.data?.updateAccount
}

export const mutationUpdateTeamAdmin = async (args: MutationUpdateTeamAdminArgs): Promise<User | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamAdmin($input: UpdateTeamAdminInput!) {
        updateTeamAdmin(input: $input) {
          id
          username
          fullName
          email
          phone
          companyName
          companyZipCode
          companyPrefecture
          companyAddress1
          companyAddress2
          companyPhone
          companyManagerName
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamAdmin: User }>

  return response.data?.updateTeamAdmin
}

export const mutationTransferTeamAdmin = async (args: MutationTransferTeamAdminArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation transferTeamAdmin($input: TransferTeamAdminInput!) {
        transferTeamAdmin(input: $input)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ transferTeamAdmin: boolean }>

  return response.data?.transferTeamAdmin || false
}

export const mutationTransferTeamAdminAccept = async (args: MutationTransferTeamAdminAcceptArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation transferTeamAdminAccept($input: TransferTeamAdminAcceptInput!) {
        transferTeamAdminAccept(input: $input)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ transferTeamAdminAccept: boolean }>
  return response.data?.transferTeamAdminAccept || false
}

export const queryGetTeam = async (args: QueryGetTeamArgs): Promise<Team | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeam($id: ID!) {
        getTeam(id: $id) {
          id
          name
          leaderUserIds

          ownerUserId
          ownerUsername
          ownerFullName

          paymentMethod
          paymentLast4Digits
          cost
          countMember
          countInvitedMember
          countReservedMember
          memberInvitationDateTimes

          teamTools
          plans
          userLimit

          statusAdmin
          statusUsage

          statusBarComment
          statusBarCommentIcon

          statusViewTbCheckRespondents
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeam: Team }>

  return response.data?.getTeam
}

export const queryGetTeams = async (args: QueryGetTeamsArgs): Promise<TeamConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeams($limit: Int, $nextToken: String, $isSortByCreatedAt: Boolean) {
        getTeams(limit: $limit, nextToken: $nextToken, isSortByCreatedAt: $isSortByCreatedAt) {
          items {
            id
            name

            ownerUserId
            ownerUsername
            ownerFullName

            leaderFullNames
            leaderUserIds

            cost
            countMember
            countInvitedMember
            countReservedMember
            memberInvitationDateTimes

            statusAdmin
            statusUsage

            teamTools
            plans
            userLimit

            teamBuildingStartDate
            teamBuildingProgress

            teamBuildingReviewHistoryCount

            karteCount

            anketBuildingAverage
            anketBuildingGrowth
            anketBuildingValue
            anketBuildingDiff
            anketBuildingDate
            anketBuildingUpdatedAt

            ghostHistoryCount
            ghostLastPlayedDate
            ghostLastDiscoveredId
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeams: TeamConnection }>

  return response.data?.getTeams
}

export const queryGetTeamsManagedByAdmin = async () => {
  const response = (await API.graphql({
    query: gql`
      query getTeamsManagedByAdmin {
        getTeamsManagedByAdmin {
          id
          name

          ownerUserId
          ownerUsername
          ownerFullName

          statusAdmin
          statusUsage
          plans

          createdAt
        }
      }
    `,
  })) as GraphQLResult<{ getTeamsManagedByAdmin: Team[] }>

  return response.data?.getTeamsManagedByAdmin
}

export const mutationCreateTeam = async (args: MutationCreateTeamArgs): Promise<Team | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeam($input: CreateTeamInput!) {
        createTeam(input: $input) {
          id
          name
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ createTeam: Team }>

  return response.data?.createTeam
}

export const mutationUpdateTeam = async (args: MutationUpdateTeamArgs): Promise<Team | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeam($input: UpdateTeamInput!) {
        updateTeam(input: $input) {
          id
          name
          statusViewTbCheckRespondents
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeam: Team }>

  return response.data?.updateTeam
}

export const mutationUpdateTeamPlan = async (args: MutationUpdateTeamPlanArgs): Promise<Team | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamPlan($input: UpdateTeamPlanInput!) {
        updateTeamPlan(input: $input) {
          id
          name
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamPlan: Team }>

  return response.data?.updateTeamPlan
}

export const queryGetTeamMember = async (args: QueryGetTeamMemberArgs): Promise<TeamMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamMember($id: ID!) {
        getTeamMember(id: $id) {
          id
          teamId
          userId
          role
          fullName
          username
          organization
          position
          email
          statusUsage
          updatedAt
          teamKarteIds
          invitationDateTime

          user {
            imageUrl
          }
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamMember: TeamMember }>

  return response.data?.getTeamMember
}

export const queryGetTeamMembers = async (args: QueryGetTeamMembersArgs): Promise<TeamMemberConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamMembers($teamId: ID!, $filter: TeamMemberFilter, $limit: Int, $nextToken: String) {
        getTeamMembers(teamId: $teamId, filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            userId
            role
            fullName
            username
            organization
            position
            email
            statusUsage
            updatedAt
            teamKarteIds
            invitationDateTime

            user {
              imageUrl
            }
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamMembers: TeamMemberConnection }>

  return response.data?.getTeamMembers
}

export const mutationCreateTeamMembers = async (
  args: MutationCreateTeamMemberArgs
): Promise<TeamMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamMembers($input: CreateTeamMemberInput!) {
        createTeamMember(input: $input) {
          id
          teamId
          fullName
          username
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ createTeamMember: TeamMember }>

  return response.data?.createTeamMember
}

export const mutationCreateTeamMemberList = async (
  args: MutationCreateTeamMembersArgs
): Promise<CreateTeamMembersResponse | null | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamMembers($input: CreateTeamMembersInput!) {
        createTeamMembers(input: $input) {
          items {
            id
            teamId
            fullName
            username
            invitationDateTime
          }
          errors {
            email
            errorMessage
          }
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ createTeamMembers: CreateTeamMembersResponse }>

  return response.data?.createTeamMembers
}

export const mutationUpdateTeamMembers = async (
  args: MutationUpdateTeamMemberArgs
): Promise<TeamMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamMember($input: UpdateTeamMemberInput!) {
        updateTeamMember(input: $input) {
          id
          userId
          teamId
          fullName
          username
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamMember: TeamMember }>

  return response.data?.updateTeamMember
}

export const mutationUpdateTeamMemberList = async (
  args: MutationUpdateTeamMembersArgs
): Promise<Array<TeamMember> | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamMembers($input: UpdateTeamMembersInput!) {
        updateTeamMembers(input: $input) {
          id
          userId
          teamId
          fullName
          username
          invitationDateTime
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamMembers: TeamMember[] }>

  return response.data?.updateTeamMembers
}

export const mutationDeleteTeamMembers = async (args: MutationDeleteTeamMemberArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteTeamMember($id: ID!) {
        deleteTeamMember(id: $id)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ deleteTeamMember: boolean }>

  return response.data?.deleteTeamMember || false
}

export const queryGetAddressByZipcode = async (args: QueryGetAddressByZipcodeArgs): Promise<Addresses | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getAddressByZipcode($input: AddressInput!) {
        getAddressByZipcode(input: $input) {
          zipcode
          prefcode
          address1
          address2
          address3
          kana1
          kana2
          kana3
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getAddressByZipcode: Addresses }>
  return response.data?.getAddressByZipcode
}

export const mutationConvertTeamMember = async (): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation convertTeamMember {
        convertTeamMember
      }
    `,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ convertTeamMember: boolean }>

  return response.data?.convertTeamMember || false
}

export const queryGetTeamToolUsageStatus = async (
  args: QueryGetTeamToolUsageStatusArgs
): Promise<TeamToolUsageStatus | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamToolUsageStatus($teamTool: TeamTool!) {
        getTeamToolUsageStatus(teamTool: $teamTool) {
          activeTeamCount
          playedTeamCount
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamToolUsageStatus: TeamToolUsageStatus }>
  return response.data?.getTeamToolUsageStatus
}

export const queryGetTeamBuilding = async (args: QueryGetTeamBuildingArgs): Promise<TeamBuilding | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuilding($id: ID!) {
        getTeamBuilding(id: $id) {
          id
          status
          q1PresenNumber
          q1PresenUserIds
          q1PresenMinute
          q2PresenNumber
          q2PresenUserIds
          q2PresenMinute
          resultQ1
          resultQ2Pre
          resultQ2
          resultQ3
          resultQ4
          endAt
          createdAt
          updatedAt

          timeQ1Presen
          timeQ2Presen
          timeQ3Vote2
          timeCompleted

          timeQ1
          timeQ2
          timeQ2Vote02
          timeQ4
          timeQ5Actions
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuilding: TeamBuilding }>
  return response.data?.getTeamBuilding
}

export const mutationUpdateTeamBuilding = async (args: MutationUpdateTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuilding($input: UpdateTeamBuildingInput!) {
        updateTeamBuilding(input: $input) {
          id
          status
          q1PresenNumber
          q1PresenUserIds
          resultQ1
          resultQ2Pre
          resultQ2
          resultQ3
          resultQ4
          endAt
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamBuilding: TeamBuilding }>

  return response.data?.updateTeamBuilding
}

export const mutationResetTeamBuilding = async (args: MutationResetTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation resetTeamBuilding($id: ID!) {
        resetTeamBuilding(id: $id) {
          isTransactionCompleted
          resetInOrderProgress {
            deletingData
            completedSteps
            notCompletedSteps
            isAllCompleted
          }
          error {
            code
            time
            requestId
            statusCode
            errorMessage
          }
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ resetTeamBuilding: TeamBuildingResetConnection }>

  return response.data?.resetTeamBuilding
}

export const mutationRefreshTeamBuilding = async (args: MutationRefreshTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation refreshTeamBuilding($input: RefreshTeamBuildingInput!) {
        refreshTeamBuilding(input: $input) {
          id
          status
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ refreshTeamBuilding: TeamBuilding }>

  return response.data?.refreshTeamBuilding
}

export const subscriptionUpdatedTeamBuilding = () => {
  const subscribe = API.graphql({
    query: gql`
      subscription {
        updatedTeamBuilding {
          id
          status
        }
      }
    `,
  })

  return subscribe as Observable<object>
}

export const subscriptionUpdatedTeamBuildingMember = () => {
  const subscribe = API.graphql({
    query: gql`
      subscription {
        updatedTeamBuildingMember {
          id
          teamId
        }
      }
    `,
  })

  return subscribe as Observable<object>
}

export const queryGetTeamSummaryBuilding = async (
  args: QueryGetTeamSummaryBuildingArgs
): Promise<TeamSummaryBuilding | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamSummaryBuilding($teamId: ID!) {
        getTeamSummaryBuilding(teamId: $teamId) {
          goal
          codeOfConducts
          progress
          latestActions {
            id
            name
            teamBuildingActionId
          }
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamSummaryBuilding: TeamSummaryBuilding }>

  return response.data?.getTeamSummaryBuilding
}

export const queryGetTeamSummaryGhosts = async (args: QueryGetTeamSummaryGhostsArgs): Promise<Ghost[] | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamSummaryGhosts($masterTeamId: ID!, $limit: Int) {
        getTeamSummaryGhosts(masterTeamId: $masterTeamId, limit: $limit) {
          id
          name
          sympton
          scenes
          pro
          con
          why
          solution
          actions
          anti
          caveats
          found # number of this ghost in GhostHistory table
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamSummaryGhosts: Ghost[] }>

  return response.data?.getTeamSummaryGhosts
}

export const queryGetMasters = async (args: QueryGetMastersArgs): Promise<MasterConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getMasters($type: MasterType!, $limit: Int, $nextToken: String) {
        getMasters(type: $type, limit: $limit, nextToken: $nextToken) {
          items {
            id
            type
            sort
            title
            reason
            detail
            timing
            code
            name
            description
            businessName
            businessDescription
            tools
            limit
            price
            publicRange
            parentId
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getMasters: MasterConnection }>
  return response.data?.getMasters
}

export const queryGetMaster = async (args: QueryGetMasterArgs): Promise<Master | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getMaster($id: ID!) {
        getMaster(id: $id) {
          id
          type
          sort
          title
          reason
          detail
          timing
          code
          name
          description
          businessName
          businessDescription
          tools
          limit
          price
          publicRange
          parentId
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ getMaster: Master }>
  return response.data?.getMaster
}

export const queryGetTeamBuildingMember = async (
  args: QueryGetTeamBuildingMemberArgs
): Promise<TeamBuildingMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingMember($id: ID!) {
        getTeamBuildingMember(id: $id) {
          id
          teamId
          userId
          resultQ1
          resultQ2Pre
          resultQ2
          resultQ3
          resultQ4
          resultQ5
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuildingMember: TeamBuildingMember }>

  return response.data?.getTeamBuildingMember
}

export const queryGetTeamBuildingMembers = async (
  args: QueryGetTeamBuildingMembersArgs
): Promise<TeamBuildingMemberConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingMembers($teamId: ID!, $limit: Int, $nextToken: String) {
        getTeamBuildingMembers(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            userId
            resultQ1
            resultQ2Pre
            resultQ2
            resultQ3
            resultQ4
            resultQ5
            dummyHash
            createdAt
            updatedAt

            user {
              id
              email
              nickname
              firstName
              lastName
              imageUrl
            }

            teamMember {
              fullName
              username
              role
            }
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuildingMembers: TeamBuildingMemberConnection }>

  return response.data?.getTeamBuildingMembers
}

export const mutationUpdateTeamBuildingMember = async (
  args: MutationUpdateTeamBuildingMemberArgs
): Promise<TeamBuildingMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingMember($input: UpdateTeamBuildingMemberInput!) {
        updateTeamBuildingMember(input: $input) {
          id
          teamId
          userId
          resultQ1
          resultQ2Pre
          resultQ2
          resultQ3
          resultQ4
          resultQ5
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamBuildingMember: TeamBuildingMember }>

  return response.data?.updateTeamBuildingMember
}

export const mutationCreateTeamBuildingAction = async (
  args: MutationCreateTeamBuildingActionArgs
): Promise<TeamBuildingAction | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamBuildingAction($input: CreateTeamBuildingActionInput!) {
        createTeamBuildingAction(input: $input) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ createTeamBuildingAction: TeamBuildingAction }>

  return response.data?.createTeamBuildingAction
}

export const mutationUpdateTeamBuildingAction = async (
  args: MutationUpdateTeamBuildingActionArgs
): Promise<TeamBuildingAction | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingAction($input: UpdateTeamBuildingActionInput!) {
        updateTeamBuildingAction(input: $input) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamBuildingAction: TeamBuildingAction }>

  return response.data?.updateTeamBuildingAction
}

export const mutationCreateTeamBuildingActionDates = async (
  args: MutationCreateTeamBuildingActionDatesArgs
): Promise<boolean | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamBuildingActionDates($input: CreateTeamBuildingActionDatesInput!) {
        createTeamBuildingActionDates(input: $input)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ createTeamBuildingActionDates: boolean }>

  return response.data?.createTeamBuildingActionDates
}

export const mutationUpdateTeamBuildingActionDate = async (
  args: MutationUpdateTeamBuildingActionDateArgs
): Promise<TeamBuildingActionDate | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingActionDate($input: UpdateTeamBuildingActionDateInput!) {
        updateTeamBuildingActionDate(input: $input) {
          id
          date
          status
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ updateTeamBuildingActionDate: TeamBuildingActionDate }>

  return response.data?.updateTeamBuildingActionDate
}

export const queryGetTeamBuildingAction = async (
  args: QueryGetTeamBuildingActionArgs
): Promise<TeamBuildingAction | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingAction($id: ID!) {
        getTeamBuildingAction(id: $id) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuildingAction: TeamBuildingAction }>

  return response.data?.getTeamBuildingAction
}

export const queryGetTeamBuildingActions = async (
  args: QueryGetTeamBuildingActionsArgs
): Promise<TeamBuildingActionConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingActions($teamId: ID!, $limit: Int, $nextToken: String) {
        getTeamBuildingActions(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            assignedTeamMemberId
            assignedTeamMemberName
            name
            reason
            content
            timing
            editable
            dummyHash
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuildingActions: TeamBuildingActionConnection }>

  return response.data?.getTeamBuildingActions
}

export const queryGetTeamBuildingActionDates = async (
  args: QueryGetTeamBuildingActionDatesArgs
): Promise<TeamBuildingActionDateConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingActionDates(
        $id: ID!
        $index: IndexGetTeamBuildingActionDates!
        $limit: Int
        $nextToken: String
        $startDate: String
        $endDate: String
      ) {
        getTeamBuildingActionDates(
          id: $id
          index: $index
          limit: $limit
          nextToken: $nextToken
          startDate: $startDate
          endDate: $endDate
        ) {
          items {
            id
            teamId
            teamBuildingActionId
            name
            assignedTeamMemberId
            assignedTeamMemberName
            date
            status
            dummyHash
            createdAt
            updatedAt

            teamBuildingAction {
              name
              assignedTeamMemberId
              assignedTeamMemberName
            }
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as GraphQLResult<{ getTeamBuildingActionDates: TeamBuildingActionDateConnection }>

  return response.data?.getTeamBuildingActionDates
}
