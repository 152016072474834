import { TeamKarte } from 'utils/generated'

import { KarteResult } from '../pages/diagnose/utils'

export const getKarteResult = (data?: string): KarteResult | undefined => {
  if (!data) {
    return undefined
  }

  const result = JSON.parse(data)

  if (result) {
    return result as KarteResult
  }
  return undefined
}

export const getTeamMemberKarteList = (userId: string, teamKarteList: TeamKarte[]) =>
  teamKarteList
    .filter((karte) => karte.userId === userId)
    .sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      } else if (a.createdAt > b.createdAt) {
        return -1
      } else {
        return 0
      }
    })

export enum KarteValueType {
  Result = 'result', // 成果重視傾向
  Achievement = 'achievement', // 実績重視主義傾向
  TeamBuilding = 'team-building', // チーム作り重視傾向
}

export const getKarteValueType = (title: string | undefined): KarteValueType | null => {
  switch (title) {
    case '社会・顧客とのつながり':
    case '上司とのつながり':
    case '職場の仲間とのつながり':
      return KarteValueType.TeamBuilding
    case '仕事の明確さ':
    case '仕事のコントロール':
    case '自律性尊重の風土':
      return KarteValueType.Achievement
    case '能力発揮の実感':
    case '成長につながる仕事':
    case '成長を後押しする環境':
      return KarteValueType.Result
    // 傾向の一覧
    // case 'テキパキお仕事処理傾向':
    // case '手堅い成長重視傾向':
    // case '自分の役割遂行傾向':
    // case '自己決定が大切傾向':
    // case '自分で成長します傾向':
    // case '自分が何とかしたい傾向':
    // case '公私充実するぞ傾向':
    // case '自己研鑽好き傾向':
    // case '絶妙な距離感重視傾向':
    // case '日々の手応え大切傾向':
    // case 'アスリート志向な傾向':
    // case '誰かの役に立ちたい傾向':
    // case '成長できる仕事求む傾向':
    // case '自分の理想追求傾向':
    // case '成長とのバランス重視傾向':
    // case '環境を活かした自己成長重視傾向':
    // case '高い成長欲求傾向':
    // case '共に成長しよう傾向':
    // case '価値貢献に携わりたい傾向':
    // case 'ストイック傾向':
    // case 'チームミッション追求傾向':
    // case '上司とタッグ傾向':
    // case '可愛い部下傾向':
    // case '部下として安心傾向':
    // case '自分のできることから貢献するぞ傾向':
    // case '目指せ甲子園傾向':
    // case 'チーム作り重視傾向':
    //   return KarteValueType.Achievement
    default:
      return null
  }
}
