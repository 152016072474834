import React from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonRenewal as Button, Props as ButtonProps } from 'components/ButtonRenewal'
import { useTeams } from 'pages/onboarding/hooks/team'

import * as constants from 'assets/constants'
import { TO_ONBOARDING_TIMELINE } from 'assets/pages'

export const StyledButton = (props: { buttonColor: 'red' | 'main' } & ButtonProps) => (
  <Button
    bgColor={props.buttonColor === 'red' ? constants.COLOR_RED3 : undefined}
    borderColor={props.buttonColor === 'red' ? constants.COLOR_RED3 : undefined}
    hoverColor={props.buttonColor === 'red' ? constants.COLOR_RED3 : undefined}
    padding={'0 10px'}
    {...props}
  >
    {props.children}
  </Button>
)

export const PlanStyledButton = () => {
  const history = useHistory()

  const handleToPlan = () => {
    history.push(constants.PAGE_PLAN)
    window.scrollTo(0, 0)
  }

  return (
    <StyledButton
      buttonColor="main"
      onClick={handleToPlan}
      width="100%"
      maxWidth="none"
      widthSP="100%"
      maxWidthSP="none"
      height="100%"
      heightSP="100%"
    >
      料金を詳しく見る
    </StyledButton>
  )
}

export const OnbStyledButton = () => {
  const { teams, loading } = useTeams()
  const [defaultTeamId, setDefaultTeamId] = React.useState<null | string>(null)
  const history = useHistory()

  const handleUseOnb = () => {
    if (defaultTeamId) {
      history.push(TO_ONBOARDING_TIMELINE(defaultTeamId))
      window.scrollTo(0, 0)
    }
  }

  React.useEffect(() => {
    if (teams.length > 0) {
      setDefaultTeamId(teams[0].id)
    }
  }, [teams])

  if (loading || !defaultTeamId) {
    return null
  }
  return (
    <StyledButton
      buttonColor="red"
      onClick={handleUseOnb}
      width="100%"
      maxWidth="none"
      widthSP="100%"
      maxWidthSP="none"
      height="100%"
      heightSP="100%"
    >
      利用開始
    </StyledButton>
  )
}
