import React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { SelectItem, SelectItemIcon } from './SelectItem'

interface Props {
  items: SelectItem[]
  icon?: SelectItemIcon
  onClick?: (name: string, value: boolean) => void
  activateGrid?: boolean
  isFlexBottomNode?: boolean
  unclickable?: boolean
}

export type SelectItem = {
  prefixIcon?: React.ReactNode
  name: string
  label: string
  value?: boolean
  bottomNode?: React.ReactNode
}

export const SelectItems: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      {props.items.map((item, index) => (
        <Grid item xs={12} lg={props.activateGrid ? 6 : false} key={`select-item-${index}`}>
          <SelectItem
            name={item.name}
            label={item.label}
            value={item.value}
            icon={props.icon}
            onClick={props.onClick}
            prefixIcon={item.prefixIcon}
            bottomNode={item.bottomNode}
            isFlexBottomNode={props.isFlexBottomNode}
            unclickable={props.unclickable}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      // marginBottom: 8,
    },
  },
}))
