import React from 'react'

import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { TeamBuildingStatus } from 'utils/generated'

import { LoadingCircular } from '../../../components'

import { Pages, useHistory } from 'assets'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsToolsBuildingRootPage: React.FC<Props> = (props) => {
  const { teamId } = props.match.params
  const { teamBuilding, loadedTeamBuilding } = React.useContext(HooksContext)
  const { route } = useHistory()

  if (loadedTeamBuilding) {
    if (!teamBuilding || teamBuilding?.status !== TeamBuildingStatus.Completed) {
      route.push(Pages.TeamsToolBuildingSubs, { teamId })
    } else {
      route.push(Pages.TeamsToolBuildingDashboard, { teamId })
    }
  }

  return <LoadingCircular loading={true} />
}
