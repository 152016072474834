import { useContext } from 'react'

import { useDispatch } from 'react-redux'

import { hintContext } from '../context/useHintContext'
import actions from '../stores/actions'
import { createConsultation, ICreateConsultationParams } from '../stores/apis/createConsultation'

import * as registers from 'assets/registers'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  createConsultationEpic: (params: ICreateConsultationParams, isPublished: number) => Promise<void>
}

// -----------------------------
// hooks
// -----------------------------
export const useConsultationModalEpic = (): IProps => {
  const dispatch = useDispatch()
  const context = useContext(hintContext)

  /**
   * Tipsの作成時の処理
   */
  const createConsultationEpic = async (params: ICreateConsultationParams, isPublished: number): Promise<void> => {
    await createConsultation({ ...params, isPublished: isPublished })
      .then((res) => {
        dispatch(actions.createConsultation.done({ params: { ...params, isPublished: isPublished }, result: res }))
      })
      .catch((e) => {
        dispatch(
          actions.createConsultation.failed({
            params: { ...params },
            error: registers.MODAL_CONSULTATION_ACTIONS.CREATE.MESSAGE,
          })
        )
        context.onCloseModal()
        throw e
      })
  }

  return {
    createConsultationEpic,
  }
}
