import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { converts } from 'pages/onboarding/utils/convertText'
import { OnboardingTeamMember } from 'utils/generated'

import * as constants from 'assets/constants'

type Props = {
  text: string
  teamMembers: OnboardingTeamMember[]
  isPost?: boolean
}

type StyleProps = {
  isLongText: boolean
  isPost: boolean | undefined
}

export const Comment: React.FC<Props> = ({ text, teamMembers, isPost }) => {
  const [isLongText, setIsLongText] = React.useState(false)
  const commentRef = React.useRef<HTMLDivElement>(null)
  const classes = useStyles({ isLongText, isPost })
  const checkHeight = isPost ? 150 : 80

  React.useEffect(() => {
    if (commentRef.current) {
      const { height } = commentRef.current.getBoundingClientRect()
      if (height > checkHeight) {
        setIsLongText(true)
      }
    }
    return () => {
      setIsLongText(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef.current?.getBoundingClientRect().height])

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={classes.message}
        dangerouslySetInnerHTML={{ __html: converts(text, teamMembers) }}
        ref={commentRef}
      />
      {isLongText ? (
        <div className={classes.messageMore}>
          <span onClick={() => setIsLongText(false)}>
            続きを読む
            <ArrowForwardIosIcon className={classes.messageMoreIcon} />
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  message: (props) => ({
    backgroundColor: !props.isPost ? constants.COLOR_ONBOARDING_WHITE : undefined,
    marginTop: !props.isPost ? 8 : undefined,
    padding:
      props.isLongText && props.isPost
        ? '24px 0'
        : props.isLongText && !props.isPost
        ? '8px 8px 24px'
        : props.isPost
        ? '24px 0 0'
        : 8,
    borderRadius: 8,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    lineHeight: 1.43,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
    display: props.isLongText ? '-webkit-box' : undefined,
    overflow: props.isLongText ? 'hidden' : undefined,
    '-webkit-box-orient': props.isLongText ? 'vertical' : undefined,
    '-webkit-line-clamp': props.isLongText && props.isPost ? 7 : props.isLongText && !props.isPost ? 4 : undefined,
    '& a': {
      color: '#2CBBC6',
      textDecoration: 'underline',
      pointerEvents: props.isPost ? 'none' : undefined,
    },
  }),
  messageMore: (props) => ({
    position: 'absolute',
    backgroundColor: !props.isPost ? constants.COLOR_ONBOARDING_WHITE : constants.COLOR_WHITE,
    bottom: 0,
    left: 0,
    padding: !props.isPost ? '5px 8px' : '10px 0',
    height: 43,
    borderRadius: 8,
    width: '100%',
    textAlign: 'right',
    '& span': {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 12,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }),
  messageMoreIcon: {
    fontSize: 14,
    position: 'relative',
    top: 3,
    left: 3,
  },
}))
