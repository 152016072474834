import React, { useState, useCallback, useMemo } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import dayjs from 'dayjs'

import { NoStyleButton } from 'components/NoStyleButton'
import { RankingItemBox } from 'pages/onboarding/components/RankingItemBox'
import {
  periodList,
  PeriodList,
  periodDate,
  categories,
  Categories,
  SrcRanking,
  OrganizedRanking,
} from 'pages/onboarding/utils/ranking'
import { OnboardingTeamMember, OnboardingTeamMemberRole } from 'utils/generated'

import arrowDown from '../assets/arrow-down.svg'
import arrowUp from '../assets/arrow-up.svg'
import * as constants from 'assets/constants'


export const RankingBoxBloc = {
  useAdapter: () => {
    const titles = {
      [categories.ownActions]: 'アクション達成数',
      [categories.ownPosts]: '投稿数',
      [categories.ownLikes]: 'いいねした数',
    }

    const [allMembersAction, setAllMembersAction] = useState(false)
    const [allMembersPost, setAllMembersPost] = useState(false)
    const [allMembersLike, setAllMembersLike] = useState(false)
    const statesAllMembers = {
      [categories.ownActions]: allMembersAction,
      [categories.ownPosts]: allMembersPost,
      [categories.ownLikes]: allMembersLike,
    }

    const handleToggleMembers = useCallback((category: Categories) => {
      const setStatesAllMembers = {
        [categories.ownActions]: setAllMembersAction,
        [categories.ownPosts]: setAllMembersPost,
        [categories.ownLikes]: setAllMembersLike,
      }

      setStatesAllMembers[category]((prev) => !prev)
    }, [])

    const dataController = useCallback((items: SrcRanking, category: Categories, period: PeriodList) => {
      // Action is determined by 'updatedAt' because 'createdAt' does not exist.
      const filterPeriod = (periods: { createdAt?: string; updatedAt?: string }[]) => {
        switch (period) {
          case periodList.lastWeek:
            return periods.filter((p) => dayjs(p.createdAt ?? p.updatedAt).isAfter(periodDate.lastWeek))
          case periodList.yesterday:
            return periods.filter(
              (p) => dayjs(p.createdAt ?? p.updatedAt).format('YYYY-MM-DD') === periodDate.yesterday
            )
          case periodList.lastMonth:
            return periods.filter((p) => dayjs(p.createdAt ?? p.updatedAt).isAfter(periodDate.lastMonth))
          case periodList.entire:
          default:
            return periods
        }
      }

      const mappedData = items.map((item) => ({
        id: item.id,
        userId: item.userId,
        src: item.imageUrl,
        name: item.nickname && item.nickname !== '名無し' ? item.nickname : item.email ?? '',
        role: item.role,
        count: filterPeriod(item[category]).length,
      }))

      // Only members are displayed in case of actions.
      const categorizedData =
        category === 'ownActions' || category === 'ownPosts'
          ? mappedData.filter((data) => data.role === OnboardingTeamMemberRole.Member)
          : mappedData.filter((data) => data.role !== OnboardingTeamMemberRole.Admin)

      const sortedData = categorizedData
        .sort((a, b) => a.name.localeCompare(b.name, 'ja')) // Sort by Name in Japanese.
        .sort((x, y) => y.count - x.count)

      const rankingData: OrganizedRanking = []
      sortedData.forEach((data, i) => {
        rankingData.push({
          ...data,
          barWidth: data.count ? Math.round((data.count / sortedData[0].count) * 100) : 0, // Prevent NaN (0 / 0 = NaN).
          rank: !rankingData.length
            ? 1 // for 1st
            : data.count === rankingData.slice(-1)[0].count
            ? rankingData.slice(-1)[0].rank // for tie rank
            : i + 1,
        })
      })

      return rankingData
    }, [])

    return { titles, statesAllMembers, handleToggleMembers, dataController }
  },
}

type Props = {
  category: Categories
  teamId: string
  period: PeriodList
  items: OnboardingTeamMember[]
}

export const RankingBox = ({ category, teamId, period, items }: Props) => {
  const classes = useStyles()
  const { titles, statesAllMembers, handleToggleMembers, dataController } = RankingBoxBloc.useAdapter()

  const allData = useMemo(
    () => dataController(items as SrcRanking, category, period),
    [category, items, period, dataController]
  )
  const top3 = allData.slice(0, 3)
  const allMembers = statesAllMembers[category]

  return (
    <div className={classes.rankingBox}>
      <div className={classes.rankingHead}>
        <h2 className={classes.rankingTitle}>{titles[category]}</h2>
        {allData.length > 3 && (
          <NoStyleButton className={classes.expand} type="button" onClick={() => handleToggleMembers(category)}>
            <span>{allMembers ? '閉じる' : '全メンバー表示'}</span>
            <img src={allMembers ? arrowUp : arrowDown} alt="" />
          </NoStyleButton>
        )}
      </div>

      <div className={classes.data}>
        {allData.length ? (
          (allMembers ? allData : top3).map((item) => (
            <RankingItemBox rankingItem={item} teamId={teamId} key={item.id} />
          ))
        ) : (
          <span className={classes.noData}>対象のユーザーが存在しません</span>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    rankingBox: {
      backgroundColor: constants.COLOR_WHITE,
      padding: 24,
      [theme.breakpoints.down('xs')]: {
        padding: '30px 16px 16px',
      },
    },
    rankingHead: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    rankingTitle: {
      margin: 0,
      fontSize: 14,
      fontWeight: 'bold',
    },
    expand: {
      '& span': {
        marginRight: 8,
        color: constants.COLOR_MAIN_NEW,
        fontSize: 12,
        fontWeight: 'bold',
      },
      '& img': {
        position: 'relative',
        top: -1,
      },
    },
    data: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      marginTop: 28,
      [theme.breakpoints.down('xs')]: {
        rowGap: 16,
      },
    },
    noData: {
      color: constants.TEXT_GRAY2,
      fontSize: 14,
    },
    barBack: {
      position: 'relative',
      flex: 1,
      minWidth: 0,
      width: '100%',
      height: 16,
      borderRadius: 8,
      overflow: 'hidden',
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      [theme.breakpoints.down('xs')]: {
        minHeight: 16,
      },
    },
    barWrapper: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    bar: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: constants.COLOR_WHITE,
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 1,
      overflow: 'hidden',
      '& span': {
        position: 'relative',
        right: 8,
      },
    },
    narrowCount: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      marginLeft: 8,
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
  { name: 'RankingBox' }
)
