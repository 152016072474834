import { GraphQLResult } from '@aws-amplify/api'

// GraphQLResult型のエラープロパティをチェックするヘルパー関数
export const isErrorWithGraphQLErrors = (error: any): error is { errors?: GraphQLResult['errors'] } => {
  return error && Array.isArray(error.errors)
}

export const ERROR_MESSAGES_FOR_HATARAKU = {
  UNEXPECTED: '予期しないエラー形式が検出されました。サポートチームに連絡するか、もう一度お試しください。',
  CREATE_FAILED: '診断結果の保存に失敗しました。サポートチームに連絡するか、もう一度お試しください。',
}
