import { Storage, Auth } from 'aws-amplify'

import { ErrorSnapshot } from './error'

export const exportDebugToS3 = async (err: unknown, snapshot?: ErrorSnapshot) => {
  const datetime = new Date().toISOString()
  const fileName = datetime + '.log'

  const { attributes } = await Auth.currentAuthenticatedUser()
  const userData = JSON.stringify(attributes)

  let errData
  if (typeof err === 'string') {
    errData = err.toUpperCase()
  } else if (err instanceof Error) {
    errData = err.message
  }

  const varsData = snapshot?.vars ? JSON.stringify(snapshot.vars, null, 4) : ''

  const content = `
  ERROR LOG AT ${datetime}

  USER DATA:
  ${userData}

  ERROR MESSAGE:
  ${errData}

  VARS WHEN ERROR OCCURED:
  ${varsData}
  `

  const href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content)

  fetch(href)
    .then((res) => res.blob())
    .then((blob) => {
      Storage.put(`ghost-debug-${datetime.slice(0, 10)}/` + fileName, blob, { level: 'private' })
    })
}
