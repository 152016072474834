import * as React from 'react'

import Box from '@material-ui/core/Box'
import { Theme, makeStyles } from '@material-ui/core/styles'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    width: '100%',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',

    '& a': {
      color: '#8D8D8D',
      textDecoration: 'underline dotted',
      cursor: 'pointer',
    },

    '& img': {
      maxWidth: '100%',
      width: '100%',
    },

    '& h1': {
      margin: 0,
      fontSize: '20px',
    },

    '& ul': {
      padding: '0 16px',
      '& li': {
        lineHeight: 1.4,
        '&::marker': {
          marginRight: '4px',
          color: '#48C13A',
        },

        '&::before': {
          lineHeight: 1.4,
          marginRight: '4px',
          color: '#48C13A',
        },
      },
    },

    '& ol': {
      padding: '0 16px',
      '& li': {
        lineHeight: 1.4,
        '&::marker': {
          marginRight: '4px',
          color: '#48C13A',
        },

        '&::before': {
          lineHeight: 1.4,
          marginRight: '8px',
          color: '#48C13A',
        },
      },
    },

    '& h2': {
      margin: 0,
      fontSize: '16px',
    },

    '& p': {
      fontSize: '14px',
      lineHeight: 1.65,
      margin: 0,
    },

    '& pre': {
      fontSize: '16px',
      marginTop: '32px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      padding: '24px 30px',
      position: 'relative',
      backgroundColor: 'rgba(72, 193, 58, 0.1)',

      '& span': {
        backgroundColor: 'transparent !important',
        fontFamily: '-apple-system, sans-serif',
      },

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-20px',
        left: '34px',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 9px 20px 9px',
        borderColor: 'transparent transparent rgba(72, 193, 58, 0.1) transparent',
      },
    },

    '& blockquote': {
      fontSize: '14px',
      backgroundColor: '#F5F5F5',
      padding: '24px 30px',
      margin: 0,
      border: 'none',

      '& span': {
        backgroundColor: 'transparent !important',
      },
    },
  },

  ellipsis: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
  className?: string
  ellipsis?: boolean
  color?: string
  lineHeight?: number
}

// -----------------------------
// Component
// -----------------------------
export const Content = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: props.text }}
      className={`${classes.root} ${props.className} ${props.ellipsis ? classes.ellipsis : ''}`}
      style={{
        color: props.color,
        lineHeight: props.lineHeight,
      }}
    />
  )
}
