import * as React from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { UsefulCard } from '../atoms'

type Props = {
  onClick?: () => void
  onEventClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  clickable?: boolean
  ownStyles?: React.CSSProperties
  isPullDownTab?: boolean
}

export const GhostDetailLabel: React.FC<Props> = (props) => {
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <UsefulCard
      backgroundColor={props.clickable ? 'lightGray3' : 'white'}
      onClick={props.clickable ? (props.onClick ? props.onClick : props.onEventClick) : undefined}
      borderRadius={25}
      ownStyles={{
        width: !props.isPullDownTab ? 161 : mediaQuery ? 250 : '100%',
        fontSize: 15,
        color: props.clickable ? constGhost.COLOR_GRAY2 : constGhost.COLOR_MAIN_NEW,
        padding: !props.isPullDownTab ? '10px 0' : '13px 0 13px 16px',
        boxShadow: 'none',
        textAlign: 'center',
        cursor: 'pointer',
        boxSizing: props.isPullDownTab && props.clickable ? 'border-box' : undefined,
        ...props.ownStyles,
      }}
    >
      {props.children}
    </UsefulCard>
  )
}
