import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { CircularProgress, Modal, OutlinedInput } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import AlertDialog from '../../../../components/AlertDialog'
import UsefulButton from '../../../../components/Button'
import Button from '../../../../components/Button'
import MessagePanel from '../../../../components/MessagePanel'
import UsefulCollapse from '../../../../components/UsefulCollapse'
import { randomDigitsString } from '../../../../services/utils/random'
import { cookieKeys } from '../static'

import { connector, ContainerProps } from './index.container'

interface IInput {
  [key: string]: string
  hope1: string
  hope2: string
  hope3: string
  expectation1: string
  expectation2: string
  expectation3: string
}

interface TagProps {
  active: any
  handleSelectTag: any
  refresh: boolean
  setRefresh: any
}

const Tags: React.FC<TagProps> = (props: TagProps) => {
  React.useEffect(() => {
    if (props.refresh) {
      props.setRefresh(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh])
  return (
    <div className="__container__content__body__tagContainer__tags">
      {[
        '仕事の明確さ',
        '仕事のコントロール',
        '自律性尊重の風土',
        '能力発揮の実感',
        '成長につながる仕事',
        '成長を後押しする環境',
        '社会・顧客とのつながり',
        '上司とのつながり',
        '職場の仲間とのつながり',
      ].map((title, index) => {
        return (
          <div
            className={`__container__content__body__tagContainer__tags__tag ${props.active(title) ? 'active' : ''}`}
            onClick={() => {
              props.handleSelectTag(title)
            }}
            key={'hopeTag-' + index}
          >
            {title}
          </div>
        )
      })}
    </div>
  )
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [cookies, , removeCookie] = useCookies([cookieKeys.name])

  const [name, setName] = React.useState('')
  React.useEffect(() => {
    const cookiesName = cookies[cookieKeys.name]
    if (!cookiesName) {
      props.history.push(pages.KARTE_LEADER_SEARCH)
      return
    } else {
      setName(cookiesName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [backConfirm, setBackConfirm] = React.useState(false)
  const handleBackConfirm = () => {
    setBackConfirm(true)
  }

  const handleBack = () => {
    removeCookie(cookieKeys.name)
    props.history.push(pages.KARTE_LEADER_SEARCH)
    window.scrollTo(0, 0)
  }

  // hope
  const [currentHopeInput, setCurrentHopeInput] = React.useState('')
  const [refreshHopeTags, setRefreshHopeTags] = React.useState(false)
  const handleClickHopeInput = (name: string) => {
    setCurrentHopeInput(name)
  }
  const handleSelectHopeTag = (title: string) => {
    if (getValues('hope1') === title) {
      setValue('hope1', '')
    }

    if (getValues('hope2') === title) {
      setValue('hope2', '')
    }

    if (getValues('hope3') === title) {
      setValue('hope3', '')
    }
    if (currentHopeInput) {
      setValue(currentHopeInput, title)
    }
    setRefreshHopeTags(true)
  }
  const activeHope = (title: string) => {
    if (getValues('hope1') === title) {
      return true
    }

    if (getValues('hope2') === title) {
      return true
    }

    if (getValues('hope3') === title) {
      return true
    }
  }

  // expectation
  const [currentExpectationInput, setCurrentExpectationInput] = React.useState('')
  const [refreshExpectationTags, setRefreshExpectationTags] = React.useState(false)
  const handleClickExpectationInput = (name: string) => {
    setCurrentExpectationInput(name)
  }
  const handleSelectExpectationTag = (title: string) => {
    if (getValues('expectation1') === title) {
      setValue('expectation1', '')
    }

    if (getValues('expectation2') === title) {
      setValue('expectation2', '')
    }

    if (getValues('expectation3') === title) {
      setValue('expectation3', '')
    }
    if (currentExpectationInput) {
      setValue(currentExpectationInput, title)
    }
    setRefreshExpectationTags(true)
  }
  const activeExpectation = (title: string) => {
    if (getValues('expectation1') === title) {
      return true
    }

    if (getValues('expectation2') === title) {
      return true
    }

    if (getValues('expectation3') === title) {
      return true
    }
  }

  // submit
  const { register, handleSubmit, setValue, errors, getValues } = useForm<IInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data: IInput) => {
    const leaderNumber = `L${randomDigitsString(6)}`
    props.onStore(props.match.params.id, name, leaderNumber, data, () => {
      props.history.push(`/karte/leader/questionnaire/${leaderNumber}/results`)
      window.scrollTo(0, 0)
    })
  }

  const renderLoading = () => {
    if (!props.isLoading) return null
    return (
      <Modal
        open={props.isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disableBackdropClick={true}
      >
        <CircularProgress className={classes.loadingCircularProgress} />
      </Modal>
    )
  }

  const renderError = () => {
    if (!props.error) return null

    const onClose = () => {
      props.onResetError()
    }

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="__container">
            <div className="__container__header">
              <div style={{ width: '100%', textAlign: 'center' }}>リーダー診察室</div>
            </div>
            <div className="__container__content">
              <div className="__container__content__title">
                自分が重視していることと
                <br />
                {name}さんが重視していること
              </div>
              <div className="__container__content__description">
                自分が重視していることを振り返り、{name}さんが
                重視していると思うことを予想し、それぞれ上位3つずつ選択してください。
              </div>

              <div className="__container__content__body">
                <div className="__container__content__body__title">１. 自分が重視しているもの（振り返り）</div>
                <div className="__container__content__body__inputs">
                  <OutlinedInput
                    name="hope1"
                    onClick={() => {
                      handleClickHopeInput('hope1')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">1位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                  <OutlinedInput
                    name="hope2"
                    onClick={() => {
                      handleClickHopeInput('hope2')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">2位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                  <OutlinedInput
                    name="hope3"
                    onClick={() => {
                      handleClickHopeInput('hope3')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">3位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />

                  {(errors.hope1 || errors.hope2 || errors.hope3) && (
                    <small style={{ color: 'red' }}>全て入力が必須です</small>
                  )}
                </div>

                <div className="__container__content__body__tagContainer">
                  <Tags
                    active={activeHope}
                    handleSelectTag={handleSelectHopeTag}
                    refresh={refreshHopeTags}
                    setRefresh={setRefreshHopeTags}
                  />

                  <UsefulCollapse
                    openLabel="各カテゴリの説明を詳しくみる"
                    body={
                      <div className={classes.collapseBody}>
                        <p>
                          <label>仕事の明確さ</label>
                          任された仕事の範囲が明確で、進めやすいかどうか
                        </p>
                        <p>
                          <label>仕事のコントロール</label>
                          進め方を自分でコントロールしやすいかどうか
                        </p>
                        <p>
                          <label>自律性尊重の風土</label>
                          個々が自律的に働くことを尊重している風土かどうか
                        </p>
                        <p>
                          <label>能力発揮の実感</label>
                          自分らしさや強みを発揮することができるかどうか
                        </p>
                        <p>
                          <label>成長につながる仕事</label>
                          自分の成長につながる仕事かどうか
                        </p>
                        <p>
                          <label>成長を後押しする環境</label>
                          自分が成長していくことを後押しする環境かどうか
                        </p>
                        <p>
                          <label>社会・顧客とのつながり</label>
                          社会や顧客にとって意味があると感じる仕事かどうか
                        </p>
                        <p>
                          <label>上司とのつながり</label>
                          上司と良好な関係性かどうか
                        </p>
                        <p>
                          <label>職場の仲間とのつながり</label>
                          職場の仲間と良好な関係性かどうか
                        </p>
                      </div>
                    }
                  />
                </div>

                <div className="__container__content__body__title">２. 重視していると思うもの（予想）</div>
                <div className="__container__content__body__inputs">
                  <OutlinedInput
                    name="expectation1"
                    onClick={() => {
                      handleClickExpectationInput('expectation1')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">1位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                  <OutlinedInput
                    name="expectation2"
                    onClick={() => {
                      handleClickExpectationInput('expectation2')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">2位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                  <OutlinedInput
                    name="expectation3"
                    onClick={() => {
                      handleClickExpectationInput('expectation3')
                    }}
                    placeholder="下記項目から選択してください"
                    className="__container__content__body__inputs__input"
                    startAdornment={<div className="__container__content__body__inputs__inputLabel">3位</div>}
                    readOnly={true}
                    inputRef={register({
                      required: true,
                    })}
                  />
                  {(errors.expectation1 || errors.expectation2 || errors.expectation3) && (
                    <small style={{ color: 'red' }}>全て入力が必須です</small>
                  )}
                </div>

                <div className="__container__content__body__tagContainer">
                  <Tags
                    active={activeExpectation}
                    handleSelectTag={handleSelectExpectationTag}
                    refresh={refreshExpectationTags}
                    setRefresh={setRefreshExpectationTags}
                  />

                  <UsefulCollapse
                    openLabel="各カテゴリの説明を詳しくみる"
                    body={
                      <div className={classes.collapseBody}>
                        <p>
                          <label>仕事の明確さ</label>
                          任された仕事の範囲が明確で、進めやすいかどうか
                        </p>
                        <p>
                          <label>仕事のコントロール</label>
                          進め方を自分でコントロールしやすいかどうか
                        </p>
                        <p>
                          <label>自律性尊重の風土</label>
                          個々が自律的に働くことを尊重している風土かどうか
                        </p>
                        <p>
                          <label>能力発揮の実感</label>
                          自分らしさや強みを発揮することができるかどうか
                        </p>
                        <p>
                          <label>成長につながる仕事</label>
                          自分の成長につながる仕事かどうか
                        </p>
                        <p>
                          <label>成長を後押しする環境</label>
                          自分が成長していくことを後押しする環境かどうか
                        </p>
                        <p>
                          <label>社会・顧客とのつながり</label>
                          社会や顧客にとって意味があると感じる仕事かどうか
                        </p>
                        <p>
                          <label>上司とのつながり</label>
                          上司と良好な関係性かどうか
                        </p>
                        <p>
                          <label>職場の仲間とのつながり</label>
                          職場の仲間と良好な関係性かどうか
                        </p>
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="__container__content__buttons">
                <UsefulButton
                  bgColor={constants.COLOR_BLUE}
                  textColor={constants.COLOR_WHITE}
                  fullWidth={true}
                  body={<div>戻る</div>}
                  onClick={handleBackConfirm}
                />

                <UsefulButton
                  bgColor={constants.COLOR_GRAY}
                  textColor={constants.COLOR_BLACK}
                  fullWidth={true}
                  body={<div>次へ</div>}
                  submit={true}
                />
              </div>
            </div>
          </div>
        </form>
        <Modal
          open={backConfirm}
          aria-labelledby="action-modal-title"
          aria-describedby="action-modal-description"
          className={classes.modal}
          disableBackdropClick={true}
        >
          <div>
            <MessagePanel
              failure={true}
              handleClose={() => {
                setBackConfirm(false)
              }}
              body={
                <div>
                  <p>
                    分析を途中で中止しますか?
                    <br />
                    「はい」を押すと途中まで回答したデータは破棄して診察室に戻ります。
                  </p>
                </div>
              }
              footer={
                <div className={classes.modalButtons}>
                  <Button
                    bgColor={constants.COLOR_BLACK}
                    fullWidth={true}
                    body={'いいえ'}
                    onClick={() => {
                      setBackConfirm(false)
                    }}
                  />
                  <Button bgColor={constants.COLOR_SECOND} fullWidth={true} body={'はい'} onClick={handleBack} />
                </div>
              }
            />
          </div>
        </Modal>
      </div>
      {renderLoading()}
      {renderError()}
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 48,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__content': {
          backgroundColor: '#fff',
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px 0',
          },
          '&__description': {
            padding: '8px 16px',
          },

          '&__body': {
            padding: 16,
            paddingBottom: 32,

            '&__title': {
              fontWeight: 'bold',
              borderBottom: `1px solid ${constants.COLOR_MAIN}`,
              paddingBottom: '4px',
              marginBottom: '4px',
            },

            '&__inputs': {
              marginTop: 16,
              marginBottom: 8,

              '&__input': {
                width: '100%',
                background: '#fff',
                height: 40,
                borderRadius: 0,
                // marginTop: 16,
                marginBottom: 8,
                paddingLeft: 0,

                '& .MuiInputBase-input': {
                  paddingLeft: 16,
                },
              },

              '&__inputLabel': {
                height: '100%',
                background: constants.COLOR_MAIN,
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                width: '80px',
                minWidth: '80px',
                justifyContent: 'center',
              },
            },

            '&__tagContainer': {
              background: '#F5F5F5',
              borderRadius: 10,
              marginBottom: 24,
              boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

              '&__tags': {
                display: 'flex',
                flexWrap: 'wrap',
                padding: 16,

                '&__tag': {
                  background: '#fff',
                  whiteSpace: 'nowrap',
                  padding: '4px 16px',
                  borderRadius: 5,
                  color: constants.COLOR_BLUE_LIGHT,
                  borderColor: constants.COLOR_MAIN,
                  border: '1px solid',
                  margin: 4,
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'center',
                  height: 26,

                  '&.active': {
                    borderColor: constants.COLOR_BLUE_LIGHT,

                    background: constants.COLOR_BLUE_LIGHT,
                    color: '#fff',
                  },
                },
              },
              '&__more': {
                padding: '4px 0',
                background: '#8D8D8D',
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                color: '#fff',
                fontWeight: 100,

                '& i': {
                  marginRight: 8,
                },

                '&.opened': {
                  background: '#fff',
                  color: constants.TEXT_GRAY,
                  border: '1px solid #F5F5F5',
                  borderTop: 'none',
                },
              },
            },
          },

          '&__buttons': {
            display: 'flex',
            padding: 16,
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
        },
      },
    },

    modal: {
      padding: 16,
      '& div': {
        '&:focus': {
          outline: 'none',
        },
      },
    },

    modalButtons: {
      display: 'flex',
      marginTop: '8px',
      '& :first-child': {
        marginRight: '4px',
      },
      '& :last-child': {
        marginLeft: '4px',
      },
    },

    collapseBody: {
      padding: 16,
      background: '#fff',
      '& p': {
        '& label': {
          display: 'block',
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 8,
        },
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
