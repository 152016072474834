export const mutationCreateGhostResearch = /* GraphQL */ `
  mutation CreateGhostResearch($input: CreateGhostResearchInput!) {
    createGhostResearch(input: $input) {
      id
      teamId
      historyId
      userId
      like
      type
      report
      createdAt
    }
  }
`

export const mutationAddLikeOnGhostResearch = /* GraphQL */ `
  mutation AddLikeOnGhostResearch($id: ID!) {
    addLikeOnGhostResearch(id: $id) {
      id
      teamId
      historyId
      like
      type
      report
      createdAt
    }
  }
`

export const subscriptionCreateGhostResearch = /* GraphQL */ `
  subscription CreateGhostResearch($teamId: String!) {
    onCreateGhostResearch(teamId: $teamId) {
      id
      teamId
      historyId
      like
      type
      report
      createdAt
    }
  }
`
