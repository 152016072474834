import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'
import { IHintBase } from '../models/hint'

// -----------------------------
// Params
// -----------------------------
export type IHintListSearchParams = {
  isPublished: number
  category: string
  nextToken: string
}

// -----------------------------
// Result
// -----------------------------
export type IHintListSearchResult = {
  items: IHintBase[]
  nextToken: string
}

export const getHintListSearch = async (params: IHintListSearchParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getHintList,
      variables: {
        filter: { isPublished: params.isPublished, category: params.category },
        first: 6,
        after: params.nextToken,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return {
      items: [...res?.data?.getHintList?.items],
      nextToken: res?.data?.getHintList?.nextToken,
    }
  } catch (e) {
    throw e
  }
}
