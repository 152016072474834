import { useContext } from 'react'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { GhostMemberRole } from 'utils/generated'

import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'

import { GHOST_BREAKPOINT_FIXED_UI } from 'assets/constants'

type UsePBForFixedUIs = {
  status?: 'hasTimer' | 'hasTimer2Btn' | 'hasTimerMemberNoBtn' | 'break' | 'selectLeader'
  isOnlyLeader?: boolean
}

// タイマーや現在のメンバー画面など、オバケの固定 UI が重ならないよう画面下部の paddingBottom を調整する。
export const usePBForFixedUIs = ({ status, isOnlyLeader }: UsePBForFixedUIs): number | undefined => {
  const isXsDown = useCustomMediaQuery('down', 'xs')
  const isBPFixedUiDown = useCustomMediaQuery('down', GHOST_BREAKPOINT_FIXED_UI)
  const { teamMember } = useContext(ghostTeamMemberContext)

  const isLeader = teamMember?.role === GhostMemberRole.Leader || teamMember?.role === GhostMemberRole.Admin

  if (isOnlyLeader && !isLeader) {
    return undefined
  }

  if (status === 'hasTimer') {
    const commonValue = isXsDown ? 72 : isBPFixedUiDown ? 96 : 50

    return commonValue
  }

  if (status === 'hasTimer2Btn') {
    const commonValue = isXsDown ? 40 : isBPFixedUiDown ? 96 : 50

    return commonValue
  }

  if (status === 'hasTimerMemberNoBtn') {
    const commonValue = isXsDown ? 132 : isBPFixedUiDown ? 120 : 54

    return commonValue
  }

  if (status === 'break') {
    const leaderValue = isXsDown ? 284 : isBPFixedUiDown ? 326 : 110
    const memberValue = isXsDown ? 40 : 110

    return isLeader ? leaderValue : memberValue
  }

  if (status === 'selectLeader') {
    const leaderValue = isXsDown ? 164 : isBPFixedUiDown ? 326 : 110
    const memberValue = isXsDown ? 40 : 110

    return isLeader ? leaderValue : memberValue
  }

  const noneStatusLeaderValue = isXsDown ? 80 : isBPFixedUiDown ? 240 : 121
  const noneStatusMemberValue = isXsDown ? 35 : 121
  return isLeader ? noneStatusLeaderValue : noneStatusMemberValue
}
