import React from 'react'

import { Button as MaterialButton, makeStyles, Theme } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { COLOR_MAIN_NEW } from 'assets/constants'

type Props = {
  onClick?: () => void
}

export const ButtonToBack: React.FC<Props> = ({ onClick = () => undefined }) => {
  const classes = useStyles({})

  return (
    <MaterialButton
      onClick={onClick}
      className={classes.base}
      variant="text"
      color="inherit"
      startIcon={<ChevronLeftIcon style={{ color: COLOR_MAIN_NEW }} />}
    >
      戻る
    </MaterialButton>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  base: {
    color: COLOR_MAIN_NEW,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
}))
