import * as React from 'react'

type Props = {}

export const UncheckedIcon: React.FC<Props> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="長方形_1984" data-name="長方形 1984" fill="#fff" stroke="#707070" strokeWidth="1">
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="none" />
      </g>
    </svg>
  )
}
