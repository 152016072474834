import * as constants from 'assets/constants'

export type ButtonItem = {
  color: 'main' | 'white' | 'red'
  category: 'plan' | 'video' | 'feature'
  video?: { title: string; src: string }
  link?: string
}

export type Feature = {
  id: string
  title: string
  label: string
  link: string
  mainText: {
    title: string
    titleDetail?: string
    body: string
    bodyPc?: string
    link: string
  }
  recommended: string | string[]
  subTexts?: { title: string; body: string }[]
}

export type TabNames = keyof typeof TAB_ITEMS

export const TAB_NAMES = ['team', 'onboarding'] as const

export const TAB_ITEMS = {
  [TAB_NAMES[0]]: {
    header: '管理職主導型組織開発ツールはこちら​',
    plans: [
      {
        title: 'スタンダードプラン',
        description:
          'チームや１対１での対話の経験が少ない管理職の方にお勧めのプランです。ファシリテーションナビにより、対話に自信がない方でもスムーズに場を作ることができます​。',
        tools: [
          { name: 'カルテ', path: constants.PAGE_FEATURES_KARTE },
          { name: 'キックオフ', path: constants.PAGE_FEATURES_KICKOFF },
        ],
      },
      {
        title: 'アドバンスプラン',
        description: `チームの対話やコミュニケーションに自信のある管理職の方にお勧めのプランです。組織について普段言いにくいような対話をゲーム感覚で行うことで、少し踏み込んだ相互の背景を理解しあうことが可能です。\n`,
        tools: [
          { name: 'カルテ', path: constants.PAGE_FEATURES_KARTE },
          { name: 'キックオフ', path: constants.PAGE_FEATURES_KICKOFF },
          { name: 'オバケ', path: constants.PAGE_FEATURES_OBAKE },
        ],
      },
    ],
  },
  [TAB_NAMES[1]]: {
    header: '新人のオンボーディングツールはこちら​',
    title: 'オンボーディングプラン',
    titleDesc:
      '配属後100日間の新入社員受け入れ支援プラン。\n同期や職場の先輩・上司とのコミュニケーションツールとしても活用可。主体性発揮や離職防止の効果も。',
  },
}

export const FEATURES: Array<Feature> = [
  {
    id: constants.FEATURE.karte.id,
    title: 'Karte',
    label: constants.FEATURE.karte.name,
    link: constants.PAGE_FEATURES_KARTE,
    mainText: {
      title: 'チームメンバー同士の信頼関係構築のための第一歩、相互理解',
      body: `信頼関係を構築することは、仕事で成果を出すために非常に重要です。\nカルテは、信頼関係を構築するための第一歩、相互理解を深めるための対話ツールです。カルテは「働きがい診断」でもあるので、働く上で大事にしている互いの価値観を知ることは、お互いを尊重し、チームの働きがいを高めることにもつながります。`,
      bodyPc:
        '信頼関係を構築することは、仕事で成果を出すために非常に重要です。\nカルテは、信頼関係を構築するための第一歩、相互理解を深めるための対話ツールです。カルテは「働きがい診断」でもあるので、働く上で大事にしている互いの価値観を知ることは、お互いを尊重し、チームの働きがいを高めることにもつながります。',
      link: 'チームメンバー同士の信頼関係構築',
    },
    recommended: 'チームの関係性をより良くしたいと思っているチームの方々におすすめです。',
    subTexts: [
      {
        title: '働きがい診断',
        body: '40問のアンケートに答えることで、9つの働く上で大事にしている価値観について今自分が何を重視して働いているか順位付けがされます。',
      },
      {
        title: '診断結果一覧',
        body: 'メンバー全員のカルテの診断結果を一覧で見ることができます。チームで相互理解を行う際に、一覧結果を見ながら互いの違いを理解し、対話を楽しむことが可能です。',
      },
      {
        title: 'メール送信機能',
        body: 'リーダーがメンバーに対して診断依頼をメールで一斉に行うことが可能です。チームでカルテの結果を見ながら相互理解を行う際に、事前にメールを送り、診断の依頼を促すことが可能です。',
      },
    ],
  },
  {
    id: constants.FEATURE.team.id,
    title: 'KickOff',
    label: constants.FEATURE.team.name,
    link: constants.PAGE_FEATURES_KICKOFF,
    mainText: {
      title: 'チームメンバーが自律的貢献欲求を高めるために',
      body: 'チームでの心理的安全性と、チームへの貢献意欲を高めるための対話ツールです。キックオフのタイミングで組織の理想の状態をすり合わせてチームへの貢献意欲を高め、問題が起こり始めるタイミングで定期的に組織課題をすり合わせて心理的安全性を高めます。',
      link: '理想のチームの状態を築く',
    },
    recommended:
      '複数メンバーをマネジメントする経験が浅い中で、担当チームを成果に導くことが求められている方に最適です。',
    subTexts: [
      {
        title: 'ファシリテーター機能',
        body: 'チームミーティングにおけるコミュニケーションで一番難しいのはファシリテーションスキル。このツールでは、難しいファシリテーションをツールが代わりに行います。発言者もこちらで指名するため、リーダーの方もメンバーの一人としてコミュニケーションを行うことができ、組織の暗黙の偏りを防ぎます。',
      },
      {
        title: '時間管理機能',
        body: 'チームミーティングでよく起きる課題としては時間内に議論が終わらないこと。時間内に議論が行われるよう、タイムマネジメントをツールが行います。時間を意識しつつ、時間切れの場合は、忖度なく時間終了の合図を行いますので、通常のミーティングよりも時間の効率化が望めます。',
      },
      {
        title: '研修理論に基づいた良質な問い',
        body: '限られた時間内で、良質な問いを投げることで、キックオフミーティングとして、深い相互理解や目指すべき組織の状態の共有が図れます。そのことによりチームメンバー一人一人がチームに対する主体者意識が上がり、チームへの主体性・貢献意欲の上昇につながります。',
      },
    ],
  },
  {
    id: constants.FEATURE.obake.id,
    title: 'Obake',
    label: constants.FEATURE.obake.name,
    link: constants.PAGE_FEATURES_OBAKE,
    mainText: {
      title: '組織の問題をチームメンバーと解決するために',
      body: '組織内に存在する問題を「オバケ」として人と問題を切り離して解決するためのツール。組織内の問題の多くは、特定の誰かではなく、”関係性や環境から生まれた目に見えないもの”が原因となっており、その象徴として「オバケ」と呼びます。「オバケ」を使い、人と問題を切り離して考えることで、チームで協力して問題を解決させることができます。',
      link: '組織の問題を解決する',
    },
    recommended: 'チームメンバーに主体的にチームに貢献してもらいたいと思っているリーダー・人事企画担当者',
    subTexts: [
      {
        title: '12体のオバケ',
        body: '目に見えない組織の問題を「言い出しっぺおばけ」「部分最適おばけ」「仲良しサークルおばけ」といった12体のオバケで表現し対話を生みやすくしています。ワイワイ楽しく時には真剣に対話をすることで、参加者全員が組織のことについて意見交換をすることが可能です。',
      },
      {
        title: '無記名投票での「オバケ」特定',
        body: '「オバケ」探しを行う際、誰が何を選んだか、誰が投票したのかわからない設定になっています。そのことにより忖度なく、本音でメンバー全員がとらえている組織の問題を可視化することが可能となります。',
      },
      {
        title: '解決方法についてのコメント機能',
        body: 'オバケを特定した後は、解決方法について全員で検討します。個人で考えたものを全員が確認できる機能で、意見を言う人と言わない人がでないような仕組みとなっています。',
      },
    ],
  },
  {
    id: constants.FEATURE.onb.id,
    title: 'OnBoarding',
    label: constants.FEATURE.onb.name,
    link: constants.PAGE_FEATURES_ONBOARDING,
    mainText: {
      title: '新入社員が早期にチームに貢献するために',
      titleDetail: '新入社員が早期に職場の一員となるために',
      body: '新入社員が配属後100日間でチームに貢献できるようになるための配属受け入れツールです。新入社員の配属先へのエンゲージメントを高め、長く働き続けたいと思う環境を整えるだけでなく、配属先のチーム内エンゲージメントも高める効果もあります。',
      link: '新入社員受け入れ支援',
    },
    recommended: [
      '新入社員の受け入れ体制に不安を感じている方',
      '新入社員にエンゲージメント高く働いてもらいたいと感じている方',
      '研修後に新入社員に主体的にチームにかかわりに行って欲しいと考えている方',
    ],
    subTexts: [
      {
        title: 'アクションリスト/レーダーチャート',
        body: '新入社員が早期にチームに貢献できるようになるためのアクションが表示されると共に、新入社員の取り組み度合いが表示されます。レーダーチャートでは、カテゴリー別に一目で進捗度合いを可視化。分析する手間がかかりません。レーダーチャートで新入社員の状態を把握することが可能です。',
      },
      {
        title: 'タイムライン',
        body: '新入社員が更新した情報がリアルタイムでタイムラインに表示されます。新入社員の投稿に対してコメントできる「グループ機能」で、オープンな環境の下、「いいね」や、コメントを入力して新入社員を支援できます。',
      },
      {
        title: 'デイリーチェック/シェア機能',
        body: 'タイムラインで新入社員が毎日更新するデイリーチェックが表示されます。その日の新入社員の状態が確認できます。「いいね」や、コメントも可能なので、気軽に声がけが可能です。シェア機能では、タイムライン上に新入社員が助けてほしい時や、相談したい時などにシェアしたい内容が表示されます。こちらでコメントをすることで新入社員を支援できます。',
      },
    ],
  },
]
