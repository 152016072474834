import * as React from 'react'
import { useHistory } from 'react-router-dom'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import { CircleGraph } from 'components/CircleGraph'
import MessagePanel from 'components/MessagePanel'
import { NoStyleButton } from 'components/NoStyleButton'
import { Header } from 'pages/hataraku/components'
import { useHataraku } from 'pages/hataraku/hooks'
import { useBuildHatarakuResults } from 'pages/hataraku/hooks/useBuildHatarakuResults'
import { useCreateHataraku } from 'pages/hataraku/hooks/useCreateHataraku'
import { cookieKeys, forms } from 'pages/hataraku/pages/static'

import Slider from './components/Slider'
import {
  IKarteMemberQuestionnaireAnswerSet,
  IKarteMemberQuestionnaireAnswerValueSet,
  IKarteMemberQuestionnaireFormField,
} from './interface'

import { Pages } from 'assets'
import * as constants from 'assets/constants'
import { replacePathParams } from 'assets/history'

export const HatarakuQuestionnariePage: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies([cookieKeys.number, cookieKeys.data])
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0)
  const [answers, setAnswers] = React.useState({} as IKarteMemberQuestionnaireAnswerSet)
  const [backConfirm, setBackConfirm] = React.useState(false)
  const { hatarakuKeyName } = useHataraku()
  const { buildResults, getTendencyId } = useBuildHatarakuResults()
  const { isLoading, errors: createHatarakuErrors, createHataraku } = useCreateHataraku()

  React.useEffect(() => {
    const number = cookies[cookieKeys.number]
    if (!number) {
      history.push(Pages.Hataraku)
      return
    }

    const data = cookies[cookieKeys.data]
    if (data) {
      setCurrentStepIndex(data.step)
      setAnswers(data.answers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, setValue, errors, reset } = useForm<IKarteMemberQuestionnaireAnswerValueSet>({
    mode: 'onChange',
  })

  // useForm と useState の併用により、useForm の isValid state（answers） によってバリデーションを行う。
  const isValid = React.useMemo(() => {
    const staticForm = forms[currentStepIndex]
    return staticForm.fields.every((field) => answers && answers[field.group]?.[field.key] !== undefined)
  }, [answers, currentStepIndex])

  React.useEffect(() => {
    reset() // reset form on change step

    const staticForm = forms[currentStepIndex]
    staticForm.fields.forEach((field) => {
      register(field.key, { required: `${field.label}は必須です` })
      if (answers[field.group] && answers[field.group][field.key]) {
        setValue(field.key, answers[field.group][field.key])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex])

  const onBack = () => {
    removeCookie(cookieKeys.number)
    removeCookie(cookieKeys.data)

    history.push(Pages.Hataraku)
    window.scrollTo(0, 0)
  }

  const onSubmit = async () => {
    if (currentStepIndex < forms.length - 1) {
      const step = currentStepIndex + 1
      setCurrentStepIndex(step)

      setCookie(cookieKeys.data, {
        step,
        answers,
      })
    } else {
      const number = cookies[cookieKeys.number]
      const response = await createHataraku({ id: number, leaderId: hatarakuKeyName, answers })
      if (!response?.data) return

      removeCookie(cookieKeys.number)
      removeCookie(cookieKeys.data)
      const results = buildResults(JSON.parse(response.data))
      const tendencyId = getTendencyId(results.about.short)

      history.push(replacePathParams(Pages.HatarakuResults, { id: response.id, tendencyId }))
    }
    window.scrollTo(0, 0)
  }

  const renderQuestionnaireField = (field: IKarteMemberQuestionnaireFormField, index: number) => {
    const getFormAnswer = (group: string, fieldKey: string) => {
      return answers[group] && answers[group][fieldKey] ? answers[group][fieldKey] : null
    }
    const setFormAnswer = (group: string, fieldKey: string, value: string) => {
      const localAnswers = { ...answers }
      if (!answers[group]) {
        localAnswers[group] = {} as IKarteMemberQuestionnaireAnswerValueSet
      }
      localAnswers[group][fieldKey] = value
      setAnswers(localAnswers)
      setValue(field.key, value)
    }
    const errorMessage =
      errors.hasOwnProperty(field.key) && errors[field.key] ? errors[field.key]?.message || 'Unknown error' : null

    if (field.type === 'radio') {
      return (
        <div key={index} className={classes.fieldBox}>
          <div className="__container__content__body__title">{field.label}</div>
          <div>
            <RadioGroup
              row={true}
              className={classes.radioGroup}
              aria-label={field.key}
              value={getFormAnswer(field.group, field.key)}
              name={field.key}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
            >
              {field.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  className={classes.radioControl}
                  value={option.key}
                  control={
                    <Radio
                      disableRipple
                      classes={{
                        root: classes.radioRoot,
                        checked: classes.radioChecked,
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {errorMessage ? <small className={classes.formErrorMsg}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'select') {
      return (
        <div key={index} className={classes.fieldBox}>
          <div className="__container__content__body__title">{field.label}</div>
          <div className="__container__content__body__select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={getFormAnswer(field.group, field.key) || ''}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
              autoWidth={true}
              displayEmpty={true}
              disableUnderline={true}
              classes={{
                root: '__container__content__body__select__root',
                icon: '__container__content__body__select__icon',
              }}
              className={`__container__content__body__select__select ${
                getFormAnswer(field.group, field.key) !== null ? 'hasValue' : ''
              }`}
            >
              <MenuItem value="" disabled>
                {field.label}を選択
              </MenuItem>
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage ? <small className={classes.formErrorMsg}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'slider') {
      const valueString = getFormAnswer(field.group, field.key)
      let value: number | undefined
      if (valueString) {
        value = parseInt(valueString, 0)
      }
      return (
        <div key={index} className={classNames(classes.sliderBox, field.group === 'anket' && 'colorBack')}>
          <div className={classes.sliderTitle}>
            <span>{`0${field.number}`.slice(-2)}</span>
            {field.label}
          </div>
          <div className={classes.slider}>
            <Slider
              key={index}
              name="member"
              value={value}
              options={field.options.map((option) => {
                return { label: option.label, value: parseInt(option.key, 0) }
              })}
              onChange={(value) => setFormAnswer(field.group, field.key, `${value}`)}
            />
          </div>
          {errorMessage ? <small className={classes.formErrorMsg}>{errorMessage}</small> : null}
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    const staticForm = forms[currentStepIndex]
    return (
      <div className="__container">
        <div className="__container__contentWrapper">
          <div className="__container__content">
            <div className={classes.progressBox}>
              <CircleGraph numerator={currentStepIndex + 1} denominator={forms.length} />
              <p>{staticForm.label}</p>
            </div>

            {staticForm.description ? (
              <div className="__container__content__description">{staticForm.description}</div>
            ) : null}

            <div className="__container__content__body">{staticForm.fields.map(renderQuestionnaireField)}</div>
          </div>
        </div>

        {createHatarakuErrors?.length && (
          <div className="__container__contentWrapper">
            <div className="__container__content">
              <ul className={classes.errorMessages}>
                {createHatarakuErrors.map((error, index) => (
                  <li key={index}>{error.message}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="__container__buttons">
          <div className="wrapper">
            <NoStyleButton
              type="button"
              className="backButton"
              onClick={() => {
                if (currentStepIndex === 0) {
                  setBackConfirm(true)
                } else {
                  setCurrentStepIndex(currentStepIndex - 1)
                }
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/hataraku/arrow-left.svg`} alt="" />
              戻る
            </NoStyleButton>

            <NoStyleButton type="submit" className="nextButton" disabled={isLoading || !isValid}>
              次へ
            </NoStyleButton>
          </div>
        </div>
      </div>
    )
  }

  const renderConfirmationDialog = () => {
    return (
      <Modal open={backConfirm} className={classes.modal}>
        <div>
          <MessagePanel
            failure={true}
            handleClose={() => {
              setBackConfirm(false)
            }}
            body={
              <div>
                <p>
                  分析を途中で中止しますか?
                  <br />
                  「はい」を押すと途中まで回答したデータは破棄して前のページに戻ります。
                </p>
              </div>
            }
            footer={
              <div className={classes.modalButtons}>
                <Button
                  bgColor={constants.COLOR_BLACK}
                  fullWidth={true}
                  body={'いいえ'}
                  onClick={() => {
                    setBackConfirm(false)
                  }}
                />
                <Button bgColor={constants.COLOR_SECOND} fullWidth={true} body={'はい'} onClick={onBack} />
              </div>
            }
          />
        </div>
      </Modal>
    )
  }

  return (
    <>
      <Header />
      <div className={classes.main}>
        <form onSubmit={handleSubmit(onSubmit)}>{renderContent()}</form>
        {renderConfirmationDialog()}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    main: {
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      background: `linear-gradient(180deg, ${constants.COLOR_BLUE_LIGHT3} 0%, ${constants.COLOR_BLUE_DARK4} 100%)`,
      fontFamily: constants.HATARAKU_FONT_FAMILY,

      '& .__container': {
        color: constants.TEXT_GRAY_DARK,

        '&__contentWrapper': {
          padding: '0 24px',
          [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
          },
        },

        '&__content': {
          margin: '0 auto',
          width: '100%',
          maxWidth: 1000,
          paddingTop: 40,

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px 0',
          },

          '&__description': {
            background: '#ffffe0',
            color: constants.COLOR_RED,
            fontSize: 14,
            textAlign: 'center',
            marginTop: 20,
            padding: 16,
            borderRadius: 8,
            whiteSpace: 'pre-wrap',
          },

          '&__body': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 16,
            marginTop: 40,

            '&__title': {
              flexShrink: 0,
              width: 272,
              color: constants.COLOR_BLUE_DARK2,
              fontSize: 16,
              fontWeight: 'bold',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            },
            '&__select': {
              width: '100%',
              '&__select': {
                width: 624,
                height: 60,
                border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
                [theme.breakpoints.down(1007)]: {
                  width: 466,
                },
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
                '&.hasValue': {
                  '& .__container__content__body__select__root': {
                    color: `${constants.TEXT_GRAY_DARK} !important`,
                  },
                },
              },

              '&__root': {
                display: 'flex',
                alignItems: 'center',
                padding: '0 24px',
                height: 60,
                fontSize: 16,
                color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
              },

              '&__icon': {
                color: constants.COLOR_BLUE_DARK2,
                padding: '8px 0',
                width: 70,
                height: 60,
                top: 0,
              },
            },
          },
        },

        '&__buttons': {
          marginTop: 80,
          padding: '0 24px',
          height: 156,
          background: '#fff',
          [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
            height: 120,
          },

          '& .wrapper': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '100%',
            maxWidth: 1000,
            height: '100%',
            [theme.breakpoints.down('sm')]: {},

            '& .backButton': {
              position: 'absolute',
              top: '50%',
              left: -4,
              transform: 'translateY(-50%)',
              padding: 4,
              color: constants.COLOR_BLUE_DARK2,
              fontSize: 16,
              fontWeight: 900,
              '@media (hover: hover)': {
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
              '& img': {
                width: 23,
                marginRight: 6,
              },
            },
            '& .nextButton': {
              width: 400,
              height: 92,
              background: `linear-gradient(90deg, ${constants.COLOR_BLUE_DARK2} 0%, ${constants.COLOR_BLUE_LIGHT4} 100%)`,
              color: '#fff',
              fontSize: 24,
              fontWeight: 900,
              borderRadius: 46,
              [theme.breakpoints.down('sm')]: {
                width: 300,
                height: 60,
              },
              [theme.breakpoints.down('xs')]: {
                width: 180,
                fontSize: 20,
              },
              '&:disabled': {
                background: constants.COLOR_GRAY,
                color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
                cursor: 'not-allowed',
              },
            },
          },
        },
      },
    },
    progressBox: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 40,
      [theme.breakpoints.down('sm')]: {
        columnGap: 16,
      },
      '& p': {
        margin: 0,
        color: '#fff',
        fontSize: 24,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
    },

    fieldBox: {
      display: 'flex',
      alignItems: 'center',
      padding: 24,
      background: '#fff',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '24px 0',
        padding: 16,
      },
    },

    radioGroup: {
      gap: 8,
    },
    radioControl: {
      position: 'relative',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        display: 'grid',
        placeItems: 'center',
        padding: '0 8px',
        width: 150,
        height: 60,
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        fontSize: 16,
        fontWeight: 'bold',
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
          width: 96,
        },
      },
      // 要素の階層が異なるため、:has を使って checked を検知する。
      '&:has(input:checked)': {
        '& .MuiFormControlLabel-label': {
          color: '#fff',
        },
      },
    },
    radioRoot: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 0,
      zIndex: 1,
      '& .MuiIconButton-label': {
        width: '100%',
        height: '100%',
        opacity: 0,
      },
    },
    radioChecked: {
      background: constants.COLOR_BLUE_DARK2,
      border: `1px solid ${constants.COLOR_BLUE_DARK2}`,
    },

    sliderBox: {
      padding: '20px 24px 52px',
      background: '#fff',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        padding: '16px 16px 40px',
      },
      '&.colorBack': {
        background: '#ffffe0',
      },
    },
    sliderTitle: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 54,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        columnGap: 24,
      },
      '& span': {
        color: constants.COLOR_BLUE_DARK2,
        fontSize: 24,
      },
    },
    slider: {
      padding: '20px 117px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 32px 0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '20px 12px 0',
      },
    },

    modal: {
      padding: 16,

      '& div': {
        '&:focus': {
          outline: 'none',
        },
      },
    },

    modalButtons: {
      display: 'flex',
      marginTop: '8px',
      '& :first-child': {
        marginRight: '4px',
      },
      '& :last-child': {
        marginLeft: '4px',
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },

    formErrorMsg: {
      display: 'block',
      color: 'red',
      paddingTop: 24,
    },

    errorMessages: {
      padding: 16,
      borderRadius: 8,
      listStyleType: 'none',
      color: constants.COLOR_RED,
      backgroundColor: constants.COLOR_RED_LIGHT,
      '& li': {
        overflowWrap: 'break-word',
      },
    },
  }),
  { name: 'HatarakuQuestionnariePage' }
)
