import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import { constants } from 'assets'

type Props = {
  maxLength?: number
  currentProgress?: number
}

export const ProgressDots: React.FC<Props> = ({ maxLength, currentProgress }) => {
  const classes = useStyles({ maxLength })
  if (!maxLength && !currentProgress) {
    return <></>
  }
  if (maxLength && maxLength <= 0) {
    console.log('maxLengthは0以上を設定してください')
    return <></>
  }
  if (maxLength && currentProgress && (currentProgress <= 0 || currentProgress > maxLength)) {
    console.log('currentProgressは0~maxLengthの間で設定してください')
    return <></>
  }
  const progressDots = () => {
    const items = [] as Array<number>
    if (!maxLength || maxLength <= 0) {
      return items
    } else {
      for (let i = 1; i <= maxLength; i++) {
        items.push(i)
      }
    }
    return items
  }

  return (
    <div className={classes.root}>
      <div className={classes.dots}>
        {progressDots().map((i) => (
          <span key={`progress-dot-${i}`} className={`${classes.dot} ${i === currentProgress ? classes.active : ''}`} />
        ))}
      </div>
    </div>
  )
}

type StyleProps = {
  maxLength?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },

  dots: ({ maxLength }) => ({
    width: maxLength ? 8 * (maxLength * 2 - 1) : 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),

  dot: {
    width: 8,
    height: 8,
    background: '#a2a2a2',
    borderRadius: 8,
  },

  active: {
    backgroundColor: constants.COLOR_ONBOARDING_MAIN,
  },
}))
