import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { CloseButton } from 'components/CloseButton'
import { GenericModal } from 'components/Modal'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { TeamMember } from 'utils/generated'
import { getTeamMemberRoleLabelFromList } from 'utils/labels'

import personSvg from '../../assets/person.svg'

import { constants } from 'assets'

type Props = {
  isModalOpen: boolean
  handleModalClose: () => void
  answeredMembers: TeamMember[]
  unansweredMembers: TeamMember[]
}

export const ModalAnswerMember: React.FC<Props> = ({
  isModalOpen,
  handleModalClose,
  answeredMembers,
  unansweredMembers,
}) => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'sm')

  const isOneAnswered = answeredMembers.length === 1

  return (
    <GenericModal
      isModalOpen={isModalOpen}
      handleModalClose={handleModalClose}
      styleProps={{
        width: '100%',
        maxWidth: 600,
        height: isSmDown ? undefined : '80%',
        maxHeight: isSmDown ? '85%' : 408,
      }}
    >
      <div className={classes.root}>
        <div className={classes.closeButtonBox}>
          <CloseButton onClose={handleModalClose} />
        </div>

        <h2 className={classes.title}>アンケート回答者</h2>
        <div className={classes.answerList}>
          <MemberBox title="回答者" teamMembers={answeredMembers} case={{ isAnsweredMember: true, isOneAnswered }} />
          <MemberBox
            title="未回答者"
            teamMembers={unansweredMembers}
            case={{ isAnsweredMember: false, isOneAnswered }}
          />
        </div>
      </div>
    </GenericModal>
  )
}

type MemberBoxProps = {
  title: string
  teamMembers: TeamMember[]
  case: {
    isAnsweredMember: boolean
    isOneAnswered: boolean
  }
}

const MemberBox: React.FC<MemberBoxProps> = ({ title, teamMembers, case: { isAnsweredMember, isOneAnswered } }) => {
  const classes = useStyles()

  const renderMember = () => {
    if (isAnsweredMember && isOneAnswered) {
      return <p className={classes.oneAnswered}>回答者は1人です</p>
    } else if (isOneAnswered) {
      return <p className={classes.oneAnswered}>未回答者は{teamMembers.length}人です</p>
    } else {
      return teamMembers.map((member) => (
        <div key={member.id} className={classes.member}>
          <img src={personSvg} alt="person" />
          <div>
            <p className={classes.memberName}>{member.fullName}さん</p>
            <p className={classes.memberRole}>{getTeamMemberRoleLabelFromList(member.role)}</p>
          </div>
        </div>
      ))
    }
  }

  return (
    <div className={classes.boxRoot}>
      <p className={classes.boxTitle}>
        <span>{title}</span>({teamMembers.length}人)
      </p>
      {teamMembers.length > 0 && (
        <div className={classes.memberContainer}>
          <div className={classes.scrollOuter}>{renderMember()}</div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // ModalAnswerMember
    root: {
      position: 'relative',
      padding: '40px 24px 24px',
      color: constants.TEXT_GRAY_DARK,
    },
    closeButtonBox: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    title: {
      margin: 0,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    answerList: {
      display: 'flex',
      gap: 16,
      marginTop: 28,
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        maxHeight: 360,
        flexDirection: 'column',
        overflowY: 'auto',
        overscrollBehavior: 'contain',
      },
    },

    // MemberBox
    oneAnswered: {
      display: 'grid',
      placeItems: 'center',
      margin: 0,
      height: '100%',
    },
    boxRoot: {
      flex: 1,
      fontSize: 12,
    },
    boxTitle: {
      margin: 0,
      color: constants.COLOR_GRAY_DARK,
      '& > span': {
        paddingRight: 8,
        fontWeight: 'bold',
      },
    },
    memberContainer: {
      marginTop: 8,
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
      [theme.breakpoints.up('md')]: {
        height: 271,
        overflow: 'hidden',
      },
    },
    scrollOuter: {
      padding: 16,
      height: '100%',
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        overscrollBehavior: 'contain',
      },
    },
    member: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '&:not(:last-child)': {
        marginBottom: 24,
      },
      '& > img': {
        width: 24,
        height: 24,
        flexShrink: 0,
      },
    },
    memberName: {
      margin: 0,
      fontWeight: 'bold',
    },
    memberRole: {
      margin: 0,
      color: constants.COLOR_GRAY3,
      fontSize: 10,
    },
  }),
  { name: 'ModalAnswerMember' }
)
