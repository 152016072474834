import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Grid, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../assets/constants'

import ApplyButton from './components/ApplyButton'
import SectionTitle from './components/SectionTitle'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={classes.root}>
        <div className="__topBlock">
          <div className="__topBoxWrapper">
            <div className="__topBox"></div>
          </div>
          <div className="__topImg">
            <Hidden smUp>
              <img src={process.env.PUBLIC_URL + '/assets/landing/team/top_img_sp.jpg'} alt="top_img" />
            </Hidden>
            <Hidden xsDown>
              <img src={process.env.PUBLIC_URL + '/assets/landing/team/top_img.jpg'} alt="top_img" />
            </Hidden>
          </div>
          <div className="__topTitleBlock">
            <Typography variant="h1" className="__title">
              TEAM
            </Typography>
            <Typography variant="subtitle1" className="__subTitle">
              テレワークによる“見えない”時代に求められる
              <br />
              管理職向けチーム力向上ツール
            </Typography>
            <div className="__topButton">
              <Hidden smUp>
                <ApplyButton height={45} />
              </Hidden>
              <Hidden xsDown>
                <ApplyButton height={56} />
              </Hidden>
            </div>
          </div>
        </div>

        <section className="__RightRadiusSection">
          <div className="__sectionBack">
            <SectionTitle
              topTitle="SOLUTION"
              mainTitle="現代のチーム作りを支える必須アイテムであるタスク・管理ツールはお持ちですか？"
              caption={
                isMdUp
                  ? [
                      'テレワークによる“見えない”状況を打破するためにも重要なのは「見える化」と「共通言語化」です。',
                      <br key="break1" />,
                      '「見えない」時代において、チームが徐々に弱体化するのを防ぐだけではなく、より一層強化させていくために、',
                      <br key="break2" />,
                      'これからのマネジメントは全体像の理解と進捗による手応えが求められます。',
                    ]
                  : [
                      'テレワークによる“見えない”状況を打破するためにも重要なのは「見える化」と「共通言語化」です。',
                      <br key="break" />,
                      '「見えない」時代において、チームが徐々に弱体化するのを防ぐだけではなく、より一層強化させていくために、これからのマネジメントは全体像の理解と進捗による手応えが求められます。',
                    ]
              }
              setMarginBottom={isMdUp ? 50 : isSmUp ? 35 : 25}
            />

            <Grid className="__solutions" container spacing={0} justify="center">
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp01.svg'} alt="solution01" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message01.svg'} alt="solution01" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution01_img.png'}
                  alt="solution01"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp02.svg'} alt="solution02" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message02.svg'} alt="solution02" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution02_img.png'}
                  alt="solution02"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp03.svg'} alt="solution03" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message03.svg'} alt="solution03" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution03_img.png'}
                  alt="solution03"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp04.svg'} alt="solution04" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message04.svg'} alt="solution04" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution04_img.png'}
                  alt="solution04"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp05.svg'} alt="solution05" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message05.svg'} alt="solution05" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution05_img.png'}
                  alt="solution05"
                />
              </Grid>
              <Grid className="__solution" item xs={6} md={4}>
                <Hidden smUp>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message_sp06.svg'} alt="solution06" />
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className="__balloon">
                    <img src={process.env.PUBLIC_URL + '/assets/svg/team/message06.svg'} alt="solution06" />
                  </div>
                </Hidden>
                <img
                  className="__img"
                  src={process.env.PUBLIC_URL + '/assets/landing/team/solution06_img.png'}
                  alt="solution06"
                />
              </Grid>
            </Grid>
            <div className="__greenBorderBalloonBlock">
              <img
                src={
                  isMdUp
                    ? process.env.PUBLIC_URL + '/assets/svg/team/greenBorderBalloon.svg'
                    : process.env.PUBLIC_URL + '/assets/svg/team/greenBorderBalloonHigh.svg'
                }
                alt="green-border-balloon"
              />
              <div className="__text">
                {isMdUp ? (
                  <p>全ての課題を「見える化」「共通言語化」で解決するのが</p>
                ) : (
                  <p>
                    全ての課題を「見える化」
                    <br />
                    「共通言語化」で解決するのが
                  </p>
                )}
              </div>
            </div>
            <div className="__taskMsg">
              <p>タスク・管理ツール！！！</p>
            </div>
          </div>
        </section>

        <section className="__LeftRadiusSection">
          <div className="__sectionBack">
            {window.innerWidth > 1315 || window.innerWidth < 960 ? (
              <SectionTitle
                topTitle="Cocolaboが推薦する"
                mainTitle="タスク・管理ツール「Jooto」"
                caption="数あるタスク・管理ツールの中で Jooto をお勧めする3つの理由"
              />
            ) : (
              <SectionTitle
                topTitle="Cocolaboが推薦する"
                mainTitle="タスク・管理ツール"
                mainTitleLine2="「Jooto」"
                caption={['数あるタスク・管理ツールの中で', <br key="break" />, 'Jooto をお勧めする3つの理由']}
              />
            )}
            {isMdUp ? (
              <div className="__jooto">
                <img src={process.env.PUBLIC_URL + '/assets/landing/team/recommended_img.png'} alt="jooto" />
              </div>
            ) : (
              <img
                className="__jootoSp"
                src={process.env.PUBLIC_URL + '/assets/landing/team/recommended_img.png'}
                alt="jooto"
              />
            )}
            {isMdUp && window.innerWidth > 1315 && <div className="__imgSpaceHigh"></div>}
            {isMdUp && window.innerWidth < 960 && <div className="__imgSpaceLow"></div>}
            <Grid className="__recommendedBlock" container spacing={0} justify="center">
              <Grid className="__recommended" item xs={12} sm={6} md={4}>
                <Typography className="__recommendedTitle01">Recommended 01</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/team/recommended01.svg'} alt="recommended01" />
                </div>
                <p className="__descriptionTitle">4名までずっと無料</p>
                <p className="__recommendedDescription">
                  小規模チームは期間に制限なく気軽に使えます。まずは気軽に試してみましょう！
                </p>
              </Grid>
              <Grid className="__recommendedMiddle" item xs={12} sm={6} md={4}>
                <Typography className="__recommendedTitle02">Recommended 02</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/team/recommended02.svg'} alt="recommended02" />
                </div>
                <p className="__descriptionTitle">シンプルでわかりやすい</p>
                <p className="__recommendedDescription">
                  直観的に触りながら使えるシンプル構造。ツールに不慣れな方でも抵抗なく使えます。あとから参加したメンバーも過去の履歴含め共有可能！
                </p>
              </Grid>
              <Grid className="__recommended" item xs={12} sm={6} md={4}>
                <Typography className="__recommendedTitle03">Recommended 03</Typography>
                <div>
                  <img src={process.env.PUBLIC_URL + '/assets/svg/team/recommended03.svg'} alt="recommended03" />
                </div>
                <p className="__descriptionTitle">複数プロジェクトの管理が可能</p>
                <p className="__recommendedDescription">
                  多数のプロジェクトがある場合でも、一つのダッシュボードで管理することができ、円滑な業務推進が可能！
                </p>
              </Grid>
            </Grid>

            <div className="__endButton">
              <Hidden smUp>
                <ApplyButton height={45} />
              </Hidden>
              <Hidden xsDown>
                <ApplyButton height={56} />
              </Hidden>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      textAlign: 'justify',
      backgroundColor: constants.COLOR_GRAY,
      '& .__topBlock': {
        position: 'relative',
        '& .__topBoxWrapper': {
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          '& .__topBox': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        '& .__topBoxWrapper:before': {
          content: "''",
          display: 'block',
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            paddingTop: '55%',
          },
          [theme.breakpoints.up('sm')]: {
            paddingTop: '30%',
          },
        },
        '& .__topImg': {
          position: 'relative',
          zIndex: 2,
          textAlign: 'right',
          '& img': {
            [theme.breakpoints.down('xs')]: {
              width: '69%',
            },
            [theme.breakpoints.up('sm')]: {
              width: '80%',
            },
            borderRadius: '0 0 0 28px',
          },
        },
        '& .__topTitleBlock': {
          position: 'absolute',
          zIndex: 3,
          [theme.breakpoints.down('xs')]: {
            top: '15%',
            left: '4%',
          },
          [theme.breakpoints.up('sm')]: {
            top: '7%',
            left: '11%',
          },
          [theme.breakpoints.up('md')]: {
            top: '30%',
            left: '11%',
          },
          '& .__title': {
            [theme.breakpoints.down('sm')]: {
              fontSize: 64,
              letterSpacing: 1.2,
            },
            [theme.breakpoints.up('md')]: {
              fontSize: 80,
              letterSpacing: 1.7,
            },
            fontWeight: 'bold',
            color: constants.COLOR_BLACK,
            textShadow: '1px 0 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff',
          },
          '& .__subTitle': {
            marginLeft: 8,
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
              letterSpacing: 1.4,
            },
            [theme.breakpoints.up('sm')]: {
              fontSize: 18,
              letterSpacing: 1.5,
            },
            fontWeight: 'bold',
            color: constants.COLOR_BLACK,
            textShadow: '1px 0 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff',
          },
        },
        '& .__topButton': {
          [theme.breakpoints.down('xs')]: {
            marginLeft: 3,
            width: '305px',
          },
          [theme.breakpoints.up('sm')]: {
            marginLeft: 5,
            width: '337px',
          },
        },
      },

      '& .__RightRadiusSection': {
        [theme.breakpoints.down('xs')]: {
          padding: '50px 7% 0 0',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '80px 3% 0 0',
        },
        [theme.breakpoints.up('md')]: {
          padding: '80px 8% 0 0',
        },
        '& .__sectionBack': {
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            padding: '14% 5% 14% 5%',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '5% 10% 2% 13%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '5% 10% 2% 18%',
          },
          borderRadius: '0 30px 30px 0',
        },
      },
      '& .__LeftRadiusSection': {
        [theme.breakpoints.down('xs')]: {
          padding: '50px 0 50px 7%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '80px 0 80px 3%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '80px 0 80px 8%',
        },
        '& .__sectionBack': {
          position: 'relative',
          backgroundColor: constants.COLOR_WHITE,
          [theme.breakpoints.down('xs')]: {
            padding: '14% 5% 0 5%',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '5% 13% 4.5% 10%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '5% 18% 4.5% 10%',
          },
          borderRadius: '30px 0 0 30px',
        },
      },

      '& .__solutions': {
        backgroundColor: 'rgba(138, 218, 28, 0.1)',
        borderRadius: '10px',
        padding: '3% 3% 60px',
        '& .__solution': {
          position: 'relative',
          [theme.breakpoints.down('xs')]: {
            padding: '5px 5px 20px 5px',
          },
          [theme.breakpoints.up('sm')]: {
            padding: '20px',
          },
          '& .__balloon': {
            position: 'relative',
            '& img': {
              width: '100%',
            },
          },
          '& .__img': {
            position: 'absolute',
            [theme.breakpoints.down('xs')]: {
              top: '60%',
              left: '21%',
              width: '33%',
            },
            [theme.breakpoints.up('sm')]: {
              top: '55%',
              left: '52%',
              width: '35%',
            },
            [theme.breakpoints.up('md')]: {
              top: '50%',
              left: '50%',
              width: '40%',
            },
            transform: 'translateX(40%) translateY(0%)',
          },
        },
      },
      '& .__greenBorderBalloonBlock': {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 40,
        },
        [theme.breakpoints.up('md')]: {
          paddingBottom: 30,
        },
        '& img': {
          position: 'absolute',
          width: '95%',
          top: '50%',
          left: '50%',
          [theme.breakpoints.down('sm')]: {
            transform: 'translateX(-50%) translateY(-70%)',
            maxWidth: 350,
          },
          [theme.breakpoints.up('md')]: {
            transform: 'translateX(-50%) translateY(-62%)',
            maxWidth: 600,
          },
        },
        '& .__text': {
          position: 'absolute',
          width: '95%',
          top: '50%',
          left: '50%',
          display: 'flex',
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: {
            transform: 'translateX(-50%) translateY(-95%)',
          },
          [theme.breakpoints.up('md')]: {
            transform: 'translateX(-50%) translateY(-110%)',
          },
          '& p': {
            margin: 0,
            color: constants.COLOR_GREEN2,
            fontWeight: 'bold',
            zIndex: 3,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16,
            },
          },
        },
      },
      '& .__taskMsg': {
        textAlign: 'center',
        '& p': {
          display: 'inline-block',
          fontWeight: 'bold',
          [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            lineHeight: 0.4,
            borderBottom: '8px solid #FDDD4C',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: 26,
            lineHeight: 0.3,
            borderBottom: '12px solid #FDDD4C',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 32,
            lineHeight: 0.3,
            borderBottom: '15px solid #FDDD4C',
          },
        },
      },

      '& .__jooto': {
        position: 'absolute',
        top: '2.8%',
        right: '10%',
        width: '50%',
        // width: 670,
        '& img': {
          width: '100%',
        },
      },
      '& .__jootoSp': {
        width: '100%',
        marginBottom: 20,
      },
      '& .__imgSpaceHigh': {
        paddingBottom: '10%',
      },
      '& .__imgSpaceLow': {
        paddingBottom: '1%',
      },
      '& .__recommendedBlock': {
        [theme.breakpoints.down('xs')]: {
          padding: '0 1%',
        },
        [theme.breakpoints.up('sm')]: {
          padding: '0 2%',
        },
        '& .__recommended': {
          textAlign: 'center',
          padding: '0 4%',
          [theme.breakpoints.down('xs')]: {
            boxShadow: '1px 1px 5px #dadada',
            borderRadius: '10px',
            padding: '8% 14%',
            marginBottom: 16,
          },
          [theme.breakpoints.up('sm')]: {
            padding: '3% 5%',
          },
          [theme.breakpoints.up('md')]: {
            padding: '0 4%',
          },

          '& .__recommendedTitle01': {
            [theme.breakpoints.down('xs')]: {
              marginBottom: '10px',
            },
            [theme.breakpoints.up('sm')]: {
              marginBottom: '16px',
            },
            color: '#FFCF00',
            fontSize: 20,
            fontWeight: 'bold',
          },
          '& .__recommendedTitle03': {
            marginBottom: '16px',
            color: '#48C13A',
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        '& .__recommendedMiddle': {
          textAlign: 'center',
          [theme.breakpoints.down('xs')]: {
            boxShadow: '1px 1px 5px #dadada',
            borderRadius: '10px',
            padding: '8% 14%',
            marginBottom: 16,
          },
          [theme.breakpoints.up('sm')]: {
            padding: '3% 5%',
          },
          [theme.breakpoints.up('md')]: {
            borderRight: 'solid 1px #DDDDDD',
            borderLeft: 'solid 1px #DDDDDD',
            padding: '0 4%',
          },
          '& .__recommendedTitle02': {
            marginBottom: '16px',
            color: '#2CB6FF',
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        '& img': {
          width: '100%',
        },
        '& .__descriptionTitle': {
          fontWeight: 'bold',
        },
        '& .__recommendedDescription': {
          marginTop: '15px',
          marginBottom: 0,
          letterSpacing: 1.3,
          lineHeight: 1.7,
          textAlign: 'left',
        },
      },

      '& .__buttonBox': {
        paddingTop: 33,
        display: 'flex',
        justifyContent: 'center',
        '& .__button': {
          width: '100%',
          maxWidth: 337,
        },
      },
      '& .__buttonBoxLeft': {
        [theme.breakpoints.down('xs')]: {
          paddingTop: 5,
          paddingBottom: 20,
        },
        [theme.breakpoints.up('sm')]: {
          paddingTop: 10,
          paddingBottom: 50,
        },
        '& .__button': {
          width: '100%',
          maxWidth: 337,
        },
      },
      '& .__endButton': {
        backgroundColor: constants.COLOR_WHITE,
        [theme.breakpoints.down('xs')]: {
          padding: '0 0 70px',
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
