import { useCallback } from 'react'

import { getGhostTeam } from '../api/team/handlers'
import { callDialog } from '../components/organisms/Dialog'
import { GhostTeamStore } from '../contexts/ghostTeam'
import { GhostTeamMemberStore } from '../contexts/ghostTeamMember'
import { PageManager } from '../contexts/pageManger'
import { LoaderPatterns, ScreenLoaderManager } from '../hooks/loader'
import { exportDebugToS3 } from '../utils/debug'
import { sleep } from '../utils/wait'

export type UnitOfWork = ReturnType<typeof useUnitOfWork>

export const useUnitOfWork = (
  masterTeamId: String,
  team: GhostTeamStore['team'],
  setLoading: ScreenLoaderManager['setLoading'],
  updateGhostTeam: GhostTeamStore['updateGhostTeam'],
  updateMemberPage: GhostTeamMemberStore['updateMemberPage'],
  jumpTo: PageManager['jumpTo']
) => {
  const checkIsGhostTeam = useCallback(
    async (onSuccess: () => Promise<void>, errMsg?: string) => {
      //when subscription is not run(like deleteGhostTeam),we can use it like subscription
      if (team && team.id) {
        const res = await getGhostTeam({ id: team.id })
        if (res) {
          onSuccess()
        } else {
          alert(errMsg ? errMsg : 'おばけ探索チームが存在しません。ダッシュボードに戻ります。')
          jumpTo(`/teams/${masterTeamId}`)
        }
      }
    },
    [team, masterTeamId, jumpTo]
  )

  const transitionExec = useCallback(
    async (pattern: LoaderPatterns, process: () => Promise<void>, duration?: number) => {
      setLoading(pattern)
      await process()
      await sleep(duration || 2000)
      setLoading('idle')
    },
    [setLoading]
  )

  const completeUserProcess = useCallback(
    async (
      page: StagesNum, // No, should not be fixed number. instead use GhostPageName
      submitProcess: () => Promise<void>
    ) => {
      if (!team) return
      try {
        await submitProcess()
        const stage = UserStageTable[page]
        const input: any = { id: team.id, [stage]: 1 } // ADD +1 execution on backend
        page !== 31 && (await updateGhostTeam({ input }))
        await updateMemberPage(page + 1)
      } catch (e) {
        console.log('e', e)
        exportDebugToS3(e, {
          teamId: team.id,
          vars: { page, team },
        })
        callDialog('reload')
      }
    },
    [team, updateGhostTeam, updateMemberPage]
  )

  const completeWithTransition = useCallback(
    async ({
      pattern,
      page,
      submitProcess,
      duration,
    }: {
      pattern: LoaderPatterns
      page: StagesNum
      submitProcess: () => Promise<void>
      duration?: number
    }) => {
      await transitionExec(
        pattern,
        async () => {
          completeUserProcess(page, submitProcess)
        },
        duration
      )
    },
    [transitionExec, completeUserProcess]
  )

  return {
    checkIsGhostTeam,
    transitionExec,
    completeUserProcess,
    completeWithTransition,
  }
}

// deprecated. use dynamic pagenum next.
const UserStageTable = {
  6: 'discover',
  12: 'report',
  16: 'research',
  18: 'like',
  23: 'researchSecond',
  25: 'likeSecond',
  31: 'step4Report',
}
export type StagesNum = keyof typeof UserStageTable
