import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router'

import { HooksContext, BuildingHooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding, useTeamBuildingMemberById, useTeamMember } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import {
  QuestionContainer,
  Button,
  UsefulContainer,
  Timer,
  SelectItems,
  ButtonContainer,
  UserIcon,
} from '../../components'
import { SelectItem } from '../../components/SelectItems'
import { pickupRandomMember } from '../../utils/pickUpTeamBuildingMember'

import { constants } from 'assets'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q2Presen: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { teamBuildingMember } = useTeamBuildingMemberById(
    props.match.params.teamId,
    teamBuilding ? (teamBuilding.q2PresenUserIds ? teamBuilding.q2PresenUserIds[0] : '') : ''
  )
  const { teamMember } = useTeamMember(teamBuildingMember?.id)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    if (teamBuilding && teamBuilding.q2PresenNumber && teamBuilding.q2PresenUserIds) {
      // 発表人数が設定人数になっていれば次のフェーズへ
      if (teamBuilding.q2PresenUserIds?.length >= teamBuilding.q2PresenNumber) {
        await updateTeamBuilding({
          id: props.match.params.teamId,
          status: TeamBuildingStatus.Q2Result,
          timeQ2Result: new Date().toISOString(),
        })
      } else {
        const q2PresenUserIds: string[] = []
        const picked = pickupRandomMember(teamBuildingMembers, teamBuilding.q2PresenUserIds)
        if (picked) {
          q2PresenUserIds.push(picked.userId)
          for (const id of teamBuilding.q2PresenUserIds) {
            q2PresenUserIds.push(id)
          }

          await updateTeamBuilding({
            id: props.match.params.teamId,
            q2PresenUserIds,
            timeQ2Presen: new Date().toISOString(),
          })
        }
      }
    }
  }

  const [keywordItems, setKeywordItems] = React.useState<SelectItem[]>([])

  React.useEffect(() => {
    if (teamBuildingMember && teamBuildingMember.resultQ2Pre) {
      const resultQ2Pre = JSON.parse(teamBuildingMember.resultQ2Pre) as { label: string; name: string }[]

      setKeywordItems(
        resultQ2Pre.map((r) => {
          return {
            ...r,
            value: false,
          }
        })
      )
    }
  }, [teamBuildingMember])

  return (
    <>
      <QuestionContainer active={2} progressDotsProps={{ maxLength: 6, currentProgress: 3 }} questionHeadBar>
        <div className={classes.cardContainer}>
          <UsefulContainer>
            <div className={classes.titleText}>
              ランダムに発表者を指名します！ <br />
              なぜそのキーワードを選んだのかお話しください。
            </div>
            <div className={classes.last}>
              {teamBuilding?.q2PresenUserIds?.length}/{teamBuilding?.q2PresenNumber}人目
            </div>
            <div className={classes.answerContainer}>
              <div className={classes.humanContainer}>
                <div className={classes.humanIcon}>
                  <UserIcon userId={teamMember?.userId} isCircleIcon={false} size={32} />
                </div>
                <div className={classes.human}>{teamMember?.fullName}さん</div>
              </div>

              <div className={classes.answer}>
                <div className={classes.answerText}>選択したキーワード</div>
                {keywordItems.length > 0 ? <SelectItems items={keywordItems} activateGrid unclickable /> : '回答無し'}
              </div>
            </div>
          </UsefulContainer>
        </div>

        {teamBuilding?.timeQ2Presen && (
          <Timer
            from={teamBuilding.timeQ2Presen}
            time={teamBuilding.q2PresenMinute ? teamBuilding.q2PresenMinute * 60 : undefined}
            container
          />
        )}

        {isLeader && (
          <ButtonContainer
            fixed
            marginTopPcLayout={40}
            buttons={[
              <Button key="q2-presen-1" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        )}
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    marginBottom: 150,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 29,
  },
  last: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    marginBottom: 20,
  },
  answerContainer: {
    margin: '0',
    padding: 16,
    border: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      padding: 24,
    },
  },
  humanContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
    marginBottom: 19,
    borderBottom: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
  },
  humanIcon: {
    marginRight: 16,
  },
  human: {
    color: constants.TEXT_GRAY_DARK,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 16,
    fontWeight: 'bold',
  },
  answer: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  answerText: {
    marginBottom: 18,
  },
}))
