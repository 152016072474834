import React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Flexbox, Font } from '../atoms'

import { WaitingMessage } from './WaitingMessage'

type Props = { ownStyles?: React.CSSProperties }

export const WaitingContainer: React.FC<Props> = ({ ownStyles }) => {
  return (
    <Flexbox
      flexDirection="column"
      ownStyles={{
        left: 0,
        bottom: 0,
        zIndex: 10,
        padding: '24px 16px',
        marginTop: 'auto',
        ...ownStyles,
      }}
    >
      <WaitingMessage />
      <Font fontSize={12} bold ownStyles={{ marginTop: 24, color: constGhost.COLOR_GRAY2 }}>
        ファシリテーターの進行をお待ちください
      </Font>
    </Flexbox>
  )
}
