import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import * as queries from '../../graphql/queries'

// -----------------------------
// Params
// -----------------------------
export type IConsultationCommentListParams = {
  id: string
}

// -----------------------------
// Result
// -----------------------------
export type IConsultationCommentListResult = {
  id: string
  consultationId: string
  username: string
  user: {
    id: string
    firstName: string
    lastName: string
    imageUrl: string
    shortDescription: string
    description: string
  }
  content: string
  createdAt: string
  updatedAt: string
}

export const getConsultationCommentList = async (params: IConsultationCommentListParams) => {
  try {
    const res: any = await API.graphql({
      query: queries.getConsultationCommentList,
      variables: {
        consultationId: params.id,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return res?.data?.getConsultationCommentList?.items
  } catch (e) {
    throw e
  }
}
