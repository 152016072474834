import React from 'react'

import { Button as MaterialButton, makeStyles, Theme } from '@material-ui/core'

import { constants } from 'assets'

type Props = {
  onClick?: () => void
  buttonType?: 'primary' | 'secondary' | 'light'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  full?: boolean
  style?: React.CSSProperties
  width?: string | number
  customWidth?: string | number
}

export const Button: React.FC<Props> = ({
  children,
  full = false,
  buttonType = 'primary',
  onClick = () => undefined,
  type,
  disabled,
  style,
  width,
  customWidth,
}) => {
  const classes = useStyles({ full, width, customWidth })

  return (
    <MaterialButton
      onClick={onClick}
      className={`${classes.base} ${classes[buttonType]}`}
      variant="contained"
      color="primary"
      type={type}
      disabled={disabled}
      style={style}
    >
      {children}
    </MaterialButton>
  )
}

interface StyleProps {
  full: boolean
  width?: string | number
  customWidth?: string | number
}

const useStyles = makeStyles((theme: Theme) => ({
  base: ({ full, width, customWidth }: StyleProps) => ({
    width: full ? '100%' : customWidth || width || 288,
    height: 48,
    fontWeight: 'bold',
    borderRadius: 8,
    boxShadow: 'none',
    whiteSpace: 'nowrap',

    '&:hover': {
      boxShadow: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      width: customWidth ? customWidth : '100%',
    },
  }),
  primary: {
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    color: '#fff',

    '&:hover': {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
    },
  },
  secondary: {
    border: `solid 1px ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    backgroundColor: '#fff',

    '&:hover': {
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },
  light: {
    backgroundColor: constants.COLOR_WHITE,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    '&.Mui-disabled': {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
    },
    '&:hover': {
      color: constants.COLOR_WHITE,
    },
  },
}))
