import { useCallback, useEffect, useState } from 'react'

import { GraphQLResult } from '@aws-amplify/api'

import { queryGetKarte } from 'pages/hataraku/pages/results/api'
import { ERROR_MESSAGES_FOR_HATARAKU, isErrorWithGraphQLErrors } from 'pages/hataraku/utils/error'
import { Karte } from 'utils/generated'

export const useGetKarte = (id: string, leaderId: string) => {
  const [karte, setKarte] = useState<Karte | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<GraphQLResult['errors'] | null>(null)

  const getKarte = useCallback(async () => {
    let response: Karte | null = null
    setIsLoading(true)
    setErrors(null)

    try {
      response = await queryGetKarte({ id, leaderId })
      setKarte(response)
    } catch (e) {
      isErrorWithGraphQLErrors(e)
        ? setErrors(e.errors as GraphQLResult['errors'])
        : setErrors([{ message: ERROR_MESSAGES_FOR_HATARAKU.UNEXPECTED }])
    } finally {
      setIsLoading(false)
    }
  }, [id, leaderId])

  useEffect(() => {
    getKarte()
  }, [getKarte])

  return {
    karte,
    isLoading,
    errors,
  }
}
