import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleStartSelfCheck = () => {
    props.history.push(pages.SELFCHECK_LANDING)
    window.scrollTo(0, 0)
  }
  const handlePageSignup = () => {
    props.history.push(`${constants.PAGE_SIGNUP}?su_flg=personal`)
    window.scrollTo(0, 0)
  }

  const handlePageSignin = () => {
    props.history.push(constants.PAGE_SIGNIN + '?r=' + pages.SELFCHECK)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && true && (
          <>
            <div className="__container">
              <div className="__content">
                <div className="__top">
                  <div className="__subTitle">
                    <span>Cocolaboはじめの第一歩</span>
                  </div>
                  <h3>セルフチェック</h3>

                  <div className="__landing__front">
                    <img
                      className="__image"
                      src={process.env.PUBLIC_URL + '/assets/svg/selfcheck/selfcheck_description.svg'}
                      alt={'seflcheck_description'}
                    />
                  </div>

                  <div className="__buttons">
                    {props.user?.id ? (
                      <Button
                        bgColor={constants.COLOR_GREEN_DARK}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>利用開始</div>}
                        onClick={handleStartSelfCheck}
                      />
                    ) : (
                      <>
                        <Button
                          bgColor={constants.COLOR_BLACK}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>無料登録</div>}
                          onClick={handlePageSignup}
                        />
                        <Button
                          bgColor={constants.COLOR_GREEN_DARK}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>ログイン</div>}
                          onClick={handlePageSignin}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="__description">
                  <div className="__title">セルフチェックとは？</div>
                  <div className="__body">
                    エンゲージメントの高いチームを作るにあたり、
                    自分自身の課題や今後のボトルネックを見つけるためのツールです。
                    <br />
                    「チーム作りが順調とは言い切れないかも…」と感じるタイミングで、現状を見つめ、早いタイミングで改善点を明らかにしましょう。
                  </div>
                  <div className="__title">どんな人におすすめ？​</div>
                  <div className="__body">
                    「初めてのチーム作りで上手くいっているかわからない」
                    <br />
                    「チーム作りが順調とは言い切れないかも」
                    <br />
                    「もっとエンゲージメント高いチームを作れるはず」
                    <br />
                    など感じていて、自身の課題を見つけたい方に最適です。
                  </div>
                </div>
                <div className="__functions">
                  <div className="__description">
                    <div className="__title">機能紹介</div>
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/selfcheck/selfchk_1.png'} alt={'selfchk_1'} />
                    </div>
                    <div className="__body">28問の質問に回答するだけで、自身の傾向が出力されます。</div>
                  </div>

                  <div className="__line" />

                  <div className="__description">
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/selfcheck/selfchk_2.png'} alt={'selfchk_2'} />
                    </div>
                    <div className="__body">
                      回答結果はグラフで出力され、良い側面と共に、エンゲージメント高いチームを作る観点から、課題となりそうな点がメッセージとして表示されます。具体的な方法は、アドバイスとして詳細が記載されていますので、ご活用ください。
                    </div>
                  </div>
                </div>

                <div className="__buttons">
                  {props.user?.id ? (
                    <Button
                      bgColor={constants.COLOR_GREEN_DARK}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      body={<div>利用開始</div>}
                      onClick={handleStartSelfCheck}
                    />
                  ) : (
                    <>
                      <Button
                        bgColor={constants.COLOR_BLACK}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>無料登録</div>}
                        onClick={handlePageSignup}
                      />
                      <Button
                        bgColor={constants.COLOR_GREEN_DARK}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>ログイン</div>}
                        onClick={handlePageSignin}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GREEN_LIGHT,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },
        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '40px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '& .__buttons': {
            display: 'flex',
            marginTop: '8px',
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
          },
          '& .__top': {
            '& .__subTitle': {
              textAlign: 'center',
              '& span': {
                background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
                fontWeight: 'bold',
              },
            },
            '& h3': {
              textAlign: 'center',
              fontSize: '24px',
              marginTop: '4px',
            },
          },
          '& .__landing__front': {
            position: 'relative',
            borderRadius: '10px',
            backgroundColor: constants.COLOR_GREEN,
            paddingTop: '30px',
          },
          '& .__image': {
            width: '100%',
          },
          '& .__title': {
            fontSize: '20px',
            color: constants.COLOR_MAIN_DARK,
            margin: '16px 0',
            width: '100%',
            fontWeight: 'bold',
          },

          '& .__description': {
            padding: '16px',
            '& .__image': {
              '& img': {
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              },
            },
            '& .__body': {
              lineHeight: '24px',
              padding: '16px 0',
            },
          },

          '& .__line': {
            margin: '16px 0',
            borderBottom: '1px solid #e3e3e3',
          },
          '& .__functions': {
            backgroundColor: constants.COLOR_GRAY,

            '& .__description': {
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
              },
              '& .__image': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },

              '& .__body': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
