import * as React from 'react'

import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer'

const Woman2: React.FC = () => {
  return (
    <Svg width="630" height="380" viewBox="0 0 630 380">
      <G transform="translate(-1407.31 -488.431)">
        <Rect x="0" y="0" width="630" height="380" rx="10" transform="translate(1407.31 488.43)" fill="#80d6dd" />
        <G>
          <G>
            <Path
              d="M1565.442,624.8l.771,101.539.264,34.786.021,2.794,96.059-.73-.02-2.635c0-.051.012-.1.012-.156l-1.034-136.328c-.022-3.008-2.057-5.452-4.519-5.434l-87.116.661C1567.418,619.321,1565.419,621.792,1565.442,624.8Z"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="17.532"
              height="14.401"
              transform="translate(1606.267 604.624) rotate(-0.458)"
              fill="#ffe4d2"
            />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1646.998 553.575)" fill="#ffe4d2" />
            <Circle cx="15.427" cy="15.427" r="15.427" transform="translate(1552.078 554.296)" fill="#ffe4d2" />
            <Circle cx="52.594" cy="52.594" r="52.594" transform="translate(1562.379 508.762)" fill="#ffe4d2" />
            <Path
              d="M1663.189,846.145a2.544,2.544,0,0,0-2.563-2.525h0l-31.375.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.626,18.286,9.551,10.065-.075,18.224-.235,18.149-10.3,0-.172-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path
              d="M1603.589,846.6a2.547,2.547,0,0,0-2.564-2.525l-31.374.241h0a2.544,2.544,0,0,0-2.523,2.563h0l.007.993h.034c.327,9.844,8.38,9.627,18.286,9.552,10.065-.075,18.224-.235,18.149-10.3,0-.174-.011-.346-.017-.519Z"
              fill="#2a2a2a"
            />
            <Path d="M1598.108,619.092l9.994,26.323,14.968-.114,8.784-26.465Z" fill="#fff" />
            <Path
              d="M1614.438,619.318l-4.6,3.709.639,13.058-3.2,7.669,9.174,25.513,7.694-26.185-3.594-7.322-1.124-4.618,1.026-8.2Z"
              fill="#4866cd"
            />
            <Rect
              x="0"
              y="0"
              width="10.613"
              height="8.709"
              transform="translate(1609.875 627.163) rotate(-0.458)"
              fill="#5b75d2"
            />
            <Path d="M1597.971,619.092l5.692,14.836,11-14.962Z" fill="#e5e5e4" />
            <Path d="M1631.855,618.832l-4.388,13.994-13.35-13.859Z" fill="#e5e5e4" />
            <Path
              d="M1614.026,499.444c32.174-.245,53.459,28.509,57.262,51.938-12.89,7.462-64.092,13.568-88.074-11.843-13.3,22.2-8.692,21.578-26.979,21.717C1555.979,527.362,1581.852,499.692,1614.026,499.444Z"
              fill="#522903"
            />
            <G>
              <Path
                d="M1736.567,652.465,1688.441,681.2a10.128,10.128,0,0,1-13.889-3.5h0l-.347-.58c-2.868-4.8-2.077-12.577,2.727-15.445l48.126-28.734c4.8-2.868,11.8.256,14.668,5.059l.346.58A10.128,10.128,0,0,1,1736.567,652.465Z"
                fill="#ffe4d2"
              />
              <Path d="M1659.98,672.3l60.828-39.029,13.673,21.845-60.831,39.029Z" fill="#545364" />
            </G>
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1566.43 754.943) rotate(-0.434)"
              fill="#545364"
            />
            <Rect
              x="0"
              y="0"
              width="36.461"
              height="91.715"
              transform="translate(1626.043 754.49) rotate(-0.434)"
              fill="#545364"
            />
            <G>
              <Path
                d="M1582.052,601.123a11.766,11.766,0,0,1,8.122,14.466l-4.842,12.987a11.764,11.764,0,0,1-14.467,8.12l-.753-.211a11.767,11.767,0,0,1-8.122-14.466l4.842-12.986a11.768,11.768,0,0,1,14.466-8.122Z"
                fill="#ffe4d2"
              />
              <Path d="M1589.815,622.649l-18.869,75.18-26.355-7.4,18.87-75.177Z" fill="#545364" />
            </G>
            <Path d="M1660.59,620.867l28.11,49.877-22.381,11.8-28.111-49.877Z" fill="#545364" />
          </G>
          <G>
            <Path
              d="M1767.868,846.753v.517c0,10.075,8.166,10.168,18.241,10.168,9.916,0,17.975.155,18.227-9.7h.036v-.995a2.547,2.547,0,0,0-2.547-2.547h-31.388a2.546,2.546,0,0,0-2.546,2.546h0Z"
              fill="#72642a"
            />
            <Path
              d="M1827.515,846.753v.517c0,10.075,8.165,10.168,18.24,10.168,9.916,0,17.976.155,18.228-9.7h.035v-.995a2.545,2.545,0,0,0-2.545-2.547h-31.39a2.546,2.546,0,0,0-2.546,2.546h0Z"
              fill="#72642a"
            />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1827.515 754.737)" fill="#ffe5d2" />
            <Rect x="0" y="0" width="36.49" height="91.786" transform="translate(1767.855 754.737)" fill="#ffe5d2" />
            <Path
              d="M1816.883,551.143s-15.615,25.42-15.615,35.225a26.052,26.052,0,0,0,8.351,18.519c2.9,3.267-10.495,17.768-2.8,32.467,7.988,15.252,5.4,5.044,36.251,5.769s-7.608,32.9,6.189,13.652c6.76-9.43,21.76-29.779,25.712-52.5,3.4-19.563-6.914-63.219-6.914-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1811.67,551.143s15.615,25.42,15.615,35.225a26.052,26.052,0,0,1-8.351,18.519c-2.9,3.267,10.495,17.768,2.8,32.467-7.988,15.252-5.4,5.044-36.251,5.769s7.608,32.9-6.189,13.652c-6.76-9.43-21.764-29.779-25.712-52.5-3.4-19.563,6.914-63.219,6.914-63.219"
              fill="#6d614a"
            />
            <Path
              d="M1859.526,619.023h-87.188a4.536,4.536,0,0,0-4.483,4.558v116.01h96.137V623.579A4.535,4.535,0,0,0,1859.526,619.023Z"
              fill="#7192cc"
            />
            <Rect x="0" y="0" width="17.546" height="14.412" transform="translate(1805.45 604.609)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1751.087 553.946)" fill="#ffe5d2" />
            <Circle cx="15.438" cy="15.438" r="15.438" transform="translate(1846.082 553.946)" fill="#ffe5d2" />
            <Circle cx="52.635" cy="52.635" r="52.635" transform="translate(1761.321 508.733)" fill="#ffe5d2" />
            <Path
              d="M1872.736,560.829c-18.3,0-12.284,3.232-25.759-18.873-23.806,25.615-76.5,17.25-89.471,9.881,3.63-23.475,24.713-52.414,56.91-52.414S1872.736,526.9,1872.736,560.829Z"
              fill="#6d614a"
            />
            <Rect x="0" y="0" width="96.149" height="70.137" transform="translate(1767.855 719.693)" fill="#7192cc" />
            <Rect x="0" y="0" width="49.841" height="9.642" transform="translate(1790.282 619.021)" fill="#ffe5d2" />
            <G>
              <G>
                <Path
                  d="M1732.006,745.123l19.574-64.528a12.748,12.748,0,0,1,15.9-8.5h0a12.745,12.745,0,0,1,8.5,15.891l0,.009-19.574,64.528a12.745,12.745,0,0,1-15.893,8.5l-.007,0h0a12.746,12.746,0,0,1-8.5-15.893Z"
                  fill="#ce6f6f"
                />
                <Rect
                  x="0"
                  y="0"
                  width="116.547"
                  height="28.78"
                  transform="translate(1733.872 734.847) rotate(-73.125)"
                  fill="#ce6f6f"
                />
              </G>
              <G>
                <Path
                  d="M1732.006,745.123l19.574-64.528a12.748,12.748,0,0,1,15.9-8.5h0a12.745,12.745,0,0,1,8.5,15.891l0,.009-19.574,64.528a12.745,12.745,0,0,1-15.893,8.5l-.007,0h0a12.746,12.746,0,0,1-8.5-15.893Z"
                  fill="#ffe5d2"
                />
                <Rect
                  x="0"
                  y="0"
                  width="116.547"
                  height="28.78"
                  transform="translate(1733.872 734.847) rotate(-73.125)"
                  fill="#7192cc"
                />
              </G>
            </G>
            <G>
              <G>
                <Path
                  d="M1890.982,761.014h0a12.747,12.747,0,0,1-15.9-8.5v0l-19.574-64.528a12.745,12.745,0,0,1,8.493-15.9l.007,0h0a12.745,12.745,0,0,1,15.9,8.493l0,.007,19.57,64.537A12.743,12.743,0,0,1,1890.982,761.014Z"
                  fill="#ce6f6f"
                />
                <Rect
                  x="0"
                  y="0"
                  width="28.78"
                  height="116.547"
                  transform="translate(1836.24 631.673) rotate(-16.875)"
                  fill="#ce6f6f"
                />
              </G>
              <G>
                <Path
                  d="M1890.982,761.014h0a12.747,12.747,0,0,1-15.9-8.5v0l-19.574-64.528a12.745,12.745,0,0,1,8.493-15.9l.007,0h0a12.745,12.745,0,0,1,15.9,8.493l0,.007,19.57,64.537A12.743,12.743,0,0,1,1890.982,761.014Z"
                  fill="#ffe5d2"
                />
                <Rect
                  x="0"
                  y="0"
                  width="28.78"
                  height="116.547"
                  transform="translate(1836.24 631.673) rotate(-16.875)"
                  fill="#7192cc"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default Woman2
