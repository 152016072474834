import { actionCreatorFactory } from 'typescript-fsa'

import { Point, QueryGetPointsArgs } from 'utils/generated'

import { IRadar } from './reducer'
import { FETCH_SETUP_POINT, FETCH_SETUP_RADAR, CHANGE_CURRENT_SETUP_STARTED_AT } from './types'

const actionCreator = actionCreatorFactory()
export const SetupActions = {
  fetchPoint: actionCreator.async<QueryGetPointsArgs, Point>(FETCH_SETUP_POINT),
  fetchRadar: actionCreator.async<
    {
      week: number
      setupStartedAt?: string
    },
    IRadar
  >(FETCH_SETUP_RADAR),
  changeCurrentSetupStartedAt: actionCreator<{ date: string }>(CHANGE_CURRENT_SETUP_STARTED_AT),
}
