import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { CSVTemplateDownloader } from './CSVTemplateDownloader'

type Props = {
  type: 'team' | 'team-member' | undefined
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const CSVTemplateDownloadBox: React.FC<Props & StyleProps> = (props) => {
  const { type } = props
  const classes = useStyles(props)

  return (
    <div className={classes.buttonContainer}>
      <span className={classes.helperText}>テンプレート（CSV）ダウンロードはこちら</span>
      <div className={classes.buttonsWrapper}>
        <CSVTemplateDownloader type={type} textCode={'sift-jis'} />
        <CSVTemplateDownloader type={type} textCode={'utf-8'} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    buttonContainer: (props: StyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      ...props.ownStyles,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    }),

    helperText: {
      fontSize: 10,
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },

    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      gap: 8,
      [theme.breakpoints.down('sm')]: {
        gap: 16,
        width: '100%',
        maxWidth: 404,
      },
    },
  }),
  { name: 'CSVTemplateDownloadBox' }
)
