import * as React from 'react'

import { useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'

export const useReactionIcon = () => {
  const processing = React.useRef(false)
  const { likeToggleHandler } = useOnboardingPostActions()

  const handleMyIconClick = (postId: string, teamId: string) => async () => {
    if (processing.current) return

    processing.current = true

    await likeToggleHandler(postId, teamId)

    setTimeout(() => {
      processing.current = false
    }, 3000)
  }

  return { handleMyIconClick }
}
