import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'

import action from './action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onStore: (id: string, name: string, leaderNumber: string, leaderData: any, successCallback: () => void) => {
    dispatch(
      action.store.started({
        id,
        name,
        leaderNumber,
        leaderData: JSON.stringify(leaderData),
        successCallback,
      })
    )
  },
  onResetError: () => dispatch(action.resetError({})),
})

const mapStateToProps = (state: RootStateType) => {
  const { isLoading, error } = state.pages.karteLeaderQuestionnaire
  return {
    user: { ...state.user },
    isLoading,
    error,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
