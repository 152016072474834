import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { useForm, useFieldArray } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import {
  mutationCreateTeamBuildingAction,
  mutationCreateTeamBuildingActionDates,
  mutationRefreshTeamBuilding,
} from 'pages/teams/hooks/graphql'
import {
  TeamBuildingStatus,
  TeamBuildingActionDateStatus,
  TeamBuilding,
  TeamBuildingAction,
  TableConst,
  TeamBuildingV1ResultQ3,
} from 'utils/generated'

import {
  Select,
  ButtonContainer,
  InputCard,
  QuestionContainer,
  Button,
  FormLabel,
  ActionListItem,
  WaitingForLeader,
  BackToIndex,
  ActionDescriptions,
  QuestionPcLayout,
  Timer,
} from '../../components'
import { ScheduleForm } from '../../components'

import { constants } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

interface Input {
  assignedTeamMemberId: string
  dates: Date[]
}

type Date = {
  date?: string
}

export const Q5Actions: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { route } = useHistory()
  const { isLeader, currentTeam, teamBuilding } = React.useContext(HooksContext)
  const { teamBuildingMembers, teamBuildingActions, defaultActions, createdActions } =
    React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const resultQ3 = JSON.parse(teamBuilding?.resultQ3 ?? '') as TeamBuildingV1ResultQ3
  const teamMission = resultQ3?.answer

  const selectMemberOptions = React.useMemo(() => {
    if (!teamBuildingMembers) {
      return []
    } else {
      return teamBuildingMembers.map((member) => ({
        value: member.id,
        label: member.teamMember?.username || '',
      }))
    }
  }, [teamBuildingMembers])

  const [selectedMasterAction, setSelectedMasterAction] = React.useState<TeamBuildingAction | undefined>(undefined)
  const setSelected = (select?: TeamBuildingAction) => {
    setSelectedMasterAction(select)
    window.scrollTo(0, 0)
  }

  const handleClickActionItem = (item: TeamBuildingAction) => {
    const isAssigned = item.assignedTeamMemberId !== TableConst.Dummy

    if (isAssigned) {
      route.push(
        `${replacePathParams(Pages.TeamsToolBuildingDashboardActionPreview, {
          teamId: currentTeam?.id,
          actionId: item.id,
        })}?from=${pathname}`
      )
    } else {
      setSelected(item)
    }
  }

  const { register, control, handleSubmit, getValues, errors, trigger } = useForm<Input>({ mode: 'onChange' })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates',
  })
  React.useEffect(() => {
    append({ date: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = async (data: Input) => {
    if (!currentTeam || !selectedMasterAction) {
      return
    }

    const actionInput = {
      teamId: currentTeam.id,
      name: selectedMasterAction.name || '',
      reason: selectedMasterAction.reason || '',
      content: selectedMasterAction.content || '',
      timing: selectedMasterAction.timing || '',
      assignedTeamMemberId: data.assignedTeamMemberId,
      assignedTeamMemberName:
        teamBuildingMembers.find((member) => member.id === data.assignedTeamMemberId)?.teamMember?.username || '',
    }

    const createdAction = await mutationCreateTeamBuildingAction({ input: actionInput })

    if (createdAction) {
      const datesInput = {
        teamBuildingActionId: createdAction.id,
        dates: data.dates.map((item) => ({
          teamBuildingActionId: createdAction.id,
          date: item.date,
          status: TeamBuildingActionDateStatus.Created,
        })),
      }

      await mutationCreateTeamBuildingActionDates({ input: datesInput })
      await mutationRefreshTeamBuilding({ input: { id: currentTeam.id } })

      const path = replacePathParams(Pages.TeamsToolBuildingDashboardActionCompleted, {
        teamId: currentTeam.id,
        actionId: createdAction.id,
      })

      route.push(`${path}?from=${pathname}`)
    }
  }

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Completed,
      timeCompleted: new Date().toISOString(),
    })
  }

  return (
    <>
      {!selectedMasterAction && (
        <QuestionContainer active={5} questionHeadBar colorTitleText>
          <div className={!isLeader ? classes.rootWaitingControl : classes.root}>
            <div className={classes.cardContainer}>
              <div className={classes.teamTergetWrapper}>
                <div style={{ fontSize: 12 }}>チームの目標</div>
                <div className={classes.textMission}>{teamMission}</div>
              </div>
              <InputCard noRadius>
                <QuestionPcLayout questionNumber={5} />
                <div>
                  <div className={classes.actionIntro}>
                    下記アクションから必要なものを選択し、予定回数と日程を決めましょう。
                  </div>
                  {defaultActions.map((item) => (
                    <ActionListItem
                      key={`q5-actions-default-action-${item.id}`}
                      item={item}
                      onClick={() => handleClickActionItem(item)}
                    />
                  ))}
                </div>
                {createdActions.length > 0 && (
                  <div style={{ marginTop: 24 }}>
                    <FormLabel>任意アクション</FormLabel>
                    {createdActions.map((item) => (
                      <ActionListItem
                        key={`q5-actions-own-action-${item.id}`}
                        item={item}
                        onClick={() => handleClickActionItem(item)}
                      />
                    ))}
                  </div>
                )}

                <Link
                  className={classes.addAction}
                  to={`${replacePathParams(Pages.TeamsToolBuildingDashboardActionCreate, {
                    teamId: currentTeam?.id,
                  })}?from=${pathname}`}
                >
                  <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_add_sm.svg'} alt="" />
                  <span>任意でアクションを追加する</span>
                </Link>
              </InputCard>
            </div>

            {teamBuilding?.timeQ5Actions && <Timer from={teamBuilding.timeQ5Actions} time={180} container />}

            {isLeader ? (
              <ButtonContainer
                fixed
                buttons={[
                  <Button key="button-1" disabled={!teamBuildingActions.length} onClick={() => onNext()}>
                    完了
                  </Button>,
                ]}
              />
            ) : (
              <WaitingForLeader wrapperBottom={10} />
            )}
          </div>
        </QuestionContainer>
      )}

      {selectedMasterAction && (
        <QuestionContainer addPaddingSide>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.root}>
              <BackToIndex onClick={() => setSelected(undefined)} full />
              <div className={classes.actionTitleWrap}>
                <h1>{selectedMasterAction.name}</h1>
              </div>
              <InputCard>
                <ActionDescriptions action={selectedMasterAction} />
              </InputCard>
              <div>
                <div style={{ marginTop: 24 }}>
                  <FormLabel>終了日までの実施予定</FormLabel>
                </div>

                {!!fields.length && (
                  <div style={{ marginTop: 16 }}>
                    <InputCard>
                      <ScheduleForm
                        fields={fields}
                        getValues={getValues}
                        errors={errors}
                        trigger={trigger}
                        control={control}
                        remove={remove}
                        append={append}
                      />
                    </InputCard>
                  </div>
                )}

                <div className={`${classes.formItem} ${classes.mb}`}>
                  <FormLabel>推進責任者</FormLabel>
                  <Select
                    name={'assignedTeamMemberId'}
                    options={selectMemberOptions}
                    ref={register({ required: '推進責任者を選択してください' })}
                  />
                </div>
              </div>
            </div>
            {teamBuilding?.timeQ5Actions && (
              <Timer from={teamBuilding.timeQ5Actions} time={180} container marginBottom={50} />
            )}
            <ButtonContainer
              fixed
              buttons={[
                <Button key={`button-1`} type="submit">
                  保存
                </Button>,
                <Button key={`button-2`} buttonType="secondary" onClick={() => setSelected(undefined)}>
                  編集をキャンセル
                </Button>,
              ]}
            />
          </form>
        </QuestionContainer>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      marginBottom: 50,
    },
    rootWaitingControl: {
      width: '100%',
      minHeight: 'calc(100vh - 150px)', // -150px is Container padding.
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 150px)', // -128px is Container padding. -72px is header. 50px is bottom adjustment.
        marginBottom: -50,
      },
    },

    cardContainer: {
      marginBottom: 40,
    },

    wrapper: {
      marginBottom: 40,
    },

    actionTitleWrap: {
      margin: '24px 0',

      '& > h1': {
        textAlign: 'center',
        fontSize: 16,
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
    },

    main: {
      padding: '24px 16px',
      backgroundColor: '#fff',
      borderRadius: '8px',
    },

    addAction: {
      display: 'inline-block',
      marginTop: 24,
      cursor: 'pointer',

      '& > img': {
        verticalAlign: 'middle',
      },

      '& > span': {
        marginLeft: 8,
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        verticalAlign: 'middle',
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
    },
    actionIntro: {
      marginBottom: 14,
    },
    teamTergetWrapper: {
      margin: '0 0 8px',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column',
      padding: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 24,
      },
    },
    textMission: {
      fontWeight: 'bold',
      marginTop: '12px',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
    formItem: {
      marginTop: 24,
    },
    mb: {
      marginBottom: 100,
    },
  }),
  { name: 'Q5Actions' }
)
