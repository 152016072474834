import { API, GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'

import { MutationSendAuthCompletedEmailArgs } from 'utils/generated'

export const MutationUpdateAccount = gql`
  mutation UpdateAccount($input: UserUpdateInput!) {
    updateAccount(input: $input) {
      q1
      q2
      q3
      q4
      q5
      anketDate
    }
  }
`

export const mutationSendAuthCompletedEmail = async (args: MutationSendAuthCompletedEmailArgs): Promise<boolean> => {
  const response = (await API.graphql({
    query: gql`
      mutation sendAuthCompletedEmail($email: String!) {
        sendAuthCompletedEmail(email: $email)
      }
    `,
    variables: { ...args },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })) as GraphQLResult<{ sendAuthCompletedEmail: boolean }>

  return response.data?.sendAuthCompletedEmail ?? false
}
