import { useCallback } from 'react'

import { GhostTeam, GhostTeamMember, VoteReportsResult } from 'utils/generated'

import { GhostStore } from '../contexts/ghost'
import { GhostTeamMemberStore } from '../contexts/ghostTeamMember'
import { HistoryStore } from '../contexts/history'
import { helpers, PageManager } from '../contexts/pageManger'
import { getLoadinPattern } from '../hooks/loader'

import { UnitOfWork } from './unitOfWork'

export type EventHandlers = ReturnType<typeof useEventHandlers>

export const useEventHandlers = (
  updateMemberPage: GhostTeamMemberStore['updateMemberPage'],
  { getPathChangeInfo, jumpTo }: PageManager,
  transitionExec: UnitOfWork['transitionExec'],
  incrementFound: GhostStore['incrementFound'],
  setResult: HistoryStore['setResult']
) => {
  const transitionJump2Result = useCallback(
    async (dest: string, teamMember: GhostTeamMember) => {
      const pattern = getLoadinPattern(dest, teamMember)
      await transitionExec(pattern, async () => jumpTo(dest), 3000)
    },
    [transitionExec, jumpTo]
  )

  const pageSync = useCallback(
    async (team: GhostTeam, teamMember: GhostTeamMember) => {
      if (!teamMember.page) return
      const pageNum = helpers.checkTeamProgress(team, teamMember)
      if (pageNum > teamMember.page) {
        await updateMemberPage(pageNum)
      } else {
        const info = getPathChangeInfo(team, pageNum)
        if (info) {
          if (info.type === 'jump') {
            jumpTo(info.dest)
          } else if (info.type === 'transJump') {
            transitionJump2Result(info.dest, teamMember)
          }
        }
      }
    },
    [updateMemberPage, getPathChangeInfo, jumpTo, transitionJump2Result]
  )

  const assignVotedResult = useCallback(
    (res: VoteReportsResult) => {
      const history = res.historyWithGhost
      if (history && history.ghostId) {
        incrementFound(history.ghostId)
        setResult(history)
      }
    },
    [incrementFound, setResult]
  )

  const handleMemberDeleted = useCallback(() => {
    window.confirm('チームから除外されました。ホーム画面に戻ります。') // not working
    jumpTo('/')
  }, [jumpTo])

  return {
    pageSync,
    assignVotedResult,
    handleMemberDeleted,
  }
}
