import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { addHours } from 'date-fns'

import { useTeamManage } from 'pages/onboarding/hooks/teamManage'

export const useLastVisitAction = (teamId?: string, userId?: string) => {
  const { pathname } = useLocation()

  const { updateTeamMemberWithoutRefresh } = useTeamManage()

  useEffect(() => {
    const updateFunc = () => {
      teamId &&
        updateTeamMemberWithoutRefresh({
          teamId,
          lastVisitAction: new Date().toISOString(),
        })
    }
    const checkLvaSteps = () => {
      const now = new Date()
      const lastVisitActionTime = localStorage.getItem(`last-visit-action-time-${teamId}-${userId}`)
      if ((lastVisitActionTime === null || now > addHours(new Date(lastVisitActionTime), 1)) && teamId) {
        updateFunc()
        localStorage.setItem(`last-visit-action-time-${teamId}-${userId}`, now.toISOString())
      }
    }
    checkLvaSteps()
  }, [pathname, teamId, updateTeamMemberWithoutRefresh, userId])
}
