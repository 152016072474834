/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'

import { makeStyles, Modal, Theme } from '@material-ui/core'

import Button from 'components/Button'
import { OnboardingDaily, Maybe } from 'utils/generated'

import { conditions } from './completeModalText'

import BAD_FEELING_SOMEWHAT from './assets/BAD_FEELING_SOMEWHAT.svg'
import BAD_PRIVATE_TIME from './assets/BAD_PRIVATE_TIME.svg'
import BAD_WORK_PROGRESS from './assets/BAD_WORK_PROGRESS.svg'
import BAD_FEELING from './assets/GOOD_FEELING_SOMEWHAT.svg'
import BAD_HUMAN_RELATION from './assets/GOOD_PRIVATE_TIME.svg'
import GREAT_SHADE from './assets/GOOD_WORK_PROGRESS.svg'
import * as constants from 'assets/constants'

interface IOwnProps {
  open: boolean
  condition?: Maybe<OnboardingDaily>
  handleClose: () => void
}

type Props = IOwnProps

export const CompleteModal: React.FC<Props> = (props) => {
  const classes = useStyles()

  const conditionImg = () => {
    switch (props.condition) {
      case 'GREAT_SHADE':
        return GREAT_SHADE
      case 'BAD_PRIVATE_TIME':
        return BAD_PRIVATE_TIME
      case 'BAD_WORK_PROGRESS':
        return BAD_WORK_PROGRESS
      case 'BAD_HUMAN_RELATION':
        return BAD_HUMAN_RELATION
      case 'BAD_FEELING':
        return BAD_FEELING
      case 'BAD_FEELING_SOMEWHAT':
        return BAD_FEELING_SOMEWHAT
    }
  }

  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
      }}
    >
      <div className={classes.main}>
        <div className={classes.card}>
          <div className={classes.img}>
            <img src={conditionImg()} alt={props.condition!} />
          </div>
          <div className={classes.title}>今日の気分が共有されました！</div>
          <div className={classes.sentence}>{conditions[props.condition!]}</div>
          <Button
            body={'確認'}
            fullWidth
            onClick={props.handleClose}
            style={{
              borderRadius: 8,
              borderColor: constants.COLOR_ONBOARDING_MAIN,
              backgroundColor: '#FFFFFF',
              boxShadow: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center',
              color: constants.COLOR_ONBOARDING_MAIN,
              margin: '32px 0 16px',
              height: 48,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },

  card: {
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 10,
    padding: '16px 16px 0 16px',
    width: '350px',
    boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
  },

  title: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '16px 0 0',
    textAlign: 'center',
    color: constants.COLOR_ONBOARDING_MAIN,
  },

  sentence: {
    fontSize: 14,
    fontWeight: 300,
    padding: '16px 0 0',
    whiteSpace: 'pre-wrap',
  },
}))
