import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import { Button, ButtonContainer, QuestionContainer, WaitingForLeader } from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q3LP: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { isLeader } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q3,
    })
  }

  return (
    <>
      <QuestionContainer
        active={3}
        // title={QuestionTitles.Q3}
        // subTitle={'皆で議論し、明文化しましょう'}
      >
        <div className={classes.root}>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 80,
            }}
          >
            <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/waitingPeople.svg'} alt="" />
          </div>

          {isLeader ? (
            <ButtonContainer
              fixed
              buttons={[
                <Button key="q3-lp-1" type={'submit'} onClick={onNext}>
                  次へ
                </Button>,
              ]}
            />
          ) : (
            <WaitingForLeader />
          )}
        </div>
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
