import { useState } from 'react'

import { useCommentEpic } from 'pages/hint/_shared/hooks/useCommentEpic'
import { ICreateConsultationCommentParams } from 'pages/hint/_shared/stores/apis/createConsultationComment'
import { ICreateHintCommentParams } from 'pages/hint/_shared/stores/apis/createHintComment'

// -----------------------------
// interface
// -----------------------------
export type IuseDialogCommentProps = {
  onSubmitHandler: () => void
  onClose: () => void
  onDoNotPublish: () => void
  onDoPublish: (params: ICreateHintCommentParams | ICreateConsultationCommentParams) => Promise<void>
  getOpen: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const useDialogComment = (): IuseDialogCommentProps => {
  const [open, setOpen] = useState<boolean>(false)
  const commentEpic = useCommentEpic()

  /**
   * ダイアログをオープンするハンドラー
   */
  const onSubmitHandler = (): void => {
    onOpenDialog()
  }

  /**
   * ダイアログをクローズする時の処理
   */
  const onClose = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログをオープンする時の処理
   */
  const onOpenDialog = (): void => {
    setOpen(true)
  }

  /**
   * ダイアログ内の記事公開ボタンをキャンセルした時
   */
  const onDoNotPublish = (): void => {
    setOpen(false)
  }

  /**
   * ダイアログ内の記事公開ボタンを押した時
   */
  const onDoPublish = async (params: ICreateHintCommentParams | ICreateConsultationCommentParams): Promise<void> => {
    await commentEpic.createCommentEpic(params)
  }

  /**
   * ダイアログの状態を返す
   */
  const getOpen = (): boolean => {
    return open
  }

  return {
    onSubmitHandler,
    onClose,
    onDoNotPublish,
    onDoPublish,
    getOpen,
  }
}
