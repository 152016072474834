import React from 'react'

import { useCookies } from 'react-cookie'

import { teamCookieKeys } from 'pages/karte/member/static'

interface Props {
  phase: Phase
  setPhase: (value: Phase) => void
}

export enum Phase {
  Prepare = 'prepare',
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Q5 = 'q5',
  Q6 = 'q6',
  Q7 = 'q7',
  Q8 = 'q8',
  Q9 = 'q9',
  Q10 = 'q10',
  Q11 = 'q11',
}

export const TeamToolsKarteDiagnoseContext = React.createContext<Props>({} as Props)

export const TeamToolsKarteDiagnoseContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [phase, setPhase] = React.useState<Phase>(Phase.Prepare)
  const [, setCookie] = useCookies([teamCookieKeys.phase])

  const setPhaseHook = (value: Phase) => {
    setPhase(value)
    setCookie(teamCookieKeys.phase, value)
    window.scrollTo(0, 0)
  }

  return (
    <TeamToolsKarteDiagnoseContext.Provider
      value={{
        phase,
        setPhase: setPhaseHook,
      }}
    >
      {children}
    </TeamToolsKarteDiagnoseContext.Provider>
  )
}
