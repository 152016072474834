import { useCallback, useMemo, Dispatch, SetStateAction, useState } from 'react'

import { get } from 'lodash-es'

import { useManageTeam, useManageTeamMembers } from 'pages/teams/hooks'
import { Team, TeamTool, TeamMemberRole, TeamMemberCreationAttributes } from 'utils/generated'

import { PatternEmailLowerCase, PatternEmailLowerCaseMessage } from 'assets/patterns'

export const useCsvUpload = (csvData: string[][], setCsvData: Dispatch<SetStateAction<string[][]>>) => {
  const [processing, setProcessing] = useState<boolean>(false)
  const [uploadedNum, setUploadedNum] = useState<{ team?: number; member?: number }>()
  const [uploadErrors, setUploadErrors] = useState<string[] | null>(null)

  const { createTeam } = useManageTeam()
  const { createTeamMemberList } = useManageTeamMembers()

  const getTeamToolIds = (arr: string[]) => {
    const result = []
    if (arr[2] === 'ON') {
      result.push(TeamTool.Building)
    }
    if (arr[3] === 'ON') {
      result.push(TeamTool.BuildingCheck)
    }
    if (arr[4] === 'ON') {
      result.push(TeamTool.Karte)
    }
    if (arr[5] === 'ON') {
      result.push(TeamTool.Ghost)
    }

    return result
  }

  const hasTeamMembers = useMemo(() => {
    for (const [index, d] of csvData.entries()) {
      if (index === 0) continue
      if (!d[0] && d[6] && d[7] && d[8]) return true
    }

    return false
  }, [csvData])

  const onUploadTeams = useCallback(
    async (sendEmailAt: string) => {
      setProcessing(true)
      const errors: string[] = []
      let team: Team | null = null
      let teamMembers: TeamMemberCreationAttributes[] = []
      let uploadedTeamNum = 0
      let uploadedMemberNum = 0

      const createMembers = async () => {
        if (teamMembers.length && team) {
          const res = await createTeamMemberList({
            teamId: team.id,
            members: teamMembers,
            invitationDateTime: sendEmailAt || undefined,
          })

          if (res?.items?.length) {
            uploadedMemberNum = uploadedMemberNum + res.items.length
          }
          if (res?.errors?.length) {
            for (const err of res.errors) {
              errors.push(`エラーが発生しました\n${err.email}　${err.errorMessage}`)
            }
          }

          teamMembers = []
          team = null
        }
      }

      for (const [index, d] of csvData.entries()) {
        if (index === 0) continue
        const t = Array.from(csvData)
        const teamToolIds = getTeamToolIds(d)
        // d[0]: teamName
        // d[1]: teamPlanCodes
        // d[2]: キックオフ
        // d[3]: TBチェック
        // d[4]: カルテ
        // d[5]: オバケ
        // d[6]: fullName
        // d[7]: username
        // d[8]: email
        // d[9]: organization
        // d[10]: position
        // d[11]: リーダー
        if (d[0]) {
          // チームが切り替わるタイミングで、キープしたメンバー情報を登録する。
          await createMembers()

          const [created, createdErrors] = await createTeam({
            name: d[0],
            teamPlanIds: d[1].split(','),
            teamToolIds,
          })

          if (created) {
            uploadedTeamNum = ++uploadedTeamNum

            setCsvData(t)
            team = created
          } else {
            errors.push(`${index + 1}行目: ${get(createdErrors, '[0].message', 'エラーが発生しました')}`)
            setCsvData(t)
            team = null
          }
        } else {
          if (team) {
            if (d[6] && d[7] && d[8]) {
              if (d[8].match(new RegExp(PatternEmailLowerCase))) {
                teamMembers.push({
                  fullName: d[6],
                  username: d[7],
                  email: d[8],
                  organization: d[9],
                  position: d[10],
                  role: d[11] === 'はい' ? [TeamMemberRole.Leader, TeamMemberRole.Member] : [TeamMemberRole.Member],
                })
                setCsvData(t)
              } else {
                errors.push(`${index + 1}行目: ${PatternEmailLowerCaseMessage}`)
              }
            } else {
              // データ不足行はエラーとするが、空行は対象外とする。
              if (!(d.length === 1 && d[0] === '')) {
                errors.push(
                  `${index + 1}行目: ユーザー情報に不足があります（氏名・ユーザー名・メールアドレスは必須です）`
                )
              }
            }
          } else {
            if (!(d.length === 1 && d[0] === '')) {
              errors.push(`${index + 1}行目: チーム情報が不足しています`)
            }
            setCsvData(t)
          }
        }
      }

      // 前行までにキープしたメンバー情報を登録する。
      await createMembers()

      setProcessing(false)

      if (uploadedTeamNum || uploadedMemberNum) {
        setUploadedNum({ team: uploadedTeamNum || undefined, member: uploadedMemberNum || undefined })
      }

      if (errors.length > 0) {
        setUploadErrors(errors)
      }
    },
    [createTeam, createTeamMemberList, csvData, setCsvData]
  )

  return { onUploadTeams, processing, uploadedNum, setUploadedNum, uploadErrors, setUploadErrors, hasTeamMembers }
}
