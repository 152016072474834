import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'

import actions from 'pages/hint/_shared/stores/actions'
import { useResult } from 'pages/hint/search/index/components/result/useResult'

// -----------------------------
// interface
// -----------------------------
export type IusePagingButton = {
  onNextButton: (pageCategory: string, id?: string) => void
  onPreviewButton: (pageCategory: string, id?: string) => void
  isTop: () => boolean
}

// -----------------------------
// hooks
// -----------------------------
export const usePagingButton = (): IusePagingButton => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const result = useResult()
  const params = useParams() as { id: string }
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentType, setCurrentType] = useState<string>('')
  const pagesSelectors = useSelector((state: RootStateType) => state.hint.pages)

  // -----------------------------
  // LifeCycle
  // -----------------------------
  useEffect(() => {
    setCurrentType(location.pathname.split('/').slice(2)[0])
    getPageNum()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  /**
   * Previousボタンを押したとき
   */
  const onPreviewButton = (pageCategory: string): void => {
    dispatch(actions.getPages({ currentPages: currentPage - 1, pageAction: 'PREVIOUS', processing: true }))

    // Case PROFILE Page
    if (isPageProfile(pageCategory)) {
      if (currentPage - 1 <= 1) {
        history.push(`/hint/tips/${pageCategory}/${params.id}`)
      } else {
        history.push(`/hint/tips/${pageCategory}/${params.id}/pages`)
      }
    }
    // Case SEARCH Page
    if (isPageSearch(pageCategory)) {
      if (currentPage - 1 <= 1) {
        history.push(`/hint/${currentType}/${pageCategory}/category/${result.getResultCategory()}`)
      } else {
        history.push(`/hint/${currentType}/${pageCategory}/category/${result.getResultCategory()}/pages`)
      }
    }
    // Case TIPS & FAQ Page
    if (!isPage(pageCategory)) {
      if (currentPage - 1 <= 1) {
        history.push(`/hint/${pageCategory}`)
      } else {
        history.push(`/hint/${pageCategory}/pages`)
      }
    }
  }

  /**
   * Nextボタンを押したとき
   */
  const onNextButton = (pageCategory: string): void => {
    dispatch(actions.getPages({ currentPages: currentPage + 1, pageAction: 'NEXT', processing: true }))

    // Case PROFILE Page
    if (isPageProfile(pageCategory)) {
      history.push(`/hint/tips/${pageCategory}/${params.id}/pages/`)
    }
    // Case SEARCH Page
    if (isPageSearch(pageCategory)) {
      history.push(`/hint/${currentType}/${pageCategory}/category/${result.getResultCategory()}/pages/`)
    }
    // Case TIPS & FAQ Page
    if (!isPage(pageCategory)) {
      history.push(`/hint/${pageCategory}/pages/`)
    }
  }

  /**
   * 現在のページ番号を取得する
   */
  const getPageNum = (): void => {
    setCurrentPage(isNaN(pagesSelectors.currentPages) ? 1 : pagesSelectors.currentPages)
  }

  /**
   * ページがTOPかどうかを判断する
   */
  const isTop = (): boolean => {
    return currentPage - 1 >= 1 ? true : false
  }

  /**
   * ProfileページかSearchページかを判定する
   */
  const isPage = (pageCategory: string): boolean => {
    return isPageProfile(pageCategory) || isPageSearch(pageCategory) ? true : false
  }

  /**
   * Profileページかを判定する
   */
  const isPageProfile = (pageCategory: string): boolean => {
    return pageCategory === 'profile' ? true : false
  }

  /**
   * Searchページかを判定する
   */
  const isPageSearch = (pageCategory: string): boolean => {
    return pageCategory === 'search' ? true : false
  }

  return {
    onNextButton,
    onPreviewButton,
    isTop,
  }
}
