import React from 'react'

import { RouteComponentProps } from 'react-router'

import { useTeamMembers } from 'pages/teams/hooks'
import { TeamKarte } from 'utils/generated'

import { Container } from '../../components/Container'
import { useTeamKarteList } from '../../hooks/teamKarte'

import { DataList } from './dataList/DataList'
import { HomeKarteResults } from './HomeKarteResults'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsToolsKarteHomePage: React.FC<Props> = (props) => {
  const { teamId } = props.match.params

  const { teamMembers } = useTeamMembers(teamId)
  const { teamKarteList, loaded } = useTeamKarteList(teamId)

  const [selectedTeamKarte, setSelectedTeamKarte] = React.useState<TeamKarte | undefined>(undefined)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedTeamKarte])

  return (
    <Container>
      <div style={{ display: !selectedTeamKarte ? 'block' : 'none' }}>
        <DataList
          teamMemberList={teamMembers}
          teamKarteList={teamKarteList}
          loaded={loaded}
          setSelectedTeamKarte={setSelectedTeamKarte}
        />
      </div>

      <div style={{ display: selectedTeamKarte ? 'block' : 'none' }}>
        {selectedTeamKarte && (
          <HomeKarteResults teamKarte={selectedTeamKarte} onBack={() => setSelectedTeamKarte(undefined)} />
        )}
      </div>
    </Container>
  )
}
