import * as React from 'react'

import { withStyles, createStyles, Theme, StyleRules, WithStyles } from '@material-ui/core'
import classNames from 'classnames'

import * as constants from '../assets/constants'

interface IOwnProps {
  failure?: boolean
  success?: boolean
  complete?: boolean
  handleClose?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  handlePage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  body?: any
  footer?: any
  noShadow?: boolean
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__msg__container">
          <div className={classNames(['__msg__container__content', props.noShadow && '__msg__container__noShadow'])}>
            {props.handleClose && (
              <div className="__msg__container__content__close">
                <img
                  className="__msg__container__content__close__icon"
                  src={process.env.PUBLIC_URL + '/assets/svg/close_message.svg'}
                  alt={'close'}
                  onClick={props.handleClose}
                />
              </div>
            )}

            <div className="__msg__container__content__header">
              {props.success && (
                <img
                  className="__msg__container__content__header__icon"
                  src={process.env.PUBLIC_URL + '/assets/svg/success.svg'}
                  alt={'success'}
                />
              )}
              {props.complete && (
                <img
                  className="__msg__container__content__header__icon"
                  src={process.env.PUBLIC_URL + '/assets/svg/complete.svg'}
                  alt="complete"
                />
              )}
              {props.failure && (
                <img
                  className="__msg__container__content__header__icon"
                  src={process.env.PUBLIC_URL + '/assets/svg/failureNoColor.svg'}
                  alt={'failure'}
                />
              )}
            </div>

            <div className="__msg__container__content__body">{props.body}</div>

            {props.footer && <div className="__msg__container__content__footer">{props.footer}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      color: constants.TEXT_GRAY_DARK,
      '& .__msg__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },

        '&__noShadow': {
          borderRadius: '0 !important',
          boxShadow: 'none !important',
        },
        '&__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: '10px',
          padding: '16px 24px 41px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__close': {
            display: 'flex',
            marginRight: -8,

            '& img': {
              cursor: 'pointer',
              marginLeft: 'auto',
              marginRight: 0,
              width: 16,
              height: 16,
            },
          },
          '&__header': {
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 0 0',
            '&__icon': {
              width: '48px',
              height: '48px',
            },
          },
          '&__body': {
            padding: '36px 0 0',
            lineHeight: '24px',
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            '& p': {
              margin: 0,
            },
          },

          '&__footer': {
            padding: '16px 0',
          },
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
