/**
 * base64の文字列をデコードする
 */
export const base64ToFile = (data: string) => {
  try {
    const separetedDate = data.split(',')
    const mimeTypeData = separetedDate[0].match(/:(.*?);/)
    const mimeType = Array.isArray(mimeTypeData) ? mimeTypeData[0] : ''
    const decodedData = atob(separetedDate[1])
    const dataLength = decodedData.length
    const arrayBuffer = new ArrayBuffer(dataLength)
    const u8arr = new Uint8Array(arrayBuffer)

    for (let i = 0; i < dataLength; i += 1) {
      u8arr[i] = decodedData.charCodeAt(i)
    }

    return new Blob([u8arr], { type: mimeType })
  } catch (errors) {
    return new Blob([])
  }
}

/**
 * MEMO: 画像容量計測のテスト用関数
 */
export const testGeneratedDownloadAnker = (base64: string, name: string) => {
  //アンカータグを生成しhrefへBase64文字列をセット
  const a = document.createElement('a')
  a.href = base64
  //ダウンロード時のファイル名を指定
  a.download = name
  //クリックイベントを発生させる
  a.click()
}
