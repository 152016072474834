import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
}

export const BackgroundFramework: React.FC<Props> = ({ children }) => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'xs')

  return (
    <section className={classes.root}>
      <WidthControlWrapper className={classes.wrapper}>{children}</WidthControlWrapper>
      <div className={classes.contentImage}>
        <img
          src={`${process.env.PUBLIC_URL}/img/obakesagashi/search-ghosts${isXsDown ? '-sp' : ''}.png`}
          alt="職場のオバケたち"
        />
      </div>
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: constants.COLOR_NAVY,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      paddingTop: 40,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0))',
        mixBlendMode: 'multiply',
        pointerEvents: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundImage: `
        url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-ghost-town.png)
        `,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom 100px center',
        backgroundSize: 'contain',
      },
    },
    wrapper: {
      zIndex: 1,
    },
    contentImage: {
      maxWidth: 800,
      marginTop: 56,
      marginBottom: 50,
      '& img': {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 40,
      },
    },
  }),
  { name: 'BackgroundFramework' }
)
