import * as React from 'react'

import { queryGetOnboardingPostLikeList, queryGetAccount } from 'pages/onboarding/graphql'
import { Maybe } from 'utils/generated'

export const useReaction = (postId: string, isLiked: boolean | null | undefined) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [reactions, setReactions] = React.useState<(Maybe<number> | undefined)[] | null>(null)
  const [reactionCounts, setReactionCounts] = React.useState<{ [key: string]: number } | null>(null)
  const [myIcon, setMyIcon] = React.useState<number | null>(null)

  const refresh = React.useCallback(async () => {
    if (!postId) {
      return
    }

    setLoading(true)

    try {
      const resList = queryGetOnboardingPostLikeList({ postId })
      const resUser = queryGetAccount()
      const [responseList, responseUser] = await Promise.all([resList, resUser])

      const postLikeLists = responseList.items

      const reactions = postLikeLists.length ? postLikeLists.map((reaction) => reaction.icon) : null
      setReactions(reactions)

      const reactionCounts = reactions
        ? reactions.reduce((prev, current) => {
            if (current === null) prev[0] = (prev[0] || 0) + 1 // for existing like - no 'icon' attribute is '0' icon
            if (current || current === 0) prev[current] = (prev[current] || 0) + 1
            return prev
          }, {} as { [key: string]: number })
        : null
      setReactionCounts(reactionCounts)

      const myLike = postLikeLists.find((list) => list.userId === responseUser.getAccount?.id)
      const myIcon = myLike
        ? myLike.icon ?? 0 // for existing like - no 'icon' attribute is '0' icon
        : null
      setMyIcon(myIcon)
    } catch (e) {
      console.log('e', e)
    }

    setLoading(false)
  }, [postId])

  React.useEffect(() => {
    refresh()
  }, [postId, refresh, isLiked])

  return { loading, reactions, reactionCounts, myIcon }
}
