import * as React from 'react'

import { Circle, ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const KarteIcon: React.FC = () => {
  return (
    <Svg width="104" height="104" viewBox="0 0 104 104">
      <Defs>
        <ClipPath id="clip-path">
          <Path d="M583.44,393.693a52,52,0,1,1-52,52A52,52,0,0,1,583.44,393.693Z" />
        </ClipPath>
      </Defs>
      <G>
        <G transform="translate(-531.44 -393.693)">
          <G>
            <Path d="M583.44,393.693a52,52,0,1,1-52,52A52,52,0,0,1,583.44,393.693Z" fill="#b9fcff" />
          </G>
          <Path d="M583.44,393.693a52,52,0,1,1-52,52A52,52,0,0,1,583.44,393.693Z" fill="#80d6dd" />
          <G>
            <G>
              <G
                // クリップに必要
                // @ts-ignore
                clipPath="url(#clip-path)"
              >
                <G>
                  <Path
                    d="M606.966,465.9H560.109a2.724,2.724,0,0,0-2.409,2.941v73.327c0,.029.006.056.007.085v1.418h51.668v-74.83A2.724,2.724,0,0,0,606.966,465.9Z"
                    fill="#545365"
                  />
                  <Rect x="0" y="0" width="9.429" height="7.746" transform="translate(577.906 458.16)" fill="#ffe5d2" />
                  <Path d="M573.631,465.9l4.83,14.2h8.05l5.27-14.2Z" fill="#fff" />
                  <Path
                    d="M579.778,468.072l.586,4.4-.586,2.488-1.9,3.953,4.245,14.053,4.83-13.76L585.2,475.1l.29-7.026L583,466.1Z"
                    fill="#4966d1"
                  />
                  <Rect
                    x="0"
                    y="0"
                    width="5.709"
                    height="4.684"
                    transform="translate(579.778 470.297)"
                    fill="#5b75d6"
                  />
                  <Path d="M582.879,465.9l5.977,8,3-8Z" fill="#e6e6e5" />
                  <Path d="M583.173,465.9l-7.124,7.509-2.417-7.509Z" fill="#e6e6e5" />
                  <G>
                    <Rect
                      x="0"
                      y="0"
                      width="14.661"
                      height="31.253"
                      transform="matrix(0.664, -0.748, 0.748, 0.664, 598.65, 478.127)"
                      fill="#fff"
                    />
                  </G>
                  <G>
                    <Rect
                      x="0"
                      y="0"
                      width="31.253"
                      height="14.661"
                      transform="matrix(0.748, -0.664, 0.664, 0.748, 534.761, 487.904)"
                      fill="#fff"
                    />
                  </G>
                  <Path
                    d="M571.168,465.592l-11.393-.02a2.723,2.723,0,0,0-2.409,2.941v99.03c0,.029.006.056.007.084v1.418h11.611V494.216C568.984,466.895,572.493,465.592,571.168,465.592Z"
                    fill="#fff"
                  />
                  <Path
                    d="M598.091,494.216v74.829H609.7v-1.418c0-.028.007-.055.007-.084v-99.03a2.723,2.723,0,0,0-2.409-2.941l-12.732.02C593.243,465.592,598.091,466.895,598.091,494.216Z"
                    fill="#fff"
                  />
                  <G>
                    <G>
                      <Circle cx="8.345" cy="8.345" r="8.345" transform="translate(599.948 430.67)" fill="#ffe5d2" />
                      <Circle cx="8.345" cy="8.345" r="8.345" transform="translate(548.603 431.06)" fill="#ffe5d2" />
                      <Circle cx="28.45" cy="28.45" r="28.45" transform="translate(554.175 406.428)" fill="#ffe5d2" />
                      <Path
                        d="M582.113,401.388c17.4-.133,28.917,15.421,30.974,28.1-6.972,4.037-34.669,7.34-47.642-6.406-7.192,12.006-4.7,11.672-14.593,11.748C550.713,416.49,564.709,401.522,582.113,401.388Z"
                        fill="#ffe5d2"
                      />
                    </G>
                    <G>
                      <Circle cx="8.345" cy="8.345" r="8.345" transform="translate(599.948 430.67)" fill="#ffe5d2" />
                      <Circle cx="8.345" cy="8.345" r="8.345" transform="translate(548.603 431.06)" fill="#ffe5d2" />
                      <Circle cx="28.45" cy="28.45" r="28.45" transform="translate(554.175 406.428)" fill="#ffe5d2" />
                      <Path
                        d="M582.113,401.388c17.4-.133,28.917,15.421,30.974,28.1-6.972,4.037-34.669,7.34-47.642-6.406-7.192,12.006-4.7,11.672-14.593,11.748C550.713,416.49,564.709,401.522,582.113,401.388Z"
                        fill="#522900"
                      />
                    </G>
                  </G>
                </G>
              </G>
            </G>
            <Path
              d="M607.243,440.4h-3.327a9.446,9.446,0,0,0-17.408-2.953,7.059,7.059,0,0,0-4.1-1.194,7.18,7.18,0,0,0-4.439,1.4,9.449,9.449,0,0,0-17.532,2.744h-3.328a1.58,1.58,0,0,0,0,3.16h3.248a9.448,9.448,0,0,0,18.8-1.317,9.2,9.2,0,0,0-.091-1.25.938.938,0,0,0,.058-.082,3.645,3.645,0,0,1,3.279-1.5,3.323,3.323,0,0,1,2.926,1.244,9.321,9.321,0,0,0-.143,1.587A9.449,9.449,0,0,0,604,443.563h3.247a1.58,1.58,0,1,0,0-3.16Zm-37.536,8.136a6.293,6.293,0,1,1,6.3-6.293A6.3,6.3,0,0,1,569.707,448.539Zm24.937,0a6.293,6.293,0,1,1,6.293-6.293A6.3,6.3,0,0,1,594.644,448.539Z"
              fill="#3e3a39"
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default KarteIcon
