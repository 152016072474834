import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { OnboardingTeamMember, OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from '../../../../../assets/constants'
import noImage from '../../../assets/noImage.svg'

interface Props {
  selectedTeamMember?: OnboardingTeamMember
  imgWidth: number
  imgHeight: number
}

export const Avatar: React.FC<Props> = ({ selectedTeamMember, imgWidth, imgHeight }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div>
        <img
          className="__img"
          src={selectedTeamMember?.imageUrl ? selectedTeamMember.imageUrl : noImage}
          alt="icon"
          width={imgWidth}
          height={imgHeight}
        />
      </div>
      <div className={classes.profile}>
        <div className="__name">{selectedTeamMember?.nickname ?? '名前'}</div>
        <div className="__position">{selectedTeamMember?.position ? selectedTeamMember?.position : '役職/部署'}</div>
        <div className="__role">
          {selectedTeamMember?.role === OnboardingTeamMemberRole.Admin
            ? '運営事務局'
            : selectedTeamMember?.role === OnboardingTeamMemberRole.Member
            ? 'メンバー'
            : selectedTeamMember?.role === OnboardingTeamMemberRole.Supporter
            ? 'サポーター'
            : ''}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    '& .__img': {
      borderRadius: '50%',
      fontSize: 0,
      objectFit: 'cover',
    },
  },
  profile: {
    fontSize: 10,
    marginLeft: 16,
    '& .__name': {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 8,
    },
    '& .__position': {
      marginBottom: 8,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
    },
    '& .__role': {
      display: 'inline-block',
      boxSizing: 'border-box',
      padding: '5px 8px',
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 4,
      color: constants.COLOR_ONBOARDING_MAIN,
      fontWeight: 'bold',
      backgroundColor: constants.COLOR_WHITE,
      letterSpacing: 0,
    },
  },
}))
