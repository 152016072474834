import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { TeamTool } from 'utils/generated'

import { constants } from 'assets'

type Props = {
  name: string
  numerator: number
  denominator: number
  teamTool: TeamTool
  isLoading: boolean
}

type StyleProps = {
  ratio: number
}

const calcRatio = (numerator: number, denominator: number) => {
  return Math.round((numerator / denominator) * 100)
}

export const PieChart: React.FC<Props> = ({ numerator, denominator, isLoading }) => {
  const classes = useStyles({ ratio: isLoading ? 0 : calcRatio(numerator, denominator) })

  return (
    <div className={classes.circle}>
      <div className={classes.graphInfo}>
        {isLoading ? (
          <div className={classes.loader}>loading</div>
        ) : (
          <>
            <div className={classes.ratio}>
              <span>{numerator}</span>/{denominator}
            </div>
            <span>チーム</span>
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    circle: ({ ratio }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 80,
      width: 80,
      height: 80,
      backgroundImage: `radial-gradient(${constants.COLOR_WHITE} 52%, transparent 53%), conic-gradient(${constants.COLOR_MAIN_NEW} 0% ${ratio}%, ${constants.COLOR_TEAMBUILDING_NEUTRAL_200} ${ratio}% 100%)`,
      borderRadius: '50%',
    }),
    graphInfo: {
      fontSize: 10,
      textAlign: 'center',
    },
    ratio: {
      color: '#666',
      fontSize: 12,
      fontWeight: 'bold',

      '& span': {
        paddingRight: 3,
        color: constants.COLOR_MAIN_NEW,
        fontSize: 16,
      },
    },
    loader: {
      position: 'relative',
      paddingRight: 4,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 'calc(100% - 4px)',
        content: '""',
        animation: '$dotsRepeat 1.5s infinite',
        '@global': {
          '@keyframes dotsRepeat': {
            '0%': {
              content: '""',
            },
            '33%': {
              content: '"."',
            },
            '66%': {
              content: '".."',
            },
            '100%': {
              content: '"..."',
            },
          },
        },
      },
    },
  }),
  { name: 'PieChart' }
)
