import * as React from 'react'

import { Font, GhostArrayData } from '../atoms/index'

type Props = {
  sympton: string
  scenes: string[]
  ownStyles?: React.CSSProperties
}

export const GhostFeaturesAndThemes: React.FC<Props> = (props) => {
  return (
    <div style={props.ownStyles}>
      <Font fontSize={14} bold>
        特徴と症状
      </Font>
      <Font fontSize={14} ownStyles={{ marginTop: 8, letterSpacing: 0.1 }}>
        {props.sympton}
      </Font>
      <Font fontSize={14} bold ownStyles={{ marginTop: 16 }}>
        オバケの出没シーン
      </Font>
      <GhostArrayData
        items={props.scenes}
        fieldType="scenes"
        fontSize={14}
        listStyles={{ marginTop: 8, marginBottom: 0 }}
      />
    </div>
  )
}
