import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { CardHeader } from 'pages/teams/components'

interface Props {}

export const OnePoint: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.onePointContainer}>
      <CardHeader title={'Cocolaboからのワンポイントアドバイス'} />
      <p>
        最近発言数が減ってきていて、やりっぱなし傾向があるかもしれません。 一度会議で仕切り直してはいかがでしょうか？
      </p>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  onePointContainer: {
    backgroundColor: '#fff',
    padding: 30,
    flexGrow: 1,
    marginTop: 10,
  },
}))
