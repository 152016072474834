export const FETCH_USER = 'FETCH_USER'
export const SIGNUP_USER = 'SIGNUP_USER'

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const FETCH_USER_DONE = 'FETCH_USER_DONE'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'
export const REFRESH_STATE_USER = 'REFRESH_STATE_USER'
export const SIGNIN_USER = 'SIGNIN_USER'
export const REFRESH_ERRORS_USER = 'REFRESH_ERRORS_USER'
export const SET_EMAIL_TO_PARAM_USER = 'SET_EMAIL_TO_PARAM_USER'
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER'
export const RESTORE_USER = 'RESTORE_USER'
export const CONFIRM_USER = 'CONFIRM_USER'
export const RESEND_CODE_USER = 'RESEND_CODE_USER'
