import React from 'react'

import MuiProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type CircularProgressNoWrapperProps = {
  size?: number
} & StyleProps

type StyleProps = {
  color?: string
  margin?: string | number
}

export const CircularProgressNoWrapper: React.FC<CircularProgressNoWrapperProps> = ({ size, color, margin }) => {
  const classes = useStyles({ color, margin })

  return <MuiProgress className={classes.progress} size={size ?? 20} />
}

const useStyles = makeStyles<Theme, StyleProps>(
  () => ({
    progress: ({ margin, color }) => ({
      margin: margin || '0 auto',
      color: color || constants.COLOR_MAIN_NEW,
    }),
  }),
  { name: 'CircularProgressNoWrapper' }
)
