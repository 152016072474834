import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetSetupAnketFeedback } from './graphql'

const getSetupAnketFeedback = async (weekDate: string | null, setupStartedAt: string) => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetSetupAnketFeedback, {
      weekDate,
      setupStartedAt,
    })
  )
  const { getSetupAnketFeedback } = response.data

  return getSetupAnketFeedback
}
export default getSetupAnketFeedback
