import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { getRoundedValue } from '../../lib'
import { ContentButton } from '../generic/ContentButton'
import { PointData } from '../hooks/useReviewPoints'

import { Step2Chart } from './Step2Chart'

type Props = {
  pointData: PointData
  onOpen: (data: PointData) => void
}
export const Step2ConfirmContent: React.FC<Props> = ({ pointData, onOpen }) => {
  const classes = useStyles()
  const showDetailModal = () => {
    onOpen(pointData)
  }
  return (
    <div className={classes.wrap}>
      <div className={classes.chartWrap}>
        <Step2Chart type="step2Confirm" data={pointData.points} />
      </div>
      <div className={classes.info}>
        <div className={classes.pointWrap}>
          <span>平均点数 </span>
          <span className={classes.point}>{getRoundedValue(pointData.average)}</span>
          <span className={classes.label}>点</span>
        </div>
        <div className={classes.buttonWrap}>
          <ContentButton label={'詳細'} onClick={showDetailModal} inline />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
    },
  },

  chartWrap: {
    display: 'flex',
    flex: 1,
    width: 'calc(100% - 149px)',
    '@media (max-width: 632px)': {
      width: '100%',
    },
  },
  info: {
    minWidth: 117,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    '@media (max-width: 632px)': {
      width: '100%',
      flexDirection: 'row',
    },
  },
  pointWrap: {
    flex: 1,
    textAlign: 'center',
    padding: '11px 16px',
    backgroundColor: '#F9F9F9',
    borderRadius: 8,
  },
  label: {
    color: '#00838C',
  },
  point: {
    color: '#00838C',
    fontWeight: 'bold',
  },
  buttonWrap: {
    width: '100%',
    flex: 1,
  },
}))
