import { OnboardingTeamMemberRole } from 'utils/generated'

import GroupIcon from '../../assets/GroupIcon.svg'
import ListIcon from '../../assets/ListIcon.svg'
import MemberStatusIcon from '../../assets/MemberStatusIcon.svg'
import OverviewIcon from '../../assets/OverviewIcon.svg'
import RankingIcon from '../../assets/RankingIcon.svg'
import RequestIcon from '../../assets/RequestIcon.svg'
import StatusIcon from '../../assets/StatusIcon.svg'
import TimeIcon from '../../assets/TimeIcon.svg'
import UserIcon from '../../assets/UserIcon.svg'
import {  OnbHistory } from '../../navigation/route'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

//handle which menu render as onboardingMemberRole
export type Menu = {
    onClick: () => void
    style:React.CSSProperties| undefined
    imgSrc: string
    labelText: string
}
export type Menus = {
  [key in OnboardingTeamMemberRole]: Menu[]
}

export const useRenderMenuHelpers = (
  history: OnbHistory,
  teamId: string | undefined,
) => {
  //helpers for watch URL -->
  // handle Styles for color  a part of HambergerMenu watching URL
  const isLocated = (page1: string, page2?: string) => {
    if (page2) {
      return (
        window.location.pathname.match(new RegExp(`/onboarding/.+/${page1}(/.+|/?$)`)) &&
        !window.location.pathname.match(new RegExp(`/onboarding/.+/${page1}/${page2}(/.+|/?$)`))
      )
    } else {
      return window.location.pathname.match(new RegExp(`/onboarding/.+/${page1}(/.+|/?$)`))
    }
  }
  
  const styleActiveMenu = 
  (location1: string, location2?: string):React.CSSProperties| undefined => {
    // set notification style if have parameter notification
    if (isLocated(location1, location2)) {
      return { backgroundColor: 'rgba(0, 131, 140, 0.04)', color: constants.COLOR_ONBOARDING_MAIN }
    } else {
      return undefined
    }
  }
  //  <-- helpers for watch URL



  const menus:Menus   =  ({
    [OnboardingTeamMemberRole.Admin]: [
      {
        onClick: () => history.push(OnbPages.Timeline, { teamId }),
        style: styleActiveMenu('timeline'),
        imgSrc: TimeIcon,
        labelText: 'タイムライン',
      },
      {
        onClick: () => history.push(OnbPages.MemberManage, { teamId }),
        style:(styleActiveMenu('member-manage', 'add-member') && styleActiveMenu('member-manage', 'invite-edit')) || styleActiveMenu('member-actions') || styleActiveMenu('member-manage-actions'),
        imgSrc: StatusIcon,
        labelText: 'アクション状況',
      },
      {
        onClick: () => history.push(OnbPages.ActionManage, { teamId }),
        style: styleActiveMenu('action-manage') || styleActiveMenu('action-add') || styleActiveMenu('actions'),
        imgSrc: RequestIcon,
        labelText: 'アクション管理',
      },
      {
        onClick: () => history.push(OnbPages.Team, { teamId }),
        style: styleActiveMenu('team') ||
        styleActiveMenu('teammember') ||
        styleActiveMenu('member-manage/add-member')||
        styleActiveMenu('member-manage/invite-edit'),
        imgSrc: GroupIcon,
        labelText: 'メンバーリスト',
      },
      {
        onClick: () => history.push(OnbPages.Mypage, { teamId }),
        style:styleActiveMenu('mypage') || styleActiveMenu('mypage-edit'),
        imgSrc: UserIcon,
        labelText: 'マイページ',
      },
    ],
    [OnboardingTeamMemberRole.Supporter]: [
      {
        onClick: () => history.push(OnbPages.Timeline, { teamId }),
        style:  styleActiveMenu('timeline'),
        imgSrc: TimeIcon,
        labelText: 'タイムライン',
      },
      {
        onClick: () => history.push(OnbPages.MemberManage, { teamId }),
        style:(styleActiveMenu('member-manage', 'add-member') && styleActiveMenu('member-manage', 'invite-edit')) || styleActiveMenu('member-actions')|| styleActiveMenu('member-manage-actions'),
        imgSrc: StatusIcon,
        labelText: 'アクション状況',
      },
      {
        onClick: () => history.push(OnbPages.ActionManage, { teamId }),
        style: styleActiveMenu('action-manage') || styleActiveMenu('action-add') || styleActiveMenu('actions'),
        imgSrc: RequestIcon,
        labelText: 'アクション管理',
      },
      {
        onClick: () => history.push(OnbPages.Team, { teamId }),
        style:         styleActiveMenu('team') ||
        styleActiveMenu('teammember') ||
        styleActiveMenu('member-manage/add-member') ||
        styleActiveMenu('member-manage/invite-edit'),
        imgSrc: GroupIcon,
        labelText: 'メンバーリスト',
      },
      {
        onClick: () => history.push(OnbPages.Mypage, { teamId }),
        style: styleActiveMenu('mypage') || styleActiveMenu('mypage-edit'),
        imgSrc: UserIcon,
        labelText: 'マイページ',
      },
    ],
    [OnboardingTeamMemberRole.Member]: [
      {
        onClick: () => history.push(OnbPages.Timeline, { teamId }),
        style: styleActiveMenu('timeline'),
        imgSrc: TimeIcon,
        labelText: 'タイムライン',
      },
      {
        onClick: () => history.push(OnbPages.Actions, { teamId }),
        style: styleActiveMenu('actions') || styleActiveMenu('member-actions'),
        imgSrc: ListIcon,
        labelText: 'アクションリスト',
      },
      {
        onClick: () => history.push(OnbPages.Team, { teamId }),
        style:             styleActiveMenu('team') ||
        styleActiveMenu('teammember') ||
        styleActiveMenu('member-manage/add-member')||
        styleActiveMenu('member-manage/invite-edit'),
        imgSrc: GroupIcon,
        labelText: 'メンバーリスト',
      },
      {
        onClick: () => history.push(OnbPages.Mypage, { teamId }),
        style: styleActiveMenu('mypage') || styleActiveMenu('mypage-edit'),
        imgSrc: UserIcon,
        labelText: 'マイページ',
      },
    ],
  })

  const overviews:Menus = {
    [OnboardingTeamMemberRole.Admin]:[{
      onClick: () => history.push(OnbPages.Ranking, { teamId }),
      style: styleActiveMenu('ranking'),
      imgSrc: RankingIcon,
      labelText: 'ランキング',
    },
    {
      onClick: () => history.push(OnbPages.Overview, { teamId }),
      style: styleActiveMenu('overview'),
      imgSrc: OverviewIcon,
      labelText: '全体実施状況',
    },
    {
      onClick: () => history.push(OnbPages.MemberStatus, { teamId }),
      style: styleActiveMenu('memberstatus'),
      imgSrc: MemberStatusIcon,
      labelText: '個別実施状況',
    }],
    [OnboardingTeamMemberRole.Supporter]:[{
      onClick: () => history.push(OnbPages.Ranking, { teamId }),
      style: styleActiveMenu('ranking'),
      imgSrc: RankingIcon,
      labelText: 'ランキング',
    },
    {
      onClick: () => history.push(OnbPages.Overview, { teamId }),
      style: styleActiveMenu('overview'),
      imgSrc: OverviewIcon,
      labelText: '全体実施状況',
    },
    {
      onClick: () => history.push(OnbPages.MemberStatus, { teamId }),
      style: styleActiveMenu('memberstatus'),
      imgSrc: MemberStatusIcon,
      labelText: '個別実施状況',
    }],
    [OnboardingTeamMemberRole.Member]:[{
      onClick: () => history.push(OnbPages.Ranking, { teamId }),
      style: styleActiveMenu('ranking'),
      imgSrc: RankingIcon,
      labelText: 'ランキング',
    },
    {
      onClick: () => history.push(OnbPages.MemberStatus, { teamId }),
      style: styleActiveMenu('memberstatus'),
      imgSrc: MemberStatusIcon,
      labelText: '個別実施状況',
    }]
  }
  

  return {styleActiveMenu,menus,overviews}
}