import * as React from 'react'

import { useReaction } from './hooks/useReaction'
import { ReactionIcon } from './reaction-icon'
import { ReactionSelections } from './reaction-selections'
import { ReactionProps, reactionNumbers } from './reaction-selections/hooks/useReactionSelections'

export const Reaction = React.memo((props: ReactionProps) => {
  const { reactions, reactionCounts, myIcon } = useReaction(props.postId, props.isLiked)

  return (
    <>
      {reactions && reactionCounts
        ? reactionNumbers.map((num) =>
            reactionCounts[num] ? (
              <ReactionIcon
                key={`reaction-count-${num}`}
                reactionNum={num}
                reactionCounts={reactionCounts}
                myIcon={myIcon}
                {...props}
              />
            ) : null
          )
        : null}
      <ReactionSelections {...props} />
    </>
  )
})
