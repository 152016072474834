import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { HooksContext } from 'pages/teams/contexts'
import { AnketStatus, Team, TeamAnketBuildingSummary } from 'utils/generated'

import { Select } from '../../_tools/buildings/components'

import { ModalCsvDownload } from './modules'

import downloadIcon from '../assets/download.svg'
import * as constants from 'assets/constants'

type Props = {
  currentAnket: TeamAnketBuildingSummary | undefined
  currentAnketLabel: string | null | undefined
  dateSelectOption: { value: string; label: string }[]
  setCurrentAnketLabel: React.Dispatch<React.SetStateAction<string | null | undefined>>
  showCSV: boolean | undefined
  team: Team | undefined
}

export const FilterAndCSV: React.FC<Props> = ({
  dateSelectOption,
  currentAnketLabel,
  currentAnket,
  setCurrentAnketLabel,
  team,
  showCSV,
}) => {
  const classes = useStyles()
  const { isAdmin } = React.useContext(HooksContext)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  //Filter
  const onChangeAnketDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentAnketLabel(e.target.value)
  }

  const handleOpen = () => {
    setIsModalOpen(true)
  }

  // CSV
  const canSaveCSV = React.useMemo(() => {
    return currentAnket && currentAnket.status === AnketStatus.Close && team
  }, [currentAnket, team])

  return (
    <div className={classes.filter}>
      <div className="selectWrap">
        <Select
          options={dateSelectOption}
          value={currentAnketLabel ?? undefined}
          style={{
            padding: '0 24px 0 16px',
            minWidth: 177,
            width: '100%',
            height: 28,
            border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
            borderRadius: 14,
            color: constants.COLOR_TEAMBUILDING_TEXT,
            fontSize: 10,
            cursor: 'pointer',
          }}
          imgStyle={{ width: 16 }}
          onChange={onChangeAnketDate}
        />
      </div>
      {showCSV && isAdmin && (
        <>
          <Button
            className={classes.dlButton}
            style={{
              width: 122,
              height: 28,
              color: constants.COLOR_TEAMBUILDING_PRIMARY,
              backgroundColor: constants.COLOR_WHITE,
              padding: '0 16px',
              border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
              boxShadow: 'none',
              borderRadius: 14,
              fontSize: 10,
              fontWeight: 'bold',
            }}
            startIcon={<img src={downloadIcon} alt="download" className={classes.img} />}
            onClick={handleOpen}
            disabled={!canSaveCSV}
          >
            チームデータDL
          </Button>

          <ModalCsvDownload isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    flexWrap: 'nowrap',
    '& .selectWrap': {
      minWidth: 120,
    },
  },
  dlButton: {
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
  },
  img: {
    width: 12,
  },
}))
