import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/styles'

import { NotificationBatch } from 'pages/onboarding/components/notification'
import { OnboardingTeamMember } from 'utils/generated'

import NotificationIcon from '../../../assets/NotificationIcon.svg'
import { useOnbHistory } from '../../../navigation/route'
import { useRenderMenuHelpers } from '../../hooks/useHamburgerBoxStyle'

import { HamburgerItem } from './atoms/HamburgerItem'

import { OnbPages } from 'assets/pages'





type Props = {
  teamId: string | undefined
  teamMember: OnboardingTeamMember | undefined
  countNotification?: number
}
type StyleProps = {
  classes: ClassNameMap
  visibleDetail: boolean
}

export const RoleMenuBox: React.FC<Props & StyleProps> = ({
  teamId,
  teamMember,
  countNotification,
  classes,
  visibleDetail,
}) => {
  const history = useOnbHistory()
  const { menus, styleActiveMenu } = useRenderMenuHelpers(history, teamId)
  const [isHover, setIsHover] = React.useState(false)
  const classesScoped = useStyles()
  const pathname = useLocation().pathname
  const isOverView = pathname.includes('overview')
  const showName = isHover && isOverView

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.menuList} style={{ justifyContent: visibleDetail ? undefined : 'center' }}>
        <ul>
          {teamMember && teamMember?.role ? (
            <>
              {menus[teamMember?.role].map((item) => (
                <div key={item.labelText}>
                  <div>
                    <HamburgerItem key={item.labelText} menu={item} visibleDetail={visibleDetail} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}

          <li
            onClick={() => history.push(OnbPages.Notification, { teamId })}
            style={styleActiveMenu('notification')}
            className={visibleDetail ? classes.notification : classes.notificationSm}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <img src={NotificationIcon} alt={'NotificationIcon'} />
            {visibleDetail ? (
              <>
                <span>お知らせ</span>
                <NotificationBatch count={countNotification ? countNotification : 0} withWrapper />
              </>
            ) : (
              <div
                className={classes.notificationBatch}
                style={{ justifyContent: visibleDetail ? undefined : 'center' }}
              >
                <NotificationBatch count={countNotification ? countNotification : 0} />
              </div>
            )}
          </li>
        </ul>
        {showName && <span className={classesScoped.labelText}>お知らせ</span>}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  labelText: {
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: 6,
    textAlign: 'center',
    position: 'absolute',
    top: 385,
    left: 25,
    pointerEvents: 'none',
  },
}))
