import React from 'react'

import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus, TeamBuildingV1ResultQ4 } from 'utils/generated'

import { QuestionContainer, Button, ButtonContainer, WaitingForLeader, PanelMessage } from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q4End: React.FC<Props> = (props) => {
  const { isLeader, teamBuilding } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q5Actions, // Changed page transition by UI UX modification
      timeQ5Actions: new Date().toISOString(), // Changed according to the above
    })
  }

  const resultQ4 = JSON.parse(teamBuilding?.resultQ4 ?? '') as TeamBuildingV1ResultQ4

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <QuestionContainer active={4} questionHeadBar titleTextBold noPadding isWaiting={!isLeader}>
        <PanelMessage
          resultTitle={'行動規範設定は下記に決定しました！'}
          resultDetails={
            <div style={{ whiteSpace: 'pre-wrap' }}>{resultQ4.items.map((i) => `${'・'} ${i.answer}`).join('\n')}</div>
          }
          nextQuestion={'次は「スケジュール」を決めましょう。'}
        />
        {isLeader ? (
          <ButtonContainer
            fixed
            marginTopPcLayout={150}
            buttons={[
              <Button key="q2-end-1" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        ) : (
          <WaitingForLeader />
        )}
      </QuestionContainer>
    </>
  )
}
