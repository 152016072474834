import * as React from 'react'

import { ClassNameMap } from '@material-ui/styles'

import { OnboardingTeamMember } from 'utils/generated'

import { useOnbHistory } from '../../../navigation/route'
import { useRenderMenuHelpers } from '../../hooks/useHamburgerBoxStyle'

import { HamburgerItem } from './atoms/HamburgerItem'

type Props = {
  teamId: string | undefined
  teamMember: OnboardingTeamMember
}
type StyleProps = {
  classes: ClassNameMap
  visibleDetail: boolean
}

export const OverviewBox: React.FC<Props & StyleProps> = ({ teamId, teamMember, classes, visibleDetail }) => {
  const history = useOnbHistory()
  const { overviews } = useRenderMenuHelpers(history, teamId)

  return (
    <>
      <div className={classes.overviewList} style={{ justifyContent: visibleDetail ? 'center' : undefined }}>
        <ul>
          {overviews[teamMember?.role].map((item) => (
            <HamburgerItem key={item.labelText} menu={item} visibleDetail={visibleDetail} />
          ))}
        </ul>
      </div>
    </>
  )
}
