import React from 'react'

import { Auth } from 'aws-amplify'

import {
  CreateTeamMemberInput,
  CreateTeamMembersInput,
  CreateTeamMembersResponse,
  TeamMember,
  TeamMemberCreationAttributes,
  TeamMemberFilter,
  TeamMemberRole,
  UpdateTeamMemberInput,
} from 'utils/generated'

import { getTeamMemberRole } from '../utils/teamMember'

import {
  mutationCreateTeamMemberList,
  mutationCreateTeamMembers,
  mutationDeleteTeamMembers,
  mutationUpdateTeamMemberList,
  mutationUpdateTeamMembers,
  queryGetTeamMember,
  queryGetTeamMembers,
} from './graphql'

import { Pages } from 'assets'
import { useHistory, replacePathParams } from 'assets/history'
import { PatternEmailLowerCase, PatternEmailLowerCaseMessage } from 'assets/patterns'

export const useTeamMember = (id?: string) => {
  const [teamMember, setTeamMember] = React.useState<TeamMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = async () => {
    if (!id) {
      setLoading(false)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMember({
        id: id,
      })
      if (response) {
        setTeamMember(response)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { teamMember, loading, error, refresh } as const
}

export const useCurrentTeamMember = (teamId?: string) => {
  const [teamMember, setTeamMember] = React.useState<TeamMember | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()
  const [role, setRole] = React.useState<TeamMemberRole | undefined>()
  const refresh = React.useCallback(async () => {
    const cognito = await Auth.currentAuthenticatedUser()

    if (!teamId || !cognito || (teamId && teamId.length < 30)) {
      setLoading(false)
      setTeamMember(undefined)
      setRole(undefined)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMember({
        id: `${teamId}-${cognito.getUsername()}`,
      })
      if (response) {
        setTeamMember(response)
        setRole(getTeamMemberRole(response))
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  return { teamMember, role, loading, loaded, error, refresh } as const
}

export const useTeamMembers = (teamId: string, filter?: TeamMemberFilter) => {
  const limit = 500
  const [teamMembers, setTeamMembers] = React.useState<TeamMember[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isMoreLoading, setIsMoreLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMembers({
        teamId,
        limit,
        filter,
      })
      if (response) {
        setTeamMembers(response.items)
        setNextToken(response.nextToken || undefined)
      }
    } catch (e) {
      setError(e as Error)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh, teamId])

  const more = React.useCallback(async () => {
    setIsMoreLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMembers({ teamId, limit, filter, nextToken })
      if (response) {
        setTeamMembers([...teamMembers, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      setError(e as Error)
    }
    setIsMoreLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembers, nextToken])

  const filterMember = (teamMembers: TeamMember[], role?: TeamMemberRole): TeamMember[] => {
    switch (role) {
      case TeamMemberRole.Admin:
        return teamMembers.filter(
          (m) => m.role.includes(TeamMemberRole.Member) || m.role.includes(TeamMemberRole.Leader)
          // 管理者であってもメンバー一覧には出さないように修正
          // ||
          // m.role.includes(TeamMemberRole.Admin)
        )

      default:
        return teamMembers.filter(
          (m) => m.role.includes(TeamMemberRole.Member) || m.role.includes(TeamMemberRole.Leader)
        )
    }
  }

  const leaderToTop = (teamMembers: TeamMember[]): TeamMember[] => {
    // リーダーを先頭に持ってくる。
    const leaderTopMembers = teamMembers.sort((a, b) => {
      if (a.role.includes(TeamMemberRole.Leader) && !b.role.includes(TeamMemberRole.Leader)) {
        return -1
      }
      if (!a.role.includes(TeamMemberRole.Leader) && b.role.includes(TeamMemberRole.Leader)) {
        return 1
      }
      return 0
    })
    return leaderTopMembers
  }

  return { teamMembers, nextToken, loading, isMoreLoading, error, refresh, more, filterMember, leaderToTop } as const
}

export const useManageTeamMembers = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error[] | undefined>()

  const createTeamMember = React.useCallback(
    async (input: CreateTeamMemberInput): Promise<[TeamMember | undefined, undefined | Error[]]> => {
      setLoading(true)
      setError(undefined)

      try {
        const response = await mutationCreateTeamMembers({
          input,
        })
        return [response, undefined]
      } catch (e) {
        setError(e.errors)
        return [undefined, e.errors]
      } finally {
        setLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  )

  const createTeamMemberList = React.useCallback(
    async (input: CreateTeamMembersInput): Promise<CreateTeamMembersResponse | null | undefined> => {
      setLoading(true)
      setError(undefined)

      try {
        const response = await mutationCreateTeamMemberList({ input })
        return response
      } catch (e: any) {
        setError(e.errors as Error[])
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const updateTeamMember = React.useCallback(async (input: UpdateTeamMemberInput) => {
    setLoading(true)
    setError(undefined)

    try {
      const response = await mutationUpdateTeamMembers({
        input,
      })
      if (response) {
        return response
      }
    } catch (e) {
      setError(e.errors)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTeamMemberList = React.useCallback(async (teamId: string, members: UpdateTeamMemberInput[]) => {
    setLoading(true)
    setError(undefined)

    try {
      const response = await mutationUpdateTeamMemberList({
        input: { teamId, members },
      })
      if (response) {
        return response
      }
    } catch (e: any) {
      setError(e.errors as Error[])
    }
    setLoading(false)
  }, [])

  const deleteTeamMember = React.useCallback(async (id: string) => {
    setLoading(true)
    setError(undefined)

    try {
      const response = await mutationDeleteTeamMembers({
        id,
      })
      if (response) {
        return response
      }
    } catch (e) {
      setError(e.errors)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    createTeamMember,
    createTeamMemberList,
    updateTeamMember,
    updateTeamMemberList,
    deleteTeamMember,
    loading,
    error,
  } as const
}

export const useTeamMembersCsvUpload = (teamId: string) => {
  const { route } = useHistory()

  const [csvData, setCsvData] = React.useState<string[][]>([])
  const [complete, setComplete] = React.useState(false)
  const [uploaded, setUploaded] = React.useState(0)
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [uploadFile, setUploadFile] = React.useState<File>()
  const [uploadErrors, setUploadErrors] = React.useState<string[] | null>(null)

  const disabled = !(csvData.length > 0)

  const { createTeamMemberList } = useManageTeamMembers()

  const handleBack = () => {
    setUploadFile(undefined)
    setComplete(false)
    setUploaded(0)
    setUploadErrors(null)
  }

  const handleToMembers = () => {
    route.push(replacePathParams(Pages.TeamsMembers, { teamId }))
  }

  const onSubmit = React.useCallback(
    async (sendEmailAt: string) => {
      const errors: string[] = []
      let teamMembers: TeamMemberCreationAttributes[] = []
      let uploadNum = 0

      const createMembers = async () => {
        const res = await createTeamMemberList({
          teamId,
          members: teamMembers,
          invitationDateTime: sendEmailAt || undefined,
        })

        if (res?.items?.length) {
          uploadNum = res.items.length
        }
        if (res?.errors?.length) {
          for (const err of res.errors) {
            errors.push(`${err.email}　${err.errorMessage}`)
          }
        }

        teamMembers = []
      }

      if (teamId) {
        setProcessing(true)

        for (const [index, d] of csvData.entries()) {
          if (index === 0) continue

          // d[0]: fullName
          // d[1]: username
          // d[2]: email
          // d[3]: organization
          // d[4]: position
          // d[5]: リーダー
          if (d[0] && d[1] && d[2]) {
            if (d[2].match(new RegExp(PatternEmailLowerCase))) {
              teamMembers.push({
                fullName: d[0],
                username: d[1],
                email: d[2],
                organization: d[3],
                position: d[4],
                role: d[5] === 'はい' ? [TeamMemberRole.Leader, TeamMemberRole.Member] : [TeamMemberRole.Member],
              })
            } else {
              errors.push(`${index + 1}行目: ${PatternEmailLowerCaseMessage}`)
            }
          } else {
            // データ不足行はエラーとするが、空行は対象外とする。
            if (!(d.length === 1 && d[0] === '')) {
              errors.push(
                `${index + 1}行目: ユーザー情報に不足があります（氏名・ユーザー名・メールアドレスは必須です）`
              )
            }
          }
        }

        await createMembers()

        setProcessing(false)

        if (uploadNum > 0) {
          setUploaded(uploadNum)
          setComplete(true)
        }

        if (errors.length > 0) {
          setUploadErrors(errors)
          setComplete(true)
        }
      }
    },
    [createTeamMemberList, csvData, teamId]
  )

  return {
    complete,
    processing,
    uploaded,
    uploadFile,
    setUploadFile,
    setCsvData,
    uploadErrors,
    disabled,
    handleBack,
    handleToMembers,
    onSubmit,
  }
}
