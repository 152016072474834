import React from 'react'
import { useParams } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { NoStyleButton } from 'components/NoStyleButton'
import { useTeamBuildingMembers } from 'pages/teams/hooks'
import closeSvg from 'pages/teams/pages/dashboard/assets/close.svg'
import SearchSvg from 'pages/teams/pages/dashboard/assets/searchBlackIcon.svg'

import { IdealTeamsList } from './IdealTeamsList'

import { constants } from 'assets'

type Props = {
  isOpen: boolean
  handleCloseDialog: () => void
}

const CIRCULAR_SIZE = 20

export const IdealTeamDialog: React.FC<Props> = ({ isOpen, handleCloseDialog }) => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { teamBuildingMembers, loading: isLoading } = useTeamBuildingMembers(teamId)
  const [searchText, setSearchText] = React.useState('')

  // 全角入力中は絞り込みを無効にするためのコード
  const [isComposing, setIsComposing] = React.useState(false)
  const handleCompositionStart = () => setIsComposing(true)
  const handleCompositionEnd = () => setIsComposing(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.target.value)
  }
  // -----------------------------

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      BackdropProps={{
        style: {
          backgroundColor: constants.TEXT_GRAY_DARK,
          opacity: 0.7,
        },
      }}
      className={classes.root}
    >
      <DialogTitle disableTypography className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>個人で選んだ理想のチーム状態</div>
        <NoStyleButton type="button" onClick={handleCloseDialog} className={classes.dialogCloseButton}>
          <img src={closeSvg} alt="ダイアログを閉じる" />
        </NoStyleButton>
      </DialogTitle>

      <div className={`${classes.inputWrapper} ${classes.contentPadding}`}>
        <InputBase
          autoFocus
          fullWidth
          onChange={(e) => handleChange(e)}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          placeholder="名前で検索"
          startAdornment={<img src={SearchSvg} alt="" className={classes.searchIcon} />}
          className={classes.input}
        />
      </div>

      <DialogContent className={classes.contentPadding}>
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress size={CIRCULAR_SIZE} />
          </div>
        ) : (
          <IdealTeamsList filterText={searchText} isComposing={isComposing} teamBuildingMembers={teamBuildingMembers} />
        )}
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& .MuiDialog-paper': {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        paddingTop: 16,
        paddingBottom: 28,
        borderRadius: 8,
      },
    },

    // DialogTitle Component
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
      padding: '0 16px',

      [theme.breakpoints.down('xs')]: {
        marginBottom: 8,
      },
    },
    dialogTitle: {
      flexGrow: 1,
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 'bold',
    },
    dialogCloseButton: {
      width: 40,
      height: 40,

      '& img': {
        width: 12,
        height: 12,
      },
    },

    // 絞り込みフォーム
    inputWrapper: {
      marginBottom: 24,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 16,
      },
    },
    input: {
      borderRadius: 50,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      padding: '12px 24px',

      '& .MuiInputBase-input': {
        padding: 0,
      },

      '& .MuiInputBase-adornedStart': {
        marginRight: 4,
      },
    },
    searchIcon: {
      marginRight: 5,
      width: 16,
      height: 16,
    },

    circularProgressWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // util class
    contentPadding: {
      padding: '0 28px',

      [theme.breakpoints.down('xs')]: {
        padding: '0 14px',
      },
    },
  }),
  { name: 'IdealTeamDialog' }
)
