import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { LikeMembers } from 'pages/onboarding/components/like-members'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingPostCommentLikes } from 'pages/onboarding/hooks/post'

import { OnbContext } from '../PagesRoot'

export const TimelineCommentLikesBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { postId } = useParams<{ postId: string }>()
    const { postCommentLikes } = useOnboardingPostCommentLikes(postId)

    return {
      teamId,
      postId,
      postCommentLikes,
    }
  },
}

const Index = () => {
  const { teamId, postId, postCommentLikes } = TimelineCommentLikesBloc.useAdapter()

  return (
    <>
      <TitleWithBack title="コメントにいいねした人" />

      <LikeMembers teamId={teamId} postId={postId} likes={postCommentLikes} />
    </>
  )
}

export default Index
