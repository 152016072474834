import { useDispatch } from 'react-redux'

import actions from '../stores/actions'
import { getConsultationList, getConsultation, getConsultationCommentList } from '../stores/apis'
import { IConsultationListParams } from '../stores/apis/getConsultationList'
import { getConsultationListSearch, IConsultationListSearchParams } from '../stores/apis/getConsultationListSearch'

import { useNextToken } from './useNextToken'

// -----------------------------
// interface
// -----------------------------
export type IProps = {
  getConsultationEpic: (consultationId: string) => Promise<void>
  getConsultationListEpic: (params: IConsultationListParams) => Promise<void>
  getConsultationListSearchListEpic: (params: IConsultationListSearchParams) => Promise<void>
}

// -----------------------------
// hooks
// -----------------------------
export const useGetConsultationEpic = (): IProps => {
  const nextToken = useNextToken()
  const dispatch = useDispatch()

  /**
   * ConsultationIDをもとにお悩み掲示板の個別記事とユーザー情報を取得する
   */
  const getConsultationEpic = async (consultationId: string): Promise<void> => {
    await getConsultation({ id: consultationId })
      .then((res) => {
        dispatch(actions.getConsultation.done({ params: { id: consultationId }, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getConsultation.failed({ params: { id: consultationId }, error: e }))
      })

    await getConsultationCommentList({ id: consultationId })
      .then((res) => {
        dispatch(actions.getConsultationCommentList.done({ params: { id: consultationId }, result: res }))
      })
      .catch((e) => {
        dispatch(actions.getConsultationCommentList.failed({ params: { id: consultationId }, error: e }))
      })
  }

  /**
   * お悩み掲示板/Consultationの一覧を取得する
   */
  const getConsultationListEpic = async (params: IConsultationListParams): Promise<void> => {
    await getConsultationList(params)
      .then((res) => {
        dispatch(actions.getConsultationList.done({ params: params, result: res }))
        nextToken.onNextToken(res.nextToken)
      })
      .catch((e) => {
        dispatch(actions.getConsultationList.failed({ params: params, error: e }))
      })
  }

  /**
   * サーチカテゴリーでHintを取得する
   */
  const getConsultationListSearchListEpic = async (params: IConsultationListSearchParams): Promise<void> => {
    await getConsultationListSearch(params)
      .then((res) => {
        dispatch(actions.getConsultationListSearch.done({ params: params, result: res }))
        nextToken.onNextToken(res.nextToken)
      })
      .catch((e) => {
        dispatch(actions.getConsultationListSearch.failed({ params: params, error: e }))
      })
  }

  return {
    getConsultationEpic,
    getConsultationListEpic,
    getConsultationListSearchListEpic,
  }
}
