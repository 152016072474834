import React from 'react'

import { CircularProgress } from '@material-ui/core'

export const Loader = ({ timeout, onTimeout }: { timeout?: number; onTimeout?: () => void }) => {
  React.useEffect(() => {
    if (!timeout || !onTimeout) return
    const timer = setTimeout(() => onTimeout(), timeout)
    return () => clearTimeout(timer) // cleanup when unmount
  }, [timeout, onTimeout])

  return (
    <div style={{ width: '100%', paddingTop: '20vh', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}
