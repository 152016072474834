import React from 'react'

interface Props {
  onErrorModal: (errors: string[]) => void
  onCloseErrorModal: () => void
}

export const useModalErrorContext = () => {
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [errorModalErrors, setErrorModalErrors] = React.useState<string[]>([])
  const onErrorModal = (errors: string[]) => {
    if (errors.length > 0) {
      setErrorModalErrors(errors)
      setOpenErrorModal(true)
    }
  }
  const onCloseErrorModal = () => {
    setOpenErrorModal(false)
    setErrorModalErrors([])
  }

  return {
    openErrorModal,
    setOpenErrorModal,
    errorModalErrors,
    onErrorModal,
    onCloseErrorModal,
  }
}

export const ModalErrorContext = React.createContext<Props>({} as Props)
