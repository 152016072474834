import React from 'react'

import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { range } from 'lodash-es'

import { TeamBuildingActionDate } from 'utils/generated'

import { constants } from 'assets'

dayjs.extend(isoWeek)

const DAYS_OF_THE_WEEK = ['月', '火', '水', '木', '金', '土', '日']

type Props = {
  selectedDate: dayjs.Dayjs | null
  teamBuildingActionDates?: TeamBuildingActionDate[]
  onChangeYearMonth?(selectedDate: dayjs.Dayjs): void
  onSelectDate?(selectedDate: dayjs.Dayjs): void
  isAvailableDate?(date: dayjs.Dayjs): boolean
}

export const Calendar: React.FC<Props> = ({
  selectedDate,
  teamBuildingActionDates = [],
  onChangeYearMonth = () => undefined,
  onSelectDate = () => undefined,
  isAvailableDate = () => true,
}) => {
  const classes = useStyles()

  const [yearMonth, setYearMonth] = React.useState<dayjs.Dayjs>(
    selectedDate ? selectedDate.startOf('month') : dayjs().startOf('month')
  )
  const offsetCount = React.useMemo(() => yearMonth.isoWeekday() - 1, [yearMonth])
  const daysInMonth = React.useMemo(() => yearMonth.daysInMonth(), [yearMonth])
  const headerLabel = React.useMemo(() => `${yearMonth.year()}年${yearMonth.month() + 1}月`, [yearMonth])

  const actionDates = React.useMemo(() => {
    return teamBuildingActionDates.map((item) => item.date)
  }, [teamBuildingActionDates])

  const dates = React.useMemo(() => {
    return range(daysInMonth).map((index) => {
      const date = yearMonth.set('date', index + 1)
      return {
        date,
        active: selectedDate ? date.isSame(selectedDate, 'day') : false,
        available: isAvailableDate(date),
        hasEvent: actionDates.includes(date.format('YYYY-MM-DD')),
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuildingActionDates, selectedDate, yearMonth, daysInMonth])

  const onPrevMonth = React.useCallback(() => {
    setYearMonth((current) => {
      const prevMonth = current.subtract(1, 'month')
      onChangeYearMonth(prevMonth)
      return prevMonth
    })
  }, [setYearMonth, onChangeYearMonth])
  const onNextMonth = React.useCallback(() => {
    setYearMonth((current) => {
      const nextMonth = current.add(1, 'month')
      onChangeYearMonth(nextMonth)
      return nextMonth
    })
  }, [setYearMonth, onChangeYearMonth])

  const handleSelectDate = React.useCallback(
    (date: dayjs.Dayjs) => {
      onSelectDate(date)
    },
    [onSelectDate]
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerArrow} onClick={() => onPrevMonth()}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_calendar_arrow_left.svg'} alt="" />
        </div>
        <div className={classes.headerLabel}>{headerLabel}</div>
        <div className={classes.headerArrow} onClick={() => onNextMonth()}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_calendar_arrow_right.svg'} alt="" />
        </div>
      </div>
      <div className={classes.head}>
        {DAYS_OF_THE_WEEK.map((count) => (
          <div key={count} className={`${classes.cell} ${classes.cellHead}`}>
            {count}
          </div>
        ))}
      </div>
      <div className={classes.body}>
        {range(offsetCount).map((count) => (
          <div key={count} className={classes.cell}></div>
        ))}
        {dates.map(({ date, active, hasEvent, available }) => (
          <div
            key={date.unix()}
            onClick={() => {
              if (!available) {
                return
              }
              handleSelectDate(date)
            }}
            className={`${classes.cell} ${!available ? classes.cellDisabled : classes.cellAvailable}`}
          >
            <span className={`${classes.cellDate} ${active ? classes.cellDateActive : ''}`}>
              <span>{date.get('date')}</span>
            </span>
            {hasEvent && <span className={classes.cellEvent}></span>}
          </div>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  header: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0',
  }),
  headerArrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  head: {
    display: 'flex',
  },
  body: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '4px',
  }),
  cell: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(100%/7)`,
    padding: 2,
    fontSize: 14,
    overflow: 'hidden',
  }),
  cellAvailable: {
    cursor: 'pointer',
  },
  cellDisabled: {
    opacity: '.25',
    cursor: 'default',
  },
  cellDate: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 24,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    lineHeight: 36,
    borderRadius: '50%',
  }),
  cellDateActive: () => ({
    color: '#fff',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      inset: '-8px',
      margin: 'auto',
      width: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
    '& > span': {
      position: 'relative',
    },
  }),
  cellEvent: {
    position: 'absolute',
    bottom: '8px',
    left: '0',
    right: '0',
    margin: 'auto',
    width: '6px',
    height: '6px',
    borderRadius: '3px',
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
  },
  cellHead: {
    color: '#A2A2A2',
    fontWeight: 'normal',
    fontSize: '12px',
  },
})
