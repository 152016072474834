import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'

import actions from '../stores/actions'
import { createConsultationComment, ICreateConsultationCommentParams } from '../stores/apis/createConsultationComment'
import { createHintComment, ICreateHintCommentParams } from '../stores/apis/createHintComment'

import * as registers from 'assets/registers'


// -----------------------------
// interface
// -----------------------------
export type IProps = {
  createCommentEpic: (params: ICreateHintCommentParams | ICreateConsultationCommentParams) => Promise<void>
}

// -----------------------------
// hooks
// -----------------------------
export const useCommentEpic = (): IProps => {
  const dispatch = useDispatch()
  const hintSelector = useSelector((state: RootStateType) => state.hint)

  /**
   * コメント | 回答作成時の処理
   */
  const createCommentEpic = async (params: ICreateHintCommentParams | ICreateConsultationCommentParams) => {
    if (params.type === 'HINT') {
      await createHintCommentEpic(params as ICreateHintCommentParams)
    } else {
      await createConsultationCommentEpic(params as ICreateConsultationCommentParams)
    }
  }

  /**
   * Tipsのコメント作成時の処理
   */
  const createHintCommentEpic = async (params: ICreateHintCommentParams): Promise<void> => {
    await createHintComment(params as ICreateHintCommentParams)
      .then((res) => {
        dispatch(actions.createHintComment.done({ params: params as ICreateHintCommentParams, result: res }))
      })
      .catch(() => {
        dispatch(
          actions.createHintComment.failed({
            params: params as ICreateHintCommentParams,
            error: registers.COMMENT_ACTIONS.CREATE.MESSAGE,
          })
        )
      })
      .finally(() => {
        window.location.href = `/hint/tips/${hintSelector.hint.id}`
      })
  }

  /**
   * Consultationのコメント作成時の処理
   */
  const createConsultationCommentEpic = async (params: ICreateConsultationCommentParams): Promise<void> => {
    await createConsultationComment(params as ICreateConsultationCommentParams)
      .then((res) => {
        dispatch(
          actions.createConsultationComment.done({ params: params as ICreateConsultationCommentParams, result: res })
        )
      })
      .catch(() => {
        dispatch(
          actions.createConsultationComment.failed({
            params: params as ICreateConsultationCommentParams,
            error: registers.COMMENT_ACTIONS.CREATE.MESSAGE,
          })
        )
      })
      .finally(() => {
        window.location.href = `/hint/faq/${hintSelector.consultation.id}`
      })
  }

  return {
    createCommentEpic,
  }
}
