import { connect, ConnectedProps } from 'react-redux'

import { RootStateType } from '../../../store'

const mapDispatchToProps = (dispatch: any) => {
  return {
    //
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
    setup: { ...state.setup },
    setupaction: { ...state.setupaction },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
