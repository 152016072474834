import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'

import * as constants from '../assets/constants'

type Props = {
  bgColor?: string
  borderColor?: string
  textColor?: string
  fullWidth?: boolean
  height?: number
  onClick?: any
  submit?: boolean
  body: any
  disabled?: boolean
  square?: boolean
  noShadow?: boolean
  style?: React.CSSProperties
  loading?: boolean
  fontSize?: number
  hoverBgColor?: string
  hoverTextColor?: string
  hoverBorderColor?: string
}

const Index: React.FC<Props> = (props) => {
  const classes = useStyles({
    hoverBgColor: props.hoverBgColor,
    hoverTextColor: props.hoverTextColor,
    hoverBorderColor: props.hoverBorderColor,
  })

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={!props.loading ? props.onClick : undefined}
      type={props.submit ? 'submit' : 'button'}
      style={{
        backgroundColor: props.disabled ? constants.COLOR_GRAY_LIGHT : props.bgColor ?? constants.COLOR_BLACK,
        border: props.borderColor ? `1px solid ${props.borderColor}` : '1px solid transparent',
        color: props.textColor || constants.COLOR_WHITE,
        width: props.fullWidth ? '100%' : '',
        height: props.height ? props.height + 'px' : '',
        borderRadius: props.square ? '3px' : '9999px',
        boxShadow: props.noShadow ? 'none' : undefined,
        fontSize: props.fontSize ? props.fontSize + 'px' : undefined,
        ...props.style,
        opacity: !props.loading ? 1 : 0.3,
      }}
      disabled={props.disabled}
    >
      {props.body}
    </Button>
  )
}

type StyleProps = {
  hoverBgColor?: string
  hoverTextColor?: string
  hoverBorderColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    button: ({ hoverBgColor, hoverTextColor, hoverBorderColor }) => ({
      minWidth: '100px',
      padding: '8px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: hoverBgColor ? `${hoverBgColor} !important` : undefined,
        color: hoverTextColor ? `${hoverTextColor} !important` : undefined,
        border: hoverBorderColor ? `2px solid ${hoverBorderColor} !important` : undefined,
      },
    }),
  }),
  { name: 'Index' }
)

export default Index
