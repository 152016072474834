export const queryGetGhostHistory = /* GraphQL */ `
  query GetGhostHistory($id: ID!, $limit: Int, $nextToken: String) {
    getGhostHistory(id: $id, limit: $limit, nextToken: $nextToken) {
      history {
        id
        masterTeamId
        ghostId
        createdAt
      }
      research {
        first {
          id
          historyId
          like
          type
          report
          userId
          createdAt
        }
        second {
          id
          historyId
          like
          type
          report
          userId
          createdAt
        }
        feeling {
          id
          historyId
          like
          type
          report
          userId
          createdAt
        }
        nextAction {
          id
          historyId
          like
          type
          report
          userId
          createdAt
        }
      }
    }
  }
`

export const queryGetGhostHistoryList = /* GraphQL */ `
  query GetGhostHistoryList($masterTeamId: ID!, $ghostId: String, $limit: Int, $nextToken: String) {
    getGhostHistoryList(masterTeamId: $masterTeamId, ghostId: $ghostId, limit: $limit, nextToken: $nextToken) {
      history {
        id
        masterTeamId
        ghostId
        createdAt
      }
      research {
        first {
          id
          historyId
          userId
          like
          type
          report
          userId
          createdAt
        }
        second {
          id
          historyId
          userId
          like
          type
          report
          userId
          createdAt
        }
        feeling {
          id
          historyId
          userId
          like
          type
          report
          userId
          createdAt
        }
        nextAction {
          id
          historyId
          userId
          like
          type
          report
          userId
          createdAt
        }
      }
    }
  }
`

export const queryGetGhostHistoriesByMaster = /* GraphQL */ `
  query GetGhostHistories($masterTeamId: ID!, $nextToken: String) {
    getGhostHistoriesByMaster(masterTeamId: $masterTeamId, nextToken: $nextToken) {
      items {
        createdAt
        ghostId
        id
        masterTeamId
        selectedGhostData {
          ghostIds
          teamMemberId
        }
      }
      nextToken
    }
  }
`

export const mutationUpdateGhostHistory = /* GraphQL */ `
  mutation UpdateGhostHistory(input: UpdateGhostHistory) {
    updateGhostHistory(input: $input) {
      id
      masterTeamId
      ghostId
      createdAt
    }
  }
`
