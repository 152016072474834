import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { UserActions } from './action'
import { IUser } from './model'

export const initialState: IUser = {
  restored: false,
  reset: false,
  confirmed: false,
  id: undefined,
  email: undefined,
  username: undefined,
  setupStartedAt: undefined,

  basePeriod: 0,
  password: undefined,
}

export const UserReducer = reducerWithInitialState<IUser>(initialState)
  // fetch
  .case(UserActions.fetch.started, (state, payload) => {
    return { ...state, fetched: false, error: null }
  })
  .case(UserActions.fetch.done, (state, payload) => {
    return { ...state, ...payload.result, fetched: true, error: null }
  })
  .case(UserActions.fetch.failed, (state, error) => {
    return { ...state, fetched: true, ...error }
  })

  // refresState
  .case(UserActions.refresState, (state, payload) => {
    return { ...state, ...initialState, error: null }
  })

  // signin
  .case(UserActions.signin.started, (state, payload) => {
    return { ...state, ...payload, error: null }
  })
  .case(UserActions.signin.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(UserActions.signin.failed, (state, error) => {
    return { ...state, ...error }
  })

  // signup
  .case(UserActions.signup.started, (state, payload) => {
    return { ...state, ...payload.user, confirmed: false, error: null }
  })
  .case(UserActions.signup.done, (state, payload) => {
    return { ...state, ...payload.result, error: null, hasSignupProcessed: true }
  })
  .case(UserActions.signup.failed, (state, error) => {
    return { ...state, ...error, hasSignupProcessed: true }
  })

  // confirmSignUp
  .case(UserActions.confirmSignUp.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(UserActions.confirmSignUp.done, (state, payload) => {
    return { ...state, confirmed: true, error: null }
  })
  .case(UserActions.confirmSignUp.failed, (state, error) => {
    return { ...state, ...error }
  })

  // resendSignUp
  .case(UserActions.resendSignUp.started, (state, payload) => {
    return { ...state, resend: false, error: null }
  })
  .case(UserActions.resendSignUp.done, (state, payload) => {
    return { ...state, resend: true, error: null }
  })
  .case(UserActions.resendSignUp.failed, (state, error) => {
    return { ...state, ...error }
  })

  // restore
  .case(UserActions.restore.started, (state, payload) => {
    return { ...state, ...payload, reset: false, restored: false, error: null }
  })
  .case(UserActions.restore.done, (state, payload) => {
    return { ...state, ...payload.result, restored: true, error: null }
  })
  .case(UserActions.restore.failed, (state, error) => {
    return { ...state, ...error }
  })

  // resetPassword
  .case(UserActions.resetPassword.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(UserActions.resetPassword.done, (state, payload) => {
    return { ...state, reset: true, error: null }
  })
  .case(UserActions.resetPassword.failed, (state, error) => {
    return { ...state, ...error }
  })

  // refreshErrors
  .case(UserActions.refreshErrors, (state, payload) => {
    return { ...state, error: null }
  })

  // setEmailToParam
  .case(UserActions.setEmailToParam, (state, payload) => {
    return { ...state, params: { ...payload }, error: null }
  })

  // signout
  .case(UserActions.signout.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(UserActions.signout.done, (state, payload) => {
    return { ...state, ...initialState, signout: true, error: null }
  })
  .case(UserActions.signout.failed, (state, error) => {
    return { ...state, ...error }
  })

  // setSetupStartedAt
  .case(UserActions.updateAccount.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(UserActions.updateAccount.done, (state, payload) => {
    return { ...state, ...payload.result, error: null }
  })
  .case(UserActions.updateAccount.failed, (state, error) => {
    return { ...state, ...error }
  })
