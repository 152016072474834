import { connect, ConnectedProps } from 'react-redux'

import { RootStateType } from '../../../store'

const mapDispatchToProps = (dispatch: any) => ({})

const mapStateToProps = (state: RootStateType) => {
  return {
    user: { ...state.user },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
