import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

type Props = {
  children: React.ReactNode
} & StyleProps &
  React.ComponentProps<'div'>

type StyleProps = {
  containerWidth?: number
}

export const WidthControlWrapper: React.FC<Props> = ({ children, className, containerWidth }) => {
  const classes = useStyles({ containerWidth })

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    },
    container: ({ containerWidth }) => ({
      width: '100%',
      maxWidth: containerWidth ?? 1000,
    }),
  }),
  { name: 'WidthControlWrapper' }
)
