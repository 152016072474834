import { useCallback, Dispatch, SetStateAction, useState, useEffect } from 'react'

import { v4 as uuid } from 'uuid'

import { mutationCreateOnboardingAction } from 'pages/onboarding/graphql'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { roleNames } from 'pages/onboarding/utils/authLabel'
import { categoryNames } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { OnboardingTeamMember, OnboardingTeamMemberRole, OnboardingActionCategory, Maybe } from 'utils/generated'

import { maxLengths } from '../pages/actions-manage-form'
import { maxLengthEmail } from '../pages/member/add'

import { PatternEmailLowerCase, PatternEmailLowerCaseMessage, PatternDateBySlashes } from 'assets/patterns'

const errFormat = (index: number, text: string) => `${index + 1}行目: ${text}`

const categories = Object.values(categoryNames)
const roles = Object.values(roleNames).filter((roleName) => roleName !== roleNames[OnboardingTeamMemberRole.Admin])

const getCategoryByValue = (value: string) => {
  return Object.keys(categoryNames).find((key) => categoryNames[key] === value) ?? OnboardingActionCategory.Category1
}
const getRoleByValue = (value: string) => {
  return Object.keys(roleNames).find((key) => roleNames[key] === value) ?? OnboardingTeamMemberRole.Member
}

export const useCsvUpload = (
  teamId: string,
  teamMember: OnboardingTeamMember,
  csvData: string[][],
  setUploadFile: Dispatch<SetStateAction<File | undefined>>,
  setCsvData: Dispatch<SetStateAction<string[][]>>,
  setOpen: Dispatch<SetStateAction<boolean>>,
  teamMembers?: OnboardingTeamMember[],
) => {
  const [processing, setProcessing] = useState<boolean>(false)
  const [uploadErrors, setUploadErrors] = useState<string[]>([])
  const [uploadedNum, setUploadedNum] = useState(0)
  const [memberEmails, setMemberEmails] = useState<(Maybe<string> | undefined)[]>()
  const { createTeamMember } = useTeamManage()

  useEffect(() => {
    if (teamMembers) {
      setMemberEmails(teamMembers.map((member) => member.email))
    }
  }, [teamMembers])

  const onUploadActions = useCallback(async () => {
    setProcessing(true)
    setUploadErrors([])
    setUploadedNum(0)

    if (!teamId || !teamMember) {
      alert('しばらく経ってから再度お試しください。')
      setProcessing(false)
      return
    }
    if (teamMember.role === OnboardingTeamMemberRole.Member) {
      alert('こちらの機能は、下記の種別の方のみ実行できます。\n運営事務局\nサポーター')
      setProcessing(false)
      return
    }
    if (!csvData.length) {
      alert('CSVファイルを選択してから、再度実行してください。')
      setProcessing(false)
      return
    }

    const errs: string[] = []
    let upNum = 0

    for (const [index, d] of csvData.entries()) {
      /**
       * "index === 0" is for header.
       * "d[0].startsWith('（例）')" is for sample.
       * "d[0] === ''" is for end of file.
       */
      if (index === 0 || d[0].startsWith('（例）') || d[0] === '') {
        continue
      }
      if (d.length < 7) {
        errs.push(errFormat(index, `入力項目に不足があります。`))
        continue
      }

      /**
       * d[0]: タイトル / mission
       * d[1]: 目的 / why
       * d[2]: アクション / what
       * d[3]: 達成基準 / how
       * d[4]: カテゴリ / category
       * d[5]: メンバー参加から何日後に追加しますか？ / period
       * d[6]: アクション追加から何日後を期限としますか？ / deadline
       */
      if (d[0].length > maxLengths.mission) {
        errs.push(errFormat(index, `タイトルは${maxLengths.mission}文字以内で入力してください。`))
        continue
      }
      if (d[1].length > maxLengths.why) {
        errs.push(errFormat(index, `目的は${maxLengths.why}文字以内で入力してください。`))
        continue
      }
      if (d[2].length > maxLengths.what) {
        errs.push(errFormat(index, `アクションは${maxLengths.what}文字以内で入力してください。`))
        continue
      }
      if (d[3].length > maxLengths.how) {
        errs.push(errFormat(index, `達成基準は${maxLengths.how}文字以内で入力してください。`))
        continue
      }
      if (!categories.includes(d[4])) {
        errs.push(errFormat(index, `カテゴリは次のいずれかを入力してください。\n　${categories.join('\n　')}`))
        continue
      }
      if (!Number.isInteger(Number(d[5])) || Number(d[5]) < 0) {
        errs.push(errFormat(index, `追加日は0以上の整数を入力してください。`))
        continue
      }
      if (!Number.isInteger(Number(d[6])) || Number(d[6]) < 0) {
        errs.push(errFormat(index, `期限は0以上の整数を入力してください。`))
        continue
      }

      if (d[0] && d[1] && d[2] && d[3] && d[4] && d[5] && d[6]) {
        const res = await mutationCreateOnboardingAction({
          input: {
            mission: d[0],
            why: d[1],
            what: d[2],
            how: d[3],
            category: getCategoryByValue(d[4]) as OnboardingActionCategory,
            period: Number(d[5]),
            deadline: Number(d[6]),
            id: uuid(),
            importance: 0,
            isPublished: true,
            point: 3,
            teamId,
            publishAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        })

        if (res) {
          upNum = ++upNum
        } else {
          errs.push(errFormat(index, `登録に失敗しました。入力項目に間違いがないかご確認ください。`))
        }
      } else {
        errs.push(errFormat(index, `入力項目に不足があります。`))
      }
    }

    if (upNum > 0) {
      setUploadedNum(upNum)
    }
    if (errs.length) {
      setUploadErrors(errs)
    }

    setUploadFile(undefined)
    setCsvData([])
    setOpen(true)
    setProcessing(false)
  }, [csvData, teamId, teamMember, setUploadFile, setCsvData, setOpen])

  const onUploadMembers = useCallback(async (reserveWillSendEmailAt? : string) => {
    setProcessing(true)
    setUploadErrors([])
    setUploadedNum(0)

    if (!teamId || !teamMember) {
      alert('しばらく経ってから再度お試しください。')
      setProcessing(false)
      return
    }
    if (teamMember.role !== OnboardingTeamMemberRole.Admin) {
      alert('こちらの機能は、下記の種別の方のみ実行できます。\n運営事務局')
      setProcessing(false)
      return
    }
    if (!csvData.length) {
      alert('CSVファイルを選択してから、再度実行してください。')
      setProcessing(false)
      return
    }

    const errs: string[] = []
    const checkedEmails: string[] = []
    let upNum = 0
    let startedAt = ''

    for (const [index, d] of csvData.entries()) {
      /**
       * "index === 0" is for header.
       * "d[0].startsWith('（例）')" is for sample.
       * "d[0] === ''" is for end of file.
       */
      if (index === 0 || d[0].startsWith('（例）') || d[0] === '') {
        continue
      }
      if (d.length < 3) {
        errs.push(errFormat(index, `入力項目に不足があります。`))
        continue
      }

      /**
       * d[0]: メールアドレス / email
       * d[1]: 種別 / role
       * d[2]: オンボーディングアクションスタート日 / startedAt
       */
      if (checkedEmails.includes(d[0])) {
        errs.push(errFormat(index, `ファイル内に同じメールアドレスが存在するためスキップしました。`))
        continue
      }
      checkedEmails.push(d[0]) // for duplicate checks in a file

      if (memberEmails?.includes(d[0])) {
        errs.push(errFormat(index, `すでにチーム内に存在するメールアドレスです。`))
        continue
      }
      if (!d[0].match(new RegExp(PatternEmailLowerCase))) {
        errs.push(errFormat(index, PatternEmailLowerCaseMessage))
        continue
      }
      if (d[0].length > maxLengthEmail) {
        errs.push(errFormat(index, `メールアドレスは${maxLengthEmail}文字以内で入力してください。`))
        continue
      }
      if (!roles.includes(d[1])) {
        errs.push(errFormat(index, `種別は次のいずれかを入力してください。\n　${roles.join('\n　')}`))
        continue
      }
      if (!d[2].match(new RegExp(PatternDateBySlashes))) {
        errs.push(
          errFormat(
            index,
            `オンボーディングアクションスタート日は 西暦/月/日 の形式で入力してください。\n　例: 2023年4月1日 → 2023/4/1\n　例: 2023年10月15日 → 2023/10/15`
          )
        )
        continue
      }
      try {
        startedAt = new Date(d[2]).toISOString()
      } catch (error) {
        errs.push(errFormat(index, `存在しない日付が入力されています。`))
        continue
      }

      if (d[0] && d[1] && d[2]) {
        const params = {
          email: d[0],
          role: getRoleByValue(d[1]) as OnboardingTeamMemberRole,
          startedAt,
          teamId,
          reserveWillSendEmailAt:reserveWillSendEmailAt,
        }
        const res = await createTeamMember(params)
        if (
          'id' in res ||
          // This 'Email address is not verified...' error occurs in success, as of 2023/1/30.
          res[0].message.match(/Email address is not verified. The following identities failed the check in region/) ||
          // Registration succeeds even if this 'Invalid domain name: ...' error occurs.
          res[0].message.match(/Invalid domain name:/)
        ) {
          upNum = ++upNum
          setMemberEmails((pre) => (pre ? [...pre, d[0]] : [d[0]]))
          // This '既に登録済です' error occurs only when email has been registered. NOT occur with non-registered email.
        } else if (res[0].message.match(/既に登録済です/)) {
          errs.push(errFormat(index, `すでに登録されているメールアドレスです。`))
        } else {
          errs.push(errFormat(index, `登録に失敗しました。入力項目に間違いがないかご確認ください。`))
        }
      } else {
        errs.push(errFormat(index, `入力項目に不足があります。`))
      }
    }

    if (upNum > 0) {
      setUploadedNum(upNum)
    }
    if (errs.length) {
      setUploadErrors(errs)
    }

    setUploadFile(undefined)
    setCsvData([])
    setOpen(true)
    setProcessing(false)
  }, [csvData, teamId, teamMember, setUploadFile, setCsvData, setOpen, createTeamMember, memberEmails])

  return { onUploadActions, onUploadMembers, processing, uploadedNum, uploadErrors }
}
