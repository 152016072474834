import React from 'react'

import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { LoadingCircular } from '../../../components'

import { Pages, useHistory } from 'assets'

export const TeamsToolsKarteRootPage: React.FC = () => {
  const { teamId } = React.useContext(TeamsHooksContext)

  const { route } = useHistory()

  React.useEffect(() => {
    if (teamId) {
      route.push(Pages.TeamsToolKarteHome, { teamId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return <LoadingCircular loading={true} />
}
