import * as React from 'react'

import { Control, useForm, useWatch } from 'react-hook-form'

import {
  MIN_PRESENTATION_TIME,
  TeamBuildingReviewHooksContext,
} from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { isLeader as fetchIsLeader } from 'pages/teams/utils/teamMember'
import { TeamBuildingReview, TeamBuildingReviewStatus } from 'utils/generated'

import { Answers } from '..'

import { Agenda } from './Agenda'
import { Complete } from './Complete'
import { Landing } from './generic/Landing'
import { WaitingOther } from './generic/WaitingOther'
import { Step1 } from './Step1'
import { Step1Presen } from './Step1Presen'
import { Step1Select } from './Step1Select'
import { Step2 } from './Step2'
import { Step2Confirm } from './Step2Confirm'
import { Step2Input } from './Step2Input'
import { Step2Presen } from './Step2Presen'
import { Step2Select } from './Step2Select'
import { Step3 } from './Step3'
import { Step3Select } from './Step3Select'

type Props = {
  teamBuildingReview: TeamBuildingReview | undefined
  waitingOther: boolean
  reviewMemberControl: Control<Answers>
  callUpdate: () => Promise<void>
}
export const RenderByTeamBuildingReviewStatus: React.FC<Props> = ({
  teamBuildingReview,
  waitingOther,
  reviewMemberControl,
  callUpdate,
}) => {
  const { control } = useForm<{ iceBreakNumber: number; iceBreakMinute: number }>()
  const { iceBreakMinute, setIceBreakNumber, setIceBreakMinute, currentTeamMember, teamBuildingReviewSummary } =
    React.useContext(TeamBuildingReviewHooksContext)
  const isLeader = React.useMemo(() => fetchIsLeader(currentTeamMember), [currentTeamMember])
  const { iceBreakNumber: watchNumber, iceBreakMinute: watchMinute } = useWatch<{
    iceBreakNumber: number
    iceBreakMinute: number
  }>({ control })

  React.useEffect(() => {
    if (watchNumber) {
      const val = watchNumber < 1 ? 1 : watchNumber
      setIceBreakNumber(Number(val))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNumber])

  React.useEffect(() => {
    if (watchMinute) {
      const val = watchMinute < MIN_PRESENTATION_TIME ? MIN_PRESENTATION_TIME : watchMinute
      setIceBreakMinute(Number(val))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMinute])

  const status = React.useMemo(() => teamBuildingReview?.status, [teamBuildingReview])
  return (
    <>
      {status === TeamBuildingReviewStatus.Created && (
        <>
          <Agenda title="リフレクションのステップ" subtitle="以下のステップで目標を振り返りましょう。" />
          <WaitingOther showMemberOnly />
        </>
      )}
      {status === TeamBuildingReviewStatus.Step1 &&
        (isLeader ? (
          <Step1 title="アイスブレイク" subtitle="アイスブレイクの内容を選択しましょう。" />
        ) : (
          <Landing title="アイスブレイク" />
        ))}
      {status === TeamBuildingReviewStatus.Step1Select &&
        (isLeader ? (
          <Step1Select
            title="アイスブレイク"
            subtitle="発表する「人数」と「発表時間」を選択してください。"
            control={control}
          />
        ) : (
          <Landing title="アイスブレイク" />
        ))}
      {status === TeamBuildingReviewStatus.Step1Presen && (
        <Step1Presen
          title={`「${teamBuildingReview?.iceBreak ?? ''}」`}
          subtitle={`ランダムに発表者を指名します！${iceBreakMinute}分でお話下さい。`}
          teamBuildingReview={teamBuildingReview}
        />
      )}
      {status === TeamBuildingReviewStatus.Step1End && (
        <Agenda title="STEP1完了" subtitle="アイスブレイクは以上です。STEP2へ進みましょう。" current={2} />
      )}
      {status === TeamBuildingReviewStatus.Step2 && (
        <Step2
          title="過去の点数を確認しましょう"
          subtitle={`過去5回分の各回の平均点数です。\n過去の点数を踏まえながら今回も取り組みましょう。`}
          histories={teamBuildingReviewSummary?.histories}
        />
      )}
      {status === TeamBuildingReviewStatus.Step2Input &&
        (waitingOther ? (
          <WaitingOther
            title={`Q.自チームのチーム目標と行動規範を思い出しましょう`}
            subtitle={`それぞれ現状は何点だと思いますか？自分なりの点数をつけましょう。`}
          />
        ) : (
          <Step2Input
            title={`Q.自チームのチーム目標と行動規範を思い出しましょう`}
            subtitle={`それぞれ現状は何点だと思いますか？自分なりの点数をつけましょう。`}
            control={reviewMemberControl}
          />
        ))}
      {status === TeamBuildingReviewStatus.Step2Confirm && (
        <Step2Confirm title={`Q.自チームのチーム目標と行動規範を思い出しましょう`} subtitle={''} />
      )}
      {status === TeamBuildingReviewStatus.Step2Select &&
        (isLeader ? (
          <Step2Select
            title="目標と行動規範についての発表"
            subtitle="発表する人数を選択してください。"
            control={control}
          />
        ) : (
          <Landing title="目標と行動規範についての発表" />
        ))}
      {status === TeamBuildingReviewStatus.Step2Presen && (
        <Step2Presen
          subtitle="ランダムに発表者を指名します！"
          title={`チーム目標と行動規範について\n${iceBreakMinute}分でお話下さい。`}
          teamBuildingReview={teamBuildingReview}
        />
      )}
      {status === TeamBuildingReviewStatus.Step2End && (
        <Agenda title="STEP2完了" subtitle="STEP2は以上です。STEP3へ進みましょう。" current={3} />
      )}
      {status === TeamBuildingReviewStatus.Step3 && (
        <Step3
          title="計画の見直し"
          subtitle="更にチームをよくしていくために、スケジュールや計画の見直し、新たなアクションがあれば追加しましょう"
          callUpdate={callUpdate}
        />
      )}
      {status === TeamBuildingReviewStatus.Step3Select &&
        (isLeader ? (
          <Step3Select title="日程の調整" subtitle="次回のリフレクションの日程を決めましょう。" />
        ) : (
          <Landing title="日程の調整" />
        ))}
      {status === TeamBuildingReviewStatus.Completed && (
        <Complete title="リフレクションは以上です" subtitle="お疲れ様でした！" nextAt={teamBuildingReview?.nextAt} />
      )}
    </>
  )
}
