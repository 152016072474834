import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  list: {
    heading: string
    desc: string
    link: string
  }[]
}

export const UsageWayList: React.FC<Props> = ({ list }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>ツール活用方法</div>

      <div className={classes.cards}>
        {list.map((item) => (
          <a className={classes.card} key={item.heading} href={item.link} target="_blank" rel="noopener noreferrer">
            <img
              className={classes.image}
              src={`${process.env.PUBLIC_URL}/assets/jpg/teams/usage-way.jpg`}
              alt="usage way image"
            />
            <div className={classes.text}>
              <div className={classes.heading}>{item.heading}</div>
              <div className={classes.desc}>{item.desc}</div>
            </div>
            <img
              className={classes.icon}
              src={`${process.env.PUBLIC_URL}/assets/svg/arrow-right.svg`}
              alt="arrow icon"
            />
          </a>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: 24,
      color: constants.TEXT_GRAY_DARK,
      borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    title: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    cards: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '16px 10px',
      marginTop: 16,

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      padding: 16,
      backgroundColor: constants.COLOR_WHITE,

      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: constants.COLOR_BLUE_LIGHT2,
        },
      },
    },
    image: {
      height: '100%',
      maxHeight: 57,
      verticalAlign: 'top',
    },
    text: {
      flex: 1,
      alignSelf: 'flex-start',
    },
    heading: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    desc: {
      marginTop: 8,
      fontSize: 10,
      fontWeight: 'bold',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    icon: {
      height: 10,
    },
  }),
  { name: 'UsageWayList' }
)
