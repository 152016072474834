import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { UsefulCard, Font, Flexbox } from '../atoms'
import { GhostFeaturesAndThemes, GhostMageImg, CircularProgressCount } from '../modules'

import { LoadingIndicator } from './LoadingIndicator'

import { GhostBackgroundAndBenefitsAndRisks2, GhostActionAndEffect2 } from '.'

type Props = {
  ghostId: string
  num?: 1 | 2
  bodyDetails: 'featuresAndThemes' | 'backgroundAndBenefitsAndRisks' | 'actionsAndEffects'
  addArrowsAndCounter?: boolean
  usersVotes?: boolean[]
  ownStyles?: React.CSSProperties
}

export const GhostSawBox: React.FC<Props> = (props) => {
  GhostSawBox.defaultProps = { addArrowsAndCounter: false }

  const { ghosts } = React.useContext(GhostContext)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const ghost = ghosts.find((g) => g.id === props.ghostId)
  if (!ghost) return <LoadingIndicator snapshot={{ vars: { ghost } }} />

  return (
    <div style={props.ownStyles}>
      <UsefulCard backgroundColor="white" borderRadius={8}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-end"
          style={{ padding: isMdUp ? '40px 40px 0 ' : '24px 16px 0' }}
        >
          <Grid item xs={12} md={6} style={{ flex: isMdUp ? 1 : '1 1 100%', paddingLeft: isMdUp ? 24 : 0 }}>
            <Flexbox ownStyles={{ padding: isMdUp ? 0 : '0 16px', justifyContent: 'flex-start', gap: '16px' }}>
              {props.usersVotes && <CircularProgressCount numerator={props.usersVotes.length + 1} denominator={3} />}
              <div>
                <Font fontSize={10} ownStyles={{ color: constGhost.COLOR_GRAY2 }}>
                  FILE NO.
                  {ghost.fileNo}
                </Font>
                <Font
                  fontSize={isMdUp ? 16 : 14}
                  bold
                  ownStyles={{ color: constGhost.COLOR_MAIN_NEW, marginTop: isMdUp ? 5 : 0 }}
                  testId={ghost.name}
                >
                  {ghost.name}
                </Font>
              </div>
            </Flexbox>
          </Grid>
          <Grid item xs={12} md={6} style={{ flex: isMdUp ? 0 : 1 }}>
            <GhostMageImg ghostId={ghost.id} ownStyles={{ marginTop: isMdUp ? 0 : 18 }} />
          </Grid>
        </Grid>
        <GhostFeaturesAndThemes
          sympton={ghost.sympton}
          scenes={ghost.scenes}
          ownStyles={
            props.bodyDetails === 'featuresAndThemes'
              ? { padding: isMdUp ? '16px 40px 40px' : '24px 16px' }
              : { display: 'none' }
          }
        />
        <GhostBackgroundAndBenefitsAndRisks2
          why={ghost.why}
          pro={ghost.pro}
          con={ghost.con}
          ownStyles={
            props.bodyDetails === 'backgroundAndBenefitsAndRisks'
              ? { padding: isMdUp ? '16px 40px 40px' : '24px 16px' }
              : { display: 'none' }
          }
        />
        <GhostActionAndEffect2
          solution={ghost.solution}
          actions={ghost.actions}
          anti={ghost.anti}
          caveats={ghost.caveats}
          ownStyles={
            props.bodyDetails === 'actionsAndEffects'
              ? { padding: isMdUp ? '16px 40px 40px' : '24px 16px' }
              : { display: 'none' }
          }
        />
      </UsefulCard>
    </div>
  )
}
