import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    marginBottom: 0,
  },

  ellipsis: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
  className?: string
  ellipsis?: boolean
  color?: string
  lineHeight?: number
}

// -----------------------------
// Component
// -----------------------------
export const Lead = (props: IProps) => {
  const classes = useStyles()

  return (
    <p
      className={`${classes.root} ${props.className} ${props.ellipsis ? classes.ellipsis : ''}`}
      style={{
        color: props.color || constants.TEXT_GRAY,
        lineHeight: props.lineHeight,
      }}
    >
      {props.text}
    </p>
  )
}
