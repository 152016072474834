import dayjs from 'dayjs'
import iconv from 'iconv-lite'

import { MemberWithNextActions } from 'pages/ghost/utils/combineMembersAndNextActions'

type InputDate = {
  createdAt: string | undefined
  ghostName: string | undefined
  nextActionList: MemberWithNextActions[]
}

export const exportCSVGhostPastList = async (inputDateList: InputDate[]) => {
  // CSV への出力データを作成
  const outputCsv = []
  outputCsv.push(['回（日付）', '探索したオバケ', '氏名', '明日から出来そうなアクション'].join(','))
  for (const [index, data] of inputDateList.entries()) {
    const targetTimes = inputDateList.length - index
    const formattedDate = dayjs(data.createdAt).format('YY/MM/DD')
    outputCsv.push([`${targetTimes}回目（${formattedDate}）`, data.ghostName, '', ''].join(','))

    if (data.nextActionList.length === 0) {
      outputCsv.push([
        '', // 回（日付）
        '', // 探索したオバケ
        'データが存在しません',
        '', // 明日から出来そうなアクション
      ])
    } else {
      for (const nextAction of data.nextActionList) {
        const name = nextAction.memberName
        const report = nextAction.report

        outputCsv.push([
          '', // 回（日付）
          '', // 探索したオバケ
          name,
          escapeCsvValue(report),
        ])
      }
    }
  }

  // Shift-JIS 形式で CSV としてエンコード
  const csvBuffer = iconv.encode(outputCsv.join('\n'), 'Shift_JIS')
  const filename = `ghostlist-${dayjs().format('YYYYMMDD_HHmmss')}.csv`

  // CSVファイルをダウンロード
  downloadCSVFile(csvBuffer, filename)
}

// CSV ファイルをダウンロードする関数
const downloadCSVFile = (buffer: Buffer, filename: string) => {
  const blob = new Blob([buffer], { type: 'text/csv; charset=shift_jis' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 改行コードやダブルクオーテーション、カンマが含まれていても
// 1セルとして認識させるためにエスケープする関数
const escapeCsvValue = (value: string): string => {
  return `"${value.replace(/"/g, '""')}"`
}
