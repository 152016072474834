import { createContext, useEffect, useState, useCallback } from 'react'

import {
  createGhostReports,
  forceGetVoteResult,
  reserveGhostReports,
  voteGhostReports,
} from 'pages/ghost/api/report/handlers'
import { GhostReport, GhostTeamMember } from 'utils/generated'

import { useFetchReports } from '../hooks/fetchReports'

import { page2Num } from '../assets/pages'

export const ReportContext = createContext<ReportStore>({} as ReportStore)

export type ReportStore = ReturnType<typeof useReportStore>

export const useReportStore = (teamId?: string, teamMember?: GhostTeamMember) => {
  const userId = teamMember?.id

  // 3 reports to vote, reserveed when user.page === 3
  const [reportsToVote, setReports2Vote] = useState<GhostReport[]>([])

  const createReports = useCallback(
    async (ghostIds: string[]) => {
      if (!teamId || !userId) return
      try {
        await createGhostReports({ input: { teamId, userId, ghostIds } })
      } catch (e) {
        console.log(e)
      }
    },
    [teamId, userId]
  )

  const reserveReports = useCallback(async () => {
    if (!teamId || !userId) return
    try {
      const reserved = await reserveGhostReports({ input: { teamId, userId } })
      if (reserved && reserved.length === 3) {
        setReports2Vote(reserved)
      } else {
        throw new Error(`reservation not completed properly ${reserved.toString()}`) // TODO create custom
      }
    } catch (e) {
      console.log(e)
    }
  }, [teamId, userId])

  const [reserved, setReserved] = useState(false)
  useEffect(() => {
    if (!reserved && reportsToVote.length === 0 && teamMember && teamMember.page === page2Num('vote')) {
      setReserved(true)
      reserveReports()
    }
  }, [reserveReports, teamMember, reportsToVote.length, reserved])

  const voteReports = useCallback(async (reports: GhostReport[], upOrDown: boolean[]) => {
    try {
      await voteGhostReports({ input: { reports, upOrDown } })
    } catch (e) {
      console.log(e)
    }
  }, [])

  const forceGetResult = useCallback(async () => {
    if (!teamId) return
    try {
      await forceGetVoteResult({ teamId })
    } catch (e) {
      console.log(e)
    }
  }, [teamId])

  const { fetchReports, reports, isFetchingReports } = useFetchReports()

  // Init
  useEffect(() => {
    if (!teamId || !teamMember) return
    const isFetch = (): boolean => {
      switch (teamMember.page) {
        case page2Num('step1Results', teamMember):
          return true
        case page2Num('step1Presentation', teamMember):
          return true
        default:
          return false
      }
    }
    if (isFetch()) fetchReports(teamId)
  }, [teamId, teamMember, fetchReports])

  return {
    createReports,
    reportsToVote,
    voteReports,
    forceGetResult,
    reports,
    isFetchingReports,
  } as const
}
