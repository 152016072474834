import React, { useContext, useEffect, useState, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { SearchBar } from 'pages/onboarding/components/search-bar'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { OnboardingTeamMember, OnboardingTeamMemberRole, OnboardingTeamMemberStatus } from 'utils/generated'

import { OnbContext } from '../PagesRoot'
import NoAvatarSvg from '../team/assets/no-avatar.svg'

import ArrowRightSvg from './assets/arrowRight.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'
import { theme } from 'assets/theme'

type Props = {}
type StyleProps = {}
export const MemberStatusPageBloc = {
  useAdapter: () => {
    const { teamMember, teamId } = useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)

    return {
      teamMember,
      teamId,
      teamMembers,
    }
  },
}
export const MemberStatusPage: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles()

  // deps
  const { teamId, teamMembers } = MemberStatusPageBloc.useAdapter()

  //data helpers -->
  const initValue: OnboardingTeamMember[] = useMemo(() => teamMembers, [teamMembers])
  const [currentTeamMembers, setCurrentTeamMembers] = useState<OnboardingTeamMember[]>([])
  useEffect(() => {
    setCurrentTeamMembers(initValue)
  }, [initValue])
  //<-- data helpers
  const history = useOnbHistory()
  const [input, setInput] = useState('')
  //↓when searchBar type
  useEffect(() => {
    let returnValue = initValue
    returnValue = returnValue.filter((item) => item.nickname && item.nickname.match(new RegExp(`^(?=.*${input}).*$`)))
    setCurrentTeamMembers(returnValue)
  }, [input, initValue])
  const [search, setSearch] = useState('')

  const handleStartSearch = () => {
    setSearch(input)
  }

  const handleStartInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleStartEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearch(input)
    }
  }
  const searchedTeamMembers = currentTeamMembers
    .filter(
      (member) =>
        member.role === OnboardingTeamMemberRole.Member && member.status === OnboardingTeamMemberStatus.Accepted
    )
    .filter((member) => member.nickname && member.nickname.indexOf(search) !== -1)

  return (
    <div className={classes.memberStatusPageRoot}>
      <div className={classes.toolBar}>
        <div className={classes.title}>個別実施状況</div>
        <div className={classes.barWrapper}>
          <div className={classes.searchBar}>
            <SearchBar
              placeholder="メンバーを検索"
              borderRadius={4}
              iconMarginLeft={16}
              iconMarginRight={21}
              onClickImg={handleStartSearch}
              onChangeInput={handleStartInput}
              onKeyPressInput={handleStartEnter}
              inputValue={input}
              setInputValue={setInput}
              searchValue={search}
              setSearchValue={setSearch}
            />
          </div>
        </div>
      </div>
      {searchedTeamMembers.map((item, id) => (
        <div className={classes.cardWrapper} key={`case-list-company-${id}`}>
          <div className={classes.cardFlame}>
            <div
              className={classes.card}
              onClick={() => {
                history.push(OnbPages.MemberStatusDetail, { teamId, userId: item.userId })
              }}
            >
              <div className={classes.element}>
                <Avatar src={item.imageUrl || NoAvatarSvg} alt="avatar" size={48} />
                <div className={classes.list}>
                  <div className={classes.name}>
                    {item.nickname && item.nickname !== '名無し' ? item.nickname : item.email ?? ''}
                  </div>
                  <div className={classes.roleLabelWrapper}>
                    <div className={classes.roleLabel}>{teamMemberRoleLabel(item.role)}</div>
                  </div>
                </div>
              </div>
              <div>
                <img className={classes.arrowRight} src={ArrowRightSvg} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const useStyles = makeStyles(
  {
    memberStatusPageRoot: {
      paddingTop: '24px',
      paddingLeft: '24px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '27px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    toolBar: {
      display: 'flex',
      justifyContent: `space-between`,
      paddingBottom: 16,
      [theme.breakpoints.down('sm')]: {
        display: `block`,
        paddingBottom: 16,
      },
    },
    title: {
      fontWeight: `bold`,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        paddingBottom: '26px',
      },
    },
    barWrapper: {
      display: 'flex',
    },
    searchBar: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
    cardWrapper: {
      backgroundColor: constants.COLOR_WHITE,
      padding: '12px 24px',
      '&:hover': {
        background: constants.COLOR_ONBOARDING_SKY_BLUE,
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: `transparent`,
        padding: '0',
      },
    },
    cardFlame: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '8px',
        backgroundColor: `transparent`,
      },
    },
    card: {
      display: 'flex',
      padding: '12px 0',
      cursor: 'pointer',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
        padding: '12px 24px',
      },
    },
    list: {
      flexDirection: 'column',
      padding: '0 16px',
    },
    avatar: {
      marginRight: 16,
    },

    element: {
      display: 'flex',
    },
    name: {
      width: 144,
      fontWeight: 'bold',
      fontSize: 12,
    },
    roleLabelWrapper: {
      display: 'inline-block',
      paddingTop: '10px',
    },
    roleLabel: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: '10px',
      paddingLeft: '8px',
      paddingRight: '8px',
      color: constants.COLOR_ONBOARDING_MAIN,
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      height: '20px',
      fontWeight: 'bold',
    },
    arrowRight: {},
  },
  {
    name: 'MemberStatusPage',
  }
)
