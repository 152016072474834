import React from 'react'

import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { Button } from './Button'

import { constants } from 'assets'
import { Pages } from 'assets'
import { replacePathParams } from 'assets/history'

export const ButtonToDiagnose: React.FC = () => {
  const { teamId, currentTeamMember, currentTeamMemberLoaded } = React.useContext(TeamsHooksContext)

  const onClick = () => {
    if (teamId) {
      window.open(replacePathParams(Pages.TeamsToolKarteDiagnose, { teamId }))
    }
  }

  return (
    <>
      {currentTeamMember && currentTeamMemberLoaded && (
        <Button
          buttonType="primary"
          style={{
            padding: '8px 16px',
            height: 32,
            borderRadius: 16,
            width: 128,
            fontSize: 12,
          }}
          endIconStyle={{
            margin: '0px -22px 0px 4px',
          }}
          hoverStyle={{
            opacity: 0.7,
            backgroundColor: constants.COLOR_MAIN_NEW,
            color: '#fff',
          }}
          icon="chevron-right"
          onClick={onClick}
        >
          {currentTeamMember.teamKarteIds && currentTeamMember.teamKarteIds.length > 0 ? '再診断する' : '診断する'}
        </Button>
      )}
    </>
  )
}
