import { API, graphqlOperation } from '@aws-amplify/api'

import { QueryGetPointsArgs, Point } from 'utils/generated'

import { QueryGetPoints } from './graphql'

export const getPoint = async (args: QueryGetPointsArgs): Promise<Point> => {
  const response: any = await API.graphql(
    graphqlOperation(QueryGetPoints, {
      ...args,
    })
  )
  const { getPoints } = response.data

  return getPoints as Point
}
