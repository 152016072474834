import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { Auth } from 'aws-amplify'

import { QueryGetKarte, QueryGetKarteList } from '../../graphql'
import { IKarte, IKarteListParams, IKarteListResult } from '../../interface'

const MutationKarteLeaderStore = `mutation KarteLeaderStore($input: KarteLeaderInput!) {
  karteLeaderStore(input: $input) {
    id
    leaderId
    data
    createdAt
    updatedAt
  }
}`

// interface IKarteLeaderStoreInput {
//   id: string
//   name: string
//   leaderNumber: string
//   leaderData: string
//   data: string
// }

export const karteLeaderStore = async (params: {
  id: string
  name: string
  leaderNumber: string
  leaderData: string
}) => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const memberKarte: any = await API.graphql({
    query: QueryGetKarte,
    variables: { id: params.id, leaderId: cognitoUser.getUsername() },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  const input = {
    id: params.leaderNumber,
    name: params.name,
    leaderNumber: params.leaderNumber,
    leaderData: params.leaderData,
    data: memberKarte.data.getKarte.data,
  }
  const response: any = await API.graphql({
    query: MutationKarteLeaderStore,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return response.data.karteLeaderStore as IKarte
}

export const getKarteList = async (params: IKarteListParams): Promise<IKarteListResult> => {
  try {
    const res: any = await API.graphql({
      query: QueryGetKarteList,
      variables: {
        filter: { leaderId: params.leaderId },
        first: params.first ?? 40,
        after: params.nextToken || '',
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    })

    return {
      items: [...res?.data?.getKarteList?.items],
      nextToken: res?.data?.getKarteList?.nextToken,
    }
  } catch (e) {
    throw e
  }
}
