import * as React from 'react'

import { Box } from '@material-ui/core'

import AlertDialog from 'components/AlertDialog'
import Button from 'components/Button'

import { IuseDialogDeleteProps } from './useDialogDelete'

import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseDialogDeleteProps
}

// -----------------------------
// Component
// -----------------------------
export const DialogDelete = (props: IProps) => {
  return (
    <AlertDialog
      open={props.presenter.getOpen()}
      failure={true}
      onClose={props.presenter.onCloseDialog}
      body={<p>この動作は戻せません。削除しますか？</p>}
      footer={
        <Box display="flex">
          <Box mr="8px" width="100%">
            <Button
              bgColor={constants.COLOR_BLACK}
              textColor={constants.TEXT_WHITE}
              fullWidth={true}
              onClick={props.presenter.onDoNotDelete}
              body={<div>いいえ</div>}
            />
          </Box>
          <Box ml="8px" width="100%">
            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.TEXT_BLACK}
              fullWidth={true}
              onClick={() => props.presenter.onDoDelete()}
              body={<div>はい</div>}
            />
          </Box>
        </Box>
      }
    />
  )
}
