export const OnboardingPostLikeToggleEvent = 'OnboardingPostLikeToggleEvent'
export const OnboardingPostCommentEvent = 'OnboardingPostCommentEvent'
export const OnboardingPostCommentLikeToggleEvent = 'OnboardingPostCommentLikeToggleEvent'
export const OnboardingPostSecondCommentEvent = 'OnboardingPostSecondCommentEvent'
export const OnboardingPostSecondCommentLikeToggleEvent = 'OnboardingPostSecondCommentLikeToggleEvent'
export const OnboardingActionLikeToggleEvent = 'OnboardingActionLikeToggleEvent'
export const OnboardingActionCommentEvent = 'OnboardingActionCommentEvent'
export const OnboardingActionFeedbackEvent = 'OnboardingActionFeedbackEvent'
export const AccountUpdateEvent = 'AccountUpdateEvent'
export const TeamMemberUpdateEvent = 'TeamMemberUpdateEvent'

export const emitOnboardingPostLikeToggleEvent = (postId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingPostLikeToggleEvent, {
      detail: {
        postId,
      },
    })
  )
}

export const emitOnboardingPostCommentEvent = (postId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingPostCommentEvent, {
      detail: {
        postId,
      },
    })
  )
}

export const emitOnboardingPostCommentLikeToggleEvent = (postId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingPostCommentLikeToggleEvent, {
      detail: {
        postId,
      },
    })
  )
}

export const emitOnboardingPostSecondCommentEvent = (postCommentId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingPostSecondCommentEvent, {
      detail: {
        postCommentId,
      },
    })
  )
}

export const emitOnboardingPostSecondCommentLikeToggleEvent = (postSecondCommentId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingPostSecondCommentLikeToggleEvent, {
      detail: {
        postSecondCommentId,
      },
    })
  )
}

export const emitOnboardingActionLikeToggleEvent = (actionId: string, actionOwnerId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingActionLikeToggleEvent, {
      detail: {
        actionId,
        actionOwnerId,
      },
    })
  )
}

export const emitOnboardingActionCommentEvent = (actionId: string, actionOwnerId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingActionCommentEvent, {
      detail: {
        actionId,
        actionOwnerId,
      },
    })
  )
}

export const emitOnboardingActionFeedbackEvent = (teamId: string, actionId: string) => {
  window.dispatchEvent(
    new CustomEvent(OnboardingActionFeedbackEvent, {
      detail: {
        teamId,
        actionId,
      },
    })
  )
}

export const emitAccountUpdateEvent = () => {
  window.dispatchEvent(
    new CustomEvent(AccountUpdateEvent, {
      detail: {},
    })
  )
}

export const emitTeamMemberUpdateEvent = () => {
  window.dispatchEvent(
    new CustomEvent(TeamMemberUpdateEvent, {
      detail: {},
    })
  )
}
