import * as React from 'react'

import sanitize from 'sanitize-html'

export const textToHtml = (content: string) => {
  const sanitizeHtml = (contentSanitize: string) => {
    return sanitize(contentSanitize, {
      allowedTags: ['a', 'br'],
      allowedClasses: {
        a: ['__link'],
      },
      allowedAttributes: {
        a: ['href', 'target', 'rel'],
      },
    })
  }

  if (content) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content),
        }}
      />
    )
  } else {
    return <></>
  }
}
