import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import { MutationIsConfirmed } from './graphql'

const isConfirmed = async (email: string) => {
  const response: any = await API.graphql({
    query: MutationIsConfirmed,
    variables: { email },
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  })
  const { isConfirmed } = response.data

  return isConfirmed
}

export default isConfirmed
