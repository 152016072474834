import * as React from 'react'

import * as constGhost from 'pages/ghost/assets/constGhost'

import grayCrossIcon from '../../assets/svg/grayCrossIcon.svg'
import userIcon from '../../assets/svg/userIcon.svg'
import { UsefulCard, Font, Flexbox } from '../atoms'

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  id: string
  memberFullName: string
  memberRole: string
  ownStyles?: React.CSSProperties
}

export const MemberCard: React.FC<Props> = (props) => {
  return (
    <UsefulCard
      backgroundColor="white"
      ownStyles={{
        border: '1px solid #E2E2E2',
        ...props.ownStyles,
      }}
    >
      <Flexbox ownStyles={{ justifyContent: 'Flex-start', position: 'relative' }}>
        <img src={userIcon} alt={'userIcon'} style={{ margin: '0 8px 0 16px' }} />
        <div>
          <Font fontSize={12} bold ownStyles={{ margin: '6px 0 0' }}>
            {props.memberFullName}
          </Font>
          <Font fontSize={10} ownStyles={{ margin: '4px 0 6px' }}>
            {props.memberRole}
          </Font>
        </div>
        <UsefulCard
          backgroundColor="lightGray3"
          borderRadius={13}
          onClick={(event) => (event !== undefined ? props.onClick(event) : undefined)}
          id={props.id}
          ownStyles={{
            width: 26,
            height: 26,
            color: constGhost.COLOR_WHITE,
            boxShadow: 'none',
            cursor: 'pointer',
            display: 'flex',
            margin: '0 8px 0 auto',
          }}
        >
          <img src={grayCrossIcon} alt={'crossIcon'} style={{ padding: 5, borderRadius: 8 }} />
        </UsefulCard>
      </Flexbox>
    </UsefulCard>
  )
}
