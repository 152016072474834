import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

import { Container } from 'pages/onboarding/components'
import { SubmitButton } from 'pages/onboarding/components/button-large'
import { useTeamMemberWithAuth } from 'pages/onboarding/hooks/team'
import OnboardingMemberEditPage from 'pages/onboarding/pages/member/edit/index'
import { OnboardingTeamMemberRole } from 'utils/generated'

import { OnbStore } from '../../PagesRoot'
import { TutorialStateProps } from '../Tutorial'

import { callTx, TxIntro } from '../assets/text'


export const Intro = ({ stage, setStage, teamMember }: TutorialStateProps & OnbStore) => {
  const styles = useIntroStyles()
  const useActivate = async () => {
    await useTeamMemberWithAuth(teamMember.teamId)
  }
  useActivate()

  const next = () => setStage(stage + 1)

  let content
  if (stage < 3) {
    content = <IntroBody stage={stage} next={next} memberType={teamMember.role} styles={styles} />
  } else {
    content = <OnboardingMemberEditPage tutorial={{ next: next }} />
  }

  return (
    <Container>
      <div className={styles.wrapper}>
        <Img name={`stage${stage}`} kls={styles.indicator} />
        {content}
      </div>
    </Container>
  )
}

const Img = ({ name, kls }: { name: string; kls: string }) => (
  <img src={require(`../assets/images/${name}.svg`).default} className={kls} alt={name} />
)

const IntroBody = ({
  stage,
  next,
  memberType,
  styles,
}: {
  stage: number
  next: () => void
  memberType: OnboardingTeamMemberRole
  styles: ClassNameMap
}) => {
  const [Tx, img, kls] =
    stage === 1 ? [TxIntro, 'onb-logo', styles.logo] : [callTx(memberType)[2], memberType, styles.role]

  return (
    <div className={styles.body}>
      <div>
        <Img key={img} name={img} kls={kls} />
      </div>

      <div>{Tx.head}</div>
      <div style={{ margin: '0 24px 100px' }}>{Tx.body}</div>
      {memberType === 'ADMIN' && <div style={{ height: 35 }} />}
      <div>
        <SubmitButton placeholder="次へ" onPress={next} />
      </div>
    </div>
  )
}

export const useIntroStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 560,
    margin: '0 auto',
    whiteSpace: 'pre-line',
  },
  indicator: {
    display: 'block',
    width: 133,
    margin: '48px auto 16px',
  },
  body: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    letterSpacing: 0,
    marginTop: 32,
    height: 'calc(100vh - 88px)',
    '& div': {
      '&:nth-child(1)': {
        height: 332,
      },
      '&:nth-child(2)': {
        fontSize: 24,
        lineHeight: '1em',
        fontWeight: 'bold',
        margin: '0 auto 30px',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        padding: 0,
        fontSize: 14,
        lineHeight: '24px',
      },
      '&:last-child': {
        flex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        '& button': {
          width: 'calc( 100% - 32px)',
          marginBottom: 32,
        },
      },
    },
  },
  logo: {
    height: 61,
    width: 'auto',
    margin: '146px auto auto',
  },
  role: {
    height: 252,
    margin: '35px auto auto',
  },
}))
