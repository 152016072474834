import { useEffect, useState, useCallback } from 'react'

import { queryGetOnboardingTeamMemberTargetFile } from 'pages/onboarding/graphql'
import { OnboardingPostFile } from 'utils/generated'

export const useFiles = (fileName: string | undefined, teamId: string, userId: string | undefined) => {
  const [file, setFile] = useState<OnboardingPostFile | undefined>(undefined)

  const fileName2FileData = useCallback(async () => {
    if (!fileName || !userId) return
    try {
      const res = await queryGetOnboardingTeamMemberTargetFile({ fileName, teamId, userId })
      setFile(res)
    } catch (e) {
      console.log(e)
    }
  }, [fileName, teamId, userId])

  useEffect(() => {
    fileName2FileData()
  }, [fileName2FileData, fileName, teamId, userId])

  return { file } as const
}
