import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'

import updateSetupAnketFeedback from '../../services/api/updateSetupAnketFeedback'
import updateSetupItemFeedback from '../../services/api/updateSetupItemFeedback'
import getSetupItemList from '../../services/getSetupItemList'
import { getWeeksByDay } from '../../services/utils/weekday'
import { RootState } from '../reducers'

import { SetupactionActions } from './action'


type Action = ActionType<typeof SetupactionActions>

const getSetupItemListEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SetupactionActions.fetch.started.match),
    exhaustMap(({ payload }) => {
      return from(getSetupItemList(payload.params.setupStartedAt, payload.params.week, payload.params.username)).pipe(
        map((res) => {
          return SetupactionActions.fetch.done({ params: payload, result: res })
        }),
        catchError((error) => of(SetupactionActions.fetch.failed({ params: payload, error })))
      )
    })
  )

const updateSetupAnketFeedbackEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SetupactionActions.submitQuestionnaire.started.match),
    exhaustMap(({ payload }) => {
      return from(updateSetupAnketFeedback(payload.data)).pipe(
        map((res) => {
          const i = getWeeksByDay(new Date(res.weekDate), new Date(payload.setupStartedAt))

          return SetupactionActions.submitQuestionnaire.done({
            params: payload,
            result: {
              id: res.id,
              anketValues: {
                ...res,
              },
              mission: `${i}週目のアンケート`,
              why: '現状を確認してみよう！',
            },
          })
        }),
        catchError((error) =>
          of(
            SetupactionActions.submitQuestionnaire.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const updateSetupItemFeedbackEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(SetupactionActions.submitMission.started.match),
    exhaustMap(({ payload }) => {
      return from(updateSetupItemFeedback(payload.data)).pipe(
        map((res) => {
          return SetupactionActions.submitMission.done({
            params: payload,
            result: {
              ...payload.item,
              missionValues: {
                ...res,
              },
            },
          })
        }),
        catchError((error) =>
          of(
            SetupactionActions.submitMission.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

export default [getSetupItemListEpic, updateSetupAnketFeedbackEpic, updateSetupItemFeedbackEpic]
