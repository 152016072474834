import * as React from 'react'

import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { UseFormMethods } from 'react-hook-form'

import { IndexedTeam } from 'pages/teams/pages/list'
import { FilterProps } from 'pages/teams/pages/list/components/Filter'
import { scrollElements } from 'pages/teams/utils/scrollElements'

import { GeneralListBodyRow } from '../atoms/GeneralListBodyRow'
import { TableHeadCell } from '../atoms/TableHeadCell'
import { SortedInfoKey, SortedInfoSort } from '../hooks/sortTeams'

import { constants } from 'assets'

const headerItems: {
  headerName: string
  keyName: SortedInfoKey
  isStickyStyle1?: boolean
  isStickyStyle2?: boolean
}[] = [
  { headerName: 'NO.', keyName: 'teamNum', isStickyStyle1: true },
  { headerName: 'チーム名', keyName: 'teamName', isStickyStyle2: true },
  { headerName: 'チーム管理者', keyName: 'admin' },
  { headerName: 'リーダー名', keyName: 'leaderName' },
  { headerName: 'メンバー数', keyName: 'countMember' },
  { headerName: '利用プラン', keyName: 'plans' },
  { headerName: '月額', keyName: 'costs' },
  { headerName: 'チームの\nステータス', keyName: 'status' },
  { headerName: 'メンバーの招待状況', keyName: 'invitation' },
  { headerName: '予約招待状況', keyName: 'reservation' },
]

interface Props {
  teams: IndexedTeam[]
  sortKey: {
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null
  setSortKey: React.Dispatch<
    React.SetStateAction<{
      key: SortedInfoKey
      sort: SortedInfoSort
    } | null>
  >
  statusChangeList: boolean
  filter: FilterProps
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  watchedTeamIds: string[] | string | boolean
}

export const GeneralList: React.FC<Props> = (props) => {
  const { teams, sortKey, setSortKey, statusChangeList, filter, register, setValue, watchedTeamIds } = props
  const classes = useStyles()
  const table = (
    <Table id={scrollElements.generalListSP}>
      <TableHead>
        <TableRow className={classes.tableHeaderRow}>
          {headerItems.map(({ headerName, keyName, isStickyStyle1, isStickyStyle2 }) => (
            <TableHeadCell
              key={`${keyName}-key`}
              headerName={headerName}
              keyName={keyName}
              sortKey={sortKey}
              setSortKey={setSortKey}
              isStickyStyle1={isStickyStyle1}
              isStickyStyle2={isStickyStyle2}
            />
          ))}
          {/* 右端の「編集」項目用の cell */}
          <TableCell className={classes.tableHeaderCell}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {teams.map((team, index) => (
          <GeneralListBodyRow
            key={`fixed-row-${index}`}
            team={team}
            statusChangeList={statusChangeList}
            filter={filter}
            register={register}
            setValue={setValue}
            watchedTeamIds={watchedTeamIds}
            sortKey={sortKey}
          />
        ))}
      </TableBody>
    </Table>
  )

  return (
    <>
      {teams.length > 0 ? (
        <>
          <div className={classes.listContainer}>
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes.tableContainer}
              id={scrollElements.generalList}
            >
              {table}
            </TableContainer>
          </div>
        </>
      ) : (
        <div className={classes.noData}>チームはまだ有りません</div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    listContainer: {
      display: 'flex',
      margin: '0 -24px',
      overflow: 'auto',
      borderTop: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },

    tableContainer: {
      overflowX: 'auto',
    },

    noData: {
      padding: 50,
      textAlign: 'center',
      color: '#999',
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    },

    tableHeaderRow: {
      borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      zIndex: 11,
    },

    tableHeaderCell: {
      backgroundColor: constants.COLOR_WHITE,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    },
  }),
  { name: 'GeneralList' }
)
