import * as React from 'react'

import { Theme, makeStyles } from '@material-ui/core/styles'

import { IuseCategory } from './useCategory'

import { IOptions } from 'assets/options'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'solid 1px #8D8D8D',
    borderRadius: '4px',
    backgroundColor: '#F7F7F7',
    color: '#8D8D8D',
    width: '170px',
    height: '47px',
    fontSize: '16px',
    paddingLeft: '8px',
    paddingRight: '32px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    backgroundImage: 'url(/assets/svg/hint/down_icn.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'right 10px center',

    '&::-ms-expand': {
      display: 'none',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  name?: string
  value?: string
  presenter: IuseCategory
  currentCategory?: string
}

// -----------------------------
// Component
// -----------------------------
export const Categories = (props: IProps) => {
  const classes = useStyles()

  return (
    <select
      className={classes.root}
      name={props.name}
      value={props.currentCategory || props.presenter.getCurrentCategory()}
      onChange={(e) => props.presenter.onSetCurrentCategory(e)}
    >
      {!props.currentCategory && <option hidden>カテゴリーを選択</option>}
      {props.presenter.getCategories().map((getCategorie: IOptions) => {
        return (
          <option key={getCategorie.label} label={getCategorie.label} value={getCategorie.value}>
            {getCategorie.label}
          </option>
        )
      })}
    </select>
  )
}
