import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { RankingIcon } from '.'

import { constants } from 'assets'

interface Props {
  items?: RankingItem[]
}

export type RankingItem = {
  id: string
  answer: string
  count: number
}

interface RankingItemWithRanking extends RankingItem {
  ranking: number
}

export const Ranking: React.FC<Props> = ({ items }) => {
  const sorts = items
    ? items.sort((a, b) => {
        const x = a.count
        const y = b.count
        if (x > y) {
          return -1
        }
        if (x < y) {
          return 1
        }
        return 0
      })
    : []

  const rankings: RankingItemWithRanking[] = []

  let count = sorts[0]?.count ?? 0
  let ranking = 1
  sorts.forEach((cur) => {
    if (count !== cur.count) {
      ranking += 1
    }

    rankings.push({
      ...cur,
      ranking,
    })
    count = cur.count
  })
  return (
    <>
      {rankings.map((item, index) => (
        <ResultItem key={`q2-result-item-${index}-${item.id}`} item={item} ranking={item.ranking} />
      ))}
    </>
  )
}

const ResultItem: React.FC<{ item: RankingItem; ranking: number }> = ({ item, ranking }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <RankingIcon ranking={ranking} />
        <div className={classes.label}>{item.answer}</div>
      </div>
      <div className={classes.labelCount}>{item.count}票</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },

  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  label: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 14,
    color: constants.COLOR_ONBOARDING_MAIN,
  },
  labelCount: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 14,
    color: constants.COLOR_ONBOARDING_MAIN,
    width: 28,
    marginLeft: 8,
  },
}))
