import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { teamConstants } from 'pages/teams/assets/constants'
import {
  Card,
  DefaultButton,
  DefaultInputWithLabel,
  CardFooter,
  DefaultErrorModal,
  IconEditor,
  ButtonContainer,
  HorizontalLine,
  ResultFramework,
  ResultWithIcon,
  ReservationFramework,
} from 'pages/teams/components'
import { HooksContext, ModalErrorContext } from 'pages/teams/contexts'
import { useManageTeamMembers, useManageUser, useTeamMember, useUser } from 'pages/teams/hooks'
import { formatDateTime, convertToDateTime } from 'pages/teams/utils/dateTimeHandler'
import { CreateTeamMemberInput, TeamMemberRole, TeamMemberStatusUsage } from 'utils/generated'

import { CheckedIcon, UncheckedIcon } from './components'

import { constants } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'
import { PatternEmailLowerCase, PatternEmailLowerCaseMessage } from 'assets/patterns'

interface IInput extends CreateTeamMemberInput {
  [key: string]: string | null | undefined | TeamMemberRole[] | boolean
  isLeader?: boolean
  isMember?: boolean
  imageUrl?: string
  sendEmailDateAt?: string
  sendEmailTimeAt?: string
}

type Props = RouteComponentProps<{ teamId: string; teamMemberId?: string }>
type DateBox = {
  heading?: string
  date: string
  dayOfWeek: string
  time: string
}

export const TeamsMembersEditPage: React.FC<Props> = (props) => {
  const { teamId, teamMemberId } = props.match.params

  const classes = useStyles()

  const { route } = useHistory()
  const {
    currentUser,
    currentTeam,
    refreshTeam,
    currentTeamMember,
    refreshCurrentTeamMember,
    refreshCurrentUser,
    isAdmin,
    isLeader,
  } = React.useContext(HooksContext)
  const { onErrorModal } = React.useContext(ModalErrorContext)
  const { teamMember, loading } = useTeamMember(teamMemberId)
  const { user, loading: loadingUser } = useUser(teamMember?.userId)
  const { updateUser } = useManageUser()
  const { createTeamMember, updateTeamMember, deleteTeamMember, loading: processing } = useManageTeamMembers()
  const isMyself = currentTeamMember?.id === teamMemberId
  const isSmDown = useCustomMediaQuery('down', 'sm')
  const isXsDown = useCustomMediaQuery('down', 'xs')

  const [isDisplayDelete, setIsDisplayDelete] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (teamMemberId) {
      if (!loading && !teamMember) {
        alert('メンバー情報が取得できませんでした')
      }
    }
  }, [teamMemberId, teamMember, loading])

  const [openErrorModal, setOpenErrorModal] = React.useState<boolean>(false)
  const [tmpInputs, setTmpInputs] = React.useState<IInput | undefined>(undefined)

  const methods = useForm<IInput>()
  const { handleSubmit, control, errors } = methods
  const hasError = Object.keys(errors).length > 0

  const [complete, setComplete] = React.useState(false)
  const [dateBox, setDateBox] = React.useState<DateBox>()
  const isReservedMember = teamMember?.statusUsage === TeamMemberStatusUsage.Reserved
  const [isSendMailSoon, setIsSendMailSoon] = React.useState(false)

  const onSubmit = async (input: IInput) => {
    if (!currentTeam) {
      return
    }
    let completed = false
    setTmpInputs(undefined)

    let role = teamMember ? teamMember.role : [TeamMemberRole.Member]

    if (currentTeamMember?.role.includes(TeamMemberRole.Admin)) {
      if (input.isLeader) {
        role.push(TeamMemberRole.Leader)
      } else {
        role = role.filter((r) => r !== TeamMemberRole.Leader)
      }

      if (input.isMember !== undefined) {
        if (input.isMember) {
          role.push(TeamMemberRole.Member)
        } else {
          role = role.filter((r) => r !== TeamMemberRole.Member)
        }
      }
    }

    role = role.filter((e, i, s) => s.findIndex((d) => d === e) === i)

    const newLeaderUserId = teamMember?.userId || 'new'

    let invitationDateTime = ''
    // 招待予約日時が入力されている場合
    if (input.sendEmailDateAt && input.sendEmailTimeAt) {
      const { formattedDate, formattedDateJP, formattedTime, dayOfWeek } = formatDateTime(
        input.sendEmailDateAt,
        input.sendEmailTimeAt
      )
      invitationDateTime = `${formattedDate} ${formattedTime}`

      if (new Date(invitationDateTime) < new Date()) {
        alert('予約の時間は既に過ぎております。')
        return
      }

      setDateBox({
        date: formattedDateJP,
        dayOfWeek: `${dayOfWeek}曜日`,
        time: formattedTime,
      })
    } else if (isReservedMember) {
      // 招待予約日時が未入力 予約招待中のメンバーを即時招待する場合
      if (window.confirm('招待メールをすぐに送信します。よろしいでしょうか？')) {
        setIsSendMailSoon(true)
      } else {
        return
      }
    }

    if (
      currentTeam.leaderUserIds.length > 0 &&
      !currentTeam.leaderUserIds.includes(newLeaderUserId) &&
      input.isLeader &&
      !openErrorModal
    ) {
      setOpenErrorModal(true)
      setTmpInputs(input)
    } else {
      if (input.imageUrl) {
        const updated = await updateUser({ imageUrl: input.imageUrl })
        if (updated) completed = true
      }

      delete input.imageUrl
      delete input.isLeader
      delete input.isMember
      delete input.sendEmailDateAt
      delete input.sendEmailTimeAt

      if (!teamMemberId) {
        const [created, errors] = await createTeamMember({
          ...input,
          teamId,
          role,
          ...(invitationDateTime && { invitationDateTime }),
        })
        if (created) completed = true
        if (errors && errors.length > 0) {
          onErrorModal(errors.map((e) => e.message))
        }
      } else {
        delete input.email

        const updated = await updateTeamMember({
          id: teamMemberId,
          ...input,
          role,
          ...(invitationDateTime && { invitationDateTime }),
        })

        if (updated) {
          completed = true
          if (currentUser?.id === updated.userId) {
            refreshCurrentTeamMember()
            refreshCurrentUser()
          }
        }
      }
    }

    if (completed) {
      refreshTeam()
      setComplete(true)
    }

    window.scrollTo(0, 0)
  }

  const handleSubmitByModal = () => {
    setOpenErrorModal(false)
    if (tmpInputs) {
      onSubmit(tmpInputs)
    }
  }

  // 削除
  const handleDelete = async () => {
    setIsDeleting(true)

    if (teamMember) {
      if (!teamMember.role.includes(TeamMemberRole.Admin)) {
        await deleteTeamMember(teamMember.id)
        refreshTeam()
        route.push(replacePathParams(Pages.TeamsMembers, { teamId }))
      } else {
        alert('管理者は削除できません')
      }
    }

    setIsDisplayDelete(false)
    setIsDeleting(false)
  }

  const handleToMembers = () => {
    route.push(replacePathParams(Pages.TeamsMembers, { teamId }))
  }

  const handlePrepareDelete = () => {
    setIsDisplayDelete(true)
    window.scrollTo(0, 0)
  }

  const handleCancelDelete = () => {
    setIsDisplayDelete(false)
    window.scrollTo(0, 0)
  }

  const defaultValues = teamMember?.invitationDateTime
    ? {
        sendEmailDateAt: convertToDateTime(teamMember.invitationDateTime).getReservedDate,
        sendEmailTimeAt: convertToDateTime(teamMember.invitationDateTime).getReservedTime,
      }
    : {
        sendEmailDateAt: '',
        sendEmailTimeAt: '',
      }

  const deleteButtonNode =
    (isAdmin || isLeader) && teamMemberId && !isDisplayDelete && !(loadingUser || loading) ? (
      <div className={classes.deleteButtonContainer}>
        <Button onClick={handlePrepareDelete} className={classes.deleteButton}>
          削除
        </Button>
      </div>
    ) : (
      <></>
    )

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.editMemberRoot}>
          <Card
            headerTitle={`${isMyself ? 'アカウント' : 'メンバー'}${teamMemberId ? '編集' : '追加'}`}
            titleStyles={{ fontSize: 14 }}
            loading={loadingUser || loading}
            style={{ padding: isSmDown ? '16px 24px 24px' : 24 }}
            headerMb={0}
            titleContainerPb={0}
          >
            {!complete && !isDisplayDelete ? (
              <>
                <HorizontalLine parentPadding={24} style={{ marginTop: isSmDown ? 16 : 24, marginBottom: 24 }} />
                <ReservationFramework
                  defaultValues={defaultValues}
                  // 新規メンバー or 予約招待中のメンバー のみ表示
                  isShowForm={!teamMemberId || isReservedMember}
                  isReserved={isReservedMember}
                  lineParentPadding={24}
                  rightNode={
                    /* リーダーフラグ */
                    currentTeamMember?.role.includes(TeamMemberRole.Admin) ? (
                      <div>
                        {isXsDown && !isReservedMember && teamMemberId && (
                          <HorizontalLine parentPadding={24} style={{ marginBottom: 24 }} />
                        )}

                        <Controller
                          name={'isLeader'}
                          control={control}
                          defaultValue={!!teamMember?.role?.includes(TeamMemberRole.Leader)}
                          render={({ onChange, value }) => (
                            <FormGroup row={true} onChange={() => onChange(!value)}>
                              <FormControlLabel
                                label={'リーダーにする'}
                                classes={{
                                  label: classes.checkboxLabel,
                                }}
                                control={
                                  <Checkbox
                                    checked={value}
                                    name={'leader'}
                                    size="small"
                                    color="primary"
                                    icon={<CheckedIcon />}
                                    checkedIcon={<UncheckedIcon />}
                                    className={classes.checkbox}
                                    disableRipple
                                  />
                                }
                              />
                            </FormGroup>
                          )}
                        />
                        {teamMember?.role?.includes(TeamMemberRole.Admin) && (
                          <>
                            {isXsDown && (
                              <HorizontalLine parentPadding={24} style={{ marginTop: 24, marginBottom: 24 }} />
                            )}
                            <Controller
                              name={'isMember'}
                              control={control}
                              defaultValue={!!teamMember?.role?.includes(TeamMemberRole.Member)}
                              render={({ onChange, value }) => (
                                <FormGroup row={true} onChange={() => onChange(!value)}>
                                  <FormControlLabel
                                    label={'メンバーにする'}
                                    classes={{
                                      label: classes.checkboxLabel,
                                    }}
                                    control={
                                      <Checkbox
                                        checked={value}
                                        name={'member'}
                                        size="small"
                                        color="primary"
                                        icon={<CheckedIcon />}
                                        checkedIcon={<UncheckedIcon />}
                                        className={classes.checkbox}
                                        disableRipple
                                      />
                                    }
                                  />
                                </FormGroup>
                              )}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className={classes.iconContainer}>
                    <Controller
                      name={'imageUrl'}
                      control={control}
                      defaultValue={user ? user.imageUrl : ''}
                      render={({ onChange, value }) => (
                        <>
                          <IconEditor
                            name={teamMember ? teamMember.fullName : ''}
                            kind="users"
                            keyPrefix={user?.id || ''}
                            imageUrl={value}
                            onChange={onChange}
                          />
                        </>
                      )}
                    />
                  </div>
                  {/* 氏名 */}
                  <DefaultInputWithLabel
                    label={'氏名'}
                    htmlFor={'fullName'}
                    name={'fullName'}
                    defaultValue={teamMember ? teamMember.fullName : ''}
                    control={control}
                    rules={{ required: '氏名は必須です' }}
                    error={errors.fullName}
                    marginBottom={24}
                  />
                  {/* ユーザー名 */}
                  <DefaultInputWithLabel
                    label={'ユーザー名'}
                    htmlFor={'userName'}
                    name={'username'}
                    defaultValue={teamMember ? teamMember.username : ''}
                    control={control}
                    rules={{ required: 'ユーザー名は必須です' }}
                    error={errors.username}
                    marginBottom={24}
                  />
                  {/* 部署名 */}
                  <DefaultInputWithLabel
                    label={'部署名'}
                    htmlFor={'organization'}
                    name={'organization'}
                    defaultValue={teamMember ? teamMember.organization : ''}
                    control={control}
                    error={errors.organization}
                    marginBottom={24}
                    optional={'任意'}
                  />
                  {/* 役職 */}
                  <DefaultInputWithLabel
                    label={'役職'}
                    htmlFor={'position'}
                    name={'position'}
                    defaultValue={teamMember ? teamMember.position : ''}
                    control={control}
                    error={errors.position}
                    marginBottom={24}
                    optional={'任意'}
                  />
                  {/* メールアドレス */}
                  <DefaultInputWithLabel
                    label={'メールアドレス'}
                    htmlFor={'email'}
                    name={'email'}
                    defaultValue={teamMember ? teamMember.email : ''}
                    control={teamMemberId ? undefined : control}
                    rules={{
                      required: 'メールアドレスは必須です',
                      pattern: {
                        value: PatternEmailLowerCase,
                        message: PatternEmailLowerCaseMessage,
                      },
                    }}
                    error={errors.email}
                    readOnly={teamMemberId ? true : false}
                    marginBottom={24}
                  />
                </ReservationFramework>
                <HorizontalLine parentPadding={24} style={{ marginTop: 24, marginBottom: 24 }} />
                <CardFooter>
                  <ButtonContainer
                    buttons={[
                      <DefaultButton
                        key="button-default-2"
                        title={teamMemberId ? '更新' : '追加'}
                        color={hasError ? 'team_disabled' : 'team_main'}
                        type="submit"
                        disabled={hasError}
                        loading={processing}
                        style={{
                          ...teamConstants.cardFooterButtonStlyes,
                          border: hasError ? teamConstants.disabledBorder : undefined,
                        }}
                      />,
                      <DefaultButton
                        key="button-default-1"
                        title="キャンセル"
                        color="team_white"
                        onClick={() => route.push(Pages.TeamsMembers, { teamId })}
                        style={teamConstants.cardFooterButtonStlyes}
                      />,
                    ]}
                  />
                </CardFooter>

                {!isSmDown && deleteButtonNode}
              </>
            ) : (
              <ResultFramework>
                {isDisplayDelete ? (
                  <ResultWithIcon
                    topText="メンバーの削除"
                    iconType="warning"
                    message={`メンバーを削除します。復元することはできません。\nよろしいですか？`}
                    button={{
                      label: 'いいえ',
                      onClick: handleCancelDelete,
                      button2: {
                        label: 'はい',
                        onClick: handleDelete,
                        color: 'main',
                        disabled: isDeleting,
                      },
                    }}
                  />
                ) : (
                  <ResultWithIcon
                    topText={
                      isSendMailSoon
                        ? 'メンバーを招待しました'
                        : teamMemberId
                        ? 'メンバー情報を更新しました'
                        : 'メンバーを追加しました'
                    }
                    iconType="success"
                    message="メンバーリストから内容をご確認ください"
                    dateBox={dateBox}
                    button={{
                      label: 'メンバーリストに戻る',
                      onClick: handleToMembers,
                    }}
                  />
                )}
              </ResultFramework>
            )}
          </Card>

          <DefaultErrorModal open={openErrorModal} setOpen={setOpenErrorModal}>
            <div className={classes.modalDescription}>リーダーを変更しますか？</div>

            <ButtonContainer
              buttons={[
                <DefaultButton key={'buttons-1'} title={'はい'} color={'team_main'} onClick={handleSubmitByModal} />,
                <DefaultButton
                  key={'buttons-2'}
                  title={'いいえ'}
                  color={'team_white'}
                  onClick={() => setOpenErrorModal(false)}
                />,
              ]}
              marginTopPcLayout={0}
            />
          </DefaultErrorModal>
        </form>

        {isSmDown && deleteButtonNode}
      </FormProvider>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  editMemberRoot: {},

  iconContainer: {
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  checkboxLabel: {
    fontSize: 12,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
  },

  modalDescription: {
    fontSize: 16,
    marginBottom: 40,
  },
  checkbox: {
    //delete default hover effects
    '&.MuiRadio-colorSecondary.Mui-checked:hover': {
      backgroundColor: constants.COLOR_WHITE,
    },
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: constants.COLOR_WHITE,
    },
    '&.MuiIconButton-colorSecondary:hover': {
      backgroundColor: constants.COLOR_WHITE,
    },
    '&.MuiIconButton-root:hover': {
      backgroundColor: constants.COLOR_WHITE,
    },
    [theme.breakpoints.down('xs')]: {
      height: 24,
    },
  },

  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 8,

    [theme.breakpoints.up('md')]: {
      position: 'relative',
      justifyContent: 'flex-end',
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
    },
  },

  deleteButton: {
    fontSize: 14,
    fontWeight: 600,
    color: constants.COLOR_RED4,
    textDecoration: 'underline',

    [theme.breakpoints.up('md')]: {
      position: 'absolute', // ButtonContainer との調整が困難だったため、絶対位置で調整
      right: 8,
      top: -44, // キャンセル・更新ボタンとの高さを合わせるため
    },

    '&:hover': {
      //delete default css props
      opacity: 1,
      backgroundColor: '#FFFFFF00',
      textDecoration: 'underline',
    },
  },
}))
