import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { GhostTeamMember } from '../../../../utils/generated'

import { MemberItem } from './'

type Props = {
  currentMembers: (GhostTeamMember & { currentId?: string; isWaiting?: boolean })[] //current member counter
  page: 'break' | 'else'
  clickable?: boolean
  selectedValue?: string //if useTheme is break ,this prop is no use
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void //if useTheme is break ,this prop is no use
}

export const MemberBox: React.FC<Props> = ({ currentMembers, page, clickable, selectedValue, handleChange }) => {
  const classes = useStyles({})
  const props = { page, clickable, selectedValue, handleChange }

  return (
    <div className={classes.itemWrapperRoot}>
      {currentMembers.map((obj, index) => (
        <MemberItem key={index} currentMember={obj} {...props} />
      ))}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    itemWrapperRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
    },
  }),
  { name: 'MemberBox' }
)
