import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import arrowDownSvg from 'pages/teams/pages/_tools/karte/assets/arrowDown.svg'

import { serialNumDescList } from '../DataList'

import { constants } from 'assets'

const TEXT_SELECT = '回を選択'
const TEXT_NO_RESULT = '診断結果がありません'

type Props = {
  asyncItem: {
    maxTimes: number
    loaded: boolean
  }
  setTime: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const TimesFilter: React.FC<Props> = ({ asyncItem, setTime }) => {
  const { maxTimes, loaded } = asyncItem
  const classes = useStyles()

  const timesList = serialNumDescList(maxTimes)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(Number(e.target.value) || undefined)

    if (e.target.value === TEXT_SELECT || e.target.value === TEXT_NO_RESULT || !e.target.value) {
      e.target.style.color = constants.COLOR_TEAMBUILDING_NEUTRAL_500
    } else {
      e.target.style.color = constants.TEXT_GRAY_DARK
    }
  }

  return (
    <select className={classes.select} onChange={handleChange}>
      {loaded && (
        <>
          <option value={undefined}>{maxTimes ? TEXT_SELECT : TEXT_NO_RESULT}</option>
          {timesList.map((time) => (
            <option key={time} value={time}>
              {`第${time}回`}
            </option>
          ))}
        </>
      )}
    </select>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    select: {
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      outline: 'none',
      width: 200,
      height: 40,
      padding: 8,
      backgroundColor: '#fff', // ブラウザごとのデフォルトの背景色を上書き
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      fontSize: 12,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 8,
      cursor: 'pointer',
      backgroundImage: `url(${arrowDownSvg})`,
      backgroundPosition: 'right 14px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 16,

      '&:focus': {
        borderColor: 'transparent',
        outline: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
      },
    },
  }),
  { name: 'TimesFilter' }
)
