import * as React from 'react'

import { Box } from '@material-ui/core'
import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'

import Button from 'components/Button'
import { makeEvenPoints } from 'pages/teams/pages/_tools/karte/pages/diagnose/utils'

import * as constants from '../../assets/constants'
import SourceHanSansJP from '../../assets/fonts/SourceHanSansJP-Normal_web.woff'
import { buildResults } from '../../pages/karte/leader/results/utils/calc'
import {
  getKarteResultCommentBodyByTotal,
  getKarteResultCommentTitleByTotal,
  getKarteResultTitleByTotal,
} from '../../pages/karte/leader/static'

import { AutoWrapLine } from './AutoWrapLine'
import { Commitment } from './Commitment'
import { Graph } from './Graph'
import { Header } from './Header'
import { MembersAnswer } from './MembersAnswer'
import { SpeechBubbles } from './SpeechBubbles'
import KarteIcon from './svg/KarteIcon'
import Man0 from './svg/Man0'
import Man1 from './svg/Man1'
import Man2 from './svg/Man2'
import Man3 from './svg/Man3'
import Man4 from './svg/Man4'
import Woman0 from './svg/Woman0'
import Woman1 from './svg/Woman1'
import Woman2 from './svg/Woman2'
import Woman3 from './svg/Woman3'
import Woman4 from './svg/Woman4'
import { Title } from './Title'


interface IOwnProps {
  gender: 'man' | 'woman'
  karteNo: string
  name: string
  results: ReturnType<typeof buildResults>
  leaderData: {
    [key in 'expectation1' | 'expectation2' | 'expectation3' | 'hope1' | 'hope2' | 'hope3']: string
  }
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontFamily: 'SourceHanSansJP',
  },
  contents: {
    padding: 12,
  },
  summary: {
    backgroundColor: constants.COLOR_GRAY_LIGHT,
    borderRadius: 5,
    padding: 8,
    marginBottom: 8,
  },
  paragraph: {
    margin: '4px 12px',
    fontSize: 12,
  },
  description: {
    fontSize: 12,
    marginVertical: 14,
  },
  karteTopContainer: {
    flexDirection: 'row',
  },
  karteTopContainer__icon: {
    left: -24,
    padding: 0,
    marginTop: 8,
    width: 32,
    height: 32,
  },
  karteTopContainer__bubble: {
    marginLeft: 24,
  },
  karteTopContainer__bubble__result: {
    color: constants.COLOR_MAIN,
    marginTop: 4,
  },
  karteBottomContainer: {
    margin: '16px 0 8px 58px',
  },
  karteBottomContainer__bubble: {
    flexDirection: 'row',
  },
  karteBottomContainer__bubble__contents: {
    width: 260,
  },
  karteBottomContainer__bubble__icon: {
    width: 200,
    height: 120,
    left: -200,
  },
  matchingContainer: {
    flexDirection: 'row',
    margin: 0,
  },
  matchingContainer__contents: {
    width: 260,
    margin: '0 4px',
    padding: 0,
  },
  graphContainer: {
    marginTop: 12,
  },
  commitment__description: {
    marginVertical: 8,
    marginLeft: 6,
  },
})

export const KartePDF: React.FC<IOwnProps> = ({ gender, karteNo, name, results, leaderData }) => {
  React.useEffect(() => {
    Font.register({
      src: SourceHanSansJP,
      family: 'SourceHanSansJP',
    })
  }, [])

  const summaryOfKarte = [
    getKarteResultCommentTitleByTotal(results.total),
    getKarteResultCommentBodyByTotal(results.total),
    results.expects.goodTop
      ? `特に、今回あなたは${name}さんの一番重視している項目を捉えきれていないため、この点に関して、しっかりと対話し、信頼関係を深めていきましょう。`
      : '',
  ].join('')

  const resultIcon = () => {
    if (results.total === 100) {
      return gender === 'man' ? Man4({}) : Woman4({})
    } else if (results.total >= 75) {
      return gender === 'man' ? Man3({}) : Woman3({})
    } else if (results.total >= 50) {
      return gender === 'man' ? Man2({}) : Woman2({})
    } else if (results.total >= 25) {
      return gender === 'man' ? Man1({}) : Woman1({})
    } else {
      return gender === 'man' ? Man0({}) : Woman0({})
    }
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={styles.text}>
        <Header karteNo={karteNo} />
        <View style={styles.contents} wrap={false}>
          <View style={styles.summary}>
            <View style={styles.karteTopContainer}>
              <View style={styles.karteTopContainer__icon}>{KarteIcon({})}</View>
              <View style={styles.karteTopContainer__bubble}>
                <SpeechBubbles>
                  <Text>あなたと部下は…</Text>
                  <Text style={styles.karteTopContainer__bubble__result}>
                    {getKarteResultTitleByTotal(results.total)}
                  </Text>
                </SpeechBubbles>
              </View>
            </View>
            <View style={styles.karteBottomContainer}>
              <SpeechBubbles>
                <View style={styles.karteBottomContainer__bubble}>
                  <View style={styles.karteBottomContainer__bubble__contents}>
                    <AutoWrapLine text={summaryOfKarte} />
                  </View>
                  <View style={styles.karteBottomContainer__bubble__contents}>
                    <View style={styles.karteBottomContainer__bubble__icon}>{resultIcon()}</View>
                  </View>
                </View>
              </SpeechBubbles>
            </View>
          </View>
          <View style={styles.paragraph}>
            <Title text={'マッチング状態'} />
            <View style={styles.description}>
              <AutoWrapLine
                text={`あなたと${name}さんとの間では、${name}さんが重視している項目と、${name}さんに対してあなたが予想をした項目で${results.match.expect}個合致しています。`}
              />
            </View>
            <View style={styles.matchingContainer}>
              <View style={styles.matchingContainer__contents}>
                <MembersAnswer
                  gender={gender}
                  goodLabel={'重視している'}
                  badLabel={'重視していない'}
                  answerGood={results.characteristics.good}
                  answerBad={results.characteristics.bad}
                />
              </View>
              <View style={styles.matchingContainer__contents}>
                <MembersAnswer
                  gender="man"
                  goodLabel={'あなた自身'}
                  badLabel={'相手への予想'}
                  answerGood={[leaderData.hope1, leaderData.hope2, leaderData.hope3]}
                  answerBad={[leaderData.expectation1, leaderData.expectation2, leaderData.expectation3]}
                  compare={false}
                />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.contents} wrap={false}>
          <View style={styles.paragraph}>
            <Title text={'働く上で重視している項目'} />
            <View style={styles.graphContainer}>
              {results.points.map((item, index) => (
                <Graph
                  key={`graph-${index}`}
                  color={item.color}
                  title={item.title}
                  rankNumber={index + 1}
                  value={makeEvenPoints(item.value)}
                  description={item.description}
                />
              ))}
            </View>
          </View>
        </View>

        <View style={styles.contents} wrap={false}>
          <View style={styles.paragraph}>
            <Title text={`${name}さんの特徴的なこだわり`} />
            <View style={styles.description}>
              <AutoWrapLine
                text={
                  '全36問の質問文で見たときに、特にこだわりが見られたのが以下の通りであり、上司や周りの人にも共有しましょう。'
                }
              />
            </View>
            <View style={styles.summary}>
              <Text style={styles.commitment__description}>重視していること</Text>
              {results.memberCharacteristics.good.map((item, index) => (
                <Commitment key={`commitment-good-${index}`} dotColor={'yellow'} text={item} />
              ))}
            </View>
            <View style={styles.summary}>
              <Text style={styles.commitment__description}>重視していないこと</Text>
              {results.memberCharacteristics.bad.map((item, index) => (
                <Commitment key={`commitment-bad-${index}`} dotColor={'blue'} text={item} />
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  // FIXME 動作確認用のフラグ
  // const showPreview = false
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* {showPreview ? (
        <Box style={{ margin: '32px 0 16px 0' }}>
          <PDFViewer width="100%" height="800px">
            {MyDoc}
          </PDFViewer>
        </Box>
      ) : (
        <></>
      )} */}
      <PDFDownloadLink document={MyDoc} fileName={`${karteNo}.pdf`}>
        {({ loading }) => (
          <div style={{ width: 150 }}>
            <Button
              bgColor={constants.COLOR_MAIN}
              textColor={constants.TEXT_WHITE}
              fullWidth={false}
              square={true}
              style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
              body={'PDFで書き出す'}
              loading={loading ? true : false}
            />
          </div>
        )}
      </PDFDownloadLink>
    </Box>
  )
}
