import * as React from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: constants.COLOR_MAIN,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  isLoad: boolean
}

// -----------------------------
// Component
// -----------------------------
export const Loading = (props: IProps) => {
  const classes = useStyles()

  return (
    <>
      {props.isLoad ? (
        <Box display="flex" justifyContent="center" alignItems="center" py="30px">
          <CircularProgress className={classes.root} />
        </Box>
      ) : (
        <>{props.children}</>
      )}
    </>
  )
}
