import * as React from 'react'

import { Button } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { FontAwesome } from 'react-web-vector-icons'

import { OnboardingActionCategory } from 'utils/generated'

import { HEADER_HEIGHT } from '../header'

import * as constants from 'assets/constants'


interface IOwnProps {
  isOpen: boolean
  category?: OnboardingActionCategory
  onCategory: (category?: OnboardingActionCategory) => void
  onClose: () => void
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, isOpen, category, onClose, onCategory }) => {
  return (
    <div className={classes.search + ` ${isOpen ? 'open' : ''} `} style={{ display: isOpen ? 'block' : 'none' }}>
      <ul>
        <li className={classes.searchHeader}>
          <Button onClick={() => onCategory(undefined)} style={{ fontWeight: 'bold' }}>
            すべて
          </Button>
          <Button onClick={onClose}>
            <FontAwesome name="times" color={constants.COLOR_BLACK} size={16} />
            閉じる
          </Button>
        </li>
        <li
          onClick={() => onCategory(OnboardingActionCategory.Category1)}
          className={`${category === OnboardingActionCategory.Category1 ? 'activeSearch' : ''}`}
        >
          <img src={process.env.PUBLIC_URL + '/assets/svg/onboarding/search_category1.svg'} alt="member" />
          <div className="__title">チーム理解行動</div>
          <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
        </li>
        <li
          onClick={() => onCategory(OnboardingActionCategory.Category2)}
          className={`${category === OnboardingActionCategory.Category2 ? 'activeSearch' : ''}`}
        >
          <img src={process.env.PUBLIC_URL + '/assets/svg/onboarding/search_category2.svg'} alt="member" />
          <div className="__title">関係構築行動</div>
          <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
        </li>
        <li
          onClick={() => onCategory(OnboardingActionCategory.Category3)}
          className={`${category === OnboardingActionCategory.Category3 ? 'activeSearch' : ''}`}
        >
          <img src={process.env.PUBLIC_URL + '/assets/svg/onboarding/search_category3.svg'} alt="member" />
          <div className="__title">社会人自律行動</div>
          <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
        </li>
        <li
          onClick={() => onCategory(OnboardingActionCategory.Category4)}
          className={`${category === OnboardingActionCategory.Category4 ? 'activeSearch' : ''}`}
        >
          <img src={process.env.PUBLIC_URL + '/assets/svg/onboarding/search_category4.svg'} alt="member" />
          <div className="__title">自己成長行動</div>
          <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
        </li>
        <li
          onClick={() => onCategory(OnboardingActionCategory.Category5)}
          className={`${category === OnboardingActionCategory.Category5 ? 'activeSearch' : ''}`}
        >
          <img src={process.env.PUBLIC_URL + '/assets/svg/onboarding/search_category5.svg'} alt="member" />
          <div className="__title">意味づけ行動</div>
          <FontAwesome name="chevron-right" color={constants.COLOR_BLACK} size={16} />
        </li>
      </ul>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    search: {
      opacity: 0,
      position: 'fixed',
      top: HEADER_HEIGHT + 'px',
      width: '100%',
      zIndex: -1,
      boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
      height: `calc(100vh -  54px)`,
      background: 'rgba(10,10,10,0.5)',
      animation: `$slideClose 500ms ${theme.transitions.easing.easeInOut}`,

      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        background: 'white',

        '& li': {
          padding: '8px 16px',
          borderBottom: '2.5px solid ' + constants.COLOR_GRAY,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          '&.activeSearch': {
            backgroundColor: constants.COLOR_MAIN_LIGHT,
          },

          '& img': {
            height: '60px',
          },
          '& .__title': {
            width: '100%',
            padding: '0 32px',
            fontWeight: 'bold',
          },
        },
      },

      '&.open': {
        zIndex: 1,
        opacity: 1,
        animation: `$slide 500ms ${theme.transitions.easing.easeInOut}`,
      },
      '@global': {
        '@keyframes slide': {
          '0%': {
            opacity: 0,
            transform: 'translateX(100%)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateX(0px)',
          },
        },
        '@keyframes slideClose': {
          '0%': {
            opacity: 1,
            zIndex: 1,
            display: 'block',
            transform: 'translateX(0px)',
          },
          '1%': {
            display: 'block',
          },
          '100%': {
            opacity: 0,
            zIndex: -1,
            display: 'none',
            transform: 'translateX(100%)',
          },
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
