import React from 'react'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import {
  TeamBuildingReviewTeamAnswer,
  TeamBuildingReviewTeamMemberAnswer,
  TeamBuildingReviewSummaryHistoryAverage,
  TeamBuildingReviewTeamAnswerType,
  TeamBuildingReviewStatus,
} from 'utils/generated'

type PointWithName = {
  name: string
  point: number
  prevPoint: number | undefined
}

type Answer =
  | TeamBuildingReviewTeamAnswer
  | TeamBuildingReviewTeamMemberAnswer
  | TeamBuildingReviewSummaryHistoryAverage

export type PointData = {
  label: string | null | undefined
  pointsWithName: PointWithName[]
  points: number[]
  average: number
  prevAverage: number | undefined
}

const INIT_POINT_DATA = {
  label: '',
  pointsWithName: [],
  points: [],
  average: 0,
  prevAverage: 0,
} as PointData

const filterTeamGoal = (answer: Answer) => answer.type === TeamBuildingReviewTeamAnswerType.TeamGoal
const filterCodeOfConduct = (answer: Answer) => answer.type === TeamBuildingReviewTeamAnswerType.CodeOfConduct

export const useReviewPoints = ({ isDashboard = false }: { isDashboard?: boolean } = {}) => {
  const {
    summaryBuilding,
    teamBuildingReview: review,
    teamBuildingReviews,
    teamBuildingReviewMembers,
  } = React.useContext(TeamBuildingReviewHooksContext)

  const completedReviews = React.useMemo(
    () => teamBuildingReviews?.filter((review) => review.status === TeamBuildingReviewStatus.Completed) ?? [],
    [teamBuildingReviews]
  )

  // ダッシュボードでは、進行中のデータを表示しないようにする
  const teamBuildingReview = isDashboard ? completedReviews?.[0] ?? undefined : review

  const prevTeamBuildingReview = React.useMemo(() => {
    if (isDashboard) {
      // ダッシュボードでは、進行中のデータを表示しないようにする
      return completedReviews && completedReviews.length > 1 ? completedReviews[1] : undefined
    }

    return teamBuildingReviews && teamBuildingReviews.length > 1 ? teamBuildingReviews[1] : undefined
  }, [teamBuildingReviews, isDashboard, completedReviews])

  const goalPoints: PointData = React.useMemo(() => {
    if (!teamBuildingReviewMembers) {
      return INIT_POINT_DATA
    }
    return teamBuildingReviewMembers.items.reduce((prev, reviewMember) => {
      const found: TeamBuildingReviewTeamMemberAnswer | undefined = reviewMember.answers.find(filterTeamGoal)
      const average: number | undefined = teamBuildingReview?.answers?.find(filterTeamGoal)?.average ?? 0
      const prevAverage: number | undefined = prevTeamBuildingReview?.answers?.find(filterTeamGoal)?.average

      const prevUserValue = prevTeamBuildingReview?.answers?.reduce((prev, answer) => {
        if (!prev) {
          const found = answer.items?.find((item) => item.userId === reviewMember.userId)
          return found?.value
        }
        return prev
      }, undefined as number | undefined)

      if (found) {
        return {
          label: summaryBuilding?.goal,
          pointsWithName: [
            ...prev.pointsWithName,
            {
              name: reviewMember.username,
              point: found.value,
              prevPoint: prevUserValue,
            },
          ],
          points: [...prev.points, found.value],
          average,
          prevAverage,
        }
      }

      return prev
    }, INIT_POINT_DATA)
  }, [prevTeamBuildingReview, summaryBuilding, teamBuildingReview, teamBuildingReviewMembers])

  const codeOfConductPoints = React.useMemo(() => {
    if (!teamBuildingReviewMembers) {
      return []
    }
    return teamBuildingReviewMembers.items.reduce((prev, reviewMember) => {
      const targetAnswers: TeamBuildingReviewTeamMemberAnswer[] = reviewMember.answers.filter(filterCodeOfConduct)
      const averages: number[] =
        teamBuildingReview?.answers?.filter(filterCodeOfConduct)?.map((answer) => answer.average ?? 0) ?? []
      const filteredPrevReviewAnswers = prevTeamBuildingReview?.answers?.filter(filterCodeOfConduct)
      const prevAverages: number[] | undefined =
        prevTeamBuildingReview?.answers?.filter(filterCodeOfConduct)?.map((average) => average.average ?? 0) ?? []
      return targetAnswers.length
        ? targetAnswers.map((answer) => {
            const foundByIndex = prev[answer.index] ?? INIT_POINT_DATA
            const average = averages[answer.index] ?? 0
            const prevAverage = prevAverages[answer.index] ?? undefined
            const prevUserValue = filteredPrevReviewAnswers?.reduce((prev, answer) => {
              if (!prev) {
                const found = answer.items?.find((item) => item.userId === reviewMember.userId)
                return found?.value
              }
              return prev
            }, undefined as number | undefined)

            const label = summaryBuilding?.codeOfConducts ? summaryBuilding?.codeOfConducts[answer.index] : ''

            return {
              label,
              pointsWithName: [
                ...foundByIndex.pointsWithName,
                {
                  name: reviewMember.username,
                  point: answer.value,
                  prevPoint: prevUserValue,
                },
              ],
              points: [...foundByIndex.points, answer.value],
              average,
              prevAverage,
            }
          })
        : prev
    }, [] as PointData[])
  }, [prevTeamBuildingReview, summaryBuilding, teamBuildingReview, teamBuildingReviewMembers])

  return { goalPoints, codeOfConductPoints } as const
}
