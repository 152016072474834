import React from 'react'

import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as constants from 'assets/constants'

type Props = {
  open: boolean
  handleModalChange: () => void
  ownStyles?: React.CSSProperties
}

export const UsefulModal: React.FC<Props> = ({ open, handleModalChange, children, ownStyles }) => {
  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.usefulModalRoot} style={ownStyles}>
          {children}
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(() => ({
  usefulModalRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 350,
    background: constants.COLOR_WHITE,
    borderRadius: 8,
    outline: 'none',
    boxSizing: 'border-box',
    padding: 24,
    textAlign: 'center',
  },
}))
