import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { exhaustMap, filter, map, catchError } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'

import { signup, signin, resetPassword, restore, confirmSignUp, resendSignUp } from '../../services/amplify'
import getAccount from '../../services/api/getAccount'
import updateAccount from '../../services/api/updateAccount'
import { RootState } from '../reducers'

import { UserActions } from './action'

type Action = ActionType<typeof UserActions>

const getAccountStartedEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(UserActions.fetch.started.match),
    exhaustMap(({ payload }) => {
      return from(getAccount()).pipe(
        map((res) => {
          return UserActions.fetch.done({ params: payload, result: res })
        }),
        catchError((error) => of(UserActions.fetch.failed({ params: payload, error })))
      )
    })
  )

const updateAccountEpic: Epic<Action, Action, RootState> = (action$, store) =>
  action$.pipe(
    filter(UserActions.updateAccount.started.match),
    exhaustMap(({ payload }) => {
      return from(updateAccount(payload.params)).pipe(
        map((res) => {
          return UserActions.updateAccount.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.updateAccount.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const singupEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.signup.started.match),
    exhaustMap(({ payload }) => {
      return from(signup(payload)).pipe(
        map((res) => {
          return UserActions.signup.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.signup.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const singinEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.signin.started.match),
    exhaustMap(({ payload }) => {
      return from(signin(payload.user)).pipe(
        map((res) => {
          return UserActions.signin.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.signin.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const resetPasswordEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.resetPassword.started.match),
    exhaustMap(({ payload }) => {
      return from(resetPassword(payload.user)).pipe(
        map((res) => {
          return UserActions.resetPassword.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.resetPassword.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const restoreEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.restore.started.match),
    exhaustMap(({ payload }) => {
      return from(restore(payload.email)).pipe(
        map((res) => {
          return UserActions.restore.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.restore.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const confirmSignUpEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.confirmSignUp.started.match),
    exhaustMap(({ payload }) => {
      return from(confirmSignUp(payload.user, store.value.user.passwordRe)).pipe(
        map((res) => {
          return UserActions.confirmSignUp.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.confirmSignUp.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

const resendSignUpEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(UserActions.resendSignUp.started.match),
    exhaustMap(({ payload }) => {
      return from(resendSignUp(payload.email)).pipe(
        map((res) => {
          return UserActions.resendSignUp.done({
            params: payload,
            result: res,
          })
        }),
        catchError((error) =>
          of(
            UserActions.resendSignUp.failed({
              params: payload,
              error,
            })
          )
        )
      )
    })
  )

export default [
  getAccountStartedEpic,
  updateAccountEpic,
  singupEpic,
  singinEpic,
  resetPasswordEpic,
  restoreEpic,
  confirmSignUpEpic,
  resendSignUpEpic,
]
