import React from 'react'

import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { Breadcrumb } from 'components/Breadcrumb'
import { useCurrentUser, useManageTeamAdmin } from 'pages/teams/hooks'
import { Addresses, UpdateTeamAdminInput } from 'utils/generated'

import {
  BackCard,
  SignUpInputWithLabel,
  SignUpSelectBoxWithLabel,
  DefaultSignUpButton,
  SearchAddressButton,
} from './components'

import { constants } from 'assets'
import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'


export const TeamsStartPage: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isBreakpointUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { route } = useHistory()

  const { handleSubmit, control, errors, setValue } = useForm<UpdateTeamAdminInput>()
  const [confirm, setConfirm] = React.useState(false)

  const { user } = useCurrentUser()
  const { updateTeamAdmin, loading: processing } = useManageTeamAdmin()

  const handleBack = () => {
    if (confirm) {
      setConfirm(false)
      route.top()
    } else {
      route.push('/')
    }
  }

  // Search by zipcode ====>
  // const [zipcode, setZipcode] = React.useState<string | undefined | null>(undefined)
  const [zipcode, setZipcode] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    if (user) {
      setZipcode(user?.companyZipCode || undefined)
    }
  }, [user])
  const handleOnClickSearchAddress = async (result: Addresses) => {
    const { address1, address2, address3 } = result
    setValue('companyAddress1', `${address1}${address2}${address3}`)
  }
  // Search by zipcode <====

  const onSubmit = async (input: UpdateTeamAdminInput) => {
    if (!confirm) {
      setConfirm(true)
      route.top()
    } else {
      setConfirm(false)
      const res = await updateTeamAdmin(input)
      if (res) {
        route.push(Pages.TeamsNew)
      }
    }
  }

  return (
    <div className={classes.teamsStartPageRoot}>
      <div className={classes.breadCrumb}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="チーム管理者登録" />
      </div>
      <div className={classes.topContainer}>
        <h1>{confirm ? <>チーム管理者登録確認</> : <>チーム管理者登録</>}</h1>
        {confirm ? (
          <p>登録内容を確認して登録ボタンをクリックしてください。</p>
        ) : (
          <p>
            Cocolaboをチームで利用するにはまず管理者登録が必要です。
            <br />
            はじめに、管理者情報と法人情報を入力してください。
          </p>
        )}
      </div>
      <BackCard
        style={{
          marginTop: 80,
          padding: `60px ${isBreakpointUp ? '100px 86px' : '24px 45px'}`,
        }}
      >
        {user ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className={classes.subTitle}>管理者情報</h4>

            <SignUpInputWithLabel
              label={'ユーザー名'}
              name={'username'}
              control={control}
              rules={{ required: 'ユーザー名は必須です' }}
              error={errors.username}
              defaultValue={user.username || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'ログインID（メールアドレス）'}
              name={'email'}
              readOnly
              defaultValue={user.email || ''}
              confirm={confirm}
            />

            <SignUpInputWithLabel
              label={'氏名'}
              name={'fullName'}
              control={control}
              rules={{ required: '氏名は必須です' }}
              error={errors.fullName}
              defaultValue={user.fullName || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'電話番号'}
              name={'phone'}
              control={control}
              rules={{ required: '電話番号は必須です' }}
              error={errors.phone}
              defaultValue={user.phone || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'部署名'}
              name={'division'}
              control={control}
              rules={{ required: '部署名は必須です' }}
              error={errors.division}
              defaultValue={user.division || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'お役職'}
              name={'position'}
              control={control}
              rules={{ required: '電話番号は必須です' }}
              error={errors.position}
              defaultValue={user.position || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <h4 className={classes.subTitle}>法人情報</h4>

            <SignUpInputWithLabel
              label={'法人名'}
              name={'companyName'}
              control={control}
              rules={{ required: '法人名は必須です' }}
              error={errors.companyName}
              defaultValue={user.companyName || ''}
              confirm={confirm}
              readOnly={confirm}
            />
            <div className={classes.zipCodeBox}>
              <SignUpInputWithLabel
                label={'郵便番号'}
                name={'companyZipCode'}
                control={control}
                rules={{ required: '郵便番号は必須です' }}
                error={errors.companyZipCode}
                defaultValue={user.companyZipCode || ''}
                onChange={(e) => setZipcode(e.target.value)}
                width={102}
                confirm={confirm}
                readOnly={confirm}
              />
              {confirm ? <></> : <SearchAddressButton zipcode={zipcode} callBack={handleOnClickSearchAddress} />}
            </div>
            <SignUpInputWithLabel
              label={'所在地'}
              name={'companyAddress1'}
              control={control}
              rules={{ required: '所在地は必須です' }}
              error={errors.companyAddress1}
              defaultValue={user.companyAddress1 || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'電話番号'}
              name={'companyPhone'}
              control={control}
              rules={{ required: '電話番号は必須です' }}
              error={errors.companyPhone}
              defaultValue={user.companyPhone || ''}
              readOnly={confirm}
              confirm={confirm}
            />

            <SignUpSelectBoxWithLabel
              label={'従業員数'}
              name={'companyNumberOfEmployees'}
              control={control}
              rules={{
                required: '従業員数は必須です',
                validate: (value) => (value === '選択してください' ? '従業員数は必須です' : true),
              }}
              error={errors.companyNumberOfEmployees}
              defaultValue={'選択してください'}
              confirm={confirm}
              readOnly={confirm}
            />

            <SignUpInputWithLabel
              label={'請求用コード'}
              name={'billingCode'}
              control={control}
              error={errors.billingCode}
              defaultValue={user.billingCode || ''}
              confirm={confirm}
              readOnly={confirm}
            />

            {confirm ? (
              <></>
            ) : (
              <>
                <p className={classes.memo}>※御社のNEWONE担当者にご確認ください。</p>
              </>
            )}

            <div className={classes.buttonContainer}>
              <DefaultSignUpButton
                buttonBodyColor={'white'}
                bodyText={confirm ? '戻る' : 'キャンセル'}
                onClick={handleBack}
              />
              <DefaultSignUpButton
                buttonBodyColor={'blue'}
                bodyText={confirm ? '登録' : '入力確認に進む'}
                type="submit"
                loading={processing}
              />
            </div>
          </form>
        ) : (
          <></>
        )}
      </BackCard>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  teamsStartPageRoot: {
    backgroundColor: constants.COLOR_WHITE,
    padding: '0 0 140px',
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
  },
  breadCrumb: {
    margin: '0 0 0 16px',
    [theme.breakpoints.up(960 + 200)]: {
      //md + 200px
      margin: '0 0 0 calc((100% - 1128px)/2)',
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: constants.TEXT_GRAY_DARK,
    '& h1': {
      fontSize: 20,
      fontWeight: 'bold',
      margin: '25px 0 0',
      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    '& p': {
      margin: '30px 48px 0',
      textAlign: 'center',
      lineHeight: 1.8,
      fontWeight: 300,
      [theme.breakpoints.up('sm')]: {
        fontSize: 13,
        margin: '20px 0 0',
      },
    },
  },
  title: {
    fontSize: 18,
    marginBottom: 30,
    textAlign: 'center',
  },
  description: {
    fontSize: 14,
    marginBottom: 30,
    whiteSpace: 'pre-wrap',
    lineHeight: '24px',
  },
  subTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: constants.COLOR_MAIN_NEW,
    padding: '20px 0 0',
    margin: '0 0 30px -32px',
    '&:before': {
      content: '"ー"',
      margin: '0 14px 0 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 30px',
    },
  },
  zipCodeBox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  memo: {
    fontSize: 12,
    marginTop: 0,
    marginBottom: 40,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}))
