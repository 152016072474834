import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  ownStyles?: React.CSSProperties
}

export const BackResponsiveCard: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classes.backResponsiveCard} style={{ ...props.ownStyles }}>
      <div>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  backResponsiveCard: {
    margin: '0 16px 0',
    padding: '24px 0 40px',
    [theme.breakpoints.up(constGhost.CONTENT_WIDTH + 32)]: {
      maxWidth: constGhost.CONTENT_WIDTH,
      margin: '0 auto',
      paddingTop: 40,
    },
  },
}))
