import { actionCreatorFactory } from 'typescript-fsa'

import { ICreateConsultationParams, ICreateConsultationResult } from './apis/createConsultation'
import { ICreateConsultationCommentParams, ICreateConsultationCommentResult } from './apis/createConsultationComment'
import { ICreateHintParams, ICreateHintResult } from './apis/createHint'
import { ICreateHintCommentParams, ICreateHintCommentResult } from './apis/createHintComment'
import { IDeleteConsultationParams, IDeleteConsultationResult } from './apis/deleteConsultation'
import { IDeleteHintParams, IDeleteHintResult } from './apis/deleteHint'
import { IConsultationParams, IConsultationResult } from './apis/getConsultation'
import { IConsultationCommentListParams, IConsultationCommentListResult } from './apis/getConsultationCommentList'
import { IConsultationListParams, IConsultationListResult } from './apis/getConsultationList'
import { IConsultationListSearchParams, IConsultationListSearchResult } from './apis/getConsultationListSearch'
import { IHintParams, IHintResult } from './apis/getHint'
import { IHintCommentListParams, IHintCommentListResult } from './apis/getHintCommentList'
import { IHintListParams, IHintListResult } from './apis/getHintList'
import { IHintListProfileParams, IHintListProfileResult } from './apis/getHintListProfile'
import {
  IHintListProfileUnpublishedParams,
  IHintListProfileUnpublishedResult,
} from './apis/getHintListProfileUnpublished'
import { IHintListSearchParams, IHintListSearchResult } from './apis/getHintListSearch'
import { IUserParams, IUserResult } from './apis/getUser'
import { IUpdateConsultationParams, IUpdateConsultationResult } from './apis/updateConsultation'
import { IUpdateHintParams, IUpdateHintResult } from './apis/updateHint'
import { IAnswer } from './models/answer'
import { ICategory } from './models/category'
import { IComment } from './models/comment'
import { IPage } from './models/page'
import { IToken } from './models/token'

const actionCreator = actionCreatorFactory()

export default {
  // HINT
  getHint: actionCreator.async<IHintParams, IHintResult, any>('GET_HINT'),
  getHintList: actionCreator.async<IHintListParams, IHintListResult, any>('GET_HINT_LIST'),
  getHintListProfile: actionCreator.async<IHintListProfileParams, IHintListProfileResult, any>('GET_HINT_LIST_PROFILE'),
  getHintListProfileUnpublished: actionCreator.async<
    IHintListProfileUnpublishedParams,
    IHintListProfileUnpublishedResult,
    any
  >('GET_HINT_LIST_PROFILE_UNPUBLISHED'),
  getHintListSearch: actionCreator.async<IHintListSearchParams, IHintListSearchResult, any>('GET_HINT_LIST_SEARCH'),
  createHint: actionCreator.async<ICreateHintParams, ICreateHintResult, any>('CREATE_HINT'),
  updateHint: actionCreator.async<IUpdateHintParams, IUpdateHintResult, any>('UPDATE_HINT'),
  deleteHint: actionCreator.async<IDeleteHintParams, IDeleteHintResult | null, any>('DELETE_HINT'),
  getHintCommentList: actionCreator.async<IHintCommentListParams, IHintCommentListResult[], any>(
    'GET_HINT_COMMENT_LIST'
  ),
  createHintComment: actionCreator.async<ICreateHintCommentParams, ICreateHintCommentResult, any>(
    'CREATE_HINT_COMMENT'
  ),

  // CONSULTATION
  getConsultation: actionCreator.async<IConsultationParams, IConsultationResult, any>('GET_CONSULTATION'),
  getConsultationList: actionCreator.async<IConsultationListParams, IConsultationListResult, any>(
    'GET_CONSULTATION_LIST'
  ),
  getConsultationListSearch: actionCreator.async<IConsultationListSearchParams, IConsultationListSearchResult, any>(
    'GET_CONSULTATION_LIST_SEARCH'
  ),
  createConsultation: actionCreator.async<ICreateConsultationParams, ICreateConsultationResult, any>(
    'CREATE_CONSULTATION'
  ),
  updateConsultation: actionCreator.async<IUpdateConsultationParams, IUpdateConsultationResult, any>(
    'UPDATE_CONSULTATION'
  ),
  deleteConsultation: actionCreator.async<IDeleteConsultationParams, IDeleteConsultationResult | null, any>(
    'DELETE_CONSULTATION'
  ),
  getConsultationCommentList: actionCreator.async<
    IConsultationCommentListParams,
    IConsultationCommentListResult[],
    any
  >('GET_CONSULTATION_COMMENT_LIST'),
  createConsultationComment: actionCreator.async<
    ICreateConsultationCommentParams,
    ICreateConsultationCommentResult,
    any
  >('CREATE_CONSULTATION_COMMENT'),

  // COMMENT
  getComment: actionCreator<IComment>('GET_COMMENT'),

  // ANSWER
  getAnswer: actionCreator<IAnswer>('GET_ANSWER'),

  // PROFILE
  getUser: actionCreator.async<IUserParams, IUserResult, any>('GET_USER'),

  // PAGES
  getPages: actionCreator<IPage>('GET_PAGES'),
  getCategory: actionCreator<ICategory>('GET_CATEGORY'),

  // UI
  getModalActive: actionCreator('GET_MODAL_ACTIVE'),
  createModalActive: actionCreator<{ id: string }>('CREATE_MODAL_ACTIVE'),
  updateModalActive: actionCreator<{ id: string }>('UPDATE_MODAL_ACTIVE'),
  closeModalActive: actionCreator('CLOSE_MODAL_ACTIVE'),

  // TOKEN
  getToken: actionCreator<IToken>('GET_TOKEN'),
  resetToken: actionCreator('RESET_TOKEN'),

  // FETCH
  resetFetch: actionCreator('RESET_FETCH'),
}
