import React, { useState, useContext } from 'react'

import { Switch } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useForm, Controller } from 'react-hook-form'

import Button from 'components/Button'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import {
  ButtonContainer,
  FormLabelMark,
  DropZoneCsv,
  TemplateDownloadBox,
  CSVUploadedModal,
} from 'pages/onboarding/components'
import { IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from '../../../../assets/constants'
import { useCsvUpload } from '../../hooks/csvUpload'
import { FormDate } from '../member/add/components/FormDate'
import { FormTime } from '../member/add/components/FormTime'
import { Tooltip } from '../member/add/components/Tooltip'
import { OnbContext } from '../PagesRoot'

type PickInput = Pick<IFormCharCountInput, 'memberEmail'>
interface IInput extends PickInput {
  role: OnboardingTeamMemberRole
  startedAt: Date
  isReserve: Date
  sendEmailDateAt: Date
  sendEmailTimeAt: Date
}

export const AddMemberCSVBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)
    const [csvData, setCsvData] = useState<string[][]>([])
    const [uploadFile, setUploadFile] = useState<File>()
    const [open, setOpen] = useState(false)
    const isXsDown = useCustomMediaQuery('down', 'xs')
    const isBreakPointsUp = useCustomMediaQuery('up', 'sm')

    const { onUploadMembers, processing, uploadedNum, uploadErrors } = useCsvUpload(
      teamId,
      teamMember,
      csvData,
      setUploadFile,
      setCsvData,
      setOpen,
      teamMembers
    )
    const disabled = !csvData.length

    const [isReserveVisible, setIsReserveVisible] = useState<boolean>(false)
    const handleReserveClose = () => setIsReserveVisible(false)
    const handleReserveOpen = () => setIsReserveVisible(true)
    const { register, setValue, control, watch } = useForm<IInput>({
      mode: 'onChange',
    })

    const checkReserve = (): string => {
      if (!isReserveVisible) {
        return 'noData'
      }
      const sendEmailDateAt = watch('sendEmailDateAt')
      const sendEmailTimeAt = watch('sendEmailTimeAt')
      if (!sendEmailDateAt || sendEmailDateAt.toString() === 'Invalid Date' || sendEmailDateAt === null) {
        alert('予約招待の時間を再度ご確認ください。予約招待を使用しない場合は、チェックを外してください。')
        return 'error'
      }

      const year = new Date(sendEmailDateAt).getFullYear().toString()
      const month = (new Date(sendEmailDateAt).getMonth() + 1).toString()
      const day = new Date(sendEmailDateAt).getDate().toString()

      if (year !== 'NaN' && month !== 'NaN' && day !== 'NaN') {
        const newValue = `${year}/${month}/${day} ${sendEmailTimeAt || '0:00'}`
        if (new Date(newValue).toString() === 'Invalid Date') {
          alert('予約招待の時間を再度ご確認ください。フォーマットとズレている可能性があります。')
          return 'error'
        }
        if (new Date(newValue) < new Date()) {
          alert('予約の時間は既に過ぎております。')
          return 'error'
        }
        if (window.confirm(`${newValue}に予約招待をします。よろしいですか？`)) {
          // return false // test
          return newValue
        }
      }
      alert('入力内容を確認してください。') //exception
      return 'error'
    }

    const onUpload = () => {
      const date = checkReserve()
      if (date === 'error') {
        return
      } else if (date === 'noData') {
        onUploadMembers()
      } else {
        onUploadMembers(date)
      }
    }

    return {
      teamId,
      disabled,
      setCsvData,
      onUpload,
      processing,
      uploadedNum,
      uploadErrors,
      uploadFile,
      setUploadFile,
      open,
      setOpen,
      isXsDown,
      isBreakPointsUp,
      isReserveVisible,
      handleReserveClose,
      handleReserveOpen,
      register,
      setValue,
      control,
    }
  },
}

export const AddMemberCSV = () => {
  const classes = useStyles()
  const {
    teamId,
    disabled,
    setCsvData,
    onUpload,
    processing,
    uploadedNum,
    uploadErrors,
    uploadFile,
    setUploadFile,
    open,
    setOpen,
    isXsDown,
    isBreakPointsUp,
    isReserveVisible,
    handleReserveClose,
    handleReserveOpen,
    control,
  } = AddMemberCSVBloc.useAdapter()

  return (
    <>
      <TitleWithBack title={'メンバーの追加'} omitBack={false} spaceBetween />
      <div className={classes.root}>
        <div className={classes.text}>CSVファイルをアップロードしてください。</div>
        <DropZoneCsv uploadFile={uploadFile} setUploadFile={setUploadFile} setCsvData={setCsvData} />

        <div className={classes.reserveRootWrapper}>
          <div className={classes.reserveLabelWrapper}>
            <label htmlFor="kind" className={classes.inputWrapperLabel}>
              予約招待メール
            </label>
            <Tooltip
              ownStyles={{ margin: '0 0 0 8px' }}
              detailStyles={{ top: 40 }}
              arrowPosition={isBreakPointsUp ? 8 : 56}
            >
              <div className={classes.toolTipTitle}>予約招待メール</div>
              <div className={classes.toolText}>
                メンバーの追加をすると、入力したメールアドレス宛に招待メールが届きます。
                招待メールを送信する日付と時間を指定することができます。
              </div>
            </Tooltip>
            <FormLabelMark markType={'optional'} ownStyles={{ margin: '0 0 0 16px' }} />
            <Switch
              onClick={isReserveVisible ? handleReserveClose : handleReserveOpen}
              disableRipple
              classes={{
                root: classes.MuiSwitchRoot,
              }}
            />
          </div>
          {isReserveVisible ? (
            <div className={classes.reserveInputWrapper}>
              <Controller
                control={control}
                name="sendEmailDateAt"
                defaultValue={''}
                render={({ onChange, name, value }) => (
                  <FormDate
                    name={name}
                    onChange={onChange}
                    value={value}
                    ownStyles={{ maxWidth: '50%', minWidth: 150 }}
                  />
                )}
              />
              <Controller
                control={control}
                name="sendEmailTimeAt"
                defaultValue={''}
                render={({ onChange, name, value }) => (
                  <FormTime name={name} value={value} onChange={onChange} ownStyles={{ margin: '0 0 0 24px' }} />
                )}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className={classes.templateContainer}>
          <TemplateDownloadBox template="team-member" />
        </div>
      </div>
      <div className={classNames([classes.buttonBox, classes.separator, classes.bottomBtnWrapper])}>
        <ButtonContainer
          button={
            <Button
              disabled={disabled || processing}
              bgColor={disabled || processing ? constants.COLOR_ONBOARDING_WHITE : constants.COLOR_WHITE}
              textColor={
                disabled || processing ? constants.COLOR_ONBOARDING_TEXT_GRAY : constants.COLOR_ONBOARDING_MAIN
              }
              borderColor={disabled || processing ? constants.COLOR_GRAY : constants.COLOR_ONBOARDING_MAIN}
              fontSize={16}
              fullWidth={true}
              body={processing ? '処理中...' : '追加する'}
              noShadow={true}
              height={50}
              style={{ borderRadius: 8, width: isXsDown ? '100%' : 318 }}
              onClick={onUpload}
            />
          }
        />
      </div>

      <CSVUploadedModal
        open={open}
        setOpen={setOpen}
        importPage="member-add"
        teamId={teamId}
        uploadedCounts={uploadedNum}
        errRows={uploadErrors}
      />
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: 15,
      paddingTop: 20,
    },
    reserveRootWrapper: {
      background: constants.COLOR_WHITE,
      marginTop: 2,
      padding: '24px 20px',
    },
    reserveLabelWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    reserveInputWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '20px 0 0',
    },
    inputWrapperLabel: {
      display: 'block',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      position: 'relative',
    },
    toolTipTitle: {
      fontWeight: 'bold',
      paddingBottom: 13,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
    },
    toolText: {
      fontSize: 14,
      fontWeight: 'normal',
    },
    text: {
      fontWeight: 'bold',
      fontSize: '14',
      paddingBottom: 10,
      color: constants.TEXT_GRAY_DARK,
    },
    border: {
      border: 'solid 0.5px #E9E9E9',
    },
    templateContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    buttonBox: {
      backgroundColor: '#F9F9F9',
      marginBottom: 38,
    },
    separator: {
      borderTop: '1px solid #F5F5F5',
      marginTop: 15,
    },
    bottomBtnWrapper: {
      position: 'static',
    },
    MuiSwitchRoot: {
      margin: '0 0 0 24px',
      padding: 6,
      '& .MuiIconButton-root:hover': {
        backgroundColor: '#FFFFFF00',
      },
      '& .MuiIconButton-label': {
        color: constants.COLOR_WHITE,
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        borderRadius: 50,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        opacity: 1,
      },
    },
  }),
  { name: 'AddAllCsv' }
)
