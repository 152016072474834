import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'

import { ActionsType, RootStateType } from '../../../../store'
import { SetupActions } from '../../../../stores/setup/action'

const mapDispatchToProps = (dispatch: Dispatch<ActionsType>) => ({
  onMount: (week: number, setupStartedAt: string) =>
    dispatch(SetupActions.fetchPoint.started({ week, setupStartedAt })),
})

const mapStateToProps = (state: RootStateType) => {
  return {
    setup: { ...state.setup },
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
