import React from 'react'

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ColorQuestionTitles } from '../utils/constants'

import { constants } from 'assets'

type Props = {
  questionNumber: 1 | 2 | 2.5 | 3 | 4 | 5
}

export const QuestionPcLayout: React.FC<Props> = ({ questionNumber }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()

  return <>{isMdUp ? <p className={classes.question}>{ColorQuestionTitles({ questionNumber })}</p> : <></>}</>
}

const useStyles = makeStyles((theme: Theme) => ({
  question: {
    margin: '0 0 26px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 16,
    fontWeight: 'bold',
  },
}))
