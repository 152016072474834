import React, { useEffect, useState, useContext } from 'react'

import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { TitleWithBack } from 'pages/onboarding/components/title-with-back'

import { OnbContext } from '../PagesRoot'

import { Movie } from './component/movie'
import { movie_sorce as source } from './moviesPool'

import * as constants from 'assets/constants'

export const Usage: React.FC = () => {
  const { teamMember } = useContext(OnbContext)
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [isMember, setIsMember] = useState<boolean>(false)
  useEffect(() => {
    if (teamMember.role === 'MEMBER') {
      setIsMember(true)
    }
  }, [isMember, teamMember.role])

  const ToSupporterRole = () => {
    setIsMember(false)
  }
  const ToMemberRole = () => {
    setIsMember(true)
  }

  const filterMovieByMEMBER = source.filter((item) => {
    return item.role === 'MEMBER'
  })

  const isRoleIsMember = teamMember.role === 'MEMBER'

  return (
    <div className={classes.root}>
      {isSmDown ? (
        <TitleWithBack title="使い方" omitBack={false} toTimeLine />
      ) : (
        <div className={classes.title}>使い方</div>
      )}
      {isRoleIsMember ? (
        <div className={classes.tagWrapper}>
          <div className={!isMember ? classes.active : classes.unActive}>サポーター用</div>
          <div className={!isMember ? classes.unActive : classes.active}>メンバー用</div>
        </div>
      ) : (
        <div className={classes.tagWrapperNotMember}>
          <div
            className={!isMember ? classes.active : classes.unActive}
            onClick={() => {
              ToSupporterRole()
            }}
          >
            サポーター用
          </div>
          <div
            className={!isMember ? classes.unActive : classes.active}
            onClick={() => {
              ToMemberRole()
            }}
          >
            メンバー用
          </div>
        </div>
      )}
      <div className={classes.contentWrapper}>
        {!isMember ? (
          <div className={classes.movie}>
            {source.map((item, index) => {
              return <Movie item={item} key={index} />
            })}
          </div>
        ) : (
          <div className={classes.movie}>
            {filterMovieByMEMBER.map((item, index) => {
              return <Movie item={item} key={index} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 16px',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginTop: 16,
      paddingBottom: 26,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        position: 'relative',
      },
    },
    tagWrapper: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        textAlign: 'center',
        borderRadius: 4,
        margin: '0 16px 16px 16px',
      },
    },
    tagWrapperNotMember: {
      display: 'flex',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        textAlign: 'center',
        borderRadius: 4,
        margin: '0 16px 16px 16px',
      },
    },
    contentWrapper: {
      padding: 24,
      [theme.breakpoints.up('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    active: {
      color: constants.COLOR_ONBOARDING_MAIN,
      borderRadius: 4,
      backgroundColor: constants.COLOR_WHITE,
      padding: '11px 25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 4,
        borderRadius: 4,
      },
    },
    unActive: {
      color: constants.COLOR_GRAY_DARK,
      borderRadius: 4,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      padding: '11px 25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: 4,
        padding: '15px 25px',
      },
    },
    movie: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      paddingBottom: 25,
      [theme.breakpoints.down('sm')]: {
        display: 'inline',
      },
    },
  }),
  { name: 'Usage' }
)
