import { GhostMemberRole, GhostTeamMember } from 'utils/generated'

import { forceGetVoteResult } from '../api/report/handlers'
import { ErrorDialog } from '../components/organisms/Dialog'

import { exportDebugToS3 } from './debug'
import { sleep } from './wait'

// Provide this info in case error occurs
export type Because = 'undefined' | 'history-missing-ghost' | 'member-not-fetched' | 'history-not-fetched'

export type ErrorSnapshot = {
  teamId?: string
  teamMember?: GhostTeamMember
  because?: Because
  vars?: object
}

export const handleRenderError = async ({ teamId, teamMember, because, vars }: ErrorSnapshot): Promise<ErrorDialog> => {
  // logging
  const path = window.location.pathname
  exportDebugToS3(new Error(`Load err on ${path}`), {
    teamId,
    teamMember,
    because,
    vars,
  })

  // recovery & ui flow
  let dialog: ErrorDialog = 'generic'
  switch (because) {
    case 'history-missing-ghost':
      if (teamMember && (teamMember.role === GhostMemberRole.Leader || teamMember.role === GhostMemberRole.Admin)) {
        //delete  admin === true  later
        // auto call, not to show annoying dialog and behave as if nothing happened
        await forceGetVoteResult({ teamId: teamMember.teamId })
        // response buffer
        await sleep(1000)
        // manual call by dialog button, just in case
        dialog = 'forceResult'
      } else if (teamMember && teamMember.role === GhostMemberRole.Member) {
        await sleep(3000) // sleep while leader calls for result
        dialog = 'reload'
      }
      break
    // MAYBE: differentiate errors if needed
    default:
      dialog = 'reload'
      break
  }

  return dialog
}
