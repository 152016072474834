import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { CircularProgress, FormControlLabel, MenuItem, Modal, Radio, RadioGroup, Select } from '@material-ui/core'
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

import * as constants from '../../../../assets/constants'
import AlertDialog from '../../../../components/AlertDialog'
import UsefulButton from '../../../../components/Button'
import Button from '../../../../components/Button'
import MessagePanel from '../../../../components/MessagePanel'
import * as staticValues from '../static'
import { cookieKeys } from '../static'

import Slider from './components/Slider'
import Stepper from './components/Stepper'
import { connector, ContainerProps } from './index.container'
import {
  IKarteMemberQuestionnaireAnswerSet,
  IKarteMemberQuestionnaireAnswerValueSet,
  IKarteMemberQuestionnaireFormField,
} from './interface'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ leaderId: string; id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [cookies, setCookie, removeCookie] = useCookies([cookieKeys.number, cookieKeys.data])
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0)
  const [answers, setAnswers] = React.useState({} as IKarteMemberQuestionnaireAnswerSet)

  React.useEffect(() => {
    const number = cookies[cookieKeys.number]
    if (!number) {
      props.history.push(`/karte/member/${props.match.params.leaderId}`)
      return
    }

    const data = cookies[cookieKeys.data]
    if (data) {
      setCurrentStepIndex(data.step)
      setAnswers(data.answers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, setValue, errors, reset } = useForm<IKarteMemberQuestionnaireAnswerValueSet>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  React.useEffect(() => {
    reset() // reset form on change step

    const staticForm = staticValues.forms[currentStepIndex]
    staticForm.fields.forEach((field) => {
      register(field.key, { required: `${field.label}は必須です` })
      if (answers[field.group] && answers[field.group][field.key]) {
        setValue(field.key, answers[field.group][field.key])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex])

  const [backConfirm, setBackConfirm] = React.useState(false)

  const onBack = () => {
    removeCookie(cookieKeys.number)
    removeCookie(cookieKeys.data)

    props.history.push(`/karte/member/${props.match.params.leaderId}/questionnaire`)
    window.scrollTo(0, 0)
  }

  const onSubmit = () => {
    if (currentStepIndex < staticValues.forms.length - 1) {
      const step = currentStepIndex + 1
      setCurrentStepIndex(step)

      setCookie(cookieKeys.data, {
        step,
        answers,
      })
    } else {
      const number = cookies[cookieKeys.number]
      props.onStore(props.match.params.leaderId, number, answers, () => {
        removeCookie(cookieKeys.number)
        removeCookie(cookieKeys.data)

        props.history.push(
          `/karte/member/${props.match.params.leaderId}/questionnaire/${props.match.params.id}/results`
        )
      })
    }
    window.scrollTo(0, 0)
  }

  const renderQuestionnaireField = (field: IKarteMemberQuestionnaireFormField, index: number) => {
    const getFormAnswer = (group: string, fieldKey: string) => {
      return answers[group] && answers[group][fieldKey] ? answers[group][fieldKey] : null
    }
    const setFormAnswer = (group: string, fieldKey: string, value: string) => {
      const localAnswers = { ...answers }
      if (!answers[group]) {
        localAnswers[group] = {} as IKarteMemberQuestionnaireAnswerValueSet
      }
      localAnswers[group][fieldKey] = value
      setAnswers(localAnswers)
      setValue(field.key, value)
    }
    const errorMessage =
      errors.hasOwnProperty(field.key) && errors[field.key] ? errors[field.key]?.message || 'Unknown error' : null

    if (field.type === 'radio') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__radio">
            <RadioGroup
              row={true}
              aria-label={field.key}
              value={getFormAnswer(field.group, field.key)}
              name={field.key}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
            >
              {field.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  value={option.key}
                  control={
                    <Radio
                      classes={{
                        checked: classes.radioChecked,
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {errorMessage ? <small style={{ color: 'red' }}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'select') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={getFormAnswer(field.group, field.key) || ''}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
              autoWidth={true}
              displayEmpty={true}
              disableUnderline={true}
              classes={{
                root: '__container__content__body__select__root',
                icon: '__container__content__body__select__icon',
              }}
              className={`__container__content__body__select__select ${
                getFormAnswer(field.group, field.key) !== null ? 'hasValue' : ''
              }`}
            >
              <MenuItem value="" disabled>
                {field.label}を選択
              </MenuItem>
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage ? <small style={{ color: 'red' }}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'slider') {
      const valueString = getFormAnswer(field.group, field.key)
      let value: number | undefined
      if (valueString) {
        value = parseInt(valueString, 0)
      }
      return (
        <div key={index}>
          <div className="__container__content__body__title">
            {field.number}. {field.label}
          </div>
          <div className="__container__content__body__slider">
            <Slider
              key={index}
              name="member"
              value={value}
              options={field.options.map((option) => {
                return { label: option.label, value: parseInt(option.key, 0) }
              })}
              onChange={(value) => setFormAnswer(field.group, field.key, `${value}`)}
            />
          </div>
          {errorMessage ? <small style={{ color: 'red', paddingTop: 16 }}>{errorMessage}</small> : null}
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    const staticForm = staticValues.forms[currentStepIndex]
    return (
      <div className="__container__content" style={{ backgroundColor: staticForm.color || '#fff' }}>
        <div className="__container__content__title">{staticForm.label}</div>

        {staticForm.description ? (
          <div className="__container__content__description">{staticForm.description}</div>
        ) : null}

        <div className="__container__content__body">{staticForm.fields.map(renderQuestionnaireField)}</div>

        <div className="__container__content__buttons">
          <UsefulButton
            bgColor={constants.COLOR_BLUE}
            textColor={constants.COLOR_WHITE}
            fullWidth={true}
            body={<div>戻る</div>}
            onClick={() => {
              if (currentStepIndex === 0) {
                setBackConfirm(true)
              } else {
                setCurrentStepIndex(currentStepIndex - 1)
              }
            }}
          />

          <UsefulButton
            bgColor={constants.COLOR_GRAY}
            textColor={constants.COLOR_BLACK}
            fullWidth={true}
            body={<div>次へ</div>}
            submit={true}
            // onClick={handleStartSeup}
          />
        </div>
      </div>
    )
  }

  const renderConfirmationDialog = () => {
    return (
      <Modal open={backConfirm} className={classes.modal} disableBackdropClick={true}>
        <div>
          <MessagePanel
            failure={true}
            handleClose={() => {
              setBackConfirm(false)
            }}
            body={
              <div>
                <p>
                  分析を途中で中止しますか?
                  <br />
                  「はい」を押すと途中まで回答したデータは破棄して診察室に戻ります。
                </p>
              </div>
            }
            footer={
              <div className={classes.modalButtons}>
                <Button
                  bgColor={constants.COLOR_BLACK}
                  fullWidth={true}
                  body={'いいえ'}
                  onClick={() => {
                    setBackConfirm(false)
                  }}
                />
                <Button bgColor={constants.COLOR_SECOND} fullWidth={true} body={'はい'} onClick={onBack} />
              </div>
            }
          />
        </div>
      </Modal>
    )
  }

  const renderLoading = () => {
    if (!props.isLoading) return null
    return (
      <Modal
        open={props.isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disableBackdropClick={true}
      >
        <CircularProgress className={classes.loadingCircularProgress} />
      </Modal>
    )
  }

  const renderError = () => {
    if (!props.error) return null

    const onClose = () => {
      props.onResetError()
    }

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="__container__header">
              <div style={{ width: '100%', textAlign: 'center' }}>メンバー診察室</div>
            </div>
            <div className="__container__stepper">
              <Stepper activeStepIndex={currentStepIndex} stepCount={staticValues.forms.length} />
            </div>
            {renderContent()}
          </form>
        </div>
        {renderConfirmationDialog()}
        {renderLoading()}
        {renderError()}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 48,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__stepper': {
          padding: '0 32px 24px',
        },

        '&__content': {
          backgroundColor: '#fff',
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px 0',
          },

          '&__description': {
            color: constants.COLOR_RED,
            fontSize: 12,
            textAlign: 'center',
            marginTop: 20,
            whiteSpace: 'pre',
          },

          '&__body': {
            padding: 16,
            //

            '&__title': {
              fontWeight: 'bold',
              borderBottom: `1px solid ${constants.COLOR_MAIN}`,
              paddingBottom: '4px',
              marginBottom: '4px',
            },
            '&__radio': {
              padding: '4px 16px',
            },
            '&__select': {
              padding: '16px 0',

              '&__select': {
                // padding: "4px 16px",
                width: '100%',
                border: '1px solid #BEBEBE',
                '&.hasValue': {
                  '& .__container__content__body__select__root': {
                    color: 'black !important',
                  },
                },
              },

              '&__root': {
                // padding: "4px 16px",
                padding: '10px 12px',
                fontSize: 14,
                color: '#BEBEBE',
              },

              '&__icon': {
                color: 'white',
                background: constants.COLOR_MAIN,
                width: 40,
                height: '100%',
                top: 0,
              },
            },
            '&__slider': {
              paddingTop: '16px',
              paddingBottom: '16px',
            },
          },

          '&__buttons': {
            display: 'flex',
            padding: 16,

            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },

            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
        },
      },
    },

    radioChecked: {
      color: `${constants.COLOR_MAIN} !important`,
    },

    modal: {
      padding: 16,

      '& div': {
        '&:focus': {
          outline: 'none',
        },
      },
    },

    modalButtons: {
      display: 'flex',
      marginTop: '8px',
      '& :first-child': {
        marginRight: '4px',
      },
      '& :last-child': {
        marginLeft: '4px',
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
