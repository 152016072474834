import React from 'react'

import MUIPopover from '@material-ui/core/Popover'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
  popover: Popover
} & React.ComponentProps<'div'>

type Popover = {
  isOpenPopover: boolean
  anchorEl: HTMLButtonElement | null
  popoverId: string | undefined
  handleClosePopover: () => void
  anchorOrigin?: {
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
  }
  transformOrigin?: {
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
  }
}

export const Popover: React.FC<Props> = ({ children, popover, className }) => {
  const { isOpenPopover, anchorEl, popoverId, handleClosePopover, anchorOrigin, transformOrigin } = popover
  const classes = useStyles()

  return (
    <MUIPopover
      id={popoverId}
      className={`${classes.popover} ${className}`}
      open={isOpenPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: anchorOrigin?.vertical ?? 'bottom',
        horizontal: anchorOrigin?.horizontal ?? 'center',
      }}
      transformOrigin={{
        vertical: transformOrigin?.vertical ?? 'top',
        horizontal: transformOrigin?.horizontal ?? 'center',
      }}
      disableRestoreFocus
    >
      {children}
    </MUIPopover>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    popover: {
      maxHeight: 'calc(100vh - 100px)',
      // PC サイズでは hover で表示するため、pointerEvents を none にする。
      [theme.breakpoints.up('md')]: {
        pointerEvents: 'none',
      },
      '& .MuiPopover-paper': {
        padding: 16,
        fontSize: 14,
        borderRadius: 8,
        background: constants.COLOR_BLUE_LIGHT2,
        boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.16)',
      },
    },
  }),
  { name: 'Popover' }
)
