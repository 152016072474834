import { combineEpics } from 'redux-observable'

import KarteLeaderEpic from './karte/leader/epic'
import KarteLeaderQuestionnaireEpic from './karte/leader/questionnaire/epic'
import KarteLeaderResultsEpic from './karte/leader/results/epic'
import KarteLeaderSearchEpic from './karte/leader/search/epic'
import KarteMemberEpic from './karte/member/epic'
import KarteMemberQuestionnaireEpic from './karte/member/questionnaire/epic'
import KarteMemberResultsEpic from './karte/member/results/epic'

export default combineEpics(
  KarteLeaderSearchEpic,
  KarteLeaderQuestionnaireEpic,
  KarteLeaderResultsEpic,
  KarteLeaderEpic,
  KarteMemberQuestionnaireEpic,
  KarteMemberResultsEpic,
  KarteMemberEpic
)
