import * as React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useOnboardingPostSecondComment } from 'pages/onboarding/hooks/timeline'

import { CommentAvatar, CommentAvatarProps } from './components/CommentAvatar'
import { CommentSet, CommentSetProps } from './components/CommentSet'

type Props = { first?: boolean } & CommentAvatarProps & CommentSetProps

export const CommentListItem: React.FC<Props> = ({
  teamId,
  teamMembers,
  item,
  userId,
  first,
  allEditClose,
  setAllEditClose,
}) => {
  const classes = useStyles()
  const { postSecondComments } = useOnboardingPostSecondComment(item.id)

  return (
    <div className={`${classes.container} ${first ? classes.first : classes.notFirst}`}>
      <div>
        <CommentAvatar teamId={teamId} item={item} />
      </div>
      <div className={classes.content}>
        <CommentSet
          teamId={teamId}
          teamMembers={teamMembers}
          item={item}
          userId={userId}
          allEditClose={allEditClose}
          setAllEditClose={setAllEditClose}
        />
        {postSecondComments.length ? (
          postSecondComments.map((secondComment, index) => (
            <div
              key={secondComment.id}
              className={`${classes.container} ${index === 0 ? classes.first : classes.notFirst}`}
            >
              <div>
                <CommentAvatar teamId={teamId} item={secondComment} />
              </div>
              <div className={classes.content}>
                <CommentSet
                  teamId={teamId}
                  teamMembers={teamMembers}
                  item={secondComment}
                  userId={userId}
                  isSecondComment
                />
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    first: {
      marginTop: 16,
    },
    notFirst: {
      marginTop: 24,
    },
    content: {
      width: '100%',
    },
  }),
  { name: 'CommentListItem' }
)
