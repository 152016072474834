import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Menu, MenuItem } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'

import { TeamConnection } from 'utils/generated'

import ChangeIcon from '../../../assets/ChangeIcon.svg'

import { replacePathParams } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = {
  teams: TeamConnection | undefined
}
type StyleProps = {
  classes: ClassNameMap
  visibleDetail: boolean
}

export const ChangePlanButton: React.FC<Props & StyleProps> = ({ teams, classes, visibleDetail }) => {
  const pureHistory = useHistory()
  const [anchorPlanEl, setAnchorPlanEl] = React.useState<null | HTMLElement>(null)
  const openPlan = Boolean(anchorPlanEl)
  const handleClickPlan = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPlanEl(event.currentTarget)
  }
  const handleClosePlan = () => {
    setAnchorPlanEl(null)
  }
  const handleClickTeam = () => {
    if (teams && teams.items.length > 0) {
      pureHistory.push(replacePathParams(Pages.TeamsDashboard, { teamId: teams.items[0].id }))
    }
  }

  return (
    <>
      <Button
        aria-controls="plan-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClickPlan}
        disableTouchRipple
        fullWidth
        className={classes.buttonPlan}
      >
        <img src={ChangeIcon} alt={'ChangeIcon'} />
        {visibleDetail ? <span className={classes.labelPlan}>プラン切替</span> : <></>}
      </Button>
      <Menu
        id="plan-menu"
        getContentAnchorEl={null}
        anchorEl={anchorPlanEl}
        open={openPlan}
        onClose={handleClosePlan}
        classes={{ paper: classes.paperPlan }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={handleClosePlan}
          disableRipple
          className={classNames([classes.menuItemPlan, classes.activePlan])}
        >
          オンボーディングプラン
        </MenuItem>
        <MenuItem onClick={handleClickTeam} disableRipple className={classes.menuItemPlan}>
          チームビルディングプラン
        </MenuItem>
      </Menu>
    </>
  )
}
