import * as React from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

import { DefaultButton } from '..'

import { constants } from 'assets'

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  teamName?: string
  isAdmin?: boolean
  isLoading?: boolean
  linkTo?: string
  endIcon?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
}

export const CategoryButton: React.FC<Props> = (props) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <DefaultButton
        onClick={props.onClick}
        loading={props.isLoading}
        loadingColor={constants.COLOR_TEAMBUILDING_PRIMARY}
        style={{
          color: constants.COLOR_MAIN_NEW,
          backgroundColor: constants.COLOR_WHITE,
          fontSize: isMdUp ? 12 : 10,
          padding: '12px 16px',
          fontWeight: 'bold',
          border: isMdUp ? `1px solid ${constants.COLOR_MAIN_NEW}` : undefined,
          boxShadow: 'none',
          minWidth: 'auto',
          width: isMdUp ? 120 : '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        endIcon={props.endIcon ?? undefined}
      >
        {props.linkTo}
      </DefaultButton>
    </>
  )
}
