export const queryGetGhostTeamMember = /* GraphQL */ `
  query GetGhostTeamMember($id: ID!) {
    getGhostTeamMember(id: $id) {
      id
      teamId
      userId
      name
      email
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const queryGetGhostTeamMemberList = /* GraphQL */ `
  query GetGhostTeamMemberList($teamId: ID!) {
    getGhostTeamMemberList(teamId: $teamId) {
      items {
        id
        teamId
        userId
        name
        email
        manager
        role
        page
        createdAt
        updatedAt
      }
    }
  }
`

export const mutationCreateGhostTeamMember = /* GraphQL */ `
  mutation CreateGhostTeamMember($input: GhostTeamMemberCreateInput!) {
    createGhostTeamMember(input: $input) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const mutationUpdateGhostTeamMember = /* GraphQL */ `
  mutation UpdateGhostTeamMember($input: GhostTeamMemberUpdateInput!) {
    updateGhostTeamMember(input: $input) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const mutationUpdateGhostTeamMemberByAdmin = /* GraphQL */ `
  mutation UpdateGhostTeamMemberByAdmin($input: GhostTeamMemberUpdateInput!) {
    updateGhostTeamMemberByAdmin(input: $input) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const mutationDeleteGhostTeamMember = /* GraphQL */ `
  mutation DeleteGhostTeamMember($id: ID!) {
    deleteGhostTeamMember(id: $id) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const subscriptionUpdateGhostTeamMember = /* GraphQL */ `
  subscription OnUpdateGhostTeamMember($teamId: ID!) {
    onUpdateGhostTeamMember(teamId: $teamId) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const subscriptionCreateGhostTeamMember = /* GraphQL */ `
  subscription OnCreateGhostTeamMember($teamId: ID!) {
    onCreateGhostTeamMember(teamId: $teamId) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`

export const subscriptionDeleteGhostTeamMember = /* GraphQL */ `
  subscription OnDeleteGhostTeamMember($teamId: ID!) {
    onDeleteGhostTeamMember(teamId: $teamId) {
      id
      teamId
      userId
      manager
      role
      page
      createdAt
      updatedAt
    }
  }
`
