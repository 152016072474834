import * as React from 'react'

import { Box, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '48px 40px',

    [theme.breakpoints.down('md')]: {
      padding: '35px 15px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  className?: string
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const Pager = (props: IProps) => {
  const classes = useStyles()

  return <Box className={classes.root}>{props.children}</Box>
}
