import React from 'react'

import { TableFilter } from 'pages/teams/pages/dashboard/components/tabs/contents/ghost/components/MembersList'
import { GhostHistory, GhostTeam, TeamMember } from 'utils/generated'

export type MemberGhostHistories = {
  teamMemberId: string
  histories: GhostHistoryData[]
}

export type GhostHistoryData = {
  historyId: string
  selectedGhostIds: number[]
  playDate: string
  times: number
}

type GhostHistoryWithTimes = GhostHistory & { times: number }

export const useGetCompletedHistories = (
  histories: GhostHistory[],
  ghostTeam?: GhostTeam
): { completedHistories: GhostHistoryWithTimes[] } => {
  const completedHistories = React.useMemo(() => {
    if (!histories.length) return []

    // histories[0]: createdAt が降順となるように取得しているため、最新のオバケデータは histories[0] になる
    // slice(1): 進行中のオバケがある場合、histories から除外する
    const completedHistories = histories[0].id === ghostTeam?.id ? histories.slice(1) : histories

    return completedHistories.map((h, i) => ({
      ...h,
      times: completedHistories.length - i, // 何回目の探索結果か
    }))
  }, [histories, ghostTeam])

  return { completedHistories }
}

export const useFormatGhostHistories = (
  teamMembers: TeamMember[],
  completedHistories: GhostHistoryWithTimes[],
  filter: TableFilter
) => {
  const sortedAndFilteredTeamMembers = React.useMemo(() => {
    if (!teamMembers.length) return []

    // メンバー名でフィルタリング
    const filteredTeamMembers = filter.targetMemberName?.length
      ? teamMembers.filter((member) => member.fullName.includes(filter.targetMemberName))
      : teamMembers

    // チームメンバーを名前の50音順にソート
    return filteredTeamMembers.sort((a, b) => a.fullName.localeCompare(b.fullName, 'ja'))
  }, [teamMembers, filter.targetMemberName])

  const filteredCompletedHistories = React.useMemo(() => {
    if (!completedHistories.length) return []
    if (!filter.allTargetHistoryId) return completedHistories

    const selectedHistory = completedHistories.find((h) => h.id === filter.allTargetHistoryId)

    // allTargetHistoryId と一致する id の history データを配列の先頭に格納する (重複は排除する)
    return selectedHistory ? [...new Set([selectedHistory, ...completedHistories])] : completedHistories
  }, [completedHistories, filter.allTargetHistoryId])

  // テーブルでの表示に扱いやすい形に整形する
  const membersHistoriesMap = React.useMemo(() => {
    // チームメンバーごとのオバケ選択データを格納する Map を作成
    const membersHistoriesMap = new Map<string, MemberGhostHistories>()
    for (const member of sortedAndFilteredTeamMembers) {
      membersHistoriesMap.set(member.id, { teamMemberId: member.id, histories: [] })
    }

    for (const history of filteredCompletedHistories) {
      if (!history.selectedGhostData?.length) {
        for (const member of sortedAndFilteredTeamMembers) {
          const memberHistories = membersHistoriesMap.get(member.id)
          if (memberHistories)
            memberHistories.histories.push({
              historyId: history.id,
              selectedGhostIds: [],
              playDate: history.createdAt,
              times: history.times,
            })
        }
        continue
      }

      // オバケ選択データ (selectedGhostData) が無いメンバーには空配列の選択データを追加
      const notGhostSelectedMembers = sortedAndFilteredTeamMembers.filter(
        (member) => !history.selectedGhostData?.some((data) => data.teamMemberId === member.id)
      )
      for (const member of notGhostSelectedMembers) {
        if (!history.selectedGhostData?.some((data) => data.teamMemberId === member.id)) {
          const memberHistories = membersHistoriesMap.get(member.id)
          if (memberHistories) {
            memberHistories.histories.push({
              historyId: history.id,
              selectedGhostIds: [],
              playDate: history.createdAt,
              times: history.times,
            })
          }
          continue
        }
      }

      for (const data of history.selectedGhostData) {
        const memberHistories = membersHistoriesMap.get(data.teamMemberId)
        if (memberHistories)
          memberHistories.histories.push({
            historyId: history.id,
            selectedGhostIds: data.ghostIds,
            playDate: history.createdAt,
            times: history.times,
          })
      }
    }

    return membersHistoriesMap
  }, [sortedAndFilteredTeamMembers, filteredCompletedHistories])

  // return Array.from(membersHistoriesMap.values())
  const membersHistories = Array.from(membersHistoriesMap.values())

  // メンバーごとのオバケ選択データを表示するためのデータを返す
  const filteredMembersHistories = React.useMemo(() => {
    if (!membersHistories?.length) return []
    if (!filter.memberHistory.memberId || !filter.memberHistory.targetHistoryId) return membersHistories

    return membersHistories.map((memberHistories) => {
      if (memberHistories.teamMemberId !== filter.memberHistory.memberId) return memberHistories

      const selectedHistory = memberHistories.histories.find(
        (h) => h.historyId === filter.memberHistory.targetHistoryId
      )

      return {
        ...memberHistories,
        histories: selectedHistory
          ? [...new Set([selectedHistory, ...memberHistories.histories])]
          : memberHistories.histories,
      }
    })
  }, [membersHistories, filter.memberHistory])

  return filteredMembersHistories
}
