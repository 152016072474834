import * as React from 'react'

import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles'

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={`${classes.container}${className ? ` ${className}` : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.25" viewBox="0 0 14 12.25">
        <path
          d="M7-11.375c-3.866,0-7,2.546-7,5.687A5.048,5.048,0,0,0,1.559-2.114,6.956,6.956,0,0,1,.06.506.217.217,0,0,0,.019.744a.214.214,0,0,0,.2.131A6.253,6.253,0,0,0,4.063-.53,8.339,8.339,0,0,0,7,0c3.866,0,7-2.546,7-5.687S10.866-11.375,7-11.375ZM3.5-4.812a.874.874,0,0,1-.875-.875A.874.874,0,0,1,3.5-6.562a.874.874,0,0,1,.875.875A.874.874,0,0,1,3.5-4.812Zm3.5,0a.874.874,0,0,1-.875-.875A.874.874,0,0,1,7-6.562a.874.874,0,0,1,.875.875A.874.874,0,0,1,7-4.812Zm3.5,0a.874.874,0,0,1-.875-.875.874.874,0,0,1,.875-.875.874.874,0,0,1,.875.875A.874.874,0,0,1,10.5-4.812Z"
          transform="translate(0 11.375)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
